import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Organization Loader
 */
export const setOrganizationLoader = createAction(Actions.SET_ORGANIZATION_LOADER);
/**
 * @desc Set Organization List
 */
export const setOrganizationList = createAction(Actions.SET_ORGANIZATION_LIST);
/**
 * @desc Set Current Organization
 */
export const setCurrentOrganization = createAction(Actions.SET_CURRENT_ORGANIZATION);
/**
 * @desc Update organization field value
 */
export const updateCurrentOrganization = createAction(Actions.UPDATE_ORGANIZATION_FIELD_VALUE);

/**
 * @desc Set Company Item
 */
export const setCompanyItem = createAction(Actions.SET_COMPANY_ITEM);
/**
 * @desc Update Company Item
 */
export const updateCompanyItem = createAction(Actions.UPDATE_COMPANY_ITEM);
/**
 * @desc Update Organization Item
 */
export const updateOrganizationItem = createAction(Actions.UPDATE_NEW_ORGANIZATION_ITEM);
/**
 * @desc Clear Organization Item
 */
export const clearOrganizationItem = createAction(Actions.CLEAR_ORGANIZATION_ITEM);

/**
 * @desc Add User Item
 */
export const addUserItem = createAction(Actions.ADD_USER_ITEM);

/**
 * @desc Update User Item
 */
export const updateUserItem = createAction(Actions.UPDATE_USER_ITEM);

/**
 * @desc Delete User Item
 */
export const deleteUserItem = createAction(Actions.DELETE_USER_ITEM);

/**
 * @desc Clear Web Form Data
 */
export const clearOrganizationData = () => (dispatch) => {
  dispatch(setOrganizationLoader(false));
  dispatch(setOrganizationList(null));
  dispatch(setCurrentOrganization(null));
};
