import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/atom/Button/Button';
import DropDown from '../../../components/DropDown/DropDown';
import Input from '../../../components/Input/Input';
import { currencyNameWithCode } from '../../../data/raw';
import {
  deleteOrganization,
  getOrganization,
  sendDeleteCompanyVerificationCode,
  updateCompanyProfile,
  uploadCompanyLogo
} from '../../../services/organizationService';
import { updateCompanyItem } from '../../../actions/organizationActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Message from '../../../components/Message/Message';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { ROUTE } from '../../../constants/routeConst';
import Validation from '../../../components/Validation/Validation';
import { isEmpty } from '../../../helpers/common';
import { appInit } from '../../../services/appService';
import { APP_INIT_RESPONSE_TYPE } from '../../../constants/constant';
import Modal from '../../../components/Modal';
import Icon from '../../../components/Icon/Icon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import {
  MainList,
  MainTitle,
  Titel,
  FormGroupWrapper,
  FormGroup,
  Label,
  UploadProfilePicture,
  UploadProfileView,
  UploadProfile,
  UploadProfileWrapper,
  TextArea,
  DropDownTitle,
  FormGroupBottom,
  ManagementWrapper,
  ManagementBlock,
  CompanyTitle,
  CompanyPara,
  ModalFormWrapper,
  LabelFile,
  Select,
  ProfileWrapper,
  Title,
  HeaderWrapper,
  HeaderLeft,
  HeaderTitle,
  Resend,
  CodeWrapper,
  Paragraph,
  ButtonWrapper,
  DeleteButton,
  MainMobileButton
} from './styles';

const CompanyProfile = () => {
  const history = useHistory();
  const organizationSelector = useSelector((state) => state.organization);
  let { organization, companyItem, loading } = organizationSelector;
  const { id: organization_id } = organization;
  const [validationErrors, setValidationErrors] = useState({});
  const [isOpenDeleteCompanyModal, setIsOpenDeleteCompanyModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const loadData = useCallback(() => {
    dispatch(getOrganization(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateValue = (propName, value) => {
    dispatch(updateCompanyItem({ propName, value }));
  };

  const onOpenDeleteCompanyModal = useCallback(() => {
    setIsOpenDeleteCompanyModal(true);
  }, []);

  const onCloseDeleteCompanyModal = useCallback(() => {
    setIsOpenDeleteCompanyModal(false);
    setVerificationCode('');
  }, []);

  const onFileUpload = useCallback(
    (file) => {
      // Create an object of formData
      const formData = new FormData();
      formData.append('myFile', file, file?.name);
      dispatch(uploadCompanyLogo(organization_id, formData));
    },
    [dispatch, organization_id]
  );

  const trackCompanyProfileUpdateAnalyticActivity = useCallback(() => {
    try {
      trackAnalyticActivity('settings - company profile : updated');
    } catch (e) {
      console.log('track settings - company profile : updated Error', e);
    }
  }, []);

  const onUpdateCompanyProfile = useCallback(
    async (e) => {
      e.preventDefault();
      let validationErrors = {};
      if (!companyItem?.name) {
        validationErrors.name = 'Required';
        setValidationErrors(validationErrors);
        return false;
      }

      setValidationErrors({});
      const params = companyItem;
      const result = await dispatch(updateCompanyProfile(organization_id, params));
      if (result) {
        trackCompanyProfileUpdateAnalyticActivity();
      }
    },
    [dispatch, trackCompanyProfileUpdateAnalyticActivity, companyItem, organization_id]
  );

  const onClickSendDeleteCompanyCode = useCallback(
    async (resend) => {
      const result = await dispatch(sendDeleteCompanyVerificationCode(organization_id));
      if (result && !resend) {
        onOpenDeleteCompanyModal();
      }
    },
    [dispatch, onOpenDeleteCompanyModal, organization_id]
  );

  const onDeleteCompany = useCallback(
    async (e) => {
      e.preventDefault();
      let validationErrors = {};
      if (!verificationCode || verificationCode.trim() === '') {
        validationErrors['verificationCode'] = 'Verification Code Required';
      }
      if (!isEmpty(validationErrors)) {
        setValidationErrors(validationErrors);
        return;
      }
      const result = await dispatch(deleteOrganization(organization_id, verificationCode));
      if (result) {
        onCloseDeleteCompanyModal();
        const response = await dispatch(appInit());
        if (response && response?.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
          history.push(response.path);
        } else if (response?.type === APP_INIT_RESPONSE_TYPE.SUCCESS) history.push(ROUTE.SELECT_ORGANIZATION);
      }
    },
    [dispatch, history, onCloseDeleteCompanyModal, organization_id, verificationCode]
  );
  return (
    <MainList mobile={mobile}>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <MainMobileButton>
            <Button primary title='Save' type='button' onClick={onUpdateCompanyProfile} loading={loading} />
          </MainMobileButton>
        </MobileTitleBar>
      )}
      {mobile ? null : (
        <MainTitle>
          <Titel>Workspace Profile</Titel>
          <Button primary title='Save' type='button' onClick={onUpdateCompanyProfile} loading={loading} />
        </MainTitle>
      )}
      <FormGroupWrapper mobile={mobile}>
        <FormGroup mobile={mobile}>
          <ProfileWrapper mobile={mobile}>
            <UploadProfilePicture mobile={mobile}>
              <Label>Your Workspace Logo</Label>
              <UploadProfileWrapper>
                <UploadProfileView>
                  {companyItem?.logoUrl ? <UploadProfile src={companyItem?.logoUrl} /> : <span>NO IMAGE</span>}
                </UploadProfileView>
                <LabelFile htmlFor='upload'>
                  <Select>Select files...</Select>
                  <Input
                    type='file'
                    name='myfile'
                    onChange={(e) => onFileUpload(e.target.files[0])}
                    isPrimarySmall={true}
                    id='upload'
                    hidden
                  />
                </LabelFile>
              </UploadProfileWrapper>
            </UploadProfilePicture>
            <UploadProfilePicture mobile={mobile}>
              <Title>Address</Title>
              <TextArea
                name='address'
                value={companyItem?.address || ''}
                onChange={(e) => updateValue('address', e.target.value)}
              />
            </UploadProfilePicture>
          </ProfileWrapper>
          <ProfileWrapper mobile={mobile}>
            <UploadProfilePicture mobile={mobile}>
              <Input
                title='Workspace Name'
                isPrimarySmall
                type='text'
                autocomplete='off'
                id='name'
                name='name'
                placeholder='Enter workspace name'
                value={companyItem?.name || ''}
                onChange={(e) => {
                  updateValue('name', e.target.value);
                }}
              />
              {validationErrors?.name && <Message text={validationErrors.name} isSuccess={false} />}
            </UploadProfilePicture>
            <UploadProfilePicture mobile={mobile}>
              <Input
                title='State'
                isPrimarySmall
                type='text'
                autocomplete='off'
                name='state'
                placeholder='Enter state'
                value={companyItem?.state || ''}
                onChange={(e) => {
                  updateValue('state', e.target.value);
                }}
              />
            </UploadProfilePicture>
          </ProfileWrapper>
          <ProfileWrapper mobile={mobile}>
            <UploadProfilePicture mobile={mobile}>
              <DropDownTitle>Default Currency</DropDownTitle>
              <DropDown
                labelField={'text'}
                valueField={'code'}
                placeholder={'Indian Rupee - INR'}
                options={currencyNameWithCode}
                value={companyItem?.currency || ''}
                onChange={(e) => {
                  updateValue('currency', e.value);
                }}
              />
            </UploadProfilePicture>
            <UploadProfilePicture mobile={mobile}>
              <Input
                title='Country'
                isPrimarySmall
                type='text'
                autocomplete='off'
                name='name'
                placeholder='Enter country'
                value={companyItem?.country || ''}
                onChange={(e) => {
                  updateValue('country', e.target.value);
                }}
              />
            </UploadProfilePicture>
          </ProfileWrapper>
          <ProfileWrapper mobile={mobile}>
            <UploadProfilePicture mobile={mobile}>
              <Input
                title='Workspace Email'
                isPrimarySmall
                type='email'
                autocomplete='off'
                name='email'
                placeholder='Enter workspace email'
                value={companyItem?.email || ''}
                onChange={(e) => {
                  updateValue('email', e.target.value);
                }}
              />
            </UploadProfilePicture>
            <UploadProfilePicture mobile={mobile}>
              <Input
                title='Contact Number'
                isPrimarySmall
                type='number'
                autocomplete='off'
                name='number'
                placeholder='Enter contact number'
                value={companyItem?.phone || ''}
                onChange={(e) => {
                  updateValue('phone', e.target.value);
                }}
              />
            </UploadProfilePicture>
          </ProfileWrapper>
        </FormGroup>
        {organization?.isAdmin && (
          <FormGroupBottom>
            <ManagementWrapper>
              <Titel>Workspace Management</Titel>
            </ManagementWrapper>
            <ManagementBlock mobile={mobile}>
              <CompanyTitle>Deleting your workspace.</CompanyTitle>
              <CompanyPara>
                As a administrator you have the ability to delete the workspace. This action can't be rollback
              </CompanyPara>
              <Button
                secondary
                title='Delete Workspace'
                onClick={() => {
                  ConfirmDialog({
                    iconName: 'icon-delete',
                    closeIcon: 'icon-close',
                    title: 'Delete',
                    message: `Are you sure want to delete this Workspace?`,
                    onOk: () => {
                      onClickSendDeleteCompanyCode();
                    }
                  });
                }}
              />
            </ManagementBlock>
          </FormGroupBottom>
        )}
      </FormGroupWrapper>
      <Modal id='simple' open={isOpenDeleteCompanyModal} onClose={onCloseDeleteCompanyModal}>
        <HeaderWrapper>
          <HeaderLeft>
            <Icon name='icon-checked-round' fontSize='22' color='var(--gray-icon-color)' />
            <HeaderTitle>Verification</HeaderTitle>
          </HeaderLeft>
          <Icon name='icon-close' fontSize='20' onClick={onCloseDeleteCompanyModal} color='var(--gray-icon-color)' />
        </HeaderWrapper>
        <ModalFormWrapper onSubmit={onDeleteCompany}>
          <Input
            isPrimarySmall
            title='Varification Code'
            name='verification code'
            placeholder='Enter Verification code'
            value={verificationCode}
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
          />
          {validationErrors?.verificationCode && <Validation error={validationErrors?.verificationCode}></Validation>}
          <CodeWrapper>
            <Resend
              onClick={() => {
                onClickSendDeleteCompanyCode(true);
              }}>
              Resend
            </Resend>
            <Paragraph>
              A verification code is sent to your Email Address. You need to enter it and get verified.
            </Paragraph>
          </CodeWrapper>
          <ButtonWrapper>
            <DeleteButton>
              <Button primary title='Delete Workspace' type='submit' loading={loading} />
            </DeleteButton>
            <Button secondary title='Cancel' type='button' onClick={onCloseDeleteCompanyModal} />
          </ButtonWrapper>
        </ModalFormWrapper>
      </Modal>
      <SnackBar />
    </MainList>
  );
};

export default CompanyProfile;
