/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import DropDown from '../DropDown/DropDown';
import { itemPerPage } from '../../data/raw';
import Icon from '../Icon/Icon';
import OrganizationPreferencesSingleton from '../../helpers/OrganizationPreferencesSingleton';
import useMobileDevice from '../../hooks/useMobileDevice';
import {
  Styles,
  StylesWrapper,
  PaginationWrapper,
  PaginationLeft,
  Span,
  PaginationRight,
  ArrowButton,
  Tr,
  customestyle,
  SpanPage,
  ViewIcon,
  SpanArrow,
  HeaderTitle
} from './Styles';
// const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
//   const defaultRef = React.useRef();
//   const resolvedRef = ref || defaultRef;
//   React.useEffect(() => {
//     resolvedRef.current.indeterminate = indeterminate;
//   }, [resolvedRef, indeterminate]);
//   return (
//     <InputWrapper>
//       <input type='checkbox' ref={resolvedRef} {...rest} />
//       <span className='checkmark'></span>
//     </InputWrapper>
//   );
// });

const PAGE_SIZE = 'pageSize';

export default function Table({ columns, data, initialState, openMoreMenu, component }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const mobile = useMobileDevice();
  useEffect(() => {
    const pageSize = OrganizationPreferencesSingleton.getInstance().getSettingValue(PAGE_SIZE, PAGE_SIZE) || 10;
    setPerPageItem(pageSize);
    setPageSize(Number(pageSize));
  }, [selectedFlatRows, setPageSize]);

  const [perPageItem, setPerPageItem] = useState(10);

  const handleChangePageSize = (value) => {
    setPageSize(Number(value));
    setPerPageItem(Number(value));
    OrganizationPreferencesSingleton.getInstance().setSettingValue(PAGE_SIZE, PAGE_SIZE, value);
  };

  return (
    <>
      <StylesWrapper component={component} mobile={mobile}>
        <Styles component={component} mobile={mobile}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <HeaderTitle>
                        {column.render('Header')}
                        <SpanArrow>
                          {column?.isSorted} {column?.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                        </SpanArrow>
                      </HeaderTitle>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return index === 0 ? (
                        <th {...cell.getCellProps()}>{cell.render('Cell')}</th>
                      ) : (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                    <td>
                      {openMoreMenu && (
                        <ViewIcon>
                          <Icon
                            title='More'
                            name='icon-more'
                            fontSize='24'
                            color='var(--gray-icon-color)'
                            onClick={(e) => {
                              openMoreMenu(e, row?.original?.id);
                            }}
                          />
                        </ViewIcon>
                      )}
                    </td>
                  </Tr>
                );
              })}
            </tbody>
          </table>
        </Styles>
      </StylesWrapper>
      <PaginationWrapper className='resize' mobile={mobile}>
        <PaginationLeft>
          <DropDown
            isSearchable={false}
            options={itemPerPage}
            styles={customestyle}
            labelField={'text'}
            valueField={'value'}
            value={perPageItem}
            menuPlacement={'top'}
            onChange={(e) => {
              handleChangePageSize(Number(e?.value));
            }}
          />
          <SpanPage>Items per page</SpanPage>
        </PaginationLeft>
        <PaginationRight>
          <Span>
            {pageIndex * pageSize + 1} - {pageIndex * pageSize + page.length} of {data?.length}
          </Span>
          <ArrowButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            <Icon name='feather-chevron-left' fontSize='20' color='var(--gray-icon-color)' />
          </ArrowButton>
          <ArrowButton onClick={() => nextPage()} disabled={!canNextPage}>
            <Icon name='feather-chevron-right' fontSize='20' color='var(--gray-icon-color)' />
          </ArrowButton>
        </PaginationRight>
      </PaginationWrapper>
    </>
  );
}
