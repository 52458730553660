import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ReportsHeader from '../../../components/ReportsHeader/ReportsHeader';
import { getOverviewReport } from '../../../services/reportServices';
import { getDateString } from '../../../helpers/common';
import { currencySymbols } from '../../../data/raw';
import { getRelativeHHMMSS } from '../../../global/Helper';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import { MobileReportDateFilter } from '../../../components/ReportDateFilter/MobileReportDateFilter';
import {
  PanelTop,
  PanelWrapper,
  ReportBlock,
  ReportBox,
  ReportWrapper,
  PanelBottom,
  OverviewTitle,
  Ul,
  Li,
  LiWrapper,
  LiTitle,
  UlData,
  DataLi,
  OverviewWrapper,
  MainMobileButton
} from './styles';

const ReportsOverview = () => {
  const mobile = useMobileDevice();
  const location = useLocation();
  const search = location?.search;
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const reportSelector = useSelector((state) => state.report);
  const { overviewReport, loading } = reportSelector;
  const history = useHistory();
  const dispatch = useDispatch();

  const currencySymbol = currencySymbols?.find((x) => x.code === organization?.settings?.currency);

  const onChange = useCallback(
    (option, fromDate, toDate) => {
      const fromDateString = getDateString(fromDate);
      const toDateString = getDateString(toDate);

      history.push(`/reports/overview?fromdate=${fromDateString}&todate=${toDateString}&option=${option}`);

      dispatch(getOverviewReport(organization_id, fromDateString, toDateString));
    },
    [dispatch, organization_id, history]
  );

  const navigateToCallSummary = useCallback(() => {
    const URLParams = new URLSearchParams(search);
    const fromDate = URLParams.get('fromdate');
    const toDate = URLParams.get('todate');
    const option = URLParams.get('option');
    history.push(`/reports/call-summary?fromdate=${fromDate}&todate=${toDate}&option=${option}`);
  }, [history, search]);

  return (
    <OverviewWrapper mobile={mobile}>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <MainMobileButton>
            <MobileReportDateFilter onChange={onChange} />
          </MainMobileButton>
        </MobileTitleBar>
      )}
      {mobile ? null : <ReportsHeader onChange={onChange} title='Overview' iconName='icon-infographic' />}
      {loading ? (
        <ReportWrapper>Loading...</ReportWrapper>
      ) : (
        <ReportWrapper mobile={mobile}>
          <ReportBlock>
            {overviewReport &&
              overviewReport?.contatcs?.length > 0 &&
              overviewReport?.contatcs?.map((contact) => {
                return (
                  <ReportBox key={contact?.tableId} mobile={mobile}>
                    <PanelWrapper>
                      <PanelTop mobile={mobile}>
                        <SVGIcon fill='var(--gray-icon-color)' size={mobile ? '22' : '24'} name='icon-user' />
                        <OverviewTitle mobile={mobile}>{contact?.name}</OverviewTitle>
                      </PanelTop>
                      <PanelBottom mobile={mobile}>
                        <Ul>
                          <Li>
                            <LiWrapper>
                              <LiTitle>Created</LiTitle>
                              <UlData>
                                <DataLi mobile={mobile}>{contact?.count}</DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li>
                          {/* <Li>
                            <LiWrapper>
                              <LiTitle>Total</LiTitle>
                              <UlData>
                                <DataLi>10</DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li> */}
                        </Ul>
                      </PanelBottom>
                    </PanelWrapper>
                  </ReportBox>
                );
              })}
            {overviewReport &&
              overviewReport?.companies?.length > 0 &&
              overviewReport?.companies?.map((company) => {
                return (
                  <ReportBox key={company?.tableId} mobile={mobile}>
                    <PanelWrapper>
                      <PanelTop mobile={mobile}>
                        <SVGIcon fill='var(--gray-icon-color)' size={mobile ? '22' : '24'} name='icon-account' />
                        <OverviewTitle mobile={mobile}>{company?.name}</OverviewTitle>
                      </PanelTop>
                      <PanelBottom mobile={mobile}>
                        <Ul>
                          <Li>
                            <LiWrapper>
                              <LiTitle>Created</LiTitle>
                              <UlData>
                                <DataLi mobile={mobile}>{company?.count}</DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li>
                          {/* <Li>
                            <LiWrapper>
                              <LiTitle>Total</LiTitle>
                              <UlData>
                                <DataLi>10</DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li> */}
                        </Ul>
                      </PanelBottom>
                    </PanelWrapper>
                  </ReportBox>
                );
              })}
            {overviewReport &&
              overviewReport?.deals?.length > 0 &&
              overviewReport?.deals?.map((deal) => {
                return (
                  <ReportBox key={deal?.tableId} mobile={mobile}>
                    <PanelWrapper>
                      <PanelTop mobile={mobile}>
                        <SVGIcon fill='var(--gray-icon-color)' size={mobile ? '22' : '24'} name='icon-deals' />
                        <OverviewTitle mobile={mobile}>{deal?.name}</OverviewTitle>
                      </PanelTop>
                      <PanelBottom mobile={mobile}>
                        <Ul>
                          <Li>
                            <LiWrapper>
                              <LiTitle>Created</LiTitle>
                              <UlData>
                                <DataLi mobile={mobile}>{deal?.count}</DataLi>
                                <DataLi mobile={mobile}>
                                  {deal?.totalAmount}
                                  {currencySymbol?.symbol}
                                </DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li>
                          <Li mobile={mobile}>
                            <LiWrapper>
                              <LiTitle>Won</LiTitle>
                              <UlData>
                                <DataLi mobile={mobile}>{deal?.wonCount}</DataLi>
                                <DataLi mobile={mobile}>
                                  {deal?.totalWonAmount}
                                  {currencySymbol?.symbol}
                                </DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li>
                          <Li mobile={mobile}>
                            <LiWrapper>
                              <LiTitle>Lost</LiTitle>
                              <UlData>
                                <DataLi mobile={mobile}>{deal?.lostCount}</DataLi>
                                <DataLi mobile={mobile}>
                                  {deal?.totalLostAmount}
                                  {currencySymbol?.symbol}
                                </DataLi>
                              </UlData>
                            </LiWrapper>
                          </Li>
                        </Ul>
                      </PanelBottom>
                    </PanelWrapper>
                  </ReportBox>
                );
              })}
            {overviewReport && overviewReport?.call && (
              <ReportBox onClick={navigateToCallSummary} mobile={mobile}>
                <PanelWrapper>
                  <PanelTop mobile={mobile}>
                    <SVGIcon fill='var(--gray-icon-color)' size={mobile ? '22' : '24'} name='icon-call' />
                    <OverviewTitle mobile={mobile}>Calls</OverviewTitle>
                  </PanelTop>
                  <PanelBottom mobile={mobile}>
                    <Ul>
                      <Li mobile={mobile}>
                        <LiWrapper>
                          <LiTitle>Total</LiTitle>
                          <UlData>
                            <DataLi mobile={mobile}>{overviewReport?.call?.count}</DataLi>
                          </UlData>
                        </LiWrapper>
                      </Li>
                      <Li mobile={mobile}>
                        <LiWrapper>
                          <LiTitle>Total Duration</LiTitle>
                          <UlData>
                            <DataLi mobile={mobile}>{getRelativeHHMMSS(overviewReport?.call?.totalDuration)}</DataLi>
                          </UlData>
                        </LiWrapper>
                      </Li>
                      <Li mobile={mobile}>
                        <LiWrapper>
                          <LiTitle>Average Duration</LiTitle>
                          <UlData>
                            <DataLi mobile={mobile}>{getRelativeHHMMSS(overviewReport?.call?.avgDuration)}</DataLi>
                          </UlData>
                        </LiWrapper>
                      </Li>
                    </Ul>
                  </PanelBottom>
                </PanelWrapper>
              </ReportBox>
            )}
            {overviewReport && (overviewReport?.email?.sentCount > 0 || overviewReport?.email?.receivedCount > 0) && (
              <ReportBox mobile={mobile}>
                <PanelWrapper>
                  <PanelTop mobile={mobile}>
                    <SVGIcon fill='var(--gray-icon-color)' size={mobile ? '22' : '24'} name='icon-email' />
                    <OverviewTitle mobile={mobile}>Emails</OverviewTitle>
                  </PanelTop>
                  <PanelBottom mobile={mobile}>
                    <Ul>
                      <Li mobile={mobile}>
                        <LiWrapper>
                          <LiTitle>Sent</LiTitle>
                          <UlData>
                            <DataLi mobile={mobile}>{overviewReport?.email?.sentCount}</DataLi>
                          </UlData>
                        </LiWrapper>
                      </Li>
                      <Li mobile={mobile}>
                        <LiWrapper>
                          <LiTitle>Received</LiTitle>
                          <UlData>
                            <DataLi mobile={mobile}>{overviewReport?.email?.receivedCount}</DataLi>
                          </UlData>
                        </LiWrapper>
                      </Li>
                    </Ul>
                  </PanelBottom>
                </PanelWrapper>
              </ReportBox>
            )}
          </ReportBlock>
        </ReportWrapper>
      )}
    </OverviewWrapper>
  );
};

export default ReportsOverview;
