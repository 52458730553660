import styled, { css } from 'styled-components';
import { Component } from 'react';

const Icons = styled.i`
  ${(props) =>
    props.isIconButton
      ? css`
          margin-right: 12px;
          font-size: ${props.fontSize || 16}px;
          line-height: ${props.fontSize || 16}px;
          color: ${props.color};
          background-color: ${props.bgColor};
          vertical-align: sub;
        `
      : css`
          margin: ${props.marginAll || 0}px;
          font-size: ${props.fontSize || 16}px;
          line-height: ${props.fontSize || 16}px;
          color: ${props.color};
          background-color: ${props.bgColor};
          padding: ${props.padDing || 0}px;
          border-radius: ${props.borderRadius || 0}px;
        `}
`;

export class Icon extends Component {
  render() {
    const {
      bgColor,
      name,
      isIconButton,
      color,
      fontSize = 16,
      padDing,
      borderRadius,
      marginAll,
      onClick,
      title
    } = this.props;
    return (
      <Icons
        title={title}
        isIconButton={isIconButton}
        bgColor={bgColor}
        className={`icon ${name}`}
        color={color}
        fontSize={fontSize}
        padDing={padDing}
        borderRadius={borderRadius}
        marginAll={marginAll}
        onClick={onClick}></Icons>
    );
  }
}

export default Icon;
