import React from 'react';
import { Text, Link } from './styles';

const NavigationText = (props) => {
  const { children, text, hover = true } = props;

  return (
    <Text hover={hover} {...props}>
      <Link>
        {text}
        {children}
      </Link>
    </Text>
  );
};
// props : customStyle, hoverBackgroundColor, hoverTextColor, href, customLinkStyle, textColor
export default NavigationText;
