import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../../components/atom/Button/Button';
import { getSubscriptionList } from '../../../services/settingService';
import { getDateDifference } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  MainList,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  FirstList,
  SubText,
  CaptionText,
  SecondList,
  SecondTitle,
  ContainBoxTwo,
  ListBox
} from './styles';

const Subscription = (props) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const loadData = useCallback(() => {
    dispatch(getSubscriptionList(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const settingSelector = useSelector((state) => state.setting);
  const { subscriptionList } = settingSelector;

  const list = useMemo(() => {
    const activeList = subscriptionList?.filter((x) => x.status === 'ACTIVE');
    const pastList = subscriptionList?.filter((x) => x.status === 'ENDED');
    return { activeList, pastList };
  }, [subscriptionList]);

  const handleChangePlan = () => {
    props.history.push('/upgrade');
  };

  const getNextDayDate = (date) => {
    const nextDayDate = moment(date).add(1, 'days').format('MMM DD, YYYY');
    return nextDayDate;
  };

  return (
    <MainList>
      <MainWrap mobile={mobile}>
        {mobile ? null : (
          <HeadView>
            <Title>Subscription</Title>
            <Button
              primary
              title={list?.activeList?.length === 0 ? 'Upgrade' : 'Change Plan'}
              onClick={handleChangePlan}
            />
          </HeadView>
        )}
        {list?.activeList?.length === 0 && <ContainBox>Opps! No Subscription Found</ContainBox>}
        {list?.activeList &&
          list?.activeList?.map((item) => {
            return (
              <ContainBox key={item?.id}>
                <FirstList>
                  <SubText>{item?.planName}</SubText>
                  <CaptionText>{`${getDateDifference(
                    item?.start,
                    item?.end
                  )} days remaining - Next Invoice : ${getNextDayDate(item?.end)}`}</CaptionText>
                </FirstList>
              </ContainBox>
            );
          })}
        {list?.pastList?.length > 0 && (
          <SecondList>
            <SecondTitle>Past Subscription</SecondTitle>
            <ContainBoxTwo>
              {list?.pastList?.map((item) => {
                return (
                  <ListBox>
                    <FirstList key={item?.id}>
                      <SubText>{item?.planName}</SubText>
                      <CaptionText>{moment(item?.end).format('MMM DD, YYYY')}</CaptionText>
                    </FirstList>
                  </ListBox>
                );
              })}
            </ContainBoxTwo>
          </SecondList>
        )}
      </MainWrap>
    </MainList>
  );
};

export default Subscription;
