import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from './../actions/types';

const invoiceLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_INVOICE_LOADER
});

const invoiceListReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_INVOICE_LIST
});

const initialState = {};
const invoiceItemReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_INVOICE_ITEM, (state = initialState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_INVOICE_ITEM, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      if (action.payload.fieldGroup !== 'emails') {
        if (!action.payload.fieldGroup) {
          item[action.payload.propName] = action.payload.value;
        } else {
          item[action.payload.fieldGroup][action.payload.fieldIndex][action.payload.propName] = action.payload.value;
        }
      } else {
        item[action.payload.fieldGroup][action.payload.fieldIndex] = action.payload.value;
      }
      return item;
    })
    .addCase(Actions.ADD_INVOICE_TAX, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      item.tax.push(action.payload.taxItem);
      return item;
    })
    .addCase(Actions.DELETE_INVOICE_TAX, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      item.tax.splice(action.payload.taxItemIndex, 1);
      return item;
    })
    .addCase(Actions.ADD_ITEM_IN_INVOICE, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      item.items.push(action.payload.productItem);
      return item;
    })
    .addCase(Actions.DELETE_ITEM_FROM_INVOICE, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      item.items.splice(action.payload.productItemIndex, 1);
      return item;
    })
    .addCase(Actions.ADD_EMAIL_IN_INVOICE, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      if (!item.emails) item.emails = [];
      item.emails.push(action.payload.email);
      return item;
    })
    .addCase(Actions.DELETE_EMAIL_FROM_INVOICE, (state, action) => {
      const invoiceItem = current(state);
      const item = JSON.parse(JSON.stringify(invoiceItem));
      item.emails.splice(action.payload.emailIndex, 1);
      return item;
    });
});

const invoiceReducer = combineReducers({
  loading: invoiceLoaderReducer,
  invoiceItem: invoiceItemReducer,
  invoiceList: invoiceListReducer
});

export default invoiceReducer;
