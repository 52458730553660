import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { rescheduleActivity } from '../../services/activityService';
import { INBOX_TYPE } from '../../constants/constant';
import Icon from '../../components/Icon/Icon';
import { DatePicker, TimePicker } from '../../components/DateTime';
import Button from '../../components/atom/Button/Button';
import {
  MainContact,
  TitleView,
  TitleText,
  Form,
  FirstList,
  SecondList2,
  BottomView,
  LableText,
  LableView
} from './styles';

function RescheduleModal(props) {
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [rescheduleTime, setRescheduleTime] = useState(new Date());
  const { selectedItem, handleCloseModal, markAsCompleteLookup } = props;
  const { id: activityId } = selectedItem?.record;

  const onChangeRescheduleDate = (Date) => {
    const FormattedDate = Date.toISOString();
    setRescheduleDate(FormattedDate || rescheduleDate);
  };

  const onChangeRescheduleTime = (e) => {
    const time = e.target.value;
    setRescheduleTime(time || rescheduleTime);
  };

  const handleRescheduleActivity = (e) => {
    e.preventDefault();
    handleCloseModal();
    const time = new Date(`${moment(rescheduleDate).format('YYYY-MMM-DD')} ${rescheduleTime}`);
    const FormattedTime = time.toISOString();
    const obj = {
      date: rescheduleDate,
      time: FormattedTime
    };
    dispatch(rescheduleActivity(organization_id, activityId, obj));
  };

  return (
    <MainContact>
      <TitleView>
        <Icon
          name='icon-big-left-arrow'
          fontSize='12'
          color='var(--gray-icon-color)'
          onClick={handleCloseModal}
          title='Back'
        />
        <TitleText>Reschedule</TitleText>
      </TitleView>
      <Form>
        <FirstList>
          <LableView>
            <LableText>Select New Date</LableText>
          </LableView>
          <DatePicker
            className='DatePickerWrapper'
            autoOk={true}
            value={rescheduleDate}
            onChange={onChangeRescheduleDate}
          />
        </FirstList>
        <SecondList2>
          <LableView>
            <LableText>Select New Time</LableText>
          </LableView>
          <TimePicker onChange={onChangeRescheduleTime} className='TimePickerWrapper' />
        </SecondList2>
        <BottomView>
          <Button
            type='button'
            title='Reschedule'
            size='small'
            width={'100%'}
            onClick={
              selectedItem?.type === INBOX_TYPE.lookups
                ? () => markAsCompleteLookup(rescheduleDate)
                : handleRescheduleActivity
            }
          />
        </BottomView>
      </Form>
    </MainContact>
  );
}

export default RescheduleModal;
