import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Button from '../../components/atom/Button/Button';
import { setLookupGroupFields, setLookupValidationError } from '../../actions/lookupActions';
import {
  createLookup,
  updateLookup,
  getLookupItemFromServer,
  getTableTypeForAnalytics,
  trackCustomerActionAnalytics
} from '../../services/lookupService';
import {
  LOOKUP_FIELD_CUSTOM_ID,
  LOOKUP_FIELD_EDIT_VIEW_FROM,
  LOOKUP_TYPE,
  LOOKUP_TYPE_ID,
  LOOKUP_UPDATE_SOURCE
} from '../../constants/constant';
import { hasUserAccessAndPermission } from '../../services/appService';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import { LookupFieldTypes } from '../../constants/constant';
import { getCurrentCompanyPlanType } from '../../services/organizationService';
import { setIsAppUpgradeToPro } from '../../actions/appActions';
import LookupFieldInput from './LookupFieldInput/LookupFieldInput';
import {
  MainContact,
  TitleView,
  TitleText,
  SecondContain,
  BottomView,
  Form,
  InputView,
  TitleLeft,
  ModalCloseIcon,
  BtnWrapper
} from './Styles';

const AddNewContact = (props) => {
  const { itemId: lookup_id, cloneId, item } = props;
  const table = props?.table;

  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const lookupSelector = useSelector((state) => state.lookup);
  const organizationSelector = useSelector((state) => state.organization);
  const { tableFields } = lookupTableSelector;
  const { groupFields, validationError, loading } = lookupSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const userAccess = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', table?.name));
    return {
      permissionEdit
    };
  }, [dispatch, table]);

  const loadData = useCallback(async () => {
    let allField = [];
    const itemId = lookup_id || cloneId;
    if (itemId) {
      await dispatch(getLookupItemFromServer(organization_id, itemId));
    } else {
      allField = tableFields.find((x) => x.tableId === table?.id);
      let fields = allField?.columns?.slice();
      if (props?.contact) {
        const index = fields?.findIndex((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.CONTACT);
        if (index !== -1) {
          const newItem = {
            ...fields[index],
            isDisabled: true,
            value_Lookup: props?.contact
          };
          fields[index] = newItem;
        }
      }
      if (props?.company) {
        const index = fields?.findIndex((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.COMPANY);
        if (index !== -1) {
          const newItem = {
            ...fields[index],
            isDisabled: true,
            value_Lookup: props?.company
          };
          fields[index] = newItem;
        }
      }
      //Add contact from suggestion
      if (item) {
        const firstNameIndex = fields?.findIndex((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.FIRSTNAME);
        const lastNameIndex = fields?.findIndex((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.LASTNAME);
        const nameIndex = fields?.findIndex((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.NAME);
        const emailIndex = fields?.findIndex((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.EMAIL);
        if (firstNameIndex !== -1) {
          const newItem = {
            ...fields[firstNameIndex],
            value: item?.firstname
          };
          fields[firstNameIndex] = newItem;
        }
        if (lastNameIndex !== -1) {
          const newItem = {
            ...fields[lastNameIndex],
            value: item?.lastname
          };
          fields[lastNameIndex] = newItem;
        }
        if (nameIndex !== -1) {
          const newItem = {
            ...fields[nameIndex],
            value: item?.name
          };
          fields[nameIndex] = newItem;
        }
        if (emailIndex !== -1) {
          const newItem = {
            ...fields[emailIndex],
            value: item?.email
          };
          fields[emailIndex] = newItem;
        }
      }
      //selected first item of status
      const statusIndex = fields?.findIndex(
        (x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.STATUS || x.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE
      );
      if (statusIndex) {
        const statusItem = fields[statusIndex]?.options?.length > 0 ? fields[statusIndex].options[0] : undefined;
        if (statusItem) {
          const newItem = {
            ...fields[statusIndex],
            value: statusItem?.id
          };
          fields[statusIndex] = newItem;
        }
      }
      dispatch(setLookupGroupFields(fields));
    }
  }, [cloneId, dispatch, item, lookup_id, organization_id, props?.company, props?.contact, table?.id, tableFields]);

  const uniqueGroupFields = useMemo(
    () =>
      _.uniqBy(groupFields, function (e) {
        return e?.id;
      }),
    [groupFields]
  );

  useEffect(() => {
    loadData();
    //Anything in here is fired on component unmount.
    return () => {
      dispatch(setLookupValidationError({}));
      dispatch(setLookupGroupFields(null));
    };
  }, [dispatch, loadData]);

  const onClose = () => {
    if (props.handleCloseModal) props.handleCloseModal();
  };

  const onSave = async (e) => {
    e.preventDefault();
    //Check plan, if expired we can show upgrade popup
    const planType = getCurrentCompanyPlanType();
    if (planType === 'expired') {
      dispatch(setIsAppUpgradeToPro(true));
      return;
    }

    const newFields = uniqueGroupFields?.map((item) => {
      let newItem = item;
      if ((item?.type === LookupFieldTypes.Date || item?.type === LookupFieldTypes.Time) && item?.value_Date) {
        newItem = { ...newItem, value_Date: new Date(item.value_Date).toISOString() };
      }
      return newItem;
    });
    let obj = {
      groupFields: [
        {
          name: 'Lookup',
          fields: newFields
        }
      ],
      companyId: organization_id
    };
    let result;
    const updateObj = {
      ...obj,
      id: lookup_id ? lookup_id : undefined
    };
    if (lookup_id) {
      result = await dispatch(updateLookup(organization_id, table?.id, updateObj));
    } else {
      result = await dispatch(createLookup(organization_id, table?.id, updateObj));
    }
    if (result) {
      const type = getTableTypeForAnalytics(table);
      let source;
      let action;
      if (lookup_id) {
        if (props.source === LOOKUP_UPDATE_SOURCE.LIST) {
          source = 'list';
        } else if (props.source === LOOKUP_UPDATE_SOURCE.DETAILS) {
          source = 'details';
        }
        action = 'updated';
      } else {
        if (props.source === LOOKUP_UPDATE_SOURCE.LIST) {
          source = 'add new';
        } else if (props.source === LOOKUP_UPDATE_SOURCE.DETAILS) {
          source = `${props.currentTableType} detail`;
        }
        action = 'added';
      }
      trackCustomerActionAnalytics(type, action, source);
      if (props.onSave) props.onSave();
    }
  };

  const onChangeValues = (item) => {
    const column = groupFields.slice();
    const index = column.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      column[index] = item;
    }
    dispatch(setLookupGroupFields(column));
  };

  return (
    <MainContact>
      <TitleView>
        <TitleLeft>
          <SVGIcon name='icon-add-account' size='22' fill='var(--gray-icon-color)' />
          <TitleText>
            {lookup_id ? 'Edit' : 'Add'} {table?.type !== LOOKUP_TYPE.others ? LOOKUP_TYPE_ID[table?.type] : null}
          </TitleText>
        </TitleLeft>
        <ModalCloseIcon onClick={() => onClose()}>
          <SVGIcon name='icon-close' size='22' fill='var(--gray-icon-color)' />
        </ModalCloseIcon>
      </TitleView>
      <Form onSubmit={onSave}>
        <SecondContain mobile={mobile}>
          {uniqueGroupFields?.map((item, index) => {
            return (
              !item?.isNonEditable && (
                <InputView key={`uniqueGroupFields_${item?.id}_${index}`} mobile={mobile}>
                  <LookupFieldInput
                    key={item?.id}
                    item={item}
                    autoFocus={index === 0}
                    tableId={table?.id}
                    showLabel={true}
                    resError={validationError}
                    viewType={LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE}
                    onChangeValues={(item) => {
                      onChangeValues(item);
                    }}
                  />
                </InputView>
              )
            );
          })}
        </SecondContain>
        <BottomView mobile={mobile}>
          <BtnWrapper>
            <Button onClick={onSave} title='save' loading={loading} disabled={!userAccess?.permissionEdit}></Button>
          </BtnWrapper>
          <Button secondary={true} type={'button'} title='Cancel' onClick={() => onClose()} />
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default AddNewContact;
