import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from '../../../components/Menu';
import Button from '../../../components/atom/Button/Button';
import {
  deleteEmailTemplate,
  getEmailTemplates,
  trackEmailTemplateActionAnalyticActivity,
  trackEmailTemplateAnalyticActivity
} from '../../../services/emailService';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import Modal from '../../../components/Modal';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import {
  MainList,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  SecondList,
  MenuItemWrapper,
  MobileButton,
  MainMobileButton
} from './styles';
import NewMailTemplate from './NewMailTemplate';

const EmailTemplate = () => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const emailSelector = useSelector((state) => state.email);
  const { emailTemplates } = emailSelector;

  const [isOpenNewEmailModal, setIsOpenNewEmailModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getEmailTemplates(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onOpenMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsMenuOpen(true);
    setSelectedItem(item);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenNewEmailModal = useCallback(
    (e, item) => {
      e.stopPropagation();
      onCloseMenu();
      if (item) {
        setSelectedItem(item);
      }
      setIsOpenNewEmailModal(true);
      if (item || selectedItem) {
        trackEmailTemplateActionAnalyticActivity(
          'edit',
          item?.subject || selectedItem?.subject,
          item?.name || selectedItem?.name
        );
      } else {
        trackEmailTemplateActionAnalyticActivity('add');
      }
    },
    [onCloseMenu, selectedItem]
  );

  const onCloseNewEmailModal = useCallback(() => {
    setIsOpenNewEmailModal(false);
    setSelectedItem();
  }, []);

  const onDeleteTemplate = useCallback(() => {
    onCloseMenu();
    trackEmailTemplateActionAnalyticActivity('delete', selectedItem?.subject, selectedItem?.name);
    const result = dispatch(deleteEmailTemplate(organization_id, selectedItem?.id));
    if (result) {
      trackEmailTemplateAnalyticActivity('deleted', selectedItem?.name, selectedItem?.subject);
      setSelectedItem();
    }
  }, [onCloseMenu, dispatch, organization_id, selectedItem]);

  return (
    <>
      <MainList mobile={mobile}>
        {mobile && (
          <MobileTitleBar hasNavBar={true} hasTitle={true}>
            <MainMobileButton>
              <MobileButton
                iconName='icon-pluse'
                fontSize='14'
                iconColor='var(--white-color)'
                strokeWidth={2}
                size='small'
                onClick={(e) => {
                  setSelectedItem();
                  onOpenNewEmailModal(e);
                }}
              />
            </MainMobileButton>
          </MobileTitleBar>
        )}
        <MainWrap mobile={mobile}>
          {mobile ? null : (
            <HeadView>
              <Title>Email Template</Title>
              <Button
                onClick={(e) => {
                  setSelectedItem();
                  onOpenNewEmailModal(e);
                }}
                title='New Email Template'
              />
            </HeadView>
          )}
          <ContainBox>
            {emailTemplates?.map((item) => {
              return (
                <ListBox key={item?.id}>
                  <FirstList>
                    <SubText
                      onClick={(e) => {
                        onOpenNewEmailModal(e, item);
                      }}>
                      {item?.name}
                    </SubText>
                    <CaptionText>{item?.subject}</CaptionText>
                  </FirstList>
                  <SecondList
                    onClick={(e) => {
                      onOpenMenu(e, item);
                    }}>
                    <SVGIcon fill='var(--gray-color)' size={24} name='icon-more' title='More' />
                  </SecondList>
                </ListBox>
              );
            })}
          </ContainBox>
        </MainWrap>
      </MainList>
      <Modal
        open={isOpenNewEmailModal}
        onClose={onCloseNewEmailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <NewMailTemplate emailTemplateId={selectedItem?.id} handleCloseModal={onCloseNewEmailModal} />
      </Modal>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        width={112}
        position='left'
        positionVertical='top'
        marginTop={23}
        MenuListProps={{ disablePadding: true }}>
        <MenuItemWrapper>
          <MenuItem
            iconName='icon-edit-pen'
            color='var(--gray-icon-color)'
            fontSize='18px'
            onClick={(e) => {
              onOpenNewEmailModal(e);
            }}
            text='Edit'
          />
          <MenuItem
            iconName='icon-delete'
            color='var(--gray-icon-color)'
            fontSize='18px'
            onClick={() => {
              ConfirmDialog({
                iconName: 'icon-delete',
                closeIcon: 'icon-close',
                title: 'Delete',
                message: `Press ok to confirm delete this Email Template`,
                onOk: () => {
                  onDeleteTemplate();
                }
              });
            }}
            text='Delete'
          />
        </MenuItemWrapper>
      </Menu>
      <SnackBar />
    </>
  );
};

export default EmailTemplate;
