import styled from 'styled-components';
import { Header4, Text1 } from '../../../../styles';

const TwilioSettingsWrapper = styled.div``;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const URLTitle = styled.h4`
  ${Text1}
  color: var(--gray-color);
  margin: 0 0 8px 0;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  display: block;
  margin-left: 12px;
`;
const TwilioSettingWrapper = styled.div``;
const InputView = styled.div`
  margin-bottom: 16px;
`;
const BtnViewList = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--Platinum-color);
`;
const ButtonWrapper = styled.div``;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Wrapper = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const ReadMore = styled.a`
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export {
  TitleText,
  TwilioSettingWrapper,
  InputView,
  TwilioSettingsWrapper,
  TitleWrapper,
  BtnViewList,
  ButtonWrapper,
  URLTitle,
  HeaderWrapper,
  Wrapper,
  ReadMore
};
