import styled, { css } from 'styled-components';
import { Header4, SubHeader3, Text1, Text2 } from '../../styles';
import Input from '../../components/Input/Input';
import { INVOICE_STATUS_TEXT_FOR_COLOR } from '../../constants/constant';
import Button from '../../components/atom/Button/Button';

const HeaderWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px;
        `
      : css`
          padding: 20px 30px;
        `}
`;
const MobileButton = styled(Button)`
  padding: 10px;
  border-radius: 50%;
  i {
    margin: 0;
    font-size: 14px;
    line-height: 14px;
  }
`;

const HoverMoreIcon = styled.div`
  height: 24px;
  width: 24px;
  svg {
    display: block;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  i:first-child {
    ${(props) =>
      props.mobile &&
      css`
       {
        margin-right: 10px;
    `}
  }
`;
const InvoiceTitle = styled.h5`
  margin: 0 0 0 12px;
  ${SubHeader3}
  color:var(--dark-color);
`;
const InvoiceType = styled.h5`
  color: var(--gray-color);
  ${Text1}
  margin:0 2px;
  padding: 6px 10px;
  cursor: pointer;
  &:active,
  &.active {
    color: var(--primary-color);
  }
`;
const HeaderInvoice = styled.div`
  display: flex;
  align-items: center;
`;
const TableWrapper = styled.div`
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px 30px 16px;
          height: calc(100vh - 156px);
        `
      : css`
          padding: 0 30px 30px 30px;
          height: calc(100vh - 76px);
        `}
`;
const Table = styled.table`
  font-family: var(--primary-font-stack);
  width: 100%;
  border-spacing: 0;
`;
const Thead = styled.thead``;
const Tbody = styled.tbody``;
const Tr = styled.tr`
  cursor: pointer;
  :hover {
    td {
      background-color: var(--deal-column-bg);
      opacity: 1;
    }
  }
  position: relative;
  :last-child {
    td {
      border-bottom: 1px solid var(--gray-border-color);
    }
  }
`;
const Th = styled.th`
  height: 40px;
  padding: 0 8px;
  ${Text1}
  white-space: nowrap;
  color: var(--gray-color);
  text-align: left;
  border-bottom: 2px solid var(--gray-border-color);
  border-left: 1px solid var(--gray-border-color);
  border-top: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  position: sticky;
  top: 0;
  z-index: 99;
  :first-child {
    border-left: 0;
  }
`;
const Td = styled.td`
  padding: 8px;
  white-space: nowrap;
  text-align: left;
  border-bottom: 1px solid var(--gray-border-color);
  border-left: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  :first-child {
    border-left: 0;
  }
  :last-child {
    border: 0;
    position: sticky;
    right: 0;
    min-width: 0px;
    padding: 0;
    opacity: 0;
  }
`;
const TdBlog = styled.div``;
const TdSpan = styled.span`
  display: block;
  :nth-child(2) {
    margin-top: 2px;
    color: var(--gray-color);
  }
  ${Text1}
  color:var(--dark-color);
  ${(props) =>
    props.statusType === INVOICE_STATUS_TEXT_FOR_COLOR.PAID &&
    css`
      color: var(--green-lite);
    `}
  ${(props) =>
    props.statusType === INVOICE_STATUS_TEXT_FOR_COLOR.OVER_DUE &&
    css`
      color: var(--tomato);
    `}
    ${(props) =>
    props.statusType === INVOICE_STATUS_TEXT_FOR_COLOR.PENDING &&
    css`
      color: var(--star-bg);
    `}
    ${(props) =>
    props.statusType === INVOICE_STATUS_TEXT_FOR_COLOR.DRAFT &&
    css`
      color: var(--gray-color);
    `}
`;
const ViewIcon = styled.div`
  position: absolute;
  right: 0;
  top: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 98%;
  padding: 0 16px;
  background: var(--linear-gradient-more);
  width: 130px;
`;
const Form = styled.form`
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          height: calc(100vh - 120px);
        `
      : css`
          height: 100%;
        `}
`;
const FormInvoice = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 0px 16px;
        `
      : css`
          padding: 10px 30px 0;
        `}
`;
const InvoiceTop = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
`;
const Label = styled.label`
  margin-bottom: 4px;
  display: block;
  ${Text1}
  color:var(--dark-color);
  text-transform: capitalize;
`;
const DropDownWrapper = styled.div`
  width: 100%;
  padding: 0 10px 20px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 25%;
        `}

  .date-picker .MuiInputBase-root.MuiInput-underline .MuiInputBase-input {
    height: 32px;
    padding: 0 12px;
  }
  .date-picker .MuiInputBase-root.MuiInput-underline .MuiIconButton-root {
    right: 6px;
    padding: 6px;
  }
  .date-picker .MuiInputBase-root .MuiInputAdornment-root {
    max-height: 32px;
    top: 2px;
  }
  .date-picker .MuiInputBase-root.MuiInput-underline .MuiInputBase-input {
    :focus {
      border: '2px solid var(--primary-color)';
      margin: -1px;
    }
  }
`;
const InvoiceInput = styled(Input)`
  padding: 7px 37px 7px 12px;
`;
const Percentage = styled.h5`
  margin: 0;
  color: var(--gray-icon-color);
  ${Text1}
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-47%);
  padding: 6px 11px;
`;
const DropDownWrapperTex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
          max-width: 100%;
          margin-bottom: 30px;
        `
      : css`
          max-width: 50%;
        `}
`;
const DropDownWrapperBlog = styled(DropDownWrapper)`
  max-width: 50%;
  ${(props) =>
    props.dnone &&
    css`
      display: none;
    `}
  ${(props) =>
    props.mobile &&
    css`
      max-width: 100%;
    `}
`;
const Link = styled.a`
  text-decoration: none;
  ${Text1}
  color:var(--primary-color);
  cursor: pointer;
`;
const DropDownWrapperLink = styled(DropDownWrapperBlog)`
  flex-direction: row;
  padding-bottom: 0;
  align-items: center;
`;
const LinkSpan = styled.span`
  border-right: 1px solid var(--gray-border-color);
  height: 14px;
  width: 1px;
  margin-left: 9px;
  margin-right: 9px;
`;
const InvoiceCenter = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px;
        `
      : css`
          padding: 30px 30px 30px 30px;
        `}
`;
const InvoiceTable = styled.div`
  padding-bottom: 15px;
`;
const InvoiceTh = styled.th`
  height: 40px;
  padding: 0 8px;
  ${Text1}
  color:var(--gray-color);
  text-align: left;
  border-bottom: 2px solid var(--gray-border-color);
  border-left: 1px solid var(--gray-border-color);
  border-top: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  :last-child {
    border-right: 0;
    border-left: 0;
    width: 40px;
  }
  :first-child {
    border-left: 0;
    width: 550px;
  }
  :nth-child(2) {
    width: 110px;
  }
  :nth-child(3) {
    width: 110px;
  }
  :nth-child(4) {
    width: 130px;
  }
`;
const InvoiceTr = styled.tr``;
const InvoiceTd = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid var(--gray-border-color);
  border-left: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  :first-child {
    border-left: 0;
  }
  :last-child {
    border-right: 0;
    border-left: 0;
    width: 40px;
  }
  .svgIcon {
    justify-content: flex-end;
  }
`;
const InvoiceTdInput = styled(Input)`
  padding: 0;
  border: 0;
  border-radius: 0;
  :focus {
    border: 0;
    margin: 0;
    box-shadow: none;
  }
`;
const TextArea = styled.textarea`
  resize: none;
  min-height: 115px;
  outline: none;
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 8px 12px;
  font-family: var(--primary-font-stack);
  ${Text1}
  flex: 1 0 auto;
  &::placeholder {
    color: var(--gray-color);
  }
  :focus {
    border: 2px solid var(--primary-color);
    margin: -1px;
  }
`;
const InvoiceWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const AmountWrapper = styled.div`
  display: flex;
  ${(props) =>
    props.mobile
      ? css`
          padding-left: 0;
          width: 100%;
          justify-content: end;
          margin-top: 16px;
        `
      : css`
          padding-left: 20px;
        `}
`;
const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const Li = styled.li`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  :last-child {
    padding: 0;
  }
`;
const TitleWrapper = styled.div`
  flex: 1 1 auto;
`;
const AmountDueWrapper = styled.div`
  flex: 0 0 auto;
  text-align: end;
  margin-left: 60px;
`;
const AmountLabel = styled.h6`
  margin: 0;
  ${Text2}
  color:var(--dark-color);
`;
const SubLabel = styled.label`
  ${Text2}
  color: var(--gray-color);
`;
const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-border-color);
  margin-bottom: 10px;
`;
const InvoiceFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding-top: 20px;
          flex-wrap: wrap;
        `
      : css`
          padding-top: 30px;
        `}
`;
const FooterLeft = styled.div``;
const FooterRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          margin-top: 20px;
        `
      : css`
          max-width: 210px;
        `}
`;
const Span = styled.span`
  ${Header4}
  color:var(--dark-color);
  display: block;
  margin-bottom: 10px;
`;
const LabelInput = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${Text1}
  color:var(--dark-color);
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0 0 2px 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
    margin-top: 2px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const Labelelse = styled.label`
  ${Text1}
  color:var(--dark-color);
  a {
    cursor: pointer;
  }
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export {
  HeaderWraper,
  HeaderLeft,
  InvoiceTitle,
  InvoiceType,
  HeaderInvoice,
  TableWrapper,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  TdBlog,
  TdSpan,
  Tbody,
  ViewIcon,
  //New-Invoice
  Form,
  FormInvoice,
  InvoiceTop,
  Label,
  DropDownWrapper,
  InvoiceInput,
  Percentage,
  DropDownWrapperTex,
  DropDownWrapperBlog,
  Link,
  DropDownWrapperLink,
  LinkSpan,
  InvoiceCenter,
  InvoiceTable,
  InvoiceTr,
  InvoiceTh,
  InvoiceTd,
  InvoiceTdInput,
  TextArea,
  InvoiceWrapper,
  AmountWrapper,
  Ul,
  Li,
  TitleWrapper,
  AmountDueWrapper,
  SubLabel,
  AmountLabel,
  Border,
  InvoiceFooter,
  FooterLeft,
  FooterRight,
  Span,
  LabelInput,
  Labelelse,
  MobileButton,
  SvgWrapper,
  HoverMoreIcon
};
