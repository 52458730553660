import styled from 'styled-components';
import { Header3, Header4, Text1 } from '../../../../styles';

const ContactActivityEmptyWrap = styled.div`
  max-width: 364px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const EmptyContact = styled.img`
  display: block;
  max-width: 153px;
  width: 100%;
`;
const ContactHead = styled.h3`
  ${Header3}
  margin: 32px 0px 0px 0px;
`;
const ContactSpan = styled.h4`
  ${Header4}
  color: var(--gray-more-icon);
  margin: 8px 0px 16px 0px;
`;
const ContactLink = styled.span`
  ${Text1}
  color: var(--primary-color);
  cursor: pointer;
`;
export { ContactActivityEmptyWrap, EmptyContact, ContactHead, ContactSpan, ContactLink };
