import styled from 'styled-components';
import { Text1 } from '../../styles';

const DropMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const EmailText = styled.span`
  color: var(--gray-color);
  ${Text1}
`;
const LeftView = styled.div`
  display: flex;
  align-items: center;
`;
const LabelText = styled.span`
  ${Text1}
`;
const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: var(--gray-color);
  margin: 0px 6px;
  border-radius: 50%;
`;
const SelectedEmail = styled.span`
  ${Text1}
  color: var(--dark-color);
`;

export { DropMenu, EmailText, LeftView, LabelText, Dot, SelectedEmail };
