import styled from 'styled-components';
import { Header4, Text1 } from '../../../styles';

const MainContact = styled.div``;
const DateTimeWrapper = styled.div`
  margin-top: 18px;
  width: 100%;
  .DatePickerWrapper {
    width: 100%;
  }
  .TimePickerWrapper {
    width: 100%;
  }
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  background: var(--white-color);
`;
const TitleText = styled.span`
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  margin-left: 10px;
`;
const Form = styled.form`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
const BottomView = styled.div`
  border-top: 1px solid var(--Platinum-color);
  padding: 10px 18px;
  margin-top: auto;
`;
const SecondContain = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const InputView = styled.div``;
const DropView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0 14px 0;
`;
const UploadProfilePicture = styled.div`
  width: 100%;
  margin-right: 20px;
`;
const IconView = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
const AddView = styled.div``;
const AddText = styled.span`
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
`;
const AllBtnView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BtnView = styled.div`
  button {
    margin-right: 15px;
  }
`;
const RightBtn = styled.div`
  display: flex;
  align-items: center;
`;
const ModifyUserOptionItemListLi = styled.li`
  list-style: none;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
  span {
  }
  input {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 17px;
    height: 17px;
    z-index: 1;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: var(--white-color);
    border-radius: 100%;
    border: 2px solid var(--gray-border-color);
    margin: 0;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 2px;
    top: 2px;
    width: 9px;
    height: 9px;
    background-color: var(--primary-color);
    border-radius: 100%;
  }
`;
const LabelInput = styled.label`
  display: inline;
  position: relative;
  padding-left: 26px;
  padding-bottom: 2px;
  cursor: pointer;
  ${Text1}
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const TitleLeft = styled.div`
  display: flex;
  align-items: center;
`;
export {
  MainContact,
  TitleView,
  TitleText,
  Form,
  SecondContain,
  BottomView,
  UploadProfilePicture,
  DropView,
  IconView,
  AddView,
  AddText,
  BtnView,
  ModifyUserOptionItemListLi,
  LabelInput,
  AllBtnView,
  RightBtn,
  DateTimeWrapper,
  InputView,
  TitleLeft
};
