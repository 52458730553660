// import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import _, { groupBy } from 'lodash';
import {
  setContactDuplication,
  setContactSuggestions,
  setDashboardDetail,
  setDashboardLoader,
  setDashboardOnBoarding,
  setMergeContactDetails
} from '../actions/dashboardActions';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason, getUTCDate, isEmpty } from '../helpers/common';
// import { REACT_APP_APIURL } from '../global/Environment';
import { LookupFieldTypes, LOOKUP_FIELD_CUSTOM_ID, LOOKUP_TYPE } from '../constants/constant';
import { setTotalEmailCount } from '../actions/emailActions';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import { REACT_APP_APIURL } from '../global/Environment';
import LookupCacheService from './lookupCacheServices';
import ActivityCacheService from './activityCacheService';
import EmailCacheServices from './emailCacheServices';
import { trackAnalyticActivity } from './analyticsService';
import { getTableFieldsFromRedux } from './lookupTableServices';

const getDashboardInboxData = (lookups, activities, emails) => (dispatch, getState) => {
  try {
    const date = getUTCDate();

    const state = getState();
    const user = state?.auth?.currentUser;
    const tables = state?.lookupTables?.list;

    //Get all table fields
    const tableWiseFieldsfields = state?.lookupTables?.tableFields.map((x) => {
      return {
        tableId: x.tableId,
        fields: x.columns?.map((y) => {
          return {
            tableId: x.tableId,
            ...y
          };
        })
      };
    });
    const fields = [].concat.apply(
      [],
      tableWiseFieldsfields?.map((x) => x?.fields)
    );
    const pastActivity = activities?.filter(
      (x) =>
        x.taskStatus !== true &&
        (!x?.assignedUser || (x?.assignedUser && x?.assignedUser?.id === user?.id)) &&
        getUTCDate(x.date) <= date
    );

    const unreadEmails = emails
      .filter((x) => x.unread === true)
      .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());

    //get filtered contact based who have folloupdate / close date on today or prev
    let filter = {
      logic: 'or',
      filters: [
        { field: 'close_date', value: date, operator: 'lte', ignoreCase: true },
        { field: 'next_followup_date', value: date, operator: 'lte', ignoreCase: true }
      ]
    };

    const filterResult = process(lookups, {
      filter: filter,
      sort: [
        { field: 'close_date', dir: 'asc' },
        { field: 'next_followup_date', dir: 'asc' }
      ]
    });

    //set lookup objects
    const filteredLookups = filterResult?.data
      ?.map((lookup) => {
        const field = fields?.find(
          (x) =>
            x.tableId === lookup.tableId &&
            (x.customId === LOOKUP_FIELD_CUSTOM_ID.CLOSE_DATE ||
              x.customId === LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE)
        );
        const assignToField = fields?.find(
          (x) => x?.tableId === lookup?.tableId && x?.customId === LOOKUP_FIELD_CUSTOM_ID.ASSIGN_USER
        );
        const table = tables?.find((x) => x?.id === lookup?.tableId);
        return {
          email: lookup?.email,
          date: lookup?.close_date || lookup?.next_followup_date,
          field: field,
          lookup: lookup,
          type: table?.type || LOOKUP_TYPE.contacts,
          assignedTo: lookup?.fields[assignToField?.id]
            ? lookup?.fields[assignToField?.id].value_MultiLookup
            : undefined
        };
      })
      .filter((x) => !x?.assignedTo || (x?.assignedTo && x?.assignedTo?.findIndex((y) => y?.id === user?.id) !== -1));

    return {
      activities: pastActivity,
      lookups: filteredLookups,
      emails: unreadEmails
    };
  } catch (e) {
    console.log('ERROR', e);
    throw e;
  }
};

export const getDashboardInbox_Local = () => async (dispatch) => {
  try {
    const lookups = await LookupCacheService.getInstance()?.getLookups();
    const activities = await ActivityCacheService.getInstance()?.getActivities();
    const emails = await EmailCacheServices.getInstance()?.getEmails();
    if (emails && emails.length > 0) {
      const total_count = emails.filter((x) => x.unread).length;
      dispatch(setTotalEmailCount(total_count));
    }
    const result = dispatch(getDashboardInboxData(lookups, activities, emails));

    return result;
  } catch (e) {
    console.log('ERROR', e);
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to get inbox please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard -getDashboardDetail
 * @param {*} organization_id
 */
export const getDashboardDetail = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setDashboardLoader(true));
    let onBoarding = OrganizationPreferencesSingleton.getInstance().getOnBoarding();
    if (onBoarding) dispatch(setDashboardOnBoarding(onBoarding));

    if (!onBoarding?.completed) {
      const response = await axios.get(`${REACT_APP_APIURL}/dashboard/${organization_id}/onboarding`);
      onBoarding = response.data;
      OrganizationPreferencesSingleton.getInstance().setOnBoarding(onBoarding);
      dispatch(setDashboardOnBoarding(onBoarding));
    }
    if (onBoarding?.completed) {
      const result = await dispatch(getDashboardInbox_Local());
      dispatch(setDashboardDetail(result));
    }

    // const response = await axios.get(`${REACT_APP_APIURL}/Dashboard/${organization_id}/inbox`);
    // console.log('data', data);
    // dispatch(setDashboardDetail(data));

    return true;
  } catch (e) {
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to get inbox please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - update OnBoarding Step
 * @param {*} obj Data Obj
 */
export const updateOnBoardingStep = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setDashboardLoader(true));
    const onBoarding = OrganizationPreferencesSingleton.getInstance().setOnBoardingValue(payload?.id, true);
    dispatch(setDashboardOnBoarding(onBoarding));

    const response = await axios.put(`${REACT_APP_APIURL}/dashboard/${organization_id}/onboarding`, payload);
    const { data } = response;
    if (data) return true;
  } catch (e) {
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to update OnBoarding Step please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Get contact Suggestions
 * @param {*} organization_id
 */
export const getContactSuggestions = (organization_id) => async (dispatch) => {
  try {
    if (!organization_id) return;
    dispatch(clearResponseMessage(''));
    dispatch(setDashboardLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Dashboard/${organization_id}/contact-suggestions`);
    const { data } = response;
    if (data) {
      dispatch(setContactSuggestions(data));
      return true;
    }
  } catch (e) {
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to get contact suggestions please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Skip contact Suggestions
 * @param {*} organization_id, suggestion_id
 */
export const skipContactSuggestions = (organization_id, suggestion_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setDashboardLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/Dashboard/${organization_id}/contact-suggestions/${suggestion_id}/skip`
    );
    const { data } = response;
    if (data) {
      dispatchDashboardSuccess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to skip contact suggestions please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Skip all contact Suggestions
 * @param {*} organization_id, suggestion_id
 */
export const skipAllContactSuggestions = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setDashboardLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/Dashboard/${organization_id}/contact-suggestions/bulk-skip`,
      payload
    );
    const { data } = response;
    if (data) {
      dispatchDashboardSuccess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to skip contact suggestions please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - remove contact Suggestions
 * @param {*} organization_id, suggestion_id
 */
export const removeContactSuggestions = (organization_id, suggestion_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setDashboardLoader(true));
    await axios.delete(`${REACT_APP_APIURL}/Dashboard/${organization_id}/contact-suggestions/${suggestion_id}/remove`);
    return true;
  } catch (e) {
    dispatchDashboardError(getAPIErrorReason(e) || 'Unable to remove contact suggestions please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Set Duplicate Contact Info
 * @param {*} table_id
 */
export const setOriginalAndDuplicateContact = (original_lookup_id, duplicate_lookup_id) => async (dispatch) => {
  let original_lookup_Local = await LookupCacheService.getInstance()?.getItem(original_lookup_id);
  let duplicate_lookup_Local = await LookupCacheService.getInstance()?.getItem(duplicate_lookup_id);
  if (original_lookup_Local && duplicate_lookup_Local) {
    const fields = dispatch(getTableFieldsFromRedux());
    const tableFields = fields?.filter((x) => x?.tableId === original_lookup_Local?.tableId);

    if (tableFields) {
      original_lookup_Local.values = {};
      duplicate_lookup_Local.values = {};
      tableFields?.forEach((field) => {
        original_lookup_Local.values[field?.id] = original_lookup_Local?.displayFields[field?.id] ? true : false;
        duplicate_lookup_Local.values[field?.id] =
          !original_lookup_Local?.displayFields[field?.id] && duplicate_lookup_Local?.displayFields[field?.id]
            ? true
            : false;
      });
    }

    const item = {
      original_lookup: original_lookup_Local,
      duplicate_lookup: duplicate_lookup_Local,
      fields: tableFields,
      tableId: original_lookup_Local?.tableId
    };
    dispatch(setMergeContactDetails(item));
  }
};

/**
 * @desc Dashboard - Get Dublicate Contact
 * @param {*}
 */
export const getDublicateContacts = () => async (dispatch, getState) => {
  const { getLookupByTable } = require('./lookupService');
  const allTablefields = OrganizationPreferencesSingleton.getInstance()?.getFields();
  const fields = [].concat.apply(
    [],
    allTablefields?.map((x) =>
      x.columns?.map((y) => {
        return {
          ...y,
          tableId: x?.tableId
        };
      })
    )
  );
  const state = getState();
  const tableList = state?.lookupTables?.list;
  const contactTableList = tableList?.filter((x) => x?.type === LOOKUP_TYPE.contacts);
  if (!isEmpty(contactTableList)) {
    let contactDuplication = [];
    await Promise.all(
      contactTableList?.map(async (item) => {
        const duplicateData = [];
        const emailFields = fields?.filter((x) => x?.tableId === item?.id && x?.type === LookupFieldTypes.Email);
        const phoneFields = fields?.filter((x) => x?.tableId === item?.id && x?.type === LookupFieldTypes.Phone);
        const lookups = await getLookupByTable(item?.id);
        lookups?.forEach((lookup) => {
          let item = {
            id: lookup?.id,
            name: lookup?.name,
            phone: lookup?.phone,
            email: lookup?.email,
            tableId: lookup?.tableId
          };
          if (emailFields?.length > 1) {
            emailFields?.forEach((email, index) => {
              if (lookup?.fields[email?.id]) {
                item = {
                  ...item,
                  email: lookup?.fields[email?.id]?.value,
                  phone: index === 0 ? item?.phone : undefined
                };
                duplicateData?.push(item);
              }
            });
          } else if (phoneFields?.length > 1) {
            phoneFields?.forEach((phone, index) => {
              if (lookup?.fields[phone?.id]) {
                item = {
                  ...item,
                  phone: lookup?.fields[phone?.id]?.value,
                  email: index === 0 ? item?.email : undefined
                };
                duplicateData?.push(item);
              }
            });
          } else {
            duplicateData?.push(item);
          }
        });
        const phoneDuplication = groupBy(duplicateData, 'phone');
        const emailDuplication = groupBy(duplicateData, 'email');
        const phoneKeys = Object.keys(phoneDuplication);
        const emailKeys = Object.keys(emailDuplication);
        let phone = {};
        let email = {};
        let list = [];
        phoneKeys.forEach((key) => {
          const uniqPhones = _.uniqBy(phoneDuplication[key], 'id');
          if (key !== 'undefined' && uniqPhones?.length > 1) {
            let data = uniqPhones?.slice(0, 2);
            phone = {
              type: 'phone',
              original: data[0],
              duplicate: data[1],
              count: uniqPhones?.length - 1
            };
            list?.push(phone);
          }
        });
        emailKeys.forEach((key) => {
          const uniqEmails = _.uniqBy(emailDuplication[key], 'id');
          if (key !== 'undefined' && uniqEmails?.length > 1) {
            let data = uniqEmails?.slice(0, 2);
            email = {
              type: 'email',
              original: data[0],
              duplicate: data[1],
              count: uniqEmails?.length - 1
            };
            list?.push(email);
          }
        });
        if (!isEmpty(list)) {
          contactDuplication = contactDuplication?.concat(list);
        }
      })
    );
    dispatch(setContactDuplication(contactDuplication));
  }
};

export const trackInboxAnalyticActivity = (type, action) => {
  try {
    trackAnalyticActivity('inbox: click on action', { type: type, action: action });
  } catch (e) {
    console.log('track Inbox Error', e);
  }
};

export const trackSupportAnalyticActivity = (action) => {
  try {
    trackAnalyticActivity(`support: ${action}`);
  } catch (e) {
    console.log(`track Support ${action} Error`, e);
  }
};

function dispatchDashboardError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
function dispatchDashboardSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
