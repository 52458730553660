import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { isEmpty } from '../helpers/common';

const automationLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_AUTOMATION_LOADER
});

const initialEmailState = [];
const automationListReducer = createReducerOrig(initialEmailState, (builder) => {
  builder
    .addCase(Actions.SET_AUTOMATION_LIST, (state = initialEmailState, action) => {
      return action.payload;
    })
    .addCase(Actions.UPDATE_AUTOMATION_LIST_ITEM, (state, action) => {
      const automationList = current(state);
      let list = JSON.parse(JSON.stringify(automationList));
      const item = action.payload.item;
      const automationIndex = list?.findIndex((x) => x?.id === item?.id);
      list[automationIndex] = item;
      return list;
    })
    .addCase(Actions.DELETE_AUTOMATION_ITEM, (state, action) => {
      const automationList = current(state);
      let list = JSON.parse(JSON.stringify(automationList));
      if (list && list?.length > 0) {
        const automationIndex = list?.findIndex((x) => x?.id === action.payload.id);
        if (automationIndex !== -1) {
          list.splice(automationIndex, 1);
        }
        return list;
      }
    });
});

const initialAutomationItemState = {
  name: 'New Automation',
  triggerAction: 3,
  steps: [],
  lookupTableId: ''
};
const createAutomationReducer = createReducerOrig(initialAutomationItemState, (builder) => {
  builder
    .addCase(Actions.SET_AUTOMATION_ITEM, (state = initialAutomationItemState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_AUTOMATION_ITEM, (state, action) => {
      const automation = { ...state };
      automation[action.payload.propsName] = action.payload.value;
      return { ...automation };
    })
    .addCase(Actions.ADD_AUTOMATION_STEP_ITEM, (state, action) => {
      const automation = { ...state };
      const steps = automation?.steps?.slice();
      const index = action.payload.index;
      if (!isEmpty(index)) {
        steps.splice(index, 0, action.payload.item);
      } else {
        steps.push(action.payload.item);
      }
      automation.steps = steps;
      return { ...automation };
    })
    .addCase(Actions.UPDATE_AUTOMATION_STEP_ITEM, (state, action) => {
      const automation = { ...state };
      const steps = automation?.steps?.slice();
      if (steps && steps?.length > 0) {
        const stepIndex = steps?.findIndex((x) => x?.localId === action.payload.id || x?.id === action.payload.id);
        if (stepIndex !== -1) {
          steps[stepIndex] = action.payload.item;
          automation.steps = steps;
          return { ...automation };
        }
      }
    })
    .addCase(Actions.DELETE_AUTOMATION_STEP_ITEM, (state, action) => {
      const automation = { ...state };
      const steps = automation?.steps?.slice();
      if (action.payload.index !== -1) {
        steps.splice(action.payload.index, 1);
      }
      automation.steps = steps;
      return { ...automation };
    })
    .addCase(Actions.CLEAR_AUTOMATION_ITEM, () => {
      const initialAutomationState = JSON.parse(JSON.stringify(initialAutomationItemState));
      return initialAutomationState;
    });
});

const automationReducer = combineReducers({
  loading: automationLoaderReducer,
  list: automationListReducer,
  automationItem: createAutomationReducer
});

export default automationReducer;
