import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import SVGIcon from '../../assets/svg/SVGIcon';
import useMobileDevice from '../../hooks/useMobileDevice';
import ReportDateFilter from '../ReportDateFilter/ReportDateFilter';
import { HeaderWrapper, HeaderTitle, Header } from './styles';

const ReportsHeader = (props) => {
  const mobile = useMobileDevice();
  const location = useLocation();
  const search = location?.search;
  const { onChange, iconName } = props;

  const [option, setOption] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const getDateInstance = useCallback((dateValue) => {
    if (!dateValue) return;
    const dateString = dateValue?.toString();
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    const date = new Date(year, month - 1, day);
    return date;
  }, []);

  const loadData = useCallback(() => {
    const URLParams = new URLSearchParams(search);
    const fromDate = URLParams.get('fromdate');
    const toDate = URLParams.get('todate');
    const option = URLParams.get('option');
    setOption(option);
    setFromDate(getDateInstance(fromDate));
    setToDate(getDateInstance(toDate));
  }, [search, getDateInstance]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <HeaderWrapper mobile={mobile}>
      <Header>
        <SVGIcon name={iconName} fill='var(--primary-color)' size={26} />
        <HeaderTitle>{props?.title}</HeaderTitle>
      </Header>
      <ReportDateFilter onChange={onChange} option={option} toDate={toDate} fromDate={fromDate} />
    </HeaderWrapper>
  );
};

export default ReportsHeader;
