import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/atom/Button/Button';
import { getEmailItem } from '../../../../services/emailService';
import IframeView from '../../../Email/IframeView';
import { clearEmailItem, setEmailDetail } from '../../../../actions/emailActions';
import { SEND_EMAIL_TYPE } from '../../../../constants/constant';
import {
  EmailPreviewMain,
  EmailPreviewTop,
  EmailFrom,
  From,
  Name,
  EmailSubject,
  Emailpara,
  EmailPreviewBottom,
  EmailButton,
  EmailNavigation,
  ButtonPagination,
  EmailWrapper
} from './styles';

const EmailDetailPreview = ({ onOpen, emails, currentEmail }) => {
  const [isSetdefaultEmail, setIsSetdefaultEmail] = useState(true);
  const [selectedThread, setSelectedThread] = useState(0);

  const [email, setEmail] = useState();
  const emailSelector = useSelector((state) => state.email);
  const organizationSelector = useSelector((state) => state.organization);
  const { emailDetail, loading } = emailSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const dispatch = useDispatch();

  const loadData = useCallback(
    async (email) => {
      if (email) {
        dispatch(getEmailItem(organization_id, email?.id));
      }
    },
    [dispatch, organization_id]
  );
  useEffect(() => {
    setEmail(currentEmail);
    loadData(currentEmail);
    return () => {
      dispatch(clearEmailItem());
      dispatch(setEmailDetail({}));
    };
  }, [currentEmail, dispatch, loadData]);

  useEffect(() => {
    //Check is email thread is loaded and we want to set default email message only once
    if (isSetdefaultEmail && emailDetail?.messages?.length > 0 && emailDetail?.messages[0]?.body) {
      //Check thread Id of email detail and current email,
      // if same then find message index from thred's messages and set default email
      if (currentEmail?.threadId === emailDetail?.threadId) {
        let messageIndex = emailDetail?.messages?.findIndex((x) => x?.id === currentEmail?.messageId);
        if (messageIndex !== -1) {
          setSelectedThread(messageIndex);
        }
      }
      setIsSetdefaultEmail(false);
    }
  }, [currentEmail, emailDetail, isSetdefaultEmail, setIsSetdefaultEmail]);

  const onClickForwordArrow = () => {
    let length = emailDetail?.messages?.length;
    if (selectedThread < length - 1) {
      setSelectedThread(selectedThread + 1);
    } else {
      setSelectedThread(0);
      const index = emails?.findIndex((x) => x.id === email?.id);
      if (index < emails.length - 1) {
        setEmail(emails[index + 1]);
        loadData(emails[index + 1]);
      }
    }
  };

  const onClickBackArrow = () => {
    if (selectedThread > 0) {
      setSelectedThread(selectedThread - 1);
    } else {
      const index = emails?.findIndex((x) => x?.id === email?.id);
      let length = emails[index - 1]?.messages?.length;
      setSelectedThread(length - 1);
      if (index > 0) {
        setEmail(emails[index - 1]);
        loadData(emails[index - 1]);
      }
    }
  };

  const fromName = useMemo(() => {
    if (emailDetail && emailDetail?.messages) {
      if (emailDetail?.messages[selectedThread]?.from?.lookup) {
        return emailDetail?.messages[selectedThread]?.from?.lookup?.name;
      } else if (emailDetail?.messages[selectedThread]?.from?.name) {
        return emailDetail?.messages[selectedThread]?.from?.name;
      } else {
        return emailDetail?.messages[selectedThread]?.from?.email;
      }
    }
  }, [emailDetail, selectedThread]);

  const onClickReply = useCallback(() => {
    const item = {
      ...emailDetail,
      id: email?.id
    };
    if (onOpen) onOpen(item, SEND_EMAIL_TYPE.REPLY);
  }, [email?.id, emailDetail, onOpen]);
  return (
    <>
      <EmailPreviewMain>
        <EmailPreviewTop>
          <EmailFrom>
            <From>From</From>
            <Name>{fromName}</Name>
          </EmailFrom>
          <EmailFrom>
            <From>To</From>
            {emailDetail &&
              emailDetail?.messages &&
              emailDetail?.messages[selectedThread]?.to?.map((item, index) => {
                const lastIndex = index < emailDetail?.messages[selectedThread]?.to.length - 1;
                return (
                  <Name key={`emailDetail_${item?.id}_${index}`}>
                    {item?.lookup ? item?.lookup?.name : item?.name ? item?.name : item?.email}
                    {lastIndex ? ',' : ''}
                  </Name>
                );
              })}
          </EmailFrom>
        </EmailPreviewTop>
        <EmailWrapper>
          <EmailSubject>{emailDetail?.subject}</EmailSubject>
          <Emailpara>
            {emailDetail && emailDetail?.messages && emailDetail?.messages[selectedThread]?.body ? (
              <IframeView
                key={`iframe-view-ref-${emailDetail?.messages[selectedThread]?.id}`}
                isHtmlMessage={emailDetail?.messages[selectedThread]?.isHtml}
                bodyContent={emailDetail?.messages[selectedThread]?.body}
              />
            ) : (
              <>Loading...</>
            )}
          </Emailpara>
        </EmailWrapper>
        <EmailPreviewBottom>
          <EmailButton>
            <Button title={'Reply'} size={'medium'} secondary disabled={loading} onClick={onClickReply} />
            <Button title={'Forward'} size={'medium'} secondary />
          </EmailButton>
          <EmailNavigation>
            <ButtonPagination iconName='icon-left-arrow' size={'small'} secondary onClick={onClickBackArrow} />
            <ButtonPagination iconName='icon-right-arrow' size={'small'} secondary onClick={onClickForwordArrow} />
          </EmailNavigation>
        </EmailPreviewBottom>
      </EmailPreviewMain>
    </>
  );
};

export default EmailDetailPreview;
