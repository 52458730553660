import styled from 'styled-components';
import { Header4, Text1 } from '../../../styles';

const MainContact = styled.div``;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  background: var(--white-color);
`;
const TitleText = styled.span`
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  margin-left: 10px;
`;
const IconView = styled.div`
  cursor: pointer;
`;
const From = styled.form`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
const SecondContain = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const DropDiv = styled.div`
  background: var(--white-color);
  border: 1px solid var(--Platinum-color);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 16px;
  z-index: 9999;
`;
const DragIcon = styled.div`
  margin-right: 16px;
  cursor: grab;
`;
const BottomView = styled.div`
  border-top: 1px solid var(--Platinum-color);
  padding: 10px 18px;
  margin-top: auto;
`;
const BtnView = styled.div`
  button {
    margin-right: 15px;
  }
`;
const TextList = styled.div``;
const FilterText = styled.span`
  ${Text1};
  color: var(--nav-dark-color);
`;
const RightIcon = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    cursor: pointer;
  }
`;
const TitleLeft = styled.div`
  display: flex;
  align-items: center;
`;

export {
  MainContact,
  TitleView,
  TitleText,
  IconView,
  From,
  SecondContain,
  DropDiv,
  DragIcon,
  BottomView,
  BtnView,
  TextList,
  FilterText,
  RightIcon,
  TitleLeft
};
