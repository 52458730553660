import styled, { css } from 'styled-components';
import { Header4, Text1, Text2 } from '../../../../../styles';

const MainList = styled.div`
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  margin-top: 25px;
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 18px;
`;
const FirstList = styled.div`
  padding-left: 16px;
`;
const ContainView = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;
  z-index: 9999;
  background-color: var(--white-color);
`;
const SubText = styled.h4`
  margin: 0 0 6px 0;
  font-style: normal;
  ${Text1}
  color: var(--dark-color);
  display: inline-block;
`;
const TextSub = styled.h4`
  margin: 0 0 12px 0;
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  display: inline-block;
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text2}
  color: var(--gray-color);
  margin: 0;
`;
const IconView = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 100%;
  min-width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BottomView = styled.div`
  border-top: 1px solid var(--Platinum-color);
  padding: 16px 16px 0 16px;
  margin: 0 -16px;
  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const Form = styled.form`
  padding: 16px;
  overflow: auto;
  max-height: calc(100vh - 131px);
`;
const AddView = styled.div`
  margin: 16px 0;
`;
const AddText = styled.span`
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
`;
const MainContact = styled.div`
  position: relative;
  outline: none;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  margin-left: 12px;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 155px;
  justify-content: space-between;
`;
const FormWrapper = styled.div``;
const ListWrappper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
const TextPopup = styled.span`
  ${Text1};
  font-style: normal;
  color: var(--dark-color);
  margin-left: 6px;
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: block;
`;
const marginBottom = css`
  margin-bottom: 12px;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    top: 2px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const SecondContain = styled.div``;
const DropDiv = styled.div`
  background: var(--white-color);
  border: 1px solid var(--Platinum-color);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 16px;
  z-index: 9999;
`;
const DragIcon = styled.div`
  margin-right: 10px;
  padding-right: 10px;
  cursor: grab;
  width: 28px;
  height: 18px;
  border-right: 1px solid var(--gray-border-color);
`;
const TextList = styled.div``;
const RightIcon = styled.div`
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const LabelInput = styled.label`
  display: inline;
  position: relative;
  padding: 0;
  margin-right: 40px;
  margin-bottom: 16px;
  cursor: pointer;
  ${Text2}
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const DragWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  input {
    border: 0;
    padding: 0;
    :focus {
      box-shadow: none;
    }
  }
`;

export {
  MainList,
  ListBox,
  FirstList,
  SubText,
  TextSub,
  CaptionText,
  IconView,
  BottomView,
  Form,
  MainContact,
  TitleText,
  TitleView,
  LeftWrapper,
  FormWrapper,
  ListWrappper,
  DropDownTitle,
  marginBottom,
  InputWrapperModal,
  TextPopup,
  AddText,
  AddView,
  ContainView,
  SecondContain,
  DragIcon,
  DropDiv,
  TextList,
  RightIcon,
  LabelInput,
  ModalHeader,
  CheckBoxWrapper,
  DragWrapper
};
