import Dexie from 'dexie';
const DB_VERSION_NUMBER = 1;

export const LOOKUP_TABLE_NAME = 'Lookups';
export const EMAILS_TABLE_NAME = 'Emails';
export const EMAIL_ACCOUNTS_TABLE_NAME = 'EmailAccounts';
export const ACTIVITIES_TABLE_NAME = 'Activities';
export const UPDATED_ON_TABLE_NAME = 'UpdatedOn';

export const UPDATE_ON_ID = {
  LOOKUPS: 'LOOKUPS',
  ACTIVTIES: 'ACTIVITIES',
  EMAILS: 'EMAILS',
  EMAIL_ACCOUNTS: 'EMAIL_ACCOUNTS'
};

export default class LocalDbService {
  constructor(props) {
    this.props = props;
    const db_name = props.dbName;
    this.db = new Dexie(db_name);
    const storeOptions = {};
    storeOptions[LOOKUP_TABLE_NAME] = '&id,tableId,value';
    storeOptions[EMAILS_TABLE_NAME] = '&id,accountId,value';
    storeOptions[ACTIVITIES_TABLE_NAME] = '&id,value';
    storeOptions[EMAIL_ACCOUNTS_TABLE_NAME] = '&id,value';
    storeOptions[UPDATED_ON_TABLE_NAME] = '&id,value';
    this.db.version(DB_VERSION_NUMBER).stores(storeOptions);
  }

  getDb() {
    const tableName = this.props.tableName;
    if (this.db) return this.db[tableName];
  }

  closeDb() {
    if (this.db) {
      this.db.close();
      this.db = undefined;
    }
  }

  getMappedValues(list) {
    const dbItems = list?.map((x) => {
      return {
        id: x?.id,
        value: x
      };
    });
    return dbItems;
  }

  async get() {
    if (this.db) {
      const rows = await this.getDb().toArray();
      const result = rows.map((x) => x.value).filter((x) => x);
      return result;
    }
    return [];
  }

  async getByIds(ids) {
    if (this.db) {
      const rows = await this.getDb().where('id').anyOf(ids).toArray();
      const result = rows.map((x) => x.value).filter((x) => x);
      return result;
    }
    return [];
  }

  async getItem(id) {
    if (this.db) {
      const rows = await this.getDb().where({ id: id }).limit(1).toArray();
      if (rows && rows.length > 0) {
        const result = rows.map((x) => x.value).filter((x) => x);
        return result[0];
      }
    }
    return null;
  }

  async clearDbData() {
    if (this.db) {
      await this.getDb().clear();
      await this.db[LOOKUP_TABLE_NAME].clear();
      await this.db[EMAIL_ACCOUNTS_TABLE_NAME].clear();
      await this.db[EMAILS_TABLE_NAME].clear();
      await this.db[ACTIVITIES_TABLE_NAME].clear();
      await this.db[UPDATED_ON_TABLE_NAME].clear();
      return true;
    }
    return false;
  }

  async addBulk(items) {
    if (this.db) {
      const result = await this.getDb().bulkPut(this.getMappedValues(items));
      return result;
    }
    return false;
  }

  async removeBulk(items) {
    if (this.db) {
      const result = await this.getDb().bulkDelete(items);
      return result;
    }
    return false;
  }

  async updateExisting(id, value) {
    if (value && this.db) {
      const dbItem = {
        id: id,
        tableId: value.tableId,
        value: value
      };
      const result = await this.getDb().update(id, dbItem);
      return result;
    }
    return null;
  }

  async getLastUpdatedTime(id) {
    try {
      if (this.db) {
        const first = await this.db[UPDATED_ON_TABLE_NAME].where('id').equals(id).first();
        return first?.value || 0;
      }
      return 0;
    } catch (e) {
      return 0;
    }
  }

  async setLastUpdatedTime(id, value) {
    if (value && this.db) {
      const result = await this.db[UPDATED_ON_TABLE_NAME].put({ id: id, value: value });
      return result;
    }
    return null;
  }

  async syncList(newList, archivedList) {
    if (this.db) {
      //Add/update list only if have list of new/archived
      if (newList && newList.length > 0) {
        await this.addBulk(newList);
      }
      if (archivedList && archivedList.length > 0) {
        await this.removeBulk(archivedList);
      }
    }
  }

  async clearTableData() {
    const tableName = this.props.tableName;
    if (!tableName) throw Error('DB Table name is missing');
    await this.db[tableName].clear();
  }
}
