import { combineReducers } from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import lookupReducer from './lookupReducer';
import lookupTablesReducer from './lookupTableReducer';
import dashboardReducer from './dashboardReducer';
import messageReducer from './messageReducer';
import organizationReducer from './organizationReducer';
import emailReducer from './emailReducer';
import settingReducer from './settingReducer';
import activityReducer from './activityReducer';
import reportReducer from './reportReducer';
import phoneCallReducer from './phoneCallReducer';
import invoiceReducer from './InvoiceReducer';
import automationReducer from './automationReducer';
import adminReducer from './adminReducer';
import goalReducer from './goalReducer';
import notificationReducer from './notificationReducer';
import callLogReducer from './callLogReducer';
import smsLogReducer from './smsLogReducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  admin: adminReducer,
  message: messageReducer,
  organization: organizationReducer,
  lookupTables: lookupTablesReducer,
  lookup: lookupReducer,
  dashboard: dashboardReducer,
  email: emailReducer,
  setting: settingReducer,
  activity: activityReducer,
  report: reportReducer,
  phoneCall: phoneCallReducer,
  callLog: callLogReducer,
  smsLog: smsLogReducer,
  invoice: invoiceReducer,
  automation: automationReducer,
  goal: goalReducer,
  notification: notificationReducer
});

export default rootReducer;
