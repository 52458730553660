import styled, { css } from 'styled-components';
import { Text1 } from '../../styles';

const Text = styled.div`
  ${(props) => (props.textGroup ? props.textGroup : Text1)};
  color: ${(props) => (props.textColor ? props.textColor : 'var(--gray-color)')};
  border-radius: 4px;
  margin: 0;
  padding: 1px 4px;
  width: fit-content;
  ${(props) =>
    Boolean(props.hover) &&
    css`
      cursor: pointer;
      :hover {
        color: ${(props) => props.hoverTextColor && props.hoverTextColor};
        background: ${(props) =>
          props.hoverBackgroundColor ? props.hoverBackgroundColor : 'var(--popup-color-hover)'};
      }
    `}
  ${(props) => (props.customStyle ? props.customStyle : Text1)};
`;

const Link = styled.a`
  text-decoration: none;
  display: block;
  ${(props) => props.customLinkStyle && props.customLinkStyle};
`;

export { Text, Link };
