import styled, { css } from 'styled-components';
import { Header3, Header4, Header5, Text1, Text2, Text3 } from '../../styles';

const customeStyle = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--dark-color)',
    margin: '4px 6px',
    padding: '6px 10px',
    letterSpacing: '0.2px',
    borderRadius: '5px',
    width: 'auto',
    '&:active': {
      background: ' var(--popup-color-hover)'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    color: '#000'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 8px',
    flexWrap: 'nowrap'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    boxShadow: 'none',
    minHeight: '34px',
    borderRadius: '4px',
    borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5',
    borderWidth: isFocused ? '2px' : '1px',
    margin: isFocused ? '0px -1px' : '0px',
    ':hover': {
      borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    boxShadow: '0px 3px 10px var(--menu-modal)',
    border: '1px solid var(--gray-border-color)',
    margin: '4px 0px'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px',
    padding: '3px 0px'
  }),
  multiValue: (base) => ({
    ...base,
    background: 'var(--popup-color-hover)',
    border: '1px solid var(--gray-border-color)',
    borderRadius: '4px',
    height: 28,
    margin: '3px',
    minWidth: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative'
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '0 8px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--gray-color)'
  }),
  multiValueRemove: (base) => ({
    ...base,
    opacity: '0',
    position: 'absolute',
    cursor: 'pointer',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ':hover': {
      background: 'var(--linear-gradient-hover);',
      right: '0',
      top: '1px',
      bottom: '0',
      width: '100%',
      textAlign: 'right',
      color: 'rgba(46, 62, 70, 0.5)',
      borderRadius: '4px',
      paddingRight: '4px',
      opacity: '1',
      zIndex: '1'
    }
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px'
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500'
  })
};
const GoalWrap = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
`;
const GoalHeader = styled.div`
  max-width: 820px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          margin: 16px;
        `
      : css`
          margin: 24px auto 16px auto;
        `}
`;
const GolaLogo = styled.div`
  display: flex;
  align-items: center;
`;
const GoalRectangle = styled.div`
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-left: auto;
        `
      : css`
          width: 30px;
          height: 30px;
          border-radius: 30px;
        `}
`;
const IconText = styled.h3`
  ${Header3}
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 0 0 12px;
        `
      : css`
          margin: 0 0 0 12px;
        `}
`;
const GoalMain = styled.div`
  max-width: 820px;
  ${(props) =>
    props.mobile
      ? css`
          margin: 16px;
        `
      : css`
          margin: 0 auto 24px auto;
        `}
`;
const GoalWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px;
        `
      : css`
          padding: 30px;
        `}
`;
const GoalTop = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  i {
    cursor: pointer;
  }
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 16px;
        `
      : css`
          margin-bottom: 24px;
        `}
`;
const GoalTopTitle = styled.span`
  ${Header4}
`;
const GoalTitleRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 200px;
        `
      : css`
          max-width: 250px;
        `}
`;
const GoalCompareText = styled.span`
  ${Text3}
`;
const GoalContact = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 16px;
        `
      : css`
          margin-bottom: 30px;
        `}
`;
const ContactIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.user &&
    css`
      background-color: var(--yellow-color);
    `}
  ${(props) =>
    props.email &&
    css`
      background-color: var(--Cerulean-Crayola);
    `}
`;
const ContactRight = styled.div`
  margin-left: 16px;
`;
const ContactHead = styled.h3`
  margin: 0;
  ${(props) =>
    props.mobile
      ? css`
          ${Header4}
        `
      : css`
          ${Header3}
        `}
`;
const ContactText = styled.span`
  ${Text1}
  color: var(--gray-color);
`;
const GoalProcess = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const GoalProcessRight = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 510px;
        `}
  .recharts-legend-wrapper {
    width: 100% !important;
    bottom: 0 !important;
    left: 0 !important;
    .recharts-default-legend {
      .recharts-surface {
        path {
          stroke: var(--primary-color) !important;
        }
      }
      .recharts-legend-item-text {
        ${Header5}
        color: var(--primary-color) !important;
      }
    }
  }
  .recharts-layer {
    ${Text2}
  }
`;
const GoalProcessLeft = styled.div`
  width: 100%;
  flex: 1 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 195px;
        `}
`;
const ProcessLeftTitle = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 16px;
        `
      : css`
          margin-bottom: 30px;
        `}
`;
const ProcessMaintext = styled.h5`
  ${Text1}
  margin: 0 0 13px 0;
`;
const ProcessGraph = styled.div`
  width: 100%;
  height: 6px;
  background-color: var(--gray-border-color);
  border-radius: 6px;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 195px;
        `}
`;
const Graph = styled.div`
  height: 6px;
  border-radius: 6px;
  ${(props) =>
    props.progress <= 50
      ? css`
          background-color: var(--yellow-color);
        `
      : css`
          background-color: var(--yellow-green);
        `}
`;
const ProcessSubtext = styled.h6`
  ${Text2}
  margin: 10px 0 0 0;
  color: var(--gray-color);
`;
const UserImgblock = styled.div`
  display: flex;
  align-items: center;
`;
const UserImg = styled.div`
  height: 30px;
  width: 30px;
  object-fit: cover;
  border: 2px solid var(--main-bg);
  border-radius: 100px;
  margin-left: -8px;
  :first-child {
    margin-left: 0;
  }
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--Platinum-color);
  padding: 16px;

  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const Form = styled.form`
  overflow: auto;
`;
const MainContainer = styled.div`
  outline: none;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          position: relative;
          height: auto;
          border-radius: 6px;
        `}
`;
const TitleText = styled.span`
  ${Header4};
  margin-left: 10px;
  color: var(--dark-color);
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  svg {
    width: 19px;
    height: 19px;
    cursor: pointer;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  justify-content: space-between;
  width: 160px;
`;
const RightWrapper = styled.div``;
const ModalWrapper = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const ListWrappper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 8px;
  }
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: block;
`;
const marginBottom = css`
  margin-bottom: 16px;
`;
const ModalStyle = {
  width: '640px'
};
const DateTimeWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  background-color: var(--white-color);
  .DatePickerWrapper {
    width: 100%;
    .MuiInputBase-input {
      &:focus {
        border: 2px solid var(--primary-color);
      }
    }
  }
  .TimePickerWrapper {
    width: 100%;
  }
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export {
  customeStyle,
  DateTimeWrapper,
  BottomView,
  marginBottom,
  MainContainer,
  Form,
  TitleText,
  TitleView,
  LeftWrapper,
  RightWrapper,
  ModalWrapper,
  ListWrappper,
  DropDownTitle,
  GoalHeader,
  GolaLogo,
  GoalRectangle,
  IconText,
  GoalMain,
  GoalWrapper,
  GoalTop,
  GoalTopTitle,
  GoalCompareText,
  GoalContact,
  ContactIcon,
  ContactRight,
  ContactHead,
  ContactText,
  GoalProcess,
  GoalTitleRight,
  GoalProcessRight,
  GoalProcessLeft,
  ProcessLeftTitle,
  ProcessMaintext,
  ProcessGraph,
  Graph,
  ProcessSubtext,
  UserImgblock,
  UserImg,
  GoalWrap,
  ModalStyle,
  HeaderLeft,
  SvgWrapper
};
