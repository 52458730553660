import styled from 'styled-components';
import { Text1, Text2 } from '../../../../styles';

const FileHeader = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 3px var(--inbox-hover-color);
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;
const HeaderLeft = styled.div``;
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
const DownloadLink = styled.a``;
const FileMain = styled.div`
  display: flex;
  align-items: center;
`;
const FileIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
const FileText = styled.div`
  padding-left: 20px;
  border-left: 1px solid var(--gray-border-color);
`;
const Captiontitle = styled.h5`
  ${Text1}
  color:var(--dark-color);
  margin: 0 0 2px 0;
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const UserName = styled.h5`
  margin: 0;
  ${Text2}
  color:var(--gray-color);
`;
const UserUploadedDate = styled.h5`
  position: relative;
  margin: 0 0 0 8px;
  ${Text2}
  color:var(--gray-color);
  padding-left: 12px;
  ::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    background-color: var(--gray-border-color);
    top: 8px;
    left: 0;
    border-radius: 100%;
  }
`;
const CloseWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;
  :hover {
    background-color: var(--popup-color-hover);
    i {
      color: var(--gray-icon-color);
    }
  }
`;
const FileWrapper = styled.div`
  margin: 0 auto 0 auto;
  border: 1px solid var(--gray-border-color);
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 1px 3px var(--inbox-hover-color);
`;
const FileModal = styled.div`
  background-color: var(--white-color);
  height: 100%;
`;
const File = styled.div`
  height: calc(100vh - 72px);
  margin: 70px auto 2px auto;
  padding-top: 30px;
  overflow: auto;
`;

export {
  FileHeader,
  HeaderLeft,
  HeaderRight,
  DownloadLink,
  FileMain,
  FileIconWrapper,
  FileText,
  Captiontitle,
  UserWrapper,
  UserName,
  UserUploadedDate,
  CloseWrapper,
  FileWrapper,
  FileModal,
  File
};
