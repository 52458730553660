import React, { useCallback, useMemo, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Navbar from '../../components/Navbar/Navbar';
import { ROUTE } from '../../constants/routeConst';
import homeRoutes from '../../routes/home-routes';
import PageNotFound from '../PageNotFound';
import useMobileDevice from '../../hooks/useMobileDevice';
import TitleBar from '../../components/TitleBar/TitleBar';
import { isMACApp, isWindowsApp } from '../../global/Helper';
import ElectronTitlebar from '../../components/ElectronTitlebar/ElectronTitlebar';
import { setIsAppSidebarOpen } from '../../actions/appActions';
import OrganizationNavbar from '../../components/Navbar/OrganizationNavbar/OrganizationNavbar';
import { OrganizationLoading } from '../../components/OrganizationLoading/OrganizationLoading';
import PrivateRoute from './../../components/PrivateRoutes';
import {
  ManeWrapper,
  RightWrapper,
  ManeBlock,
  Link,
  Overlay,
  ScMainWrapper,
  MobileHomeLinear,
  MacDragDro,
  MainPageWrapper
} from './styles';

export default function Home(props) {
  const location = useLocation();
  const mobile = useMobileDevice();
  const [navbarNavigation, setNavbarNavigation] = useState(false);
  const [sideBarValue, setSideBarValue] = useState(1);
  const [isSwitchOrgLoading, setIsSwitchOrgLoading] = useState(false);

  const appSelector = useSelector((state) => state.app);
  const isShowNavbar = appSelector?.isSidebarOpen;
  const dispatch = useDispatch();

  const routeComponents = homeRoutes.map((r, i) => {
    if (r.private) {
      return <PrivateRoute exact key={i} {...r} />;
    }
    return <Route exact key={i} {...r} />;
  });

  const match = useMemo(() => {
    return matchPath(location.pathname, {
      path: '/email/segments/:id/view/:threadId',
      exact: true
    });
  }, [location.pathname]);

  const onChangeSideBar = useCallback((value) => {
    setSideBarValue(value);
  }, []);

  const setNavBar = (value) => {
    setSideBarValue(value);
  };

  const onCloseNavbar = useCallback(() => {
    if (!mobile) return;
    dispatch(setIsAppSidebarOpen(false));
  }, [dispatch, mobile]);

  const onOpenNavbar = useCallback(() => {
    dispatch(setIsAppSidebarOpen(true));
  }, [dispatch]);

  const onSwitchOrganizationLoading = useCallback((value) => {
    setIsSwitchOrgLoading(value);
  }, []);

  const isHideMobileTitleBar = useMemo(() => {
    const url = location.pathname;
    let splitUrl = url.split('/');
    if (url?.startsWith('/cn/') || url?.startsWith('/cm/') || url?.startsWith('/dl/') || url?.startsWith('/others/')) {
      //Hide menu for contact detail page
      return true;
    }
    //Hide menu for setting/add Email Acount page
    if (url?.startsWith('/settings/sync')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for setting/email template page
    if (url?.startsWith('/settings/emailtemplate')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for setting/integration page
    if (url?.startsWith('/settings/integrations')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for setting/webform page
    if (url?.startsWith('/settings/forms')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for setting/webformDetail page
    if (url?.startsWith('/settings/forms/details/')) {
      if (splitUrl?.length === 5) return true;
    }
    //Hide menu for setting/PhoneSetting page
    if (url?.startsWith('/settings/phone-settings')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for /settings/Api-Key page
    if (url?.startsWith('/settings/api-keys')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for /settings/workspace profile page
    if (url?.startsWith('/settings/companyprofile')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for /settings/customize page
    if (url?.startsWith('/settings/customize')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for task page
    if (url?.startsWith('/task')) {
      if (splitUrl?.length === 2) return true;
    }
    //Hide menu for report->overview page
    if (url?.startsWith('/reports/overview')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for report->activity page
    if (url?.startsWith('/reports/activity')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for report->call-summary page
    if (url?.startsWith('/reports/call-summary')) {
      if (splitUrl?.length === 3) return true;
    }
    //Hide menu for duplication page
    if (url?.startsWith('/home/inbox/duplication')) {
      if (splitUrl?.length === 4) return true;
    }
    //Hide menu for callLog->call page
    if (url?.startsWith('/settings/calllog/call')) {
      if (splitUrl?.length === 4) return true;
    }
    //Hide menu for callLog->call page
    if (url?.startsWith('/settings/calllog/message')) {
      if (splitUrl?.length === 4) return true;
    }
    //Hide menu for ContactSuggestion page
    if (url?.startsWith('/home/inbox/contact-suggestion')) {
      if (splitUrl?.length === 4) return true;
    }
    //Hide menu for goal page
    if (url?.startsWith('/goal')) {
      if (splitUrl?.length === 2) return true;
    }
    //Hide menu for email page
    if (url?.startsWith('/email/segments/')) {
      if (splitUrl?.length === 4) return true;
    }
    //Hide menu for email detail page
    if (url?.startsWith('/email/segments/')) {
      if (splitUrl?.length === 6) return true;
    }
    return false;
  }, [location.pathname]);

  const orgNavBar = useMemo(() => {
    if (!mobile) return <OrganizationNavbar onSwitchOrganizationLoading={onSwitchOrganizationLoading} />;
    return null;
  }, [mobile, onSwitchOrganizationLoading]);

  if (isSwitchOrgLoading) return <OrganizationLoading />;

  return (
    <>
      <ScMainWrapper mobile={mobile} id='page-container'>
        {mobile ? (
          !match && (
            <TitleBar
              sideBarValue={sideBarValue}
              setNavBar={setNavBar}
              onOpenNavbar={onOpenNavbar}
              isHideMobileTitleBar={isHideMobileTitleBar}
            />
          )
        ) : isMACApp() ? (
          <MacDragDro></MacDragDro>
        ) : null}
        <ManeWrapper mobile={mobile} path={location?.pathname === ROUTE.INBOX}>
          {orgNavBar}
          <ManeBlock
            className={sideBarValue === 1 && navbarNavigation && 'sidebar'}
            mobile={mobile}
            isShowNavbar={isShowNavbar}>
            <Navbar
              navbarNavigation={navbarNavigation}
              sideBarValue={sideBarValue}
              onChangeSideBar={onChangeSideBar}
              onCloseNavbar={onCloseNavbar}
              {...props}
              setNavBar={setNavBar}
            />
            <Link
              className={sideBarValue === 1 && 'resizeDriver'}
              onClick={() => setNavbarNavigation(!navbarNavigation)}
            />
          </ManeBlock>
          <RightWrapper mobile={mobile} className='resizeWrapper'>
            {isWindowsApp() && <ElectronTitlebar titleBarWithNavbar />}
            <MainPageWrapper>
              <Switch>
                <Redirect exact path='/' to={ROUTE.INBOX} />
                {routeComponents}
                <Route component={PageNotFound} />
              </Switch>
            </MainPageWrapper>
          </RightWrapper>
          {mobile && isShowNavbar && <Overlay onClick={onCloseNavbar}></Overlay>}
        </ManeWrapper>
        <MobileHomeLinear mobile={mobile} />
      </ScMainWrapper>
    </>
  );
}
