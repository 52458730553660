import React from 'react';
import { useSelector } from 'react-redux';
import SVGIcon from '../../assets/svg/SVGIcon';
import { isElectronApp } from '../../global/Helper';
import { hasProduction } from '../../helpers/common';
import useMobileDevice from '../../hooks/useMobileDevice';

import {
  MainNav,
  TopView,
  TextView,
  SpanText,
  SecondView,
  TitleView,
  LeftText,
  HrLine,
  YouList,
  Ul,
  Li,
  SpanList,
  ThirdView,
  CompanyList,
  MenuItemLink,
  NavContain,
  HelpBlock,
  NavIcon,
  HelpSpanList,
  ContentView,
  InnerView,
  TopLinear,
  BottmLinear
} from './styles';

const SettingNavbar = (props) => {
  const { onCloseNavbar, onOpenHelpModal, isOpenHelpModal } = props;
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { isAdmin, isOwner } = organization;
  const userAccess = organization?.settings?.access;
  const isShowSubscription = !isElectronApp();

  return (
    <>
      <MainNav>
        <TopLinear></TopLinear>
        <BottmLinear></BottmLinear>
        <NavContain>
          <TopView>
            <InnerView onClick={props?.onClickBack} mobile={mobile}>
              <SVGIcon name='icon-big-left-arrow' fill='var(--white-color)' size={mobile ? 14 : 12} strokeWidth={2} />
              <TextView>
                <SpanText>Back</SpanText>
              </TextView>
            </InnerView>
          </TopView>
          <ContentView>
            <SecondView>
              <TitleView>
                <LeftText>You</LeftText>
                <HrLine></HrLine>
              </TitleView>
              <YouList>
                <Ul>
                  <Li>
                    <MenuItemLink to={'/mysettings'} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Profile</SpanList>
                    </MenuItemLink>
                  </Li>
                </Ul>
              </YouList>
            </SecondView>
            <ThirdView>
              <TitleView>
                <LeftText>Company</LeftText>
                <HrLine></HrLine>
              </TitleView>
              <CompanyList>
                <Ul>
                  {isAdmin && (
                    <Li>
                      <MenuItemLink
                        to={'/settings/users/userslist'}
                        activeClassName='active_menu'
                        onClick={onCloseNavbar}>
                        <SpanList>Users</SpanList>
                      </MenuItemLink>
                    </Li>
                  )}
                  <Li className={hasProduction() ? 'inDevelopment' : ''}>
                    <MenuItemLink to={'/settings/features'} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList mobile={mobile}>Features</SpanList>
                    </MenuItemLink>
                  </Li>
                  <Li>
                    <MenuItemLink to={'/settings/importdata'} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Import Data</SpanList>
                    </MenuItemLink>
                  </Li>
                  {userAccess?.email?.isAccess && (
                    <>
                      <Li>
                        <MenuItemLink to={'/settings/sync'} activeClassName='active_menu' onClick={onCloseNavbar}>
                          <SpanList>Email Accounts</SpanList>
                        </MenuItemLink>
                      </Li>
                      <Li>
                        <MenuItemLink
                          to={'/settings/emailtemplate'}
                          activeClassName='active_menu'
                          onClick={onCloseNavbar}>
                          <SpanList>Email Template</SpanList>
                        </MenuItemLink>
                      </Li>
                    </>
                  )}
                  {isAdmin && (
                    <>
                      <Li>
                        <MenuItemLink
                          to={'/settings/integrations'}
                          activeClassName='active_menu'
                          onClick={onCloseNavbar}>
                          <SpanList>Integrations</SpanList>
                        </MenuItemLink>
                      </Li>
                      <Li>
                        <MenuItemLink to={'/settings/forms'} activeClassName='active_menu' onClick={onCloseNavbar}>
                          <SpanList>Web Form</SpanList>
                        </MenuItemLink>
                      </Li>
                    </>
                  )}
                  <Li>
                    <MenuItemLink to={'/settings/phone-settings'} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Phone Settings</SpanList>
                    </MenuItemLink>
                  </Li>
                  {isAdmin && (
                    <>
                      <Li>
                        <MenuItemLink to={'/settings/customize'} activeClassName='active_menu' onClick={onCloseNavbar}>
                          <SpanList>Customize</SpanList>
                        </MenuItemLink>
                      </Li>
                      {isOwner && (
                        <Li>
                          <MenuItemLink
                            to={'/settings/companyprofile'}
                            activeClassName='active_menu'
                            onClick={onCloseNavbar}>
                            <SpanList>Workspace Profile</SpanList>
                          </MenuItemLink>
                        </Li>
                      )}

                      {isOwner && isShowSubscription && (
                        <Li>
                          <MenuItemLink
                            to={'/settings/subscriptions'}
                            activeClassName='active_menu'
                            onClick={onCloseNavbar}>
                            <SpanList>Subscription</SpanList>
                          </MenuItemLink>
                        </Li>
                      )}
                    </>
                  )}
                  {isAdmin && (
                    <Li>
                      <MenuItemLink to={'/settings/api-keys'} activeClassName='active_menu' onClick={onCloseNavbar}>
                        <SpanList>API Key</SpanList>
                      </MenuItemLink>
                    </Li>
                  )}
                </Ul>
              </CompanyList>
            </ThirdView>
          </ContentView>
          <HelpBlock
            activeClassName='active_menu'
            mobile={mobile}
            onClick={onOpenHelpModal}
            isOpenHelpModal={isOpenHelpModal}>
            <NavIcon name='icon-feedback' help />
            <HelpSpanList help>Help & Feedback</HelpSpanList>
          </HelpBlock>
        </NavContain>
      </MainNav>
    </>
  );
};

export default SettingNavbar;
