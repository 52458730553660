import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ComposedChart, Line, Area, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Menu, MenuItem } from '../../components/Menu';
import Avtar from '../../components/Avtar/Avtar';
import { deleteGoal, getGoalList } from '../../services/goalService';
import { GOAL_TYPE } from '../../constants/constant';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import useMobileDevice from '../../hooks/useMobileDevice';
import Modal from '../../components/Modal';
import SVGIcon from '../../assets/svg/SVGIcon';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import {
  GoalHeader,
  GolaLogo,
  GoalRectangle,
  IconText,
  GoalMain,
  GoalWrapper,
  GoalTop,
  GoalTopTitle,
  GoalCompareText,
  GoalContact,
  ContactIcon,
  ContactRight,
  ContactHead,
  ContactText,
  GoalProcess,
  GoalTitleRight,
  GoalProcessRight,
  GoalProcessLeft,
  ProcessLeftTitle,
  ProcessMaintext,
  ProcessGraph,
  Graph,
  ProcessSubtext,
  UserImgblock,
  UserImg,
  GoalWrap,
  ModalStyle,
  SvgWrapper
} from './Styles';
import AddNewGoalModal from './AddNewGoalModal';
const CHART_ITEM_NAME = {
  1: 'Contacts',
  2: 'Emails'
};
const Goal = () => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const goalSelector = useSelector((state) => state.goal);
  const { list: goalList, loading } = goalSelector;
  const [isOpenAddGoalModal, setIsOpenAddGoalModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getGoalList(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const goalTypeIcon = useCallback((type) => {
    let iconName;
    switch (type) {
      case GOAL_TYPE.NEW_CONTACT:
        iconName = 'icon-user';
        break;
      case GOAL_TYPE.EMAIL_SENT:
        iconName = 'icon-email';
        break;
      default:
        break;
    }
    return <SVGIcon name={iconName} fill='var(--white-color)' size={24} />;
  }, []);

  const openMoreMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
    setSelectedItemId(item);
  }, []);

  const onCloseMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
    setSelectedItemId(undefined);
  }, []);

  const onOpenAddGoalModal = useCallback(() => {
    setIsOpenAddGoalModal(true);
  }, []);

  const onCloseAddGoalModal = useCallback(() => {
    setIsOpenAddGoalModal(false);
    onCloseMoreMenu();
  }, [onCloseMoreMenu]);

  const onClickDelete = useCallback(async () => {
    onCloseMoreMenu();
    const result = await dispatch(deleteGoal(organization_id, selectedItemId));
    if (result) {
      loadData();
    }
  }, [dispatch, loadData, onCloseMoreMenu, organization_id, selectedItemId]);

  const onClickEdit = useCallback(() => {
    onOpenAddGoalModal();
  }, [onOpenAddGoalModal]);

  return (
    <>
      <GoalWrap>
        {mobile ? (
          <MobileTitleBar hasNavBar={true} hasTitle={true}>
            <GoalRectangle mobile={mobile} onClick={onOpenAddGoalModal} title='Add Goal'>
              <SVGIcon name='icon-pluse' fill='var(--white-color)' size={14} strokeWidth={2} />
            </GoalRectangle>
          </MobileTitleBar>
        ) : (
          <GoalHeader mobile={mobile}>
            <GolaLogo>
              <SVGIcon name='icon-goal' fill='var(--primary-color)' size={26} />
              <IconText mobile={mobile}>Goals</IconText>
            </GolaLogo>
            <GoalRectangle onClick={onOpenAddGoalModal} title='Add Goal'>
              <SVGIcon name='icon-pluse' fill='var(--white-color)' size={14} strokeWidth={2} />
            </GoalRectangle>
          </GoalHeader>
        )}
        {!loading && (!goalList || goalList?.length === 0) && 'Goals Not Found'}
        {loading && (!goalList || goalList?.length === 0) ? (
          <div>Loading...</div>
        ) : (
          goalList &&
          goalList?.map((item, index) => {
            const duration = moment(item?.endDate).diff(new Date(), 'days');
            const goalTypeLogo = goalTypeIcon(item?.type);
            const chartData = item?.chartData?.map((chartItem) => {
              return {
                ...chartItem,
                targetCount: item?.targetCount
              };
            });
            return (
              <GoalMain key={`${item?.id}_${index}`} mobile={mobile}>
                <GoalWrapper mobile={mobile}>
                  <GoalTop mobile={mobile}>
                    <GoalTopTitle>{item?.name}</GoalTopTitle>
                    <GoalTitleRight mobile={mobile}>
                      <GoalCompareText>Actual v/s Target</GoalCompareText>
                      <SvgWrapper
                        onClick={(e) => {
                          openMoreMenu(e, item?.id);
                        }}>
                        <SVGIcon name='icon-more' fill='var(--gray-more-icon)' size={24} />
                      </SvgWrapper>
                    </GoalTitleRight>
                  </GoalTop>
                  <GoalProcess mobile={mobile}>
                    <GoalProcessLeft mobile={mobile}>
                      <GoalContact mobile={mobile}>
                        <ContactIcon user>{goalTypeLogo}</ContactIcon>
                        <ContactRight>
                          <ContactHead mobile={mobile}>{item?.statusText}</ContactHead>
                          <ContactText>
                            Out {item?.totalCount} of {item?.target}
                          </ContactText>
                        </ContactRight>
                      </GoalContact>
                      <ProcessLeftTitle mobile={mobile}>
                        <ProcessMaintext>
                          {item?.goalStatusText} ({item?.progress}%)
                        </ProcessMaintext>
                        <ProcessGraph mobile={mobile}>
                          <Graph
                            style={{ width: item?.progress >= 100 ? '100%' : `${item?.progress}%` }}
                            progress={item?.progress}></Graph>
                        </ProcessGraph>
                        <ProcessSubtext>
                          {Math.abs(duration)} Days {duration > 0 ? 'remains' : 'overdue'}
                        </ProcessSubtext>
                      </ProcessLeftTitle>
                      <UserImgblock>
                        {item?.users &&
                          item?.users?.map((user, index) => {
                            return (
                              <UserImg key={`user_${user?.id}_${index}`}>
                                <Avtar imgSrc={user?.profile_photo} name={user?.name} />
                              </UserImg>
                            );
                          })}
                      </UserImgblock>
                    </GoalProcessLeft>
                    <GoalProcessRight>
                      <ResponsiveContainer height={205}>
                        <ComposedChart
                          data={chartData || []}
                          margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20
                          }}>
                          <CartesianGrid stroke='#f5f5f5' />
                          <XAxis dataKey='formatedDate' />
                          {/* <YAxis /> */}
                          <Tooltip />
                          <Legend />
                          <Area type='monotone' dataKey='targetCount' fill='#EAF2FF' stroke='#EAF2FF' name='Target' />
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#2E69CE'
                            strokeWidth={2}
                            name={CHART_ITEM_NAME[item?.type]}
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </GoalProcessRight>
                  </GoalProcess>
                </GoalWrapper>
              </GoalMain>
            );
          })
        )}
        <Modal open={isOpenAddGoalModal} onClose={onCloseAddGoalModal} style={ModalStyle}>
          <AddNewGoalModal handleCloseModal={onCloseAddGoalModal} itemId={selectedItemId} />
        </Modal>
        <Menu
          id='more-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMoreMenuOpen}
          onClose={onCloseMoreMenu}
          getContentAnchorEl={null}
          position='left'
          positionVertical='top'
          width={120}
          marginTop={20}>
          <MenuItem
            iconName='icon-edit-pen'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Edit'
            onClick={onClickEdit}
          />
          <MenuItem
            iconName='icon-delete'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Delete'
            onClick={() => {
              ConfirmDialog({
                title: 'Delete?',
                message: `Are you sure want to delete this goals?`,
                onCancel: () => {
                  onCloseMoreMenu();
                },
                onOk: () => {
                  onClickDelete();
                }
              });
            }}
          />
        </Menu>
      </GoalWrap>
    </>
  );
};

export default Goal;
