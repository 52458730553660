import { useState, useEffect } from 'react';

const useMobileDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const { innerWidth: width } = window;
    if (width <= 640) {
      setIsMobileDevice(true);
    }
  }, []);

  return isMobileDevice;
};
export default useMobileDevice;
