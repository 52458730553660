import React from 'react';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import ComingSoonSvg from '../../../assets/images/svg/comingsoon.svg';
import {
  PageWrapper,
  MainList,
  Header,
  Title,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  LoginDetails,
  IconWrapper,
  RightList,
  ToogleBox,
  Switch,
  ComingSoonImg,
  ComingSoon,
  SubEvent,
  LearnMore,
  CaptionContent
} from './styles';

const Features = () => {
  const mobile = useMobileDevice();
  const history = useHistory();

  return (
    <PageWrapper>
      <MainList mobile={mobile}>
        <Header>
          <Title>Features</Title>
        </Header>
        <ContainBoxTwo>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--invoice-bg)'>
                <SVGIcon name='icon-invoice' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Invoicing & Payment</SubText>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>
                    Create invoice and collect payment from your customer Create invoice and collect payment from your
                    customer
                  </CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <IconView
                onClick={() => {
                  history.push('/settings/features/invoiceandpayment');
                }}>
                <SVGIcon name='icon-settings-round' fill='var(--gray-color)' size={24} />
              </IconView>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--calendar-bg)'>
                <SVGIcon name='icon-calendar' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Calender</SubText>
                  <ComingSoon>Comming Soon</ComingSoon>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>Create a calender for your schedule your schedule</CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--user-bg)'>
                <SVGIcon name='icon-user' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Phone Book</SubText>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>View your contact or add contact</CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--email-bg)'>
                <SVGIcon name='icon-email' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Email</SubText>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>View your all emails here</CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--goal-bg)'>
                <SVGIcon name='icon-goal' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Goals</SubText>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>View your goals or add new goals</CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--draft-bg)'>
                <SVGIcon name='icon-draft' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Email Sequence</SubText>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>Your emails</CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper bgColor='var(--phone-bg)'>
                <SVGIcon name='icon-phone' fill='var(--white-color)' size={20} />
              </IconWrapper>
              <LoginDetails>
                <SubEvent>
                  <SubText>Call log screen</SubText>
                </SubEvent>
                <CaptionContent>
                  <CaptionText>View your Call and SMS list</CaptionText>
                  <LearnMore>Learn more</LearnMore>
                </CaptionContent>
              </LoginDetails>
            </FirstList>
            <RightList>
              <ToogleBox>
                <Switch className='switch'>
                  <input type='checkbox' />
                  <span className='slider round'></span>
                </Switch>
              </ToogleBox>
            </RightList>
          </ListBox>
          <ListBox>
            <FirstList>
              <IconWrapper>
                <ComingSoonImg src={ComingSoonSvg}></ComingSoonImg>
              </IconWrapper>
              <LoginDetails>
                <SubText>Comming Soon</SubText>
              </LoginDetails>
            </FirstList>
          </ListBox>
        </ContainBoxTwo>
      </MainList>
    </PageWrapper>
  );
};

export default Features;
