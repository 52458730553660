import styled, { css } from 'styled-components';
import Button from '../../components/atom/Button/Button';
import { Header3, Header4, Header6, mobileHeader1, mobileText1, SubHeader3, Text1, Text2, Text4 } from '../../styles';

const ManeWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const RightWrapper = styled.div`
  width: 100%;
  margin-left: 215px;
`;
const TwoList = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const LeftContain = styled.div`
  max-width: 321px;
  min-width: 300px;
  width: 100%;
  padding-bottom: 20px;
  height: 100%;
  border-right: 1px solid var(--gray-border-color);
  position: relative;
  ${(props) =>
    props.mobile && props.isShowEmailDetail
      ? css`
          display: none;
        `
      : props.mobile &&
        css`
          display: block;
          max-width: 100%;
        `}
`;
const ContainList = styled.div`
  display: block;
  overflow: hidden;
`;
const TopView = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 1px;
  background: var(--white-color);
  padding: 12px 12px 0 12px;
  z-index: 1;
`;
const FormView = styled.div`
  ::after {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.959407) 7.81%,
      rgba(255, 255, 255, 0.9) 66.41%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 76px;
    z-index: 0;
  }
`;
const SearchRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid var(--gray-border-color);
  border-radius: 3px;
  z-index: 2;
  position: relative;
`;
const CloseIcon = styled.div`
  margin-bottom: -4px;
  cursor: pointer;
`;
const EmailEditIcon = styled.div`
  margin-left: auto;
  svg {
    display: block;
  }
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    flex: 0 0 18px;
  }
  div:nth-child(1) {
    width: 100%;
  }
  label {
    margin: 0;
  }
  input {
    padding: 0;
    width: 100%;
    border: none;
    &:focus {
      border: none;
      margin: 0;
      box-shadow: none;
    }
  }
`;
const BottomView = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ${(props) =>
    props.mobile
      ? css`
          padding: 8px;
        `
      : css`
          padding: 62px 12px 12px 12px;
        `}
  &::-webkit-scrollbar {
    display: none;
  }
`;
const InBoxDetail = styled.div`
  margin-right: 0px;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px 12px;
          border-top: 1px solid var(--gray-border-color);
          &:first-child {
            border-top: 0;
          }
          :hover: none;
        `
      : css`
          padding: 8px 10px;
          margin-bottom: 8px;
          border: 1px solid transparent;
          border-radius: 6px;
        `}
  .unread {
    font-weight: 700;
  }
  :hover {
    background: var(--popup-color-hover);
  }
  ${(props) =>
    props.active && props.mobile
      ? css`
          border-radius: 8px;
          border-top: 1px solid transparent;
          background-color: var(--white-color);
          box-shadow: 0px 1px 3px var(--mobile-box-shadow);
          ${InBoxDetail} {
            border-top: 1px solid transparent;
          }
        `
      : props.active &&
        css`
          border: 1px solid var(--gray-border-color);
          box-shadow: 0px 1px 2px var(--inbox-icon-color);
          background-color: var(--white-color);
        `}
`;
const BorderDiv = styled.div`
  border-bottom: 1px solid var(--gray-border-color);
  margin-top: 10px;
`;
const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LUser = styled.h5`
  font-style: normal;
  color: var(--dark-color);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
  ${(props) =>
    props.mobile
      ? css`
          ${mobileHeader1}
          font-weight: 600;
        `
      : css`
          ${Text1};
        `}
`;
const RTimeView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  svg {
    margin-right: 6px;
  }
`;
const RTime = styled.span`
  ${Header6};
  color: var(--gray-color);
`;
const SecondRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  margin: 2px 0 4px 0;
`;
const CaptionText = styled.p`
  font-style: normal;
  color: var(--nav-dark-color);
  margin: 0 6px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88%;
  ${(props) =>
    props.mobile
      ? css`
          ${mobileText1}
        `
      : css`
          ${Text1};
        `}
`;
const RBox = styled.div`
  display: block;
`;
const NumText = styled.span`
  font-style: normal;
  color: var(--gray-color);
  margin: 0;
  background: var(--gainsboro-color);
  border-radius: 20px;
  ${(props) =>
    props.mobile
      ? css`
          ${Text4}
          padding: 0px 8px;
        `
      : css`
          ${Text2};
          padding: 1px 7px;
        `}
`;
const ThirdRow = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;
const MessageText = styled.p`
  color: var(--gray-color);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.mobile
      ? css`
          ${mobileText1}
          letter-spacing: 0.2px;
        `
      : css`
          ${Text2};
        `}
`;
const RightContain = styled.div`
  width: 100%;
  background-color: var(--main-bg);
  ${(props) =>
    props.mobile && props.isShowEmailDetail
      ? css`
          display: block;
          background-color: transparent;
        `
      : props.mobile &&
        css`
          display: none;
        `}
`;
const RightList = styled.div`
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          height: calc(100vh - 54px);
        `
      : css`
          height: 100vh;
        `}
`;

const IconButton = styled(Button)`
  svg {
    margin-right: 10px;
  }
`;
const MobileDetailTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const TopHeader = styled.div`
  margin-left: auto;
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;
const Li = styled.li`
  margin-right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:last-child {
    margin-right: 0;
  }
`;
const DetailList = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 0;
        `
      : css`
          padding: 16px 24px;
        `}

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const TopTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 17px;
`;
const LeftTitle = styled.h4`
  color: var(--nav-dark-color);
  ${(props) =>
    props.mobile
      ? css`
          margin: 0;
          ${Header3}
        `
      : css`
          margin: 0 10px 6px 0;
          ${Header4};
        `}
`;
const Rightbtn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${(props) =>
    props.mobile &&
    css`
      padding: 0px 16px;
    `}
`;
const Ul2 = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const Li2 = styled.li`
  margin-right: 6px;
  display: inline-flex;
  ${(props) =>
    props.mobile &&
    css`
      margin-top: 6px;
    `}
`;
const Alist = styled.a`
  ${Text2};
  color: var(--gray-color);
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 8px;
`;
const MessageBox = styled.div`
  margin: 17px 0;
  div:first-child {
    padding-top: 0;
  }
`;
const BoxList = styled.div`
  display: flex;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 16px 16px;
  :last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;
const RightTextList = styled.div`
  margin-left: 16px;
  ${(props) =>
    props.mobile
      ? css`
          width: 84%;
        `
      : css`
          width: 96%;
        `}
`;
const FirstTextView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
`;
const LeSpan = styled.span`
  width: 36%;
  color: var(--nav-dark-color);
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${(props) =>
    props.mobile
      ? css`
          ${mobileHeader1}
        `
      : css`
          ${Text1};
        `}
`;
const RiSpan = styled.span`
  ${Text2};
  letter-spacing: normal;
  color: var(--gray-color);
`;
const SeondTextView = styled.div`
  white-space: nowrap;
  width: 50%;
  display: inline-grid;
`;
const CaptionList = styled.p`
  margin: 0;
  ${Text1};
  color: var(--gray-color);
  overflow: hidden;
  text-overflow: ellipsis;
`;
const EmailItem = styled.p`
  margin: 0 6px 0 0;
  ${Text1};
  color: var(--gray-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const EmailName = styled.span`
  ${Text1};
  color: var(--nav-dark-color);
  font-style: normal;
  white-space: nowrap;
`;
const EmailDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const EmailListItem = styled.div`
  display: flex;
  margin-right: 5px;
  flex-wrap: wrap;
`;
const ReadBox = styled.div`
  display: flex;
  border-top: 1px solid var(--gray-border-color);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  cursor: pointer;
  ${(props) =>
    props.expand &&
    css`
      background: var(--white-color);
      border: 1px solid var(--gray-border-color);
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
      padding: 16px;
      :first-child {
        border-bottom: 0;
      }
    `}
  ${(props) =>
    props.hasTopStyle &&
    css`
      border-top: 0;
    `}
    ${(props) =>
    !props.expand &&
    props.isLast &&
    css`
      border-bottom: 1px solid var(--gray-border-color);
    `}
    ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
        `
      : css`
          padding: 16px;
        `}
`;
const BoxList2 = styled.div`
  display: flex;
  width: 100%;
`;
const BtnEye = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 3px 8px;
  margin-right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const RView = styled.div`
  display: flex;
  align-items: center;
`;
const LBtnText = styled.span`
  ${Text2};
  font-style: normal;
  color: var(--gray-color);
  margin-left: 6px;
`;
const LastIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  display: inline-flex;
  ${(props) =>
    props.expand &&
    css`
      display: none;
    `}
`;
const MessageDetail = styled.div`
  /* display: flex; */
  border: 1px solid var(--gray-border-color);
  border-top: 0;
  background: var(--white-color);
  padding: 12px;
  border-radius: 0 0 5px 5px;
`;
const MessageInbox = styled.p`
  ${Text1};
  color: var(--nav-dark-color);
  margin: 0 0 20px 0;
  :last-child {
    margin-bottom: 0;
  }
`;
const LstIconView = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 3px;
  padding: 0 6px;
  display: inline-block;
  margin-top: 13px;
  cursor: pointer;
`;
const LastBtnView = styled.div`
  text-align: center;
  margin: 20px 0;
  button {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
`;
const PopupView = styled.div`
  padding: 6px 0;
  div:last-child {
    margin-bottom: 0;
  }
`;
const ViewList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 6px 3px 6px;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: var(--popup-color-hover);
  }
`;
const ShowText = styled.span`
  ${Text1};
  font-style: normal;
  color: var(--nav-dark-color);
`;
const ShowTime = styled.span`
  ${Text2};
  color: var(--gray-color);
`;
const EmailMainBox = styled.div`
  position: relative;
`;
const EmailMainwrap = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const CrossIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;
const EmialEmptyImg = styled.div``;
const EmailEmptyText = styled.span`
  ${Header3}
  display: block;
  margin-top: 30px;
  margin-bottom: 16px;
`;
const EmailEmptyPara = styled.span`
  ${Text1}
  display: block;
  margin-bottom: 30px;
  color: var(--gray-color);
  text-align: center;
`;
const EmailNotFoundWrapper = styled.div`
  max-width: 247px;
  width: 100%;
  margin: auto;
  text-align: center;
  height: fit-content;
`;
const EmailNotFoundImg = styled.div`
  margin-bottom: 30px;
`;
const EmailNotFoundHead = styled.h3`
  ${Header3}
  color: var(--dark-color);
  margin: 0;
`;
const EmailNotFoundText = styled.h3`
  ${SubHeader3}
  color: var(--gray-color);
  margin: 10px 0px 15px 0px;
`;
const EmailNotFoundLinkBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EmailCircle = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--gray-border-color);
  margin: 0px 12px;
`;
const SendEmailLink = styled.a`
  ${Text1}
  color: var(--primary-color);
  cursor: pointer;
`;
const ModalStyle = {
  width: '640px'
};

export {
  CloseIcon,
  EmailListItem,
  EmailName,
  EmailItem,
  EmailDetail,
  ManeWrapper,
  RightWrapper,
  TwoList,
  LeftContain,
  ContainList,
  TopView,
  SearchRow,
  RightView,
  BottomView,
  InBoxDetail,
  FirstRow,
  LUser,
  RTimeView,
  RTime,
  SecondRow,
  CaptionText,
  RBox,
  NumText,
  ThirdRow,
  MessageText,
  FormView,
  RightContain,
  RightList,
  TopHeader,
  Ul,
  Li,
  DetailList,
  TopTitle,
  LeftTitle,
  Rightbtn,
  Ul2,
  Li2,
  Alist,
  MessageBox,
  BoxList,
  RightTextList,
  FirstTextView,
  LeSpan,
  RiSpan,
  SeondTextView,
  CaptionList,
  ReadBox,
  BoxList2,
  RView,
  LBtnText,
  BtnEye,
  LastIcon,
  MessageDetail,
  MessageInbox,
  LstIconView,
  LastBtnView,
  PopupView,
  ViewList,
  ShowText,
  ShowTime,
  BorderDiv,
  EmailMainBox,
  EmialEmptyImg,
  EmailEmptyText,
  EmailEmptyPara,
  CrossIcon,
  EmailNotFoundWrapper,
  EmailNotFoundImg,
  EmailNotFoundHead,
  EmailNotFoundText,
  EmailNotFoundLinkBlock,
  EmailCircle,
  SendEmailLink,
  EmailMainwrap,
  ModalStyle,
  IconButton,
  EmailEditIcon,
  MobileDetailTop
};
