import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Button from '../../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../../components/Menu';
// import { ReactComponent as Dot } from '../../../assets/images/svg/dot.svg';
import { ReactComponent as Empty } from '../../../assets/images/svg/web-form-empty.svg';
import { getWebForms, trackWebFormActionAnalyticActivity } from '../../../services/settingService';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { setWebFormDetail } from '../../../actions/settingActions';
import Modal from '../../../components/Modal';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import ContactFormBuilder from './ContactFormBuilder';
import {
  MainList,
  Title,
  ContainBoxTwo,
  SubText,
  CaptionText,
  IconView,
  // LoginDetails,
  WrapperTitle,
  ContainBox,
  TextWrapper,
  MailWraper,
  MenuItemWrapper,
  ListBoxWebForm,
  MobileButton,
  MainMobileButton
} from './styles';
const WebForm = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getWebForms(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const settingSelector = useSelector((state) => state.setting);
  const { webFormList } = settingSelector;

  const onOpenMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
    setSelectedItem(item);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl();
  }, []);

  const onOpenEditMenu = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(setWebFormDetail());
      setIsMenuOpen(false);
      setMenuAnchorEl();
      if (!isEmpty(selectedItem)) {
        trackWebFormActionAnalyticActivity('edit', selectedItem?.name);
      } else {
        trackWebFormActionAnalyticActivity('add');
      }
      setIsEditMenuOpen(true);
    },
    [dispatch, selectedItem]
  );

  const onCloseEditMenu = useCallback(() => {
    setIsEditMenuOpen(false);
    setSelectedItem();
    setMenuAnchorEl();
  }, []);

  const onViewWebFormDetails = async (item) => {
    trackWebFormActionAnalyticActivity('view', item?.name);
    props.history.push(`/settings/forms/details/${item?.id}`);
  };

  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <MainMobileButton>
            <MobileButton
              iconName='icon-pluse'
              fontSize='14'
              iconColor='var(--white-color)'
              strokeWidth={2}
              size='small'
              onClick={(e) => {
                setSelectedItem();
                onOpenEditMenu(e);
              }}
            />
          </MainMobileButton>
        </MobileTitleBar>
      )}
      <MainList mobile={mobile}>
        <MailWraper>
          {mobile ? null : (
            <WrapperTitle>
              <Title>Web Form</Title>
              <Button
                title='New Web Form'
                onClick={(e) => {
                  setSelectedItem();
                  onOpenEditMenu(e);
                }}
              />
            </WrapperTitle>
          )}
          <ContainBoxTwo mobile={mobile}>
            {webFormList && webFormList?.length === 0 ? (
              <ListBoxWebForm>
                <ContainBox>
                  <Empty />
                  <TextWrapper>
                    <SubText>No Web Form</SubText>
                    <CaptionText>Want to add a web form? Click the top-right button to start.</CaptionText>
                  </TextWrapper>
                </ContainBox>
              </ListBoxWebForm>
            ) : (
              webFormList?.map((item) => {
                return (
                  <ListBoxWebForm key={item?.id}>
                    <SubText
                      onClick={() => {
                        onViewWebFormDetails(item);
                        setSelectedItem(item);
                      }}>
                      {item?.name}
                    </SubText>
                    {/* <LoginDetails>
                        <CaptionText>Total Submission 54</CaptionText>
                        <Dot />
                        <CaptionText>
                          <span>Last Submitted</span> 3 day ago
                        </CaptionText>
                      </LoginDetails> */}
                    <IconView
                      onClick={(e) => {
                        onOpenMenu(e, item);
                      }}>
                      <SVGIcon fill='var(--gray-color)' size={24} name='icon-more' title='More' />
                    </IconView>
                  </ListBoxWebForm>
                );
              })
            )}
          </ContainBoxTwo>
        </MailWraper>
        <Menu
          id='simple-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={onCloseMenu}
          getContentAnchorEl={null}
          positionVertical='top'
          position='left'
          width={112}
          marginTop={25}
          MenuListProps={{ disablePadding: true }}>
          <MenuItemWrapper>
            <MenuItem
              iconName='icon-show'
              color='var(--gray-icon-color)'
              fontSize='18px'
              text='View'
              onClick={() => {
                onViewWebFormDetails(selectedItem);
              }}
            />
            <MenuItem
              iconName='icon-edit-pen'
              color='var(--gray-icon-color)'
              fontSize='18px'
              text='Edit'
              onClick={(e) => {
                onOpenEditMenu(e);
              }}
            />
          </MenuItemWrapper>
        </Menu>
        <Modal open={isEditMenuOpen} onClose={onCloseEditMenu}>
          <ContactFormBuilder
            webFormId={selectedItem?.id}
            handleCloseModal={onCloseEditMenu}
            isEditMenuOpen={isEditMenuOpen}
          />
        </Modal>
      </MainList>
      <SnackBar />
    </>
  );
};

export default WebForm;
