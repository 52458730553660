import styled, { css } from 'styled-components';
import { Text1 } from '../../styles';

const PopView = styled.div``;
const Border = styled.div`
  border-bottom: 1px solid var(--Platinum-color);
  margin: 12px 0;
`;
const Wrapper = styled.div`
  padding: 0 10px;
`;
const DateTitle = styled.h5`
  margin: 0 0 6px 0;
  ${Text1}
  color:var(--dark-color);
  ${(props) =>
    props.margin &&
    css`
      margin-top: 10px;
    `}
`;
const ButtonWrapper = styled.div`
  margin: 20px 6px 0 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  ${Text1}
  color:var(--dark-color);
  margin: 0 10px 0 0;
`;
const HeaderRightWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          padding: 4px 8px;
          background-color: var(--white-color);
        `
      : css`
          padding: 8px 12px;
        `}
`;

export { PopView, Border, Wrapper, DateTitle, ButtonWrapper, HeaderRightWrapper, Title };
