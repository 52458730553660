import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { EMAIL_PROVIDER_TYPE } from '../../../constants/constant';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  MainContact,
  TitleView,
  TitleText,
  OptionWrapper,
  OptionBoxUl,
  OptionBoxLi,
  OptionBox,
  OptionBoxLabel,
  OptionBoxSpan,
  OptionBoxSpanPara,
  StepWrapper,
  HeaderWrapper,
  SvgWrapper,
  OptionMainWrap
} from './styles';
import TwoWaySyncEmailProvider from './TwoWaySyncEmailProvider';
import AliasEmailProvider from './AliasEmailProvider';
import SendOnlyEmailProvider from './SendOnlyEmailProvider';

const ChooseEmailProvider = (props) => {
  const [type, setType] = useState(1);
  const mobile = useMobileDevice();
  // const [loading, setLoading] = useState(false);

  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;
  const { access_token } = currentUser;

  const onClose = useCallback(() => {
    if (props.handleCloseModal) props.handleCloseModal();
  }, [props]);

  const handleChange = useCallback((type) => {
    setType(type);
  }, []);

  return (
    <>
      <MainContact mobile={mobile}>
        <TitleView>
          <HeaderWrapper>
            <SVGIcon name='icon-email-open' size={22} fill='var(--gray-icon-color)' />
            <TitleText>Choose Email Provider</TitleText>
          </HeaderWrapper>
          <SvgWrapper onClick={onClose} title='Close'>
            <SVGIcon name='icon-close' size={20} fill='var(--gray-icon-color)' />
          </SvgWrapper>
        </TitleView>
        <OptionMainWrap>
          <OptionWrapper>
            <OptionBoxUl mobile={mobile}>
              <OptionBoxLi mobile={mobile}>
                <OptionBox>
                  <OptionBoxLabel
                    border={type === EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC}
                    onClick={() => {
                      handleChange(EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC);
                    }}>
                    <SVGIcon name='icon-two-way' size={34} fill='var(--dark-color)' />
                    <OptionBoxSpan>Two Way Sync</OptionBoxSpan>
                    <OptionBoxSpanPara>We register a new email for you.</OptionBoxSpanPara>
                  </OptionBoxLabel>
                </OptionBox>
              </OptionBoxLi>
              <OptionBoxLi mobile={mobile}>
                <OptionBox>
                  <OptionBoxLabel
                    border={type === EMAIL_PROVIDER_TYPE.SAND_ONLY}
                    onClick={() => {
                      handleChange(EMAIL_PROVIDER_TYPE.SAND_ONLY);
                    }}>
                    <SVGIcon fill='var(--dark-color)' size={34} name='icon-refresh' />
                    <OptionBoxSpan>Send Only</OptionBoxSpan>
                    <OptionBoxSpanPara>Add SMTP email Details</OptionBoxSpanPara>
                  </OptionBoxLabel>
                </OptionBox>
              </OptionBoxLi>
              <OptionBoxLi mobile={mobile}>
                <OptionBox>
                  <OptionBoxLabel
                    border={type === EMAIL_PROVIDER_TYPE.ALIAS}
                    onClick={() => {
                      handleChange(EMAIL_PROVIDER_TYPE.ALIAS);
                    }}>
                    <SVGIcon fill='var(--dark-color)' size={34} name='icon-refresh' />
                    <OptionBoxSpan>Alias</OptionBoxSpan>
                    <OptionBoxSpanPara>Add email alias or google group.</OptionBoxSpanPara>
                  </OptionBoxLabel>
                </OptionBox>
              </OptionBoxLi>
            </OptionBoxUl>
          </OptionWrapper>
          <StepWrapper>
            {type === EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC && (
              <TwoWaySyncEmailProvider orgId={organization_id} token={access_token} />
            )}
            {type === EMAIL_PROVIDER_TYPE.SAND_ONLY && (
              <SendOnlyEmailProvider orgId={organization_id} onClose={onClose} />
            )}
            {type === EMAIL_PROVIDER_TYPE.ALIAS && <AliasEmailProvider orgId={organization_id} onClose={onClose} />}
          </StepWrapper>
        </OptionMainWrap>
      </MainContact>
    </>
  );
};

export default ChooseEmailProvider;
