import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/Environment';
import { setCallLogList, setCallLogLoader } from '../actions/callLogActions';
import { setSmsList, setSmsLoader } from '../actions/smsLogActions';

/**
 * @desc CallLog - Get CallLog List
 * @param {*} organization_id
 */
export const getCallLogList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setCallLogLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Call/${organization_id}`);
    const { data } = response;
    if (data) {
      dispatch(setCallLogList(data));
      return true;
    }
  } catch (e) {
    dispatchCallLogError(getAPIErrorReason(e) || 'Unable to fetch call log entries, please try again', dispatch);
    return false;
  } finally {
    dispatch(setCallLogLoader(false));
  }
};

/**
 * @desc CallLog - Get SMS List
 * @param {*} organization_id
 */
export const getSmsLogList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSmsLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Call/${organization_id}/sms`);
    const { data } = response;
    if (data) {
      dispatch(setSmsList(data));
      return true;
    }
  } catch (e) {
    dispatchCallLogError(getAPIErrorReason(e) || 'Unable to fetch sms log entries, please try again', dispatch);
    return false;
  } finally {
    dispatch(setSmsLoader(false));
  }
};

/**
 * @desc Disconnect running voice call
 * @param {*} organization_id
 */
export const disconnectVoiceCall = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id || !payload || !payload?.callSid) return false;
    await axios.post(`${REACT_APP_APIURL}/Call/${organization_id}/VoiceCall/disconnect`, payload);
    return true;
  } catch (e) {
    console.log('ERROR', e);
    // dispatchCallLogError(getAPIErrorReason(e) || 'Unable to disconnect call, please try again', dispatch);
    return false;
  }
};

function dispatchCallLogError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
