import React, { useMemo } from 'react';
import { ATTACHMENT } from '../../constants/constant';
import { ReactComponent as Pdf } from '../../assets/images/pdf-icon.svg';
import { ReactComponent as Image } from '../../assets/images/svg/jpg-icon.svg';
import { ReactComponent as Docx } from '../../assets/images/svg/docx-icon.svg';
import { ReactComponent as Code } from '../../assets/images/svg/html_css_js-icon.svg';
import { ReactComponent as Dmg } from '../../assets/images/svg/dmg-icon.svg';
import { ReactComponent as Exe } from '../../assets/images/svg/exe-icon.svg';
import { ReactComponent as Mp3 } from '../../assets/images/svg/mp3-icon.svg';
import { ReactComponent as Mp4 } from '../../assets/images/svg/mp4-icon.svg';
import { ReactComponent as Pptx } from '../../assets/images/svg/pptx-icon.svg';
import { ReactComponent as Psd } from '../../assets/images/svg/psd-icon.svg';
import { ReactComponent as Ttf } from '../../assets/images/svg/ttf-icon.svg';
import { ReactComponent as Txt } from '../../assets/images/svg/txt-icon.svg';
import { ReactComponent as Xlsx } from '../../assets/images/svg/xlsx-icon.svg';
import { ReactComponent as Zip } from '../../assets/images/svg/zip-icon.svg';
import { ReactComponent as DefaultFile } from '../../assets/images/svg/default-file-icon.svg';

const FileIcon = ({ fileURL }) => {
  const getFileIcon = (type) => {
    switch (type) {
      case ATTACHMENT.JPEG:
      case ATTACHMENT.JPG:
      case ATTACHMENT.JFIF:
      case ATTACHMENT.PNG:
      case ATTACHMENT.SVG:
        return <Image />;
      case ATTACHMENT.PDF:
        return <Pdf />;
      case ATTACHMENT.DOCX:
      case ATTACHMENT.DOC:
        return <Docx />;
      case ATTACHMENT.JS:
      case ATTACHMENT.CSS:
      case ATTACHMENT.HTML:
        return <Code />;
      case ATTACHMENT.TXT:
        return <Txt />;
      case ATTACHMENT.MP3:
        return <Mp3 />;
      case ATTACHMENT.MP4:
        return <Mp4 />;
      case ATTACHMENT.PSD:
        return <Psd />;
      case ATTACHMENT.PPTX:
      case ATTACHMENT.PPT:
        return <Pptx />;
      case ATTACHMENT.TTF:
        return <Ttf />;
      case ATTACHMENT.XLSX:
      case ATTACHMENT.CSV:
      case ATTACHMENT.XLS:
        return <Xlsx />;
      case ATTACHMENT.ZIP:
        return <Zip />;
      case ATTACHMENT.DMG:
        return <Dmg />;
      case ATTACHMENT.EXE:
        return <Exe />;
      default:
        return <DefaultFile />;
    }
  };

  const extension = useMemo(() => {
    const extension = fileURL?.split('.').pop();
    return extension;
  }, [fileURL]);

  return <>{getFileIcon(extension)}</>;
};

export default FileIcon;
