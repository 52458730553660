import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setNotificationList, setNotificationLoader } from '../actions/notificationActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIErrorReason } from '../helpers/common';

/**
 * @desc Notification - Get Notification List
 * @param {*} organization_id
 */
export const getNotificationList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setNotificationLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/notifications/${organization_id}`);
    const { data } = response;
    if (data) dispatch(setNotificationList(data));
    return true;
  } catch (e) {
    dispatchNotificationError(getAPIErrorReason(e) || 'Unable to get notification list please try again', dispatch);
    return false;
  } finally {
    dispatch(setNotificationLoader(false));
  }
};

/**
 * @desc Notification - Mark As Read
 * @param {*} organization_id
 */
export const markAsReadNotification = (organization_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setNotificationLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/notifications/${organization_id}/item/${item_id}`);
    const { data } = response;
    if (data) await dispatch(getNotificationList(organization_id));
    return true;
  } catch (e) {
    dispatchNotificationError(getAPIErrorReason(e) || 'Unable to mark as read notification please try again', dispatch);
    return false;
  } finally {
    dispatch(setNotificationLoader(false));
  }
};

/**
 * @desc Notification - Mark As All Read
 * @param {*} organization_id
 */
export const markAsAllReadNotification = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setNotificationLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/notifications/${organization_id}/all`);
    const { data } = response;
    if (data) await dispatch(getNotificationList(organization_id));
    return true;
  } catch (e) {
    dispatchNotificationError(
      getAPIErrorReason(e) || 'Unable to mark as all read notification please try again',
      dispatch
    );
    return false;
  } finally {
    dispatch(setNotificationLoader(false));
  }
};

function dispatchNotificationError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
