import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/Modal';
import { Menu, MenuItem } from '../../../components/Menu';
import {
  AvatarBGColors,
  DETAIL_SCREEN_TYPE,
  LOOKUP_FIELD_CUSTOM_ID,
  LOOKUP_TYPE,
  LOOKUP_TYPE_ID,
  LOOKUP_UPDATE_SOURCE
} from '../../../constants/constant';
import { getGravatarURL, getLookupFieldValueForDisplay } from '../../../helpers/common';

import {
  deleteLookup,
  getLookupDetails,
  getLookupDetailsByEmail,
  getLookupTags,
  getTableTypeForAnalytics,
  trackContactDetailActionAnalytics,
  trackContactDetailAnalytics,
  trackCustomerActionAnalytics,
  updateLookupField
} from '../../../services/lookupService';
import { getActivityByLookup } from '../../../services/activityService';
import { getEmailList } from '../../../services/emailService';
import Avtar from '../../../components/Avtar/Avtar';
import { setLookupDetail, setLookupEnrichmentFields, setLookupTags } from '../../../actions/lookupActions';
import AddNewContact from '../AddNewContact';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { appInit, clearOrgReduxData, hasUserAccessAndPermission } from '../../../services/appService';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import { ROUTE } from '../../../constants/routeConst';
import { ReactComponent as EmailNotImg } from '../../../assets/images/svg/contact_empty.svg';
import UserPreferenceSingleton from '../../../helpers/UserPreferenceSingleton';
import { getOrganizationList } from '../../../services/organizationService';
import { hideIntercomLauncher } from '../../../services/analyticsService';
import ContactActivity from './ContactActivity/ContactActivity';
import {
  TwoListDetail,
  LeftList,
  ProFileContain,
  ProfileLogo,
  UserView,
  UserName,
  UserLeave,
  InputWrapperModal,
  InputLabel,
  StyledMenuItem,
  Status,
  ActionWrapper,
  Actions,
  ActionLi,
  FirstContainWrap,
  ActivityButton,
  OverviewButton,
  MobileActivityTab,
  MobileTabMain,
  ModalStyle,
  RighLinkIcon,
  ProfileExternalApp,
  ExtraOrganize,
  ExtraOrganizeText,
  ExtraOrganizeInner,
  AddContactBlock,
  AddContactSpan,
  ProfileUpperBlock,
  ExternalEmpty,
  ExtraEmptyMain,
  EmptyHeading,
  EmptyPara
} from './styles';
import TransferLookupModal from './TransferLookupModal/TransferLookupModal';
import { EnrichModal } from './EnrichModal/EnrichModal';
import ContactDetailProfile from './Components/ContactDetailProfile';
import ContactDetailActionBlock from './Components/ContactDetailActionBlock';
import ContactDetailConnectedItems from './Components/ContactDetailConnectedItems';
import ContactDetailFields from './Components/ContactDetailFields';

let frontAppcontext;

export const ContactDetail = (props) => {
  const paramId = props.match.params?.id;
  const paramTableName = props.match.params?.name;
  const dispatch = useDispatch();
  let history = useHistory();
  const [emailLookup, setEmailLookup] = useState(undefined);
  const [noConversation, setNoConversation] = useState(false);
  const [conversationRecipientItem, setConversationRecipientItem] = useState(undefined);
  const [newContactItem, setNewContactItem] = useState();

  const organizationSelector = useSelector((state) => state.organization);
  const lookupSelector = useSelector((state) => state.lookup);
  const lookupTableSelector = useSelector((state) => state.lookupTables);

  const { organization, list: OrgList } = organizationSelector;
  const { lookupDetail: lookupDetailData, tags, connectedLookup } = lookupSelector;
  const { list: lookupTableList } = lookupTableSelector;
  const { id: organization_id, users, isPeopleEnrichment } = organization;

  const hasFrontApp = useMemo(() => {
    return props?.location?.pathname === ROUTE.FRONTAPP_PAGE_URL;
  }, [props?.location?.pathname]);

  const hasExternalApp = useMemo(() => {
    return hasFrontApp;
  }, [hasFrontApp]);

  useEffect(() => {
    if (hasExternalApp) hideIntercomLauncher();
  }, [hasExternalApp]);

  const loadLookupByEmail = useCallback(
    async (conversation) => {
      if (conversation) {
        setNoConversation(false);
        setEmailLookup(undefined);
        const email = conversation?.recipient?.handle;
        setConversationRecipientItem(conversation?.recipient);
        const lookup = await dispatch(getLookupDetailsByEmail(organization_id, email));
        setEmailLookup(lookup);
      } else {
        setNoConversation(true);
        setEmailLookup(undefined);
      }
    },
    [dispatch, organization_id]
  );

  const TableNameOrId = useMemo(() => {
    return paramTableName || emailLookup?.tableId || newContactItem?.tableId;
  }, [paramTableName, emailLookup?.tableId, newContactItem?.tableId]);

  const lookup_id = useMemo(() => {
    return emailLookup?.id || paramId;
  }, [emailLookup?.id, paramId]);

  useEffect(() => {
    if (!hasFrontApp) return;
    const Front = require('@frontapp/plugin-sdk');
    Front.contextUpdates.subscribe((context) => {
      frontAppcontext = context;
      switch (context.type) {
        case 'noConversation':
          // console.log('No conversation selected');
          setNoConversation(true);
          loadLookupByEmail(undefined);
          break;
        case 'singleConversation':
          // console.log('Selected conversation:', context.conversation);
          loadLookupByEmail(context.conversation);
          break;
        case 'multiConversations':
          // console.log('Multiple conversations selected', context.conversations);
          loadLookupByEmail(context.conversations?.[0]);
          break;
        default:
          console.error(`Unsupported context type: ${context.type}`);
          break;
      }
    });
  }, [loadLookupByEmail, hasFrontApp]);

  const mobile = useMobileDevice();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemCloneId, setSelectedItemCloneId] = useState();
  const [newDealContact, setNewDealContact] = useState();
  const [newDealCompany, setNewDealCompany] = useState();
  const [isOpenTransferLookupModal, setIsOpenTransferLookupModal] = useState(false);
  const [isOpenEnrichModal, setIsOpenEnrichModal] = useState(false);
  const [isSwitchOrgMenuOpen, setIsSwitchOrgMenuOpen] = useState(false);
  const [isContactTableListMenu, setIsContactTableListMenu] = useState(false);

  const [screen, setScreen] = useState(DETAIL_SCREEN_TYPE.OVERVIEW);

  const table = useMemo(() => {
    if (!TableNameOrId) return;
    const currentTable = lookupTableList?.find((x) => x?.name === TableNameOrId || x?.id === TableNameOrId);
    const index = lookupTableList.findIndex((el) => el.id === currentTable.id);
    return { ...currentTable, index };
  }, [lookupTableList, TableNameOrId]);

  const tableTypeForAnalytics = useMemo(() => {
    if (table) {
      const type = getTableTypeForAnalytics(table);
      return type;
    }
  }, [table]);

  const getDealTables = useMemo(() => {
    const tables = lookupTableList?.filter((x) => x?.type === LOOKUP_TYPE.deals);
    return tables;
  }, [lookupTableList]);

  const userAccess = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', table?.name));
    const permissionDelete = dispatch(hasUserAccessAndPermission('tables', 'permissionDelete', table?.name));
    const permissionSendMail = dispatch(hasUserAccessAndPermission('email', 'permissionSend'));
    const isEmailAccess = dispatch(hasUserAccessAndPermission('email', 'isAccess'));

    let isDealAccess = false;
    let permissionEditDeal = false;

    getDealTables?.forEach((table) => {
      const isAccess = dispatch(hasUserAccessAndPermission('tables', 'isAccess', table?.name));
      const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', table?.name));
      if (isAccess) isDealAccess = true;
      if (permissionEdit) permissionEditDeal = true;
    });

    return {
      permissionEdit,
      permissionDelete,
      permissionSendMail,
      isEmailAccess,
      isDealAccess,
      permissionEditDeal
    };
  }, [dispatch, table, getDealTables]);

  const loadData = useCallback(async () => {
    if (!lookup_id) return;
    dispatch(getLookupDetails(organization_id, lookup_id));
    dispatch(getLookupTags(organization_id, table?.id));
  }, [dispatch, organization_id, lookup_id, table]);

  useEffect(() => {
    const elPageContainer = document.getElementById('page-container');
    if (elPageContainer) elPageContainer.classList.add('page-container');
    return () => {
      if (elPageContainer) elPageContainer.classList.remove('page-container');
    };
  }, []);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setLookupDetail(null));
      dispatch(setLookupTags(undefined));
    };
  }, [loadData, dispatch]);

  const trackDetailAnalyticsEvent = useCallback(
    (field, value) => {
      let params, action;
      if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.STATUS || field?.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE) {
        const fromOption = field?.options?.find((x) => x?.id === field?.value);
        const toOption = field?.options?.find((x) => x?.id === value?.value);
        if (fromOption && toOption) {
          params = {
            from: String(fromOption.value).toLowerCase(),
            to: String(toOption.value).toLowerCase()
          };
        }
        action = 'status changed';
        if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE) action = 'stage changed';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE) {
        action = 'followup changed';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.CLOSE_DATE) {
        action = 'close date changed';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.ASSIGN_USER) {
        action = 'assigned';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.TAGS) {
        let result;
        if (
          value?.value_Options?.length > field?.value_Options?.length ||
          (!field?.value_Options && value?.value_Options)
        ) {
          action = 'tag added';
          result = value?.value_Options?.find((x) => !field?.value_Options?.some((y) => x?.id === y?.id));
        } else {
          action = 'tag removed';
          result = field?.value_Options?.find((x) => !value?.value_Options?.some((y) => x?.id === y?.id));
        }
        if (result) {
          params = { 'tag name': result?.id };
        }
      }
      trackContactDetailAnalytics(tableTypeForAnalytics, action, params);
    },
    [tableTypeForAnalytics]
  );

  const updateLookupFields = useCallback(
    async (field, value, isUpdateStatus) => {
      if (!lookup_id) return;
      const obj = {
        field: field,
        value: value
      };
      const result = dispatch(updateLookupField(organization_id, lookup_id, obj));
      if (result) {
        trackDetailAnalyticsEvent(field, value);
        if (isUpdateStatus) {
          dispatch(getActivityByLookup(organization_id, lookup_id));
        }
      }
    },
    [dispatch, organization_id, lookup_id, trackDetailAnalyticsEvent]
  );

  //Get lookup fields and display value from lookupGroupFields
  const lookupDetails = useMemo(() => {
    const groupFields = lookupDetailData?.groupFields;
    if (!groupFields || groupFields.length === 0) return {};

    const fields = [].concat.apply(
      [],
      groupFields?.map((x) => x?.fields)
    );

    const statusField = fields.find(
      (x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.STATUS || x.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE
    );
    const dateField = fields.find(
      (x) =>
        x.customId === LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE || x.customId === LOOKUP_FIELD_CUSTOM_ID.CLOSE_DATE
    );

    const assignToField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.ASSIGN_USER);
    const tagsField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.TAGS);

    const emailField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.EMAIL);
    const phoneField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.PHONE);
    const cityField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.CITY);
    const countryField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.COUNTRY);
    const firstNameField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.FIRST_NAME);
    const lastNameField = fields.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.LAST_NAME);
    const nameField = fields?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.NAME);
    const facebookField = fields?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.FACEBOOK);
    const googlePlusField = fields?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.GOOGLE_PLUS);
    const linkedinField = fields?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.LINKEDIN);
    const skypeField = fields?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.SKYPE);
    const twitterField = fields?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.TWITTER);

    let name;
    if (nameField) name = nameField?.value;
    if (!name && (firstNameField || lastNameField))
      name = `${firstNameField?.value || ''} ${lastNameField?.value || ''}`.trim();
    if (!name && emailField) name = emailField?.value;
    if (!name && phoneField) name = phoneField?.value;

    const excludeFields = [
      LOOKUP_FIELD_CUSTOM_ID.STATUS,
      LOOKUP_FIELD_CUSTOM_ID.STAGE,
      LOOKUP_FIELD_CUSTOM_ID.ASSIGN_USER,
      LOOKUP_FIELD_CUSTOM_ID.TAGS,
      LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE,
      LOOKUP_FIELD_CUSTOM_ID.CLOSE_DATE
    ];

    const fieldToDisplay = fields
      .map((item) => {
        return {
          ...item,
          value_formatted: getLookupFieldValueForDisplay(item, item)
        };
      })
      .filter((x) => x.value_formatted)
      .filter((x) => excludeFields.indexOf(x.customId) !== 0)
      .filter((x) => !x.isNonEditable);

    const addFieldList = fields
      .filter((x) => {
        return !fieldToDisplay.find((y) => {
          return x.id === y.id;
        });
      })
      .filter((x) => !x.isNonEditable)
      .filter((x) => {
        return !excludeFields.find((y) => y === x.customId);
      });

    const city = getLookupFieldValueForDisplay(cityField, cityField);
    const country = getLookupFieldValueForDisplay(countryField, countryField);
    const address = (city || '') + (country ? ', ' + country : '');
    return {
      id: lookupDetailData?.id,
      name: name,
      email: getLookupFieldValueForDisplay(emailField, emailField),
      phone: getLookupFieldValueForDisplay(phoneField, phoneField),
      firstName: getLookupFieldValueForDisplay(firstNameField, firstNameField),
      lastName: getLookupFieldValueForDisplay(lastNameField, lastNameField),
      twitter: getLookupFieldValueForDisplay(twitterField, twitterField),
      google_plus: getLookupFieldValueForDisplay(googlePlusField, googlePlusField),
      linkedin: getLookupFieldValueForDisplay(linkedinField, linkedinField),
      skype: getLookupFieldValueForDisplay(skypeField, skypeField),
      facebook: getLookupFieldValueForDisplay(facebookField, facebookField),
      address: address,
      statusField,
      dateField,
      assignToField,
      tagsField,
      fieldToDisplay,
      addFieldList,
      tableId: table?.id
    };
  }, [lookupDetailData?.groupFields, lookupDetailData?.id, table?.id]);

  const gravatar = useMemo(() => {
    let gravatar;
    if (table?.type === LOOKUP_TYPE.contacts && lookupDetails?.email) {
      gravatar = getGravatarURL(lookupDetails?.email);
    }
    return gravatar;
  }, [lookupDetails?.email, table?.type]);

  const openAddModal = useCallback(
    (type) => {
      if (type === 'isEditModal') setIsOpenAddModal(true);

      if (table?.type === LOOKUP_TYPE.contacts) {
        setNewDealContact(lookupDetails);
      }
      if (table?.type === LOOKUP_TYPE.companies) {
        setNewDealCompany(lookupDetails);
      }
    },
    [table, lookupDetails]
  );

  const closeAddModal = useCallback(() => {
    setIsOpenAddModal(false);
    setSelectedItemId(undefined);
    setSelectedItemCloneId(undefined);
  }, []);

  const onSave = useCallback(() => {
    closeAddModal();
    loadData();
  }, [loadData, closeAddModal]);

  const onOpenSwitchOrgMenu = useCallback((e) => {
    e.stopPropagation();
    setIsSwitchOrgMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseSwitchOrgMenu = useCallback(() => {
    setIsSwitchOrgMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const openEnrichModal = useCallback(() => {
    setIsOpenEnrichModal(true);
  }, []);

  const closeEnrichModal = useCallback(() => {
    setIsOpenEnrichModal(false);
    dispatch(setLookupEnrichmentFields(null));
  }, [dispatch]);

  const onSend = useCallback(async () => {
    await dispatch(getEmailList(organization_id));
    dispatch(getActivityByLookup(organization_id, lookupDetailData?.id));
  }, [dispatch, lookupDetailData?.id, organization_id]);

  const onClickEdit = useCallback(() => {
    openAddModal('isEditModal');
  }, [openAddModal]);

  const openTranferLookupModal = useCallback(() => {
    setIsOpenTransferLookupModal(true);
  }, []);

  const closeTransferLookupModal = useCallback(() => {
    setIsOpenTransferLookupModal(false);
  }, []);

  const onClickTransfer = useCallback(() => {
    openTranferLookupModal();
  }, [openTranferLookupModal]);

  const onOpenContactTableListMenu = useCallback((e) => {
    setIsContactTableListMenu(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseContactTableListMenu = useCallback(() => {
    setIsContactTableListMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const onDeleteItem = useCallback(async () => {
    const result = await dispatch(deleteLookup(organization_id, lookupDetails?.id));
    if (result) {
      const type = getTableTypeForAnalytics(table);
      trackCustomerActionAnalytics(type, 'deleted', 'details');
      props.history.goBack();
    }
  }, [dispatch, lookupDetails?.id, organization_id, props.history, table]);

  const onPressSwitchOrganization = useCallback(
    async (item) => {
      try {
        UserPreferenceSingleton.getInstance().setOrganization(item);
        await dispatch(clearOrgReduxData());
        await dispatch(appInit());
        history?.push(ROUTE.FRONTAPP_PAGE_URL);
      } catch (e) {
        console.log('ERROR', e);
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    //Load org list if not have
    if (hasExternalApp && (!OrgList || OrgList?.length === 0)) {
      dispatch(getOrganizationList());
    }
  }, [hasExternalApp, OrgList, dispatch]);

  const externalAppSwitchOrgView = useMemo(() => {
    if (hasExternalApp && organization?.name && OrgList?.length > 0) {
      return (
        <ExtraOrganize>
          <ExtraOrganizeInner onClick={onOpenSwitchOrgMenu}>
            <Avtar imgSrc={organization?.logoUrl} name={organization?.name} size={20} />
            <ExtraOrganizeText>{organization?.name}</ExtraOrganizeText>
          </ExtraOrganizeInner>
        </ExtraOrganize>
      );
    }
    return null;
  }, [hasExternalApp, onOpenSwitchOrgMenu, organization?.logoUrl, organization?.name, OrgList?.length]);

  const noConversationView = useMemo(() => {
    if (hasExternalApp && noConversation) {
      return (
        <ExternalEmpty>
          <ExtraEmptyMain>
            <EmailNotImg />
            <EmptyHeading>No Conversation Selected!</EmptyHeading>
            <EmptyPara>Select conversation to view contact and other information</EmptyPara>
          </ExtraEmptyMain>
        </ExternalEmpty>
      );
    }
    return null;
  }, [hasExternalApp, noConversation]);

  const switchOrgMenuView = useMemo(() => {
    if (hasExternalApp) {
      return (
        <Menu
          id='switch-org-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isSwitchOrgMenuOpen}
          onClose={onCloseSwitchOrgMenu}
          className='switch-org-menu'
          getContentAnchorEl={null}
          position='left'
          marginTop='35px'
          positionVertical='top'
          width='180px'>
          {OrgList?.map((item, index) => {
            return (
              <StyledMenuItem key={`switch_org_menu_${item?.id}_${index}`}>
                <InputWrapperModal
                  onClick={() => {
                    onPressSwitchOrganization(item);
                    onCloseSwitchOrgMenu();
                  }}>
                  <InputLabel htmlFor={item?.id}>
                    {item?.logoUrl ? (
                      <Avtar imgSrc={organization?.logoUrl} name={organization?.name} size={30} />
                    ) : (
                      <Avtar
                        name={item?.name}
                        size={30}
                        backgroundColor={AvatarBGColors[index % AvatarBGColors.length]}
                      />
                    )}
                    <Status>{item?.name}</Status>
                  </InputLabel>
                </InputWrapperModal>
              </StyledMenuItem>
            );
          })}
        </Menu>
      );
    }
    return null;
  }, [
    OrgList,
    hasExternalApp,
    isSwitchOrgMenuOpen,
    menuAnchorEl,
    onCloseSwitchOrgMenu,
    onPressSwitchOrganization,
    organization?.logoUrl,
    organization?.name
  ]);

  const onOpenLinkOnNewTab = useCallback(() => {
    if (hasExternalApp && lookupDetails?.id && table?.name) {
      const url = `https://dashboard.salescamp.app/cn/${table?.name}/view/${lookupDetails?.id}/overview`;
      if (hasFrontApp) {
        frontAppcontext.openUrl(url);
      }
    }
  }, [hasExternalApp, hasFrontApp, lookupDetails?.id, table?.name]);

  const contactTables = useMemo(() => {
    if (!hasExternalApp) return null;
    const contactList = lookupTableList?.filter((x) => x?.type === LOOKUP_TYPE.contacts);
    return contactList || [];
  }, [hasExternalApp, lookupTableList]);

  const onAddNewContactClick = useCallback(
    (table, recipientContact) => {
      let contactItem = {
        email: recipientContact?.handle,
        name: recipientContact?.name,
        tableId: table.id
      };
      let lastname = '';
      let firstname;
      const nameStrings = contactItem?.name?.split(' ');
      if (nameStrings) {
        nameStrings?.forEach((x, i) => {
          if (i === 0) firstname = x;
          else lastname = lastname.concat(x);
          if (i !== 0 && nameStrings.length - 1 !== i) lastname = lastname.concat(' ');
        });
        contactItem = {
          ...contactItem,
          firstname,
          lastname
        };
      }
      if (contactItem?.email) {
        setNewContactItem(contactItem);
        setIsOpenAddModal(true);
      }
      onCloseContactTableListMenu();
    },
    [onCloseContactTableListMenu]
  );

  const contactListMenuView = useMemo(() => {
    if (!hasExternalApp) return null;
    return (
      <Menu
        id='save-contact-menu'
        anchorEl={menuAnchorEl}
        open={isContactTableListMenu}
        onClose={onCloseContactTableListMenu}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        marginTop='40px'>
        {contactTables?.map((item) => {
          return (
            <MenuItem
              fontSize='16'
              color='var(--gray-icon-color)'
              text={item?.label}
              onClick={() => {
                onAddNewContactClick(item, conversationRecipientItem);
              }}
            />
          );
        })}
      </Menu>
    );
  }, [
    hasExternalApp,
    onCloseContactTableListMenu,
    isContactTableListMenu,
    contactTables,
    menuAnchorEl,
    onAddNewContactClick,
    conversationRecipientItem
  ]);

  const newconversationContactView = useMemo(() => {
    if (hasExternalApp && !noConversationView && conversationRecipientItem) {
      return (
        <ProfileExternalApp>
          <ProfileUpperBlock>
            <ProFileContain>
              <ProfileLogo>
                <Avtar imgSrc={gravatar} name={conversationRecipientItem?.name} />
              </ProfileLogo>
              <UserView>
                <UserName>{lookupDetails?.name || conversationRecipientItem?.name}</UserName>
                <UserLeave>
                  {lookupDetails?.email || conversationRecipientItem?.handle || conversationRecipientItem?.email}
                </UserLeave>
              </UserView>
            </ProFileContain>
            {lookupDetails?.id && (
              <RighLinkIcon onClick={onOpenLinkOnNewTab}>
                <SVGIcon name='icon-share-link' size={20} fill='var(--gray-icon-color)' />
              </RighLinkIcon>
            )}
          </ProfileUpperBlock>
          {!lookupDetails?.id && (
            <AddContactBlock onClick={onOpenContactTableListMenu}>
              <SVGIcon name='icon-pluse' size={14} fill='var(--gray-icon-color)' />
              <AddContactSpan>Add to Contact</AddContactSpan>
            </AddContactBlock>
          )}
        </ProfileExternalApp>
      );
    }
    return null;
  }, [
    hasExternalApp,
    noConversationView,
    conversationRecipientItem,
    gravatar,
    lookupDetails?.name,
    lookupDetails?.email,
    lookupDetails?.id,
    onOpenLinkOnNewTab,
    onOpenContactTableListMenu
  ]);

  return (
    <>
      <TwoListDetail mobile={mobile}>
        <LeftList mobile={mobile}>
          {!hasExternalApp && mobile && (
            <MobileTitleBar
              hasNavBar={false}
              hasTitle={false}
              containerStyle={{ position: 'relative', top: 'auto', zIndex: '1' }}></MobileTitleBar>
          )}
          {externalAppSwitchOrgView}
          {noConversationView}
          {newconversationContactView}
          {!noConversationView && lookupDetails?.id && (
            <>
              <ContactDetailProfile
                hasExternalApp={hasExternalApp}
                lookupDetails={lookupDetails}
                table={table}
                userAccess={userAccess}
                tableTypeForAnalytics={tableTypeForAnalytics}
                onSendEmail={onSend}
              />
              {mobile && (
                <MobileTabMain hasExternalApp={hasExternalApp}>
                  <MobileActivityTab hasExternalApp={hasExternalApp}>
                    <OverviewButton
                      hasExternalApp={hasExternalApp}
                      screen={screen}
                      onClick={() => {
                        setScreen(DETAIL_SCREEN_TYPE.OVERVIEW);
                      }}>
                      Overview
                    </OverviewButton>
                    <ActivityButton
                      hasExternalApp={hasExternalApp}
                      screen={screen}
                      onClick={() => {
                        setScreen(DETAIL_SCREEN_TYPE.ACTIVITY);
                      }}>
                      Activity
                    </ActivityButton>
                  </MobileActivityTab>
                </MobileTabMain>
              )}
              <FirstContainWrap screen={screen} mobile={mobile}>
                <ContactDetailActionBlock
                  lookupDetails={lookupDetails}
                  userAccess={userAccess}
                  users={users}
                  updateLookupFields={updateLookupFields}
                  table={table}
                />
                <ContactDetailConnectedItems
                  lookupDetails={lookupDetails}
                  lookupTableList={lookupTableList}
                  connectedLookup={connectedLookup}
                  table={table}
                  userAccess={userAccess}
                  organization={organization}
                  tags={tags}
                  lookup_id={lookup_id}
                  onSendEmail={onSend}
                  onSaveContact={onSave}
                />
                <ContactDetailFields
                  lookupDetails={lookupDetails}
                  userAccess={userAccess}
                  table={table}
                  lookup_id={lookup_id}
                />

                {(userAccess?.permissionEdit || userAccess?.permissionDelete) && (
                  <ActionWrapper mobile={mobile} hasExternalApp={hasExternalApp}>
                    {userAccess?.permissionEdit && (
                      <>
                        <ActionLi
                          onClick={() => {
                            setSelectedItemCloneId(lookupDetails?.id);
                            trackContactDetailActionAnalytics(tableTypeForAnalytics, 'clone');
                            onClickEdit();
                          }}>
                          <Actions>Clone</Actions>
                        </ActionLi>
                        <ActionLi>
                          <Actions
                            onClick={() => {
                              setSelectedItemId(lookupDetails?.id);
                              trackContactDetailActionAnalytics(tableTypeForAnalytics, 'edit');
                              onClickEdit();
                            }}>
                            Edit
                          </Actions>
                        </ActionLi>
                      </>
                    )}
                    {userAccess?.permissionDelete && (
                      <ActionLi>
                        <Actions
                          delete
                          onClick={() => {
                            trackContactDetailActionAnalytics(tableTypeForAnalytics, 'delete');
                            ConfirmDialog({
                              iconName: 'icon-delete',
                              closeIcon: 'icon-close',
                              title: 'Delete  ',
                              message: `Are you sure want to delete this ${LOOKUP_TYPE_ID[table?.type]}?`,
                              onOk: () => {
                                onDeleteItem();
                              }
                            });
                          }}>
                          Delete
                        </Actions>
                      </ActionLi>
                    )}
                    <ActionLi onClick={onClickTransfer}>
                      <Actions>Transfer</Actions>
                    </ActionLi>
                    {isPeopleEnrichment && (
                      <ActionLi onClick={openEnrichModal}>
                        <Actions>Enrich</Actions>
                      </ActionLi>
                    )}
                  </ActionWrapper>
                )}
              </FirstContainWrap>

              {screen === DETAIL_SCREEN_TYPE.ACTIVITY && (
                <ContactActivity {...props} lookupDetails={lookupDetails} table={table} />
              )}
            </>
          )}
        </LeftList>
        {!mobile && <ContactActivity {...props} lookupDetails={lookupDetails} table={table} />}
      </TwoListDetail>
      <Modal open={isOpenAddModal} onClose={closeAddModal} style={ModalStyle}>
        <AddNewContact
          handleCloseModal={closeAddModal}
          onSave={onSave}
          source={LOOKUP_UPDATE_SOURCE.DETAILS}
          currentTableType={tableTypeForAnalytics}
          company={newDealCompany}
          contact={newDealContact}
          itemId={selectedItemId}
          cloneId={selectedItemCloneId}
          item={newContactItem}
          table={table}
          {...props}
        />
      </Modal>
      <Modal
        id='simple-transfer-lookup'
        open={isOpenTransferLookupModal}
        onClose={closeTransferLookupModal}
        position='center'>
        <TransferLookupModal onClose={closeTransferLookupModal} table={table} lookupId={lookupDetailData?.id} />
      </Modal>
      <Modal id='simple-enrich' open={isOpenEnrichModal} onClose={closeEnrichModal}>
        <EnrichModal
          onClose={closeEnrichModal}
          lookup={lookupDetails}
          groupFields={lookupDetailData?.groupFields}
          onSave={loadData}
        />
      </Modal>
      {switchOrgMenuView}
      {contactListMenuView}
      <SnackBar />
    </>
  );
};

export default ContactDetail;
