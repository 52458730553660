import axios from 'axios';
import Dexie from 'dexie';
import {
  setCurrentOrganization,
  setOrganizationList,
  setOrganizationLoader,
  setCompanyItem
} from '../actions/organizationActions';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/Environment';
import { teamSize } from '../data/raw';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import { trackAnalyticActivity } from './analyticsService';
import TwilioService from './twilioService';
import LookupCacheService from './lookupCacheServices';
import EmailCacheService from './emailCacheServices';
import EmailAccountCacheService from './emailAccountCacheServices';
import ActivityCacheService from './activityCacheService';
/**
 * @desc Organization - Get Organization List
 * @param {*} obj Data Obj
 */
export const getOrganizationList = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrganizationLoader(true));
    //Load from Local
    let organizations = UserPreferenceSingleton.getInstance().getOrganizations();
    dispatch(setOrganizationList(organizations));

    const response = await axios.get(`${REACT_APP_APIURL}/Company/list`);
    const { data } = response;
    if (data.length > 0) {
      const organization = UserPreferenceSingleton.getInstance().getOrganization();
      let selectedOrganization = {};
      organization
        ? (selectedOrganization = data.find((x) => x.id === organization.id))
        : (selectedOrganization = data[0]);
      UserPreferenceSingleton.getInstance().setOrganization(selectedOrganization);
      dispatch(setCurrentOrganization(selectedOrganization));
      UserPreferenceSingleton.getInstance().setOrganizations(data);
      dispatch(setOrganizationList(data));
    }
    return true;
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'Unable to get Workspace please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Create - New Organization
 */
export const createOrganization = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrganizationLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Company/Create`, payload);
    const { data } = response.data;
    if (data) {
      trackOrganizationAnalyticActivity(payload);
      await dispatch(getOrganizationDetails(data?.id));
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'Unable to Create Workspace please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

const trackOrganizationAnalyticActivity = (payload) => {
  try {
    let customerType = 'both';
    if (payload?.customerType === 1) customerType = 'company';
    else if (payload?.customerType === 2) customerType = 'individual';
    else customerType = 'both';

    let teamType;
    if (payload?.teamType && teamSize) {
      let type = teamSize?.find((x) => x.value === payload?.teamType);
      if (type) teamType = type.name;
    }
    trackAnalyticActivity('account created', {
      name: payload?.name,
      'customer type': customerType,
      'team size': teamType,
      industry: payload?.industry,
      currency: payload?.currency
    });
  } catch (e) {
    console.log('track workspace Error', e);
  }
};

/**
 * @desc Organization - Get Organization Item for Company Profile
 */
export const getOrganization = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/company/item/${organization_id}`);
    const { data } = response;
    if (data) {
      dispatch(setCompanyItem(data));
      return data;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'unable to get workspace please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Organization - Get Organization Details with Settings
 */
export const getOrganizationDetails = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/company/details/${organization_id}`);
    const { data } = response;
    if (data) {
      UserPreferenceSingleton.getInstance().setOrganization(data);
      dispatch(setCurrentOrganization(data));
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'unable to get workspace details please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc User - Make an admin
 */
export const makeAnAdmin = (organization_id, user_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id && !user_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/Company/${organization_id}/User/${user_id}/admin`);
    const { data } = response;
    if (data) {
      await dispatch(getOrganizationDetails(organization_id));
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'unable to make an admin please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Users - Remove From Admin
 */
export const removeFromAdmin = (organization_id, user_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id && !user_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/Company/${organization_id}/User/${user_id}/admin`);
    const { data } = response;
    if (data) {
      await dispatch(getOrganizationDetails(organization_id));
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'unable to remove from admin please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Organization - Get Organization Users
 * @param {*} table_id
 */
export const getOrganizationUsers = () => {
  const organization = UserPreferenceSingleton.getInstance().getOrganization();
  const users = organization?.users;
  return users;
};

/**
 * @desc Upload Company Profile Logo - User Profile Settings
 */
export const uploadCompanyLogo = (organization_id, fileData) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/company/uploadLogo/${organization_id}`, fileData);
    const { data } = response;
    const { message } = data || response;

    data && dispatch(getOrganization(organization_id));
    dispatchOrganizationSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'Unable to upload workspace logo please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Update Company Profile - Settings
 */
export const updateCompanyProfile = (organization_id, params) => async (dispatch) => {
  try {
    if (!params?.name) {
      dispatchOrganizationError('Workspace Name is Required', dispatch);
      return false;
    }
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/company/update/${organization_id}`, params);
    const { data } = response;
    const { message } = data || response;

    if (data) {
      dispatch(getOrganization(organization_id));
      dispatchOrganizationSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'Unable to update workspace profile please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Send Delete Company Verification Code
 */
export const sendDeleteCompanyVerificationCode = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/company/${organization_id}/DeleteRequest`);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatchOrganizationSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'Unable to Send Delete Request please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

/**
 * @desc Organization: delete Organization
 * @param organization_id, verificationCode
 */
export const deleteOrganization = (organization_id, verificationCode) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setOrganizationLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/company/${organization_id}/delete/${verificationCode}`);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatchOrganizationSuccess(message, dispatch);
      UserPreferenceSingleton.getInstance().setOrganization(undefined);
      OrganizationPreferencesSingleton.getInstance().clearAllOrgData();
      TwilioService.getInstance()?.clearTwilioData();
      //clear existing org data
      await LookupCacheService.getInstance()?.clearDbData();
      const { clearOrgReduxData } = require('./appService');
      dispatch(clearOrgReduxData());
      await LookupCacheService.removeInstance();
      await EmailCacheService.removeInstance();
      await EmailAccountCacheService.removeInstance();
      await ActivityCacheService.removeInstance();
      await Dexie.delete(organization_id);
      return true;
    }
  } catch (e) {
    dispatchOrganizationError(getAPIErrorReason(e) || 'Unable to delete workspace please try again', dispatch);
    return false;
  } finally {
    dispatch(setOrganizationLoader(false));
  }
};

export const getCurrentCompanyPlanType = () => {
  let company = UserPreferenceSingleton.getInstance().getOrganization();
  if (company) {
    if (company.plan === 'trial') return 'trial';
    else if (company.plan === 'expired') return 'expired';
    else if (company.plan === 'free') return 'free';
    else return 'paid';
  }
  return 'free';
};

function dispatchOrganizationError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchOrganizationSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
