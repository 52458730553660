import styled from 'styled-components';
import { Header4, Text2 } from '../../../../styles';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  display: block;
  margin-left: 12px;
`;
const IconView = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
const TwilioSettingWrapper = styled.div``;
const Wrapper = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const BtnViewList = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--Platinum-color);
`;
const TopTitle = styled.span`
  display: block;
  ${Text2}
  color: var(--dark-color);
  margin-bottom: 8px;
`;
const PhoneNumWrap = styled.div``;
const PhoneNumRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;
const RoWLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const PhoneNumber = styled.span`
  display: block;
  margin-bottom: 4px;
  ${Header4}
  color: var(--dark-color);
`;
const PhoneStatus = styled.span`
  ${Text2}
  color: var(--gray-color);
`;
const RowRight = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-color);
  }

  input:focus + .slider {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 12px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
export {
  TitleWrapper,
  HeaderWrapper,
  TitleText,
  IconView,
  TwilioSettingWrapper,
  Wrapper,
  BtnViewList,
  TopTitle,
  PhoneNumWrap,
  PhoneNumRow,
  RoWLeft,
  PhoneNumber,
  PhoneStatus,
  RowRight
};
