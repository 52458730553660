import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGIcon from '../../assets/svg/SVGIcon';
import Button from '../../components/atom/Button/Button';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import { ROUTE } from '../../constants/routeConst';
import useMobileDevice from '../../hooks/useMobileDevice';
import { getDublicateContacts } from '../../services/dashboardService';
import {
  MainContain,
  WelView,
  ContainList,
  RowList,
  ListView,
  Leftcontain,
  LeftRow,
  ListTextView,
  MobileButton,
  CaptionList,
  IconWrapper,
  Iconbg,
  NavigationTextWrapper,
  TitleMereg,
  DuplicationTitle,
  DuplicationHeaderWrapper,
  HeaderTitle,
  ContactListIcon
} from './styles';

const InboxDuplication = (props) => {
  const dashboard = useSelector((state) => state.dashboard);
  const { contactDuplication } = dashboard;
  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const loadData = useCallback(async () => {
    dispatch(getDublicateContacts());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const renderDuplicateContact = useCallback(
    (item) => {
      return (
        <ListView mobile={mobile}>
          <Leftcontain>
            <LeftRow mobile={mobile}>
              <IconWrapper bgColor='#55CCF1'>
                <Iconbg name='icon-copy' size={20} />
              </IconWrapper>
              <ListTextView>
                <NavigationTextWrapper>
                  <TitleMereg mobile={mobile}>
                    {item?.original?.name} & {item?.duplicate?.name} as same {item?.type}
                  </TitleMereg>
                </NavigationTextWrapper>
                <CaptionList>
                  <DuplicationTitle>There are {item?.count} duplication from this contact</DuplicationTitle>
                </CaptionList>
              </ListTextView>
            </LeftRow>
          </Leftcontain>
          {mobile ? (
            <MobileButton mobile={mobile}>
              <Button
                onClick={() => {
                  props.history.push(
                    `${ROUTE.INBOX_MERGE_DUPLICATION}?original=${item?.original?.id}&duplicate=${item?.duplicate?.id}`
                  );
                }}
                iconName='icon-merge'
                iconColor='var(--gray-color)'
                fontSize='20px'
                secondary></Button>
            </MobileButton>
          ) : (
            <MobileButton>
              <Button
                onClick={() => {
                  props.history.push(
                    `${ROUTE.INBOX_MERGE_DUPLICATION}?original=${item?.original?.id}&duplicate=${item?.duplicate?.id}`
                  );
                }}
                iconName='icon-merge'
                iconColor='var(--gray-color)'
                fontSize='20px'
                secondary
                title='Merge'></Button>
            </MobileButton>
          )}
        </ListView>
      );
    },
    [props.history, mobile]
  );
  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={false} hasTitle={false}>
          <HeaderTitle mobile={mobile}>Screening</HeaderTitle>
        </MobileTitleBar>
      )}
      <MainContain>
        <WelView mobile={mobile}>
          <ContainList>
            {mobile ? null : (
              <DuplicationHeaderWrapper>
                <ContactListIcon onClick={props.history.goBack}>
                  <SVGIcon name='icon-list' fill='var(--primary-color)' size={26} />
                </ContactListIcon>
                <HeaderTitle>Screening</HeaderTitle>
              </DuplicationHeaderWrapper>
            )}
            {contactDuplication?.map((item, index) => {
              return <RowList key={`duplicate_contact_item_${index}`}>{renderDuplicateContact(item)}</RowList>;
            })}
          </ContainList>
        </WelView>
      </MainContain>
    </>
  );
};

export default InboxDuplication;
