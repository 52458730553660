import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-flagpack';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { ROUTE } from '../../../constants/routeConst';
import { setAuthValidationError } from '../../../actions/authActions';
import {
  dispatchAuthValidationError,
  getInvitedUserDetail,
  signup,
  validateIsLoggedIn
} from '../../../services/authServices';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import { APP_INIT_RESPONSE_TYPE } from '../../../constants/constant';
import AppLogo from '../../../components/AppLogo';
import { countryWithCodes } from '../../../data/raw';
import CountryDropDown from '../../../components/CountryDropDown/CountryDropDown';
import { fetchIPLocation } from '../../../services/twilioService';
import LoginLinearImg from '../../../assets/images/features.png';
import useMobileDevice from '../../../hooks/useMobileDevice';
import Icon from '../../../components/Icon/Icon';
import Validation from '../../../components/Validation/Validation';
import { trackPage } from '../../../services/analyticsService';
import { isMACApp, isWindowsApp } from '../../../global/Helper';
import ElectronTitlebar from '../../../components/ElectronTitlebar/ElectronTitlebar';
import {
  ManeWrapper,
  LoginContainer,
  Title,
  LogoWrapper,
  Form,
  PrivacyPolicy,
  AlreadyPara,
  Link,
  colourStyles,
  DropDownWrapper,
  CountryDropDownWrapper,
  MobileInput,
  LoginWrap,
  LoginLeft,
  LoginRight,
  LoginImage,
  InputBlock,
  Lable,
  Para,
  NumberWrapper,
  NumberSpan,
  IconBackground,
  OtpWrapper,
  MacDragDro
} from './Styles';

const SIGN_UP_STEP = {
  SIGN_UP: 1,
  PHONE_VERIFICATION: 2,
  UPDATE_PHONE_NUMBER: 3
};
// const INDIA_CODE = '+91';

export const Signup = ({ ...props }) => {
  const mobile = useMobileDevice();
  const auth = useSelector((state) => state.auth);
  const { loading, validationError } = auth;

  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({});
  const [countryFlag, setCountryFlag] = useState();
  const [code, setCode] = useState('');
  const [step, setStep] = useState(SIGN_UP_STEP.SIGN_UP);

  const setCountryDetail = useCallback((country) => {
    let countryCode = country?.countryCode;
    if (country?.countryCode === 'GB') countryCode = 'GB-UKM';
    setSelectedCountry(country);
    setCountryFlag(countryCode);
  }, []);

  useEffect(() => {
    if (validateIsLoggedIn()) {
      props.history.push(ROUTE.MAIN);
    }
  }, [props.history]);

  useEffect(() => {
    trackPage({ visit_url: 'signup' });
    // componentDidMount events
    return () => {
      // componentWillUnmount events
      dispatch(setAuthValidationError(''));
    };
  }, [dispatch]);

  useEffect(() => {
    async function loadData() {
      try {
        const userLocation = await fetchIPLocation();
        if (userLocation) {
          const country = countryWithCodes?.find((x) => x?.countryCode === userLocation?.country_code);
          if (country) {
            setCountryDetail(country);
          }
        }
      } catch (e) {}
    }
    loadData();
  }, [setCountryDetail]);

  const invite_id = useMemo(() => {
    const inviteId = props.match.params.invitationId;
    return inviteId;
  }, [props.match.params.invitationId]);

  useEffect(() => {
    const getInvitedUser = async () => {
      const invitedUser = await dispatch(getInvitedUserDetail(invite_id));
      setEmail(invitedUser?.email);
      setName(invitedUser?.name);
    };
    if (invite_id) {
      getInvitedUser();
    }
    return () => {
      dispatch(setAuthValidationError(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invite_id]);

  // const onSendVerificationCode = useCallback(async () => {
  //   const phoneNumber = `${selectedCountry?.code}${mobileNumber}`;
  //   const appVerifier = window.recaptchaVerifier;
  //   const auth = getAuth();
  //   signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //     })
  //     .catch((error) => {
  //       const err = JSON.parse(JSON.stringify(error));
  //       dispatchAuthValidationError(err?.code, dispatch);
  //     });
  // }, [selectedCountry?.code, mobileNumber, dispatch]);

  // const onSendOTP = useCallback(async () => {
  //   setStep(SIGN_UP_STEP.PHONE_VERIFICATION);
  //   setCode('');
  //   initializeFirebaseApp();
  //   const auth = getAuth();
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     'recaptcha-container',
  //     {
  //       size: 'invisible',
  //       callback: (token) => {},
  //       'expired-callback': () => {}
  //     },
  //     auth
  //   );
  //   onSendVerificationCode();
  // }, [onSendVerificationCode]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const signupObj = {
        name: name,
        email: email,
        password: password,
        confirmPassword: password,
        countryCode: selectedCountry?.code,
        phoneNumber: mobileNumber,
        appName: 'WebApp',
        inviteId: invite_id
      };
      // if (selectedCountry?.code === INDIA_CODE && sendOtp) {
      //   const checkInput = await dispatch(checkValidation(signupObj));
      //   if (!checkInput) {
      //     return;
      //   }
      //   const result = await dispatch(validateSignupDetails(signupObj));
      //   if (result) {
      //     onSendOTP();
      //   }
      // } else {
      const result = await dispatch(signup(signupObj));
      if (result) {
        // setStep(SIGN_UP_STEP.SIGN_UP);
        // dispatchAuthValidationError(null, dispatch);
        if (result && result.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
          props.history.push(result.path);
        } else {
          props.history.push(ROUTE.INBOX);
        }
      }
      // }
    },
    [name, email, password, selectedCountry?.code, mobileNumber, invite_id, dispatch, props.history]
  );

  // const onConfirmationCode = useCallback(
  //   async (e) => {
  //     e.preventDefault();
  //     window.confirmationResult
  //       .confirm(code)
  //       .then((result) => {
  //         onSubmit(e);
  //       })
  //       .catch((error) => {
  //         const err = JSON.parse(JSON.stringify(error));
  //         dispatchAuthValidationError(err?.code, dispatch);
  //       });
  //   },
  //   [code, dispatch, onSubmit]
  // );

  const onClickLogin = () => {
    props.history.push(ROUTE.LOGIN);
  };

  const renderErrors = () => {
    return <Validation error={validationError} />;
  };

  const renderFlag = useMemo(() => {
    if (countryFlag === 'AN' || countryFlag === 'XK') return null;
    try {
      return (
        <Flag
          className='flagicon'
          code={countryFlag || countryWithCodes[0].countryCode}
          gradient='real-linear'
          size='m'
          hasDropShadow
        />
      );
    } catch (error) {
      return null;
    }
  }, [countryFlag]);

  return (
    <ManeWrapper>
      {isMACApp() && <MacDragDro />}
      {isWindowsApp() && <ElectronTitlebar />}
      <LoginWrap>
        <LoginLeft mobile={mobile}>
          {step === SIGN_UP_STEP.SIGN_UP ? (
            <LoginContainer>
              <LogoWrapper>
                <AppLogo />
                <Title>Create New Account</Title>
              </LogoWrapper>
              <Form onSubmit={onSubmit}>
                <InputBlock>
                  <Lable>Name </Lable>
                  <Input
                    isPrimary={true}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Name'
                    value={name}
                    disabled={loading}
                  />
                </InputBlock>
                <InputBlock>
                  <Lable>Email</Lable>
                  <Input
                    isPrimary={true}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type='Email'
                    id='email'
                    name='email'
                    placeholder='Email'
                    value={email}
                    disabled={invite_id || loading}
                  />
                </InputBlock>
                <InputBlock>
                  <Lable>Mobile Number</Lable>
                  <DropDownWrapper>
                    {renderFlag}
                    <CountryDropDownWrapper>
                      <CountryDropDown
                        styles={colourStyles}
                        options={countryWithCodes}
                        labelField={'text'}
                        valueField={'countryCode'}
                        value={selectedCountry?.countryCode}
                        placeholder='+1'
                        onChange={(value) => {
                          setCountryDetail(value);
                        }}
                      />
                    </CountryDropDownWrapper>
                    <MobileInput
                      isPrimary={true}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      type='number'
                      id='number'
                      name='number'
                      placeholder='Mobile Number'
                      value={mobileNumber}
                      disabled={loading}
                    />
                  </DropDownWrapper>
                </InputBlock>
                <InputBlock>
                  <Lable>Password</Lable>
                  <Input
                    isPrimary={true}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type='password'
                    id='password'
                    name='Password'
                    placeholder='Password'
                    value={password}
                    disabled={loading}
                  />
                </InputBlock>
                {renderErrors()}
                <PrivacyPolicy>
                  By signing up, you are indicating that you have read and agree to the Terms of Use and Privacy Policy.
                </PrivacyPolicy>
                <Button
                  width={'100%'}
                  size='large'
                  fullWidth
                  title='Create New Account'
                  primary
                  type='submit'
                  loading={loading}
                />
                <AlreadyPara>
                  Already have an Account? <Link onClick={loading ? null : onClickLogin}>Log in</Link>
                </AlreadyPara>
              </Form>
            </LoginContainer>
          ) : step === SIGN_UP_STEP.PHONE_VERIFICATION ? (
            <LoginContainer>
              <LogoWrapper>
                <AppLogo />
                <Title>Phone Verification</Title>
                <Para>We heve sent you an SMS with a OTP code to your number for verification</Para>
                <NumberWrapper>
                  <NumberSpan>{`${selectedCountry?.code} ${mobileNumber}`}</NumberSpan>
                  <IconBackground
                    onClick={() => {
                      setStep(SIGN_UP_STEP.UPDATE_PHONE_NUMBER);
                      dispatchAuthValidationError(null, dispatch);
                    }}>
                    <Icon title='Edit' name='icon-edit' fontSize='16' color='var(--primary-color)' />
                  </IconBackground>
                </NumberWrapper>
              </LogoWrapper>
              <Form
              // onSubmit={onConfirmationCode}
              >
                <InputBlock>
                  <Lable>Enter 6 digit code</Lable>
                  <Input
                    isPrimary={true}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    type='number'
                    id='number'
                    name='number'
                    placeholder='Enter 6 digit code'
                    value={code}
                    disabled={loading}
                  />
                </InputBlock>
                {renderErrors()}
                <OtpWrapper>
                  Didn’t receive the OTP?
                  <Link
                  // onClick={onSendVerificationCode}
                  >
                    Resend code
                  </Link>
                </OtpWrapper>
                <Button width={'100%'} size='large' fullWidth title='Next' primary type='submit' loading={loading} />
                <div id='recaptcha-container'></div>
              </Form>
            </LoginContainer>
          ) : (
            <LoginContainer>
              <LogoWrapper>
                <AppLogo />
                <Title>Update phone number</Title>
                <Para>Please enter your current phone number.</Para>
              </LogoWrapper>
              <Form
              // onSubmit={(e) => {
              //   onSubmit(e, true);
              // }}
              >
                <InputBlock>
                  <Lable>Mobile Number</Lable>
                  <DropDownWrapper>
                    {renderFlag}
                    <CountryDropDownWrapper>
                      <CountryDropDown
                        styles={colourStyles}
                        options={countryWithCodes}
                        labelField={'text'}
                        valueField={'countryCode'}
                        value={selectedCountry?.countryCode}
                        placeholder='+1'
                        onChange={(value) => {
                          setCountryDetail(value);
                        }}
                      />
                    </CountryDropDownWrapper>
                    <MobileInput
                      isPrimary={true}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      type='number'
                      id='number'
                      name='number'
                      placeholder='Mobile Number'
                      value={mobileNumber}
                      disabled={loading}
                    />
                  </DropDownWrapper>
                </InputBlock>
                {renderErrors()}
                <Button
                  width={'100%'}
                  size='large'
                  fullWidth
                  title='Send OTP'
                  primary
                  type='submit'
                  loading={loading}
                />
              </Form>
            </LoginContainer>
          )}
        </LoginLeft>
        <LoginRight mobile={mobile}>
          <LoginImage src={LoginLinearImg}></LoginImage>
        </LoginRight>
      </LoginWrap>
      <SnackBar />
    </ManeWrapper>
  );
};

export default Signup;
