import styled from 'styled-components';
import { Header3, SubHeader3 } from '../../../styles';

const EmailNotFoundWrapper = styled.div`
  max-width: 523px;
  width: 100%;
  padding: 40px;
  margin: auto;
  text-align: center;
  height: fit-content;
`;
const EmailNotFoundImg = styled.div`
  margin-bottom: 30px;
`;
const EmailNotFoundHead = styled.h3`
  ${Header3}
  color: var(--dark-color);
  margin: 0;
`;
const EmailNotFoundText = styled.h3`
  ${SubHeader3}
  color: var(--gray-color);
  margin: 10px 0px 18px 0px;
`;
const EmailNotFoundLinkBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    :first-child {
      margin-right: 12px;
    }
  }
`;
const ContactWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export {
  EmailNotFoundWrapper,
  EmailNotFoundImg,
  EmailNotFoundHead,
  EmailNotFoundText,
  EmailNotFoundLinkBlock,
  ContactWrapper
};
