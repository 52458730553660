import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';
import SVGIcon from '../../assets/svg/SVGIcon';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      minHeight: 'auto'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)',
      borderRadius: '5px;'
    },
    '& svg': {
      marginRight: '12px'
    },
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '18px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '0 6px',
    padding: '7px 10px'
  }
}))(MaterialMenuItem);

const MenuItem = React.forwardRef(({ iconName, color, fontSize, text, ...props }, ref) => {
  return (
    <>
      <StyledMenuItem {...props} ref={ref}>
        {iconName && <SVGIcon isIconButton name={iconName} size={fontSize} fill={color} />}
        {text}
      </StyledMenuItem>
    </>
  );
});

export default MenuItem;

MenuItem.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string
};

MenuItem.defaultProps = {
  iconName: '',
  text: 'Item Name'
};
