import React, { useCallback, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import {
  createEmailTemplate,
  getEmailTemplateItem,
  trackEmailTemplateAnalyticActivity,
  updateEmailTemplate
} from '../../../services/emailService';
import { setEmailTemplateItem, updateEmailTemplateItem } from '../../../actions/emailActions';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  MainContact,
  TitleView,
  TitleText,
  TextEditer,
  SecondContain,
  InputView,
  BottomView,
  Form,
  HeaderWrapper,
  ButtonWrapper,
  SvgWrapper
} from './styles';

const NewMailTemplate = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const id = props?.emailTemplateId;
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (!id) return;
    dispatch(getEmailTemplateItem(organization_id, id));
  }, [dispatch, organization_id, id]);

  const emailSelector = useSelector((state) => state.email);
  const { emailTemplateItem, loading } = emailSelector;

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setEmailTemplateItem(null));
    };
  }, [dispatch, loadData]);

  const onClose = useCallback(() => {
    props.handleCloseModal();
    dispatch(setEmailTemplateItem(null));
  }, [props, dispatch]);

  const onUpdateEmailTemplate = useCallback(
    (fieldName, FieldVal) => {
      dispatch(updateEmailTemplateItem({ propName: fieldName, value: FieldVal }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const params = emailTemplateItem;
      const result = params?.id
        ? await dispatch(updateEmailTemplate(organization_id, id, params))
        : await dispatch(createEmailTemplate(organization_id, params));

      if (result) {
        onClose();
        if (params?.id) {
          trackEmailTemplateAnalyticActivity('updated', emailTemplateItem?.name, emailTemplateItem?.subject);
        } else {
          trackEmailTemplateAnalyticActivity('added', emailTemplateItem?.name, emailTemplateItem?.subject);
        }
      }
    },
    [dispatch, onClose, organization_id, id, emailTemplateItem]
  );

  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
      [{ list: 'ordered' }],
      [{ list: 'bullet' }],
      ['clean']
    ]
  };
  return (
    <MainContact mobile={mobile}>
      <TitleView>
        <HeaderWrapper>
          <SVGIcon fill='var(--gray-icon-color)' size={22} name='icon-document' />
          <TitleText>{id ? 'Edit Email Template' : 'New Email Template'}</TitleText>
        </HeaderWrapper>
        <SvgWrapper onClick={onClose} title='Close'>
          <SVGIcon fill='var(--gray-icon-color)' size={20} name='icon-close' />
        </SvgWrapper>
      </TitleView>
      <Form
        onSubmit={(e) => {
          onSubmit(e);
        }}>
        <SecondContain>
          <InputView>
            <Input
              isPrimarySmall={true}
              type='text'
              id='name'
              name='Name'
              placeholder='Template Lable'
              title='Template Lable'
              value={emailTemplateItem?.name || ''}
              onChange={(e) => {
                onUpdateEmailTemplate('name', e.target.value);
              }}
            />
          </InputView>
          <InputView>
            <Input
              isPrimarySmall={true}
              type='text'
              id='subject'
              name='Name'
              placeholder='Subject'
              title='Subject'
              value={emailTemplateItem?.subject || ''}
              onChange={(e) => {
                onUpdateEmailTemplate('subject', e.target.value);
              }}
            />
          </InputView>
          <TextEditer>
            <ReactQuill
              theme='snow'
              modules={modules}
              placeholder={'Enter event descriptions..'}
              style={{ border: 'none' }}
              value={(emailTemplateItem && emailTemplateItem?.body) || ''}
              onChange={(e) => {
                onUpdateEmailTemplate('body', e);
              }}
            />
          </TextEditer>
        </SecondContain>
        <BottomView>
          <ButtonWrapper>
            <Button type='submit' title='save' loading={loading} />
          </ButtonWrapper>
          <Button type='button' title='cancel' secondary onClick={onClose} />
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default NewMailTemplate;
