import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { currencyWithDecimal } from '../../../../helpers/common';
import { ACTIVITY_ACTION, DATE_FORMAT, LOOKUP_TABLE_TYPE, TOOLTIP_DATE_FORMAT } from '../../../../constants/constant';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import {
  AllList,
  Amount,
  AmountView,
  Captiontitle,
  ContainView,
  DateText,
  IconList,
  MainList,
  RightList,
  TimeText,
  ToDay,
  UserRecive,
  UserText,
  Value,
  IconWrapper,
  ActivityUpperBlock,
  ActivityBottomBlock,
  UserWrapperBlock
} from './styles';
import CommentHoverItem from './Comments/CommentHoverItem';
import CommentActivityItem from './ActivityItem/CommentActivityItem';
import StatusActivityItem from './ActivityItem/StatusActivityItem';
import EmailActivityItem from './ActivityItem/EmailActivityItem';
import MeetingActivityItem from './ActivityItem/MeetingActivityItem';
import SmsActivityItem from './ActivityItem/SmsActivityItem';
import CallActivityItem from './ActivityItem/CallActivityItem';
import FileActivityItem from './ActivityItem/FileActivityItem';
import NotesActivityItem from './ActivityItem/NotesActivityItem';

const ActivityItem = (props) => {
  const {
    item,
    isOpenMoreOptionMenu,
    isOpenThread,
    onMoreMenuClick: handleClickOnMoreOptionActivity,
    onOpenEmailDetailModal,
    onOpenFilePreview,
    selectedActivity,
    setSelectedActivity
  } = props;
  const mobile = useMobileDevice();

  const history = useHistory();
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;

  const onLookupClick = useCallback(
    (lookup) => {
      const table = lookupTableList.find((x) => x.id === lookup?.tableId);
      if (table) {
        const type = LOOKUP_TABLE_TYPE[table?.type];
        const pathName = `/${type}/${table?.name}/view/${lookup?.id}/overview`;
        history.push(pathName);
      }
    },
    [lookupTableList, history]
  );
  const [isShowCommentInput, setIsShowCommentInput] = useState(false);

  const onClickComment = useCallback(() => {
    setIsShowCommentInput(true);
    if (setSelectedActivity) setSelectedActivity(item);
  }, [item, setSelectedActivity]);

  const haveCallRecording = useMemo(() => item?.callRecording?.length > 0, [item?.callRecording]);

  const svgIconSize = useMemo(() => (mobile ? 22 : 18), [mobile]);

  const actionIcon = useMemo(() => {
    let action = item?.action;
    if (haveCallRecording) action = ACTIVITY_ACTION.CallWithRecord;

    let iconName;
    let bgColor;
    switch (action) {
      case ACTIVITY_ACTION.Call:
        iconName = 'icon-phone';
        bgColor = '#F476CB';
        break;
      case ACTIVITY_ACTION.Meeting:
        iconName = 'icon-account';
        bgColor = '#7D89F3';
        break;
      case ACTIVITY_ACTION.Email:
        iconName = 'icon-email';
        bgColor = '#FF6C5F';
        break;
      case ACTIVITY_ACTION.Sms:
        iconName = 'icon-email';
        bgColor = '#2DDE98';
        break;
      case ACTIVITY_ACTION.Lunch:
        iconName = 'icon-food';
        bgColor = '#FF6C5F';
        break;
      case ACTIVITY_ACTION.DeadLine:
        iconName = 'icon-flag';
        bgColor = '#F69231';
        break;
      case ACTIVITY_ACTION.Tasks:
        iconName = 'icon-task';
        bgColor = '#FF4F81';
        break;
      case ACTIVITY_ACTION.Notes:
        iconName = 'icon-document';
        bgColor = '#FF6C5F';
        break;
      case ACTIVITY_ACTION.Status:
        iconName = 'icon-star';
        bgColor = '#2DDE98';
        break;
      case ACTIVITY_ACTION.File:
        iconName = 'icon-attachment';
        bgColor = ' #48977D';
        break;
      case ACTIVITY_ACTION.CallWithRecord:
        iconName = 'icon-phone';
        bgColor = '#F476CB';
        break;
      case ACTIVITY_ACTION.Activity:
        // //timeline Activity
        iconName = 'activity-icon';
        // bgColor = colors.taskWhite;
        break;
      default:
    }
    return (
      <IconWrapper bgColor={bgColor} mobile={mobile}>
        <SVGIcon fill='var(--white-color)' size={svgIconSize} name={iconName} strokeWidth={1.8} />
      </IconWrapper>
    );
  }, [haveCallRecording, item?.action, mobile, svgIconSize]);

  const createdOn = useMemo(() => {
    const dateTime = moment(item?.createdOn);
    return {
      tooltip: dateTime.format(TOOLTIP_DATE_FORMAT),
      absolute: dateTime.fromNow()
    };
  }, [item?.createdOn]);

  const itemDate = useMemo(() => {
    const dateTime = moment(item?.date);
    return {
      tooltip: dateTime.format(DATE_FORMAT),
      absolute: dateTime.fromNow(),
      month: dateTime.format('MMM').toUpperCase(),
      day: dateTime.format('DD')
    };
  }, [item?.date]);

  const result = useMemo(() => {
    switch (item?.action) {
      case ACTIVITY_ACTION.Status:
        return (
          <>
            <StatusActivityItem
              item={item}
              actionIcon={actionIcon}
              createdOn={createdOn}
              isOpenThread={isOpenThread}
              onLookupClick={onLookupClick}
            />
          </>
        );
      case ACTIVITY_ACTION.Call:
        return (
          <CallActivityItem
            item={item}
            actionIcon={actionIcon}
            createdOn={createdOn}
            isOpenThread={isOpenThread}
            onLookupClick={onLookupClick}
            onMoreMenuClick={handleClickOnMoreOptionActivity}
            haveCallRecording={haveCallRecording}
            isOpenMoreOptionMenu={isOpenMoreOptionMenu}
            selectedActivity={selectedActivity}
            onClickComment={onClickComment}
            isShowCommentInput={isShowCommentInput}
          />
        );
      case ACTIVITY_ACTION.Sms:
        return (
          <SmsActivityItem
            item={item}
            actionIcon={actionIcon}
            createdOn={createdOn}
            isOpenThread={isOpenThread}
            onLookupClick={onLookupClick}
            onMoreMenuClick={handleClickOnMoreOptionActivity}
            isShowCommentInput={isShowCommentInput}
            onClickComment={onClickComment}
            selectedActivity={selectedActivity}
            isOpenMoreOptionMenu={isOpenMoreOptionMenu}
          />
        );
      case ACTIVITY_ACTION.Comment:
        return (
          <CommentActivityItem
            item={item}
            svgIconSize={svgIconSize}
            isOpenThread={isOpenThread}
            onLookupClick={onLookupClick}
            onMoreMenuClick={handleClickOnMoreOptionActivity}
            isOpenMoreOptionMenu={isOpenMoreOptionMenu}
            selectedActivity={selectedActivity}
            isShowCommentInput={isShowCommentInput}
            onClickComment={onClickComment}
          />
        );
      case ACTIVITY_ACTION.Meeting:
        return (
          <>
            <MeetingActivityItem
              item={item}
              svgIconSize={svgIconSize}
              itemDate={itemDate}
              isOpenThread={isOpenThread}
              onLookupClick={onLookupClick}
              onMoreMenuClick={handleClickOnMoreOptionActivity}
              onClickComment={onClickComment}
              selectedActivity={selectedActivity}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
              isShowCommentInput={isShowCommentInput}
            />
          </>
        );
      case ACTIVITY_ACTION.Tasks:
      case ACTIVITY_ACTION.Notes:
      case ACTIVITY_ACTION.Lunch:
      case ACTIVITY_ACTION.Activity:
        return (
          <NotesActivityItem
            item={item}
            createdOn={createdOn}
            actionIcon={actionIcon}
            isOpenThread={isOpenThread}
            onLookupClick={onLookupClick}
            onOpenFilePreview={onOpenFilePreview}
            onMoreMenuClick={handleClickOnMoreOptionActivity}
            isShowCommentInput={isShowCommentInput}
            onClickComment={onClickComment}
            selectedActivity={selectedActivity}
            isOpenMoreOptionMenu={isOpenMoreOptionMenu}
          />
        );
      case ACTIVITY_ACTION.File:
        return (
          <>
            <FileActivityItem
              item={item}
              createdOn={createdOn}
              actionIcon={actionIcon}
              isOpenThread={isOpenThread}
              onLookupClick={onLookupClick}
              onOpenFilePreview={onOpenFilePreview}
              onMoreMenuClick={handleClickOnMoreOptionActivity}
              isShowCommentInput={isShowCommentInput}
              onClickComment={onClickComment}
              selectedActivity={selectedActivity}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
            />
          </>
        );
      case ACTIVITY_ACTION.Email:
        return (
          <>
            <EmailActivityItem
              item={item}
              actionIcon={actionIcon}
              isOpenThread={isOpenThread}
              onLookupClick={onLookupClick}
              onMoreMenuClick={handleClickOnMoreOptionActivity}
              onOpenEmailDetailModal={onOpenEmailDetailModal}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
              selectedActivity={selectedActivity}
              isShowCommentInput={isShowCommentInput}
              onClickComment={onClickComment}
            />
          </>
        );
      case ACTIVITY_ACTION.Deal:
        return (
          <MainList key={item?.id}>
            <ActivityUpperBlock mobile={mobile} isOpenThread={isOpenThread}>
              <IconList>
                <IconWrapper bgColor='#00AEFF' mobile={mobile}>
                  <SVGIcon fill='var(--white-color)' size={svgIconSize} name='icon-deals' strokeWidth={1.8} />
                </IconWrapper>
              </IconList>
              <AllList>
                <RightList isOpenThread={isOpenThread}>
                  <UserWrapperBlock>
                    <UserText mobile={mobile} isOpenThread={isOpenThread}>
                      {item?.assignedUser?.name}
                    </UserText>
                    <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                      {' '}
                      added deal
                    </UserRecive>
                    <TimeText isOpenThread={isOpenThread} mobile={mobile} title={itemDate.tooltip}>
                      {itemDate.absolute}
                    </TimeText>
                  </UserWrapperBlock>
                </RightList>
              </AllList>
            </ActivityUpperBlock>
            <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
              <ContainView>
                <Captiontitle>{item?.title}</Captiontitle>
                <AmountView>
                  <Amount>Amount :</Amount>
                  <Value>{currencyWithDecimal(item?.lookup?.dealAmount)}</Value>
                  <DateText>Amount :</DateText>
                  <ToDay>{currencyWithDecimal(item?.lookup?.dealAmount)}</ToDay>
                </AmountView>
                {handleClickOnMoreOptionActivity && (
                  <CommentHoverItem
                    item={item}
                    isOpenMoreOptionMenu={isOpenMoreOptionMenu}
                    selectedActivity={selectedActivity}
                    onClickMoreMenu={handleClickOnMoreOptionActivity}
                    onClickComment={onClickComment}
                  />
                )}
              </ContainView>
            </ActivityBottomBlock>
          </MainList>
        );
      default:
        return null;
    }
  }, [
    actionIcon,
    createdOn,
    handleClickOnMoreOptionActivity,
    haveCallRecording,
    isOpenMoreOptionMenu,
    isOpenThread,
    isShowCommentInput,
    item,
    itemDate,
    mobile,
    onClickComment,
    onLookupClick,
    onOpenEmailDetailModal,
    onOpenFilePreview,
    selectedActivity,
    svgIconSize
  ]);

  return <>{result}</>;
};

export default ActivityItem;
