import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AccessGraphic } from '../../assets/svg/access_denied_graphics.svg';
import Button from '../../components/atom/Button/Button';
import { AccessContainer, AccessBlock, AccessHead, AcceessText } from './styles';

const AccessDenied = () => {
  const history = useHistory();
  return (
    <>
      <AccessContainer>
        <AccessBlock>
          <AccessGraphic />
          <AccessHead>Access Denied</AccessHead>
          <AcceessText>You do not have permission to access this resource</AcceessText>
          <Button
            onClick={() => {
              history.goBack();
            }}
            size='medium'
            title='Go Back'
            secondary
          />
        </AccessBlock>
      </AccessContainer>
    </>
  );
};

export default AccessDenied;
