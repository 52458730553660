import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set lookup loader
 */
export const setLookupLoader = createAction(Actions.SET_LOOKUP_LOADER);

/**
 * @desc Set lookup list
 */
export const setLookupList = createAction(Actions.SET_LOOKUP_LIST);

/**
 * @desc update lookup item
 */
export const updateLookupItem = createAction(Actions.UPDATE_LOOKUP_ITEM);

/**
 * @desc Set Segment list
 */
export const setSegmentList = createAction(Actions.SET_SEGMENT_LIST);

/**
 * @desc Set Segment list
 */
export const setManageSegmentList = createAction(Actions.SET_MANAGE_SEGMENT_LIST);

/**
 * @desc Set Lookup detail
 */
export const setLookupDetail = createAction(Actions.SET_LOOKUP_DETAIL);

/**
 * @desc Set Lookup Group Fields
 */
export const setLookupGroupFields = createAction(Actions.SET_LOOKUP_GROUP_FIELDS);

/**
 * @desc Set Lookup Validation Error
 */
export const setLookupValidationError = createAction(Actions.SET_LOOKUP_VALIDATION_ERROR);
/**
 * @desc Set Segment Filter
 */
export const setSegmentFilter = createAction(Actions.SET_SEGMENT_FILTER);
/**
 * @desc Update Segment Filter Item
 */
export const updateSegmentFilterItem = createAction(Actions.UPDATE_SEGMENT_FILTER_ITEM);
/**
 * @desc Add Segment Filter
 */
export const addSegmentFilterItem = createAction(Actions.ADD_SEGMENT_FILTER_ITEM);
/**
 * @desc Delete Segment Filter
 */
export const deleteSegmentFilterItem = createAction(Actions.DELETE_SEGMENT_FILTER_ITEM);
/**
 * @desc Update Segment Field
 */
export const updateSegmentField = createAction(Actions.UPDATE_SEGMENT_FIELD);
/**
 * @desc Clear Segment Filter
 */
export const clearSegmentFilter = createAction(Actions.CLEAR_SEGMENT_FILTER);
/**
 * @desc Set Lookup Tags
 */
export const setLookupTags = createAction(Actions.SET_LOOKUP_TAGS);
/**
 * @desc Remove Lookup Tags
 */
export const removeLookupTags = createAction(Actions.REMOVE_LOOKUP_TAG);
/**
 * @desc Add Lookup Tags
 */
export const addLookupTag = createAction(Actions.ADD_LOOKUP_TAG);
/**
 * @desc Set Lookup's company, contacts and deals
 */
export const setConnectedLookups = createAction(Actions.SET_LOOKUP_CONNECTED_LOOKUPS);

/**
 * @desc Set All Lookup
 */
export const setAllLookup = createAction(Actions.SET_ALL_LOOKUP);
/**
 * @desc set Lookup Fields
 */
export const setLookupFields = createAction(Actions.SET_LOOKUP_FIELDS);
/**
 * @desc Set Lookup Enrichment Fields
 */
export const setLookupEnrichmentFields = createAction(Actions.SET_LOOKUP_ENRICHMENT_FIELDS);
/**
 * @desc Update Lookup Enrichment Item
 */
export const updateLookupEnrichmentItem = createAction(Actions.UPDATE_LOOKUP_ENRICHMENT_ITEM);
/**
 * @desc Clear Lookup Data
 */
export const clearLookupData = () => (dispatch) => {
  dispatch(setLookupLoader(false));
  dispatch(setLookupList(null));
  dispatch(setSegmentList(null));
  dispatch(setLookupDetail(null));
  dispatch(setLookupGroupFields(null));
  dispatch(setLookupValidationError(null));
  dispatch(setConnectedLookups(null));
};
