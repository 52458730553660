import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import LocalDbService, { EMAILS_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton;
let organizationId;

export default class EmailCacheService extends LocalDbService {
  constructor(props) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = EMAILS_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (org) {
      if (!singleton || organizationId !== org.id) {
        organizationId = org.id;
        singleton = new EmailCacheService({ organizationId: org.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getEmails() {
    const result = await this.get();
    return result;
  }

  getMappedValues(list) {
    const dbItems = list?.map((x) => {
      return {
        id: x.id,
        accountId: x.emailAccountId,
        value: x
      };
    });
    return dbItems;
  }

  async setEmails(value) {
    if (!value) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async updateEmails(newList, archivedList) {
    const result = await this.syncList(newList, archivedList);
    return result;
  }

  async getLastUpdatedTime() {
    const result = await super.getLastUpdatedTime(UPDATE_ON_ID.EMAILS);
    return result;
  }

  async setLastUpdatedTime(value) {
    const result = await super.setLastUpdatedTime(UPDATE_ON_ID.EMAILS, value);
    return result;
  }

  async updateEmailItem(emailIds, key, value) {
    if (!emailIds || !key) return;
    const emails = [];
    for (let i = 0; i < emailIds?.length; i++) {
      const email = await this.getItem(emailIds[i]);
      if (email) {
        email[key] = value;
        emails.push(email);
      }
    }
    await this.addBulk(emails);
    return emails;
  }

  async deleteEmails(emailIds) {
    if (!emailIds) return;
    await this.removeBulk(emailIds);
  }
}
