import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import { setReplyMailItem, updateEmailStatus } from '../../services/emailService';
import { getDashboardDetail, trackInboxAnalyticActivity } from '../../services/dashboardService';
import SnackBar from '../../components/SnackBar/SnackBar';
import { LOOKUP_TABLE_TYPE, SEND_EMAIL_TYPE } from '../../constants/constant';
import Button from '../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../components/Menu';
import { isPastDate } from '../../helpers/common';
import Icon from '../../components/Icon/Icon';
import SendEmailModal from '../Email/SendEmailModal/SendEmailModal';
import { setEmailDetail } from '../../actions/emailActions';
import { hasUserAccessAndPermission } from '../../services/appService';
import NavigationText from '../../components/NavigationText/NavigationText';
import { Header4 } from '../../styles';
import {
  MainContain,
  Title,
  RowView,
  WelText,
  HeaderView,
  HeaderWrapper,
  WelView,
  ContainList,
  RowList,
  ListView,
  Leftcontain,
  LeftRow,
  ListTextView,
  RightContain,
  ViewIcon,
  CaptionList,
  SecondList,
  FirstText,
  SecondText,
  Iconbg,
  RowBox,
  UserEmailTwo,
  RowHeaderView,
  IconBack,
  IconWrapper,
  ModalStyle
} from './styles';

const InboxEmail = (props) => {
  const dashboard = useSelector((state) => state.dashboard);
  const organizationSelector = useSelector((state) => state.organization);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const emailSelector = useSelector((state) => state.email);
  const { emailAccounts } = emailSelector;

  const { list: lookupTableList } = lookupTableSelector;
  const { dashboardDetail } = dashboard;
  const dispatch = useDispatch();

  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const emailContainerEl = useRef(null);
  const [menuAnchorEl, setmenuAnchorEl] = useState(null);
  const [isOpenViewMenu, setViewMenu] = useState(false);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [sendMailType, setSendMailType] = useState();
  const [hasMoreValue, setHasMoreValue] = useState(true);
  const [page, setPage] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  const loadData = useCallback(() => {
    dispatch(getDashboardDetail(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const userAccess = useMemo(() => {
    const permissionSendMail = dispatch(hasUserAccessAndPermission('email', 'permissionSend'));
    return {
      permissionSendMail: permissionSendMail
    };
  }, [dispatch]);

  const fetchMoreListItems = (refresh = false) => {
    if (!hasMoreValue && !refresh) return;
    const limit = 30;
    const offset = page * limit;
    const data = dashboardDetail?.emails?.slice(0, offset);
    setListItems(data);
    setPage(page + 1);
    setHasMoreValue(dashboardDetail?.emails?.length !== data?.length);
  };

  useEffect(() => {
    if (!dashboardDetail?.emails) return;
    setPage(1);
    setHasMoreValue(true);
    fetchMoreListItems(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardDetail?.emails]);

  const closeViewModal = () => {
    setViewMenu(false);
  };

  const handleScroll = () => {
    if (Math.ceil(emailContainerEl?.current?.scrollTop) <= emailContainerEl?.current?.scrollHeight * 0.7) return;
    fetchMoreListItems();
  };

  const openSendEmailModal = useCallback(
    (item, type) => {
      trackInboxAnalyticActivity('email', 'reply');
      const account = emailAccounts?.find((x) => x.id === item?.emailAccountId);
      if (account) {
        item = {
          ...item,
          account
        };
      }
      dispatch(setEmailDetail(item));
      setIsOpenSendEmailModal(true);
      setSendMailType(type);
      if (type === SEND_EMAIL_TYPE.REPLY) {
        dispatch(setReplyMailItem(item));
      }
    },
    [dispatch, emailAccounts]
  );

  const closeSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(false);
    dispatch(setEmailDetail(null));
    setSendMailType();
  }, [dispatch]);

  const openViewModal = (e, item) => {
    e.stopPropagation();
    setmenuAnchorEl(e.currentTarget);
    setViewMenu(true);
    setSelectedItem(item);
  };

  const markAsReadEmail = async () => {
    trackInboxAnalyticActivity('email', 'mark as read');
    closeViewModal();
    const activityEmailId = selectedItem?.id;
    const obj = {
      value: false
    };
    await dispatch(updateEmailStatus(organization_id, [activityEmailId], obj));
  };

  const onClickMarkAsAllRead = useCallback(async () => {
    const allEmailId = dashboardDetail?.emails?.map((item) => {
      return (item = item?.id);
    });
    const obj = {
      value: false
    };
    await dispatch(updateEmailStatus(organization_id, allEmailId, obj));
  }, [dashboardDetail?.emails, organization_id, dispatch]);

  const emailCount = useMemo(() => {
    return dashboardDetail?.emails?.length;
  }, [dashboardDetail?.emails]);

  const getPath = useCallback(
    (lookup) => {
      const table = lookupTableList.find((x) => x.id === lookup?.tableId);
      if (table) {
        const type = LOOKUP_TABLE_TYPE[table?.type];
        const pathName = `/${type}/${table?.name}/view/${lookup?.id}/overview`;
        return pathName;
      }
    },
    [lookupTableList]
  );

  const onSend = useCallback(() => {
    loadData();
  }, [loadData]);

  const renderEmails = (item) => {
    const { lookup } = item || {};
    const isPast = isPastDate(item?.record?.dateTime);
    let url;
    if (lookup) {
      url = getPath(lookup);
    }
    return (
      <RowList key={item?.lookup?.id}>
        <ListView>
          <Leftcontain>
            <LeftRow>
              <IconWrapper bgColor={'#1CC7D0'}>
                <Iconbg name='icon-email' size='20' />
              </IconWrapper>
              <ListTextView>
                <UserEmailTwo>
                  <NavigationText
                    onClick={() => {
                      props.history.push(url);
                    }}>
                    {lookup?.name}
                  </NavigationText>
                  {lookup?.company && `@ ${lookup?.company?.name || lookup?.company}`}
                </UserEmailTwo>

                <CaptionList>
                  <NavigationText
                    textGroup={Header4}
                    textColor={'var(--dark-color);'}
                    customStyle={'margin: 3px 6px 0 0;'}
                    onClick={() => {
                      props.history.push(`/email/segments/inbox/view/${item?.id}`);
                    }}>
                    {item?.subject}
                    {item?.dateTime && ` @ ${moment(item?.dateTime).format(isPast ? 'DD MMM YYYY' : 'h: mm A')}`}
                  </NavigationText>
                </CaptionList>
              </ListTextView>
            </LeftRow>
          </Leftcontain>
          <RightContain>
            {item?.lookup?.email && userAccess?.permissionSendMail && (
              <Button
                iconName='icon-reply'
                iconColor='var(--gray-color)'
                fontSize='20px'
                onClick={() => {
                  openSendEmailModal(item, SEND_EMAIL_TYPE.REPLY);
                }}
                secondary
                title='Reply'></Button>
            )}
            <ViewIcon title={'More'}>
              <Icon name='icon-more' fontSize='24' color='var(--gray-color)' onClick={(e) => openViewModal(e, item)} />
            </ViewIcon>
          </RightContain>
        </ListView>
        <SecondList
          onClick={() => {
            props.history.push(`/email/segments/inbox/view/${item?.id}`);
          }}>
          <FirstText>{`Re: ${item?.lastMsg?.subject}`}</FirstText>
          <SecondText>{item?.snippet}</SecondText>
        </SecondList>
      </RowList>
    );
  };
  return (
    <>
      <MainContain>
        <WelView onScroll={handleScroll} ref={emailContainerEl}>
          <ContainList>
            <RowBox>
              <HeaderWrapper>
                <HeaderView>
                  <RowView>
                    <IconBack>
                      <Icon
                        title={'Back'}
                        name='feather-chevron-left'
                        color='var(--primary-color)'
                        fontSize='20'
                        onClick={props.history.goBack}
                      />
                    </IconBack>
                    <Title>Email</Title>
                  </RowView>
                  <RowHeaderView>
                    <WelText>{`${emailCount} unread email found in your mailbox`}</WelText>
                    <Button
                      title={'Mark As All Read'}
                      primary={true}
                      onClick={() => {
                        onClickMarkAsAllRead();
                      }}
                    />
                  </RowHeaderView>
                </HeaderView>
              </HeaderWrapper>

              {listItems?.map((item, index) => {
                return <div key={index}>{renderEmails(item)}</div>;
              })}
            </RowBox>
          </ContainList>
        </WelView>
      </MainContain>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenViewMenu}
        onClose={closeViewModal}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width={189}
        marginTop={38}>
        <MenuItem
          iconName='icon-checked-round'
          color='var(--gray-icon-color)'
          fontSize='18'
          text='Mark As Read'
          onClick={markAsReadEmail}
        />
      </Menu>
      <Modal
        open={isOpenSendEmailModal}
        onClose={closeSendEmailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        style={ModalStyle}>
        <SendEmailModal handleCloseModal={closeSendEmailModal} onSend={onSend} type={sendMailType} />
      </Modal>
      <SnackBar />
    </>
  );
};

export default InboxEmail;
