import styled, { css } from 'styled-components';
import Icon from '../../../../components/Icon/Icon';
import { Header3, Header4, Header5, Header6, mobileHeader1, Text1, Text2 } from '../../../../styles';
// import play from '../../../../assets/images/svg/play.svg';
// import pause from '../../../../assets/images/svg/pause.svg';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import Button from '../../../../components/atom/Button/Button';
const ManeWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const RightWrapper = styled.div`
  width: 100%;
  margin-left: 215px;
`;
const TwoList = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
`;
const LeftContain = styled.div`
  max-width: 320px;
  min-width: 320px;
  padding: 16px 20px;
  height: 100%;
  overflow: auto;
`;
const ProfileView = styled.div`
  display: flex;
  align-items: center;
`;
const ProfileBox = styled.div`
  width: 40px;
  height: 40px;
  background: var(--popup-color-hover);
  border: 1px solid var(--grey-border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NameList = styled.div`
  margin-left: 14px;
`;
const TextA = styled.h2`
  margin: 0;
  font-style: normal;
  ${Header3};
  color: var(--gray-color);
`;
const UserName = styled.h3`
  margin: 0;
  font-style: normal;
  ${Header3};
  color: var(--dark-color);
`;
const UserCity = styled.p`
  margin: 0;
  ${Text2};
  color: var(--gray-color);
`;
const BtnView = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const IconView = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  width: 33px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
`;
const FirstConatin = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 0 12px 0 15px;
  margin-top: 23px;
`;
const FirstView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 9px 0;
`;
const SecondView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 9px 0;
  :last-child {
    border-bottom: 0;
  }
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
  margin-right: 48px;
`;
const BoxText = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin-left: 8px;
`;
const LastView = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Lasttext = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
`;
const SecondContain = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-top: 21px;
`;
const ALasttext = styled.a`
  font-style: normal;
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
`;
const TitleView = styled.div`
  padding: 9px 12px 9px 14px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const TitleView2 = styled.div`
  padding: 9px 12px 9px 14px;
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NameUser = styled.span`
  ${Text2};
  color: var(--primary-color);
`;
const UserAmount = styled.p`
  ${Header4};
  color: var(--gray-color);
  margin: 0;
`;
const BoxFirst = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const FirstLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ContainDeal = styled.div`
  width: 100%;
`;
const TagView = styled.div``;
const ValueBox = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 12px 15px;
  margin: 13px 0;
`;
const LastViewOne = styled.div`
  width: 100%;
`;
const FirstTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 12px 9px 14px;
`;
const TagList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 13px 12px 12px 14px;
  margin: 0 -3px;
`;
const TagBox = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 4px 8px;
  margin: 3px;
  cursor: pointer;
  position: relative;
  display: flex;
  i {
    margin: 2px 0;
  }
`;
const IconDelete = styled.div`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :hover {
    background: var(--linear-gradient-delete);
    right: 0;
    top: 1px;
    bottom: 0;
    text-align: right;
    padding-right: 2px;
    opacity: 1;
    z-index: 1;
  }
`;
const TagText = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
`;
const TextDetail = styled.h3`
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  margin: 0 0 0 8px;
`;
const UlList = styled.div`
  padding: 0px 14px 0 14px;
  position: relative;
`;
const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const IconA = styled.a`
  background: #ffffff;
  border: 1px solid #e4e4e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -22px;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
  cursor: pointer;
`;
const Li = styled.li`
  padding: 11px 0 0 0;
  display: flex;
  align-items: center;
  position: relative;
  :hover {
    span {
      color: var(--dark-color);
    }
    p {
      color: var(--dark-color);
    }
  }
  :hover ${IconA} {
    right: -18px;
    opacity: 1;
    z-index: 1;
  }
`;
const LSpan = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  white-space: nowrap;
`;
const RText = styled.p`
  margin: 0 0 0 14px;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  width: 79%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const LSpan2 = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: var(--gray-color);
  cursor: pointer;
  margin-bottom: 11px;
`;
//right
const RightContain = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          overflow: unset;
        `
      : css`
          overflow: auto;
        `}
  &::before {
    content: '';
    background-color: var(--gray-border-color);
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
    ${(props) =>
      props.Empty &&
      props.mobile &&
      css`
        display: none;
      `}
    ${(props) =>
      props.mobile
        ? css`
            left: 31px;
            top: 73px;
          `
        : css`
            left: 27px;
            top: 0;
          `}
  }
  ${(props) =>
    props.mobile
      ? css`
          padding: 0px;
          ::-webkit-scrollbar {
            display: none;
          }
        `
      : css`
          padding: 0 20px 0 14px;
        `}
`;
const RightContainWrapper = styled.div`
  position: relative;
  &::before {
    content: '';
    background-color: var(--gray-border-color);
    height: 100%;
    position: absolute;
    width: 1px;
    ${(props) =>
      props.mobile
        ? css`
            left: 31px;
            top: 73px;
          `
        : css`
            left: 13px;
            top: 0;
          `}
  }
`;
const HeaderView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px 0 0;
          margin-left: 0;
          position: relative;
        `
      : css`
          padding: 20px 0 0 0;
          margin-left: 17px;
        `}
`;
const MenuList = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      width: 92%;
    `}
`;
const Ul2 = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  ${(props) =>
    props.mobile &&
    css`
      ::-webkit-scrollbar {
        display: none;
      }
      overflow-x: auto;
      max-width: 315px;
    `}
`;
const Li2 = styled.li`
  padding: 0;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 0 0 30px;
          :first-child {
            margin: 0 0 0 16px;
          }
          :last-child {
            margin-right: 70px;
          }
        `
      : css`
          margin: 0 0 0 24px;
        `}
`;
const Atag = styled.a`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  &:active,
  &.active {
    color: var(--dark-color);
  }
`;
const AddIcon = styled.div`
  width: 28px;
  height: 28px;
  background: var(--primary-color);
  border: 1px solid var(--white-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 3;
`;
const UpdateView = styled.div`
  background: #ffffff;
  border: 1px solid #e4e4e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: inline-block;
  padding: 6px 8px;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          margin: 12px 0 0 0;
        `
      : css`
          margin: 24px 15px 0 0;
        `}
`;
const TitleText = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: #333333;
`;
const MainList = styled.div`
  position: relative;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;
const ActivityWrapper = styled.div`
  padding-bottom: 30px;
`;
const IconList = styled.div`
  svg {
    display: inline-block;
  }
`;
const Iconbg = styled(Icon).attrs((props) => ({
  padDing: 5,
  borderRadius: 50,
  color: 'var(--white-color)'
}))``;
const AllList = styled.div`
  margin-left: 10px;
  width: 100%;
`;
const RightList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${(props) =>
    props.isOpenThread &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;
const UserText = styled.h3`
  margin: 0 4px 0 0;
  font-style: normal;
  color: var(--dark-color);
  ${(props) =>
    props.mobile
      ? css`
          ${mobileHeader1}
        `
      : css`
          ${Text1};
        `}
  ${(props) =>
    props.isOpenThread &&
    css`
      ${Text2}
    `}
`;
const UserRecive = styled.span`
  font-style: normal;
  color: var(--gray-color);
  margin: 0 4px 0 0;
  display: inline;
  ${(props) =>
    props.mobile
      ? css`
          ${mobileHeader1}
        `
      : css`
          ${Text1};
        `}
  ${(props) =>
    props.isOpenThread &&
    css`
      ${Text2}
    `}
`;
const TimeText = styled.p`
  margin: 0;
  font-style: normal;
  color: var(--gray-color);
  display: inline;
  ${(props) =>
    props.Comment &&
    css`
      margin-top: 6px;
    `}
  ${(props) =>
    props.mobile
      ? css`
          ${mobileHeader1}
        `
      : css`
          ${Text1};
        `}
        ${(props) =>
    props.isOpenThread &&
    css`
      ${Text2}
    `}
`;
const ContainView = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 11px 15px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  :hover {
    .commentbox {
      display: flex;
    }
  }
  ${(props) =>
    props.notes &&
    css`
      background: var(--cornsilk-color);
      border: 1px solid var(--yellow-lite-border);
    `}
  ${(props) =>
    props.audio &&
    css`
      display: block;
    `}
    ${(props) =>
    props.meeting &&
    css`
      display: block;
    `}
`;
const ContainViewTwo = styled.div`
  background: var(--yellow-lite-color);
  border: 1px solid #f2c94c;
  border-radius: 6px;
  padding: 13px 14px;
`;
const Captiontitle = styled.h5`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  word-break: break-all;
  span {
    color: var(--primary-color);
  }
`;
const Time = styled.span``;
const Captionmess = styled.div`
  font-style: normal;
  ${Text2};
  display: block;
  width: 100%;
  color: var(--gray-color);
  p {
    color: var(--charcoal-color);
    margin: 0;
  }
  strong {
    font-weight: 500;
  }
  ul {
    list-style-type: disc;
    padding: 5px 0px 0px 20px;
  }
  ol {
    list-style-type: decimal;
    padding: 5px 0px 0px 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${Text2}
    color: var(--gray-color);
  }
  p {
    em {
      font-style: italic;
    }
    strong {
      font-weight: 500;
    }
  }
  a {
    ${Text2}
    color: var(--primary-color);
  }
  ${(props) =>
    props.isTitle &&
    css`
      margin: 6px 0 0 0;
    `}
`;
const InputView = styled.div`
  display: flex;
  align-items: center;
  input {
    display: block;
    margin: 0 8px 0 0;
  }
`;
const InputCheck = styled.input``;
const CaptionmessTwo = styled.p`
  margin: 0;
  font-style: normal;
  ${Text2};
  color: var(--gray-color);
`;
const AmountView = styled.div`
  display: flex;
  align-items: center;
`;
const Amount = styled.h4`
  font-style: normal;
  ${Header6};
  text-transform: uppercase;
  color: #868995;
  margin: 0 10px 0 0;
`;
const Value = styled.span`
  font-style: normal;
  ${Text1};
  color: #333333;
`;
const DateText = styled.h4`
  font-style: normal;
  ${Header6};
  text-transform: uppercase;
  color: #868995;
  margin: 0 10px 0 12px;
`;
const ToDay = styled.span`
  font-style: normal;
  ${Text1};
  color: #333333;
`;
const InputList = styled.div`
  position: absolute;
  top: 1px;
  right: 0;
  left: 0;
  width: 110%;
  z-index: 9;
  &::before {
    content: '';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 10%;
    height: 35px;
    z-index: 1;
  }
  &::after {
    content: '';
    background: linear-gradient(to bottom, #fff 0, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 100%;
    left: 0;
    right: 10%;
    height: 35px;
    z-index: 1;
  }
`;
const SpanLabel = styled.span`
  ${Text2};
  position: absolute;
  padding: 0 5px;
  top: -8px;
  left: 8px;
  font-size: 12px;
  z-index: 2;
  background: #ffffff;
`;
const FormTag = styled.form`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e4e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
`;
const InputEdit = styled.input`
  border: 0;
  outline: 0;
  height: 25px;
  padding-left: 12px;
  flex: 1 0 0%;
`;
const CheckIcon = styled.div`
  border-left: 1px solid #e4e4e5;
  padding: 8px;
  cursor: pointer;
`;
const CloseIcon = styled.div`
  border-left: 1px solid #e4e4e5;
  padding: 8px;
  cursor: pointer;
`;
const ProfilIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  ${(props) =>
    !props.lastIndex &&
    css`
      margin-right: -5px;
    `}
  ${(props) =>
    props.lastIndex &&
    css`
      margin-right: 2px;
    `}
`;
const ProfileName = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-right: 12px;
  margin-bottom: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const PopBtn = styled.div`
  margin: 0 auto;
`;
const BtnText = styled.span`
  color: var(--primary-color);
`;
//add detail popup
const AddDetail = styled.div`
  position: absolute;
  padding: 15px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
`;
const DetailList = styled.div`
  background-color: #fff;
  border: 1px solid rgba(46, 62, 70, 0.2);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
  border-radius: 3px;
  position: relative;
`;
const Selecter = styled.div`
  .css-1eze3c7-control {
    border: none;
    border-width: 0;
    border-style: none;
  }
  .css-1gzxue3-control {
    border: none;
    border-width: 0;
    border-style: none;
  }
  .css-1gzxue3-control {
    :hover {
      border: none;
      border-color: transparent;
    }
    :focus {
      border: none;
      border-color: transparent;
    }
  }
`;
const InputView2 = styled.div`
  border-top: 1px solid var(--gray-border-color);
  padding: 10px 10px 15px 10px;
  display: flex;
  align-items: center;
  label {
    margin: 0;
  }
  input {
    border: 0;
    width: 100%;
    background-color: var(--white-color);
    :focus {
      border: 0;
    }
  }
`;
const LastRow = styled.div`
  display: flex;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0 1px 2px var(--box-shadow-secondary);
  border-radius: 3px;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: -4px;
  transform: translateX(-50%);
  z-index: 0;
`;
const LIcon = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  border-left: 1px solid #e4e4e5;
  cursor: pointer;
  :first-child {
    border-left: none;
  }
`;
const InputBlock = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const InputSpan = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const InputWrapper = styled.div`
  margin: 0 10px 5px 10px;
`;
const ActivitiesWrapper = styled.div``;
const FileIconWrapper = styled.div`
  width: 39px;
  height: 32px;
  padding-right: 6px;
  border-right: 1px solid var(--gray-border-color);
`;
const FileIcons = styled.div`
  height: 100%;
  display: inline-block;
  ${(props) =>
    props.audio &&
    css`
      display: flex;
      align-items: center;
      padding-right: 12px;
      border-right: none;
    `}
  svg {
    width: 32px;
    height: 32px;
  }
`;
const FileMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.audio &&
    css`
      width: auto;
    `}
  ${(props) =>
    props.isPriview &&
    css`
      cursor: pointer;
    `}
`;
const FileText = styled.div`
  width: 100%;
  &.fileBorder {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const CallSummaryItem = styled.div`
  padding-top: 11px;
  margin-top: 11px;
  border-top: 1px solid var(--gray-border-color);
`;
const AudioText = styled.span`
  color: var(--gray-color);
  p {
    ${Text1};
  }
`;
const CallDurationText = styled.span`
  ${Text1}
  color: var(--dark-color);
  white-space: nowrap;
  display: block;
  ${(props) =>
    props.mobile
      ? css`
          margin-right: 10px;
        `
      : css`
          margin-right: 16px;
        `}
`;
const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  span {
    ${Text1};
    color: var(--dark-color);
  }

  .rhap_container {
    &.rhap_loop-off {
      flex: 1 0 0;
    }

    .rhap_main {
      flex: 1 0 0;
      display: flex;
      align-items: center;

      .rhap_progress-section {
        display: flex;
        flex: 1 0 0;
        align-items: center;
        padding: 3px 2px 3px 3px;
        border: 1px solid var(--gray-border-color);
        border-radius: 24px;

        .rhap_progress-bar {
          height: 4px;
          background-color: var(--gray-border-color);
          border-radius: 10px;

          .rhap_progress-filled {
            background-color: var(--primary-color);
            border-radius: 10px;
            height: 4px;
          }
        }

        .rhap_time {
          ${Text1}
          color: var(--gray-color);
          width: 40px;
          ${(props) =>
            props.mobile
              ? css`
                  margin-left: 6px;
                `
              : css`
                  margin-left: 12px;
                `}
        }

        .rhap_main-controls {
          .rhap_play-pause-button {
            width: 24px;
            height: 24px;
            padding: 0;
            border-radius: 70px;
            background-color: var(--primary-color);
            cursor: pointer;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            .feather-pause {
              margin-left: 0;
            }
          }
        }
        .rhap_volume-controls {
          .rhap_volume-container {
            flex: 1 0 0;

            .rhap_volume-button {
              padding: 0;

              background-color: transparent;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              ${(props) =>
                props.mobile
                  ? css`
                      margin: 0px 6px 0px 6px;
                    `
                  : css`
                      margin: 0px 6px 0px 12px;
                    `}
              svg {
                height: 18px;
                width: 18px;
                color: var(--gray-color);
              }
            }
          }
        }
        .rhap_progress-container {
          flex: 1 0 0;

          ${(props) =>
            props.mobile
              ? css`
                  margin-left: 6px;
                `
              : css`
                  margin-left: 12px;
                `}
        }
      }
      .rhap_controls-section {
        display: flex;
        align-items: center;
      }
    }
  }
`;
const AudioMain = styled.div`
  width: 100%;
`;
const DownloadLink = styled.a`
  border-left: 1px solid var(--gray-border-color);
  padding-left: 10px;
  text-decoration: none;
  cursor: pointer;
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const DateWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--cultured-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 44px;
`;
const Monthly = styled.span`
  display: inline-block;
  color: var(--callreject-icon);
  ${Header5}
`;
const Date = styled.span`
  display: inline-block;
  color: var(--dark-color);
  ${Header4}
  line-height: 19px;
`;
const IconWrapper = styled.div`
  flex: 0 0 auto;
  border-radius: 100%;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.mobile
      ? css`
          width: 32px;
          height: 32px;
        `
      : css`
          width: 28px;
          height: 28px;
        `}
`;

const MoreIconBlock = styled.div`
  margin-left: 6px;
  cursor: pointer;
  svg {
    display: block;
  }
`;

const SVGIconBg = styled(SVGIcon)`
  width: 20px !important;
  height: 20px !important;
`;

const CommentInputBlock = styled.div`
  margin-left: 10px;
  width: 100%;
  position: relative;
  textarea {
    padding: 10px 74px 10px 10px;
    height: auto;
    width: 100%;
    border-radius: 6px;
    background-color: var(--white-color);
    border: 1px solid var(--gray-border-color);
    outline: none;
    font-family: var(--primary-font-stack);
    font-weight: 500;
    :focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 3px #afcdff, 1px 1px 2px 0 rgb(0 0 0 / 5%);
    }
  }
`;

const IconBtn = styled(Button)`
  margin-right: 8px;
  padding: 3px;
  background: none;
  box-shadow: none;
  svg {
    margin-right: 0;
  }
  :hover {
    background: var(--popup-color-hover);
  }
`;

const CommentBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 6px;
`;
const HeaderLinearBg = styled.div`
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  width: 100px;
  background: rgb(243, 242, 240);
  background: linear-gradient(90deg, rgba(243, 242, 240, 0) 0%, rgba(243, 242, 240, 1) 50%);
`;
const ActivityUpperBlock = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 8px;
        `
      : css`
          margin-bottom: 4px;
        `}
  ${(props) =>
    props.isOpenThread &&
    css`
      margin-bottom: 12px;
    `}
`;
const ActivityBottomBlock = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 0px;
        `
      : css`
          margin-left: 38px;
        `}
  ${(props) =>
    props.isOpenThread &&
    css`
      margin-left: 0;
    `}
`;
const ActivityMainWrap = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      padding: 0 16px;
    `}
`;

const ThraedInner = styled.div`
  cursor: pointer;
`;
const UserWrapperBlock = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
export {
  Time,
  DownloadLink,
  ManeWrapper,
  RightWrapper,
  TwoList,
  LeftContain,
  RightContain,
  ProfileView,
  UserName,
  UserCity,
  BtnView,
  IconView,
  FirstConatin,
  FirstView,
  BoxText,
  Lasttext,
  LastView,
  FirstList,
  SecondView,
  SecondContain,
  ALasttext,
  TitleView,
  TitleView2,
  TagView,
  FirstTag,
  TagList,
  TagBox,
  TagText,
  TextDetail,
  ThraedInner,
  UlList,
  Ul,
  Li,
  LSpan,
  RText,
  LSpan2,
  HeaderView,
  MenuList,
  Ul2,
  Li2,
  Atag,
  AddIcon,
  UpdateView,
  TitleText,
  IconA,
  MainList,
  Iconbg,
  AllList,
  RightList,
  UserText,
  UserRecive,
  TimeText,
  IconList,
  ContainView,
  Captiontitle,
  Captionmess,
  ContainViewTwo,
  InputCheck,
  InputView,
  CaptionmessTwo,
  Amount,
  Value,
  DateText,
  ToDay,
  AmountView,
  ProfileBox,
  TextA,
  NameList,
  LastViewOne,
  IconDelete,
  InputList,
  SpanLabel,
  FormTag,
  InputEdit,
  CheckIcon,
  CloseIcon,
  ProfileName,
  ProfilIcon,
  InputWrapperModal,
  BoxFirst,
  ValueBox,
  NameUser,
  UserAmount,
  PopBtn,
  BtnText,
  AddDetail,
  DetailList,
  Selecter,
  InputView2,
  LastRow,
  LIcon,
  FirstLine,
  ContainDeal,
  InputBlock,
  InputSpan,
  RightContainWrapper,
  ActivityWrapper,
  InputWrapper,
  ActivitiesWrapper,
  FileIcons,
  FileMain,
  FileText,
  CallSummaryItem,
  AudioText,
  AudioWrapper,
  AudioMain,
  FileIconWrapper,
  MenuItemWrapper,
  CallDurationText,
  DateWrapper,
  Monthly,
  Date,
  IconWrapper,
  SVGIconBg,
  CommentInputBlock,
  IconBtn,
  CommentBtnWrapper,
  MoreIconBlock,
  HeaderLinearBg,
  ActivityUpperBlock,
  ActivityBottomBlock,
  ActivityMainWrap,
  UserWrapperBlock
};
