import React, { useCallback } from 'react';
import { DatePicker } from '../../../components/DateTime';
import DropDown from '../../../components/DropDown/DropDown';
import Input from '../../../components/Input/Input';
import { SEGMENT_FIELD_TYPE } from '../../../constants/constant';

const SegmentInputField = ({ type, item, index, fieldName, resError, onChangeValues }) => {
  const onChangeInput = useCallback(
    (key, value) => {
      if (key === 'value_Date' && !isNaN(value.getTime())) {
        value = value.toISOString();
      }
      onChangeValues(key, value);
    },
    [onChangeValues]
  );

  const inputField = () => {
    switch (type) {
      case SEGMENT_FIELD_TYPE.TEXT:
        return (
          <>
            <Input
              isPrimarySmall={true}
              type={'text'}
              id={item[fieldName]?.id}
              name={item[fieldName]?.label}
              value={item?.value ? item?.value : ''}
              onChange={(e) => onChangeInput('value', e.target.value)}
            />
          </>
        );
      case SEGMENT_FIELD_TYPE.NUMBER:
        return (
          <>
            <Input
              isPrimarySmall={true}
              type='number'
              id={item[fieldName]?.id}
              name={item[fieldName]?.label}
              value={item?.value_Number}
              onChange={(e) => onChangeInput('value_Number', e.target.value)}
            />
          </>
        );
      case SEGMENT_FIELD_TYPE.DATE_AND_TIME:
        return (
          <>
            <DatePicker
              autoOk={true}
              value={item?.value_Date ? new Date(item?.value_Date) : new Date()}
              className='DatePickerWrapper'
              onChange={(date) => onChangeInput('value_Date', date)}
            />
          </>
        );

      case SEGMENT_FIELD_TYPE.OPTION:
        return (
          <>
            <DropDown
              options={item[fieldName]?.fieldOption || []}
              labelField={'value'}
              valueField={'id'}
              value={item?.value}
              onChange={(e) => onChangeInput('value', e.value)}
              placeholder={''}
            />
          </>
        );
      case SEGMENT_FIELD_TYPE.BOOLEAN:
        return (
          <>
            <input
              type='checkbox'
              checked={item.value_Bool}
              onChange={() => onChangeInput('value_Bool', !item.value_Bool)}
              id={item[fieldName]?.id}
              name={item[fieldName]?.label}
            />
            <span className='checkmark'></span>
          </>
        );

      default:
        return null;
    }
  };
  return <>{inputField()}</>;
};
export default SegmentInputField;
