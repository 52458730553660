import { useMemo } from 'react';

import useMobileDevice from '../../../../../hooks/useMobileDevice';
import Avtar from '../../../../../components/Avtar/Avtar';
import CommentHoverItem from '../Comments/CommentHoverItem';
import RenderComments from '../Comments/RenderComments';
import { getGravatarURL, urlify } from '../../../../../helpers/common';

import {
  MainList,
  ActivityUpperBlock,
  IconList,
  AllList,
  RightList,
  UserText,
  UserRecive,
  ActivityBottomBlock,
  ContainView,
  Captiontitle,
  Captionmess,
  FileMain,
  FileText,
  TimeText,
  ThraedInner,
  UserWrapperBlock
} from '../styles';
const SmsActivityItem = ({
  item,
  actionIcon,
  createdOn,
  isOpenThread,
  onLookupClick,
  onMoreMenuClick,
  isShowCommentInput,
  onClickComment,
  selectedActivity,
  isOpenMoreOptionMenu
}) => {
  const mobile = useMobileDevice();
  let actionPrifix = '';
  if (item?.title === 'SMS Sent') actionPrifix = 'sent a';
  else actionPrifix = 'received a';
  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);

  const htmlContent = useMemo(() => {
    if (item?.description) {
      return urlify(item?.description);
    }
    return item?.description;
  }, [item?.description]);

  return (
    <MainList key={item?.id}>
      <ActivityUpperBlock mobile={mobile} isOpenThread={isOpenThread}>
        {isOpenThread ? (
          item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
        ) : (
          <IconList>{actionIcon}</IconList>
        )}

        <AllList>
          <RightList mobile={mobile} isOpenThread={isOpenThread}>
            {isOpenThread && item?.lookup && (
              <ThraedInner
                onClick={() => {
                  onLookupClick(item?.lookup);
                }}>
                <UserText>{item?.lookup?.name}</UserText>
              </ThraedInner>
            )}
            <UserWrapperBlock mobile={mobile}>
              <UserText isOpenThread={isOpenThread} mobile={mobile}>
                {item?.assignedUser?.name}
              </UserText>
              <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                {actionPrifix} {String(item?.actionName || '').toLowerCase()}
              </UserRecive>
              <TimeText isOpenThread={isOpenThread} mobile={mobile} title={createdOn?.tooltip}>
                {createdOn?.absolute}
              </TimeText>
            </UserWrapperBlock>
          </RightList>
        </AllList>
      </ActivityUpperBlock>
      <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
        <ContainView>
          <FileMain>
            <FileText>
              <Captiontitle>{item?.title}</Captiontitle>
              {htmlContent && (
                <Captionmess
                  className='quill_format'
                  isTitle={item?.title ? true : false}
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
              )}
            </FileText>
          </FileMain>
          {onMoreMenuClick && (
            <CommentHoverItem
              item={item}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
              selectedActivity={selectedActivity}
              onClickMoreMenu={onMoreMenuClick}
              onClickComment={onClickComment}
            />
          )}
        </ContainView>
        <RenderComments item={item} isShowCommentInput={isShowCommentInput} selectedActivity={selectedActivity} />
      </ActivityBottomBlock>
    </MainList>
  );
};

export default SmsActivityItem;
