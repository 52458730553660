import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Switch, Route } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import Modal from '../../components/Modal';
import Input from '../../components/Input/Input';
import { deleteEmailItem, getEmailList, getSearchResult, updateStarEmailStatus } from '../../services/emailService';
import { isPastDate } from '../../helpers/common';
import { setEmailList } from '../../actions/emailActions';
import SnackBar from '../../components/SnackBar/SnackBar';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { trackEmailNavigateAnalyticActivity } from '../../components/EmailNavbar/EmailNavbar';
import { DATE_FORMAT, SEND_EMAIL_TYPE, TIME_FORMAT, TOOLTIP_DATE_FORMAT } from '../../constants/constant';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import {
  TwoList,
  LeftContain,
  ContainList,
  TopView,
  SearchRow,
  RightView,
  BottomView,
  InBoxDetail,
  FirstRow,
  LUser,
  RTimeView,
  RTime,
  SecondRow,
  CaptionText,
  RBox,
  NumText,
  ThirdRow,
  MessageText,
  FormView,
  RightContain,
  CloseIcon,
  ModalStyle,
  EmailEditIcon
} from './styles';
import EmailDetails from './EmailDetails';
import EmailEmptyStateModal from './EmailEmptyStateModal';
import EmailEmptyState from './EmailEmptyState';
import SendEmailModal from './SendEmailModal/SendEmailModal';

const Email = (props) => {
  const emailContainerEl = useRef(null);
  const emailLabel = props.match.params.id;
  const threadId = props.match.params.threadId;
  const location = useLocation();
  const query = location.search;
  const accountId = new URLSearchParams(query).get('accountId');
  const organizationSelector = useSelector((state) => state.organization);
  const emailSelector = useSelector((state) => state.email);
  const dispatch = useDispatch();
  const { organization } = organizationSelector;
  const { list: emailList, emailAccounts } = emailSelector;
  const { id: organization_id } = organization;

  const [hasMoreValue, setHasMoreValue] = useState(true);
  const [isOpenEmailEmptyModal, setIsOpenEmailEmptyModal] = useState(false);
  const [isShowEmailDetail, setIsShowEmailDetail] = useState(false);

  const [page, setPage] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);

  const [sendMailType, setSendMailType] = useState();
  const mobile = useMobileDevice();

  const loadData = useCallback(() => {
    const label = emailLabel || 'inbox';
    if (!emailLabel) trackEmailNavigateAnalyticActivity('inbox', 'all');
    if (organization_id) {
      setPage(1);
      setSearchText('');
      dispatch(getEmailList(organization_id, label, accountId));
    }
  }, [accountId, dispatch, emailLabel, organization_id]);

  useEffect(() => {
    loadData();
  }, [organization_id, accountId, emailLabel, loadData]);

  useEffect(() => {
    if (!mobile) return;
    const splitTokens = location?.pathname?.split('/');
    if (splitTokens?.length >= 6) {
      setTimeout(() => {
        setIsShowEmailDetail(true);
      }, 100);
    } else setIsShowEmailDetail(false);
  }, [mobile, location?.pathname]);

  const fetchMoreListItems = (refresh = false) => {
    if (!hasMoreValue && !refresh) return;
    const limit = 30;
    const offset = page * limit;
    const data = emailList?.slice(0, offset);
    setListItems(data);
    setPage(page + 1);
    setHasMoreValue(emailList?.length !== data?.length);
  };

  useEffect(() => {
    setPage(1);
    setHasMoreValue(true);
    fetchMoreListItems(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailList]);

  const onEmailClick = useCallback(
    (item) => {
      const label = emailLabel || 'inbox';
      if (item)
        props.history.push(`/email/segments/${label}/view/${item?.id}${accountId ? '?accountId=' + accountId : ''}`);
    },
    [accountId, props.history, emailLabel]
  );
  const onCloseEmailDetail = useCallback(() => {
    setIsShowEmailDetail(false);
  }, []);

  const handleScroll = () => {
    if (Math.ceil(emailContainerEl?.current?.scrollTop) <= emailContainerEl?.current?.scrollHeight * 0.8) return;
    fetchMoreListItems();
  };

  const getEmailUsers = (participants) => {
    let displayName = [];
    participants.forEach((item) => {
      let name = '';
      if (item?.name) {
        name = item?.name;
      } else {
        name = item?.email;
      }
      displayName.push(name);
    });
    let users = displayName.join(', ');
    return users;
  };

  const setSearchResult = async (query, label) => {
    if (query) {
      const result = await getSearchResult(query);
      try {
        trackAnalyticActivity('email: search', { 'search text': query.toLowerCase() });
      } catch (e) {
        console.log('track Email Search Error', e);
      }
      dispatch(setEmailList(result));
    } else {
      dispatch(getEmailList(organization_id, label, accountId));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchData = useCallback(debounce(setSearchResult, 300), []);

  const searchEmail = useCallback(
    (value) => {
      const label = emailLabel || 'inbox';
      setSearchText(value);
      debounceSetSearchData(value, label);
    },
    [debounceSetSearchData, emailLabel]
  );

  const trackDeleteThreadAnalyticActivity = useCallback(() => {
    try {
      trackAnalyticActivity('email: email deleted');
    } catch (e) {
      console.log('track email: email deleted Error', e);
    }
  }, []);
  const onDeleteEmail = async (thread_id) => {
    if (thread_id && organization_id) {
      const label = emailLabel || 'inbox';
      const result = await dispatch(deleteEmailItem(organization_id, thread_id));
      if (result) {
        trackDeleteThreadAnalyticActivity();
        props.history.push(`/email/segments/${label}${accountId ? '?accountId=' + accountId : ''}`);
      }
    }
  };

  const onClickStar = useCallback(
    async (action) => {
      const label = emailLabel || 'inbox';
      const payload = {
        value: action
      };
      const result = await dispatch(updateStarEmailStatus(organization_id, threadId, payload));
      if (result) {
        props.history.push(`/email/segments/${label}${accountId ? '?accountId=' + accountId : ''}`);
      }
    },
    [accountId, dispatch, emailLabel, organization_id, props.history, threadId]
  );

  const closeEmailEmptyModal = useCallback(() => {
    setIsOpenEmailEmptyModal(false);
  }, []);

  const openEmailEmptyModal = useCallback(() => {
    setIsOpenEmailEmptyModal(true);
  }, []);

  useEffect(() => {
    if (emailAccounts?.length === 0) {
      openEmailEmptyModal();
    }
  }, [emailAccounts, openEmailEmptyModal]);

  const openSendEmailModal = useCallback((type) => {
    setIsOpenSendEmailModal(true);
    setSendMailType(type);
  }, []);

  const closeSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(false);
    setSendMailType();
  }, []);

  const onSend = useCallback(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {emailAccounts.length === 0 ? (
        <TwoList>
          <EmailEmptyState />
        </TwoList>
      ) : (
        <>
          {mobile && !isShowEmailDetail && (
            <MobileTitleBar hasNavBar={true} hasTitle={true}>
              <EmailEditIcon
                onClick={() => {
                  openSendEmailModal(SEND_EMAIL_TYPE.COMPOSE);
                }}>
                <SVGIcon name='icon-edit' size={24} fill='var(--dark-color)' />
              </EmailEditIcon>
            </MobileTitleBar>
          )}
          <TwoList>
            <LeftContain mobile={mobile} isShowEmailDetail={isShowEmailDetail}>
              <ContainList>
                {mobile ? null : (
                  <TopView>
                    <FormView>
                      <SearchRow>
                        <RightView>
                          <Input
                            isPrimarySmall={true}
                            placeholder='Search'
                            onChange={(e) => {
                              searchEmail(e.target.value);
                            }}
                            value={searchText}
                          />
                          {searchText ? (
                            <CloseIcon
                              onClick={() => {
                                searchEmail('');
                              }}>
                              <SVGIcon name='icon-close' size={18} fill='var(--gray-icon-color)' />
                            </CloseIcon>
                          ) : (
                            <SVGIcon name='icon-search' size={18} fill='var(--gray-icon-color)' strokeWidth={2} />
                          )}
                        </RightView>
                      </SearchRow>
                    </FormView>
                  </TopView>
                )}
                <BottomView onScroll={handleScroll} ref={emailContainerEl} mobile={mobile}>
                  {listItems?.map((item, index) => {
                    const isPast = isPastDate(item?.dateTime);
                    const name =
                      !item?.lookup && item?.participants?.length > 0
                        ? getEmailUsers(item?.participants)
                        : item?.lookup?.name;
                    return (
                      <InBoxDetail
                        key={`${index}`}
                        mobile={mobile}
                        active={threadId === item.id}
                        className={item.unread && 'unread'}
                        onClick={() => {
                          onEmailClick(item);
                        }}>
                        <FirstRow>
                          <LUser title={name} className={item.unread && 'unread'} mobile={mobile}>
                            {name}
                          </LUser>
                          <RTimeView>
                            {item?.hasAttachments && (
                              <SVGIcon
                                name='icon-attachment'
                                size={18}
                                fill='var(--gray-icon-color)'
                                onClick={() => {
                                  searchEmail('');
                                }}
                              />
                            )}
                            <RTime
                              className={item.unread && 'unread'}
                              title={moment(item?.dateTime).format(TOOLTIP_DATE_FORMAT)}>
                              {moment(item?.dateTime).format(isPast ? DATE_FORMAT : TIME_FORMAT)}
                            </RTime>
                          </RTimeView>
                        </FirstRow>
                        <SecondRow>
                          <CaptionText className={item.unread && 'unread'} title={item?.subject} mobile={mobile}>
                            {item?.subject}
                          </CaptionText>
                          <RBox>
                            <NumText className={item.unread && 'unread'} mobile={mobile}>
                              {item?.msgCount}
                            </NumText>
                          </RBox>
                        </SecondRow>
                        <ThirdRow>
                          <MessageText className={item.unread && 'unread'} title={item?.snippet} mobile={mobile}>
                            {item?.snippet}
                          </MessageText>
                        </ThirdRow>
                      </InBoxDetail>
                    );
                  })}
                </BottomView>
              </ContainList>
            </LeftContain>
            <RightContain mobile={mobile} isShowEmailDetail={isShowEmailDetail}>
              <Switch>
                <Route
                  path='/email/segments/:id/view/:threadId'
                  render={() => {
                    return (
                      <EmailDetails
                        onDeleteEmail={onDeleteEmail}
                        onClickStar={onClickStar}
                        onClose={onCloseEmailDetail}
                        openNewEmailModal={openSendEmailModal}
                      />
                    );
                  }}
                />
                {/* <Route component={EmailEmptyState} /> */}
              </Switch>
            </RightContain>
          </TwoList>
          <SnackBar />
        </>
      )}
      <Modal open={isOpenEmailEmptyModal} onClose={closeEmailEmptyModal}>
        <EmailEmptyStateModal handleClose={closeEmailEmptyModal} />
      </Modal>
      <Modal open={isOpenSendEmailModal} onClose={closeSendEmailModal} style={ModalStyle}>
        <SendEmailModal handleCloseModal={closeSendEmailModal} onSend={onSend} type={sendMailType} />
      </Modal>
    </>
  );
};
export default Email;
