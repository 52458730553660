import React, { useCallback } from 'react';
import SVGIcon from '../../assets/svg/SVGIcon';
import { WindowMainNavigation, NavigationLeft, SalescampSpan, NavigationRight, Li } from './styles';

const ElectronTitlebar = (props) => {
  const sendCommandToElectron = useCallback((command) => {
    if (window?.salescampApp?.sendCommand) {
      window?.salescampApp?.sendCommand(command);
    }
  }, []);

  return (
    <>
      <WindowMainNavigation>
        <NavigationLeft>
          <SalescampSpan>Salescamp</SalescampSpan>
        </NavigationLeft>
        <NavigationRight>
          <Li
            onClick={() => {
              sendCommandToElectron('minimize');
            }}>
            <SVGIcon name='icon-minus' fill='var(--disable-color)' size={12} strokeWidth={2} />
          </Li>
          <Li
            onClick={() => {
              sendCommandToElectron('maximize');
            }}>
            <SVGIcon name='icon-maximius' fill='var(--disable-color)' size={12} strokeWidth={2} />
          </Li>
          <Li
            onClick={() => {
              sendCommandToElectron('close');
            }}>
            <SVGIcon name='icon-close' fill='var(--disable-color)' size={12} strokeWidth={2.5} />
          </Li>
        </NavigationRight>
      </WindowMainNavigation>
    </>
  );
};

export default ElectronTitlebar;
