import React from 'react';
import ReactDOM from 'react-dom';
import SmartPreview from './preview-javascript';
import { previewDefaultStyle } from './CMPreviewCss';
//import Style from './style.css';

export default class IframeView extends React.Component {
  constructor() {
    super();
    this.input = {};
    this.loadHtml = this.loadHtml.bind(this);
    this._iframeLoaded = this._iframeLoaded.bind(this);
    this._expandQuotedText = this._expandQuotedText.bind(this);
    this.iframeWindowOnClick = this.iframeWindowOnClick.bind(this);
  }

  iframeWindowOnClick(e) {
    if (e.target.id === 'sc_show_quoted_text' || e.target.id === 'sc_show_quoted_text_tap_icon') {
      this._expandQuotedText(e);
      return;
    }
    const anchorTag = e.target.closest('a');
    if (anchorTag) {
      e.preventDefault();
      const href = anchorTag.href;
      const url = new URL(href);
      //TODO: Open Url
      window.open(url, '_blank').focus();
    }
  }

  _expandQuotedText() {
    this.quotedTextExpanded = true;
    this.scrollTop = this.iframe.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop;
    this.html = this._loadViews();
    this.quotedTextExpanded = false;
    if (!this.iframe) return;
    const iframeNode = ReactDOM.findDOMNode(this.iframe);
    const doc = iframeNode.contentDocument;
    if (!doc) {
      return;
    }
    doc.open();
    doc.write(this.html);
    doc.close();
    if (!this.props.allowScroll) {
      SmartPreview.resizeIframe(this.props.index);
    }
  }

  _iframeLoaded() {
    if (!this.iframe || !this.iframe.contentWindow || !this.iframe.contentWindow.document) return;
    this.smartPreview = new SmartPreview(
      this.input,
      this.props.index,
      this.iframe.contentWindow.document,
      this._rsvpAction
    );
    this.smartPreview.render(this.props.transform !== false);

    if (!this.props.allowScroll) {
      SmartPreview.resizeIframe(this.props.index);
    }

    if (this.scrollTop) {
      this.iframe.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = this.scrollTop;
    }

    // eslint-disable-next-line max-len
    // need to set them again as after quoted text is expanded we have to again attach the event as iframe window will change
    // this.setUpContextMenuWithCards();
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.window.addEventListener('contextmenu', this.contextMenuListener, false);
      this.iframe.contentWindow.onclick = this.iframeWindowOnClick;
      this.iframe.contentWindow.onmouseover = this.hoverOverLink;
      this.iframe.contentWindow.onmouseout = this.removelinkHoverView;
    }
  }

  static doesHtmlStringHasVisibleText(smartBody) {
    if (!smartBody || smartBody == null) {
      return false;
    }

    if (smartBody.includes('<img')) {
      return true;
    }
    return false;
  }

  static getDocType(html, isHtmlMessage) {
    let docTypeDef = '';

    let maxLength = 150;
    if (isHtmlMessage === 0) {
      return docTypeDef;
    }

    html = html.trim();
    if (html.length <= 9) {
      return docTypeDef;
    }

    const seq = html.substring(0, 9);
    const docType = seq.match(/<!DOCTYPE/i);
    if (!docType) {
      return docTypeDef;
    }

    const i = html.indexOf('>');
    maxLength = maxLength > html.length ? html.length : maxLength;
    if (i >= maxLength) {
      return docTypeDef;
    }

    docTypeDef = html.substring(0, i + 1);
    docTypeDef = docTypeDef.replace(/"/g, "'");
    return docTypeDef;
  }

  populatePreviewInlineAttachments(input) {}

  getHTMLBody(html, isHtmlMessage) {
    const previewStyles = `${previewDefaultStyle}; `;

    this.input.sc_content = html;
    this.input.is_chrome_based_web = true;
    this.input.is_html_body = isHtmlMessage;
    this.input.is_print_view = 0;
    this.input.body_uncompressed = this.props.bodyContent;
    this.input.display_quoted_text_button = !this.quotedTextExpanded;
    this.input.generate_smart_body = this.quotedTextExpanded ? false : true;
    this.input.inline_attachment = this.props.attachmentList?.map((att) => {
      return {
        ...att,
        cid: att.contentID,
        content_url: this.props.attachmentBaseUrl
          ? `${this.props.attachmentBaseUrl}&contentType=${att.contentType}&name=${att.name}&id=${att.id}`
          : undefined
      };
    });

    this.populatePreviewInlineAttachments(this.input);

    let head = IframeView.getDocType(html, isHtmlMessage);
    if (head.length > 0) {
      head = head.replace(' //EN', '//EN');
    }

    const metaTag = "<meta name='viewport' content='width=device-width' />";
    const actualHtml = `${head} \
    ${metaTag} \
    <meta http-equiv='content-type' content='text/html; charset=UTF-8'> \
    <style> ${previewStyles}</style> \
    </head> \
    <body> \
    <div id='sc_mail_${this.props.index}' class='sc_mail_${this.props.index}'> \
    ${SmartPreview.getMailContent(this.input, this.props.index, this._expandQuotedText)}
    </div> \
    </body> \
    </html>
    `;
    return actualHtml;
  }

  loadHtml(details) {
    let html = this.props.smartBody;

    if (!html) {
      html = this.props.bodyContent;
    }

    if (html == null) {
      console.log('html is null');
      return null;
    }

    if (!this.props.isHtmlMessage) {
      html = IframeView.textToHTML(html);
    }
    if (this.props.expandedQuotedText) {
      html = this.props.bodyContent;
    }

    html = this.getHTMLBody(html, this.props.isHtmlMessage);
    return html;
  }

  _loadViews() {
    return this.loadHtml(null);
  }

  /**
   * This will convert spaces and new line character to hexcode for non html mail where html tags are not used
   * to add spacing, line breaks, etc. For example Outbox mails.
   */
  static textToHTML(text) {
    return ((text || '') + '')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\t/g, '    ')
      .replace(/ /g, '&#8203;&nbsp;&#8203;')
      .replace(/\r\n|\r|\n/g, '<br />');
  }

  UNSAFE_componentWillMount() {
    this.html = this._loadViews();
  }

  componentDidCatch(error, errorInfo) {
    console.log('iframeView', 'componentDidCatch', error, errorInfo);
  }

  componentDidUpdate() {
    // if quoted text expanded or ICS attachment downloaded
    if (this.quotedTextExpanded) {
      // eslint-disable-next-line max-len
      // this.iframe.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = this.scrollTop ? this.scrollTop : 0;
      //console.log("iframe componentDidUpdate.....",this.iframe)
      this.html = this._loadViews();
      this.quotedTextExpanded = false;
      if (!this.iframe) return;
      const iframeNode = ReactDOM.findDOMNode(this.iframe);
      const doc = iframeNode.contentDocument;
      if (!doc) {
        return;
      }
      doc.open();
      doc.write(this.html);
      doc.close();
      if (!this.props.allowScroll) {
        SmartPreview.resizeIframe(this.props.index);
      }
    }

    this.iframe.contentWindow.onkeydown = this.onKeyDown;
  }

  // end inline download click

  componentDidMount() {
    if (!this.iframe) return;
    const iframeNode = ReactDOM.findDOMNode(this.iframe);
    if (iframeNode) {
      //console.log("iframe node",iframeNode)
      const doc = iframeNode.contentDocument;
      //console.log("doc",doc)
      if (!doc) {
        return;
      }
      doc.open();
      doc.write(this.html);
      doc.close();
    }
    if (!this.props.allowScroll) {
      SmartPreview.resizeIframe(this.props.index);
    }
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.onkeydown = this.onKeyDown;
      this.iframe.contentWindow.onclick = this.iframeWindowOnClick;
    }
  }

  render() {
    return (
      <iframe
        title='Message Preview'
        ref={(input) => {
          this.iframe = input;
        }}
        key={`key_frame_${this.props.index}`}
        id={`frame_${this.props.index}`}
        frameBorder='0'
        src=''
        onLoad={this._iframeLoaded}
        scrolling='no'
        width='100%'
        height='10px'
      />
    );
  }
}
