import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWebFormCode, setWebFormDetail } from '../../../actions/settingActions';
import Input from '../../../components/Input/Input';
import { ContainBoxTwo } from '../Users/styles';
import {
  getWebFormCode,
  getWebFormDetails,
  trackWebFormActionAnalyticActivity
} from '../../../services/settingService';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { setSuccessMessage } from '../../../actions/messageActions';
import SVGIcon from '../../../assets/svg/SVGIcon';
import Modal from '../../../components/Modal';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import {
  BorderBottom,
  FormCodeWrapper,
  IconWrapper,
  ListBlock,
  ListBox,
  MainList,
  NavigationBlock,
  NavigationTitleCord,
  DetailsBack,
  TextArea,
  TextCopyWrapper,
  TitleList,
  TextAreaWrapper,
  SvgWrapper,
  BackMainIcon
} from './styles';
import ContactFormBuilder from './ContactFormBuilder';

const WebFormDetail = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const id = props?.match?.params?.id;

  const loadData = useCallback(() => {
    dispatch(getWebFormDetails(organization_id, id));
    dispatch(getWebFormCode(organization_id, id));
  }, [dispatch, organization_id, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const settingSelector = useSelector((state) => state.setting);
  const { webFormDetail, webFormCode } = settingSelector;

  const onWebFormDetailBack = () => {
    dispatch(setWebFormDetail({}));
    dispatch(setWebFormCode(''));
    props.history.push('/settings/forms/');
  };

  const onOpenEditMenu = useCallback(
    (e) => {
      e.stopPropagation();
      trackWebFormActionAnalyticActivity('edit', webFormDetail?.name);
      setIsEditMenuOpen(true);
    },
    [webFormDetail?.name]
  );

  const onCloseEditMenu = useCallback(() => {
    setIsEditMenuOpen(false);
  }, []);

  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={false} hasTitle={false}>
          <NavigationTitleCord mobile={mobile}>{webFormDetail?.name}</NavigationTitleCord>
        </MobileTitleBar>
      )}
      <MainList mobile={mobile}>
        <FormCodeWrapper>
          {mobile ? null : (
            <NavigationBlock>
              <DetailsBack>
                <BackMainIcon onClick={onWebFormDetailBack}>
                  <SVGIcon fill='var(--gray-icon-color)' size={14} name='icon-big-left-arrow' strokeWidth={2} />
                </BackMainIcon>
                <NavigationTitleCord>{webFormDetail?.name}</NavigationTitleCord>
              </DetailsBack>
              <SvgWrapper
                onClick={(e) => {
                  onOpenEditMenu(e);
                }}>
                <SVGIcon fill='var(--gray-color)' size={24} name='icon-tools' strokeWidth={0} />
              </SvgWrapper>
            </NavigationBlock>
          )}
          <ContainBoxTwo>
            <ListBox>
              <ListBlock mobile={mobile}>
                <BorderBottom>
                  <TitleList>Your Endpoint</TitleList>
                  <IconWrapper
                    onClick={() => {
                      navigator.clipboard.writeText(webFormDetail?.endPoint);
                      dispatch(setSuccessMessage('Copied to clipboard'));
                    }}>
                    <SVGIcon fill='var(--gray-more-icon)' size={16} name='icon-copy' />
                  </IconWrapper>
                </BorderBottom>
                <TextCopyWrapper>
                  <Input isPrimary disabled autocomplete='off' required='' value={webFormDetail?.endPoint || ''} />
                </TextCopyWrapper>
              </ListBlock>
            </ListBox>
          </ContainBoxTwo>
          <ContainBoxTwo>
            <ListBox>
              <ListBlock>
                <BorderBottom>
                  <TitleList>Code</TitleList>
                  <IconWrapper
                    onClick={() => {
                      navigator.clipboard.writeText(webFormCode);
                      dispatch(setSuccessMessage('Copied to clipboard'));
                    }}>
                    <SVGIcon fill='var(--gray-more-icon)' size={16} name='icon-copy' />
                  </IconWrapper>
                </BorderBottom>
                <TextAreaWrapper>
                  <TextArea mobile={mobile} disabled value={webFormCode} />
                </TextAreaWrapper>
              </ListBlock>
            </ListBox>
          </ContainBoxTwo>
        </FormCodeWrapper>
        <Modal open={isEditMenuOpen} onClose={onCloseEditMenu}>
          <ContactFormBuilder webFormId={id} handleCloseModal={onCloseEditMenu} />
        </Modal>
        <SnackBar />
      </MainList>
    </>
  );
};

export default WebFormDetail;
