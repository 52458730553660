import React from 'react';
import { FilterPanelWrap, FilterMainText, FilterSpan, StaticFilterText } from './styles';

const FilterPanel = () => {
  return (
    <>
      <FilterPanelWrap>
        <FilterMainText>
          <FilterSpan>Status</FilterSpan>
          <StaticFilterText>is</StaticFilterText>
          <FilterSpan>Intrested</FilterSpan>
          <StaticFilterText>or</StaticFilterText>
          <FilterSpan>Email</FilterSpan>
          <StaticFilterText>Contain</StaticFilterText>
          <FilterSpan>@gmail.com</FilterSpan>
        </FilterMainText>
      </FilterPanelWrap>
    </>
  );
};

export default FilterPanel;
