import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/atom/Button/Button';
import { ReactComponent as VerifyIcon } from '../../../../assets/images/svg/verified_phone_number.svg';
import { ReactComponent as DisabledIcon } from '../../../../assets/images/svg/disabledverified.svg';
import { getFormattedPhoneNumber } from '../../../../global/Helper';
import { getVerificationCodeForNumber } from '../../../../services/settingService';
import { CALL_NUMBER_VERIFICATION_STEP } from '../../../../constants/constant';
import {
  VerifyModal,
  TitleViewlist,
  TitleText,
  VerfyList,
  IconVerify,
  Mobnub,
  SpanText,
  CaptionVerify,
  BtnViewList,
  ErrorView,
  ErrorText,
  HeaderWrapper,
  IconView
} from '../styles';
import { trackAnalyticActivity } from '../../../../services/analyticsService';
import SVGIcon from '../../../../assets/svg/SVGIcon';

const VerifyPhoneNumberModal = ({ activeStep, onClose, externalNumber, onOpenVerifyingPhoneNumber }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const settingSelector = useSelector((state) => state.setting);
  const { loading } = settingSelector;
  const formattedPhoneNumber = getFormattedPhoneNumber(externalNumber?.number);

  const number = `${externalNumber?.code} ${formattedPhoneNumber}`;

  const dispatch = useDispatch();

  const trackPhoneVerifyingAnalyticActivity = useCallback((phoneNumber) => {
    try {
      trackAnalyticActivity('settings - phone: verifying', { phone: phoneNumber });
    } catch (e) {
      console.log('track settings - phone: verifying Error', e);
    }
  }, []);

  const onNumberVerification = useCallback(async () => {
    const payload = {
      countryCode: externalNumber?.code,
      number: externalNumber?.number
    };
    const phoneNumberItem = await dispatch(getVerificationCodeForNumber(organization_id, payload));
    if (phoneNumberItem) {
      onOpenVerifyingPhoneNumber(phoneNumberItem);
      trackPhoneVerifyingAnalyticActivity(phoneNumberItem?.phoneNumber);
    }
  }, [dispatch, trackPhoneVerifyingAnalyticActivity, onOpenVerifyingPhoneNumber, externalNumber, organization_id]);

  const buttonTitle = useMemo(() => {
    switch (activeStep) {
      case CALL_NUMBER_VERIFICATION_STEP.CALL_ME_STEP:
        return 'Call me now';
      case CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFICATION_FAILED_STEP:
        return 'Call me again';
      case CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFIED_STEP:
        return 'Done';
      default:
        return '';
    }
  }, [activeStep]);

  return (
    <div>
      <VerifyModal>
        <TitleViewlist>
          <HeaderWrapper>
            <SVGIcon name='icon-cellphone' fill='var(--gray-icon-color)' size={22} />
            <TitleText>Verify External Number</TitleText>
          </HeaderWrapper>
          <IconView
            onClick={() => {
              onClose();
            }}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
          </IconView>
        </TitleViewlist>
        <VerfyList>
          {activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFICATION_FAILED_STEP && (
            <ErrorView>
              <SVGIcon name='icon-warning' fill='#F08334' size={22} />
              <ErrorText>Your number could not be verified. Please try again or contact hello@salescamp.app</ErrorText>
            </ErrorView>
          )}
          <>
            {activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFIED_STEP && (
              <IconVerify>
                <VerifyIcon />
              </IconVerify>
            )}
            {activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFICATION_FAILED_STEP && (
              <IconVerify>
                <DisabledIcon />
              </IconVerify>
            )}
          </>
          <Mobnub>{number}</Mobnub>
          {(activeStep === CALL_NUMBER_VERIFICATION_STEP.CALL_ME_STEP ||
            activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFICATION_FAILED_STEP) && (
            <>
              <SpanText>Needs to be verified</SpanText>
              <CaptionVerify>
                A 6-digit verification code will be generated on the next screen. You need to enter it on you phone’s
                keypad when we call you.
              </CaptionVerify>
            </>
          )}
          {activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFIED_STEP && (
            <SpanText>Verified Successfully</SpanText>
          )}
          <BtnViewList step={activeStep}>
            <Button
              title={buttonTitle}
              disabled={loading}
              loading={loading}
              onClick={() => {
                activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFIED_STEP
                  ? onClose()
                  : onNumberVerification();
              }}></Button>
          </BtnViewList>
        </VerfyList>
      </VerifyModal>
    </div>
  );
};

export default VerifyPhoneNumberModal;
