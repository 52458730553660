import styled from 'styled-components';
import { Header4, Header5, Text1 } from '../../../../styles';

const InvoiceContainer = styled.div`
  max-width: 680px;
  width: 100%;
  padding: 24px 0;
  margin: auto;
`;
const InvoiceHeader = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
`;
const BackIcon = styled.div`
  svg {
    display: block;
  }
`;
const BackSpan = styled.span`
  ${Header4}
  margin-left: 10px;
  color: var(--dark-color);
`;
const StripeWrap = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  box-shadow: 0px 1px 2px var(--inbox-icon-color);
`;
const StripeHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const ConfigSpan = styled.span`
  ${Header5}
  display: block;
  color: var(--dark-color);
`;
const ConnectStripe = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    ${Text1}
    color: var(--primary-color);
    margin-left: 8px;
  }
`;
const StripeContainer = styled.div`
  padding: 16px;
`;
const NoConnectSpan = styled.span`
  display: block;
  ${Header4}
  color: var(--dark-color);
`;
const SmStripSpan = styled.span`
  display: block;
  ${Text1}
  color: var(--gray-color);
  margin-top: 2px;
`;
const ConnectStripeMain = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;
const ConnectMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const MoreIcon = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
const ConnectedUser = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 16px;
`;
const UserImage = styled.img`
  display: block;
  max-width: 100%;
`;
const UserSpan = styled.span`
  ${Text1}
  color: var(--dark-color);
`;

export {
  InvoiceContainer,
  InvoiceHeader,
  BackIcon,
  BackSpan,
  StripeWrap,
  StripeHeader,
  ConfigSpan,
  ConnectStripe,
  StripeContainer,
  NoConnectSpan,
  SmStripSpan,
  ConnectStripeMain,
  ConnectMain,
  ConnectedUser,
  UserImage,
  UserSpan,
  MoreIcon
};
