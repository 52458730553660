import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';
import Validation from '../../../components/Validation/Validation';
import { isEmpty } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { changePassword, trackUserProfileUpdateAnalyticActivity } from '../../../services/authServices';
import {
  FirstBox,
  PersonalList,
  FirstList,
  TitleHead,
  CaptionList,
  FillListTwo,
  InpView,
  InpViewTwo,
  BtnView,
  InpViewThree
} from './styles';

const ChangePassword = () => {
  const mobile = useMobileDevice();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const authSelector = useSelector((state) => state.auth);
  const { currentUserProfile, loading } = authSelector;

  const dispatch = useDispatch();

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!currentPassword) {
      validationErrors.currentPassword = 'Required';
      valid = false;
    }
    if (!newPassword) {
      validationErrors.newPassword = 'Required';
      valid = false;
    }
    if (!confirmPassword) {
      validationErrors.confirmPassword = 'Required';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [currentPassword, newPassword, confirmPassword]);

  const handlePasswordChange = useCallback(async () => {
    const validateResult = validateInputs();
    if (!validateResult) return;

    const params = {
      oldPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    };
    const result = await dispatch(changePassword(params));
    if (result) {
      trackUserProfileUpdateAnalyticActivity('password changed');
    }
  }, [validateInputs, dispatch, currentPassword, newPassword, confirmPassword]);

  return (
    <FirstBox>
      <PersonalList>
        <FirstList>
          <TitleHead>Change Password</TitleHead>
          <CaptionList mobile={mobile}>
            {`Change your password associated for the account associated with ${currentUserProfile?.email}`}
          </CaptionList>
          <FillListTwo mobile={mobile}>
            <InpView>
              <Input
                isPrimarySmall={true}
                type='password'
                id='currentPassword'
                name='currentPassword'
                placeholder='Enter your Current Password'
                title='Current Password'
                value={currentPassword}
                isRequierd
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </InpView>
            <InpViewTwo mobile={mobile}>
              <Input
                isPrimarySmall={true}
                type='password'
                id='newPassword'
                name='newPassword'
                placeholder='Enter your New Password'
                title='New Password'
                value={newPassword}
                isRequierd
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </InpViewTwo>
            <InpViewThree mobile={mobile}>
              <Input
                isPrimarySmall={true}
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                placeholder='Enter your Confirm Password'
                title='Confirm Password'
                value={confirmPassword}
                isRequierd
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </InpViewThree>
          </FillListTwo>
          {!isEmpty(validationErrors) && <Validation error={'* This fields are requierd'}></Validation>}
        </FirstList>
        <BtnView>
          <Button onClick={handlePasswordChange} title='Change Password' type='button' disabled={loading} />
        </BtnView>
      </PersonalList>
    </FirstBox>
  );
};

export default ChangePassword;
