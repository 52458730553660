import styled, { css } from 'styled-components';
import Input from '../../../components/Input/Input';
import { Header1, Text2, Header4, Text1 } from '../../../styles';
import Lineargradient from '../../../assets/images/lightgradientbg.png';

const ManeWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const LoginWrap = styled.div`
  background: var(--white-color);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
const LoginLeft = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 50%;
        `}
`;
const LoginRight = styled.div`
  max-width: 50%;
  width: 100%;
  height: 100%;
  padding: 36px 64px;
  overflow: hidden;
  background-image: url(${Lineargradient});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.mobile &&
    css`
      display: none;
    `}
`;
const LoginImage = styled.img`
  height: auto;
  width: 100%;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 320px;
  margin: auto;
`;
const InputBlock = styled.div`
  margin-bottom: 12px;
  input {
    :focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 3px #afcdff, 1px 1px 2px 0 rgb(0 0 0 / 5%);
    }
  }
`;
const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
  svg {
    display: block;
    height: 38px;
    width: 36px;
  }
`;
const Title = styled.h1`
  ${Header1}
  color: var(--dark-color);
  margin: 0;
  text-align: left;
  padding-top: 16px;
`;
const Lable = styled.label`
  ${Header4}
  color: var(--sonic-silver);
  display: block;
  margin-bottom: 8px;
`;
const Form = styled.form`
  input {
    position: relative;
    border-radius: 4px;
  }
  #name {
  }
  #email {
  }
  #number {
  }
  #password {
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  button {
    width: 100%;
    justify-content: center;
  }
`;
const PrivacyPolicy = styled.p`
  ${Text2}
  color: var(--gray-color);
  margin: 24px 0;
`;
const AlreadyPara = styled.p`
  display: block;
  ${Text1}
  text-align: center;
  color: var(--gray-color);
  margin: 32px 0 0 0;
`;
const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  color: var(--primary-color);
  :hover {
    text-decoration: underline;
  }
`;
const colourStyles = {
  container: (base) => ({
    ...base,
    width: 70,
    borderRight: '1px solid var(--gray-border-color)'
  }),
  menu: (base) => ({
    ...base,
    width: 240
  }),
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--gray-icon-color)',
    '&:active': {
      background: ' var(--popup-color-hover)'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 20px'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    // This line disable the blue border
    backgroundColor: 'transparent',
    boxShadow: 'none',
    minHeight: '34px',
    borderRadius: '4px',
    borderColor: isFocused ? 'transparent' : 'transparent',
    borderWidth: isFocused ? '0' : '0',
    margin: isFocused ? '0' : '0',
    ':hover': {
      borderColor: isFocused ? 'transparent;' : 'transparent'
    }
    // borderColor: '#E4E4E5'
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px'
  }),

  indicatorsContainer: (base) => ({
    ...base,
    display: 'none'
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500'
  })
};
const DropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  position: relative;
  .flagicon {
    margin: 0px 0px 0px 20px;
    position: absolute;
    left: 0;
    z-index: 1;
  }
`;
const CountryDropDownWrapper = styled.div`
  position: absolute;
  left: 25px;
  z-index: 1;
`;
const MobileInput = styled(Input)`
  padding-left: 106px;
`;
const Para = styled.p`
  margin: 8px 0 0 0;
  ${Text1}
  color: var(--gray-color);
  text-align: left;
`;
const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const NumberSpan = styled.span`
  display: inline-block;
  ${Header4}
  color: var(--gray-color);
  margin-right: 8px;
`;
const IconBackground = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: var(--popup-color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const OtpWrapper = styled(AlreadyPara)`
  margin: 12px 0 32px 0;
  text-align: left;
`;

const MacDragDro = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 24px;
  width: 100%;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
`;

export {
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  PrivacyPolicy,
  AlreadyPara,
  Link,
  colourStyles,
  DropDownWrapper,
  CountryDropDownWrapper,
  MobileInput,
  LoginWrap,
  LoginLeft,
  LoginRight,
  LoginImage,
  InputBlock,
  Lable,
  Para,
  NumberWrapper,
  NumberSpan,
  IconBackground,
  OtpWrapper,
  MacDragDro
};
