import styled, { css } from 'styled-components';
import { Header2, Header4, Text1 } from '../../../styles';
import Button from '../../../components/atom/Button/Button';

const PageWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const MainList = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px 16px 16px;
        `
      : css`
          padding: 24px 0 0 0;
        `}
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h1`
  margin: 0;
  ${Header2}
  color: var(--dark-color);
`;
const ContainBoxTwo = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          background: transparent;
        `
      : css`
          background: var(--white-color);
        `}
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 2px 3px var(--pagination-box-shadow);
  border-radius: 6px;
  margin-bottom: 16px;
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--white-color);
    `}
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
`;
const SubText = styled.h4`
  margin: 0 0 2px 0;
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  display: inline-block;
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1}
  color: var(--gray-color);
  margin: 0;
`;
const IconView = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const LoginDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--Platinum-color);
  padding: 16px 16px 0 16px;
  margin: 0 -16px;
  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const Form = styled.form`
  padding: 16px;
  overflow: auto;
`;
const MainContact = styled.div`
  position: relative;
  outline: none;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  margin-left: 12px;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const LeftWrapper = styled.div``;
const RightWrapper = styled.div``;
const ModalWrapper = styled.div`
  margin-bottom: 16px;
`;
const ListWrappper = styled.div`
  width: 100%;
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: block;
`;
const marginBottom = css`
  margin-bottom: 12px;
`;
const IconWrapper = styled.div`
  flex: 0 0 auto;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  PageWrapper,
  MainList,
  Header,
  Title,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  LoginDetails,
  BottomView,
  Form,
  MainContact,
  TitleText,
  TitleView,
  LeftWrapper,
  RightWrapper,
  ModalWrapper,
  ListWrappper,
  DropDownTitle,
  marginBottom,
  IconWrapper,
  MenuItemWrapper,
  HeaderWrapper,
  MainMobileButton,
  MobileButton
};
