import React from 'react';
import OrganizationNavbar from '../Navbar/OrganizationNavbar/OrganizationNavbar';
import NavbarLoad from '../../assets/images/svg/top-loading.svg';
import MainLoad from '../../assets/images/svg/org_mainload.svg';
import { OrgLoadingWrapper, OrgImgNavbar, NavbarLoadImg, OrgLoadWrapper, OrgNavbarImgMain } from './styles';

export const OrganizationLoading = () => {
  return (
    <>
      <OrgLoadingWrapper>
        <OrganizationNavbar />
        <OrgImgNavbar>
          <NavbarLoadImg src={NavbarLoad}></NavbarLoadImg>
        </OrgImgNavbar>
        <OrgLoadWrapper>
          <OrgNavbarImgMain>
            <NavbarLoadImg src={MainLoad}></NavbarLoadImg>
          </OrgNavbarImgMain>
        </OrgLoadWrapper>
      </OrgLoadingWrapper>
    </>
  );
};
