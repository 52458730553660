import styled from 'styled-components';
import { Header2, Header6, Text1 } from '../../../../styles';

const MainContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: auto;
`;
const ContainerWrap = styled.div`
  max-width: 935px;
  width: 100%;
  margin: 0 auto;
`;
const HeadWrap = styled.div`
  text-align: center;
  padding: 30px;
`;
const HeadView = styled.div``;
const TabView = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 3px;
  display: inline-block;
  section {
    display: none;
    border: none;
  }
  input {
    display: none;
  }
  label {
    display: inline-block;
    padding: 9px 33px;
    text-align: center;
    color: var(--dark-color);
    background: var(--white-color);
    border: none;
  }
  label:before {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--white-color);
  }
  label:hover {
    cursor: pointer;
  }
  input:checked + label {
    color: var(--white-color);
    background: var(--primary-color);
    border-radius: 0 3px 3px 0;
  }
  input:checked + label:nth-child(2) {
    color: var(--white-color);
    background: var(--primary-color);
    border-radius: 3px 0 0 3px;
  }
`;
const InputOne = styled.input``;
const InLabelOne = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--white-color);
`;
const InputTwo = styled.input``;
const InLabelTwo = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--white-color);
`;
const CloseView = styled.div`
  position: absolute;
  right: 30px;
  top: 33px;
`;
const SecondWrap = styled.div`
  margin-top: 76px;
`;
const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BoxItem = styled.div`
  width: 100%;
  max-width: 23.1%;
`;
const BoxView = styled.div`
  text-align: center;
  padding: 20px 14px 30px 14px;
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 10px;
  width: 100%;
`;
const Title = styled.h4`
  margin: 0 0 12px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--dark-color);
`;
const Caption = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-color);
`;
const DetailView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
`;
const Amount = styled.h4`
  margin: 0 10px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: var(--dark-color);
`;
const BillCaption = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-color);
`;
const BtnView = styled.div``;
const ListApp = styled.div``;
const Ul = styled.ul`
  list-style: none;
  padding: 8px 0 0 0;
  margin: 0 0 0 20px;
`;
const Li = styled.li`
  margin: 12px auto;
`;
const ListSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--dark-color);
  margin-left: 8px;
`;
const BtnMore = styled.div`
  text-align: center;
  margin: 40px 0 20px 0;
`;
const BtnA = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: var(--primary-color);
  cursor: pointer;
  padding: 8px 16px;
  text-decoration: none;
  padding: 1px 4px;
  border-radius: 4px;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const HelpView = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  margin-bottom: 30px;
`;
const ViewLeft = styled.div``;
const Help = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-color);
  margin: 0;
`;
const Helpcaption = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
  margin: 5px 0 0 0;
`;
const ViewRight = styled.div``;

const MinusPlusImg = styled.img``;
const PaymentsStripeWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
const PaymentsStripe = styled.div`
  ${Text1}
  padding:6px 16px;
  background-color: var(--popup-color-hover);
  border-radius: 20px;
  border: 1px solid var(--gray-border-color);
  text-align: center;
  display: inline-block;
  color: #000;
`;
const PaymentHeadBox = styled.div`
  padding: 24px;
  background: var(--primary-color);
  border-radius: 8px 8px 0 0;
  position: relative;
`;
const Heading = styled.h5`
  margin: 0 0 8px 0;
  ${Header6}
  color:var(--white-color);
  opacity: 0.5;
`;
const SubHeading = styled.h3`
  margin: 0 0 5px 0;
  ${Header2}
  color:var(--white-color);
`;
const UserNumberPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;
const UserNumber = styled.span``;
const UserSelectTitle = styled.p`
  ${Text1}
  color:var(--white-color);
  margin: 0;
  padding-top: 15px;
`;
const PluseMinusBtn = styled.span`
  display: inline-flex;
  background: var(--white-color);
  justify-content: flex-start;
  margin-top: 10px;
  border-radius: 4px;
  .btn {
    border-radius: 0;
    padding: 8.5px 13px;

    display: inline-block;
    color: var(--gray-icon-color);
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-image: none;
    margin: 0;
    transition: 0.3s ease-in-out;
    text-decoration: none;
  }
  .btn[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
  }
`;
const MinusBtn = styled.button`
  background: 0 0;
  border: none;
  line-height: 0;
  border-right: 1px solid var(--gray-border-color);
`;
const PluseBtn = styled.button`
  background: 0 0;
  border: none;
  line-height: 0;
  border-left: 1px solid var(--gray-border-color);
`;
const CountUser = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  margin: 0;
`;
const Price = styled.span``;
const TotalPrice = styled.span`
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: var(--white-color);
  display: flex;
  align-items: flex-start;
`;
const Small = styled.small`
  ${Text1}
  color: var(--white-color);
`;
const PriceText = styled.p`
  margin: 0;
  ${Text1}
  color:var(--white-color);
  text-align: right;
`;
const Form = styled.form`
  padding: 24px;
  .StripeElement .StripeElement--empty {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--gray-border-color);
    box-sizing: border-box;
    border-radius: 4px;
    :hover {
      border-color: var(--bright-navy-blue);
      box-shadow: 0 1px 2px var(--bright-navy-box);
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  justify-content: flex-end;
`;
const Link = styled.div`
  position: absolute;
  right: 4px;
  top: 5px;
  z-index: 11;
  cursor: pointer;
  display: flex;
  padding: 10px;
`;

export {
  MainContainer,
  ContainerWrap,
  HeadView,
  TabView,
  InputOne,
  InLabelOne,
  InputTwo,
  InLabelTwo,
  CloseView,
  HeadWrap,
  SecondWrap,
  FirstRow,
  BoxView,
  Title,
  Caption,
  DetailView,
  Amount,
  BillCaption,
  BtnView,
  BoxItem,
  ListApp,
  Ul,
  Li,
  ListSpan,
  BtnMore,
  BtnA,
  HelpView,
  ViewLeft,
  Help,
  Helpcaption,
  ViewRight,
  MinusPlusImg,
  PaymentsStripeWrapper,
  PaymentsStripe,
  PaymentHeadBox,
  Heading,
  SubHeading,
  UserNumberPrice,
  UserNumber,
  UserSelectTitle,
  PluseMinusBtn,
  MinusBtn,
  PluseBtn,
  CountUser,
  Price,
  TotalPrice,
  Small,
  PriceText,
  Form,
  ButtonWrapper,
  Link
};
