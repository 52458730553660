import styled from 'styled-components';
import { Header4 } from '../../../../styles';

const MainContact = styled.div`
  background: var(--white-color);
  border: 1px solid #e4e6e8;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 260px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  outline: none;
`;
const TitleText = styled.h2`
  margin: 0;
  color: var(--dark-color);
  ${Header4}
  padding:10px 15px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const InputView = styled.div`
  margin: 15px 15px 20px 15px;
`;
const BtnView = styled.div`
  padding: 0 15px 15px 15px;
  width: 100%;
  max-width: 175px;
  display: flex;
  justify-content: space-between;
`;

export { MainContact, TitleText, InputView, BtnView };
