import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const smsLogLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_SMS_LOADER
});

const initialState = [];
const smsLogListReducer = createReducerOrig(initialState, (builder) => {
  builder.addCase(Actions.SET_SMS_LIST, (state = initialState, action) => {
    return action.payload;
  });
});

const smsLogReducer = combineReducers({
  loading: smsLogLoaderReducer,
  list: smsLogListReducer
});

export default smsLogReducer;
