import SVGIcon from '../../../../../assets/svg/SVGIcon';
import { CommentHoverBox, CommentIconMain } from './styles';

const CommentHoverItem = ({ item, isOpenMoreOptionMenu, selectedActivity, onClickMoreMenu, onClickComment }) => {
  return (
    <CommentHoverBox className='commentbox' boxblock={isOpenMoreOptionMenu} current={selectedActivity?.id === item?.id}>
      {/* <CommentIconMain className={hasProduction() ? 'inDevelopment' : ''}>
        <SVGIcon title='icon-thumbs-up' fill='var(--gray-color)' size={16} name='icon-thumbs-up' strokeWidth={2} />
      </CommentIconMain> */}
      <CommentIconMain
        onClick={() => {
          onClickComment(item);
        }}>
        <SVGIcon title='icon-message' fill='var(--gray-color)' size={16} name='icon-message' strokeWidth={2} />
      </CommentIconMain>
      {/* <CommentIconMain className={hasProduction() ? 'inDevelopment' : ''}>
        <SVGIcon title='icon-bookmark' fill='var(--gray-color)' size={16} name='icon-bookmark' strokeWidth={2} />
      </CommentIconMain> */}
      <CommentIconMain onClick={(e) => onClickMoreMenu(e, item)}>
        <SVGIcon title='icon-more' fill='var(--gray-color)' size={16} name='icon-more' strokeWidth={2} />
      </CommentIconMain>
    </CommentHoverBox>
  );
};
export default CommentHoverItem;
