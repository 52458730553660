import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Board, { moveCard } from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Modal from '../../components/Modal';
import { DATE_FORMAT, LookupFieldTypes, LOOKUP_FIELD_CUSTOM_ID, TIME_FORMAT } from '../../constants/constant';
import Header from '../../components/Header/Header';
import { getLookupList, getSegmentFilteredList, getSegmentList, updateLookupField } from '../../services/lookupService';
import { getTableFields } from '../../services/lookupTableServices';
import UserPreferenceSingleton from '../../helpers/UserPreferenceSingleton';
import AddNewContact from '../Contact/AddNewContact';
import { currencySymbols } from '../../data/raw';
import OrganizationPreferencesSingleton from '../../helpers/OrganizationPreferencesSingleton';
import { setLookupList, setSegmentList } from '../../actions/lookupActions';
import useMobileDevice from '../../hooks/useMobileDevice';
import { downloadFile } from '../../helpers/common';
import {
  DealsWrapper,
  BoardWrapper,
  ReactKanbanColumn,
  ReactCardTitle,
  ReactCardDescription,
  ReactColumn,
  ReactColumnWrapper,
  ReactCardWrapper,
  ReactCardName
} from './Styles';

const SEGMENT = 'segment';

const ControlledBoard = ({ board, updateDealStage, tableId, tableName, currency }) => {
  const [controlledBoard, setBoard] = useState(board);

  useEffect(() => {
    setBoard(board);
  }, [board]);

  const handleCardMove = (_card, source, destination) => {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    const value = {
      value: destination?.toColumnId
    };
    updateDealStage(_card?.id, value);
    setBoard(updatedBoard);
  };

  if (!controlledBoard) return <div className='test'></div>;
  return (
    <Board
      onCardDragEnd={handleCardMove}
      disableColumnDrag
      renderCard={({ ...item }, { removeCard, dragging }) => {
        return (
          <ReactKanbanColumn>
            <ReactColumnWrapper>
              <ReactCardWrapper>
                <ReactCardName to={{ pathname: `/dl/${tableName}/view/${item?.id}/overview`, tableId: tableId }}>
                  {item?.name}
                </ReactCardName>
              </ReactCardWrapper>
              {item?.fields?.map((x, index) => {
                const currencySymbol = currencySymbols?.find((x) => x.code === currency);
                return x?.value && x?.value !== item?.status ? (
                  <ReactColumn key={`fileds_${item?.id}${x?.label}_${index}`}>
                    <ReactCardTitle>{x?.label}</ReactCardTitle>
                    <ReactCardDescription>
                      {x?.label === 'Amount' && currencySymbol?.symbol}
                      {x?.value}
                    </ReactCardDescription>
                  </ReactColumn>
                ) : null;
              })}
            </ReactColumnWrapper>
          </ReactKanbanColumn>
        );
      }}>
      {controlledBoard}
    </Board>
  );
};

const Deals = (props) => {
  const lookupName = props.match.params.name;
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const company = UserPreferenceSingleton.getInstance().getOrganization();
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const lookupSelector = useSelector((state) => state.lookup);
  const { list: lookupTableList, tableFields } = lookupTableSelector;
  const { list: lookupList, segments } = lookupSelector;
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const table = useMemo(() => {
    return lookupTableList.find((x) => x.name === lookupName);
  }, [lookupTableList, lookupName]);

  const segmentId = useMemo(() => {
    const selectedSegmentId = OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, SEGMENT);
    const segment_id =
      selectedSegmentId ||
      props.match.params.segmentId ||
      (segments && segments?.length > 0 ? segments[0]?.id : undefined) ||
      'all';
    return segment_id;
  }, [props.match.params, segments, table?.id]);

  useEffect(() => {
    return () => {
      dispatch(setSegmentList(null));
      dispatch(setLookupList(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setSegmentList(null));
    dispatch(setLookupList(null));
  }, [dispatch, lookupName]);

  const loadData = useCallback(async () => {
    const tableId = table?.id;
    const companyId = company?.id;
    if (table) {
      dispatch(getSegmentList(tableId));
      dispatch(getTableFields(companyId));
      dispatch(getLookupList(companyId, tableId));
    }
  }, [table, dispatch, company]);

  useEffect(() => {
    loadData();
  }, [loadData, lookupName]);

  const lookupDetails = useMemo(() => {
    if (!tableFields) return {};
    const lookupTableFields = tableFields?.find((x) => x.tableId === table?.id);
    const stageField = lookupTableFields?.columns?.find((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE);
    return stageField;
  }, [tableFields, table]);

  const tableColumns = useMemo(() => {
    const lookupTableFields = tableFields?.find((x) => x.tableId === table?.id);
    const columns = lookupTableFields?.columns
      ?.filter((x) => x.isShow === true)
      ?.map((field) => {
        return {
          Header: field.label,
          accessor: field.id,
          field: field
        };
      });
    return columns;
  }, [tableFields, table]);

  const getExportData = useCallback(
    (list, fields, filePrefix) => {
      const lookupTableFields = tableFields?.find((x) => x?.tableId === table?.id);
      const columns = lookupTableFields?.columns;
      if (columns) {
        let csvData = '';
        //Write headers
        columns?.forEach((x) => {
          csvData += x?.label + ',';
        });
        csvData += '\n';

        list?.forEach((lookup) => {
          columns?.forEach((field) => {
            let value = lookup[fields][field?.id];
            //Handle the Date and Time Formate
            if (field.type === LookupFieldTypes.Date) {
              value = lookup[fields][field?.id] ? moment(lookup[fields][field?.id]).format(DATE_FORMAT) : undefined;
            }
            if (field.type === LookupFieldTypes.Time) {
              value = lookup[fields][field?.id] ? moment(lookup[fields][field?.id]).format(TIME_FORMAT) : undefined;
            }
            if (field.type === LookupFieldTypes.Amount) {
              value = String(value);
            }
            let newValue = value?.trim() || '';
            // eslint-disable-next-line no-useless-escape
            if (newValue && newValue?.indexOf(',') !== -1) newValue = `\"${newValue}\"`;
            csvData += String(newValue) + ',';
          });
          csvData += '\n';
        });
        downloadFile(
          csvData,
          `Salescamp_${table?.name}_${filePrefix}_${moment().format('DD-MMM-YYYY')}.csv`,
          'text/csv'
        );
      }
    },
    [table?.id, table?.name, tableFields]
  );

  const exportAllData = useCallback(() => {
    getExportData(lookupList, 'displayFields', 'ExportedData');
  }, [getExportData, lookupList]);

  const lookupSegmentData = useMemo(() => {
    if (!lookupList) return null;
    dispatch(getSegmentList(table?.id));
    const segmentLookupList = getSegmentFilteredList(lookupList, table?.id, segmentId);
    const tableData = segmentLookupList?.map((lookup) => {
      const rowData = {
        id: lookup.id,
        name: lookup.name,
        ...lookup?.displayFields,
        stage: lookup.stage,
        stage_name: lookup.stage_name
      };
      return rowData;
    });
    return tableData;
  }, [dispatch, lookupList, table?.id, segmentId]);

  const openAddModal = () => {
    setIsOpenAddModal(true);
  };
  const closeAddModal = () => {
    setIsOpenAddModal(false);
  };

  const onSave = () => {
    loadData();
    closeAddModal();
  };

  const boardColumns = useMemo(() => {
    const stageCards = lookupDetails?.options?.map((item) => {
      const cardLookups = lookupSegmentData?.filter((x) => x.stage === item.id) || [];

      const cards = cardLookups?.map((lookup) => {
        let fields = [];
        //Get lookup field name and value
        tableColumns.forEach((item) => {
          let value = lookup[item.accessor];
          if (item?.field?.type === LookupFieldTypes.Date || item?.field?.type === LookupFieldTypes.Time) {
            value = moment(lookup[item.accessor]).format(DATE_FORMAT);
          }
          fields.push({ label: item.Header, value });
        });
        let singleCard = {
          ...lookup,
          id: lookup.id,
          title: lookup.name,
          fields: fields,
          status: item.value,
          description: lookup?.amount ? `₹${lookup.amount}` : ``
        };
        return singleCard;
      });

      const record = {
        id: item.id,
        title: `${item.value}(${cards?.length})`,
        cards: cards
      };

      return record;
    });
    return { columns: stageCards };
  }, [lookupDetails, tableColumns, lookupSegmentData]);

  const updateDealStage = useCallback(
    async (lookup_id, value) => {
      const payload = {
        field: lookupDetails,
        value: value
      };
      await dispatch(updateLookupField(organization_id, lookup_id, payload));
    },
    [dispatch, organization_id, lookupDetails]
  );

  const CardBoard = useMemo(() => {
    return (
      <ControlledBoard
        board={boardColumns}
        updateDealStage={updateDealStage}
        tableId={table?.id}
        tableName={table?.name}
        currency={organization?.settings?.currency}
      />
    );
  }, [boardColumns, updateDealStage, table?.id, table?.name, organization?.settings?.currency]);
  return (
    <>
      <DealsWrapper>
        <Header
          title='Add Deal'
          table={table}
          table_id={table?.id}
          segmentId={segmentId}
          segments={segments}
          openAddModal={openAddModal}
          primary={true}
          exportData={exportAllData}
          props={props}
        />
        <BoardWrapper mobile={mobile}>{CardBoard}</BoardWrapper>
      </DealsWrapper>
      <Modal open={isOpenAddModal} onClose={closeAddModal}>
        <AddNewContact
          handleCloseModal={closeAddModal}
          onSave={onSave}
          table={table}
          // itemId={selectedItemId}
          {...props}
        />
      </Modal>
    </>
  );
};
export default Deals;
