import React, { useMemo } from 'react';
import { isMAC, isWindows } from '../../global/Helper';

const PlatformText = ({ defaultText, mac, win }) => {
  const macApp = useMemo(isMAC, []);
  const windowApp = useMemo(isWindows, []);

  if (macApp && mac) {
    return <>{mac}</>;
  } else if (windowApp && win) {
    return <>{win}</>;
  } else {
    return <>{defaultText}</>;
  }
};

export default PlatformText;
