import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Button from '../../../components/atom/Button/Button';
import { getEmailSyncSettings, updateSyncSettings } from '../../../services/emailService';
import DropDown from '../../../components/DropDown/DropDown';
import { EMAIL_SYNC_START_FROM_TYPE, EMAIL_SYNC_WITH_TYPE, SYNC_PAST_EMAIL_TYPE } from '../../../constants/constant';
import { setEmailSyncSettings, updateSyncSettingsFields } from '../../../actions/emailActions';
import { openIntercomChat, trackAnalyticActivity } from '../../../services/analyticsService';
import { getAppHostName } from '../../../helpers/common';
import { ButtonWrapper, HeaderWrapper } from '../EmailTemplate/styles';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  MainContact,
  TitleView,
  TitleText,
  Form,
  BottomView,
  BottomWrapper,
  SynceWrapper,
  HeadingTitle,
  ListWrapper,
  ListTitel,
  ModifyUserOptionItemListLi,
  LabelInput,
  KnowTitel,
  Multi,
  SvgWrapper
} from './styles';
const SyncSettings = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: orgId } = organization;

  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);

  const onClose = useCallback(() => {
    if (props.handleCloseModal) props.handleCloseModal();
  }, [props]);

  const dispatch = useDispatch();
  const id = props?.Id;

  const loadData = useCallback(() => {
    dispatch(getEmailSyncSettings(orgId, id));
  }, [dispatch, orgId, id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setEmailSyncSettings());
    };
  }, [dispatch, loadData]);

  const emailSelector = useSelector((state) => state.email);
  const { syncSettings, loading } = emailSelector;

  const onUpdateSettingsField = useCallback(
    (fieldName, FieldVal) => {
      dispatch(updateSyncSettingsFields({ propName: fieldName, value: FieldVal }));
    },
    [dispatch]
  );

  const trackSyncAnalyticActivity = useCallback((emailId, syncedItem) => {
    try {
      const activityType = 'sync configuration changed';

      trackAnalyticActivity('settings - email: sync setting changed', {
        type: activityType,
        'email id': emailId,
        'sync configuration': syncedItem
      });
    } catch (e) {
      console.log('track settings - email: action Error', e);
    }
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const params = syncSettings;
      const result = await dispatch(updateSyncSettings(orgId, id, params));
      if (result) {
        onClose();
        trackSyncAnalyticActivity(syncSettings?.email, syncSettings);
      }
    },
    [dispatch, onClose, trackSyncAnalyticActivity, orgId, id, syncSettings]
  );
  return (
    <MainContact mobile={mobile}>
      <TitleView>
        <HeaderWrapper>
          <SVGIcon fill='var(--gray-icon-color)' size={22} name='icon-refresh' />
          <TitleText>Which emails should be synced?</TitleText>
        </HeaderWrapper>
        <SvgWrapper onClick={onClose}>
          <SVGIcon fill='var(--gray-icon-color)' size={20} name='icon-close' title='Close' />
        </SvgWrapper>
      </TitleView>
      <Form
        onSubmit={(e) => {
          onSubmit(e);
        }}>
        {loading && isEmpty(syncSettings) ? (
          <SynceWrapper>Loading...</SynceWrapper>
        ) : (
          <SynceWrapper>
            <HeadingTitle>
              You’re about to connect {syncSettings?.email} with {appName}.
            </HeadingTitle>
            <ListWrapper>
              <ListTitel>Sync emails with:</ListTitel>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  All emails will be synced with {appName}
                  <input
                    type='radio'
                    name='radio'
                    checked={syncSettings?.syncWith === EMAIL_SYNC_WITH_TYPE.SYNC_ALL_EMAIL}
                    onChange={() => {
                      onUpdateSettingsField('syncWith', EMAIL_SYNC_WITH_TYPE.SYNC_ALL_EMAIL);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  Only emails with certain labels will be synced with Salescamp
                  <input
                    type='radio'
                    name='radio'
                    checked={syncSettings?.syncWith === EMAIL_SYNC_WITH_TYPE.SYNC_WITH_LABELS}
                    onChange={() => {
                      onUpdateSettingsField('syncWith', EMAIL_SYNC_WITH_TYPE.SYNC_WITH_LABELS);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
                {syncSettings?.syncWith === EMAIL_SYNC_WITH_TYPE.SYNC_WITH_LABELS && (
                  <>
                    <Multi>MULTISELECT</Multi>
                    <DropDown
                      options={syncSettings?.accountLabels}
                      labelField={'display_name'}
                      valueField={'id'}
                      value={syncSettings?.syncLabels}
                      isMulti={true}
                      onChange={(value) => {
                        onUpdateSettingsField('syncLabels', value);
                      }}
                      placeholder={'Select Labels'}
                    />
                  </>
                )}
              </ModifyUserOptionItemListLi>
            </ListWrapper>
            <ListWrapper>
              <ListTitel>Sync starting form:</ListTitel>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  Now
                  <input
                    type='radio'
                    name='month'
                    checked={syncSettings?.syncStartFrom === EMAIL_SYNC_START_FROM_TYPE.NOW}
                    onChange={() => {
                      onUpdateSettingsField('syncStartFrom', EMAIL_SYNC_START_FROM_TYPE.NOW);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  1 month ago
                  <input
                    type='radio'
                    name='month'
                    checked={syncSettings?.syncStartFrom === EMAIL_SYNC_START_FROM_TYPE.ONE_MONTH}
                    onChange={() => {
                      onUpdateSettingsField('syncStartFrom', EMAIL_SYNC_START_FROM_TYPE.ONE_MONTH);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  3 month ago
                  <input
                    type='radio'
                    name='month'
                    checked={syncSettings?.syncStartFrom === EMAIL_SYNC_START_FROM_TYPE.THREE_MONTH}
                    onChange={() => {
                      onUpdateSettingsField('syncStartFrom', EMAIL_SYNC_START_FROM_TYPE.THREE_MONTH);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  6 month ago
                  <input
                    type='radio'
                    name='month'
                    checked={syncSettings?.syncStartFrom === EMAIL_SYNC_START_FROM_TYPE.SIX_MONTH}
                    onChange={() => {
                      onUpdateSettingsField('syncStartFrom', EMAIL_SYNC_START_FROM_TYPE.SIX_MONTH);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <KnowTitel>
                Your perferred option is not listed?{' '}
                <span
                  onClick={() => {
                    openIntercomChat();
                  }}>
                  Let us know
                </span>
              </KnowTitel>
            </ListWrapper>
            <ListWrapper>
              <ListTitel>Sync past emails sent to and received from:</ListTitel>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  {appName} contacts only
                  <input
                    type='radio'
                    name='from'
                    checked={syncSettings?.syncPastEmail === SYNC_PAST_EMAIL_TYPE.SALESCAMP_ONLY}
                    onChange={() => {
                      onUpdateSettingsField('syncPastEmail', SYNC_PAST_EMAIL_TYPE.SALESCAMP_ONLY);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  All people
                  <input
                    type='radio'
                    name='from'
                    checked={syncSettings?.syncPastEmail === SYNC_PAST_EMAIL_TYPE.ALL_PEOPLE}
                    onChange={() => {
                      onUpdateSettingsField('syncPastEmail', SYNC_PAST_EMAIL_TYPE.ALL_PEOPLE);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <KnowTitel>
                This won’t affect your future emails. All emails in the selected folder will be synced.
              </KnowTitel>
            </ListWrapper>
          </SynceWrapper>
        )}
        <BottomView>
          <BottomWrapper>
            <ButtonWrapper>
              <Button loading={loading} primary title='Start Syncing' type='submit' />
            </ButtonWrapper>
            <Button secondary title='Cancel' onClick={onClose} type='button' />
          </BottomWrapper>
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default SyncSettings;
