import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from './../actions/types';

const phoneCallLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_PHONE_CALL_LOADER
});

const initialState = null;
const phoneCallDetailReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_PHONE_CALL_DETAILS, (state = initialState, action) => {
      return action.payload || null;
    })
    .addCase(Actions.UPDATE_PHONE_CALL_DETAILS, (state, action) => {
      if (state) {
        const details = current(state) ? { ...state } : undefined;
        if (details) {
          details[action.payload.propName] = action.payload.value;
          Object.preventExtensions(details);
        }
        return details;
      }
    });
});

const twilioChargesReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_TWILIO_CHARGES
});

const twilioCallInputDevicesReducer = createReducer({
  initialState: [],
  actionType: Actions.UPDATE_INPUT_DEVICES
});

const twilioCallOutputDevicesReducer = createReducer({
  initialState: [],
  actionType: Actions.UPDATE_OUTPUT_DEVICES
});

const initialSmsState = {
  message: '',
  phone: {},
  to: []
};
const sendSmsReducer = createReducerOrig(initialSmsState, (builder) => {
  builder
    .addCase(Actions.SET_SMS_ITEM, (state = initialSmsState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_SMS_ITEM, (state, action) => {
      const sms = { ...state };
      sms[action.payload.propsName] = action.payload.value;
      return { ...sms };
    })
    .addCase(Actions.ADD_SMS_TO_ITEM, (state, action) => {
      const sms = { ...state };
      const to = sms?.to?.slice();
      to?.push(action.payload.item);
      sms.to = to;
      return { ...sms };
    })

    .addCase(Actions.CLEAR_SMS_ITEM, () => {
      const smsState = JSON.parse(JSON.stringify(initialSmsState));
      return smsState;
    });
});

const phoneCallReducer = combineReducers({
  loading: phoneCallLoaderReducer,
  details: phoneCallDetailReducer,
  charges: twilioChargesReducer,
  sms: sendSmsReducer,
  callInputDevices: twilioCallInputDevicesReducer,
  callOutputDevices: twilioCallOutputDevicesReducer
});

export default phoneCallReducer;
