import React, { useCallback, useEffect, useState } from 'react';
import DropDown from '../../../components/DropDown/DropDown';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import { countryWithCodes } from '../../../data/raw';
import { getIPLocation } from '../../../services/twilioService';
import Validation from '../../../components/Validation/Validation';
import { isEmpty } from '../../../helpers/common';
import {
  Form,
  Step2,
  TwoInput,
  ListWrappper,
  DropDownTitle,
  BottomView,
  LeftWrapper,
  BtnView,
  ErrorMsg
} from './styles';

const AddExternalPhoneNumber = ({ onOpenVerifyNubModal, onClose }) => {
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const ipLocation = getIPLocation();
    const countryCode = ipLocation?.country_code;
    if (countryCode) {
      const country = countryWithCodes.find((x) => (x.countryCode = countryCode));
      const code = country.code;
      setCountryCode(code || '+91');
    }
  }, []);

  const onVerifyPhoneNumber = useCallback(() => {
    const validationErrors = {};
    if (countryCode === '+91' || countryCode === '+1') {
      if (phoneNumber?.length !== 10) {
        validationErrors.phoneNumber = 'Phone number of length 10 is Required';
        setValidationErrors(validationErrors);
        return;
      }
    } else {
      if (phoneNumber?.length < 8) {
        validationErrors.phoneNumber = 'Phone number of minimum length 8 is Required';
        setValidationErrors(validationErrors);
        return;
      }
    }
    setValidationErrors();
    if (countryCode && phoneNumber) {
      onClose();
      onOpenVerifyNubModal({ countryCode, phoneNumber });
    }
  }, [onClose, phoneNumber, onOpenVerifyNubModal, countryCode]);

  return (
    <>
      <Step2>
        <Form>
          <TwoInput>
            <ListWrappper>
              <DropDownTitle>Country</DropDownTitle>
              <DropDown
                menuPlacement={'top'}
                options={countryWithCodes}
                labelField={'text'}
                valueField={'code'}
                value={countryCode}
                placeholder={'united States (+1)'}
                onChange={(value) => {
                  setCountryCode(value.code);
                }}
              />
            </ListWrappper>
            <Input
              title='Phone Number To Add'
              isPrimarySmall
              type='number'
              autocomplete='off'
              placeholder='e.g. 222-444-5555'
              isRequierd
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </TwoInput>
          <ErrorMsg>
            {!isEmpty(validationErrors) && <Validation error={'* This fields are requierd'}></Validation>}
          </ErrorMsg>
          <BottomView>
            <LeftWrapper>
              <Button
                type='button'
                title='Verify Number'
                onClick={() => {
                  onVerifyPhoneNumber();
                }}
              />
              <BtnView>
                <Button secondary title='Cancel' type='button' onClick={onClose} />
              </BtnView>
            </LeftWrapper>
          </BottomView>
        </Form>
      </Step2>
    </>
  );
};

export default AddExternalPhoneNumber;
