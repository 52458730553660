import React, { useMemo, useCallback, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  createActivity,
  getActivity,
  trackActivityActionAnalyticActivity,
  trackActivityAnalyticActivity,
  updateActivityItems,
  uploadFile
} from '../../../../services/activityService';
import {
  addAttendeesItem,
  updateAttendeesItem,
  deleteAttendeesItem,
  updateActivityItem,
  setActivityItem,
  setActivityValidationError
} from '../../../../actions/activityActions';
import { getAllLookup } from '../../../../services/lookupService';
import { hasUserAccessAndPermission } from '../../../../services/appService';
import { getSecondsFromHHMMSS, toHHMMSS } from '../../../../helpers/common';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import { callLog } from './../../../../data/raw';
import { isEmpty } from './../../../../global/Helper';
import {
  ACTIVITY_ACTION,
  HEADER_TITLE_BY_ACTIVITY_TYPE,
  ICON_NAME_BY_ACTIVITY_TYPE,
  FILE_VALIDATION_MSG,
  RTE_PLACEHOLDER_BY_ACTIVITY_TYPE,
  TITLE_BY_ACTIVITY_TYPE,
  FILE_VALIDATION_SIZE
} from './../../../../constants/constant';
import Input from './../../../../components/Input/Input';
import Validation from './../../../../components/Validation/Validation';
import Button from './../../../../components/atom/Button/Button';
import DropDown from './../../../../components/DropDown/DropDown';
import { DatePicker, TimePicker } from './../../../../components/DateTime';
import SVGIcon from './../../../../assets/svg/SVGIcon';
import {
  ModalHeader,
  ModalHeaderTitel,
  LeftWrapper,
  RightWrapper,
  MeetingInputWrapper,
  MeetingInputWrapperTwo,
  Footer,
  MainForm,
  IconWrapper,
  DateBlock,
  DateWrapper,
  Span,
  InputOne,
  Switch,
  SpanText,
  ToggleDiv,
  NoteInputWrapper,
  InputBlock,
  CallLog,
  ValidationWrapper,
  BtnWrapper,
  DrogWrapper,
  TitleDrog,
  ManinWrapper,
  FileUpload,
  AssignToWrapper,
  InView,
  SvgWrapper,
  FormWrapper,
  DateMainPicker,
  TimeMainPicker,
  DragMainWrap,
  FormMainInner
} from './styles';
import AddCommentActivity from './AddCommentActivity';
export default function AddActivity({ ...props }) {
  const { type, lookup, selectedActivity } = props;
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const authSelector = useSelector((state) => state.auth);
  const activitySelector = useSelector((state) => state.activity);
  const lookupSelector = useSelector((state) => state.lookup);

  const { currentUser } = authSelector;
  const { organization } = organizationSelector;
  const { activityLoading, activityItem, resError } = activitySelector;
  const { id: activity_id } = selectedActivity || {};
  const { lookups } = lookupSelector;
  const { id: organization_id, users } = organization;

  const {
    isAllDay,
    title,
    date,
    time,
    toDate,
    toTime,
    attendees,
    channel,
    location,
    description,
    file,
    fileURL,
    duration,
    id: activityId
  } = activityItem;

  const isShowLookups = !lookup;

  useEffect(() => {
    if (activity_id) {
      trackActivityActionAnalyticActivity('edit');
      if (selectedActivity?.duration) {
        const seconds = Math.max(0, getSecondsFromHHMMSS(`${selectedActivity?.duration}`));
        const times = toHHMMSS(seconds);
        selectedActivity.duration = times;
      }
      dispatch(setActivityItem(selectedActivity));
      dispatch(getActivity(organization_id, activity_id));
    } else {
      if (!lookup) {
        dispatch(getAllLookup());
      }
      let user = {
        id: currentUser?.id,
        name: currentUser?.given_name,
        email: currentUser?.email
      };

      let lookupObj = lookup
        ? {
            id: lookup?.id,
            name: lookup?.name,
            email: lookup?.email
          }
        : undefined;

      dispatch(updateActivityItem({ propsName: 'lookup', value: lookupObj }));
      dispatch(updateActivityItem({ propsName: 'assignedUser', value: user }));
      dispatch(updateActivityItem({ propsName: 'title', value: TITLE_BY_ACTIVITY_TYPE[type] }));
      dispatch(updateActivityItem({ propsName: 'companyId', value: organization_id }));
      if (toggleItem?.isShowStartTime) {
        dispatch(updateActivityItem({ propsName: 'time', value: moment().format('HH:mm') }));
      }
      if (toggleItem?.isShowEndTime) {
        dispatch(updateActivityItem({ propsName: 'toTime', value: moment().add(30, 'minutes').format('HH:mm') }));
        dispatch(updateActivityItem({ propsName: 'toDate', value: new Date() }));
      }
      const blanckAttendees = { email: '', name: '' };
      dispatch(addAttendeesItem({ attendeesItem: blanckAttendees }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPermission = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('task', 'permissionEdit'));
    return {
      permissionEdit
    };
  }, [dispatch]);

  const getTimeDifference = () => {
    if (time < toTime) {
      var start = moment.duration(time, 'HH:mm');
      var end = moment.duration(toTime, 'HH:mm');
      var diff = end.subtract(start);
      const hour = Math.abs(diff.hours()) + 'h';
      const minute = Math.abs(diff.minutes()) + 'm';
      const totalTime = (hour !== '0h' ? hour : '') + minute;
      return totalTime;
    }
  };

  const toggleItem = useMemo(() => {
    const isShowTitle =
      type === ACTIVITY_ACTION.Meeting || type === ACTIVITY_ACTION.Tasks || type === ACTIVITY_ACTION.File;
    const isShowAttendees = type === ACTIVITY_ACTION.Meeting;
    const isShowStartDate =
      type !== ACTIVITY_ACTION.Notes && type !== ACTIVITY_ACTION.File && type !== ACTIVITY_ACTION.Comment;
    const isShowStartTime = type !== ACTIVITY_ACTION.Notes && !isAllDay && type !== ACTIVITY_ACTION.File;
    const isShowEndDate = type === ACTIVITY_ACTION.Meeting;
    const isShowEndTime = type === ACTIVITY_ACTION.Meeting && !isAllDay;
    const isShowAllDayEvent = type === ACTIVITY_ACTION.Meeting;
    const isShowChannel = type === ACTIVITY_ACTION.Meeting;
    const isShowLocation = type === ACTIVITY_ACTION.Meeting;
    const isShowNote = type === ACTIVITY_ACTION.Notes;
    const isShowSummary = type !== ACTIVITY_ACTION.Notes || type === ACTIVITY_ACTION.File;
    const isShowIncoming = type === ACTIVITY_ACTION.Call;
    const isShowFile = type === ACTIVITY_ACTION.File;

    return {
      isShowTitle,
      isShowAttendees,
      isShowStartDate,
      isShowStartTime,
      isShowEndDate,
      isShowEndTime,
      isShowAllDayEvent,
      isShowChannel,
      isShowLocation,
      isShowNote,
      isShowSummary,
      isShowIncoming,
      isShowFile
    };
  }, [type, isAllDay]);

  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
      [{ list: 'ordered' }],
      [{ list: 'bullet' }],
      ['clean']
    ]
  };

  const onChangeStartTime = useCallback(
    (e) => {
      dispatch(updateActivityItem({ propsName: 'time', value: e.target.value }));
    },
    [dispatch]
  );

  const onChangeEndTime = useCallback(
    (e) => {
      dispatch(updateActivityItem({ propsName: 'toTime', value: e.target.value }));
    },
    [dispatch]
  );

  const handleChangeAllDayEvent = useCallback(() => {
    if (isAllDay) {
      dispatch(
        updateActivityItem({
          propsName: 'time',
          value: moment().add().format('HH:mm')
        })
      );
      dispatch(
        updateActivityItem({
          propsName: 'toTime',
          value: moment().add(30, 'minutes').format('HH:mm')
        })
      );
    }
    dispatch(updateActivityItem({ propsName: 'isAllDay', value: !isAllDay }));
  }, [isAllDay, dispatch]);

  const setSelectedCallLog = useCallback(
    (item) => {
      dispatch(updateActivityItem({ propsName: 'title', value: item.value }));
    },
    [dispatch]
  );

  const addNewBlanckAttendees = useCallback(() => {
    const blanckAttendees = { email: '', name: '' };
    dispatch(addAttendeesItem({ attendeesItem: blanckAttendees }));
  }, [dispatch]);

  const handleChangeAttendees = useCallback(
    (e, index) => {
      if (attendees.length - 1 === index) {
        addNewBlanckAttendees();
      }
      const item = { name: '', email: e.target.value };
      dispatch(updateAttendeesItem({ index, attendeesItem: item }));
    },
    [attendees, addNewBlanckAttendees, dispatch]
  );

  const onRemoveAttendees = useCallback(
    (index) => {
      dispatch(deleteAttendeesItem({ index }));
    },
    [dispatch]
  );

  const clearResError = useCallback(() => {
    dispatch(setActivityValidationError(null));
  }, [dispatch]);

  const onChangeFile = useCallback(
    async (event) => {
      clearResError();
      const file = event.target.files[0];
      if (!file) return;
      const fileSize = file?.size.toFixed(2);
      if (fileSize > FILE_VALIDATION_SIZE) {
        dispatch(setActivityValidationError({ file: FILE_VALIDATION_MSG }));
        return;
      }
      const fileName = file?.name;
      if (!title) dispatch(updateActivityItem({ propsName: 'title', value: fileName }));
      dispatch(updateActivityItem({ propsName: 'file', value: file }));
    },
    [clearResError, dispatch, title]
  );

  const isShowFileInput = useMemo(() => {
    const isShowFileInput = toggleItem.isShowFile && !file && !fileURL;
    return isShowFileInput;
  }, [file, fileURL, toggleItem.isShowFile]);

  const isShowFileName = useMemo(() => {
    const isShowFileName = file || fileURL;
    return isShowFileName;
  }, [file, fileURL]);

  const onBlur = useCallback(
    (event) => {
      const value = event.target.value;
      const seconds = Math.max(0, getSecondsFromHHMMSS(value));

      const times = toHHMMSS(seconds);
      dispatch(updateActivityItem({ propsName: 'duration', value: times }));
    },
    [dispatch]
  );

  const onCreateActivity = async (e) => {
    e.preventDefault();
    clearResError();
    let durationTime = [];
    let resError = {};
    if (toggleItem.isShowTitle && !title) {
      resError['title'] = 'Title is Required';
    } else if (toggleItem.isShowStartDate && !date) {
      resError['date'] = 'Starting Date is Required';
    } else if (toggleItem.isShowStartTime && isEmpty(time)) {
      resError['time'] = 'Starting time is Required';
    } else if (toggleItem.isShowEndDate && !toDate) {
      resError['toDate'] = 'Ending date is Required';
    } else if (toggleItem.isShowEndTime && !toTime) {
      resError['toTime'] = 'Ending time is Required';
    } else if (toggleItem.isShowAttendees && attendees?.length > 1 && !attendees?.filter((x) => x.email).length > 0) {
      resError['attendees'] = 'Attendees are Required';
    } else if (!fileURL && toggleItem.isShowFile && !file) {
      resError['file'] = 'File is Required';
    } else if (type === ACTIVITY_ACTION.Tasks && isShowLookups && !activityItem?.lookup) {
      resError['lookup'] = 'Task is Required';
    } else if (type === ACTIVITY_ACTION.Call) {
      if (isEmpty(duration)) {
        resError['duration'] = 'Call Duration is Required';
      } else {
        durationTime = duration?.split(':');
        if (durationTime?.length === 2 && moment(duration, 'mm:ss').format('mm:ss') === 'Invalid date') {
          resError['duration'] = 'enter valid time';
        }
        if (durationTime?.length === 3 && moment(duration, 'HH:mm:ss').format('HH:mm:ss') === 'Invalid date') {
          resError['duration'] = 'enter valid time';
        }
      }
    }
    if (!isEmpty(resError)) {
      dispatch(setActivityValidationError(resError));
      return;
    }
    let seconds = 0;
    if (durationTime?.length === 2) {
      seconds = Number(durationTime[0] * 60) + Number(durationTime[1]);
    } else if (durationTime?.length === 3) {
      seconds = Number(durationTime[0] * 60 * 60) + Number(durationTime[1] * 60) + Number(durationTime[2]);
    }
    let newTitle = '';
    if (type === ACTIVITY_ACTION.Comment) {
      newTitle = title?.replaceAll('@', '');
    }
    let obj = {
      ...activityItem,
      title: type === ACTIVITY_ACTION.Comment ? newTitle : title,
      action: type,
      taskStatus:
        type === ACTIVITY_ACTION.Notes ||
        type === ACTIVITY_ACTION.File ||
        type === ACTIVITY_ACTION.Call ||
        type === ACTIVITY_ACTION.Comment
          ? true
          : undefined,
      date: new Date(`${moment(date).format('YYYY-MMM-DD')}`),
      time: toggleItem.isShowStartTime ? new Date(moment(date).format('YYYY-MMM-DD') + ' ' + time) : undefined,
      toDate: toggleItem.isShowEndDate ? new Date(moment(toDate).format('YYYY-MM-DD')) : undefined,
      toTime: toggleItem.isShowEndTime ? new Date(moment(date).format('YYYY-MMM-DD') + ' ' + toTime) : undefined,
      isAllDay: toggleItem.isShowAllDayEvent ? isAllDay : undefined,
      attendees: toggleItem.isShowAttendees ? attendees?.filter((x) => x.email) : undefined,
      duration: seconds
    };
    let result;
    if (activity_id) {
      result = await dispatch(updateActivityItems(organization_id, activityId, obj));
    } else {
      if (toggleItem.isShowFile) {
        if (!file) return;
        const url = await dispatch(uploadFile(organization_id, file));
        if (!url) return;
        obj.fileURL = url;
      }
      result = await dispatch(createActivity(organization_id, obj));
    }
    if (result) {
      closeMenu();
      if (activity_id) {
        trackActivityAnalyticActivity('updated');
      } else {
        trackActivityAnalyticActivity('added');
      }
    }
  };

  const closeMenu = useCallback(() => {
    props.onMenuClose();
    if (props.onSave) props.onSave();
  }, [props]);

  return (
    <MainForm eventType={type}>
      <ModalHeader isNotShowBorder={toggleItem?.isShowNote}>
        <LeftWrapper>
          <SVGIcon name={ICON_NAME_BY_ACTIVITY_TYPE[type]} fill='var(--gray-icon-color)' size={22} />
          <ModalHeaderTitel>{HEADER_TITLE_BY_ACTIVITY_TYPE[type]}</ModalHeaderTitel>
        </LeftWrapper>
        <RightWrapper onClick={closeMenu}>
          <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
        </RightWrapper>
      </ModalHeader>
      <FormWrapper eventType={type}>
        <FormMainInner>
          {toggleItem.isShowTitle && (
            <MeetingInputWrapper alignItems>
              <IconWrapper iconMarginTop>
                <SVGIcon name='icon-edit' fill='var(--gray-icon-color)' size={22} />
              </IconWrapper>
              <ValidationWrapper>
                <Input
                  isPrimarySmall
                  placeholder='Add Title'
                  name='title'
                  value={title}
                  onChange={(e) => {
                    dispatch(updateActivityItem({ propsName: 'title', value: e.target.value }));
                  }}
                />
                {!isEmpty(resError) && <Validation error={resError?.title} />}
              </ValidationWrapper>
            </MeetingInputWrapper>
          )}
          {toggleItem.isShowAttendees && (
            <MeetingInputWrapper alignItems>
              <IconWrapper iconMarginTop>
                <SVGIcon name='icon-add-account' fill='var(--gray-icon-color)' size={22} />
              </IconWrapper>
              <ManinWrapper>
                {attendees &&
                  attendees.length > 0 &&
                  attendees.map((item, index) => {
                    return (
                      <InputBlock key={index} oneItem={attendees?.length > 1}>
                        <Input
                          isPrimarySmall
                          placeholder='Add required attendees'
                          value={item?.email}
                          onChange={(value) => {
                            handleChangeAttendees(value, index);
                          }}
                        />
                        {index !== 0 && (
                          <SvgWrapper
                            onClick={() => {
                              onRemoveAttendees(index);
                            }}>
                            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={16} />
                          </SvgWrapper>
                        )}
                      </InputBlock>
                    );
                  })}
              </ManinWrapper>
            </MeetingInputWrapper>
          )}
          {toggleItem.isShowStartDate && (
            <MeetingInputWrapper alignItems>
              {toggleItem.isShowStartDate && (
                <IconWrapper iconMarginTop>
                  <SVGIcon name='icon-timer' fill='var(--gray-icon-color)' size={22} />
                </IconWrapper>
              )}
              {toggleItem.isShowStartDate && (
                <DateBlock>
                  <DateWrapper eventType={type} mobile={mobile}>
                    <DateMainPicker>
                      <DatePicker
                        className={toggleItem.isShowStartTime ? 'DatePicker' : 'DatePickerTwo'}
                        autoOk={'true'}
                        value={date}
                        onChange={(date) => {
                          dispatch(updateActivityItem({ propsName: 'date', value: date.toDateString() }));
                        }}
                      />
                      {!isEmpty(resError) && <Validation error={resError?.date} />}
                    </DateMainPicker>
                    <TimeMainPicker>
                      {toggleItem.isShowStartTime && (
                        <TimePicker
                          className='TimePicker'
                          defaultValue={new Date().getHours() + `:` + new Date().getMinutes()}
                          value={time}
                          onChange={onChangeStartTime}
                        />
                      )}
                      {!isEmpty(resError) && <Validation error={resError?.time} />}
                    </TimeMainPicker>
                  </DateWrapper>
                  {type === ACTIVITY_ACTION.Meeting && (
                    <DateWrapper mobile={mobile}>
                      {toggleItem.isShowEndDate && (
                        <DatePicker
                          className={toggleItem.isShowEndTime ? 'DatePicker' : 'DatePickerTwo'}
                          autoOk={'true'}
                          value={toDate}
                          onChange={(date) => {
                            dispatch(updateActivityItem({ propsName: 'toDate', value: date.toDateString() }));
                          }}
                        />
                      )}
                      {!isEmpty(resError) && <Validation error={resError?.toDate} />}
                      {toggleItem.isShowEndTime && (
                        <TimePicker
                          className='TimePicker'
                          defaultValue={new Date().getHours() + `:` + new Date().getMinutes()}
                          value={toTime}
                          onChange={onChangeEndTime}
                        />
                      )}
                      {!isEmpty(resError) && <Validation error={resError?.toTime} />}
                      {toggleItem.isShowAllDayEvent && (
                        <ToggleDiv mobile={mobile}>
                          {!isAllDay && <SpanText> {getTimeDifference()} </SpanText>}
                          <Switch className='switch'>
                            <InputOne
                              type='checkbox'
                              checked={isAllDay}
                              value={isAllDay}
                              onChange={handleChangeAllDayEvent}
                            />
                            <Span className='slider round'></Span>
                          </Switch>
                          <SpanText>All Day</SpanText>
                        </ToggleDiv>
                      )}
                    </DateWrapper>
                  )}
                </DateBlock>
              )}
            </MeetingInputWrapper>
          )}
          {toggleItem.isShowChannel && (
            <MeetingInputWrapper>
              <IconWrapper>
                <SVGIcon name='icon-add-channel' fill='var(--gray-icon-color)' size={22} />
              </IconWrapper>
              <Input
                isPrimarySmall
                placeholder='Add channel'
                value={channel}
                onChange={(e) => {
                  dispatch(updateActivityItem({ propsName: 'channel', value: e.target.value }));
                }}
              />
            </MeetingInputWrapper>
          )}
          {toggleItem.isShowLocation && (
            <MeetingInputWrapper>
              <IconWrapper>
                <SVGIcon name='icon-location' fill='var(--gray-icon-color)' size={22} />
              </IconWrapper>
              <Input
                isPrimarySmall
                placeholder='Add location'
                value={location}
                onChange={(e) => {
                  dispatch(updateActivityItem({ propsName: 'location', value: e.target.value }));
                }}
              />
            </MeetingInputWrapper>
          )}
          {toggleItem.isShowNote && (
            <NoteInputWrapper mobile={mobile}>
              <ReactQuill
                theme='snow'
                className='snow'
                modules={modules}
                placeholder={RTE_PLACEHOLDER_BY_ACTIVITY_TYPE[type]}
                value={description || ''}
                onChange={(value) => {
                  dispatch(updateActivityItem({ propsName: 'description', value: value }));
                }}
              />
            </NoteInputWrapper>
          )}
          {toggleItem.isShowIncoming && (
            <CallLog>
              <IconWrapper iconMarginTop>
                <SVGIcon name='icon-phone' fill='var(--gray-icon-color)' size={22} />
              </IconWrapper>
              <InView>
                <Input
                  isPrimarySmall
                  placeholder='00:00:00'
                  value={duration}
                  onBlur={onBlur}
                  onChange={(e) => {
                    if (e.target.value.length <= 8) {
                      dispatch(updateActivityItem({ propsName: 'duration', value: e.target.value }));
                    }
                  }}
                />
                {!isEmpty(resError) && <Validation error={resError?.duration} />}
              </InView>
              <DropDown
                placeholder={'Select Call Log'}
                className='dropDown'
                options={callLog}
                labelField={'label'}
                valueField={'value'}
                value={title}
                onChange={(value) => {
                  setSelectedCallLog(value);
                }}
              />
            </CallLog>
          )}
          {type === ACTIVITY_ACTION.Tasks && isShowLookups && (
            <AssignToWrapper>
              <DropDown
                options={lookups || []}
                labelField={'name'}
                valueField={'id'}
                hasObject={true}
                value={activityItem?.lookup}
                placeholder={'Contacts'}
                onChange={(values) => {
                  dispatch(updateActivityItem({ propsName: 'lookup', value: values }));
                }}
              />
              {!isEmpty(resError) && <Validation error={resError?.lookup} />}
            </AssignToWrapper>
          )}
          {type === ACTIVITY_ACTION.Tasks && (
            <AssignToWrapper>
              <DropDown
                options={users || []}
                labelField={'name'}
                valueField={'id'}
                placeholder={'AssignTo'}
                hasObject={true}
                value={activityItem?.assignedUser}
                onChange={(values) => {
                  dispatch(updateActivityItem({ propsName: 'assignedUser', value: values }));
                }}
              />
              {!isEmpty(resError) && <Validation error={resError?.lookup} />}
            </AssignToWrapper>
          )}
          {toggleItem.isShowSummary && (
            <MeetingInputWrapperTwo mobile={mobile}>
              {toggleItem.isShowIncoming ? (
                <SVGIcon name='icon-document' fill='var(--gray-icon-color)' size={22} />
              ) : (
                type !== ACTIVITY_ACTION.Comment && (
                  <SVGIcon name='icon-rich-text' fill='var(--gray-icon-color)' size={22} />
                )
              )}
              {type === ACTIVITY_ACTION.Comment ? (
                <AddCommentActivity
                  value={title}
                  isAddActivty={true}
                  onChange={(value) => {
                    dispatch(updateActivityItem({ propsName: 'title', value }));
                  }}
                />
              ) : (
                <ReactQuill
                  theme='snow'
                  className='snow'
                  modules={modules}
                  placeholder={RTE_PLACEHOLDER_BY_ACTIVITY_TYPE[type]}
                  value={description || ''}
                  onChange={(value) => {
                    dispatch(updateActivityItem({ propsName: 'description', value: value }));
                  }}
                />
              )}
            </MeetingInputWrapperTwo>
          )}
          {isShowFileInput && (
            <DragMainWrap>
              <DrogWrapper mobile={mobile}>
                <Input
                  disabled={activity_id}
                  id='file'
                  type='file'
                  isPrimary={true}
                  onChange={(e) => {
                    onChangeFile(e);
                  }}
                />
                <TitleDrog mobile={mobile}>Drag file here (Max 5 MB)</TitleDrog>
              </DrogWrapper>

              {!isEmpty(resError) && <Validation error={resError?.file} />}
            </DragMainWrap>
          )}
          {isShowFileName && (
            <FileUpload>
              <div>
                <span>{file?.name || fileURL?.split('/').pop()}</span>
              </div>
            </FileUpload>
          )}
        </FormMainInner>
        <Footer border={type !== ACTIVITY_ACTION.Notes}>
          <BtnWrapper>
            <Button
              primary={true}
              type={'submit'}
              loading={activityLoading}
              title={type === ACTIVITY_ACTION.Comment ? 'Post Comment' : 'save'}
              disabled={!userPermission?.permissionEdit}
              onClick={onCreateActivity}
            />
          </BtnWrapper>
          <Button secondary={true} type={'button'} title='Cancel' onClick={props.onMenuClose} />
        </Footer>
      </FormWrapper>
    </MainForm>
  );
}
