import React, { useMemo } from 'react';
import SmileLogo from '../../../../assets/images/successx.png';
import { getAppHostName } from '../../../../helpers/common';
import { MainView, ContainList, ImgView, ImgSmile, TitleText, CaptionText } from './styles';

const SuccessFul = () => {
  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);
  return (
    <MainView>
      <ContainList>
        <ImgView>
          <ImgSmile src={SmileLogo} />
        </ImgView>
        <TitleText>Payment Transfar Successful!</TitleText>
        <CaptionText>You have successfully subscribed to {appName} Pro</CaptionText>
      </ContainList>
    </MainView>
  );
};

export default SuccessFul;
