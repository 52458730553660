import styled from 'styled-components';
import { Text1 } from '../../../styles';

const MainContact = styled.div`
  position: relative;
  outline: none;
`;
const TitleText = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 12px;
  display: block;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const Form = styled.form`
  padding: 12px;
`;
const FirstList = styled.div`
  width: 100%;
  .DatePickerWrapper {
    width: 100%;
  }
`;
const SecondList2 = styled.div`
  width: 100%;
  margin-top: 12px;
  .TimePickerWrapper {
    width: 100%;
  }
`;
const LableView = styled.div`
  margin-bottom: 4px;
`;
const BottomView = styled.div`
  text-align: center;
  margin-top: 12px;
  button {
    width: 100%;
    justify-content: center;
  }
`;
const LableText = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
export { MainContact, TitleText, TitleView, Form, FirstList, SecondList2, LableView, BottomView, LableText };
