/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTable, useRowSelect, useSortBy } from 'react-table';
import useMobileDevice from '../../../hooks/useMobileDevice';
import Icon from '../../Icon/Icon';
import { Styles, StylesWrapper, Tr, ViewIcon, SpanArrow, HeaderTitle } from './Styles';

export default function TableWithoutPagination({ columns, data, initialState, openMoreMenu, component }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      initialState
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );
  const mobile = useMobileDevice();

  return (
    <>
      <StylesWrapper component={component} mobile={mobile}>
        <Styles component={component} mobile={mobile}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <HeaderTitle>
                        {column.render('Header')}
                        <SpanArrow>
                          {column?.isSorted} {column?.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                        </SpanArrow>
                      </HeaderTitle>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows?.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return index === 0 ? (
                        <th {...cell.getCellProps()}>{cell.render('Cell')}</th>
                      ) : (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                    <td>
                      {openMoreMenu && (
                        <ViewIcon>
                          <Icon
                            title='More'
                            name='icon-more'
                            fontSize='24'
                            color='var(--gray-icon-color)'
                            onClick={(e) => {
                              openMoreMenu(e, row?.original?.id);
                            }}
                          />
                        </ViewIcon>
                      )}
                    </td>
                  </Tr>
                );
              })}
            </tbody>
          </table>
        </Styles>
      </StylesWrapper>
    </>
  );
}
