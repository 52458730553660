import styled, { css } from 'styled-components';
import Button from '../../components/atom/Button/Button';
import { Header4, Text1 } from '../../styles';
const ManeWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const RightWrapper = styled.div`
  width: 100%;
  margin-left: 215px;
`;
const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px 20px 20px;
`;
const LeftView = styled.div`
  display: flex;
  align-items: center;
`;
const Ul2 = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Li2 = styled.li`
  margin: 0 24px 0 0;
  padding: 0;
  cursor: pointer;
`;
const Atag = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: var(--gray-color);
  &:active {
    color: var(--dark-color);
  }
`;
const SpnaText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #4f81d8;
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 12px;
  }
  i {
    cursor: pointer;
  }
`;
const VermoreIcon = styled.i``;
const MainContact = styled.div``;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #e4e6e8;
  svg {
    cursor: pointer;
  }
`;
const TitleText = styled.span`
  font-style: normal;
  ${Header4};
  color: var(--dark-color);
  margin-left: 10px;
  display: block;
`;
const SecondContain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px 16px;
  padding: 16px;
  overflow: auto;
  max-height: calc(100vh - 250px);
  flex-wrap: wrap;
`;
const LableView = styled.div`
  margin-bottom: 4px;
`;
const InputView = styled.div`
  width: 100%;
  display: inline-block;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 48.64%;
        `}
`;
const Border = styled.hr`
  border-bottom: 1px solid #e4e6e8;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 8px 0;
`;
const Form = styled.form``;
const BottomView = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #e4e6e8;
  padding: 16px;
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--white-color);
    `}
`;
const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const AddTagButton = styled(Button)`
  margin-left: 13px;
`;
const ModalPopupStyle = {
  width: '320px',
  overflow: 'unset'
};
const DateTimeWrapper = styled.div`
  margin-top: 18px;
  width: 100%;
  .DatePickerWrapper {
    width: 100%;
  }
  .TimePickerWrapper {
    width: 100%;
  }
`;
const SendEmailModalWidth = {
  width: '640px'
};
const InputCheckBoxView = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  div {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--white-color);
    border: 1px solid #dcdcdc;
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid #999;
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
`;
const LabelTitle = styled.span`
  ${Text1}
  color: var(--dark-color);
  padding-right: 12px;
  cursor: pointer;
`;
const Textarea = styled.textarea`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  width: 100%;
  height: 108px;
  resize: none;
  outline: none;
  padding: 7px 12px;
  font-family: var(--primary-font-stack);
  font-weight: 500;
  &:focus {
    margin: 0 -1px;
    border: 2px solid var(--primary-color);
    padding: 6px 12px;
  }
`;
const Title = styled.span`
  ${Text1}
  color: var(--dark-color);
  display: block;
  margin-bottom: 4px;
`;
const Tag = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 12px 12px 12px;
`;
const TagBox = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  height: 28px;
  padding: 0 8px;
  margin: 3px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin: 2px;
  }
  ${(props) =>
    props.margin &&
    css`
      width: 28px;
      i {
        margin: 2px 0;
      }
    `}
`;
const IconDelete = styled.div`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  :hover {
    background: var(--linear-gradient-hover);
    right: 0;
    top: 1px;
    bottom: 0;
    width: 100%;
    text-align: right;
    color: rgba(46, 62, 70, 0.5);
    border-radius: 4px;
    padding-right: 2px;
    opacity: 1;
    z-index: 1;
  }
`;
const TagText = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
`;

const TitleLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ModalCloseIcon = styled.div`
  height: 22px;
  width: 22px;
`;

const BtnWrapper = styled.div`
  margin-right: 12px;
`;

const ModalStyle = {
  width: '640px'
};

export {
  TagText,
  IconDelete,
  TagBox,
  Tag,
  ManeWrapper,
  RightWrapper,
  MainHeader,
  LeftView,
  Ul2,
  Li2,
  Atag,
  SpnaText,
  RightView,
  VermoreIcon,
  MainContact,
  TitleView,
  TitleText,
  SecondContain,
  InputView,
  BottomView,
  Form,
  Border,
  Wrapper,
  DateTimeWrapper,
  LableView,
  InputCheckBoxView,
  InputWrapper,
  LabelTitle,
  Textarea,
  Title,
  ModalStyle,
  SendEmailModalWidth,
  TitleLeft,
  ModalCloseIcon,
  BtnWrapper,
  ModalPopupStyle,
  AddTagButton
};
