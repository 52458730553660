import styled, { css } from 'styled-components';
import Button from '../../../components/atom/Button/Button';
import { Header2, Header4, Text1 } from '../../../styles';

const MainList = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`;
const MainWrap = styled.div`
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          padding: 0 16px 16px 16px;
        `
      : css`
          width: 680px;
          padding: 24px 0px;
        `}
`;
const HeadView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h2`
  margin: 0;
  ${Header2};
  color: var(--dark-color);
`;
const ContainBox = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  &:last-child {
    border-bottom: 0;
  }
`;
const FirstList = styled.div``;
const SubText = styled.h4`
  margin: 0 0 2px 0;
  font-style: normal;
  ${Header4};
  color: var(--dark-color);
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin: 0;
`;
const SecondList = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;
const LeftIcon = styled.div`
  padding-right: 15px;
  cursor: pointer;
`;
const RightIcon = styled.div`
  padding-left: 15px;
  border-left: 1px solid var(--gray-border-color);
  cursor: pointer;
`;
const MainContact = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          position: fixed;
          top: auto;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: var(--white-color);
        `
      : css`
          position: relative;
        `}
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  i {
    cursor: pointer;
  }
`;
const TitleText = styled.span`
  font-style: normal;
  ${Header4};
  color: var(--dark-color);
  display: inline-block;
  margin-left: 12px;
`;
const TextEditer = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      flex: 1 0 auto;
      .ql-container.ql-snow {
        height: 100%;
      }
      .ql-editor {
        font-family: var(--primary-font-stack);
        p {
          font-size: 16px;
        }
      }
    `}

  .quill {
    height: 100%;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid var(--gray-border-color);
    border-radius: 4px 4px 0px 0px;
    padding: 8px 12px;
  }
  .ql-container {
    height: 120px;
  }
  .ql-container.ql-snow {
    border: 1px solid var(--gray-border-color);
    border-radius: 0px 0px 4px 4px;
  }
  ::placeholder {
    font-family: var(--primary-font-stack);
  }
  .ql-snow {
    border: none;
    padding: 12px;
  }

  .ql-editor.ql-blank::before {
    color: var(--disable-color);
    font-style: normal;
    ${Text1}
  }

  .ql-editor {
    font-family: var(--primary-font-stack);
    padding: 0;
    overflow-x: auto;
  }
  .ql-toolbar {
    .ql-active {
      color: var(--primary-color);
    }
    .ql-formats {
      margin-right: 0;
      :first-child {
        margin-right: 16px;
      }
    }
    .ql-formats button::after {
      color: var(--gray-icon-color);
      -webkit-text-stroke: 0.3px;
      text-shadow: var(--gray-icon-color);
    }
    button {
      padding: 0;
      height: 20px;
      width: 20px;
      margin-right: 12px;
      svg {
        display: none;
      }
    }
    .ql-expanded .ql-picker-label {
      color: var(--primary-color);
    }
    .ql-picker-label {
      svg {
        display: none;
      }
    }
    .ql-picker-label {
      border-radius: 4px;
    }

    .ql-expanded .ql-picker-options {
      border-radius: 4px;
      border-color: var(--gray-border-color);
      box-shadow: 0 3px 10px var(--menu-modal);
      top: 30px;
      margin-bottom: 6px;
    }
  }
  .ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid var(--gray-border-color);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    ::before {
      line-height: 15px;
      font-weight: 500;
      font-size: 12px;
      color: var(--primary-color);
    }
    ::after {
      right: 4px;
      color: var(--primary-color);
    }
  }
`;
const SecondContain = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const InputView = styled.div`
  margin-bottom: 12px;
`;
const Border = styled.hr`
  border-bottom: 1px solid var(--gray-border-color);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 8px 0;
`;
const Form = styled.form``;
const BottomView = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray-border-color);
  padding: 16px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  margin-right: 12px;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export {
  MainList,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  SecondList,
  LeftIcon,
  RightIcon,
  MainContact,
  TitleView,
  TitleText,
  TextEditer,
  SecondContain,
  InputView,
  BottomView,
  Form,
  Border,
  MenuItemWrapper,
  HeaderWrapper,
  ButtonWrapper,
  SvgWrapper,
  MobileButton,
  MainMobileButton
};
