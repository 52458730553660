import styled, { css } from 'styled-components';
import { Header1, Header3, Text1, Header4 } from '../../../styles';
const OverviewWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  ${(props) =>
    props.mobile
      ? css`
          height: 100%;
          overflow: auto;
        `
      : css``}
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;
const ReportWrapper = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px;
        `
      : css`
          padding: 0 24px;
          overflow: auto;
          height: calc(100% - 76px);
        `}
`;
const ReportBlock = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
`;
const ReportBox = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin-bottom: 10px;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 50%;
        `}
`;
const PanelWrapper = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 6px;
  margin-top: 10px;
  :nth-child(1) {
    margin-top: 0;
  }
  :nth-child(2) {
    margin-top: 0;
  }
`;
const PanelTop = styled.div`
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
        `
      : css`
          padding: 8px 16px;
        `}
`;
const PanelBottom = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
        `
      : css`
          padding: 12px 16px;
        `}
`;
const OverviewTitle = styled.h3`
  color: var(--dark-color);
  margin: 0 0 0 12px;
  ${(props) =>
    props.mobile
      ? css`
          ${Header4}
        `
      : css`
          ${Header3}
        `}
`;
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex: 0 1 auto;
`;
const Li = styled.li`
  border-left: 1px solid var(--gray-border-color);
  padding-left: 20px;
  :first-child {
    border-left: 0 solid transparent;
    padding-left: 0;
    margin-left: 0;
  }
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 20px;
        `
      : css`
          margin-left: 30px;
        `}
`;
const LiWrapper = styled.div``;
const LiTitle = styled.span`
  ${Text1}
  color:var(--gray-color);
  margin-bottom: 5px;
  display: block;
`;
const UlData = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;
const DataLi = styled.li`
  :first-child {
    margin-left: 0;
    ::before {
      display: none;
    }
  }
  margin-left: 30px;
  position: relative;
  color: var(--dark-color);
  ::before {
    content: '';
    background-color: var(--gray-border-color);
    width: 6px;
    height: 6px;
    position: absolute;
    left: -18px;
    top: 50%;
    margin-top: -3px;
    border-radius: 100%;
  }
  span {
    ${Header3}
    color:var(--gray-color);
  }
  ${(props) =>
    props.mobile
      ? css`
          ${Header3}
        `
      : css`
          ${Header1}
        `}
`;

export {
  ReportWrapper,
  ReportBlock,
  ReportBox,
  PanelWrapper,
  PanelTop,
  PanelBottom,
  OverviewTitle,
  Ul,
  Li,
  LiWrapper,
  LiTitle,
  UlData,
  DataLi,
  OverviewWrapper,
  MainMobileButton
};
