import axios from 'axios';
import Dexie from 'dexie';
import { saveToken, clearToken, setupToken } from '../helpers/authTokenHelpers';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason, isEmail } from '../helpers/common';
import {
  clearAuthData,
  setAuthLoader,
  setAuthValidationError,
  setCurrentUser,
  setCurrentUserProfile
} from '../actions/authActions';
import { REACT_APP_APIURL } from '../global/Environment';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import localDataService from './localdataservice';
import { appInit, clearOrgReduxData, removeFromPushNotification } from './appService';
import LookupCacheService from './lookupCacheServices';
import EmailCacheService from './emailCacheServices';
import ActivityCacheService from './activityCacheService';
import EmailAccountCacheService from './emailAccountCacheServices';
import { clearAnlayticsUser, trackAnalyticActivity } from './analyticsService';
import TwilioService from './twilioService';
var qs = require('querystring');
/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const login = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (!obj) {
      dispatchAuthValidationError('Username is Required', dispatch);
      return;
    } else if (!obj.username) {
      dispatchAuthValidationError('Username is Required', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthValidationError('Password is Required', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const response = await axios.post(`${REACT_APP_APIURL}/token`, qs.stringify(obj), {
      headers: headers,
      withCredentials: true
    });
    const { data } = response;
    if (data) {
      trackLoginAnalyticActivity();
      dispatch(setLoginDetail(data));
      const result = await dispatch(appInit());
      return result;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to login please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

export const checkValidation = (payload) => (dispatch) => {
  let valid = true;
  if (!payload) {
    dispatchAuthValidationError('Name is Required', dispatch);
    valid = false;
  } else if (!payload?.name || payload?.name?.trim() === '') {
    dispatchAuthValidationError('Name is Required', dispatch);
    valid = false;
  } else if (!payload?.email || payload?.email?.trim() === '') {
    dispatchAuthValidationError('Email is required', dispatch);
    valid = false;
  } else if (isEmail(payload?.email) === false) {
    dispatchAuthValidationError('Please enter a valid email address', dispatch);
    valid = false;
  } else if (!payload?.countryCode || payload?.countryCode?.trim() === '') {
    dispatchAuthValidationError('Country Code is Required', dispatch);
    valid = false;
  } else if (!payload?.phoneNumber || payload?.phoneNumber?.trim() === '') {
    dispatchAuthValidationError('Mobile Number is Required', dispatch);
    valid = false;
  } else if (!payload?.password || payload?.password?.trim() === '') {
    dispatchAuthValidationError('Password is required', dispatch);
    valid = false;
  } else if (payload?.password?.length < 8) {
    dispatchAuthValidationError('Use atleast 8 character password', dispatch);
    valid = false;
  }
  return valid;
};

/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const signup = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    const checkInput = await dispatch(checkValidation(obj));
    if (!checkInput) {
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Membership/Register`, obj);
    const { data } = response;
    if (data) {
      trackSignUpAnalyticActivity();
      dispatchAuthSucess(data.message || 'Register Sucessfully', dispatch);
      const loginObj = { username: obj.email, password: obj.password };
      return await dispatch(login(loginObj));
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to signup please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Invited User - Get Detail
 * @param {*} invite_id invite id
 */
export const getInvitedUserDetail = (invite_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const Response = await axios.get(`${REACT_APP_APIURL}/company/invitation/${invite_id}`);
    const { data } = Response;
    if (data) return data;
  } catch (e) {
    dispatchAuthError('Unable to get invited user detail, Please try again', dispatch);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Log user out
 */
export const logout = () => async (dispatch) => {
  // Call LogOut API to clear cookie and session
  const options = { withCredentials: true };
  axios.delete(`${REACT_APP_APIURL}/membership/logOff`, options);

  /**
   * Remove token from localStorage
   * Remove auth header for future requests
   * Set current user to {} which will set isAuthenticated to false
   */
  trackLogOutAnalyticActivity();
  clearToken();
  localDataService.ClearAllKeys();
  TwilioService.getInstance()?.clearTwilioData();
  UserPreferenceSingleton.getInstance().clearStoredUserData();
  OrganizationPreferencesSingleton.getInstance().clearAllOrgData();

  //clear existing org data
  await LookupCacheService.getInstance()?.clearDbData();

  //clear reducer data
  dispatch(clearAuthData());
  dispatch(clearOrgReduxData());
  clearAnlayticsUser();
  removeFromPushNotification();

  await LookupCacheService.removeInstance();
  await EmailCacheService.removeInstance();
  await EmailAccountCacheService.removeInstance();
  await ActivityCacheService.removeInstance();
  await OrganizationPreferencesSingleton.removeInstance();
  await UserPreferenceSingleton.removeInstance();

  //Remove all indexeddb data
  const databases = await Dexie.getDatabaseNames();
  for (let i = 0; i < databases?.length; i++) {
    let dbName = databases[i];
    await Dexie.delete(dbName);
  }
};

export const forgotPassword = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (!obj?.email || obj?.email.trim() === '') {
      dispatchAuthValidationError('Email is required', dispatch);
      return;
    } else if (isEmail(obj?.email) === false) {
      dispatchAuthValidationError('Please enter a valid email address', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/membership/forgotpassword`, obj);
    const { data } = response;
    if (data) {
      dispatchAuthSucess(data.message, dispatch);
      return data;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to Forgot Password, please try again later', dispatch);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (!payload?.email || payload?.email.trim() === '') {
      dispatchAuthValidationError('Email is required', dispatch);
      return;
    } else if (isEmail(payload?.email) === false) {
      dispatchAuthValidationError('Please enter a valid email address', dispatch);
      return;
    } else if (!payload.password) {
      dispatchAuthValidationError('Password is Required', dispatch);
      return;
    } else if (!payload.confirmPassword) {
      dispatchAuthValidationError('Confirm password is Required', dispatch);
      return;
    } else if (payload.password !== payload.confirmPassword) {
      dispatchAuthValidationError('Password and Confirm Password must be same', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/membership/resetpassword`, payload);
    const { data } = response;
    if (data) {
      dispatchAuthSucess(data.message, dispatch);
      return data;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to reset Password, please try again later', dispatch);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Get current user profile information
 */
export const getCurrentUserProfile = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const Response = await axios.get(`${REACT_APP_APIURL}/Membership/Profile`);
    const { data } = Response;
    data && dispatch(setCurrentUserProfile(data));
    return true;
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to get current User profile please try again', dispatch);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc update current user profile information
 */
export const updateCurrentUserProfile = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Membership/Profile`, obj);
    const { data } = response;
    if (data) {
      dispatch(getCurrentUserProfile());
      dispatchAuthSucess(data.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to update current User profile please try again', dispatch);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc set login token and set user
 */
export const setLoginDetail = (item) => (dispatch) => {
  // save auth deteils and set token in header for request
  saveToken(item.access_token);
  UserPreferenceSingleton.getInstance().setCurrentUser(item);
  dispatch(setCurrentUser(item));
};

/**
 * @desc Change Email User Profile Settings
 */
export const changeEmail = (params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/membership/changeEmail`, params);
    const { data } = response;
    if (data) {
      const { message } = data || response;
      dispatchAuthSucess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to change email please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Change Password User Profile Settings
 */
export const changePassword = (params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/membership/changepassword`, params);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatchAuthSucess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to change password please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Upload User Profile Photo - User Profile Settings
 */
export const uploadUserProfilePhoto = (fileData) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/membership/profile/upload`, fileData);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatch(getCurrentUserProfile());
      dispatchAuthSucess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to upload profile photo please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

export const validateIsLoggedIn = () => {
  try {
    const token = setupToken();
    if (token) return true;
    return false;
  } catch (e) {
    console.log('Error', e);
    return false;
  }
};

export const trackUserProfileUpdateAnalyticActivity = (action) => {
  try {
    trackAnalyticActivity(`settings - user profile: ${action}`);
  } catch (e) {
    console.log(`track settings - user profile: ${action} Error`, e);
  }
};

const trackSignUpAnalyticActivity = () => {
  try {
    trackAnalyticActivity('signed up');
  } catch (e) {
    console.log('track signed up Error', e);
  }
};

const trackLoginAnalyticActivity = () => {
  try {
    trackAnalyticActivity('signed in');
  } catch (e) {
    console.log('track signed in Error', e);
  }
};
const trackLogOutAnalyticActivity = () => {
  try {
    trackAnalyticActivity('signed out');
  } catch (e) {
    console.log('track signed out Error', e);
  }
};

/**
 * @desc MemberShip - Validate Signup Details
 * @param {*} payload Data payload
 */
export const validateSignupDetails = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Membership/ValidateSignup`, payload);
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to validate Signup Details please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

function dispatchAuthError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
function dispatchAuthSucess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
export function dispatchAuthValidationError(msg, dispatch) {
  dispatch(setAuthValidationError(msg));
}
