import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationList } from '../../services/organizationService';
import Button from '../../components/atom/Button/Button';
import UserLogo from '../../assets/images/pixerlogo.png';
import { ROUTE } from '../../constants/routeConst';
import UserPreferenceSingleton from '../../helpers/UserPreferenceSingleton';
import { appInit, clearOrgReduxData } from '../../services/appService';
import AppLoading from '../../components/AppLoading';
import AppLogo from '../../components/AppLogo';
import useMobileDevice from '../../hooks/useMobileDevice';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import {
  MainWrap,
  SwitchContainer,
  LogoWrapper,
  Title,
  AllView,
  ListCompany,
  ImgView,
  ImgProfile,
  CompanyName,
  ListSpace,
  BtnView,
  ButtonWrap,
  MobileButton,
  MobileHomeLinear
} from './styles';

const SwitchOrganization = (props) => {
  const mobile = useMobileDevice();
  const [loading, setLoading] = useState(false);
  const organizationSelector = useSelector((state) => state.organization);
  const { list } = organizationSelector;

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getOrganizationList());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onPressOrganization = async (item) => {
    try {
      setLoading(true);
      UserPreferenceSingleton.getInstance().setOrganization(item);
      await dispatch(clearOrgReduxData());
      await dispatch(appInit());
      props?.history?.push(ROUTE.INBOX);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <AppLoading />
  ) : (
    <MainWrap mobile={mobile}>
      {mobile && (
        <MobileTitleBar hasNavBar={false} hasTitle={false}>
          <ButtonWrap mobile={mobile}>
            <Title mobile={mobile}>Select Workspace</Title>
            <MobileButton
              iconName='icon-pluse'
              fontSize='14'
              iconColor='var(--white-color)'
              strokeWidth={2}
              size='small'
              onClick={() => {
                props.history.push('/onboarding');
              }}
            />
          </ButtonWrap>
        </MobileTitleBar>
      )}
      <SwitchContainer mobile={mobile}>
        {mobile ? null : (
          <LogoWrapper mobile={mobile}>
            <AppLogo />
            <Title mobile={mobile}>Select Workspace</Title>
          </LogoWrapper>
        )}
        <AllView>
          {list?.map((item) => {
            return (
              <ListSpace key={item?.id} onClick={() => onPressOrganization(item)} mobile={mobile}>
                <ListCompany mobile={mobile}>
                  <ImgView>
                    <ImgProfile src={item?.logoUrl ? item?.logoUrl : UserLogo} />
                  </ImgView>
                  <CompanyName>{item?.name}</CompanyName>
                </ListCompany>
              </ListSpace>
            );
          })}
        </AllView>
        {mobile ? null : (
          <BtnView>
            <Button
              primary
              title='Create Workspace'
              size='large'
              type='button'
              disabled={loading}
              onClick={() => {
                props.history.push('/onboarding');
              }}
            />
          </BtnView>
        )}
        <MobileHomeLinear mobile={mobile} />
      </SwitchContainer>
    </MainWrap>
  );
};
export default SwitchOrganization;
