import React, { useCallback, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Icon from '../../../components/Icon/Icon';
// import { ReactComponent as CaptureIcon } from '../../../assets/images/svg/captureIcon.svg';
// import { ReactComponent as ShopifyLogo } from '../../../assets/images/svg/shopifyLogo.svg';
import { ReactComponent as IntegrationsIcon } from '../../../assets/images/svg/reorder-down.svg';
import { ReactComponent as SegmentLogo } from '../../../assets/images/svg/segment_logo.svg';
import { getIntegrationSecretKey } from '../../../services/settingService';
import { getAppHostName } from '../../../helpers/common';
import Modal from '../../../components/Modal';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import {
  IntegrationsWrapper,
  Heading,
  BlogWrapper,
  BlockWrapper,
  Block,
  Title,
  Para,
  // SyncWrapper,
  // Sync,
  SegmentView,
  TitleViewlist,
  TitleText,
  FormList,
  AllList,
  TextSegment,
  Ptext,
  FirstBox,
  BtnView,
  SecondBox,
  ApiSpan,
  IconView,
  BottomView,
  HeaderWrapper,
  Input
} from './styles';

const Integrations = () => {
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const settingSelector = useSelector((state) => state.setting);
  const authSelector = useSelector((state) => state.auth);

  const { organization } = organizationSelector;
  const { integrationSecretKey } = settingSelector;
  const { id: organization_id } = organization;
  const { currentUser } = authSelector;
  const { access_token } = currentUser;

  const [isOpenSegmentModal, setSegmentModal] = useState(false);

  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);

  const handleClickOnSegment = useCallback(() => {
    dispatch(getIntegrationSecretKey(organization_id));
    setSegmentModal(true);
  }, [dispatch, organization_id]);

  const closeSegmentModal = useCallback(() => {
    setSegmentModal(false);
  }, []);

  const handleCopy = (value) => {
    copy(value);
  };

  return (
    <>
      {mobile && <MobileTitleBar hasNavBar={true} hasTitle={true}></MobileTitleBar>}
      <IntegrationsWrapper mobile={mobile}>
        {mobile ? null : <Heading>Integrations</Heading>}
        <BlogWrapper mobile={mobile}>
          {/* <BlockWrapper>
            <Block>
              <CaptureIcon />
              <Title>Capture Leads from Website</Title>
              <Para>Get Leads from your website, density in Bearbook CRM</Para>
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <Block>
              <ShopifyLogo />
              <Title>Shopify</Title>
              <Para>Get Leads from your website, density in Bearbook CRM</Para>
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <Block>
              <ShopifyLogo />
              <Title>Shopify</Title>
              <SyncWrapper>
                <Sync>Sync Data</Sync>
                <Icon name='feather-refresh-cw' fontSize='18' color='var(--gray-icon-color)' />
              </SyncWrapper>
              <Para>last Updateed 2:45pm</Para>
            </Block>
          </BlockWrapper> */}
          <BlockWrapper onClick={handleClickOnSegment} mobile={mobile}>
            <Block mobile={mobile}>
              <SegmentLogo />
              <Title>Segment</Title>
              <Para>Get Leads from Segment, directly in {appName}</Para>
            </Block>
          </BlockWrapper>
        </BlogWrapper>
      </IntegrationsWrapper>
      <Modal open={isOpenSegmentModal} onClose={closeSegmentModal} onBackdropClick={closeSegmentModal}>
        <SegmentView>
          <TitleViewlist>
            <HeaderWrapper>
              <IntegrationsIcon />
              <TitleText>Install Segment for {appName}</TitleText>
            </HeaderWrapper>
            <Icon name='icon-close' fontSize='20' color='var(--gray-icon-color)' onClick={closeSegmentModal} />
          </TitleViewlist>
          <FormList
            method={'post'}
            // eslint-disable-next-line max-len
            action={`https://api.salescamp.app/account/auth/segment?companyId=${organization_id}&access_token=${access_token}`}>
            <AllList>
              <FirstBox>
                <TextSegment>
                  <Ptext>
                    There is two way to connect {appName} with segment, click to button to connect with segment
                  </Ptext>
                </TextSegment>
                <BtnView>
                  <input type='hidden' name='appName' value='WebApp' />
                  <input
                    type='hidden'
                    name='returnUrl'
                    value={`${encodeURIComponent(
                      window.location.href.split('?')[0]
                    )}?success=Segment%20integrated%20successfully`}
                  />
                  <Button title={'Connect With Segment'} type={'submit'} />
                </BtnView>
              </FirstBox>
              <SecondBox>
                <TextSegment>
                  <Ptext>API Key</Ptext>
                </TextSegment>
                <Input>
                  <ApiSpan>{integrationSecretKey?.token}</ApiSpan>
                  <IconView>
                    <Icon
                      name='icon-copy'
                      fontSize='16'
                      color='var(--gray-more-icon)'
                      onClick={() => handleCopy(integrationSecretKey?.token)}
                    />
                  </IconView>
                </Input>
              </SecondBox>
            </AllList>
          </FormList>
          <BottomView>
            <Button title={'Cancel'} secondary type={'cancel'} onClick={closeSegmentModal} />
          </BottomView>
        </SegmentView>
      </Modal>
    </>
  );
};

export default Integrations;
