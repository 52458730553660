import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, isMACApp, isWindowsApp } from '../../global/Helper';
import Avtar from '../Avtar/Avtar';
import TitleSearchBar from '../TitleSearchBar/TitleSearchBar';
import { ROUTE } from '../../constants/routeConst';
import { logout } from '../../services/authServices';
import {
  FirstRow,
  TextView,
  HeadTitle,
  MainContain,
  RightView,
  IconView,
  SeconView,
  LinkTag,
  SecondRow,
  HeadCaption,
  CaptionText,
  LiTwo,
  AlistTwo,
  SpanListMenu
} from '../Navbar/styles';
import { openIntercomChat } from '../../services/analyticsService';
import { trackSupportAnalyticActivity } from '../../services/dashboardService';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import MobileTitleBar from './MobileTitleBar';
import {
  NavbarTopNavigation,
  ArrowNavigation,
  BackButton,
  ForwordButton,
  ManeWrapper,
  NavbarLeft,
  NavbarRightWrapper,
  NavbarRight,
  NavbarButtonWrapper,
  ButtonLeft,
  FeedbackModal,
  MenuUl,
  SwitchList,
  FirstList,
  SpanListMenu2,
  IconViewt,
  WorkSpace,
  StyledMenu
} from './styles';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#000000',
    border: '50px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
const classes = useStyles;

const TitleBar = (props) => {
  const { setNavBar, onOpenNavbar, isHideMobileTitleBar } = props;
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const dispatch = useDispatch();
  const [isOpenHelpModal, toggleOpenHelpModal] = useState(false);
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);

  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;

  let history = useHistory();
  const handleClickOnLeftArrow = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleClickOnRightArrow = useCallback(() => {
    history.goForward();
  }, [history]);

  const openHelpModal = useCallback((e) => {
    window.nolt('init', {
      url: 'https://feedback.salescamp.app',
      selector: '.nolt-btn'
    });
    const noltToken = getCookie('sc-nolt-token');
    if (noltToken && window.nolt) {
      window.nolt('identify', {
        jwt: noltToken
      });
    }
    toggleOpenHelpModal(true);
  }, []);

  const closeHelpModal = useCallback((e) => {
    toggleOpenHelpModal(false);
  }, []);

  const openSettingNavbar = useCallback(() => {
    setNavBar(2);
  }, [setNavBar]);

  const [anchorEl, setAnchorEl] = useState(null);
  const closeProfileMenu = (e) => {
    toggleProfileMenu(false);
    setAnchorEl(null);
  };
  const openProfileMenu = useCallback((e) => {
    toggleProfileMenu(true);
    setAnchorEl(e.currentTarget);
  }, []);
  const [isOpenProfileMenu, toggleProfileMenu] = useState(false);
  const openSwitchOrganisation = () => {
    closeProfileMenu();
    history.push(ROUTE.SELECT_ORGANIZATION);
  };

  const onClickLogout = useCallback(async () => {
    await dispatch(logout());
    history.push(ROUTE.LOGIN);
  }, [dispatch, history]);

  const macApp = useMemo(isMACApp, []);
  const windowsApp = useMemo(isWindowsApp, []);

  const sendCommandToElectron = useCallback((command) => {
    if (window?.salescampApp?.sendCommand) {
      window?.salescampApp?.sendCommand(command);
    }
  }, []);

  const onClickMobileSetting = useCallback(() => {
    onOpenNavbar();
    openSettingNavbar();
    closeProfileMenu();
  }, [onOpenNavbar, openSettingNavbar]);

  const onClickSearch = useCallback(() => {
    setIsShowSearchBar(!isShowSearchBar);
  }, [isShowSearchBar]);

  return (
    <>
      <ManeWrapper mobile={mobile}>
        <NavbarLeft mac={macApp} mobile={mobile}>
          <NavbarTopNavigation>
            <ArrowNavigation>
              <div title='go back'>
                <BackButton
                  iconName='icon-left-arrow'
                  iconColor='var(--gray-more-icon)'
                  disabled={history.length === 2}
                  onClick={handleClickOnLeftArrow}
                />
              </div>
              <div title='go forward'>
                <ForwordButton
                  iconName='icon-right-arrow'
                  iconColor='var(--gray-more-icon)'
                  onClick={handleClickOnRightArrow}
                />
              </div>
            </ArrowNavigation>
          </NavbarTopNavigation>
        </NavbarLeft>
        {!isHideMobileTitleBar && <MobileTitleBar />}
        {!mobile && <TitleSearchBar />}
        <NavbarRightWrapper win={windowsApp}>
          <NavbarRight onClick={openHelpModal} mobile={mobile} title={'Help'}>
            <SVGIcon name='icon-information' size={16} fill='var(--gray-more-icon)' strokeWidth={2} />
          </NavbarRight>
          <NavbarRight onClick={openSettingNavbar} mobile={mobile} title={'Settings'}>
            <SVGIcon name='icon-tools' size={16} fill='var(--gray-more-icon)' strokeWidth={0.6} />
          </NavbarRight>
          {mobile ? null : (
            <NavbarRight mobile={mobile} onClick={onClickSearch}>
              <SVGIcon
                name={mobile && isShowSearchBar ? 'icon-close' : 'icon-search'}
                size={30}
                fill={mobile ? 'var(--dark-color)' : 'var(--gray-more-icon)'}
              />
            </NavbarRight>
          )}
          {mobile ? null : (
            <NavbarRight onClick={openProfileMenu} title={'Switch Workspace'} mobile={mobile}>
              <Avtar imgSrc={currentUser?.profile_photo} name={currentUser?.given_name} size={mobile ? '30' : '24'} />
            </NavbarRight>
          )}
        </NavbarRightWrapper>
        {windowsApp && (
          <NavbarButtonWrapper>
            <ButtonLeft
              onClick={() => {
                sendCommandToElectron('minimize');
              }}>
              <SVGIcon name='icon-minus' size={16} fill='var(--gray-more-icon)' />
            </ButtonLeft>
            <ButtonLeft
              left
              onClick={() => {
                sendCommandToElectron('maximize');
              }}>
              <SVGIcon name='icon-maximius' size={16} fill='var(--gray-more-icon)' />
            </ButtonLeft>
            <ButtonLeft
              right
              onClick={() => {
                sendCommandToElectron('close');
              }}>
              <SVGIcon name='icon-close' size={16} fill='var(--gray-more-icon)' />
            </ButtonLeft>
          </NavbarButtonWrapper>
        )}
        {/* {mobile && isShowSearchBar ? <TitleSearchBar /> : null} */}
      </ManeWrapper>
      <Modal
        className={classes.modal}
        open={isOpenHelpModal}
        onClose={closeHelpModal}
        BackdropProps={{ invisible: true }}>
        <MainContain>
          <FirstRow>
            <SVGIcon name='icon-feedback' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
            <RightView>
              <HeadTitle>Help & Feedback</HeadTitle>
              <IconView onClick={closeHelpModal}>
                <SVGIcon name='icon-close' size={20} fill='var(--gray-color)' strokeWidth={2} />
              </IconView>
            </RightView>
          </FirstRow>
          <SeconView>
            <LinkTag
              href={'https://www.salescamp.app/help'}
              target={'_blank'}
              onClick={() => {
                trackSupportAnalyticActivity('help center clicked');
              }}>
              <SecondRow>
                <SVGIcon name='icon-information' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
                <TextView>
                  <HeadCaption>Help Center</HeadCaption>
                  <CaptionText>Explore all features and best practices.</CaptionText>
                </TextView>
              </SecondRow>
            </LinkTag>
            <SecondRow
              onClick={() => {
                openIntercomChat();
                trackSupportAnalyticActivity('chat with us clicked');
              }}>
              <SVGIcon name='icon-message' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
              <TextView>
                <HeadCaption>Talk to us</HeadCaption>
                <CaptionText>Let us know if you having any issues.</CaptionText>
              </TextView>
            </SecondRow>
            <FeedbackModal
              className='nolt-btn'
              onClick={() => {
                trackSupportAnalyticActivity('feedback clicked');
              }}
              data-nolt='button'>
              <SVGIcon name='icon-star' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
              <TextView>
                <HeadCaption>Feedback</HeadCaption>
                <CaptionText>Share your feedback or feature request.</CaptionText>
              </TextView>
            </FeedbackModal>
            <LinkTag
              onClick={() => {
                trackSupportAnalyticActivity('resource clicked');
              }}
              href={'https://www.salescamp.app/free-sales-tools'}
              target={'_blank'}>
              <SecondRow>
                <SVGIcon name='icon-resource' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
                <TextView>
                  <HeadCaption>Resource</HeadCaption>
                  <CaptionText>Get free sales tools and resource </CaptionText>
                </TextView>
              </SecondRow>
            </LinkTag>
          </SeconView>
        </MainContain>
      </Modal>
      <StyledMenu
        id='customized-user'
        anchorEl={anchorEl}
        keepMounted
        open={isOpenProfileMenu}
        onClose={closeProfileMenu}>
        <MenuUl>
          <SwitchList onClick={openSwitchOrganisation}>
            <FirstList>
              <SpanListMenu2>{organization?.name}</SpanListMenu2>
              <IconViewt>
                <SVGIcon name='icon-right-arrow' size={mobile ? 20 : 18} fill='var(--gainsBoro-color)' />
              </IconViewt>
            </FirstList>
            <WorkSpace>Create or Switch Workspace</WorkSpace>
          </SwitchList>
          <LiTwo onClick={onClickMobileSetting}>
            <AlistTwo>
              <SpanListMenu>Settings</SpanListMenu>
            </AlistTwo>
          </LiTwo>
          <LiTwo onClick={onClickLogout}>
            <AlistTwo>
              <SpanListMenu>Logout</SpanListMenu>
            </AlistTwo>
          </LiTwo>
        </MenuUl>
        {/* <UlTwo>
          {mobile && (
            <LiTwo onClick={onClickMobileSetting}>
              <AlistTwo>
                <SpanListMenu>Setting</SpanListMenu>
              </AlistTwo>
            </LiTwo>
          )}
          <LiTwo onClick={openSwitchOrganisation}>
            <AlistTwo>
              <SpanListMenu>Switch Workspace</SpanListMenu>
            </AlistTwo>
          </LiTwo>
          <LiTwo>
            <AlistTwo onClick={onClickLogout}>
              <SpanListMenu>Logout</SpanListMenu>
            </AlistTwo>
          </LiTwo>
        </UlTwo> */}
      </StyledMenu>
    </>
  );
};

export default TitleBar;
