import styled from 'styled-components';
import { Text3 } from '../../styles';

const FilterPanelWrap = styled.div``;

const FilterMainText = styled.div`
  width: fit-content;
  padding: 6px 9px;
  display: flex;
  align-items: center;
  background-color: var(--deal-column-bg);
  border: 1px solid var(--shade-gray);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  ${Text3}
`;

const FilterSpan = styled.span`
  color: var(--dark-color);
  cursor: pointer;
  margin-right: 3px;
  :last-child {
    margin-right: 0;
  }
`;

const StaticFilterText = styled.span`
  color: var(--gray-color);
  margin-right: 3px;
  :last-child {
    margin-right: 0;
  }
`;

export { FilterPanelWrap, FilterMainText, FilterSpan, StaticFilterText };
