import { useMemo } from 'react';
import moment from 'moment';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import Avtar from '../../../../../components/Avtar/Avtar';

import useMobileDevice from '../../../../../hooks/useMobileDevice';
import CommentHoverItem from '../Comments/CommentHoverItem';
import RenderComments from '../Comments/RenderComments';
import { ReactComponent as Play } from '../../../../../assets/images/svg/play.svg';
import { ReactComponent as Pause } from '../../../../../assets/images/svg/pause.svg';
import { isEmpty, urlify } from '../../../../../helpers/common';
import { getGravatarURL } from '../../../../../helpers/common';

import {
  MainList,
  ActivityUpperBlock,
  IconList,
  AllList,
  RightList,
  UserText,
  UserRecive,
  ActivityBottomBlock,
  ContainView,
  Captionmess,
  FileMain,
  TimeText,
  FileIcons,
  SVGIconBg,
  AudioWrapper,
  CallDurationText,
  CallSummaryItem,
  AudioText,
  ThraedInner,
  UserWrapperBlock
} from '../styles';
const CallActivityItem = ({
  item,
  actionIcon,
  createdOn,
  isOpenThread,
  onLookupClick,
  onMoreMenuClick,
  isShowCommentInput,
  onClickComment,
  selectedActivity,
  isOpenMoreOptionMenu,
  haveCallRecording
}) => {
  const mobile = useMobileDevice();
  const title = useMemo(() => {
    let title;
    switch (item?.title) {
      case 'No Answer':
        title = `had a call but ${item?.title}`;
        break;
      case 'Log-call':
      case 'Log call':
      case 'Call':
        title = 'had a Call';
        break;
      default:
        title = item?.title;
        break;
    }
    return String(title || '').toLowerCase();
  }, [item?.title]);

  const duration = item?.duration || 0;

  const recordingIcon = useMemo(() => {
    if (haveCallRecording || !isEmpty(duration) || item?.title) {
      if (item?.title?.toLowerCase()?.includes('outgoing')) {
        return {
          recordingIconName: 'icon-outgoing',
          recordingIconColor: 'var(--icon-green)'
        };
      } else {
        return {
          recordingIconName: 'icon-ingoing',
          recordingIconColor: 'var(--icon-blue)'
        };
      }
    }
    return {
      recordingIconName: '',
      recordingIconColor: ''
    };
  }, [duration, haveCallRecording, item?.title]);

  let recordingIconName = recordingIcon.recordingIconName;
  let recordingIconColor = recordingIcon.recordingIconColor;
  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);

  const htmlContent = useMemo(() => {
    if (item?.description) {
      return urlify(item?.description);
    }
    return item?.description;
  }, [item?.description]);

  return (
    <MainList>
      <ActivityUpperBlock mobile={mobile} isOpenThread={isOpenThread}>
        {isOpenThread ? (
          item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
        ) : (
          <IconList>{actionIcon}</IconList>
        )}
        <AllList>
          <RightList mobile={mobile} isOpenThread={isOpenThread}>
            {isOpenThread && item?.lookup && (
              <ThraedInner
                onClick={() => {
                  onLookupClick(item?.lookup);
                }}>
                <UserText>{item?.lookup?.name}</UserText>
              </ThraedInner>
            )}
            <UserWrapperBlock>
              <UserText isOpenThread={isOpenThread} mobile={mobile}>
                {item?.assignedUser?.name}
              </UserText>
              <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                {title}
              </UserRecive>
              <TimeText isOpenThread={isOpenThread} mobile={mobile} title={createdOn?.tooltip}>
                {createdOn?.absolute}
              </TimeText>
            </UserWrapperBlock>
          </RightList>
        </AllList>
      </ActivityUpperBlock>
      <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
        <ContainView audio>
          {haveCallRecording ? (
            <FileMain audio>
              <FileIcons audio>
                <SVGIconBg fill={recordingIconColor} size={20} name={recordingIconName} />
              </FileIcons>
              {item?.callRecording?.map((record) => {
                const callDuration = moment
                  .utc(Math.abs(record?.duration) * 1000)
                  .format(duration > 3599 ? 'HH:mm:ss' : 'mm:ss');

                return (
                  <>
                    <AudioWrapper mobile={mobile}>
                      <CallDurationText mobile={mobile}>{callDuration} min</CallDurationText>
                      <AudioPlayer
                        src={record?.url}
                        showJumpControls={false}
                        defaultCurrentTime={false}
                        customProgressBarSection={[
                          RHAP_UI.MAIN_CONTROLS,
                          RHAP_UI.CURRENT_TIME,
                          RHAP_UI.PROGRESS_BAR,
                          RHAP_UI.DURATION,
                          RHAP_UI.VOLUME_CONTROLS
                        ]}
                        customIcons={{
                          play: <Play />,
                          pause: <Pause />
                        }}
                        customControlsSection={[]}
                        customAdditionalControls={[]}
                      />
                    </AudioWrapper>
                  </>
                );
              })}
            </FileMain>
          ) : (
            !isEmpty(duration) && (
              <FileMain>
                <FileIcons audio>
                  <SVGIconBg fill={recordingIconColor} size={20} name={recordingIconName} />
                </FileIcons>
                <AudioWrapper>
                  <span>
                    {moment.utc(Math.abs(duration) * 1000).format(duration > 3599 ? 'HH:mm:ss' : 'mm:ss')} min
                  </span>
                </AudioWrapper>
              </FileMain>
            )
          )}
          <CallSummaryItem>
            <FileMain>
              <AudioText>
                {htmlContent ? (
                  <Captionmess
                    className='quill_format'
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    haveCallRecording={haveCallRecording}
                  />
                ) : (
                  <Captionmess>No summary!</Captionmess>
                )}
              </AudioText>
            </FileMain>
          </CallSummaryItem>
          {onMoreMenuClick && (
            <CommentHoverItem
              item={item}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
              selectedActivity={selectedActivity}
              onClickMoreMenu={onMoreMenuClick}
              onClickComment={onClickComment}
            />
          )}
        </ContainView>
        <RenderComments item={item} isShowCommentInput={isShowCommentInput} selectedActivity={selectedActivity} />
      </ActivityBottomBlock>
    </MainList>
  );
};

export default CallActivityItem;
