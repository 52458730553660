import styled, { css } from 'styled-components';
import { Header2, Header4, SubHeader3, Text1, Text2 } from '../../../styles';
import CheckIcon from '../../../assets/images/CheckIcon.svg';
import Input from '../../../components/Input/Input';

const ImportDataWrapper = styled.div`
  height: 100%;
  overflow: auto;
  ${(props) =>
    props.step === 3 &&
    css`
      height: calc(100% - 93px);
    `}
`;
const ItemRight = styled.div`
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px 16px 16px;
        `
      : css`
          padding: 24px 24px 0 24px;
        `}
`;
const Heading = styled.h1`
  color: var(--dark-color);
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray-border-color);
  ${Header2}
`;
const TimelineViewWrapper = styled.div`
  position: relative;
  height: auto;
  padding-top: 24px;
  ${(props) =>
    props.mobile
      ? css`
          padding-left: 0;
        `
      : css`
          padding-left: 16px;
        `}
  ::before {
    content: '';
    background-color: var(--gray-border-color);
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0px;
    ${(props) =>
      props.mobile
        ? css`
            left: 18px;
          `
        : css`
            left: 34px;
          `}
  }
`;
const TimelineViewListing = styled.div``;
const TimelineViewListitemActive = styled.div`
  padding-bottom: 24px;
`;
const TimelineViewHeader = styled.div`
  display: flex;
  align-items: center;
`;
const TimeSpan = styled.span`
  color: var(--primary-color);
  flex-shrink: 0;
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  border-radius: 100%;
  ${SubHeader3}
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 36px;
  height: 36px;
  margin-right: 12px;
`;
const TimeSpanBlock = styled.span`
  flex-shrink: 0;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--gray-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 36px;
  height: 36px;
  margin-right: 12px;
`;
const TitleOne = styled.h3`
  color: var(--dark-color);
  margin: 0;
  ${(props) =>
    props.mobile
      ? css`
          ${Text1}
        `
      : css`
          ${Header4}
        `}
`;
const TimelineViewContent = styled.div`
  padding: 16px 0 24px 48px;
  width: 320px;
`;
const DropDownWrapper = styled.div`
  margin-bottom: 16px;
`;
const TimeSpanDone = styled.span`
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-width: 36px;
  height: 36px;
  margin-right: 12px;
  background-image: url(${CheckIcon});
  background-repeat: no-repeat;
  background-position: center;
`;
const TimelineViewContentBlock = styled.div`
  padding: 8px 0 0 48px;
  table {
    thead {
      tr {
        th {
          :first-child {
            padding: 0px 12px;
          }
        }
      }
    }
  }
`;
const ListSelectBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 772px;
  flex-wrap: wrap;
  margin-top: 16px;
`;
const ListWrappper = styled.div`
  width: 100%;
  max-width: 378px;
  margin-bottom: 12px;
  :nth-child(odd) {
    ${(props) =>
      props.mobile
        ? css`
            margin-right: 0;
          `
        : css`
            margin-right: 16px;
          `}
  }
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 4px;
  display: block;
  white-space: nowrap;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 772px;
`;
const LabelTitle = styled.span`
  color: var(--dark-color);
  margin-right: 15px;
  white-space: nowrap;
  ${(props) =>
    props.mobile
      ? css`
          ${Text1};
        `
      : css`
          ${Header4};
        `}
`;
const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-border-color);
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-width: 740px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const Thead = styled.thead``;
const Tr = styled.tr``;
const Th = styled.th`
  ${Text2};
  color: var(--gray-color);
  border-bottom: 1px solid var(--gray-border-color);
  padding: 11px 10px;
  text-align: left;
  white-space: nowrap;
`;
const Tbody = styled.tbody``;
const Td = styled.td`
  ${Text1};
  color: var(--dark-color);
  border-bottom: 1px solid var(--gray-border-color);
  border-right: 1px solid var(--gray-border-color);
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  :last-child {
    border-right: 0;
  }
  :first-child {
    color: var(--primary-color);
  }
`;
const TimelineViewButton = styled.div`
  margin-top: 24px;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperInput = styled.div`
  position: relative;
  display: inline-flex;
  height: 34px;
  input {
    z-index: 2;
    cursor: pointer;
    text-indent: -999px;
  }
`;
const marginBottom = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 34px;
  z-index: 5;
  opacity: 0;
`;
const CreatableDropDown = {
  control: (base, { isFocused }) => ({
    ...base,
    // This line disable the blue border
    boxShadow: 'none',
    minHeight: '34px',
    borderRadius: '4px',
    borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5',
    borderWidth: isFocused ? '2px' : '1px',
    margin: '0px',
    ':hover': {
      borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5'
    }
  })
};
const FileInput = styled(Input)`
  padding: 8px 16px;
  :focus {
    margin: -1px 0;
  }
`;
const FieldWrapper = styled.div`
  margin-bottom: 12px;
`;
const Title = styled(DropDownTitle)`
  color: var(--red-lite);
  margin-top: 12px;
  margin-bottom: 0;
`;

export {
  CreatableDropDown,
  ImportDataWrapper,
  ItemRight,
  Heading,
  TimelineViewWrapper,
  TimelineViewListitemActive,
  TimelineViewListing,
  TimeSpan,
  TimelineViewHeader,
  TimeSpanBlock,
  TimelineViewContent,
  TitleOne,
  TimeSpanDone,
  DropDownWrapper,
  TimelineViewContentBlock,
  ListSelectBlock,
  TimelineViewButton,
  TableWrapper,
  Table,
  Td,
  Tr,
  Th,
  Tbody,
  Thead,
  Border,
  LabelTitle,
  LabelWrapper,
  DropDownTitle,
  ListWrappper,
  WrapperInput,
  marginBottom,
  FileInput,
  FieldWrapper,
  Title
};
