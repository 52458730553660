import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../../components/atom/Button/Button';
import SalescampLogo from '../../../assets/images/svg/Salescamplogo.svg';
import { deleteInvoiceItem, getInvoiceItem } from '../../../services/invoiceService';
import { currencyNameWithCode } from '../../../data/raw';
import { Menu, MenuItem } from '../../../components/Menu';
import { INVOICE_STATUS, INVOICE_STATUS_TEXT, INVOICE_STATUS_TEXT_FOR_COLOR } from '../../../constants/constant';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { isPastDate } from '../../../helpers/common';
import { setInvoiceItem } from '../../../actions/invoiceActions';
import { REACT_APP_APIURL } from '../../../global/Environment';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import Modal from '../../../components/Modal';
import { PaymentModal } from '../PaymentModal/PaymentModal';
import {
  InvoiceWrapper,
  Header,
  InvoiceBoxWrappe,
  SpanInvoiceLeft,
  InvoiceTopWrapper,
  InvoiceMainStatus,
  InvoiceStatus,
  InvoiceBoxMain,
  SpanInvoiceRight,
  InvoiceIcon,
  InvoiceNumber,
  InvoiceMainButton,
  IconButton,
  InvoiceTop,
  InvoiceLogoMain,
  InvoiceLogo,
  InvoiceDetail,
  InvoiceListMain,
  InvoiceInnerList,
  InvoiceLable,
  InvoiceDetailText,
  InvoiceRibinMain,
  InvoiceInnerRibbin,
  InvoiceRibbinHead,
  InvoiceRibbinRight,
  InvoiceRibbinLeft,
  BillMain,
  BillFrom,
  BillLable,
  FromBillMain,
  BillHead,
  BillPara,
  InvoiceTable,
  InvoiceTableHead,
  InvoiceTableRow,
  TableHeadCell,
  InvoiceTabaleBody,
  InvoicetdCell,
  InvoiceTablewrapper,
  InvoiceAmountInner,
  InvoiceAmountList,
  AmountLable,
  AmountDetail,
  InvoiceBottom,
  InvoiceNote,
  InvoiceNotePara,
  InvoiceListWrapper,
  InvoiceTotal,
  InvoiceLine,
  InvoiceLogoBlock,
  InvoiceLeftBlog,
  InvoiceAmountBlog,
  InvoiceButtonList,
  BottomLogoWrapper,
  LogoSpan,
  InvoiceBottomLogo,
  SalescampMainLogo,
  DownloadInvoiceLink,
  SvgWrapper
} from './styles';

const ViewInvoice = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const invoiceSelector = useSelector((state) => state.invoice);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { invoiceItem, loading } = invoiceSelector;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id: invoiceId } = useParams();

  useEffect(() => {
    dispatch(getInvoiceItem(organization_id, invoiceId));
    return () => {
      dispatch(setInvoiceItem());
    };
  }, [dispatch, organization_id, invoiceId]);

  const onOpenPaymentModal = useCallback(() => {
    setIsPaymentModalOpen(true);
  }, []);

  const onClosePaymentModal = useCallback(() => {
    setIsPaymentModalOpen(false);
  }, []);

  const onOpenMoreMenu = useCallback((e) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onDeleteInvoice = useCallback(async () => {
    onCloseMoreMenu();
    const result = await dispatch(deleteInvoiceItem(organization_id, invoiceItem?.id));
    if (result) {
      history.push('/invoices');
    }
  }, [onCloseMoreMenu, dispatch, organization_id, invoiceItem?.id, history]);

  const currencySymbol = useMemo(() => {
    const symbol = currencyNameWithCode?.find((x) => x.code === invoiceItem?.currency);
    return symbol;
  }, [invoiceItem?.currency]);

  const invoiceStatus = useMemo(() => {
    const hasPastDate = isPastDate(invoiceItem?.dueDate);
    const isPaidStatus = invoiceItem?.status === INVOICE_STATUS.PAID;
    const isOverDue = !isPaidStatus && hasPastDate;
    const status =
      isOverDue && invoiceItem?.status !== INVOICE_STATUS.DRAFT
        ? INVOICE_STATUS_TEXT.OVER_DUE
        : INVOICE_STATUS_TEXT[invoiceItem?.status];
    return status;
  }, [invoiceItem?.dueDate, invoiceItem?.status]);

  const getInvoiceStatusColor = useMemo(() => {
    switch (invoiceStatus) {
      case INVOICE_STATUS_TEXT_FOR_COLOR.PAID:
        return '--sheen-green';
      case INVOICE_STATUS_TEXT_FOR_COLOR.OVER_DUE:
        return '--tomato';
      case INVOICE_STATUS_TEXT_FOR_COLOR.PENDING:
        return '--star-bg';
      case INVOICE_STATUS_TEXT_FOR_COLOR.DRAFT:
        return '--grey-color';
      default:
        break;
    }
  }, [invoiceStatus]);

  return (
    <>
      <InvoiceWrapper mobile={mobile}>
        <Header mobile={mobile}>
          <InvoiceIcon mobile={mobile}>
            {mobile ? (
              <SvgWrapper
                onClick={() => {
                  props.history.goBack();
                }}>
                <SVGIcon name='icon-big-left-arrow' fill='var(--gray-icon-color)' size={20} />
              </SvgWrapper>
            ) : null}
            <SVGIcon name='icon-invoice' fill='var(--dark-color)' size={24} />
            <InvoiceNumber>{invoiceItem?.number}</InvoiceNumber>
          </InvoiceIcon>
          <InvoiceMainButton mobile={mobile}>
            {invoiceItem?.status !== INVOICE_STATUS.PAID && (
              <InvoiceButtonList>
                <Button
                  title='Pay Invoice'
                  loading={loading}
                  onClick={() => {
                    onOpenPaymentModal();
                  }}
                  primary
                  size='medium'
                  type='button'
                />
              </InvoiceButtonList>
            )}
            <InvoiceButtonList>
              <Button title='Web Invoice' onClick={() => {}} secondary size='medium' type='button' />
            </InvoiceButtonList>
            <InvoiceButtonList title='Print'>
              <DownloadInvoiceLink
                href={`${REACT_APP_APIURL}/invoices/${organization_id}/pdf/${invoiceId}.pdf`}
                target='_blank'>
                <IconButton
                  iconName='icon-printer'
                  iconColor='var(--gray-icon-color)'
                  fontSize={16}
                  secondary
                  size='small'
                  type='button'
                />
              </DownloadInvoiceLink>
            </InvoiceButtonList>
            <InvoiceButtonList title='Download'>
              <DownloadInvoiceLink
                href={`${REACT_APP_APIURL}/invoices/${organization_id}/pdf/${invoiceId}.pdf?download=true`}
                target='_blank'>
                <IconButton
                  iconName='icon-download'
                  iconColor='var(--gray-icon-color)'
                  fontSize={16}
                  secondary
                  size='small'
                  type='button'
                />
              </DownloadInvoiceLink>
            </InvoiceButtonList>
            <InvoiceButtonList>
              <Button
                title='Edit'
                onClick={() => {
                  history.push(`/invoices/edit/${invoiceId}`);
                }}
                secondary
                size='medium'
                type='button'
              />
            </InvoiceButtonList>
            {invoiceItem?.status < INVOICE_STATUS.PARTIALLY_PAID && (
              <InvoiceButtonList title='More'>
                <IconButton
                  iconName='icon-more'
                  fontSize={16}
                  iconColor='var(--gray-icon-color)'
                  onClick={(e) => {
                    onOpenMoreMenu(e);
                  }}
                  secondary
                  size='small'
                  type='button'
                />
              </InvoiceButtonList>
            )}
          </InvoiceMainButton>
        </Header>
        <InvoiceBoxWrappe>
          <SpanInvoiceLeft mobile={mobile}></SpanInvoiceLeft>
          <InvoiceBoxMain mobile={mobile}>
            <InvoiceTopWrapper>
              <InvoiceMainStatus>
                <InvoiceStatus statusColor={getInvoiceStatusColor}>{invoiceStatus}</InvoiceStatus>
              </InvoiceMainStatus>
              <InvoiceTop mobile={mobile}>
                <InvoiceLogoMain mobile={mobile}>
                  <InvoiceLogoBlock mobile={mobile}>
                    <InvoiceLogo src={organization?.logoUrl} />
                  </InvoiceLogoBlock>
                </InvoiceLogoMain>
                <InvoiceDetail>
                  <InvoiceListMain>
                    <InvoiceInnerList>
                      <InvoiceLable>Invoice ID:</InvoiceLable>
                      <InvoiceDetailText>{invoiceItem?.number}</InvoiceDetailText>
                    </InvoiceInnerList>
                    <InvoiceInnerList>
                      <InvoiceLable>Issue Date:</InvoiceLable>
                      <InvoiceDetailText>{moment(invoiceItem?.date).format('DD/MM/YYYY')}</InvoiceDetailText>
                    </InvoiceInnerList>
                    <InvoiceInnerList>
                      <InvoiceLable>Due Date:</InvoiceLable>
                      <InvoiceDetailText>{moment(invoiceItem?.dueDate).format('DD/MM/YYYY')}</InvoiceDetailText>
                    </InvoiceInnerList>
                    <InvoiceInnerList>
                      <InvoiceLable>Amount:</InvoiceLable>
                      <InvoiceDetailText>
                        {`${currencySymbol?.symbol}`}
                        {Number(invoiceItem?.totalAmount)?.toFixed(2)}
                      </InvoiceDetailText>
                    </InvoiceInnerList>
                  </InvoiceListMain>
                </InvoiceDetail>
              </InvoiceTop>
              <InvoiceRibinMain>
                <InvoiceInnerRibbin>
                  <InvoiceRibbinHead>
                    <InvoiceRibbinRight></InvoiceRibbinRight>
                    Invoice
                    <InvoiceRibbinLeft></InvoiceRibbinLeft>
                  </InvoiceRibbinHead>
                </InvoiceInnerRibbin>
              </InvoiceRibinMain>
            </InvoiceTopWrapper>
            <BillMain mobile={mobile}>
              <BillFrom>
                <BillLable>From :</BillLable>
                <FromBillMain>
                  <BillHead>{organization?.name}</BillHead>
                  {organization?.address && <BillPara>{organization?.address}</BillPara>}
                  {organization?.phone && <BillPara>{organization?.phone}</BillPara>}
                  {organization?.email && <BillPara>{organization?.email}</BillPara>}
                </FromBillMain>
              </BillFrom>
              <BillFrom>
                <BillLable>To :</BillLable>
                <FromBillMain>
                  <BillHead>{invoiceItem?.customer?.companyName}</BillHead>
                  {invoiceItem?.customer?.addressLine1 && <BillPara>{invoiceItem?.customer?.addressLine1}</BillPara>}
                  {invoiceItem?.customer?.addressLine2 && <BillPara>{invoiceItem?.customer?.addressLine2}</BillPara>}
                  {invoiceItem?.customer?.phone && <BillPara>{invoiceItem?.customer?.phone}</BillPara>}
                  {invoiceItem?.customer?.email && <BillPara>{invoiceItem?.customer?.email}</BillPara>}
                </FromBillMain>
              </BillFrom>
            </BillMain>
            <InvoiceTablewrapper>
              <InvoiceTable>
                <InvoiceTableHead>
                  <InvoiceTableRow>
                    <TableHeadCell>Description</TableHeadCell>
                    <TableHeadCell>Qty</TableHeadCell>
                    <TableHeadCell>Unit Price</TableHeadCell>
                    <TableHeadCell>Amount</TableHeadCell>
                  </InvoiceTableRow>
                </InvoiceTableHead>
                <InvoiceTabaleBody>
                  {invoiceItem?.items?.map((item, index) => {
                    return (
                      <InvoiceTableRow key={`invoiceItem_${item?.id}_${index}`}>
                        <InvoicetdCell>{item?.desc}</InvoicetdCell>
                        <InvoicetdCell>{item?.qty}</InvoicetdCell>
                        <InvoicetdCell>
                          {`${currencySymbol?.symbol}`}
                          {Number(item?.unitPrice)?.toFixed(2)}
                        </InvoicetdCell>
                        <InvoicetdCell>
                          {`${currencySymbol?.symbol}`}
                          {Number(item?.amount)?.toFixed(2)}
                        </InvoicetdCell>
                      </InvoiceTableRow>
                    );
                  })}
                </InvoiceTabaleBody>
              </InvoiceTable>
            </InvoiceTablewrapper>
            <InvoiceAmountBlog>
              <InvoiceLeftBlog></InvoiceLeftBlog>
              <InvoiceListWrapper>
                <InvoiceAmountInner>
                  <InvoiceAmountList>
                    <AmountLable>Sub Total :</AmountLable>
                    <AmountDetail>
                      {`${currencySymbol?.symbol}`}
                      {Number(invoiceItem?.subTotal)?.toFixed(2)}
                    </AmountDetail>
                  </InvoiceAmountList>
                  <InvoiceAmountList>
                    <AmountLable>Discount ({invoiceItem?.discount}%) :</AmountLable>
                    <AmountDetail>
                      - {`${currencySymbol?.symbol}`}
                      {Number(invoiceItem?.totalDiscount)?.toFixed(2)}
                    </AmountDetail>
                  </InvoiceAmountList>
                  {invoiceItem?.tax?.map((item, index) => {
                    return (
                      <InvoiceAmountList key={`invoiceItem_${item?.id}_${index}`}>
                        <AmountLable>Tax ({item?.value}%) :</AmountLable>
                        <AmountDetail>
                          {`${currencySymbol?.symbol}`}
                          {Number(index === 0 ? invoiceItem?.totalTax : invoiceItem?.totalTax2)?.toFixed(2)}
                        </AmountDetail>
                      </InvoiceAmountList>
                    );
                  })}
                </InvoiceAmountInner>
                <InvoiceTotal>
                  <AmountLable>Amount Due :</AmountLable>
                  <AmountDetail>
                    {`${currencySymbol?.symbol}`}
                    {Number(invoiceItem?.totalAmount)?.toFixed(2)}
                  </AmountDetail>
                </InvoiceTotal>
                <InvoiceLine></InvoiceLine>
              </InvoiceListWrapper>
            </InvoiceAmountBlog>
            <InvoiceBottom>
              <InvoiceNote>Notes</InvoiceNote>
              <InvoiceNotePara>{invoiceItem?.notes}</InvoiceNotePara>
            </InvoiceBottom>
          </InvoiceBoxMain>
          <SpanInvoiceRight mobile={mobile}></SpanInvoiceRight>
        </InvoiceBoxWrappe>
        <BottomLogoWrapper>
          <LogoSpan>Powered by</LogoSpan>
          <InvoiceBottomLogo>
            <SalescampMainLogo src={SalescampLogo} />
          </InvoiceBottomLogo>
        </BottomLogoWrapper>
      </InvoiceWrapper>
      <Modal open={isPaymentModalOpen} onClose={onClosePaymentModal}>
        <PaymentModal onClose={onClosePaymentModal} />
      </Modal>
      <Menu
        id='delete-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenuOpen}
        onClose={onCloseMoreMenu}
        getContentAnchorEl={null}
        width={112}
        position='left'
        positionVertical='top'
        marginTop={20}>
        <MenuItem
          iconName='icon-delete'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Delete'
          onClick={() => {
            ConfirmDialog({
              title: 'Delete?',
              message: `Press ok to confirm delete this Invoice`,
              onCancel: () => {
                onCloseMoreMenu();
              },
              onOk: () => {
                onDeleteInvoice();
              }
            });
          }}
        />
      </Menu>
    </>
  );
};
export default ViewInvoice;
