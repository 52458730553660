import axios from 'axios';
import { setInvoiceList, setInvoiceItem, setInvoiceLoader } from '../actions/invoiceActions';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIErrorReason } from '../helpers/common';

/**
 * @desc get Invoice List
 */
export const getInvoiceList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setInvoiceLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/invoices/${organization_id}`);
    const { data } = response;
    if (data) {
      await dispatch(setInvoiceList(data));
      return true;
    }
  } catch (e) {
    dispatchInvoiceError(getAPIErrorReason(e) || 'Unable to get Invoice list please try again', dispatch);
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc get Next Invoice Number for Invoice Creation
 */
export const getNextInvoiceNumber = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setInvoiceLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/invoices/${organization_id}/newnumber`);
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    dispatchInvoiceError(
      getAPIErrorReason(e) || 'Unable to get next invoice number for creating invoice please try again',
      dispatch
    );
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc create new Invoice
 */
export const createNewInvoice = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/invoices`, payload);
    const { data } = response;
    if (data) {
      return data.data;
    }
  } catch (e) {
    dispatchInvoiceError(getAPIErrorReason(e) || 'Unable to create new Invoice please try again', dispatch);
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Delete Invoice Item
 */
export const deleteInvoiceItem = (organization_id, invoiceId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setInvoiceLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/invoices/${organization_id}/item/${invoiceId}`);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      await dispatch(getInvoiceList(organization_id));
      dispatchInvoiceSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchInvoiceError(getAPIErrorReason(e) || 'Unable to delete Invoice please try again', dispatch);
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Get Invoice Item
 */
export const getInvoiceItem = (organization_id, invoiceId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setInvoiceLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/invoices/${organization_id}/item/${invoiceId}`);
    let { data } = response;
    // previousLookupId is used to compare with lookupId, incase if lookupId changes,
    // then and only customer data should be changed
    data = { ...data, previousLookupId: data?.lookupId };
    if (data) {
      dispatch(setInvoiceItem(data));
      return true;
    }
  } catch (e) {
    dispatchInvoiceError(getAPIErrorReason(e) || 'Unable to get invoice item please try again', dispatch);
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Update Invoice Item
 */
export const updateInvoice = (invoiceId, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/invoices/${invoiceId}`, payload);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatchInvoiceSuccess(message, dispatch);
      return data.data;
    }
  } catch (e) {
    dispatchInvoiceError(getAPIErrorReason(e) || 'Unable to Update Invoice please try again', dispatch);
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

function dispatchInvoiceError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchInvoiceSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
