import { withStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Menu from '../../components/Menu/Menu';
import { isElectronApp } from '../../global/Helper';
import { Header3, Header7, Text1 } from '../../styles';
import Button from '../atom/Button/Button';

const NavbarLeft = styled.div`
  display: flex;
  align-items: center;
  -webkit-app-region: no-drag;
  ${(props) =>
    props.mobile &&
    css`
      display: none;
    `}
  ${(props) =>
    props.mac &&
    css`
      padding-left: 87px;
    `}
`;
const MobileHead = styled.div`
  display: block;
  ${(props) =>
    props.mobile
      ? css`
          display: flex;
          align-items: center;
          padding: 12px 16px;
          width: 100%;
          position: fixed;
          top: 0;
        `
      : css`
          display: none;
        `}
`;
const MobileInnerHeader = styled.div`
  display: flex;
  align-items: center;
`;
const PageText = styled.span`
  ${Header3}
  ${(props) =>
    props.mobile
      ? css`
          color: var(--dark-color);
          text-transform: capitalize;
          margin-left: 20px;
          white-space: nowrap;
        `
      : css`
          margin-left: 20px;
        `}
`;
const NavbarTopNavigation = styled.div`
  display: flex;
  align-self: center;
  justify-content: end;
`;
const ArrowNavigation = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const BackButton = styled(Button)`
  padding: 0px;
  box-shadow: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--gray-more-icon);
  margin-right: 2px;
  :hover {
    background-color: var(--gainsBoro-color);
  }
  ${() =>
    isElectronApp() &&
    css`
      :hover {
        background-color: var(--Gunmetal);
      }
    `}
  i {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
  }
  ${(props) =>
    props.disabled &&
    css`
      color: var(--gray-more-icon);
      :hover {
        background-color: transparent;
      }
    `}
`;
const ButtonLeft = styled.button`
  padding: 4px;
  box-shadow: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--gray-more-icon);
  margin-right: 2px;
  border: none;
  outline: none;
  cursor: pointer;
  :hover {
    background-color: var(--Gunmetal);
  }
  i {
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      color: var(--gray-more-icon);
      :hover {
        background-color: transparent;
      }
    `}
  ${(props) =>
    props.right &&
    css`
      margin-left: 2px;
      margin-right: 0;
    `}
    ${(props) =>
    props.left &&
    css`
      margin-left: 2px;
    `}
`;

const ForwordButton = styled(Button)`
  padding: 0px;
  box-shadow: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--gray-more-icon);
  margin-left: 2px;
  :hover {
    background-color: var(--gainsBoro-color);
  }
  ${() =>
    isElectronApp() &&
    css`
      :hover {
        background-color: var(--Gunmetal);
      }
    `}
  i {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
  }
  ${(props) =>
    props.disabled &&
    css`
      color: var(--gray-more-icon);
      :hover {
        background-color: transparent;
      }
    `}
`;
const ManeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
  position: relative;
  z-index: 9;
  ${(props) =>
    props.mobile
      ? css`
          background: transparent;
          flex-wrap: wrap;
          position: fixed;
          top: 0;
          width: 100%;
        `
      : css`
          border-bottom: 1px solid var(--gray-border-color);
          padding: 6px 12px;
          background: var(--cultured-color);
        `}
  ${() =>
    isElectronApp() &&
    css`
      border-bottom: 0;
      background: var(--nav-dark-color);
    `}
`;
const NavbarRightWrapper = styled.div`
  display: flex;
  align-items: center;
  -webkit-app-region: no-drag;
  ${(props) => props.win && css``}
`;
const NavbarRight = styled.div`
  margin-left: 6px;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  transition: all 300ms;
  :hover {
    background-color: var(--gainsboro-color);
    i {
      color: var(--gray-icon-color);
    }
  }
  :first-child {
    margin-left: 0;
    ${(props) =>
      props.mobile &&
      css`
        display: none;
      `}
  }
  :nth-child(2) {
    ${(props) =>
      props.mobile &&
      css`
        display: none;
      `}
  }
  :nth-child(3) {
    ${(props) =>
      props.mobile
        ? css`
            display: block;
            padding: 0;
            margin-right: 8px;
            height: 30px;
            width: 30px;
            :hover {
              background-color: transparent;
              i {
                color: var(--white-color);
              }
            }
          `
        : css`
            display: none;
          `}
  }
  :last-child {
    padding: 0;
    ${(props) =>
      props.mobile &&
      css`
        height: 30px;
        width: 30px;
      `}
  }
  ${() =>
    isElectronApp() &&
    css`
      :hover {
        background-color: var(--Gunmetal);
      }
    `}
`;
const NavbarButtonWrapper = styled.div`
  -webkit-app-region: no-drag;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FeedbackModal = styled.div`
  display: flex;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const MenuUl = styled.ul`
  list-style: none;
  margin: 6px 0 6px 0;
  padding: 0;
`;
const SwitchList = styled.div`
  padding: 6px 0 8px 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #4a4e59;
  cursor: pointer;
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const SpanListMenu2 = styled.span`
  ${Text1};
  color: var(--white-color);
  margin-right: 4px;
`;
const IconViewt = styled.div`
  display: inline-flex;
  min-height: 18px;
  min-width: 18px;
`;
const WorkSpace = styled.span`
  color: var(--disable-color);
  ${Header7}
  display: block;
`;
const StyledMenu = withStyles({
  paper: {
    border: '1px solid var(--nav-dark-color-focus)',
    padding: '0px 6px',
    background: 'var(--nav-dark-color-hover)',
    width: '184px',
    marginTop: '12px',
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));

export {
  NavbarLeft,
  NavbarTopNavigation,
  ArrowNavigation,
  ForwordButton,
  BackButton,
  ManeWrapper,
  NavbarRight,
  NavbarRightWrapper,
  NavbarButtonWrapper,
  ButtonLeft,
  MobileHead,
  PageText,
  SvgWrapper,
  FeedbackModal,
  MenuUl,
  SwitchList,
  FirstList,
  SpanListMenu2,
  IconViewt,
  WorkSpace,
  StyledMenu,
  MobileInnerHeader
};
