import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set phone call loader
 */
export const setPhoneCallLoader = createAction(Actions.SET_PHONE_CALL_LOADER);

/**
 * @desc Set phone call details
 */
export const setPhoneCallDetails = createAction(Actions.SET_PHONE_CALL_DETAILS);

/**
 * @desc update phone call details
 */
export const updatePhoneCallDetails = createAction(Actions.UPDATE_PHONE_CALL_DETAILS);

/**
 * @desc update output device list
 */
export const updateOutputDevices = createAction(Actions.UPDATE_OUTPUT_DEVICES);

/**
 * @desc update input device list
 */
export const updateInputDevices = createAction(Actions.UPDATE_INPUT_DEVICES);

/**
 * @desc Set Twilio Charges
 */
export const setTwilioCharges = createAction(Actions.SET_TWILIO_CHARGES);

/**
 * @desc Set Sms Item
 */
export const setSmsItem = createAction(Actions.SET_SMS_ITEM);

/**
 * @desc Update Sms Item
 */
export const updateSmsItem = createAction(Actions.UPDATE_SMS_ITEM);

/**
 * @desc Add Sms To Item
 */
export const addSmsToItem = createAction(Actions.ADD_SMS_TO_ITEM);

/**
 * @desc Clear Sms Item
 */
export const clearSmsItem = createAction(Actions.CLEAR_SMS_ITEM);

/**
 * @desc Set Call Forwarding Item
 */
export const setCallForwardingItem = createAction(Actions.SET_CALL_FORWARDING_ITEM);

/**
 * @desc Update Call Forwarding Item
 */
export const updateCallForwardingItem = createAction(Actions.UPDATE_CALL_FORWARDING_ITEM);

/**
 * @desc Clear Call Forwarding Item
 */
export const clearCallForwardingItem = createAction(Actions.CLEAR_CALL_FORWARDING_ITEM);

/**
 * @desc Clear phone call data
 */
export const clearPhoneCallData = createAction(Actions.CLEAR_PHONE_CALL_LOADER);
