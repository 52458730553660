import styled, { css } from 'styled-components';
import { Header1, Header4, Header5, Text1, Text2 } from '../../../styles';
import Lineargradient from '../../../assets/images/lightgradientbg.png';

const ManeWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const MacDragDro = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 24px;
  width: 100%;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
`;
const LoginWrap = styled.div`
  background: var(--white-color);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
const LoginLeft = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 50%;
        `}
  ${(props) =>
    props.isRoadMap &&
    css`
      max-width: 100%;
    `}
`;
const LoginRight = styled.div`
  max-width: 50%;
  width: 100%;
  height: 100%;
  padding: 36px 64px;
  overflow: hidden;
  background-image: url(${Lineargradient});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.mobile &&
    css`
      display: none;
    `}
  ${(props) =>
    props.isRoadMap &&
    css`
      display: none;
    `}
`;
const LoginImage = styled.img`
  height: auto;
  width: 100%;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 320px;
  margin: auto;
`;
const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;
  svg {
    display: block;
    height: 38px;
    width: 36px;
  }
`;
const Title = styled.h1`
  ${Header1}
  color: var(--dark-color);
  margin: 0;
  text-align: left;
  padding-top: 16px;
`;
const Form = styled.form``;
const Lable = styled.label`
  ${Header4}
  color: var(--sonic-silver);
  display: block;
  margin-bottom: 8px;
`;
const InputBlock = styled.div`
  margin-bottom: 10px;
  input {
    :focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 3px #afcdff, 1px 1px 2px 0 rgb(0 0 0 / 5%);
    }
  }
`;
const ButtonWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 16px 0px 24px 0px;
  padding-left: 2px;
  flex-wrap: wrap;
  position: relative;
`;
const LabelInput = styled.label`
  display: inline;
  position: relative;
  padding: 0px 0px 0px 0px;
  padding-bottom: 0px;
  cursor: pointer;
  ${Text2}
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const TextPopup = styled.span`
  ${Header5};
  font-style: normal;
  color: var(--gray-color);
  margin-left: 10px;
`;
const ForgotLink = styled.a`
  ${Header5}
  color: var(--primary-color);
  cursor: pointer;
`;
const LoginBtnBlock = styled.div`
  button {
    width: 100%;
    justify-content: center;
  }
`;
const BottomText = styled.span`
  display: block;
  ${Text1}
  text-align: center;
  color: var(--gray-color);
  margin-top: 32px;
`;
const CreateSpan = styled.span`
  color: var(--primary-color);
  cursor: pointer;
`;

export {
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  ButtonWrapper,
  ForgotLink,
  LoginLeft,
  LoginRight,
  LoginWrap,
  LoginImage,
  Lable,
  InputBlock,
  InputWrapperModal,
  LabelInput,
  TextPopup,
  LoginBtnBlock,
  BottomText,
  CreateSpan,
  MacDragDro
};
