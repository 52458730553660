import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';
import Message from '../../../components/Message/Message';
import { isEmail } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { changeEmail, trackUserProfileUpdateAnalyticActivity } from '../../../services/authServices';
import {
  FirstBox,
  PersonalList,
  FirstList,
  TitleHead,
  CaptionList,
  FillListTwo,
  InpView,
  InpViewTwo,
  BtnView
} from './styles';

const ChangeEmail = () => {
  const mobile = useMobileDevice();
  const authSelector = useSelector((state) => state.auth);
  const { loading } = authSelector;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!email) {
      validationErrors.email = 'Required';
      valid = false;
    } else if (!isEmail(email)) {
      validationErrors.email = 'Please enter a valid email address.';
      valid = false;
    }
    if (!password) {
      validationErrors.password = 'Required';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [email, password]);

  const handleEmailChange = useCallback(async () => {
    const validateResult = validateInputs();
    if (!validateResult) return;

    const params = {
      email: email,
      password: password
    };

    const result = await dispatch(changeEmail(params));
    if (result) {
      trackUserProfileUpdateAnalyticActivity('email changed');
    }
  }, [validateInputs, dispatch, email, password]);

  return (
    <FirstBox>
      <PersonalList>
        <FirstList>
          <TitleHead>Change your Email</TitleHead>
          <CaptionList mobile={mobile}>How to change the email address registered on your account.</CaptionList>
          <FillListTwo mobile={mobile}>
            <InpView>
              <Input
                isPrimarySmall={true}
                type='email'
                id='email'
                name='email'
                isRequierd
                placeholder='Enter your Email Address'
                title='Email Address'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {validationErrors?.email && <Message text={validationErrors.email} isSuccess={false} />}
            </InpView>
            <InpViewTwo mobile={mobile}>
              <Input
                isPrimarySmall={true}
                type='password'
                id='password'
                name='currentPassword'
                placeholder='Enter your Password'
                title='Password'
                isRequierd
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {validationErrors?.password && <Message text={validationErrors.password} isSuccess={false} />}
            </InpViewTwo>
          </FillListTwo>
        </FirstList>
        <BtnView>
          <Button title='Change Email' onClick={handleEmailChange} type='button' disabled={loading} />
        </BtnView>
      </PersonalList>
    </FirstBox>
  );
};

export default ChangeEmail;
