import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason, setFieldConditionOptions } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/Environment';
import {
  deleteAutomationItem,
  setAutomationItem,
  setAutomationList,
  setAutomationLoader
} from '../actions/automationActions';
import { getLookupFields } from './lookupService';

/**
 * @desc Automation - Get Automations
 * @param {*} organization_id
 */
export const getAutomationList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setAutomationLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Automations/${organization_id}`);
    const { data } = response;
    if (data) {
      dispatch(setAutomationList(data));
      return true;
    }
  } catch (e) {
    dispatchAutomationError(getAPIErrorReason(e) || 'Unable to get automation please try again', dispatch);
    return false;
  } finally {
    dispatch(setAutomationLoader(false));
  }
};

/**
 * @desc Automation - Create Automations
 * @param {*} organization_id,payload
 */
export const createAutomation = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setAutomationLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Automations/${organization_id}/Create`, payload);
    const { data } = response;
    if (data) {
      await dispatch(getAutomationList(organization_id));
      return true;
    }
  } catch (e) {
    dispatchAutomationError(getAPIErrorReason(e) || 'Unable to create automation please try again', dispatch);
    return false;
  } finally {
    dispatch(setAutomationLoader(false));
  }
};

/**
 * @desc Automation - Update Automations
 * @param {*} organization_id,automation_id,payload
 */
export const updateAutomation = (organization_id, automation_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setAutomationLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/Automations/${organization_id}/update/${automation_id}`,
      payload
    );
    const { data } = response;
    if (data) {
      await dispatch(getAutomationList(organization_id));
      return true;
    }
  } catch (e) {
    dispatchAutomationError(getAPIErrorReason(e) || 'Unable to update automation please try again', dispatch);
    return false;
  } finally {
    dispatch(setAutomationLoader(false));
  }
};

/**
 * @desc Automation - Get Automation Item
 * @param {*} organization_id,automation_id
 */
export const getAutomationItem = (organization_id, automation_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setAutomationLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Automations/${organization_id}/item/${automation_id}`);
    const { data } = response;
    if (data) {
      const result = await dispatch(getLookupFields(organization_id, data?.lookupTableId));

      data.steps = data?.steps?.map((item) => {
        if (item?.action === 1) {
          let localField = result?.find((x) => x?.id === item?.actionConditionValue?.fieldId);
          if (localField) {
            localField = {
              ...localField,
              fieldOption: localField?.options,
              options: undefined
            };
          }
          const actionConditionValue = {
            ...item.actionConditionValue,
            localField
          };

          setFieldConditionOptions(actionConditionValue, 'localField');
          return {
            ...item,
            actionConditionValue: actionConditionValue
          };
        } else return item;
      });

      dispatch(setAutomationItem(data));
      return data;
    }
  } catch (e) {
    dispatchAutomationError(getAPIErrorReason(e) || 'Unable to get automation Item please try again', dispatch);
    return false;
  } finally {
    dispatch(setAutomationLoader(false));
  }
};

/**
 * @desc Automation - Get Automations
 * @param {*} organization_id
 */
export const updateAutomationStatus = (organization_id, automation_id, status) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    const response = await axios.put(
      `${REACT_APP_APIURL}/Automations/${organization_id}/status/${automation_id}/${status}`
    );
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    dispatchAutomationError(getAPIErrorReason(e) || 'Unable to get automation status please try again', dispatch);
    return false;
  } finally {
    dispatch(setAutomationLoader(false));
  }
};

/**
 * @desc Automation - Delete Automations
 * @param {*} organization_id
 */
export const deleteAutomation = (organization_id, automation_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setAutomationLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/Automations/${organization_id}/item/${automation_id}`);
    const { data } = response;
    if (data) {
      dispatch(deleteAutomationItem({ id: automation_id }));
      dispatchAutomationSuccess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchAutomationError(getAPIErrorReason(e) || 'Unable to delete automation please try again', dispatch);
    return false;
  } finally {
    dispatch(setAutomationLoader(false));
  }
};

function dispatchAutomationError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchAutomationSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
