import styled, { css } from 'styled-components';
import { MenuItem, withStyles } from '@material-ui/core';
import { Header1, Header2, Header3, Header4, Header5, Text1, Text2 } from '../../../styles';
import { CALL_NUMBER_VERIFICATION_STEP } from '../../../constants/constant';
import Button from '../../../components/atom/Button/Button';

const MainList = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;
const MainWrap = styled.div`
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          padding: 0 16px 16px 16px;
        `
      : css`
          width: 680px;
          padding: 24px 0px;
        `}
`;
const HeadView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h2`
  margin: 0;
  ${Header2};
  color: var(--dark-color);
`;

const ButtonWrapper = styled.div`
  margin: 32px 0 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AvtarWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-bg);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
`;
const AvtarWrapperBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-bg);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--primary-color-secondery-active);
  ${Text2}
  white-space: nowrap;
`;
const ContainBox = styled.div`
  background: var(--white-color);
  border-radius: 6px;
  ${(props) =>
    props.hasPhoneNumber &&
    css`
      border: 1px solid var(--gray-border-color);
    `}
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--gray-border-color);
  &:last-child {
    border-bottom: 0;
  }
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const SubText = styled.h5`
  margin: 0 18px 0 0;
  ${Header4};
  color: var(--dark-color);
  ${(props) =>
    props.debit &&
    css`
      color: var(--danger-Base-bg);
    `}
  ${(props) =>
    props.credit &&
    css`
      color: var(--green-color);
    `}
`;

const CaptionText = styled.p`
  ${Text2};
  color: var(--gray-color);
  background-color: var(--popup-color-hover);
  border-radius: 14px;
  text-align: center;
  padding: 2px 10px;
  display: block;
  ${(props) =>
    props.mobile
      ? css`
          margin: 10px 0 0 0; ;
        `
      : css`
          margin: 0 0 0 10px;
        `}
`;
const SecondList = styled.div`
  display: flex;
  align-items: center;
`;
const IconView = styled.div`
  cursor: pointer;
  display: inline-flex;
`;
const AssignUser = styled.div``;
const IconWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--white-color);
  cursor: pointer;
`;
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      background: 'var(--popup-color-hover)'
    },
    borderRadius: '5px;',
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '17px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '2px 6px',
    padding: '5px 6px',
    cursor: 'pointer'
  }
}))(MenuItem);
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-left: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    right: 0px;
    margin: 0;
    top: 4px;
  }
  .checkmark {
    position: absolute;
    top: 4px;
    right: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ProfileName = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 12px;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContainBoxTwo = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-top: 24px;
  padding: 12px 16px;
`;
const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftList = styled.div`
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
  }
`;
const TextCall = styled.h6`
  margin: 0 8px 0 0;
  ${Header3}
  color: var(--dark-color);
`;
const RightList = styled.div`
  display: flex;
  align-items: center;
  .tgl {
    display: none;
  }
  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .tgl::-moz-selection,
  .tgl:after::-moz-selection,
  .tgl:before::-moz-selection,
  .tgl *::-moz-selection,
  .tgl *:after::-moz-selection,
  .tgl *:before::-moz-selection,
  .tgl + .tgl-btn::-moz-selection {
    background: none;
  }
  .tgl::selection,
  .tgl:after::selection,
  .tgl:before::selection,
  .tgl *::selection,
  .tgl *:after::selection,
  .tgl *:before::selection,
  .tgl + .tgl-btn::selection {
    background: none;
  }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 36px;
    height: 14px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .tgl + .tgl-btn:after,
  .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
  }
  .tgl + .tgl-btn:after {
    left: 0;
  }
  .tgl + .tgl-btn:before {
    display: none;
  }
  .tgl:checked + .tgl-btn:after {
    left: 50%;
  }

  .tgl-light + .tgl-btn {
    background: rgba(0, 0, 0, 0.38);
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
  }
  .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fafafa;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 2px rgba(0, 0, 0, 0.02);
    transition: all 0.2s ease;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -3px;
  }
  .tgl-light:checked + .tgl-btn {
    background: #2e69ce;
  }
`;
const RightCredit = styled.div`
  margin-left: 12px;
  cursor: pointer;
`;
const Transaction = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  margin-right: 6px;
`;
const WarningList = styled.div`
  padding: 12px 16px;
  background-color: var(--cornsilk-color);
  border: 1px solid var(--yellow-lite-border);
  border-radius: 6px;
  margin-top: 20px;
`;
const WarnText = styled.p`
  margin: 0;
  ${Text2};
  color: var(--sonic-silver);
`;
const AmountText = styled.span`
  ${Header3};
  color: var(--dark-color);
  display: inline-block;
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 0 0 10px;
        `
      : css`
          margin: 8px 10px 0 0;
        `}
`;
const SupportView = styled.div`
  padding: 7px 15px;
`;
const Warncaption = styled.p`
  ${Text2}
  margin: 0;
  color: var(--dark-color);
`;
const Knowt = styled.span`
  ${Text2}
  color: var(--primary-color);
  cursor: pointer;
`;
const SupportT = styled.h4`
  margin: 10px 0 0 0;
  ${Text2}
  cursor: pointer;
  color: var(--primary-color);
`;
//Modal
const MainContact = styled.div`
  position: relative;
  outline: none;
`;
const ModalStyle = {
  width: '640px'
};
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  display: inline-block;
  margin-left: 12px;
`;
const OptionWrapper = styled.div`
  padding: 16px;
`;
const OptionBoxUl = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -8px;
  padding-bottom: 22px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const OptionBoxLi = styled.li`
  width: 100%;
  max-width: 33.33%;
  padding: 0 8px;
  list-style: none;
`;
const OptionBox = styled.div`
  height: 100%;
`;
const OptionBoxLabel = styled.div`
  border-radius: 6px;
  display: flex;
  padding: 24px 16px;
  box-shadow: 0 0 0 1px var(--gray-border-color);
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
  z-index: 1;
  ${(props) =>
    props.border &&
    css`
      border: 2px solid var(--primary-color);
      ::before,
      ::after {
        top: 100%;
        left: 50%;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      ::before {
        z-index: 2;
        border: 12px solid var(--before-after-color);
        border-bottom-color: var(--gray-border-color);
        margin-left: -12px;
      }
      ::after {
        z-index: 2;
        border: 13px solid var(--before-after-color);
        border-bottom-color: var(--white-color);
        margin-left: -13px;
      }
    `}
  :hover {
    border: 2px solid var(--primary-color);
    cursor: pointer;
  }
`;
const OptionBoxSpan = styled.span`
  ${Header4}
  margin-top: 16px;
  display: block;
  color: var(--dark-color);
`;
const OptionBoxSpanPara = styled.p`
  ${Text1}
  color:var(--gray-color);
  margin: 6px 0 0 0;
`;
const StepWrapper = styled.div``;
const Step1 = styled.div``;
const Form = styled.form``;
const TwoInput = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 16px;
`;
const ListWrappper = styled.div`
  width: 100%;
  margin-right: 16px;
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: block;
`;
const BottomView = styled.div`
  border-top: 1px solid var(--gray-border-color);
  padding: 16px;
  display: flex;
  align-items: center;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Step2 = styled.div``;
const BtnView = styled.div`
  margin-left: 12px;
`;
const StepPara = styled.p`
  margin: 0;
  ${Text1}
  color:var(--gray-color);
  letter-spacing: 0.4px;
  a {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }
`;
const VerifyModal = styled.div`
  outline: none;
`;
const TitleViewlist = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const VerfyList = styled.div`
  text-align: center;
  padding: 16px;
`;
const IconVerify = styled.div`
  margin-bottom: 16px;
`;
const Mobnub = styled.h4`
  margin: 0;
  ${Header1}
  color: var(--dark-color);
`;
const SpanText = styled.span`
  ${Header3}
  display: block;
  margin-top: 6px;
  color: var(--dark-color);
`;
const CaptionVerify = styled.p`
  margin: 16px 0;
  color: var(--gray-color);
  ${Text1}
`;
const BtnViewList = styled.div`
  ${(props) =>
    props.step === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFIED_STEP &&
    css`
      margin-top: 16px;
    `}
`;
const Status = styled.span`
  ${Text1};
  color: var(--dark-color);
  max-width: 100%;
  margin: 0 9px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PopupView = styled.div`
  padding: 6px 0;
`;
const ErrorView = styled.div`
  background: #fff1e7;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  text-align: left;
  margin-bottom: 16px;
`;
const ErrorText = styled.p`
  margin: 0 0 0 10px;
  ${Text2}
  color: #F08334;
`;
const CalliingPhone = styled.div`
  padding: 16px 80px;
`;
const CallNubView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const CallText = styled.span`
  margin: 0 4px 0 10px;
  display: block;
  ${Text1}
  color: var(--gray-color);
`;
const CallNub = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const VerifyCodeView = styled.div`
  text-align: center;
  padding-top: 16px;
`;
const Verification = styled.p`
  ${Header3}
  color: var(--dark-color);
  margin: 0;
`;
const Codenub = styled.h3`
  margin: 16px 0 0 0;
  ${Header1}
  color: var(--dark-color);
`;
const ModalFormWrapper = styled.form``;

const NavigationWrapper = styled.div`
  cursor: pointer;
  ${Text1}
  color:var(--gray-color);
  display: flex;
  align-items: center;
  margin-left: -8px;
`;

const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CreditBox = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  margin-top: 24px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const CreditWrapper = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    `}
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ErrorMsg = styled.div`
  padding: 0 16px;
  div {
    margin-bottom: 16px;
  }
`;
const AmountWrapper = styled.div`
  padding: 16px;
`;
const ValidationWrapper = styled.div`
  padding: 6px 0;
  div {
    margin: 0;
  }
`;
const ButtonMargin = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: end;
`;
const ButtonCredit = styled.div`
  margin-right: 12px;
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const NavigationLeft = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
`;
const NavigationRight = styled.div``;
const TransactionsListWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
`;
const TransactionsList = styled.div`
  border-bottom: 1px solid var(--gray-border-color);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :last-child {
    border-bottom: 0;
  }
`;
const CreditsIcon = styled.div`
  width: 44px;
  height: 44px;
  background-color: var(--popup-color-hover);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 44px;
  margin-right: 16px;
`;
const ListDetails = styled.div``;
const ListWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ListDuration = styled.h4`
  ${Text1}
  margin: 0;
  padding-left: 12px;
  color: var(--gray-color);
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: var(--gray-border-color);
  }
`;
const ListTwo = styled.div`
  ${Text1}
  color:var(--gray-color);
  margin-right: 8px;
`;
const ListOne = styled.h3`
  margin: 0 0 2px 0;
  ${Header4}
  color:var(--dark-color);
`;
const DetailsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
const TimeDetail = styled.h3`
  margin: 0;
  ${Text2}
  color:var(--gray-color);
`;
const RupeesDetail = styled.h3`
  margin: 0 0 0 24px;
  color: #1ad36f;
  ${Header5}
  ${(props) =>
    props.fail &&
    css`
      color: #ff322e;
    `}
`;

const TwillioImg = styled.img``;
const LogoBlock = styled.div`
  padding: 16px;
  border: 1px solid var(--gray-border-color);
  border-radius: 8px;
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
`;
const TopRow = styled.div`
  max-width: 444px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
`;
const TwillioWrapper = styled.div`
  margin: 32px 0 0 0;
`;
const BottomRow = styled.div`
  max-width: 330px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TwillioSpan = styled.span`
  display: block;
  text-align: center;
  ${Text1}
  margin: 32px 0 0px 0;
`;
const LearnMore = styled.a`
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export {
  WrapperTitle,
  NavigationWrapper,
  ModalFormWrapper,
  MainList,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  SecondList,
  IconView,
  AssignUser,
  IconWrapper,
  StyledMenuItem,
  InputWrapperModal,
  InputLabel,
  ProfileName,
  ContainBoxTwo,
  FirstRow,
  TextCall,
  LeftList,
  RightList,
  WarningList,
  WarnText,
  AmountText,
  SupportView,
  Warncaption,
  Knowt,
  SupportT,
  MainContact,
  TitleView,
  TitleText,
  OptionWrapper,
  OptionBoxUl,
  OptionBoxLi,
  OptionBox,
  OptionBoxLabel,
  OptionBoxSpan,
  OptionBoxSpanPara,
  StepWrapper,
  Step1,
  Form,
  TwoInput,
  ListWrappper,
  DropDownTitle,
  BottomView,
  LeftWrapper,
  Step2,
  BtnView,
  StepPara,
  VerifyModal,
  TitleViewlist,
  VerfyList,
  IconVerify,
  Mobnub,
  SpanText,
  CaptionVerify,
  BtnViewList,
  Status,
  PopupView,
  ErrorView,
  ErrorText,
  AvtarWrapper,
  AvtarWrapperBox,
  CalliingPhone,
  CallNubView,
  CallText,
  CallNub,
  VerifyCodeView,
  Verification,
  Codenub,
  ButtonWrapper,
  RightCredit,
  Transaction,
  CreditWrapper,
  CreditBox,
  ModalStyle,
  HeaderWrapper,
  ErrorMsg,
  AmountWrapper,
  ValidationWrapper,
  ButtonMargin,
  ButtonCredit,
  Navigation,
  NavigationLeft,
  NavigationRight,
  TransactionsListWrapper,
  TransactionsList,
  CreditsIcon,
  ListDuration,
  ListTwo,
  ListWrapper,
  ListOne,
  ListDetails,
  DetailsRight,
  TimeDetail,
  RupeesDetail,
  MobileButton,
  MainMobileButton,
  TwillioImg,
  LogoBlock,
  TopRow,
  TwillioWrapper,
  BottomRow,
  TwillioSpan,
  LearnMore
};
