import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/Environment';
import {
  setLookupTables,
  setLookupTableLoader,
  setTableFields,
  setLookupTableItem
} from '../actions/lookupTableActions';
import { LOOKUP_TYPE } from '../constants/constant';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';

/**
 * @desc LookupTable - Get Lookup Tables
 * @param {*} obj Data Obj
 */
export const getLookupTables =
  (company_id, isRefresh = false) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return false;
      let local_tables = OrganizationPreferencesSingleton.getInstance().getTables();
      dispatch(setLookupTables(local_tables));

      if (isRefresh) {
        dispatch(setLookupTableLoader(true));
        const response = await axios.get(`${REACT_APP_APIURL}/LookupTables/${company_id}`);
        const { data } = response;
        const tables = data?.filter((x) => x.type !== LOOKUP_TYPE.leads) || [];
        let collections = await setTableOrderByOldSortedList(tables, local_tables);
        dispatch(setLookupTables(collections));
        OrganizationPreferencesSingleton.getInstance().setTables(collections);
      }
      return true;
    } catch (e) {
      dispatchLookupTablesError(getAPIErrorReason(e) || 'Unable to get lookup tables please try again', dispatch);
      return false;
    } finally {
      dispatch(setLookupTableLoader(false));
    }
  };

/**
 * @desc LookupTable - set Table order by old sorted list
 */
export const setTableOrderByOldSortedList = (newList, oldList) => {
  let collections = [];
  const newTables = [];
  newList?.forEach((table, index) => {
    if (table?.id !== oldList[index]?.id) {
      const tableIndex = oldList?.findIndex((x) => x?.id === table?.id);
      if (tableIndex === -1) {
        newTables?.push(table);
      } else {
        collections?.splice(tableIndex, 0, table);
      }
    } else {
      collections?.push(table);
    }
  });
  collections = collections?.concat(newTables);
  return collections;
};

/**
 * @desc LookupTable - get Table Columns
 * @param {*} obj Data Obj
 */
export const getTableFields =
  (company_id, isRefresh = false) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return false;
      let fields = OrganizationPreferencesSingleton.getInstance().getFields();
      dispatch(setTableFields(fields));

      if (isRefresh) {
        dispatch(setLookupTableLoader(true));
        const response = await axios.get(`${REACT_APP_APIURL}/Settings/${company_id}`);
        const { data } = response;
        dispatch(setTableFields(data?.lookup));
        OrganizationPreferencesSingleton.getInstance().setFields(data?.lookup);
        OrganizationPreferencesSingleton.getInstance().setSegments(data?.segments);
      }
      return true;
    } catch (e) {
      dispatchLookupTablesError(getAPIErrorReason(e) || 'Unable to get table fields please try again', dispatch);
      return false;
    } finally {
      dispatch(setLookupTableLoader(false));
    }
  };

export const getTableFieldsFromRedux = () => (dispatch, getState) => {
  const state = getState();
  //Get all table fields
  const tableWiseFieldsfields = state?.lookupTables?.tableFields.map((x) => {
    return {
      tableId: x.tableId,
      fields: x.columns?.map((y) => {
        return {
          tableId: x.tableId,
          ...y
        };
      })
    };
  });
  const fields = [].concat.apply(
    [],
    tableWiseFieldsfields?.map((x) => x?.fields)
  );
  return fields;
};

/**
 * @desc Get Lookup Table Item
 */
export const getLookupTableItem = (organization_id, tableId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupTableLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/lookupTables/${organization_id}/item/${tableId}`);
    const { data } = response;

    data && dispatch(setLookupTableItem(data));
    return true;
  } catch (e) {
    dispatchLookupTablesError(getAPIErrorReason(e) || 'Unable to get Lookup Table Item please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupTableLoader(false));
  }
};

/**
 * @desc Create Lookup Table Item - Settings
 */
export const createLookupTableItem = (organization_id, params) => async (dispatch) => {
  try {
    if (!params?.label) {
      dispatchLookupTablesError('Table Label Field is Required', dispatch);
      return false;
    } else if (!params?.name) {
      dispatchLookupTablesError('Table Name Field is Required', dispatch);
      return false;
    } else if (!params?.type) {
      dispatchLookupTablesError('Table Type Field is Required', dispatch);
      return false;
    }
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupTableLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookupTables/${organization_id}/create`, params);
    const { data } = response;
    const { message } = data || response;
    const { getOrganizationList } = require('./organizationService');
    data && (await dispatch(getOrganizationList())) && (await dispatch(getLookupTables(organization_id, true)));
    dispatchLookupTablesSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchLookupTablesError(getAPIErrorReason(e) || 'Unable to Create Lookup Table Item please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupTableLoader(false));
  }
};

/**
 * @desc Update Lookup Table Item - Settings
 */
export const updateLookupTableItem = (organization_id, tableId, params) => async (dispatch) => {
  try {
    if (!params?.label) {
      dispatchLookupTablesError('Table Label Field is Required', dispatch);
      return false;
    }
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupTableLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/lookupTables/${organization_id}/update/${tableId}`, params);
    const { data } = response;
    const { message } = data || response;
    const { getOrganizationList } = require('./organizationService');
    data && (await dispatch(getOrganizationList())) && (await dispatch(getLookupTables(organization_id, true)));
    dispatchLookupTablesSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchLookupTablesError(getAPIErrorReason(e) || 'Unable to update Lookup table item please try again', dispatch);
  } finally {
    dispatch(setLookupTableLoader(false));
  }
};

/**
 * @desc Delete Lookup Table Item - Settings
 */
export const deleteLookupTableItem = (organization_id, tableId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupTableLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/lookupTables/${organization_id}/item/${tableId}`);
    const { data } = response;
    const { message } = data || response;
    const { getOrganizationList } = require('./organizationService');
    data && (await dispatch(getOrganizationList())) && (await dispatch(getLookupTables(organization_id, true)));
    dispatchLookupTablesSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchLookupTablesError(getAPIErrorReason(e) || 'Unable to delete Lookup table item please try again', dispatch);
  } finally {
    dispatch(setLookupTableLoader(false));
  }
};

function dispatchLookupTablesError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchLookupTablesSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
