import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { ReactComponent as LoadingIcon } from '../../../../assets/images/svg/loader.svg';
import { callToVerifyPhoneNumber } from '../../../../services/settingService';
import {
  VerifyModal,
  TitleViewlist,
  TitleText,
  CalliingPhone,
  CallNubView,
  CallText,
  CallNub,
  VerifyCodeView,
  Verification,
  Codenub,
  HeaderWrapper,
  IconView
} from '../styles';
import SVGIcon from '../../../../assets/svg/SVGIcon';

const VerifyingPhoneNumberModal = ({ onVerifyingSuccess, onVerifyingFailed, onClose, phoneNumberItem }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const [timerCount, setTimerCount] = useState(240);

  const dispatch = useDispatch();

  useEffect(() => {
    const timerId = setInterval(async () => {
      if (!isEmpty(phoneNumberItem)) {
        let isSuccessResponse = true;
        if (timerCount > 0) {
          const count = timerCount - 1;
          setTimerCount(count);
          if (isSuccessResponse) {
            isSuccessResponse = false;
            const result = await dispatch(callToVerifyPhoneNumber(organization_id, phoneNumberItem?.id));
            if (result) {
              if (timerId) {
                clearInterval(timerId);
                onVerifyingSuccess();
              }
            }
            isSuccessResponse = true;
          }
        } else {
          if (timerId) {
            clearInterval(timerId);
            onVerifyingFailed();
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [dispatch, organization_id, onVerifyingFailed, onVerifyingSuccess, timerCount, phoneNumberItem]);

  return (
    <div>
      <VerifyModal>
        <TitleViewlist>
          <HeaderWrapper>
            <SVGIcon name='icon-cellphone' fill='var(--gray-icon-color)' size={22} />
            <TitleText>Verify External Number</TitleText>
          </HeaderWrapper>
          <IconView
            onClick={() => {
              onClose();
            }}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
          </IconView>
        </TitleViewlist>
        <CalliingPhone>
          <CallNubView>
            <LoadingIcon />
            <CallText>Calling</CallText>
            <CallNub>{`${phoneNumberItem?.countryCode} ${phoneNumberItem?.number}`}</CallNub>
          </CallNubView>
          <VerifyCodeView>
            <Verification>Answer and enter the verification code:</Verification>
            <Codenub>{phoneNumberItem?.validationCode}</Codenub>
          </VerifyCodeView>
        </CalliingPhone>
      </VerifyModal>
    </div>
  );
};

export default VerifyingPhoneNumberModal;
