export const ConditionOption = {
  Text: [
    { value: 'is', text: 'Is', valueType: 1 },
    { value: 'isnot', text: 'is not', valueType: 1 },
    { value: 'contains', text: 'contains', valueType: 1 },
    { value: 'endwith', text: 'end with', valueType: 1 },
    { value: 'notcontains', text: 'does not contains', valueType: 1 },
    { value: 'isunknown', text: 'is unknown', valueType: -1 },
    { value: 'any', text: 'has any value', valueType: -1 }
  ],
  Number: [
    { value: 'is', text: 'Is', valueType: 2 },
    { value: 'isnot', text: 'is not', valueType: 2 },
    { value: 'lessthan', text: 'less than', valueType: 2 },
    { value: 'morethan', text: 'more than', valueType: 2 },
    { value: 'isunknown', text: 'is unknown', valueType: -1 },
    { value: 'any', text: 'has any value', valueType: -1 }
  ],
  DateTime: [
    { value: 'morethan', text: 'more than', valueType: 2 },
    { value: 'lessthan', text: 'less than', valueType: 2 },
    { value: 'is', text: 'exactly', valueType: 2 },
    { value: 'on', text: 'on', valueType: 3 },
    { value: 'before', text: 'before', valueType: 3 },
    { value: 'after', text: 'after', valueType: 3 },
    { value: 'isunknown', text: 'is unknown', valueType: -1 },
    { value: 'any', text: 'has any value', valueType: -1 }
  ],
  Option: [
    { value: 'is', text: 'Is', valueType: 4 },
    { value: 'isnot', text: 'is not', valueType: 4 },
    { value: 'any', text: 'has any value', valueType: -1 }
  ],
  Boolean: [
    { value: 'is', text: 'Is', valueType: 5 },
    { value: 'isnot', text: 'is not', valueType: 5 },
    { value: 'any', text: 'has any value', valueType: -1 }
  ]
};
