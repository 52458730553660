import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/atom/Button/Button';
import Avtar from '../../../../components/Avtar/Avtar';
import { Menu } from '../../../../components/Menu';
import TwilioService, { getIPLocation } from '../../../../services/twilioService';

import { LOOKUP_TYPE, LookupFieldTypes, SEND_EMAIL_TYPE } from '../../../../constants/constant';
import { getGravatarURL, isEmpty, removeSpecialCharactersFromNumber } from '../../../../helpers/common';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import { trackContactDetailActionAnalytics } from '../../../../services/lookupService';
import { ReactComponent as Facebook } from '../../../../assets/images/svg/social-icon-facebook.svg';
import { ReactComponent as GooglePlus } from '../../../../assets/images/svg/social-icon-google-plus.svg';
import { ReactComponent as Linkdin } from '../../../../assets/images/svg/social-icon-linkdin.svg';
import { ReactComponent as Skype } from '../../../../assets/images/svg/social-icon-skype.svg';
import { ReactComponent as Twitter } from '../../../../assets/images/svg/social-icon-twitter.svg';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import Modal from '../../../../components/Modal';
import SendEmailModal from '../../../Email/SendEmailModal/SendEmailModal';
import {
  ProFileContain,
  ProfileLogo,
  UserView,
  UserName,
  UserLeave,
  BtnContain,
  IconView,
  PhoneIconWrapper,
  SocialBtn,
  SocialContain,
  ButtonWrapper,
  ContactProfileBlock,
  WhatsappView,
  StyledMenuItem,
  InputWrapperModal,
  InputLabel,
  Status,
  ModalStyle
} from '../styles';
import SendSmsModal from '../SendSmsModal/SendSmsModal';
import UserPreferenceSingleton from '../../../../helpers/UserPreferenceSingleton';
import { callForwardToDevice } from '../../../../services/activityService';
import { setErrorMessage } from '../../../../actions/messageActions';
import { CountryCodes } from '../../../../data/raw';

const ContactDetailProfile = ({
  hasExternalApp,
  lookupDetails,
  table,
  userAccess,
  tableTypeForAnalytics,
  onSendEmail
}) => {
  const mobile = useMobileDevice();
  const dispatch = useDispatch();

  const settingsSelector = useSelector((state) => state.setting);

  const { phoneNumberList } = settingsSelector;

  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;

  const [isEmailMenuOpen, setIsEmailMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [lookupIds, setLookupIds] = useState([]);
  const [isCallMenuOpen, setIsCallMenuOpen] = useState(false);
  const [isOpenWhatsappMenu, setIsOpenWhatsappMenu] = useState(false);
  const [isOpenSendSms, SetIsOpenSendSms] = useState(false);

  const isShowEmailIcon = !isEmpty(lookupDetails?.email);
  const isShowphoneIcon = !isEmpty(lookupDetails?.phone);

  const hasTwilioPhoneNumber = useMemo(() => {
    const twilioNumber = phoneNumberList?.filter((x) => x.isTwilioNumber);
    if (twilioNumber) {
      return twilioNumber.length > 0;
    } else {
      return false;
    }
  }, [phoneNumberList]);

  const hasPhoneNumber = useMemo(() => {
    return phoneNumberList?.length > 0;
  }, [phoneNumberList?.length]);

  const emailFields = useMemo(() => {
    const fields = lookupDetails?.fieldToDisplay?.filter(
      (x) => x.type === LookupFieldTypes.Email && x?.value?.trim() !== ''
    );
    return fields || [];
  }, [lookupDetails?.fieldToDisplay]);

  const phoneFields = useMemo(() => {
    const fields = lookupDetails?.fieldToDisplay?.filter(
      (x) => x.type === LookupFieldTypes.Phone && x?.value?.trim() !== ''
    );
    return fields || [];
  }, [lookupDetails?.fieldToDisplay]);

  const gravatar = useMemo(() => {
    let gravatar;
    if (table?.type === LOOKUP_TYPE.contacts && lookupDetails?.email) {
      gravatar = getGravatarURL(lookupDetails?.email);
    }
    return gravatar;
  }, [lookupDetails?.email, table?.type]);

  const openSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(true);
  }, []);

  const onOpenEmailMenu = useCallback((e) => {
    e.stopPropagation();
    setIsEmailMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onEmailClick = useCallback(
    (email) => {
      trackContactDetailActionAnalytics(tableTypeForAnalytics, 'email');
      openSendEmailModal();
      setLookupIds([{ id: lookupDetails?.id, email: email }]);
    },
    [lookupDetails?.id, openSendEmailModal, tableTypeForAnalytics]
  );

  const onOpenCallMenu = useCallback((e) => {
    e.stopPropagation();
    setIsCallMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCallClick = useCallback(
    async (number, item) => {
      const company = UserPreferenceSingleton.getInstance().getOrganization();
      trackContactDetailActionAnalytics(tableTypeForAnalytics, 'call');
      if (company?.settings?.isCallForwardOnDevice) {
        let phone = number;
        if (!phone) {
          dispatch(setErrorMessage('Phone Number not available to call'));
          return;
        }
        if (!phone.startsWith('+')) {
          //If number not have country code add country dial code(local)
          //Get location and country of user,if found find country code
          let IpLocation = getIPLocation();
          if (IpLocation) {
            let countryCode = IpLocation.country_code;
            if (countryCode) {
              let country = CountryCodes.find((x) => (x.countryCode = countryCode));
              if (country) {
                phone = country.code + phone;
              }
            }
          }
        }
        const payload = {
          id: item.id,
          value: phone
        };
        await dispatch(callForwardToDevice(company.id, item.id, payload));
      } else {
        dispatch(TwilioService.getInstance().connectCall(number, lookupDetails?.name, item));
      }
    },
    [dispatch, tableTypeForAnalytics, lookupDetails?.name]
  );

  const onOpenSendSms = useCallback(() => {
    SetIsOpenSendSms(true);
  }, []);

  const onOpenWhatsappMenu = useCallback((e) => {
    e.stopPropagation();
    setIsOpenWhatsappMenu(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseEmailMenu = useCallback(() => {
    setIsEmailMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onCloseCallMenu = useCallback(() => {
    setIsCallMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onCloseWhatsappMenu = useCallback(() => {
    setIsOpenWhatsappMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const onCloseSendSms = useCallback(() => {
    SetIsOpenSendSms(false);
  }, []);

  const closeSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(false);
    setLookupIds([]);
  }, []);

  const showWhatsappNumbers = useMemo(() => {
    return phoneFields?.map((item, index) => {
      let phone = removeSpecialCharactersFromNumber(item?.value);
      return (
        <StyledMenuItem key={`phoneFields_${item?.id}_${index}`}>
          <WhatsappView
            to={{ pathname: `https://wa.me/${phone}` }}
            target='_blank'
            onClick={() => {
              onCloseWhatsappMenu();
            }}>
            <InputLabel htmlFor={item?.id}>
              <SVGIcon name='icon-whatsapp' size={16} fill='var(--dark-color)' />
              <Status>{item?.value}</Status>
            </InputLabel>
          </WhatsappView>
        </StyledMenuItem>
      );
    });
  }, [onCloseWhatsappMenu, phoneFields]);

  const showWhasappButton = useMemo(() => {
    const phone = removeSpecialCharactersFromNumber(lookupDetails?.phone);
    return phoneFields?.length > 1 ? (
      <IconView title={'Whatsapp'}>
        <Button
          iconName='icon-whatsapp'
          iconColor='var(--gray-icon-color)'
          fontSize={18}
          secondary
          onClick={onOpenWhatsappMenu}></Button>
      </IconView>
    ) : (
      <WhatsappView to={{ pathname: `https://wa.me/${phone}` }} target='_blank' title={'Whatsapp'}>
        <Button iconName='icon-whatsapp' iconColor='var(--gray-icon-color)' fontSize={18} secondary></Button>
      </WhatsappView>
    );
  }, [lookupDetails?.phone, onOpenWhatsappMenu, phoneFields?.length]);

  const emailMenu = useMemo(() => {
    return (
      <Menu
        id='email-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isEmailMenuOpen}
        onClose={onCloseEmailMenu}
        className='email-menu'
        getContentAnchorEl={null}
        position='left'
        marginTop='35px'
        positionVertical='top'
        // width='180px'
      >
        {emailFields?.map((item, index) => {
          return (
            <StyledMenuItem key={`${item?.id}_${index}`}>
              <InputWrapperModal
                onClick={() => {
                  onEmailClick(item?.value);
                  onCloseEmailMenu();
                }}>
                <InputLabel htmlFor={item?.id}>
                  <SVGIcon name='icon-email' size={16} fill='var(--dark-color)' />
                  <Status>{item?.value}</Status>
                </InputLabel>
              </InputWrapperModal>
            </StyledMenuItem>
          );
        })}
      </Menu>
    );
  }, [emailFields, isEmailMenuOpen, menuAnchorEl, onCloseEmailMenu, onEmailClick]);

  const callMenu = useMemo(() => {
    return (
      <Menu
        id='call-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isCallMenuOpen}
        onClose={onCloseCallMenu}
        className='call-menu'
        getContentAnchorEl={null}
        position='left'
        marginTop='35px'
        positionVertical='top'
        width='180px'>
        {phoneFields?.map((item, index) => {
          return (
            <StyledMenuItem key={`phoneFields_${item?.id}_${index}`}>
              <InputWrapperModal
                onClick={() => {
                  onCallClick(item?.value, item);
                  onCloseCallMenu();
                }}>
                <InputLabel htmlFor={item?.id}>
                  <SVGIcon name='icon-call' size={16} fill='var(--dark-color)' />
                  <Status>{item?.value}</Status>
                </InputLabel>
              </InputWrapperModal>
            </StyledMenuItem>
          );
        })}
      </Menu>
    );
  }, [isCallMenuOpen, menuAnchorEl, onCallClick, onCloseCallMenu, phoneFields]);

  const whatsappMenu = useMemo(() => {
    return (
      <Menu
        id='call-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenWhatsappMenu}
        onClose={onCloseWhatsappMenu}
        className='call-menu'
        getContentAnchorEl={null}
        position='left'
        marginTop='35px'
        positionVertical='top'
        width='180px'>
        {showWhatsappNumbers}
      </Menu>
    );
  }, [isOpenWhatsappMenu, menuAnchorEl, onCloseWhatsappMenu, showWhatsappNumbers]);

  const sendSmsModal = useMemo(() => {
    return (
      <Modal
        id='simple-send-sms'
        open={isOpenSendSms}
        onClose={onCloseSendSms}
        position='center'
        positionVertical='center'>
        <>
          <SendSmsModal lookup={lookupDetails} onMenuClose={onCloseSendSms} />
        </>
      </Modal>
    );
  }, [isOpenSendSms, lookupDetails, onCloseSendSms]);

  const sendEmailModal = useMemo(() => {
    return (
      <Modal
        open={isOpenSendEmailModal}
        onClose={closeSendEmailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        style={ModalStyle}>
        <SendEmailModal
          handleCloseModal={closeSendEmailModal}
          onSend={onSendEmail}
          lookupIds={lookupIds}
          type={SEND_EMAIL_TYPE.COMPOSE}
        />
      </Modal>
    );
  }, [closeSendEmailModal, isOpenSendEmailModal, lookupIds, onSendEmail]);

  const buttonWrapperView = useMemo(() => {
    if (isShowEmailIcon && userAccess?.permissionSendMail && userAccess?.isEmailAccess) {
      return (
        <ButtonWrapper>
          <Button
            onClick={(e) => {
              emailFields?.length > 1 ? onOpenEmailMenu(e) : onEmailClick(lookupDetails?.email);
            }}
            title='Send Mail'
          />
        </ButtonWrapper>
      );
    }
    return null;
  }, [
    emailFields?.length,
    isShowEmailIcon,
    lookupDetails?.email,
    onEmailClick,
    onOpenEmailMenu,
    userAccess?.isEmailAccess,
    userAccess?.permissionSendMail
  ]);

  const phoneIconWrapperView = useMemo(() => {
    if (isShowphoneIcon && (hasPhoneNumber || organization?.settings?.isCallForwardOnDevice)) {
      return (
        <PhoneIconWrapper>
          <IconView title={'Call'}>
            <Button
              iconName='icon-phone'
              iconColor='var(--gray-icon-color)'
              fontSize={18}
              secondary
              onClick={(e) => {
                phoneFields?.length > 1 ? onOpenCallMenu(e) : onCallClick(lookupDetails?.phone, lookupDetails);
              }}></Button>
          </IconView>
          {showWhasappButton}
          {hasTwilioPhoneNumber && (
            <IconView onClick={onOpenSendSms} title={'Massage'}>
              <Button iconName='icon-message' iconColor='var(--gray-icon-color)' fontSize={18} secondary></Button>
            </IconView>
          )}
        </PhoneIconWrapper>
      );
    }
    return null;
  }, [
    hasPhoneNumber,
    hasTwilioPhoneNumber,
    isShowphoneIcon,
    lookupDetails,
    onCallClick,
    onOpenCallMenu,
    onOpenSendSms,
    phoneFields?.length,
    showWhasappButton,
    organization?.settings?.isCallForwardOnDevice
  ]);

  const socialContainView = useMemo(() => {
    if (
      lookupDetails?.facebook ||
      lookupDetails?.twitter ||
      lookupDetails?.linkedin ||
      lookupDetails?.skype ||
      lookupDetails?.google_plus
    ) {
      return (
        <SocialContain mobile={mobile}>
          {lookupDetails?.facebook && (
            <SocialBtn to={{ pathname: `${lookupDetails?.facebook}` }} target='_blank' title='Facebook'>
              <Facebook />
            </SocialBtn>
          )}
          {lookupDetails?.twitter && (
            <SocialBtn to={{ pathname: lookupDetails?.twitter }} target='_blank' title='Twitter'>
              <Twitter />
            </SocialBtn>
          )}
          {lookupDetails?.linkedin && (
            <SocialBtn to={{ pathname: `${lookupDetails?.linkedin}` }} target='_blank' title='Linkedin'>
              <Linkdin />
            </SocialBtn>
          )}
          {lookupDetails?.skype && (
            <SocialBtn to={{ pathname: lookupDetails?.skype }} target='_blank' title='Skype'>
              <Skype />
            </SocialBtn>
          )}
          {lookupDetails?.google_plus && (
            <SocialBtn to={{ pathname: lookupDetails?.google_plus }} target='_blank' title='Google Plus'>
              <GooglePlus />
            </SocialBtn>
          )}
        </SocialContain>
      );
    }
    return null;
  }, [
    lookupDetails?.facebook,
    lookupDetails?.google_plus,
    lookupDetails?.linkedin,
    lookupDetails?.skype,
    lookupDetails?.twitter,
    mobile
  ]);

  return (
    <>
      <ContactProfileBlock hasExternalApp={hasExternalApp} mobile={mobile}>
        <ProFileContain mobile={mobile}>
          <ProfileLogo>
            <Avtar imgSrc={gravatar} name={lookupDetails?.name} />
          </ProfileLogo>
          <UserView mobile={mobile}>
            <UserName>{lookupDetails?.name}</UserName>
            <UserLeave mobile={mobile}>{lookupDetails?.address}</UserLeave>
          </UserView>
        </ProFileContain>
        <BtnContain mobile={mobile}>
          {buttonWrapperView}
          {phoneIconWrapperView}
        </BtnContain>
        {socialContainView}
      </ContactProfileBlock>
      {emailMenu}
      {callMenu}
      {whatsappMenu}
      {sendSmsModal}
      {sendEmailModal}
    </>
  );
};

export default ContactDetailProfile;
