import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../components/atom/Button/Button';
import { deleteInvoiceItem, getInvoiceList } from '../../services/invoiceService';
import { isEmpty, isPastDate } from '../../helpers/common';
import { currencyNameWithCode } from '../../data/raw';
import { INVOICE_STATUS, INVOICE_STATUS_TEXT, TOOLTIP_DATE_FORMAT } from '../../constants/constant';
import { Menu, MenuItem } from '../../components/Menu';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import {
  HeaderWraper,
  HeaderLeft,
  InvoiceTitle,
  InvoiceType,
  HeaderInvoice,
  TableWrapper,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  TdBlog,
  TdSpan,
  Tbody,
  ViewIcon,
  MobileButton,
  HoverMoreIcon
} from './styles';

const Invoice = (...props) => {
  const { primary } = props;
  const mobile = useMobileDevice();
  const history = useHistory();
  const organizationSelector = useSelector((state) => state.organization);
  const invoiceSelector = useSelector((state) => state.invoice);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { invoiceList, loading } = invoiceSelector;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [filteredInvoiceList, setFilteredInvoiceList] = useState();
  const [filterType, setFilterType] = useState('open');
  const [selectedInvoice, setSelectedInvoice] = useState('');

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getInvoiceList(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!isEmpty(invoiceList)) {
      const list = invoiceList?.filter((invoice) => invoice?.status < INVOICE_STATUS.PAID);
      setFilteredInvoiceList(list);
    }
  }, [invoiceList]);

  const getCurrencySymbol = useCallback((currencyCode) => {
    const symbolObj = currencyNameWithCode?.find((x) => x.code === currencyCode);
    return symbolObj.symbol;
  }, []);

  const onOpenMoreMenu = useCallback((e, selectedInvoice) => {
    e.stopPropagation();
    setSelectedInvoice(selectedInvoice);
    setIsMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const getStatus = useCallback((statusType, dueDate) => {
    const hasPastDate = isPastDate(dueDate);
    const isPaidStatus = statusType === INVOICE_STATUS.PAID;
    const isOverDue = !isPaidStatus && hasPastDate;
    const status =
      isOverDue && statusType !== INVOICE_STATUS.DRAFT ? INVOICE_STATUS_TEXT.OVER_DUE : INVOICE_STATUS_TEXT[statusType];
    return status;
  }, []);

  const handleFilterInvoiceList = useCallback(
    (filterOption) => {
      if (filterOption === 'open') {
        setFilterType('open');
        const list = invoiceList?.filter((invoice) => invoice?.status < INVOICE_STATUS.PAID);
        setFilteredInvoiceList(list);
      } else {
        setFilterType('all');
        setFilteredInvoiceList(invoiceList);
      }
    },
    [invoiceList]
  );

  const onOpenInvoice = useCallback(
    (invoice) => {
      if (invoice?.status === INVOICE_STATUS.DRAFT) {
        history.push(`/invoices/edit/${invoice?.id}`);
      } else {
        history.push(`/invoices/view/${invoice?.id}`);
      }
    },
    [history]
  );

  const onEditInvoice = useCallback(() => {
    history.push(`/invoices/edit/${selectedInvoice?.id}`);
  }, [history, selectedInvoice?.id]);

  const onDeleteInvoice = useCallback(() => {
    onCloseMoreMenu();
    dispatch(deleteInvoiceItem(organization_id, selectedInvoice?.id));
  }, [dispatch, onCloseMoreMenu, organization_id, selectedInvoice?.id]);

  return (
    <>
      <HeaderWraper mobile={mobile}>
        <HeaderLeft>
          <SVGIcon name='icon-invoice' fill='var(--dark-color)' size={24} />
          <InvoiceTitle>Invoice</InvoiceTitle>
        </HeaderLeft>
        <HeaderInvoice>
          <InvoiceType
            className={filterType === 'open' && 'active'}
            onClick={() => {
              handleFilterInvoiceList('open');
            }}>
            Open
          </InvoiceType>
          <InvoiceType
            className={filterType === 'all' && 'active'}
            onClick={() => {
              handleFilterInvoiceList();
            }}>
            All Invoices
          </InvoiceType>
        </HeaderInvoice>
        {mobile ? (
          <MobileButton
            iconName='icon-pluse'
            iconColor='var(--gray-icon-color)'
            size='small'
            primary={primary}
            onClick={() => {
              history.push('/invoices/create');
            }}
          />
        ) : (
          <Button
            title='New Invoice'
            onClick={() => {
              history.push('/invoices/create');
            }}
            loading={loading}
          />
        )}
      </HeaderWraper>
      {!loading && (!invoiceList || invoiceList?.length === 0) ? (
        <TableWrapper>No Invoices Found</TableWrapper>
      ) : (
        <TableWrapper mobile={mobile}>
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Invoice No</Th>
                <Th>Invoice Date</Th>
                <Th>Amount</Th>
                <Th m>Status</Th>
              </Tr>
            </Thead>
            {loading && isEmpty(invoiceList) ? (
              <Tbody>Loading...</Tbody>
            ) : (
              <Tbody>
                {filteredInvoiceList &&
                  filteredInvoiceList?.length > 0 &&
                  filteredInvoiceList?.map((invoice) => {
                    const status = getStatus(invoice?.status, invoice?.dueDate);
                    return (
                      <Tr
                        key={invoice?.id}
                        onClick={() => {
                          onOpenInvoice(invoice);
                        }}>
                        <Td>
                          <TdBlog>
                            <TdSpan>
                              {invoice?.customer?.firstName ? `${invoice?.customer?.firstName} ` : null}
                              {invoice?.customer?.lastName ? `${invoice?.customer?.lastName}` : null}
                            </TdSpan>
                            {invoice?.customer?.companyName && <TdSpan>{invoice?.customer?.companyName}</TdSpan>}
                          </TdBlog>
                        </Td>
                        <Td>
                          <TdBlog>
                            <TdSpan>{invoice?.number}</TdSpan>
                          </TdBlog>
                        </Td>
                        <Td>
                          <TdBlog>
                            <TdSpan>{moment(invoice?.date).format('DD/MM/YYYY')}</TdSpan>
                            {invoice?.status !== INVOICE_STATUS.DRAFT && (
                              <TdSpan title={moment(invoice?.dueDate).format(TOOLTIP_DATE_FORMAT)}>{`Due ${moment(
                                invoice?.dueDate
                              ).fromNow()}`}</TdSpan>
                            )}
                          </TdBlog>
                        </Td>
                        <Td>
                          <TdBlog>
                            <TdSpan>
                              {getCurrencySymbol(invoice?.currency)}
                              {Number(invoice?.totalAmount).toFixed(2)}
                            </TdSpan>
                            {invoice?.status !== INVOICE_STATUS.DRAFT && invoice?.status !== INVOICE_STATUS.PAID && (
                              <TdSpan>
                                {`Due ${getCurrencySymbol(invoice?.currency)}`}
                                {Number(invoice?.dueAmount).toFixed(2)}
                              </TdSpan>
                            )}
                          </TdBlog>
                        </Td>
                        <Td>
                          <TdBlog>
                            <TdSpan statusType={status}>{status}</TdSpan>
                          </TdBlog>
                        </Td>
                        <Td>
                          <ViewIcon>
                            <HoverMoreIcon
                              title='More'
                              onClick={(e) => {
                                onOpenMoreMenu(e, invoice);
                              }}>
                              <SVGIcon name='icon-more' fill='var(--gray-icon-color)' size={24} />
                            </HoverMoreIcon>
                          </ViewIcon>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            )}
          </Table>
        </TableWrapper>
      )}
      <Menu
        id='delete-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenuOpen}
        onClose={onCloseMoreMenu}
        getContentAnchorEl={null}
        width={112}
        position='left'
        positionVertical='top'
        marginTop={20}>
        <MenuItem
          iconName='icon-edit-pen'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Edit'
          onClick={() => {
            onEditInvoice();
          }}
        />
        {selectedInvoice?.status < INVOICE_STATUS.PARTIALLY_PAID && (
          <MenuItem
            iconName='icon-delete'
            color='var(--gray-icon-color)'
            fontSize='18'
            text='Delete'
            onClick={() => {
              ConfirmDialog({
                title: 'Delete?',
                message: `Press ok to confirm delete this Invoice`,
                onCancel: () => {
                  onCloseMoreMenu();
                },
                onOk: () => {
                  onDeleteInvoice();
                }
              });
            }}
          />
        )}
      </Menu>
    </>
  );
};
export default Invoice;
