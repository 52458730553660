import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import ReportsHeader from '../../../components/ReportsHeader/ReportsHeader';
import {
  setActivityEvents,
  setActivityUsers,
  updateActivityEvents,
  updateActivityUsers
} from '../../../actions/reportActions';
import { getDateString } from '../../../helpers/common';
import { getActivityReport } from '../../../services/reportServices';
import { ACTIVITY_ACTION, EVENT_TYPE, LOOKUP_TYPE } from '../../../constants/constant';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import { MobileReportDateFilter } from '../../../components/ReportDateFilter/MobileReportDateFilter';
import {
  SummaryContent,
  SummaryRow,
  SummaryCol,
  SummaryColTwo,
  ReportActivityWrapper,
  ReportActivityUl,
  ReportActivityLi,
  TimelineView,
  ReportActivityHead,
  ReportActivityTime,
  Calltext,
  Left,
  Right,
  Timetext,
  StatusText,
  StatusSpan,
  EventTitle,
  SummaryColInner,
  SummaryColFilter,
  SummaryUserColFilter,
  SummaryColFilterUl,
  SummaryColFilterli,
  InputWrapperModal,
  TextPopup,
  MainMobileButton
} from './styles';

export default function Activity() {
  const [isEventMenu, setIsEventMenu] = useState(true);
  const [isUserMenu, setIsUserMenu] = useState(true);
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;
  const mobile = useMobileDevice();

  const reportSelector = useSelector((state) => state.report);
  const { activityEvents, activityUsers, activityReport, loading } = reportSelector;

  const dispatch = useDispatch();
  const history = useHistory();

  const events = useMemo(() => {
    let eventList = [];
    eventList.push({ isSelected: true, id: 'activity', name: 'Activity', type: 1 });
    eventList.push({ isSelected: false, id: 'email', name: 'Emails', type: 2 });

    lookupTableList.forEach((table) => {
      if (table.type !== LOOKUP_TYPE.others)
        eventList.push({ isSelected: false, id: table.id, name: table.label, type: 3 });
    });
    return eventList;
  }, [lookupTableList]);

  const users = useMemo(() => {
    let company = organization;
    let userList = [];
    if (company && company?.users) {
      let users = company?.users;
      users.forEach((user) => {
        userList.push({ isSelected: true, user: user });
      });
    }
    return userList;
  }, [organization]);

  useEffect(() => {
    if (events?.length > 0 && users?.length > 0) {
      dispatch(setActivityEvents(events));
      dispatch(setActivityUsers(users));
    }
    return () => {
      dispatch(setActivityEvents([]));
      dispatch(setActivityUsers([]));
    };
  }, [dispatch, events, users]);

  const selectedData = useMemo(() => {
    if (activityEvents?.length > 0 && activityUsers?.length > 0) {
      const selectedEvents = activityEvents?.filter((event) => event?.isSelected === true);
      const userItems = activityUsers?.filter((user) => user?.isSelected === true);
      let selectedUsers = [];
      userItems?.forEach((item) => {
        selectedUsers.push(item.user);
      });
      return {
        selectedEvents: selectedEvents,
        selectedUsers: selectedUsers
      };
    }
  }, [activityEvents, activityUsers]);

  const GetUserById = useCallback(
    (id) => {
      if (selectedData?.selectedUsers?.length > 0) {
        let user = selectedData?.selectedUsers.find((user) => user.id === id);
        return user;
      }
    },
    [selectedData?.selectedUsers]
  );

  const onChange = useCallback(
    (option, fromDate, toDate) => {
      if (selectedData) {
        const fromDateString = getDateString(fromDate);
        const toDateString = getDateString(toDate);

        history.push(`/reports/activity?fromdate=${fromDateString}&todate=${toDateString}&option=${option}`);

        const payload = {
          events: selectedData?.selectedEvents,
          users: selectedData?.selectedUsers
        };
        dispatch(getActivityReport(organization_id, fromDateString, toDateString, payload));
      }
    },
    [dispatch, history, organization_id, selectedData]
  );

  const onUpdateReportEvents = useCallback(
    (id, prop, val) => {
      dispatch(updateActivityEvents({ eventId: id, propName: prop, value: val }));
    },
    [dispatch]
  );

  const onUpdateReportUsers = useCallback(
    (id, prop, val) => {
      dispatch(updateActivityUsers({ userId: id, propName: prop, value: val }));
    },
    [dispatch]
  );
  const HandleEventAllMenu = useCallback(
    (action) => {
      if (action === EVENT_TYPE.EVENT) {
        setIsEventMenu(!isEventMenu);
      }
      if (action === EVENT_TYPE.USER) {
        setIsUserMenu(!isUserMenu);
      }
    },
    [isEventMenu, isUserMenu]
  );

  const renderActivityIcon = useCallback((action) => {
    let iconName;
    switch (action) {
      case ACTIVITY_ACTION.Call:
        iconName = 'icon-call';
        break;
      case ACTIVITY_ACTION.Meeting:
        iconName = 'icon-account';
        break;
      case ACTIVITY_ACTION.Email:
        iconName = 'icon-email';
        break;
      case ACTIVITY_ACTION.Lunch:
        iconName = 'icon-food';
        break;
      case ACTIVITY_ACTION.DeadLine:
        iconName = 'icon-flag';
        break;
      case ACTIVITY_ACTION.Tasks:
        iconName = 'icon-check';
        break;
      case ACTIVITY_ACTION.Notes:
        iconName = 'icon-message';
        break;
      case ACTIVITY_ACTION.Status:
        iconName = 'icon-star';
        break;
      case ACTIVITY_ACTION.Activity:
        // //timeline Activity
        iconName = 'activity-icon';
        // bgColor = colors.taskWhite;
        break;
      default:
    }
    return <SVGIcon name={iconName} fill='var(--dark-color)' size={20} />;
  }, []);

  const renderLookupIcon = useCallback((lookupType) => {
    let iconName;
    switch (lookupType) {
      case LOOKUP_TYPE.contacts:
        iconName = 'icon-user';
        break;
      case LOOKUP_TYPE.companies:
        iconName = 'icon-account';
        break;
      case LOOKUP_TYPE.deals:
        iconName = 'icon-deals';
        break;
      default:
    }
    return <SVGIcon name={iconName} fill='var(--dark-color)' size={20} />;
  }, []);

  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <MainMobileButton>
            <MobileReportDateFilter onChange={onChange} />
          </MainMobileButton>
        </MobileTitleBar>
      )}
      {mobile ? null : <ReportsHeader onChange={onChange} title='Activity' iconName='icon-document' />}
      <SummaryContent mobile={mobile}>
        <SummaryRow mobile={mobile}>
          {!loading && activityReport && activityReport?.length === 0 && <SummaryCol>No Data Found</SummaryCol>}
          {loading ? (
            <SummaryCol>Loading...</SummaryCol>
          ) : (
            <SummaryCol mobile={mobile}>
              <ReportActivityWrapper>
                <ReportActivityUl mobile={mobile}>
                  {activityReport?.map((item, index) => {
                    return item?.activityType !== 5 ? (
                      <ReportActivityLi key={item?.id || index} mobile={mobile}>
                        <Left>
                          <TimelineView>{renderActivityIcon(item?.action)}</TimelineView>
                        </Left>
                        <Right mobile={mobile}>
                          <ReportActivityHead>
                            <Calltext>
                              {item?.action === ACTIVITY_ACTION.Status ? (
                                <>
                                  Status <span>updated by </span>
                                </>
                              ) : item?.action === ACTIVITY_ACTION.Email ? (
                                item?.activityType === 1 ? (
                                  <>
                                    {item?.actionName} <span>sent from </span>
                                  </>
                                ) : (
                                  `${item?.actionName}`
                                )
                              ) : (
                                <>
                                  {item?.actionName} <span>created by </span>
                                </>
                              )}
                              {GetUserById(item?.createdBy)?.name}
                            </Calltext>
                            <StatusText>
                              {item?.action === ACTIVITY_ACTION.Email ? 'Subject : ' : ''}
                              {item?.action === ACTIVITY_ACTION.Status ? `${item?.description} ` : `${item?.title} `}
                              {item?.action === ACTIVITY_ACTION.Tasks && item?.assignedUser && (
                                <>
                                  assigned to <StatusSpan incoming>{item?.assignedUser?.name}</StatusSpan>
                                </>
                              )}
                            </StatusText>
                          </ReportActivityHead>
                          <ReportActivityTime>
                            <Timetext>
                              {item?.date && moment(item?.date).format('DD/MM/YYYY')}
                              {item?.time && ` at ${moment(item?.time).format('h:mm A')}`}
                            </Timetext>
                          </ReportActivityTime>
                        </Right>
                      </ReportActivityLi>
                    ) : (
                      <ReportActivityLi key={index} mobile={mobile}>
                        <Left>
                          <TimelineView>{renderLookupIcon(item?.lookupType)}</TimelineView>
                        </Left>
                        <Right mobile={mobile}>
                          <ReportActivityHead>
                            <Calltext>
                              {item?.lookup?.name}
                              <span> {item?.actionName} by </span>
                              {GetUserById(item?.createdBy)?.name}
                            </Calltext>
                            <StatusText>{item?.title}</StatusText>
                          </ReportActivityHead>
                          <ReportActivityTime>
                            <Timetext>
                              {item?.date && moment(item?.date).format('DD/MM/YYYY')}
                              {item?.time && ` at ${moment(item?.time).format('h:mm A')}`}
                            </Timetext>
                          </ReportActivityTime>
                        </Right>
                      </ReportActivityLi>
                    );
                  })}
                  {/* <ReportActivityLi>
                    <Left>
                      <TimelineView>
                        <Icon name={'icon-webcam'} color={'var(--spanish-gray)'} />
                      </TimelineView>
                      <ReportActivityHead>
                        <Calltext>
                          Notify <span>no Contact Add Automation Started</span>
                        </Calltext>
                        <StatusText></StatusText>
                      </ReportActivityHead>
                    </Left>
                    <ReportActivityTime>
                      <Timetext>25/01/2019 at 5:42 PM</Timetext>
                    </ReportActivityTime>
                  </ReportActivityLi> */}
                </ReportActivityUl>
              </ReportActivityWrapper>
            </SummaryCol>
          )}
          <SummaryColTwo mobile={mobile}>
            <SummaryColInner mobile={mobile}>
              <EventTitle
                onClick={
                  mobile
                    ? () => {
                        HandleEventAllMenu(EVENT_TYPE.EVENT);
                      }
                    : null
                }>
                <h4>Event</h4>
                {mobile ? <SVGIcon name={isEventMenu ? 'icon-down-arrow' : 'icon-up-arrow'} size={24} /> : null}
              </EventTitle>
              <SummaryColFilter isEventMenu={isEventMenu} mobile={mobile}>
                <SummaryColFilterUl>
                  {activityEvents &&
                    activityEvents?.length > 0 &&
                    activityEvents?.map((event) => {
                      return (
                        <SummaryColFilterli key={event?.id}>
                          <InputWrapperModal>
                            <input
                              type='checkbox'
                              id={event?.id}
                              checked={event?.isSelected}
                              onChange={() => {
                                onUpdateReportEvents(event?.id, 'isSelected', !event?.isSelected);
                              }}
                            />
                            <span className='checkmark'></span>
                            <TextPopup htmlFor={event?.id}>{event?.name}</TextPopup>
                          </InputWrapperModal>
                        </SummaryColFilterli>
                      );
                    })}
                </SummaryColFilterUl>
              </SummaryColFilter>
              <EventTitle
                onClick={
                  mobile
                    ? () => {
                        HandleEventAllMenu(EVENT_TYPE.USER);
                      }
                    : null
                }>
                <h4>User</h4>
                {mobile ? <SVGIcon name={isUserMenu ? 'icon-down-arrow' : 'icon-up-arrow'} size={24} /> : null}
              </EventTitle>
              <SummaryUserColFilter isUserMenu={isUserMenu} mobile={mobile}>
                <SummaryColFilterUl>
                  {activityUsers &&
                    activityUsers?.length > 0 &&
                    activityUsers?.map((item) => {
                      return (
                        <SummaryColFilterli key={item?.user?.id}>
                          <InputWrapperModal>
                            <input
                              type='checkbox'
                              id={item?.user?.id}
                              checked={item?.isSelected}
                              onChange={() => {
                                onUpdateReportUsers(item?.user?.id, 'isSelected', !item?.isSelected);
                              }}
                            />
                            <span className='checkmark'></span>
                            <TextPopup htmlFor={item?.user?.id}>{item?.user?.name}</TextPopup>
                          </InputWrapperModal>
                        </SummaryColFilterli>
                      );
                    })}
                </SummaryColFilterUl>
              </SummaryUserColFilter>
            </SummaryColInner>
          </SummaryColTwo>
        </SummaryRow>
      </SummaryContent>
    </>
  );
}
