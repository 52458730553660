import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { Header4, Button1 } from '../../../styles';
import SVGIcon from '../../../assets/svg/SVGIcon';

const Buttons = styled.button`
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 6px 15px;
  ${Button1}
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 1px 2px var(--box-shadow);
  font-family: var(--primary-font-stack);
  text-transform: capitalize;
  transition: 0.3s;
  background: var(--primary-color);
  color: var(--white-color);
  display: flex;
  align-items: center;
  :hover {
    background: var(--primary-color-active);
  }
  ${(props) =>
    props.secondary &&
    css`
      background: var(--white-color);
      color: var(--gray-color);
      border-color: var(--gray-border-color);

      &:hover {
        border-color: var(--primary-color-secondery-active);
        background: var(--white-color);
        color: var(--gray-color);
      }
    `}

  ${(props) =>
    props.isDelete &&
    css`
      background: var(--danger-Base-bg);
      color: var(--white-color);
      border-color: var(--danger-Base-border);
      :hover {
        background: var(--danger-Base-active);
        border-color: var(--danger-Base-border);
        color: var(--white-color);
      }
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      padding: 9px 24px 9px 24px;
      ${Header4}
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 5px 15px;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: var(--primary-color-active);
    `}
    ${(props) =>
    props.disabled &&
    props.secondary &&
    css`
      background: var(--white-color);
    `}
`;

const ButtonWrap = styled.div`
  position: relative;
  display: inline-block;
  ${(props) =>
    props.isLoading &&
    css`
      &::before {
        position: absolute;
        top: 50%;
        left: calc(50% - 2px);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--white-color);
        z-index: 2;
        margin-top: 4px;
        -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
        animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
        -webkit-animation-delay: 0.15s;
        animation-delay: 0.15s;
      }
      ${Buttons} {
        cursor: wait;
        color: transparent;
        background-color: var(--primary-color-active);
        &:hover {
          cursor: wait;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: calc(50% - 2px);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--white-color);
          z-index: 2;
          margin-top: 4px;
          -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          margin-left: -15px;
        }
        &::after {
          position: absolute;
          top: 50%;
          left: calc(50% - 2px);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--white-color);
          z-index: 2;
          margin-top: 4px;
          -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          margin-left: 15px;
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        }
      }
    `}
  ${(props) =>
    props.secondary &&
    css`
      &::before {
        background-color: var(--gray-disable-color);
      }
      ${Buttons} {
        &::before {
          background-color: var(--gray-disable-color);
        }
        &::after {
          background-color: var(--gray-disable-color);
        }
      }
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

const ball = keyframes`
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
`;

class Button extends Component {
  render() {
    const {
      title,
      size,
      disabled,
      type,
      onClick,
      secondary,
      fontSize,
      iconName,
      loading,
      isDelete,
      width,
      iconColor,
      strokeWidth,
      ...props
    } = this.props;
    return (
      <ButtonWrap isLoading={loading} width={width} secondary={secondary}>
        <Buttons
          onClick={onClick}
          secondary={secondary}
          isDelete={isDelete}
          disabled={disabled || loading}
          size={size}
          type={type}
          {...props}>
          {iconName && (
            <SVGIcon isIconButton name={iconName} size={fontSize} fill={iconColor} strokeWidth={strokeWidth} />
          )}
          {title || ''}
        </Buttons>
      </ButtonWrap>
    );
  }
}

export default Button;

Button.propTypes = {
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  iconName: PropTypes.string,
  type: PropTypes.string
};

Button.defaultProps = {
  secondary: false,
  size: 'medium'
};
