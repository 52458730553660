import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
import Avtar from '../../../../../components/Avtar/Avtar';
import { isEmpty } from '../../../../../helpers/common';
import CommentLSvg from '../../../../../assets/svg/comment.svg';
import MobileCommentSVG from '../../../../../assets/images/svg/mobilecomment.svg';
import ResolveSvg from '../../../../../assets/svg/resolve.svg';
import { Menu, MenuItem } from '../../../../../components/Menu';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../../constants/constant';
import {
  deleteActivityComment,
  resolveComment,
  subscribeActivityComments,
  unSubscribeActivityComments,
  updateLocalLookupActivityItem
} from '../../../../../services/activityService';
import { displayRelativeDate } from '../../../../../global/Helper';
import { deleteCommentItem, updateLookupActivityItem } from '../../../../../actions/activityActions';
import useMobileDevice from '../../../../../hooks/useMobileDevice';
import RenderCommentInput from './RenderCommentInput';
import {
  AvtarExternal,
  CommentContainer,
  CommentedMassegeSpan,
  CommentedUser,
  CommentHeader,
  CommentIconMain,
  CommentMainBlock,
  CommentMassegeBlock,
  CommentResolve,
  CommentSvg,
  CommentWrapper,
  LSvg,
  MenuItemWrapper,
  ResolveCommentBlock,
  ResolveImage,
  ResolveLeft,
  ResolveSpan,
  ResolveTime,
  SVGIconBg,
  TimeText,
  UseCommentBlock,
  UserCommentTextMain,
  UserLeft,
  UserRecive,
  UserRight,
  CommnetRightMain,
  ToggleBlock,
  ResolveToggleBlock,
  CommentWrapperBlock,
  CommentWrapperSpan,
  IconView,
  IconWrapper
} from './styles';

const RenderComments = ({ item, isShowCommentInput, selectedActivity }) => {
  const [isOpenCommentMenu, setIsOpenCommentMenu] = useState(false);
  const [isOpenCommentContinerShow, setIsOpenCommentContinerShow] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const organizationSelector = useSelector((state) => state.organization);
  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;
  const mobile = useMobileDevice();

  const { organization } = organizationSelector;
  const { users, id: organization_id } = organization;
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?.isCommentsClosed) {
      setIsOpenCommentContinerShow(true);
    }
  }, [item?.isCommentsClosed]);

  const userComments = useMemo(() => {
    const comments = [];
    let lastItem;
    if (item?.comments && item?.comments?.length !== 0) {
      lastItem = item?.comments[item?.comments?.length - 1];
    }
    item?.comments?.forEach((comment) => {
      const user = users?.find((x) => x?.id === comment?.createdBy);
      if (user) {
        const isItemisItemExist = comments?.find((x) => x?.id === user?.id);
        if (!isItemisItemExist) comments?.push(user);
      }
    });
    return { comments, lastItem };
  }, [item?.comments, users]);

  const subscriber = useMemo(() => {
    let subscribe;
    subscribe = item?.subscribers?.find((x) => x?.id === currentUser?.id);
    return subscribe;
  }, [currentUser?.id, item?.subscribers]);

  const handleCommentMenu = useCallback((e) => {
    setMenuAnchorEl(e.currentTarget);
    setIsOpenCommentMenu(true);
  }, []);

  const onCommentMenuClose = useCallback(() => {
    setIsOpenCommentMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const onClickdeleteComment = useCallback(
    (item, comment) => {
      const activityId = item?.commentActivityId || item?.id;
      const comment_id = comment?.id;
      if (activityId && comment_id && comment.createdBy === currentUser?.id) {
        dispatch(deleteCommentItem({ id: item?.id, item: comment, messageId: item?.messageId }));
        dispatch(deleteActivityComment(organization_id, activityId, comment_id));
      }
    },
    [currentUser?.id, dispatch, organization_id]
  );

  const onClickResolve = useCallback(async () => {
    onCommentMenuClose();
    const newItem = {
      ...item,
      isCommentsClosed: true,
      commentsClosedOn: new Date().toISOString(),
      commentsClosedBy: currentUser?.id
    };
    dispatch(updateLookupActivityItem({ id: item?.id, item: newItem }));
    //Comment activity present in sync email for a message
    const activityId = item?.commentActivityId || item?.id;
    const result = dispatch(resolveComment(organization_id, activityId, {}));
    if (result) {
      await dispatch(updateLocalLookupActivityItem(item?.id));
    }
  }, [currentUser?.id, dispatch, item, onCommentMenuClose, organization_id]);

  const comments = useMemo(() => {
    return (
      <>
        {item?.comments?.map((comment, index) => {
          const user = users?.find((x) => x?.id === comment?.createdBy);
          return (
            <CommentMassegeBlock key={`${comment?.id || comment?.localId}_${index}`}>
              <CommentedUser>
                <Avtar imgSrc={user?.profile_photo} name={user?.name} size={24} />
              </CommentedUser>
              <CommnetRightMain mobile={mobile}>
                <CommentWrapperSpan>
                  <CommentedMassegeSpan
                    mobile={mobile}
                    dangerouslySetInnerHTML={{ __html: comment?.comment }}></CommentedMassegeSpan>
                  {comment.createdBy === currentUser?.id && (
                    <IconWrapper className='remove-comment'>
                      <IconView onClick={() => onClickdeleteComment(item, comment)}>
                        <SVGIcon name='icon-delete' size={18} fill='var(--gray-icon-color)' />
                      </IconView>
                    </IconWrapper>
                  )}
                </CommentWrapperSpan>
                <TimeText Comment className='TimeText'>
                  {moment(comment?.createdOn).fromNow()}
                </TimeText>
              </CommnetRightMain>
            </CommentMassegeBlock>
          );
        })}
      </>
    );
  }, [item, mobile, onClickdeleteComment, users, currentUser?.id]);

  const handleCommentContainer = useCallback(() => {
    setIsOpenCommentContinerShow(!isOpenCommentContinerShow);
  }, [isOpenCommentContinerShow]);

  const CommentedUserProfile = useMemo(() => {
    return (
      <>
        {userComments?.comments?.map((user, index) => {
          return (
            <AvtarExternal key={`Avtar_${user?.id}_${index}`}>
              <Avtar imgSrc={user?.profile_photo} name={user?.name} size={mobile ? 20 : 16} />
            </AvtarExternal>
          );
        })}
      </>
    );
  }, [mobile, userComments?.comments]);
  const onClickUnFollow = useCallback(async () => {
    const subscriber = item?.subscriber || [];
    const index = subscriber?.findIndex((x) => x?.id === currentUser?.id);
    if (index !== -1) {
      subscriber?.splice(index, 1);
    }
    const newItem = {
      ...item,
      subscribers: subscriber
    };
    dispatch(updateLookupActivityItem({ id: item?.id, item: newItem }));
    //Comment activity present in sync email for a message
    const activityId = item?.commentActivityId || item?.id;
    const result = await dispatch(unSubscribeActivityComments(organization_id, activityId));
    if (result) {
      await dispatch(updateLocalLookupActivityItem(item?.id));
      onCommentMenuClose();
    }
  }, [currentUser?.id, dispatch, item, onCommentMenuClose, organization_id]);

  const onClickFollow = useCallback(async () => {
    const subscriber = item?.subscriber || [];
    subscriber?.push({ id: currentUser?.id });
    const newItem = {
      ...item,
      subscribers: subscriber
    };
    dispatch(updateLookupActivityItem({ id: item?.id, item: newItem }));
    //Comment activity present in sync email for a message
    const activityId = item?.commentActivityId || item?.id;
    const result = await dispatch(subscribeActivityComments(organization_id, activityId));
    if (result) {
      await dispatch(updateLocalLookupActivityItem(item?.id));
      onCommentMenuClose();
    }
  }, [currentUser?.id, dispatch, item, onCommentMenuClose, organization_id]);
  return (
    <>
      {((isShowCommentInput && selectedActivity?.id === item?.id) ||
        !isEmpty(item?.comments) ||
        item?.isCommentsClosed) && (
        <CommentWrapper mobile={mobile}>
          <CommentSvg mobile={mobile}>
            <LSvg src={mobile ? MobileCommentSVG : CommentLSvg}></LSvg>
          </CommentSvg>
          <CommentMainBlock>
            {item?.isCommentsClosed ? (
              <CommentResolve isExpand={isOpenCommentContinerShow}>
                <ResolveToggleBlock onClick={handleCommentContainer}></ResolveToggleBlock>
                <ResolveLeft>
                  <ResolveImage src={ResolveSvg}></ResolveImage>
                  <ResolveCommentBlock>
                    <ResolveSpan>
                      Thread resolved {displayRelativeDate(item?.commentsClosedOn, DATE_FORMAT)}
                    </ResolveSpan>
                    <ResolveTime> , {moment(item?.commentsClosedOn).format(TIME_FORMAT)}</ResolveTime>
                  </ResolveCommentBlock>
                </ResolveLeft>
                {/* <CommentIconMain>
                  <SVGIcon title='icon-more' fill='var(--gray-color)' size={20} name='icon-more' strokeWidth={2} />
                </CommentIconMain> */}
              </CommentResolve>
            ) : (
              item?.comments &&
              item?.comments?.length !== 0 && (
                <CommentHeader isExpand={isOpenCommentContinerShow}>
                  <ToggleBlock onClick={handleCommentContainer}></ToggleBlock>
                  <UserLeft>
                    <UseCommentBlock>{CommentedUserProfile}</UseCommentBlock>
                    <UserCommentTextMain>
                      <UserRecive>{item?.comments?.length} comments,</UserRecive>
                      <UserRecive>Last commented</UserRecive>
                      <TimeText>{moment(userComments?.lastItem?.createdOn).fromNow()}</TimeText>
                    </UserCommentTextMain>
                  </UserLeft>
                  <UserRight>
                    <CommentIconMain onClick={handleCommentMenu}>
                      <SVGIconBg
                        title='icon-more'
                        fill='var(--gray-color)'
                        size={20}
                        name='icon-more'
                        strokeWidth={2}
                      />
                    </CommentIconMain>
                    <CommentIconMain onClick={onClickResolve}>
                      <SVGIcon title='icon-more' fill='var(--gray-color)' size={20} name='icon-done' strokeWidth={2} />
                    </CommentIconMain>
                  </UserRight>
                </CommentHeader>
              )
            )}
            <CommentContainer isExpand={isOpenCommentContinerShow}>
              {item?.comments && item?.comments?.length !== 0 && <CommentWrapperBlock>{comments}</CommentWrapperBlock>}
              <CommentMassegeBlock isCommentInput={true}>
                <CommentedUser>
                  <Avtar imgSrc={currentUser?.profile_photo} name={currentUser?.name} size={24} />
                </CommentedUser>
                {<RenderCommentInput autoFocus={selectedActivity?.id === item?.id && isShowCommentInput} item={item} />}
              </CommentMassegeBlock>
            </CommentContainer>
          </CommentMainBlock>
        </CommentWrapper>
      )}
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenCommentMenu}
        onClose={onCommentMenuClose}
        getContentAnchorEl={null}
        marginTop={24}
        width={147}
        MenuListProps={{ disablePadding: true }}
        positionVertical='top'
        position='right'>
        <MenuItemWrapper>
          <MenuItem
            text={subscriber ? 'Unfollow' : 'Follow'}
            iconName='icon-user-minus-delete'
            fontSize='18'
            color='var(--gray-icon-color)'
            onClick={subscriber ? onClickUnFollow : onClickFollow}
          />
          <MenuItem
            iconName='icon-checked-round'
            fontSize='18'
            text='Mark a Done'
            color='var(--gray-icon-color)'
            onClick={onClickResolve}
          />
        </MenuItemWrapper>
      </Menu>
    </>
  );
};
export default RenderComments;
