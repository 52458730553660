import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { useParams } from 'react-router';
import Button from '../../../../../components/atom/Button/Button';
import Input from '../../../../../components/Input/Input';
import DropDown from '../../../../../components/DropDown/DropDown';
import { LookupFieldTypes, LOOKUP_TYPE } from '../../../../../constants/constant';
import {
  addSelectedCustomizeFieldOption,
  deleteSelectedCustomizeFieldOption,
  editCustomizeField,
  setSelectedCustomizeField,
  updateSelectedCustomizeField,
  updateSelectedCustomizeFieldOption
} from '../../../../../actions/settingActions';
import Message from '../../../../../components/Message/Message';
import { UserFriendlyString } from '../../../../../helpers/common';
import store from '../../../../../store/store';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
import {
  MainContact,
  TitleView,
  TitleText,
  BottomView,
  Form,
  LeftWrapper,
  FormWrapper,
  marginBottom,
  InputWrapperModal,
  ListBox,
  IconView,
  FirstList,
  SubText,
  TextSub,
  CaptionText,
  ContainView,
  TextPopup,
  ListWrappper,
  DropDownTitle,
  SecondContain,
  DragIcon,
  TextList,
  RightIcon,
  AddView,
  AddText,
  LabelInput,
  ModalHeader,
  CheckBoxWrapper,
  DragWrapper
} from './styles';

const onUpdateFieldOption = (e, optionIndex) => {
  e.preventDefault();
  e.stopPropagation();
  const value = e.target.value;
  store.dispatch(updateSelectedCustomizeFieldOption({ optionIndex, value }));
};

const removeFieldOption = (e, optionIndex) => {
  e.stopPropagation();
  if (optionIndex !== -1) {
    store.dispatch(deleteSelectedCustomizeFieldOption({ optionIndex }));
  }
};

const DragHandle = SortableHandle(() => <SVGIcon name='icon-menu' fill='var(--gray-icon-color)' size={18} />);

const SortableItem = SortableElement(({ item, indexTemp }) => {
  return (
    <ContainView>
      <DragWrapper>
        <DragIcon>
          <DragHandle />
        </DragIcon>
        <TextList>
          <Input
            isPrimarySmall
            type='text'
            autocomplete='off'
            placeholder='Input value'
            value={item?.value}
            key={indexTemp}
            onChange={(e) => {
              onUpdateFieldOption(e, indexTemp);
            }}
          />
        </TextList>
      </DragWrapper>
      {!item?.isSystem && (
        <RightIcon
          onClick={(e) => {
            removeFieldOption(e, indexTemp);
          }}>
          <SVGIcon name='icon-close-round' fill='var(--gray-icon-color)' size={18} />
        </RightIcon>
      )}
    </ContainView>
  );
});

const SortableList = SortableContainer(({ fieldOptions }) => {
  return (
    <div>
      {fieldOptions?.map((item, index) => {
        return <SortableItem key={`field_${index}`} index={index} item={item} indexTemp={index} />;
      })}
    </div>
  );
});

const EditFieldModal = (props) => {
  let fieldItem = props?.fieldItem;
  const dispatch = useDispatch();
  const params = useParams();
  const lookupName = params?.name;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const settingSelector = useSelector((state) => state.setting);
  const { list: lookupTableList } = lookupTableSelector;
  const { selectedCustomizeField: field } = settingSelector;

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    dispatch(setSelectedCustomizeField(fieldItem));
    return () => {
      dispatch(setSelectedCustomizeField());
    };
  }, [dispatch, fieldItem]);

  const table = useMemo(() => {
    const currentTable = lookupTableList?.find((x) => x.name === lookupName);
    return currentTable;
  }, [lookupTableList, lookupName]);

  const onClose = useCallback(() => {
    if (props?.handleCloseModal) props?.handleCloseModal();
  }, [props]);

  const tableRecordCounts = useMemo(() => {
    return table?.fieldCounts;
  }, [table?.fieldCounts]);

  const onUpdateSelectedField = useCallback(
    (propName, value) => {
      dispatch(updateSelectedCustomizeField({ propName, value }));
    },
    [dispatch]
  );

  const setUserFriendlyId = useCallback(
    (labelVal) => {
      if (!field?.id) {
        const id = UserFriendlyString(labelVal);
        onUpdateSelectedField('customId', id);
      }
    },
    [field?.id, onUpdateSelectedField]
  );

  const addFieldOption = () => {
    let option = {
      value: ''
    };
    dispatch(addSelectedCustomizeFieldOption({ option }));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newTableColumns = arrayMoveImmutable(field?.options, oldIndex, newIndex);
      onUpdateSelectedField('options', newTableColumns);
    }
  };

  const RelatedToOptions = useMemo(() => {
    let lookupTables = [];
    if (lookupTables) lookupTables = lookupTableList?.filter((table) => table.type !== LOOKUP_TYPE.leads);
    let usertable = {
      isSystem: false,
      fieldCounts: 0,
      parentId: '',
      leadMeta: {
        contactTableId: '',
        companyTableId: ''
      }
    };
    usertable.id = 'users';
    usertable.label = 'Users';
    usertable.name = 'users';
    usertable.type = LOOKUP_TYPE.users;
    lookupTables.push(usertable);
    return lookupTables;
  }, [lookupTableList]);

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    const options = field?.options?.filter((option) => option?.value);

    if (!field?.label) {
      validationErrors.label = 'Required';
      valid = false;
    }
    if (field?.type === LookupFieldTypes.Select || field?.type === LookupFieldTypes.MultiSelect) {
      if (options?.length === 0) {
        validationErrors.fieldOption = 'Option Required with Value';
        valid = false;
      }
    }
    if (field?.type === LookupFieldTypes.Lookup) {
      if (!field?.lookupTableId) {
        validationErrors.relatedLookupTableId = 'Required';
        valid = false;
      }
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [field?.options, field?.label, field?.type, field?.lookupTableId]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;
      onClose();
      let selectedField = JSON.parse(JSON.stringify(field));
      dispatch(editCustomizeField({ groupIndex: props?.fieldGroupIndex, field: selectedField }));
    },
    [validateInputs, onClose, field, dispatch, props?.fieldGroupIndex]
  );

  const renderFieldDetails = useCallback((type) => {
    switch (type) {
      case LookupFieldTypes.Text:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-input-field' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Single-line Text</SubText>
              <CaptionText>Usually used for any text value like name, city etc.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.MultiLineText:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-t-square' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Multiple Line text</SubText>
              <CaptionText>
                Usually used for any text value with multiple lines like description, address etc.
              </CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Number:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-keyboard' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Number</SubText>
              <CaptionText>Usually used for only Number type value.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Amount:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-input-field' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Amount</SubText>
              <CaptionText>Usually used for only Amount type value.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Boolean:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-check-square' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Checkbox</SubText>
              <CaptionText>Usually used for conditional value whether it is yes or no.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Phone:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-cellphone' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Phone</SubText>
              <CaptionText>Usually used for phone value.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Date:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-calendar' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Date </SubText>
              <CaptionText>Usually used for Date</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Time:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-timer' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Time</SubText>
              <CaptionText>Usually used for Time.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Select:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-drop-down-menu' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Select One Option</SubText>
              <CaptionText>Usually used for text value which can be selected from the Options.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.MultiSelect:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-t-square' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Select Multiple Options</SubText>
              <CaptionText>Usually used for text value which can be selected multiple from the Options.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Email:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-email-symbol' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Email</SubText>
              <CaptionText>Usually used for email value.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Url:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-earth' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Website/URL</SubText>
              <CaptionText>Usually used for website or URL.</CaptionText>
            </FirstList>
          </ListBox>
        );
      case LookupFieldTypes.Lookup:
        return (
          <ListBox>
            <IconView>
              <SVGIcon name='icon-two-way' fill='var(--gray-icon-color)' size={22} />
            </IconView>
            <FirstList>
              <SubText>Associated with Another Record</SubText>
              <CaptionText>Usually used for reference of another collection's record.</CaptionText>
            </FirstList>
          </ListBox>
        );
      default:
        break;
    }
  }, []);

  return (
    <MainContact>
      <TitleView>
        <ModalHeader>
          <SVGIcon name='icon-summary' fill='var(--gray-icon-color)' size={22} />
          <TitleText>Edit Field</TitleText>
        </ModalHeader>
        <ModalHeader onClick={onClose}>
          <SVGIcon name='icon-close' title='Close' fill='var(--gray-icon-color)' size={20} />
        </ModalHeader>
      </TitleView>
      <Form onSubmit={onSubmit}>
        <FormWrapper>
          {renderFieldDetails(field?.type)}
          <TextSub>ID: {field?.customId}</TextSub>
          <Input
            marginBottom={marginBottom}
            title='Label'
            isPrimarySmall
            type='text'
            autocomplete='off'
            placeholder='Label'
            value={field?.label}
            onChange={(e) => {
              onUpdateSelectedField('label', e.target.value);
              setUserFriendlyId(e.target.value);
            }}
          />
          {validationErrors?.label && <Message text={validationErrors.label} isSuccess={false} />}
          {field?.type !== LookupFieldTypes.Boolean && (
            <Input
              marginBottom={marginBottom}
              title='Watermark'
              isPrimarySmall
              type='text'
              autocomplete='off'
              placeholder='Watermark'
              value={field?.placeholder}
              onChange={(e) => {
                onUpdateSelectedField('placeholder', e.target.value);
              }}
            />
          )}
          {field?.type === LookupFieldTypes.Lookup && (
            <ListWrappper>
              <DropDownTitle>Related To</DropDownTitle>
              <DropDown
                options={RelatedToOptions}
                labelField={'label'}
                valueField={'id'}
                value={field?.lookupTableId}
                onChange={(value) => {
                  onUpdateSelectedField('lookupTableId', value?.id);
                }}
                isDisabled={field?.isNonEditable || field?.isSystem === true || (field?.id && tableRecordCounts > 0)}
                placeholder={'Select Collection'}
              />
            </ListWrappper>
          )}
          {validationErrors?.relatedLookupTableId && (
            <Message text={validationErrors.relatedLookupTableId} isSuccess={false} />
          )}
          {!field?.isNonEditable && (
            <CheckBoxWrapper>
              {field?.type === LookupFieldTypes.Boolean && (
                <LabelInput>
                  <InputWrapperModal>
                    <input
                      type='checkbox'
                      checked={field?.value_Bool}
                      onChange={() => {
                        onUpdateSelectedField('value_Bool', !field?.value_Bool);
                      }}
                    />
                    <span className='checkmark'></span>
                    <TextPopup>Default - Yes?</TextPopup>
                  </InputWrapperModal>
                </LabelInput>
              )}
              {field?.type === LookupFieldTypes.Lookup && (
                <LabelInput>
                  <InputWrapperModal>
                    <input
                      type='checkbox'
                      checked={field?.isMultiSelection}
                      disabled={field?.isSystem}
                      onChange={() => {
                        onUpdateSelectedField('isMultiSelection', !field?.isMultiSelection);
                      }}
                    />
                    <span className='checkmark'></span>
                    <TextPopup>Is MultiSelection?</TextPopup>
                  </InputWrapperModal>
                </LabelInput>
              )}
              {field?.type !== LookupFieldTypes.Boolean && (
                <LabelInput>
                  <InputWrapperModal>
                    <input
                      type='checkbox'
                      checked={field?.isRequired}
                      onChange={() => {
                        onUpdateSelectedField('isRequired', !field?.isRequired);
                      }}
                    />
                    <span className='checkmark'></span>
                    <TextPopup>Is Required</TextPopup>
                  </InputWrapperModal>
                </LabelInput>
              )}
              <LabelInput>
                <InputWrapperModal>
                  <input
                    type='checkbox'
                    checked={field?.isQuickAdd}
                    onChange={() => {
                      onUpdateSelectedField('isQuickAdd', !field?.isQuickAdd);
                    }}
                  />
                  <span className='checkmark'></span>
                  <TextPopup>Show in Quick Add</TextPopup>
                </InputWrapperModal>
              </LabelInput>
            </CheckBoxWrapper>
          )}
          {(field?.type === LookupFieldTypes.Select || field?.type === LookupFieldTypes.MultiSelect) && (
            <SecondContain>
              <SubText>Options</SubText>
              <SortableList fieldOptions={field?.options} onSortEnd={onSortEnd} axis='xy' useDragHandle />
              <AddView>
                <AddText onClick={addFieldOption}>Add Option</AddText>
              </AddView>
            </SecondContain>
          )}
          {validationErrors?.fieldOption && <Message text={validationErrors.fieldOption} isSuccess={false} />}
        </FormWrapper>
        <BottomView>
          <LeftWrapper>
            <Button primary={true} type='submit' title='Save' />
            <Button secondary={true} title='Cancel' type='button' onClick={onClose} />
          </LeftWrapper>
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default EditFieldModal;
