import SuccessFul from '../Pages/Setting/Subscription/PayementOption/SuccessFul';
import EmailTemplate from '../Pages/Setting/EmailTemplate/EmailTemplate';
import ImportData from '../Pages/Setting/ImportData/ImportData';
import Users from '../Pages/Setting/Users/Users';
import Contact from '../Pages/Contact/Contact';
import Deals from '../Pages/Deals/Deals';
import Email from '../Pages/Email/Email';
import Inbox from '../Pages/Inbox/Inbox';
import OnboardingWelcome from '../Pages/Onborading/OnboardingWelcome';
import Onborading from '../Pages/Onborading/Onborading';
import TaskList from '../Pages/Task/Task';
import MyProfile from '../Pages/Setting/MyProfile/MyProfile';
import ContactDetail from '../Pages/Contact/ContactDetail/ContactDetail';
import EmailAccounts from '../Pages/Setting/EmailAccounts/EmailAccounts';
import Integrations from '../Pages/Setting/Integrations/Integrations';
import Customize from '../Pages/Setting/Customize/Customize';
import Subscription from '../Pages/Setting/Subscription/Subscription';
import Filed from '../Pages/Setting/Subscription/PayementOption/Filed';
import WebForm from '../Pages/Setting/WebForm/WebForm';
import CompanyProfile from '../Pages/Setting/CompanyProfile/CompanyProfile';
import { ROUTE } from '../constants/routeConst';
import WebFormDetail from '../Pages/Setting/WebForm/webFormDetails';
import CustomizeFields from '../Pages/Setting/Customize/ContactFields/CustomizeFieldsView';
import ReportsOverview from '../Pages/Reports/ReportOverview/ReportsOverview';
import StatusChange from '../Pages/Reports/StatusChange/StatusChange';
import CallSummary from '../Pages/Reports/CallSummary/CallSummary';
import Activity from '../Pages/Reports/Activity/Activity';
import Goal from '../Pages/Goal/Goal';
import InboxEmail from '../Pages/Inbox/InboxEmail';
import PhoneSettings from '../Pages/Setting/PhoneSettings/PhoneSettings';
import Calling from '../components/Calling/Calling';
import Invoice from '../Pages/Invoice/Invoice';
import NewInvoice from '../Pages/Invoice/NewInvoice';
import ViewInvoice from '../Pages/Invoice/ViewInvoice/ViewInvoice';
import Transaction from '../Pages/Setting/PhoneSettings/Transaction';
import NewAutomation from '../Pages/Automation/NewAutomation';
import Automation from '../Pages/Automation/Automation';
import InboxContactSuggestion from '../Pages/Inbox/InboxContactSuggestion';
import InboxDuplication from '../Pages/Inbox/InboxDuplication';
import InboxMergeDuplication from '../Pages/Inbox/InboxMergeDuplication';
import ApiKey from '../Pages/Setting/ApiKey/ApiKey';
import Features from '../Pages/Setting/Features/Features';
import AccessDenied from '../Pages/AccessDenied';
import Call from '../Pages/Setting/CallLog/Call';
import Message from '../Pages/Setting/CallLog/Message';
import InboxOpenThread from '../Pages/Inbox/InboxOpenThread/InboxOpenThread';
import { InvoiceAndPayment } from '../Pages/Setting/Features/InvoiceAndPayment/InvoiceAndPayment';
/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: ROUTE.INBOX,
    exact: true,
    component: Inbox,
    private: true
  },
  {
    path: ROUTE.INBOX_OPENTHREAD,
    exact: true,
    component: InboxOpenThread,
    private: true
  },
  {
    path: ROUTE.INBOX_DUPLICATION,
    exact: true,
    component: InboxDuplication,
    private: true
  },
  {
    path: ROUTE.INBOX_MERGE_DUPLICATION,
    exact: true,
    component: InboxMergeDuplication,
    private: true
  },
  {
    path: ROUTE.INBOXEMAIL,
    exact: true,
    component: InboxEmail,
    private: true,
    roleModuleName: 'email'
  },
  {
    path: ROUTE.INBOX_CONTACT_SUGGESTION,
    exact: true,
    component: InboxContactSuggestion,
    private: true
  },
  {
    path: `${ROUTE.EMAILS}/:id`,
    exact: true,
    component: Email,
    private: true,
    roleModuleName: 'email'
  },
  {
    path: `/email/segments/:id/view/:threadId`,
    exact: true,
    component: Email,
    private: true,
    roleModuleName: 'email'
  },
  {
    path: '/cn/:name',
    exact: true,
    component: Contact,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/cn/:name/segments/:segmentId',
    exact: true,
    component: Contact,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/cn/:name/view/:id/overview',
    exact: true,
    component: ContactDetail,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/cm/:name/view/:id/overview',
    exact: true,
    component: ContactDetail,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/dl/:name/view/:id/overview',
    exact: true,
    component: ContactDetail,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/cm/:name',
    exact: true,
    component: Contact,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/cm/:name/segments/:segmentId',
    exact: true,
    component: Contact,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/dl/:name',
    exact: true,
    component: Deals,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/dl/:name/segments/:segmentId',
    exact: true,
    component: Deals,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/onboarding',
    exact: true,
    component: Onborading,
    private: true
  },
  {
    path: '/call',
    exact: true,
    component: Calling,
    private: true
  },
  {
    path: '/onboardingwelcome',
    exact: true,
    component: OnboardingWelcome,
    private: true
  },
  {
    path: ROUTE.TASK,
    exact: true,
    component: TaskList,
    private: true,
    roleModuleName: 'task'
  },
  {
    path: '/others/:name',
    exact: true,
    component: Contact,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/others/:name/segments/:segmentId',
    exact: true,
    component: Contact,
    private: true,
    roleModuleName: 'tables'
  },
  {
    path: '/mysettings',
    exact: true,
    component: MyProfile,
    private: true
  },
  {
    path: '/settings/users/userslist',
    exact: true,
    component: Users,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/features',
    exact: true,
    component: Features,
    private: true
  },
  {
    path: '/settings/features/invoiceandpayment',
    exact: true,
    component: InvoiceAndPayment,
    private: true
  },
  {
    path: '/settings/importdata',
    exact: true,
    component: ImportData,
    private: true
  },
  {
    path: '/settings/sync',
    exact: true,
    component: EmailAccounts,
    private: true,
    roleModuleName: 'email'
  },
  {
    path: '/settings/emailtemplate',
    exact: true,
    component: EmailTemplate,
    private: true,
    roleModuleName: 'email'
  },
  {
    path: '/settings/integrations',
    exact: true,
    component: Integrations,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/forms',
    exact: true,
    component: WebForm,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/calllog/call',
    exact: true,
    component: Call,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/calllog/message',
    exact: true,
    component: Message,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/forms/details/:id',
    exact: true,
    component: WebFormDetail,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/customize',
    exact: true,
    component: Customize,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/customize/:name',
    exact: true,
    component: CustomizeFields,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/companyprofile',
    exact: true,
    component: CompanyProfile,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/phone-settings',
    exact: true,
    component: PhoneSettings,
    private: true
  },
  {
    path: '/settings/phone-settings/transaction',
    exact: true,
    component: Transaction,
    private: true
  },
  {
    path: '/settings/subscriptions',
    exact: true,
    component: Subscription,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/api-keys',
    exact: true,
    component: ApiKey,
    private: true,
    role: 'admin'
  },
  {
    path: '/settings/payment/successful',
    exact: true,
    component: SuccessFul,
    private: true
  },
  {
    path: '/settings/payment/filed',
    exact: true,
    component: Filed,
    private: true
  },
  {
    path: ROUTE.REPORTSOVERVIEW,
    exact: true,
    component: ReportsOverview,
    private: true,
    roleModuleName: 'report'
  },
  {
    path: ROUTE.STATUSCHANGE,
    exact: true,
    component: StatusChange,
    private: true,
    roleModuleName: 'report'
  },
  {
    path: ROUTE.CALLSUMMARY,
    exact: true,
    component: CallSummary,
    private: true,
    roleModuleName: 'report'
  },
  {
    path: ROUTE.ACTIVITY,
    exact: true,
    component: Activity,
    private: true,
    roleModuleName: 'report'
  },
  {
    path: ROUTE.GOAL,
    exact: true,
    component: Goal,
    private: true,
    roleModuleName: 'report'
  },
  {
    path: ROUTE.INVOICE,
    exact: true,
    component: Invoice,
    private: true,
    roleModuleName: 'invoices'
  },
  {
    path: '/invoices/edit/:id',
    exact: true,
    component: NewInvoice,
    private: true,
    roleModuleName: 'invoices'
  },
  {
    path: '/invoices/create',
    exact: true,
    component: NewInvoice,
    private: true,
    roleModuleName: 'invoices'
  },
  {
    path: '/invoices/view/:id',
    exact: true,
    component: ViewInvoice,
    private: true,
    roleModuleName: 'invoices'
  },
  {
    path: '/automation',
    exact: true,
    component: Automation,
    private: true
  },
  {
    path: '/automation/new',
    exact: true,
    component: NewAutomation,
    private: true
  },
  {
    path: '/automation/edit/:id',
    exact: true,
    component: NewAutomation,
    private: true
  },
  {
    path: '/access-denied',
    exact: true,
    component: AccessDenied,
    private: true
  }
];

export default routes;
