import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/atom/Button/Button';
import { setOriginalAndDuplicateContact } from '../../services/dashboardService';
import { updateMergeContactDetails } from '../../actions/dashboardActions';
import { mergeLookup } from '../../services/lookupService';
import TableWithoutPagination from '../../components/Table/TableWithoutPagination/TableWithoutPagination';
import SVGIcon from '../../assets/svg/SVGIcon';
import { LookupFieldTypes } from '../../constants/constant';
import { getLookupFieldValueForDisplay } from '../../helpers/common';
import {
  HeaderMergeWrapper,
  HeaderMergeLeft,
  HeaderMergeRight,
  MergeDuplicationTitle,
  InputWrapper,
  FastCol,
  InputData,
  ContactListIcon
} from './styles';

const InboxMergeDuplication = (props) => {
  const dashboard = useSelector((state) => state.dashboard);
  const lookupSelector = useSelector((state) => state.lookup);

  const organizationSelector = useSelector((state) => state.organization);
  const { mergeContactDetails } = dashboard;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { loading } = lookupSelector;

  const dispatch = useDispatch();
  const location = useLocation();
  const search = location?.search;
  const URLParams = new URLSearchParams(search);
  const originalContactId = URLParams.get('original');
  const duplicateContactId = URLParams.get('duplicate');

  const loadData = useCallback(async () => {
    await dispatch(setOriginalAndDuplicateContact(originalContactId, duplicateContactId));
  }, [dispatch, duplicateContactId, originalContactId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const setContactValueInput = useCallback(
    (e, propsName, item) => {
      dispatch(updateMergeContactDetails({ propsName, item, value: e.target.checked }));
    },
    [dispatch]
  );

  const onClickMerge = useCallback(async () => {
    const fieldValues = [];
    mergeContactDetails?.fields?.forEach((field) => {
      let item = { id: field?.id };
      if (mergeContactDetails?.original_lookup?.values[field?.id]) {
        item = {
          ...item,
          ...mergeContactDetails?.original_lookup?.fields[field?.id]
        };
      } else if (mergeContactDetails?.duplicate_lookup?.values[field?.id]) {
        item = {
          ...item,
          ...mergeContactDetails?.duplicate_lookup?.fields[field?.id]
        };
      }
      if (
        mergeContactDetails?.original_lookup?.fields[field?.id] ||
        mergeContactDetails?.duplicate_lookup?.fields[field?.id]
      )
        fieldValues.push(item);
    });
    const payload = {
      tableId: mergeContactDetails?.tableId,
      keepLookupId: mergeContactDetails?.original_lookup?.id,
      deleteLookupId: mergeContactDetails?.duplicate_lookup?.id,
      fieldValues
    };
    const result = await dispatch(mergeLookup(organization_id, payload));
    if (result) {
      props.history.goBack();
    }
  }, [dispatch, mergeContactDetails, organization_id, props.history]);
  const tableData = useMemo(() => {
    const columns = [
      {
        Header: 'Property Name',
        accessor: 'name',
        Cell: ({ row }) => {
          const { original } = row;
          return <span>{original?.name}</span>;
        }
      },
      {
        Header: '1st Contact',
        accessor: 'original_contact',
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <InputData>
              <FastCol>
                <InputWrapper>
                  <input
                    type='checkbox'
                    disabled={original?.original_contact_disabled}
                    checked={original?.original_contact_value}
                    onChange={(e) => {
                      setContactValueInput(e, 'original_lookup', original);
                    }}
                  />
                  <span className='checkmark'></span>
                </InputWrapper>
              </FastCol>
              <span>{original?.original_contact}</span>
            </InputData>
          );
        }
      },
      {
        Header: '2nd Contact',
        accessor: 'duplicate_contact',
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <InputData>
              <FastCol>
                <InputWrapper>
                  <input
                    type='checkbox'
                    disabled={original?.duplicate_contact_disabled}
                    checked={original?.duplicate_contact_value}
                    onChange={(e) => {
                      setContactValueInput(e, 'duplicate_lookup', original);
                    }}
                  />
                  <span className='checkmark'></span>
                </InputWrapper>
              </FastCol>
              <span>{original?.duplicate_contact}</span>
            </InputData>
          );
        }
      }
    ];

    const data = [];
    mergeContactDetails?.fields?.forEach((field) => {
      if (field?.isNonEditable) return null;

      let original_contact_display_value = mergeContactDetails?.original_lookup?.displayFields[field?.id];
      let duplicate_contact_display_value = mergeContactDetails?.duplicate_lookup?.displayFields[field?.id];
      //Show formatted value to display in UI
      if (field?.type === LookupFieldTypes.Date || field?.type === LookupFieldTypes.Time) {
        original_contact_display_value = getLookupFieldValueForDisplay(field, {
          value_Date: original_contact_display_value
        });
        duplicate_contact_display_value = getLookupFieldValueForDisplay(field, {
          value_Date: original_contact_display_value
        });
      }
      let rowData = {
        id: field.id,
        name: field?.label,
        original_contact: original_contact_display_value,
        duplicate_contact: duplicate_contact_display_value,
        original_contact_value: mergeContactDetails?.original_lookup?.values[field?.id],
        duplicate_contact_value: mergeContactDetails?.duplicate_lookup?.values[field?.id],
        original_contact_disabled: false,
        duplicate_contact_disabled: false
      };
      if (
        mergeContactDetails?.original_lookup?.displayFields[field?.id] &&
        !mergeContactDetails?.duplicate_lookup?.displayFields[field?.id]
      ) {
        rowData = { ...rowData, duplicate_contact_disabled: true };
      } else if (
        !mergeContactDetails?.original_lookup?.displayFields[field?.id] &&
        mergeContactDetails?.duplicate_lookup?.displayFields[field?.id]
      ) {
        rowData = { ...rowData, original_contact_disabled: true };
      } else if (
        !mergeContactDetails?.original_lookup?.displayFields[field?.id] &&
        !mergeContactDetails?.duplicate_lookup?.displayFields[field?.id]
      ) {
        return null;
      }

      data.push(rowData);
    });
    return {
      columns,
      data
    };
  }, [mergeContactDetails, setContactValueInput]);

  return (
    <>
      <HeaderMergeWrapper>
        <HeaderMergeLeft>
          <ContactListIcon onClick={props.history.goBack}>
            <SVGIcon name='icon-left-arrow' fill='var(--gray-icon-color)' size={32} />
          </ContactListIcon>
          <MergeDuplicationTitle>Merge Duplication</MergeDuplicationTitle>
        </HeaderMergeLeft>
        <HeaderMergeRight>
          <Button title='Merge' onClick={onClickMerge} loading={loading}></Button>
        </HeaderMergeRight>
      </HeaderMergeWrapper>
      <TableWithoutPagination columns={tableData?.columns || []} data={tableData?.data || []}></TableWithoutPagination>
    </>
  );
};

export default InboxMergeDuplication;
