import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EmailNotImg } from '../../../src/assets/images/svg/emailnotfound.svg';
import {
  EmailNotFoundWrapper,
  EmailNotFoundImg,
  EmailNotFoundHead,
  EmailNotFoundText,
  EmailNotFoundLinkBlock,
  EmailCircle,
  SendEmailLink
} from './styles';

const EmailEmptyState = () => {
  const history = useHistory();
  return (
    <>
      <EmailNotFoundWrapper>
        <EmailNotFoundImg>
          <EmailNotImg />
        </EmailNotFoundImg>
        <EmailNotFoundHead>No Email Found</EmailNotFoundHead>
        <EmailNotFoundText>There is no email in your inbox.</EmailNotFoundText>
        <EmailNotFoundLinkBlock>
          <SendEmailLink
            onClick={() => {
              history.push('/settings/sync?isNew=true');
            }}>
            Connect Mail Account
          </SendEmailLink>
          <EmailCircle></EmailCircle>
          <SendEmailLink>Send Mail</SendEmailLink>
        </EmailNotFoundLinkBlock>
      </EmailNotFoundWrapper>
    </>
  );
};

export default EmailEmptyState;
