import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const organizationLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_ORGANIZATION_LOADER
});

const organizationListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_ORGANIZATION_LIST
});

const initialState = {};
const currentOrganizationReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_CURRENT_ORGANIZATION, (state = initialState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_ORGANIZATION_FIELD_VALUE, (state, action) => {
      // Update Call Recording Status
      const oraganizationState = { ...state };
      const org = JSON.parse(JSON.stringify(oraganizationState));
      if (action.payload.propName === 'isCallRecording') {
        org.settings[action.payload.propName] = action.payload.value;
      }
      return org;
    });
});

const companyItemReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_COMPANY_ITEM, (state = initialState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_COMPANY_ITEM, (state, action) => {
      const org = current(state);
      const organization = JSON.parse(JSON.stringify(org));
      organization[action.payload.propName] = action.payload.value;
      return organization;
    });
});

const initialCreateState = {
  industry: '',
  users: [],
  invitedUsers: [{}],
  currency: 'USD',
  customerType: 2,
  name: '',
  teamType: null
};
const newOrganizationReducer = createReducerOrig(initialCreateState, (builder) => {
  builder
    .addCase(Actions.UPDATE_NEW_ORGANIZATION_ITEM, (state, action) => {
      const organization = { ...state };
      organization[action.payload.propsName] = action.payload.value;
      return { ...organization };
    })
    .addCase(Actions.ADD_USER_ITEM, (state, action) => {
      const oraganizationState = { ...state };
      const invitedUsers = oraganizationState.invitedUsers.slice();
      invitedUsers.push(action.payload.item);
      oraganizationState.invitedUsers = invitedUsers;
      return { ...oraganizationState };
    })
    .addCase(Actions.UPDATE_USER_ITEM, (state, action) => {
      const oraganizationState = { ...state };
      const invitedUsers = oraganizationState.invitedUsers.slice();
      invitedUsers[action.payload.index] = action.payload.item;
      oraganizationState.invitedUsers = invitedUsers;
      return { ...oraganizationState };
    })
    .addCase(Actions.DELETE_USER_ITEM, (state, action) => {
      const oraganizationState = { ...state };
      const invitedUsers = oraganizationState.invitedUsers.slice();
      if (action.payload.index !== -1) {
        invitedUsers.splice(action.payload.index, 1);
      }
      oraganizationState.invitedUsers = invitedUsers;
      return { ...oraganizationState };
    })
    .addCase(Actions.CLEAR_ORGANIZATION_ITEM, () => {
      const initialState = JSON.parse(JSON.stringify(initialCreateState));
      return initialState;
    });
});

const organizationReducer = combineReducers({
  loading: organizationLoaderReducer,
  organization: currentOrganizationReducer,
  list: organizationListReducer,
  companyItem: companyItemReducer,
  organizationItem: newOrganizationReducer
});

export default organizationReducer;
