import React, { useState } from 'react';
import { ADD_NUMBER_TYPE } from '../../../constants/constant';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import BuyNumber from './BuyNumber';
import AddExternalPhoneNumber from './AddExternalPhoneNumber';
import PortingByoc from './PortingByoc';
import {
  MainContact,
  TitleView,
  TitleText,
  OptionWrapper,
  OptionBoxUl,
  OptionBoxLi,
  OptionBox,
  OptionBoxLabel,
  OptionBoxSpan,
  OptionBoxSpanPara,
  StepWrapper,
  HeaderWrapper,
  IconView
} from './styles';

function AddPhoneNumberModal({ onClose, openVerifyNumberContainer }) {
  const mobile = useMobileDevice();
  const [type, setType] = useState(ADD_NUMBER_TYPE.NEW_NUMBER);

  return (
    <>
      <MainContact mobile={mobile}>
        <TitleView>
          <HeaderWrapper>
            <SVGIcon name='icon-call' fill='var(--gray-icon-color)' size={22} />
            <TitleText>Add Personal Number</TitleText>
          </HeaderWrapper>
          <IconView onClick={onClose}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} title='Close' />
          </IconView>
        </TitleView>
        <OptionWrapper>
          <OptionBoxUl mobile={mobile}>
            <OptionBoxLi mobile={mobile}>
              <OptionBox>
                <OptionBoxLabel
                  border={type === ADD_NUMBER_TYPE.NEW_NUMBER}
                  onClick={() => {
                    setType(ADD_NUMBER_TYPE.NEW_NUMBER);
                  }}>
                  <SVGIcon name='icon-buy-number' fill='var(--dark-color)' size={34} />
                  <OptionBoxSpan>Buy Number</OptionBoxSpan>
                  <OptionBoxSpanPara>We register a new number for you.</OptionBoxSpanPara>
                </OptionBoxLabel>
              </OptionBox>
            </OptionBoxLi>
            <OptionBoxLi mobile={mobile}>
              <OptionBox>
                <OptionBoxLabel
                  //   border
                  border={type === ADD_NUMBER_TYPE.EXTERNAL_NUMBER}
                  onClick={() => {
                    setType(ADD_NUMBER_TYPE.EXTERNAL_NUMBER);
                  }}>
                  <SVGIcon name='icon-add-number' fill='var(--dark-color)' size={34} />
                  <OptionBoxSpan>External Number</OptionBoxSpan>
                  <OptionBoxSpanPara>Add any phone number for outgoing calls.</OptionBoxSpanPara>
                </OptionBoxLabel>
              </OptionBox>
            </OptionBoxLi>
            <OptionBoxLi mobile={mobile}>
              <OptionBox>
                <OptionBoxLabel border={type === ADD_NUMBER_TYPE.PORTING_AND_BYOC_NUMBER}>
                  <SVGIcon name='icon-porting' fill='var(--dark-color)' size={34} />
                  <OptionBoxSpan>Porting & BYOC</OptionBoxSpan>
                  <OptionBoxSpanPara>We register a new number for you.</OptionBoxSpanPara>
                </OptionBoxLabel>
              </OptionBox>
            </OptionBoxLi>
          </OptionBoxUl>
        </OptionWrapper>
        <StepWrapper>
          {type === ADD_NUMBER_TYPE.NEW_NUMBER && <BuyNumber onClose={onClose} />}
          {type === ADD_NUMBER_TYPE.EXTERNAL_NUMBER && (
            <AddExternalPhoneNumber onClose={onClose} onOpenVerifyNubModal={openVerifyNumberContainer} />
          )}
          {type === ADD_NUMBER_TYPE.PORTING_AND_BYOC_NUMBER && <PortingByoc />}
        </StepWrapper>
      </MainContact>
    </>
  );
}

export default AddPhoneNumberModal;
