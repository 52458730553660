import React, { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateLookupField } from '../../services/lookupService';
import { getActivityItem, updateActivity } from '../../services/activityService';
import UserPreferenceSingleton from '../../helpers/UserPreferenceSingleton';
import {
  getContactSuggestions,
  getDashboardDetail,
  getDublicateContacts,
  trackInboxAnalyticActivity
} from '../../services/dashboardService';
import SnackBar from '../../components/SnackBar/SnackBar';
import TwilioService from '../../services/twilioService';
import {
  ACTIVITY_ACTION,
  INBOX_TYPE,
  LOOKUP_TABLE_TYPE,
  LOOKUP_TYPE,
  SEND_EMAIL_TYPE,
  TOOLTIP_DATE_FORMAT
} from '../../constants/constant';
import { getGreetingsText, getCount, displayRelativeDate } from '../../global/Helper';
import { Menu, MenuItem } from '../../components/Menu';
import SendEmailModal from '../Email/SendEmailModal/SendEmailModal';
import { ROUTE } from '../../constants/routeConst';
import { isEmpty } from '../../helpers/common';
import { hasUserAccessAndPermission } from '../../services/appService';
import NavigationText from '../../components/NavigationText/NavigationText';
import AddActivity from '../Contact/ContactDetail/AddActivity/AddActivity';
import { setActivityValidationError, clearActivityItem } from '../../actions/activityActions';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import Modal from '../../components/Modal';
import RescheduleModal from './RescheduleModal';
import {
  MainContain,
  Title,
  RowView,
  WelText,
  CaptionText,
  HeaderView,
  HeaderWrapper,
  WelView,
  ContainList,
  RowList,
  ListView,
  Leftcontain,
  LeftRow,
  ListTextView,
  RightContain,
  ViewIcon,
  CaptionList,
  HeadText,
  BottomList,
  ListText,
  RightBtn,
  RowBox,
  NavigationTextWrapper,
  StaticColumn,
  DropDownBox,
  MobileIcon,
  IconWrapper,
  ModalStyle,
  IconButton,
  OpenThread
} from './styles';
import InboxOnBoarding from './InboxOnBoarding';
function Inbox(props) {
  const mobile = useMobileDevice();
  const dashboard = useSelector((state) => state.dashboard);
  const organizationSelector = useSelector((state) => state.organization);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const settingsSelector = useSelector((state) => state.setting);
  const { list: lookupTableList } = lookupTableSelector;

  const { dashboardDetail, onBoarding, contactSuggestions, contactDuplication, dataLoading } = dashboard;

  const dispatch = useDispatch();

  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { phoneNumberList } = settingsSelector;

  const hasPhoneNumber = phoneNumberList?.length > 0;
  const [menuAnchorEl, setmenuAnchorEl] = useState(null);
  const [isOpenViewMenu, setViewMenu] = useState(false);
  const [isReschedulOpen, setIsReschedulOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [selectedEmailLookupId, setSelectedEmailLookupId] = useState();
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [addActivity, setAddActivity] = useState();
  const [selectedActivity, setSelectedActivity] = useState(undefined);

  useEffect(() => {
    dispatch(getDashboardDetail(organization_id));
    dispatch(getContactSuggestions(organization_id));
    dispatch(getDublicateContacts());
  }, [dispatch, organization_id]);

  const suggestionCount = useMemo(() => {
    return contactSuggestions?.length || 0;
  }, [contactSuggestions]);

  const duplicationCount = useMemo(() => {
    let count = contactDuplication?.length || 0;
    return count;
  }, [contactDuplication]);

  const userPermission = useMemo(() => {
    const permissionSendMail = dispatch(hasUserAccessAndPermission('email', 'permissionSend'));
    const permissionEditTask = dispatch(hasUserAccessAndPermission('task', 'permissionEdit'));
    return {
      permissionSendMail: permissionSendMail,
      permissionEditTask: permissionEditTask
    };
  }, [dispatch]);

  const userLookupEditPermission = useCallback(
    (tableId) => {
      const table = lookupTableList?.find((table) => table?.id === tableId);
      const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', table?.name));
      return permissionEdit;
    },
    [dispatch, lookupTableList]
  );

  const closeViewModal = () => {
    setViewMenu(false);
  };

  const openViewModal = useCallback((e, item) => {
    e.stopPropagation();
    setIsReschedulOpen(false);
    setmenuAnchorEl(e.currentTarget);
    setViewMenu(true);
    setSelectedItem(item);
  }, []);

  const onCloseRescheduleMenu = () => {
    setIsReschedulOpen(false);
  };

  const onOpenRescheduleMenu = useCallback(() => {
    trackInboxAnalyticActivity(selectedItem?.type === INBOX_TYPE.activities ? 'activity' : 'followup', 'reschedule');
    setIsReschedulOpen(true);
  }, [selectedItem?.type]);

  const markAsCompleteActivity = () => {
    trackInboxAnalyticActivity('activity', 'mark as complete');
    dispatch(updateActivity(organization_id, selectedItem?.record?.id, true));
    closeViewModal();
  };

  const onAddActivityOpen = useCallback(() => {
    setIsAddActivityOpen(true);
    closeViewModal();
  }, []);

  const onAddActivityClose = useCallback(() => {
    setIsAddActivityOpen(false);
    setmenuAnchorEl(null);
    setSelectedActivity(undefined);
    dispatch(clearActivityItem());
    dispatch(setActivityValidationError(null));
  }, [dispatch]);

  const onClickActivityMoreMenu = useCallback(async (e, item) => {
    setAddActivity();
    setmenuAnchorEl(e.currentTarget);
    const action = item?.record?.action;
    setSelectedItem(item);
    setViewMenu(true);
    if (
      action === ACTIVITY_ACTION.Meeting ||
      action === ACTIVITY_ACTION.Notes ||
      action === ACTIVITY_ACTION.Tasks ||
      action === ACTIVITY_ACTION.Call ||
      action === ACTIVITY_ACTION.File
    ) {
      setAddActivity(action);
      const activity = await getActivityItem(item?.record?.id);
      setSelectedActivity(activity);
    }
  }, []);

  const markAsCompleteLookup = (date) => {
    closeViewModal();
    onCloseRescheduleMenu();
    const value = {
      value_Date: date
    };
    const lookupId = selectedItem?.record?.lookup?.id;
    const obj = {
      field: selectedItem?.record?.field,
      value: date ? value : {}
    };
    dispatch(updateLookupField(organization_id, lookupId, obj));
  };

  const dataList = useMemo(() => {
    if (!dashboardDetail) return [];
    const allInboxList = [];
    if (dashboardDetail?.activities && dashboardDetail?.activities?.length > 0) {
      dashboardDetail.activities.forEach((item) => {
        allInboxList.push({ record: item, type: 1 });
      });
    }
    if (dashboardDetail?.lookups && dashboardDetail?.lookups?.length > 0) {
      dashboardDetail.lookups.forEach((item) => {
        allInboxList.push({ record: item, type: 2 });
      });
    }
    return allInboxList;
  }, [dashboardDetail]);

  const emailCount = useMemo(() => {
    return dashboardDetail?.emails?.length;
  }, [dashboardDetail?.emails]);

  const getPath = useCallback(
    (lookup) => {
      const table = lookupTableList.find((x) => x.id === lookup?.tableId);
      if (table) {
        const type = LOOKUP_TABLE_TYPE[table?.type];
        const pathName = `/${type}/${table?.name}/view/${lookup?.id}/overview`;
        return pathName;
      }
    },
    [lookupTableList]
  );

  const openSendEmailModal = useCallback((lookup_id, type, email) => {
    trackInboxAnalyticActivity(type === INBOX_TYPE.activities ? 'activity' : 'followup', 'email');
    setSelectedEmailLookupId({ id: lookup_id, email });
    setIsOpenSendEmailModal(true);
  }, []);

  const onCallClick = useCallback(
    (lookup) => {
      dispatch(TwilioService.getInstance().connectCall(lookup?.phone, lookup?.name, lookup));
    },
    [dispatch]
  );

  const closeSendEmailModal = useCallback(() => {
    setSelectedEmailLookupId();
    setIsOpenSendEmailModal(false);
  }, []);

  // useEffect(() => {
  //   trackAnalyticActivity('navigation: inbox');
  // }, []);

  const onSave = useCallback(() => {
    dispatch(getDashboardDetail(organization_id));
  }, [dispatch, organization_id]);

  const renderLookups = (item) => {
    const { record } = item || {};
    const { lookup } = record || {};
    const companyOrContact = lookup?.company || lookup?.contact;
    return (
      <RowList key={record?.lookup?.id} mobile={mobile}>
        <ListView mobile={mobile}>
          <Leftcontain>
            <LeftRow>
              {lookupIcon(record.type)}
              {/* <ContactIcon /> */}
              <ListTextView>
                <NavigationTextWrapper mobile={mobile}>
                  <NavigationText
                    onClick={() => {
                      const url = getPath(lookup);
                      props.history.push(url);
                    }}>
                    {lookup?.name}
                    {record.type === 3 && lookup?.dealAmount && <>{` | ₹${lookup?.dealAmount}`}</>}
                  </NavigationText>

                  {companyOrContact && (
                    <>
                      <StaticColumn mobile={mobile}>|</StaticColumn>
                      <NavigationText
                        onClick={() => {
                          const url = getPath(companyOrContact);
                          props.history.push(url);
                        }}>
                        {companyOrContact?.name}
                      </NavigationText>
                    </>
                  )}
                </NavigationTextWrapper>
                <CaptionList>
                  {record?.type === 3 ? (
                    <HeadText>
                      This deal is about to close{' '}
                      <span title={moment(record.date).format(TOOLTIP_DATE_FORMAT)}>
                        {displayRelativeDate(record.date)}!
                      </span>
                    </HeadText>
                  ) : (
                    <HeadText>
                      Scheduled follow-up{' '}
                      <span title={moment(record.date).format(TOOLTIP_DATE_FORMAT)}>
                        {displayRelativeDate(record.date)}!
                      </span>
                    </HeadText>
                  )}
                </CaptionList>
              </ListTextView>
            </LeftRow>
          </Leftcontain>
          <RightContain mobile={mobile}>
            {userPermission?.permissionSendMail && (
              <IconButton
                iconName='icon-email'
                fontSize={20}
                iconColor='var(--gray-icon-color)'
                onClick={() => {
                  openSendEmailModal(lookup?.id, item?.type, lookup?.email);
                }}
                secondary
                title='Email'></IconButton>
            )}
            {hasPhoneNumber && (
              <RightBtn>
                <IconButton
                  iconName='icon-phone'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                  onClick={() => {
                    onCallClick(lookup);
                  }}
                  secondary
                  title='Call'></IconButton>
              </RightBtn>
            )}
            {userLookupEditPermission(record?.field?.tableId) && (
              <ViewIcon
                title={'More'}
                onClick={(e) => {
                  openViewModal(e, item);
                }}>
                <SVGIcon fill='var(--gray-color)' size={24} strokeWidth={2} name='icon-more' />
              </ViewIcon>
            )}
          </RightContain>
          <MobileIcon
            mobile={mobile}
            onClick={(e) => {
              onClickActivityMoreMenu(e, item);
            }}>
            <SVGIcon fill='var(--gray-color)' size={24} strokeWidth={2} name='icon-more' />
          </MobileIcon>
        </ListView>
      </RowList>
    );
  };

  const renderActivities = (item) => {
    const { record } = item || {};
    const { lookup, action, title } = record || {};
    const text = lookup?.contact ? lookup?.name + '@' + lookup?.contact?.name : lookup?.name;
    let url;
    if (lookup) {
      url = getPath(lookup);
    }
    return (
      <>
        <RowList key={record?.lookup?.id} mobile={mobile}>
          <ListView mobile={mobile}>
            <Leftcontain>
              <LeftRow>
                {actionIcon(action)}
                <ListTextView>
                  <NavigationTextWrapper>
                    <NavigationText
                      text={text}
                      onClick={() => {
                        props.history.push(url);
                      }}
                    />
                  </NavigationTextWrapper>
                  <CaptionList>
                    <HeadText>
                      {title}
                      {record.time && <>{` @ ${moment(record.time).format('h:mm A')}`}</>}
                    </HeadText>
                  </CaptionList>
                </ListTextView>
              </LeftRow>
            </Leftcontain>
            <RightContain mobile={mobile}>
              {(record?.lookup?.phone || record?.phone) && hasPhoneNumber && (
                <IconButton
                  iconName='icon-phone'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                  onClick={() => {
                    onCallClick(lookup);
                  }}
                  secondary
                  title='Call'></IconButton>
              )}
              <RightBtn>
                {(record?.lookup?.email || record?.email) && userPermission?.permissionSendMail && (
                  <IconButton
                    iconName='icon-email'
                    fontSize={20}
                    iconColor='var(--gray-icon-color)'
                    onClick={() => {
                      openSendEmailModal(lookup?.id, item?.type, lookup?.email);
                    }}
                    secondary
                    title='Email'></IconButton>
                )}
              </RightBtn>
              {userPermission?.permissionEditTask && (
                <ViewIcon
                  title={'More'}
                  onClick={(e) => {
                    onClickActivityMoreMenu(e, item);
                  }}>
                  <SVGIcon fill='var(--gray-color)' size={24} strokeWidth={2} name='icon-more' />
                </ViewIcon>
              )}
            </RightContain>
            <MobileIcon
              mobile={mobile}
              onClick={(e) => {
                onClickActivityMoreMenu(e, item);
              }}>
              <SVGIcon fill='var(--gray-color)' size={24} name='icon-more' strokeWidth={2} />
            </MobileIcon>
          </ListView>
          <BottomList>
            {record.description && <ListText dangerouslySetInnerHTML={{ __html: record.description }}></ListText>}
          </BottomList>
        </RowList>
      </>
    );
  };

  const actionIcon = (action) => {
    let iconName;
    let bgColor;
    switch (action) {
      case ACTIVITY_ACTION.Call:
        iconName = 'icon-phone';
        bgColor = '#F476CB';
        break;
      case ACTIVITY_ACTION.Meeting:
        iconName = 'icon-account';
        bgColor = '#F9A779';
        break;
      case ACTIVITY_ACTION.Comment:
        iconName = 'icon-message';
        bgColor = '#ED9190';
        break;
      case ACTIVITY_ACTION.Email:
        iconName = 'icon-email';
        bgColor = '#1CC7D0';
        break;
      case ACTIVITY_ACTION.Lunch:
        iconName = 'icon-food';
        bgColor = '#D87CA1';
        break;
      case ACTIVITY_ACTION.DeadLine:
        iconName = 'icon-flag';
        bgColor = '#F8AC63';
        break;
      case ACTIVITY_ACTION.Tasks:
        iconName = 'icon-check';
        bgColor = '#718F9D';
        break;
      case ACTIVITY_ACTION.Notes:
        iconName = 'icon-note';
        bgColor = '#7BD1B8';
        break;
      case ACTIVITY_ACTION.Status:
        iconName = 'icon-star';
        bgColor = '#2DDE98';
        break;
      case ACTIVITY_ACTION.File:
        iconName = 'icon-attachment';
        bgColor = ' #56AADA';
        break;
      case ACTIVITY_ACTION.Activity:
        // //timeline Activity
        iconName = 'activity-icon';
        // bgColor = colors.taskWhite;
        break;
      default:
    }
    return (
      <IconWrapper bgColor={bgColor}>
        <SVGIcon fill='var(--white-color)' size={20} name={iconName} strokeWidth={1.8} />
      </IconWrapper>
    );
  };

  const lookupIcon = (action) => {
    let iconName;
    let bgColor;
    switch (action) {
      case LOOKUP_TYPE.contacts:
        iconName = 'icon-user';
        bgColor = '#8185E0';
        break;
      case LOOKUP_TYPE.companies:
        iconName = 'icon-account';
        bgColor = '#F9A779';
        break;
      case LOOKUP_TYPE.deals:
        iconName = 'icon-deals';
        bgColor = '#00AEFF';
        break;
      default:
    }
    return (
      <IconWrapper bgColor={bgColor}>
        <SVGIcon fill='var(--white-color)' size={20} name={iconName} strokeWidth={1.8} />
      </IconWrapper>
    );
  };

  const renderItem = (item) => {
    if (!item) return null;
    const { type } = item;
    switch (type) {
      case INBOX_TYPE.activities:
        return renderActivities(item);
      case INBOX_TYPE.lookups:
        return renderLookups(item);
      default:
    }
  };

  const lookupIds = useMemo(() => {
    return [selectedEmailLookupId];
  }, [selectedEmailLookupId]);

  const mobileNavigation = useCallback(
    (url) => {
      if (!mobile) return null;
      props.history.push(url);
    },
    [mobile, props.history]
  );

  const user = UserPreferenceSingleton.getInstance().getCurrentUser();

  return (
    <>
      <MainContain mobile={mobile}>
        <WelView>
          <ContainList>
            <RowBox mobile={mobile}>
              <HeaderWrapper mobile={mobile}>
                <HeaderView mobile={mobile}>
                  {!mobile && (
                    <RowView>
                      <SVGIcon name='icon-inbox' size={26} fill='var(--primary-color)' />
                      <Title>Inbox</Title>
                      <OpenThread
                        onClick={() => {
                          props.history.push(ROUTE.INBOX_OPENTHREAD);
                        }}>
                        Open Thread
                      </OpenThread>
                    </RowView>
                  )}
                  <WelText mobile={mobile}>{`${getGreetingsText()} ${user?.given_name}`}</WelText>
                  <CaptionText>{`Today, you need to followup with ${getCount(dataList)} customer. `}</CaptionText>
                </HeaderView>
              </HeaderWrapper>
              {dataLoading ? (
                <span>Loading...</span>
              ) : onBoarding?.completed ? (
                <>
                  {emailCount > 0 && (
                    <RowList
                      mobile={mobile}
                      onClick={() => {
                        mobileNavigation(ROUTE.INBOXEMAIL);
                      }}>
                      <ListView>
                        <Leftcontain>
                          <LeftRow>
                            <IconWrapper bgColor='#1CC7D0'>
                              <SVGIcon name='icon-email' size={20} fill='var(--white-color)' strokeWidth={1.8} />
                            </IconWrapper>
                            <ListTextView>
                              <NavigationTextWrapper>
                                <NavigationText text={'Email'} hover={false} />
                              </NavigationTextWrapper>
                              <CaptionList>
                                <HeadText>{emailCount} unread email found in your mailbox </HeadText>
                              </CaptionList>
                            </ListTextView>
                          </LeftRow>
                        </Leftcontain>
                        <RightContain mobile={mobile}>
                          <IconButton
                            iconName='icon-video'
                            fontSize={20}
                            iconColor='var(--gray-icon-color)'
                            onClick={() => {
                              props.history.push(ROUTE.INBOXEMAIL);
                            }}
                            secondary
                            title='Start Screening'></IconButton>
                        </RightContain>
                      </ListView>
                    </RowList>
                  )}
                  {suggestionCount > 0 && (
                    <RowList
                      mobile={mobile}
                      onClick={() => {
                        mobileNavigation(ROUTE.INBOX_CONTACT_SUGGESTION);
                      }}>
                      <ListView>
                        <Leftcontain>
                          <LeftRow>
                            <IconWrapper bgColor='#2DDE98'>
                              <SVGIcon name='icon-star' size={20} fill='var(--white-color)' strokeWidth={1.8} />
                            </IconWrapper>
                            <ListTextView>
                              <NavigationTextWrapper>
                                <NavigationText text={'Suggestion'} hover={false} />
                              </NavigationTextWrapper>
                              <CaptionList>
                                <HeadText>{suggestionCount} Potential leads found </HeadText>
                              </CaptionList>
                            </ListTextView>
                          </LeftRow>
                        </Leftcontain>
                        <RightContain
                          onClick={() => {
                            props?.history?.push(ROUTE.INBOX_CONTACT_SUGGESTION);
                          }}
                          mobile={mobile}>
                          <IconButton
                            iconName='icon-note'
                            fontSize={20}
                            iconColor='var(--gray-icon-color)'
                            onClick={() => {}}
                            secondary
                            title='Review'></IconButton>
                        </RightContain>
                      </ListView>
                    </RowList>
                  )}
                  {duplicationCount > 0 && (
                    <RowList
                      mobile={mobile}
                      onClick={() => {
                        mobileNavigation(ROUTE.INBOX_DUPLICATION);
                      }}>
                      <ListView>
                        <Leftcontain>
                          <LeftRow>
                            <IconWrapper bgColor='#55CCF1'>
                              <SVGIcon name='icon-copy' size={20} fill='var(--white-color)' strokeWidth={1.8} />
                            </IconWrapper>
                            <ListTextView>
                              <NavigationTextWrapper>
                                <NavigationText
                                  text={`${duplicationCount} Duplicate contact found, review and marge it!`}
                                  hover={false}
                                />
                              </NavigationTextWrapper>
                              <CaptionList>
                                <HeadText>Duplication</HeadText>
                              </CaptionList>
                            </ListTextView>
                          </LeftRow>
                        </Leftcontain>
                        <RightContain mobile={mobile}>
                          <IconButton
                            iconName='icon-note'
                            fontSize={20}
                            iconColor='var(--gray-icon-color)'
                            secondary
                            title='Review'
                            onClick={() => {
                              props.history.push(ROUTE.INBOX_DUPLICATION);
                            }}></IconButton>
                        </RightContain>
                      </ListView>
                    </RowList>
                  )}
                  {dataList?.map((item, index) => {
                    return <div key={`Inbox_Activity_${index}`}>{renderItem(item)}</div>;
                  })}
                </>
              ) : (
                !isEmpty(onBoarding) && <InboxOnBoarding />
              )}
            </RowBox>
          </ContainList>
        </WelView>
      </MainContain>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenViewMenu}
        onClose={closeViewModal}
        getContentAnchorEl={null}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        width='214px'
        MenuListProps={{ disablePadding: true }}
        marginTop={12}>
        {isReschedulOpen ? (
          <RescheduleModal
            props={props}
            markAsCompleteLookup={markAsCompleteLookup}
            selectedItem={selectedItem}
            handleCloseModal={onCloseRescheduleMenu}
          />
        ) : (
          <DropDownBox>
            {mobile && (
              <>
                {(selectedItem?.record?.lookup?.phone || selectedItem?.record?.phone) && hasPhoneNumber && (
                  <MenuItem
                    iconName='icon-phone'
                    text='Call'
                    fontSize='18'
                    onClick={() => {
                      onCallClick(selectedItem?.record?.lookup);
                    }}
                  />
                )}
                {(selectedItem?.record?.lookup?.email || selectedItem?.record?.email) &&
                  userPermission?.permissionSendMail && (
                    <MenuItem
                      iconName='icon-email'
                      text='Email'
                      fontSize='18'
                      onClick={() => {
                        openSendEmailModal(
                          selectedItem?.record?.lookup?.id,
                          selectedItem?.record?.type,
                          selectedItem?.record?.email
                        );
                      }}
                    />
                  )}
              </>
            )}
            {selectedItem?.type === INBOX_TYPE.activities && addActivity && (
              <MenuItem
                iconName='icon-edit-pen'
                color='var(--gray-icon-color)'
                fontSize='18'
                text='Edit'
                onClick={onAddActivityOpen}
              />
            )}
            <MenuItem
              iconName='icon-checked-round'
              color='var(--gray-icon-color)'
              fontSize='18'
              text='Mark As Complete'
              onClick={() =>
                selectedItem?.type === INBOX_TYPE.lookups ? markAsCompleteLookup() : markAsCompleteActivity()
              }
            />
            <MenuItem
              iconName='icon-repeat'
              color='var(--gray-icon-color)'
              fontSize='18'
              text='Reschedule'
              onClick={onOpenRescheduleMenu}
            />
          </DropDownBox>
        )}
      </Menu>

      <Modal
        open={isOpenSendEmailModal}
        onClose={closeSendEmailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        style={ModalStyle}>
        <SendEmailModal handleCloseModal={closeSendEmailModal} lookupIds={lookupIds} type={SEND_EMAIL_TYPE.COMPOSE} />
      </Modal>
      <Modal open={isAddActivityOpen} onClose={onAddActivityClose}>
        <>
          {isAddActivityOpen && (
            <AddActivity
              type={addActivity}
              selectedActivity={selectedActivity}
              lookup={selectedActivity?.lookup}
              onMenuClose={onAddActivityClose}
              onSave={onSave}
            />
          )}
        </>
      </Modal>
      <SnackBar />
    </>
  );
}

export default Inbox;
