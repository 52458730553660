import { makeStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: ({ mobile }) => (mobile ? 'flex-end' : 'center'),
    justifyContent: 'center'
  }
}));
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  :focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;
const ModalWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;
const MainContainer = styled.div`
  background: var(--white-color);
  border-radius: 6px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  height: auto;
  margin: 25px;
  width: 560px;
  ${(props) =>
    props.style &&
    css`
      ${props.style}
    `}
  ${(props) =>
    props.mobile &&
    css`
      width: 100% !important;
      margin: 0;
    `}
`;
const Backdrop = styled.div`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
`;

export { MainContainer, ModalWrapper, Wrapper, Backdrop, useStyles };
