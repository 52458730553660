import React, { useMemo, useCallback } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { useSelector } from 'react-redux';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import { ValidationWrapper } from './styles';

const AddCommentActivity = ({ onChange, value, onAddMentionUser, onSubmit, isAddActivty, autoFocus = false }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { users } = organization;
  const mobile = useMobileDevice();

  const userList = useMemo(() => {
    return users?.map((item) => {
      return { ...item, display: item?.name };
    });
  }, [users]);
  const onAdd = useCallback(
    (id) => {
      const user = {
        id
      };
      if (onAddMentionUser) onAddMentionUser(user);
    },
    [onAddMentionUser]
  );

  return (
    <ValidationWrapper isAddActivty={isAddActivty} mobile={mobile}>
      <MentionsInput
        autoFocus={autoFocus}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (onSubmit) onSubmit(e);
          }
        }}
        id={'Comment'}
        placeholder='Add Comment'
        name='Comment'
        value={value}
        className='mentions'
        onChange={(e) => {
          onChange(e.target.value);
        }}>
        <Mention
          trigger='@'
          markup='@<span id="__id__">__display__</span>'
          data={userList}
          type='user'
          className='mentions__mention'
          onAdd={onAdd}
        />
      </MentionsInput>
    </ValidationWrapper>
  );
};
export default AddCommentActivity;
