import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const emailLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_EMAIL_LOADER
});

const emailAccountsReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_EMAIL_ACCOUNTS, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_EMAIL_ACCOUNT_LIST, (state, action) => {
      const list = current(state);
      const emailAccountList = JSON.parse(JSON.stringify(list));
      const emailAccountIndex = emailAccountList?.findIndex((item) => item?.id === action.payload.emailAccountId);
      if (emailAccountIndex !== -1) {
        const userIndex = emailAccountList[emailAccountIndex].users?.findIndex(
          (user) => user?.id === action.payload.userItem?.id
        );
        if (userIndex !== -1) {
          emailAccountList[emailAccountIndex].users.splice(userIndex, 1);
        } else {
          emailAccountList[emailAccountIndex].users.push(action.payload.userItem);
        }
      }
      return emailAccountList;
    });
});

const emailLabelListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_EMAIL_LABEL_LIST
});

const initialEmailState = [];
const emailListReducer = createReducerOrig(initialEmailState, (builder) => {
  builder
    .addCase(Actions.SET_EMAIL_LIST, (state = initialEmailState, action) => {
      return action.payload;
    })
    .addCase(Actions.UPDATE_EMAIL_LIST_ITEM, (state, action) => {
      const emailList = current(state);
      let list = JSON.parse(JSON.stringify(emailList));
      const items = action.payload.items;
      items?.forEach((item) => {
        const emailIndex = list?.findIndex((x) => x.id === item?.id);
        if (emailIndex !== -1) {
          list[emailIndex] = item;
        }
      });
      return list;
    })
    .addCase(Actions.DELETE_EMAIL_ITEM, (state, action) => {
      const emailList = current(state);
      let list = JSON.parse(JSON.stringify(emailList));
      const emailIndex = list.findIndex((x) => x.id === action.payload.id);
      if (emailIndex !== -1) {
        list.splice(emailIndex, 1);
      }
      return list;
    });
});

const emailTemplatesReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_EMAIL_TEMPLATES
});

const emailDetailReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_EMAIL_DETAIL
});

const emailSyncSettingsReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_EMAIL_SYNC_SETTINGS, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_SYNC_SETTINGS_FIELD_VALUE, (state, action) => {
      const syncSettings = { ...state };
      syncSettings[action.payload.propName] = action.payload.value;
      return { ...syncSettings };
    });
});

const emailTemplateItemReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_EMAIL_TEMPLATE_ITEM, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_EMAIL_TEMPLATE_ITEM, (state, action) => {
      const emailTemplate = { ...state };
      emailTemplate[action.payload.propName] = action.payload.value;
      return { ...emailTemplate };
    });
});

const totalEmailCountReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_TOTAL_EMAIL_COUNT
});
const initialEmailItemState = {
  body: '',
  emailAccount: {},
  files: [],
  signature: '',
  subject: '',
  to: [],
  cc: [],
  bcc: [],
  individualEmail: false
};
const emailItemReducer = createReducerOrig(initialEmailItemState, (builder) => {
  builder
    .addCase(Actions.SET_EMAIL_ITEM, (state = initialEmailItemState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_EMAIL_ITEM, (state, action) => {
      const emailItem = { ...state };
      emailItem[action.payload.propsName] = action.payload.value;
      return { ...emailItem };
    })
    .addCase(Actions.ADD_EMAIL_FILES, (state, action) => {
      const emailItem = { ...state };
      const files = emailItem.files.slice();
      files.push(action.payload.item);
      emailItem.files = files;
      return { ...emailItem };
    })
    .addCase(Actions.DELETE_EMAIL_FILES, (state, action) => {
      const emailItem = { ...state };
      const files = emailItem.files.slice();
      if (action.payload.index !== -1) {
        files.splice(action.payload.index, 1);
      }
      emailItem.files = files;
      return { ...emailItem };
    })
    .addCase(Actions.CLEAR_EMAIL_ITEM, () => {
      const initialItemState = JSON.parse(JSON.stringify(initialEmailItemState));
      return initialItemState;
    });
});

const emailReducer = combineReducers({
  loading: emailLoaderReducer,
  emailAccounts: emailAccountsReducer,
  list: emailListReducer,
  emailLabelList: emailLabelListReducer,
  emailTemplates: emailTemplatesReducer,
  emailTemplateItem: emailTemplateItemReducer,
  emailDetail: emailDetailReducer,
  syncSettings: emailSyncSettingsReducer,
  totalEmailCount: totalEmailCountReducer,
  emailItem: emailItemReducer
});

export default emailReducer;
