import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  addCustomizeFieldGroup,
  setIntegrationSecretKey,
  setCustomizeFields,
  setImportData,
  setPhoneNumberList,
  setSettingLoader,
  setSettingSignature,
  setSubscriptionList,
  setSubscriptionPlans,
  setTotalAmount,
  setTwilioSettingItem,
  setUserAccessData,
  setWebFormCode,
  setWebFormDetail,
  setWebFormList,
  setAPIKeys,
  setAPIKeyItem,
  setExistingPhoneNumbers
} from '../actions/settingActions';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';
import { REACT_APP_APIURL } from '../global/Environment';
import { isEmail, isEmpty } from '../global/Helper';
import { getAPIErrorReason, getUniqueId, randomIntFromInterval } from '../helpers/common';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import { trackAnalyticActivity } from './analyticsService';
import { getEmailAccounts } from './emailService';
import { getTableFields } from './lookupTableServices';
import { getOrganizationDetails, getOrganizationList } from './organizationService';
import TwilioService from './twilioService';

let phoneNumberAjaxRequest = null;
let emailAccountAjaxRequest = null;
/**
 * @desc Get phone numbers
 */
export const getPhoneNumbers = (organization_id, isRefresh) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    let phoneNumbers;
    if (!isRefresh) {
      phoneNumbers = OrganizationPreferencesSingleton.getInstance().getPhoneNumbers();
      if (phoneNumbers) {
        dispatch(setPhoneNumberList(phoneNumbers));
      }
    }
    if (!phoneNumbers || phoneNumbers.length === 0 || isRefresh) {
      if (phoneNumberAjaxRequest) {
        phoneNumberAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
      }
      phoneNumberAjaxRequest = axios.CancelToken.source();
      const response = await axios.get(`${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers`, {
        cancelToken: phoneNumberAjaxRequest.token
      });
      const { data } = response;
      if (data) {
        if (data?.length === 0) {
          dispatch(getTwilioSettings(organization_id));
        }
        dispatch(setPhoneNumberList(data));
        OrganizationPreferencesSingleton.getInstance().setPhoneNumbers(data);
      }
      return data;
    }
    return undefined;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get phone numbers, please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Add User In Phone Number List for Settings
 */
export const addUserInPhoneList = (organization_id, phoneNumberItemId, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    if (phoneNumberAjaxRequest) {
      phoneNumberAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    phoneNumberAjaxRequest = axios.CancelToken.source();
    const response = await axios.post(
      `${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers/${phoneNumberItemId}/users`,
      payload,
      {
        cancelToken: phoneNumberAjaxRequest.token
      }
    );
    const { data } = response;
    if (data) {
      await dispatch(getPhoneNumbers(organization_id, true));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Add User In Phone Number List please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Delete User From Phone Number List for Settings
 */
export const deleteUserFromPhoneList = (organization_id, phoneNumberItemId, userId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    if (phoneNumberAjaxRequest) {
      phoneNumberAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    phoneNumberAjaxRequest = axios.CancelToken.source();
    const response = await axios.delete(
      `${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers/${phoneNumberItemId}/users/${userId}`,
      {
        cancelToken: phoneNumberAjaxRequest.token
      }
    );
    const { data } = response;
    if (data) {
      await dispatch(getPhoneNumbers(organization_id, true));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIErrorReason(e) || 'Unable to Delete User from Phone Number List please try again',
      dispatch
    );
  } finally {
    dispatch(setSettingLoader(false));
  }
};
/**
 * @desc Add User In Emial Account List for Settings
 */
export const addUserInEmailAccount = (organization_id, emailId, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    if (emailAccountAjaxRequest) {
      emailAccountAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    emailAccountAjaxRequest = axios.CancelToken.source();
    const response = await axios.post(
      `${REACT_APP_APIURL}/Settings/${organization_id}/EmailAccounts/${emailId}/Users`,
      payload,
      {
        cancelToken: emailAccountAjaxRequest.token
      }
    );
    const { data } = response;
    if (data) {
      await dispatch(getEmailAccounts(organization_id, true));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Add User In Email Account, Please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Delete User From Email List for Settings
 */
export const deleteUserInEmailAccount = (organization_id, emailItemId, userId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(null));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    if (emailAccountAjaxRequest) {
      emailAccountAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    emailAccountAjaxRequest = axios.CancelToken.source();
    const response = await axios.delete(
      `${REACT_APP_APIURL}/Settings/${organization_id}/EmailAccounts/${emailItemId}/users/${userId}`,
      {
        cancelToken: emailAccountAjaxRequest.token
      }
    );
    const { data } = response;
    if (data) {
      await dispatch(getEmailAccounts(organization_id, true));
      return true;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchSettingError(
      getAPIErrorReason(e) || 'Unable to Delete User from Email Account, Please try again',
      dispatch
    );
  } finally {
    dispatch(setSettingLoader(false));
  }
};
/**
 * @desc Update Call Recording Status for Settings
 */
export const updateCallRecordingStatus = (organization_id, isRecordEnabled) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/company/${organization_id}/callrecording/${isRecordEnabled}`,
      {}
    );
    const { data } = response;
    if (data) {
      await dispatch(getOrganizationList());
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Update Call Recording status please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Make Number as Primary for Phone Settings
 */
export const makeNumberAsPrimary = (organization_id, phoneNumberItemId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers/${phoneNumberItemId}/primary`,
      {}
    );
    const { data } = response;
    if (data) {
      await dispatch(getPhoneNumbers(organization_id, true));
      dispatch(TwilioService.getInstance().initCallingService(true));
      return true;
    }
  } catch (e) {
    dispatchSettingError(
      getAPIErrorReason(e) || 'Unable to make number as Primary for phone settings please try again',
      dispatch
    );
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Delete Number for Phone Settings
 */
export const deletePhoneNumber = (organization_id, phoneNumberItemId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.delete(
      `${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers/item/${phoneNumberItemId}`
    );
    const { data } = response;
    if (data) {
      await dispatch(getPhoneNumbers(organization_id, true));
      dispatch(TwilioService.getInstance().initCallingService(true));
    }
    return true;
  } catch (e) {
    dispatchSettingError(
      getAPIErrorReason(e) || 'Unable to delete number for phone settings please try again',
      dispatch
    );
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc get Verification Code to Add Number for Phone Settings
 */
export const getVerificationCodeForNumber = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers/verify`, payload);
    const { data } = response;
    const { data: phoneNumberItem } = data;
    if (phoneNumberItem) {
      return phoneNumberItem;
    }
  } catch (e) {
    dispatchSettingError(
      getAPIErrorReason(e) || 'Unable to get verification code to add number for phone settings please try again',
      dispatch
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc call To Verify Phone Number to Add Number in Phone Settings
 */
export const callToVerifyPhoneNumber = (organization_id, phoneNumberItemId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.get(
      `${REACT_APP_APIURL}/call/${organization_id}/phoneNumbers/verify/${phoneNumberItemId}`
    );
    const { data } = response;
    if (data) {
      await dispatch(getPhoneNumbers(organization_id, true));
      dispatch(TwilioService.getInstance().initCallingService(true));
      return true;
    }
  } catch (e) {
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Twilio Settings
 */
export const getTwilioSettings = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/twilio`);
    const { data } = response;
    if (data) {
      dispatch(setTwilioSettingItem(data));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Twilio settings please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update Twilio Settings
 */
export const updateTwilioSettings = (organization_id, payload) => async (dispatch) => {
  try {
    if (isEmpty(payload?.accountSId)) {
      dispatchSettingError('Account SID is Required', dispatch);
      return false;
    }
    if (isEmpty(payload?.authToken)) {
      dispatchSettingError('Auth Token is Required', dispatch);
      return false;
    }
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/settings/${organization_id}/twilio`, payload);
    const { data } = response;
    if (data) {
      dispatchSettingSuccess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update Twilio settings please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Twilio Available Numbers
 */
export const getTwillioAvailablePhoneNumbers = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/twilio/availablephonenumbers/${organization_id}`, payload);
    const { data } = response;

    if (data && data?.length > 0) {
      const filteredPhoneNumbers = data?.filter(
        (item) => item?.capabilities?.sms === true && item?.capabilities?.voice === true
      );
      const randomNumberIndex = randomIntFromInterval(0, filteredPhoneNumbers?.length - 1);
      const randomPhoneNumberItem = filteredPhoneNumbers[randomNumberIndex];

      const payloadToBuyNumber = {
        friendlyName: randomPhoneNumberItem?.friendly_name,
        phoneNumber: randomPhoneNumberItem?.phone_number
      };

      const result = await dispatch(buyTwilioPhoneNumber(organization_id, payloadToBuyNumber));
      if (result) return result;
    }
  } catch (e) {
    dispatchSettingError(
      getAPIErrorReason(e) || 'Unable to get Twilio available phone numbers please try again',
      dispatch
    );
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Buy Twilio Phone Number
 */
export const buyTwilioPhoneNumber = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/twilio/buyphonenumber/${organization_id}`, payload);
    const { data } = response;
    if (data) {
      await dispatch(getPhoneNumbers(organization_id, true));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to buy Twilio phone number please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Web Form List for Settings
 */
export const getWebForms = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/forms`);
    const { data } = response;
    data && dispatch(setWebFormList(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Web Forms please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Web Form Detail for Settings
 */
export const getWebFormDetails = (organization_id, webFormId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const ItemResponse = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/forms/item/${webFormId}`);
    const { data: webFormDetail } = ItemResponse;
    webFormDetail && dispatch(setWebFormDetail(webFormDetail));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Web Form Details please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Web Form Code for Settings
 */
export const getWebFormCode = (organization_id, webFormId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/forms/GetForm/${webFormId}`);
    const { data } = response;
    data && dispatch(setWebFormCode(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Web Form Details please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update Web Form for Settings
 */
export const updateWebForm = (organization_id, webFormId, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/settings/${organization_id}/forms/update/${webFormId}`,
      params
    );
    const { data } = response;
    const { message } = data || response;
    data && (await dispatch(getWebForms(organization_id)));
    dispatchSettingSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Update Web Form please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Create Web Form for Settings
 */
export const createWebForm = (organization_id, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/settings/${organization_id}/forms/create`, params);
    const { data } = response;
    const { message } = data || response;
    data && (await dispatch(getWebForms(organization_id)));
    dispatchSettingSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to create Web Form please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Delete Web Form for Settings
 */
export const deleteWebForm = (organization_id, webFormId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/settings/${organization_id}/forms/item/${webFormId}`);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      await dispatch(getWebForms(organization_id));
      dispatchSettingSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Web Form Details please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update Columns Setting
 * @param {*} obj Data Obj
 */
export const updateColumnsSetting = (organization_id, table_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Settings/${organization_id}/Fields/${table_id}`, obj);
    const { data } = response;
    if (data) return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update Setting please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get User Access Data for Settings
 */
export const getUserAccessData = (organization_id, userId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/company/${organization_id}/user/${userId}`);
    const { data } = response;
    data && dispatch(setUserAccessData(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get User Access Data please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update User Access Data for Settings
 */
export const updateUserAccessPermissionData = (organization_id, userId, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/company/${organization_id}/user/${userId}`, params);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      trackUserPermissionUpdateAnalyticActivity();
      await dispatch(getOrganizationDetails(organization_id));
      dispatchSettingSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update User access data please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};
const trackUserPermissionUpdateAnalyticActivity = () => {
  try {
    trackAnalyticActivity('settings - user: permission updated');
  } catch (e) {
    console.log('track settings - user: permission updated Error', e);
  }
};
/**
 * @desc Invite User - Settings
 */
export const inviteUser = (organization_id, params) => async (dispatch) => {
  try {
    if (isEmpty(params?.email)) {
      dispatchSettingError('Email is Required', dispatch);
      return false;
    } else if (!isEmail(params?.email)) {
      dispatchSettingError('Please enter a valid email address', dispatch);
      return false;
    }
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/company/users`, params);
    const { data } = response;
    const { message } = data || response;

    if (data) {
      trackUserActivityEvent(data?.data);
      await dispatch(getOrganizationDetails(organization_id));
    }
    dispatchSettingSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Invite user please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

const trackUserActivityEvent = (data) => {
  try {
    if (data) {
      let Prefix = 'account added user';
      if (data?.id) Prefix = 'account added user';
      else Prefix = 'invite sent';
      trackAnalyticActivity(Prefix, { invitee_email: data?.email, invitee_name: data?.name });
    }
  } catch (e) {}
};

/**
 * @desc Delete User - Settings
 */
export const deleteUser = (organization_id, userId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/company/users/${organization_id}/item/${userId}`);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      await dispatch(getOrganizationDetails(organization_id));
    }
    dispatchSettingSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Delete user please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Customize Fields for Settings
 */
export const getCustomizeFields =
  (organization_id, tableId, addFieldGroup = false) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!organization_id) return false;
      dispatch(setSettingLoader(true));
      const response = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/customize/${tableId}`);
      const { data } = response;
      data && dispatch(setCustomizeFields(data));

      if (addFieldGroup) {
        let fieldGroup = {};
        fieldGroup.name = 'Group';
        fieldGroup.localId = getUniqueId();
        fieldGroup.fields = [];
        dispatch(addCustomizeFieldGroup({ fieldGroup: fieldGroup }));
      }
      return true;
    } catch (e) {
      dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Table customize fields please try again', dispatch);
    } finally {
      dispatch(setSettingLoader(false));
    }
  };

/**
 * @desc Update Customize Fields for Settings
 */
export const updateCustomizeFields = (organization_id, tableId, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/settings/${organization_id}/customize/${tableId}`, payload);
    const { data } = response;
    if (data) {
      dispatch(getTableFields(organization_id, true));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update Table customize fields please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Subscription List for Settings
 */
export const getSubscriptionList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/Subscriptions/${organization_id}`);
    const { data } = response;
    data && dispatch(setSubscriptionList(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Subscription list please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Subscription Plans for Settings
 */
export const getSubscriptionPlans = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/Subscriptions/${organization_id}/plans`);
    const { data } = response;
    data && dispatch(setSubscriptionPlans(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Subscription plans please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc create stripe subscription
 */
export const createStripeSubscription = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(`${REACT_APP_APIURL}/subscriptions/stripe/subscription`, payload);
    const { data } = response;
    return data;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to create subscription', dispatch);
    throw e;
  }
};

/**
 * @desc create salescamp subscription
 */
export const createSalescampSubscription = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(`${REACT_APP_APIURL}/subscriptions/create`, payload);
    const { data } = response;
    return data;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to create subscription', dispatch);
    throw e;
  }
};

/**
 * @desc Get Import Mapped Data for Settings
 */
export const getImportData = (organization_id, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${organization_id}/getimport`, params);
    const { data } = response;
    if (data) {
      trackFieldMappedAnalyticActivity();
      dispatch(setImportData(data));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Import data please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

const trackFieldMappedAnalyticActivity = () => {
  try {
    trackAnalyticActivity('settings - import: field mapped');
  } catch (e) {
    console.log('track settings - import: field mapped Error', e);
  }
};

/**
 * @desc Import Data for Settings
 */
export const importTableData = (organization_id, tableId, importData) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${organization_id}/import/${tableId}`, importData);
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to Import data please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc get Setting Signature
 */
export const getSettingSignature = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/signature`);
    const { data } = response;
    if (data) {
      dispatch(setSettingSignature(data));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Signature please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update Email Account Signature
 */
export const updateEmailAccountSignature = (organization_id, email_account_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.put(
      `${REACT_APP_APIURL}/Settings/${organization_id}/EmailAccounts/${email_account_id}/signature`,
      payload
    );
    const { data } = response;
    if (data) {
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update email account signature please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get API Key for Integrations
 */
export const getIntegrationSecretKey = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/settings/${organization_id}/apikey`);
    const { data } = response;
    dispatch(setIntegrationSecretKey(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get Key please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update Call Forwarding
 */
export const updateCallForwarding = (organization_id, phone_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/Call/${organization_id}/PhoneNumbers/${phone_id}/call-forwarding`,
      payload
    );
    const { data } = response;
    if (data) {
      dispatchSettingSuccess(data?.message, dispatch);
      dispatch(getPhoneNumbers(organization_id, true));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update call forwarding please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc get Credit Amount
 * @param organization_id
 */
export const getCreditAmount = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const data = {
      amount: 1000
    };
    dispatch(setTotalAmount(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get credit amount please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get api key list
 */
export const getAPIKeys = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/apikeys/${organization_id}`);
    const { data } = response;
    if (data) dispatch(setAPIKeys(data));
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get api keys, please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get api key list
 */
export const getAPIKeyItem = (organization_id, id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/apikeys/${organization_id}/item/${id}`);
    const { data } = response;
    if (data) dispatch(setAPIKeyItem(data));
    return data;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get api keys, please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Create api key
 */
export const createAPIKey = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/apikeys/${organization_id}/create`, payload);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatch(getAPIKeys(organization_id));
      dispatchSettingSuccess(message, dispatch);
    }
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to create Web Form please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update api key
 */
export const updateAPIKey = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/apikeys/${organization_id}/update/${payload.id}`, payload);
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatch(getAPIKeys(organization_id));
    }
    dispatchSettingSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update api key, please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Update api key
 */
export const regenerateAPIKey = (organization_id, id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/apikeys/${organization_id}/regenerate/${id}`, {});
    const { data } = response;
    const { message } = data || response;
    if (data) {
      dispatch(getAPIKeys(organization_id));
    }
    dispatchSettingSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to update api key, please try again', dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Delete api key
 */
export const deleteAPIKey = (organization_id, id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));

    const response = await axios.delete(`${REACT_APP_APIURL}/apikeys/${organization_id}/item/${id}`);
    const { data } = response;
    if (data) {
      dispatch(getAPIKeys(organization_id));
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to delete api key, please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get Existing Phone Numbers
 */
export const getExistingPhoneNumbers = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/twilio/${organization_id}/PhoneNumbers/existing`);
    const { data } = response;
    if (data) {
      const newData = data?.map((item) => {
        return { ...item, isLinkedNumber: false };
      });
      dispatch(setExistingPhoneNumbers(newData));
      return newData;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to get existing phone numbers please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Add Existing Phone Number
 */
export const addExistingPhoneNumber = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setSettingLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/twilio/${organization_id}/PhoneNumbers/existing`, payload);
    const { data } = response;
    if (data) {
      dispatchSettingSuccess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchSettingError(getAPIErrorReason(e) || 'Unable to add existing phone numbers please try again', dispatch);
  } finally {
    dispatch(setSettingLoader(false));
  }
};

export const trackWebFormActionAnalyticActivity = (action, formName) => {
  try {
    trackAnalyticActivity('settings - web form: action', {
      action: action,
      'form name': formName ? String(formName).toLowerCase() : undefined
    });
  } catch (e) {
    console.log('track settings - web form: action Error', e);
  }
};

export const trackWebFormAnalyticActivity = (activity, name, field) => {
  try {
    trackAnalyticActivity(`settings - web form: ${activity}`, {
      name: String(name).toLowerCase(),
      field: field
    });
  } catch (e) {
    console.log(`track settings - web form: ${activity} Error`, e);
  }
};

export const trackWebFormDeleteAnalyticActivity = (id) => {
  try {
    trackAnalyticActivity(`settings - web form: deleted`, { id: id });
  } catch (e) {
    console.log(`track settings - web form: deleted Error`, e);
  }
};

export const trackCustomizeCollectionAnalyticActivity = (activity, itemName, itemType) => {
  try {
    trackAnalyticActivity(`settings - customize: collection ${activity}`, {
      name: String(itemName).toLowerCase(),
      type: itemType
    });
  } catch (e) {
    console.log(`track settings - customize: collection ${activity} Error`, e);
  }
};

export const trackAPIKeyActionAnalyticActivity = (action, formName) => {
  try {
    trackAnalyticActivity('settings - api key: action', {
      action: action,
      'key name': formName ? String(formName).toLowerCase() : undefined
    });
  } catch (e) {
    console.log('track settings - api key: action Error', e);
  }
};

function dispatchSettingError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchSettingSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
