import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import { LOOKUP_FIELD_CUSTOM_ID } from '../../../../constants/constant';
import { displayRelativeDate, isEmpty } from '../../../../helpers/common';
import Avtar from '../../../../components/Avtar/Avtar';
import { Menu } from '../../../../components/Menu';
import DayPickerCard from '../../../../components/DateTime/DayPicker';

import {
  FirstConatin,
  FirstView,
  FirstList,
  BoxText,
  LastViewOne,
  SecondView,
  LastView,
  Lasttext,
  Lasttext2,
  PopView,
  ViewList,
  ShowText,
  ShowTime,
  DateTextView,
  Border,
  useStyles,
  ProfileName,
  InputWrapperModal,
  InputLabel,
  StyledMenuItem,
  AvtarWrapper,
  AvtarWrapperBox,
  IconWrapper,
  Customize,
  SvgWrapper,
  Status
} from '../styles';

const ContactDetailActionBlock = ({ lookupDetails, userAccess, users, updateLookupFields, table }) => {
  const mobile = useMobileDevice();
  const history = useHistory();
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [statusField, setStatusField] = useState(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateField, setFollowupDateField] = useState(null);
  const [assignTo, setAssignTo] = useState();
  const [isAssignedOpen, setIsAssignedOpen] = useState(false);

  useEffect(() => {
    setFollowupDateField(lookupDetails?.dateField?.value_Date);
  }, [lookupDetails.dateField]);

  useEffect(() => {
    if (lookupDetails.assignToField?.value_MultiLookup) setAssignTo(lookupDetails?.assignToField?.value_MultiLookup);
  }, [lookupDetails.assignToField]);

  useEffect(() => {
    const status = lookupDetails?.statusField?.options?.find((x) => x?.id === lookupDetails?.statusField?.value);
    setStatusField(status);
  }, [lookupDetails.statusField]);

  const onOpenStatusMenu = useCallback((e) => {
    e.stopPropagation();
    setIsStatusOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onOpenCalendar = useCallback((e) => {
    setIsCalendarOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onOpenAssignedMenu = useCallback((e) => {
    e.stopPropagation();
    setIsAssignedOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseStatusMenu = useCallback(() => {
    setIsStatusOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onClickCustomize = useCallback(() => {
    history.push(`/settings/customize/${table?.name}?field-id=${lookupDetails?.statusField?.id}`);
  }, [history, table?.name, lookupDetails?.statusField?.id]);

  const onCloseCalendar = useCallback(() => {
    setIsCalendarOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const handleChangeStatusField = useCallback(
    (status) => {
      onCloseStatusMenu();
      if (status?.id === statusField?.id) return;
      setStatusField(status);
      const field = lookupDetails?.statusField;
      const value = { value: status?.id };
      updateLookupFields(field, value, true);
    },
    [lookupDetails?.statusField, onCloseStatusMenu, statusField?.id, updateLookupFields]
  );

  const handleChangeFollowupDateField = useCallback(
    (date) => {
      const formatedValue = date ? date.format('YYYY MMM DD') : undefined;
      const field = lookupDetails?.dateField;
      const value = { value_Date: formatedValue };
      onCloseCalendar();
      setFollowupDateField(date);
      updateLookupFields(field, value);
    },
    [lookupDetails?.dateField, onCloseCalendar, updateLookupFields]
  );

  const dateOfToday = useMemo(() => {
    return moment();
  }, []);

  const dateOfTomorrow = useMemo(() => {
    return moment().add(1, 'days');
  }, []);

  const dateOfNextMonday = useMemo(() => {
    return moment().day(8);
  }, []);

  const onCloseAssignedMenu = useCallback(() => {
    setIsAssignedOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onPressUser = useCallback(
    (item) => {
      let assign = assignTo?.slice() || [];

      const index = assign?.findIndex((x) => x?.id === item?.id);
      if (index === -1) {
        assign?.push(item);
      } else {
        assign?.splice(index, 1);
      }
      setAssignTo(assign);
      if (assign?.length - 1 < 1) onCloseAssignedMenu();
      const field = lookupDetails?.assignToField;
      const value = {
        value_MultiLookup: assign
      };
      updateLookupFields(field, value);
    },
    [assignTo, lookupDetails?.assignToField, updateLookupFields, onCloseAssignedMenu]
  );

  const statusMenu = useMemo(() => {
    return (
      <Menu
        id='status-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isStatusOpen}
        onClose={onCloseStatusMenu}
        className='status-menu'
        getContentAnchorEl={null}
        position='center'
        marginTop='25px'
        positionVertical='top'
        width='180px'>
        {lookupDetails?.statusField?.options?.map((item, index) => {
          return (
            <StyledMenuItem key={`lookupDetails_${item?.id}_${index}`} mobile={mobile}>
              <InputWrapperModal
                onClick={() => {
                  handleChangeStatusField(item);
                }}>
                <InputLabel htmlFor={item?.id}>
                  <Status>{item?.value}</Status>
                </InputLabel>
              </InputWrapperModal>
            </StyledMenuItem>
          );
        })}
        <Customize onClick={onClickCustomize}>
          Customize
          <SVGIcon name='icon-arrow-right' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
        </Customize>
      </Menu>
    );
  }, [
    handleChangeStatusField,
    isStatusOpen,
    lookupDetails?.statusField?.options,
    menuAnchorEl,
    mobile,
    onClickCustomize,
    onCloseStatusMenu
  ]);

  const followupMenu = useMemo(() => {
    return (
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        classes={{ paper: classes.paperMenu }}
        keepMounted
        open={isCalendarOpen}
        onClose={onCloseCalendar}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        MenuListProps={{ disablePadding: true }}
        width={245}
        marginTop={30}>
        <PopView>
          <ViewList
            onClick={() => {
              handleChangeFollowupDateField(dateOfToday);
            }}>
            <SvgWrapper>
              <SVGIcon name='icon-today' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
            </SvgWrapper>
            <DateTextView>
              <ShowText>Today</ShowText>
              <ShowTime>{dateOfToday.format('ddd Do')}</ShowTime>
            </DateTextView>
          </ViewList>
          <ViewList
            onClick={() => {
              handleChangeFollowupDateField(dateOfTomorrow);
            }}>
            <SvgWrapper>
              <SVGIcon name='icon-tomorrow' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
            </SvgWrapper>
            <DateTextView>
              <ShowText>Tomorrow</ShowText>
              <ShowTime>{dateOfTomorrow.format('ddd Do')}</ShowTime>
            </DateTextView>
          </ViewList>
          <ViewList
            onClick={() => {
              handleChangeFollowupDateField(dateOfNextMonday);
            }}>
            <SvgWrapper>
              <SVGIcon name='icon-next-week' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
            </SvgWrapper>
            <DateTextView>
              <ShowText>Next Week</ShowText>
              <ShowTime>{dateOfNextMonday.format('ddd Do')}</ShowTime>
            </DateTextView>
          </ViewList>
          <ViewList
            onClick={() => {
              handleChangeFollowupDateField(null);
            }}>
            <SvgWrapper>
              <SVGIcon name='icon-stop' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
            </SvgWrapper>
            <DateTextView>
              <ShowText>No Date</ShowText>
            </DateTextView>
          </ViewList>
          <Border></Border>
          <DayPickerCard
            selectedDays={new Date(dateField)}
            disabledDays={{ before: new Date() }}
            onDayClick={(date) => {
              handleChangeFollowupDateField(moment(date));
            }}
          />
        </PopView>
      </Menu>
    );
  }, [
    classes.paperMenu,
    dateField,
    dateOfNextMonday,
    dateOfToday,
    dateOfTomorrow,
    handleChangeFollowupDateField,
    isCalendarOpen,
    menuAnchorEl,
    onCloseCalendar
  ]);

  const assignedToMenu = useMemo(() => {
    return (
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isAssignedOpen}
        onClose={onCloseAssignedMenu}
        getContentAnchorEl={null}
        position='left'
        marginTop='25px'
        positionVertical='top'
        width='180px'>
        {users?.map((user, index) => {
          const selectedUser = assignTo || lookupDetails?.assignToField?.value_MultiLookup;
          const selected = selectedUser?.find((x) => x.id === user?.id);
          return (
            <StyledMenuItem key={`user_${user?.id}_${index}`}>
              <InputWrapperModal>
                <InputLabel htmlFor={`user_${user?.id}_index_${index}`}>
                  <Avtar key={user?.id} imgSrc={user?.profile_photo} name={user?.name} size={20} />
                  <ProfileName>{user?.name}</ProfileName>
                </InputLabel>
                <input
                  id={`user_${user?.id}_index_${index}`}
                  type='checkbox'
                  checked={!isEmpty(selected) ? true : false}
                  onChange={() => {
                    onPressUser(user);
                  }}
                />
                <span className='checkmark'></span>
              </InputWrapperModal>
            </StyledMenuItem>
          );
        })}
      </Menu>
    );
  }, [
    assignTo,
    isAssignedOpen,
    lookupDetails?.assignToField?.value_MultiLookup,
    menuAnchorEl,
    onCloseAssignedMenu,
    onPressUser,
    users
  ]);

  const statusFieldView = useMemo(() => {
    if (lookupDetails.statusField)
      return (
        <FirstView mobile={mobile}>
          <FirstList>
            <SVGIcon name='icon-star' size={22} fill='var(--gray-icon-color)' />
            <BoxText>{lookupDetails?.statusField?.label}</BoxText>
          </FirstList>
          <LastViewOne onClick={userAccess?.permissionEdit ? onOpenStatusMenu : null}>
            {statusField?.value ? <span>{statusField?.value}</span> : <Lasttext>Add</Lasttext>}
          </LastViewOne>
        </FirstView>
      );
  }, [lookupDetails.statusField, mobile, onOpenStatusMenu, statusField?.value, userAccess?.permissionEdit]);

  const followupFieldView = useMemo(() => {
    if (lookupDetails.dateField)
      return (
        <SecondView mobile={mobile}>
          <FirstList>
            <SVGIcon name='icon-follow-up' size={22} fill='var(--gray-icon-color)' />
            <BoxText>
              {lookupDetails?.dateField?.customId === LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE
                ? 'Followup'
                : lookupDetails?.dateField?.label}
            </BoxText>
          </FirstList>
          <LastView onClick={userAccess?.permissionEdit ? onOpenCalendar : null}>
            {dateField ? (
              <Lasttext2> {displayRelativeDate(dateField)} </Lasttext2>
            ) : (
              userAccess?.permissionEdit && <Lasttext>Add</Lasttext>
            )}
          </LastView>
        </SecondView>
      );
  }, [dateField, lookupDetails.dateField, mobile, onOpenCalendar, userAccess?.permissionEdit]);

  const assignToFieldView = useMemo(() => {
    if (lookupDetails?.assignToField)
      return (
        <SecondView mobile={mobile}>
          <FirstList>
            <SVGIcon name='icon-assign' size={22} fill='var(--gray-icon-color)' />
            <BoxText>{lookupDetails?.assignToField?.label}</BoxText>
          </FirstList>
          <LastView>
            {isEmpty(assignTo) ? (
              userAccess?.permissionEdit && <Lasttext onClick={onOpenAssignedMenu}>Add</Lasttext>
            ) : (
              <>
                {assignTo?.slice(0, assignTo?.length > 4 ? 3 : 4)?.map((item, index) => {
                  const selectedUser = users?.find((x) => x.id === item.id);

                  return (
                    selectedUser && (
                      <AvtarWrapper key={`assignTo_${item?.id}_${index}`}>
                        <Avtar key={item.id} imgSrc={selectedUser?.profile_photo} name={selectedUser?.name} size={22} />
                      </AvtarWrapper>
                    )
                  );
                })}
                {assignTo?.length > 4 && <AvtarWrapperBox> {`+${assignTo.length - 3}`} </AvtarWrapperBox>}
                {userAccess?.permissionEdit && (
                  <IconWrapper title={'Assign User'} onClick={onOpenAssignedMenu}>
                    <SVGIcon name='icon-add-plus' size={18} fill='var(--gray-icon-color)' />
                  </IconWrapper>
                )}
              </>
            )}
          </LastView>
        </SecondView>
      );
  }, [assignTo, lookupDetails?.assignToField, mobile, onOpenAssignedMenu, userAccess?.permissionEdit, users]);

  return (
    <>
      <FirstConatin mobile={mobile}>
        {statusFieldView}
        {followupFieldView}
        {assignToFieldView}
      </FirstConatin>
      {statusMenu}
      {followupMenu}
      {assignedToMenu}
    </>
  );
};

export default ContactDetailActionBlock;
