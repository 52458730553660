import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avtar from '../../components/Avtar/Avtar';
import { createOrganization, getOrganizationUsers } from '../../services/organizationService';
import { ROUTE } from '../../constants/routeConst';
import { appInit } from '../../services/appService';
import AppLoading from '../../components/AppLoading';
import { clearOrganizationItem } from '../../actions/organizationActions';
import { isEmail, isEmpty } from '../../helpers/common';
import { trackAnalyticActivity } from '../../services/analyticsService';
import OnboardingStep1 from './OnboardingStep1';
import OnboardingStep2 from './OnboardingStep2';
import OnboardingStep3 from './OnboardingStep3';

const Form = styled.form`
  height: 100%;
  position: relative;
  .Avtar {
    width: 33px;
    height: 33px;
    position: absolute;
    bottom: 14px;
    left: 15px;
  }
`;

const Onborading = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const organizationSelector = useSelector((state) => state.organization);
  const { organizationItem } = organizationSelector;
  const dispatch = useDispatch();
  const history = useHistory();

  const gotoStep2 = useCallback(() => {
    setCurrentStep(2);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationItem());
    };
  }, [dispatch]);
  const gotoStep3 = useCallback(() => {
    setCurrentStep(3);
  }, []);

  const trackRegisterInvitedMemberAnalyticActivity = useCallback((type, user) => {
    try {
      if (type && user && user.email) {
        let Prefix = 'account added user';
        if (type === 1) Prefix = 'account added user';
        else if (type === 2) Prefix = 'invite sent';
        trackAnalyticActivity(Prefix, { invitee_email: user.email, invitee_name: user.name });
      }
    } catch (e) {
      console.log('track invite sent Error', e);
    }
  }, []);

  const getDataToSendToIntercom = useCallback(
    (item) => {
      try {
        if (!item) return;
        const users = getOrganizationUsers();
        if (item?.invitedUsers && item?.invitedUsers.length > 0) {
          item?.invitedUsers?.forEach((x) => {
            let user = users?.find((y) => y?.email === x?.email);
            // Type 1: invited User already had an account Type 2: invited User don't have an account
            if (user) trackRegisterInvitedMemberAnalyticActivity(1, user);
            else if (x && x?.email) trackRegisterInvitedMemberAnalyticActivity(2, x);
          });
        }
      } catch (e) {
        console.log('track invite sent Error', e);
      }
    },
    [trackRegisterInvitedMemberAnalyticActivity]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let error = {};
      organizationItem.invitedUsers.forEach((item) => {
        if (item.email && item.email !== '' && !isEmail(item.email)) {
          error['email'] = 'Please enter a valid email address';
        }
      });
      if (!isEmpty(error)) {
        setError(error);
        return;
      }
      const result = await dispatch(createOrganization(organizationItem));
      try {
        if (result) {
          setLoading(true);
          getDataToSendToIntercom(organizationItem);
          await dispatch(appInit());
          history.push(ROUTE.INBOX);
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [dispatch, getDataToSendToIntercom, history, organizationItem]
  );

  return loading ? (
    <AppLoading />
  ) : (
    <>
      <Form>
        {currentStep === 1 && <OnboardingStep1 isShow={false} gotoNext={gotoStep2} />}
        {currentStep === 2 && <OnboardingStep2 gotoNext={gotoStep3} />}
        {currentStep === 3 && <OnboardingStep3 onSubmit={onSubmit} error={error} />}
        <Avtar size={33} className={'Avtar'} name={organizationItem?.name} />
      </Form>
    </>
  );
};

export default Onborading;
