import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { getCallLogList } from '../../../services/callLogService';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { ReactComponent as Play } from '../../../assets/images/svg/play.svg';
import { ReactComponent as Pause } from '../../../assets/images/svg/pause.svg';
import Table from '../../../components/Table/Table';
import { AudioWrapper } from '../../Contact/ContactDetail/ContactActivity/styles';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import { CollLogMain, CollLogHeader, CollLogContainer, LinkBlock, LinkListItem, TableStyle, IconBox } from './styles';
import CallLogEmptyState from './CallLogEmptyState';

const CallLog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const callLogSelector = useSelector((state) => state.callLog);

  const { organization } = organizationSelector;
  const { list, loading } = callLogSelector;
  const { id: organization_id } = organization;
  const loadData = useCallback(() => {
    dispatch(getCallLogList(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const tableColumns = useMemo(() => {
    return [
      { Header: 'To', accessor: 'to' },
      { Header: 'From', accessor: 'from' },
      { Header: 'Time', accessor: 'time' },
      { Header: 'Duration', accessor: 'duration' }
    ];
  }, []);

  const tableData = useMemo(() => {
    const columns = [
      {
        Header: () => <></>,
        accessor: 'call',
        Cell: (props) => {
          return (
            <IconBox title={props?.cell?.row?.original?.status}>
              {props?.cell?.row?.original?.direction === 'outbound-dial' ? (
                <SVGIcon name='icon-outgoing' fill='var(--callreject-icon)' size={18}></SVGIcon>
              ) : (
                <SVGIcon name='icon-ingoing' fill='var(--mantis-icon)' size={18}></SVGIcon>
              )}
            </IconBox>
          );
        }
      },
      ...tableColumns,
      {
        Header: () => <div>Recording</div>,
        accessor: 'recording',
        Cell: (props) => {
          return (
            <div>
              {props?.cell?.row?.original?.recordings &&
                props?.cell?.row?.original?.recordings.map((item) => {
                  return (
                    <>
                      <AudioWrapper mobile={mobile}>
                        <AudioPlayer
                          src={item}
                          showJumpControls={false}
                          defaultCurrentTime={false}
                          customProgressBarSection={[
                            RHAP_UI.MAIN_CONTROLS,
                            RHAP_UI.CURRENT_TIME,
                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.DURATION,
                            RHAP_UI.VOLUME_CONTROLS
                          ]}
                          customIcons={{
                            play: <Play />,
                            pause: <Pause />
                          }}
                          customControlsSection={[]}
                          customAdditionalControls={[]}
                        />
                      </AudioWrapper>
                    </>
                  );
                })}
            </div>
          );
        }
      }
    ];

    const data = list?.map((item) => {
      return {
        ...item,
        time: moment(item.date).format('DD MMM YYYY HH:mm:ss'),
        duration: moment.utc(Math.abs(item?.duration) * 1000).format(item?.duration > 3599 ? 'HH:mm:ss' : 'mm:ss')
      };
    });
    return {
      columns,
      data: data
    };
  }, [list, mobile, tableColumns]);

  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <CollLogHeader mobile={mobile}>
            <LinkBlock>
              <LinkListItem
                className='active'
                onClick={() => {
                  history.push('/settings/calllog/call');
                }}>
                Call
              </LinkListItem>
              <LinkListItem
                onClick={() => {
                  history.push('/settings/calllog/message');
                }}>
                Message
              </LinkListItem>
            </LinkBlock>
          </CollLogHeader>
        </MobileTitleBar>
      )}
      <CollLogMain>
        {mobile ? null : (
          <CollLogHeader>
            <LinkBlock>
              <LinkListItem
                className='active'
                onClick={() => {
                  history.push('/settings/calllog/call');
                }}>
                Call
              </LinkListItem>
              <LinkListItem
                onClick={() => {
                  history.push('/settings/calllog/message');
                }}>
                Message
              </LinkListItem>
            </LinkBlock>
          </CollLogHeader>
        )}
        <CollLogContainer>
          {!loading && (!list || list?.length === 0) && <CallLogEmptyState />}
          {loading && (!list || list?.length === 0) ? (
            <div>Loading...</div>
          ) : (
            list &&
            list?.length > 0 && (
              <TableStyle mobile={mobile}>
                <Table columns={tableData?.columns || []} data={tableData?.data || []}></Table>
              </TableStyle>
            )
          )}
        </CollLogContainer>
      </CollLogMain>
    </>
  );
};

export default CallLog;
