import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import { setIsAppSidebarOpen } from '../../actions/appActions';
import { getScreenName } from '../../helpers/common';
import { MobileHead, PageText, SvgWrapper, MobileInnerHeader } from './styles';

const MobileTitleBar = ({ hasNavBar = true, hasTitle = true, title, children, containerStyle = {} }) => {
  const mobile = useMobileDevice();
  const location = useLocation();
  const history = useHistory();

  const appSelector = useSelector((state) => state.app);
  const isSidebarOpen = appSelector?.isSidebarOpen;
  const dispatch = useDispatch();

  const onOpenNavbar = useCallback(() => {
    dispatch(setIsAppSidebarOpen(true));
  }, [dispatch]);

  const onCloseNavbar = useCallback(() => {
    if (!mobile) return;
    dispatch(setIsAppSidebarOpen(false));
  }, [dispatch, mobile]);

  const screenName = useMemo(() => {
    if (!mobile) return;
    if (title) return title;
    let name = '';
    if (location?.pathname) name = dispatch(getScreenName(location?.pathname));
    return name;
  }, [dispatch, title, mobile, location?.pathname]);

  if (!mobile) return null;

  return (
    <MobileHead mobile={mobile} style={containerStyle}>
      <MobileInnerHeader>
        {hasNavBar ? (
          <SvgWrapper
            onClick={() => {
              isSidebarOpen ? onCloseNavbar() : onOpenNavbar();
            }}>
            <SVGIcon
              name={isSidebarOpen ? 'icon-close' : 'icon-menu'}
              size={30}
              fill={mobile ? 'var(--dark-color)' : 'var(--gray-icon-color)'}
            />
          </SvgWrapper>
        ) : (
          <SvgWrapper onClick={history.goBack}>
            <SVGIcon name='icon-left' size={30} fill='var(--dark-color)' />
          </SvgWrapper>
        )}
        {hasTitle && <PageText mobile={mobile}>{screenName}</PageText>}
      </MobileInnerHeader>
      {children && <>{children}</>}
    </MobileHead>
  );
};

export default MobileTitleBar;
