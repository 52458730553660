import styled, { css } from 'styled-components';
import { Header2, Header4, Text1, Text2 } from '../../../styles';

const PageWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const MainList = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px;
        `
      : css`
          padding: 16px 0 0 0;
        `}
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h1`
  margin: 0;
  ${Header2}
  color: var(--dark-color);
`;
const ContainBoxTwo = styled.div`
  background: var(--white-color);
`;
const ListBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 2px 3px var(--pagination-box-shadow);
  border-radius: 6px;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
`;
const SubText = styled.h4`
  margin: 0 0 2px 0;
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  display: inline-block;
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1}
  color: var(--gray-color);
  margin: 0;
  max-width: 386px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const LearnMore = styled.a`
  display: inline-block;
  ${Text1}
  color: var(--primary-color);
  cursor: pointer;
  margin-left: 5px;
`;
const IconView = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const LoginDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  margin-left: 12px;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const LeftWrapper = styled.div``;
const RightWrapper = styled.div``;
const ModalWrapper = styled.div`
  margin-bottom: 16px;
`;
const ListWrappper = styled.div`
  width: 100%;
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: block;
`;
const IconWrapper = styled.div`
  flex: 0 0 auto;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightList = styled.div`
  display: flex;
  align-items: center;
`;
const ToogleBox = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 28px;
    left: 0px;
    margin: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 16px;
    width: 28px;
    background-color: var(--gray-border-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: var(--white-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-color);
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
  .slider.round {
    border-radius: 8px;
  }
  .slider.round:before {
    border-radius: 50%;
    box-shadow: 0 2px 4px var(--Switch-round);
  }
`;
const SubEvent = styled.div`
  display: flex;
  align-items: center;
`;
const CaptionContent = styled.div`
  display: flex;
  align-items: center;
`;
const ComingSoonImg = styled.img``;
const ComingSoon = styled.span`
  ${Text2}
  padding: 2px 10px;
  background-color: var(--popup-color-hover);
  border-radius: 14px;
  color: var(--sonic-silver);
  margin: 0 0 0 10px;
  display: inline-block;
`;

export {
  PageWrapper,
  MainList,
  Header,
  Title,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  LoginDetails,
  TitleText,
  TitleView,
  LeftWrapper,
  RightWrapper,
  ModalWrapper,
  ListWrappper,
  DropDownTitle,
  IconWrapper,
  HeaderWrapper,
  RightList,
  ToogleBox,
  Switch,
  ComingSoonImg,
  ComingSoon,
  SubEvent,
  LearnMore,
  CaptionContent
};
