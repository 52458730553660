import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Report Loader
 */
export const setReportLoader = createAction(Actions.SET_REPORT_LOADER);
/**
 * @desc Set Report Overview Data
 */
export const setOverviewReport = createAction(Actions.SET_OVERVIEW_REPORT);
/**
 * @desc Set Report Activity Data
 */
export const setActivityReport = createAction(Actions.SET_ACTIVITY_REPORT);
/**
 * @desc Set Activity Users List
 */
export const setActivityUsers = createAction(Actions.SET_ACTIVITY_USERS);
/**
 * @desc Set Activity Events List
 */
export const setActivityEvents = createAction(Actions.SET_ACTIVITY_EVENTS);
/**
 * @desc Update Activity Events List
 */
export const updateActivityEvents = createAction(Actions.UPDATE_ACTIVITY_EVENTS);
/**
 * @desc Update Activity Users List
 */
export const updateActivityUsers = createAction(Actions.UPDATE_ACTIVITY_USERS);
/**
 * @desc Set Report Status Change Data
 */
export const setStatusChangeReport = createAction(Actions.SET_STATUS_CHANGE_REPORT);
/**
 * @desc Set Report Call Summary Data
 */
export const setCallSummaryReport = createAction(Actions.SET_CALL_SUMMARY_REPORT);
/**
 * @desc Set Call Summary Users List
 */
export const setCallSummaryUsers = createAction(Actions.SET_CALL_SUMMARY_USERS);
/**
 * @desc Set Call Summary Events List
 */
export const setCallSummaryEvents = createAction(Actions.SET_CALL_SUMMARY_EVENTS);
/**
 * @desc Update Call Summary Events List
 */
export const updateCallSummaryEvents = createAction(Actions.UPDATE_CALL_SUMMARY_EVENTS);
/**
 * @desc Update Call Summary Users List
 */
export const updateCallSummaryUsers = createAction(Actions.UPDATE_CALL_SUMMARY_USERS);
