import styled from 'styled-components';

const MainView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
const ContainList = styled.div`
  text-align: center;
`;
const ImgView = styled.div``;
const ImgSmile = styled.img``;
const TitleText = styled.h3`
  margin: 30px 0 4px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-color);
`;
const CaptionText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray-color);
`;

export { MainView, ContainList, ImgView, ImgSmile, TitleText, CaptionText };
