import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AddIcon } from '../../assets/images/svg/addicon.svg';
import Input from '../../components/Input/Input';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/atom/Button/Button';
import { addUserItem, deleteUserItem, updateUserItem } from '../../actions/organizationActions';
import Validation from '../../components/Validation/Validation';
import AppLogo from '../../components/AppLogo';
import {
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  Label,
  LabelTitle,
  RowView,
  CloseView,
  // CloseViewTwo,
  InputView,
  AddView,
  AddText,
  // SpanText,
  ViewBtn,
  ListView,
  SkipBtn,
  LabelWrapper
} from './Styles';

const OnboardingStep3 = (props) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { loading, organizationItem } = organizationSelector;

  const dispatch = useDispatch();

  const onClickAdd = useCallback(() => {
    dispatch(addUserItem({ item: {} }));
  }, [dispatch]);

  const onChangeInput = useCallback(
    (key, item, value, index) => {
      item = {
        ...item,
        [key]: value
      };
      dispatch(updateUserItem({ item: item, index: index }));
    },
    [dispatch]
  );

  const onRemoveItem = useCallback(
    (index) => {
      dispatch(deleteUserItem({ index: index }));
    },
    [dispatch]
  );
  return (
    <ManeWrapper>
      <LoginContainer>
        <LogoWrapper>
          <AppLogo />
          <Title>Just a few quick things to set up your account…</Title>
        </LogoWrapper>
        <Form>
          <LabelWrapper>
            <Label>Step 3 to 3</Label>
          </LabelWrapper>
          <LabelTitle>Invite Members</LabelTitle>
          <ListView>
            {organizationItem?.invitedUsers?.map((item, index) => {
              return (
                <RowView key={`${index}`}>
                  <InputView>
                    <Input
                      isPrimarySmall={true}
                      title={index === 0 ? 'Email address' : ''}
                      placeholder='example@pixer.io'
                      onChange={(e) => {
                        onChangeInput('email', item, e.target.value, index);
                      }}
                    />
                  </InputView>
                  <InputView>
                    <Input
                      isPrimarySmall={true}
                      title={index === 0 ? 'full name (Optional)' : ''}
                      placeholder='Full Name'
                      onChange={(e) => {
                        onChangeInput('name', item, e.target.value, index);
                      }}
                    />
                  </InputView>
                  <CloseView
                    onClick={() => {
                      onRemoveItem(index);
                    }}>
                    <Icon name={'feather-x-circle'} fontSize='20' color='var(--gray-icon-color)' />
                  </CloseView>
                </RowView>
              );
            })}
            {props.error?.email && <Validation error={props.error?.email} />}
            <AddView onClick={onClickAdd}>
              <AddIcon />
              <AddText>
                Add another
                {/* <SpanText> or</SpanText> add many at once */}
              </AddText>
            </AddView>
          </ListView>

          <ViewBtn>
            <Button
              primary
              title='Get Started'
              loading={loading}
              onClick={(e) => {
                props.onSubmit(e);
              }}
            />
            <SkipBtn
              onClick={(e) => {
                props.onSubmit(e);
              }}>
              Skip
            </SkipBtn>
          </ViewBtn>
        </Form>
      </LoginContainer>
    </ManeWrapper>
  );
};

export default OnboardingStep3;
