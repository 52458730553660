import React, { useCallback, useEffect, useMemo } from 'react';
// import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SnackBar from '../../../components/SnackBar/SnackBar';
// import { isEmpty } from '../../../helpers/common';
import { getTwilioCharges } from '../../../services/twilioService';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { currencyWithDecimal, isEmpty } from '../../../helpers/common';
import {
  MainList,
  Title,
  MainWrap,
  Navigation,
  NavigationLeft,
  NavigationRight,
  TransactionsListWrapper,
  TransactionsList,
  CreditsIcon,
  ListDetails,
  ListOne,
  ListWrapper,
  ListTwo,
  ListDuration,
  DetailsRight,
  TimeDetail,
  RupeesDetail,
  LeftList
} from './styles';

const Transaction = () => {
  const organizationSelector = useSelector((state) => state.organization);
  const phoneCallSelector = useSelector((state) => state.phoneCall);
  const { charges } = phoneCallSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTwilioCharges(organization_id));
  }, [dispatch, organization_id]);
  const twilioCharges = useMemo(() => {
    const charge = charges?.filter((x) => !isEmpty(x?.totalPrice));
    return charge;
  }, [charges]);

  const getStarFormattedNumber = useCallback((string) => {
    if (isEmpty(string)) return;
    let subString = string?.slice(-4);
    let starString = '*** ***';
    return starString?.concat(subString);
  }, []);

  const renderCreditTransaction = useCallback((item, index) => {
    return (
      <>
        <LeftList>
          <CreditsIcon>
            <SVGIcon name='icon-wallet' fill='var(--gray-icon-color)' size={18} />
          </CreditsIcon>
          <ListDetails>
            <ListOne>Add Credits</ListOne>
            <ListWrapper>
              <ListTwo>by credit card ***78</ListTwo>
            </ListWrapper>
          </ListDetails>
        </LeftList>
        <DetailsRight>
          <TimeDetail>{moment(item?.dateCreated).fromNow()}</TimeDetail>
          <RupeesDetail>+ ${currencyWithDecimal(item?.totalPrice)}</RupeesDetail>
        </DetailsRight>
      </>
    );
  }, []);

  const renderDebitTransaction = useCallback(
    (item) => {
      const duration = moment.utc(Math.abs(item?.duration) * 1000).format(item?.duration > 3599 ? 'HH:mm:ss' : 'mm:ss');
      const toNumber = getStarFormattedNumber(item?.to);
      const fromNumber = getStarFormattedNumber(item?.from);
      return (
        <>
          <LeftList>
            <CreditsIcon>
              <SVGIcon name='icon-call' fill='var(--gray-icon-color)' size={18} />
            </CreditsIcon>
            <ListDetails>
              <ListOne>Call to {toNumber}</ListOne>
              <ListWrapper>
                <ListTwo>by calling {fromNumber}</ListTwo>
                <ListDuration>Duration: {duration}</ListDuration>
              </ListWrapper>
            </ListDetails>
          </LeftList>
          <DetailsRight>
            <TimeDetail>{moment(item?.dateCreated).fromNow()}</TimeDetail>
            <RupeesDetail fail>{`- $ ${currencyWithDecimal(item?.totalPrice)}`}</RupeesDetail>
          </DetailsRight>
        </>
      );
    },
    [getStarFormattedNumber]
  );

  return (
    <>
      <MainList>
        <MainWrap>
          <Navigation>
            <NavigationLeft
              onClick={() => {
                history.goBack();
              }}>
              <SVGIcon name='icon-big-left-arrow' fill='var(--gray-icon-color)' size={18} />
              <Title>Credit Transactions</Title>
            </NavigationLeft>
            <NavigationRight></NavigationRight>
          </Navigation>
          <TransactionsListWrapper>
            {twilioCharges?.map((item, index) => {
              return (
                <TransactionsList key={`${item?.id}_${index}`}>
                  {item?.type === 2 ? renderCreditTransaction(item) : renderDebitTransaction(item)}
                </TransactionsList>
              );
            })}
          </TransactionsListWrapper>
        </MainWrap>
      </MainList>
      <SnackBar />
    </>
  );
};

export default Transaction;
