import axios from 'axios';
import moment from 'moment';
import { join } from 'lodash';
import { process } from '@progress/kendo-data-query';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIErrorReason, isEmpty } from '../helpers/common';
import {
  setEmailLoader,
  setEmailAccounts,
  setEmailList,
  setEmailLabelsList,
  setEmailTemplates,
  setEmailDetail,
  setEmailSyncSettings,
  setEmailTemplateItem,
  setTotalEmailCount,
  setEmailListItem,
  deleteEmail,
  updateEmailItem
} from '../actions/emailActions';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import { EMAIL_LABEL_ID } from '../constants/constant';
import EmailCacheService from './emailCacheServices';
import EmailAccountCacheService from './emailAccountCacheServices';
import { getDashboardDetail } from './dashboardService';
import { trackAnalyticActivity } from './analyticsService';

let ajaxRequest = null;
let emailDetailAjaxRequest = null;
let emailAccountsAjaxRequest = null;

/**
 * @desc Lookup - Get Lookup List
 * @param {*} obj Data Obj
 */
export const getEmailList =
  (company_id, label_id, account_id, forceReSync = false) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return false;
      dispatch(setEmailLoader(true));

      //Set list from local cache
      const emails_local = await EmailCacheService.getInstance()?.getEmails();
      if (emails_local && emails_local.length > 0) {
        const accounts = await getAccountUnreadCount(emails_local);
        dispatch(setEmailAccounts(accounts));
        dispatch(setEmailLabelsList(getLabels(emails_local, dispatch)));
        dispatch(setEmailList(getLabelFilteredList(emails_local, label_id, account_id)));
      }

      const lastUpdatedTimeFromLocalStorage = await EmailCacheService.getInstance()?.getLastUpdatedTime();
      // const lastUpdatedTime = 0;
      let lastUpdatedTime = lastUpdatedTimeFromLocalStorage ? new Date(lastUpdatedTimeFromLocalStorage).getTime() : 0;
      if (forceReSync) lastUpdatedTime = 0;

      //Don't call api again within 30 seconds
      if (ajaxRequest) {
        ajaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
      }
      ajaxRequest = axios.CancelToken.source();
      const response = await axios.get(`${REACT_APP_APIURL}/Emails/${company_id}/LastUpdatedList/${lastUpdatedTime}`, {
        cancelToken: ajaxRequest.token
      });

      const { data: emailList, lastUpdatedOn, archived, isReloadData } = response.data;
      if (isReloadData) {
        //If get reload required from server side then we request again with force refresh
        dispatch(getEmailList(company_id, label_id, account_id, true));
      }
      if (lastUpdatedTime === 0) {
        await EmailCacheService.getInstance()?.clearTableData();
        await EmailCacheService.getInstance()?.setEmails(emailList);
      } else await EmailCacheService.getInstance()?.updateEmails(emailList, archived);
      await await EmailCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn);

      //Set list after update from server
      const emails = await EmailCacheService.getInstance()?.getEmails();
      const accounts = await getAccountUnreadCount(emails);
      dispatch(setEmailAccounts(accounts));
      dispatch(setEmailLabelsList(getLabels(emails, dispatch)));
      dispatch(setEmailList(getLabelFilteredList(emails, label_id, account_id)));

      return true;
    } catch (e) {
      console.log('ERROR', e);
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchEmailError(getAPIErrorReason(e) || 'Unable to get emails please try again', dispatch);
      return false;
    } finally {
      dispatch(setEmailLoader(false));
    }
  };

/**
 * @desc get emails by lookup ids
 */
export const getEmailsByLookup = (organization_id, lookupIds) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    if (lookupIds?.length === 0) return false;

    dispatch(setEmailLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Emails/${organization_id}/lookups`, lookupIds);
    if (response?.data) {
      await EmailCacheService.getInstance()?.updateEmails(response?.data, []);
      return true;
    }
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to fetch emails by lookup, please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

export const getLabelFilteredList = (list, labelId, accountId) => {
  if (!list) return [];
  let emailList = list;
  const labelInUpperCase = String(labelId).toUpperCase();
  if (accountId) emailList = emailList.filter((x) => x.emailAccountId === accountId);
  emailList = emailList.filter(
    (x) => x.labels.findIndex((y) => y.id === labelId || String(y.id).toUpperCase() === labelInUpperCase) !== -1
  );
  const filterResult = process(emailList, {
    sort: [{ field: 'dateTime', dir: 'desc' }]
  });
  return filterResult.data;
};

const searchEmailAddress = (participants, query) => {
  let emailIndex = participants?.findIndex(
    (x) => (x?.email && x?.email?.includes(query)) || (x?.name && x?.name?.includes(query))
  );
  if (emailIndex !== -1) return true;
  return false;
};

export const getSearchResult = async (searchText) => {
  if (!searchText) return null;
  let emailList = await EmailCacheService.getInstance()?.getEmails();
  searchText = searchText.trim().toLowerCase();
  emailList = emailList.filter(
    (x) =>
      x.subject?.trim().toLowerCase().includes(searchText) ||
      x.snippet?.trim().toLowerCase().includes(searchText) ||
      x.lookup?.name?.trim().toLowerCase().includes(searchText) ||
      searchEmailAddress(x?.participants, searchText)
  );
  return emailList;
};

const getLabels = (list, dispatch) => {
  const allLabels = [].concat.apply(
    [],
    list?.map((x) => x.labels)
  );
  let uniqeLabels = allLabels?.filter((item, index) => index === allLabels.findIndex((label) => label.id === item.id));
  uniqeLabels.forEach((label) => {
    label.unreadCount = list.filter(
      (x) => x.labels.findIndex((y) => y.id === label.id) !== -1 && x.labels.findIndex((y) => y.id === 'UNREAD') !== -1
    ).length;
  });
  const total_count = list.filter((x) => x.unread).length;
  dispatch(setTotalEmailCount(total_count));
  return uniqeLabels;
};

const getAccountUnreadCount = async (list) => {
  if (!list) return [];
  const email_accounts_local = await EmailAccountCacheService.getInstance()?.getEmailAccounts();
  email_accounts_local?.forEach((account) => {
    account.unreadCount = list.filter(
      (x) => x.emailAccountId === account.id && x?.labels?.findIndex((y) => y?.id === 'UNREAD') !== -1
    ).length;
  });
  return email_accounts_local;
};

/**
 * @desc Email - Get Email Item
 */
export const getEmailItem = (organization_id, email_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailDetail(null));
    dispatch(setEmailLoader(true));

    //Set detail from local db
    const emailItem = await EmailCacheService.getInstance()?.getItem(email_id);
    if (emailItem && emailItem?.messages) {
      const emailDetails = JSON.parse(JSON.stringify(emailItem));
      const filterResult = process(emailDetails?.messages, {
        sort: [{ field: 'date', dir: 'desc' }]
      });
      emailDetails.messages = filterResult?.data;
      emailDetails.lastMail = emailDetails.lastMsg || emailDetails.lastMail;
      dispatch(setEmailDetail(emailDetails));
    }

    if (emailDetailAjaxRequest) {
      emailDetailAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    emailDetailAjaxRequest = axios.CancelToken.source();

    const response = await axios.get(`${REACT_APP_APIURL}/Emails/${organization_id}/item/${email_id}`, {
      cancelToken: emailDetailAjaxRequest.token
    });
    const { data } = response;
    if (data) {
      if (data?.messages) {
        const filterResult = process(data?.messages, {
          sort: [{ field: 'date', dir: 'desc' }]
        });

        data.messages = filterResult?.data;
      }
      dispatch(setEmailDetail(data));
      return data;
    }
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to get email detail please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Email - Delete Email Item
 */
export const deleteEmailItem = (organization_id, email_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    await EmailCacheService.getInstance()?.deleteEmails([email_id]);
    dispatch(deleteEmail({ id: email_id }));
    const response = await axios.delete(`${REACT_APP_APIURL}/Emails/${organization_id}/item/${email_id}`);
    const { data } = response;
    if (data) {
      return true;
    }
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to delete email please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Email - Create Email
 */
export const createEmail = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    if (isEmpty(payload?.emailAccount)) {
      dispatchEmailError('From Field is Required', dispatch);
      return false;
    } else if (isEmpty(payload?.to)) {
      dispatchEmailError('To Field is Required', dispatch);
      return false;
    } else if (!payload?.subject || payload?.subject?.trim() === '') {
      dispatchEmailError('The Subject field is required', dispatch);
      return false;
    } else if (!payload?.body || payload?.body?.trim() === '') {
      dispatchEmailError('The Body field is required', dispatch);
      return false;
    }
    dispatch(setEmailDetail(null));
    dispatch(setEmailLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/Emails/${organization_id}/Create`, payload);
    const { message } = response?.data;
    if (response?.data) {
      dispatchEmailSuccess(message, dispatch);
      OrganizationPreferencesSingleton.getInstance().setDefaultEmailAccountId(payload?.emailAccount?.id);
      return true;
    }
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to create email please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Update Email Status
 * @param {*} organization_id, email_id, payload
 */
export const updateEmailStatus = (organization_id, email_ids, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const updatedEmails = await EmailCacheService.getInstance()?.updateEmailItem(email_ids, 'unread', payload.value);
    dispatch(setEmailListItem({ items: updatedEmails }));
    await dispatch(getDashboardDetail(organization_id));

    for (let i = 0; i < email_ids?.length; i++) {
      await axios.put(`${REACT_APP_APIURL}/Emails/${organization_id}/status/${email_ids[i]}`, payload);
    }
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to update Email please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Update Star Email Status
 * @param {*} organization_id, email_id, payload
 */
export const updateStarEmailStatus = (organization_id, email_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const updatedEmails = await EmailCacheService.getInstance()?.updateEmailItem([email_id], 'starred', payload.value);
    dispatch(setEmailListItem({ items: updatedEmails }));
    await dispatch(getDashboardDetail(organization_id));

    const response = await axios.put(`${REACT_APP_APIURL}/Emails/${organization_id}/favorite/${email_id}`, payload);
    const { data } = response;
    if (data) {
      return true;
    }
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to update star email status please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Get Email Accounts
 */
export const getEmailAccounts =
  (organization_id, forceRefresh = true) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!organization_id) return false;
      dispatch(setEmailLoader(true));

      let emailAccounts;
      if (!forceRefresh) {
        emailAccounts = await EmailAccountCacheService.getInstance()?.getEmailAccounts();
        if (emailAccounts && emailAccounts?.length > 0) {
          dispatch(setEmailAccounts(emailAccounts));
        }
      }
      const lastUpdatedTimeFromLocalStorage = await EmailAccountCacheService.getInstance()?.getLastUpdatedTime();
      // const lastUpdatedTime = 0;
      let lastUpdatedTime = lastUpdatedTimeFromLocalStorage ? new Date(lastUpdatedTimeFromLocalStorage).getTime() : 0;
      if (forceRefresh) lastUpdatedTime = 0;

      if (emailAccountsAjaxRequest) {
        emailAccountsAjaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
      }
      emailAccountsAjaxRequest = axios.CancelToken.source();
      const response = await axios.get(
        `${REACT_APP_APIURL}/settings/${organization_id}/emailaccounts/lastUpdatedList/${lastUpdatedTime}`,
        {
          cancelToken: emailAccountsAjaxRequest.token
        }
      );
      const { data: list, lastUpdatedOn, archived } = response.data;
      if (lastUpdatedTime === 0) {
        await EmailAccountCacheService.getInstance()?.clearTableData();
        await EmailAccountCacheService.getInstance()?.setEmailAccounts(list);
      } else await EmailAccountCacheService.getInstance()?.updateEmailAccounts(list, archived);
      await await EmailAccountCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn);

      //Set list after update from server
      const email_accounts = await EmailAccountCacheService.getInstance()?.getEmailAccounts();
      dispatch(setEmailAccounts(email_accounts));
      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchEmailError(getAPIErrorReason(e) || 'Unable to get Email accounts please try again', dispatch);
      return false;
    } finally {
      dispatch(setEmailLoader(false));
    }
  };
/**
 * @desc Delete Email Account for Settings
 */
export const deleteEmailAccount = (organization_id, emailAccountId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.delete(
      `${REACT_APP_APIURL}/settings/${organization_id}/emailaccounts/${emailAccountId}`
    );
    const { data } = response;
    const { message } = data || response;
    if (data) await dispatch(getEmailAccounts(organization_id));
    dispatchEmailSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to delete Email account please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Add SMTP Send Only Email Account in Settings
 */
export const addSendOnlyEmailAccount = (organization_id, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Settings/${organization_id}/EmailAccounts`, params);
    const { data } = response;
    if (data) {
      await dispatch(getEmailAccounts(organization_id));
      trackAddEmailAccountAnalyticActivity('smtp', response?.data?.email || data?.data?.details?.senderEmail);
      return data;
    }
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to add Email account please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc sync Email Accounts
 */
export const SyncEmailAccounts = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/settings/${organization_id}/emailaccounts/sync`);
    const { message } = response?.data;
    if (response?.data) {
      dispatchEmailSuccess(message, dispatch);
    }
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to get Email accounts please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

export const trackAddEmailAccountAnalyticActivity = (type, emailId) => {
  try {
    trackAnalyticActivity('settings - email: account added', { type: type, 'email id': emailId });
  } catch (e) {
    console.log('track settings - email: account added Error', e);
  }
};

/**
 * @desc Add SMTP Send Only Email Account in Settings
 */
export const testSMTPEmail = (organization_id, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/settings/${organization_id}/emailaccounts/testemail`,
      params
    );
    const { data } = response;
    const { message } = data || response;
    if (data) await dispatch(getEmailAccounts(organization_id));
    dispatchEmailSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to add Email account please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Add Alias Email Account in Settings
 */
export const addAliasEmailAccount = (organization_id, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Settings/${organization_id}/EmailAccounts/Alias`, params);
    const { data } = response;
    if (data) await dispatch(getEmailAccounts(organization_id));
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to add Email account please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Update Email Contact Sync Settings
 */
export const updateEmailSyncSettings = (organization_id, emailAccountId, syncType, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/Settings/${organization_id}/emailAccounts/${emailAccountId}/${syncType}`,
      params
    );
    const { data } = response;
    if (data) await dispatch(getEmailAccounts(organization_id));
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to update Email sync settings please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Get Email Sync Settings
 */
export const getEmailSyncSettings = (organization_id, emailAccountId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.get(
      `${REACT_APP_APIURL}/Settings/${organization_id}/EmailAccounts/${emailAccountId}/syncsettings`
    );
    const { data } = response;
    data && dispatch(setEmailSyncSettings(data));
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to get Email sync settings please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Update Email Sync Settings
 */
export const updateSyncSettings = (organization_id, emailAccountId, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/Settings/${organization_id}/emailAccounts/${emailAccountId}/syncsettings`,
      params
    );
    const { data } = response;
    const { message } = data || response;
    if (data) await dispatch(getEmailAccounts(organization_id));
    dispatchEmailSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to update Email sync settings please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

export const trackEmailTemplateActionAnalyticActivity = (action, subject, title) => {
  try {
    trackAnalyticActivity('settings - email template: action', {
      action: action,
      subject: subject ? String(subject).toLowerCase() : undefined,
      title: title ? String(title).toLowerCase() : undefined
    });
  } catch (e) {
    console.log('track settings - email template: action Error', e);
  }
};

export const trackEmailTemplateAnalyticActivity = (action, itemName, itemSub) => {
  try {
    trackAnalyticActivity(`settings - email template: ${action}`, {
      title: String(itemName).toLowerCase(),
      subject: String(itemSub).toLowerCase()
    });
  } catch (e) {
    console.log(`track settings - email template: ${action} Error`, e);
  }
};

/**
 * @desc Get Email Templates
 */
export const getEmailTemplates = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/emailTemplates/${organization_id}`);
    const { data } = response;
    data && dispatch(setEmailTemplates(data));
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to get Email templates please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Get Email Template Item
 */
export const getEmailTemplateItem = (organization_id, emailTemplateId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/emailTemplates/${organization_id}/item/${emailTemplateId}`);
    const { data } = response;

    data && dispatch(setEmailTemplateItem(data));
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to get Email template Item please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Update Email Template
 */
export const updateEmailTemplate = (organization_id, emailTemplateId, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/emailTemplates/${organization_id}/update/${emailTemplateId}`,
      params
    );
    const { data } = response;
    const { message } = data || response;

    data && (await dispatch(getEmailTemplates(organization_id)));
    dispatchEmailSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to update Email sync settings please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Create Email Template
 */
export const createEmailTemplate = (organization_id, params) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/emailTemplates/${organization_id}/create`, params);
    const { data } = response;
    const { message } = data || response;

    data && (await dispatch(getEmailTemplates(organization_id)));
    dispatchEmailSuccess(message, dispatch);
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to create Email template please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};

/**
 * @desc Delete Email Template for Settings
 */
export const deleteEmailTemplate = (organization_id, emailTemplateId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setEmailLoader(true));
    const response = await axios.delete(
      `${REACT_APP_APIURL}/emailTemplates/${organization_id}/item/${emailTemplateId}`
    );
    const { data } = response;
    data && (await dispatch(getEmailTemplates(organization_id)));
    return true;
  } catch (e) {
    dispatchEmailError(getAPIErrorReason(e) || 'Unable to delete Email template please try again', dispatch);
    return false;
  } finally {
    dispatch(setEmailLoader(false));
  }
};
const getFormatedString = (data) => {
  const newData = data?.map((x) => {
    const email = x?.lookup ? x?.lookup?.email : x?.email;
    const name = x?.lookup ? x?.lookup?.name : x?.name;
    return `${name} < <a href='mailto:${email}'>${email}</a> >`;
  });
  return join(newData);
};

export const setReplyMailItem = (item) => async (dispatch) => {
  if (item) {
    const messageId = item?.replyToMessageId || item?.threadId;
    let subject = `Re: ${item?.subject}`;
    let replyTo = [];
    const lastMail = item?.lastMail || item?.lastMsg;
    const sentMessage = lastMail?.labelIds?.find((x) => x === EMAIL_LABEL_ID.SENT || x === 'Sent Items');
    if (sentMessage) {
      replyTo = lastMail?.to;
    } else if (!isEmpty(lastMail?.replyTo)) {
      replyTo = lastMail?.replyTo;
    } else {
      replyTo = [lastMail?.from];
    }

    const lookupIds = replyTo?.map((x) => {
      let newItem;
      if (x?.lookup) {
        newItem = { id: x?.lookup?.id, email: x?.lookup?.email || x?.email };
      } else {
        newItem = { email: x?.email };
      }
      return newItem;
    });

    const date = `${moment(item?.lastMail?.date).format('ddd, MMM DD, YYYY')} at ${moment(item?.lastMail?.date).format(
      ' h:mm a'
    )}`;
    const fromEmail = item?.lastMail?.from?.email;
    const fromName = item?.lastMail?.from?.name;

    // eslint-disable-next-line max-len
    const quotedTextHeader = `On ${date}, ${fromName || ''} &lt;<a href='mailto:${fromEmail || ''}'>${
      fromEmail || ''
    }</a>&gt; wrote:`;
    const blockquoteStyle = 'margin:0;border-left: #D6D6D6 1px solid;padding-left: 10px;';
    const message = item?.lastMail?.body ? item?.lastMail?.body : '';
    // eslint-disable-next-line max-len
    const mailReplyBody = `<br /><br /><div>${quotedTextHeader}</div><BLOCKQUOTE style="${blockquoteStyle}">${getQuotedText(
      message
    )}</BLOCKQUOTE>`;

    dispatch(updateEmailItem({ propsName: 'lookupIds', value: lookupIds }));
    dispatch(updateEmailItem({ propsName: 'to', value: replyTo }));
    const account = { ...item?.account, label: `${item?.account?.name} < ${item?.account?.email} >` };
    dispatch(updateEmailItem({ propsName: 'messageId', value: messageId }));
    dispatch(updateEmailItem({ propsName: 'emailAccount', value: account }));
    dispatch(updateEmailItem({ propsName: 'signature', value: account?.signature }));
    dispatch(updateEmailItem({ propsName: 'subject', value: subject }));
    dispatch(updateEmailItem({ propsName: 'quotedBody', value: mailReplyBody }));
  }
};

export const setForwardMailItem = (item) => async (dispatch) => {
  if (item) {
    const email = item?.lastMail?.from.lookup ? item?.lastMail?.from?.lookup?.email : item?.lastMail?.from?.email;
    const name = item?.lastMail?.from.lookup ? item?.lastMail?.from?.lookup?.name : item?.lastMail?.from?.name;
    const from = `${name} < <a href='mailto:${email}'>${email}</a> >`;
    const date = `${moment(item?.lastMail?.date).format('ddd, MMM DD, YYYY')} at ${moment(item?.lastMail?.date).format(
      ' h:mm a'
    )}`;
    const to = getFormatedString(item?.lastMail?.to);
    const cc = getFormatedString(item?.lastMail?.cc);
    const bcc = getFormatedString(item?.lastMail?.bcc);

    const body = `<br /><br />
    ---------- Forwarded message ---------<br />
    From: ${from}<br />
    Date: ${date}<br />
    Subject: ${item?.lastMail?.subject}<br />
    ${!isEmpty(item?.lastMail?.to) ? `To: ${to}<br />` : ''}
    ${!isEmpty(item?.lastMail?.cc) ? `Cc: ${cc} <br />` : ''}
    ${!isEmpty(item?.lastMail?.bcc) ? `Bcc: ${bcc} <br />` : ''}
    <br /><br />
    ${item?.lastMail?.body ? item?.lastMail?.body : ''}
    `;
    const messageId = item?.replyToMessageId || item?.threadId;
    const account = { ...item?.account, label: `${item?.account?.name} < ${item?.account?.email} >` };
    dispatch(updateEmailItem({ propsName: 'messageId', value: messageId }));
    dispatch(updateEmailItem({ propsName: 'emailAccount', value: account }));
    dispatch(updateEmailItem({ propsName: 'signature', value: account?.signature }));
    dispatch(updateEmailItem({ propsName: 'subject', value: item?.subject }));
    dispatch(updateEmailItem({ propsName: 'body', value: body }));
  }
};

function dispatchEmailError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchEmailSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}

const getQuotedText = (bodyText) => {
  if (bodyText) return bodyText.replace(/(?:\r\n|\r|\n)/g, '<br />');
  return bodyText;
};
