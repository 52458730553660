import { useMemo } from 'react';
import useMobileDevice from '../../../../../hooks/useMobileDevice';
import Avtar from '../../../../../components/Avtar/Avtar';
import { getGravatarURL } from '../../../../../helpers/common';
import {
  MainList,
  ActivityUpperBlock,
  IconList,
  AllList,
  RightList,
  UserText,
  UserRecive,
  TimeText,
  ThraedInner,
  UserWrapperBlock
} from '../styles';

const StatusActivityItem = ({ item, actionIcon, createdOn, isOpenThread, onLookupClick }) => {
  const mobile = useMobileDevice();
  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);
  return (
    <>
      <MainList key={item?.id}>
        <ActivityUpperBlock isOpenThread={isOpenThread}>
          {isOpenThread ? (
            item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
          ) : (
            <IconList>{actionIcon}</IconList>
          )}
          <AllList>
            <RightList mobile={mobile} isOpenThread={isOpenThread}>
              {isOpenThread && item?.lookup && (
                <ThraedInner
                  onClick={() => {
                    onLookupClick(item?.lookup);
                  }}>
                  <UserText>{item?.lookup?.name}</UserText>
                </ThraedInner>
              )}
              <UserWrapperBlock mobile={mobile}>
                <UserText mobile={mobile} isOpenThread={isOpenThread}>
                  {item?.assignedUser?.name}
                </UserText>
                <UserRecive mobile={mobile} isOpenThread={isOpenThread}>
                  {String(item?.actionName || '').toLowerCase()}
                </UserRecive>
                <UserText mobile={mobile} isOpenThread={isOpenThread}>
                  {item?.description}
                </UserText>
                <TimeText mobile={mobile} title={createdOn?.tooltip} isOpenThread={isOpenThread}>
                  {createdOn?.absolute}
                </TimeText>
              </UserWrapperBlock>
            </RightList>
          </AllList>
        </ActivityUpperBlock>
      </MainList>
    </>
  );
};

export default StatusActivityItem;
