import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/atom/Button/Button';
import { addLookupTag } from '../../../../actions/lookupActions';
import { MainContact, TitleText, InputView, BtnView } from './styles';

const CreateNewTag = (props) => {
  const [tagName, setTagName] = useState('');
  const dispatch = useDispatch();
  const onChangeInput = useCallback((e) => {
    e.preventDefault();
    setTagName(e.target.value);
  }, []);

  const handleAddNewTag = useCallback(
    (item) => {
      dispatch(addLookupTag({ item: item }));
      props.handleCloseModal();
      if (props.onSave) props.onSave(item);
    },
    [dispatch, props]
  );

  return (
    <MainContact>
      <TitleText>New Tag</TitleText>
      <InputView>
        <Input
          isPrimarySmall={true}
          type='text'
          autoFocus={true}
          id='First-Name'
          name='Name'
          placeholder='Name'
          title='Please enter tag name'
          value={tagName}
          onChange={onChangeInput}
        />
      </InputView>
      <BtnView>
        <Button
          onClick={() => {
            handleAddNewTag(tagName);
          }}
          title='Ok'
        />
        <Button onClick={props.handleCloseModal} secondary title='Cancel' />
      </BtnView>
    </MainContact>
  );
};

export default CreateNewTag;
