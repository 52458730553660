import styled from 'styled-components';
import { Header1 } from '../../styles';

const WelcomeWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
const WelcomeMain = styled.div`
  padding: 80px 20px 60px 80px;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
`;

const SalescampLogo = styled.div`
  cursor: pointer;
`;
const WelcomeMainText = styled.span`
  max-width: 350px;
  width: 100%;
  display: block;
  margin: 36px 0px 40px 0px;
  ${Header1}
`;
const ButtonMain = styled.div`
  max-width: 219px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WelcomeMainImage = styled.div`
  text-align: right;
`;
const ImgWelcome = styled.img``;

export { WelcomeMain, SalescampLogo, WelcomeMainText, ButtonMain, WelcomeMainImage, ImgWelcome, WelcomeWrapper };
