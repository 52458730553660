import styled from 'styled-components';
import { Header4, Text2 } from '../../../../styles';

const ModalHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const ModalHeaderTitel = styled.span`
  color: var(--dark-color);
  ${Header4}
  margin-left: 12px;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const RightWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const MeetingInputWrapperTwo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  width: 100%;
  svg {
    margin-right: 16px;
    margin-top: 6px;
  }
  textarea {
    height: 200px;
    ::placeholder {
      ${Text2}
      color: var(--gray-disable-color);
    }
  }
`;
const MeetingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
const ErrorText = styled.div`
  margin-top: 8px;
  text-align: center;
`;
const NumberDropDown = styled.div`
  padding: 12px 16px 0px 52px;
`;
const Footer = styled.div`
  padding: 16px;
  border-top: 1px solid var(--gray-border-color);
`;
const MainForm = styled.form``;
const BtnWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const TextArea = styled.textarea`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  width: 100%;
  resize: none;
  outline: none;
  padding: 7px 12px;
  font-family: var(--primary-font-stack);
  font-weight: 500;
  &:focus {
    margin: 0 -1px;
    border: 2px solid var(--primary-color);
    padding: 6px 12px;
  }
`;

export {
  ModalHeader,
  ModalHeaderTitel,
  LeftWrapper,
  RightWrapper,
  MeetingInputWrapperTwo,
  Footer,
  MainForm,
  BtnWrapper,
  NumberDropDown,
  TextArea,
  MeetingWrap,
  ErrorText
};
