import styled, { css } from 'styled-components';
import { Header2, Header3, Header4, Text1 } from '../../../styles';

const IntegrationsWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px 16px 16px;
        `
      : css`
          padding: 24px 24px 0 24px;
        `}
`;
const Heading = styled.h1`
  ${Header2}
  color: var(--dark-color);
  margin: 0 0 12px 0;
`;
const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.mobile
      ? css`
          margin: -12px;
        `
      : css`
          margin: 0 -12px;
        `}
`;
const BlockWrapper = styled.div`
  width: 100%;
  padding: 12px;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 25%;
        `}
`;
const Block = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 24px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  svg {
    margin-bottom: 24px;
    height: 50px;
  }
  :hover {
    border-color: var(--spanish-gray);
  }
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--white-color);
    `}
`;
const Title = styled.h2`
  ${Header4}
  color:var(--dark-color);
  margin: 0 0 6px 0;
`;
const Para = styled.p`
  margin: 0 9px;
  ${Text1}
  color: var(--gray-color);
`;
const SyncWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
`;
const Sync = styled.h3`
  ${Header3}
  color:var(--dark-color);
  margin: 0 9px 0 0;
`;
const SegmentView = styled.div`
  cursor: default;
`;
const TitleViewlist = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    width: 19px;
    height: 19px;
    cursor: pointer;
  }
`;
const TitleText = styled.span`
  display: inline-block;
  margin-left: 12px;
  ${Header4};
  color: var(--dark-color);
`;
const FormList = styled.form`
  padding: 16px;
`;
const AllList = styled.div``;
const Ptext = styled.p`
  margin: 0;
  ${Text1};
  color: #000;
`;
const TextSegment = styled.div`
  display: flex;
  align-items: center;
`;
const FirstBox = styled.div`
  margin-bottom: 16px;
`;
const BtnView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
`;
const SecondBox = styled.div``;
const ApiSpan = styled.span`
  ${Text1}
  color: var(--disable-color);
`;
const IconView = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 12px;
`;
const BottomView = styled.div`
  border-top: 1px solid var(--Platinum-color);
  padding: 16px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Input = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 12px;
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  width: 300px;
`;

export {
  IntegrationsWrapper,
  Heading,
  BlogWrapper,
  BlockWrapper,
  Block,
  Title,
  Para,
  SyncWrapper,
  Sync,
  SegmentView,
  TitleViewlist,
  TitleText,
  FormList,
  AllList,
  Ptext,
  FirstBox,
  TextSegment,
  BtnView,
  SecondBox,
  ApiSpan,
  IconView,
  BottomView,
  HeaderWrapper,
  Input
};
