import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Activity Loader
 */
export const setActivityLoader = createAction(Actions.SET_ACTIVITY_LOADER);

/**
 * @desc Clear Activity Data
 */
export const clearActivityData = createAction(Actions.CLEAR_ACTIVITY_DATA);
/**
 * @desc Clear Activity Data
 */
export const setActivityItem = createAction(Actions.SET_ACTIVITY_ITEM);
/**
 * @desc Update Activity Item
 */
export const updateActivityItem = createAction(Actions.UPDATE_ACTIVITY_ITEM);
/**
 * @desc Update Activity Item
 */
export const setActivityValidationError = createAction(Actions.SET_ACTIVITY_VALIDATION_ERROR);
/**
 * @desc Clear Activity Item
 */
export const clearActivityItem = createAction(Actions.CLEAR_ACTIVITY_ITEM);
/**
 * @desc Add Attendees
 */
export const addAttendeesItem = createAction(Actions.ADD_ATTENDEES_ITEM);
/**
 * @desc Update Attendees
 */
export const updateAttendeesItem = createAction(Actions.UPDATE_ATTENDEES_ITEM);
/**
 * @desc Delete Attendees
 */
export const deleteAttendeesItem = createAction(Actions.DELETE_ATTENDEES_ITEM);
/**
 * @desc Set Activity By Lookup
 */
export const setActivityByLookup = createAction(Actions.SET_ACTIVITY_BY_LOOKUP);
/**
 * @desc Add Activity Comment Item
 */
export const addActivityCommentItem = createAction(Actions.ADD_ACTIVITY_COMMENT);
/**
 *
 * @desc Set Activity List
 */
export const setActivityList = createAction(Actions.SET_ACTIVITY_LIST);
/**
 *
 * @desc Set Task Activity List
 */
export const setTaskActivityList = createAction(Actions.SET_TASK_ACTIVITY_LIST);
/**
 *
 * @desc Set Activity Task Count
 */
export const setActivityTaskCount = createAction(Actions.SET_TASK_COUNT);

/**
 *
 * @desc Update Task Activity Item
 */
export const updateTaskActivityItem = createAction(Actions.UPDATE_TASK_ACTIVITY_ITEM);

/**
 * @desc Update Comment Item
 */
export const updateCommentItem = createAction(Actions.UPDATE_COMMENT_ITEM);

/**
 * @desc Delete Comment Item
 */
export const deleteCommentItem = createAction(Actions.DELETE_ACTIVITY_COMMENT);

/**
 * @desc Update Lookup Activity Item
 */
export const updateLookupActivityItem = createAction(Actions.UPDATE_LOOKUP_ACTIVITY_ITEM);

/**
 *
 * @desc Set activity list which have comments and comment is open
 */
export const setOpenCommentActivityList = createAction(Actions.SET_OPEN_COMMENT_ACTIVITY_LIST);
