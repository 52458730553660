import styled, { css } from 'styled-components';
import { SubHeader3 } from '../../styles';

const HeaderWrapper = styled.div`
  padding: 24px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 0 24px;
        `}
`;
const HeaderTitle = styled.h2`
  ${SubHeader3}
  margin:0;
  margin-left: 16px;
`;
const Header = styled.div`
  align-items: center;
  display: flex;
`;

export { HeaderWrapper, HeaderTitle, Header };
