import styled, { css } from 'styled-components';
import { isMACApp } from '../../../global/Helper';
import OrgNavbar from '../../../assets/images/svg/navbar_arrow.svg';

const OrganizationMain = styled.div`
  min-width: 58px;
  height: 100%;
  padding-top: 15px;
  background-color: var(--organize-navbar-bg);
  ${() =>
    isMACApp() &&
    css`
      padding-top: 40px;
      min-width: 78px;
    `}
  :hover {
    .pluse-icon {
      opacity: 1;
    }
  }
`;
const OrganizationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const OrganizeListItem = styled.li`
  height: 38px;
  width: 38px;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 26px;
  padding: 2px;
  position: relative;
  z-index: 1;
  border: 2px solid transparent;
  transition: all 300ms;
  :last-child {
    margin-bottom: 0;
  }
  :hover {
    border-color: var(--titlebar-bg);
  }
  &.active {
    border-color: var(--white-color);
  }
  ${(props) =>
    props.PluseIcon &&
    css`
      background-color: var(--nav-dark-color);
    `}
  &.pluse-icon {
    opacity: 0;
  }
`;
const PluseOrganizeIcon = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrganizeItemBlock = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
`;
const OrganizeImg = styled.img`
  height: 100%;
  width: 100%;
`;
const OranizationWrap = styled.div`
  position: relative;
`;
const ToggleOrgNavbar = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 40px;
  right: -27px;
  border-radius: 8px;
  background: url(${OrgNavbar});
  width: 28px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.hasNavBarExpiredText &&
    css`
      bottom: 80px;
    `}
`;

export {
  OrganizationMain,
  OrganizationList,
  OrganizeListItem,
  OrganizeItemBlock,
  OrganizeImg,
  PluseOrganizeIcon,
  ToggleOrgNavbar,
  OranizationWrap
};
