import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/atom/Button/Button';
import DropDown from '../../components/DropDown/DropDown';
import { currencyList } from '../../data/raw';
import { updateOrganizationItem } from '../../actions/organizationActions';
import AppLogo from '../../components/AppLogo';
import {
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  Label,
  LabelTitle,
  TabList,
  LabelTwo,
  SelectCardList,
  CardView,
  CompantText,
  IconView,
  RadioBtn,
  CheckmarkLabel,
  GroupIcon,
  LabelWrapper,
  DropWrapper,
  DropLabel
} from './Styles';

const OnboardingStep2 = (props) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organizationItem } = organizationSelector;

  const dispatch = useDispatch();

  const onChangeInput = useCallback(
    (propsName, value) => {
      dispatch(updateOrganizationItem({ propsName, value }));
    },
    [dispatch]
  );

  const onClickNext = useCallback(
    (e) => {
      e.preventDefault();
      props.gotoNext();
    },
    [props]
  );
  return (
    <ManeWrapper>
      <LoginContainer>
        <LogoWrapper>
          <AppLogo />
          <Title>Just a few quick things to set up your account…</Title>
        </LogoWrapper>
        <Form>
          <LabelWrapper>
            <Label>Step 2 to 3</Label>
          </LabelWrapper>
          <LabelTitle>Invite Members</LabelTitle>
          <DropWrapper>
            <DropLabel>Chose your currency</DropLabel>
            <DropDown
              options={currencyList}
              labelField={'name'}
              valueField={'value'}
              value={organizationItem?.currency}
              placeholder={'USD - $'}
              onChange={(item) => {
                onChangeInput('currency', item?.value);
              }}
            />
          </DropWrapper>
          <TabList>
            <LabelTwo>Who is your customer ?</LabelTwo>
            <SelectCardList>
              <CardView
                label
                onClick={() => {
                  onChangeInput('customerType', 1);
                }}>
                <RadioBtn
                  type='radio'
                  name='radio'
                  checked={organizationItem?.customerType === 1}
                  onChange={() => {}}
                />
                <CheckmarkLabel span>
                  <IconView>
                    <Icon name='icon-check' fontSize='17' color='var(--primary-color)' />
                  </IconView>
                  <Icon name='icon-account' fontSize='28' color='#A160FB' />
                  <CompantText>Companies</CompantText>
                </CheckmarkLabel>
              </CardView>
              <CardView
                label
                onClick={() => {
                  onChangeInput('customerType', 2);
                }}>
                <RadioBtn
                  type='radio'
                  name='radio'
                  checked={organizationItem?.customerType === 2}
                  onChange={() => {}}
                />
                <CheckmarkLabel span>
                  <IconView>
                    <Icon name='icon-check' fontSize='17' color='var(--primary-color)' />
                  </IconView>
                  <Icon name='icon-user' fontSize='28' color='#58A39A' />
                  <CompantText>Individuals</CompantText>
                </CheckmarkLabel>
              </CardView>
              <CardView
                label
                onClick={() => {
                  onChangeInput('customerType', 3);
                }}>
                <RadioBtn
                  type='radio'
                  name='radio'
                  checked={organizationItem?.customerType === 3}
                  onChange={() => {}}
                />
                <CheckmarkLabel span>
                  <IconView>
                    <Icon name='icon-check' fontSize='17' color='var(--primary-color)' />
                  </IconView>
                  <GroupIcon>
                    <Icon name='icon-account' fontSize='28' color='#A160FB' />
                    <Icon name='icon-pluse' fontSize='10' color='rgba(0, 0, 0, 0.4)' marginAll='0 5' />
                    <Icon name='icon-user' fontSize='28' color='#58A39A' />
                  </GroupIcon>
                  <CompantText>Both</CompantText>
                </CheckmarkLabel>
              </CardView>
            </SelectCardList>
          </TabList>
          <Button primary title='Next' onClick={onClickNext} />
        </Form>
      </LoginContainer>
    </ManeWrapper>
  );
};

export default OnboardingStep2;
