import { combineReducers } from 'redux';
import moment from 'moment';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const goalLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_GOAL_LOADER
});

const initialGoalState = [];
const goalListReducer = createReducerOrig(initialGoalState, (builder) => {
  builder
    .addCase(Actions.SET_GOAL_LIST, (state = initialGoalState, action) => {
      return action.payload;
    })
    .addCase(Actions.DELETE_GOAL_ITEM, (state, action) => {
      const goalList = current(state);
      let list = JSON.parse(JSON.stringify(goalList));
      if (list && list?.length > 0) {
        const goalIndex = list?.findIndex((x) => x?.id === action.payload.id);
        if (goalIndex !== -1) {
          list.splice(goalIndex, 1);
        }
        return list;
      }
    });
});

const initialGoalItemState = {
  name: '',
  type: '',
  target: '',
  duration: 7,
  startDate: moment().format('YYYY-MM-DD'),
  users: []
};
const createGoalReducer = createReducerOrig(initialGoalItemState, (builder) => {
  builder
    .addCase(Actions.SET_GOAL_ITEM, (state = initialGoalItemState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_GOAL_ITEM, (state, action) => {
      const goal = { ...state };
      goal[action.payload.propsName] = action.payload.value;
      return { ...goal };
    })
    .addCase(Actions.CLEAR_GOAL_ITEM, () => {
      const initialGoalState = JSON.parse(JSON.stringify(initialGoalItemState));
      return initialGoalState;
    });
});

const goalReducer = combineReducers({
  loading: goalLoaderReducer,
  list: goalListReducer,
  goalItem: createGoalReducer
});

export default goalReducer;
