const { default: styled } = require('styled-components');
const { Text1, Text2, Header4 } = require('../../styles');

const FirstRow = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--Platinum-color);
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const HeadTitle = styled.h3`
  ${Header4};
  color: var(--nav-dark-color);
  margin: 0;
  margin-left: 12px;
`;
const SeconView = styled.div`
  padding: 8px 16px 6px 16px;
`;
const LinkTag = styled.a`
  text-decoration: none;
  display: block;
`;
const SecondRow = styled.div`
  display: flex;
  margin-bottom: 2px;
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const TextView = styled.div`
  margin-left: 12px;
`;
const HeadCaption = styled.h3`
  ${Text1};
  color: var(--dark-color);
  margin: 0;
  margin-bottom: 4px;
`;
const CaptionText = styled.span`
  ${Text2};
  color: var(--gray-color);
`;
const IconView = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;
const FeedbackModal = styled.div`
  display: flex;
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;

export {
  IconView,
  CaptionText,
  HeadCaption,
  TextView,
  SecondRow,
  LinkTag,
  HeadTitle,
  RightView,
  FirstRow,
  SeconView,
  FeedbackModal
};
