import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import staticHomeRoutes from '../../routes/static-home-routes';
import StaticPageNotFound from '../StaticPageNotFound';
import { ManeWrapper, RightWrapper } from './styles';

export default function StaticHome() {
  const routeComponents = staticHomeRoutes.map((r, i) => {
    return <Route exact key={i} {...r} />;
  });

  return (
    <ManeWrapper>
      <Navbar />
      <RightWrapper className='resizeWrapper'>
        <Switch>
          <Redirect exact path='/static' to='/static/inbox2' />
          {routeComponents}
          <Route component={StaticPageNotFound} />
        </Switch>
      </RightWrapper>
    </ManeWrapper>
  );
}
