import moment from 'moment';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import LocalDbService, { ACTIVITIES_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton;
let organizationId;

export default class ActivityCacheService extends LocalDbService {
  constructor(props) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = ACTIVITIES_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (org) {
      if (!singleton || organizationId !== org.id) {
        organizationId = org.id;
        singleton = new ActivityCacheService({ organizationId: org.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getActivities() {
    const result = await this.get();
    return result;
  }

  async setActivities(value) {
    if (!value) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async updateActivity(item) {
    if (!item) return false;
    const result = await this.addBulk([item]);
    return result;
  }

  async updateActivities(newList, archivedList) {
    const result = await this.syncList(newList, archivedList);
    return result;
  }

  async getLastUpdatedTime() {
    const result = await super.getLastUpdatedTime(UPDATE_ON_ID.ACTIVTIES);
    return result;
  }

  async updateActivityItem(activityId, key, value) {
    if (!activityId || !key) return;
    const activity = await this.getItem(activityId);
    if (activity) {
      activity[key] = value;
      await this.addBulk([activity]);
      return activity;
    }
  }

  async setLastUpdatedTime(value) {
    const result = await super.setLastUpdatedTime(UPDATE_ON_ID.ACTIVTIES, value);
    return result;
  }

  async getActivityItem(activityId) {
    if (!activityId) return;
    const activity = await this.getItem(activityId);
    if (activity) {
      if (activity.time) {
        activity.time = moment(activity.time).format('HH:mm');
      }
      if (activity.toTime) {
        activity.toTime = moment(activity.toTime).format('HH:mm');
      }
      return activity;
    }
  }
}
