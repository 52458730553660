import styled, { css } from 'styled-components';
import { ACTIVITY_ACTION } from '../../../../constants/constant';
import { Header2, Header4, SubHeader3, Text1, Text2 } from '../../../../styles';

const TaskWrapper = styled.div`
  padding: 15px 20px;
  cursor: default;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const HeaderTitel = styled.h2`
  margin: 0;
  ${Header2}
  color:var(--dark-color);
`;
const ActivityWrapper = styled.div`
  margin-bottom: 60px;
  margin-top: 24px;
`;
const ActivityTitel = styled.h3`
  margin: 0 0 8px 0;
  color: var(--primary-color);
  ${SubHeader3}
`;
const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const TasksListingTitlebar = styled.div`
  display: flex;
  align-items: end;
  flex-basis: 62%;
  margin: 0 auto auto;
  padding-right: 12px;
  flex-grow: 1;
  max-width: 100%;
  margin-left: 32px;
`;
const TimelineView = styled.div`
  background: var(--maximum-yellow-red);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;
const LabelInput = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 8px;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const TasksListingTitleWp = styled.div`
  width: 95%;
`;
const TasksH3 = styled.h3`
  margin: 0;
  ${Header4}
  color:var(--dark-color);
`;
const TaskPara = styled.p`
  margin: 0;
  ${Text1}
  color:var(--gray-color);
`;
const TasksListingTimelog = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 0.4;
  max-width: 100%;
  svg {
    margin: 0 0 auto;
  }
`;
const TaksTimelogList = styled.div`
  margin: 0 10px;
`;
const TimeSpan = styled.span`
  ${SubHeader3}
  color:var(--gray-color);
  display: block;
`;
const TasksListingUsers = styled.div`
  margin-right: 35px;
  cursor: pointer;
`;
const TasksListingMore = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const TasksLookupName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const TasksPerson = styled.span`
  ${Text1}
  color:var(--dark-color);
  margin-left: 10px;
`;
const PlusIcon = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
`;
const InputWrapper = styled.div``;
const InputWrapperModal = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  margin-left: 16px;
  :last-child {
    margin-bottom: 6px;
  }
`;
const InputType = styled.div`
  position: relative;
  margin-bottom: 13px;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    right: 16px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    right: 16px;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputSpan = styled.span`
  ${Text1}
  color:var(--dark-color);
  margin-left: 10px;
  width: 82px;
`;
const AvtarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const ActivityBlock = styled.div`
  display: flex;
  flex: 0 1 auto;
  margin-bottom: 24px;
`;
const ActivityNoUpcoming = styled.span`
  display: block;
  ${Text1}
  color:var(--gray-disable-color);
`;
const ModalHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.isNotShowBorder &&
    css`
      border-bottom: none;
    `}
`;
const ModalHeaderTitel = styled.span`
  color: var(--dark-color);
  ${Header4}
  margin-left: 12px;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const RightWrapper = styled.div`
  cursor: pointer;
  height: 20px;
`;
const MeetingInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 12px;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: flex-start;
    `}
`;
const MeetingInputWrapperTwo = styled.div`
  display: inline-flex;
  padding: 0 16px;
  width: 100%;
  margin-bottom: 16px;
  svg {
    margin-right: 16px;
    margin-top: 6px;
  }
  .snow {
    border: 1px solid var(--gray-border-color);
    border-radius: 4px;
    font-family: var(--primary-font-stack);
    min-height: 213px;
    height: 213px;
    overflow-x: hidden;
    width: 100%;
    ::placeholder {
      font-family: var(--primary-font-stack);
    }
    .ql-snow {
      border: none;
      padding: 8px 12px;
    }
    .ql-editor {
      padding: 0;
      overflow-x: auto;
    }
    .ql-toolbar {
      border-bottom: 1px solid var(--gray-border-color);
      .ql-active {
        color: var(--primary-color);
      }
      .ql-formats {
        margin-right: 0;
        :first-child {
          margin-right: 12px;
          ${(props) =>
            props.mobile &&
            css`
              float: left;
            `}
        }
        :nth-child(2) {
          ${(props) =>
            props.mobile &&
            css`
              display: flex;
            `}
        }
      }
      button {
        padding: 0;
        height: 20px;
        width: 20px;
        margin-right: 12px;
        svg {
          display: none;
        }
      }
      .ql-expanded .ql-picker-label {
        color: var(--primary-color);
      }
      .ql-picker-label {
        svg {
          display: none;
        }
      }
      .ql-picker-label {
        border-radius: 4px;
      }

      .ql-picker-options {
        border-radius: 4px;
        margin-top: 6px;
        border-color: var(--gray-border-color);
        box-shadow: 0 3px 10px var(--menu-modal);
      }
    }
    .ql-container {
      ${(props) =>
        props.mobile
          ? css`
              height: 154px;
            `
          : css`
              height: 170px;
            `}
    }
  }
`;
const NoteInputWrapper = styled.div`
  margin-top: -16px;
  .snow {
    background-color: var(--yellow-lite-color);
    border: 1px solid var(--yellow-lite-border);
    font-family: var(--primary-font-stack);
    width: 100%;
    height: calc(100vh - 70vh);
    display: flex;
    flex-direction: column;
    ::placeholder {
      font-family: var(--primary-font-stack);
    }
    .ql-snow {
      border: none;
    }
    .ql-editor {
      padding: 0;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      flex: 1 0 0;
    }
    .ql-toolbar {
      .ql-active {
        color: var(--primary-color);
      }
      .ql-formats {
        margin-right: 0;
        :first-child {
          margin-right: 12px;
          ${(props) =>
            props.mobile &&
            css`
              float: left;
            `}
        }
        :nth-child(2) {
          ${(props) =>
            props.mobile &&
            css`
              display: flex;
            `}
        }
      }
      button {
        padding: 0;
        height: 20px;
        width: 20px;
        margin-right: 12px;
        svg {
          display: none;
        }
      }
      .ql-expanded .ql-picker-label {
        color: var(--primary-color);
      }
      .ql-picker-label {
        svg {
          display: none;
        }
      }
      .ql-picker-label {
        border-radius: 4px;
      }

      .ql-picker-options {
        border-radius: 4px;
        margin-top: 6px;
        border-color: var(--gray-border-color);
        box-shadow: 0 3px 10px var(--menu-modal);
      }
    }
    .ql-container {
      padding: 0 16px;
      ${(props) =>
        props.mobile
          ? css`
              height: 238px;
            `
          : css`
              display: flex;
              flex-direction: column;
              flex: 1 0 0;
            `}
    }
  }
`;
const Footer = styled.div`
  margin-top: auto;
  padding: 16px;
  ${(props) =>
    props.border &&
    css`
      border-top: 1px solid var(--gray-border-color);
    `}
`;
const MainForm = styled.form`
  ${(props) => props.eventType === 7 && css``}
  ${(props) => props.eventType === 6 && css``}
  ${(props) => props.eventType === 2 && css``}
  ${(props) => props.eventType === 1 && css``}
  input {
    ::placeholder {
      color: var(--disable-color);
    }
  }
`;
const IconWrapper = styled.div`
  margin-right: 16px;
  ${(props) =>
    props.iconMarginTop &&
    css`
      margin-top: 5px;
    `}
`;
const DateBlock = styled.div`
  width: 100%;
`;
const DateWrapper = styled.div`
  margin: 0px 0px 8px 0px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
  :last-child {
    margin: 0;
  }
  .DatePicker {
    ${(props) =>
      props.mobile
        ? css`
            width: 173px;
          `
        : css`
            width: 200px;
          `}
    margin-right: 4px;
  }
  .DatePickerTwo {
    margin-right: 0px;
    ${(props) =>
      props.mobile
        ? css`
            width: 322px;
          `
        : css`
            width: 348px;
          `}
  }
  .TimePicker {
    width: 140px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 100%;
      padding-top: 0px !important;
    }
    .MuiInputBase-root.MuiInput-underline .MuiInputBase-input {
      padding: 6px 12px;
    }
  }
  .TimePickerWrapper {
    width: 300px;
    margin-right: 8px;
  }
  .dropDown {
    width: 170px;
  }
  ${(props) =>
    props.eventType === ACTIVITY_ACTION.Call &&
    css`
      width: 100%;
      .TimePicker {
        width: 100%;
        padding-left: 4px;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 100%;
          padding-top: 0px !important;
        }
        .MuiInputBase-root.MuiInput-underline .MuiInputBase-input {
          padding: 6px 12px;
        }
      }
      .DatePicker {
        ${(props) =>
          props.mobile
            ? css`
                width: 161px;
              `
            : css`
                width: 100%;
              `}
        padding-right: 4px;
        margin-right: 0;
      }
      .statusText {
        ${(props) =>
          props.mobile
            ? css`
                width: 161px;
              `
            : css`
                width: 100%;
              `}
      }
    `}
`;
const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 3px;
          margin-top: 5px;
        `
      : css`
          margin-left: 10px;
        `}
`;
const SpanText = styled.span`
  ${Text2}
  color:var(--gray-color);
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 0 8px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + .slider {
    background-color: var(--primary-color);
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(7px);
    -ms-transform: translateX(7px);
    transform: translateX(7px);
  }
`;
const InputOne = styled.input``;
const Span = styled.span`
  &.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-icon-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &.slider:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 3px;
    bottom: 2px;
    background-color: var(--white-color);
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  &.slider&.round {
    border-radius: 34px;
  }

  &.slider&.round:before {
    border-radius: 50%;
  }
`;
const InputBlock = styled.div`
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0px 0px 4px 6px;
  input {
    position: relative;
    padding-right: 24px;
  }
  svg {
    position: absolute;
    right: 8px;
  }
  ${(props) =>
    !props?.oneItem &&
    css`
      width: 100%;
    `}
`;
const CallLog = styled.div`
  padding: 0 16px 14px 16px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  .statusText {
    width: 100%;
  }
  .dropDown {
    width: 100%;
    padding-left: 4px;
  }
`;
const InView = styled.div`
  padding-right: 4px;
  width: 100%;
`;
const ValidationWrapper = styled.div`
  width: 100%;

  .mentions--singleLine .mentions__control {
    display: inline-block;
  }
  .mentions--singleLine .mentions__higlighter {
  }
  .mentions--singleLine .mentions__input {
  }
  .mentions--multiLine .mentions__control {
  }
  .mentions--multiLine .mentions__highlighter {
    padding: 10px 74px 10px 10px;
    color: var(--dark-color) !important;
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 500 !important;
    letter-spacing: inherit;
    ${(props) =>
      props.isAddActivty &&
      css`
        padding: 10px;
        height: 168px;
      `}
    ${(props) =>
      props.mobile &&
      css`
        padding: 11px 74px 10px 7px;
      `}
  }
  .mentions--multiLine .mentions__input {
    position: absolute !important;
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 500;
    color: var(--dark-color);
    padding: 10px 74px 10px 10px;
    border-radius: 6px;
    background-color: var(--white-color);
    border: 1px solid var(--gray-border-color);
    outline: none;
    font-family: var(--primary-font-stack) !important;
    ::placeholder {
      ${Text1}
      color: var(--gray-color);
    }
    :focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 3px #afcdff, 1px 1px 2px 0 rgb(0 0 0 / 5%);
    }
    ${(props) =>
      props.isAddActivty &&
      css`
        overflow: auto !important;
        ::-webkit-scrollbar {
          display: none;
        }
        padding: 10px;
        :focus {
          box-shadow: 0px 0px 0px 1px var(--primary-color), 0px 0px 0px -0.5px var(--primary-color) inset;
          border-color: var(--primary-color);
        }
      `}
  }
  .mentions__suggestions__list {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  .mentions__suggestions__item {
    padding: 6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .mentions__suggestions__item--focused {
    padding: 6px;
    background-color: #f2f2f2;
    border-radius: 5px;
  }
  .mentions__mention {
    color: var(--primary-color);
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 500 !important;
    letter-spacing: inherit;
    position: relative;
    z-index: 999;
  }
  .mentions__highlighter__substring {
  }
  .mentions__suggestions {
    margin-top: 24px !important;
    bottom: 32px !important;
    top: unset !important;
    padding: 6px;
    border: 1px solid var(--gray-border-color);
    box-shadow: var(--box-shadow-two);
    min-width: 180px !important;
    background-color: var(--white-color) !important;
    border-radius: 8px;
    z-index: 9000 !important;
  }
`;
const BtnWrapper = styled.div`
  display: inline-block;
  margin-right: 12px;
`;
const DrogWrapper = styled.div`
  background: var(--main-bg);
  border: 2px dashed var(--gray-border-color);
  border-radius: 4px;
  padding: 12px 16px;
  cursor: pointer;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          width: 83%;
        `
      : css`
          width: 100%;
        `}
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
    text-indent: -999px;
    opacity: 0;
  }
`;
const TitleDrog = styled.h4`
  ${Text2}
  color:var(--disable-color);
  margin: auto;
  width: 100%;
  text-align: center;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 20%;
        `}
`;
const ManinWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 -4px -6px;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;
const FileUpload = styled.div`
  background: var(--main-bg);
  border: 1px dashed var(--gray-border-color);
  border-radius: 4px;
  margin: 0px 16px 16px 53px;
  padding: 12px;
  text-align: center;
  div {
    padding: 11px 20px;
    width: 100%;
    outline: 0;
  }
  span {
    ${Text2}
    color: var(--gray-color);
  }
`;
const AssignToWrapper = styled.div`
  width: 100%;
  padding: 0 16px 0 52px;
  margin-bottom: 14px;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 99px);
  ${(props) =>
    props.eventType === ACTIVITY_ACTION.Comment &&
    css`
      overflow: unset;
    `}
`;

const DateMainPicker = styled.div``;
const TimeMainPicker = styled.div``;
const DragMainWrap = styled.div`
  margin: 0px 16px 16px 53px;
`;
const FormMainInner = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding-top: 16px;
`;

const Textarea = styled.input`
  ${Text1}
  font-family: var(--primary-font-stack);
  resize: none;
  padding: 10px;
  width: 100%;
  border-radius: 6px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  outline: none;
  :focus {
    border-color: var(--primary-color);
    box-shadow: 0px 0px 0px 1px var(--primary-color), 0px 0px 0px -0.5px var(--primary-color) inset;
  }
`;

export {
  Textarea,
  AssignToWrapper,
  Span,
  InputOne,
  Switch,
  SpanText,
  ToggleDiv,
  TaskWrapper,
  Header,
  HeaderTitel,
  ActivityWrapper,
  ActivityTitel,
  ListWrapper,
  TasksListingTitlebar,
  TimelineView,
  LabelInput,
  TasksListingTitleWp,
  TasksH3,
  TaskPara,
  TasksListingTimelog,
  TaksTimelogList,
  TimeSpan,
  TasksListingUsers,
  TasksListingMore,
  TasksLookupName,
  TasksPerson,
  PlusIcon,
  InputWrapper,
  InputWrapperModal,
  InputType,
  InputSpan,
  AvtarWrapper,
  ActivityBlock,
  ActivityNoUpcoming,
  ModalHeader,
  ModalHeaderTitel,
  LeftWrapper,
  RightWrapper,
  MeetingInputWrapper,
  MeetingInputWrapperTwo,
  Footer,
  MainForm,
  IconWrapper,
  DateBlock,
  DateWrapper,
  NoteInputWrapper,
  InputBlock,
  CallLog,
  ValidationWrapper,
  BtnWrapper,
  DrogWrapper,
  TitleDrog,
  ManinWrapper,
  FileUpload,
  InView,
  SvgWrapper,
  FormWrapper,
  DateMainPicker,
  TimeMainPicker,
  DragMainWrap,
  FormMainInner
};
