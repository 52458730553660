import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/atom/Button/Button';
import Icon from '../../components/Icon/Icon';
import { ReactComponent as EmailEmpty } from '../../assets/images/svg/emailnotlinked.svg';
import { EmailMainBox, EmialEmptyImg, EmailEmptyText, EmailEmptyPara, CrossIcon, EmailMainwrap } from './styles';

const EmailEmptyStateModal = (props) => {
  const history = useHistory();
  return (
    <EmailMainBox>
      <EmailMainwrap>
        <CrossIcon>
          <Icon
            name={'feather-x'}
            fontSize='18'
            color={'var(--gray-icon-color)'}
            onClick={() => {
              props.handleClose();
            }}
          />
        </CrossIcon>
        <EmialEmptyImg>
          <EmailEmpty />
        </EmialEmptyImg>
        <EmailEmptyText>Opps! Your email is not linked</EmailEmptyText>
        <EmailEmptyPara>
          do you link email addresss follow steup ( Settings - Email - Add Link Address ). Simple and easy steup ? click
          to below Button
        </EmailEmptyPara>
        <Button
          title={'Link to Email'}
          size={'Medium'}
          onClick={() => {
            history.push('/settings/sync?isNew=true');
          }}
        />
      </EmailMainwrap>
    </EmailMainBox>
  );
};
export default EmailEmptyStateModal;
