import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from './../actions/types';

const setSearchOpenReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_APP_IS_SEARCH_OPEN
});

const setUpgradeToProOpenReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_APP_IS_UPGRADE_TO_PRO
});

const setSidebarOpenReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_APP_IS_SIDEBAR_OPEN
});

const appReducer = combineReducers({
  isSearchOpen: setSearchOpenReducer,
  isUpgradeToPro: setUpgradeToProOpenReducer,
  isSidebarOpen: setSidebarOpenReducer
});

export default appReducer;
