import React, { Component } from 'react';
import styled, { css } from 'styled-components';
const Buttons = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

class IconOnlyButton extends Component {
  render() {
    const { isBtnIcon, returnIcon, type, disabled, onClick, ...props } = this.props;
    return (
      <Buttons type={type} disabled={disabled} onClick={onClick} {...props}>
        {isBtnIcon && returnIcon()}
      </Buttons>
    );
  }
}

export default IconOnlyButton;
