export const PLATFORM_KEY_NAME = 'platform';
export const ENRICH_FEILDS = 'enrichFeilds';
export const APP_HOST_TYPE = {
  SALESCAMP: 1,
  GET_PEOPLE: 2
};

export const LOOKUP_ACTIVITY_TYPE = {
  TASKS: 1,
  CONVERSATIONS: 2,
  NOTES: 3,
  EMAILS: 4
};

export const ACTIVITY_ACTION = {
  Call: 1,
  Meeting: 2,
  Email: 3,
  Lunch: 4,
  DeadLine: 5,
  Tasks: 6,
  Notes: 7,
  Status: 8,
  Activity: 9,
  Sms: 10,
  File: 11,
  Comment: 12,
  EmailComment: 13,
  CallWithRecord: 98
};
export const TITLE_BY_ACTIVITY_TYPE = {
  7: 'New Note',
  6: '',
  2: '',
  1: 'Log call',
  11: ''
};
export const RTE_PLACEHOLDER_BY_ACTIVITY_TYPE = {
  7: 'Type details for this new notes',
  6: 'Type details for this new task',
  2: 'Type details for this new meeting',
  1: 'Type Summary here...',
  11: 'Summary'
};
export const ICON_NAME_BY_ACTIVITY_TYPE = {
  7: 'icon-add-notes',
  6: 'icon-task',
  2: 'icon-calendar',
  1: 'icon-call',
  11: 'icon-attachment',
  12: 'icon-message'
};
export const HEADER_TITLE_BY_ACTIVITY_TYPE = {
  7: 'Add Note',
  6: 'Add Task',
  2: 'Create Meeting',
  1: 'Log Call',
  11: 'Add File',
  12: 'Add Comment'
};
export const ACTIVITY_ACTION_VALUE = {
  0: 'All',
  1: 'Call',
  2: 'Meeting',
  3: 'Email',
  4: 'Lunch',
  5: 'DeadLine',
  6: 'Tasks',
  7: 'Notes',
  8: 'Status',
  9: 'Activity',
  10: 'Sms',
  11: 'File',
  12: 'Comment'
};
export const INBOX_TYPE = {
  activities: 1,
  lookups: 2,
  emails: 3
};

export const LOOKUP_TYPE = {
  contacts: 1,
  companies: 2,
  deals: 3,
  others: 4,
  leads: 6,
  users: 5
};
export const LOOKUP_TYPE_ID = {
  1: 'Contact',
  2: 'Company',
  3: 'Deal',
  4: 'Others',
  6: 'Leads'
};
export const LOOKUP_TABLE_TYPE = {
  1: 'cn',
  2: 'cm',
  3: 'dl',
  4: 'others'
};

export const LookupFieldTypes = {
  None: 0,
  Text: 1,
  MultiLineText: 2,
  Number: 3,
  Amount: 4,
  Boolean: 5,
  Phone: 6,
  Date: 7,
  Time: 8,
  Select: 9,
  MultiSelect: 10,
  Email: 11,
  Url: 12,
  Lookup: 13
};

export const LOOKUP_FIELD_LABEL_TEXT = {
  1: 'Text Input',
  2: 'MultiLine Text',
  3: 'Number',
  4: 'Amount',
  5: 'Yes / No',
  6: 'Phone',
  7: 'Date',
  8: 'Time',
  9: 'Select',
  10: 'Multi Select',
  11: 'Email',
  12: 'Url',
  13: 'Untitled'
};

export const EMAIL_LABEL_ID = {
  INBOX: 'INBOX',
  SENT: 'SENT',
  TRASH: 'TRASH',
  DRAFT: 'DRAFT',
  ARCHIVE: 'ARCHIVE',
  STARRED: 'STARRED'
};

export const LOOKUP_FIELD_CUSTOM_ID = {
  NAME: 'name',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  EMAIL: 'email',
  PHONE: 'phone',
  CONTACT: 'contact',
  COMPANY: 'company',
  AMOUNT: 'amount',
  STAGE: 'stage',
  STATUS: 'status',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  JOB_TITLE: 'job_title',
  CLOSE_DATE: 'close_date',
  NEXT_FOLLOW_UP_DATE: 'next_follow_up_date',
  TAGS: 'system_tags',
  ASSIGN_USER: 'assigned_to',
  WEBSITE: 'website',
  FIRST_NAME: 'firstname',
  LAST_NAME: 'lastname',
  FACEBOOK: 'facebook',
  SKYPE: 'skype',
  GOOGLE_PLUS: 'google_plus',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter'
};

export const EMAIL_PROVIDER_TYPE = {
  NONE: 0,
  TWO_WAY_SYNC: 1,
  ALIAS: 2,
  SAND_ONLY: 3
};

export const LOOKUP_SEGMENT_FIELD_TYPE = {
  None: 0,
  LookupField: 1,
  TagField: 2
};

export const EMAIL_SYNC_WITH_TYPE = {
  SYNC_ALL_EMAIL: 1,
  SYNC_WITH_LABELS: 2
};

export const EMAIL_SYNC_START_FROM_TYPE = {
  NOW: 0,
  ONE_MONTH: 1,
  THREE_MONTH: 3,
  SIX_MONTH: 6
};

export const SYNC_PAST_EMAIL_TYPE = {
  SALESCAMP_ONLY: 1,
  ALL_PEOPLE: 2
};

export const EMAIL_ACCOUNT_SYNC_TYPE = {
  EMAIL_SYNC: 1,
  CONTACT_SYNC: 2
};

export const USER_DATA_ACCESS_TYPE = {
  OWNER: 1,
  ALL: 2
};

export const SEGMENT_CONDITION_TYPE = {
  MATCH_ALL: 1,
  MATCH_ANY: 2
};

export const SEGMENT_FIELD_TYPE = {
  TEXT: 1,
  NUMBER: 2,
  DATE_AND_TIME: 3,
  OPTION: 4,
  BOOLEAN: 5
};

export const TASK_LIST_TYPE = {
  ALL: 0,
  COMPLETED: 1,
  UNCOMPLETED: 2
};

export const ADD_NUMBER_TYPE = {
  NONE: 0,
  NEW_NUMBER: 1,
  EXTERNAL_NUMBER: 2,
  PORTING_AND_BYOC_NUMBER: 3
};

export const PHONE_CALL_TYPE = {
  NONE: 0,
  OUTGOING: 1,
  INCOMING: 2
};

export const APP_INIT_RESPONSE_TYPE = {
  SUCCESS: 'sucess',
  FAILED: 'failed',
  REDIRECT: 'redirect'
};

export const ONBOARDING_TYPE = {
  ISIMPORTDATA: 'isImportData',
  ISSYNCACCOUNT: 'isSyncAccount',
  ISCUSTOMIZE: 'isCustomize',
  ISINVITEUSER: 'isInviteUser',
  GETSTARTEDVIDEO: 'getStartedVideo'
};

export const CALL_NUMBER_VERIFICATION_STEP = {
  CALL_ME_STEP: 1,
  PHONE_NUMBER_VERIFYING_STEP: 2,
  PHONE_NUMBER_VERIFICATION_FAILED_STEP: 3,
  PHONE_NUMBER_VERIFIED_STEP: 4
};

export const FILE_VALIDATION_MSG = 'The selected file must be less than 5mb';
export const FILE_VALIDATION_SIZE = 5242880; //5Mb

export const SUBSCRIPTION_TYPE = {
  MONTHLY: 1,
  YEARLY: 2
};
export const ATTACHMENT = {
  JPEG: 'jpeg',
  JFIF: 'jfif',
  JPG: 'jpg',
  SVG: 'svg',
  TXT: 'txt',
  DOCX: 'docx',
  DOC: 'doc',
  PDF: 'pdf',
  JS: 'js',
  CSS: 'css',
  HTML: 'html',
  PNG: 'png',
  MP4: 'mp4',
  MP3: 'mp3',
  PSD: 'psd',
  ZIP: 'zip',
  XLSX: 'xlsx',
  TTF: 'ttf',
  DMG: 'dmg',
  EXE: 'exe',
  PPTX: 'pptx',
  CSV: 'csv',
  XLS: 'xls',
  PPT: 'ppt'
};

export const BULK_LOOKUP_TYPE_ID = {
  1: 'Contacts',
  2: 'Companies',
  3: 'Deals',
  4: 'Others',
  6: 'Leads'
};

export const LOOKUP_UPDATE_SOURCE = {
  LIST: 1,
  DETAILS: 2
};

export const INVOICE_STATUS = {
  OVER_DUE: 'OVER DUE',
  NONE: 0,
  DRAFT: 1,
  AWAITING_APPROVAL: 2,
  PENDING: 3,
  PARTIALLY_PAID: 4,
  PAID: 5
};

export const INVOICE_STATUS_TEXT = {
  OVER_DUE: 'OVER DUE',
  0: 'NONE',
  1: 'DRAFT',
  2: 'AWAITING APPROVAL',
  3: 'PENDING',
  4: 'PARTIALLY PAID',
  5: 'PAID'
};

export const INVOICE_STATUS_TEXT_FOR_COLOR = {
  OVER_DUE: 'OVER DUE',
  NONE: 'NONE',
  DRAFT: 'DRAFT',
  AWAITING_APPROVAL: 'AWAITING APPROVAL',
  PENDING: 'PENDING',
  PARTIALLY_PAID: 'PARTIALLY PAID',
  PAID: 'PAID'
};

export const TRIGGER_ACTION = {
  1: 'Create',
  2: 'Update',
  3: 'Create & Update'
};

export const USER_PREFERENCES = 'UserPreferences';

export const SEND_EMAIL_TYPE = {
  COMPOSE: 1,
  REPLY: 2,
  REPLY_ALL: 3,
  FORWARD: 4
};
export const GOAL_TYPE = {
  NEW_CONTACT: 1,
  EMAIL_SENT: 2
};

export const LOOKUP_FIELD_EDIT_VIEW_FROM = {
  LIST: 1,
  DETAILS_UPDATE_FIELD: 2,
  DETAILS_ADD_FIELD: 3,
  LOOKUP_ADD_UPDATE: 4
};

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'h:mm A';
export const TOOLTIP_DATE_FORMAT = 'DD/MM/YYYY h:mm A';

export const DETAIL_SCREEN_TYPE = {
  OVERVIEW: 1,
  ACTIVITY: 2
};

export const NOTIFICATION_TYPE = {
  None: 0,
  Activity: 1,
  LookupAssign: 2,
  Comments: 3,
  Meeting: 4,
  Email: 5,
  IncomingCall: 6,
  IncomingSMS: 7
};

export const AvatarBGColors = ['#007AFF', '#34C759', '#5856D6', '#AF52DE', '#FF2D55', '#FF3B30', '#FF9500'];
export const EVENT_TYPE = {
  EVENT: 1,
  USER: 2
};
