import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import {
  setReportLoader,
  setActivityReport,
  setOverviewReport,
  setStatusChangeReport,
  setCallSummaryReport
} from '../actions/reportActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIErrorReason } from '../helpers/common';

/**
 * @desc Get Report Overview Data
 */
export const getOverviewReport = (organization_id, fromDate, toDate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setReportLoader(true));
    const params = {
      fromdate: fromDate,
      todate: toDate
    };
    const response = await axios.get(`${REACT_APP_APIURL}/reports/overview/${organization_id}`, { params });
    const { data } = response;
    data && dispatch(setOverviewReport(data));
    return true;
  } catch (e) {
    dispatchReportError(getAPIErrorReason(e) || 'Unable to get Overview Report please try again', dispatch);
    return false;
  } finally {
    dispatch(setReportLoader(false));
  }
};

/**
 * @desc Get Report Activity Data
 */
export const getActivityReport = (organization_id, fromDate, toDate, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setReportLoader(true));
    const params = {
      fromDate: fromDate,
      todate: toDate
    };
    const response = await axios.post(`${REACT_APP_APIURL}/reports/activity/${organization_id}`, payload, { params });
    const { data } = response;
    data && dispatch(setActivityReport(data));
    return true;
  } catch (e) {
    dispatchReportError(getAPIErrorReason(e) || 'Unable to get Activity Report please try again', dispatch);
    return false;
  } finally {
    dispatch(setReportLoader(false));
  }
};

/**
 * @desc Get Report Status Change Data
 */
export const getStatusChangeReport = (organization_id, fromDate, toDate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setReportLoader(true));
    const params = {
      fromdate: fromDate,
      todate: toDate
    };
    const response = await axios.get(`${REACT_APP_APIURL}/reports/statuschange/${organization_id}`, { params });
    const { data } = response;
    data && dispatch(setStatusChangeReport(data));
    return true;
  } catch (e) {
    dispatchReportError(getAPIErrorReason(e) || 'Unable to get Status Change Report please try again', dispatch);
    return false;
  } finally {
    dispatch(setReportLoader(false));
  }
};

/**
 * @desc Get Report Call Summary Data
 */
export const getCallSummaryReport = (organization_id, fromDate, toDate, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setReportLoader(true));
    const params = {
      fromDate: fromDate,
      todate: toDate
    };
    const response = await axios.post(`${REACT_APP_APIURL}/reports/calldetails/${organization_id}`, payload, {
      params
    });
    const { data } = response;
    data && dispatch(setCallSummaryReport(data));
    return true;
  } catch (e) {
    dispatchReportError(getAPIErrorReason(e) || 'Unable to get Call Summary Report please try again', dispatch);
    return false;
  } finally {
    dispatch(setReportLoader(false));
  }
};

function dispatchReportError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
