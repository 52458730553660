import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { isWindowsApp } from '../../global/Helper';
import { Text1, Text2 } from '../../styles';

const DealsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

const BoardWrapper = styled.div`
  overflow-x: hidden;
  flex: 1 1 auto;

  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--white-color);
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 1px 3px var(--mobile-box-shadow);
      padding-top: 16px;
    `}

  .react-kanban-board {
    height: 100%;
    ${() => isWindowsApp() && css``}
    ${(props) =>
      props.mobile
        ? css`
            padding: 0;
          `
        : css`
            padding: 0 24px;
          `}

    > div {
      display: flex;
      align-items: stretch;
      height: 100%;
    }
  }
  .react-kanban-column {
    background: transparent;
    border: 1px solid var(--gray-border-color);
    border-left: 0px;
    border-bottom: 0px;
    border-radius: 0;
    margin: 0;
    padding: 0 8px;
    height: auto !important;
    min-width: 260px;
    overflow-y: auto;
    overflow-x: hidden;
    ${() => isWindowsApp() && css``}
    :first-child {
      ${(props) =>
        props.mobile &&
        css`
          margin-left: 16px;
        `}
    }
    :last-child {
      border-right: 0px;
      ${(props) =>
        props.mobile &&
        css`
          margin-right: 16px;
        `}
    }
    ::-webkit-scrollbar {
      display: none;
    }

    > div:first-child {
      background-color: var(--white-color);
      padding: 11px 8px;
      margin: 0 -8px 9px -8px;
      position: sticky;
      top: 0;
      border-bottom: 2px solid var(--gray-border-color);
    }
    > div:nth-child(2) {
      min-height: calc(100% - 51px) !important;
      height: calc(100% - 51px) !important;
    }
  }
  .react-kanban-column-header {
    color: var(--gray-color);
    padding-bottom: 0;
    ${Text1}
  }
  .react-kanban-card-skeleton {
    min-width: 150px;
    height: calc(100vh - 147px);
  }
`;
const ReactKanbanColumn = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px 10px 0 10px;
  margin-bottom: 12px;
  width: 245px;
`;
const ReactColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  div:last-child {
    padding-right: 0;
  }
`;
const ReactColumn = styled.div`
  margin-bottom: 8px;
  display: inline-block;
  min-width: 50%;
  padding-right: 10px;
  :last-child {
    margin-bottom: 10px;
  }
`;
const ReactCardTitle = styled.span`
  ${Text2};
  color: var(--gray-color);
  display: block;
`;
const ReactCardDescription = styled.span`
  color: var(--dark-color);
  ${Text1}
`;
const ReactCardName = styled(NavLink)`
  color: var(--dark-color);
  ${Text1}
  padding: 1px 4px;
  border-radius: 4px;
  text-decoration: none;
  :hover {
    background: var(--popup-color-hover);
  }
`;
const ReactCardWrapper = styled.div`
  width: 100%;
  margin: 0 -4px 8px -4px;
`;

export {
  DealsWrapper,
  BoardWrapper,
  ReactKanbanColumn,
  ReactCardTitle,
  ReactCardDescription,
  ReactColumn,
  ReactColumnWrapper,
  ReactCardWrapper,
  ReactCardName
};
