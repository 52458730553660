import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { ROUTE } from '../../constants/routeConst';
import UserPreferenceSingleton from '../../helpers/UserPreferenceSingleton';
import OrganizationPreferencesSingleton from '../../helpers/OrganizationPreferencesSingleton';
import { LOOKUP_TYPE } from '../../constants/constant';
import SettingNavbar from '../SettingNavbar/SettingNavbar';
import EmailNavbar from '../EmailNavbar/EmailNavbar';
import ReportNavbar from '../ReportNavbar/ReportNavbar';
import useMobileDevice from '../../hooks/useMobileDevice';
import { getUniqueId, hasProduction, noltInit } from '../../helpers/common';
import { isElectronApp } from '../../global/Helper';
import { getCurrentCompanyPlanType } from '../../services/organizationService';
import { setLookupTables } from '../../actions/lookupTableActions';
import SVGIcon from '../../assets/svg/SVGIcon';
import Avtar from '../Avtar/Avtar';
import { logout } from '../../services/authServices';
import Modal from '../Modal';
import HelpAndFeedbackModal from '../HelpAndFeedbackModal/HelpAndFeedbackModal';
import { trackAnalyticActivity } from '../../services/analyticsService';
import PlatformText from '../PlatformText/PlatformText';
import Notifications from '../../Pages/Notifications/Notifications';
import { setIsAppSearchOpen } from '../../actions/appActions';
import { getNotificationList } from '../../services/notificationServices';
import {
  MainNav,
  NavList,
  Ul,
  Li,
  Alist,
  SpanList,
  ListView,
  NumbList,
  SpanListtwo,
  NavBottom,
  StyledMenuTwo,
  LiTwo,
  AlistTwo,
  NavIcon,
  MenuItemLink,
  MainNavWrapper,
  SpanPlanExpired,
  PlanLi,
  LiMore,
  AlistMore,
  SpanPlan,
  TitleView,
  LeftText,
  HrLine,
  IconWrapper,
  NavbarTop,
  Profile,
  CompanyName,
  SwitchList,
  FirstList,
  WorkSpace,
  SpanListMenu2,
  IconViewt,
  TopLeft,
  NavbarTitleBar,
  ProfileMenu,
  SpanListMenu,
  HelpBlock,
  HelpMainblock,
  ModalStyle,
  MenuUl,
  SlidebarAvtar,
  NotifyIcon,
  NotificationMenu,
  SearchButton,
  SearchLeft,
  SearchRight,
  InputBox,
  SearchSpan,
  SlidebarSearch,
  TopLinear,
  BottmLinear
} from './styles';

const Tooltips = withStyles({
  tooltip: {
    color: 'var(--white-color)',
    backgroundColor: 'var(--nav-dark-color-hover)',
    fontSize: '14px',
    padding: '4px 10px',
    marginLeft: '20px'
  },
  arrow: {
    width: '10px !important',
    height: '15px !important',
    marginTop: '4px !important',
    marginLeft: '-9px !important',
    color: 'var(--nav-dark-color-hover)',
    borderRadius: 1
  }
})(Tooltip);
const Navbar = (props) => {
  const { onCloseNavbar, setNavBar } = props;
  const { navbarNavigation, onChangeSideBar, sideBarValue } = props;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const emailSelector = useSelector((state) => state.email);
  const organizationSelector = useSelector((state) => state.organization);
  const notificationSelector = useSelector((state) => state.notification);
  const { list: notifications } = notificationSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const company = UserPreferenceSingleton.getInstance().getOrganization();
  const { list: lookupTableList } = lookupTableSelector;
  const { emailAccounts, totalEmailCount } = emailSelector;
  const activitySelector = useSelector((state) => state.activity);
  const { taskCount } = activitySelector;
  const [isOpenProfileMenulist, toggleProfileMenulist] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenProfileMenu, setIsopenProfileMenu] = useState(false);
  const [isOpenNotificationMenu, setIsopenNotificationeMenu] = useState(false);
  const [isOpenHelpModal, setOpenHelpModal] = useState(false);

  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  let history = useHistory();
  // const [navbarNavigation, setNavbarNavigation] = useState(false);
  const userAccess = useMemo(() => {
    const AccessSetting = company?.settings?.access;
    return {
      emailAccess: AccessSetting?.email?.isAccess,
      automationAccess: AccessSetting?.automation?.isAccess,
      reportAccess: AccessSetting?.report?.isAccess,
      taskAccess: AccessSetting?.task?.isAccess
    };
  }, [company]);

  const lookupTableListWithAccess = useMemo(() => {
    const tableList = [];
    lookupTableList?.forEach((table) => {
      const settingTable = company?.settings?.access?.tables?.find((item) => item?.tableId === table?.id);
      if (settingTable && settingTable?.isAccess) tableList?.push(table);
    });
    return tableList;
  }, [company, lookupTableList]);

  const isUnreadNotification = useMemo(() => {
    const unReadList = notifications?.filter((x) => !x.isRead);
    return unReadList?.length > 0 ? true : false;
  }, [notifications]);

  const trackHelpAndFeedbackAnalyticActivity = useCallback(() => {
    try {
      trackAnalyticActivity('support: help feedback clicked');
    } catch (e) {
      console.log('track support: help feedback clicked Error', e);
    }
  }, []);

  const isOpenProfileMorelist = (e) => {
    setAnchorEl(e.currentTarget);
    toggleProfileMenulist(true);
  };
  const closeProfileMenuMorelist = (e) => {
    setAnchorEl(null);
    toggleProfileMenulist(false);
  };

  const openEmailNavbar = () => {
    const accounts = emailAccounts?.filter((x) => x?.isEmailSync)?.length > 0;
    closeProfileMenuMorelist();
    onChangeSideBar(accounts ? 3 : 1);
  };
  const openReportNavbar = () => {
    closeProfileMenuMorelist();
    onChangeSideBar(4);
  };

  const handleBackButton = () => {
    onChangeSideBar(1);
  };

  const planType = useMemo(() => {
    return getCurrentCompanyPlanType();
  }, []);

  const onClickUpgrade = useCallback(() => {
    trackAnalyticActivity('sales: clicked in trial');
    history.push('/upgrade');
  }, [history]);

  const onClickInvoice = useCallback(() => {
    closeProfileMenuMorelist();
    history.push('/invoices');
  }, [history]);

  const onClickAutomation = useCallback(() => {
    closeProfileMenuMorelist();
    history.push('/automation');
  }, [history]);

  const onClickGoal = useCallback(() => {
    closeProfileMenuMorelist();
    history.push(ROUTE.GOAL);
  }, [history]);

  const onClickCallLog = useCallback(() => {
    closeProfileMenuMorelist();
    history.push('/settings/calllog/call');
  }, [history]);

  const handleProfileMenu = useCallback(
    (e) => {
      setAnchorEl(e.currentTarget);
      setIsopenProfileMenu(!isOpenProfileMenu);
    },
    [isOpenProfileMenu]
  );
  const handleNotificationMenu = useCallback(
    (e) => {
      dispatch(getNotificationList(organization_id));
      setAnchorEl(e.currentTarget);
      setIsopenNotificationeMenu(!isOpenNotificationMenu);
    },
    [dispatch, isOpenNotificationMenu, organization_id]
  );
  const closeNotificationMenu = useCallback(() => {
    setAnchorEl(null);
    setIsopenNotificationeMenu(false);
  }, []);

  const closeProfileMenu = useCallback(() => {
    setAnchorEl(null);
    setIsopenProfileMenu(false);
  }, []);

  const openSettingNavbar = useCallback(() => {
    closeProfileMenu();
    setNavBar(2);
  }, [closeProfileMenu, setNavBar]);

  const openSwitchOrganisation = () => {
    closeProfileMenu();
    history.push(ROUTE.SELECT_ORGANIZATION);
  };

  const onClickLogout = async () => {
    history.push(`${ROUTE.LOGIN}?logout=true`);
    await dispatch(logout());
  };

  const onOpenHelpModal = useCallback(() => {
    noltInit();
    setOpenHelpModal(true);
    trackHelpAndFeedbackAnalyticActivity();
  }, [trackHelpAndFeedbackAnalyticActivity]);

  const onCloseHelpModal = useCallback(() => {
    setOpenHelpModal(false);
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const tables = lookupTableListWithAccess;
      const sortedCollections = arrayMoveImmutable(tables, oldIndex, newIndex);
      dispatch(setLookupTables(sortedCollections));
      OrganizationPreferencesSingleton.getInstance().setTables(sortedCollections);
    }
  };

  const onClickSearch = useCallback(() => {
    dispatch(setIsAppSearchOpen(true));
  }, [dispatch]);

  const renderCollectionIcon = (type) => {
    switch (type) {
      case LOOKUP_TYPE.contacts:
        return <NavIcon name='icon-user' />;
      case LOOKUP_TYPE.companies:
        return <NavIcon name='icon-account' />;
      case LOOKUP_TYPE.deals:
        return <NavIcon name='icon-deals' />;
      case LOOKUP_TYPE.others:
        return <NavIcon name='icon-other' />;
      case LOOKUP_TYPE.leads:
        return <NavIcon name='icon-leads' />;
      default:
    }
  };

  const getCollectionType = (type) => {
    switch (type) {
      case LOOKUP_TYPE.contacts:
        return 'cn';
      case LOOKUP_TYPE.companies:
        return 'cm';
      case LOOKUP_TYPE.deals:
        return 'dl';
      case LOOKUP_TYPE.others:
        return 'others';
      case LOOKUP_TYPE.leads:
        return 'cm';
      default:
    }
  };

  const SortableItem = SortableElement(({ value }) => (
    <Li key={value?.id} className={navbarNavigation && 'hideSpan'} onClick={onCloseNavbar}>
      {navbarNavigation ? (
        <Tooltips title={value?.label} placement='right' arrow={true}>
          <MenuItemLink to={`/${getCollectionType(value?.type)}/${value?.name}`} activeClassName='active_menu'>
            {renderCollectionIcon(value?.type)}
          </MenuItemLink>
        </Tooltips>
      ) : (
        <MenuItemLink
          to={`/${getCollectionType(value?.type)}/${value?.name}`}
          activeClassName='active_menu'
          mobile={mobile}>
          {renderCollectionIcon(value?.type)}
          <SpanList mobile={mobile}>{value?.label}</SpanList>
        </MenuItemLink>
      )}
    </Li>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <>
        {items?.map((item, index) => (
          <SortableItem key={`${item?.id}_collection_${index}`} index={index} value={item} disabled={mobile} />
        ))}
      </>
    );
  });

  return (
    <MainNav className={sideBarValue === 1 && navbarNavigation && 'sidebarWrapper'}>
      {sideBarValue === 1 && (
        <>
          <MainNavWrapper className={navbarNavigation && 'sidebarBlock'} mobile={mobile}>
            <NavList
              className={classNames({ sidebarNav: navbarNavigation, 'electron-app': isElectronApp() })}
              mobile={mobile}>
              <ListView mobile={mobile}>
                <TopLinear></TopLinear>
                <BottmLinear></BottmLinear>
                {navbarNavigation ? (
                  <SlidebarAvtar onClick={handleProfileMenu}>
                    <Avtar imgSrc={organization?.logoUrl} name={organization?.name} size={mobile ? '30' : '32'} />
                  </SlidebarAvtar>
                ) : (
                  <NavbarTop>
                    <TopLeft onClick={handleProfileMenu}>
                      <Avtar imgSrc={organization?.logoUrl} name={organization?.name} size={20} />
                      {navbarNavigation ? null : (
                        <Profile>
                          <CompanyName>{organization?.name}</CompanyName>
                        </Profile>
                      )}
                    </TopLeft>
                    {navbarNavigation ? null : (
                      <NotifyIcon
                        isOpenNotificationMenu={isOpenNotificationMenu}
                        onClick={handleNotificationMenu}
                        className={isUnreadNotification ? 'notification' : null}>
                        <SVGIcon fill='var(--gray-color)' size={18} name='icon-notification' strokeWidth={2} />
                      </NotifyIcon>
                    )}
                  </NavbarTop>
                )}
                <Ul className={navbarNavigation && 'ul'} mobile={mobile}>
                  {mobile ? null : navbarNavigation ? (
                    <SlidebarSearch onClick={onClickSearch}>
                      <SVGIcon fill='var(--gray-color)' size={18} name='icon-search' strokeWidth={2} />
                    </SlidebarSearch>
                  ) : (
                    <NavbarTitleBar onClick={onClickSearch}>
                      <SearchButton>
                        <SearchLeft>
                          <SVGIcon fill='var(--raven-color)' size={18} name='icon-search' strokeWidth={2} />
                          <SearchSpan>Search</SearchSpan>
                        </SearchLeft>
                        <SearchRight>
                          <InputBox mobile={mobile}>
                            <PlatformText mac={'⌘+K'} win={'⌘+K'} defaultText={'ctrl+K'} />
                          </InputBox>
                        </SearchRight>
                      </SearchButton>
                    </NavbarTitleBar>
                  )}
                  <Li className={navbarNavigation && 'hideSpan'} onClick={onCloseNavbar} mobile={mobile}>
                    {navbarNavigation ? (
                      <Tooltips title='Home' placement='right' arrow={true}>
                        <MenuItemLink to={ROUTE.INBOX} activeClassName='active_menu'>
                          <NavIcon name='icon-dashboard' />
                        </MenuItemLink>
                      </Tooltips>
                    ) : (
                      <MenuItemLink to={ROUTE.INBOX} activeClassName='active_menu' mobile={mobile}>
                        <SVGIcon
                          name='icon-dashboard'
                          size={18}
                          fill='var(--white-color)'
                          bgfill='var(--icon-fill)'
                          strokeWidth={2}
                        />
                        <SpanList mobile={mobile}>Home</SpanList>
                      </MenuItemLink>
                    )}
                  </Li>
                  {userAccess?.emailAccess && (
                    <Li
                      onClick={() => {
                        onCloseNavbar();
                        openEmailNavbar();
                      }}
                      className={navbarNavigation && 'hideSpan'}>
                      {navbarNavigation ? (
                        <Tooltips title='Email' placement='right' arrow={true}>
                          <MenuItemLink to={`${ROUTE.EMAILS}/inbox`} activeClassName='active_menu'>
                            <NavIcon name='icon-email' />
                          </MenuItemLink>
                        </Tooltips>
                      ) : (
                        <MenuItemLink to={`${ROUTE.EMAILS}/inbox`} activeClassName='active_menu' mobile={mobile}>
                          <NavIcon name='icon-email' />
                          <SpanList>Email</SpanList>
                          <NumbList>{totalEmailCount > 0 ? totalEmailCount : ''}</NumbList>
                        </MenuItemLink>
                      )}
                    </Li>
                  )}
                  {userAccess?.taskAccess && (
                    <Li className={navbarNavigation && 'hideSpan'} onClick={onCloseNavbar}>
                      {navbarNavigation ? (
                        <Tooltips title='Task' placement='right' arrow={true}>
                          <MenuItemLink to={ROUTE.TASK} activeClassName='active_menu'>
                            <NavIcon name='icon-task' />
                          </MenuItemLink>
                        </Tooltips>
                      ) : (
                        <MenuItemLink to={ROUTE.TASK} activeClassName='active_menu' mobile={mobile}>
                          <NavIcon name='icon-task' />
                          <SpanList>Task</SpanList>
                          <NumbList>{taskCount}</NumbList>
                        </MenuItemLink>
                      )}
                    </Li>
                  )}
                  {userAccess?.reportAccess && (
                    <LiMore>
                      {navbarNavigation ? (
                        <Tooltips title='More' placement='right' arrow={true}>
                          <Alist onClick={isOpenProfileMorelist} className={navbarNavigation && 'more'}>
                            <SVGIcon fill='var(--gray-color)' size={18} name='icon-more' strokeWidth={2} />
                          </Alist>
                        </Tooltips>
                      ) : (
                        <AlistMore onClick={isOpenProfileMorelist} mobile={mobile}>
                          <SVGIcon fill='var(--gray-color)' size={mobile ? 24 : 18} name='icon-more' strokeWidth={2} />
                          <SpanListtwo mobile={mobile}>More</SpanListtwo>
                        </AlistMore>
                      )}
                    </LiMore>
                  )}

                  {navbarNavigation ? (
                    <TitleView className='collection-blog'>
                      <IconWrapper
                        className='icon-plus'
                        onClick={() => {
                          let uniqId = getUniqueId();
                          history.push({
                            pathname: `/settings/customize`,
                            search: '?isNew=true',
                            state: { uniqId }
                          });
                          onCloseNavbar();
                        }}>
                        <SVGIcon fill='var(--gray-color)' size={18} name='icon-add-plus' strokeWidth={2} />
                      </IconWrapper>
                    </TitleView>
                  ) : (
                    <TitleView>
                      <LeftText mobile={mobile}>Collection</LeftText>
                      <HrLine></HrLine>
                      <IconWrapper
                        mobile={mobile}
                        className='icon-plus'
                        onClick={() => {
                          let uniqId = getUniqueId();
                          history.push({
                            pathname: `/settings/customize`,
                            search: '?isNew=true',
                            state: { uniqId }
                          });
                          onCloseNavbar();
                        }}>
                        <SVGIcon fill='var(--gray-color)' size={18} name='icon-add-plus' strokeWidth={2} />
                      </IconWrapper>
                    </TitleView>
                  )}
                  <SortableList items={lookupTableListWithAccess} onSortEnd={onSortEnd} distance={1} lockAxis='y' />
                </Ul>
                <HelpMainblock onClick={onOpenHelpModal}>
                  {navbarNavigation ? (
                    <Tooltips title='Help & Feedback' placement='right' arrow={true}>
                      <HelpBlock
                        activeClassName='active_menu'
                        className={navbarNavigation && 'hideSpan'}
                        isOpenHelpModal={isOpenHelpModal}>
                        <NavIcon name='icon-feedback' help />
                      </HelpBlock>
                    </Tooltips>
                  ) : (
                    <HelpBlock
                      activeClassName='active_menu'
                      mobile={mobile}
                      onClick={onOpenHelpModal}
                      isOpenHelpModal={isOpenHelpModal}>
                      <NavIcon name='icon-feedback' help />
                      <SpanList help>Help & Feedback</SpanList>
                    </HelpBlock>
                  )}
                </HelpMainblock>
                <NavBottom className={navbarNavigation && 'navBottom'}>
                  <>
                    {planType !== 'paid' && (company?.plan === 'expired' || company?.planRemainingDays <= 7) && (
                      <PlanLi>
                        <div onClick={onClickUpgrade}>
                          {company?.plan === 'trial' && (
                            <SpanPlanExpired>
                              {company?.planRemainingDays} Day{company?.planRemainingDays === 1 ? '' : 's'} left
                            </SpanPlanExpired>
                          )}
                          {company?.plan === 'expired' && <SpanPlan>Expired</SpanPlan>}
                        </div>
                      </PlanLi>
                    )}
                  </>
                </NavBottom>
              </ListView>
            </NavList>
          </MainNavWrapper>
        </>
      )}
      <ProfileMenu mobile={mobile} keepMounted anchorEl={anchorEl} open={isOpenProfileMenu} onClose={closeProfileMenu}>
        <MenuUl>
          <SwitchList onClick={openSwitchOrganisation}>
            <FirstList>
              <SpanListMenu2 mobile={mobile}>{organization?.name}</SpanListMenu2>
              <IconViewt>
                <SVGIcon name='icon-right-arrow' size={mobile ? 20 : 18} fill='var(--gainsBoro-color)' />
              </IconViewt>
            </FirstList>
            <WorkSpace mobile={mobile}>Create or Switch Workspace</WorkSpace>
          </SwitchList>
          <LiTwo onClick={openSettingNavbar}>
            <AlistTwo>
              <SpanListMenu mobile={mobile}>Settings</SpanListMenu>
            </AlistTwo>
          </LiTwo>
          <LiTwo isProfile onClick={onClickLogout}>
            <AlistTwo>
              <SpanListMenu mobile={mobile}>Logout</SpanListMenu>
            </AlistTwo>
          </LiTwo>
        </MenuUl>
      </ProfileMenu>

      <NotificationMenu
        mobile={mobile}
        keepMounted
        anchorEl={anchorEl}
        open={isOpenNotificationMenu}
        onClose={closeNotificationMenu}>
        <Notifications onClose={closeNotificationMenu} isUnreadNotification={isUnreadNotification} />
      </NotificationMenu>

      <StyledMenuTwo
        mobile={mobile}
        id='customized-more'
        anchorEl={anchorEl}
        keepMounted
        open={isOpenProfileMenulist}
        MenuListProps={{ disablePadding: true }}
        onClose={closeProfileMenuMorelist}>
        <MenuUl>
          {userAccess?.reportAccess && (
            <LiTwo onClick={openReportNavbar}>
              <AlistTwo>
                <SVGIcon name='icon-infographic' size={18} fill='var(--white-color)' bgfill='var(--icon-fill)' />

                <SpanList>Reports</SpanList>
              </AlistTwo>
            </LiTwo>
          )}
          <LiTwo
            onClick={() => {
              onClickInvoice();
              if (mobile) onCloseNavbar();
            }}
            className={hasProduction() ? 'inDevelopment' : ''}>
            <AlistTwo>
              <SVGIcon
                name='icon-invoice'
                size={18}
                fill='var(--white-color)'
                bgfill='var(--icon-fill)'
                strokeWidth={2}
              />
              <SpanList>Invoices</SpanList>
            </AlistTwo>
          </LiTwo>
          {userAccess?.automationAccess && (
            <LiTwo
              onClick={() => {
                onClickAutomation();
                if (mobile) onCloseNavbar();
              }}>
              <AlistTwo>
                <SVGIcon
                  name='icon-webcam'
                  size={18}
                  fill='var(--white-color)'
                  bgfill='var(--icon-fill)'
                  strokeWidth={2}
                />
                <SpanList>Automation</SpanList>
              </AlistTwo>
            </LiTwo>
          )}
          <LiTwo>
            <AlistTwo
              onClick={() => {
                onClickGoal();
                if (mobile) onCloseNavbar();
              }}>
              <SVGIcon name='icon-goal' size={18} fill='var(--white-color)' bgfill='var(--icon-fill)' strokeWidth={2} />
              <SpanList>Goal</SpanList>
            </AlistTwo>
          </LiTwo>
          <LiTwo>
            <AlistTwo
              onClick={() => {
                onClickCallLog();
                if (mobile) onCloseNavbar();
              }}>
              <SVGIcon
                name='icon-phone'
                size={18}
                fill='var(--white-color)'
                bgfill='var(--icon-fill)'
                strokeWidth={2}
              />
              <SpanList>Call Log</SpanList>
            </AlistTwo>
          </LiTwo>
        </MenuUl>
      </StyledMenuTwo>
      <Modal open={isOpenHelpModal} onClose={onCloseHelpModal} BackdropProps={{ invisible: true }} style={ModalStyle}>
        <HelpAndFeedbackModal onClose={onCloseHelpModal} />
      </Modal>
      {sideBarValue === 2 && (
        <SettingNavbar
          onClickBack={handleBackButton}
          onCloseNavbar={onCloseNavbar}
          onOpenHelpModal={onOpenHelpModal}
          isOpenHelpModal={isOpenHelpModal}
        />
      )}
      {sideBarValue === 3 && (
        <EmailNavbar
          onClickBack={handleBackButton}
          onCloseNavbar={onCloseNavbar}
          onOpenHelpModal={onOpenHelpModal}
          isOpenHelpModal={isOpenHelpModal}
          {...props}
        />
      )}
      {sideBarValue === 4 && (
        <ReportNavbar
          onClickBack={handleBackButton}
          onCloseNavbar={onCloseNavbar}
          onOpenHelpModal={onOpenHelpModal}
          isOpenHelpModal={isOpenHelpModal}
        />
      )}
    </MainNav>
  );
};

export default Navbar;
