export const ROUTE = {
  LOGIN: '/membership/login',
  SIGN_UP: '/membership/signup',
  FORGOT_PASSWORD: '/membership/forgot-password',
  RESET_PASSWORD: '/membership/reset-password',
  VERIFICATION_REQUIRED: '/verification-code',
  MAIN: '/',
  SELECT_ORGANIZATION: '/select-organization',
  INBOX: '/home/inbox',
  INBOX_OPENTHREAD: '/home/open-threads',
  INBOXEMAIL: '/home/inbox/email',
  INBOX_DUPLICATION: '/home/inbox/duplication',
  INBOX_MERGE_DUPLICATION: '/home/inbox/merge-duplication',
  INBOX_CONTACT_SUGGESTION: '/home/inbox/contact-suggestion',
  EMAILS: '/email/segments',
  TASK: '/task',
  REPORTSOVERVIEW: '/reports/overview',
  STATUSCHANGE: '/reports/status-change',
  CALLSUMMARY: '/reports/call-summary',
  ACTIVITY: '/reports/activity',
  GOAL: '/goal',
  INVOICE: '/invoices',
  AUTOMATION: '/automation',
  NEW_AUTOMATION: '/automation/new',
  MY_PROFILE: '/mysettings',
  USERS: '/settings/users/userslist',
  IMPORT_DATA: '/settings/importdata',
  EMAIL_ACCOUNTS: '/settings/sync',
  EMAIL_TEMPLATE: '/settings/emailtemplate',
  INTEGRATIONS: '/settings/integrations',
  WEB_FORMS: '/settings/forms',
  PHONE_SETTINGS: '/settings/phone-settings',
  CREDITS_TRANSACTIONS: '/settings/phone-settings/transaction',
  CUSTOMIZE: '/settings/customize',
  WORKSPACE_PROFILE: '/settings/companyprofile',
  SUBSCRIPTIONS: '/settings/subscriptions',
  API_KEY: '/settings/api-keys',
  FRONTAPP_PAGE_URL: '/front-app',
  //Static Routes
  STATIC_MAIN: '/static',
  STATIC_INBOX2: '/static/inbox2',
  STATIC_CONTACT_DETAILS: '/static/contact/detail'
};
