import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Invoice Loader
 */
export const setInvoiceLoader = createAction(Actions.SET_INVOICE_LOADER);
/**
 * @desc Set Invoice List
 */
export const setInvoiceList = createAction(Actions.SET_INVOICE_LIST);
/**
 * @desc Set Invoice Item
 */
export const setInvoiceItem = createAction(Actions.SET_INVOICE_ITEM);
/**
 * @desc Update Invoice Item
 */
export const updateInvoiceItem = createAction(Actions.UPDATE_INVOICE_ITEM);
/**
 * @desc Add Invoice Tax
 */
export const addInvoiceTax = createAction(Actions.ADD_INVOICE_TAX);
/**
 * @desc Delete Invoice Tax
 */
export const deleteInvoiceTax = createAction(Actions.DELETE_INVOICE_TAX);
/**
 * @desc Add Item in Invoice
 */
export const addItemInInvoice = createAction(Actions.ADD_ITEM_IN_INVOICE);
/**
 * @desc Add Item from Invoice
 */
export const deleteItemFromInvoice = createAction(Actions.DELETE_ITEM_FROM_INVOICE);
/**
 * @desc Add Email in Invoice
 */
export const addEmailInInvoice = createAction(Actions.ADD_EMAIL_IN_INVOICE);
/**
 * @desc Delete Email From Invoice
 */
export const deleteEmailFromInvoice = createAction(Actions.DELETE_EMAIL_FROM_INVOICE);
