import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Modal from '../../components/Modal/index';
import { Menu, MenuItem } from '../../components/Menu';
import { LOOKUP_TYPE } from '../../constants/constant';
import Button from '../atom/Button/Button';
import IconOnlyButton from '../IconOnlyButton/IconOnlyButton';
import AddNewFilter from '../../Pages/Contact/AddNewFilter/AddNewFilter';
import ManageColumsModal from '../../Pages/Contact/ManageColumsModal/ManageColumsModal';
import ManageFilterModal from '../../Pages/Contact/ManageFilterModal/ManageFilterModal';
import { getTableFields } from '../../services/lookupTableServices';
import ArrowUpDown from '../../assets/images/svg/arrowupdown.svg';
import {
  getLookupList,
  getSegmentList,
  getTableTypeForAnalytics,
  trackSegmentNaviagateCollectionAnalyticActivity
} from '../../services/lookupService';
import OrganizationPreferencesSingleton from '../../helpers/OrganizationPreferencesSingleton';
import { hasUserAccessAndPermission } from '../../services/appService';
import { trackAnalyticActivity } from '../../services/analyticsService';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import { getCurrentCompanyPlanType } from '../../services/organizationService';
import { setIsAppUpgradeToPro } from '../../actions/appActions';
import MobileTitleBar from '../TitleBar/MobileTitleBar';
import { getScreenName, hasProduction } from '../../helpers/common';
import FilterPanel from '../FilterPanel/FilterPanel';
import {
  MainHeader,
  LeftView,
  RightView,
  Border,
  MenuItemWrapper,
  MobileButton,
  ContactMobileMenu,
  AllMenuSpan,
  ArrowUpDownImg,
  AllContactMenu,
  VerticalIcon,
  ContactMobileRight,
  DotDiv,
  ContactMobileLeft,
  MenuListItem,
  HeaderMainWrap,
  FilterPanelBox,
  FilterIcon,
  Customstyle
} from './styles';

const SEGMENT = 'segment';

const Header = ({ ...props }) => {
  const mobile = useMobileDevice();
  const params = useParams();
  const tableName = params?.name;
  const { size, primary, segments, table_id, segmentId } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isContactMenuOpen, setIsContactMenuOpen] = useState(false);
  const [isDealsMenuOpen, setIsDealsMenuOpen] = useState(false);
  const [isCompanyMenuOpen, setIsCompanyMenuOpen] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [isOpenNewFilterModal, setIsOpenNewFilterModal] = useState(false);
  const [isOpenManageColumsModal, setIsOpenManageColumsModal] = useState(false);
  const [isOpenManageFilterModal, setIsOpenManageFilterModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const history = useHistory();
  const location = useLocation();
  const [isDurty, setIsDurty] = useState(false);
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const selectedSegment = useMemo(() => {
    return segments?.find((x) => x?.id === segmentId);
  }, [segmentId, segments]);

  const userPermission = useMemo(() => {
    const permissionExport = dispatch(hasUserAccessAndPermission('tables', 'permissionExport', tableName));
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', tableName));

    return {
      permissionEdit: permissionEdit,
      permissionExport: permissionExport
    };
  }, [dispatch, tableName]);

  const onOpenMenu = useCallback(
    (e) => {
      e.stopPropagation();
      const { table } = props;
      setMenuAnchorEl(e.currentTarget);
      if (table?.type === LOOKUP_TYPE.contacts) {
        setIsContactMenuOpen(true);
      } else if (table?.type === LOOKUP_TYPE.deals) {
        setIsDealsMenuOpen(true);
      } else if (table?.type === LOOKUP_TYPE.companies || LOOKUP_TYPE.leads) {
        setIsCompanyMenuOpen(true);
      }
    },
    [props]
  );

  const screenName = useMemo(() => {
    let name = '';
    if (location?.pathname) name = dispatch(getScreenName(location?.pathname));
    return name;
  }, [dispatch, location?.pathname]);

  const onCloseMenu = useCallback(() => {
    setIsContactMenuOpen(false);
    setIsDealsMenuOpen(false);
    setIsCompanyMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const openMoreMenu = useCallback((e) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const openNewFilterModal = useCallback((item_id) => {
    if (item_id) {
      setSelectedItemId(item_id);
    }
    setIsOpenNewFilterModal(true);
  }, []);

  const openManageColumsModal = useCallback(() => {
    setIsOpenManageColumsModal(true);
  }, []);

  const openManageFilterModal = useCallback(() => {
    setIsOpenManageFilterModal(true);
  }, []);

  // const openAddModal = () => {
  //   const { table } = props;
  //   if (table?.type === LOOKUP_TYPE.contacts) {
  //     setIsOpenAddContactModal(true);
  //   }
  // };

  const closeManageColumns = useCallback(() => {
    setIsOpenManageColumsModal(false);
  }, []);

  const closeNewFilterModal = useCallback(() => {
    setIsOpenNewFilterModal(false);
    setSelectedItemId(undefined);
  }, []);

  const onUpdateFilter = useCallback(
    (item_id) => {
      if (item_id && item_id === segmentId) {
        dispatch(getLookupList(organization_id, table_id));
      }
    },
    [dispatch, organization_id, segmentId, table_id]
  );

  const handleFilterPanel = useCallback(() => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  }, [isFilterPanelOpen]);

  const closeManageFilterModal = useCallback(async () => {
    if (isDurty) {
      await dispatch(getTableFields(organization_id, true));
      dispatch(getSegmentList(table_id));
    }
    setIsOpenManageFilterModal(false);
  }, [isDurty, organization_id, dispatch, table_id]);

  const onChangeManageFilter = useCallback((isDurty) => {
    setIsDurty(isDurty);
  }, []);

  const buttonTitle = useMemo(() => {
    if (!props.table) return '';
    const { type } = props.table;
    switch (type) {
      case LOOKUP_TYPE.contacts:
        return 'Add Contact';
      case LOOKUP_TYPE.companies:
        return 'Add Company';
      case LOOKUP_TYPE.deals:
        return 'Add Deal';
      case LOOKUP_TYPE.others:
        return 'Add Entry';
      default:
    }
  }, [props.table]);

  const tableType = useMemo(() => {
    if (!props.table) return '';
    const { type } = props.table;
    switch (type) {
      case LOOKUP_TYPE.contacts:
        return 'cn';
      case LOOKUP_TYPE.companies:
        return 'cm';
      case LOOKUP_TYPE.deals:
        return 'dl';
      case LOOKUP_TYPE.others:
        return 'others';
      default:
    }
  }, [props.table]);

  const handleSegmentClick = (x) => {
    props.props.history.push(`/${tableType}/${props.table?.name}/segments/${x?.id}`);
    OrganizationPreferencesSingleton.getInstance().setSettingValue(table_id, SEGMENT, x?.id);
    const type = getTableTypeForAnalytics(props?.table);
    trackSegmentNaviagateCollectionAnalyticActivity(type, x?.name, x?.count);
  };

  const onClickAddNew = useCallback(() => {
    //Check plan, if expired we can show upgrade popup
    const planType = getCurrentCompanyPlanType();
    if (planType === 'expired') {
      dispatch(setIsAppUpgradeToPro(true));
      return;
    }

    if (props.openAddModal) props.openAddModal();
    const type = getTableTypeForAnalytics(props.table);
    try {
      trackAnalyticActivity(`${type}: click new ${type}`);
    } catch (e) {
      console.log(`track click new ${type} Error`, e);
    }
  }, [props, dispatch]);

  const onClickExport = useCallback(() => {
    if (props?.exportData) props?.exportData();
    onCloseMenu();
  }, [onCloseMenu, props]);

  return (
    <>
      {mobile ? (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <ContactMobileMenu>
            <ContactMobileLeft>
              <DotDiv></DotDiv>
              <AllContactMenu onClick={openMoreMenu}>
                <AllMenuSpan mobile={mobile}>{selectedSegment?.name || ''}</AllMenuSpan>
                <ArrowUpDownImg src={ArrowUpDown} />
              </AllContactMenu>
            </ContactMobileLeft>
            <ContactMobileRight>
              <MobileButton
                iconName='icon-pluse'
                fontSize='14'
                iconColor='var(--white-color)'
                strokeWidth={2}
                size='small'
                primary={primary}
                onClick={onClickAddNew}
              />
              <VerticalIcon
                onClick={(e) => {
                  onOpenMenu(e);
                }}>
                <SVGIcon name='icon-dots-vertical' fill='var(--gray-icon-color)' size={24} />
              </VerticalIcon>
            </ContactMobileRight>
          </ContactMobileMenu>
        </MobileTitleBar>
      ) : (
        <MainHeader mobile={mobile}>
          <HeaderMainWrap>
            <LeftView>
              <AllMenuSpan>{screenName}</AllMenuSpan>
              <DotDiv></DotDiv>
              <AllContactMenu onClick={openMoreMenu} isMoreMenuOpen={isMoreMenuOpen}>
                <AllMenuSpan>{selectedSegment?.name || ''}</AllMenuSpan>
                <ArrowUpDownImg src={ArrowUpDown} />
              </AllContactMenu>
            </LeftView>
            <RightView>
              <FilterIcon onClick={handleFilterPanel} className={hasProduction() ? 'inDevelopment' : ''}>
                <SVGIcon
                  name='icon-filter-edit'
                  size={24}
                  fill='var(--gray-icon-color)'
                  bgfill={isFilterPanelOpen ? 'var(--disable-color)' : 'var(--white-color)'}
                />
              </FilterIcon>
              {userPermission?.permissionEdit && (
                <Button title={buttonTitle} size={size} primary={primary} onClick={onClickAddNew} />
              )}
              {!mobile && (
                <IconOnlyButton
                  isBtnIcon={true}
                  type='button'
                  className={'spacing'}
                  returnIcon={() => <SVGIcon name='icon-dots-vertical' fill='var(--gray-icon-color)' size={24} />}
                  onClick={(e) => {
                    onOpenMenu(e);
                  }}
                />
              )}
            </RightView>
          </HeaderMainWrap>
          {isFilterPanelOpen ? (
            <FilterPanelBox className={hasProduction() ? 'inDevelopment' : ''}>
              <FilterPanel />
            </FilterPanelBox>
          ) : null}
        </MainHeader>
      )}
      <Menu
        id='simple-menu'
        onClick={closeMoreMenu}
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenuOpen}
        onClose={closeMoreMenu}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        MenuListProps={{ disablePadding: true }}
        marginTop={mobile ? '35px' : '40px'}
        width='192px'>
        <MenuItemWrapper>
          {segments &&
            segments.map((segment, i) => {
              return (
                <MenuListItem key={segment?.id}>
                  <MenuItem
                    className={segment?.id === selectedSegment?.id ? 'MenuActive' : ''}
                    onClick={() => {
                      handleSegmentClick(segment);
                    }}
                    text={`${segment?.name}${segment?.count ? '(' + segment?.count + ')' : ''}`}
                  />
                </MenuListItem>
              );
            })}
          {segments?.length > 0 && <Border></Border>}
          <MenuItem
            onClick={() => {
              openNewFilterModal();
            }}
            iconName='icon-add-plus'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Add Filter'
          />
          <MenuItem
            onClick={openManageFilterModal}
            iconName='icon-customize'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Manage Filter'
          />
        </MenuItemWrapper>
      </Menu>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isContactMenuOpen || isCompanyMenuOpen || isDealsMenuOpen}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width='189px'
        MenuListProps={{ disablePadding: true }}
        marginTop='35px'>
        <MenuItemWrapper>
          <MenuItem
            iconName='icon-import'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Import'
            onClick={() => {
              history.push(`/settings/importdata?tableId=${table_id}`);
            }}
          />
          {userPermission?.permissionExport && (
            <MenuItem
              iconName='icon-export'
              fontSize='18'
              color='var(--gray-icon-color)'
              text='Export'
              onClick={onClickExport}
            />
          )}
          <MenuItem
            onClick={openManageColumsModal}
            iconName='icon-column-chooser'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Manage Columns'
          />
        </MenuItemWrapper>
      </Menu>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenManageColumsModal}
        onClose={closeManageColumns}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width='280px'
        MenuListProps={{ disablePadding: true }}
        marginTop='35px'>
        <ManageColumsModal handleCloseModal={closeManageColumns} {...props} />
      </Menu>
      <Modal
        open={isOpenNewFilterModal}
        style={Customstyle}
        onClose={closeNewFilterModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <AddNewFilter
          handleCloseModal={closeNewFilterModal}
          onUpdateFilter={onUpdateFilter}
          table={props?.table}
          table_id={table_id}
          item_id={selectedItemId}
        />
      </Modal>
      <Modal
        open={isOpenManageFilterModal}
        style={Customstyle}
        onClose={closeManageFilterModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <ManageFilterModal
          handleCloseModal={closeManageFilterModal}
          openNewFilterModal={openNewFilterModal}
          closeAddFilterModal={closeNewFilterModal}
          table_id={table_id}
          table={props?.table}
          onChange={onChangeManageFilter}
        />
      </Modal>
    </>
  );
};

export default Header;
