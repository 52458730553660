import styled, { css } from 'styled-components';
import Button from '../../../components/atom/Button/Button';
import { Header2, Header4, Text1 } from '../../../styles';

const MainList = styled.div`
  position: relative;
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          height: 100%;
        `
      : css`
          height: calc(100vh - 43px);
        `}
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
const MainWrap = styled.div`
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          padding: 0 16px 16px 16px;
        `
      : css`
          width: 680px;
          padding: 24px 0px;
        `}
`;
const ApiEmptyBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
`;
const HeadView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h2`
  margin: 0;
  ${Header2};
  color: var(--dark-color);
`;
const ContainBox = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  &:last-child {
    border-bottom: 0;
  }
`;
const LeftList = styled.div``;
const ListTitle = styled.h3`
  margin: 0 0 2px 0;
  ${Header4}
  color: var(--dark-color);
`;
const KeyTitle = styled.h4`
  margin: 0;
  ${Text1}
  color: var(--gray-color);
  word-break: break-all;
`;
const RightList = styled.div`
  display: flex;
  align-items: center;
  svg:last-child {
    margin-left: 12px;
  }
  svg {
    cursor: pointer;
  }
`;
const ModalWrapper = styled.div``;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
  margin-left: 12px;
`;
const InputWrapper = styled.div`
  padding: 16px;
`;
const Footer = styled.div`
  border-top: 1px solid var(--Platinum-color);
  padding: 16px;
`;
const ListBoxWebForm = styled(ListBox)`
  padding: 12px 16px;
`;
const TextWrapper = styled.div`
  margin-left: 8px;
`;
const SubText = styled.h4`
  margin: 0;
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  display: inline-block;
  cursor: pointer;
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1}
  color: var(--gray-color);
  margin: 0;
`;
const IconView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const MainContact = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          position: fixed;
          top: auto;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: var(--white-color);
        `
      : css`
          position: relative;
        `}
`;
const BottomView = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray-border-color);
  padding: 16px;
`;
const Form = styled.form``;

const ButtonWrapper = styled.div`
  margin-right: 12px;
`;
const InputView = styled.div`
  margin-bottom: 12px;
`;
const SecondContain = styled.div`
  padding: 16px;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export {
  MainList,
  MainWrap,
  HeadView,
  Title,
  ListBox,
  ContainBox,
  LeftList,
  KeyTitle,
  ListTitle,
  RightList,
  ModalWrapper,
  TitleView,
  HeaderWrapper,
  TitleText,
  InputWrapper,
  Footer,
  TextWrapper,
  SubText,
  CaptionText,
  IconView,
  MenuItemWrapper,
  ListBoxWebForm,
  MainContact,
  BottomView,
  Form,
  ButtonWrapper,
  InputView,
  SecondContain,
  SvgWrapper,
  MainMobileButton,
  MobileButton,
  ApiEmptyBlock
};
