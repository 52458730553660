import { useCallback, useMemo } from 'react';
import AttachmentView from './AttachmentView';

const AttachmentList = ({ attachments, onAttachmentRemove, isShowSize = false, downloadBaseUrl }) => {
  const onRemoveAttachment = useCallback(
    (index) => {
      onAttachmentRemove(index);
    },
    [onAttachmentRemove]
  );

  const attachmentList = useMemo(() => {
    return attachments?.filter((x) => !x.contentID);
  }, [attachments]);

  if (attachmentList) {
    return attachmentList?.map((attachment, index) => (
      <AttachmentView
        key={`attachment_${index}`}
        attachment={attachment}
        onAttachmentRemove={
          onAttachmentRemove
            ? () => {
                onRemoveAttachment(index);
              }
            : undefined
        }
        isShowSize={isShowSize}
        // eslint-disable-next-line max-len
        downloadUrl={
          downloadBaseUrl
            ? `${downloadBaseUrl}&contentType=${attachment.contentType}&name=${attachment.name}&id=${attachment.id}`
            : undefined
        }
      />
    ));
  }
  return null;
};

export default AttachmentList;
