import React, { useEffect, useMemo, useState, useCallback } from 'react';
// import Modal from '@material-ui/core/Modal';
import { useSelector } from 'react-redux';
import { toLower } from 'lodash';
import SendEmailModal from '../../Pages/Email/SendEmailModal/SendEmailModal';
import Modal from '../../components/Modal/index';
import { EMAIL_LABEL_ID, EMAIL_PROVIDER_TYPE, SEND_EMAIL_TYPE } from '../../constants/constant';
import { trackAnalyticActivity } from '../../services/analyticsService';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import {
  MainNav,
  NavContain,
  TopView,
  TextView,
  SpanText,
  LeftView,
  RightView,
  IconView,
  CenterView,
  Ul,
  Li,
  SpanList,
  BottomView,
  TitleView,
  LeftText,
  HrLine,
  ListView,
  Li2,
  Alist2,
  SpanList2,
  RightSpan,
  MenuItemLink,
  MenuItemLink2,
  Li3,
  SpanView,
  SpanList3,
  RightSpan3,
  MenuItemLinkTwo,
  UlTwo,
  ModalStyle,
  HelpBlock,
  HelpSpanList,
  NavIcon,
  TopLinear,
  BottmLinear
} from './styles';

export const trackEmailNavigateAnalyticActivity = (location, label) => {
  try {
    trackAnalyticActivity('email: navigate', { location: location, label: label });
  } catch (e) {
    console.log('track email: navigate Error', e);
  }
};

const EmailNavbar = (props) => {
  const { onCloseNavbar, onOpenHelpModal, isOpenHelpModal } = props;
  const mobile = useMobileDevice();
  const [emailLabel, setEmailLabel] = useState();
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const emailSelector = useSelector((state) => state.email);
  const { emailLabelList: labels, emailAccounts } = emailSelector;

  const accounts = useMemo(() => {
    const accounts = emailAccounts?.filter((x) => x?.isEmailSync && x.accountType === EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC);
    if (accounts) {
      return accounts;
    }
  }, [emailAccounts]);

  const emailLabels = useMemo(() => {
    if (!labels) return {};
    const inboxLabel = labels.find((x) => x.id === EMAIL_LABEL_ID.INBOX);
    const sentLabel = labels.find((x) => x.id === EMAIL_LABEL_ID.SENT);
    const starredLabel = labels.find((x) => x.id === EMAIL_LABEL_ID.STARRED);
    const trashLabel = labels.find((x) => x.id === EMAIL_LABEL_ID.TRASH);
    const draftLabel = labels.find((x) => x.id === EMAIL_LABEL_ID.DRAFT);
    const archiveLabel = labels.find((x) => x.id === EMAIL_LABEL_ID.ARCHIVE);

    const excludeFields = [
      EMAIL_LABEL_ID.INBOX,
      EMAIL_LABEL_ID.SENT,
      EMAIL_LABEL_ID.STARRED,
      EMAIL_LABEL_ID.TRASH,
      EMAIL_LABEL_ID.DRAFT,
      EMAIL_LABEL_ID.ARCHIVE
    ];
    const labelToDisplay = labels
      .map((item) => {
        return {
          ...item
        };
      })
      .filter((x) => excludeFields.indexOf(x.id) < 0);
    return { starredLabel, sentLabel, inboxLabel, trashLabel, draftLabel, archiveLabel, labelToDisplay };
  }, [labels]);
  const openSendEmailModal = () => {
    try {
      trackAnalyticActivity('email: click new email');
    } catch (e) {
      console.log('track Send Email Error', e);
    }
    setIsOpenSendEmailModal(true);
  };
  const closeSendEmailModal = () => {
    setIsOpenSendEmailModal(false);
  };

  useEffect(() => {
    setEmailLabel(emailLabels?.inboxLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackEmailNavigate = useCallback((id, name) => {
    let location;
    let label = '';
    if (id === 'All Inboxes') {
      location = 'inbox';
      label = 'all';
    } else if (id === 'Accounts') {
      location = 'inbox';
      label = name;
    } else if (id === 'Starred') location = 'starred';
    else if (id === 'Draft') location = 'draft';
    else if (id === 'Trash') location = 'trash';
    else if (id === 'Sent') location = 'sent';
    else if (id === 'Archive') location = 'archive';
    else if (id === 'Label') {
      location = 'label';
      label = name;
    }
    trackEmailNavigateAnalyticActivity(location, label);
  }, []);

  const RenderLabels = ({ iconName, item, name }) => {
    return (
      <Li
        onClick={() => {
          setEmailLabel(item);
          onCloseNavbar();
        }}
        key={item?.id}>
        <MenuItemLink
          mobile={mobile}
          to={`/email/segments/${toLower(item?.id)}`}
          activeClassName={item?.id === emailLabel?.id && 'active_menu'}
          onClick={() => {
            trackEmailNavigate(name);
          }}>
          <Alist2>
            <SVGIcon name={iconName} size={18} fill='var(--white-color)' bgfill='var(--icon-fill)' strokeWidth={2} />
            <SpanView>
              <SpanList mobile={mobile}>{item?.display_name || name}</SpanList>
              {item?.unreadCount > 0 && <RightSpan>{item?.unreadCount}</RightSpan>}
            </SpanView>
          </Alist2>
        </MenuItemLink>
        <Li3>
          {item.id === 'INBOX' &&
            accounts?.length > 1 &&
            accounts?.map((account, index) => {
              return (
                <>
                  <MenuItemLinkTwo
                    mobile={mobile}
                    key={`email_account_${account?.id}_${index}`}
                    to={`/email/segments/inbox?accountId=${account?.id}`}
                    activeClassName='active_menu'
                    isActive={(match, location) => location?.search === `?accountId=${account?.id}`}
                    onClick={() => {
                      trackEmailNavigate('Accounts', account?.email);
                    }}>
                    <SpanList3 title={account?.email}>{account?.email}</SpanList3>
                    {account?.unreadCount > 0 && <RightSpan3>{account?.unreadCount}</RightSpan3>}
                  </MenuItemLinkTwo>
                </>
              );
            })}
        </Li3>
      </Li>
    );
  };
  return (
    <>
      <MainNav mobile={mobile}>
        <NavContain>
          <TopLinear></TopLinear>
          <BottmLinear></BottmLinear>
          <TopView>
            <LeftView onClick={props.onClickBack} mobile={mobile}>
              <SVGIcon name='icon-big-left-arrow' fill='var(--white-color)' size={mobile ? 14 : 12} strokeWidth={2} />
              <TextView>
                <SpanText>Back</SpanText>
              </TextView>
            </LeftView>
            <RightView>
              <IconView onClick={openSendEmailModal} title={'Compose'} mobile={mobile}>
                <SVGIcon name='icon-edit' fill='var(--white-color)' size={mobile ? 18 : 20} strokeWidth={2} />
              </IconView>
            </RightView>
          </TopView>
          <CenterView>
            <Ul>
              {emailLabels.inboxLabel && (
                <RenderLabels iconName='icon-inbox' name={'All Inboxes'} item={emailLabels.inboxLabel} />
              )}
              {emailLabels.starredLabel && (
                <RenderLabels iconName='icon-star' name={'Starred'} item={emailLabels.starredLabel} />
              )}
              {emailLabels.sentLabel && (
                <RenderLabels iconName='icon-share' name={'Sent'} item={emailLabels.sentLabel} />
              )}
              {emailLabels.draftLabel && (
                <RenderLabels iconName='icon-draft' name={'Draft'} item={emailLabels.draftLabel} />
              )}
              {emailLabels.archiveLabel && (
                <RenderLabels iconName='icon-essential' name={'Archive'} item={emailLabels.archiveLabel} />
              )}
              {emailLabels.trashLabel && (
                <RenderLabels iconName='icon-delete' name={'Trash'} item={emailLabels.trashLabel} />
              )}
            </Ul>
            <BottomView>
              <TitleView>
                <LeftText>labels</LeftText>
                <HrLine></HrLine>
              </TitleView>
              <ListView>
                <UlTwo>
                  {emailLabels &&
                    emailLabels?.labelToDisplay?.map((item, index) => {
                      return (
                        <Li2
                          key={`emailLabels_${item?.id}_${index}`}
                          onClick={() => {
                            setEmailLabel(item);
                            trackEmailNavigate('Label', item?.display_name);
                            onCloseNavbar();
                          }}>
                          <MenuItemLink2 to={`/email/segments/${toLower(item?.id)}`} activeClassName='active_menu'>
                            <Alist2>
                              <SpanView>
                                <SpanList2>{item?.display_name}</SpanList2>
                                {item?.unreadCount > 0 && <RightSpan>{item?.unreadCount}</RightSpan>}
                              </SpanView>
                            </Alist2>
                          </MenuItemLink2>
                        </Li2>
                      );
                    })}
                </UlTwo>
              </ListView>
            </BottomView>
          </CenterView>
          <HelpBlock
            activeClassName='active_menu'
            mobile={mobile}
            onClick={onOpenHelpModal}
            isOpenHelpModal={isOpenHelpModal}>
            <NavIcon name='icon-feedback' help />
            <HelpSpanList help>Help & Feedback</HelpSpanList>
          </HelpBlock>
        </NavContain>
      </MainNav>
      <Modal open={isOpenSendEmailModal} onClose={closeSendEmailModal} style={ModalStyle}>
        <SendEmailModal handleCloseModal={closeSendEmailModal} type={SEND_EMAIL_TYPE.COMPOSE} />
      </Modal>
    </>
  );
};

export default EmailNavbar;
