import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Header6, Text1, Text2 } from '../../styles';
import SVGIcon from '../../assets/svg/SVGIcon';
import { isElectronApp, isMACApp } from '../../global/Helper';

const MainNav = styled.div`
  height: 100%;
  width: 200px;
  background-color: var(--nav-dark-color);
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
  ${(props) =>
    props.mobile
      ? css``
      : css`
          box-shadow: 0.5px 0px 0px var(--Platinum-color);
        `}
`;
const NavContain = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  ${() =>
    isElectronApp() &&
    css`
      padding-top: 32px;
    `}
  ${() =>
    !isMACApp() &&
    css`
      padding-top: 0px;
    `}
`;
const TopView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 8px 8px 0 8px;
`;
const TopLinear = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;
  z-index: 0;
  background: rgb(32, 38, 50);
  background: linear-gradient(0deg, rgba(32, 38, 50, 0) 0%, rgba(32, 38, 50, 1) 30%, rgba(32, 38, 50, 1) 100%);
`;
const BottmLinear = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 0;
  background: rgb(32, 38, 50);
  background: linear-gradient(180deg, rgba(32, 38, 50, 0) 0%, rgba(32, 38, 50, 1) 30%, rgba(32, 38, 50, 1) 100%);
`;
const TextView = styled.div`
  margin-left: 8px;
  display: inline-flex;
`;
const SpanText = styled.span`
  font-style: normal;
  color: var(--white-color);
  ${Text1}
`;
const LeftView = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 7px 19px 7px 10px;
  :hover {
    background: var(--titlebar-bg);
  }
  i {
    -webkit-text-stroke: 0.3px;
    text-shadow: var(--dark-color);
  }
  ${(props) =>
    props.mobile &&
    css`
      background: var(--titlebar-bg);
    `}
`;
const RightView = styled.div``;
const IconView = styled.div`
  background: var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.mobile
      ? css`
          width: 24px;
          height: 24px;
        `
      : css`
          width: 26px;
          height: 26px;
        `}
`;
const CenterView = styled.div`
  padding-top: 20px;
  flex: 1 1 auto;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  svg {
    flex: 0 0 18px;
  }
`;
const Li = styled.li`
  margin: 0 8px 8px 8px;
  border-radius: 6px;
  i {
    -webkit-text-stroke: 0.3px;
    text-shadow: var(--dark-color);
  }
  :last-child {
    margin-bottom: 0;
  }
`;
const Alist = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 10px 14px;
  text-decoration: none;
  border-radius: 6px;
  color: #3a3e42;
  cursor: pointer;
  &:hover {
    background: var(--Platinum-color);
  }
  &:active {
    background: var(--Platinum-color);
  }
`;
const SpanList = styled.span`
  color: var(--white-color);
  margin-left: 16px;
  ${Text1}
`;
const BottomView = styled.div``;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 18px 16px 18px;
`;
const LeftText = styled.span`
  color: var(--Shuttle-Gray);
  text-transform: uppercase;
  ${Header6};
`;
const HrLine = styled.div`
  height: 1px;
  background: var(--collection-linear);
  width: 100%;
  margin-left: 8px;
`;
const ListView = styled.div``;
const Li2 = styled.li`
  margin: 0 8px 8px 8px;
  padding: 7px 10px;
  :last-child {
    margin-bottom: 0;
  }
`;
const Li3 = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Alist2 = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
`;
const SpanView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  :hover {
    span:first-child {
      color: var(--white-color);
    }
  }
`;
const SpanList2 = styled.span`
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--gray-color);
  ${Text1};
  :active {
    color: var(--dark-color);
  }
`;
const RightSpan = styled.span`
  font-style: normal;
  ${Text2};
  color: var(--gray-color);
  :active {
    color: var(--dark-color);
  }
`;
const MenuItemLink = styled(NavLink)`
  :hover {
    background: var(--titlebar-bg);
  }
  &.active_menu {
    background: var(--nav-dark-color-focus);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--Platinum-color);
    span {
      color: var(--white-color);
    }
  }
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 1;
  border-radius: 6px;
  cursor: pointer;
  padding: 7px 10px;
`;
const MenuItemLink2 = styled(NavLink)`
  display: block;
  &.active_menu {
    font-style: normal;
    ${Text1};
    color: var(--dark-color);
    p {
      color: var(--dark-color);
    }
    span {
      color: var(--white-color);
    }
  }
  text-decoration: none;
`;
const MenuItemLinkTwo = styled(NavLink)`
  margin: 8px 0px 0px 0px;
  display: flex;
  border-radius: 6px;
  :hover {
    background: var(--titlebar-bg);
  }
  :active {
    p {
      color: var(--white-color);
    }
    span {
      color: var(--white-color);
    }
  }
  &.active_menu {
    font-style: normal;
    background: var(--nav-dark-color-focus);
    ${Text1}
    color: var(--white-color);
    p {
      color: var(--white-color);
    }
    span {
      color: var(--white-color);
    }
  }
  text-decoration: none;
  padding: 7px 10px;
`;
const UlTwo = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 432px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const SpanList3 = styled.p`
  font-style: normal;
  margin: 0;
  ${Text1};
  color: var(--white-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  :active {
    color: var(--dark-color);
  }
`;
const RightSpan3 = styled.span`
  ${Text2};
  font-style: normal;
  color: var(--disable-color);
  margin-left: auto;
  :active {
    color: var(--dark-color);
  }
`;

const ModalStyle = {
  width: '640px'
};

const SmallModalStyle = {
  width: '360px'
};

const HelpBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 7px 10px;
  margin: 0px 8px 8px 8px;
  position: relative;
  z-index: 2;
  :hover {
    background-color: var(--titlebar-bg);
  }
  ${(props) =>
    props.isOpenHelpModal &&
    css`
      svg {
        path {
          stroke: var(--disable-color);
        }
      }
      background-color: var(--nav-dark-color-focus);
      span {
        color: var(--disable-color);
      }
    `}
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--nav-dark-color-focus);
      svg {
        path {
          stroke: var(--disable-color);
        }
      }
      span {
        color: var(--disable-color);
      }
      :hover {
        background-color: var(--nav-dark-color-focus);
        span {
          color: var(--disable-color);
        }
      }
    `}
`;
const NavIcon = styled(SVGIcon).attrs(({ help }) => ({
  size: '18',
  fill: help ? 'var(--gray-color)' : 'var(--white-color)',
  strokeWidth: '2'
}))``;

const HelpSpanList = styled.span`
  color: var(--white-color);
  margin-left: 16px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${Text1}
  ${(props) =>
    props.help &&
    css`
      color: var(--gray-color);
      margin-left: 14px;
    `}
`;

export {
  MainNav,
  NavContain,
  MenuItemLink,
  MenuItemLink2,
  TopView,
  TextView,
  SpanText,
  LeftView,
  RightView,
  IconView,
  CenterView,
  Ul,
  Li,
  Alist,
  SpanList,
  BottomView,
  TitleView,
  LeftText,
  HrLine,
  ListView,
  Li2,
  Alist2,
  SpanList2,
  RightSpan,
  Li3,
  SpanView,
  SpanList3,
  MenuItemLinkTwo,
  RightSpan3,
  UlTwo,
  ModalStyle,
  HelpBlock,
  NavIcon,
  HelpSpanList,
  SmallModalStyle,
  TopLinear,
  BottmLinear
};
