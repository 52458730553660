import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Dashboard Detail
 */
export const setDashboardDetail = createAction(Actions.SET_DASHBOARD_DETAIL);
/**
 * @desc Set Dashboard Loader
 */
export const setDashboardLoader = createAction(Actions.SET_DASHBOARD_LOADER);
/**
 * @desc set App No Wait Loader
 */
export const setDataLoader = createAction(Actions.SET_DATA_LOADER);
/**
 * @desc update dashboard activity
 */
export const updateDashboardActivity = createAction(Actions.UPDATE_DASHBOARD_ACTIVITY_ITEM);
/**
 * @desc set Dashboard OnBoarding
 */
export const setDashboardOnBoarding = createAction(Actions.SET_DASHBOARD_ONBOARDING);
/**
 * @desc set Contact Suggestions
 */
export const setContactSuggestions = createAction(Actions.SET_CONTACT_SUGGESTIONS);
/**
 * @desc delete Contact Suggestion Item
 */
export const deleteContactSuggestionItem = createAction(Actions.DELETE_CONTACT_SUGGESTION_ITEM);
/**
 * @desc set Contact Suggestions
 */
export const setContactDuplication = createAction(Actions.SET_CONTACT_DUPLICATION);
/**
 * @desc set Merge Contact Details
 */
export const setMergeContactDetails = createAction(Actions.SET_MERGE_CONTACT_DETAILS);
/**
 * @desc update Merge Contact Details
 */
export const updateMergeContactDetails = createAction(Actions.UPDATE_MERGE_CONTACT_DETAILS);
/**
 * @desc Clear Dashboard Data
 */
export const clearDashboardData = () => (dispatch) => {
  dispatch(setDashboardDetail(null));
  dispatch(setDashboardLoader(false));
};
