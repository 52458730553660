import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const notificationLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_NOTIFICATION_LOADER
});
const notificationListReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_NOTIFICATION_LIST
});

const notificationReducer = combineReducers({
  loading: notificationLoaderReducer,
  list: notificationListReducer
});

export default notificationReducer;
