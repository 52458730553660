import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { isEmpty } from '../../helpers/common';
import { Icon as Icon1 } from '../../components/Icon/Icon';

import { ReactComponent as TrashIcon } from '../../assets/images/svg/trashicont.svg';
import SVGIcon from '../../assets/svg/SVGIcon';

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      options,
      value,
      isDisabled,
      onChange,
      labelField = 'label',
      valueField = 'value',
      hasObject,
      hasCompany,
      removeIcon,
      isMulti = false,
      ...props
    } = this.props;

    const Icon = ({ innerRef }) => (
      <span className='iconsvg' role='img' aria-label='Dropdown emoji' ref={innerRef}>
        <SVGIcon name='icon-down-arrow' size={20} fill='var(--gray-icon-color)' />
      </span>
    );
    const MultiValueRemove = (props) => {
      return (
        <components.MultiValueRemove {...props}>
          {removeIcon ? (
            <Icon1 name={removeIcon} fontSize={this.props?.removeIconSize} color={'var(--gray-icon-color)'} />
          ) : (
            <TrashIcon />
          )}
        </components.MultiValueRemove>
      );
    };

    const style = {
      option: (base) => ({
        ...base,
        whiteSpace: 'pre-wrap',
        fontSize: 14,
        color: 'var(--dark-color)',
        '&:active': {
          background: ' var(--popup-color-hover)'
        },
        '&:hover': {
          background: ' var(--popup-color-hover)'
        }
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: 14,
        color: '#000'
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '1px 8px'
      }),
      control: (base, { isFocused }) => ({
        ...base,
        // This line disable the blue border
        boxShadow: 'none',
        minHeight: '34px',
        borderRadius: '4px',
        borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5',
        borderWidth: isFocused ? '2px' : '1px',
        margin: isFocused ? '0px -1px' : '0px',
        ':hover': {
          borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5'
        }
        // borderColor: '#E4E4E5'
      }),

      indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
      }),
      menuList: (base) => ({
        ...base,
        maxHeight: '140px'
      }),

      indicatorsContainer: (base) => ({
        ...base,
        marginRight: '12px',
        '.iconsvg': {
          display: 'flex'
        }
      }),

      placeholder: (base) => ({
        ...base,
        fontSize: '14px'
      })
    };

    const items = options
      ? options.map((item) => {
          return {
            ...item,
            label: item[labelField],
            value: item[valueField]
          };
        })
      : [];

    let valueItem = null;
    if (!isEmpty(value)) {
      if (hasObject) {
        valueItem = {
          ...value,
          label: value[labelField],
          value: value[valueField]
        };
      } else if (isMulti) {
        valueItem = [];
        value.forEach((item) => {
          let optionValue = items.find((x) => x.value === item[valueField]);
          valueItem.push(optionValue);
        });
      } else {
        valueItem = items.find((x) => x.value === value);
      }
    }
    return (
      <Select
        options={items}
        styles={style}
        value={valueItem}
        onChange={onChange}
        isMulti={isMulti}
        // menuIsOpen={true}
        menuPosition='fixed'
        {...props}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'var(--popup-color-hover)',
            primary: 'var(--popup-color-hover)'
          }
        })}
        isDisabled={isDisabled}
        components={{ MultiValueRemove, DropdownIndicator: Icon }}
      />
    );
  }
}
export default DropDown;
