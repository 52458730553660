import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal/index';
import Button from '../../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../../components/Menu';
import { LOOKUP_TYPE } from '../../../constants/constant';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { deleteLookupTableItem, getLookupTables } from '../../../services/lookupTableServices';
import { setWarningMessage } from '../../../actions/messageActions';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { trackCustomizeCollectionAnalyticActivity } from '../../../services/settingService';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import ModifyCustomizeModal from './ModifyCustomizeModal';
import {
  PageWrapper,
  MainList,
  Header,
  Title,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  IconWrapper,
  LoginDetails,
  MenuItemWrapper,
  MainMobileButton,
  MobileButton
} from './styles';

const Customize = (props) => {
  const search = props.location.search;
  const state = props.location.state;
  const isNew = new URLSearchParams(search).get('isNew');
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list } = lookupTableSelector;

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState('');
  const [isEdit, setIsEdit] = useState(true);
  const [uniqId, setUniqId] = useState();

  const trackCustomizeActionAnalyticActivity = useCallback((action, collectionName) => {
    try {
      trackAnalyticActivity('settings - customize: action', { action: action, 'collection name': collectionName });
    } catch (e) {
      console.log('track settings - customize: action Error', e);
    }
  }, []);

  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const loadData = useCallback(() => {
    dispatch(getLookupTables(organization_id, true));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onOpenMenu = useCallback((e, item) => {
    e.stopPropagation();
    setSelectedTable(item);
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);
  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
    setSelectedTable();
  }, []);

  const onOpenEditMenu = useCallback(() => {
    setIsMenuOpen(false);
    setIsEditMenuOpen(true);
    setMenuAnchorEl(null);
    if (selectedTable?.id) {
      trackCustomizeActionAnalyticActivity('edit', selectedTable?.name);
    } else {
      trackCustomizeActionAnalyticActivity('add');
    }
  }, [trackCustomizeActionAnalyticActivity, selectedTable]);

  const onCloseEditMenu = useCallback(() => {
    setIsEditMenuOpen(false);
    setMenuAnchorEl(null);
    setSelectedTable();
  }, []);

  useEffect(() => {
    if (uniqId !== state?.uniqId) {
      setUniqId(state?.uniqId);
    }
    if ((state?.uniqId !== uniqId || isEdit) && isNew) {
      onOpenEditMenu();
      setIsEdit(false);
    }
  }, [isEdit, isNew, onOpenEditMenu, state?.uniqId, uniqId]);

  const getLookupIcon = (item) => {
    const { type } = item;
    let iconName;
    let bgColor;
    switch (type) {
      case LOOKUP_TYPE.contacts:
        iconName = 'icon-user';
        bgColor = '#8185E0';
        break;
      case LOOKUP_TYPE.companies:
        iconName = 'icon-account';
        bgColor = '#F9A779';
        break;
      case LOOKUP_TYPE.deals:
        iconName = 'icon-deals';
        bgColor = '#00AEFF';
        break;
      case LOOKUP_TYPE.others:
        iconName = 'icon-other';
        bgColor = '#8B60B6';
        break;
      default:
    }
    return (
      <IconWrapper bgColor={bgColor}>
        <SVGIcon name={iconName} fill='var(--white-color)' size={20} />
      </IconWrapper>
    );
  };

  const onViewCollectionDetails = useCallback(
    (item) => {
      trackCustomizeActionAnalyticActivity('click', item?.name);
      props.history.push(`/settings/customize/${item?.name}`);
    },
    [trackCustomizeActionAnalyticActivity, props]
  );

  const onDeleteTemplate = useCallback(async () => {
    onCloseMenu();
    trackCustomizeActionAnalyticActivity('delete', selectedTable?.name);
    setSelectedTable();
    if (selectedTable?.fieldCounts > 0) {
      dispatch(setWarningMessage('You cannot delete lookup table having Records'));
      return;
    }
    const result = await dispatch(deleteLookupTableItem(organization_id, selectedTable?.id));
    if (result) {
      trackCustomizeCollectionAnalyticActivity('deleted', selectedTable?.name, selectedTable?.type);
    }
  }, [onCloseMenu, dispatch, trackCustomizeActionAnalyticActivity, organization_id, selectedTable]);

  return (
    <PageWrapper>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <MainMobileButton>
            <MobileButton
              iconName='icon-pluse'
              fontSize='14'
              iconColor='var(--white-color)'
              strokeWidth={2}
              size='small'
              onClick={() => {
                onOpenEditMenu();
                setSelectedTable(null);
              }}
            />
          </MainMobileButton>
        </MobileTitleBar>
      )}
      <MainList mobile={mobile}>
        {mobile ? null : (
          <Header>
            <Title>Customize</Title>
            <Button
              isPrimary={true}
              title='Add Collection'
              onClick={() => {
                onOpenEditMenu();
                setSelectedTable(null);
              }}
            />
          </Header>
        )}
        <ContainBoxTwo mobile={mobile}>
          {list?.map((item) => {
            return (
              <ListBox
                mobile={mobile}
                onClick={() => {
                  onViewCollectionDetails(item);
                }}
                key={item?.id}>
                <FirstList>
                  {getLookupIcon(item)}
                  <LoginDetails>
                    <SubText>{item?.label}</SubText>
                    <CaptionText>{item?.fieldCounts} Records</CaptionText>
                  </LoginDetails>
                </FirstList>
                <IconView
                  onClick={(e) => {
                    onOpenMenu(e, item);
                  }}>
                  <SVGIcon name='icon-more' fill='var(--gray-color)' size={24} />
                </IconView>
              </ListBox>
            );
          })}
        </ContainBoxTwo>
        <Menu
          id='simple-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={onCloseMenu}
          getContentAnchorEl={null}
          position='left'
          positionVertical='top'
          width={132}
          marginTop={25}
          MenuListProps={{ disablePadding: true }}>
          <MenuItemWrapper>
            <MenuItem
              iconName='icon-edit-pen'
              color='var(--gray-icon-color)'
              fontSize='18px'
              text='Edit'
              onClick={onOpenEditMenu}
            />
            <MenuItem
              iconName='icon-delete'
              color='var(--gray-icon-color)'
              fontSize='18px'
              text='Delete'
              onClick={() => {
                ConfirmDialog({
                  iconName: 'icon-delete',
                  closeIcon: 'icon-close',
                  title: 'Delete?',
                  message: `Press ok to confirm delete this Lookup table`,
                  onOk: () => {
                    onDeleteTemplate();
                  }
                });
              }}
            />
          </MenuItemWrapper>
        </Menu>
        <Modal open={isEditMenuOpen} onClose={onCloseEditMenu}>
          <ModifyCustomizeModal tableId={selectedTable?.id} handleCloseModal={onCloseEditMenu} />
        </Modal>
        <SnackBar />
      </MainList>
    </PageWrapper>
  );
};

export default Customize;
