import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../../components/Menu';
import {
  deleteEmailAccount,
  getEmailAccounts,
  getEmailList,
  SyncEmailAccounts,
  trackAddEmailAccountAnalyticActivity
} from '../../../services/emailService';
import { EMAIL_PROVIDER_TYPE, TOOLTIP_DATE_FORMAT } from '../../../constants/constant';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import Modal from '../../../components/Modal';
import { MenuItemWrapper } from '../EmailTemplate/styles';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import UserSelectionMenu from '../../../components/UserMenu/UserSelectionMenu';
import { updateEmailAccountList } from '../../../actions/emailActions';
import { addUserInEmailAccount, deleteUserInEmailAccount } from '../../../services/settingService';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import ChooseEmailProvider from './ChooseEmailProvider';
import {
  MainList,
  MainWrap,
  HeadView,
  Title,
  // PrimaryText,
  ContainBox,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  SecondList,
  LeftIcon,
  RightIcon,
  FirstRow,
  MainPopup,
  SetText,
  ModalStyle,
  SvgWrapper,
  UserShareBlock,
  SyncMainBlock,
  SyncTimeBlock,
  TimeSapn,
  Circle,
  ButtonWrap,
  IconButton,
  InvalidAccountBlock,
  MobileButton
} from './styles';
import SyncSettings from './SyncSettings';

const EmailAccounts = (props) => {
  const mobile = useMobileDevice();
  const search = props.location.search;
  const id = new URLSearchParams(search).get('syncsettings');
  const isNew = new URLSearchParams(search).get('isNew');
  const emailSelector = useSelector((state) => state.email);
  const authSelector = useSelector((state) => state.auth);
  const { emailAccounts, loading: emailLoading } = emailSelector;
  const { currentUser } = authSelector;

  //Set email account if already exist or pass as blank object with id to open settings for first time link
  const emailAccount = id ? emailAccounts.find((x) => x?.id === id) || { id } : undefined;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProviderMenuOpen, setIsProviderMenuOpen] = useState(false);
  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false);
  const [isSyncSettingsModalOpen, setIsSyncSettingsModalOpen] = useState(emailAccount ? true : false);
  const [selectedItem, setSelectedItem] = useState(emailAccount);
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id, users: orgUsers } = organization;

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getEmailAccounts(organization_id, true));
  }, [dispatch, organization_id]);

  const trackAddEmailAnalyticActivity = useCallback(() => {
    if (id) {
      trackAddEmailAccountAnalyticActivity('two way sync');
    }
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    trackAddEmailAnalyticActivity();
  }, [trackAddEmailAnalyticActivity]);

  const onOpenMenu = useCallback((e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const DeleteMenuOpen = useCallback((e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setIsDeleteMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseDeleteMenu = useCallback(() => {
    setIsDeleteMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const trackEmailActionAnalyticActivity = useCallback((action, emailId) => {
    try {
      trackAnalyticActivity('settings - email: action', { action: action, 'email id': emailId });
    } catch (e) {
      console.log('track settings - email: action Error', e);
    }
  }, []);

  const onOpenProviderMenu = useCallback(() => {
    setIsProviderMenuOpen(true);
    trackEmailActionAnalyticActivity('add');
  }, [trackEmailActionAnalyticActivity]);

  const onCloseProviderMenu = useCallback(() => {
    setIsProviderMenuOpen(false);
  }, []);

  useEffect(() => {
    if (isNew) {
      onOpenProviderMenu();
    }
  }, [isNew, onOpenProviderMenu]);

  const onOpenSyncSettingsModal = useCallback(() => {
    onCloseMenu();
    setIsSyncSettingsModalOpen(true);
    trackEmailActionAnalyticActivity('sync setting', selectedItem?.email);
  }, [onCloseMenu, trackEmailActionAnalyticActivity, selectedItem?.email]);

  const onCloseSyncSettingsModal = useCallback(() => {
    setIsSyncSettingsModalOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onDeleteAccount = useCallback(async () => {
    onCloseDeleteMenu();
    const result = await dispatch(deleteEmailAccount(organization_id, selectedItem?.id));
    if (result) {
      trackEmailActionAnalyticActivity('delete', selectedItem?.email);
    }
  }, [onCloseDeleteMenu, dispatch, trackEmailActionAnalyticActivity, organization_id, selectedItem]);

  const getUserListToDisplay = useCallback(
    (users) => {
      const prevUsers = users?.filter((item) => !orgUsers.find((obj) => obj.id === item?.id));
      const prevUsersList = prevUsers?.map((user) => {
        return { ...user, isSelected: true };
      });
      const currentUsers = orgUsers?.map((user) => {
        const isSelectedIndex = users?.findIndex((item) => item?.id === user?.id);
        if (isSelectedIndex !== -1) {
          return {
            ...user,
            isSelected: true
          };
        } else {
          return {
            ...user,
            isSelected: false
          };
        }
      });
      return [...prevUsersList, ...currentUsers];
    },
    [orgUsers]
  );

  const onChangeUserSelection = useCallback(
    async (val, userId, emailAccountId) => {
      const userItem = orgUsers?.find((item) => item?.id === userId);
      userItem && dispatch(updateEmailAccountList({ emailAccountId: emailAccountId, userItem: userItem }));
      if (val) {
        await dispatch(deleteUserInEmailAccount(organization_id, emailAccountId, userId));
      } else {
        const payload = {
          userId: userId
        };
        await dispatch(addUserInEmailAccount(organization_id, emailAccountId, payload));
      }
    },
    [dispatch, organization_id, orgUsers]
  );

  const setSSLByEmailAccountType = (item) => {
    const accountType = item?.accountType;
    switch (accountType) {
      case EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC:
        return 'Two Way Sync';
      case EMAIL_PROVIDER_TYPE.SAND_ONLY:
        return 'SMTP, Send Only';
      case EMAIL_PROVIDER_TYPE.ALIAS:
        return 'Alias';
      default:
    }
  };

  useEffect(() => {
    if (isNew) {
      onOpenProviderMenu();
    }
  }, [isNew, onOpenProviderMenu]);

  const onResyncEmails = useCallback(async () => {
    await dispatch(SyncEmailAccounts(organization_id));
    await dispatch(getEmailList(organization_id, undefined, undefined, true));
    loadData();
  }, [dispatch, organization_id, loadData]);

  return (
    <>
      <MainList>
        {mobile && (
          <MobileTitleBar hasNavBar={true} hasTitle={true}>
            <ButtonWrap mobile={mobile}>
              <IconButton
                mobile={mobile}
                onClick={onResyncEmails}
                iconName='icon-refresh'
                iconColor='var(--gray-icon-color)'
                fontSize={16}
                secondary
                size='small'
                type='button'
                disabled={emailLoading}
              />
              <MobileButton
                iconName='icon-pluse'
                fontSize='14'
                iconColor='var(--white-color)'
                strokeWidth={2}
                size='small'
                onClick={(e) => {
                  onOpenProviderMenu(e);
                }}
              />
            </ButtonWrap>
          </MobileTitleBar>
        )}
        <MainWrap mobile={mobile}>
          {mobile ? null : (
            <HeadView>
              <Title>Email Accounts</Title>
              <ButtonWrap>
                <IconButton
                  onClick={onResyncEmails}
                  iconName='icon-refresh'
                  iconColor='var(--gray-icon-color)'
                  fontSize={16}
                  secondary
                  size='small'
                  type='button'
                  disabled={emailLoading}
                />
                <Button
                  title='Add Email Account'
                  onClick={(e) => {
                    onOpenProviderMenu(e);
                  }}
                />
              </ButtonWrap>
            </HeadView>
          )}
          <ContainBox>
            {emailAccounts?.map((item) => {
              const userList = getUserListToDisplay(item?.users);
              return (
                <ListBox key={item?.id} mobile={mobile}>
                  <FirstList mobile={mobile}>
                    <FirstRow mobile={mobile}>
                      <SubText mobile={mobile}>{item?.email}</SubText>
                      <UserShareBlock>
                        <UserSelectionMenu
                          userList={userList || []}
                          itemId={item?.id}
                          onChange={(val, userId, emailAccountId) => {
                            onChangeUserSelection(val, userId, emailAccountId);
                          }}
                          currentUserId={currentUser?.id}
                        />
                      </UserShareBlock>
                      {/* {item?.isPrimary && <PrimaryText mobile={mobile}>Primary Email</PrimaryText>} */}
                    </FirstRow>
                    <SyncMainBlock>
                      <CaptionText>{setSSLByEmailAccountType(item)}</CaptionText>
                      {item?.lastEmailSyncOn && (
                        <SyncTimeBlock>
                          <Circle></Circle>
                          Last Sync :
                          <TimeSapn title={moment(item?.lastEmailSyncOn).format(TOOLTIP_DATE_FORMAT)}>
                            {moment(item?.lastEmailSyncOn).fromNow()}
                          </TimeSapn>
                        </SyncTimeBlock>
                      )}
                      {item?.isInvalid && (
                        <InvalidAccountBlock>
                          <Circle></Circle>
                          Invalid Account
                        </InvalidAccountBlock>
                      )}
                    </SyncMainBlock>
                  </FirstList>
                  <SecondList mobile={mobile}>
                    <LeftIcon
                      onClick={(e) => {
                        onOpenMenu(e, item);
                      }}>
                      {item?.accountType === EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC && (
                        <SVGIcon
                          fill='var(--gray-icon-color)'
                          size={24}
                          name='icon-tools'
                          title='Settings'
                          strokeWidth={1.5}
                        />
                      )}
                    </LeftIcon>
                    {item?.accountType === EMAIL_PROVIDER_TYPE.TWO_WAY_SYNC && <RightIcon></RightIcon>}
                    <SvgWrapper
                      onClick={(e) => {
                        DeleteMenuOpen(e, item);
                      }}>
                      <SVGIcon fill='var(--gray-icon-color)' size={24} name='icon-more' title='More' />
                    </SvgWrapper>
                  </SecondList>
                </ListBox>
              );
            })}
          </ContainBox>
        </MainWrap>
      </MainList>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        width={178}
        position='left'
        positionVertical='top'
        marginTop={25}
        MenuListProps={{ disablePadding: true }}>
        <MainPopup>
          <SetText onClick={onOpenSyncSettingsModal}>Sync Settings</SetText>
        </MainPopup>
      </Menu>
      <Modal open={isProviderMenuOpen} onClose={onCloseProviderMenu} style={ModalStyle}>
        <ChooseEmailProvider handleCloseModal={onCloseProviderMenu} />
      </Modal>
      <Modal open={isSyncSettingsModalOpen} onClose={onCloseSyncSettingsModal} style={ModalStyle}>
        <SyncSettings Id={selectedItem?.id} handleCloseModal={onCloseSyncSettingsModal} />
      </Modal>
      <Menu
        id='delete-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isDeleteMenuOpen}
        onClose={onCloseDeleteMenu}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        marginTop={20}
        MenuListProps={{ disablePadding: true }}>
        <MenuItemWrapper>
          <MenuItem
            iconName='icon-delete'
            fontSize='18px'
            color='var(--gray-icon-color)'
            text='Delete'
            onClick={() => {
              ConfirmDialog({
                iconName: 'icon-delete',
                closeIcon: 'icon-close',
                title: 'Delete',
                message: `Press ok to confirm delete this Email Account`,
                onOk: () => {
                  onDeleteAccount();
                }
              });
            }}
          />
        </MenuItemWrapper>
      </Menu>
      <SnackBar />
    </>
  );
};

export default EmailAccounts;
