import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import DropDown from '../../../components/DropDown/DropDown';
import Input from '../../../components/Input/Input';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Validation from '../../../components/Validation/Validation';
import { isEmpty } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { addAliasEmailAccount } from '../../../services/emailService';
import {
  BottomView,
  DropDownTitle,
  Form,
  LeftWrapper,
  ListWrappper,
  Step3,
  DropDownWrapper,
  Wrapper,
  WrapperStep
} from './styles';

const AliasEmailProvider = (props) => {
  const mobile = useMobileDevice();
  const orgId = props.orgId;
  const [senderName, setSenderName] = useState('');
  const [aliasEmail, setAliasEmail] = useState('');
  const [parentEmail, setParentEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const emailSelector = useSelector((state) => state.email);
  const { loading, emailAccounts } = emailSelector;

  const dispatch = useDispatch();

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!senderName) {
      validationErrors.senderName = 'Required';
      valid = false;
    }
    if (!aliasEmail) {
      validationErrors.aliasEmail = 'Required';
      valid = false;
    }
    if (!parentEmail) {
      validationErrors.parentEmail = 'Required';
      valid = false;
    }

    setValidationErrors(validationErrors);
    return valid;
  }, [senderName, aliasEmail, parentEmail]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;

      const params = {
        senderName: senderName,
        senderEmail: aliasEmail,
        parentId: parentEmail.id
      };

      const result = await dispatch(addAliasEmailAccount(orgId, params));
      if (result) {
        props.onClose();
      }
    },
    [dispatch, validateInputs, orgId, aliasEmail, parentEmail, senderName, props]
  );

  return (
    <Step3>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Wrapper>
          <WrapperStep>
            <Input
              title='Sender Email Full Name'
              onChange={(e) => {
                setSenderName(e.target.value);
              }}
              isPrimarySmall
              autocomplete='off'
              placeholder='John Doe'
              isRequierd
            />
          </WrapperStep>
          <DropDownWrapper mobile={mobile}>
            <Input
              title='Alias Email'
              onChange={(e) => {
                setAliasEmail(e.target.value);
              }}
              isPrimarySmall
              autocomplete='off'
              placeholder='email@company.com'
              isRequierd
            />
            <ListWrappper mobile={mobile}>
              <DropDownTitle>
                Parent Email<span>*</span>
              </DropDownTitle>
              <DropDown
                menuPlacement={'top'}
                options={emailAccounts}
                labelField={'email'}
                valueField={'id'}
                value={parentEmail}
                onChange={(value) => {
                  setParentEmail(value);
                }}
                placeholder={'Email'}
              />
            </ListWrappper>
          </DropDownWrapper>
          {!isEmpty(validationErrors) && <Validation error={'* This fields are requierd'}></Validation>}
        </Wrapper>
        <BottomView>
          <LeftWrapper>
            <Button type='submit' loading={loading} title='Add Account' />
          </LeftWrapper>
        </BottomView>
        <SnackBar />
      </Form>
    </Step3>
  );
};

export default AliasEmailProvider;
