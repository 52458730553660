import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set application search open flag
 */
export const setIsAppSearchOpen = createAction(Actions.SET_APP_IS_SEARCH_OPEN);

/**
 * @desc Set application search open flag
 */
export const setIsAppUpgradeToPro = createAction(Actions.SET_APP_IS_UPGRADE_TO_PRO);

/**
 * @desc Set application sidebar view for mobile
 */
export const setIsAppSidebarOpen = createAction(Actions.SET_APP_IS_SIDEBAR_OPEN);
