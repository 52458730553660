import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from '../../helpers/common';
import { Header7, Text3 } from '../../styles';

const AvtarWrap = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${(props) =>
    props.isnotImg &&
    css`
      overflow: unset;
    `}
  ${(props) =>
    props.size &&
    css`
      min-width: ${props.size}px;
      min-height: ${props.size}px;
      max-width: ${props.size}px;
      max-height: ${props.size}px;
      border-radius: ${props.size / 2}px;
    `}
`;
const UserLogo = styled.img`
  height: 100%;
  width: 100%;
`;
const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.textColor ? props.textColor : '#ffffff')};
  border: ${(props) => (props.borderColor ? '1px solid ' + props.borderColor : '')};
  display: flex;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#CBCBCF')};
`;
const AvatarText = styled.span`
  ${Text3}
  ${(props) =>
    props.size <= 20 &&
    css`
      ${Header7}
    `}
`;
class Avtar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAvtarName = (name) => {
    if (isEmpty(name)) {
      return '#';
    }
    let splitedName = name.trim().split(' ');
    if (splitedName.length === 1) {
      return this.getChar(name);
    } else {
      if (splitedName[1].trim() === '') {
        return this.getChar(name);
      } else {
        return this.getChar(splitedName[0]) + this.getChar(splitedName[1]);
      }
    }
  };

  getChar = (value) => {
    const firstChar = value.charAt(0);
    const isString = Boolean(firstChar.match(/[a-zA-Z]/));
    return isString ? firstChar.toUpperCase() : '';
  };

  render() {
    const { imgSrc, size, name, className, backgroundColor, textColor, borderColor } = this.props;
    const avtarName = this.getAvtarName(name);
    return (
      <AvtarWrap size={size} isnotImg={!imgSrc}>
        {imgSrc ? (
          <UserLogo src={imgSrc} />
        ) : (
          <TextWrapper
            className={className}
            backgroundColor={backgroundColor}
            textColor={textColor}
            borderColor={borderColor}>
            <AvatarText size={size}>{avtarName}</AvatarText>
          </TextWrapper>
        )}
      </AvtarWrap>
    );
  }
}

export default Avtar;
