import styled from 'styled-components';
import { Text1 } from '../../../styles';

const MainContainer = styled.div`
  width: 150px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 10px;
  margin-top: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: transparent;
  border: 0px solid #dedfe2;
`;
const FileWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 4px;
  padding: 8px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 4px 6px 8px 6px;
`;
const FileTitle = styled.span`
  ${Text1}
  padding:0 10px 0 8px;
`;
const BorderFile = styled.div`
  border-right: 1px solid var(--gray-border-color);
  margin-right: 10px;
  height: 21px;
`;

const DownloadLink = styled.a`
  text-decoration: none;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export { MainContainer, ContentContainer, FileWrapper, FileTitle, BorderFile, DownloadLink, SvgWrapper };
