import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LookupFieldTypes, LOOKUP_FIELD_LABEL_TEXT } from '../../../../../constants/constant';
import { getUniqueId, UserFriendlyString } from '../../../../../helpers/common';
import { addCustomizeField } from '../../../../../actions/settingActions';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
import {
  MainContact,
  TitleView,
  TitleText,
  Form,
  SubText,
  CaptionText,
  FirstList,
  ListBox,
  IconView,
  ModalHeader,
  SvgWrapper
} from './styles';

const AddFieldModal = (props) => {
  const settingSelector = useSelector((state) => state.setting);
  const { customizeFields } = settingSelector;

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    if (props?.handleCloseModal) props?.handleCloseModal();
  }, [props]);

  const setUserFriendlyId = useCallback((field) => {
    if (!field) return;
    if (!field.id) field.customId = UserFriendlyString(field.label);
    return field;
  }, []);

  const onAddField = useCallback(
    (type, fieldGroupId) => {
      let field = {};
      field.type = type;
      field.label = LOOKUP_FIELD_LABEL_TEXT[type];
      field.localId = getUniqueId();
      field.isOverview = false;
      field.isQuickAdd = false;
      field.isRequired = false;
      field.isSystem = false;
      // Set multiselect if Lookup
      if (field.type === LookupFieldTypes.Lookup) field.isMultiSelection = false;
      // Set Boolean if Check box
      if (field.type === LookupFieldTypes.Boolean) field.value_Bool = false;
      // Set options if select or multi select
      if (field.type === LookupFieldTypes.Select || field.type === LookupFieldTypes.MultiSelect) {
        field.options = [];
        let item = {};
        item.value = '';
        field.options.push(item);
      }

      if (fieldGroupId) {
        const index = customizeFields.findIndex((x) => x.id === fieldGroupId || x.localId === fieldGroupId);
        if (index !== -1 && !field?.id) {
          const fieldWithUserFriendlyId = setUserFriendlyId(field);
          dispatch(addCustomizeField({ groupIndex: index, field: fieldWithUserFriendlyId }));
          onClose();
          if (props?.openEditMenu) props?.openEditMenu(fieldWithUserFriendlyId, index);
        }
      }
    },
    [dispatch, onClose, setUserFriendlyId, props, customizeFields]
  );

  const onFieldSelection = useCallback(
    (type) => {
      if (!type && !customizeFields) return;
      if (props?.fieldGroupIndex !== -1) {
        const fieldGroup = customizeFields[props?.fieldGroupIndex];
        if (fieldGroup) {
          onAddField(type, fieldGroup?.id || fieldGroup?.localId);
        }
      }
    },
    [onAddField, props, customizeFields]
  );

  return (
    <MainContact>
      <TitleView>
        <ModalHeader>
          <SVGIcon name='icon-summary' fill='var(--gray-icon-color)' size={22} />
          <TitleText>Add Field</TitleText>
        </ModalHeader>
        <SvgWrapper onClick={onClose} title='Close'>
          <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
        </SvgWrapper>
      </TitleView>
      <Form>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Text);
          }}>
          <IconView>
            <SVGIcon name='icon-input-field' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Single-line Text</SubText>
            <CaptionText>Usually used for any text value like name, city etc.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Phone);
          }}>
          <IconView>
            <SVGIcon name='icon-cellphone' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Phone</SubText>
            <CaptionText>Usually used for phone value.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Date);
          }}>
          <IconView>
            <SVGIcon name='icon-calendar' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Date</SubText>
            <CaptionText>Usually used for Date</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Time);
          }}>
          <IconView>
            <SVGIcon name='icon-timer' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Time</SubText>
            <CaptionText>Usually used for Time.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Email);
          }}>
          <IconView>
            <SVGIcon name='icon-email-symbol' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Email</SubText>
            <CaptionText>Usually used for email value.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Boolean);
          }}>
          <IconView>
            <SVGIcon name='icon-check-square' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Checkbox</SubText>
            <CaptionText>Usually used for conditional value whether it is yes or no.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Select);
          }}>
          <IconView>
            <SVGIcon name='icon-drop-down-menu' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Select One Option</SubText>
            <CaptionText>Usually used for text value which can be selected from the Options.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Number);
          }}>
          <IconView>
            <SVGIcon name='icon-keyboard' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Number</SubText>
            <CaptionText>Usually used for only Number type value.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Url);
          }}>
          <IconView>
            <SVGIcon name='icon-earth' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Website/URL</SubText>
            <CaptionText>Usually used for website or URL.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.MultiLineText);
          }}>
          <IconView>
            <SVGIcon name='icon-t-square' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Multiple Line text</SubText>
            <CaptionText>
              Usually used for any text value with multiple lines like description, address etc.
            </CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Amount);
          }}>
          <IconView>
            <SVGIcon name='icon-cash-protection' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Amount</SubText>
            <CaptionText>Usually used for only Amount type value.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.MultiSelect);
          }}>
          <IconView>
            <SVGIcon name='icon-drop-down-menu' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Select Multiple Options</SubText>
            <CaptionText>Usually used for text value which can be selected multiple from the Options.</CaptionText>
          </FirstList>
        </ListBox>
        <ListBox
          onClick={() => {
            onFieldSelection(LookupFieldTypes.Lookup);
          }}>
          <IconView>
            <SVGIcon name='icon-two-way' fill='var(--gray-icon-color)' size={22} />
          </IconView>
          <FirstList>
            <SubText>Associated with Another Record</SubText>
            <CaptionText>Usually used for reference of another collection's record.</CaptionText>
          </FirstList>
        </ListBox>
      </Form>
    </MainContact>
  );
};

export default AddFieldModal;
