import React, { useState } from 'react';
import Button from '../../../components/atom/Button/Button';
import DayPickerCard from '../../../components/DateTime/DayPicker';
import DropDown from '../../../components/DropDown/DropDown';
import Icon from '../../../components/Icon/Icon';
import { Menu } from '../../../components/Menu';
import {
  TwoListDetail,
  LeftList,
  ProFileContain,
  ProfileLogo,
  AvtarText,
  UserView,
  UserName,
  UserLeave,
  BtnContain,
  IconView,
  FirstConatin,
  FirstView,
  FirstList,
  BoxText,
  LastViewOne,
  SecondView,
  LastView,
  Lasttext,
  Lasttext2,
  customestyle,
  SecondContain,
  TitleView,
  ALasttext,
  TagView,
  FirstTag,
  TagList,
  TagBox,
  TitleView2,
  TagText,
  TextDetail,
  UlList,
  Ul,
  Li,
  IconA,
  LSpan,
  RText,
  LSpan2,
  FormView,
  InputList,
  SpanTitle,
  InputValue,
  RightIconView,
  CloseIconView,
  PopView,
  ViewList,
  ShowText,
  ShowTime,
  DateTextView,
  Border,
  useStyles
} from './styles';
export const ContactDetail = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const classes = useStyles();

  const onOpenCalendar = (e) => {
    setIsCalendarOpen(true);
    setMenuAnchorEl(e.currentTarget);
  };
  const onCloseCalendar = () => {
    setIsCalendarOpen(false);
    setMenuAnchorEl(null);
  };
  return (
    <>
      <TwoListDetail>
        <LeftList>
          <ProFileContain>
            <ProfileLogo>
              <AvtarText>RH</AvtarText>
            </ProfileLogo>
            <UserView>
              <UserName>Ryan Hayward</UserName>
              <UserLeave>Surat, India</UserLeave>
            </UserView>
          </ProFileContain>
          <BtnContain>
            <Button onClick={() => {}} title='Send Mail' />
            <IconView>
              <Icon name='icon-call' fontSize='16' color='var(--gray-icon-color)' />
            </IconView>
            <IconView>
              <Icon name='icon-whatsapp' fontSize='16' color='var(--gray-icon-color)' />
            </IconView>
            <IconView>
              <Icon name='icon-message' fontSize='16' color='var(--gray-icon-color)' />
            </IconView>
            <IconView width>
              <Icon name='feather-more-vertical' fontSize='16' color='var(--gray-icon-color)' />
            </IconView>
          </BtnContain>
          <FirstConatin>
            <FirstView>
              <FirstList>
                <Icon name='icon-star' fontSize='19' color='var(--gray-icon-color)' />
                <BoxText>Status</BoxText>
              </FirstList>
              <LastViewOne>
                <DropDown styles={customestyle} placeholder={'Select'} />
              </LastViewOne>
            </FirstView>
            <SecondView>
              <FirstList>
                <Icon name='icon-calendar' fontSize='19' color='var(--gray-icon-color)' />
                <BoxText>Followup</BoxText>
              </FirstList>
              <LastView onClick={onOpenCalendar}>
                <Lasttext2>in 3 days</Lasttext2>
              </LastView>
            </SecondView>
            <SecondView>
              <FirstList>
                <Icon name='icon-user' fontSize='19' color='var(--gray-icon-color)' />
                <BoxText>Assigned To</BoxText>
              </FirstList>
              <LastView>
                <Lasttext>Add</Lasttext>
              </LastView>
            </SecondView>
          </FirstConatin>
          <SecondContain>
            <TitleView>
              <FirstList>
                <Icon name='icon-deals' fontSize='19' color='var(--gray-icon-color)' />
                <BoxText>Deal (0)</BoxText>
              </FirstList>
              <LastView>
                <ALasttext>Add</ALasttext>
              </LastView>
            </TitleView>
            <TagView>
              <FirstTag>
                <FirstList>
                  <Icon name='icon-tag' fontSize='19' color='var(--gray-icon-color)' />
                  <BoxText>Tag (2)</BoxText>
                </FirstList>
                <LastView>
                  <Icon name='feather-chevron-down' fontSize='16' color='var(--gray-icon-color)' />
                </LastView>
              </FirstTag>
              <TagList>
                <TagBox>
                  <TagText>Pro</TagText>
                </TagBox>
                <TagBox>
                  <TagText>Surat</TagText>
                </TagBox>
                <TagBox>
                  <Icon name='icon-pluse' fontSize='14' color='var(--gray-icon-color)' />
                </TagBox>
              </TagList>
            </TagView>
          </SecondContain>
          <SecondContain>
            <TitleView2>
              <FirstList>
                <Icon name='icon-tag' fontSize='19' color='var(--gray-icon-color)' />
                <TextDetail>Detail</TextDetail>
              </FirstList>
              <LastView>
                <ALasttext>Add</ALasttext>
              </LastView>
            </TitleView2>
            <UlList>
              <Ul>
                <Li>
                  <LSpan>Email :</LSpan>
                  <RText>tirthk@gmail.com</RText>
                  <IconA>
                    <Icon name='feather-edit-3' fontSize='16' color='var(--gray-icon-color)' />
                  </IconA>
                </Li>
                <Li>
                  <LSpan>Phone :</LSpan>
                  <RText>9724154576</RText>
                  <IconA>
                    <Icon name='feather-edit-3' fontSize='16' color='var(--gray-icon-color)' />
                  </IconA>
                </Li>
                <Li>
                  <LSpan>Assigned To :</LSpan>
                  <RText>Tirth</RText>
                  <IconA>
                    <Icon name='feather-edit-3' fontSize='16' color='var(--gray-icon-color)' />
                  </IconA>
                </Li>
                <Li>
                  <LSpan>City :</LSpan>
                  <RText>Surat</RText>
                  <IconA>
                    <Icon name='feather-edit-3' fontSize='16' color='var(--gray-icon-color)' />
                  </IconA>
                </Li>
                <Li>
                  <LSpan>State :</LSpan>
                  <RText>Gujarat</RText>
                  <IconA>
                    <Icon name='feather-edit-3' fontSize='16' color='var(--gray-icon-color)' />
                  </IconA>
                  <InputList>
                    <SpanTitle>State</SpanTitle>
                    <FormView>
                      <InputValue type='name' />
                      <RightIconView>
                        <Icon name='feather-check' fontSize='16' color='var( --gray-icon-color)' />
                      </RightIconView>
                      <CloseIconView>
                        <Icon name='feather-x' fontSize='16' color='var( --gray-icon-color)' />
                      </CloseIconView>
                    </FormView>
                  </InputList>
                </Li>
                <Li>
                  <LSpan>Country :</LSpan>
                  <RText>India</RText>
                  <IconA>
                    <Icon name='feather-edit-3' fontSize='16' color='var(--gray-icon-color)' />
                  </IconA>
                </Li>
                <Li>
                  <LSpan2>Show More</LSpan2>
                </Li>
              </Ul>
            </UlList>
          </SecondContain>
        </LeftList>
      </TwoListDetail>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        classes={{ paper: classes.paperMenu }}
        keepMounted
        open={isCalendarOpen}
        onClose={onCloseCalendar}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        MenuListProps={{ disablePadding: true }}
        width={245}
        marginTop={30}>
        <PopView>
          <ViewList>
            <Icon name='feather-calendar' fontSize='16' color='var(--gray-icon-color)' />
            <DateTextView>
              <ShowText>Today</ShowText>
              <ShowTime>Mon, 6th</ShowTime>
            </DateTextView>
          </ViewList>
          <ViewList>
            <Icon name='feather-sun' fontSize='16' color='var(--gray-icon-color)' />
            <DateTextView>
              <ShowText>Tomorrow</ShowText>
              <ShowTime>Tue, 7th</ShowTime>
            </DateTextView>
          </ViewList>
          <ViewList>
            <Icon name='feather-next-calendar' fontSize='16' color='var(--gray-icon-color)' />
            <DateTextView>
              <ShowText>Next Week</ShowText>
              <ShowTime>Mon, 10th</ShowTime>
            </DateTextView>
          </ViewList>
          <ViewList>
            <Icon name='feather-x-octagon' fontSize='16' color='var(--gray-icon-color)' />
            <DateTextView>
              <ShowText>No Date</ShowText>
            </DateTextView>
          </ViewList>
          <Border></Border>
          <DayPickerCard />
        </PopView>
      </Menu>
    </>
  );
};

export default ContactDetail;
