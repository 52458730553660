import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Text1, Text2 } from '../../styles';

const StylesWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  ${(props) =>
    props.component === 'ImportData' &&
    css`
      padding: 0;
    `}
  ${(props) =>
    props.mobile
      ? css`
          overflow: auto;
          padding: 0;
        `
      : css``}
`;
const Styles = styled.div`
  z-index: 2;
  position: relative;
  ${(props) =>
    props.component === 'ImportData' &&
    css`
      max-height: calc(100vh - 445px);
      overflow: auto;
    `}
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      :hover {
        td {
          background-color: var(--popup-color-hover);
          :last-child {
            opacity: 1;
            background-color: var(--popup-color-hover);
            border: 0;
          }
        }
      }
    }
    tbody tr {
      :hover {
        th {
          background-color: var(--popup-color-hover);
          z-index: 4;
        }
      }
    }
    thead th {
      position: sticky;
      top: 0;
      z-index: 3;
      margin: 0;
      padding: 0 12px;
      border-right: 1px solid var(--Platinum-color);
      text-align: left;
      background-color: var(--white-color);
      min-width: 150px;
      white-space: nowrap;
      ${Text1}
      color: var(--gray-color);
      height: 35px;
      border-bottom: 2px solid var(--Platinum-color);
      border-top: 1px solid var(--Platinum-color);
      min-height: 35px;
      box-sizing: content-box;
    }
    thead th:first-child {
      position: sticky;
      left: 0;
      z-index: 5;
      border-left: 0;
      ${(props) =>
        props.mobile
          ? css`
              top: 0;
              left: auto;
              padding: 0px;
            `
          : css`
              left: 0;
            `}
    }
    thead th:last-child {
      border-right: 0px;
    }
    tbody th {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
      ${(props) =>
        props.mobile
          ? css`
              position: static;
            `
          : css`
              position: sticky;
            `}
    }
    tbody {
      tr {
        position: relative;
        th {
          margin: 0;
          padding: 0 12px;
          text-align: left;
          background-color: var(--white-color);
          min-width: 150px;
          white-space: nowrap;
          ${Text1}
          color: var(--dark-color);
          height: 38px;
          border-bottom: 1px solid var(--Platinum-color);
          border-right: 1px solid var(--Platinum-color);
          border-top: 0px;
          position: sticky;
          left: 0;
          background: white;
          z-index: 4;
          ${(props) =>
            props.mobile
              ? css`
                  position: static;
                `
              : css`
                  position: sticky;
                `}
        }
      }
    }
    td {
      margin: 0;
      padding: 0 12px;
      border-right: 1px solid var(--Platinum-color);
      text-align: left;
      background-color: var(--white-color);
      min-width: 150px;
      white-space: nowrap;
      ${Text1}
      color: var(--dark-color);
      height: 38px;
      border-bottom: 1px solid var(--Platinum-color);
      position: relative;
      :last-child {
        border-right: 0;
        position: sticky;
        right: 0;
        min-width: 0px;
        padding: 0;
        opacity: 0;
      }
      :nth-last-of-type(2) {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;
const Title = styled.span`
  ${Text1}
  margin-left: 15px;
  color: var(--gray-color);
`;
const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  .input {
    margin: 0;
    width: 14px;
    height: 14px;
  }
`;
const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 11px;
  .avatar {
    width: 22px;
    height: 22px;
  }
`;
const CellLinkWrapper = styled(NavLink)`
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const InputWrapper = styled.div`
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white-color);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  ${(props) =>
    props.mobile
      ? css`
          display: flex;
          left: 0;
          padding: 8px 12px;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
        `
      : css`
          padding: 16px 24px;
          left: 258px;
        `}
`;
const PaginationLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .iconsvg {
    padding: 0 5px 0 0;
  }
`;
const Span = styled.span`
  color: var(--gray-color);
  ${Text2}
  margin-right: 12px;
`;
const SpanPage = styled.span`
  color: var(--gray-color);
  ${Text2};
  margin-left: 16px;
`;
const PaginationRight = styled.div`
  display: flex;
  align-items: center;
  button:last-child {
    margin-right: 0;
    margin-left: 5px;
  }
`;
const ArrowButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 5px;
  margin: 0px 6px;
  padding: 0;
  background: var(--white-color);
  :hover {
    box-shadow: 0px 3px 10px var(--pagination-box-shadow-hover);
    border-radius: 5px;
    transition: 0.3s all ease;
    cursor: pointer;
    i {
      color: var(--white-color);
    }
  }
  &:disabled {
    background: var(--gainsboro-color);
    border: 1px solid var(--gainsboro-color);
    box-shadow: none;
    :hover {
      i {
        color: var(--gray-icon-color);
      }
    }
  }
`;
const ViewIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0px 8px 16px;
  padding: 0 16px;
  background: var(--popup-color-hover);
  width: 130px;
`;
const Tr = styled.tr`
  :hover {
    td {
      opacity: 1;
      background-color: var(--popup-color-hover);
    }
  }
`;
const FlatIcon = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 38px;
  padding: 13px 29px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  a {
    text-decoration: none;
    margin-right: 24px;
    i {
      margin-right: 0;
    }
  }
  i {
    margin-right: 24px;
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;
const customestyle = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--dark-color)',
    '&:active': {
      background: ' var(--popup-color-hover)'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    color: '#000',
    minWidth: '20px',
    margin: 0
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px 5px 0 0',
    minWidth: '20px'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    // This line disable the blue border
    minHeight: '28px',
    minWidth: '44px',
    borderRadius: '4px',
    borderColor: 'var(--gray-border-color)',
    borderWidth: '1px',
    margin: '0px',
    paddingLeft: '8px',
    background: 'var(--white-color)',
    boxShadow: '0px 1px 2px var(--pagination-box-shadow)',
    ':hover': {
      borderColor: 'var(--gray-border-color)'
    }
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px'
  }),

  indicatorsContainer: (base) => ({
    ...base,
    display: 'none'
  }),
  input: (base) => ({
    ...base,
    margin: '0'
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500'
  })
};
const SpanArrow = styled.span`
  margin-left: auto;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

export {
  Styles,
  Title,
  CellWrapper,
  AvatarWrapper,
  CellLinkWrapper,
  InputWrapper,
  StylesWrapper,
  PaginationWrapper,
  PaginationLeft,
  Span,
  PaginationRight,
  ArrowButton,
  ViewIcon,
  Tr,
  FlatIcon,
  customestyle,
  SpanPage,
  SpanArrow,
  HeaderTitle
};
