import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';
/**
 * @desc Set CallLog Loader
 */
export const setCallLogLoader = createAction(Actions.SET_CALL_LOGS_LOADER);
/**
 * @desc Set CallLog List
 */
export const setCallLogList = createAction(Actions.SET_CALL_LOGS);

export const clearAutomationData = () => (dispatch) => {
  dispatch(setCallLogLoader(false));
  dispatch(setCallLogList(null));
};
