import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Header6, Text1 } from '../../styles';
import SVGIcon from '../../assets/svg/SVGIcon';
import { isElectronApp, isMACApp } from '../../global/Helper';

const MenuItemLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  padding: 7px 10px;
  display: block;
  &.active_menu {
    span {
      color: var(--white-color);
    }
  }
  :hover {
    span {
      color: var(--white-color);
    }
  }
`;
const MainNav = styled.div`
  height: 100%;
  width: 100%;
`;
const MainNavWrapper = styled.div`
  background: var(--nav-dark-color);
  height: 100%;
  width: 100%;
  ${() =>
    isElectronApp() &&
    css`
      padding-top: 32px;
    `}
  ${() =>
    !isMACApp() &&
    css`
      padding-top: 0px;
    `}
`;
const NavList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px;
  position: relative;
`;
const ListView = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
`;
const Li = styled.li`
  margin-bottom: 8px;
`;
const SpanList = styled.span`
  color: var(--gainsBoro-color);
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${Text1};
`;
const TopView = styled.div`
  position: relative;
  z-index: 2;
`;
const LeftView = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 7px 19px 7px 10px;
  :hover {
    background-color: var(--titlebar-bg);
  }
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--titlebar-bg);
    `}
`;
const TextView = styled.div`
  margin-left: 8px;
  display: inline-flex;
`;
const SpanText = styled.span`
  font-style: normal;
  display: inline-block;
  color: var(--white-color);
  ${Text1}
`;
const Title = styled.div`
  text-transform: uppercase;
  color: var(--Shuttle-Gray);
  ${Header6}
`;
const Border = styled.div`
  height: 1px;
  background: var(--collection-linear);
  margin-left: 8px;
  width: 100%;
`;
const ReportLi = styled.li`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin-bottom: 8px;
`;
const ReportMain = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TopLinear = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;
  z-index: 0;
  background: rgb(32, 38, 50);
  background: linear-gradient(0deg, rgba(32, 38, 50, 0) 0%, rgba(32, 38, 50, 1) 30%, rgba(32, 38, 50, 1) 100%);
`;
const BottmLinear = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 0;
  background: rgb(32, 38, 50);
  background: linear-gradient(180deg, rgba(32, 38, 50, 0) 0%, rgba(32, 38, 50, 1) 30%, rgba(32, 38, 50, 1) 100%);
`;
const HelpBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  :hover {
    background-color: var(--titlebar-bg);
  }
  ${(props) =>
    props.isOpenHelpModal &&
    css`
      svg {
        path {
          stroke: var(--disable-color);
        }
      }
      background-color: var(--nav-dark-color-focus);
      span {
        color: var(--disable-color);
      }
    `}
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--nav-dark-color-focus);
      svg {
        path {
          stroke: var(--disable-color);
        }
      }
      span {
        color: var(--disable-color);
      }
      :hover {
        background-color: var(--nav-dark-color-focus);
        span {
          color: var(--disable-color);
        }
      }
    `}
`;
const NavIcon = styled(SVGIcon).attrs(({ help }) => ({
  size: '18',
  fill: help ? 'var(--gray-color)' : 'var(--white-color)',
  strokeWidth: '2'
}))``;

const HelpSpanList = styled.span`
  color: var(--white-color);
  margin-left: 16px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${Text1}
  ${(props) =>
    props.help &&
    css`
      color: var(--gray-color);
      margin-left: 14px;
    `}
`;
const ModalStyle = {
  width: '360px'
};

export {
  MainNav,
  MainNavWrapper,
  NavList,
  Ul,
  Li,
  SpanList,
  ListView,
  MenuItemLink,
  TextView,
  TopView,
  LeftView,
  SpanText,
  Title,
  Border,
  ReportLi,
  ReportMain,
  HelpBlock,
  NavIcon,
  HelpSpanList,
  ModalStyle,
  TopLinear,
  BottmLinear
};
