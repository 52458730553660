import Empty from '../../../assets/images/automation-empty.svg';
import { EmptyImg, EmptyWrapper, DetailsTitle } from './styles';

const SmsLogEmptyState = () => {
  return (
    <EmptyWrapper>
      <EmptyImg src={Empty} />
      <DetailsTitle>No SMS Log Found</DetailsTitle>
    </EmptyWrapper>
  );
};

export default SmsLogEmptyState;
