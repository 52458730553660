import React from 'react';
import PropTypes from 'prop-types';
import { Modal as MaterialModal } from '@material-ui/core';
import useMobileDevice from '../../hooks/useMobileDevice';
import { MainContainer, ModalWrapper, Wrapper, Backdrop, useStyles } from './Styles';

const Modal = ({ children, headerTitle, style, ...props }) => {
  const mobile = useMobileDevice();
  const classes = useStyles({ mobile });
  return (
    <>
      <MaterialModal
        mobile={mobile}
        className={classes.modal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropComponent={Backdrop}
        {...props}>
        <Wrapper mobile={mobile}>
          <ModalWrapper mobile={mobile}>
            <MainContainer style={style} mobile={mobile}>
              {children}
            </MainContainer>
          </ModalWrapper>
        </Wrapper>
      </MaterialModal>
    </>
  );
};

export default Modal;

Modal.propTypes = {
  position: PropTypes.oneOf(['center', 'right'])
};

Modal.defaultProps = {
  open: false,
  position: 'center'
};
