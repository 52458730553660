import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from './../actions/types';

const dashboardLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_DASHBOARD_LOADER
});

const initialState = {};
const dashboardDetailReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_DASHBOARD_DETAIL, (state = initialState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_DASHBOARD_ACTIVITY_ITEM, (state, action) => {
      const dashboard = current(state);
      const dashboardData = JSON.parse(JSON.stringify(dashboard));
      const index = dashboardData?.activities?.findIndex((x) => x.id === action.payload.item?.id);
      if (index !== -1) dashboardData.activities[index] = action.payload.item;
      return dashboardData;
    });
});

const clearDashboardDataReducer = createReducer({
  initialState: null,
  actionType: Actions.CLEAR_DASHBOARD_DATA
});

const dashboardOnBoardingReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_DASHBOARD_ONBOARDING
});

const initialContactSuggestionsState = null;
const dashboardContactSuggestionsReducer = createReducerOrig(initialContactSuggestionsState, (builder) => {
  builder
    .addCase(Actions.SET_CONTACT_SUGGESTIONS, (state = initialContactSuggestionsState, action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.DELETE_CONTACT_SUGGESTION_ITEM, (state, action) => {
      let contactSuggestions = current(state);
      const suggestions = JSON.parse(JSON.stringify(contactSuggestions));
      const index = contactSuggestions?.findIndex((x) => x.id === action.payload.item?.id);
      if (index !== -1) {
        suggestions.splice(index, 1);
      }
      contactSuggestions = suggestions;
      return contactSuggestions;
    });
});

const initialMergeContactDetailsState = null;
const mergeContactDetailsReducer = createReducerOrig(initialMergeContactDetailsState, (builder) => {
  builder
    .addCase(Actions.SET_MERGE_CONTACT_DETAILS, (state = initialMergeContactDetailsState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_MERGE_CONTACT_DETAILS, (state, action) => {
      let contactDetails = JSON.parse(JSON.stringify(current(state)));
      const item = action?.payload?.item;
      const value = action?.payload?.value;
      if (action?.payload?.propsName === 'duplicate_lookup' && contactDetails['original_lookup'].values[item?.id]) {
        contactDetails['original_lookup'].values[item?.id] = false;
      } else if (
        action?.payload?.propsName === 'original_lookup' &&
        contactDetails['duplicate_lookup'].values[item?.id]
      ) {
        contactDetails['duplicate_lookup'].values[item?.id] = false;
      }
      contactDetails[action?.payload?.propsName].values[item?.id] = value;
      return contactDetails;
    });
});

const contactDuplicationReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_CONTACT_DUPLICATION
});

const dataLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_DATA_LOADER
});

const dashboardReducer = combineReducers({
  loading: dashboardLoaderReducer,
  dashboardDetail: dashboardDetailReducer,
  onBoarding: dashboardOnBoardingReducer,
  clearDashboardData: clearDashboardDataReducer,
  contactSuggestions: dashboardContactSuggestionsReducer,
  contactDuplication: contactDuplicationReducer,
  mergeContactDetails: mergeContactDetailsReducer,
  dataLoading: dataLoaderReducer
});

export default dashboardReducer;
