import styled from 'styled-components';
import { Header1, SubHeader3, Text2 } from '../../../styles';

const ManeWrapper = styled.div`
  background: var(--main-bg);
  width: 100%;
  height: 100%;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  flex-direction: column;
`;
const LogoWrapper = styled.div`
  text-align: center;
  svg {
    display: block;
    margin: auto;
  }
`;
const Title = styled.h1`
  ${Header1}
  text-align: center;
  color: var(--dark-color);
  margin: 25px 0 0;
`;
const Form = styled.form`
  width: 100%;
  margin-top: 40px;
  input {
    :focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 3px #afcdff, 1px 1px 2px 0 rgb(0 0 0 / 5%);
    }
  }
`;
const AlreadyPara = styled.p`
  ${SubHeader3}
  text-align: center;
  color: var(--dark-color);
  margin: 20px 0 0 0;
`;
const ButtonWrap = styled.div`
  margin-top: 30px;
  width: 100%;
  button {
    width: 100%;
    text-align: center;
    display: block;
  }
`;
const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #4f81d8;
  :hover {
    text-decoration: underline;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 353px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
`;
const PrivacyPolicy = styled.p`
  ${Text2}
  color: var(--dark-color);
  margin: 20px 0 0 0;
  text-align: center;
`;
const MacDragDro = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 24px;
  width: 100%;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
`;

export {
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  AlreadyPara,
  ButtonWrap,
  Link,
  Container,
  PrivacyPolicy,
  MacDragDro
};
