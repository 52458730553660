import { css } from 'styled-components';

const Header1 = css`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
`;
const Header2 = css`
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
`;
const Header3 = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;
const SubHeader3 = css`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
`;
const Header4 = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
const Header5 = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;
const Header6 = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;
const Text1 = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
const Text2 = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
`;
const Text3 = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
`;
const Text4 = css`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`;
const Button1 = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const Header7 = css`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`;
const mobileHeader1 = css`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`;
const mobileText1 = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
export {
  Header1,
  Header2,
  Header3,
  SubHeader3,
  Header4,
  Header5,
  Header6,
  Text1,
  Text2,
  Text3,
  Text4,
  Button1,
  Header7,
  mobileHeader1,
  mobileText1
};
