import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Buttons from '../../../../components/atom/Button/Button';
import Input from '../../../../components/Input/Input';
import {
  clearCallForwardingItem,
  setCallForwardingItem,
  updateCallForwardingItem
} from '../../../../actions/phoneCallActions';
import { updateCallForwarding } from '../../../../services/settingService';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import { IconView } from '../styles';
import {
  CallForwardWrap,
  CallForwardTop,
  CallForwardHeading,
  HeaderWrapper,
  CallForwardMiddle,
  CallForwardingPara,
  CallForwardingForm,
  CallForwardMain,
  CallForwardBottom,
  CallForwardnum,
  ButtonPrimary
} from './styles';

const CallForwardingModal = ({ onClose, phone }) => {
  const dispatch = useDispatch();
  const settingSelector = useSelector((state) => state.setting);
  const { callForwarding, loading } = settingSelector;
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  useEffect(() => {
    if (phone) {
      dispatch(setCallForwardingItem(phone));
    }
    return () => {
      dispatch(clearCallForwardingItem());
    };
  }, [dispatch, phone]);

  const onChangeInput = useCallback(
    (propsName, value) => {
      dispatch(updateCallForwardingItem({ propsName, value }));
    },
    [dispatch]
  );

  const onSave = useCallback(
    async (e) => {
      e.preventDefault();
      const payload = {
        phoneId: phone?.id,
        isCallForwarding: callForwarding?.isCallForwarding,
        forwardingPhoneNumber: callForwarding?.forwardingPhoneNumber
      };
      const result = await dispatch(updateCallForwarding(organization_id, phone?.id, payload));
      if (result) {
        if (onClose) onClose();
      }
    },
    [callForwarding, dispatch, onClose, organization_id, phone?.id]
  );

  return (
    <>
      <CallForwardWrap onSubmit={onSave}>
        <CallForwardMain>
          <CallForwardTop>
            <HeaderWrapper>
              <SVGIcon name='icon-two-way' fill='var(--gray-icon-color)' size={22} />
              <CallForwardHeading>Setup Call Forwarding</CallForwardHeading>
            </HeaderWrapper>
            <IconView
              onClick={() => {
                onClose();
              }}>
              <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
            </IconView>
          </CallForwardTop>
          <CallForwardMiddle>
            <CallForwardingPara>
              Call forwarding allows you to route incoming calls to another phone number when you're not at your desk
              (or when Salescamp isn't open).
            </CallForwardingPara>
            <CallForwardingForm>
              <lable>Enable call forwarding</lable>
              <input
                type='checkbox'
                checked={callForwarding?.isCallForwarding}
                onChange={(e) => {
                  onChangeInput('isCallForwarding', e.target.checked);
                }}
              />
              <span className='checkmark'></span>
            </CallForwardingForm>
            <CallForwardnum>
              <Input
                title='Forwarding number'
                isPrimarySmall={true}
                placeholder='Number'
                type='text'
                value={callForwarding?.forwardingPhoneNumber}
                onChange={(e) => {
                  onChangeInput('forwardingPhoneNumber', e.target.value);
                }}
              />
            </CallForwardnum>
          </CallForwardMiddle>
        </CallForwardMain>
        <CallForwardBottom>
          <ButtonPrimary>
            <Buttons primary='primary' size='middle' title='save' type='submit' loading={loading} />
          </ButtonPrimary>
          <Buttons
            secondary='secondary'
            size='middle'
            title='Cancel'
            type='button'
            onClick={() => {
              onClose();
            }}
          />
        </CallForwardBottom>
      </CallForwardWrap>
    </>
  );
};

export default CallForwardingModal;
