import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/atom/Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import Input from '../../components/Input/Input';
import DropDown from '../../components/DropDown/DropDown';
import { GOAL_TYPE } from '../../constants/constant';
import { clearGoalItem, updateGoalItem } from '../../actions/goalActions';
import { DatePicker } from '../../components/DateTime';
import { goalDuration } from '../../data/raw';
import Validation from '../../components/Validation/Validation';
import { createGoal, getGoalItem, updateGoal } from '../../services/goalService';
import { isEmpty } from '../../helpers/common';
import SVGIcon from '../../assets/svg/SVGIcon';
import useMobileDevice from '../../hooks/useMobileDevice';
import {
  BottomView,
  customeStyle,
  DateTimeWrapper,
  DropDownTitle,
  Form,
  LeftWrapper,
  ListWrappper,
  MainContainer,
  marginBottom,
  ModalWrapper,
  TitleText,
  TitleView,
  HeaderLeft
} from './Styles';

const goalTypes = [
  { label: 'Contact', value: GOAL_TYPE.NEW_CONTACT },
  { label: 'Email', value: GOAL_TYPE.EMAIL_SENT }
];

const AddNewGoalModal = ({ handleCloseModal, itemId }) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id, users } = organization;
  const goalSelector = useSelector((state) => state.goal);
  const { goalItem, loading } = goalSelector;
  const [error, setError] = useState({});

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (itemId) {
      dispatch(getGoalItem(organization_id, itemId));
    }
  }, [dispatch, itemId, organization_id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearGoalItem());
    };
  }, [dispatch, loadData]);

  const onClose = useCallback(() => {
    if (handleCloseModal) handleCloseModal();
  }, [handleCloseModal]);

  const onUpdateGoalItem = useCallback(
    (propsName, value) => {
      dispatch(updateGoalItem({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let error = {};
      setError({});
      const payload = goalItem;
      if (!payload?.name || payload?.name?.trim() === '') {
        error['name'] = 'Required';
      }
      if (isEmpty(payload?.type)) {
        error['type'] = 'Required';
      }
      if (!payload?.target) {
        error['target'] = 'Required';
      }
      if (!payload?.startDate || payload?.startDate.trim() === '') {
        error['startDate'] = 'Required';
      }
      if (!payload?.duration) {
        error['duration'] = 'Required';
      }
      if (!isEmpty(error)) {
        setError(error);
        return;
      }
      let result;
      if (itemId) {
        result = await dispatch(updateGoal(organization_id, itemId, payload));
      } else {
        result = await dispatch(createGoal(organization_id, payload));
      }
      if (result) {
        onClose();
      }
    },
    [dispatch, goalItem, itemId, onClose, organization_id]
  );

  return (
    <MainContainer mobile={mobile}>
      <TitleView>
        <HeaderLeft>
          <SVGIcon name='icon-goal' fill='var(--gray-icon-color)' size={22} />
          <TitleText>{itemId ? 'Edit' : 'Add'} Goals</TitleText>
        </HeaderLeft>
        <CloseIcon onClick={onClose} title='Close' />
      </TitleView>
      <Form onSubmit={onSubmit}>
        <ModalWrapper>
          <Input
            title='Goal Name'
            marginBottom={marginBottom}
            isPrimarySmall
            autocomplete='off'
            placeholder='Enter your goals name'
            value={goalItem?.name}
            onChange={(e) => {
              onUpdateGoalItem('name', e.target.value);
            }}
          />
          {error?.name && <Validation error={error?.name} />}

          <ListWrappper>
            <DropDownTitle>Type</DropDownTitle>
            <DropDown
              options={goalTypes}
              labelField={'label'}
              valueField={'value'}
              value={goalItem?.type}
              onChange={(item) => {
                onUpdateGoalItem('type', item.value);
              }}
              placeholder={'Select'}
            />
            {error?.type && <Validation error={error?.type} />}
          </ListWrappper>
          <Input
            title='Target'
            className='margin-bottom'
            type='number'
            isPrimarySmall
            autocomplete='off'
            marginBottom={marginBottom}
            placeholder='Enter your target value'
            value={goalItem?.target}
            onChange={(e) => {
              onUpdateGoalItem('target', e.target.value);
            }}
          />
          {error?.target && <Validation error={error?.target} />}

          <DateTimeWrapper>
            <DropDownTitle>Start Date</DropDownTitle>
            <DatePicker
              autoOk={true}
              value={new Date(goalItem?.startDate) || new Date()}
              className='DatePickerWrapper'
              onChange={(date) => {
                onUpdateGoalItem('startDate', moment(date).format('YYYY-MM-DD'));
              }}
            />
            {error?.startDate && <Validation error={error?.startDate} />}
          </DateTimeWrapper>
          <ListWrappper>
            <DropDownTitle>Duration</DropDownTitle>
            <DropDown
              options={goalDuration}
              labelField={'label'}
              valueField={'value'}
              value={goalItem?.duration}
              onChange={(item) => {
                onUpdateGoalItem('duration', item.value);
              }}
              placeholder={'Select'}
            />
            {error?.duration && <Validation error={error?.duration} />}
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>Assign User</DropDownTitle>
            <DropDown
              options={users || []}
              labelField={'name'}
              valueField={'id'}
              isMulti
              styles={customeStyle}
              value={goalItem?.users}
              menuPlacement={'auto'}
              onChange={(user) => {
                onUpdateGoalItem('users', user);
              }}
              className='basic-multi-select'
            />
          </ListWrappper>
        </ModalWrapper>
        <BottomView>
          <LeftWrapper>
            <Button loading={loading ? loading : undefined} primary title='Save' type='submit' />
            <Button disabled={loading} secondary title='Cancel' onClick={onClose} type='button' />
          </LeftWrapper>
        </BottomView>
      </Form>
    </MainContainer>
  );
};

export default AddNewGoalModal;
