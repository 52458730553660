import styled, { css } from 'styled-components';
import { Text1, Text2 } from '../../../styles';

const StatusChangeWrapper = styled.div`
  padding: 10px 24px 0 24px;
  position: relative;
  width: 100%;
  overflow: auto;
  height: calc(100% - 76px);
`;
const ReportsContent = styled.div``;
const Pdt = styled.div`
  padding: 0 0 40px 0;
`;
const H3 = styled.h3`
  margin: 0;
  ${Text1}
  color:var(--dark-color);
`;
const TableContainer = styled.div`
  overflow: hidden;
  margin-top: 16px;
`;
const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: unset;
  border-spacing: 0;
`;
const Thead = styled.thead``;
const Tr = styled.tr``;
const Th = styled.th`
  padding: 0 8px;
  ${Text2}
  color:var(--gray-color);
  text-align: left;
  letter-spacing: 0.2px;
  line-height: 18px;
  border-top: 1px solid var(--gray-border-color);
  border-bottom: 1px solid var(--gray-border-color);
  border-right: 1px solid var(--gray-border-color);
  border-left: 0;
  max-width: 320px;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
  :last-child {
    border-right: 0;
  }
  :nth-last-child(3) {
    border-right: 0;
  }
`;
const Tbody = styled.tbody`
  tr:hover {
    background-color: var(--deal-column-bg);
  }
`;
const Td = styled.td`
  padding: 0 8px;
  border-right: 1px solid var(--gray-border-color);
  border-bottom: 1px solid var(--gray-border-color);
  color: var(--dark-color);
  ${Text1}
  max-width: 320px;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
  :first-child {
    color: var(--primary-color);
    cursor: pointer;
  }
  :nth-child(4) {
    border-right: 0;
  }
  :last-child {
    border-right: 0;
  }
  ${(props) =>
    props.Mantis &&
    css`
      color: var(--mantis-icon);
    `}
`;
const LookupName = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;
const PlayImg = styled.img`
  margin-right: 6px;
`;
const ArrowIcon = styled.img``;
const TdWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const ArrowWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export {
  StatusChangeWrapper,
  ReportsContent,
  Pdt,
  H3,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  LookupName,
  PlayImg,
  ArrowIcon,
  TdWrapper,
  ArrowWrapper
};
