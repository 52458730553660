/* eslint-disable max-len */
import ThreeDots from './../../../assets/images/3dots-horizontal@2x.png';
import CMHtml4Defs from './PreviewJavascriptHelper/CMHtml4Defs';
import CMHtmlSanitizerURI from './PreviewJavascriptHelper/CMHtmlSanitizerURI';
import CMHtmlSanitizer from './PreviewJavascriptHelper/CMHtmlSanitizer';
import {
  HTMLSanitizerWrapper,
  HTMLSanitizerSlashHref,
  HTMLSanitizerColorScheme
} from './PreviewJavascriptHelper/CMHtmlSanitizerWrapper';

export default class SmartPreview {
  constructor(input, i, doc, rsvpAction) {
    this.input = input;
    this.index = i;
    this.document = doc;
    this.webviewWidth = 0;
    this.CMshouldManipulateImages = true;
    this.rsvpActionClickListener = rsvpAction;
    this.CMHandleOnErrorImage = this.CMHandleOnErrorImage.bind(this);
    this.CMHandleOnLoadImage = this.CMHandleOnLoadImage.bind(this);
  }

  setScaleChangeListener(onScaleChange) {
    this.onScaleChange = onScaleChange;
  }

  manipulateDivHeight() {
    // This function finds divs with height set to 100%
    // and resets it to 'auto'. This is done only for
    // devices below Kitkat as they use the old webview
    // and in case of encountering such a div the old
    // webview causes the div to expand infinitely in
    // height. Test case: Quora top stories from your feed mail
    const divs = this.contentNode.getElementsByTagName('div');
    // console.log("divs....",divs)
    for (let i = 0; i < divs.length; i += 1) {
      const div = divs[i];
      if (div.style.height === '100%') {
        div.style.height = 'auto';
      }
    }
  }

  static resizeIframe(index) {
    const frame = document.getElementById(`frame_${index}`);
    if (!frame || !frame.contentWindow || !frame.contentWindow.document || !frame.contentWindow.document.body) {
      return;
    }
    let iframeHeigth = 0;
    const currentHeight = frame.style.height;
    const scrollHeight = frame.contentWindow.document.body.scrollHeight;

    if (scrollHeight + 'px' !== currentHeight) {
      // we have kept > sign to avoid any resizing flicker
      frame.style.cssText = `height: ${scrollHeight + 10}px;`;
    }

    const frameDiv = frame.parentNode; // document.getElementById(`frame_div_${i}`);
    if (frameDiv) {
      if (frameDiv.style.height === scrollHeight + 10 + 'px') {
        return;
      }
      frameDiv.style.cssText = 'height:0px;';
      iframeHeigth = scrollHeight + 10;
      frameDiv.style.cssText = `height:${iframeHeigth}px;`;
    }
  }

  static getMailContent(input, index) {
    //console.log("going to access mail content...")
    CMHtml4Defs();
    CMHtmlSanitizerURI();
    CMHtmlSanitizer();

    let content = input.sc_content;
    content = HTMLSanitizerSlashHref(content);

    content = HTMLSanitizerColorScheme(content);
    content = HTMLSanitizerWrapper(content);
    content = SmartPreview.CMSmartBodyWordWrap(content);
    let smartBody = content;
    if (input.generate_smart_body === true) {
      smartBody = SmartPreview.GenerateSmartBody(content);
    }
    let quotedText = '';
    if (input.display_quoted_text_button === true) {
      if (!input.generate_smart_body || (input.generate_smart_body && content.length !== smartBody.length)) {
        quotedText = `<div style="display:block;"> \
        <div id="sc_show_quoted_text" class="sc_show_quoted_text"> \
        <img id="sc_show_quoted_text_tap_icon" class="sc_show_quoted_text_tap_icon" width="16" height="4" src="${ThreeDots}"/>
        </div>
        </div>
        `;
      }
    }
    //console.log("quotedText...",quotedText)
    return `<div id='sc_content_${index}' dir='auto'> \
        ${smartBody}${quotedText} \
      </div>`;
  }

  static CMSmartBodyWordWrap(str) {
    // Insert WBR tag in case of too many consecutive
    // repetitions of a single item
    str = str.replace(/(.)\1{24}/g, '$&<wbr>');
    // Also insert WBR when too many characters or numbers
    // are repeated without spaces or special characters
    str = str.replace(/[0-9a-z]{250}/g, '$&<wbr>');
    return str;
  }

  render(transform = true) {
    this.document.body.style.minWidth = `${this.input.webview_width}px`;

    this.contentNode = this.document.getElementById(`sc_mail_${this.index}`);
    let contentNodeWidth = this.input.webview_width;

    // In case of large html body, don't calculate scrollWidth as it is an expensive operation before dom load.
    // Instead just use the webviewWidth. This will also avoid Hyphenation.
    if (this.input.is_large_msg_body === 0) {
      contentNodeWidth = this.contentNode.scrollWidth;
    }

    const iframe = document.getElementById(`frame_${this.index}`);
    this.webviewWidth = iframe.offsetWidth;
    this.scaleRatio = this.webviewWidth / contentNodeWidth;

    try {
      this.CMMakeHyperLink();
    } catch (err) {
      console.log(err);
    }

    this.CMHandleInlineImages(`sc_mail_${this.index}`, true);

    // We don't want images which are loaded later and whose
    // width is not specified to go outside the window width
    this.CMmanipulateImages();

    const meetingDetails = this.input.meeting_details;
    if (meetingDetails) {
      this.CMShowMeetingDetails(meetingDetails);
    }

    this.manipulateDivHeight();
    transform && this.setTransform();
  }

  setTransform() {
    const node = this.document.getElementById(`sc_mail_${this.index}`);
    const frame = document.getElementById(`frame_${this.index}`);
    const width = document.getElementById(`frame_${this.index}`).contentWindow.innerWidth;
    if (node.scrollWidth > width) {
      //  fix height issue of mail and clipping issue
      // const height = frame.style.cssText.height;
      // for some case the attached immage was getting very small scaling was wrong to avoid it we did this
      // http://stackoverflow.com/questions/11382473/resize-external-website-content-to-fit-iframe-width
      // frame.style.cssText = `width:${node.scrollWidth}px; height:${node.scrollHeight}px`;
      // self.zoomState = true;
      const scale = (width / node.scrollWidth).toFixed(2);

      if (scale < 0.75 && this.onScaleChange) {
        this.onScaleChange();
      }

      node.style.webkitTransformOrigin = '0 0';

      // resizing of the iframe is done after scaling
      const scrollHeight = frame.contentDocument.getElementById(`sc_mail_${this.index}`).scrollHeight;
      frame.style.cssText = `height: ${scrollHeight * scale + 10}px;`; // plus 10 is done for a particular mail where content was getting clipped
      const frameDiv = frame.parentNode;
      if (frameDiv) {
        frameDiv.style.cssText = 'height:0px;';
        const iframeHeigth = scrollHeight * scale + 100;
        frameDiv.style.cssText = `height:${iframeHeigth}px;`;
      }
    }
  }

  static GenerateSmartBody(content) {
    try {
      /** general spacers for time and date */
      const spacers = '[\\s,/\\.\\-]';

      const timeTaggerTagStart = '(?:<a\\s+class\\s*=\\s*"sc_tt_anchor"[^>]*>\\s*)?';
      const timeTaggerTagEnd = '(?:\\s*<\\/a>)?';

      /** matches times */
      const timePattern = `${timeTaggerTagStart}(?:[0-2])?[0-9]:[0-5][0-9](?::[0-5][0-9])?(?:(?:\\s)?[AP]M)?${timeTaggerTagEnd}`;

      /** matches day of the week */
      const dayPattern = `${timeTaggerTagStart}(?:(?:Mon(?:day)?)|(?:Tue(?:sday)?)|(?:Wed(?:nesday)?)|(?:Thu(?:rsday)?)|(?:Fri(?:day)?)|(?:Sat(?:urday)?)|(?:Sun(?:day)?))${timeTaggerTagEnd}`;

      /** matches day of the month (number and st, nd, rd, th) */
      const dayOfMonthPattern = `[0-3]?[0-9]${spacers}*(?:(?:th)|(?:st)|(?:nd)|(?:rd))?`;

      /** matches months (numeric and text) */
      const monthPattern =
        '(?:(?:Jan(?:uary)?)|(?:Feb(?:uary)?)|(?:Mar(?:ch)?)|(?:Apr(?:il)?)|(?:May)|(?:Jun(?:e)?)|(?:Jul(?:y)?)|(?:Aug(?:ust)?)|(?:Sep(?:tember)?)|(?:Oct(?:ober)?)|(?:Nov(?:ember)?)|(?:Dec(?:ember)?)|(?:[0-1]?[0-9]))';

      /** matches years (only 1000's and 2000's, because we are matching emails) */
      const yearPattern = '(?:[1-2]?[0-9])[0-9][0-9]';

      /** matches a full date */
      const datePattern = `(?:${dayPattern}${spacers}+)?(?:(?:${dayOfMonthPattern}${spacers}+${monthPattern})|(?:${monthPattern}${spacers}+${dayOfMonthPattern}))${spacers}+${yearPattern}`;

      /** matches a date and time combo (in either order) */
      const dateTimePattern = `(?:${datePattern}[\\s,]*(?:(?:at)|(?:@))?\\s*${timePattern})|(?:${timePattern}[\\s,]*(?:on)?\\s*${datePattern})`;

      /** matches a leading line such as
       * ----Original Message----
       * or simply
       * ------------------------
       */
      const leadInLine = '---+\\s*(?:Original)\\sMessage\\s*---+';

      /** matches a header line indicating the date */
      const dateLine = `(>|\n)(?:(?:date)|(?:sent)|(?:time)):\\s*${dateTimePattern}.*\n`;

      /** matches a subject or address line */
      const subjectOrAddressLine = '((?:(>|\n)from:)|(?:(>|\n)subject:))';

      /** matches gmail style quoted text beginning, i.e.
       * On Mon Jun 7, 2010 at 8:50 PM, Simon wrote:
       */
      const gmailQuotedTextBeginning = `(On\\s+${dateTimePattern}.*wrote:)`;

      const Pattern = /garbage/;

      Pattern.compile(leadInLine, 'gi');

      let pos = content.search(Pattern);

      Pattern.compile(subjectOrAddressLine, 'gi');
      let tpos = content.search(Pattern);
      if (pos === -1) {
        pos = tpos;
      } else if (tpos !== -1 && tpos < pos) {
        pos = tpos;
      }

      Pattern.compile(gmailQuotedTextBeginning, 'gi');
      tpos = content.search(Pattern);
      if (pos === -1) {
        pos = tpos;
      } else if (tpos !== -1 && tpos < pos) {
        pos = tpos;
      }

      Pattern.compile(dateLine, 'gi');
      tpos = content.search(Pattern);
      if (pos === -1) {
        pos = tpos;
      } else if (tpos !== -1 && tpos < pos) {
        pos = tpos;
      }

      if (pos !== -1) {
        content = content.substr(0, pos);
      }
    } catch (e) {
      console.log(e);
    }
    return content;
  }

  CMShowMeetingDetails(meetingDetails) {
    //console.log("calling CMShowMeetingDetails....  with meetingDetails :-",meetingDetails)
    const div = this.document.createElement('div');
    div.id = 'sc_meeting_details_dummy';
    if (meetingDetails.is_dummy) {
      div.className = 'sc_meeting_details_dummy';
      div.style.backgroundColor = 'rgb(99, 99, 189)';

      const spinner = this.document.createElement('div');
      spinner.className = 'spinner';
      const bounce1 = this.document.createElement('div');
      bounce1.className = 'bounce1';
      const bounce2 = this.document.createElement('div');
      bounce2.className = 'bounce2';
      const bounce3 = this.document.createElement('div');
      bounce3.className = 'bounce3';
      spinner.appendChild(bounce1);
      spinner.appendChild(bounce2);
      spinner.appendChild(bounce3);
      div.appendChild(spinner);
      const node = this.document.getElementById(`sc_content_${this.index}`);
      this.contentNode.insertBefore(div, node);

      const scaleRatio = (this.webviewWidth / node.scrollWidth) * 100;
      if (scaleRatio < 100) {
        node.style.zoom = `${scaleRatio}%`;
        node.style.width = `${(this.webviewWidth * 100) / scaleRatio}px`;
      }
      SmartPreview.resizeIframe(this.index);
      return;
    }
  }

  removeRSVPLoader() {
    if (this.contentNode.ownerDocument.getElementById('sc_meeting_details_dummy')) {
      this.contentNode.ownerDocument.getElementById('sc_meeting_details_dummy').remove();
    }
  }

  CMHandleOnErrorImage(e) {
    if (e.target.src.toLowerCase().substring(0, 4) === 'cid:' && this.CMshouldManipulateImages) {
      e.target.width = 0;
      e.target.height = 0;
    }
    e.stopPropagation();
  }

  CMHandleOnLoadImage(e) {
    // Image max Width reset according to scaling done
    // after load, to counteract the effect
    // of setting maxWidth as 100%
    // Test case: FB mails with images.
    // Without this it will overlap
    if (this.CMshouldManipulateImages) {
      const ratio = this.scaleRatio;

      const width = e.target.scrollWidth;
      let maxElementWidth = width / ratio;
      const maxWebviewWidth = this.webviewWidth / ratio;

      if (maxElementWidth > maxWebviewWidth) {
        maxElementWidth = maxWebviewWidth;
      }

      e.target.style.maxWidth = maxElementWidth;
    }
    e.stopPropagation();
  }

  CMMakeHyperLink() {
    // eslint-disable-next-line no-useless-escape
    let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    this.CMLinkify(this.contentNode, exp, false, false);
    exp = /(\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b)/gi;
    this.CMLinkify(this.contentNode, exp, true, false);
    exp =
      /((?:\+?(\d{1,3} *)?[-.(]*)?\b(\d{3})[-. )]*(\d{3})[-. ]*(\d{2,4})(?: *x(\d+))?\s*?\b)|(\+(\d{1,3})?(\d{10,14})\s*?)/;
    this.CMLinkify(this.contentNode, exp, false, true);
  }

  CMLinkify(contentNode, regex, ismailto, isphone) {
    const temp = contentNode.childNodes;

    const startTime = new Date().getTime();
    const maxTimeOut = 150;

    for (let i = 0; i < temp.length; i += 1) {
      const node = temp[i];

      // var endTimeOuter = new Date().getTime();
      // if (endTimeOuter - startTime > maxTimeOut) {
      //     return;
      // }

      if (node.nodeName.toLowerCase() === 'style') {
        continue;
      }

      if (node.nodeType === 3 && node.parentNode.nodeName.toLowerCase() !== 'a') {
        let searchnode = node;
        let pos = -1;
        while ((pos = searchnode.data.search(regex)) >= 0) {
          let match = searchnode.data.slice(pos);
          match = match.match(regex)[0];
          if (match.length === 0) break;
          const anode = this.document.createElement('a');
          const middlebit = searchnode.splitText(pos);
          searchnode = middlebit.splitText(match.length);
          const middleclone = middlebit.cloneNode(true);
          anode.appendChild(middleclone);
          if (ismailto === true) {
            anode.href = `mailto:${middleclone.nodeValue}`;
          } else if (isphone === true) {
            anode.href = `tel:${middleclone.nodeValue}`;
          } else {
            anode.href = middleclone.nodeValue;
          }

          node.parentNode.replaceChild(anode, middlebit);
          searchnode = node;

          const endTimeInner = new Date().getTime();
          if (endTimeInner - startTime > maxTimeOut) {
            return;
          }
        }
      } else {
        if (node.parentNode.nodeName.toLowerCase() !== 'a') {
          this.CMLinkify(node, regex, ismailto, isphone);
        }
      }
    }
  }

  CMHandleInlineImages(contentNodeId, shouldManipulateImages) {
    const CMinlineAttachments = this.input.inline_attachment;
    const contentNode = this.document.getElementById(contentNodeId);
    const imageNodes = contentNode.getElementsByTagName('IMG');
    this.CMshouldManipulateImages = shouldManipulateImages;
    const index = this.index;
    for (let i = 0; i < imageNodes.length; i += 1) {
      if (CMinlineAttachments && imageNodes[i].src) {
        const src = imageNodes[i].src.toLowerCase();

        if (src.substring(0, 4) === 'cid:') {
          for (let j = 0; j < CMinlineAttachments.length; j += 1) {
            if (src === `cid:${CMinlineAttachments[j].cid?.toLowerCase()}`) {
              imageNodes[i].setAttribute('CIDSrc', src);
              imageNodes[i].setAttribute('draggable', true);
              imageNodes[i].src = CMinlineAttachments[j].content_url;
              imageNodes[i].addEventListener('error', this.CMHandleOnErrorImage, false);
              imageNodes[i].addEventListener('load', () => {
                SmartPreview.resizeIframe(index);
              });
              break;
            }
          }
        }
      }
    }
  }

  CMmanipulateImages() {
    const images = this.contentNode.getElementsByTagName('img');
    for (let i = 0; i < images.length; i += 1) {
      const image = images[i];
      if (image.offsetWidth > this.webviewWidth) {
        image.style.cssText = 'width: 100%';
      }
    }
  }
}
