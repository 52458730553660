import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from './../actions/types';

const adminLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_ADMIN_LOADER
});
const companyListByUserEmailReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_COMPANY_LIST_BY_USER_EMAIL
});

const adminReducer = combineReducers({
  loading: adminLoaderReducer,
  companyListByUserEmail: companyListByUserEmailReducer
});

export default adminReducer;
