import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Email Loader
 */
export const setEmailLoader = createAction(Actions.SET_EMAIL_LOADER);

/**
 * @desc Set Email List
 */
export const setEmailList = createAction(Actions.SET_EMAIL_LIST);
/**
 * @desc Update Email Account List
 */
export const updateEmailAccountList = createAction(Actions.UPDATE_EMAIL_ACCOUNT_LIST);
/**
 * @desc Set Email List Item
 */
export const setEmailListItem = createAction(Actions.UPDATE_EMAIL_LIST_ITEM);
/**
 * @desc Set Email List
 */
export const setEmailLabelsList = createAction(Actions.SET_EMAIL_LABEL_LIST);

/**
 * @desc Set Email Accounts
 */
export const setEmailAccounts = createAction(Actions.SET_EMAIL_ACCOUNTS);

/**
 * @desc Set Email Accounts
 */
export const setEmailSyncSettings = createAction(Actions.SET_EMAIL_SYNC_SETTINGS);

/**
 * @desc Update Email Accounts
 */
export const updateSyncSettingsFields = createAction(Actions.UPDATE_SYNC_SETTINGS_FIELD_VALUE);

/**
 * @desc Set Email Detail
 */
export const setEmailDetail = createAction(Actions.SET_EMAIL_DETAIL);

/**
 * @desc Set Email Templates
 */
export const setEmailTemplates = createAction(Actions.SET_EMAIL_TEMPLATES);

/**
 * @desc Set Email Template Item
 */
export const setEmailTemplateItem = createAction(Actions.SET_EMAIL_TEMPLATE_ITEM);

/**
 * @desc Update Email Template Item
 */
export const updateEmailTemplateItem = createAction(Actions.UPDATE_EMAIL_TEMPLATE_ITEM);

/**
 * @desc Set Email Item
 */
export const setEmailItem = createAction(Actions.SET_EMAIL_ITEM);

/**
 * @desc Update Email Item
 */
export const updateEmailItem = createAction(Actions.UPDATE_EMAIL_ITEM);
/**
 * @desc Add Email Files
 */
export const addEmailFiles = createAction(Actions.ADD_EMAIL_FILES);
/**
 * @desc Delete Email Files
 */
export const deleteEmailFiles = createAction(Actions.DELETE_EMAIL_FILES);

/**
 * @desc Clear Email Item
 */
export const clearEmailItem = createAction(Actions.CLEAR_EMAIL_ITEM);

/**
 * @desc Set Total Email Count
 */
export const setTotalEmailCount = createAction(Actions.SET_TOTAL_EMAIL_COUNT);

/**
 * @desc Delete Email Item
 */
export const deleteEmail = createAction(Actions.DELETE_EMAIL_ITEM);

/**
 * @desc Clear Email Data
 */
export const clearEmailData = () => (dispatch) => {
  dispatch(setEmailLoader(false));
  dispatch(setEmailList(null));
  dispatch(setEmailLabelsList(null));
  dispatch(setEmailAccounts(null));
  dispatch(setEmailSyncSettings(null));
  dispatch(setEmailDetail(null));
  dispatch(setEmailTemplates(null));
  dispatch(setEmailTemplateItem(null));
  dispatch(setTotalEmailCount(null));
};
