import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';
import DropDown from '../../../components/DropDown/DropDown';
import {
  getCurrentUserProfile,
  trackUserProfileUpdateAnalyticActivity,
  updateCurrentUserProfile,
  uploadUserProfilePhoto
} from '../../../services/authServices';
import { updateCurrentUserProfileFieldValue } from '../../../actions/authActions';
import { CountryCodes } from '../../../data/raw';
import SnackBar from '../../../components/SnackBar/SnackBar';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  Wrapper,
  ContainList,
  FormList,
  TitleView,
  HeadText,
  FirstBox,
  PersonalList,
  FirstList,
  TitleHead,
  CaptionList,
  ImgContain,
  ImageLogo,
  ImgText,
  InputView,
  LabelFile,
  FileBtn,
  FillList,
  FillListTwo,
  InpView,
  InpViewTwo,
  BtnView,
  UploadProfile,
  DropDownTitle
} from './styles';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';

let CountryWithCodes = CountryCodes;

CountryWithCodes = CountryWithCodes.map((item) => {
  return { ...item, text: item.country + ' (' + item.code + ')' };
});

const MyProfile = () => {
  const mobile = useMobileDevice();
  const authSelector = useSelector((state) => state.auth);
  let { currentUserProfile, loading } = authSelector;
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    dispatch(getCurrentUserProfile());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateValue = useCallback(
    (propName, value) => {
      dispatch(updateCurrentUserProfileFieldValue({ propName, value }));
    },
    [dispatch]
  );

  const onClickSave = useCallback(
    async (e) => {
      e.preventDefault();
      const result = await dispatch(updateCurrentUserProfile(currentUserProfile));
      if (result) {
        trackUserProfileUpdateAnalyticActivity('info updated');
      }
    },
    [dispatch, currentUserProfile]
  );

  const onFileUpload = useCallback(
    async (file) => {
      // Create an object of formData
      const formData = new FormData();
      formData.append('myFile', file, file?.name);
      const result = await dispatch(uploadUserProfilePhoto(formData));
      if (result) {
        trackUserProfileUpdateAnalyticActivity('info updated');
      }
    },
    [dispatch]
  );

  return (
    <Wrapper>
      <ContainList mobile={mobile}>
        <FormList>
          {mobile ? null : (
            <TitleView>
              <HeadText>Profile</HeadText>
            </TitleView>
          )}
          <FirstBox mobile={mobile}>
            <PersonalList>
              <FirstList>
                <TitleHead>Personal Info</TitleHead>
                <CaptionList mobile={mobile}>
                  By letting us know your name, we can make our support experience much more personal.
                </CaptionList>
                <ImgContain>
                  {currentUserProfile.profile_photo ? (
                    <ImageLogo mobile={mobile}>
                      <UploadProfile src={currentUserProfile.profile_photo} />
                    </ImageLogo>
                  ) : (
                    <ImageLogo>
                      <ImgText>NO IMAGE</ImgText>
                    </ImageLogo>
                  )}
                  <InputView>
                    <FileBtn>
                      <LabelFile htmlFor='upload'>Select File...</LabelFile>
                    </FileBtn>
                    <Input
                      isPrimarySmall={true}
                      type='file'
                      id='upload'
                      name='myfile'
                      onChange={(e) => onFileUpload(e.target.files[0])}
                      hidden
                    />
                  </InputView>
                </ImgContain>
                <FillList>
                  <Input
                    isPrimarySmall={true}
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Enter your Full Name'
                    title='First Name'
                    value={currentUserProfile?.name || ''}
                    onChange={(e) => updateValue('name', e.target.value)}
                  />
                </FillList>
                <FillListTwo mobile={mobile}>
                  <InpView>
                    <DropDownTitle>Select Country</DropDownTitle>
                    <DropDown
                      labelField={'text'}
                      valueField={'code'}
                      placeholder={'India (+91)'}
                      options={CountryWithCodes}
                      value={currentUserProfile?.countryCode || ''}
                      onChange={(e) => {
                        updateValue('countryCode', e.value);
                      }}
                    />
                  </InpView>
                  <InpViewTwo mobile={mobile}>
                    <Input
                      isPrimarySmall={true}
                      type='number'
                      id='phone'
                      name='phone'
                      placeholder='Enter your Contact number'
                      title='Contact Number'
                      value={currentUserProfile?.phone || ''}
                      onChange={(e) => updateValue('phone', e.target.value)}
                    />
                  </InpViewTwo>
                </FillListTwo>
              </FirstList>
              <BtnView>
                <Button
                  onClick={(e) => {
                    onClickSave(e);
                  }}
                  title='Save'
                  disabled={loading}
                  type='button'
                />
              </BtnView>
            </PersonalList>
          </FirstBox>
          <ChangeEmail />
          <ChangePassword />
        </FormList>
        <SnackBar />
      </ContainList>
    </Wrapper>
  );
};

export default MyProfile;
