import React from 'react';
import NoContactActivity from '../../../../assets/images/svg/no-activity-found-graphics.svg';
import { ContactActivityEmptyWrap, EmptyContact, ContactHead, ContactSpan, ContactLink } from './styles';

const ContactActivityEmptyState = ({ onClickAddActivity }) => {
  return (
    <>
      <ContactActivityEmptyWrap>
        <EmptyContact src={NoContactActivity} />
        <ContactHead>No Activity Found</ContactHead>
        <ContactSpan>There is no Activity for you, please add Activity.</ContactSpan>
        <ContactLink
          onClick={(e) => {
            onClickAddActivity(e);
          }}>
          Add Activity
        </ContactLink>
      </ContactActivityEmptyWrap>
    </>
  );
};

export default ContactActivityEmptyState;
