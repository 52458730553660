import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { LOOKUP_SEGMENT_FIELD_TYPE, SEGMENT_CONDITION_TYPE } from '../constants/constant';

const lookupLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_LOOKUP_LOADER
});

const initialState = [];
const lookupListReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_LOOKUP_LIST, (state = initialState, action) => {
      return action.payload;
    })
    .addCase(Actions.UPDATE_LOOKUP_ITEM, (state, action) => {
      const list = [...(state || [])];
      const lookup = action.payload;
      if (lookup) {
        const lookupIndex = list.findIndex((x) => x.id === lookup.id);
        if (lookupIndex !== -1) {
          list[lookupIndex] = lookup;
        }
      }
      return list;
    });
});

const segmentListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_SEGMENT_LIST
});

const manageSegmentListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_MANAGE_SEGMENT_LIST
});

const lookupDetailReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_LOOKUP_DETAIL
});

const lookupGroupFieldsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_LOOKUP_GROUP_FIELDS
});

const lookupValidationErrorReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_LOOKUP_VALIDATION_ERROR
});

const initialSegmentFilterItemState = {
  name: '',
  filters: [
    {
      condition: undefined,
      field: undefined,
      type: LOOKUP_SEGMENT_FIELD_TYPE.LookupField,
      localConditionOptions: []
    }
  ],
  condition: SEGMENT_CONDITION_TYPE.MATCH_ALL
};
const segmentFilterReducer = createReducerOrig(initialSegmentFilterItemState, (builder) => {
  builder
    .addCase(Actions.SET_SEGMENT_FILTER, (state = initialSegmentFilterItemState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_SEGMENT_FIELD, (state, action) => {
      const segmentFilter = { ...state };
      segmentFilter[action.payload.propsName] = action.payload.value;
      return { ...segmentFilter };
    })
    .addCase(Actions.ADD_SEGMENT_FILTER_ITEM, (state, action) => {
      const segmentFilter = { ...state };
      const filters = segmentFilter.filters.slice();
      filters.push(action.payload.item);
      segmentFilter.filters = filters;
      return { ...segmentFilter };
    })
    .addCase(Actions.UPDATE_SEGMENT_FILTER_ITEM, (state, action) => {
      const segmentFilter = { ...state };
      const filters = segmentFilter.filters.slice();
      filters[action.payload.index] = action.payload.item;
      segmentFilter.filters = filters;
      return { ...segmentFilter };
    })
    .addCase(Actions.DELETE_SEGMENT_FILTER_ITEM, (state, action) => {
      const segmentFilter = { ...state };
      const filters = segmentFilter.filters.slice();
      if (action.payload.index !== -1) {
        filters.splice(action.payload.index, 1);
      }
      segmentFilter.filters = filters;
      return { ...segmentFilter };
    })
    .addCase(Actions.CLEAR_SEGMENT_FILTER, () => {
      const initialSegmentState = JSON.parse(JSON.stringify(initialSegmentFilterItemState));
      return initialSegmentState;
    });
});

const initialTagsState = [];
const lookupTagsReducer = createReducerOrig(initialTagsState, (builder) => {
  builder
    .addCase(Actions.SET_LOOKUP_TAGS, (state = initialTagsState, action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.REMOVE_LOOKUP_TAG, (state, action) => {
      const lookupTags = [...state];
      const tags = lookupTags.slice();
      let tagIndex;
      if (!action.payload.index) {
        tagIndex = tags?.findIndex((x) => x === action.payload.item);
      }
      const index = action.payload.index || tagIndex;
      if (index !== -1) {
        tags.splice(index, 1);
      }
      return [...tags];
    })
    .addCase(Actions.ADD_LOOKUP_TAG, (state, action) => {
      const lookupTags = [...state];
      const tags = lookupTags.slice();
      tags.push(action.payload.item);
      return [...tags];
    });
});

const connectedLookupReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_LOOKUP_CONNECTED_LOOKUPS
});

const lookupsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_ALL_LOOKUP
});

const lookupFieldsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_LOOKUP_FIELDS
});

const enrichmentInitialState = [];
const enrichmentFieldsReducer = createReducerOrig(enrichmentInitialState, (builder) => {
  builder
    .addCase(Actions.SET_LOOKUP_ENRICHMENT_FIELDS, (state = enrichmentInitialState, action) => {
      return action.payload;
    })
    .addCase(Actions.UPDATE_LOOKUP_ENRICHMENT_ITEM, (state, action) => {
      const enrichment = JSON.parse(JSON.stringify([...(state || [])]));
      const propsName = action.payload.propsName;
      const value = action.payload.value;
      const index = action.payload.index;
      if (index !== -1) {
        enrichment[index][propsName] = value;
      }
      return enrichment;
    });
});

const lookupReducer = combineReducers({
  loading: lookupLoaderReducer,
  list: lookupListReducer,
  segments: segmentListReducer,
  lookupDetail: lookupDetailReducer,
  groupFields: lookupGroupFieldsReducer,
  validationError: lookupValidationErrorReducer,
  manageSegments: manageSegmentListReducer,
  segmentFilter: segmentFilterReducer,
  tags: lookupTagsReducer,
  lookups: lookupsReducer,
  connectedLookup: connectedLookupReducer,
  fields: lookupFieldsReducer,
  enrichmentFields: enrichmentFieldsReducer
});

export default lookupReducer;
