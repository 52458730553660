import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';
/**
 * @desc Set Goal Loader
 */
export const setGoalLoader = createAction(Actions.SET_GOAL_LOADER);
/**
 * @desc Set Goal List
 */
export const setGoalList = createAction(Actions.SET_GOAL_LIST);
/**
 * @desc Set Goal Item
 */
export const setGoalItem = createAction(Actions.SET_GOAL_ITEM);
/**
 * @desc Update Goal Item
 */
export const updateGoalItem = createAction(Actions.UPDATE_GOAL_ITEM);
/**
 * @desc Delete Goal Item
 */
export const deleteGoalItem = createAction(Actions.DELETE_GOAL_ITEM);
/**
 * @desc clear Goal Item
 */
export const clearGoalItem = createAction(Actions.CLEAR_GOAL_ITEM);
/*
 * @desc Clear Goal Data
 */
export const clearGoalData = () => (dispatch) => {
  dispatch(setGoalLoader(false));
  dispatch(setGoalList(null));
};
