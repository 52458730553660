import { DialogContent, DialogActions } from '@material-ui/core';
import styled from 'styled-components';
import { Header4, Text1 } from '../../styles';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  i {
    cursor: pointer;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  margin-left: 12px;
  display: inline-block;
  ${Header4}
`;
const Backdrop = styled.div`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
`;
const Paper = styled.div`
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0px 6px 25px var(--modal-box-shadow);
  width: 560px;
`;
const DialogContentBox = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 16px 16px 24px 16px;
  }
  .MuiDialogContentText-root {
    margin: 0;
    ${Text1}
    color:var(--sonic-silver);
    letter-spacing: unset;
    font-family: var(--primary-font-stack);
  }
`;
const DialogActionsBox = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0 16px 16px 16px;
  }
  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 12px;
  }
`;

export { HeaderWrapper, HeaderLeft, Title, Backdrop, Paper, DialogContentBox, DialogActionsBox };
