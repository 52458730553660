import styled, { css } from 'styled-components';
import { Header2, Header4, Header6, Text1, Text2 } from '../../../styles';

const ManeWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const RightWrapper = styled.div`
  width: 100%;
  margin-left: 215px;
  overflow: auto;
  height: 100vh;
`;
const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
`;
const ContainList = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 16px 16px 16px;
        `
      : css`
          margin: 16px auto;
          width: 100%;
          max-width: 680px;
        `}
`;
const FormList = styled.form``;
const TitleView = styled.div``;
const HeadText = styled.h4`
  font-style: normal;
  color: var(--dark-color);
  margin: 24px 0 0 0;
  ${Header2};
`;
const FirstBox = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  border-radius: 6px;
  ${(props) =>
    props.mobile
      ? css`
          margin-top: 0;
        `
      : css`
          margin-top: 24px;
        `}
`;
const PersonalList = styled.div``;
const FirstList = styled.div`
  padding: 16px 16px 24px 16px;
`;
const TitleHead = styled.h5`
  font-style: normal;
  ${Header4};
  color: var(--dark-color);
  margin: 0 0 4px 0;
`;
const CaptionList = styled.p`
  font-style: normal;
  color: var(--gray-color);
  margin: 0;
  ${(props) =>
    props.mobile
      ? css`
          ${Text1};
        `
      : css`
          ${Text2};
        `}
`;
const ImgContain = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const UploadProfile = styled.img`
  width: 100%;
  border-radius: 100%;
  height: 100%;
  object-fit: cover;
`;

const DropDownTitle = styled.span`
  display: block;
  ${Text1}
  color: var(--dark-color);
  margin-bottom: 6px;
`;
const ImageLogo = styled.div`
  background: #efefef;
  border: 1px solid var(--grey-border-color);
  box-sizing: border-box;
  border-radius: 50px;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          width: 60px;
          height: 60px;
        `
      : css`
          width: 80px;
          height: 80px;
        `}
`;
const ImgText = styled.span`
  font-style: normal;
  ${Header6};
  text-align: center;
  color: var(--gray-color);
  padding: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const InputView = styled.div`
  margin-left: 20px;
  label {
    margin: 0;
  }
`;
const FileBtn = styled.div``;
const LabelFile = styled.label`
  background-color: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  ${Text2};
  color: var(--sonic-silver);
  padding: 7px 12px;
`;
const FillList = styled.div``;
const FillListTwo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const InpView = styled.div`
  margin-right: 6px;
  width: 100%;
`;
const InpViewTwo = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 0px;
          margin-top: 16px;
        `
      : css`
          margin-left: 6px;
        `}
`;
const InpViewThree = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 0px;
          margin-top: 16px;
        `
      : css`
          margin-left: 12px;
        `}
`;
const BtnView = styled.div`
  border-top: 1px solid #e4e4e5;
  padding: 12px 16px;
`;

export {
  Wrapper,
  ManeWrapper,
  RightWrapper,
  ContainList,
  FormList,
  TitleView,
  HeadText,
  FirstBox,
  PersonalList,
  FirstList,
  TitleHead,
  CaptionList,
  ImgContain,
  ImageLogo,
  ImgText,
  InputView,
  LabelFile,
  FileBtn,
  FillList,
  FillListTwo,
  InpView,
  InpViewTwo,
  BtnView,
  UploadProfile,
  DropDownTitle,
  InpViewThree
};
