import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Modal as CoreModal } from '@material-ui/core';
import { useLocation } from 'react-router';
import { arrayMoveImmutable } from 'array-move';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input/Input';
import SnackBar from '../../../../components/SnackBar/SnackBar';
import Button from '../../../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../../../components/Menu';
import { LookupFieldTypes } from '../../../../constants/constant';
import {
  addCustomizeFieldGroup,
  deleteCustomizeField,
  deleteCustomizeFieldGroup,
  setCustomizeFields,
  updateCustomizeFieldGroupName,
  updateCustomizeGroupFields
} from '../../../../actions/settingActions';
import { getUniqueId, isEmpty } from '../../../../helpers/common';
import Message from '../../../../components/Message/Message';
import {
  getCustomizeFields,
  trackCustomizeCollectionAnalyticActivity,
  updateCustomizeFields
} from '../../../../services/settingService';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import EditFieldModal from './EditField/EditField';
import AddFieldModal from './AddField/AddField';
import {
  FirstList,
  IconView,
  ListBox,
  MainList,
  NavigationBlock,
  NavigationTitleCord,
  NavigationWrapper,
  SubText,
  ModifyUserWrapper,
  Form,
  ModifyUserBlock,
  ModifyUserContent,
  SecondContain,
  DragIcon,
  DragWrapper,
  FieldWrapper,
  Span,
  GroupWrapper,
  PageWrapper
} from './styles';

const SortableItem = SortableElement(({ value, fieldGroupIndex, onOpenMenu, renderFieldIcon }) => {
  return (
    <ModifyUserContent key={value?.id || value?.localId}>
      <DragIcon className='leftIcon' title='Drag'>
        <SVGIcon name='icon-drag-and-drop' fill='var(--gray-icon-color)' size={12} />
      </DragIcon>
      <ListBox>
        <DragWrapper>
          {renderFieldIcon(value?.type)}
          <FirstList>
            <SubText>{value?.label}</SubText>
          </FirstList>
        </DragWrapper>
        <IconView onClick={(e) => onOpenMenu(e, value, fieldGroupIndex)}>
          <SVGIcon className={fieldGroupIndex} name='icon-more' fill='var(--gray-more-icon)' size={22} />
        </IconView>
      </ListBox>
    </ModifyUserContent>
  );
});

const SortableList = SortableContainer(({ items, fieldGroupIndex, onOpenMenu, renderFieldIcon }) => {
  return (
    <div>
      {items?.map((item, index) => {
        return (
          <SortableItem
            key={`${item?.id}_${index}`}
            distance={1}
            index={index}
            renderFieldIcon={renderFieldIcon}
            value={item}
            onOpenMenu={onOpenMenu}
            fieldGroupIndex={fieldGroupIndex}
          />
        );
      })}
    </div>
  );
});

const CustomizeFields = (props) => {
  const location = useLocation();
  const search = location?.search;
  const URLParams = new URLSearchParams(search);
  const fieldId = URLParams.get('field-id');
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const settingSelector = useSelector((state) => state.setting);
  const { customizeFields, loading } = settingSelector;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;

  const lookupName = props?.match.params.name;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [fieldGroupIndex, setFieldGroupIndex] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isEdit, setIsEdit] = useState(true);

  const table = useMemo(() => {
    const currentTable = lookupTableList.find((x) => x.name === lookupName);
    return currentTable;
  }, [lookupTableList, lookupName]);

  const onOpenEditMenu = useCallback((field, fieldGroupIndex) => {
    setSelectedItem(field);
    setFieldGroupIndex(fieldGroupIndex);
    setIsMenuOpen(false);
    setIsEditMenuOpen(true);
  }, []);

  const onCloseEditMenu = useCallback(() => {
    setIsEditMenuOpen(false);
  }, []);

  useEffect(() => {
    if (fieldId && !isEmpty(customizeFields) && isEdit) {
      customizeFields?.forEach((item, index) => {
        const field = item?.fields?.find((x) => x?.id === fieldId);
        if (field) {
          onOpenEditMenu(field, index);
          setIsEdit(false);
        }
      });
    }
  }, [customizeFields, onOpenEditMenu, isEdit, fieldId]);

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getCustomizeFields(organization_id, table?.id, true));
  }, [dispatch, organization_id, table?.id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setCustomizeFields());
    };
  }, [dispatch, loadData]);

  const onOpenMenu = useCallback((e, item, fieldGroupIndex) => {
    e.preventDefault();
    setSelectedItem(item);
    setFieldGroupIndex(fieldGroupIndex);
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenAddMenu = useCallback((e) => {
    e.stopPropagation();
    setIsMenuOpen(false);
    setIsAddMenuOpen(true);
  }, []);

  const onCloseAddMenu = useCallback(() => {
    setIsAddMenuOpen(false);
  }, []);

  const renderFieldIcon = (type) => {
    switch (type) {
      case LookupFieldTypes.Text:
        return <SVGIcon name='icon-contact' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.MultiLineText:
        return <SVGIcon name='icon-t-square' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Number:
        return <SVGIcon name='icon-keyboard' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Amount:
        return <SVGIcon name='icon-cash-protection' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Boolean:
        return <SVGIcon name='icon-check-square' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Phone:
        return <SVGIcon name='icon-cellphone' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Date:
        return <SVGIcon name='icon-calendar' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Time:
        return <SVGIcon name='icon-timer' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Select:
        return <SVGIcon name='icon-drop-down-menu' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.MultiSelect:
        return <SVGIcon name='icon-drop-down-menu' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Email:
        return <SVGIcon name='icon-email-symbol' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Url:
        return <SVGIcon name='icon-earth' fill='var(--gray-icon-color)' size={24} />;
      case LookupFieldTypes.Lookup:
        return <SVGIcon name='icon-two-way' fill='var(--gray-icon-color)' size={24} />;
      default:
    }
  };

  const onSortEnd = (fields, oldIndex, newIndex, groupIndex) => {
    let newCustomizeFields = [];
    newCustomizeFields = arrayMoveImmutable(fields, oldIndex, newIndex);
    dispatch(updateCustomizeGroupFields({ groupIndex, fields: newCustomizeFields }));
  };

  const onCustomizeDetailBack = () => {
    props.history.push('/settings/customize/');
  };

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    customizeFields?.forEach((fieldGroup) => {
      if (!fieldGroup?.name) {
        validationErrors.fieldGroupName = 'Required';
        valid = false;
      }
    });
    setValidationErrors(validationErrors);
    return valid;
  }, [customizeFields]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;

      const payload = customizeFields?.filter((fieldGroup) => fieldGroup?.fields?.length > 0);
      const result = await dispatch(updateCustomizeFields(organization_id, table?.id, payload));
      if (result) {
        trackCustomizeCollectionAnalyticActivity('customized', table?.name, table?.type);
        props.history.push('/settings/customize/');
      }
    },
    [customizeFields, dispatch, validateInputs, organization_id, props.history, table]
  );

  const onDeleteField = useCallback(
    (fieldId, fieldGroupIndex) => {
      if (fieldId && fieldGroupIndex !== -1) {
        dispatch(
          deleteCustomizeField({
            fieldId: fieldId,
            groupIndex: fieldGroupIndex
          })
        );
        setSelectedItem({});
        onCloseMenu();
      }
    },
    [dispatch, onCloseMenu]
  );

  const onAddFieldGroup = useCallback(() => {
    let fieldGroup = {};
    fieldGroup.name = 'Group';
    fieldGroup.localId = getUniqueId();
    fieldGroup.fields = [];
    dispatch(addCustomizeFieldGroup({ fieldGroup: fieldGroup }));
  }, [dispatch]);

  const onUpdateFieldGroupName = useCallback(
    (value, index) => {
      dispatch(updateCustomizeFieldGroupName({ fieldName: value, groupIndex: index }));
    },
    [dispatch]
  );

  const onDeleteFieldGroup = useCallback(() => {
    if (fieldGroupIndex !== -1) {
      onCloseMenu();
      dispatch(deleteCustomizeFieldGroup({ groupIndex: fieldGroupIndex }));
    }
  }, [dispatch, onCloseMenu, fieldGroupIndex]);
  return (
    <PageWrapper>
      <MainList>
        <Form onSubmit={onSubmit}>
          <NavigationBlock>
            <NavigationWrapper onClick={onCustomizeDetailBack}>
              <SVGIcon name='icon-big-left-arrow' fill='var(--gray-icon-color)' size={14} strokeWidth={2} />
              <NavigationTitleCord>{`Customize ${table?.label}`}</NavigationTitleCord>
            </NavigationWrapper>
            <Button loading={loading} primary={true} title='Save' type='submit' />
          </NavigationBlock>
          {loading && isEmpty(customizeFields) ? (
            <SecondContain>Loading...</SecondContain>
          ) : (
            <SecondContain>
              {customizeFields &&
                customizeFields?.map((field, index) => {
                  return (
                    <ModifyUserBlock key={field?.id || field?.localId}>
                      <ModifyUserWrapper>
                        <Input
                          isPrimarySmall
                          type='text'
                          autocomplete='off'
                          placeholder='Input value'
                          value={field?.name}
                          onChange={(e) => {
                            onUpdateFieldGroupName(e.target.value, index);
                          }}
                        />
                        {validationErrors?.fieldGroupName && !field?.name && (
                          <Message text={validationErrors.fieldGroupName} isSuccess={false} />
                        )}
                        {!field?.isSystem && (
                          <IconView
                            onClick={(e) => {
                              onOpenMenu(e);
                              setFieldGroupIndex(index);
                            }}>
                            <SVGIcon name='icon-more' fill='var(--gray-more-icon)' size={22} />
                          </IconView>
                        )}
                      </ModifyUserWrapper>
                      <SortableList
                        fieldGroupIndex={index}
                        items={field?.fields}
                        onOpenMenu={onOpenMenu}
                        renderFieldIcon={renderFieldIcon}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          onSortEnd(field?.fields, oldIndex, newIndex, index);
                        }}
                        distance={1}
                        axis='xy'
                      />
                      <FieldWrapper>
                        <span
                          onClick={(e) => {
                            onOpenAddMenu(e);
                            setFieldGroupIndex(index);
                          }}>
                          + Add Field
                        </span>
                      </FieldWrapper>
                    </ModifyUserBlock>
                  );
                })}
              <GroupWrapper>
                <Span
                  onClick={() => {
                    onAddFieldGroup();
                  }}>
                  + Add Group
                </Span>
              </GroupWrapper>
            </SecondContain>
          )}
        </Form>
        <Menu
          id='simple-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={onCloseMenu}
          getContentAnchorEl={null}
          positionVertical='top'
          position='left'
          width={112}
          marginTop={25}>
          {selectedItem && (
            <MenuItem
              iconName='icon-edit-pen'
              color='var(--gray-icon-color)'
              fontSize='18px'
              text='Edit'
              onClick={() => {
                onOpenEditMenu(selectedItem, fieldGroupIndex);
              }}
            />
          )}
          {!selectedItem?.isSystem && (
            <MenuItem
              iconName='icon-delete'
              color='var(--gray-icon-color)'
              fontSize='18px'
              text='Delete'
              onClick={() => {
                selectedItem
                  ? onDeleteField(selectedItem?.id || selectedItem?.localId, fieldGroupIndex)
                  : onDeleteFieldGroup();
              }}
            />
          )}
        </Menu>
        <Modal open={isEditMenuOpen} onClose={onCloseEditMenu}>
          <EditFieldModal
            fieldItem={selectedItem}
            fieldGroupIndex={fieldGroupIndex}
            handleCloseModal={onCloseEditMenu}
          />
        </Modal>
        <CoreModal
          open={isAddMenuOpen}
          onClose={onCloseAddMenu}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          BackdropProps={{ invisible: true }}>
          <AddFieldModal
            openEditMenu={onOpenEditMenu}
            fieldGroupIndex={fieldGroupIndex}
            handleCloseModal={onCloseAddMenu}
          />
        </CoreModal>
        <SnackBar />
      </MainList>
    </PageWrapper>
  );
};

export default CustomizeFields;
