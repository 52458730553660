import styled from 'styled-components';
import { Text1 } from '../../styles';

const DropMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftView = styled.div`
  display: flex;
  align-items: center;
`;
const LabelText = styled.span`
  ${Text1}
`;
const SelectedValue = styled.span`
  ${Text1}
`;

export { DropMenu, LeftView, LabelText, SelectedValue };
