import styled, { css } from 'styled-components';
import { Header3, SubHeader3, Text1 } from '../../../styles';

const CollLogMain = styled.div`
  height: 100%;
  overflow: hidden;
`;
const CollLogHeader = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 0px;
        `
      : css`
          padding: 24px;
        `}
`;
const CollLogContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;
const LinkBlock = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;
const LinkListItem = styled.span`
  display: block;
  margin-right: 25px;
  ${Text1}
  color: var(--gray-color);
  cursor: pointer;
  :hover {
    color: var(--primary-color);
  }
  :last-child {
    margin-right: 0;
  }
  &.active {
    color: var(--primary-color);
  }
`;
const Recording = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const AudioPlayImg = styled.img``;
const TimeSpan = styled.span`
  display: inline;
  margin-left: 10px;
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
  }
`;
const TableStyle = styled.div`
  overflow: auto;
  z-index: 2;
  position: relative;
  height: 100%;
  ${(props) =>
    props.mobile
      ? css`
          max-height: calc(100% - 44px);
          padding: 16px 0 0 16px;
          background-color: var(--white-color);
          border-radius: 8px;
        `
      : css`
          max-height: calc(100% - 126px);
        `}
  table {
    td {
      ${(props) =>
        props.mobile
          ? css`
              height: 44px;
            `
          : css``}
    }
    thead {
      th {
        ${(props) =>
          props.mobile
            ? css`
                height: 41 px;
              `
            : css``}
        :first-child {
          min-width: 25px;
          width: 25px;
          display: table-cell;
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        th {
          width: 25px;
          min-width: 25px;
          display: table-cell;
          text-align: center;
        }
      }
    }
  }
`;
const Duration = styled.span`
  color: var(--gray-color);
  display: block;
  margin-left: 10px;
`;
const MsgSpan = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 400px;
  white-space: break-spaces;
`;
const NewTitle = styled.span`
  color: var(--primary-color);
  ${Text1};
  cursor: pointer;
`;
const EmptyImg = styled.img``;
const EmptyWrapper = styled.div`
  width: fit-content;
  margin: 74px auto 0 auto;
  text-align: center;
`;
const DetailsTitle = styled.span`
  ${Header3}
  color:var(--dark-color);
  margin-top: 20px;
  margin-bottom: 6px;
  display: block;
`;
const Details = styled.span`
  ${SubHeader3}
  color:var(--gray-color);
  margin-bottom: 16px;
  display: block;
`;
export {
  CollLogMain,
  CollLogHeader,
  CollLogContainer,
  LinkBlock,
  LinkListItem,
  TableStyle,
  Recording,
  AudioPlayImg,
  TimeSpan,
  Duration,
  MsgSpan,
  NewTitle,
  EmptyImg,
  EmptyWrapper,
  DetailsTitle,
  Details,
  IconBox
};
