import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/atom/Button/Button';
import Input from '../../components/Input/Input';
import PlusIcon from '../../assets/images/plus.svg';
import RemoveIcon from '../../assets/images/remove.svg';
import DropDown from '../../components/DropDown/DropDown';
import {
  addAutomationStepItem,
  clearAutomationStepItem,
  deleteAutomationStepItem,
  updateAutomationItem,
  updateAutomationStepItem
} from '../../actions/automationActions';
import { getUniqueId, isEmpty, setFieldConditionOptions } from '../../helpers/common';
import { getLookupFields } from '../../services/lookupService';
import { setLookupFields } from '../../actions/lookupActions';
import { LookupFieldTypes, SEGMENT_FIELD_TYPE, TRIGGER_ACTION } from '../../constants/constant';
import SegmentInputField from '../Contact/AddNewFilter/SegmentInputField';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import Validation from '../../components/Validation/Validation';
import {
  createAutomation,
  getAutomationItem,
  updateAutomation,
  updateAutomationStatus
} from '../../services/automationService';
import SVGIcon from '../../assets/svg/SVGIcon';
import { getEmailTemplates } from '../../services/emailService';
import { Border, MenuItemWrapper } from '../Email/SendEmailModal/styles';
import { Menu, MenuItem } from '../../components/Menu';
import {
  AutomationEmptyWrapper,
  AutomationEmptyLeft,
  AutomationEmptyRight,
  LeftButton,
  InputWrapper,
  IconWrapper,
  AutomationWrapper,
  AutomationContentView,
  AutomationContainer,
  AutomationOptionWp,
  AutomationListWrapper,
  AutomationListItem,
  AutomationBlock,
  H5,
  P,
  AutomationAddBtn,
  ButtonPlus,
  Plus,
  RemoveButton,
  RemoveIconWrapper,
  AutomationFormView,
  ListWrappper,
  DropDownTitle,
  LastListWrap,
  DropDownMain,
  FileBox
} from './styles';

const Automation_Trigger = [
  { value: 1, label: 'Create' },
  { value: 2, label: 'Update' },
  { value: 3, label: 'Create & Update' }
];

const Automation_Step_Action = [
  { value: 1, label: 'Condition' },
  { value: 2, label: 'Send Mail' }
];

const STEPS_ACTION = {
  1: 'Condition',
  2: 'Send Mail'
};

const modules = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
    [{ list: 'ordered' }],
    [{ list: 'bullet' }],
    ['clean']
  ]
};

const NewAutomation = () => {
  let quillRef = useRef(null);
  const history = useHistory();
  const { id: itemId } = useParams();

  const automationSelector = useSelector((state) => state.automation);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const lookupSelector = useSelector((state) => state.lookup);
  const emailSelector = useSelector((state) => state.email);
  const organizationSelector = useSelector((state) => state.organization);
  const { automationItem, loading } = automationSelector;
  const { list: lookupTableList } = lookupTableSelector;
  const { organization } = organizationSelector;
  const { id: organization_id, users } = organization;
  const { fields: lookupFields } = lookupSelector;
  const { emailAccounts, emailTemplates } = emailSelector;
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState('Trigger');
  const [selectedTable, setSelectedTable] = useState();
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [error, setError] = useState({});
  const loadData = useCallback(async () => {
    if (itemId) {
      const result = await dispatch(getAutomationItem(organization_id, itemId));
      await dispatch(getEmailTemplates(organization_id));
      if (result) {
        const table = lookupTableList?.find((x) => x.id === result?.lookupTableId);
        if (table) {
          setSelectedTable(table);
        }
      }
    }
  }, [dispatch, itemId, lookupTableList, organization_id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearAutomationStepItem());
    };
  }, [dispatch, loadData, organization_id]);
  const fields = useMemo(() => {
    if (lookupFields) {
      const fieldList = lookupFields
        .filter((x) => x.type !== LookupFieldTypes.Lookup)
        .map((item) => {
          return { ...item, fieldOption: item?.options, options: undefined };
        });
      return fieldList;
    }
  }, [lookupFields]);

  const onChangeInput = useCallback(
    (propsName, value) => {
      dispatch(updateAutomationItem({ propsName, value }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (emailAccounts) {
      const isEmail = emailAccounts?.length > 0;
      if (!isEmail) {
        Automation_Step_Action[1]['isDisabled'] = true;
      }
    }
  }, [emailAccounts]);

  const userList = useMemo(() => {
    if (selectedTable && users) {
      const table = {
        ...selectedTable,
        id: 'Lookup'
      };
      const user = [table, ...users];
      return user;
    }
  }, [selectedTable, users]);

  const onChangeTable = useCallback(
    async (table) => {
      dispatch(setLookupFields(null));
      onChangeInput('lookupTableId', table?.id);
      setSelectedTable(table);
      await dispatch(getLookupFields(organization_id, table?.id));
    },
    [dispatch, onChangeInput, organization_id]
  );

  useEffect(() => {
    if (!itemId) {
      if (lookupTableList && lookupTableList?.length !== 0) {
        onChangeTable(lookupTableList[0]);
      }
    }
  }, [itemId, lookupTableList, onChangeTable]);

  const onClickAddStep = useCallback(
    (index) => {
      const localId = getUniqueId();
      const item = {
        action: 1,
        localId,
        actionConditionValue: { condition: undefined, fieldId: undefined, localConditionOptions: [], localField: {} }
      };
      setSelectedItem(item);
      if (!isEmpty(index)) {
        dispatch(addAutomationStepItem({ item: item, index: index }));
      } else {
        dispatch(addAutomationStepItem({ item: item }));
      }
    },
    [dispatch]
  );

  const onClickRemoveItem = useCallback(
    (index) => {
      setSelectedItem(undefined);
      dispatch(deleteAutomationStepItem({ index }));
    },
    [dispatch]
  );

  const onClickItem = useCallback((item) => {
    setSelectedItem(item);
  }, []);

  const onChangeStepAction = useCallback(
    (action) => {
      let item = {
        ...selectedItem,
        action
      };
      if (action === 1) {
        item = {
          ...item,
          actionConditionValue: { condition: undefined, fieldId: undefined, localConditionOptions: [], localField: {} },
          actionEmailValue: undefined
        };
      } else if (action === 2) {
        item = {
          ...item,
          actionEmailValue: { to: '', subject: '', body: '' },
          actionConditionValue: undefined
        };
      }
      setSelectedItem(item);
      dispatch(updateAutomationStepItem({ id: selectedItem?.localId || selectedItem?.id, item: item }));
    },
    [dispatch, selectedItem]
  );
  const onChangeStepField = useCallback(
    (field) => {
      const item = {
        ...selectedItem?.actionConditionValue,
        localField: field,
        fieldId: field?.id,
        condition: {},
        value: undefined,
        value_Bool: undefined,
        value_Date: undefined,
        value_Lookup: undefined,
        value_Number: undefined
      };
      setFieldConditionOptions(item, 'localField');
      const newItem = {
        ...selectedItem,
        actionConditionValue: item
      };
      setSelectedItem(newItem);
      dispatch(updateAutomationStepItem({ id: selectedItem?.localId, item: newItem }));
    },
    [dispatch, selectedItem]
  );

  const onChangeStepFieldInput = useCallback(
    (key, value) => {
      const item = {
        ...selectedItem,
        actionConditionValue: {
          ...selectedItem.actionConditionValue,
          [key]: value
        }
      };
      setSelectedItem(item);
      dispatch(updateAutomationStepItem({ id: selectedItem?.localId || selectedItem?.id, item: item }));
    },
    [dispatch, selectedItem]
  );
  const onChangeStepCondition = useCallback(
    (value) => {
      const item = {
        ...selectedItem,
        actionConditionValue: {
          ...selectedItem.actionConditionValue,
          condition: value,
          value: undefined,
          value_Bool: undefined,
          value_Date: undefined,
          value_Lookup: undefined,
          value_Number: undefined
        }
      };
      setSelectedItem(item);
      dispatch(updateAutomationStepItem({ id: selectedItem?.localId || selectedItem?.id, item: item }));
    },
    [dispatch, selectedItem]
  );

  const onChangeStepMailInput = useCallback(
    (propsName, value) => {
      setSelectedItem((selectedItem) => {
        const updatedItem = {
          ...selectedItem,
          actionEmailValue: {
            ...selectedItem.actionEmailValue,
            [propsName]: value
          }
        };
        dispatch(updateAutomationStepItem({ id: selectedItem?.localId || selectedItem?.id, item: updatedItem }));
        return updatedItem;
      });
    },
    [dispatch]
  );

  const getInputValue = (item) => {
    let value = '';
    if (item?.action === 1) {
      if (item?.actionConditionValue?.condition?.valueType === SEGMENT_FIELD_TYPE.TEXT) {
        value = item?.actionConditionValue?.value;
      } else if (item?.actionConditionValue?.condition?.valueType === SEGMENT_FIELD_TYPE.NUMBER) {
        value = item?.actionConditionValue?.value_Number;
      } else if (item?.actionConditionValue?.condition?.valueType === SEGMENT_FIELD_TYPE.DATE_AND_TIME) {
        value = moment(item?.actionConditionValue?.value_Date).format('DD MMM YYYY');
      } else if (item?.actionConditionValue?.condition?.valueType === SEGMENT_FIELD_TYPE.BOOLEAN) {
        value = item?.actionConditionValue?.value_Bool ? String(item?.actionConditionValue?.value_Bool) : '';
      } else if (item?.actionConditionValue?.condition?.valueType === SEGMENT_FIELD_TYPE.OPTION) {
        const field = item?.actionConditionValue?.localField?.fieldOption?.find(
          (x) => x.id === item?.actionConditionValue?.value
        );
        if (field) {
          value = field?.value;
        }
      }
    } else {
      if (item?.actionEmailValue.to === 'Lookup') {
        value = selectedTable?.label;
      } else {
        const user = users?.find((x) => x.id === item?.actionEmailValue.to);
        if (user) {
          value = user?.name;
        }
      }
    }

    return value;
  };
  const onClickSave = useCallback(
    async (e) => {
      e.preventDefault();

      let resError = {};
      resError.steps = [];
      if (!automationItem?.name || automationItem.name.trim() === '') {
        resError.name = 'Name is Required ';
      }
      automationItem?.steps?.forEach((item) => {
        const error = {};
        if (item?.action === 1) {
          if (isEmpty(item?.actionConditionValue?.localField)) {
            error.localField = 'Is Required';
          }
          if (isEmpty(item?.actionConditionValue?.condition)) {
            error.condition = 'Is Required';
          }
          if (
            item?.actionConditionValue?.condition?.valueType === 1 ||
            item?.actionConditionValue?.condition?.valueType === 4
          ) {
            if (!item?.actionConditionValue?.value || item?.actionConditionValue?.value.trim() === '') {
              error.value = 'Is Required';
            }
          } else if (item?.actionConditionValue?.condition?.valueType === 2) {
            if (!item?.actionConditionValue?.value_Number) {
              error.value = 'Is Required';
            }
          } else if (item?.actionConditionValue?.condition?.valueType === 3) {
            if (!item?.actionConditionValue?.value_Date || item?.actionConditionValue?.value_Date.trim() === '') {
              error.value = 'Is Required';
            }
          } else if (item?.actionConditionValue?.condition?.valueType === 5) {
            if (!item?.actionConditionValue?.value_Bool) {
              error.value = 'Is Required';
            }
          }
        } else if (item?.action === 2) {
          if (!item?.actionEmailValue?.to || item?.actionEmailValue?.to.trim() === '') {
            error['to'] = 'Is Required';
          }
          if (!item?.actionEmailValue?.subject || item?.actionEmailValue?.subject.trim() === '') {
            error['subject'] = 'Is Required';
          }
          if (!item?.actionEmailValue?.body || item?.actionEmailValue?.body.trim() === '') {
            error['body'] = 'Is Required';
          }
        }

        if (!isEmpty(error)) resError.steps[item?.localId || item?.id] = error;
      });
      if (!resError.name && isEmpty(resError.steps)) {
        setError({});
      } else {
        setError(resError);
        return;
      }
      let result;
      if (itemId) {
        result = await dispatch(updateAutomation(organization_id, itemId, automationItem));
      } else {
        result = await dispatch(createAutomation(organization_id, automationItem));
      }
      if (result) {
        dispatch(clearAutomationStepItem());
        history.goBack();
      }
    },
    [automationItem, dispatch, history, itemId, organization_id]
  );

  const onClickStatus = useCallback(
    async (automation_id, status) => {
      const result = await dispatch(updateAutomationStatus(organization_id, automation_id, status));
      if (result) {
        const status = result?.data?.status;
        const statusText = result?.data?.statusText;
        dispatch(updateAutomationItem({ propsName: 'status', value: status }));
        dispatch(updateAutomationItem({ propsName: 'statusText', value: statusText }));
      }
    },
    [dispatch, organization_id]
  );

  const onChangeLookupField = useCallback((field) => {
    let insrtBody = field?.customId || field?.body;
    const newQuill = quillRef?.current.getEditor();
    const index = newQuill?.selection?.savedRange?.index || 0;
    newQuill?.insertText(index, `{{${insrtBody}}}`);
  }, []);

  const openTemplateMenu = useCallback((e) => {
    e.stopPropagation();
    setIsTemplateOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeTemplateMenu = useCallback(() => {
    setIsTemplateOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onClickEmailTemplate = useCallback(
    (emailTemplet) => {
      const item = {
        ...selectedItem,
        actionEmailValue: {
          ...selectedItem.actionEmailValue,
          body: emailTemplet?.body,
          subject: emailTemplet?.subject
        }
      };
      setSelectedItem(item);
      dispatch(updateAutomationStepItem({ id: selectedItem?.localId || selectedItem?.id, item: item }));
      closeTemplateMenu();
      return false;
    },
    [closeTemplateMenu, dispatch, selectedItem]
  );

  return (
    <>
      <AutomationEmptyWrapper>
        <AutomationEmptyLeft>
          <IconWrapper title='Back' onClick={history.goBack}>
            <SVGIcon name='icon-left-arrow' fill='var(--gray-icon-color)' size={24} />
          </IconWrapper>
          <InputWrapper>
            <Input
              disabled={loading}
              isPrimarySmall={true}
              autoFocus={true}
              placeholder='New Automation'
              value={automationItem?.name}
              onChange={(e) => {
                onChangeInput('name', e.target.value);
              }}
            />
            {!isEmpty(error) && <Validation error={error?.name} />}
          </InputWrapper>
        </AutomationEmptyLeft>
        <AutomationEmptyRight>
          {itemId && (
            <LeftButton
              onClick={() => {
                onClickStatus(automationItem?.id, automationItem?.status === 1 ? 2 : 1);
              }}>
              <Button
                secondary
                fontSize='18'
                iconName={automationItem?.status === 1 ? 'icon-audio-play' : 'icon-audio-pause'}
                iconColor={automationItem?.status === 1 ? 'var(--kelly-green)' : 'var(--jonquil)'}
                title={automationItem?.statusText}
              />
            </LeftButton>
          )}
          <Button isPrimary={true} title='Save' onClick={onClickSave} loading={loading} />
        </AutomationEmptyRight>
      </AutomationEmptyWrapper>
      <>
        {loading ? (
          <div>Loading.....</div>
        ) : (
          <AutomationWrapper>
            <AutomationContentView>
              <AutomationContainer>
                <AutomationListWrapper>
                  <AutomationListItem>
                    <AutomationBlock
                      active={selectedItem === 'Trigger'}
                      onClick={() => {
                        onClickItem('Trigger');
                      }}>
                      <H5>Trigger</H5>
                      <P>
                        {selectedTable?.label} {TRIGGER_ACTION[automationItem?.triggerAction]}
                      </P>
                    </AutomationBlock>
                  </AutomationListItem>
                  {automationItem?.steps &&
                    automationItem?.steps?.map((item, index) => {
                      let value = getInputValue(item);
                      return (
                        <AutomationListItem key={item?.localId || item?.id}>
                          <ButtonPlus
                            title='Add Step'
                            onClick={() => {
                              onClickAddStep(index);
                            }}>
                            <Plus src={PlusIcon} />
                          </ButtonPlus>
                          <AutomationBlock
                            active={
                              item?.localId ? item?.localId === selectedItem?.localId : item?.id === selectedItem?.id
                            }
                            onClick={() => {
                              onClickItem(item);
                            }}>
                            <RemoveButton
                              title='Remove Step'
                              onClick={() => {
                                onClickRemoveItem(index);
                              }}>
                              <RemoveIconWrapper src={RemoveIcon} />
                            </RemoveButton>
                            <H5>{STEPS_ACTION[item?.action]}</H5>
                            {item?.action === 1 ? (
                              <P className='automation-block-p'>
                                {item?.actionConditionValue?.localField?.label}
                                <span>{item?.actionConditionValue?.condition?.text}</span>
                                {value}
                              </P>
                            ) : (
                              <P className='automation-block-p'>
                                <span>To</span> {value}
                              </P>
                            )}
                          </AutomationBlock>
                        </AutomationListItem>
                      );
                    })}

                  <AutomationAddBtn
                    onClick={() => {
                      onClickAddStep();
                    }}>
                    Add Step
                  </AutomationAddBtn>
                </AutomationListWrapper>
              </AutomationContainer>
            </AutomationContentView>
            <AutomationOptionWp>
              {selectedItem === 'Trigger' ? (
                <AutomationFormView>
                  <ListWrappper>
                    <DropDownTitle>Collection</DropDownTitle>
                    <DropDown
                      labelField={'label'}
                      valueField={'id'}
                      placeholder={'Select Table'}
                      options={lookupTableList}
                      value={automationItem?.lookupTableId}
                      onChange={(table) => {
                        if (automationItem?.steps?.length > 0) {
                          ConfirmDialog({
                            title: 'Are you sure want to change collection?',
                            message: `This action will remove all steps`,
                            onOk: () => {
                              onChangeInput('steps', []);
                              onChangeTable(table);
                            }
                          });
                        } else {
                          onChangeTable(table);
                        }
                      }}
                    />
                  </ListWrappper>
                  <ListWrappper>
                    <DropDownTitle>On</DropDownTitle>
                    <DropDown
                      placeholder={'Select Field'}
                      options={Automation_Trigger}
                      value={automationItem?.triggerAction}
                      onChange={(item) => {
                        onChangeInput('triggerAction', item?.value);
                      }}
                    />
                  </ListWrappper>
                </AutomationFormView>
              ) : (
                selectedItem && (
                  <AutomationFormView>
                    <ListWrappper>
                      <DropDownTitle>Action</DropDownTitle>
                      <DropDown
                        placeholder={'Select Field'}
                        options={Automation_Step_Action}
                        value={selectedItem?.action}
                        onChange={(step_action) => {
                          onChangeStepAction(step_action.value);
                        }}
                      />
                    </ListWrappper>
                    {selectedItem?.action === 1 ? (
                      <>
                        <ListWrappper>
                          <DropDownTitle>Field</DropDownTitle>
                          <DropDown
                            labelField={'label'}
                            valueField={'id'}
                            placeholder={'Select Field'}
                            options={fields}
                            hasObject={true}
                            value={selectedItem?.actionConditionValue?.localField}
                            onChange={(field) => {
                              onChangeStepField(field);
                            }}
                          />
                          {!isEmpty(error) && (
                            <Validation error={error?.steps[selectedItem?.localId || selectedItem?.id]?.localField} />
                          )}
                        </ListWrappper>
                        <ListWrappper>
                          <DropDown
                            labelField={'text'}
                            valueField={'value'}
                            hasObject={true}
                            value={selectedItem?.actionConditionValue?.condition}
                            placeholder={'Select Condition'}
                            options={selectedItem?.actionConditionValue?.localConditionOptions}
                            onChange={(condition) => {
                              onChangeStepCondition(condition);
                            }}
                          />
                          {!isEmpty(error) && (
                            <Validation error={error?.steps[selectedItem?.localId || selectedItem?.id]?.condition} />
                          )}
                        </ListWrappper>
                        {!isEmpty(selectedItem?.actionConditionValue?.condition) && (
                          <ListWrappper>
                            <SegmentInputField
                              type={selectedItem?.actionConditionValue?.condition?.valueType}
                              item={selectedItem?.actionConditionValue}
                              fieldName={'localField'}
                              onChangeValues={(key, value) => {
                                onChangeStepFieldInput(key, value);
                              }}
                            />
                            {!isEmpty(error) && (
                              <Validation error={error?.steps[selectedItem?.localId || selectedItem?.id]?.value} />
                            )}
                          </ListWrappper>
                        )}
                      </>
                    ) : (
                      <>
                        <ListWrappper>
                          <DropDownTitle>To</DropDownTitle>
                          <DropDown
                            labelField={'name'}
                            valueField={'id'}
                            placeholder={'To'}
                            value={selectedItem?.actionEmailValue?.to}
                            options={userList}
                            onChange={(to) => {
                              onChangeStepMailInput('to', to?.id);
                            }}
                          />
                          {!isEmpty(error) && (
                            <Validation error={error?.steps[selectedItem?.localId || selectedItem?.id]?.to} />
                          )}
                        </ListWrappper>
                        <ListWrappper>
                          <DropDownTitle>Subject</DropDownTitle>
                          <Input
                            isPrimarySmall={true}
                            placeholder='subject'
                            value={selectedItem?.actionEmailValue?.subject}
                            onChange={(e) => {
                              onChangeStepMailInput('subject', e.target.value);
                            }}
                          />
                          {!isEmpty(error) && (
                            <Validation error={error?.steps[selectedItem?.localId || selectedItem?.id]?.subject} />
                          )}
                        </ListWrappper>
                        <DropDownTitle>Body</DropDownTitle>
                        <LastListWrap>
                          <DropDownMain>
                            <DropDownTitle smallLable={true}>Field</DropDownTitle>
                            <DropDown
                              labelField={'label'}
                              valueField={'customId'}
                              placeholder={'Select Field'}
                              options={fields}
                              value={selectedItem?.actionEmailValue?.emailField}
                              onChange={(field) => {
                                onChangeLookupField(field);
                              }}
                            />
                          </DropDownMain>
                          <ReactQuill
                            ref={quillRef}
                            theme='snow'
                            placeholder={'Insert text here ...'}
                            style={{ border: 'none' }}
                            modules={modules}
                            value={selectedItem?.actionEmailValue?.body || ''}
                            onChange={(value) => {
                              onChangeStepMailInput('body', value);
                            }}
                          />
                          <FileBox
                            active={isTemplateOpen}
                            title='Template'
                            onClick={(e) => {
                              openTemplateMenu(e);
                            }}>
                            <SVGIcon name='icon-document' size={22} fill='var(--gray-icon-color)' />
                          </FileBox>
                          <Menu
                            id='simple-menu'
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={isTemplateOpen}
                            onClose={closeTemplateMenu}
                            getContentAnchorEl={null}
                            position='left'
                            positionVertical='top'
                            // marginTop={-60}
                            style={{ marginLeft: 25 }}
                            MenuListProps={{ disablePadding: true }}
                            width='154px'>
                            <MenuItemWrapper>
                              {emailTemplates?.map((item) => {
                                return (
                                  <MenuItem
                                    text={item?.name}
                                    onClick={() => {
                                      onClickEmailTemplate(item);
                                    }}
                                  />
                                );
                              })}
                              <Border></Border>
                              <MenuItem
                                text='Manage Template'
                                onClick={() => {
                                  history.push(`/settings/emailtemplate`);
                                }}
                              />
                            </MenuItemWrapper>
                          </Menu>
                          {!isEmpty(error) && (
                            <Validation error={error?.steps[selectedItem?.localId || selectedItem?.id]?.body} />
                          )}
                        </LastListWrap>
                      </>
                    )}
                  </AutomationFormView>
                )
              )}
            </AutomationOptionWp>
          </AutomationWrapper>
        )}
      </>
    </>
  );
};

export default NewAutomation;
