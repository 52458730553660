import React, { useCallback, useEffect } from 'react';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/atom/Button/Button';
import {
  deleteSegmentFilter,
  getManageSegmentList,
  getTableTypeForAnalytics,
  segmentIndexChange,
  trackSegmentReorderedCollectionAnalyticActivity
} from '../../../services/lookupService';
import { setManageSegmentList } from '../../../actions/lookupActions';
import { isEmpty } from '../../../helpers/common';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import SVGIcon from '../../../assets/svg/SVGIcon';
import {
  MainContact,
  TitleView,
  TitleText,
  IconView,
  From,
  SecondContain,
  DropDiv,
  DragIcon,
  BottomView,
  BtnView,
  TextList,
  FilterText,
  RightIcon,
  TitleLeft
} from './styles';

const ManageFilterModal = (props) => {
  const table_id = props?.table_id;
  const dispatch = useDispatch();
  const lookupSelector = useSelector((state) => state.lookup);
  const organizationSelector = useSelector((state) => state.organization);
  const { manageSegments, loading } = lookupSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const onClose = useCallback(() => {
    if (props.handleCloseModal) props.handleCloseModal();
  }, [props]);

  const loadData = useCallback(async () => {
    if (table_id) {
      await dispatch(getManageSegmentList(organization_id, table_id, true));
    }
  }, [table_id, dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData, table_id]);

  const handleOnClickEdit = useCallback(
    (value) => {
      if (props.openNewFilterModal) props.openNewFilterModal(value?.id);
    },
    [props]
  );

  const onDeleteItem = useCallback(
    async (segment_id) => {
      await dispatch(deleteSegmentFilter(organization_id, table_id, segment_id));
    },
    [table_id, organization_id, dispatch]
  );

  const DragHandle = sortableHandle(() => (
    <Icon name='feather-menu' fontSize='16' color='var(--gray-icon-color)' title='Drag' />
  ));
  const SortableItem = SortableElement(({ value }) => {
    return (
      <DropDiv>
        <DragIcon>
          <DragHandle />
        </DragIcon>
        <TextList>
          <FilterText>{value?.name}</FilterText>
        </TextList>
        {!value.isSystem && (
          <RightIcon>
            <Icon
              title='Edit'
              isIconButton
              name='feather-edit-3'
              fontSize='16'
              color='var(--gray-icon-color)'
              onClick={() => {
                handleOnClickEdit(value);
              }}
            />
            <Icon
              title='Delete'
              name='feather-trash-2'
              fontSize='16'
              color='var(--gray-icon-color)'
              onClick={() => {
                ConfirmDialog({
                  title: 'Delete?',
                  message: `Are you sure want to delete this segment?`,

                  onOk: () => {
                    onDeleteItem(value?.id);
                  }
                });
              }}
            />
          </RightIcon>
        )}
      </DropDiv>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem key={`field_${index}`} index={index} value={item} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      if (props.onChange) props.onChange(true);
      const newTableColumns = arrayMoveImmutable(manageSegments, oldIndex, newIndex);
      dispatch(setManageSegmentList(newTableColumns));
      const segment_id = newTableColumns[newIndex]?.id;
      const payload = {
        id: segment_id,
        index: newIndex
      };
      const result = dispatch(segmentIndexChange(organization_id, table_id, segment_id, payload));
      const type = getTableTypeForAnalytics(props?.table);
      if (result) trackSegmentReorderedCollectionAnalyticActivity(type, newTableColumns[newIndex]?.count);
    }
  };

  return (
    <MainContact>
      <TitleView>
        <TitleLeft>
          <IconView title='Close'>
            <SVGIcon name='icon-filter-sort' size={22} fill='var(--gray-icon-color)' />
          </IconView>
          <TitleText>Manage Filter</TitleText>
        </TitleLeft>
        <IconView title='Close' onClick={onClose}>
          <SVGIcon name='icon-close' size={20} fill='var(--gray-icon-color)' />
        </IconView>
      </TitleView>
      <From>
        {loading && isEmpty(manageSegments) ? (
          <div>Loading...</div>
        ) : (
          <SecondContain>
            <SortableList items={manageSegments} onSortEnd={onSortEnd} axis='xy' useDragHandle />
          </SecondContain>
        )}
        <BottomView>
          <BtnView>
            <Button onClick={onClose} secondary title='Close' type='button' />
          </BtnView>
        </BottomView>
      </From>
    </MainContact>
  );
};

export default ManageFilterModal;
