import styled from 'styled-components';
import { Header4, Text2 } from '../../../../styles';

const ModalFormWrapper = styled.form``;

const ModalHead = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  i {
    cursor: pointer;
  }
`;

const ModalHeadLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ModalText = styled.span`
  ${Header4}
  margin-left: 12px;
  color: var(--dark-color);
`;
const ModalWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: flex-start;
`;

const ModalDownBlock = styled.div`
  margin-left: 20px;
  flex: 1 0 0;
  .statusText {
    margin-top: 8px;
    text-align: center;
  }
`;

const ModalIcon = styled.div`
  margin-top: 5px;
`;

const Warningview = styled.div`
  padding: 16px;
  display: flex;
  margin-top: 16px;
  border-radius: 4px;
  background: var(--linear-gradient-gray);
`;
const ErrorMsg = styled.span`
  display: block;
  ${Text2}
  color: var(--gray-color);
  margin-left: 10px;
`;
const IconView = styled.div`
  display: inline-block;
`;

const ModalButton = styled.div`
  padding: 16px;
  border-top: 1px solid var(--gray-border-color);
  button:first-child {
    margin-right: 12px;
  }
`;
const SvgWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export {
  ModalFormWrapper,
  ModalHead,
  ModalHeadLeft,
  ModalText,
  ModalWrapper,
  ModalDownBlock,
  ModalIcon,
  ErrorMsg,
  ModalButton,
  SvgWrapper,
  Warningview,
  IconView
};
