import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';
import DropDown from '../../../components/DropDown/DropDown';
import {
  createLookupTableItem,
  updateLookupTableItem,
  getLookupTableItem
} from '../../../services/lookupTableServices';
import { setLookupTableItem, updateLookupTableItemField } from '../../../actions/lookupTableActions';
import { LOOKUP_TYPE } from '../../../constants/constant';
import { UserFriendlyString } from '../../../helpers/common';
import Message from '../../../components/Message/Message';
import { trackCustomizeCollectionAnalyticActivity } from '../../../services/settingService';
import SVGIcon from '../../../assets/svg/SVGIcon';
import {
  MainContact,
  TitleView,
  TitleText,
  BottomView,
  Form,
  LeftWrapper,
  ModalWrapper,
  ListWrappper,
  DropDownTitle,
  marginBottom,
  HeaderWrapper,
  IconView
} from './styles';

const tableTypes = [
  { label: 'Contacts', value: LOOKUP_TYPE.contacts },
  { label: 'Companies', value: LOOKUP_TYPE.companies },
  { label: 'Deals', value: LOOKUP_TYPE.deals },
  { label: 'Others', value: LOOKUP_TYPE.others }
];

const ModifyCustomizeModal = (props) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { tableItem, loading } = lookupTableSelector;

  const [validationErrors, setValidationErrors] = useState({});

  const id = props?.tableId;
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (!id) {
      dispatch(updateLookupTableItemField({ propName: 'type', value: tableTypes[0].value }));
      return;
    }
    dispatch(getLookupTableItem(organization_id, id));
  }, [dispatch, organization_id, id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setLookupTableItem());
    };
  }, [dispatch, loadData]);

  const onClose = useCallback(() => {
    props.handleCloseModal();
    dispatch(setLookupTableItem(null));
  }, [dispatch, props]);

  const onUpdateTableItem = useCallback(
    (fieldName, FieldVal) => {
      dispatch(updateLookupTableItemField({ propName: fieldName, value: FieldVal }));
    },
    [dispatch]
  );

  useEffect(() => {
    !id && onUpdateTableItem('name', UserFriendlyString(tableItem?.label));
  }, [onUpdateTableItem, tableItem?.label, id]);

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!tableItem?.label) {
      validationErrors.label = 'Required';
      valid = false;
    }
    if (!tableItem?.name) {
      validationErrors.name = 'Required';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [tableItem]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;
      const params = tableItem;
      const result = params?.id
        ? await dispatch(updateLookupTableItem(organization_id, params?.id, params))
        : await dispatch(createLookupTableItem(organization_id, params));

      if (result) {
        if (params?.id) {
          trackCustomizeCollectionAnalyticActivity('updated', params?.name, params?.type);
        } else {
          trackCustomizeCollectionAnalyticActivity('added', params?.name, params?.type);
        }
        onClose();
      }
    },
    [dispatch, onClose, validateInputs, tableItem, organization_id]
  );

  return (
    <MainContact>
      <TitleView>
        <HeaderWrapper>
          <SVGIcon name='icon-column-edit' fill='var(--gray-icon-color)' size={22} />
          <TitleText>{id ? 'Edit' : 'Add'} Table</TitleText>
        </HeaderWrapper>
        <IconView onClick={onClose}>
          <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
        </IconView>
      </TitleView>
      <Form onSubmit={onSubmit}>
        <ModalWrapper>
          <Input
            title='Table Lable'
            marginBottom={marginBottom}
            isPrimarySmall
            autocomplete='off'
            placeholder='Input Value'
            value={tableItem?.label || ''}
            onChange={(e) => {
              onUpdateTableItem('label', e.target.value);
            }}
          />
          {validationErrors?.label && <Message text={validationErrors.label} isSuccess={false} />}
          <Input
            title='Table Name'
            className='margin-bottom'
            isPrimarySmall
            autocomplete='off'
            marginBottom={marginBottom}
            placeholder='Input Value'
            disabled={id}
            value={tableItem?.name || ''}
            onChange={(e) => {
              onUpdateTableItem('name', e.target.value);
            }}
          />
          {validationErrors?.name && <Message text={validationErrors.name} isSuccess={false} />}
          <ListWrappper>
            <DropDownTitle>Type</DropDownTitle>
            <DropDown
              menuPlacement={'top'}
              options={tableTypes}
              labelField={'label'}
              valueField={'value'}
              value={tableItem?.type}
              onChange={(item) => {
                onUpdateTableItem('type', item.value);
              }}
              placeholder={'Select'}
              isDisabled={id}
            />
          </ListWrappper>
        </ModalWrapper>
        <BottomView>
          <LeftWrapper>
            <Button loading={loading} primary title='Save' type='submit' />
          </LeftWrapper>
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default ModifyCustomizeModal;
