import StaticHome from '../staticPages/StaticHome';
import { ROUTE } from '../constants/routeConst';
import SwitchOrganization from '../Pages/SwitchOrganization/SwitchOrganization';
import Home from '../Pages/Home';
import Login from '../Pages/Membership/Login/Login';
import Signup from '../Pages/Membership/Signup/Signup';
import OnboardingWelcome from '../Pages/Onborading/OnboardingWelcome';
import Onborading from '../Pages/Onborading/Onborading';
import ForgotPassword from '../Pages/Membership/ForgotPassword/ForgotPassword';
import ResetPassword from '../Pages/Membership/ResetPassword/ResetPassword';
import Upgrade from '../Pages/Setting/Subscription/Upgrade/Upgrade';
import Welcome from '../Pages/Welcome/Welcome';
import FilePreviewModal from '../Pages/Contact/ContactDetail/FilePreviewModal/FilePreviewModal';
import ContactDetail from '../Pages/Contact/ContactDetail/ContactDetail';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: ROUTE.LOGIN,
    exact: true,
    component: Login,
    private: false
  },
  {
    path: ROUTE.SIGN_UP,
    exact: true,
    component: Signup,
    private: false
  },
  {
    path: '/membership/invitation/:invitationId',
    exact: true,
    component: Signup,
    private: false
  },
  {
    path: ROUTE.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword,
    private: false
  },
  {
    path: '/welcome',
    exact: true,
    component: Welcome,
    private: false
  },
  {
    path: ROUTE.RESET_PASSWORD,
    exact: true,
    component: ResetPassword,
    private: false
  },
  {
    path: '/onboarding',
    exact: true,
    component: Onborading,
    private: true
  },
  {
    path: '/onboardingwelcome',
    exact: true,
    component: OnboardingWelcome,
    private: true
  },
  {
    path: ROUTE.SELECT_ORGANIZATION,
    exact: true,
    component: SwitchOrganization,
    private: false
  },
  {
    path: '/upgrade',
    exact: true,
    component: Upgrade,
    private: false
  },
  {
    path: '/file-preview',
    exact: true,
    component: FilePreviewModal,
    private: true
  },
  {
    path: ROUTE.STATIC_MAIN,
    component: StaticHome,
    private: true
  },
  {
    path: ROUTE.FRONTAPP_PAGE_URL,
    exact: true,
    component: ContactDetail,
    private: true
  },
  {
    path: ROUTE.MAIN,
    component: Home,
    private: true
  }
];

export default routes;
