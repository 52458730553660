import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import copy from 'copy-to-clipboard';
import { Menu, MenuItem } from '../../../components/Menu';
import SVGIcon from '../../../assets/svg/SVGIcon';
import Button from '../../../components/atom/Button/Button';
import Modal from '../../../components/Modal/index';
import {
  deleteAPIKey,
  getAPIKeys,
  regenerateAPIKey,
  trackAPIKeyActionAnalyticActivity
} from '../../../services/settingService';
import { ReactComponent as Empty } from '../../../assets/images/svg/web-form-empty.svg';
import { setSuccessMessage } from '../../../actions/messageActions';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import {
  ContainBox,
  HeadView,
  ListBox,
  MainList,
  MainWrap,
  Title,
  LeftList,
  ListTitle,
  KeyTitle,
  RightList,
  TextWrapper,
  SubText,
  CaptionText,
  IconView,
  MenuItemWrapper,
  MainMobileButton,
  MobileButton,
  ApiEmptyBlock
} from './styles';
import NewApiKey from './NewApiKey';

const ApiKey = (props) => {
  const [isOpenApiKeyModal, setIsOpenApiKeyModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const settingSelector = useSelector((state) => state.setting);
  const { apiKeyList } = settingSelector;

  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const loadData = useCallback(() => {
    dispatch(getAPIKeys(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const openApiKeyModal = useCallback(() => {
    setIsOpenApiKeyModal(true);
  }, []);
  const closeApiKeyModal = useCallback(() => {
    setIsOpenApiKeyModal(false);
  }, []);

  const onOpenMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
    setSelectedItem(item);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl();
  }, []);

  const onOpenEditMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setIsMenuOpen(false);
      setIsOpenApiKeyModal(true);
      setMenuAnchorEl();
      if (!isEmpty(selectedItem)) {
        trackAPIKeyActionAnalyticActivity('edit', selectedItem?.name);
      } else {
        trackAPIKeyActionAnalyticActivity('add');
      }
    },
    [selectedItem]
  );

  const onCopyToClipboard = useCallback(
    (value) => {
      copy(value);
      dispatch(setSuccessMessage('Copied to clipboard'));
    },
    [dispatch]
  );

  const onDeleteKey = useCallback(
    async (e) => {
      e.stopPropagation();
      setIsMenuOpen(false);
      const result = await dispatch(deleteAPIKey(organization_id, selectedItem?.id));
      if (result) {
        trackAPIKeyActionAnalyticActivity('delete', selectedItem?.name);
      }
    },
    [dispatch, organization_id, selectedItem?.id, selectedItem?.name]
  );

  const onRegenerateKey = useCallback(
    async (e) => {
      e.stopPropagation();
      setIsMenuOpen(false);
      const result = await dispatch(regenerateAPIKey(organization_id, selectedItem?.id));
      if (result) {
        trackAPIKeyActionAnalyticActivity('delete', selectedItem?.name);
      }
    },
    [dispatch, organization_id, selectedItem?.id, selectedItem?.name]
  );

  return (
    <>
      <MainList mobile={mobile}>
        {mobile && (
          <MobileTitleBar hasNavBar={true} hasTitle={true}>
            <MainMobileButton>
              <MobileButton
                iconName='icon-pluse'
                fontSize='14'
                iconColor='var(--white-color)'
                strokeWidth={2}
                size='small'
                onClick={() => {
                  setSelectedItem();
                  openApiKeyModal();
                }}
              />
            </MainMobileButton>
          </MobileTitleBar>
        )}
        <MainWrap mobile={mobile}>
          {mobile ? null : (
            <HeadView>
              <Title>API Keys</Title>
              <Button
                primary
                title='Create Key'
                onClick={() => {
                  setSelectedItem();
                  openApiKeyModal();
                }}
              />
            </HeadView>
          )}
          <ContainBox>
            {apiKeyList && apiKeyList?.length === 0 ? (
              <ApiEmptyBlock>
                <Empty />
                <TextWrapper>
                  <SubText>No API Key(s)</SubText>
                  <CaptionText>Want to create a API key? Click the top-right button.</CaptionText>
                </TextWrapper>
              </ApiEmptyBlock>
            ) : (
              apiKeyList?.map((item) => {
                return (
                  <ListBox key={item?.id}>
                    <LeftList
                      onClick={() => {
                        setSelectedItem(item);
                      }}>
                      <ListTitle>{item?.name}</ListTitle>
                      <KeyTitle>{item?.maskedKey}</KeyTitle>
                    </LeftList>
                    <RightList>
                      <IconView
                        onClick={(e) => {
                          onCopyToClipboard(item?.secretKey);
                        }}>
                        <SVGIcon name='icon-copy' title='copy' size={24} fill='var(--gray-icon-color)' />
                      </IconView>
                      <IconView
                        onClick={(e) => {
                          onOpenMenu(e, item);
                        }}>
                        <SVGIcon fill='var(--gray-color)' size={24} name='icon-more' title='More' />
                      </IconView>
                    </RightList>
                  </ListBox>
                );
              })
            )}
          </ContainBox>
        </MainWrap>
      </MainList>
      <Menu
        id='api-keys-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        positionVertical='top'
        position='left'
        marginTop={25}
        MenuListProps={{ disablePadding: true }}>
        <MenuItemWrapper>
          <MenuItem
            iconName='icon-edit-pen'
            color='var(--gray-icon-color)'
            fontSize='18px'
            text='Edit'
            onClick={(e) => {
              onOpenEditMenu(e);
            }}
          />
          <MenuItem
            iconName='icon-refresh'
            color='var(--gray-icon-color)'
            fontSize='18px'
            text='Regenerate'
            onClick={(e) => {
              ConfirmDialog({
                iconName: 'icon-refresh',
                closeIcon: 'icon-close',
                title: 'Regenerate',
                okButtonText: 'Regenerate',
                message: 'Are you sure want to regenerate key? Once regenerate key, older key will stop working.',
                onOk: () => {
                  onRegenerateKey(e);
                }
              });
            }}
          />
          <MenuItem
            iconName='icon-delete'
            color='var(--gray-icon-color)'
            fontSize='18px'
            text='Delete'
            onClick={(e) => {
              ConfirmDialog({
                iconName: 'icon-delete',
                closeIcon: 'icon-close',
                title: 'Delete',
                okButtonText: 'Delete',
                message: 'Are you sure want to delete API Key?',
                onOk: () => {
                  onDeleteKey(e);
                }
              });
            }}
          />
        </MenuItemWrapper>
      </Menu>
      <Modal open={isOpenApiKeyModal} onClose={closeApiKeyModal}>
        <NewApiKey id={selectedItem?.id} handleCloseModal={closeApiKeyModal} />
      </Modal>
    </>
  );
};

export default ApiKey;
