import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Arrow from '../../../assets/images/arrow-right.svg';
import ReportsHeader from '../../../components/ReportsHeader/ReportsHeader';
import { getDateString } from '../../../helpers/common';
import { getStatusChangeReport } from '../../../services/reportServices';
import SVGIcon from '../../../assets/svg/SVGIcon';
import {
  StatusChangeWrapper,
  ReportsContent,
  Pdt,
  H3,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  LookupName,
  ArrowIcon,
  TdWrapper,
  ArrowWrapper
} from './styles';

export default function StatusChange() {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const reportSelector = useSelector((state) => state.report);
  const { statusChangeReport, loading } = reportSelector;

  const dispatch = useDispatch();
  const history = useHistory();

  const onChange = useCallback(
    (option, fromDate, toDate) => {
      const fromDateString = getDateString(fromDate);
      const toDateString = getDateString(toDate);
      history.push(`/reports/status-change?fromdate=${fromDateString}&todate=${toDateString}&option=${option}`);

      dispatch(getStatusChangeReport(organization_id, fromDateString, toDateString));
    },
    [dispatch, history, organization_id]
  );

  return (
    <>
      <ReportsHeader onChange={onChange} title='Status Change' iconName='icon-porting' />
      {loading ? (
        <StatusChangeWrapper>Loading...</StatusChangeWrapper>
      ) : !loading && statusChangeReport && statusChangeReport?.length === 0 ? (
        <StatusChangeWrapper>No Data Found</StatusChangeWrapper>
      ) : (
        <StatusChangeWrapper>
          <ReportsContent>
            {statusChangeReport &&
              statusChangeReport?.length > 0 &&
              statusChangeReport?.map((tableItem) => {
                return (
                  <Pdt key={tableItem?.id}>
                    <H3>{tableItem?.name}</H3>
                    <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th>Status</Th>
                            <Th>Entered</Th>
                            <Th>Left</Th>
                            <Th>Started</Th>
                            <Th>Ended</Th>
                            <Th>Net Change</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {tableItem &&
                            tableItem?.status?.length > 0 &&
                            tableItem?.status?.map((statusItem) => {
                              return (
                                <Tr key={statusItem?.id}>
                                  <Td>
                                    <LookupName>
                                      <SVGIcon name='icon-audio-play' fill='var(--primary-color)' size={16} />
                                      {statusItem?.statusName}
                                    </LookupName>
                                  </Td>
                                  <Td>{statusItem?.entered === 0 ? '-' : statusItem?.entered}</Td>
                                  <Td>{statusItem?.left === 0 ? '-' : statusItem?.left}</Td>
                                  <Td>
                                    <TdWrapper>
                                      {statusItem?.started}
                                      <ArrowWrapper>
                                        <ArrowIcon src={Arrow} />
                                      </ArrowWrapper>
                                    </TdWrapper>
                                  </Td>
                                  <Td>{statusItem?.ended}</Td>
                                  {statusItem?.netChange === 0 ? (
                                    <Td>-</Td>
                                  ) : statusItem?.netChange > 0 ? (
                                    <Td Mantis>{statusItem?.netChange}</Td>
                                  ) : (
                                    <Td>{statusItem?.netChange}</Td>
                                  )}
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Pdt>
                );
              })}
          </ReportsContent>
        </StatusChangeWrapper>
      )}
    </>
  );
}
