import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Dexie from 'dexie';
import { setErrorMessage } from '../actions/messageActions';
import { ROUTE } from '../constants/routeConst';
import { REACT_APP_APIURL } from '../global/Environment';
import { isDev } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import { getLookupList } from './lookupService';
import { getLookupTables, getTableFields } from './lookupTableServices';

let singleton;
let organizationId;
export default class RealTimeService {
  hubConnection;
  apiurl;
  user;
  isConnected = false;
  static getInstance() {
    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (org) {
      if (!singleton || organizationId !== org.id) {
        organizationId = org.id;
        singleton = new RealTimeService();
      }
    }
    return singleton;
  }

  static removeInstance() {
    organizationId = undefined;
    singleton = undefined;
  }

  init =
    (isForceReconnect = false) =>
    async (dispatch, getState) => {
      try {
        const user = UserPreferenceSingleton.getInstance().getCurrentUser();
        if (!user) return;

        if (this.isConnected && !isForceReconnect) return;

        this.hubConnection = new HubConnectionBuilder()
          .withUrl(`${REACT_APP_APIURL}/realtime?access_token=${user.access_token}`)
          .configureLogging(LogLevel.None)
          .build();

        this.hubConnection
          .start()
          .then(() => {
            this.isConnected = true;
            this.hubConnection.on('CallStatus', (callId, status) => {
              if (status === 'in-progress') {
                //TODO: Update status of current call
                // TwilioService.getInstance().setDeviceAnswered({ callId: callId, status: status })
              }
            });
            this.hubConnection.on('UserAccessUpdated', (userId, companyId) => {
              let user = UserPreferenceSingleton.getInstance().getCurrentUser();
              if (user && user.id === userId) {
                //TODO: Clear localdb and refresh page
                dispatch(initOnUserAccessUpdated(companyId));
              }
            });

            this.hubConnection.on('CompanyDeleted', async (companyId, userId) => {
              let company = UserPreferenceSingleton.getInstance().getOrganization();
              if (company && company.id === companyId) {
                //TODO: Remove localdb and current organization, appInit and navigate to switch organization
                dispatch(initOnCompanyDeleted(companyId));
              }
            });

            this.hubConnection.on('TableUpdated', (companyId, tableId) => {
              let company = UserPreferenceSingleton.getInstance().getOrganization();
              if (company && company.id === companyId) {
                //TODO: update local table and settings
                dispatch(initOnTableUpdated(companyId, tableId));
              }
            });
          })
          .catch((err) => {
            this.isConnected = false;
            console.error('Error while establishing realtime connection :(');

            try {
              var errorObj = JSON.parse(JSON.stringify(err));
              //Init connection again if disconnect from server,(when we stop server it disconnected)
              if (
                (errorObj && (errorObj.statusCode === 0 || errorObj.statusCode === 1006)) ||
                err.toString().indexOf('status code: 1006') !== -1
              ) {
                if (isDev() === false)
                  setTimeout(() => {
                    this.init(true);
                  }, 10000);
              }
            } catch (e) {
              this.errorLogService.logError(e);
            }
          });
      } catch (e) {
        dispatch(setErrorMessage(e.message));
      }
    };

  disconnectConnection = () => {
    this.user = undefined;
    if (this.isConnected) {
      this.hubConnection.stop();
      this.isConnected = false;
    }
  };
}

const initOnUserAccessUpdated = (companyId) => async (dispatch) => {
  try {
    try {
      await Dexie.delete(companyId);
    } catch (e) {
      dispatch(setErrorMessage(e.message));
    }
    const { clearOrgReduxData } = require('./appService');
    dispatch(clearOrgReduxData());
    window.location.reload();
  } catch (e) {
    dispatch(setErrorMessage(e.message));
  }
};

const initOnCompanyDeleted = (companyId) => async (dispatch) => {
  try {
    try {
      await Dexie.delete(companyId);
    } catch (e) {
      dispatch(setErrorMessage(e.message));
    }
    UserPreferenceSingleton.getInstance().setOrganization(undefined);
    const { clearOrgReduxData, appInit } = require('./appService');
    dispatch(clearOrgReduxData());
    await dispatch(appInit());
    window.location.href = ROUTE.SELECT_ORGANIZATION;
  } catch (e) {
    dispatch(setErrorMessage(e.message));
  }
};

const initOnTableUpdated = (companyId, tableId) => async (dispatch) => {
  try {
    await dispatch(getLookupTables(companyId, true));
    await dispatch(getTableFields(companyId, true));
    await dispatch(getLookupList(companyId, tableId));
  } catch (e) {
    dispatch(setErrorMessage(e.message));
  }
};
