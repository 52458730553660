import React from 'react';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import UserLogo from '../../../../assets/images/pixer-logo.png';
import {
  InvoiceContainer,
  InvoiceHeader,
  BackIcon,
  BackSpan,
  StripeWrap,
  StripeHeader,
  ConfigSpan,
  ConnectStripe,
  StripeContainer,
  NoConnectSpan,
  SmStripSpan,
  ConnectStripeMain,
  ConnectMain,
  ConnectedUser,
  UserImage,
  UserSpan,
  MoreIcon
} from './styles';

export const InvoiceAndPayment = () => {
  const history = useHistory();
  return (
    <>
      <InvoiceContainer>
        <InvoiceHeader
          onClick={() => {
            history.push('/settings/features');
          }}>
          <BackIcon>
            <SVGIcon name='icon-big-left-arrow' fill='var(--gray-color)' size={14} />
          </BackIcon>
          <BackSpan>Invoicing & Payment</BackSpan>
        </InvoiceHeader>
        <StripeWrap>
          <StripeHeader>
            <ConfigSpan>Configure Stripe</ConfigSpan>
            <ConnectStripe>
              <SVGIcon strokeWidth={3} name='icon-pluse' fill='var(--primary-color)' size={12} />
              <span>Connect Stripe</span>
            </ConnectStripe>
          </StripeHeader>
          <StripeContainer>
            <NoConnectSpan>No Stripe Account Connected</NoConnectSpan>
            <SmStripSpan>Link stripe account to start collecting payment</SmStripSpan>
          </StripeContainer>
          <StripeContainer>
            <ConnectStripeMain>
              <ConnectMain>
                <ConnectedUser>
                  <UserImage src={UserLogo}></UserImage>
                </ConnectedUser>
                <UserSpan>Pixer Digital</UserSpan>
              </ConnectMain>
              <MoreIcon>
                <SVGIcon name='icon-more' fill='var(--gray-icon-color)' size={24} />
              </MoreIcon>
            </ConnectStripeMain>
          </StripeContainer>
        </StripeWrap>
      </InvoiceContainer>
    </>
  );
};
