import styled, { css } from 'styled-components';
import { Header3, Header4, Header5, Text1, Text2 } from '../../../styles';

const MainList = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          padding-top: 0;
        `
      : css`
          padding-top: 24px;
        `}
`;
const MainTitle = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Titel = styled.h2`
  margin: 0;
  ${Header3}
  color: var(--dark-color);
`;
const FormGroupWrapper = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      padding: 4px 16px 16px 16px;
    `}
`;
const FormGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 16px 16px 0 16px;
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--white-color);
    `}
`;
const Label = styled.label`
  ${Header4}
  color:var(--dark-color);
  margin-bottom: 4px;
  display: block;
`;
const UploadProfilePicture = styled.div`
  width: 100%;
  margin-right: 16px;
  :nth-child(even) {
    margin-right: 0;
  }
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
          :nth-child(even) {
            margin-bottom: 0;
          }
        `
      : css`
          max-width: 50%;
        `}
`;
const UploadProfileView = styled.div`
  width: 80px;
  height: 80px;
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  overflow: hidden;
  span {
    ${Header5}
    color:var(--gray-color);
    padding: 0 12px;
    text-align: center;
  }
`;
const UploadProfile = styled.img`
  width: 100%;
`;
const UploadProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TextArea = styled.textarea`
  border: 1px solid var(--gray-border-color);
  outline: 0;
  resize: none;
  min-height: 82px;
  width: 100%;
  border-radius: 4px;
  padding: 7px 12px;
  color: var(--gray-color);
  ${Text1}
  font-family: var(--primary-font-stack);
`;
const DropDownTitle = styled.span`
  display: block;
  ${Text1}
  color: var(--dark-color);
  margin-bottom: 6px;
`;
const FormGroupBottom = styled.div`
  margin-top: 24px;
`;
const ManagementWrapper = styled.div`
  margin-bottom: 16px;
`;
const ManagementBlock = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 24px;
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--white-color);
    `}
`;
const CompanyTitle = styled.h4`
  margin: 0 0 12px 0;
  ${Header4}
  color:var(--dark-color);
`;
const CompanyPara = styled.p`
  ${Text1}
  color:var(--gray-color);
  margin: 0 0 16px 0;
`;
const ModalFormWrapper = styled.form`
  padding: 12px 16px;
`;
const LabelFile = styled.label`
  input {
    display: flex;
    height: 30px;
    opacity: 0;
    width: 103px;
  }
  position: relative;
`;
const Select = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 2px;
  color: var(--sonic-silver);
  padding: 6px 12px;
  text-align: center;
  ${Text2}
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;
const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const Title = styled.label`
  ${Text1}
  color: var(--dark-color);
  margin-bottom: 4px;
  display: block;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  i {
    cursor: pointer;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderTitle = styled.span`
  display: inline-block;
  margin-left: 12px;
  ${Header4}
  color:var(--dark-color);
`;
const Resend = styled.span`
  color: var(--primary-color);
  ${Header5}
  display: inline-block;
  cursor: pointer;
`;
const CodeWrapper = styled.div`
  padding-top: 12px;
`;
const Paragraph = styled.p`
  margin: 16px 0 12px 0;
  ${Text1}
  color:var(--sonic-silver);
`;
const ButtonWrapper = styled.div`
  border-top: 1px solid var(--gray-border-color);
  margin: 0 -16px;
  padding: 16px 16px 4px 16px;
  display: flex;
  align-items: center;
`;
const DeleteButton = styled.div`
  margin-right: 12px;
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;

export {
  ModalFormWrapper,
  MainList,
  MainTitle,
  Titel,
  FormGroupWrapper,
  FormGroup,
  Label,
  UploadProfilePicture,
  UploadProfileView,
  UploadProfile,
  UploadProfileWrapper,
  TextArea,
  DropDownTitle,
  FormGroupBottom,
  ManagementWrapper,
  ManagementBlock,
  CompanyTitle,
  CompanyPara,
  LabelFile,
  Select,
  ProfileWrapper,
  Title,
  HeaderWrapper,
  HeaderLeft,
  HeaderTitle,
  Resend,
  CodeWrapper,
  Paragraph,
  ButtonWrapper,
  DeleteButton,
  MainMobileButton
};
