import React, { Component } from 'react';
import SmileLogo from '../../../../assets/images/filed.png';
import { MainView, ContainList, ImgView, ImgSmile, TitleText, CaptionText } from './styles';

export class Filed extends Component {
  render() {
    return (
      <MainView>
        <ContainList>
          <ImgView>
            <ImgSmile src={SmileLogo} />
          </ImgView>
          <TitleText>Payment Transfar Filed!</TitleText>
          <CaptionText>Contact your account manager</CaptionText>
        </ContainList>
      </MainView>
    );
  }
}

export default Filed;
