import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import LeftArrow from '../../../assets/images/svg/chevron-left.svg';
import RightArrow from '../../../assets/images/svg/chevron-right.svg';
import { Header3, Header6, Text1, Text2 } from '../../../styles';

const TwoListDetail = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
`;
const LeftList = styled.div`
  max-width: 290px;
  width: 100%;
  padding: 17px 10px 0 9px;
  height: 100%;
  overflow: auto;
`;
const ProFileContain = styled.div`
  display: flex;
  align-items: center;
`;
const ProfileLogo = styled.div`
  width: 42px;
  height: 42px;
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AvtarText = styled.h4`
  ${Header3};
  margin: 0;
  font-style: normal;
  color: var(--gray-color);
`;
const UserView = styled.div`
  margin-left: 14px;
`;
const UserName = styled.h4`
  margin: 0;
  font-style: normal;
  ${Header3};
  color: var(--dark-color);
`;
const UserLeave = styled.p`
  margin: 0;
  ${Text2};
  color: var(--gray-color);
`;
const BtnContain = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1px;
  margin-top: 17px;
  button {
    margin-right: 5px;
  }
`;
const IconView = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  width: 33px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  ${(props) =>
    props.width &&
    css`
      width: 25px;
    `}
`;
const FirstConatin = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 0 13px;
  margin-top: 20px;
  max-width: 262px;
  width: 100%;
`;
const FirstView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 9px 0;
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
  margin-right: 48px;
`;
const BoxText = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin-left: 8px;
`;
const LastViewOne = styled.div`
  width: 100%;
`;
const SecondView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 9px 0;
  :last-child {
    border-bottom: 0;
  }
`;
const LastView = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Lasttext = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
`;
const Lasttext2 = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--darke-color);
  cursor: pointer;
`;
const customestyle = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 13
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    minHeight: '20px',
    maxHeight: '20px',
    width: '118px',
    ':focus': {
      outline: 'none'
    },
    borderWidth: 0
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px'
  }),

  indicatorsContainer: (base) => ({
    ...base,
    marginRight: 0,
    display: 'none'
  }),
  container: (base) => ({
    ...base,
    height: '20px'
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500',
    right: 0
  }),
  singleValue: (base) => ({
    ...base,
    right: 0
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    height: '20px'
  }),
  input: (base) => ({
    ...base,
    width: '100%',
    textAlign: 'right',
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0
  })
};
const SecondContain = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-top: 18px;
  max-width: 262px;
  width: 100%;
`;
const TitleView = styled.div`
  padding: 9px 12px 10px 12px;
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ALasttext = styled.a`
  font-style: normal;
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
`;
const TagView = styled.div``;
const FirstTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 2px 12px;
`;
const TagList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 12px 8px 14px;
  margin: 0 -3px;
`;
const TagBox = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 5px 8px;
  margin: 3px;
  cursor: pointer;
  position: relative;
  display: flex;
  i {
    margin: 2px 0;
  }
`;
const TagText = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
`;
const TitleView2 = styled.div`
  padding: 9px 12px 9px 12px;
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TextDetail = styled.h3`
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  margin: 0 0 0 8px;
`;
const UlList = styled.div`
  padding: 0px 14px 0 14px;
  position: relative;
`;
const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const IconA = styled.a`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -30px;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
  cursor: pointer;
`;
const Li = styled.li`
  padding: 11px 0 0 0;
  display: flex;
  align-items: center;
  position: relative;
  :first-child {
    padding-top: 13px;
  }
  :hover {
    span {
      color: var(--dark-color);
    }
    p {
      color: var(--dark-color);
    }
  }
  :hover ${IconA} {
    right: -18px;
    opacity: 1;
    z-index: 1;
  }
`;
const LSpan = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  white-space: nowrap;
`;
const RText = styled.p`
  margin: 0 0 0 14px;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  width: 79%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const LSpan2 = styled.span`
  font-style: normal;
  ${Text1};
  text-decoration-line: underline;
  color: var(--gray-color);
  cursor: pointer;
  margin-bottom: 14px;
`;
const FormView = styled.form`
  display: flex;
  align-items: center;
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
`;
const InputList = styled.div`
  position: absolute;
  top: 3px;
  right: 0;
  left: 0;
  width: 110%;
  z-index: 9;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      181.23deg,
      rgba(255, 255, 255, 0) 1.04%,
      #ffffff 28.3%,
      #ffffff 75.48%,
      rgba(255, 255, 255, 0) 98.95%
    );
    top: -32px;
    bottom: 0;
    left: 0;
    right: 10%;
    height: 100px;
    z-index: -1;
  }
`;
const SpanTitle = styled.span`
  ${Text2};
  color: var(--dark-color);
  position: absolute;
  padding: 0 5px;
  top: -8px;
  left: 8px;
  font-size: 12px;
  z-index: 2;
  background: var(--white-color);
`;
const InputValue = styled.input`
  border: 0;
  outline: 0;
  height: 25px;
  padding-left: 12px;
  flex: 1 0 0%;
`;
const RightIconView = styled.div`
  border-left: 1px solid var(--gray-border-color);
  padding: 6.75px;
  cursor: pointer;
`;
const CloseIconView = styled.div`
  border-left: 1px solid var(--gray-border-color);
  padding: 6.75px;
  cursor: pointer;
`;
const PopView = styled.div`
  padding: 2px 0 13px 0;
  .DayPicker {
    display: block;
    width: 100%;

    .DayPicker-wrapper {
      padding-bottom: 0;
    }

    .DayPicker-Caption {
      ${Header6};
      padding: 8px 0;
      margin-bottom: 0;
    }

    .DayPicker-NavButton {
      background-size: 100%;
      border-radius: 2px;
      margin-top: 0;
      top: 8px;
      right: 8px;
      &:hover {
        background-color: var(--popup-color-hover);
      }
    }

    .DayPicker-NavButton--prev {
      margin-right: 20px;
      background-image: url(${LeftArrow});
    }

    .DayPicker-NavButton--next {
      background-image: url(${RightArrow});
    }

    .DayPicker-Month {
      margin: 0;
    }

    .DayPicker-WeekdaysRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
    }

    .DayPicker-Weekday {
      ${Text2};
      width: 24px;
      height: 24px;
      line-height: 1.7;
      color: var(--gray-color);
      padding: 2px;
      margin-top: 0;
    }

    .DayPicker-Week {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
    }
    .DayPicker-Caption > div {
      ${Header6};
      color: var(--dark-color);
    }
    .DayPicker-Day {
      border-radius: 30px;
      ${Text2};
      width: 24px;
      height: 24px;
      line-height: 1.7;
      padding: 2px;
      &:hover {
        background-color: var(--popup-color-hover);
      }
    }
  }
`;
const ViewList = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 6px 0;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: var(--popup-color-hover);
  }
`;
const DateTextView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 14px;
`;
const ShowText = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const ShowTime = styled.span`
  ${Text1};
  color: var(--gray-color);
`;
const Border = styled.hr`
  border-bottom: 1px solid var(--Platinum-color);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 6px 0 5px 0;
`;
const useStyles = makeStyles((theme) => ({
  paperMenu: {
    marginLeft: '-17px'
  }
}));

export {
  TwoListDetail,
  LeftList,
  ProFileContain,
  ProfileLogo,
  AvtarText,
  UserView,
  UserName,
  UserLeave,
  BtnContain,
  IconView,
  FirstConatin,
  FirstView,
  FirstList,
  BoxText,
  LastViewOne,
  SecondView,
  LastView,
  Lasttext,
  customestyle,
  Lasttext2,
  SecondContain,
  TitleView,
  ALasttext,
  TagView,
  FirstTag,
  TagList,
  TagBox,
  TagText,
  TitleView2,
  TextDetail,
  UlList,
  Ul,
  Li,
  IconA,
  LSpan,
  RText,
  LSpan2,
  FormView,
  InputList,
  SpanTitle,
  InputValue,
  RightIconView,
  CloseIconView,
  PopView,
  ViewList,
  ShowText,
  ShowTime,
  DateTextView,
  Border,
  useStyles
};
