import styled, { css } from 'styled-components';
import { Header1, SubHeader3, Text1, Text2 } from '../../styles';

const ManeWrapper = styled.div`
  background: var(--main-bg);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LoginContainer = styled.div`
  width: 100%;
  margin: auto;
`;
const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 54px;
  margin-top: 13px;
  svg {
    display: block;
    height: 42px;
    margin: auto auto 20px auto;
  }
`;
const Title = styled.h1`
  ${Header1}
  text-align: center;
  color: var(--dark-color);
  margin: 0;
`;
const Form = styled.div`
  background: #ffffff;
  border: 1px solid #e4e8ea;
  box-shadow: 0px 2px 12px var(--box-shadow-secondary);
  border-radius: 6px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  position: relative;
  button {
    margin-top: 32px;
  }
  ${(props) =>
    props.input &&
    css`
      input {
        margin-bottom: 14px;
      }
      button {
        margin-top: 16px;
      }
    `}
`;
const Label = styled.label`
  ${Text1}
`;
const LabelWrapper = styled.div`
  background: var(--primary-color);
  box-shadow: 0px 2px 6px var(--box-shadow-secondary);
  border-radius: 21px;
  text-align: center;
  color: var(--white-color);
  padding: 0 24px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -20px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LabelTitle = styled.p`
  text-align: center;
  ${SubHeader3}
  color: var(--dark-color);
  margin: 19px 0 24px 0;
`;
const DropLabel = styled.label`
  ${Text1};
  margin-bottom: 4px;
  display: block;
`;
const DropWrapper = styled.div`
  margin-top: 14px;
`;
const LabelTitleTwo = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--dark-color);
  margin: 0px 0 25px 0;
`;
const TabList = styled.div`
  margin-top: 15px;
`;
const LabelTwo = styled.span`
  ${Text1}
  text-transform: capitalize;
  color: var(--dark-color);
  padding-bottom: 5px;
  display: block;
`;
const SelectCardList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CardView = styled.label`
  background: var(--white-color);
  width: 133px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  position: relative;
  cursor: pointer;
  svg {
    height: 25px;
    margin-top: 5px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ span div {
      display: block;
    }
    &:checked ~ span {
      box-shadow: 0 0 0px 2px var(--primary-color);
    }
  }
`;
const CheckmarkLabel = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  box-shadow: 0 0 0px 1px var(--gray-border-color);
  border-radius: 4px;
  &:hover {
    box-shadow: 0 0 0px 2px var(--primary-color);
  }
`;
const IconView = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;
  svg {
    height: auto;
    margin: 0;
  }
`;
const CompantText = styled.span`
  ${Text2}
  text-transform: capitalize;
  color: var(--dark-color);
  margin-top: 10px;
`;
const ListView = styled.div`
  min-height: 215px;
`;
const RowView = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -5px;
  input {
    margin-bottom: 5px;
  }
`;
const InputView = styled.div`
  padding: 0 5px;
  width: 210px;
`;
const CloseView = styled.div`
  display: flex;
  padding: 0 5px 0 5px;
  margin-top: 16px;
  cursor: pointer;
`;
const CloseViewTwo = styled.div`
  margin-top: 0px;
  display: flex;
  padding: 0 5px 0 5px;
`;
const AddView = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  svg {
    height: auto;
  }
`;
const AddText = styled.span`
  ${Text1}
  color: var(--primary-color);
  margin-left: 10px;
  text-decoration: none;
`;
const SpanText = styled.span`
  color: var(--gray-color);
  cursor: context-menu;
`;
const ViewBtn = styled.div`
  button {
    margin: 0;
  }
`;
const RadioBtn = styled.input``;
const GroupIcon = styled.div`
  display: flex !important;
  align-items: center;
`;
const SkipBtn = styled.span`
  ${Text1};
  color: var(--primary-color);
  margin: 5px;
  border-radius: 4px;
  padding: 4px 4px;
  width: fit-content;
  cursor: pointer;
  :hover {
    background: var(--popup-color-hover);
  }
`;
export {
  SkipBtn,
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  Label,
  LabelTitle,
  LabelTitleTwo,
  TabList,
  LabelTwo,
  SelectCardList,
  CardView,
  CompantText,
  IconView,
  RowView,
  CloseView,
  CloseViewTwo,
  InputView,
  AddView,
  AddText,
  SpanText,
  ViewBtn,
  RadioBtn,
  ListView,
  CheckmarkLabel,
  GroupIcon,
  LabelWrapper,
  DropLabel,
  DropWrapper
};
