import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/atom/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import { getSubscriptionPlans } from '../../../../services/settingService';
import PlusIcon from '../../../../assets/images/svg/plus-icon.svg';
import MinusIcon from '../../../../assets/images/svg/minus-icon.svg';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../../../global/Environment';
import { SUBSCRIPTION_TYPE } from '../../../../constants/constant';
import { getAppHostName } from '../../../../helpers/common';
import { setIsAppUpgradeToPro } from '../../../../actions/appActions';
import StripeCheckoutForm from './StripeCheckoutForm';
import {
  MainContainer,
  ContainerWrap,
  HeadView,
  TabView,
  InputOne,
  InLabelOne,
  InputTwo,
  InLabelTwo,
  CloseView,
  HeadWrap,
  SecondWrap,
  FirstRow,
  BoxView,
  Title,
  Caption,
  DetailView,
  Amount,
  BillCaption,
  BtnView,
  BoxItem,
  ListApp,
  Ul,
  Li,
  ListSpan,
  BtnMore,
  BtnA,
  HelpView,
  ViewLeft,
  Help,
  Helpcaption,
  ViewRight,
  MinusPlusImg,
  PaymentsStripeWrapper,
  PaymentsStripe,
  PaymentHeadBox,
  Heading,
  SubHeading,
  UserNumberPrice,
  UserNumber,
  UserSelectTitle,
  PluseMinusBtn,
  MinusBtn,
  PluseBtn,
  CountUser,
  Price,
  TotalPrice,
  Small,
  PriceText,
  Link
} from './styles';

const SUBSCRIPTION_ID = {
  STARTER_MONTHLY: 'Starter_Monthly',
  PLUS_MONTHLY: 'Plus_Monthly',
  PRO_MONTHLY: 'Pro_Monthly',
  ENTERPRISE_MONTHLY: 'Enterprise_Monthly',
  STARTER_YEARLY: 'Starter_Yearly',
  PLUS_YEARLY: 'Plus_Yearly',
  PRO_YEARLY: 'Pro_Yearly',
  ENTERPRISE_YEARLY: 'Enterprise_Yearly'
};

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const Upgrade = (props) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const [subscriptionType, setSubscriptionType] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState();
  const [userCount, setUserCount] = useState(organization?.users?.length || 1);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getSubscriptionPlans(organization_id));
    dispatch(setIsAppUpgradeToPro(false));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);

  const settingSelector = useSelector((state) => state.setting);
  const { subscriptionPlans } = settingSelector;

  const handleClosePlan = useCallback(() => {
    props.history.push('/settings/subscriptions');
  }, [props]);

  const changeSubscriptionType = useCallback((type) => {
    setSubscriptionType(type);
  }, []);

  const handleChangePlan = useCallback(
    (id) => {
      const selectedPlan = subscriptionPlans?.find((plan) => plan.id === id);
      if (selectedPlan) {
        setSelectedPlan(selectedPlan);
        setIsPaymentModalOpen(true);
      } else {
        selectedPlan(null);
      }
    },
    [subscriptionPlans]
  );

  const onChangeUserCount = useCallback(
    (value) => {
      setUserCount(userCount + value);
    },
    [setUserCount, userCount]
  );

  const planAmount = useMemo(() => {
    if (selectedPlan) {
      return userCount * Number(selectedPlan?.amount) * (selectedPlan?.subscriptionType === 2 ? 12 : 1);
    }
    return 0;
  }, [selectedPlan, userCount]);

  const onClosePaymentModal = useCallback(() => {
    setIsPaymentModalOpen(false);
  }, []);

  const useStyles = makeStyles((theme) => ({
    modal: {
      width: '100%',
      maxWidth: '430px',
      margin: 'auto',
      border: ' 1px solid var(--Platinum-color)',
      boxShadow: '0px 6px 25px var(--modal-shadow)',
      borderRadius: '8px',
      backgroundColor: 'var(--white-color)',
      height: 'fit-content'
    }
  }));

  const classes = useStyles();

  return (
    <MainContainer>
      <ContainerWrap>
        <HeadWrap>
          <HeadView>
            <TabView>
              <InputOne
                id='tab1'
                type='radio'
                name='tabsA'
                checked={subscriptionType === SUBSCRIPTION_TYPE.MONTHLY}
                onChange={() => {
                  changeSubscriptionType(SUBSCRIPTION_TYPE.MONTHLY);
                }}
              />
              <InLabelOne htmlFor='tab1'>Monthly</InLabelOne>
              <InputTwo
                id='tab2'
                type='radio'
                name='tabsA'
                checked={subscriptionType === SUBSCRIPTION_TYPE.YEARLY}
                onChange={() => {
                  changeSubscriptionType(SUBSCRIPTION_TYPE.YEARLY);
                }}
              />
              <InLabelTwo htmlFor='tab2'>Annual</InLabelTwo>
            </TabView>
          </HeadView>
          <CloseView>
            <Button title='Close' secondary onClick={handleClosePlan} />
          </CloseView>
        </HeadWrap>
        <SecondWrap>
          <FirstRow>
            <BoxItem>
              <BoxView>
                <Title>Starter</Title>
                <Caption>The essentials to get your team up and running</Caption>
                <DetailView>
                  <Amount>{subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? '$12' : '$9'}</Amount>
                  <BillCaption>user &#47; month billed annually</BillCaption>
                </DetailView>
                <BtnView>
                  <Button
                    primary
                    size='medium'
                    title='Select Plan'
                    type='button'
                    onClick={() => {
                      handleChangePlan(
                        subscriptionType === SUBSCRIPTION_TYPE.MONTHLY
                          ? SUBSCRIPTION_ID.STARTER_MONTHLY
                          : SUBSCRIPTION_ID.STARTER_YEARLY
                      );
                    }}
                  />
                </BtnView>
              </BoxView>
              <ListApp>
                <Ul>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Upto 5 Users</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Store leads &#38; contacts </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom Fields </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom collection</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Goals</ListSpan>
                  </Li>
                </Ul>
              </ListApp>
            </BoxItem>
            <BoxItem>
              <BoxView>
                <Title>Plus</Title>
                <Caption>The essentials to get your team up and running</Caption>
                <DetailView>
                  <Amount>{subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? '$29' : '$24'}</Amount>
                  <BillCaption>user &#47; month billed annually</BillCaption>
                </DetailView>
                <BtnView>
                  <Button
                    primary
                    size='medium'
                    title='Select Plan'
                    type='button'
                    onClick={() => {
                      handleChangePlan(
                        subscriptionType === SUBSCRIPTION_TYPE.MONTHLY
                          ? SUBSCRIPTION_ID.PLUS_MONTHLY
                          : SUBSCRIPTION_ID.PLUS_YEARLY
                      );
                    }}
                  />
                </BtnView>
              </BoxView>
              <ListApp>
                <Ul>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Users Unlimited</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Store leads &#38; contacts </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom Fields </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom collection</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Goals</ListSpan>
                  </Li>
                </Ul>
              </ListApp>
            </BoxItem>
            <BoxItem>
              <BoxView>
                <Title>Pro</Title>
                <Caption>The essentials to get your team up and running</Caption>
                <DetailView>
                  <Amount>{subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? '$49' : '$39'}</Amount>
                  <BillCaption>user &#47; month billed annually</BillCaption>
                </DetailView>
                <BtnView>
                  <Button
                    primary
                    size='medium'
                    title='Select Plan'
                    type='button'
                    onClick={() => {
                      handleChangePlan(
                        subscriptionType === SUBSCRIPTION_TYPE.MONTHLY
                          ? SUBSCRIPTION_ID.PRO_MONTHLY
                          : SUBSCRIPTION_ID.PRO_YEARLY
                      );
                    }}
                  />
                </BtnView>
              </BoxView>
              <ListApp>
                <Ul>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Users Unlimited</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Store leads &#38; contacts </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom Fields </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom collection</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Goals</ListSpan>
                  </Li>
                </Ul>
              </ListApp>
            </BoxItem>
            <BoxItem>
              <BoxView>
                <Title>Enterprise</Title>
                <Caption>The essentials to get your team up and running</Caption>
                <DetailView>
                  <Amount>{subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? '$99' : '$79'}</Amount>
                  <BillCaption>user &#47; month billed annually</BillCaption>
                </DetailView>
                <BtnView>
                  <Button
                    primary
                    size='medium'
                    title='Select Plan'
                    type='button'
                    onClick={() => {
                      handleChangePlan(
                        subscriptionType === SUBSCRIPTION_TYPE.MONTHLY
                          ? SUBSCRIPTION_ID.ENTERPRISE_MONTHLY
                          : SUBSCRIPTION_ID.ENTERPRISE_YEARLY
                      );
                    }}
                  />
                </BtnView>
              </BoxView>
              <ListApp>
                <Ul>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Users Unlimited</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Store leads &#38; contacts </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom Fields </ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Custom collection</ListSpan>
                  </Li>
                  <Li>
                    <Icon name='feather-check' fontSize='16' color='var(--dark-color)' />
                    <ListSpan>Goals</ListSpan>
                  </Li>
                </Ul>
              </ListApp>
            </BoxItem>
          </FirstRow>
        </SecondWrap>

        {selectedPlan && (
          <Modal
            open={isPaymentModalOpen}
            onClose={onClosePaymentModal}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            BackdropProps={{ invisible: true }}
            disableBackdropClick={true}
            className={classes.modal}>
            <>
              <PaymentHeadBox>
                <Link onClick={onClosePaymentModal}>
                  <Icon name='feather-x' fontSize='16' color='var(--white-color)' />
                </Link>
                <Heading>Your {appName} plan</Heading>
                <SubHeading>{selectedPlan?.planName}</SubHeading>
                <UserNumberPrice>
                  <UserNumber>
                    <UserSelectTitle>Select number of user</UserSelectTitle>
                    <PluseMinusBtn>
                      <MinusBtn
                        type='button'
                        className='btn'
                        disabled={userCount <= organization?.users?.length}
                        onClick={() => {
                          onChangeUserCount(-1);
                        }}>
                        <MinusPlusImg src={MinusIcon} alt='minus' />
                      </MinusBtn>
                      <CountUser>{userCount}</CountUser>
                      <PluseBtn
                        type='button'
                        className='btn'
                        onClick={() => {
                          onChangeUserCount(1);
                        }}>
                        <MinusPlusImg src={PlusIcon} alt='plus' />
                      </PluseBtn>
                    </PluseMinusBtn>
                  </UserNumber>
                  <Price>
                    <TotalPrice>
                      <Small>$</Small>
                      {planAmount}
                    </TotalPrice>
                    {selectedPlan?.subscriptionType === 1 ? (
                      <PriceText>Per month</PriceText>
                    ) : (
                      <PriceText>Per Year</PriceText>
                    )}
                  </Price>
                </UserNumberPrice>
              </PaymentHeadBox>
              <Elements stripe={stripePromise}>
                <StripeCheckoutForm plan={selectedPlan} userCount={userCount} />
              </Elements>
            </>
          </Modal>
        )}

        <BtnMore>
          <BtnA href='https://www.salescamp.app/pricing' target='_blank'>
            See all features
          </BtnA>
        </BtnMore>
        <HelpView>
          <ViewLeft>
            <Help>Need help?</Help>
            <Helpcaption>Talk to an expert and find the best plan for your team</Helpcaption>
          </ViewLeft>
          <ViewRight>
            <BtnA href='mailto:hello@salescamp.app' target='_blank'>
              <Button secondary type='button' title='Contact Us' />
            </BtnA>
          </ViewRight>
        </HelpView>
        <PaymentsStripeWrapper>
          <PaymentsStripe>Payments powered by stripe</PaymentsStripe>
        </PaymentsStripeWrapper>
      </ContainerWrap>
    </MainContainer>
  );
};

export default Upgrade;
