import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Header3, SubHeader3, Text1, Text2 } from '../../styles';

const AutomationEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 16px 24px;
  position: sticky;
  top: 0;
`;
const AutomationEmptyLeft = styled.div`
  display: flex;
  align-items: center;
`;
const AutomationEmptyRight = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  display: block;
  margin-left: 16px;
  ${SubHeader3}
`;
const NewTitle = styled.span`
  color: var(--primary-color);
  ${Text1};
  cursor: pointer;
`;
const EmptyImg = styled.img``;
const EmptyWrapper = styled.div`
  width: fit-content;
  margin: 74px auto 0 auto;
  text-align: center;
`;
const DetailsTitle = styled.span`
  ${Header3}
  color:var(--dark-color);
  margin-top: 20px;
  margin-bottom: 6px;
  display: block;
`;
const Details = styled.span`
  ${SubHeader3}
  color:var(--gray-color);
  margin-bottom: 16px;
  display: block;
`;
const LeftButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
  button {
    svg {
      margin-right: 10px;
    }
  }
`;
const InputWrapper = styled.div`
  margin-left: 6px;
`;
const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: -5px;
`;
const AutomationWrapper = styled.div`
  height: calc(100vh - 84px);
  position: relative;
`;
const AutomationContentView = styled.div`
  padding: 9px 0 25px 25px;
  height: 100%;
  overflow: auto;
`;
const AutomationContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
const AutomationListWrapper = styled.div`
  position: relative;
  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    border-left: 1px dashed var(--gray-border-color);
    z-index: -9;
  }
`;
const AutomationListItem = styled.div`
  position: relative;
  :last-of-type .automation-block-p::before {
    background-color: var(--gray-border-color);
  }
  :first-child h5::before {
    content: none;
  }
  :first-child ::after {
    content: none;
  }
`;
const AutomationBlock = styled.div`
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(46 62 70 / 5%);
  display: block;
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  cursor: pointer;
  ::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: calc(50% - 8px);
    width: 16px;
    height: 16px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 0px 1px var(--gray-border-color);
    border-radius: 100%;
    z-index: -1;
  }
  ::after {
    content: '';
    position: absolute;
    top: -9px;
    left: calc(50% - 8px);
    width: 16px;
    height: 16px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 0px 1px var(--gray-border-color);
    border-radius: 100%;
    z-index: -1;
  }
  ${(props) =>
    props.active &&
    css`
      border: 1px solid var(--primary-color);
      ::before {
        content: '';
        box-shadow: 0px 0px 0px 1px var(--primary-color);
      }
      ::after {
        content: '';
        box-shadow: 0px 0px 0px 1px var(--primary-color);
      }
    `}
  :hover {
    button {
      display: block;
    }
  }
`;
const H5 = styled.h5`
  color: var(--gray-color);
  text-transform: uppercase;
  background-color: var(--popup-color-hover);
  ${Text2}
  border-bottom: 1px solid var(--gray-border-color);
  border-radius: 3px 3px 0 0;
  padding: 5px 12px;
  display: block;
  width: 100%;
  position: relative;
  margin: 0;
  ::before {
    content: '';
    position: absolute;
    top: -9px;
    left: calc(50% - 8px);
    background-color: var(--primary-color);
    border: 4px solid var(--popup-color-hover);
    border-radius: 100%;
    z-index: 0;
    padding: 4px;
  }
`;
const P = styled.p`
  margin: 0;
  padding: 15px 12px;
  min-height: 50px;
  display: block;
  width: 100%;
  ${SubHeader3}
  color:var(--dark-color);
  position: relative;
  span {
    ${SubHeader3}
    color:var(--Gray-color);
    display: inline-block;
    margin: 0 6px;
  }
  ::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: calc(50% - 8px);
    background-color: var(--primary-color);
    border: 4px solid var(--white-color);
    border-radius: 100%;
    z-index: 11;
    padding: 4px;
  }
`;
const AutomationAddBtn = styled.button`
  background-color: var(--white-color);
  border: 1px dashed var(--gray-border-color);
  border-radius: 3px;
  ${Text2}
  outline: 0;
  color: var(--gray-color);
  padding: 15px 12px;
  display: block;
  width: 100%;
  position: relative;
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  cursor: pointer;
  ::before {
    content: '';
    position: absolute;
    top: -9px;
    z-index: 2;
    left: calc(50% - 8px);
    border-radius: 100%;
    background-color: var(--gray-border-color);
    border: 4px solid var(--white-color);
    border-radius: 100%;
    z-index: 0;
    padding: 4px;
  }
  ::after {
    content: '';
    position: absolute;
    top: -10px;
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
    background-color: var(--white-color);
    border: 1px dashed var(--gray-border-color);
    border-bottom: 0;
    border-radius: 100%;
    z-index: -1;
  }
`;
const ButtonPlus = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  margin-top: -50px;
  margin-left: -11.5px;
  z-index: 9;
  cursor: pointer;
`;
const Plus = styled.img``;
const RemoveButton = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  z-index: 9;
  cursor: pointer;
  position: absolute;
  right: -12px;
  top: 40px;
  display: none;
`;
const RemoveIconWrapper = styled.img``;
const AutomationOptionWp = styled.div`
  width: 325px;
  position: absolute;
  right: 30px;
  top: 0;
`;
const AutomationFormView = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 4px;
  padding: 20px 16px 8px 16px;
`;
const ListWrappper = styled.div`
  margin-bottom: 12px;
  .MuiFormControl-root.MuiTextField-root.makeStyles-root-14.DatePickerWrapper {
    width: 100%;
    .snow {
      background-color: var(--yellow-lite-color);
      border: 1px solid var(--yellow-lite-border);
      font-family: var(--primary-font-stack);
      min-height: 300px;
      height: 300px;
      overflow-x: hidden;
      width: 100%;
      ::placeholder {
        font-family: var(--primary-font-stack);
      }
      .ql-snow {
        border: none;
        padding: 8px 12px;
      }
      .ql-editor {
        padding: 0;
        overflow-x: auto;
      }
      .ql-toolbar {
        .ql-active {
          color: var(--primary-color);
        }
        .ql-formats {
          margin-right: 0;
          :first-child {
            margin-right: 12px;
          }
        }
        button {
          padding: 0;
          height: 20px;
          width: 20px;
          margin-right: 12px;
          svg {
            display: none;
          }
        }
        .ql-expanded .ql-picker-label {
          color: var(--primary-color);
        }

        .ql-picker-label {
          svg {
            display: none;
          }
        }
        .ql-picker-label {
          border-radius: 4px;
        }

        .ql-picker-options {
          border-radius: 4px;
          margin-top: 6px;
          border-color: var(--gray-border-color);
          box-shadow: 0 3px 10px var(--menu-modal);
        }
      }
      .ql-container {
        height: 249px;
      }
    }
  }
`;
const DropDownTitle = styled.span`
  display: block;
  margin-bottom: 4px;
  ${Text1}
  ${(props) =>
    props.smallLable &&
    css`
      ${Text2}
    `}
`;
const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 90px;
  min-height: 90px;
  outline: 0;
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 8px 12px;
  font-family: var(--primary-font-stack);
  font-weight: 500;
  :focus {
    border: 2px solid var(--primary-color);
    margin: -1px;
  }
`;
const ViewIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0px 8px 16px;
  padding: 0 16px;
  background: var(--linear-gradient-more);
  width: 130px;
`;
const CellLinkWrapper = styled(NavLink)`
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const TitleLink = styled.span`
  ${Text1}
  margin-left: 15px;
  color: var(--gray-color);
`;
const AutomationTableWrap = styled.div`
  overflow: auto;
  z-index: 2;
  position: relative;
  max-height: calc(100% - 134px);
  height: 100%;
`;
const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  .input {
    margin: 0;
    width: 14px;
    height: 14px;
  }
  I {
    cursor: pointer;
  }
`;
const Name = styled.span`
  display: block;
  margin-left: 36px;
  ${Text1}
`;
const IconBox = styled.div``;
const MenuWrapper = styled.div`
  padding: 6px 0;
  li {
    margin-bottom: 2px;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const LastListWrap = styled.div`
  padding-left: 12px;
`;
const DropDownMain = styled.div`
  margin-bottom: 16px;
`;
const FileBox = styled.div`
  margin-top: 16px;
  display: inline-block;
  cursor: pointer;
  svg {
    display: block;
  }
`;

export {
  TitleLink,
  CellLinkWrapper,
  CellWrapper,
  ViewIcon,
  AutomationEmptyWrapper,
  AutomationEmptyLeft,
  AutomationEmptyRight,
  Title,
  NewTitle,
  EmptyImg,
  EmptyWrapper,
  DetailsTitle,
  Details,
  LeftButton,
  InputWrapper,
  IconWrapper,
  AutomationWrapper,
  AutomationContentView,
  AutomationContainer,
  AutomationOptionWp,
  AutomationListWrapper,
  AutomationListItem,
  AutomationBlock,
  H5,
  P,
  AutomationAddBtn,
  ButtonPlus,
  Plus,
  RemoveIconWrapper,
  RemoveButton,
  AutomationFormView,
  DropDownTitle,
  ListWrappper,
  TextArea,
  Name,
  IconBox,
  MenuWrapper,
  SvgWrapper,
  AutomationTableWrap,
  LastListWrap,
  DropDownMain,
  FileBox
};
