import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/images/svg/Salecamplogo.svg';
import Button from '../../components/atom/Button/Button';
import Welcomeimg from '../../assets/images/Welcome.png';
import { ROUTE } from '../../constants/routeConst';
import { getAppHostName } from '../../helpers/common';
import { APP_HOST_TYPE } from '../../constants/constant';
import {
  WelcomeMain,
  SalescampLogo,
  WelcomeMainText,
  ButtonMain,
  WelcomeMainImage,
  ImgWelcome,
  WelcomeWrapper
} from './styles';

const Welcome = () => {
  const history = useHistory();

  const welcomeText = useMemo(() => {
    const name = getAppHostName();
    if (name === APP_HOST_TYPE.GET_PEOPLE) {
      return 'Salescamp CRM builds to simplify your sales workflow.';
    } else {
      return 'GetPeople helps you Keep your team connected and organized by collaborating and sharing tasks.';
    }
  }, []);

  return (
    <>
      <WelcomeWrapper>
        <WelcomeMain>
          <SalescampLogo>
            <LogoIcon />
          </SalescampLogo>
          <WelcomeMainText>{welcomeText}</WelcomeMainText>
          <ButtonMain>
            <Button
              title={'Login'}
              size={'large'}
              onClick={() => {
                history.push(ROUTE.LOGIN);
              }}
            />
            <Button
              title={'Sign Up'}
              size={'large'}
              secondary={'secondary'}
              onClick={() => {
                history.push(ROUTE.SIGN_UP);
              }}
            />
          </ButtonMain>
          <WelcomeMainImage>
            <ImgWelcome src={Welcomeimg} />
          </WelcomeMainImage>
        </WelcomeMain>
      </WelcomeWrapper>
    </>
  );
};

export default Welcome;
