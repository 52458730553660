import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set Setting Loader
 */
export const setSettingLoader = createAction(Actions.SET_SETTING_LOADER);
/**
 * @desc Set phone number list
 */
export const setPhoneNumberList = createAction(Actions.SET_PHONE_NUMBER_LIST);
/**
 * @desc Update phone number list
 */
export const updatePhoneNumberList = createAction(Actions.UPDATE_PHONE_NUMBER_LIST);
/**
 * @desc Set Twilio Settings
 */
export const setTwilioSettingItem = createAction(Actions.SET_TWILIO_SETTINGS);
/**
 * @desc Set Web Form List
 */
export const setWebFormList = createAction(Actions.SET_WEBFORM_LIST);

/**
 * @desc Set Web Form Detail
 */
export const setWebFormDetail = createAction(Actions.SET_WEBFORM_DETAIL);

/**
 * @desc Set Web Form Detail
 */
export const updateWebFormDetail = createAction(Actions.UPDATE_WEBFORM_DETAIL);

/**
 * @desc Set Web Form Code
 */
export const setWebFormCode = createAction(Actions.SET_WEBFORM_CODE);

/**
 * @desc Set User Access Data
 */
export const setUserAccessData = createAction(Actions.SET_USER_ACCESS_DATA);

/**
 * @desc Update User Access Data
 */
export const updateUserAccessData = createAction(Actions.UPDATE_USER_ACCESS_DATA);

/**
 * @desc set Customize Fields Data
 */
export const setCustomizeFields = createAction(Actions.SET_CUSTOMIZE_FIELDS);

/**
 * @desc Edit Customize Field Data
 */
export const editCustomizeField = createAction(Actions.EDIT_CUSTOMIZE_FIELD);

/**
 * @desc Update Customize Group Fields
 */
export const updateCustomizeGroupFields = createAction(Actions.UPDATE_CUSTOMIZE_GROUP_FIELDS);

/**
 * @desc Add Customize Field Data
 */
export const addCustomizeField = createAction(Actions.ADD_CUSTOMIZE_FIELD);

/**
 * @desc Delete Customize Field Data
 */
export const deleteCustomizeField = createAction(Actions.DELETE_CUSTOMIZE_FIELD);

/**
 * @desc Add Customize Field Group
 */
export const addCustomizeFieldGroup = createAction(Actions.ADD_CUSTOMIZE_FIELD_GROUP);

/**
 * @desc Delete Customize Field Group
 */
export const deleteCustomizeFieldGroup = createAction(Actions.DELETE_CUSTOMIZE_FIELD_GROUP);

/**
 * @desc Update Customize Field Group Name
 */
export const updateCustomizeFieldGroupName = createAction(Actions.UPDATE_CUSTOMIZE_FIELD_GROUP_NAME);
/**
 * @desc set Selected Customize Field
 */
export const setSelectedCustomizeField = createAction(Actions.SET_SELECTED_CUSTOMIZE_FIELD);
/**
 * @desc Update Selected Customize Field
 */
export const updateSelectedCustomizeField = createAction(Actions.UPDATE_SELECTED_CUSTOMIZE_FIELD);
/**
 * @desc Update Selected Customize Field option
 */
export const updateSelectedCustomizeFieldOption = createAction(Actions.UPDATE_SELECTED_CUSTOMIZED_FIELD_OPTION);
/**
 * @desc Add Selected Customize Field Option
 */
export const addSelectedCustomizeFieldOption = createAction(Actions.ADD_SELECTED_CUSTOMIZED_FIELD_OPTION);
/**
 * @desc Delete Selected Customize Field
 */
export const deleteSelectedCustomizeFieldOption = createAction(Actions.DELETE_SELECTED_CUSTOMIZED_FIELD_OPTION);

/**
 * @desc set Subscription List
 */
export const setSubscriptionList = createAction(Actions.SET_SUBSCRIPTION_LIST);

/**
 * @desc set Subscription Plans
 */
export const setSubscriptionPlans = createAction(Actions.SET_SUBSCRIPTION_PLANS);

/**
 * @desc set Import Data
 */
export const setImportData = createAction(Actions.SET_IMPORT_DATA);

/**
 * @desc set Setting ignature
 */
export const setSettingSignature = createAction(Actions.SET_SETTING_SIGNATURE);

/**
 * @desc set Api Key
 */
export const setIntegrationSecretKey = createAction(Actions.SET_INTEGRATION_SECRET_KEY);
/**
 * @desc Set Total Amount
 */
export const setTotalAmount = createAction(Actions.SET_TOTAL_AMOUNT);

/**
 * @desc Set api key list
 */
export const setAPIKeys = createAction(Actions.SET_API_KEYS);

/**
 * @desc Set API Key Item
 */
export const setAPIKeyItem = createAction(Actions.SET_API_KEY_ITEM);

/**
 * @desc Update API Key Item
 */
export const updateAPIKeyItem = createAction(Actions.UPDATE_API_KEY_ITEM);
/**
 * @desc Set Existing Phone Numbers
 */
export const setExistingPhoneNumbers = createAction(Actions.SET_EXISTING_PHONE_NUMBERS);

/**
 * @desc Update Existing Phone Number
 */
export const updateExistingPhoneNumber = createAction(Actions.UPDATE_EXISTING_PHONE_NUMBER);
/**
 * @desc Clear Web Form Data
 */
export const clearSettingData = () => (dispatch) => {
  dispatch(setWebFormList(null));
  dispatch(setSettingLoader(false));
  dispatch(setPhoneNumberList(null));
  dispatch(setUserAccessData(null));
  dispatch(setCustomizeFields(null));
  dispatch(setSubscriptionList(null));
  dispatch(setSubscriptionPlans(null));
  dispatch(setSettingSignature(null));
  dispatch(setIntegrationSecretKey(null));
  dispatch(setImportData(null));
  dispatch(setAPIKeys(null));
  dispatch(setAPIKeyItem(null));
};
