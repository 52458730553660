import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateExistingPhoneNumber } from '../../../../actions/settingActions';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import Button from '../../../../components/atom/Button/Button';
import { addExistingPhoneNumber } from '../../../../services/settingService';
import {
  TitleWrapper,
  HeaderWrapper,
  TitleText,
  IconView,
  TwilioSettingWrapper,
  Wrapper,
  BtnViewList,
  TopTitle,
  PhoneNumWrap,
  PhoneNumRow,
  RoWLeft,
  PhoneNumber,
  PhoneStatus,
  RowRight
} from './styles';

const TwilioAccountConnect = ({ onClose, onSave }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const settingsSelector = useSelector((state) => state.setting);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { existingPhoneNumbers, loading } = settingsSelector;
  const dispatch = useDispatch();

  const onChangeInput = useCallback(
    (id, propsName, value, index) => {
      dispatch(updateExistingPhoneNumber({ id, propsName, value, index }));
    },
    [dispatch]
  );

  const onClickLinkNumber = useCallback(async () => {
    const payload = [];
    existingPhoneNumbers?.forEach((number) => {
      if (number?.isLinkedNumber) {
        payload?.push(number);
      }
    });
    if (payload?.length > 0) {
      const result = await dispatch(addExistingPhoneNumber(organization_id, payload));
      if (result) {
        onClose();
        onSave();
      }
    }
  }, [dispatch, existingPhoneNumbers, onClose, onSave, organization_id]);
  return (
    <>
      <TitleWrapper>
        <HeaderWrapper>
          <SVGIcon name='icon-refresh' fill='var(--gray-icon-color)' size={22} />
          <TitleText>Connect Twilio</TitleText>
        </HeaderWrapper>
        <IconView
          onClick={() => {
            onClose();
          }}>
          <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
        </IconView>
      </TitleWrapper>
      <TwilioSettingWrapper>
        <Wrapper>
          <TopTitle>
            Link you existing number with Salescamp, Select number which you would like to link with Salescamp
          </TopTitle>
          <PhoneNumWrap>
            {existingPhoneNumbers &&
              existingPhoneNumbers?.map((item, index) => {
                return (
                  <PhoneNumRow key={`phoneSid_${item?.phoneSid}_index_${index}`}>
                    <RoWLeft>
                      <PhoneNumber>{item?.number}</PhoneNumber>
                      <PhoneStatus>{item?.isTwilioNumber ? 'Incoming/Outgoing Only' : 'Outgoing Only'}</PhoneStatus>
                    </RoWLeft>
                    <RowRight>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          onChange={(e) => {
                            onChangeInput(item?.phoneSid, 'isLinkedNumber', e.target.checked);
                          }}
                          checked={item?.isLinkedNumber}></input>
                        <span className='slider round'></span>
                      </label>
                    </RowRight>
                  </PhoneNumRow>
                );
              })}
          </PhoneNumWrap>
        </Wrapper>
        <BtnViewList>
          <Button title='Link Number' loading={loading} onClick={onClickLinkNumber} />
        </BtnViewList>
      </TwilioSettingWrapper>
    </>
  );
};

export default TwilioAccountConnect;
