import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';
/**
 * @desc Set SmsLog Loader
 */
export const setSmsLoader = createAction(Actions.SET_SMS_LOADER);
/**
 * @desc Set SmsLog List
 */
export const setSmsList = createAction(Actions.SET_SMS_LIST);

export const clearAutomationData = () => (dispatch) => {
  dispatch(setSmsLoader(false));
  dispatch(setSmsList(null));
};
