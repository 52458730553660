import amplitude from 'amplitude-js';
import ReactGA from 'react-ga';
import _ from 'lodash';
import { REACT_APP_AMPLITUDE_ANALYTICS_KEY, REACT_APP_GOOGLE_ANALYTICS_ID } from '../global/Environment';
import { getCookie, isElectronApp, isMAC, isWindows } from '../global/Helper';
import { isDev } from '../helpers/common';
import { PLATFORM_KEY_NAME } from '../constants/constant';

const analytics = amplitude.getInstance();
if (REACT_APP_AMPLITUDE_ANALYTICS_KEY) analytics.init(REACT_APP_AMPLITUDE_ANALYTICS_KEY);

let currentUserId;
let currentCompanyId;
/**
 * @desc init analytics
 */
export const initAnalytics = () => {
  try {
    if (isDev()) {
      console.log('initAnalytics');
      return;
    }
    if (REACT_APP_AMPLITUDE_ANALYTICS_KEY) analytics.init(REACT_APP_AMPLITUDE_ANALYTICS_KEY);
    if (REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID, {
        titleCase: false
      });
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc set userid and email for analytics
 */
const setAnalyticUser = (user) => {
  try {
    currentUserId = user?.id;
    if (isDev()) {
      console.log('setAnalyticUser', user?.id);
      return;
    }
    if (user && user.email) {
      if (analytics) {
        analytics.setUserId(user.email);
        analytics.setUserProperties(
          setAmplitudeUserParams({
            email: user.email
          })
        );
      }
      setLuckyOrangeIdentity(user, 0);
      if (REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.set({
          userId: user.id,
          username: user.email
        });
      }
    } else {
      clearAnlayticsUser();
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc set user's company information for analytics
 */
const setAnalyticCompany = (id, properties) => {
  try {
    currentCompanyId = id;
    if (isDev()) {
      console.log('setAnalyticCompany', id, properties);
      return;
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc set use and company for analytics
 */
export const setAnlayticUserAndCompany = (user, company) => {
  try {
    currentUserId = user?.id;
    currentCompanyId = company?.id;
    if (isDev()) {
      console.log('setUserAndCompany', user.id, company);
      return;
    }
    const utm_source = getCookie('utm_source');
    const utm_medium = getCookie('utm_medium');
    const utm_campaign = getCookie('utm_campaign');
    const utm_content = getCookie('utm_content');
    const utm_term = getCookie('utm_term');
    const referrer = getCookie('referrer');
    const ref = getCookie('ref');

    const intercomPayload = {
      id: user.id,
      email: user.email,
      created_at: user.user_created_on,
      name: user.given_name,
      phone: user.phone,
      company: {}
    };

    if (utm_source) intercomPayload.utm_source = utm_source;
    if (utm_medium) intercomPayload.utm_medium = utm_medium;
    if (utm_campaign) intercomPayload.utm_campaign = utm_campaign;
    if (utm_content) intercomPayload.utm_content = utm_content;
    if (utm_term) intercomPayload.utm_term = utm_term;
    if (referrer) intercomPayload.referrer = referrer;
    if (ref) intercomPayload.refer_by = ref;

    setAnalyticUser(intercomPayload);

    if (company) {
      intercomPayload.company = {
        id: company.id,
        name: company.name,
        created_at: company.created_on,
        plan: company.plan,
        monthly_spend: company.spend
      };
      setAnalyticCompany(company.id, intercomPayload.company);
    }
    if (window.Intercom) window.Intercom('update', intercomPayload);

    if (window.salesmachine) {
      const salesmachinePayload = { ...intercomPayload };
      delete salesmachinePayload.company; //Delete company payload for salesmachine
      if (company) {
        salesmachinePayload.account_uid = company.id;
      }
      window.salesmachine.contact(user.id, salesmachinePayload);
      if (company) {
        const accountPayload = intercomPayload.company;
        if (accountPayload && company && window.salesmachine) window.salesmachine.account(company.id, accountPayload);
      }
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc clear user information for analytics
 */
export const clearAnlayticsUser = () => {
  try {
    currentUserId = undefined;
    currentCompanyId = undefined;
    if (isDev()) {
      console.log('clearUser');
      return;
    }
    if (analytics) analytics.setUserId(null);
    if (window.Intercom) window.Intercom('shutdown');
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc track activity in analytics
 */
export const trackAnalyticActivity = (name, params) => {
  try {
    if (isDev()) {
      console.log('trackActivity', name, params);
      return;
    }
    if (analytics) analytics.logEvent(name, params);
    if (window.Intercom) window.Intercom('trackEvent', name, params);
    if (window.salesmachine && currentUserId) {
      if (!params) params = {};
      if (currentCompanyId) params.account_uid = currentCompanyId;
      window.salesmachine.track(currentUserId, name, params);
    }
    if (window?.LO?.events?.track) {
      window.LO.events.track(name, params);
    }
    if (REACT_APP_GOOGLE_ANALYTICS_ID) {
      let category = 'sc';
      if (name && name.includes(':')) {
        category = String(name).split(':')[0];
      }
      ReactGA.event({
        category: category,
        action: name
      });
    }
    if (window.fbq) {
      window.fbq('trackCustom', name, params);
    }
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc open intercom chat
 */
export const openIntercomChat = () => {
  try {
    window.Intercom('showNewMessage');
  } catch (e) {
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc hide intercom launcher
 */
export const hideIntercomLauncher = () => {
  try {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  } catch (e) {
    console.log('ANALYTICS ERROR at hideIntercomChat', e);
  }
};

/**
 * @desc track page view
 */
export const trackPage = (params) => {
  try {
    var fnTrackPage = _.throttle(() => {
      if (isDev()) {
        console.log('trackPage', params);
        return;
      }
      if (window.salesmachine && currentUserId) {
        if (!params) params = {};
        if (currentCompanyId) params.account_uid = currentCompanyId;
        window.salesmachine.track(currentUserId, 'pageview', params);
      }
    }, 500);
    fnTrackPage();
  } catch (e) {
    console.log('ANALYTICS ERROR trackPage', e);
  }
};

const setLuckyOrangeIdentity = (user, count) => {
  if (!user || count >= 10) return;

  if (window?.LO?.visitor?.identify) {
    window.LO.visitor.identify(user.id, { email: user.email });
  } else {
    setTimeout(() => {
      setLuckyOrangeIdentity(user, (count += 1));
    }, 1000);
  }
};

const setAmplitudeUserParams = (params) => {
  try {
    if (!params) params = {};
    const platform = localStorage.getItem(PLATFORM_KEY_NAME);
    if (platform) {
      if (platform === 'darwin') params.platform = 'mac';
      else if (platform === 'win32') params.platform = 'windows';
      else params.platform = platform;
    } else if (isElectronApp()) {
      if (isMAC()) params.platform = 'mac';
      else if (isWindows()) params.platform = 'windows';
    }
  } catch (e) {
    console.log('ERROR setAmplitudeUserParams', e);
  }
  return params;
};
