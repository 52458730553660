import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import DropDown from '../../../components/DropDown/DropDown';
import { bulkUnAssign, getSelectedLooupFromTableRows } from '../../../services/lookupService';
import { customestyle } from '../BulkAssignModal/styles';
import { MainContact, TitleText, InputView, LabelTitle, BtnView } from './styles';

const BulkUnAssignModal = ({ handleCloseModal, selectedRows, onSave }) => {
  const [assign, setAssign] = useState();
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization);
  const lookupSelector = useSelector((state) => state.lookup);
  const { loading } = lookupSelector;

  const { organization } = organizationSelector;
  const { users, id: organization_id } = organization;

  const onChangeItem = useCallback((value) => {
    setAssign(value);
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const references = getSelectedLooupFromTableRows(selectedRows, organization_id);
      const payload = { references, users: assign };
      const result = await dispatch(bulkUnAssign(organization_id, payload));
      if (result) {
        if (onSave) onSave();
      }
    },
    [assign, dispatch, onSave, organization_id, selectedRows]
  );
  return (
    <>
      <MainContact onSubmit={onSubmit}>
        <TitleText>Bulk Un-Assign</TitleText>
        <InputView>
          <LabelTitle>Please select user(s)</LabelTitle>
          <DropDown
            options={users}
            labelField={'name'}
            valueField={'id'}
            isMulti
            value={assign}
            styles={customestyle}
            onChange={(value) => {
              onChangeItem(value);
            }}
            placeholder={''}
            className='basic-multi-select'
          />
        </InputView>
        <BtnView>
          <Button type='submit' title='Ok' loading={loading} />
          <Button secondary type='button' title='Cancel' onClick={handleCloseModal} />
        </BtnView>
      </MainContact>
    </>
  );
};

export default BulkUnAssignModal;
