const currentUserKey = 'user';
const CURRENT_ORGANIZATION_KEY = 'organization';

export const getAuthUser = () => {
  try {
    const user = localStorage.getItem(currentUserKey);
    if (user) return JSON.parse(user);
    else return undefined;
  } catch (error) {
    console.log(error.message);
  }
};

export const getCurrentUser = () => {
  return getLocalData(currentUserKey);
};

const setCurrentUser = (user) => {
  setLocalData(currentUserKey, user);
};

const deleteCurrentUser = () => {
  removeLocalData(currentUserKey);
};

export const getOrganization = () => {
  return getLocalData(CURRENT_ORGANIZATION_KEY);
};

const setOrganization = (org) => {
  setLocalData(CURRENT_ORGANIZATION_KEY, org);
};

const deleteOrganization = () => {
  removeLocalData(CURRENT_ORGANIZATION_KEY);
};

export const ClearAllKeys = () => {
  removeLocalData(currentUserKey);
  removeLocalData(CURRENT_ORGANIZATION_KEY);
};

const setLocalData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getLocalData = (key) => {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
  return;
};

const removeLocalData = (key) => {
  localStorage.removeItem(key);
};

const localDataService = {
  getCurrentUser,
  setCurrentUser,
  deleteCurrentUser,
  getOrganization,
  setOrganization,
  deleteOrganization,
  setLocalData,
  getLocalData,
  removeLocalData,
  ClearAllKeys
};

export default localDataService;
