import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import ReportsHeader from '../../../components/ReportsHeader/ReportsHeader';
import {
  setCallSummaryEvents,
  setCallSummaryUsers,
  updateCallSummaryEvents,
  updateCallSummaryUsers
} from '../../../actions/reportActions';
import { getDateString } from '../../../helpers/common';
import { getCallSummaryReport } from '../../../services/reportServices';
import { getRelativeHHMinSS } from '../../../global/Helper';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import { MobileReportDateFilter } from '../../../components/ReportDateFilter/MobileReportDateFilter';
import { EVENT_TYPE } from '../../../constants/constant';
import {
  PanelTop,
  PanelWrapper,
  ReportBlock,
  ReportBox,
  ReportWrapper,
  PanelBottom,
  OverviewTitle,
  Ul,
  Li,
  LiWrapper,
  LiTitle,
  UlData,
  DataLi,
  SummaryContent,
  SummaryRow,
  SummaryCol,
  SummaryColTwo,
  ReportActivityWrapper,
  ReportActivityUl,
  ReportActivityLi,
  TimelineView,
  ReportActivityHead,
  ReportActivityTime,
  Calltext,
  Left,
  Timetext,
  StatusText,
  StatusSpan,
  EventTitle,
  SummaryColInner,
  SummaryColFilter,
  SummaryColFilterUl,
  SummaryColFilterli,
  InputWrapperModal,
  TextPopup,
  Wrapper,
  MainMobileButton,
  SummaryUserColFilter,
  Right
} from './styles';

export default function CallSummary() {
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const [isEventMenu, setIsEventMenu] = useState(true);
  const [isUserMenu, setIsUserMenu] = useState(true);
  const reportSelector = useSelector((state) => state.report);
  const { callSummaryEvents, callSummaryUsers, callSummaryReport, loading } = reportSelector;
  const mobile = useMobileDevice();
  const dispatch = useDispatch();
  const history = useHistory();

  const events = useMemo(() => {
    let eventList = [];
    eventList.push({ isSelected: true, id: 'completed', name: 'Successful' });
    eventList.push({ isSelected: true, id: 'failed', name: 'Failed' });
    eventList.push({ isSelected: true, id: 'no-answer', name: 'No Answer' });
    eventList.push({ isSelected: true, id: 'incoming', name: 'Incoming' });
    return eventList;
  }, []);

  const users = useMemo(() => {
    let company = organization;
    let userList = [];
    if (company && company?.users) {
      let users = company?.users;
      users.forEach((user) => {
        userList.push({ isSelected: true, user: user });
      });
    }
    return userList;
  }, [organization]);

  useEffect(() => {
    if (events?.length > 0 && users?.length > 0) {
      dispatch(setCallSummaryEvents(events));
      dispatch(setCallSummaryUsers(users));
      return () => {
        dispatch(setCallSummaryEvents([]));
        dispatch(setCallSummaryUsers([]));
      };
    }
  }, [dispatch, events, users]);

  const selectedData = useMemo(() => {
    if (callSummaryEvents?.length > 0 && callSummaryUsers?.length > 0) {
      const selectedEvents = callSummaryEvents?.filter((event) => event?.isSelected === true);
      const userItems = callSummaryUsers?.filter((user) => user?.isSelected === true);
      let selectedUsers = [];
      userItems?.forEach((item) => {
        selectedUsers.push(item?.user);
      });
      return {
        selectedEvents: selectedEvents,
        selectedUsers: selectedUsers
      };
    }
  }, [callSummaryEvents, callSummaryUsers]);

  const GetUserById = useCallback(
    (id) => {
      if (selectedData?.selectedUsers?.length > 0) {
        let user = selectedData?.selectedUsers?.find((user) => user?.id === id);
        return user;
      }
    },
    [selectedData?.selectedUsers]
  );

  const onChange = useCallback(
    (option, fromDate, toDate) => {
      if (selectedData) {
        const fromDateString = getDateString(fromDate);
        const toDateString = getDateString(toDate);

        history.push(`/reports/call-summary?fromdate=${fromDateString}&todate=${toDateString}&option=${option}`);

        const payload = {
          events: selectedData?.selectedEvents,
          users: selectedData?.selectedUsers
        };
        dispatch(getCallSummaryReport(organization_id, fromDateString, toDateString, payload));
      }
    },
    [dispatch, history, organization_id, selectedData]
  );

  const onUpdateReportEvents = useCallback(
    (id, prop, val) => {
      dispatch(updateCallSummaryEvents({ eventId: id, propName: prop, value: val }));
    },
    [dispatch]
  );

  const onUpdateReportUsers = useCallback(
    (id, prop, val) => {
      dispatch(updateCallSummaryUsers({ userId: id, propName: prop, value: val }));
    },
    [dispatch]
  );

  const HandleEventAllMenu = useCallback(
    (action) => {
      if (action === EVENT_TYPE.EVENT) {
        setIsEventMenu(!isEventMenu);
      }
      if (action === EVENT_TYPE.USER) {
        setIsUserMenu(!isUserMenu);
      }
    },
    [isEventMenu, isUserMenu]
  );

  const GetCallStatus = (status) => {
    if (!status) return;
    if (status === 'completed') return <StatusSpan successful> successful</StatusSpan>;
    else if (status === 'failed') return <StatusSpan Failed>Failed</StatusSpan>;
    else if (status === 'no-answer') return <StatusSpan NoAnswer>No Answer</StatusSpan>;
    else if (status === 'incoming') return <StatusSpan incoming>incoming</StatusSpan>;
  };

  const renderCallIcon = useCallback((status) => {
    let iconName;
    let bgColor;
    switch (status) {
      case 'completed':
        iconName = 'icon-outgoing';
        bgColor = 'var(--mantis-icon)';
        break;
      case 'failed':
        iconName = 'icon-missed-call';
        bgColor = 'var(--callreject-icon)';
        break;
      case 'no-answer':
        iconName = 'icon-call-no-answer';
        bgColor = 'var(--dark-color)';
        break;
      case 'incoming':
        iconName = 'icon-ingoing';
        bgColor = 'var(--primary-color)';
        break;
      default:
    }
    return <SVGIcon name={iconName} fill={bgColor} size={20} />;
  }, []);

  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <MainMobileButton>
            <MobileReportDateFilter onChange={onChange} />
          </MainMobileButton>
        </MobileTitleBar>
      )}
      {mobile ? null : <ReportsHeader onChange={onChange} title='Call' iconName='icon-phone' />}
      <Wrapper mobile={mobile}>
        <ReportWrapper mobile={mobile}>
          <ReportBlock>
            <ReportBox>
              <PanelWrapper>
                <PanelTop>
                  <SVGIcon name='icon-call' fill='var(--gray-icon-color)' size={24} />
                  <OverviewTitle>Call Summary</OverviewTitle>
                </PanelTop>
                <PanelBottom mobile={mobile}>
                  <Ul mobile={mobile}>
                    <Li mobile={mobile}>
                      <LiWrapper mobile={mobile}>
                        <LiTitle mobile={mobile}>Total Call</LiTitle>
                        <UlData>
                          <DataLi mobile={mobile}>{loading ? '-' : callSummaryReport?.summary?.totalCalls}</DataLi>
                        </UlData>
                      </LiWrapper>
                    </Li>
                    <Li mobile={mobile}>
                      <LiWrapper mobile={mobile}>
                        <LiTitle mobile={mobile}>Successful Call</LiTitle>
                        <UlData>
                          <DataLi mobile={mobile}>{loading ? '-' : callSummaryReport?.summary?.success}</DataLi>
                        </UlData>
                      </LiWrapper>
                    </Li>
                    <Li mobile={mobile}>
                      <LiWrapper mobile={mobile}>
                        <LiTitle mobile={mobile}>Failed</LiTitle>
                        <UlData>
                          <DataLi mobile={mobile}>{loading ? '-' : callSummaryReport?.summary?.failed}</DataLi>
                        </UlData>
                      </LiWrapper>
                    </Li>
                    <Li mobile={mobile}>
                      <LiWrapper mobile={mobile}>
                        <LiTitle mobile={mobile}>No Answar</LiTitle>
                        <UlData>
                          <DataLi mobile={mobile}>{loading ? '-' : callSummaryReport?.summary?.notAnswered}</DataLi>
                        </UlData>
                      </LiWrapper>
                    </Li>
                  </Ul>
                  <Ul mobile={mobile}>
                    <Li mobile={mobile}>
                      <LiWrapper mobile={mobile}>
                        <LiTitle mobile={mobile}>Total Duration </LiTitle>
                        <UlData>
                          <DataLi mobile={mobile}>
                            {loading ? '-' : getRelativeHHMinSS(callSummaryReport?.summary?.totalDuration)}
                          </DataLi>
                        </UlData>
                      </LiWrapper>
                    </Li>
                    <Li mobile={mobile}>
                      <LiWrapper mobile={mobile}>
                        <LiTitle mobile={mobile}>Average Duration</LiTitle>
                        <UlData>
                          <DataLi mobile={mobile}>
                            {loading ? '-' : getRelativeHHMinSS(callSummaryReport?.summary?.avgDuration)}
                          </DataLi>
                        </UlData>
                      </LiWrapper>
                    </Li>
                  </Ul>
                </PanelBottom>
              </PanelWrapper>
            </ReportBox>
          </ReportBlock>
        </ReportWrapper>
        <SummaryContent mobile={mobile}>
          <SummaryRow mobile={mobile}>
            {!loading && callSummaryReport && callSummaryReport?.items?.length === 0 && (
              <SummaryCol>No Data Found</SummaryCol>
            )}
            {loading ? (
              <SummaryCol>Loading...</SummaryCol>
            ) : (
              <SummaryCol mobile={mobile}>
                <ReportActivityWrapper>
                  <ReportActivityUl mobile={mobile}>
                    {callSummaryReport?.items?.map((item) => {
                      return (
                        <ReportActivityLi key={item?.id} mobile={mobile}>
                          <Left>
                            <TimelineView>{renderCallIcon(item?.status)}</TimelineView>
                          </Left>
                          <Right mobile={mobile}>
                            <ReportActivityHead>
                              <Calltext>
                                {GetUserById(item?.createdBy)?.name} <span>called to</span> {item?.lookup?.name}
                              </Calltext>
                              <StatusText>
                                {item?.duration !== 0 && `${getRelativeHHMinSS(item?.duration)},`}
                                <StatusSpan>{GetCallStatus(item?.status)}</StatusSpan>
                              </StatusText>
                            </ReportActivityHead>
                            <ReportActivityTime>
                              <Timetext>
                                {item?.createdOn && moment(item?.createdOn).format('DD/MM/YYYY')}
                                {item?.createdOn && ` at ${moment(item?.createdOn).format('h:mm A')}`}
                              </Timetext>
                            </ReportActivityTime>
                          </Right>
                        </ReportActivityLi>
                      );
                    })}
                  </ReportActivityUl>
                </ReportActivityWrapper>
              </SummaryCol>
            )}
            <SummaryColTwo mobile={mobile}>
              <SummaryColInner>
                <EventTitle
                  onClick={
                    mobile
                      ? () => {
                          HandleEventAllMenu(EVENT_TYPE.EVENT);
                        }
                      : null
                  }>
                  <h4>Event</h4>
                  {mobile ? <SVGIcon name={isEventMenu ? 'icon-down-arrow' : 'icon-up-arrow'} size={24} /> : null}
                </EventTitle>
                <SummaryColFilter isEventMenu={isEventMenu} mobile={mobile}>
                  <SummaryColFilterUl>
                    {callSummaryEvents &&
                      callSummaryEvents?.length > 0 &&
                      callSummaryEvents?.map((event) => {
                        return (
                          <SummaryColFilterli key={event?.id}>
                            <InputWrapperModal>
                              <input
                                type='checkbox'
                                id={event?.id}
                                checked={event?.isSelected}
                                onChange={() => {
                                  onUpdateReportEvents(event?.id, 'isSelected', !event?.isSelected);
                                }}
                              />
                              <span className='checkmark'></span>
                              <TextPopup htmlFor={event?.id}>{event?.name}</TextPopup>
                            </InputWrapperModal>
                          </SummaryColFilterli>
                        );
                      })}
                  </SummaryColFilterUl>
                </SummaryColFilter>
                <EventTitle
                  onClick={
                    mobile
                      ? () => {
                          HandleEventAllMenu(EVENT_TYPE.USER);
                        }
                      : null
                  }>
                  <h4>User</h4>
                  {mobile ? <SVGIcon name={isUserMenu ? 'icon-down-arrow' : 'icon-up-arrow'} size={24} /> : null}
                </EventTitle>
                <SummaryUserColFilter isUserMenu={isUserMenu} mobile={mobile}>
                  <SummaryColFilterUl>
                    {callSummaryUsers &&
                      callSummaryUsers?.length > 0 &&
                      callSummaryUsers?.map((item) => {
                        return (
                          <SummaryColFilterli key={item?.user?.id}>
                            <InputWrapperModal>
                              <input
                                type='checkbox'
                                id={item?.user?.id}
                                checked={item?.isSelected}
                                onChange={() => {
                                  onUpdateReportUsers(item?.user?.id, 'isSelected', !item?.isSelected);
                                }}
                              />
                              <span className='checkmark'></span>
                              <TextPopup htmlFor={item?.user?.id}>{item?.user?.name}</TextPopup>
                            </InputWrapperModal>
                          </SummaryColFilterli>
                        );
                      })}
                  </SummaryColFilterUl>
                </SummaryUserColFilter>
              </SummaryColInner>
            </SummaryColTwo>
          </SummaryRow>
        </SummaryContent>
      </Wrapper>
    </>
  );
}
