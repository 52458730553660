import styled from 'styled-components';
import { Header4, Text1, Text2 } from '../../../../styles';
import Button from '../../../../components/atom/Button/Button';

const EmailPreviewMain = styled.div`
  padding: 16px;
  height: 100%;
  max-height: 600px;
  overflow: auto;
`;
const EmailPreviewTop = styled.div`
  display: flex;
  align-items: center;
`;
const EmailFrom = styled.div`
  display: flex;
  align-items: center;
  :first-child {
    margin-right: 20px;
  }
`;
const From = styled.span`
  ${Text2}
  color: var(--gray-color);
  margin-right: 8px;
`;
const Name = styled.span`
  ${Text2}
  color: var(--dark-color);
`;
const EmailSubject = styled.h4`
  display: block;
  margin: 8px 0px 12px 0px;
  ${Header4}
`;
const Emailpara = styled.div`
  ${Text1}
  color: var(--dark-color);
`;
const EmailPreviewBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0px 0 0px;
`;
const EmailButton = styled.div`
  display: flex;
  align-items: center;
  button:first-child {
    margin-right: 10px;
  }
`;
const EmailNavigation = styled.div`
  button i {
    margin: 0;
  }
`;
const ButtonPagination = styled(Button)`
  background-color: transparent;
  border-style: none;
  box-shadow: none;
  :hover i {
    color: var(--dark-color);
  }
  :first-child {
    margin-right: 10px;
  }
`;
const EmailWrapper = styled.div`
  height: calc(100% - 68px) !important;
  overflow: auto;
`;

export {
  EmailPreviewMain,
  EmailPreviewTop,
  EmailFrom,
  From,
  Name,
  EmailSubject,
  Emailpara,
  EmailPreviewBottom,
  EmailButton,
  EmailNavigation,
  ButtonPagination,
  EmailWrapper
};
