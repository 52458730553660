import React from 'react';
import Icon from '../../../components/Icon/Icon';
import { DatePicker, TimePicker } from '../../../components/DateTime';
import Button from '../../../components/atom/Button/Button';
import {
  MainContact,
  TitleView,
  TitleText,
  Form,
  FirstList,
  SecondList2,
  BottomView,
  LableText,
  LableView
} from './styles';

function SendSchedualModal(props) {
  const { onClose } = props;
  return (
    <MainContact>
      <TitleView>
        <Icon
          name='icon-big-left-arrow'
          fontSize='12'
          color='var(--gray-icon-color)'
          onClick={
            onClose
              ? () => {
                  onClose();
                }
              : null
          }
        />
        <TitleText>Send Schedule</TitleText>
      </TitleView>
      <Form>
        <FirstList>
          <LableView>
            <LableText>Select New Date</LableText>
          </LableView>
          <DatePicker className='DatePickerWrapper' autoOk={true} />
        </FirstList>
        <SecondList2>
          <LableView>
            <LableText>Select New Time</LableText>
          </LableView>
          <TimePicker className='TimePickerWrapper' />
        </SecondList2>
        <BottomView>
          <Button width={'100%'} type='button' title='Send' size='small' />
        </BottomView>
      </Form>
    </MainContact>
  );
}

export default SendSchedualModal;
