import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LeadManagment from '../../assets/images/svg/lead_managment.svg';
import CallSMS from '../../assets/images/svg/call_&_sms.svg';
import EasyCustomize from '../../assets/images/svg/easy_customization.svg';
import ActiveGoals from '../../assets/images/svg/activities_&_goals.svg';
import SalesReporting from '../../assets/images/svg/sales_reporting.svg';
import EmaiSync from '../../assets/images/svg/email_sync.svg';
import DealPipeLine from '../../assets/images/svg/deal_pipeline.svg';
import Integration from '../../assets/images/svg/integration.svg';
import SVGIcon from '../../assets/svg/SVGIcon';
import { setIsAppUpgradeToPro } from '../../actions/appActions';
import { getCurrentCompanyPlanType } from '../../services/organizationService';
import { getAppHostName } from '../../helpers/common';
import { openIntercomChat, trackAnalyticActivity } from '../../services/analyticsService';
import { trackSupportAnalyticActivity } from '../../services/dashboardService';
import Modal from './../../components/Modal';
import {
  MainContact,
  BlueWrapper,
  IconView,
  HeadView,
  TitleText,
  CaptionText,
  UpgradeButton,
  ContainWrapper,
  BtnView,
  ProfileImg,
  Slider,
  HeadList,
  ImgView,
  CaptionImg,
  CaptionView,
  LBtn,
  RBtn,
  RequestTrialButton,
  SliderLeftChangeIcon,
  SliderRightChangeIcon,
  ModalStyle
} from './styles';

const UpgradeSubscription = ({ history }) => {
  const dispatch = useDispatch();
  const appSelector = useSelector((state) => state.app);
  const isUpgradeToPro = appSelector?.isUpgradeToPro;
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;

  useEffect(() => {
    if (isUpgradeToPro) {
      trackAnalyticActivity('sales: view upgrade popup');
    }
  }, [isUpgradeToPro]);

  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);

  const planType = useMemo(() => {
    if (organization?.id) {
      return getCurrentCompanyPlanType();
    }
  }, [organization?.id]);

  const isPlanExpired = useMemo(() => {
    if (planType === 'expired') {
      setTimeout(() => {
        dispatch(setIsAppUpgradeToPro(true));
      }, 2000);
      return true;
    }
    return false;
  }, [planType, dispatch]);

  const onCloseUpgradeToPro = useCallback(() => {
    dispatch(setIsAppUpgradeToPro(false));
  }, [dispatch]);

  const onNavigateToUpgrade = useCallback(() => {
    dispatch(setIsAppUpgradeToPro(false));
    history.push('/upgrade');
  }, [dispatch, history]);

  const onRequestTrialExtend = useCallback(() => {
    openIntercomChat();
    onCloseUpgradeToPro();
    trackSupportAnalyticActivity('extend trial clicked');
  }, [onCloseUpgradeToPro]);

  if (!isPlanExpired) return null;

  return (
    <Modal open={isUpgradeToPro} onClose={onCloseUpgradeToPro} BackdropProps={{ invisible: true }} style={ModalStyle}>
      <MainContact>
        <BlueWrapper>
          <HeadView>
            <SVGIcon name='icon-security-lock' size={20} fill='var(--white-color)'></SVGIcon>
            <TitleText>Upgrade to Premium</TitleText>
          </HeadView>
          <CaptionText>{appName} CRM is built to thrive your sales. Maximise your sales up to 2x.</CaptionText>
          <IconView onClick={onCloseUpgradeToPro}>
            <SVGIcon name='icon-close' size={20} fill='var(--white-color)'></SVGIcon>
          </IconView>
          <BtnView>
            <UpgradeButton primary title='Upgrade Now' type='button' onClick={onNavigateToUpgrade} />
            <RequestTrialButton primary title='Request trial extend!' type='button' onClick={onRequestTrialExtend} />
          </BtnView>
        </BlueWrapper>
        <ContainWrapper>
          <Carousel
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            showIndicators={false}
            showStatus={false}
            centerMode={true}
            centerSlidePercentage={55}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <LBtn>
                  <SliderLeftChangeIcon onClick={onClickHandler}>
                    <SVGIcon name='icon-left-arrow' size={32} fill='var(--gray-icon-color)'></SVGIcon>
                  </SliderLeftChangeIcon>
                </LBtn>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <RBtn>
                  <SliderRightChangeIcon onClick={onClickHandler}>
                    <SVGIcon name='icon-right-arrow' size={32} fill='var(--gray-icon-color)'></SVGIcon>
                  </SliderRightChangeIcon>
                </RBtn>
              )
            }>
            <Slider>
              <HeadList>Automate your lead collection.</HeadList>
              <ImgView>
                <ProfileImg src={LeadManagment} />
              </ImgView>
              <CaptionView>
                <CaptionImg>
                  Manage, create and send invoices faster using salescamp. Follow up on unpaid bills easily.
                </CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Email Sync</HeadList>
              <ImgView>
                <ProfileImg src={EmaiSync} />
              </ImgView>
              <CaptionView>
                <CaptionImg>Communicate with customers directly from CRM with two-way email sync.</CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Easy Customization</HeadList>
              <ImgView>
                <ProfileImg src={EasyCustomize} />
              </ImgView>
              <CaptionView>
                <CaptionImg>Quickly build your CRM as per your business need with customization.</CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Activities & Goals</HeadList>
              <ImgView>
                <ProfileImg src={ActiveGoals} />
              </ImgView>
              <CaptionView>
                <CaptionImg>Easily reach your goals by tracking activities with leads.</CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Call & SMS</HeadList>
              <ImgView>
                <ProfileImg src={CallSMS} />
              </ImgView>
              <CaptionView>
                <CaptionImg>Reach the customer quickly using the inbuilt Call & SMS feature.</CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Deal Pipeline</HeadList>
              <ImgView>
                <ProfileImg src={DealPipeLine} />
              </ImgView>
              <CaptionView>
                <CaptionImg>Win your deals by identifying potential values with the deal pipeline.</CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Sales Reporting</HeadList>
              <ImgView>
                <ProfileImg src={SalesReporting} />
              </ImgView>
              <CaptionView>
                <CaptionImg>Make instant valuable decisions with Salescamp Sales reporting.</CaptionImg>
              </CaptionView>
            </Slider>
            <Slider>
              <HeadList>Integration</HeadList>
              <ImgView>
                <ProfileImg src={Integration} />
              </ImgView>
              <CaptionView>
                <CaptionImg>
                  Contacts allow you to manage and centralize your relationships between companies.
                </CaptionImg>
              </CaptionView>
            </Slider>
          </Carousel>
        </ContainWrapper>
      </MainContact>
    </Modal>
  );
};

export default UpgradeSubscription;
