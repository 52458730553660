import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Text1, Text2 } from '../../../styles';
import Input from '../../../components/Input/Input';
import DropDown from '../../../components/DropDown/DropDown';
import { LOOKUP_FIELD_EDIT_VIEW_FROM } from '../../../constants/constant';

const InputBox = styled(Input)`
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 8px 12px;
  ${Text1}
  background-color: var(--white-color);
  //Detail
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD &&
    css`
      padding: 9px 76px 9px 8px;
    `}
  // Detail Add
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD &&
    css`
      padding: 9px 8px 9px 8px;
    `}
`;
const DropDownDetail = styled(DropDown)``;

const DateTimeWrapper = styled.div`
  width: 100%;
  background-color: var(--white-color);
  .DatePickerWrapper {
    width: 100%;
    .MuiInputBase-input {
      &:focus {
        border: 2px solid var(--primary-color);
      }
    }
  }
  .TimePickerWrapper {
    width: 100%;
    input.MuiInputBase-input.MuiInput-input {
      border: 0;
      padding: 7px 12px;
      :focus {
        border: 0;
        margin: 0;
      }
    }
  }
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE &&
    css`
      .DatePickerWrapper {
        input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
          border: 1px solid var(--gray-border-color);
          :focus {
            border: 2px solid var(--primary-color);
            margin: -1px;
          }
        }
      }
      .TimePickerWrapper {
        input.MuiInputBase-input.MuiInput-input {
          border: 1px solid var(--gray-border-color);
          :focus {
            border: 2px solid var(--primary-color);
            margin: -1px;
          }
        }
      }
    `}
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    border: 0px;
    padding: 9px 12px;
    :focus {
      border: 0px;
      margin: 0;
    }
  }
`;

const TitleText = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 4px;
  display: block;
`;

const InputCheckBoxView = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  div {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--white-color);
    border: 1px solid #dcdcdc;
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid #999;
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD &&
    css`
      height: 38px;
    `}
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST &&
    css`
      padding: 8px 0;
    `}
`;
const LabelTitle = styled.span`
  ${Text1}
  color: var(--dark-color);
  padding: 0px 8px 0px 12px;
  cursor: pointer;
`;
const Textarea = styled.textarea`
  border: 0;
  border-radius: 4px;
  width: 100%;
  resize: none;
  outline: none;
  padding: 9px 12px;
  font-family: var(--primary-font-stack);
  ${Text1}

  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE &&
    css`
      border: 1px solid var(--gray-border-color);
      &:focus {
        margin: -1px;
        border: 2px solid var(--primary-color);
      }
    `}
`;
const Title = styled.span`
  ${Text1}
  color: var(--dark-color);
  display: block;
  margin-bottom: 4px;
`;

const CloseIconView = styled.button`
  border: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: transparent;
  border-left: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.border &&
    css`
      border-left: 1px solid var(--gray-border-color);
    `};
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST &&
    css`
      width: 26px;
      height: 25px;
    `}
`;
const CloseIconbtn = styled.button`
  padding: 6.75px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: var(--modal-shadow);
  border-radius: 3px;
  margin-left: 8px;
  cursor: pointer;
  flex: 0 1 34px;
`;

const FormView = styled.form`
  display: flex;
  align-items: center;
  top: 0px;
  right: 0;
  width: calc(100% + 71px);
  padding: 0 69px 0 0;
  background-color: var(--white-color);
  position: absolute;
  z-index: 9;
  border: 2px solid var(--primary-color);
  box-shadow: 1px 2px 24px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  ${(props) =>
    props.mobile
      ? css`
          left: -5px;
        `
      : css`
          left: -1px;
        `}
  input {
    border: 0;
    :focus {
      border: 0;
      margin: 0;
      box-shadow: none;
    }
  }
  ${(props) =>
    props.formButton &&
    css`
      height: 42px;
      width: 100%;
      .input {
        height: 42px;
      }
    `}
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD &&
    css`
      width: calc(100% + 30px);
      ::before {
        content: '';
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
        position: absolute;
        bottom: 106%;
        left: 0;
        right: 0;
        height: 30px;
        z-index: 1;
        width: 95%;
      }
      ::after {
        content: '';
        background: linear-gradient(to bottom, #fff 0, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 106%;
        left: 0;
        right: 0;
        height: 35px;
        z-index: 1;
        width: 95%;
      }
      ${(props) =>
        props.mobile
          ? css`
              left: -10px;
            `
          : css`
              left: -1px;
            `}
    `}
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD &&
    css`
      width: calc(100% + 0px);
      position: relative;
      top: 0;
      min-height: 38px;
      z-index: 1;
      left: 0.1px;
      border: 0;
      padding: 0;
      ::after {
        content: '';
        background: linear-gradient(to bottom, #fff 0, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 30px;
        z-index: 1;
        margin-top: 1px;
      }
    `}
    ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD &&
    props.border &&
    css`
      border: 1px solid var(--gray-border-color);
      border-radius: 4px;
      background-color: var(--white-color);
    `}
    ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST &&
    css`
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 3px var(--focus-color), 1px 1px 2px 0 rgb(0 0 0 / 5%);
      border-radius: 0;
      width: calc(100% + 64px);
      padding: 0 68px 0 0;
      input {
        padding: 8.5px 12px;
      }
    `}
`;

//viewType - 1, List, 2 = details, 3 = details - Add new field
const InputList = styled.div`
  ${(props) =>
    props.viewType === 2 &&
    css`
      position: absolute;
    `}
  top: 3px;
  right: 0;
  left: 0;
  width: 105%;
  position: absolute;
  z-index: 9;

  ${(props) =>
    !props.formButton &&
    css`
      top: -2px;
      right: 0;
      left: -3px;
      height: 42px;
    `}

  ${(props) =>
    props.formButton &&
    css`
      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(
          181.23deg,
          rgba(255, 255, 255, 0) 1.04%,
          #ffffff 28.3%,
          #ffffff 75.48%,
          rgba(255, 255, 255, 0) 98.95%
        );
        top: -32px;
        bottom: 0;
        left: 0;
        right: 10%;
        height: 100px;
        z-index: -1;
      }
    `}
`;

const RightIconView = styled.button`
  border: 0;
  ${(props) =>
    props.viewType !== LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD &&
    css`
      border-left: 1px solid var(--gray-border-color);
    `}
  width: 34px;
  height: 34px;
  padding: 0;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST &&
    css`
      width: 26px;
      height: 25px;
      border-left: none;
    `}
`;

const SpanTitle = styled.span`
  ${Text2};
  color: var(--dark-color);
  position: absolute;
  padding: 0 5px;
  top: -9px;
  left: 8px;
  font-size: 12px;
  z-index: 10;
  background: var(--white-color);
`;

const IconA = styled.a`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -30px;
  opacity: 0;
  z-index: -1;
  top: 0;
  transition: all 0.3s ease;
  cursor: pointer;
`;

const Item = styled.div`
  margin: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 37px;
  :hover ${IconA} {
    right: -22px;
    opacity: 1;
    z-index: 1;
  }
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD &&
    css`
      height: 30px;
    `}
`;

const LSpan = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  white-space: nowrap;
`;

const RText = styled.p`
  margin: 0 0 0 8px;
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;
const Select = styled.div`
  width: 100%;
  .basic-multi-select {
    width: 100%;
  }
`;

const customestyle = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--dark-color)',
    margin: '4px 6px',
    padding: '6px 10px',
    letterSpacing: '0.2px',
    borderRadius: '5px',
    width: 'auto',
    '&:active': {
      background: ' var(--popup-color-hover)'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    color: '#000'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 8px',
    flexWrap: 'nowrap'
  }),
  control: (base, { isFocused, selectProps: { viewType } }) => ({
    ...base,
    boxShadow: 'none',
    minHeight: '36px',
    borderRadius: '4px',
    borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5',
    borderWidth: viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE ? (isFocused ? '2px' : '1px') : '0px',
    margin: viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE ? (isFocused ? '0px -1px' : '0px') : '0px',
    ':hover': {
      borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    boxShadow: '0px 3px 10px var(--menu-modal)',
    border: '1px solid var(--gray-border-color)',
    margin: '4px 0px'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px',
    padding: '3px 0px'
  }),
  multiValue: (base) => ({
    ...base,
    background: 'var(--popup-color-hover)',
    border: '1px solid var(--gray-border-color)',
    borderRadius: '4px',
    height: 28,
    margin: '3px',
    minWidth: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative'
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '0 8px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--gray-color)'
  }),
  multiValueRemove: (base) => ({
    ...base,
    opacity: '0',
    position: 'absolute',
    cursor: 'pointer',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ':hover': {
      background: 'var(--linear-gradient-hover);',
      right: '0',
      top: '1px',
      bottom: '0',
      width: '100%',
      textAlign: 'right',
      color: 'rgba(46, 62, 70, 0.5)',
      borderRadius: '4px',
      paddingRight: '4px',
      opacity: '1',
      zIndex: '1'
    }
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px'
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px'
  })
};
const AddView = styled.div`
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  top: 12px;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          width: calc(100% - 30px);
        `
      : css`
          width: 236px;
        `}
`;

const mulcustomestyle = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--dark-color)',
    margin: '4px 6px',
    padding: '6px 10px',
    letterSpacing: '0.2px',
    borderRadius: '5px',
    width: 'auto',
    '&:active': {
      background: ' var(--popup-color-hover)'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    color: '#000'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 8px',
    flexWrap: 'nowrap'
  }),
  control: (base, { isFocused, selectProps: { viewType } }) => ({
    ...base,
    boxShadow: 'none',
    minHeight: '36px',
    borderRadius: '4px',
    borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5',
    borderWidth: viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE ? (isFocused ? '2px' : '1px') : '0px',
    margin: viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LOOKUP_ADD_UPDATE ? (isFocused ? '0px -1px' : '0px') : '0px',
    ':hover': {
      borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    boxShadow: '0px 3px 10px var(--menu-modal)',
    border: '1px solid var(--gray-border-color)',
    margin: '4px 0px'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px',
    padding: '3px 0px'
  }),
  multiValue: (base) => ({
    ...base,
    background: 'var(--popup-color-hover)',
    border: '1px solid var(--gray-border-color)',
    borderRadius: '3px',
    height: 26,
    margin: '3px',
    minWidth: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative'
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '0 8px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--gray-color)'
  }),
  multiValueRemove: (base) => ({
    ...base,
    opacity: '0',
    position: 'absolute',
    cursor: 'pointer',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ':hover': {
      background: 'var(--linear-gradient-hover);',
      right: '0',
      top: '1px',
      bottom: '0',
      width: '100%',
      textAlign: 'right',
      color: 'rgba(46, 62, 70, 0.5)',
      borderRadius: '4px',
      paddingRight: '4px',
      opacity: '1',
      zIndex: '1'
    }
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px'
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px'
  })
};
const EditList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  border: 1px solid var(--gray-border-color);
  border-top: 0;
  border-radius: 6px;
  background-color: var(--white-color);
  .css-2b097c-container {
    flex: 1 1 auto;
    position: relative;
    z-index: 3;
  }
  .css-15lkusn-menu {
    top: 70%;
  }
  &.focus {
    border: 2px solid var(--primary-color);
    margin: -1px;
  }
  &.focus form::after {
    top: 102%;
  }
`;
const FirstSelect = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 2;
  min-height: 34px;
`;
const SecondSelect = styled.div`
  width: 100%;
  .css-120uv05-control {
    border: 0;
    min-height: auto;
    margin: 0;
  }
  .css-hhpvm7-control {
    min-height: auto;
    border: 0;
    border-color: transparent;
  }
  .css-hhpvm7-control:hover {
    border-color: 'none';
  }
  input {
    border: 0;
    width: 99%;
    &:focus {
      border: 0;
      margin: 0px;
    }
  }
  textarea {
    border: 0;
    &:focus {
      border: 0;
    }
  }
  .TimePickerWrapper {
    width: 100%;
    input {
      border: 0 !important;
      padding: 0 10px !important;
      &:focus {
        border: 0;
        margin: 0px;
      }
    }
  }
  .MuiInputBase-input:focus {
    border: 0 !important;
  }
  .DatePickerWrapper {
    width: 99%;
    input {
      border: 0 !important;
      &:focus {
        border: 0;
        margin: 0px;
      }
    }
  }
`;
const BtnViewadd = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  ${(props) =>
    props.isdescription &&
    css`
      bottom: 6px;
    `}
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD
      ? css`
          left: 50%;
          bottom: -35px;
          z-index: 2;
          background: var(--white-color);
          border: 1px solid var(--gray-border-color);
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          transform: translateX(-50%);
        `
      : css`
          right: 0;
        `}
  ${(props) =>
    props.viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST &&
    css`
      right: 6px;
      border: 1px solid var(--gray-border-color);
      box-shadow: 0px 1px 2px #f0f0f0;
      border-radius: 3px;
    `}
`;
const CustomeEdit = styled.div`
  margin: 0px;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 18px;
  .TimePickerWrapper {
    width: 100%;
  }
`;
//dropdown
const customstyleadd = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--dark-color)'
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    color: '#000'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 8px'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    // This line disable the blue border
    boxShadow: 'none',
    minHeight: '34px',
    borderRadius: '4px 4px 0px 0px',
    borderColor: 'var(--gray-border-color)',
    borderWidth: '1px',
    borderRight: 0,
    borderLeft: 0,
    ':hover': {
      borderColor: 'var(--gray-border-color)'
    }
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px'
  }),

  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px'
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500'
  })
};

const LinkField = styled(NavLink)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const UrlField = styled(NavLink)`
  margin: 0 0 0 8px;
  font-style: normal;
  ${Text1};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--primary-color);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
const ReferenceLink = styled.li`
  display: flex;
  margin-left: 0;
  padding-left: 0;
`;
const Actions = styled.span`
  ${Text1};
  margin: 0;
  border-radius: 4px;
  padding: 1px 2px;
  width: fit-content;
  cursor: pointer;
  :hover {
    background: var(--popup-color-hover);
    color: var(--primary-color);
  }
`;

export {
  Actions,
  ReferenceLink,
  InputBox,
  TitleText,
  DateTimeWrapper,
  InputCheckBoxView,
  InputWrapper,
  LabelTitle,
  Textarea,
  Title,
  FormView,
  CloseIconView,
  InputList,
  RightIconView,
  SpanTitle,
  IconA,
  Item,
  LSpan,
  RText,
  Select,
  Overlay,
  customestyle,
  CloseIconbtn,
  AddView,
  FirstSelect,
  SecondSelect,
  BtnViewadd,
  CustomeEdit,
  DropDownDetail,
  EditList,
  LinkField,
  UrlField,
  customstyleadd,
  mulcustomestyle
};
