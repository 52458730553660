import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/Environment';
import { deleteGoalItem, setGoalItem, setGoalList, setGoalLoader } from '../actions/goalActions';

/**
 * @desc Goal - Get Goals
 * @param {*} organization_id
 */
export const getGoalList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setGoalLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Goals/${organization_id}`);
    const { data } = response;
    if (data) {
      dispatch(setGoalList(data));
      return true;
    }
  } catch (e) {
    dispatchGoalError(getAPIErrorReason(e) || 'Unable to get goals please try again', dispatch);
    return false;
  } finally {
    dispatch(setGoalLoader(false));
  }
};

/**
 * @desc Goal - Create Goal
 * @param {*} organization_id, payload
 */
export const createGoal = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setGoalLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Goals/${organization_id}/Create`, payload);
    const { data, message } = response;
    if (data) {
      dispatchGoalSuccess(message, dispatch);
      await dispatch(getGoalList(organization_id));
      return true;
    }
  } catch (e) {
    dispatchGoalError(getAPIErrorReason(e) || 'Unable to create goal please try again', dispatch);
    return false;
  } finally {
    dispatch(setGoalLoader(false));
  }
};

/**
 * @desc Goal - Update Goal
 * @param {*} organization_id,goal_id,payload
 */
export const updateGoal = (organization_id, goal_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setGoalLoader(true));
    const response = await axios.put(`${REACT_APP_APIURL}/Goals/${organization_id}/Update/${goal_id}`, payload);
    const { data, message } = response;
    if (data) {
      dispatchGoalSuccess(message, dispatch);
      await dispatch(getGoalList(organization_id));
      return true;
    }
  } catch (e) {
    dispatchGoalError(getAPIErrorReason(e) || 'Unable to update goal please try again', dispatch);
    return false;
  } finally {
    dispatch(setGoalLoader(false));
  }
};

/**
 * @desc Goal - Get Goal Item
 * @param {*} organization_id,goal_id
 */
export const getGoalItem = (organization_id, goal_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setGoalLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Goals/${organization_id}/item/${goal_id}`);
    const { data } = response;
    if (data) {
      dispatch(setGoalItem(data));
      return data;
    }
  } catch (e) {
    dispatchGoalError(getAPIErrorReason(e) || 'Unable to get goal Item please try again', dispatch);
    return false;
  } finally {
    dispatch(setGoalLoader(false));
  }
};

/**
 * @desc Goal - Delete Goal
 * @param {*} organization_id
 */
export const deleteGoal = (organization_id, goal_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setGoalLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/Goals/${organization_id}/item/${goal_id}`);
    const { data } = response;
    if (data) {
      dispatch(deleteGoalItem({ id: goal_id }));
      dispatchGoalSuccess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchGoalError(getAPIErrorReason(e) || 'Unable to delete goal please try again', dispatch);
    return false;
  } finally {
    dispatch(setGoalLoader(false));
  }
};

function dispatchGoalError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchGoalSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
