import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Menu } from '../../components/Menu';
import { getEmailItem, setForwardMailItem, setReplyMailItem, updateEmailStatus } from '../../services/emailService';
import Avtar from '../../components/Avtar/Avtar';
import { LOOKUP_TABLE_TYPE, SEND_EMAIL_TYPE, TIME_FORMAT, TOOLTIP_DATE_FORMAT } from '../../constants/constant';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { hasUserAccessAndPermission } from '../../services/appService';
import { trackAnalyticActivity } from '../../services/analyticsService';
import NavigationText from '../../components/NavigationText/NavigationText';
import { REACT_APP_APIURL } from '../../global/Environment';
import { hasProduction, isEmpty } from '../../helpers/common';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import {
  RightList,
  TopHeader,
  Ul,
  Li,
  DetailList,
  TopTitle,
  LeftTitle,
  Rightbtn,
  Ul2,
  Li2,
  Alist,
  // MessageBox,
  // BoxList,
  RightTextList,
  FirstTextView,
  LeSpan,
  RiSpan,
  SeondTextView,
  CaptionList,
  ReadBox,
  BoxList2,
  RView,
  // LBtnText,
  // BtnEye,
  LastIcon,
  MessageDetail,
  MessageInbox,
  // LstIconView,
  LastBtnView,
  PopupView,
  ViewList,
  ShowText,
  ShowTime,
  EmailDetail,
  EmailItem,
  EmailName,
  EmailListItem,
  IconButton,
  MobileDetailTop
} from './styles';
import IframeView from './IframeView';
import SmartPreview from './IframeView/preview-javascript';
import AttachmentList from './AttachmentList';

const EmailDetails = ({ onDeleteEmail, onClickStar, onClose, openNewEmailModal }) => {
  const params = useParams();
  const threadId = params?.threadId;
  const history = useHistory();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const organizationSelector = useSelector((state) => state.organization);
  const emailSelector = useSelector((state) => state.email);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;
  const { organization } = organizationSelector;
  const { id: organization_id, users } = organization;
  const { emailDetail } = emailSelector;
  const [menuAnchorEl, setmenuAnchorEl] = useState(null);
  const [isOpenViewMenu, setViewMenu] = useState(false);
  const mobile = useMobileDevice();
  const trackEmailThreadAnalyticActivity = useCallback((count) => {
    try {
      trackAnalyticActivity('email: select thread', { count: count });
    } catch (e) {
      console.log('track email: select thread Error', e);
    }
  }, []);

  const loadData = useCallback(async () => {
    const result = await dispatch(getEmailItem(organization_id, threadId));
    if (result) {
      trackEmailThreadAnalyticActivity(result?.messages?.length);
      if (result?.unread) {
        setTimeout(() => {
          const obj = {
            value: false
          };
          dispatch(updateEmailStatus(organization_id, [threadId], obj));
        }, 1000);
      }
    }
  }, [dispatch, organization_id, threadId, trackEmailThreadAnalyticActivity]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const userAccess = useMemo(() => {
    const permissionDeleteMail = dispatch(hasUserAccessAndPermission('email', 'permissionDelete'));
    return {
      permissionDeleteMail: permissionDeleteMail
    };
  }, [dispatch]);

  // const openViewModal = (e) => {
  //   setmenuAnchorEl(e.currentTarget);
  //   setViewMenu(true);
  // };

  const closeViewModal = () => {
    setViewMenu(false);
    setmenuAnchorEl(null);
  };

  const onHeaderClick = (e, index) => {
    setTimeout(() => {
      SmartPreview.resizeIframe(index);
    }, 10);
  };

  const onClickEmailName = useCallback(
    (item) => {
      if (item?.from?.lookup) {
        const tableId = item?.from?.lookup?.tableId;
        const table = lookupTableList.find((x) => x.id === tableId);
        if (table) {
          const type = LOOKUP_TABLE_TYPE[table?.type];
          history.push(`/${type}/${table?.name}/view/${item?.from?.lookup?.id}/overview`);
        }
      }
    },
    [lookupTableList, history]
  );

  const onClickEmailItemName = useCallback(
    (item) => {
      if (item?.lookup) {
        const tableId = item?.lookup?.tableId;
        const table = lookupTableList.find((x) => x.id === tableId);
        if (table) {
          const type = LOOKUP_TABLE_TYPE[table?.type];
          history.push(`/${type}/${table?.name}/view/${item?.lookup?.id}/overview`);
        }
      }
    },
    [lookupTableList, history]
  );

  const onClickArchive = useCallback(async () => {
    if (onDeleteEmail) onDeleteEmail(threadId);
  }, [onDeleteEmail, threadId]);

  const openSendEmailModal = useCallback(
    (type) => {
      if (openNewEmailModal) openNewEmailModal(type);
      if (type === SEND_EMAIL_TYPE.REPLY) {
        dispatch(setReplyMailItem(emailDetail));
      }
      if (type === SEND_EMAIL_TYPE.FORWARD) {
        dispatch(setForwardMailItem(emailDetail));
      }
    },
    [dispatch, emailDetail, openNewEmailModal]
  );

  const onClickStarAction = useCallback(
    (action) => {
      if (onClickStar) onClickStar(action);
    },
    [onClickStar]
  );

  const getFormattedName = useCallback(
    (data) => {
      const length = data?.length;
      return data?.map((x, index) => {
        const email = x?.lookup ? x?.lookup?.email : x?.email;
        return (
          <EmailListItem key={`emailData_${x?.id}_${index}`}>
            <EmailName>
              {x?.lookup ? (
                <NavigationText
                  onClick={() => {
                    onClickEmailItemName(x);
                  }}
                  textColor={'var(--dark-color)'}
                  text={x?.lookup?.name}
                  customStyle={'font-style: normal;margin: 0 0 0 -4px;'}
                />
              ) : (
                x?.name
              )}
            </EmailName>
            <EmailName>{`< ${email} >`}</EmailName>
            {length - 1 !== index ? ',' : null}
          </EmailListItem>
        );
      });
    },
    [onClickEmailItemName]
  );

  const getFormattedSenderName = useCallback(
    (item) => {
      let name;
      if (item?.sc_username) {
        name = item?.sc_username;
      } else if (item?.sc_userId) {
        const user = users?.find((x) => x?.id === item?.sc_userId);
        if (user) {
          name = user?.name;
        }
      }
      return name;
    },
    [users]
  );

  const attachmentBaseUrl = useMemo(() => {
    const token = authSelector?.currentUser?.access_token;
    // eslint-disable-next-line max-len
    return `${REACT_APP_APIURL}/emails/${organization_id}/files/${emailDetail?.account?.id}/download?access_token=${token}`;
  }, [authSelector?.currentUser?.access_token, emailDetail?.account?.id, organization_id]);

  if (!emailDetail) return null;
  let nextToExpandedIndex = emailDetail?.messages?.length;
  return (
    <>
      <Accordion allowMultipleExpanded={false} preExpanded={[emailDetail?.lastMail?.id]}>
        <RightList mobile={mobile}>
          <DetailList mobile={mobile}>
            <TopTitle>
              {mobile && (
                <MobileTitleBar hasNavBar={false} hasTitle={false}>
                  <MobileDetailTop>
                    <Ul>
                      {userAccess?.permissionDeleteMail && (
                        <Li
                          title={'Archive'}
                          onClick={() => {
                            ConfirmDialog({
                              iconName: 'icon-delete',
                              closeIcon: 'icon-close',
                              title: 'Delete',
                              message: `Are you sure want to delete this Email?`,
                              onOk: () => {
                                onClickArchive();
                              }
                            });
                          }}>
                          <SVGIcon
                            name='icon-essential'
                            size={mobile ? 24 : 18}
                            fill={mobile ? 'var(--dark-color)' : 'var(--gray-icon-color)'}
                            strokeWidth={1.5}
                          />
                        </Li>
                      )}
                      <Li
                        title={'Star'}
                        onClick={() => {
                          onClickStarAction(emailDetail?.starred ? false : true);
                        }}>
                        <SVGIcon
                          name={emailDetail?.starred ? 'icon-fill-star' : 'icon-star'}
                          size={mobile ? 24 : 18}
                          strokeWidth={1.5}
                          fill={
                            emailDetail?.starred
                              ? 'var(--star-bg)'
                              : mobile
                              ? 'var(--dark-color)'
                              : 'var(--gray-icon-color)'
                          }
                        />
                      </Li>
                      <Li className={hasProduction() ? 'inDevelopment' : ''}>
                        <SVGIcon
                          name='icon-more'
                          size={mobile ? 24 : 18}
                          fill={mobile ? 'var(--dark-color)' : 'var(--gray-icon-color)'}
                          strokeWidth={2}
                        />
                      </Li>
                    </Ul>
                  </MobileDetailTop>
                </MobileTitleBar>
              )}
              <Rightbtn mobile={mobile}>
                <LeftTitle mobile={mobile}>{emailDetail?.subject}</LeftTitle>
                {mobile ? null : (
                  <Ul2 mobile={mobile}>
                    {emailDetail?.labels?.map((item) => {
                      return (
                        <Li2 key={item?.id} mobile={mobile}>
                          <Alist>{item?.display_name}</Alist>
                        </Li2>
                      );
                    })}
                  </Ul2>
                )}
              </Rightbtn>
              {mobile ? null : (
                <TopHeader>
                  <Ul mobile={mobile}>
                    {userAccess?.permissionDeleteMail && (
                      <Li
                        title={'Archive'}
                        onClick={() => {
                          ConfirmDialog({
                            iconName: 'icon-delete',
                            closeIcon: 'icon-close',
                            title: 'Delete',
                            message: `Are you sure want to delete this Email?`,
                            onOk: () => {
                              onClickArchive();
                            }
                          });
                        }}>
                        <SVGIcon name='icon-essential' size={18} fill='var(--gray-icon-color)' strokeWidth={2} />
                      </Li>
                    )}
                    <Li
                      title={'Star'}
                      onClick={() => {
                        onClickStarAction(emailDetail?.starred ? false : true);
                      }}>
                      <SVGIcon
                        name={emailDetail?.starred ? 'icon-fill-star' : 'icon-star'}
                        size={18}
                        strokeWidth={2}
                        fill={emailDetail?.starred ? 'var(--star-bg)' : 'var(--gray-icon-color)'}
                      />
                    </Li>
                    <Li className={hasProduction() ? 'inDevelopment' : ''}>
                      <SVGIcon name='icon-more' size={18} fill='var(--gray-icon-color)' strokeWidth={2} />
                    </Li>
                  </Ul>
                </TopHeader>
              )}
            </TopTitle>

            {emailDetail?.messages?.map((item, index) => {
              const lookupName = item.from?.lookup?.name;
              const senderName = getFormattedSenderName(item);
              const to = getFormattedName(item?.to);
              const cc = getFormattedName(item?.cc);
              const bcc = getFormattedName(item?.bcc);
              return (
                <AccordionItem
                  key={item?.id}
                  uuid={item?.id}
                  className='boxlist'
                  onClick={(e) => {
                    onHeaderClick(e, index);
                  }}>
                  <AccordionItemHeading>
                    <AccordionItemButton className='acbtn'>
                      <AccordionItemState>
                        {({ expanded, ...rest }) => {
                          if (expanded) nextToExpandedIndex = index + 1;
                          return (
                            <ReadBox
                              expand={expanded}
                              mobile={mobile}
                              index={index}
                              hasTopStyle={nextToExpandedIndex === index}
                              isLast={index === emailDetail?.messages?.length - 1}>
                              <BoxList2>
                                <Avtar
                                  name={senderName ? senderName : lookupName || item.from?.name}
                                  size={mobile ? 42 : 38}
                                  backgroundColor={'white'}
                                  borderColor={'var(--gray-border-color)'}
                                  textColor={'var(--gray-color)'}
                                />
                                <RightTextList mobile={mobile}>
                                  <FirstTextView>
                                    <LeSpan
                                      mobile={mobile}
                                      expand={expanded}
                                      onClick={
                                        !senderName && item?.from?.lookup
                                          ? () => {
                                              onClickEmailName(item);
                                            }
                                          : null
                                      }>
                                      {senderName ? (
                                        senderName
                                      ) : item?.from?.lookup ? (
                                        <NavigationText
                                          textColor={'var(--dark-color)'}
                                          text={item?.from?.lookup?.name}
                                          customStyle={'font-style: normal;margin: 0 0 0 -4px;'}
                                        />
                                      ) : (
                                        item?.from?.name
                                      )}
                                    </LeSpan>
                                    <RView>
                                      {/* <BtnEye onClick={openViewModal}>
                                        <Icon name='feather-eye' fontSize='14' color='var(--gray-icon-color)' />
                                        <LBtnText>2 Opens</LBtnText>
                                      </BtnEye> */}
                                      <RiSpan title={moment(item.date).format(TOOLTIP_DATE_FORMAT)}>{`${moment(
                                        item.date
                                      ).format(TIME_FORMAT)} (${moment(item.date).fromNow()})`}</RiSpan>
                                      {!hasProduction() && (
                                        <LastIcon expand={!expanded} title='More'>
                                          <SVGIcon
                                            name='icon-more'
                                            size={mobile ? 24 : 18}
                                            fill={mobile ? 'var(--dark-color)' : 'var(--gray-icon-color)'}
                                            strokeWidth={2}
                                          />
                                        </LastIcon>
                                      )}
                                    </RView>
                                  </FirstTextView>
                                  {!expanded && (
                                    <SeondTextView>
                                      <CaptionList title={!expanded && item?.snippet}>
                                        {!expanded && item?.snippet}
                                      </CaptionList>
                                    </SeondTextView>
                                  )}

                                  {expanded ? (
                                    <EmailDetail>
                                      {!isEmpty(to) && (
                                        <EmailListItem>
                                          <EmailItem>To :</EmailItem>
                                          {to}
                                        </EmailListItem>
                                      )}
                                      {!isEmpty(cc) && (
                                        <EmailListItem>
                                          <EmailItem>Cc :</EmailItem>
                                          {cc}
                                        </EmailListItem>
                                      )}
                                      {!isEmpty(bcc) && (
                                        <EmailListItem>
                                          <EmailItem>Bcc :</EmailItem>
                                          {bcc}
                                        </EmailListItem>
                                      )}
                                    </EmailDetail>
                                  ) : null}
                                </RightTextList>
                              </BoxList2>
                            </ReadBox>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <MessageDetail>
                      <MessageInbox>
                        {item?.body ? (
                          <IframeView
                            key={`iframe-view-ref-${index}`}
                            index={index}
                            isHtmlMessage={item?.isHtml}
                            bodyContent={item?.body}
                            expandedQuotedText={true}
                            attachmentList={item?.files}
                            attachmentBaseUrl={`${attachmentBaseUrl}&messageId=${item?.id}`}
                          />
                        ) : (
                          <>Loading...</>
                        )}
                      </MessageInbox>
                      <AttachmentList
                        attachments={item?.files}
                        downloadBaseUrl={`${attachmentBaseUrl}&messageId=${item?.id}`}
                      />
                    </MessageDetail>
                    {/* <LstIconView>
                        <Icon name='feather-more-horizontal' fontSize='24' color='var(--gray-icon-color)' />
                      </LstIconView> */}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}

            {/* <MessageBox>
              <BoxList>
                <Logoview>
                  <Logotext>SP</Logotext>
                </Logoview>
                <RightTextList>
                  <FirstTextView>
                    <LeSpan>Sanjay Patel</LeSpan>
                    <RiSpan>9:35 AM (3 hours ago)</RiSpan>
                  </FirstTextView>
                  <SeondTextView>
                    <CaptionList>Hey, Sanjay Thanks for getting back to me, That sounds great and to...</CaptionList>
                  </SeondTextView>
                </RightTextList>
              </BoxList>
              <BoxList>
                <Logoview>
                  <Logotext>SP</Logotext>
                </Logoview>
                <RightTextList>
                  <FirstTextView>
                    <LeSpan>Sanjay Patel</LeSpan>
                    <RiSpan>9:35 AM (3 hours ago)</RiSpan>
                  </FirstTextView>
                  <SeondTextView>
                    <CaptionList>Hey, Sanjay Thanks for getting back to me, That sounds great and to...</CaptionList>
                  </SeondTextView>
                </RightTextList>
              </BoxList>
              <BoxList>
                <Logoview>
                  <Logotext>SP</Logotext>
                </Logoview>
                <RightTextList>
                  <FirstTextView>
                    <LeSpan>Sanjay Patel</LeSpan>
                    <RiSpan>9:35 AM (3 hours ago)</RiSpan>
                  </FirstTextView>
                  <SeondTextView>
                    <CaptionList>Hey, Sanjay Thanks for getting back to me, That sounds great and to...</CaptionList>
                  </SeondTextView>
                </RightTextList>
              </BoxList>
            </MessageBox> */}
          </DetailList>
          <LastBtnView>
            <IconButton
              iconName='icon-reply'
              iconColor='var(--gray-icon-color)'
              fontSize={20}
              onClick={() => {
                openSendEmailModal(SEND_EMAIL_TYPE.REPLY);
              }}
              secondary
              title='Reply '
            />
            <IconButton
              iconName='icon-forward'
              iconColor='var(--gray-icon-color)'
              fontSize={20}
              onClick={() => {
                openSendEmailModal(SEND_EMAIL_TYPE.FORWARD);
              }}
              secondary
              title='Forward'
            />
          </LastBtnView>
        </RightList>
      </Accordion>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenViewMenu}
        onClose={closeViewModal}
        getContentAnchorEl={null}
        position='center'
        MenuListProps={{
          disablePadding: true
        }}
        marginTop={28}
        positionVertical='top'
        width={157}>
        <PopupView>
          <ViewList>
            <ShowText>Opened</ShowText>
            <ShowTime>2 min ago</ShowTime>
          </ViewList>
          <ViewList>
            <ShowText>Clicked</ShowText>
            <ShowTime>Yesterday</ShowTime>
          </ViewList>
          <ViewList>
            <ShowText>Opened</ShowText>
            <ShowTime>Yesterday</ShowTime>
          </ViewList>
        </PopupView>
      </Menu>
    </>
  );
};
export default EmailDetails;
