import styled, { css } from 'styled-components';
import { MenuItem, withStyles } from '@material-ui/core';
import { Text1, Text2 } from '../../styles';

const AssignUser = styled.div``;

const AvtarWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-border-color);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
`;

const AvtarWrapperBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-border-color);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--primary-color-secondery-active);
  ${Text2}
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  cursor: pointer;
  i {
    -webkit-text-stroke-width: 0.75px;
    -webkit-text-stroke-color: var(--gray-icon-color);
  }
`;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      minHeight: 'auto'
    },
    '&:hover': {
      background: 'var(--popup-color-hover)'
    },
    borderRadius: '6px;',
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '17px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '6px',
    padding: '6px',
    cursor: 'pointer'
  }
}))(MenuItem);

const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-left: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    right: 0px;
    margin: 0;
    top: 4px;
  }
  .checkmark {
    position: absolute;
    top: 4px;
    right: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const InputLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ProfileName = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 12px;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  AssignUser,
  AvtarWrapper,
  StyledMenuItem,
  AvtarWrapperBox,
  IconWrapper,
  ProfileName,
  InputLabel,
  InputWrapperModal
};
