import React, { useCallback, useMemo, useState } from 'react';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import { LOOKUP_FIELD_EDIT_VIEW_FROM } from '../../../../constants/constant';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import { getTableTypeForAnalytics, trackContactDetailActionAnalytics } from '../../../../services/lookupService';
import AddLookupFieldForm from '../../LookupFieldInput/AddLookupFieldForm';
import LookupFieldViewEditForm from '../../LookupFieldInput/LookupFieldViewEditForm';
import {
  FirstList,
  LastView,
  SecondContain,
  ALasttext,
  TitleView2,
  TextDetail,
  UlList,
  Ul,
  Li,
  LSpan2
} from '../styles';

const ContactDetailFields = ({ lookupDetails, userAccess, table, lookup_id }) => {
  const mobile = useMobileDevice();

  const [isShowAddField, setIsShowAddField] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const fieldList = useMemo(() => {
    return lookupDetails?.fieldToDisplay;
  }, [lookupDetails?.fieldToDisplay]);

  const openAddNewField = useCallback(() => {
    setIsShowAddField(true);
  }, []);

  const closeAddNewField = useCallback(() => {
    setIsShowAddField(false);
  }, []);

  const tableTypeForAnalytics = useMemo(() => {
    if (table) {
      const type = getTableTypeForAnalytics(table);
      return type;
    }
  }, [table]);

  const onClickShowMoreLess = useCallback(() => {
    let action;
    if (!showMore) {
      action = 'show more';
    } else {
      action = 'show less';
    }
    trackContactDetailActionAnalytics(tableTypeForAnalytics, action);
    setShowMore(!showMore);
  }, [showMore, tableTypeForAnalytics]);

  const addView = useMemo(() => {
    if (userAccess?.permissionEdit)
      return (
        <LastView onClick={openAddNewField}>
          <ALasttext>Add</ALasttext>
        </LastView>
      );
    return null;
  }, [openAddNewField, userAccess?.permissionEdit]);

  const addLookupFieldFormView = useMemo(() => {
    if (isShowAddField)
      return (
        <AddLookupFieldForm
          fields={lookupDetails?.addFieldList}
          isShowAddField
          onClose={closeAddNewField}
          lookup_id={lookupDetails?.id}
          table={table}
        />
      );
    return null;
  }, [closeAddNewField, isShowAddField, lookupDetails?.addFieldList, lookupDetails?.id, table]);

  const fieldListView = useMemo(() => {
    if (fieldList?.length > 0)
      return fieldList?.slice(0, showMore ? 100 : 7)?.map((field, index) => {
        return (
          <Li key={`fieldList_${field?.id}_${index}`}>
            <LookupFieldViewEditForm
              table={table}
              field={field}
              lookup_id={lookup_id}
              viewType={LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD}
            />
          </Li>
        );
      });
    return null;
  }, [fieldList, lookup_id, showMore, table]);

  return (
    <SecondContain mobile={mobile}>
      <TitleView2 mobile={mobile}>
        <FirstList>
          <SVGIcon name='icon-details' size={22} fill='var(--gray-icon-color)' />
          <TextDetail>Detail</TextDetail>
        </FirstList>
        {addView}
      </TitleView2>
      <UlList mobile={mobile}>
        {addLookupFieldFormView}
        <Ul>
          {fieldListView}
          {fieldList?.length > 7 && (
            <Li>
              <LSpan2 onClick={onClickShowMoreLess}>{showMore ? 'Show Less' : 'Show More'}</LSpan2>
            </Li>
          )}
        </Ul>
      </UlList>
    </SecondContain>
  );
};

export default ContactDetailFields;
