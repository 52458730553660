import styled from 'styled-components';
import { Header4, Text1 } from '../../../styles';

const MainContact = styled.form``;
const TitleText = styled.h2`
  margin: 0;
  color: var(--dark-color);
  ${Header4}
  padding:10px 20px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const InputView = styled.div`
  margin: 15px 15px 20px 15px;
`;
const BtnView = styled.div`
  padding: 0 15px 15px 15px;
  width: 100%;
  max-width: 175px;
  display: flex;
  justify-content: space-between;
`;
const LabelTitle = styled.span`
  ${Text1}
  color: var(--dark-color);
`;
const customestyle = {
  option: (base) => ({
    ...base,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--dark-color)',
    margin: '4px 6px',
    padding: '6px 10px',
    letterSpacing: '0.2px',
    borderRadius: '5px',
    width: 'auto',
    '&:active': {
      background: ' var(--popup-color-hover)'
    },
    '&:hover': {
      background: ' var(--popup-color-hover)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    color: '#000'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 8px',
    flexWrap: 'nowrap'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    boxShadow: 'none',
    minHeight: '35px',
    borderRadius: '4px',
    borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5',
    borderWidth: isFocused ? '2px' : '1px',
    margin: isFocused ? '0px -1px' : '0px',
    ':hover': {
      borderColor: isFocused ? 'var(--primary-color);' : '#E4E4E5'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    boxShadow: '0px 3px 10px var(--menu-modal)',
    border: '1px solid var(--gray-border-color)',
    margin: '4px 0px'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px',
    padding: '3px 0px'
  }),
  multiValue: (base) => ({
    ...base,
    background: 'var(--popup-color-hover)',
    border: '1px solid var(--gray-border-color)',
    borderRadius: '4px',
    height: 28,
    margin: '3px',
    minWidth: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative'
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '0 8px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--gray-color)'
  }),
  multiValueRemove: (base) => ({
    ...base,
    opacity: '0',
    position: 'absolute',
    cursor: 'pointer',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ':hover': {
      background: 'var(--linear-gradient-hover);',
      right: '0',
      top: '1px',
      bottom: '0',
      width: '100%',
      textAlign: 'right',
      color: 'rgba(46, 62, 70, 0.5)',
      borderRadius: '4px',
      paddingRight: '4px',
      opacity: '1',
      zIndex: '1'
    }
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px'
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: '14px'
  })
};

export { MainContact, TitleText, InputView, BtnView, LabelTitle, customestyle };
