import styled, { css } from 'styled-components';
import { MenuItem, withStyles } from '@material-ui/core';
import { Header2, Header3, Header4, SubHeader3, Text1, mobileHeader1 } from '../../styles';
import Button from '../../components/atom/Button/Button';

const Task = styled.div`
  overflow: auto;
  height: 100%;
`;
const TaskWrapper = styled.div`
  cursor: default;
  width: 100%;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          padding: 0px 16px 16px 16px;
          height: 100%;
        `
      : css`
          max-width: 844px;
          padding: 24px 0px 0 0;
        `}
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 12px 16px 12px;
`;
const HeaderTitel = styled.h2`
  margin: 0;
  ${Header2}
  color:var(--dark-color);
`;
const ActivityTitel = styled.h3`
  margin: 0 0 16px 12px;
  color: var(--primary-color);
  ${Text1}
`;
const TaskContainer = styled.div`
  padding-bottom: 24px;
`;
const InputWrapper = styled.div`
  padding: 4px 15px;
`;
const InputWrapperModal = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  margin-left: 16px;
  :last-child {
    margin-bottom: 6px;
  }
`;
const InputType = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0 0 13px 0;
  width: 100%;
  margin-left: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    right: 0px;
    margin: 0;
    top: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputSpan = styled.span`
  ${Text1}
  color:var(--dark-color);
  margin-left: 10px;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const AvtarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const Wrapper = styled.div`
  padding: 11px;
  border: 1px solid transparent;
  ${(props) =>
    props.isSummaryTextShow &&
    css`
      box-shadow: 0 1px 2px var(--pagination-box-shadow);
      border-color: var(--gray-border-color);
      border-radius: 6px;
    `}
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
          background-color: var(--white-color);
          box-shadow: 0px 1px 3px rgba(10, 10, 46, 0.1);
          border-radius: 8px;
          margin-bottom: 12px;
        `
      : css``}
`;
const TaskchekList = styled.div`
  display: block;
  position: relative;
  margin-right: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          flex: 1 0 auto;
          padding-left: 16px;
        `
      : css`
          flex: 1 0 0;
          padding-left: 32px;
        `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    width: 18px;
    left: 0px;
    margin: 0;
    ${(props) =>
      props.mobile
        ? css`
            top: 1px;
          `
        : css`
            top: 2px;
          `}
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 20px;
    ${(props) =>
      props.mobile
        ? css`
            top: 2px;
          `
        : css`
            top: 3px;
          `}
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkedmark {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    ${(props) =>
      props.mobile
        ? css`
            ${mobileHeader1}
            margin-left: 12px;
          `
        : css`
            ${Header4}
          `}
  }
`;
const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
        `
      : css``}
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
`;
const Linkdiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          margin: 12px 0 0 24px;
        `
      : css`
          margin-right: 16px;
          padding: 3px 6px;
          border-radius: 3px;
          :hover {
            background-color: var(--popup-color-hover);
          }
        `};
`;
const Lastdiv = styled.div`
  display: flex;
  align-items: center;
  img {
    display: block;
    cursor: pointer;
  }
  i {
    cursor: pointer;
  }
`;
const NameSpan = styled.span`
  ${Text1}
  margin-left: 8px;
  ${(props) =>
    props.mobile
      ? css`
          color: var(--gray-color);
        `
      : css`
          color: var(--dark-color);
        `}
`;
const IconView = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      margin-left: auto;
    `}
`;
const PopupForm = styled.form``;
const DropdownView = styled.div`
  margin-bottom: 16px;
`;
const DropLabel = styled.span`
  ${Text1}
  color: var(--dark-color);
  margin-bottom: 4px;
  display: block;
`;
const InputWrapperTwo = styled.div``;
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      minHeight: 'auto'
    },
    '&:hover': {
      background: 'var(--popup-color-hover)'
    },
    borderRadius: '5px;',
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '17px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '2px 6px',
    padding: '5px 6px',
    cursor: 'pointer'
  }
}))(MenuItem);
const AvtarBox = styled.div`
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          margin-right: 12px;
        `
      : css`
          margin-right: 16px;
        `}
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.margin &&
    css`
      margin-right: 16px;
    `}
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
const SummarySpan = styled.span`
  display: block;
  ${Text1}
  color: var(--gray-color);
  p {
    margin: 8px 0 0 0;
  }
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 26px;
        `
      : css`
          margin-left: 32px;
        `}
`;
const DcIconView = styled.div`
  margin-left: 8px;
  svg {
    display: block;
  }
`;
const SpanWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TaskEmptyWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TaskEmptyBlock = styled.div`
  max-width: 326px;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TaskEmptyTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;
const TaskEmptyImage = styled.img``;
const TaskEmptyHead = styled.span`
  display: block;
  text-align: center;
  ${Header3}
  color: var(--dark-color);
  margin-top: 24px;
`;
const TaskSpan = styled.span`
  display: block;
  text-align: center;
  ${SubHeader3}
  color: var(--gray-color);
  margin-top: 10px;
`;
const LeftWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 1 0;
`;

export {
  Task,
  TaskWrapper,
  Header,
  TaskContainer,
  HeaderTitel,
  ActivityTitel,
  InputWrapper,
  InputWrapperModal,
  InputType,
  InputSpan,
  AvtarWrapper,
  Wrapper,
  TaskchekList,
  Form,
  RightSide,
  Linkdiv,
  Lastdiv,
  NameSpan,
  IconView,
  PopupForm,
  DropdownView,
  DropLabel,
  InputWrapperTwo,
  StyledMenuItem,
  AvtarBox,
  SvgWrapper,
  MobileButton,
  SummarySpan,
  DcIconView,
  SpanWrapper,
  TaskEmptyWrap,
  TaskEmptyBlock,
  TaskEmptyTop,
  TaskEmptyImage,
  TaskEmptyHead,
  TaskSpan,
  LeftWrapper
};
