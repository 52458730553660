//App
export const SET_APP_IS_SEARCH_OPEN = 'SET_APP_IS_SEARCH_OPEN';
export const SET_APP_IS_UPGRADE_TO_PRO = 'SET_APP_IS_UPGRADE_TO_PRO';
export const SET_APP_IS_SIDEBAR_OPEN = 'SET_APP_IS_SIDEBAR_OPEN';

// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const UPDATE_CURRENT_USER_PROFILE_FIELD_VALUE = 'UPDATE_CURRENT_USER_PROFILE_FIELD_VALUE';
export const SET_AUTH_VALIDATION_ERRORS = 'SET_AUTH_VALIDATION_ERRORS';
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';
export const SET_USER_ACCESS_DATA = 'SET_USER_ACCESS_DATA';
export const UPDATE_USER_ACCESS_DATA = 'UPDATE_USER_ACCESS_DATA';

//Admin
export const SET_ADMIN_LOADER = 'SET_ADMIN_LOADER';
export const SET_COMPANY_LIST_BY_USER_EMAIL = 'SET_COMPANY_LIST_BY_USER_EMAIL';

//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const SET_RES_WARNING_MSG = 'SET_RES_WARNING_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';
export const SET_ITEM_ERROR_MSG = 'SET_ITEM_ERROR_MSG';
export const SET_ITEM_SUCESS_MSG = 'SET_ITEM_SUCESS_MSG';

//Organization
export const SET_ORGANIZATION_LOADER = 'SET_ORGANIZATION_LOADER';
export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST';
export const UPDATE_NEW_ORGANIZATION_ITEM = 'UPDATE_NEW_ORGANIZATION_ITEM';
export const CLEAR_ORGANIZATION_ITEM = 'CLEAR_ORGANIZATION_ITEM';
export const UPDATE_ORGANIZATION_FIELD_VALUE = 'UPDATE_ORGANIZATION_FIELD_VALUE';
export const SET_COMPANY_ITEM = 'SET_COMPANY_ITEM';
export const UPDATE_COMPANY_ITEM = 'UPDATE_COMPANY_ITEM';
export const ADD_USER_ITEM = 'ADD_USER_ITEM';
export const UPDATE_USER_ITEM = 'UPDATE_USER_ITEM';
export const DELETE_USER_ITEM = 'DELETE_USER_ITEM';

//Lookup
export const SET_LOOKUP_LOADER = 'SET_LOOKUP_LOADER';
export const SET_LOOKUP_LIST = 'SET_LOOKUP_LIST';
export const UPDATE_LOOKUP_ITEM = 'UPDATE_LOOKUP_ITEM';
export const SET_SEGMENT_LIST = 'SET_SEGMENT_LIST';
export const SET_LOOKUP_DETAIL = 'SET_LOOKUP_DETAIL';
export const SET_LOOKUP_GROUP_FIELDS = 'SET_LOOKUP_GROUP_FIELDS';
export const SET_LOOKUP_VALIDATION_ERROR = 'SET_LOOKUP_VALIDATION_ERROR';
export const SET_MANAGE_SEGMENT_LIST = 'SET_MANAGE_SEGMENT_LIST';
export const SET_SEGMENT_FILTER = 'SET_SEGMENT_FILTER';
export const UPDATE_SEGMENT_FILTER_ITEM = 'UPDATE_SEGMENT_FILTER_ITEM';
export const UPDATE_SEGMENT_FIELD = 'UPDATE_SEGMENT_FIELD';
export const DELETE_SEGMENT_FILTER_ITEM = 'DELETE_SEGMENT_FILTER_ITEM';
export const ADD_SEGMENT_FILTER_ITEM = 'ADD_SEGMENT_FILTER_ITEM';
export const CLEAR_SEGMENT_FILTER = 'CLEAR_SEGMENT_FILTER';
export const SET_LOOKUP_TAGS = 'SET_LOOKUP_TAGS';
export const REMOVE_LOOKUP_TAG = 'REMOVE_LOOKUP_TAG';
export const ADD_LOOKUP_TAG = 'ADD_LOOKUP_TAG';
export const SET_LOOKUP_CONNECTED_LOOKUPS = 'SET_LOOKUP_CONNECTED_LOOKUPS';
export const SET_ALL_LOOKUP = 'SET_ALL_LOOKUP';
export const SET_LOOKUP_FIELDS = 'SET_LOOKUP_FIELDS';
export const SET_LOOKUP_ENRICHMENT_FIELDS = 'SET_LOOKUP_ENRICHMENT_FIELDS';
export const UPDATE_LOOKUP_ENRICHMENT_ITEM = 'UPDATE_LOOKUP_ENRICHMENT_ITEM';
export const CLEAR_LOOKUP_DATA = 'CLEAR_LOOKUP_DATA';

//Lookup Tables
export const SET_LOOKUP_TABLE_LOADER = 'SET_LOOKUP_TABLE_LOADER';
export const SET_LOOKUP_TABLES = 'SET_LOOKUP_TABLES';
export const SET_TABLE_FIELDS = 'SET_TABLE_FIELDS';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_LOOKUP_TABLE_ITEM = 'SET_LOOKUP_TABLE_ITEM';
export const UPDATE_LOOKUP_TABLE_ITEM = 'UPDATE_LOOKUP_TABLE_ITEM';

//Dashboard
export const SET_DASHBOARD_LOADER = 'SET_DASHBOARD_LOADER';
export const SET_DASHBOARD_DETAIL = 'SET_DASHBOARD_DETAIL';
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';
export const UPDATE_DASHBOARD_ACTIVITY_ITEM = 'UPDATE_DASHBOARD_ACTIVITY_ITEM';
export const SET_DASHBOARD_ONBOARDING = 'SET_DASHBOARD_ONBOARDING';
export const SET_CONTACT_SUGGESTIONS = 'SET_CONTACT_SUGGESTIONS';
export const DELETE_CONTACT_SUGGESTION_ITEM = 'DELETE_CONTACT_SUGGESTION_ITEM';
export const SET_CONTACT_DUPLICATION = 'SET_CONTACT_DUPLICATION';
export const SET_MERGE_CONTACT_DETAILS = 'SET_MERGE_CONTACT_DETAILS';
export const UPDATE_MERGE_CONTACT_DETAILS = 'UPDATE_MERGE_CONTACT_DETAILS';
export const SET_DATA_LOADER = 'SET_DATA_LOADER';

//Activity
export const SET_ACTIVITY_LOADER = 'SET_ACTIVITY_LOADER';
export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA';
export const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST';
export const SET_ACTIVITY_BY_LOOKUP = 'SET_ACTIVITY_BY_LOOKUP';
export const SET_ACTIVITY_ITEM = 'SET_ACTIVITY_ITEM';
export const UPDATE_ACTIVITY_ITEM = 'UPDATE_ACTIVITY_ITEM';
export const SET_ACTIVITY_VALIDATION_ERROR = 'SET_ACTIVITY_VALIDATION_ERROR';
export const CLEAR_ACTIVITY_ITEM = 'CLEAR_ACTIVITY_ITEM';
export const ADD_ATTENDEES_ITEM = 'ADD_ATTENDEES_ITEM';
export const UPDATE_ATTENDEES_ITEM = 'UPDATE_ATTENDEES_ITEM';
export const SET_TASK_ACTIVITY_LIST = 'SET_TASK_ACTIVITY_LIST';
export const UPDATE_TASK_ACTIVITY_ITEM = 'UPDATE_TASK_ACTIVITY_ITEM';
export const DELETE_ATTENDEES_ITEM = 'DELETE_ATTENDEES_ITEM';
export const SET_TASK_COUNT = 'SET_TASK_COUNT';
export const ADD_ACTIVITY_COMMENT = 'ADD_ACTIVITY_COMMENT';
export const UPDATE_COMMENT_ITEM = 'UPDATE_COMMENT_ITEM';
export const DELETE_ACTIVITY_COMMENT = 'DELETE_ACTIVITY_COMMENT';
export const UPDATE_LOOKUP_ACTIVITY_ITEM = 'UPDATE_LOOKUP_ACTIVITY_ITEM';
export const SET_OPEN_COMMENT_ACTIVITY_LIST = 'SET_OPEN_COMMENT_ACTIVITY_LIST';

//Email
export const SET_EMAIL_LOADER = 'SET_EMAIL_LOADER';
export const SET_EMAIL_LIST = 'SET_EMAIL_LIST';
export const UPDATE_EMAIL_ACCOUNT_LIST = 'UPDATE_EMAIL_ACCOUNT_LIST';
export const UPDATE_EMAIL_LIST_ITEM = 'UPDATE_EMAIL_LIST_ITEM';
export const SET_EMAIL_LABEL_LIST = 'SET_EMAIL_LABEL_LIST';
export const DELETE_EMAIL_ITEM = 'DELETE_EMAIL_ITEM';
export const SET_EMAIL_DETAIL = 'SET_EMAIL_DETAIL';
export const SET_EMAIL_ACCOUNTS = 'SET_EMAIL_ACCOUNTS';
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES';
export const SET_EMAIL_TEMPLATE_ITEM = 'SET_EMAIL_TEMPLATE_ITEM';
export const UPDATE_EMAIL_TEMPLATE_ITEM = 'UPDATE_EMAIL_TEMPLATE_ITEM';
export const SET_EMAIL_SYNC_SETTINGS = 'SET_EMAIL_SYNC_SETTINGS';
export const UPDATE_SYNC_SETTINGS_FIELD_VALUE = 'UPDATE_SYNC_SETTINGS_FIELD_VALUE';
export const SET_TOTAL_EMAIL_COUNT = 'SET_TOTAL_EMAIL_COUNT';
export const SET_EMAIL_ITEM = 'SET_EMAIL_ITEM';
export const UPDATE_EMAIL_ITEM = 'UPDATE_EMAIL_ITEM';
export const CLEAR_EMAIL_ITEM = 'CLEAR_EMAIL_ITEM';
export const ADD_EMAIL_FILES = 'ADD_EMAIL_FILES';
export const DELETE_EMAIL_FILES = 'DELETE_EMAIL_FILES';
export const CLEAR_EMAIL_DATA = 'CLEAR_EMAIL_DATA';

//Setting
export const SET_SETTING_LOADER = 'SET_SETTING_LOADER';
export const SET_PHONE_NUMBER_LIST = 'SET_PHONE_NUMBER_LIST';
export const UPDATE_PHONE_NUMBER_LIST = 'UPDATE_PHONE_NUMBER_LIST';
export const SET_TWILIO_SETTINGS = 'SET_TWILIO_SETTINGS';
export const SET_WEBFORM_LIST = 'SET_WEBFORM_LIST';
export const SET_WEBFORM_DETAIL = 'SET_WEBFORM_DETAIL';
export const UPDATE_WEBFORM_DETAIL = 'UPDATE_WEBFORM_DETAIL';
export const SET_WEBFORM_CODE = 'SET_WEBFORM_CODE';
export const SET_CUSTOMIZE_FIELDS = 'SET_CUSTOMIZE_FIELDS';
export const EDIT_CUSTOMIZE_FIELD = 'EDIT_CUSTOMIZE_FIELD';
export const ADD_CUSTOMIZE_FIELD = 'ADD_CUSTOMIZE_FIELD';
export const UPDATE_CUSTOMIZE_GROUP_FIELDS = 'UPDATE_CUSTOMIZE_GROUP_FIELDS';
export const DELETE_CUSTOMIZE_FIELD = 'DELETE_CUSTOMIZE_FIELD';
export const SET_SELECTED_CUSTOMIZE_FIELD = 'SET_SELECTED_CUSTOMIZE_FIELD';
export const UPDATE_SELECTED_CUSTOMIZE_FIELD = 'UPDATE_SELECTED_CUSTOMIZE_FIELD';
export const UPDATE_SELECTED_CUSTOMIZED_FIELD_OPTION = 'UPDATE_SELECTED_CUSTOMIZED_FIELD_OPTION';
export const ADD_SELECTED_CUSTOMIZED_FIELD_OPTION = 'ADD_SELECTED_CUSTOMIZED_FIELD_OPTION';
export const DELETE_SELECTED_CUSTOMIZED_FIELD_OPTION = 'DELETE_SELECTED_CUSTOMIZED_FIELD_OPTION';
export const ADD_CUSTOMIZE_FIELD_GROUP = 'ADD_CUSTOMIZE_FIELD_GROUP';
export const DELETE_CUSTOMIZE_FIELD_GROUP = 'DELETE_CUSTOMIZE_FIELD_GROUP';
export const UPDATE_CUSTOMIZE_FIELD_GROUP_NAME = 'UPDATE_CUSTOMIZE_FIELD_GROUP_NAME';
export const SET_SUBSCRIPTION_LIST = 'SET_SUBSCRIPTION_LIST';
export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS';
export const SET_IMPORT_DATA = 'SET_IMPORT_DATA';
export const SET_SETTING_SIGNATURE = 'SET_SETTING_SIGNATURE';
export const SET_INTEGRATION_SECRET_KEY = 'SET_INTEGRATION_SECRET_KEY';
export const SET_TOTAL_AMOUNT = 'SET_TOTAL_AMOUNT';

export const SET_API_KEYS = 'SET_API_KEYS';
export const SET_API_KEY_ITEM = 'SET_API_KEY_ITEM';
export const UPDATE_API_KEY_ITEM = 'UPDATE_API_KEY_ITEM';
export const SET_EXISTING_PHONE_NUMBERS = 'SET_EXISTING_PHONE_NUMBERS';
export const UPDATE_EXISTING_PHONE_NUMBER = 'UPDATE_EXISTING_PHONE_NUMBER';

//Report
export const SET_REPORT_LOADER = 'SET_REPORT_LOADER';
export const SET_OVERVIEW_REPORT = 'SET_OVERVIEW_REPORT';
export const SET_ACTIVITY_REPORT = 'SET_ACTIVITY_REPORT';
export const SET_ACTIVITY_EVENTS = 'SET_ACTIVITY_EVENTS';
export const SET_ACTIVITY_USERS = 'SET_ACTIVITY_USERS';
export const UPDATE_ACTIVITY_EVENTS = 'UPDATE_ACTIVITY_EVENTS';
export const UPDATE_ACTIVITY_USERS = 'UPDATE_ACTIVITY_USERS';
export const SET_STATUS_CHANGE_REPORT = 'SET_STATUS_CHANGE_REPORT';
export const SET_CALL_SUMMARY_REPORT = 'SET_CALL_SUMMARY_REPORT';
export const SET_CALL_SUMMARY_EVENTS = 'SET_CALL_SUMMARY_EVENTS';
export const SET_CALL_SUMMARY_USERS = 'SET_CALL_SUMMARY_USERS';
export const UPDATE_CALL_SUMMARY_EVENTS = 'UPDATE_CALL_SUMMARY_EVENTS';
export const UPDATE_CALL_SUMMARY_USERS = 'UPDATE_CALL_SUMMARY_USERS';

//Phone call
export const SET_PHONE_CALL_LOADER = 'SET_PHONE_CALL_LOADER';
export const SET_PHONE_CALL_DETAILS = 'SET_PHONE_CALL_DETAILS';
export const UPDATE_PHONE_CALL_DETAILS = 'UPDATE_PHONE_CALL_DETAILS';
export const SET_TWILIO_CHARGES = 'SET_TWILIO_CHARGES';
export const CLEAR_PHONE_CALL_LOADER = 'CLEAR_PHONE_CALL_LOADER';
export const SET_SMS_ITEM = 'SET_SMS_ITEM';
export const UPDATE_SMS_ITEM = 'UPDATE_SMS_ITEM';
export const ADD_SMS_TO_ITEM = 'ADD_SMS_TO_ITEM';
export const CLEAR_SMS_ITEM = 'CLEAR_SMS_ITEM';
export const SET_CALL_FORWARDING_ITEM = 'SET_CALL_FORWARDING_ITEM';
export const UPDATE_CALL_FORWARDING_ITEM = 'UPDATE_CALL_FORWARDING_ITEM';
export const CLEAR_CALL_FORWARDING_ITEM = 'CLEAR_CALL_FORWARDING_ITEM';
export const UPDATE_INPUT_DEVICES = 'UPDATE_INPUT_DEVICES';
export const UPDATE_OUTPUT_DEVICES = 'UPDATE_OUTPUT_DEVICES';

//CallLog
export const SET_CALL_LOGS_LOADER = 'SET_CALL_LOGS_LOADER';
export const SET_CALL_LOGS = 'SET_CALL_LOGS';

//SMSLog
export const SET_SMS_LOADER = 'SET_SMS_LOADER';
export const SET_SMS_LIST = 'SET_SMS_LIST';

//Invoice
export const SET_INVOICE_LOADER = 'SET_INVOICE_LOADER';
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_INVOICE_ITEM = 'SET_INVOICE_ITEM';
export const UPDATE_INVOICE_ITEM = 'UPDATE_INVOICE_ITEM';
export const ADD_INVOICE_TAX = 'ADD_INVOICE_TAX';
export const DELETE_INVOICE_TAX = 'DELETE_INVOICE_TAX';
export const ADD_ITEM_IN_INVOICE = 'ADD_ITEM_IN_INVOICE';
export const DELETE_ITEM_FROM_INVOICE = 'DELETE_ITEM_FROM_INVOICE';
export const ADD_EMAIL_IN_INVOICE = 'ADD_EMAIL_IN_INVOICE';
export const DELETE_EMAIL_FROM_INVOICE = 'DELETE_EMAIL_FROM_INVOICE';

//Automation
export const SET_AUTOMATION_LOADER = 'SET_AUTOMATION_LOADER';
export const SET_AUTOMATION_LIST = 'SET_AUTOMATION_LIST';
export const UPDATE_AUTOMATION_LIST_ITEM = 'UPDATE_AUTOMATION_LIST_ITEM';
export const DELETE_AUTOMATION_ITEM = 'DELETE_AUTOMATION_ITEM';
export const SET_AUTOMATION_ITEM = 'SET_AUTOMATION_ITEM';
export const UPDATE_AUTOMATION_ITEM = 'UPDATE_AUTOMATION_ITEM';
export const ADD_AUTOMATION_STEP_ITEM = 'ADD_AUTOMATION_STEP_ITEM';
export const DELETE_AUTOMATION_STEP_ITEM = 'DELETE_AUTOMATION_STEP_ITEM';
export const UPDATE_AUTOMATION_STEP_ITEM = 'UPDATE_AUTOMATION_STEP_ITEM';
export const CLEAR_AUTOMATION_ITEM = 'CLEAR_AUTOMATION_ITEM';

//Goal
export const SET_GOAL_LOADER = 'SET_GOAL_LOADER';
export const SET_GOAL_LIST = 'SET_GOAL_LIST';
export const SET_GOAL_ITEM = 'SET_GOAL_ITEM';
export const UPDATE_GOAL_ITEM = 'UPDATE_GOAL_ITEM';
export const DELETE_GOAL_ITEM = 'DELETE_GOAL_ITEM';
export const CLEAR_GOAL_ITEM = 'CLEAR_GOAL_ITEM';

//Notifications
export const SET_NOTIFICATION_LOADER = 'SET_NOTIFICATION_LOADER';
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
