import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/Input/Input';
import Button from '../../components/atom/Button/Button';
import DropDown from '../../components/DropDown/DropDown';
import { industry, teamSize } from '../../data/raw';
import { updateOrganizationItem } from '../../actions/organizationActions';
import { isEmpty } from '../../helpers/common';
import Validation from '../../components/Validation/Validation';
import AppLogo from '../../components/AppLogo';
import {
  ManeWrapper,
  LoginContainer,
  LogoWrapper,
  Title,
  Form,
  Label,
  LabelTitle,
  LabelWrapper,
  DropLabel,
  DropWrapper
} from './Styles';
const OnboardingStep1 = (props) => {
  const organizationSelector = useSelector((state) => state.organization);
  const { organizationItem } = organizationSelector;
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  const onChangeInput = useCallback(
    (propsName, value) => {
      dispatch(updateOrganizationItem({ propsName, value }));
    },
    [dispatch]
  );

  const onClickNext = useCallback(
    (e) => {
      e.preventDefault();
      let error = {};
      if (!organizationItem?.name || organizationItem?.name?.trim() === '') {
        error['name'] = 'Required';
      }
      if (isEmpty(organizationItem?.teamType)) {
        error['teamType'] = 'Required';
      }
      if (!organizationItem?.industry || organizationItem?.industry.trim() === '') {
        error['industry'] = 'Required';
      }
      if (!isEmpty(error)) {
        setError(error);
        return;
      }
      props.gotoNext();
    },
    [organizationItem, props]
  );

  return (
    <ManeWrapper>
      <LoginContainer>
        <LogoWrapper>
          <AppLogo />
          <Title>Just a few quick things to set up your account…</Title>
        </LogoWrapper>
        <Form>
          <LabelWrapper>
            <Label>Step 1 to 3</Label>
          </LabelWrapper>
          <LabelTitle>First, tell us a bit about your workspace.</LabelTitle>
          <Input
            isPrimarySmall={true}
            title='Workspace Name'
            placeholder='Enter Workspace name'
            value={organizationItem?.name}
            onChange={(e) => {
              onChangeInput('name', e.target.value);
            }}
          />
          {error?.name && <Validation error={error.name} />}

          <DropWrapper>
            <DropLabel>Team member</DropLabel>
            <DropDown
              options={teamSize}
              labelField={'name'}
              valueField={'value'}
              value={organizationItem?.teamType}
              placeholder={'How big is your team?'}
              onChange={(item) => {
                onChangeInput('teamType', item?.value);
              }}
            />
            {error?.teamType && <Validation error={error.teamType} />}
          </DropWrapper>
          <DropWrapper>
            <DropLabel>Industry</DropLabel>
            <DropDown
              options={industry}
              labelField={'name'}
              valueField={'value'}
              value={organizationItem?.industry}
              onChange={(item) => {
                onChangeInput('industry', item?.value);
              }}
              placeholder={'Which type of industry you belong to?'}
            />
            {error?.industry && <Validation error={error.industry} />}
          </DropWrapper>
          <Button primary title='Next' onClick={onClickNext} />
        </Form>
      </LoginContainer>
    </ManeWrapper>
  );
};

export default OnboardingStep1;
