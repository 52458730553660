import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/atom/Button/Button';
import { getExistingPhoneNumbers, getTwilioSettings, updateTwilioSettings } from '../../../../services/settingService';
import Message from '../../../../components/Message/Message';
import { setTwilioSettingItem } from '../../../../actions/settingActions';
import { isEmpty } from '../../../../helpers/common';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import { IconView } from '../styles';
import {
  InputView,
  TitleText,
  TwilioSettingWrapper,
  TitleWrapper,
  TwilioSettingsWrapper,
  BtnViewList,
  ButtonWrapper,
  URLTitle,
  HeaderWrapper,
  Wrapper,
  ReadMore
} from './styles';

const TwilioSettingsModal = ({ onClose, hasPhoneNumber, openTwilioAccountModal }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const settingsSelector = useSelector((state) => state.setting);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { twilioSettings, loading } = settingsSelector;
  const [validationErrors, setValidationErrors] = useState({});
  const [accountSId, setAccountSId] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [appId, setAppId] = useState('');

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getTwilioSettings(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    if (!isEmpty(twilioSettings)) {
      setAccountSId(twilioSettings?.accountSId);
      setAuthToken(twilioSettings?.authToken);
      setAppId(twilioSettings?.appId);
    }
  }, [twilioSettings]);

  const onModalClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!accountSId) {
      validationErrors.accountSId = 'Required';
      valid = false;
    }
    if (!authToken) {
      validationErrors.authToken = 'Required';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [accountSId, authToken]);

  const onUpdateTwilioSettings = useCallback(async () => {
    const validateResult = validateInputs();
    if (!validateResult) return;

    const payload = { accountSId, authToken, appId };
    const result = await dispatch(updateTwilioSettings(organization_id, payload));
    if (result) {
      dispatch(setTwilioSettingItem(payload));
      const phonesResult = await dispatch(getExistingPhoneNumbers(organization_id));
      if (phonesResult?.length > 0) {
        openTwilioAccountModal();
      } else {
        onModalClose();
      }
    }
  }, [accountSId, appId, authToken, dispatch, onModalClose, openTwilioAccountModal, organization_id, validateInputs]);

  return (
    <>
      <TwilioSettingsWrapper>
        <TitleWrapper>
          <HeaderWrapper>
            <SVGIcon name='icon-refresh' fill='var(--gray-icon-color)' size={22} />
            <TitleText>Connect Twilio</TitleText>
          </HeaderWrapper>
          <IconView onClick={onModalClose}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
          </IconView>
        </TitleWrapper>
        <TwilioSettingWrapper>
          <Wrapper>
            <InputView>
              <Input
                isPrimarySmall={true}
                type='text'
                id='Twilio Account SID'
                name='Twilio Account SID'
                placeholder='Twilio Account SID'
                title='Twilio Account SID'
                value={accountSId || ''}
                onChange={(e) => {
                  setAccountSId(e.target.value);
                }}
              />
            </InputView>
            {validationErrors?.accountSId && <Message text={validationErrors.accountSId} isSuccess={false} />}
            <InputView>
              <Input
                isPrimarySmall={true}
                type='text'
                id='Auth token'
                name='Auth token'
                placeholder='Auth token'
                title='Auth token'
                value={authToken || ''}
                onChange={(e) => {
                  setAuthToken(e.target.value);
                }}
              />
            </InputView>
            {validationErrors?.authToken && <Message text={validationErrors.authToken} isSuccess={false} />}
            <URLTitle>
              <ReadMore
                href='https://pixerhq.notion.site/
                  Twilio-Setting-For-Client-6ca4e060d7514a479da24ca51dd1a40d'
                target='_blank'>
                Learn more
              </ReadMore>{' '}
              about how to get above information.
            </URLTitle>
          </Wrapper>
          <BtnViewList>
            <ButtonWrapper>
              <Button
                title='Connect Twilio'
                loading={loading}
                // disabled={hasPhoneNumber}
                onClick={onUpdateTwilioSettings}
              />
            </ButtonWrapper>
          </BtnViewList>
        </TwilioSettingWrapper>
      </TwilioSettingsWrapper>
    </>
  );
};

export default TwilioSettingsModal;
