import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem, Modal, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
// import { Menu } from '../../../components/Menu';
import Input from '../../../components/Input/Input';
import { addLookupTag, removeLookupTags } from '../../../actions/lookupActions';
import { isEmpty } from '../../../helpers/common';
import { hasUserAccessAndPermission } from '../../../services/appService';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { Border, BtnText, IconDelete, PopBtn, Tag, TagBox, TagText, InputWrapper, TagMenu, TagItems } from './styles';
import CreateNewTag from './CreateNewTag/CreateNewTag';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      minHeight: 'auto'
    },
    '&:hover': {
      background: 'var(--popup-color-hover)',
      borderRadius: '5px;'
    },
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '17px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '4px 6px',
    padding: '6px 10px'
  }
}))(MenuItem);

const TagList = (props) => {
  const params = useParams();
  const tableName = params?.name;
  const { tags, field, updateLookupFields, selectedTags, isBulk } = props;
  const [isOpenAddTagMenu, setIsOpenAddTagMenu] = useState(false);
  const [isOpenCreateNewTagModal, setCreateTagOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [tagList, setTagList] = useState();
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setTagList(tags);
  }, [tags]);

  const userAccess = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', tableName));
    return {
      permissionEdit: permissionEdit
    };
  }, [dispatch, tableName]);

  const closeAddTagModal = useCallback(() => {
    setIsOpenAddTagMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const openTagModal = useCallback((e) => {
    setMenuAnchorEl(e.currentTarget);
    setIsOpenAddTagMenu(true);
  }, []);

  const onOpenCreateTagModal = useCallback(() => {
    closeAddTagModal();
    setCreateTagOpen(true);
  }, [closeAddTagModal]);

  const closeCrateNewTagModal = useCallback(() => {
    setCreateTagOpen(false);
  }, []);

  const onPressTags = useCallback(
    (item, i) => {
      let tag = selectedTags || [];
      let options = [];

      tag?.forEach((tagItem) => {
        const tagObj = {
          id: tagItem.value,
          value: tagItem.value
        };
        options.push(tagObj);
      });

      const index = tag?.findIndex((x) => x.value === item);
      if (index === -1) {
        const tagItem = {
          id: item,
          value: item
        };
        options.push(tagItem);
        dispatch(removeLookupTags({ item: item, index: i }));
      } else {
        options.splice(index, 1);
        dispatch(addLookupTag({ item: item }));
      }

      const value = {
        value_Options: options
      };

      if (isBulk) {
        props.onPressTag(options);
      } else {
        updateLookupFields(field, value);
      }

      closeAddTagModal();
    },
    [selectedTags, isBulk, closeAddTagModal, dispatch, props, updateLookupFields, field]
  );

  const searchTag = useCallback((searchTexts) => {
    let newList = [];
    searchTexts = searchTexts.trim().toLowerCase();
    if (!isEmpty(searchTexts)) {
      newList = tags.filter((x) => x.trim().toLowerCase().includes(searchTexts));
    } else {
      newList = tags;
    }
    setTagList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSearch = useCallback(
    (value) => {
      setSearchText(value);
      searchTag(value);
    },
    [searchTag]
  );

  return (
    <>
      <Tag>
        {selectedTags?.map((item, index) => {
          return (
            <TagBox key={`${index}`}>
              <TagText>{item?.value}</TagText>
              {userAccess?.permissionEdit && (
                <IconDelete
                  onClick={() => {
                    onPressTags(item?.id, index);
                  }}>
                  <SVGIcon name='icon-delete' size={14} fill='var(--gray-icon-color)' title='Delete' />
                </IconDelete>
              )}
            </TagBox>
          );
        })}
        {userAccess?.permissionEdit && (
          <TagBox margin onClick={openTagModal} title='Add Tag'>
            <SVGIcon name='icon-pluse' size={12} fill='var(--gray-icon-color)' strokeWidth={3} />
          </TagBox>
        )}
      </Tag>
      <TagMenu
        id='tag-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenAddTagMenu}
        isBulk={isBulk}
        onClose={() => {
          closeAddTagModal();
        }}
        MenuListProps={{ disablePadding: true }}>
        <InputWrapper>
          <Input
            isPrimarySmall={true}
            type='text'
            id='tag'
            name='tags'
            placeholder='Search'
            calue={searchText}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </InputWrapper>
        <TagItems>
          {tagList?.map((item, index) => {
            return (
              <StyledMenuItem
                onClick={() => {
                  onPressTags(item, index);
                }}
                key={index}>
                {item}
              </StyledMenuItem>
            );
          })}
        </TagItems>
        <Border></Border>
        <PopBtn onClick={onOpenCreateTagModal}>
          <BtnText>Create New</BtnText>
        </PopBtn>
      </TagMenu>
      <Modal
        open={isOpenCreateNewTagModal}
        onClose={closeCrateNewTagModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <CreateNewTag handleCloseModal={closeCrateNewTagModal} onSave={onPressTags} />
      </Modal>
    </>
  );
};

export default TagList;
