import React from 'react';
import SVGIcon from '../../assets/svg/SVGIcon';
import Avtar from '../Avtar/Avtar';
import { AssignUser, AvtarWrapper, AvtarWrapperBox, IconWrapper } from './styles';

const UserAvatarList = (props) => {
  const { userList, openAssignedMenu } = props;

  const selectedUsers = userList?.filter((user) => user?.isSelected === true);

  return (
    <>
      {selectedUsers?.slice(0, selectedUsers?.length > 4 ? 3 : 4)?.map((item) => {
        return (
          <AssignUser key={item?.id}>
            <AvtarWrapper>
              <Avtar key={item.id} imgSrc={item?.profile_photo} name={item?.name} size={22} />
            </AvtarWrapper>
          </AssignUser>
        );
      })}
      {selectedUsers?.length > 4 && <AvtarWrapperBox> {`+${selectedUsers.length - 3}`} </AvtarWrapperBox>}
      <IconWrapper
        onClick={(e) => {
          openAssignedMenu(e);
        }}
        title='Assign User'>
        <SVGIcon name='icon-pluse' fill='var(--gray-icon-color)' size={12} strokeWidth={2} />
      </IconWrapper>
    </>
  );
};

export default UserAvatarList;
