import styled, { css } from 'styled-components';
import { Text1 } from '../../../styles';

const StylesWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  ${(props) =>
    props.component === 'ImportData' &&
    css`
      padding: 0;
    `}
`;
const Styles = styled.div`
  z-index: 2;
  position: relative;
  ${(props) =>
    props.component === 'ImportData' &&
    css`
      max-height: calc(100vh - 445px);
      overflow: auto;
    `}
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      :hover {
        td {
          background-color: var(--popup-color-hover);
          :last-child {
            opacity: 1;
            background-color: var(--popup-color-hover);
            border: 0;
          }
        }
      }
    }
    tbody tr {
      :hover {
        th {
          background-color: var(--popup-color-hover);
          z-index: 4;
        }
      }
    }
    thead th {
      position: sticky;
      top: 0;
      z-index: 3;
      margin: 0;
      padding: 0 12px;
      border-right: 1px solid var(--Platinum-color);
      text-align: left;
      background-color: var(--white-color);
      min-width: 150px;
      white-space: nowrap;
      ${Text1}
      color: var(--gray-color);
      height: 35px;
      border-bottom: 2px solid var(--Platinum-color);
      border-top: 1px solid var(--Platinum-color);
      min-height: 35px;
      box-sizing: content-box;
    }
    thead th:first-child {
      position: sticky;
      left: 0;
      z-index: 5;
      border-left: 0;
      ${(props) =>
        props.mobile
          ? css`
              top: 0;
              left: auto;
              padding: 0px;
            `
          : css`
              left: 0;
            `}
    }
    thead th:last-child {
      border-right: 0px;
    }
    tbody th {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
      ${(props) =>
        props.mobile
          ? css`
              position: static;
            `
          : css`
              position: sticky;
            `}
    }
    tbody {
      tr {
        position: relative;
        th {
          margin: 0;
          padding: 0 12px;
          text-align: left;
          background-color: var(--white-color);
          min-width: 150px;
          white-space: nowrap;
          ${Text1}
          color: var(--dark-color);
          height: 38px;
          border-bottom: 1px solid var(--Platinum-color);
          border-right: 1px solid var(--Platinum-color);
          border-top: 0px;
          position: sticky;
          left: 0;
          background: white;
          z-index: 4;
          ${(props) =>
            props.mobile
              ? css`
                  position: static;
                `
              : css`
                  position: sticky;
                `}
        }
      }
    }
    td {
      margin: 0;
      padding: 0 12px;
      border-right: 1px solid var(--Platinum-color);
      text-align: left;
      background-color: var(--white-color);
      min-width: 150px;
      white-space: nowrap;
      ${Text1}
      color: var(--dark-color);
      height: 38px;
      border-bottom: 1px solid var(--Platinum-color);
      position: relative;
      :last-child {
        border-right: 0;
        position: sticky;
        right: 0;
        min-width: 0px;
        padding: 0;
        opacity: 0;
      }
      :nth-last-of-type(2) {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;
const ViewIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0px 8px 16px;
  padding: 0 16px;
  background: var(--popup-color-hover);
  width: 130px;
`;
const Tr = styled.tr`
  :hover {
    td {
      opacity: 1;
      background-color: var(--popup-color-hover);
    }
  }
`;

const SpanArrow = styled.span`
  margin-left: auto;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

export { Styles, StylesWrapper, Tr, ViewIcon, SpanArrow, HeaderTitle };
