import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '../../components/Menu';
import Button from '../../components/atom/Button/Button';
import Icon from '../../components/Icon/Icon';
import { trackSupportAnalyticActivity, updateOnBoardingStep } from '../../services/dashboardService';
import { ONBOARDING_TYPE } from '../../constants/constant';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { getAppHostName } from '../../helpers/common';
import {
  ContainList,
  LineList,
  ListContain,
  LeftList,
  ListTextView,
  UserData,
  Caption,
  RightList,
  ViewIcon,
  BottomView,
  HelpText,
  BtnView,
  DemoView,
  MenuItemWrapper,
  IconButton
} from './styles';

const InboxOnBoarding = () => {
  const [menuAnchorEl, setmenuAnchorEl] = useState(null);
  const [isMoreMenu, setIsMoreMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const dashboard = useSelector((state) => state.dashboard);
  const organizationSelector = useSelector((state) => state.organization);
  const { onBoarding } = dashboard;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const history = useHistory();
  const dispatch = useDispatch();

  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);

  const closeMoreMenu = useCallback(() => {
    setIsMoreMenu(false);
    setSelectedItem();
    setmenuAnchorEl(null);
  }, []);

  const openMoreMenu = useCallback((e, type) => {
    e.stopPropagation();
    setmenuAnchorEl(e.currentTarget);
    setIsMoreMenu(true);
    setSelectedItem(type);
  }, []);

  const trackOnBoardingAnalyticActivity = useCallback((id, actionType) => {
    try {
      if (!id || !actionType) return;

      let action;
      let step = '';
      if (actionType === 1) action = 'skip';
      else if (actionType === 2) {
        if (id === ONBOARDING_TYPE.ISIMPORTDATA) action = 'import data';
        else if (id === ONBOARDING_TYPE.ISSYNCACCOUNT) action = 'add account';
        else if (id === ONBOARDING_TYPE.ISCUSTOMIZE) action = 'customize';
        else if (id === ONBOARDING_TYPE.ISINVITEUSER) action = 'invite member';
      }
      if (id === ONBOARDING_TYPE.ISIMPORTDATA) step = 'import data';
      else if (id === ONBOARDING_TYPE.ISSYNCACCOUNT) step = 'sync account';
      else if (id === ONBOARDING_TYPE.ISCUSTOMIZE) step = 'customize';
      else if (id === ONBOARDING_TYPE.ISINVITEUSER) step = 'invite user';
      else if (id === ONBOARDING_TYPE.GETSTARTEDVIDEO) step = 'get started video';

      trackAnalyticActivity('onboarding: click on action', { step: step, action: action });
    } catch (e) {}
  }, []);

  const onClickSkip = useCallback(() => {
    trackOnBoardingAnalyticActivity(selectedItem, 1);
    const payload = {
      id: selectedItem
    };
    const result = dispatch(updateOnBoardingStep(organization_id, payload));
    if (result) {
      closeMoreMenu();
    }
  }, [trackOnBoardingAnalyticActivity, selectedItem, dispatch, organization_id, closeMoreMenu]);

  const onClickButton = useCallback(
    (type, path) => {
      trackOnBoardingAnalyticActivity(type, 2);
      history.push(path);
    },
    [history, trackOnBoardingAnalyticActivity]
  );

  return (
    <>
      <ContainList>
        {!onBoarding?.isImportData && (
          <LineList>
            <ListContain>
              <LeftList>
                <Icon
                  name='icon-import'
                  bgColor='#53ACF2'
                  color='var(--white-color)'
                  fontSize='20'
                  padDing='12'
                  borderRadius='70'
                />
                <ListTextView>
                  <UserData>Import Data</UserData>
                  <Caption>Migrate from your old crm or excel</Caption>
                </ListTextView>
              </LeftList>
              <RightList>
                <IconButton
                  iconName='icon-import'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                  onClick={() => {
                    onClickButton(ONBOARDING_TYPE.ISIMPORTDATA, '/settings/importdata?ref=onboarding');
                  }}
                  secondary
                  title='Import'
                />
                <ViewIcon>
                  <Icon
                    name='icon-more'
                    fontSize='24'
                    color='var(--gray-color)'
                    onClick={(e) => {
                      openMoreMenu(e, ONBOARDING_TYPE.ISIMPORTDATA);
                    }}
                  />
                </ViewIcon>
              </RightList>
            </ListContain>
          </LineList>
        )}
        {!onBoarding?.isSyncAccount && (
          <LineList>
            <ListContain>
              <LeftList>
                <Icon
                  name='icon-email'
                  bgColor='#F08C00'
                  color='var(--white-color)'
                  fontSize='20'
                  padDing='12'
                  borderRadius='70'
                />
                <ListTextView>
                  <UserData>Sync Your Email</UserData>
                  <Caption>To see email converations & send bulk mail</Caption>
                </ListTextView>
              </LeftList>
              <RightList>
                <IconButton
                  iconName='icon-email'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                  onClick={() => {
                    onClickButton(ONBOARDING_TYPE.ISSYNCACCOUNT, '/settings/sync?isNew=true&ref=onboarding');
                  }}
                  secondary
                  title='Add Email Account'
                />
                <ViewIcon>
                  <Icon
                    name='icon-more'
                    fontSize='24'
                    color='var(--gray-color)'
                    onClick={(e) => {
                      openMoreMenu(e, ONBOARDING_TYPE.ISSYNCACCOUNT);
                    }}
                  />
                </ViewIcon>
              </RightList>
            </ListContain>
          </LineList>
        )}
        {!onBoarding?.isCustomize && (
          <LineList>
            <ListContain>
              <LeftList>
                <Icon
                  name='icon-customize'
                  bgColor='#3A3E42'
                  color='var(--white-color)'
                  fontSize='20'
                  padDing='12'
                  borderRadius='70'
                />
                <ListTextView>
                  <UserData>Customize</UserData>
                  <Caption>{`Adapt ${appName} CRM for your needs`}</Caption>
                </ListTextView>
              </LeftList>
              <RightList>
                <IconButton
                  onClick={() => {
                    onClickButton(ONBOARDING_TYPE.ISCUSTOMIZE, '/settings/customize?ref=onboarding');
                  }}
                  iconName='icon-customize'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                  secondary
                  title='Customize'
                />
                <ViewIcon>
                  <Icon
                    name='icon-more'
                    fontSize='24'
                    color='var(--gray-color)'
                    onClick={(e) => {
                      openMoreMenu(e, ONBOARDING_TYPE.ISCUSTOMIZE);
                    }}
                  />
                </ViewIcon>
              </RightList>
            </ListContain>
          </LineList>
        )}
        {!onBoarding?.isInviteUser && (
          <LineList>
            <ListContain>
              <LeftList>
                <Icon
                  name='icon-team'
                  bgColor='#20C997'
                  color='var(--white-color)'
                  fontSize='20'
                  padDing='12'
                  borderRadius='70'
                />
                <ListTextView>
                  <UserData>Invite Your Team</UserData>
                  <Caption>{`Bring your team to ${appName} CRM.`}</Caption>
                </ListTextView>
              </LeftList>
              <RightList>
                <IconButton
                  iconName='icon-add-account'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                  onClick={() => {
                    onClickButton(ONBOARDING_TYPE.ISINVITEUSER, '/settings/users/userslist?ref=onboarding');
                  }}
                  secondary
                  title='Invite Member'
                />
                <ViewIcon>
                  <Icon
                    name='icon-more'
                    fontSize='24'
                    color='var(--gray-color)'
                    onClick={(e) => {
                      openMoreMenu(e, ONBOARDING_TYPE.ISINVITEUSER);
                    }}
                  />
                </ViewIcon>
              </RightList>
            </ListContain>
          </LineList>
        )}
        {!onBoarding?.getStartedVideo && (
          <LineList>
            <ListContain>
              <LeftList>
                <Icon
                  name='icon-video'
                  bgColor='#FF6B6B'
                  color='var(--white-color)'
                  fontSize='20'
                  padDing='12'
                  borderRadius='70'></Icon>
                <ListTextView>
                  <UserData>Check out Get started Video </UserData>
                  <Caption>Watch our get started video setup your account.</Caption>
                </ListTextView>
              </LeftList>
              <RightList>
                <IconButton
                  onClick={() => {
                    onClickButton(ONBOARDING_TYPE.GETSTARTEDVIDEO, '');
                  }}
                  secondary
                  title='Watch Now!'
                  iconName='icon-video'
                  fontSize={20}
                  iconColor='var(--gray-icon-color)'
                />
                <ViewIcon>
                  <Icon
                    name='icon-more'
                    fontSize='24'
                    color='var(--gray-color)'
                    onClick={(e) => {
                      openMoreMenu(e, ONBOARDING_TYPE.GETSTARTEDVIDEO);
                    }}
                  />
                </ViewIcon>
              </RightList>
            </ListContain>
          </LineList>
        )}
        <BottomView>
          <HelpText>Need Help on Account Setup ?</HelpText>
          <DemoView>
            <BtnView
              href={`https://cal.salescamp.app/devanshu/salescamp-demo`}
              target='_blank'
              onClick={() => {
                trackSupportAnalyticActivity('request demo clicked');
              }}>
              <Button primary title='Book a Demo' />
            </BtnView>
          </DemoView>
        </BottomView>
      </ContainList>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenu}
        onClose={closeMoreMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width={120}
        MenuListProps={{ disablePadding: true }}
        marginTop={35}>
        <MenuItemWrapper>
          <MenuItem
            iconName='icon-next'
            color='var(--gray-icon-color)'
            fontSize='18'
            text='Skip'
            onClick={onClickSkip}
          />
        </MenuItemWrapper>
      </Menu>
    </>
  );
};
export default InboxOnBoarding;
