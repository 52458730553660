import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// import Modal from '@material-ui/core/Modal';
import { useLocation } from 'react-router';
import { Avatar } from '@material-ui/core';
import Modal from '../../components/Modal';
import {
  DATE_FORMAT,
  LookupFieldTypes,
  LOOKUP_TYPE,
  LOOKUP_TYPE_ID,
  LOOKUP_UPDATE_SOURCE,
  SEND_EMAIL_TYPE,
  TIME_FORMAT
} from '../../constants/constant';
import Header from '../../components/Header/Header';
import LookupTable from '../../components/Table/LookupTable/LookupTable';
import {
  bulkActionCollectionAnalyticActivity,
  bulkTags,
  deleteLookup,
  deleteLookups,
  getLookupByTable,
  getLookupList,
  getLookupTags,
  getSegmentFilteredList,
  getSegmentList,
  getSelectedLooupFromTableRows,
  getTableTypeForAnalytics,
  trackCollectionActionAnalyticActivity,
  trackCustomerActionAnalytics
} from '../../services/lookupService';
import { getTableFields } from '../../services/lookupTableServices';
import { Menu, MenuItem } from '../../components/Menu';
import SnackBar from '../../components/SnackBar/SnackBar';
import { setLookupList, setSegmentList } from '../../actions/lookupActions';
import OrganizationPreferencesSingleton from '../../helpers/OrganizationPreferencesSingleton';
import { ConfirmDialog } from '../../components/ConfirmDialog/index';
import { downloadFile, getGravatarURL, isEmpty } from '../../helpers/common';
import SendEmailModal from '../Email/SendEmailModal/SendEmailModal';
import TwilioService from '../../services/twilioService';
import { FastCol, NameTitle, NameList, AvatarWrapper } from '../../components/Table/LookupTable/Styles';
import Avtar from '../../components/Avtar/Avtar';
import useMobileDevice from '../../hooks/useMobileDevice';
import ContactEmpty from '../../Pages/Contact/ContactEmpty/ContactEmpty';
import { Wrapper, ModalStyle, SendEmailModalWidth, ModalPopupStyle, AddTagButton } from './Styles';
import AddNewContact from './AddNewContact';
import LookupListFieldViewEditForm from './LookupFieldInput/LookupListFieldViewEditForm';
import BulkAssignModal from './BulkAssignModal/BulkAssignModal';
import TagList from './ContactDetail/TagList';
import BulkUnAssignModal from './BulkUnAssignModal/BulkUnAssignModal';

const SEGMENT = 'segment';
const SORTED_FEILDS = 'sortedFeilds';

const Contact = (props) => {
  const lookupName = props.match.params.name;
  const segmentIdParam = props.match.params.segmentId;
  const location = useLocation();
  const search = location?.search;

  const dispatch = useDispatch();

  const organizationSelector = useSelector((state) => state.organization);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const lookupSelector = useSelector((state) => state.lookup);
  const settingsSelector = useSelector((state) => state.setting);
  const emailSelector = useSelector((state) => state.email);

  const { list: lookupTableList, tableFields } = lookupTableSelector;
  const { list: lookupList, segments, tags, loading } = lookupSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { phoneNumberList } = settingsSelector;
  const { emailAccounts } = emailSelector;

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isBulkMoreMenuOpen, setIsBulkMoreMenuOpen] = useState(false);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(undefined);
  const [selectedItemCloneId, setSelectedItemCloneId] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState(undefined);
  const [isOpenBulkAssignModal, setIsOpenBulkAssignModal] = useState(false);
  const [isOpenBulkUnAssignModal, setIsOpenBulkUnAssignModal] = useState(false);
  const [isGetTags, setIsGetTags] = useState(true);
  const [isOpenBulkTagsMenu, setIsOpenBulkTagsMenu] = useState(false);
  const [selectedTags, setSelectedTags] = useState();
  const [lookups, setLookups] = useState();
  const [selectedEmailId, setSelectedEmailId] = useState();
  const mobile = useMobileDevice();
  useEffect(() => {
    return () => {
      dispatch(setSegmentList(null));
      dispatch(setLookupList(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (search) {
      const URLParams = new URLSearchParams(search);
      const newLookup = URLParams.get('new');
      const itemId = URLParams.get('id');
      if (newLookup || itemId) {
        if (itemId) {
          setSelectedItemId(itemId);
        } else {
          setSelectedItemId(undefined);
        }
        openAddModal();
      }
    }
  }, [search]);

  const table = useMemo(() => {
    const currentTable = lookupTableList.find((x) => x.name === lookupName);
    return currentTable;
  }, [lookupTableList, lookupName]);

  useEffect(() => {
    dispatch(setSegmentList(null));
    dispatch(setLookupList(null));
  }, [dispatch, lookupName]);

  const sortedFeild = useMemo(() => {
    const sortedFeild = OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, SORTED_FEILDS);
    return sortedFeild;
  }, [table?.id]);

  const tableTypeForAnalytics = useMemo(() => {
    const type = getTableTypeForAnalytics(table);
    return type;
  }, [table]);

  const userAccess = useMemo(() => {
    const tableList = organization?.settings?.access?.tables;
    const tableItem = tableList?.find((item) => item?.tableId === table?.id);
    return {
      permissionDelete: tableItem?.permissionDelete,
      permissionEdit: tableItem?.permissionEdit
    };
  }, [organization, table?.id]);

  const hasPhoneNumber = useMemo(() => {
    return phoneNumberList?.length > 0;
  }, [phoneNumberList?.length]);

  const hasEmailAccount = useMemo(() => {
    return emailAccounts?.filter((x) => x?.isEmailSync)?.length > 0;
  }, [emailAccounts]);

  const getTableType = (item) => {
    switch (item?.type) {
      case LOOKUP_TYPE.contacts:
        return 'cn';
      case LOOKUP_TYPE.companies:
        return 'cm';
      case LOOKUP_TYPE.others:
        return 'others';
      default:
        return '';
    }
  };

  const lookupType = useMemo(() => {
    const type = getTableType(table);
    return type;
  }, [table]);

  const segmentId = useMemo(() => {
    const selectedSegmentId = OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, SEGMENT);
    const patamSegmentId =
      selectedSegmentId || segmentIdParam || (segments && segments?.length > 0 ? segments[0]?.id : undefined);
    //Use table Id filter condition to fix issue of showing previous table data
    const segment_id = selectedSegmentId
      ? selectedSegmentId
      : segments?.find((x) => x.id === patamSegmentId && x.tableId === table?.id)?.id;
    return segment_id;
  }, [segmentIdParam, segments, table?.id]);

  const loadData = useCallback(async () => {
    const tableId = table?.id;
    if (tableId) {
      dispatch(getSegmentList(tableId));
      dispatch(getTableFields(organization_id));
      dispatch(getLookupList(organization_id, tableId));
    }
  }, [table?.id, dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData, table?.id]);

  useEffect(() => {
    const getLookups = async () => {
      const tableLookups = await getLookupByTable(table?.id);
      setLookups(tableLookups);
    };
    getLookups();
  }, [table?.id]);

  const getExportData = useCallback(
    (list, fields, filePrefix) => {
      if (fields === 'displayFields') {
        list = list.reverse();
      }
      const lookupTableFields = tableFields?.find((x) => x?.tableId === table?.id);
      const columns = lookupTableFields?.columns;
      if (columns) {
        let csvData = '';
        //Write headers
        columns?.forEach((x) => {
          csvData += x?.label + ',';
        });
        csvData += '\n';
        list?.forEach((lookup) => {
          columns?.forEach((field) => {
            let value = lookup[fields][field?.id];
            if (field.type === LookupFieldTypes.Date) {
              value = lookup[fields][field?.id] ? moment(lookup[fields][field?.id]).format(DATE_FORMAT) : undefined;
            }
            if (field.type === LookupFieldTypes.Time) {
              value = lookup[fields][field?.id] ? moment(lookup[fields][field?.id]).format(TIME_FORMAT) : undefined;
            }
            let newValue = value?.trim() || '';
            // eslint-disable-next-line no-useless-escape
            if (newValue && newValue?.indexOf(',') !== -1) newValue = `\"${newValue}\"`;
            csvData += String(newValue) + ',';
          });
          csvData += '\n';
        });

        downloadFile(
          csvData,
          `Salescamp_${table?.name}_${filePrefix}_${moment().format('DD-MMM-YYYY')}.csv`,
          'text/csv'
        );
      }
    },
    [table?.id, table?.name, tableFields]
  );
  const exportAllData = useCallback(() => {
    getExportData(lookups, 'displayFields', 'ExportedData');
  }, [getExportData, lookups]);

  const exportBulkData = useCallback(() => {
    getExportData(selectedRows, 'original', 'BulkExported');
  }, [getExportData, selectedRows]);

  const onNameClick = useCallback((e, item_id) => {
    e.preventDefault();
    setSelectedItemId(item_id);
    openAddModal();
  }, []);

  const nameField = useMemo(() => {
    let prefix = `${lookupType}/${table?.name}/view`;
    return {
      id: 'name',
      accessor: 'name',
      Header: () => (
        <FastCol>
          <NameTitle>Name</NameTitle>
        </FastCol>
      ),
      Cell: ({ row }) => {
        const gravatar = getGravatarURL(row?.original?.email);
        return (
          <FastCol>
            <AvatarWrapper mobile={mobile}>
              {table?.type === LOOKUP_TYPE.contacts && row?.original?.email ? (
                <Avtar imgSrc={gravatar} size={mobile ? 24 : 26} />
              ) : (
                table?.type !== LOOKUP_TYPE.others && <Avatar className='avatar'></Avatar>
              )}
              <NameList
                mobile={mobile}
                to={{ pathname: `/${prefix}/${row?.original?.id}/overview`, tableId: table?.id }}
                onClick={(e) => {
                  LOOKUP_TYPE.others === table?.type && onNameClick(e, row?.original?.id);
                }}>
                {row?.original?.name}
              </NameList>
            </AvatarWrapper>
          </FastCol>
        );
      }
    };
  }, [lookupType, mobile, onNameClick, table?.id, table?.name, table?.type]);

  const lookupTableData = useMemo(() => {
    if (!lookupList) return null;
    const lookupTableFields = tableFields?.find((x) => x.tableId === table?.id);
    let columns = lookupTableFields?.columns
      ?.filter((x) => x.isShow === true && x.customId && x.label)
      ?.map((field) => {
        return {
          Header: field.label,
          accessor: field.id,
          field: field
        };
      });
    columns?.unshift(nameField);
    const tableData = lookupList?.map((lookup) => {
      const rowData = {
        id: lookup.id,
        tableId: table?.id,
        name: lookup.name,
        email: lookup.email,
        ...lookup?.displayFields,
        fields: lookup.fields
      };
      return rowData;
    });
    return {
      columns,
      data: tableData
    };
  }, [lookupList, tableFields, nameField, table?.id]);

  const lookupSegmentData = useMemo(() => {
    if (!lookupTableData?.data) return null;
    dispatch(getSegmentList(table?.id));
    return getSegmentFilteredList(lookupTableData?.data, table?.id, segmentId);
  }, [dispatch, lookupTableData?.data, table?.id, segmentId]);

  const openAddModal = () => {
    setIsOpenAddModal(true);
  };
  const closeAddModal = useCallback(() => {
    onCloseMoreMenu();
    setIsOpenAddModal(false);
  }, []);

  const onCloseMoreMenu = () => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
    setSelectedItemId(undefined);
    setSelectedItemCloneId(undefined);
  };

  const openMoreMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    setSelectedItemId(item?.original?.id);
    setSelectedItemCloneId(item?.original?.id);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeBulkMoreMenu = useCallback(() => {
    setIsBulkMoreMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const openBulkMoreMenu = useCallback((e) => {
    e.stopPropagation();
    setIsBulkMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeBulkTagsMenu = useCallback(() => {
    setIsOpenBulkTagsMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const openBulkTagsMenu = useCallback((e) => {
    e.stopPropagation();
    setIsOpenBulkTagsMenu(true);
    setMenuAnchorEl(e.currentTarget);
    bulkActionCollectionAnalyticActivity('tag', tableTypeForAnalytics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(true);
  }, []);

  const closeSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(false);
  }, []);

  const onSave = useCallback(() => {
    loadData();
    closeAddModal();
  }, [closeAddModal, loadData]);

  const onClickEdit = () => {
    openAddModal();
  };

  const onOpenBulkAssignModal = useCallback(() => {
    closeBulkMoreMenu();
    setIsOpenBulkAssignModal(true);
  }, [closeBulkMoreMenu]);

  const onCloseBulkAssignModal = useCallback(() => {
    setIsOpenBulkAssignModal(false);
  }, []);

  const onOpenBulkUnAssignModal = useCallback(() => {
    closeBulkMoreMenu();
    setIsOpenBulkUnAssignModal(true);
  }, [closeBulkMoreMenu]);

  const onCloseBulkUnAssignModal = useCallback(() => {
    setIsOpenBulkUnAssignModal(false);
  }, []);

  const onDeleteItem = async () => {
    onCloseMoreMenu();
    const result = await dispatch(deleteLookup(organization_id, selectedItemId));
    if (result) {
      const type = getTableTypeForAnalytics(table);
      trackCustomerActionAnalytics(type, 'deleted', 'list');
      loadData();
    }
  };

  const onClickBulkDelete = useCallback(
    async (rows) => {
      const payload = getSelectedLooupFromTableRows(rows, organization_id);
      const result = await dispatch(deleteLookups(organization_id, payload));
      if (result) {
        loadData();
      }
    },
    [dispatch, loadData, organization_id]
  );

  const initialStateOfTable = useMemo(() => {
    if (sortedFeild?.fieldId && sortedFeild?.desc !== undefined) {
      const initialState = {
        sortBy: [
          {
            id: sortedFeild.fieldId,
            desc: sortedFeild.desc
          }
        ]
      };
      return initialState;
    }
    return undefined;
  }, [sortedFeild?.desc, sortedFeild?.fieldId]);

  const getSelectedRows = useCallback(
    (rows) => {
      if (!isEmpty(rows)) {
        setSelectedRows(rows);
        if (rows?.length > 1 && isGetTags) {
          setIsGetTags(false);
          dispatch(getLookupTags(organization_id, table?.id));
        }
      }
    },
    [dispatch, isGetTags, organization_id, table?.id]
  );

  const onClickBulkEmail = useCallback(() => {
    const ids = selectedRows
      ?.map((item) => {
        return { id: item?.original?.id, email: item?.original?.email };
      })
      ?.filter((x) => x.email);

    setSelectedEmailId(ids);
    openSendEmailModal();
    bulkActionCollectionAnalyticActivity('email', tableTypeForAnalytics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSendEmailModal, selectedRows]);

  const onPressBulkAssign = useCallback(() => {
    loadData();
    onCloseBulkAssignModal();
  }, [loadData, onCloseBulkAssignModal]);

  const onPressTag = useCallback((tag) => {
    setSelectedTags(tag);
  }, []);

  const onClickTagsDone = useCallback(async () => {
    const references = getSelectedLooupFromTableRows(selectedRows, organization_id);
    const tags = selectedTags?.map((item) => {
      return { id: item?.id, name: item?.id };
    });
    const payload = { references, tags };
    const result = await dispatch(bulkTags(organization_id, payload));
    if (result) {
      closeBulkTagsMenu();
      loadData();
    }
  }, [closeBulkTagsMenu, dispatch, loadData, organization_id, selectedRows, selectedTags]);

  const onClickEmail = useCallback(
    (item, emailAddress) => {
      setSelectedEmailId([{ id: item?.id, email: emailAddress }]);
      openSendEmailModal();
    },
    [openSendEmailModal]
  );

  const onClickCall = useCallback(
    (item) => {
      const phone = item?.row?.original[item?.column?.field?.id];
      if (phone) {
        dispatch(TwilioService.getInstance().connectCall(phone, item?.row?.original?.name, item?.row?.original));
      }
    },
    [dispatch]
  );

  const onPressBulkUnAssign = useCallback(() => {
    loadData();
    onCloseBulkUnAssignModal();
  }, [loadData, onCloseBulkUnAssignModal]);

  const TableView = useMemo(() => {
    return (
      <LookupTable
        detailUrlPrefix={`/${lookupType}/${table?.name}/view`}
        table={table}
        columns={lookupTableData?.columns || []}
        openMoreMenu={openMoreMenu}
        openBulkMoreMenu={openBulkMoreMenu}
        onClickBulkDelete={onClickBulkDelete}
        onClickBulkTags={openBulkTagsMenu}
        initialState={initialStateOfTable}
        defaultColumn={{ Cell: LookupListFieldViewEditForm }}
        data={lookupSegmentData || []}
        onNameClick={onNameClick}
        exportData={exportBulkData}
        selectedRows={getSelectedRows}
        onClickCall={onClickCall}
        onClickEmail={onClickEmail}
        onClickBulkEmail={onClickBulkEmail}
        hasPhoneNumber={hasPhoneNumber}
        hasEmailAccount={hasEmailAccount}></LookupTable>
    );
  }, [
    hasEmailAccount,
    hasPhoneNumber,
    lookupType,
    onClickCall,
    onClickEmail,
    table,
    lookupTableData?.columns,
    lookupSegmentData,
    openMoreMenu,
    openBulkMoreMenu,
    exportBulkData,
    getSelectedRows,
    initialStateOfTable,
    onClickBulkDelete,
    onClickBulkEmail,
    onNameClick,
    openBulkTagsMenu
  ]);

  return (
    <>
      {!loading && (!lookupList || lookupList?.length === 0) ? (
        <ContactEmpty table_id={table?.id} openAddModal={openAddModal} />
      ) : (
        <Wrapper>
          <Header
            table={table}
            props={props}
            segmentId={segmentId}
            segments={segments}
            primary={true}
            openAddModal={openAddModal}
            table_id={table?.id}
            exportData={exportAllData}
            {...props}
          />
          {TableView}
          <SnackBar />
        </Wrapper>
      )}
      <Modal
        open={isOpenAddModal}
        mobile={mobile}
        onClose={closeAddModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}
        style={ModalStyle}>
        <AddNewContact
          handleCloseModal={closeAddModal}
          onSave={onSave}
          source={LOOKUP_UPDATE_SOURCE.LIST}
          table={table}
          cloneId={selectedItemCloneId}
          itemId={selectedItemId}
          {...props}
        />
      </Modal>
      <Menu
        id='more-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenuOpen}
        onClose={onCloseMoreMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width='189px'
        marginTop='30px'>
        {userAccess?.permissionEdit && (
          <MenuItem
            iconName='icon-edit-pen'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Edit'
            onClick={() => {
              setSelectedItemCloneId(undefined);
              trackCollectionActionAnalyticActivity('edit', tableTypeForAnalytics);
              onClickEdit();
            }}
          />
        )}
        {userAccess?.permissionEdit && (
          <MenuItem
            iconName='icon-copy'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Clone'
            onClick={() => {
              setSelectedItemId(undefined);
              trackCollectionActionAnalyticActivity('clone', tableTypeForAnalytics);
              onClickEdit();
            }}
          />
        )}
        {userAccess?.permissionDelete && (
          <MenuItem
            iconName='icon-delete'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Delete'
            onClick={() => {
              trackCollectionActionAnalyticActivity('delete', tableTypeForAnalytics);
              ConfirmDialog({
                title: 'Delete?',
                message: `Are you sure want to delete this ${LOOKUP_TYPE_ID[table?.type]}?`,
                onCancel: () => {
                  onCloseMoreMenu();
                },
                onOk: () => {
                  onDeleteItem();
                }
              });
            }}
          />
        )}
      </Menu>
      <Menu
        id='more-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isBulkMoreMenuOpen}
        onClose={closeBulkMoreMenu}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        width='140px'
        marginTop='-40px'>
        <MenuItem fontSize='16' color='var(--gray-icon-color)' text='Bulk Assign' onClick={onOpenBulkAssignModal} />
        <MenuItem
          fontSize='16'
          color='var(--gray-icon-color)'
          text='Bulk Un-Assign'
          onClick={onOpenBulkUnAssignModal}
        />
      </Menu>
      <Menu
        id='more-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenBulkTagsMenu}
        onClose={closeBulkTagsMenu}
        getContentAnchorEl={null}
        position='top'
        positionVertical='bottom'
        width='190px'>
        <TagList tags={tags} selectedTags={selectedTags} onPressTag={onPressTag} isBulk={true} />
        <AddTagButton secondary type='button' title='Done' onClick={onClickTagsDone} loading={loading} />
      </Menu>
      <Modal open={isOpenSendEmailModal} onClose={closeSendEmailModal} style={SendEmailModalWidth}>
        <SendEmailModal
          handleCloseModal={closeSendEmailModal}
          lookupIds={selectedEmailId}
          type={SEND_EMAIL_TYPE.COMPOSE}
        />
      </Modal>
      <Modal open={isOpenBulkAssignModal} onClose={onCloseBulkAssignModal} style={ModalPopupStyle}>
        <BulkAssignModal
          handleCloseModal={onCloseBulkAssignModal}
          selectedRows={selectedRows}
          onSave={onPressBulkAssign}
        />
      </Modal>
      <Modal open={isOpenBulkUnAssignModal} onClose={onCloseBulkUnAssignModal} style={ModalPopupStyle}>
        <BulkUnAssignModal
          handleCloseModal={onCloseBulkUnAssignModal}
          selectedRows={selectedRows}
          onSave={onPressBulkUnAssign}
        />
      </Modal>
    </>
  );
};

export default Contact;
