import React, { useCallback, useState } from 'react';
import { Menu } from '../Menu';
import UserAvatarList from './UserAvatarList';
import UserListPopup from './UserListPopup';

const UserSelectionMenu = (props) => {
  const { userList, onChange, currentUserId, itemId } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isAssignedOpen, setIsAssignedOpen] = useState(false);

  const onCloseAssignedMenu = useCallback(() => {
    setIsAssignedOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenAssignedMenu = useCallback((e) => {
    e.stopPropagation();
    setIsAssignedOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  return (
    <>
      <UserAvatarList userList={userList} openAssignedMenu={onOpenAssignedMenu} />
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isAssignedOpen}
        onClose={onCloseAssignedMenu}
        getContentAnchorEl={null}
        position='left'
        marginTop={25}
        positionVertical='top'
        MenuListProps={{ disablePadding: true }}
        width={180}>
        <UserListPopup itemId={itemId} userList={userList} currentUserId={currentUserId} onChange={onChange} />
      </Menu>
    </>
  );
};

export default UserSelectionMenu;
