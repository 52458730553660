import React, { useMemo } from 'react';
import Input from '../../components/Input/Input';
import Button from '../../components/atom/Button/Button';
import { getAppHostName } from '../../helpers/common';
import AppLogo from '../../components/AppLogo';
import { ManeWrapper, LoginContainer, LogoWrapper, Title, Form, LabelTitleTwo } from './Styles';

const OnboardingWelcome = () => {
  const appName = useMemo(() => {
    const name = getAppHostName();
    return name;
  }, []);
  return (
    <ManeWrapper>
      <LoginContainer>
        <LogoWrapper>
          <AppLogo />
          <Title>{`Walter Melon Invite you to join ${appName}`}</Title>
        </LogoWrapper>
        <Form input>
          <LabelTitleTwo>First, tell us a bit about your company.</LabelTitleTwo>
          <Input isPrimarySmall={true} title='Name' placeholder='Enter your name' />
          <Input isPrimarySmall={true} title='Email' type='Email' placeholder='How big is your team?' />
          <Input isPrimarySmall={true} title='Password' type='password' placeholder='Enter your password' />
          <Button primary title={`Join ${appName}`} />
        </Form>
      </LoginContainer>
    </ManeWrapper>
  );
};

export default OnboardingWelcome;
