import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '../../components/Menu';
import Button from '../../components/atom/Button/Button';
import Table from '../../components/Table/Table';
import { deleteAutomation, getAutomationList, updateAutomationStatus } from '../../services/automationService';
import { updateAutomationListItem } from '../../actions/automationActions';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { TRIGGER_ACTION } from '../../constants/constant';
import SVGIcon from '../../assets/svg/SVGIcon';
import AutomationEmptyState from './AutomationEmptyState';

import {
  AutomationEmptyWrapper,
  AutomationEmptyLeft,
  AutomationEmptyRight,
  Title,
  CellLinkWrapper,
  CellWrapper,
  Name,
  IconBox,
  MenuWrapper,
  SvgWrapper,
  AutomationTableWrap
} from './styles';

const Automation = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isOpenAutomationMenu, setIsOpenAutomationMenu] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState();
  const organizationSelector = useSelector((state) => state.organization);
  const automationSelector = useSelector((state) => state.automation);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;
  const { list, loading } = automationSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const history = useHistory();
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getAutomationList(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const tableColumns = useMemo(() => {
    return [
      { Header: 'When?', accessor: 'triggerActionText' },
      { Header: 'Action', accessor: 'action' },
      { Header: 'Last Occured', accessor: 'lastOccurence' }
    ];
  }, []);
  const onClickStatus = useCallback(
    async (automation_id, status) => {
      const result = await dispatch(updateAutomationStatus(organization_id, automation_id, status));
      if (result) {
        dispatch(updateAutomationListItem({ item: result?.data }));
      }
    },
    [dispatch, organization_id]
  );
  const openMoreMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
    setSelectedItemId(item);
  }, []);

  const tableData = useMemo(() => {
    const columns = [
      {
        Header: () => (
          <CellWrapper>
            <Name>Name</Name>
          </CellWrapper>
        ),
        accessor: 'name',
        Cell: (props) => {
          return (
            <CellWrapper>
              <IconBox>
                {props?.cell?.row?.original?.status === 1 ? (
                  <SvgWrapper
                    title='Play Automation'
                    onClick={() => {
                      onClickStatus(props?.cell?.row?.original?.id, 2);
                    }}>
                    <SVGIcon name='icon-audio-play' fill='var(--kelly-green)' size={24} />
                  </SvgWrapper>
                ) : (
                  <SvgWrapper
                    title='Pause Automation'
                    onClick={() => {
                      onClickStatus(props?.cell?.row?.original?.id, 1);
                    }}>
                    <SVGIcon name='icon-audio-pause' fill='var(--jonquil)' size={24} />
                  </SvgWrapper>
                )}
              </IconBox>

              <CellLinkWrapper to={{ pathname: `automation/edit/${props?.cell?.row?.original?.id}` }}>
                {props?.cell?.row?.original.name}
              </CellLinkWrapper>
            </CellWrapper>
          );
        }
      },
      ...tableColumns
    ];

    const data = list?.map((automation) => {
      let triggerActionText;
      const table = lookupTableList?.find((x) => x?.id === automation?.lookupTableId);
      if (table) {
        triggerActionText = `${table?.label} ${TRIGGER_ACTION[automation?.triggerAction]}`;
      }
      const rowData = {
        id: automation.id,
        name: automation?.name,
        status: automation?.status,
        triggerActionText: triggerActionText,
        action:
          automation?.steps[automation?.steps?.length - 1]?.action === 1
            ? 'Condition'
            : automation?.steps[automation?.steps?.length - 1]?.action === 2
            ? 'Send Mail'
            : '',
        lastOccurence: automation?.lastOccurence ? moment(automation?.lastOccurence).format('DD MMM YYYY') : ''
      };
      return rowData;
    });
    return {
      columns,
      data
    };
  }, [lookupTableList, list, onClickStatus, tableColumns]);

  const onCloseMoreMenu = useCallback(() => {
    setIsMoreMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenAutomationMenu = useCallback((e, item) => {
    e.stopPropagation();
    setIsOpenAutomationMenu(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);
  const onCloseAutomationMenu = useCallback(() => {
    setIsOpenAutomationMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const onDeleteItem = useCallback(async () => {
    const result = await dispatch(deleteAutomation(organization_id, selectedItemId));
    if (result) {
      onCloseMoreMenu();
    }
  }, [dispatch, onCloseMoreMenu, organization_id, selectedItemId]);

  const onClickNewAutomation = useCallback(() => {
    history.push('/automation/new');
  }, [history]);

  return (
    <>
      <AutomationEmptyWrapper>
        <AutomationEmptyLeft>
          <SVGIcon name='icon-webcam' fill='var(--primary-color)' size={26} />
          <Title>Automation</Title>
        </AutomationEmptyLeft>
        <AutomationEmptyRight>
          <Button isPrimary={true} title='New Automation' onClick={onOpenAutomationMenu} />
        </AutomationEmptyRight>
      </AutomationEmptyWrapper>
      {!loading && (!list || list?.length === 0) && <AutomationEmptyState />}
      {loading && (!list || list?.length === 0) ? (
        <div>Loading...</div>
      ) : (
        list &&
        list?.length > 0 && (
          <AutomationTableWrap>
            <Table
              columns={tableData?.columns || []}
              data={tableData?.data || []}
              detailUrlPrefix={'/automation/new'}
              openMoreMenu={openMoreMenu}></Table>
          </AutomationTableWrap>
        )
      )}
      <Menu
        id='more-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreMenuOpen}
        onClose={onCloseMoreMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        width={189}
        marginTop={20}>
        <MenuItem
          iconName='icon-delete'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Delete'
          onClick={() => {
            ConfirmDialog({
              title: 'Delete?',
              message: `Are you sure want to delete this Automations?`,
              onCancel: () => {
                onCloseMoreMenu();
              },
              onOk: () => {
                onDeleteItem();
              }
            });
          }}
        />
      </Menu>
      <Menu
        id='delete-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenAutomationMenu}
        onClose={onCloseAutomationMenu}
        getContentAnchorEl={null}
        MenuListProps={{ disablePadding: true }}
        position='right'
        positionVertical='top'
        width={190}
        marginTop={42}>
        <MenuWrapper>
          <MenuItem
            iconName='icon-toggle'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Data Change'
            onClick={onClickNewAutomation}
          />
          <MenuItem iconName='icon-timer' fontSize='18' color='var(--gray-icon-color)' text='Schedule' />
          <MenuItem iconName='icon-refresh' fontSize='18' color='var(--gray-icon-color)' text='On Time Change' />
        </MenuWrapper>
      </Menu>
    </>
  );
};

export default Automation;
