import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/atom/Button/Button';
import DropDown from '../../../../components/DropDown/DropDown';
import Validation from '../../../../components/Validation/Validation';
import { BULK_LOOKUP_TYPE_ID, LOOKUP_TABLE_TYPE, LOOKUP_TYPE_ID } from '../../../../constants/constant';
import { isEmpty } from '../../../../helpers/common';
import { transferLookup } from '../../../../services/lookupService';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import {
  ModalFormWrapper,
  ModalHead,
  ModalHeadLeft,
  ModalText,
  ModalWrapper,
  ModalDownBlock,
  ModalIcon,
  ErrorMsg,
  ModalButton,
  SvgWrapper,
  Warningview,
  IconView
} from './styles';

const TransferLookupModal = (props) => {
  const mobile = useMobileDevice();
  const { table, lookupId } = props;
  const organizationSelector = useSelector((state) => state.organization);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const lookupSelector = useSelector((state) => state.lookup);
  const { loading } = lookupSelector;

  const { list: lookupTableList } = lookupTableSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const [error, setError] = useState({});
  const [selectedTable, setSelectedTable] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const tables = useMemo(() => {
    if (table) {
      const tables = lookupTableList?.filter((x) => x?.type === table?.type && x?.id !== table?.id);
      if (tables) {
        return tables;
      } else return [];
    }
  }, [lookupTableList, table]);

  const onClickCancel = useCallback(() => {
    if (props?.onClose) props?.onClose();
  }, [props]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let error = {};
      setError({});
      if (!selectedTable) {
        error['table'] = `${LOOKUP_TYPE_ID[table?.type]} is required`;
      }
      if (!isEmpty(error)) {
        setError(error);
        return;
      }
      const payload = {
        lookupId,
        tableId: selectedTable?.id
      };
      const result = await dispatch(transferLookup(organization_id, payload));
      if (result) {
        if (props?.onClose) props.onClose();
        history.push(`/${LOOKUP_TABLE_TYPE[selectedTable?.type]}/${selectedTable?.name}/view/${lookupId}/overview`);
      }
    },
    [dispatch, history, lookupId, organization_id, props, selectedTable, table?.type]
  );

  return (
    <>
      <ModalFormWrapper onSubmit={onSubmit} mobile={mobile}>
        <ModalHead>
          <ModalHeadLeft>
            <SVGIcon name='icon-two-way' fill='var(--gray-icon-color)' size={22} />
            <ModalText>Contact Transfer</ModalText>
          </ModalHeadLeft>
          <SvgWrapper onClick={onClickCancel}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
          </SvgWrapper>
        </ModalHead>
        <ModalWrapper>
          <ModalIcon>
            <SVGIcon name='icon-user' fill='var(--gray-icon-color)' size={20} />
          </ModalIcon>
          <ModalDownBlock>
            <DropDown
              options={tables}
              labelField={'label'}
              valueField={'id'}
              value={selectedTable}
              placeholder={BULK_LOOKUP_TYPE_ID[table?.type]}
              onChange={(values) => {
                setSelectedTable(values);
              }}
            />
            {error?.table && <Validation error={error?.table}></Validation>}
            <Warningview>
              <IconView>
                <SVGIcon name='icon-warning' fill='var(--gray-icon-color)' size={20} />
              </IconView>
              <ErrorMsg>
                You want to transferred lookup property from one contact to other. In that Case, If Lookup property will
                not matched ,Your data might be lose.
              </ErrorMsg>
            </Warningview>
          </ModalDownBlock>
        </ModalWrapper>
        <ModalButton>
          <Button title='Send' type='submit' loading={loading} />
          <Button secondary title='Cancel' type='button' onClick={onClickCancel} />
        </ModalButton>
      </ModalFormWrapper>
    </>
  );
};

export default TransferLookupModal;
