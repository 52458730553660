import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const settingLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_SETTING_LOADER
});

const webFormListReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_WEBFORM_LIST
});

const webFormCodeReducer = createReducer({
  initialState: '',
  actionType: Actions.SET_WEBFORM_CODE
});

const webFormDetailReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_WEBFORM_DETAIL, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_WEBFORM_DETAIL, (state, action) => {
      const webFormDetail = { ...state };
      webFormDetail[action.payload.propName] = action.payload.value;
      return { ...webFormDetail };
    });
});

const userAccessDataReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_USER_ACCESS_DATA, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_USER_ACCESS_DATA, (state, action) => {
      if (action.payload.type !== 'tables') {
        state[action.payload.type][action.payload.propName] = action.payload.value;
      } else {
        const tableIndex = state.tables.findIndex((x) => x.tableId === action.payload.tableId);
        state.tables[tableIndex][action.payload.propName] = action.payload.value;
      }
    });
});

const customizeFieldsReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_CUSTOMIZE_FIELDS, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.EDIT_CUSTOMIZE_FIELD, (state, action) => {
      const customizeFields = current(state);
      const fieldGroups = JSON.parse(JSON.stringify(customizeFields));
      const fieldId = action.payload.field.id || action.payload.field.localId;
      if (action.payload.groupIndex !== -1 && fieldId) {
        const fieldIndex = fieldGroups[action.payload.groupIndex].fields.findIndex(
          (x) => x.id === fieldId || x.localId === fieldId
        );
        if (fieldIndex !== -1) {
          fieldGroups[action.payload.groupIndex].fields.splice(fieldIndex, 1, action.payload.field);
        }
      }
      return fieldGroups;
    })
    .addCase(Actions.ADD_CUSTOMIZE_FIELD, (state, action) => {
      const customizeFields = current(state);
      const fields = JSON.parse(JSON.stringify(customizeFields));
      fields[action.payload.groupIndex].fields.push(action.payload.field);
      return fields;
    })
    .addCase(Actions.DELETE_CUSTOMIZE_FIELD, (state, action) => {
      const customizeFields = current(state);
      const fieldGroups = JSON.parse(JSON.stringify(customizeFields));
      const fieldIndex = fieldGroups[action.payload.groupIndex].fields.findIndex(
        (x) => x.id === action.payload.fieldId || x.localId === action.payload.fieldId
      );
      fieldGroups[action.payload.groupIndex].fields.splice(fieldIndex, 1);
      return fieldGroups;
    })
    .addCase(Actions.UPDATE_CUSTOMIZE_GROUP_FIELDS, (state, action) => {
      const customizeFields = JSON.parse(JSON.stringify(current(state)));
      const fields = action.payload.fields;
      const index = action.payload.groupIndex;
      customizeFields[index].fields = fields;
      return customizeFields;
    })
    .addCase(Actions.ADD_CUSTOMIZE_FIELD_GROUP, (state, action) => {
      const customizeFields = current(state);
      const fieldGroups = JSON.parse(JSON.stringify(customizeFields));
      fieldGroups.push(action.payload.fieldGroup);
      return fieldGroups;
    })
    .addCase(Actions.UPDATE_CUSTOMIZE_FIELD_GROUP_NAME, (state, action) => {
      const customizeFields = current(state);
      const fieldGroups = JSON.parse(JSON.stringify(customizeFields));
      fieldGroups[action.payload.groupIndex].name = action.payload.fieldName;
      return fieldGroups;
    })
    .addCase(Actions.DELETE_CUSTOMIZE_FIELD_GROUP, (state, action) => {
      const customizeFields = current(state);
      const fieldGroups = JSON.parse(JSON.stringify(customizeFields));
      fieldGroups.splice(action.payload.groupIndex, 1);
      return fieldGroups;
    });
});

const subscriptionListReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTION_LIST
});

const selectedCustomizeFieldReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_SELECTED_CUSTOMIZE_FIELD, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_SELECTED_CUSTOMIZE_FIELD, (state, action) => {
      const selectedField = current(state);
      const field = JSON.parse(JSON.stringify(selectedField));
      field[action.payload.propName] = action.payload.value;
      return field;
    })
    .addCase(Actions.UPDATE_SELECTED_CUSTOMIZED_FIELD_OPTION, (state, action) => {
      const selectedField = current(state);
      const field = JSON.parse(JSON.stringify(selectedField));
      field['options'][action.payload.optionIndex].value = action.payload.value;
      return field;
    })
    .addCase(Actions.ADD_SELECTED_CUSTOMIZED_FIELD_OPTION, (state, action) => {
      const selectedField = current(state);
      const field = JSON.parse(JSON.stringify(selectedField));
      if (!field?.options) field.options = [];
      field['options'].push(action.payload.option);
      return field;
    })
    .addCase(Actions.DELETE_SELECTED_CUSTOMIZED_FIELD_OPTION, (state, action) => {
      const selectedField = current(state);
      const field = JSON.parse(JSON.stringify(selectedField));
      field['options'].splice(action.payload.optionIndex, 1);
      return field;
    });
});

const subscriptionPlansReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTION_PLANS
});

const importDataReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_IMPORT_DATA
});

const settingSignatureReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SETTING_SIGNATURE
});

const integrationSecretKeyReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_INTEGRATION_SECRET_KEY
});

const creditAmountReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_TOTAL_AMOUNT
});

const phoneNumberReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_PHONE_NUMBER_LIST, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_PHONE_NUMBER_LIST, (state, action) => {
      const list = current(state);
      const phoneNumberList = JSON.parse(JSON.stringify(list));
      const phoneItemIndex = phoneNumberList?.findIndex((item) => item?.id === action.payload.phoneItemId);
      if (phoneItemIndex !== -1) {
        const userIndex = phoneNumberList[phoneItemIndex].users?.findIndex(
          (user) => user?.id === action.payload.user?.id
        );
        if (userIndex !== -1) {
          phoneNumberList[phoneItemIndex].users.splice(userIndex, 1);
        } else {
          phoneNumberList[phoneItemIndex].users.push(action.payload.user);
        }
      }
      return phoneNumberList;
    });
});

const twilioSettingsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_TWILIO_SETTINGS
});

const initialCallForwardingState = {
  isCallForwarding: false,
  forwardingPhoneNumber: ''
};

const callForwardingReducer = createReducerOrig(initialCallForwardingState, (builder) => {
  builder
    .addCase(Actions.SET_CALL_FORWARDING_ITEM, (state = initialCallForwardingState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_CALL_FORWARDING_ITEM, (state, action) => {
      const callForwarding = { ...state };
      callForwarding[action.payload.propsName] = action.payload.value;
      return { ...callForwarding };
    })
    .addCase(Actions.CLEAR_CALL_FORWARDING_ITEM, () => {
      const callForwardingState = JSON.parse(JSON.stringify(initialCallForwardingState));
      return callForwardingState;
    });
});

const apiKeysReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_API_KEYS
});

const apiKeyItemReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_API_KEY_ITEM, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_API_KEY_ITEM, (state, action) => {
      const stateItem = { ...state };
      if (stateItem) {
        stateItem[action.payload.propName] = action.payload.value;
      }
      return { ...stateItem };
    });
});

const existingPhoneNumbersReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_EXISTING_PHONE_NUMBERS, (state = {}, action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_EXISTING_PHONE_NUMBER, (state, action) => {
      let numbers = JSON.parse(JSON.stringify(current(state)));
      const index = numbers?.findIndex((x) => x.phoneSid === action.payload.id);
      if (index !== -1) {
        numbers[index][action.payload.propsName] = action.payload.value;
      }
      return numbers;
    });
});

const settingReducer = combineReducers({
  loading: settingLoaderReducer,
  webFormList: webFormListReducer,
  webFormDetail: webFormDetailReducer,
  webFormCode: webFormCodeReducer,
  userAccessData: userAccessDataReducer,
  customizeFields: customizeFieldsReducer,
  subscriptionList: subscriptionListReducer,
  subscriptionPlans: subscriptionPlansReducer,
  importData: importDataReducer,
  settingSignature: settingSignatureReducer,
  integrationSecretKey: integrationSecretKeyReducer,
  phoneNumberList: phoneNumberReducer,
  creditAmount: creditAmountReducer,
  selectedCustomizeField: selectedCustomizeFieldReducer,
  twilioSettings: twilioSettingsReducer,
  callForwarding: callForwardingReducer,
  apiKeyList: apiKeysReducer,
  apiKeyItem: apiKeyItemReducer,
  existingPhoneNumbers: existingPhoneNumbersReducer
});

export default settingReducer;
