import React, { useCallback, useMemo, useState } from 'react';
import DropDown from '../../../components/DropDown/DropDown';
import { LOOKUP_FIELD_EDIT_VIEW_FROM } from '../../../constants/constant';
import useMobileDevice from '../../../hooks/useMobileDevice';
import LookupFieldInputForm from './LookupFieldInputForm';
import { AddView, EditList, FirstSelect, SecondSelect, customstyleadd } from './Styles';

const AddLookupFieldForm = (props) => {
  const { onClose, fields, lookup_id, table } = props;
  const [field, setField] = useState();
  const mobile = useMobileDevice();

  const fieldList = useMemo(() => {
    const field = fields?.map((item) => {
      return { ...item, fieldOption: item?.options, options: undefined };
    });
    return field;
  }, [fields]);

  const onChangeInput = useCallback((value) => {
    value = {
      ...value,
      options: value?.fieldOption,
      value: undefined
    };
    setField(value);
  }, []);

  const onUpdated = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <AddView mobile={mobile}>
      <EditList className={field && 'focus'}>
        <FirstSelect>
          <DropDown
            options={fieldList || []}
            labelField={'label'}
            valueField={'id'}
            value={field}
            onChange={(item) => {
              onChangeInput(item);
            }}
            placeholder={''}
            styles={customstyleadd}
            className='basic-multi-select'
          />
        </FirstSelect>

        <SecondSelect>
          <LookupFieldInputForm
            viewType={LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD}
            showEditLabel={false}
            field={field}
            fieldValue={field}
            onCancel={onClose}
            onUpdated={onUpdated}
            lookup_id={lookup_id}
            tableId={table?.id}
            table={table}
          />
        </SecondSelect>
      </EditList>
    </AddView>
  );
};

export default AddLookupFieldForm;
