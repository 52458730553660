import UserPreferenceSingleton from './UserPreferenceSingleton';

let singleton;
let organizationId;
const STORAGE_KEY_PREFIX = 'OrganizationPreferences_';
const loadDataFromStorage = (props) => {
  try {
    const settings = localStorage.getItem(`${STORAGE_KEY_PREFIX}${props.organizationId}`);
    if (settings) return JSON.parse(settings);
    return {};
  } catch (error) {
    return {};
  }
};
const saveDataToStorage = (props, data) => {
  try {
    localStorage.setItem(`${STORAGE_KEY_PREFIX}${props.organizationId}`, JSON.stringify(data));
  } catch (error) {}
};

const containsKey = (obj, key) => ({}.hasOwnProperty.call(obj || {}, key));

class OrganizationPreferences {
  constructor(props) {
    this.props = props;
    this.data = loadDataFromStorage(props);
  }

  get(key, defaultValue) {
    if (containsKey(this.data, key)) {
      return this.data[key];
    }
    return defaultValue;
  }

  save(settings) {
    saveDataToStorage(this.props, settings);
  }

  set(key, value) {
    this.data = loadDataFromStorage(this.props);
    this.data[key] = value;
    this.save(this.data);
  }

  remove(key) {
    delete this.data[key];
    this.save(this.data);
  }

  loadDataFromStorage() {
    this.data = loadDataFromStorage(this.defaults);
  }

  contains(key) {
    return Object.prototype.hasOwnProperty.call(this.data, key);
  }
}

export default class OrganizationPreferencesSingleton {
  static get TABLES() {
    return 'collections';
  }
  static get FIELDS() {
    return 'fields';
  }
  static get SEGMENTS() {
    return 'segments';
  }
  static get SETTING() {
    return 'setting';
  }
  static get PhoneNumbers() {
    return 'phoneNumbers';
  }
  static get ONBOARDING() {
    return 'onBoarding';
  }
  static get LAST_USED_EMAIL_ACCOUNT_ID() {
    return 'defaultEmailAccountId';
  }

  constructor(props) {
    this.orgPreferences = new OrganizationPreferences(props);
  }

  static getInstance() {
    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (org) {
      if (!singleton || organizationId !== org.id) {
        singleton = new OrganizationPreferencesSingleton({ organizationId: org.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    organizationId = undefined;
    singleton = undefined;
  }

  getDefaultEmailAccountId() {
    return this.orgPreferences.get(OrganizationPreferencesSingleton.LAST_USED_EMAIL_ACCOUNT_ID, undefined);
  }

  setDefaultEmailAccountId(value) {
    return this.orgPreferences.set(OrganizationPreferencesSingleton.LAST_USED_EMAIL_ACCOUNT_ID, value);
  }

  getTables() {
    return this.orgPreferences.get(OrganizationPreferencesSingleton.TABLES, []);
  }

  setTables(value) {
    return this.orgPreferences.set(OrganizationPreferencesSingleton.TABLES, value);
  }

  getFields() {
    return this.orgPreferences.get(OrganizationPreferencesSingleton.FIELDS, []);
  }

  setFields(value) {
    return this.orgPreferences.set(OrganizationPreferencesSingleton.FIELDS, value);
  }

  getSegments() {
    return this.orgPreferences.get(OrganizationPreferencesSingleton.SEGMENTS, []);
  }

  getSettingValue(tableId, propName) {
    const settings = this.orgPreferences.get(OrganizationPreferencesSingleton.SETTING, {});
    if (settings[tableId]) {
      return settings[tableId][propName];
    }
    return undefined;
  }

  setSettingValue(tableId, propName, value) {
    const settings = this.orgPreferences.get(OrganizationPreferencesSingleton.SETTING, {});
    if (!settings[tableId]) settings[tableId] = {};
    settings[tableId][propName] = value;
    this.orgPreferences.set(OrganizationPreferencesSingleton.SETTING, settings);
  }

  getSegmentsByTable(tableId) {
    if (!tableId) return [];
    let results = this.getSegments().find((x) => x.tableId === tableId);
    if (results) return JSON.parse(JSON.stringify(results?.items));
    return [];
  }

  setSegments(value) {
    return this.orgPreferences.set(OrganizationPreferencesSingleton.SEGMENTS, value);
  }

  getPhoneNumbers() {
    return this.orgPreferences.get(OrganizationPreferencesSingleton.PhoneNumbers, []);
  }

  setPhoneNumbers(value) {
    return this.orgPreferences.set(OrganizationPreferencesSingleton.PhoneNumbers, value);
  }

  getOnBoarding() {
    return this.orgPreferences.get(OrganizationPreferencesSingleton.ONBOARDING, {});
  }

  setOnBoarding(value) {
    return this.orgPreferences.set(OrganizationPreferencesSingleton.ONBOARDING, value);
  }

  setOnBoardingValue(propName, value) {
    let onBoarding = this.orgPreferences.get(OrganizationPreferencesSingleton.ONBOARDING, {});
    if (!onBoarding) onBoarding = {};
    onBoarding[propName] = value;
    const isAllStepCompleted = Object.values(onBoarding).every((value) => value);
    if (isAllStepCompleted) {
      onBoarding = {
        completed: true
      };
    }
    this.orgPreferences.set(OrganizationPreferencesSingleton.ONBOARDING, onBoarding);
    return onBoarding;
  }

  get(key, defaultValue = null) {
    return this.orgPreferences.get(key, defaultValue);
  }

  set(key, value) {
    this.orgPreferences.set(key, value);
  }

  clearStoredData() {
    this.orgPreferences.remove(OrganizationPreferencesSingleton.TABLES);
    this.orgPreferences.remove(OrganizationPreferencesSingleton.FIELDS);
    this.orgPreferences.remove(OrganizationPreferencesSingleton.SEGMENTS);
    this.orgPreferences.remove(OrganizationPreferencesSingleton.SETTING);
    this.orgPreferences.remove(OrganizationPreferencesSingleton.ONBOARDING);
  }

  clearAllOrgData() {
    for (var key in localStorage) {
      if (key.startsWith(STORAGE_KEY_PREFIX)) localStorage.removeItem(key);
    }
  }
}
