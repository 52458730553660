import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../../components/Menu';
import Input from '../../../components/Input/Input';
import {
  addUserInPhoneList,
  deletePhoneNumber,
  deleteUserFromPhoneList,
  getCreditAmount,
  getPhoneNumbers,
  getTwilioSettings,
  makeNumberAsPrimary,
  updateCallRecordingStatus
} from '../../../services/settingService';
import { getFormattedPhoneNumber } from '../../../global/Helper';
import UserSelectionMenu from '../../../components/UserMenu/UserSelectionMenu';
import { updatePhoneNumberList } from '../../../actions/settingActions';
import { updateCurrentOrganization } from '../../../actions/organizationActions';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import TwilioService from '../../../services/twilioService';
import { openIntercomChat, trackAnalyticActivity } from '../../../services/analyticsService';
import Validation from '../../../components/Validation/Validation';
import { currencyWithDecimal, hasProduction, isEmpty } from '../../../helpers/common';
import Modal from '../../../components/Modal';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import TwilioImg from '../../../assets/images/twilio.png';
import SalesCampLogo from '../../../assets/images/salescamp_logo.png';
import { trackSupportAnalyticActivity } from '../../../services/dashboardService';
import {
  MainList,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  SecondList,
  IconView,
  ContainBoxTwo,
  FirstRow,
  LeftList,
  TextCall,
  RightList,
  WarningList,
  WarnText,
  SupportView,
  Warncaption,
  Knowt,
  SupportT,
  PopupView,
  AmountText,
  ModalFormWrapper,
  ButtonWrapper,
  RightCredit,
  Transaction,
  CreditBox,
  CreditWrapper,
  ModalStyle,
  HeaderWrapper,
  TitleText,
  TitleView,
  AmountWrapper,
  ValidationWrapper,
  ButtonMargin,
  ButtonCredit,
  MobileButton,
  MainMobileButton,
  TwillioImg,
  LogoBlock,
  TopRow,
  TwillioWrapper,
  BottomRow,
  TwillioSpan,
  LearnMore
} from './styles';
import AddPhoneNumberModal from './AddPhoneNumberModal';
import VerifyPhoneNumberContainer from './VerifyPhoneNumberContainer/VerifyPhoneNumberContainer';
import TwilioSettingsModal from './TwillioSettingsModal/TwilioSettingsModal';
import CallForwardingModal from './CallForwardingModal/CallForwardingModal';
import TwilioAccountConnect from './TwilioAccountConnect/TwilioAccountConnect';

const PhoneSettings = () => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id, users: orgUsers } = organization;
  const settingSelector = useSelector((state) => state.setting);
  const { phoneNumberList, loading, creditAmount, twilioSettings } = settingSelector;
  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isNewNumberModalOpen, setIsNewNumberModalOpen] = useState(false);
  const [isVerifyNumberContainerOpen, setIsVerifyNumberContainerOpen] = useState(false);
  const [isOpenTwilioSettingsModal, setIsOpenTwilioSettingsModal] = useState(false);
  const [isCallForwardingModalOpen, setIsCallForwardingModalOpen] = useState(false);
  const [isTwillioConnectModal, IsSetTwillioConnectModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [isCallRecordingEnabled, setIsCallRecordingEnabled] = useState(organization?.settings?.isCallRecording);
  const [externalNumber, setExternalNumber] = useState();
  const [validationError, setValidationError] = useState({});
  const [isOpenAddCreditModal, setIsOpenAddCreditModal] = useState(false);
  const [amount, setAmount] = useState('');

  const isPhoneCallAccess = useMemo(() => {
    return organization?.isPhoneCall;
  }, [organization?.isPhoneCall]);

  const dispatch = useDispatch();
  const history = useHistory();

  const loadData = useCallback(() => {
    dispatch(getPhoneNumbers(organization_id, true));
    if (isPhoneCallAccess) {
      dispatch(getCreditAmount(organization_id));
    }
  }, [isPhoneCallAccess, dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const hasPhoneNumber = useMemo(() => {
    return phoneNumberList?.length > 0;
  }, [phoneNumberList?.length]);

  useEffect(() => {
    if (!isPhoneCallAccess && !hasPhoneNumber) {
      dispatch(getTwilioSettings(organization_id));
    }
  }, [dispatch, hasPhoneNumber, isPhoneCallAccess, loadData, organization_id]);

  const trackPhoneActionAnalyticActivity = (action, phoneNumber) => {
    try {
      if (phoneNumber) {
        trackAnalyticActivity('settings - phone: action', { action: action, phone: phoneNumber });
      } else {
        trackAnalyticActivity('settings - phone: action', { action: action });
      }
    } catch (e) {
      console.log('track settings - phone: action Error', e);
    }
  };

  const trackPhoneRecordingStatusAnalyticActivity = useCallback((status) => {
    try {
      let action;
      if (status) {
        action = 'enabled';
      } else {
        action = 'disabled';
      }
      trackAnalyticActivity('settings - phone: recording updated', { action: action });
    } catch (e) {
      console.log('track settings - phone: recording updated Error', e);
    }
  }, []);

  const onCloseHelpMenu = useCallback(() => {
    setIsHelpOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenHelpMenu = useCallback((e) => {
    e.stopPropagation();
    setIsHelpOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMoreMenu = useCallback(() => {
    setIsMoreOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenMoreMenu = useCallback((e, itemId) => {
    e.stopPropagation();
    setIsMoreOpen(true);
    setSelectedItem(itemId);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onOpenNewNumberModal = useCallback(() => {
    trackPhoneActionAnalyticActivity('add');
    setIsNewNumberModalOpen(true);
  }, []);

  const onCloseNewNumberModal = useCallback(() => {
    setIsNewNumberModalOpen(false);
  }, []);

  const onOpenVerifyNumberContainer = useCallback(
    ({ countryCode, phoneNumber }) => {
      if (countryCode && phoneNumber) {
        setExternalNumber({ code: countryCode, number: phoneNumber });
      }
      onCloseNewNumberModal();
      setIsVerifyNumberContainerOpen(true);
    },
    [onCloseNewNumberModal]
  );

  const onCloseVerifyNumberContainer = useCallback(() => {
    setIsVerifyNumberContainerOpen(false);
  }, []);

  const onCloseTwilioSettingsModal = useCallback(() => {
    setIsOpenTwilioSettingsModal(false);
  }, []);

  const onOpenTwilioSettingsModal = useCallback(() => {
    setIsOpenTwilioSettingsModal(true);
  }, []);

  const onOpenCallForwardingModal = useCallback(() => {
    setIsCallForwardingModalOpen(true);
    onCloseMoreMenu();
  }, [onCloseMoreMenu]);

  const onCloseCallForwardingModal = useCallback(() => {
    setIsCallForwardingModalOpen(false);
  }, []);

  const getUserListToDisplay = useCallback(
    (users) => {
      const prevUsers = users?.filter((item) => !orgUsers.find((obj) => obj.id === item?.id));
      const prevUsersList = prevUsers?.map((user) => {
        return { ...user, isSelected: true };
      });
      const currentUsers = orgUsers?.map((user) => {
        const isSelectedIndex = users?.findIndex((item) => item?.id === user?.id);
        if (isSelectedIndex !== -1) {
          return {
            ...user,
            isSelected: true
          };
        } else {
          return {
            ...user,
            isSelected: false
          };
        }
      });
      return [...prevUsersList, ...currentUsers];
    },
    [orgUsers]
  );

  const onChangeUserSelection = useCallback(
    (val, userId, phoneNumberItemId, phoneNumber) => {
      trackPhoneActionAnalyticActivity('assign', phoneNumber);
      const user = orgUsers?.find((item) => item?.id === userId);
      user && dispatch(updatePhoneNumberList({ phoneItemId: phoneNumberItemId, user: user }));
      if (val) {
        dispatch(deleteUserFromPhoneList(organization_id, phoneNumberItemId, userId));
      } else {
        const payload = {
          userId: userId
        };
        dispatch(addUserInPhoneList(organization_id, phoneNumberItemId, payload));
      }
    },
    [dispatch, organization_id, orgUsers]
  );

  const onMakeNumberAsPrimary = useCallback(async () => {
    trackPhoneActionAnalyticActivity('mark as primary', selectedItem?.phoneNumber);
    onCloseMoreMenu();
    const result = await dispatch(makeNumberAsPrimary(organization_id, selectedItem?.id));
    if (result) {
      dispatch(TwilioService.getInstance().initCallingService(true));
    }
  }, [dispatch, onCloseMoreMenu, organization_id, selectedItem]);

  const onDeletePhoneNumber = useCallback(() => {
    trackPhoneActionAnalyticActivity('delete', selectedItem?.phoneNumber);
    onCloseMoreMenu();
    dispatch(deletePhoneNumber(organization_id, selectedItem?.id));
  }, [dispatch, onCloseMoreMenu, organization_id, selectedItem]);

  const OnUpdateCallRecordingStatus = useCallback(
    (updateVal) => {
      setIsCallRecordingEnabled(updateVal);
      dispatch(updateCurrentOrganization({ propName: 'isCallRecording', value: updateVal }));
      const result = dispatch(updateCallRecordingStatus(organization_id, updateVal));
      if (result) {
        trackPhoneRecordingStatusAnalyticActivity(updateVal);
      }
    },
    [dispatch, trackPhoneRecordingStatusAnalyticActivity, organization_id]
  );
  const onOpenAddCreditModal = useCallback(() => {
    setIsOpenAddCreditModal(true);
  }, []);

  const onCloseAddCreditModal = useCallback(() => {
    setIsOpenAddCreditModal(false);
    setAmount('');
  }, []);

  const openTwilioAccount = useCallback(() => {
    IsSetTwillioConnectModal(true);
  }, []);
  const openTwilioAccountModal = useCallback(() => {
    onCloseTwilioSettingsModal();
    openTwilioAccount();
  }, [onCloseTwilioSettingsModal, openTwilioAccount]);

  const closeTwilioAccount = useCallback(() => {
    IsSetTwillioConnectModal(false);
  }, []);

  const onClickAddCredit = useCallback(
    (e) => {
      e.preventDefault();
      let validationError = {};
      setValidationError({});
      if (!amount || amount.trim() === '') {
        validationError['amount'] = 'Amount Required';
      }
      if (!isEmpty(validationError)) {
        setValidationError(validationError);
        return;
      }
    },
    [amount]
  );

  const isAccess = useMemo(() => {
    return isPhoneCallAccess || hasPhoneNumber || !isEmpty(twilioSettings);
  }, [hasPhoneNumber, isPhoneCallAccess, twilioSettings]);

  return (
    <>
      <MainList>
        {mobile && (
          <MobileTitleBar hasNavBar={true} hasTitle={true}>
            {hasPhoneNumber && (
              <MainMobileButton>
                <MobileButton
                  iconName='icon-pluse'
                  fontSize='14'
                  iconColor='var(--white-color)'
                  strokeWidth={2}
                  size='small'
                  onClick={onOpenNewNumberModal}
                />
              </MainMobileButton>
            )}
          </MobileTitleBar>
        )}
        <MainWrap mobile={mobile}>
          {mobile ? null : (
            <HeadView>
              <Title>Phone Settings</Title>
              {hasPhoneNumber && (
                <Button
                  onClick={() => {
                    onOpenNewNumberModal();
                  }}
                  title='Add Number'
                />
              )}
            </HeadView>
          )}
          {isAccess && !loading && phoneNumberList?.length === 0 && (
            <TwillioWrapper>
              <TopRow>
                <LogoBlock>
                  <TwillioImg src={TwilioImg}></TwillioImg>
                </LogoBlock>
              </TopRow>
              <BottomRow>
                <ButtonWrapper>
                  <Button primary title='Add Numner' onClick={onOpenNewNumberModal} />
                </ButtonWrapper>
                <TwillioSpan>Your Twilio account is connected! Link your existing number or buy new one.</TwillioSpan>
              </BottomRow>
            </TwillioWrapper>
          )}
          {isAccess && loading && phoneNumberList?.length === 0 ? (
            <ContainBox>Loading...</ContainBox>
          ) : (
            <ContainBox hasPhoneNumber={hasPhoneNumber}>
              {phoneNumberList &&
                phoneNumberList?.length > 0 &&
                phoneNumberList?.map((item) => {
                  const userList = getUserListToDisplay(item?.users);
                  return (
                    <ListBox key={item?.id} mobile={mobile}>
                      <FirstList mobile={mobile}>
                        <SubText mobile={mobile}>{`${item?.countryCode} ${getFormattedPhoneNumber(
                          item?.number
                        )}`}</SubText>
                        <UserSelectionMenu
                          userList={userList}
                          itemId={item?.id}
                          onChange={(val, userId, phoneItemId) => {
                            onChangeUserSelection(val, userId, phoneItemId, item?.phoneNumber);
                          }}
                          currentUserId={currentUser?.id}
                        />
                        {item?.isPrimary && <CaptionText mobile={mobile}>Primary Number</CaptionText>}
                      </FirstList>
                      <SecondList>
                        <IconView
                          onClick={(e) => {
                            onOpenMoreMenu(e, item);
                          }}>
                          <SVGIcon name='icon-more' fill='var(--gray-color)' size={24} />
                        </IconView>
                      </SecondList>
                    </ListBox>
                  );
                })}
            </ContainBox>
          )}
          {hasPhoneNumber && organization?.isAdmin && (
            <>
              <ContainBoxTwo>
                <FirstRow>
                  <LeftList>
                    <TextCall>Call Recording</TextCall>
                    <IconView onClick={onOpenHelpMenu}>
                      <SVGIcon name='icon-information' fill='var(--primary-color)' size={22} />
                    </IconView>
                  </LeftList>
                  <RightList>
                    <input
                      className='tgl tgl-light'
                      id='cb1'
                      type='checkbox'
                      checked={isCallRecordingEnabled}
                      onChange={() => {
                        isCallRecordingEnabled
                          ? OnUpdateCallRecordingStatus(!isCallRecordingEnabled)
                          : ConfirmDialog({
                              iconName: 'icon-video',
                              closeIcon: 'icon-close',
                              title: 'Start Recording',
                              message: `Are you sure you want to Start Recording?\n
                      Call Recording is excluded from Call Charges. So, this may cause you some Extra Charges`,
                              onOk: () => {
                                OnUpdateCallRecordingStatus(!isCallRecordingEnabled);
                              }
                            });
                      }}
                    />
                    <label className='tgl-btn' htmlFor='cb1'>
                      {' '}
                    </label>
                  </RightList>
                </FirstRow>
                <WarningList>
                  <WarnText>
                    Legal Warning! Record Call on your own Responsibility, Our Organization is not Responsible for any
                    legal issues.
                  </WarnText>
                </WarningList>
              </ContainBoxTwo>
              <CreditBox className={hasProduction() ? 'inDevelopment' : ''} mobile={mobile}>
                <CreditWrapper mobile={mobile}>
                  <LeftList>
                    <TextCall>Credits</TextCall>
                    <IconView onClick={onOpenHelpMenu}>
                      <SVGIcon name='icon-information' fill='var(--primary-color)' size={22} />
                    </IconView>
                  </LeftList>
                  <AmountText mobile={mobile}>${currencyWithDecimal(creditAmount?.amount)}</AmountText>
                </CreditWrapper>
                <RightList>
                  <Button onClick={onOpenAddCreditModal} secondary title='Add Credit' />
                  <RightCredit
                    onClick={() => {
                      history.push('/settings/phone-settings/transaction');
                    }}>
                    <Transaction>See Transaction</Transaction>
                    <SVGIcon name='icon-big-right-arrow' fill='var(--primary-color)' size={10} strokeWidth={2} />
                  </RightCredit>
                </RightList>
              </CreditBox>
            </>
          )}
          {!hasPhoneNumber && !isPhoneCallAccess && isEmpty(twilioSettings) && (
            <TwillioWrapper>
              <TopRow>
                <LogoBlock>
                  <TwillioImg src={TwilioImg}></TwillioImg>
                </LogoBlock>
                <SVGIcon name='icon-two-way' size={40} fill='var(--titlebar-bg)' />
                <LogoBlock>
                  <TwillioImg src={SalesCampLogo}></TwillioImg>
                </LogoBlock>
              </TopRow>
              <BottomRow>
                {organization?.isAdmin && (
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        onOpenTwilioSettingsModal();
                      }}
                      primary
                      title='Connect Twilio'
                    />
                  </ButtonWrapper>
                )}
                <TwillioSpan>
                  Salescmap’s Phone feature rely on Twilio, Please connect your twilio account with Salescamp,{' '}
                  <LearnMore
                    href='https://pixerhq.notion.site/
                  Twilio-Setting-For-Client-6ca4e060d7514a479da24ca51dd1a40d'
                    target='_blank'>
                    Learn more
                  </LearnMore>
                </TwillioSpan>
                <TwillioSpan>
                  Need help?{' '}
                  <LearnMore
                    onClick={() => {
                      // if (onClose) onClose();
                      openIntercomChat();
                      trackSupportAnalyticActivity('chat with us clicked');
                    }}>
                    Chat with us!
                  </LearnMore>
                </TwillioSpan>
              </BottomRow>
            </TwillioWrapper>
          )}
        </MainWrap>
      </MainList>

      {isHelpOpen && (
        <Menu
          id='simple-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isHelpOpen}
          onClose={onCloseHelpMenu}
          getContentAnchorEl={null}
          position='left'
          marginTop={25}
          positionVertical='top'
          width={196}>
          <SupportView>
            <Warncaption>
              You required credit to do call, sms and buy new number.<Knowt> Know more</Knowt>
            </Warncaption>
            <a
              href={`https://www.salescamp.app/help-category/call-and-sms?user=${currentUser?.email}`}
              target='_blank'
              rel='noreferrer'>
              <SupportT onClick={onCloseHelpMenu}>Get Support</SupportT>
            </a>
          </SupportView>
        </Menu>
      )}
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMoreOpen}
        onClose={onCloseMoreMenu}
        getContentAnchorEl={null}
        position='left'
        marginTop={25}
        positionVertical='top'
        MenuListProps={{ disablePadding: true }}
        width={175}>
        <PopupView>
          {!selectedItem?.isPrimary && (
            <MenuItem
              iconName='icon-checked-round'
              color='var(--gray-icon-color)'
              fontSize='18px'
              onClick={onMakeNumberAsPrimary}
              text='Make as Primary'
            />
          )}
          {/* <StyledMenuItem>
            <InputWrapperModal>
              <InputLabel>
                <Status>Voicemail greeting</Status>
              </InputLabel>
            </InputWrapperModal>
          </StyledMenuItem> */}
          {selectedItem?.isTwilioNumber && (
            <MenuItem
              iconName='icon-two-way'
              fontSize='18px'
              color='var(--gray-icon-color)'
              onClick={onOpenCallForwardingModal}
              text='Call Forwarding'
            />
          )}
          <MenuItem
            iconName='icon-delete'
            fontSize='18px'
            color='var(--gray-icon-color)'
            text='Delete Number'
            onClick={() => {
              ConfirmDialog({
                iconName: 'icon-delete',
                closeIcon: 'icon-close',
                title: 'Delete',
                message: `Are you sure want to delete this number ${selectedItem?.phoneNumber} ?`,
                onOk: () => {
                  onDeletePhoneNumber();
                }
              });
            }}
          />
        </PopupView>
      </Menu>
      <Modal style={ModalStyle} open={isNewNumberModalOpen} onClose={onCloseNewNumberModal}>
        <AddPhoneNumberModal onClose={onCloseNewNumberModal} openVerifyNumberContainer={onOpenVerifyNumberContainer} />
      </Modal>
      <Modal open={isVerifyNumberContainerOpen} onClose={onCloseVerifyNumberContainer}>
        {isVerifyNumberContainerOpen && (
          <VerifyPhoneNumberContainer externalNumber={externalNumber} onClose={onCloseVerifyNumberContainer} />
        )}
      </Modal>
      <Modal open={isOpenTwilioSettingsModal} onClose={onCloseTwilioSettingsModal}>
        <TwilioSettingsModal
          onClose={onCloseTwilioSettingsModal}
          hasPhoneNumber={hasPhoneNumber}
          openTwilioAccountModal={openTwilioAccountModal}
        />
      </Modal>
      <Modal open={isTwillioConnectModal} onClose={closeTwilioAccount}>
        <TwilioAccountConnect onClose={closeTwilioAccount} onSave={loadData} />
      </Modal>
      <Modal id='simple' open={isOpenAddCreditModal} onClose={onCloseAddCreditModal}>
        <ModalFormWrapper onSubmit={onClickAddCredit}>
          <TitleView>
            <HeaderWrapper>
              <SVGIcon name='icon-cash-protection' fill='var(--gray-icon-color)' size={22} />
              <TitleText>Amount</TitleText>
            </HeaderWrapper>
            <IconView onClick={onCloseAddCreditModal}>
              <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
            </IconView>
          </TitleView>
          <AmountWrapper>
            <Input
              title='Amount'
              isPrimarySmall
              name='Amount'
              type='number'
              placeholder='Enter Amount'
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
            {validationError?.amount && (
              <ValidationWrapper>
                <Validation error={validationError?.amount}></Validation>
              </ValidationWrapper>
            )}
            <ButtonMargin>
              <ButtonCredit>
                <Button title='Add Credit' type='submit' />
              </ButtonCredit>
              <Button secondary title='Cancel' type='button' onClick={onCloseAddCreditModal} />
            </ButtonMargin>
          </AmountWrapper>
        </ModalFormWrapper>
      </Modal>
      <Modal open={isCallForwardingModalOpen} onClose={onCloseCallForwardingModal}>
        <CallForwardingModal onClose={onCloseCallForwardingModal} phone={selectedItem} />
      </Modal>
    </>
  );
};

export default PhoneSettings;
