import styled, { css } from 'styled-components';
import { Header3, Header4, SubHeader3, Text1 } from '../../styles';
import Button from '../../components/atom/Button/Button';
import SVGIcon from '../../assets/svg/SVGIcon';
const ManeWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const RightWrapper = styled.div`
  width: 100%;
  margin-left: 215px;
`;
const MainContain = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;
const HeaderWrapper = styled.div`
  top: 0;
  right: 17px;
  left: 0px;
  z-index: 1;
  ${(props) =>
    props.mobile
      ? css`
          background: none;
          position: static;
          padding: 8px 0px 24px 0px;
        `
      : css`
          background: var(--linear-gradient-white);
          position: sticky;
          padding: 24px 0px 24px 0px;
        `}
`;
const HeaderView = styled.div`
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          background-color: none;
        `
      : css`
          background-color: var(--white-color);
        `}
`;
const RowView = styled.div`
  display: flex;
  align-items: center;
`;
const RowHeaderView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.h5`
  ${SubHeader3};
  color: var(--primary-color);
  margin: 0 0 0 12px;
`;
const WelText = styled.h4`
  ${Header3};
  color: var(--dark-color);
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 0 3px 0;
        `
      : css`
          margin: 16px 0 3px 0;
        `}
`;
const CaptionText = styled.p`
  margin: 0;
  ${Text1};
  color: var(--gray-color);
`;
const WelView = styled.div`
  height: 100%;
  overflow: auto;
  ${(props) =>
    props.mobile &&
    css`
      padding: 0 16px;
      overflow-y: scroll;
    `}
`;
const ContainList = styled.div`
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
`;
const RowList = styled.div`
  background: var(--white-color);
  border-radius: 6px;
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px 16px;
          margin: 0px auto 16px;
          border: none;
          box-shadow: 0px 1px 3px var(--mobile-box-shadow);
        `
      : css`
          padding: 16px;
          margin: 0px auto 16px;
          border: 1px solid var(--gray-border-color);
          box-shadow: 0px 1px 2px var(--pagination-box-shadow);
        `}
  &:hover {
    box-shadow: 0px 1px 3px var(--inbox-hover-color);
  }
`;
const ListView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile &&
    css`
      align-items: flex-start;
    `}
`;
const Leftcontain = styled.div``;
const ListTextView = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;
const MobileButton = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      button {
        padding: 8px;
      }
    `}
`;
const UserName = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--gray-color);
`;
const UserEmailTwo = styled.div`
  ${Text1};
  color: var(--gray-color);
  display: flex;
  align-items: center;
`;
const CaptionList = styled.div``;
const RightContain = styled.div`
  button {
    svg {
      margin-right: 10px;
    }
  }
  ${(props) =>
    props.mobile
      ? css`
          display: none;
        `
      : css`
          display: flex;
          align-items: center;
        `}
`;
const ViewIcon = styled.div`
  margin-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MobileIcon = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
const HeadText = styled.h3`
  ${Header4};
  color: var(--dark-color);
  margin: 0 0 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const LeftRow = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      align-items: flex-start;
    `}
`;
const BottomList = styled.div`
  border-left: 1px solid var(--gray-border-color);
  margin-left: 15px;
  padding-left: 15px;
`;
const ListText = styled.div`
  ${Text1};
  color: var(--gray-color);
  margin: 0;
  padding: 8px 21px;
  width: 100%;
  overflow: auto;
  p {
    margin: 0;
  }
`;
const RightBtn = styled.div`
  margin-left: 12px;
`;
const SecondList = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  padding: 12px;
  margin: 10px 0 0 48px;
  cursor: pointer;
`;
const FirstText = styled.h5`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
`;
const SecondText = styled.p`
  margin: 4px 0 0 0;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
`;
const MainContact = styled.div`
  position: relative;
  outline: none;
`;
const TitleText = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 12px;
  display: block;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const Form = styled.form`
  padding: 12px;
`;
const FirstList = styled.div`
  width: 100%;
  .DatePickerWrapper {
    width: 100%;
  }
`;
const SecondList2 = styled.div`
  width: 100%;
  margin-top: 12px;
  .TimePickerWrapper {
    width: 100%;
  }
`;
const LableView = styled.div`
  margin-bottom: 4px;
`;
const LableText = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const BottomView = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 32px;
  button {
    width: 100%;
    justify-content: center;
  }
`;
const Iconbg = styled(SVGIcon).attrs((props) => ({
  fill: 'var(--white-color)'
}))``;
const IconWrapper = styled.div`
  flex: 0 0 auto;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 38px;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px var(--inbox-icon-color);
`;
const IconBack = styled.div`
  cursor: pointer;
`;
const RowBox = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      padding: 0px 16px 16px 16px;
    `}
`;
const BtnView = styled.a``;
const DemoView = styled.div`
  margin-top: 12px;
`;
const Caption = styled.h3`
  ${Text1};
  color: var(--gray-color);
  margin: 0px;
`;
const HelpText = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const LeftList = styled.div`
  display: flex;
  align-items: center;
`;
const LineList = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px 16px;
  margin: 0px auto 16px auto;
`;
const ListContain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RightList = styled.div`
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
    line-height: 20px;
  }
`;
const UserData = styled.a`
  ${Header4};
  color: var(--dark-color);
`;
const NavigationTextWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const StaticColumn = styled.span`
  ${(props) =>
    props.mobile &&
    css`
      display: none;
    `}
  ${Text1};
  color: var(--gray-color);
  margin: 0;
  border-radius: 4px;
  padding: 1px 4px;
  width: fit-content;
`;
const DropDownBox = styled.div`
  padding: 6px 0;
  i {
    margin-right: 12px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 16px;
  div:not(:first-child) {
    margin-left: 13px;
  }
  button {
    svg {
      margin-right: 10px;
    }
  }
`;
const UserDetails = styled.div`
  margin-left: 20px;
`;
const UserEmail = styled.h4`
  ${Header4}
  color:var(--dark-color);
  margin: 0;
`;
const InboxSuggestion = styled.div`
  display: flex;
  align-items: flex-start;
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const IconButton = styled(Button)`
  svg {
    margin-right: 10px;
  }
`;
const TitleMereg = styled.span`
  display: inline-block;
  ${Header4}
  color:var(--dark-color);
  margin-bottom: 2px;
  ${(props) =>
    props.mobile &&
    css`
      margin-right: 10px;
    `}
`;
const DuplicationTitle = styled.span`
  display: inline-block;
  ${Text1}
  color:var(--gray-color);
`;
const DuplicationHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 24px 0;
`;
const HeaderTitle = styled.span`
  display: inline-block;
  color: var(--dark-color);
  margin-left: 16px;
  ${(props) =>
    props.mobile
      ? css`
          ${Header3}
        `
      : css`
          ${SubHeader3}
        `}
`;
const MobileRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    border: none;
    line-height: 18px;
  }
`;
const ContactListIcon = styled.div`
  svg {
    display: block;
  }
`;
const HeaderMergeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 24px 24px;
`;
const HeaderMergeLeft = styled.div`
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
  }
`;
const HeaderMergeRight = styled.div``;
const MergeDuplicationTitle = styled.span`
  display: inline-block;
  ${SubHeader3}
  color:var(--dark-color);
  margin-left: 6px;
`;
const InputWrapper = styled.div`
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const FastCol = styled.div`
  display: flex;
  align-items: center;
  .input {
    margin: 0;
    width: 14px;
    height: 14px;
  }
`;
const InputData = styled.div`
  display: flex;
  align-items: center;
`;
const ModalStyle = {
  width: '640px'
};
const OpenThread = styled.span`
  ${SubHeader3};
  color: var(--dark-color);
  margin: 0 0 0 16px;
  cursor: pointer;
  :hover {
    color: var(--primary-color);
  }
`;

export {
  InputData,
  FastCol,
  InputWrapper,
  IconBack,
  DemoView,
  ListContain,
  RightList,
  LineList,
  UserData,
  LeftList,
  HelpText,
  Caption,
  BtnView,
  ManeWrapper,
  RightWrapper,
  MainContain,
  Title,
  RowView,
  WelText,
  CaptionText,
  HeaderView,
  HeaderWrapper,
  WelView,
  ContainList,
  RowList,
  ListView,
  Leftcontain,
  LeftRow,
  ListTextView,
  UserName,
  RightContain,
  ViewIcon,
  CaptionList,
  HeadText,
  BottomList,
  ListText,
  RightBtn,
  SecondList,
  FirstText,
  SecondText,
  Iconbg,
  MainContact,
  TitleText,
  TitleView,
  Form,
  FirstList,
  SecondList2,
  BottomView,
  LableView,
  LableText,
  RowBox,
  UserEmailTwo,
  RowHeaderView,
  NavigationTextWrapper,
  StaticColumn,
  DropDownBox,
  ButtonWrapper,
  UserDetails,
  UserEmail,
  InboxSuggestion,
  MenuItemWrapper,
  MobileIcon,
  IconWrapper,
  TitleMereg,
  DuplicationTitle,
  DuplicationHeaderWrapper,
  HeaderTitle,
  HeaderMergeWrapper,
  HeaderMergeLeft,
  HeaderMergeRight,
  MergeDuplicationTitle,
  ModalStyle,
  IconButton,
  ContactListIcon,
  OpenThread,
  MobileButton,
  MobileRight
};
