import styled from 'styled-components';
import Button from '../../components/atom/Button/Button';
import { Header2, Header4, Text1, Text2 } from '../../styles';

const ModalStyle = {
  width: '720px'
};

const MainContact = styled.div`
  width: 720px;
  background: var(--white-color);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.34);
  border-radius: 4px;
  outline: none;
  z-index: 9999;
`;
const BlueWrapper = styled.div`
  background-color: var(--primary-color);
  padding: 40px;
  text-align: center;
  border-radius: 4px 4px 0px 0px;
  position: relative;
`;
const IconView = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  svg {
    display: block;
  }
`;
const HeadView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TitleText = styled.h3`
  margin: 0 0 0 8px;
  ${Header2};
  color: var(--white-color);
`;
const CaptionText = styled.p`
  margin: 14px auto 24px;
  ${Text1};
  color: var(--gainsBoro-color);
  max-width: 296px;
  width: 100%;
`;
const BtnView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UpgradeButton = styled(Button)`
  padding: 8px 16px;
  background-color: var(--white-color);
  color: var(--primary-color);
  margin-right: 12px;
  box-shadow: none;
`;
const ContainWrapper = styled.div`
  padding: 24px 0 24px 0;
  position: relative;
  .carousel .slide {
    min-width: 55% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
`;
const Slider = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HeadList = styled.h4`
  margin: 0 0 24px 0;
  ${Header4}
  color: var(--dark-color);
`;
const ImgView = styled.div`
  width: 300px;
`;
const CaptionView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;
const CaptionImg = styled.p`
  margin: 0;
  ${Text2}
  color: var(--gray-color);
  text-align: center;
  padding: 0px 10px;
`;
const SliderLeftChangeIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: 44%;
  left: 16px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  box-shadow: 0px 1px 2px var(--inbox-icon-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 3px;
  cursor: pointer;
`;
const SliderRightChangeIcon = styled.div`
  position: absolute;
  z-index: 2;
  top: 44%;
  right: 16px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  box-shadow: 0px 1px 2px var(--inbox-icon-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 3px;
  cursor: pointer;
`;
const RequestTrialButton = styled(Button)`
  padding: 8px 16px;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid var(--white-color);
`;
const LBtn = styled.div`
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  width: 250px;
  height: 100%;
  position: absolute;
  .fqnzVl {
    position: relative;
    display: inline-block;
    width: 250px;
    height: 100%;
  }
`;
const RBtn = styled.div`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 1;
  width: 250px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  .fqnzVl {
    position: relative;
    display: inline-block;
    width: 250px;
    height: 100%;
  }
`;

export {
  MainContact,
  BlueWrapper,
  IconView,
  HeadView,
  TitleText,
  CaptionText,
  UpgradeButton,
  ContainWrapper,
  BtnView,
  ProfileImg,
  Slider,
  HeadList,
  ImgView,
  CaptionImg,
  CaptionView,
  LBtn,
  RBtn,
  RequestTrialButton,
  SliderLeftChangeIcon,
  SliderRightChangeIcon,
  ModalStyle
};
