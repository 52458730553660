import { LookupFieldTypes, LOOKUP_FIELD_CUSTOM_ID } from '../constants/constant';
import UserPreferenceSingleton from './../helpers/UserPreferenceSingleton';
import OrgPreferenceSingleton from './../helpers/OrganizationPreferencesSingleton';
import { getLookupFieldValueForDisplay } from './../helpers/common';
import LocalDbService, { LOOKUP_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton;
let organizationId;

export default class LookupCacheService extends LocalDbService {
  constructor(props) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = LOOKUP_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (org) {
      if (!singleton || organizationId !== org.id) {
        organizationId = org.id;
        singleton = new LookupCacheService({ organizationId: org.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getLookups() {
    const result = await this.get();
    return result;
  }

  setLookupDisplayValue(lookups) {
    if (lookups && lookups.length > 0) {
      const allTablefields = OrgPreferenceSingleton.getInstance()?.getFields();
      const fields = [].concat.apply(
        [],
        allTablefields?.map((x) =>
          x.columns?.map((y) => {
            return {
              ...y,
              tableId: x.tableId
            };
          })
        )
      );
      lookups?.forEach((lookup) => {
        const emailField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.EMAIL
        );
        const phoneField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.PHONE
        );
        const stageField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE
        );
        const statusField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.STATUS
        );
        const amountField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.AMOUNT
        );
        const contactField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.CONTACT
        );
        const companyField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.COMPANY
        );
        const closeDateField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.CLOSE_DATE
        );
        const nextFollowUpDateField = fields?.find(
          (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE
        );
        //Set name if not present in lookup
        if (!lookup.name) {
          const fnameField = fields?.find(
            (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.FIRSTNAME
          );
          const lnameField = fields?.find(
            (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.LASTNAME
          );
          const nameField = fields?.find(
            (x) => x.tableId === lookup.tableId && x.customId === LOOKUP_FIELD_CUSTOM_ID.NAME
          );

          if (nameField) lookup.name = lookup.fields[nameField?.id]?.value;
          if (!lookup.name && (fnameField || lnameField))
            lookup.name = `${lookup.fields[fnameField?.id]?.value || ''} ${
              lookup.fields[lnameField?.id]?.value || ''
            }`.trim();
          if (!lookup.name && emailField) lookup.name = lookup.fields[emailField?.id]?.value;
          if (!lookup.name && phoneField) lookup.name = lookup.fields[phoneField?.id]?.value;
        }
        if (emailField) lookup.email = lookup.fields[emailField?.id]?.value;
        if (statusField) {
          lookup.status = lookup.fields[statusField?.id]?.value;
          lookup.status_name = getLookupFieldValueForDisplay(statusField, lookup.fields[statusField?.id]);
        }
        if (stageField) {
          lookup.stage = lookup.fields[stageField?.id]?.value;
          lookup.stage_name = getLookupFieldValueForDisplay(stageField, lookup.fields[stageField?.id]);
        }
        if (amountField) lookup.amount = lookup.fields[amountField?.id]?.value_Number;
        if (contactField) lookup.contact = lookup.fields[contactField?.id]?.value_Lookup;
        if (companyField) lookup.company = lookup.fields[companyField?.id]?.value_Lookup;
        if (closeDateField && lookup.fields[closeDateField?.id])
          lookup.close_date = new Date(lookup.fields[closeDateField?.id]?.value_Date);
        if (nextFollowUpDateField && lookup.fields[nextFollowUpDateField?.id])
          lookup.next_followup_date = new Date(lookup.fields[nextFollowUpDateField?.id]?.value_Date);

        lookup.displayFields = {};
        Object.keys(lookup?.fields)?.forEach((key) => {
          let tableField = fields.find((x) => x.id === key);
          if (tableField) {
            let value;
            if (tableField?.type === LookupFieldTypes.Date || tableField?.type === LookupFieldTypes.Time) {
              if (lookup?.fields[key]?.value_Date) {
                lookup.fields[key].value_Date = new Date(lookup?.fields[key]?.value_Date);
                value = lookup?.fields[key]?.value_Date || undefined;
              }
            } else {
              value = getLookupFieldValueForDisplay(tableField, lookup?.fields[key]);
            }
            lookup.displayFields[key] = value;
          }
        });
      });
    }
    return lookups;
  }

  getMappedValues(list) {
    const dbItems = list?.map((x) => {
      return {
        id: x.id,
        tableId: x.tableId,
        value: x
      };
    });
    return dbItems;
  }

  async setLookups(value) {
    if (!value) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async getLookupsByTable(tableId) {
    if (tableId && this.db) {
      const rows = await this.getDb().where('tableId').equals(tableId).toArray();
      const result = rows.map((x) => x.value);
      return result;
    }
    return [];
  }

  async getLookupsByTableIds(tableIds) {
    if (tableIds && tableIds.length > 0 && this.db) {
      const rows = await this.getDb().where('tableId').anyOf(tableIds).toArray();
      const result = rows.map((x) => x.value);
      return result;
    }
    return [];
  }

  async getLookupsByEmail(email) {
    if (email && this.db) {
      const rows = await this.getDb()
        .filter((x) => {
          return x?.value?.email === email;
        })
        .toArray();
      const result = rows.map((x) => x.value);
      return result;
    }
    return [];
  }

  async updateLookups(newList, archivedList) {
    await this.syncList(newList, archivedList);
  }

  async updateLookupField(lookupId, field, fieldValue) {
    if (!lookupId || !fieldValue || !field) return;
    const lookup = await this.getItem(lookupId);
    const fieldValueOnly = {};
    Object.keys(fieldValue).forEach((key) => {
      if (key.startsWith('value')) {
        fieldValueOnly[key] = fieldValue[key];
      }
    });
    lookup.fields[field.id] = fieldValueOnly;
    const lookupWithFields = this.setLookupDisplayValue([lookup]);
    await this.addBulk(lookupWithFields);
    return lookupWithFields[0];
  }

  async updateLookupItem(lookup) {
    if (!lookup) return;
    const fields = [].concat.apply(
      [],
      lookup.groupFields?.map((x) => x?.fields)
    );
    const newFormattedLookup = {
      id: lookup.id,
      companyId: lookup.companyId,
      tableId: lookup.tableId,
      fields: {}
    };
    fields.forEach((item) => {
      const fieldValue = {};
      if (item) {
        Object.keys(item).forEach((key) => {
          if (key.startsWith('value')) fieldValue[key] = item[key];
        });
      }
      newFormattedLookup.fields[item.id] = fieldValue;
    });
    const lookupWithFields = this.setLookupDisplayValue([newFormattedLookup]);
    await this.addBulk(lookupWithFields);
  }

  async getLastUpdatedTime() {
    const result = await super.getLastUpdatedTime(UPDATE_ON_ID.LOOKUPS);
    return result;
  }

  async setLastUpdatedTime(value) {
    const result = await super.setLastUpdatedTime(UPDATE_ON_ID.LOOKUPS, value);
    return result;
  }
}
