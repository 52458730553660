import { combineReducers } from 'redux';
import { createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import { LOOKUP_ACTIVITY_TYPE, LOOKUP_TYPE } from '../constants/constant';
import * as Actions from './../actions/types';

const activityLoading = createReducer({
  initialState: false,
  actionType: Actions.SET_ACTIVITY_LOADER
});

const activityList = createReducer({
  initialState: null,
  actionType: Actions.SET_ACTIVITY_LIST
});

const taskCountReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_TASK_COUNT
});

const activityValidationReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_ACTIVITY_VALIDATION_ERROR
});

const initialActivityItemState = {
  activityType: LOOKUP_ACTIVITY_TYPE.TASKS,
  lookupType: LOOKUP_TYPE.contacts,
  date: new Date(),
  time: undefined,
  toDate: undefined,
  title: '',
  toTime: undefined,
  attendees: undefined,
  channel: undefined,
  isAllDay: false,
  location: undefined,
  description: undefined,
  file: undefined
};
const activityItem = createReducerOrig(initialActivityItemState, (builder) => {
  builder
    .addCase(Actions.SET_ACTIVITY_ITEM, (state = initialActivityItemState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_ACTIVITY_ITEM, (state, action) => {
      const activityItem = { ...state };
      activityItem[action.payload.propsName] = action.payload.value;
      return { ...activityItem };
    })
    .addCase(Actions.CLEAR_ACTIVITY_ITEM, () => {
      const initialActivityState = JSON.parse(JSON.stringify(initialActivityItemState));
      return initialActivityState;
    })
    .addCase(Actions.ADD_ATTENDEES_ITEM, (state, action) => {
      const initialState = { ...state };
      if (!initialState?.attendees) initialState.attendees = [];
      const attendees = initialState?.attendees?.slice();
      attendees.push(action.payload.attendeesItem);
      initialState.attendees = attendees;
      return { ...initialState };
    })
    .addCase(Actions.UPDATE_ATTENDEES_ITEM, (state, action) => {
      const initialState = { ...state };
      const attendees = initialState.attendees.slice();
      attendees[action.payload.index] = action.payload.attendeesItem;
      initialState.attendees = attendees;
      return { ...initialState };
    })
    .addCase(Actions.DELETE_ATTENDEES_ITEM, (state, action) => {
      const initialState = { ...state };
      const attendees = initialState.attendees.slice();
      if (action.payload.index !== -1) {
        attendees.splice(action.payload.index, 1);
      }
      initialState.attendees = attendees;
      return { ...initialState };
    });
});
const initialTaskActivityState = [];
const taskActivityReducer = createReducerOrig(initialTaskActivityState, (builder) => {
  builder
    .addCase(Actions.SET_TASK_ACTIVITY_LIST, (state = initialTaskActivityState, action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_TASK_ACTIVITY_ITEM, (state, action) => {
      const taskList = current(state);
      let newList = JSON.parse(JSON.stringify(taskList));
      const item = newList[action.payload.groupIndex].list[action.payload.taskIndex];
      if (item) {
        const newItem = {
          ...item,
          [action.payload.propsName]: action.payload.value
        };
        newList[action.payload.groupIndex].list[action.payload.taskIndex] = newItem;
      }
      return [...newList];
    });
});
const initialActivityByLookupState = null;
const activityByLookup = createReducerOrig(initialActivityByLookupState, (builder) => {
  builder
    .addCase(Actions.SET_ACTIVITY_BY_LOOKUP, (state = initialActivityByLookupState, action) => {
      return action.payload;
    })
    .addCase(Actions.ADD_ACTIVITY_COMMENT, (state, action) => {
      if (!state) return;
      const list = current(state);
      let newList = JSON.parse(JSON.stringify(list));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.messageId);
      }
      if (index !== -1) {
        const item = newList[index];
        if (item) {
          const comments = newList[index].comments || [];
          comments?.push(action.payload.item);
          const newItem = {
            ...item,
            comments
          };
          newList[index] = newItem;
        }
      }
      return [...newList];
    })
    .addCase(Actions.UPDATE_COMMENT_ITEM, (state, action) => {
      if (!state) return;
      let list = JSON.parse(JSON.stringify(current(state)));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.messageId);
      }
      if (index !== -1) {
        const item = list[index];
        if (item) {
          const commentIndex = list[index].comments?.findIndex((x) => x?.localId === action.payload.item.localId);
          if (commentIndex !== -1) {
            list[index].comments[commentIndex] = action.payload.item;
          }
          if (action.payload?.messageId && action.payload.item?.activityId) {
            list[index].commentActivityId = action.payload.item?.activityId;
          }
        }
      }
      return [...list];
    })
    .addCase(Actions.DELETE_ACTIVITY_COMMENT, (state, action) => {
      if (!state) return;
      const list = current(state);
      let newList = JSON.parse(JSON.stringify(list));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.messageId);
      }
      if (index !== -1) {
        const item = newList[index];
        if (item) {
          const comments = newList[index].comments || [];
          const commentIndex = comments?.findIndex((x) => x?.id === action?.payload?.item?.id);
          comments?.splice(commentIndex, 1);
          const newItem = {
            ...item,
            comments
          };
          newList[index] = newItem;
        }
      }
      return [...newList];
    })
    .addCase(Actions.UPDATE_LOOKUP_ACTIVITY_ITEM, (state, action) => {
      //Update item in openthread list if user update activity from open thread page
      if (!state) return state;
      let list = JSON.parse(JSON.stringify(current(state)));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.item?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.item?.messageId);
      }
      if (index !== -1) {
        const item = list[index];
        if (item) {
          list[index] = action.payload.item;
        }
      }
      return [...list];
    });
});

const initialOpenCommentActivityListState = null;
const openCommentActivityListReducer = createReducerOrig(initialOpenCommentActivityListState, (builder) => {
  builder
    .addCase(Actions.SET_OPEN_COMMENT_ACTIVITY_LIST, (state = initialOpenCommentActivityListState, action) => {
      return action.payload;
    })
    .addCase(Actions.ADD_ACTIVITY_COMMENT, (state, action) => {
      if (!state) return;
      const list = current(state);
      let newList = JSON.parse(JSON.stringify(list));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.messageId);
      }
      if (index !== -1) {
        const item = newList[index];
        if (item) {
          const comments = newList[index].comments || [];
          comments?.push(action.payload.item);
          const newItem = {
            ...item,
            comments
          };
          newList[index] = newItem;
        }
      }
      return [...newList];
    })
    .addCase(Actions.UPDATE_COMMENT_ITEM, (state, action) => {
      if (!state) return;
      let list = JSON.parse(JSON.stringify(current(state)));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.messageId);
      }
      if (index !== -1) {
        const item = list[index];
        if (item) {
          const commentIndex = list[index].comments?.findIndex((x) => x?.localId === action.payload.item.localId);
          if (commentIndex !== -1) {
            list[index].comments[commentIndex] = action.payload.item;
          }
          if (action.payload?.messageId && action.payload.item?.activityId) {
            list[index].commentActivityId = action.payload.item?.activityId;
          }
        }
      }
      return [...list];
    })
    .addCase(Actions.UPDATE_LOOKUP_ACTIVITY_ITEM, (state, action) => {
      if (!state) return state;
      let list = JSON.parse(JSON.stringify(current(state)));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.item?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.item?.messageId);
      }
      if (index !== -1) {
        const item = list[index];
        if (item) {
          list[index] = action.payload.item;
        }
      }
      return [...list];
    })
    .addCase(Actions.DELETE_ACTIVITY_COMMENT, (state, action) => {
      if (!state) return;
      const list = current(state);
      let newList = JSON.parse(JSON.stringify(list));
      let index = list?.findIndex((x) => x?.id === action.payload.id);
      //For email thread, find message and update activity comment
      if (action.payload?.messageId) {
        index = list?.findIndex((x) => x?.id === action.payload.id && x?.messageId === action.payload?.messageId);
      }
      if (index !== -1) {
        const item = newList[index];
        if (item) {
          const comments = newList[index].comments || [];
          const commentIndex = comments?.findIndex((x) => x?.id === action?.payload?.item?.id);
          comments?.splice(commentIndex, 1);
          const newItem = {
            ...item,
            comments
          };
          newList[index] = newItem;
        }
      }
      return [...newList];
    });
});

const activityReducer = combineReducers({
  activityList,
  activity: activityByLookup,
  activityItem,
  activityLoading,
  taskActivityList: taskActivityReducer,
  resError: activityValidationReducer,
  taskCount: taskCountReducer,
  openCommentActivityList: openCommentActivityListReducer
});

export default activityReducer;
