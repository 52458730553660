import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import DropDown from '../../../components/DropDown/DropDown';
import { bulkAssign, getSelectedLooupFromTableRows } from '../../../services/lookupService';
import { MainContact, TitleText, InputView, BtnView, LabelTitle, customestyle } from './styles';

const BulkAssignModal = (props) => {
  const [assign, setAssign] = useState();
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization);
  const lookupSelector = useSelector((state) => state.lookup);
  const { loading } = lookupSelector;

  const { organization } = organizationSelector;
  const { users, id: organization_id } = organization;

  const onChangeItem = useCallback((value) => {
    setAssign(value);
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const references = getSelectedLooupFromTableRows(props?.selectedRows, organization_id);
      const payload = { references, users: assign };
      const result = await dispatch(bulkAssign(organization_id, payload));
      if (result) {
        if (props.onSave) props.onSave();
      }
    },
    [assign, dispatch, organization_id, props]
  );

  return (
    <MainContact onSubmit={onSubmit}>
      <TitleText>Bulk Assign</TitleText>
      <InputView>
        <LabelTitle>Please select user(s)</LabelTitle>
        <DropDown
          options={users}
          labelField={'name'}
          valueField={'id'}
          isMulti
          value={assign}
          styles={customestyle}
          onChange={(value) => {
            onChangeItem(value);
          }}
          placeholder={''}
          className='basic-multi-select'
        />
      </InputView>
      <BtnView>
        <Button type='submit' title='Ok' loading={loading} />
        <Button secondary type='button' title='Cancel' onClick={props.handleCloseModal} />
      </BtnView>
    </MainContact>
  );
};

export default BulkAssignModal;
