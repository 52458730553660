import { useMemo } from 'react';
import Avtar from '../../../../../components/Avtar/Avtar';
import { ACTIVITY_ACTION, ATTACHMENT } from '../../../../../constants/constant';
import FileIcon from '../../../../../components/FileIcon/FileIcon';
import useMobileDevice from '../../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
import CommentHoverItem from '../Comments/CommentHoverItem';
import RenderComments from '../Comments/RenderComments';
import { getGravatarURL, urlify } from '../../../../../helpers/common';

import {
  AllList,
  Captionmess,
  Captiontitle,
  ContainView,
  IconList,
  MainList,
  RightList,
  TimeText,
  UserRecive,
  UserText,
  FileMain,
  FileText,
  FileIcons,
  DownloadLink,
  FileIconWrapper,
  ActivityUpperBlock,
  ActivityBottomBlock,
  ThraedInner,
  UserWrapperBlock
} from '../styles';

const FileActivityItem = ({
  item,
  createdOn,
  actionIcon,
  isOpenThread,
  onLookupClick,
  onOpenFilePreview,
  onMoreMenuClick,
  isShowCommentInput,
  onClickComment,
  selectedActivity,
  isOpenMoreOptionMenu
}) => {
  const mobile = useMobileDevice();

  const isPriview = useMemo(() => {
    const extension = item?.fileURL?.split('.').pop();
    if (
      extension === ATTACHMENT.JPEG ||
      extension === ATTACHMENT.JPG ||
      extension === ATTACHMENT.JFIF ||
      extension === ATTACHMENT.PNG ||
      extension === ATTACHMENT.SVG ||
      extension === ATTACHMENT.PDF
    ) {
      return true;
    }
    return false;
  }, [item?.fileURL]);

  let actionPrefix = 'added a';
  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);

  const htmlContent = useMemo(() => {
    if (item?.description) {
      return urlify(item?.description);
    }
    return item?.description;
  }, [item?.description]);

  return (
    <>
      <MainList key={item?.id}>
        <ActivityUpperBlock mobile={mobile} isOpenThread={isOpenThread}>
          {isOpenThread ? (
            item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
          ) : (
            <IconList>{actionIcon}</IconList>
          )}
          <AllList>
            <RightList mobile={mobile} isOpenThread={isOpenThread}>
              {isOpenThread && item?.lookup && (
                <ThraedInner
                  onClick={() => {
                    onLookupClick(item?.lookup);
                  }}>
                  <UserText>{item?.lookup?.name}</UserText>
                </ThraedInner>
              )}
              <UserWrapperBlock mobile={mobile}>
                <UserText isOpenThread={isOpenThread} mobile={mobile}>
                  {item?.assignedUser?.name}
                </UserText>
                <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                  {actionPrefix} {String(item?.actionName || '').toLowerCase()}
                </UserRecive>
                <TimeText isOpenThread={isOpenThread} mobile={mobile} title={createdOn?.tooltip}>
                  {createdOn?.absolute}
                </TimeText>
              </UserWrapperBlock>
            </RightList>
          </AllList>
        </ActivityUpperBlock>
        <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
          <ContainView notes={item?.action === ACTIVITY_ACTION.Notes}>
            <FileMain
              isPriview={isPriview}
              onClick={(e) => {
                if (isPriview && onOpenFilePreview) onOpenFilePreview(e, item);
              }}>
              <FileIconWrapper>
                <FileIcons>
                  <FileIcon fileURL={item?.fileURL} />
                </FileIcons>
              </FileIconWrapper>

              <FileText className='fileBorder'>
                <Captiontitle>{item?.title}</Captiontitle>
                {htmlContent && (
                  <Captionmess
                    className='quill_format'
                    isTitle={item?.title ? true : false}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  />
                )}
              </FileText>
              {!isPriview && (
                <DownloadLink href={item?.fileURL} target='_blank' title='Download'>
                  <SVGIcon size={24} name='icon-download' fil='var(--gray-icon-color)' />
                </DownloadLink>
              )}
            </FileMain>
            {onMoreMenuClick && (
              <CommentHoverItem
                item={item}
                isOpenMoreOptionMenu={isOpenMoreOptionMenu}
                selectedActivity={selectedActivity}
                onClickMoreMenu={onMoreMenuClick}
                onClickComment={onClickComment}
              />
            )}
          </ContainView>
          <RenderComments item={item} isShowCommentInput={isShowCommentInput} selectedActivity={selectedActivity} />
        </ActivityBottomBlock>
      </MainList>
    </>
  );
};

export default FileActivityItem;
