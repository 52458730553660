import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Message from '../../../components/Message/Message';
import Input from '../../../components/Input/Input';
import { countryWithCodes } from '../../../data/raw';
import DropDown from '../../../components/DropDown/DropDown';
import { getTwillioAvailablePhoneNumbers } from '../../../services/settingService';
import { Form, Step1, BottomView, LeftWrapper, TwoInput, ListWrappper, DropDownTitle } from './styles';

const BuyNumber = ({ onClose }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const settingSelector = useSelector((state) => state.setting);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { loading } = settingSelector;

  const [countryCode, setCountryCode] = useState('+91');
  const [prefix, setPrefix] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();

  const onCloseModal = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (prefix?.length > 7) {
      validationErrors.prefix = 'Prefix length should not be greater than 7';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [prefix]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;

      const params = {
        countryCode: countryCode,
        contains: prefix
      };
      const result = await dispatch(getTwillioAvailablePhoneNumbers(organization_id, params));
      if (result) {
        onCloseModal();
      }
    },
    [validateInputs, countryCode, prefix, dispatch, organization_id, onCloseModal]
  );

  return (
    <>
      <Step1>
        <Form onSubmit={onSubmit}>
          <TwoInput>
            <ListWrappper>
              <DropDownTitle>Country</DropDownTitle>
              <DropDown
                menuPlacement={'top'}
                options={countryWithCodes}
                labelField={'text'}
                valueField={'code'}
                value={countryCode}
                placeholder={'united States (+1)'}
                onChange={(value) => {
                  setCountryCode(value.countryCode);
                }}
              />
            </ListWrappper>
            <Input
              title='Prefix (optional)'
              isPrimarySmall
              type='number'
              autocomplete='off'
              placeholder='e.g. 897'
              onChange={(e) => {
                setPrefix(e.target.value);
              }}
            />
          </TwoInput>
          {validationErrors?.prefix && <Message text={validationErrors.prefix} isSuccess={false} />}
          <BottomView>
            <LeftWrapper>
              <Button type='submit' title='Request Number' loading={loading} />
            </LeftWrapper>
          </BottomView>
        </Form>
      </Step1>
    </>
  );
};

export default BuyNumber;
