import axios from 'axios';
import moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { addDays, addMonth, getAPIErrorReason, getSecondsFromHHMMSS, getUTCDate, toHHMMSS } from '../helpers/common';
import {
  setActivityByLookup,
  setActivityItem,
  setActivityList,
  setActivityLoader,
  setTaskActivityList,
  setActivityTaskCount,
  updateCommentItem,
  setOpenCommentActivityList
} from '../actions/activityActions';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';
import { ACTIVITY_ACTION, TASK_LIST_TYPE } from '../constants/constant';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import { getDashboardDetail } from './dashboardService';
import ActivityCacheService from './activityCacheService';
import EmailCacheService from './emailCacheServices';
import { getEmailList } from './emailService';
import { trackAnalyticActivity } from './analyticsService';
import LookupCacheService from './lookupCacheServices';

let ajaxRequest = null;
/**
 * @desc Activities - Get Activity List
 * @param {*} obj Data Obj
 */
export const getActivityList = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return;
    dispatch(setActivityLoader(true));

    //Set list from local cache
    const activities_local = await ActivityCacheService.getInstance()?.getActivities();
    if (activities_local && activities_local.length > 0) {
      dispatch(setActivityList(activities_local));
    }

    const lastUpdatedTimeFromLocalStorage = await ActivityCacheService.getInstance()?.getLastUpdatedTime();
    const lastUpdatedTime = lastUpdatedTimeFromLocalStorage ? new Date(lastUpdatedTimeFromLocalStorage).getTime() : 0;

    if (ajaxRequest) {
      ajaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    ajaxRequest = axios.CancelToken.source();
    const response = await axios.get(
      `${REACT_APP_APIURL}/LookupActivities/${organization_id}/LastUpdatedList/${lastUpdatedTime}`,
      {
        cancelToken: ajaxRequest.token
      }
    );

    const { data: activityList, lastUpdatedOn, archived } = response.data;
    if (lastUpdatedTime === 0) await ActivityCacheService.getInstance()?.setActivities(activityList);
    else await ActivityCacheService.getInstance()?.updateActivities(activityList, archived);

    await ActivityCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn);

    //Set list after update from server
    const activities = await ActivityCacheService.getInstance()?.getActivities();
    dispatch(setActivityList(activities));

    return true;
  } catch (e) {
    console.log('ERROR', e);
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to get activity list please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

export const getActivityListFromServer = (organization_id) => async (dispatch) => {
  try {
    if (!organization_id) return false;
    const lastUpdatedTimeFromLocalStorage = await ActivityCacheService.getInstance()?.getLastUpdatedTime();
    const lastUpdatedTime = lastUpdatedTimeFromLocalStorage ? new Date(lastUpdatedTimeFromLocalStorage).getTime() : 0;

    if (ajaxRequest) {
      ajaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    ajaxRequest = axios.CancelToken.source();
    const response = await axios.get(
      `${REACT_APP_APIURL}/LookupActivities/${organization_id}/LastUpdatedList/${lastUpdatedTime}`,
      {
        cancelToken: ajaxRequest.token
      }
    );

    const { data: activityList, lastUpdatedOn, archived } = response.data;
    if (lastUpdatedTime === 0) await ActivityCacheService.getInstance()?.setActivities(activityList);
    else await ActivityCacheService.getInstance()?.updateActivities(activityList, archived);
    const activities_local = await ActivityCacheService.getInstance()?.getActivities();
    dispatch(setTaskCount(activities_local));
    await ActivityCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn);
    return true;
  } catch (e) {
    console.log('ERROR', e);
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to get activity list please try again', dispatch);
    return false;
  }
};

const setTaskCount = (activityList) => (dispatch) => {
  const user = UserPreferenceSingleton.getInstance().getCurrentUser();
  const list = activityList?.filter(
    (x) => x?.assignedUser?.id === user?.id && x?.action === ACTIVITY_ACTION.Tasks && !x?.taskStatus
  );
  let today = getUTCDate();
  let todayTime = today.getTime();
  let count = list?.filter((x) => x?.date && getUTCDate(x?.date).getTime() <= todayTime).length;
  dispatch(setActivityTaskCount(count > 0 ? count : ''));
};

export const getActivityByLookup = (organization_id, lookup_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id || !lookup_id) return false;
    dispatch(setActivityLoader(true));
    //Load data from local and set into redux to display data
    dispatch(setActivityListByLookup_Local(lookup_id));

    await dispatch(getActivityListFromServer(organization_id));
    await dispatch(getEmailList(organization_id, 'all'));

    //Load data from local and set into redux to display data
    dispatch(setActivityListByLookup_Local(lookup_id));

    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to update Activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

const searchEmailAddress = (participants, lookupEmail) => {
  if (!participants || participants?.length === 0 || !lookupEmail) return false;
  let emailIndex = participants?.findIndex(
    (x) =>
      x?.email &&
      participants.findIndex(
        (x) => String(x.email).toLowerCase().trim() === String(lookupEmail).toLowerCase().trim()
      ) !== -1
  );
  if (emailIndex !== -1) return true;
  return false;
};

/**
 * @desc get Activity By Lookup
 * @param {*} lookup_id
 */
const setActivityListByLookup_Local = (lookup_id) => async (dispatch) => {
  try {
    const lookup = await LookupCacheService.getInstance()?.getItem(lookup_id);
    const lookupEmail = lookup?.email;
    const activities_local = await ActivityCacheService.getInstance()?.getActivities();
    dispatch(setTaskCount(activities_local));
    const activities = activities_local.filter((x) => x.lookup?.id === lookup_id);
    //Get sync email's comment activity to link with email message
    const emailCommentActivities = activities_local?.filter((x) => x.action === ACTIVITY_ACTION.EmailComment);
    const emails_local = await EmailCacheService.getInstance()?.getEmails();
    const email = emails_local
      .filter((x) => x?.lookup?.id === lookup_id || (lookupEmail && searchEmailAddress(x.participants, lookupEmail)))
      .map((item) => {
        return (item = {
          ...item,
          action: 3,
          taskStatus: true
        });
      });
    //Add individual message in a thread to show individual items in lookup activity list
    const emailMessageList = [];
    email.forEach((thread) => {
      thread?.messages.forEach((message) => {
        const commentActivity =
          emailCommentActivities.find((x) => x.emailThreadId === thread.threadId && x.emailMessageId === message.id) ||
          {};

        const threadMessage = Object.assign({}, { ...commentActivity, ...thread });
        threadMessage.commentActivityId = commentActivity?.id;
        threadMessage.messageId = message.id;
        threadMessage.messages = [message];
        threadMessage.subject = message.subject;
        threadMessage.dateTime = new Date(message.date);
        threadMessage.snippet = message.snippet;
        emailMessageList.push(threadMessage);
      });
    });
    let list = emailMessageList;
    const newActivities = activities?.map((item) => {
      return { ...item, dateTime: new Date(item?.createdOn) };
    });
    list = list.concat(newActivities);
    const filterResult = process(list, {
      sort: [
        { field: 'taskStatus', dir: 'asc' },
        { field: 'dateTime', dir: 'desc' }
      ]
    });
    dispatch(setActivityByLookup(filterResult?.data));
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to update Activity please try again', dispatch);
    return false;
  }
};

/**
 * @desc Update Activity
 * @param {*} organization_id, activityId, action
 */
export const updateActivity = (organization_id, activityId, action) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    await ActivityCacheService.getInstance()?.updateActivityItem(activityId, 'taskStatus', action);
    const activities_local = await ActivityCacheService.getInstance()?.getActivities();
    dispatch(setActivityList(activities_local));
    dispatch(setTaskCount(activities_local));
    await dispatch(getDashboardDetail(organization_id));
    const response = await axios.put(
      `${REACT_APP_APIURL}/LookupActivities/${organization_id}/Update/${activityId}/status/${action}`
    );
    const { data } = response;
    if (data) return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to update Activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Update Activity AssignTo
 * @param {*} organization_id, activityId, payload
 */
export const updateActivityAssignTo = (organization_id, activityId, user) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const payload = {
      id: user?.id
    };
    await ActivityCacheService.getInstance()?.updateActivityItem(activityId, 'assignedUser', user);
    const activities_local = await ActivityCacheService.getInstance()?.getActivities();
    dispatch(setActivityList(activities_local));
    dispatch(setTaskCount(activities_local));

    const response = await axios.put(
      `${REACT_APP_APIURL}/LookupActivities/${organization_id}/Update/${activityId}/assignto`,
      { id: payload?.id }
    );
    const { data } = response;
    if (data) return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to update activity assignTo please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Reschedule Activity
 * @param {*} obj Data Obj
 */
export const rescheduleActivity = (organization_id, activityId, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/Update/${activityId}/datetime`,
      obj
    );
    const { data } = response;
    data && (await dispatch(getDashboardDetail(organization_id)));
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to Reschedule Activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Create Activity
 * @param {*} obj Data Obj
 * @param {*} organization_id Organization Id
 */
export const createActivity = (organization_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookupActivities/${organization_id}/create`, obj);
    const { data } = response;
    dispatchActivitySucess(data?.message, dispatch);
    ActivityCacheService.getInstance()?.setActivities([data?.data]);
    dispatch(getActivityByLookup(organization_id, obj?.lookup?.id));
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to add Email account please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Get Activity
 * @param {*} obj Data Obj
 * @param {*} organization_id Organization Id
 */
export const getActivity = (organization_id, activity_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${activity_id}`);
    const { data } = response;
    if (data) {
      if (data.action === ACTIVITY_ACTION.Meeting) {
        data?.attendees?.push({ email: '', name: '' });
      }
      if (data.time) {
        data.time = moment(data.time).format('HH:mm');
      }
      if (data.toTime) {
        data.toTime = moment(data.toTime).format('HH:mm');
      }
      if (data?.duration) {
        const seconds = Math.max(0, getSecondsFromHHMMSS(`${data?.duration}`));
        const times = toHHMMSS(seconds);
        data.duration = times;
      }
      dispatch(setActivityItem(data));
    }
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to load activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Update Activity
 * @param {*} obj Data Obj
 * @param {*} organization_id Organization Id
 */
export const updateActivityItems = (organization_id, activity_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/update/${activity_id}`,
      obj
    );
    const { data } = response;
    dispatchActivitySucess(data?.message, dispatch);
    ActivityCacheService.getInstance()?.updateActivities([data?.data], undefined);
    dispatch(getActivityByLookup(organization_id, obj?.lookup?.id));
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to add Email account please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Delete Activity
 * @param {*} obj Data Obj
 * @param {*} organization_id Organization Id
 */
export const deleteActivity = (organization_id, activity_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.delete(`${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${activity_id}`);
    const { data } = response;
    if (data) dispatchActivitySucess(data?.message, dispatch);
    ActivityCacheService.getInstance()?.updateActivities([], [activity_id]);
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to delete activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Upload file in activity
 * @param {*} obj Data Obj
 * @param {*} organization_id Organization Id
 */
export const uploadFile = (organization_id, file) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    let formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${REACT_APP_APIURL}/Files/${organization_id}`, formData);
    const { data } = response;
    if (data) return data?.data[0];
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to load activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

export const getActivityItem = async (activityId) => {
  if (!activityId) return;
  const activity = await ActivityCacheService.getInstance()?.getActivityItem(activityId);
  if (activity) return activity;
};

export const getTaskActivityList = (organization_id, type, assinedUser) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));

    dispatch(getTaskActivityList_Local(type, assinedUser));

    await dispatch(getActivityListFromServer(organization_id));

    dispatch(getTaskActivityList_Local(type, assinedUser));

    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to load activity please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

export const getOpenCommentActivity = (organization_id) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const authState = getState().auth;
    const userId = authState?.currentUser?.id;
    if (userId) {
      const list = await ActivityCacheService.getInstance()?.getActivities();
      let openCommentsActivity = list?.filter(
        (x) => x.comments?.length > 0 && !x.isCommentsClosed && x?.subscribers?.findIndex((y) => y.id === userId) !== -1
      );
      if (openCommentsActivity && openCommentsActivity.length > 0) {
        openCommentsActivity = process(openCommentsActivity, {
          sort: [{ field: 'dateTime', dir: 'desc' }]
        })?.data;
      }
      dispatch(setOpenCommentActivityList(openCommentsActivity || []));
    }
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to get open thread, please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

const getTaskActivityList_Local = (type, assinedUser) => async (dispatch) => {
  const list = await ActivityCacheService.getInstance()?.getActivities();
  dispatch(setTaskCount(list));
  let groupList = [];
  let itemList_All;

  if (type === TASK_LIST_TYPE.ALL) {
    itemList_All = list?.filter((x) => x?.action === ACTIVITY_ACTION.Tasks);
  } else if (type === TASK_LIST_TYPE.COMPLETED)
    itemList_All = list?.filter((x) => x?.taskStatus && x?.action === ACTIVITY_ACTION.Tasks);
  else if (type === TASK_LIST_TYPE.UNCOMPLETED)
    itemList_All = list?.filter((x) => !x?.taskStatus && x?.action === ACTIVITY_ACTION.Tasks);

  if (assinedUser && assinedUser?.id) {
    itemList_All = itemList_All?.filter((x) => x?.assignedUser?.id === assinedUser?.id);
  }

  let text = '';
  let today = getUTCDate();
  let year = today.getFullYear();
  let month = today.getMonth();
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  //For Delayed
  text = 'Delayed';
  let yesterday = addDays(today, -1);
  let delayedList = itemList_All?.filter((x) => x.date && getUTCDate(x.date) <= getUTCDate(yesterday));
  if (delayedList?.length > 0) {
    groupList.push({
      list: delayedList,
      display: text,
      fromDate: undefined,
      toDate: yesterday,
      displayDate: true
    });
  }

  //For Today
  text = 'Today';
  let todayTime = today.getTime();
  let todayList = itemList_All?.filter((x) => x?.date && getUTCDate(x?.date).getTime() === todayTime);
  if (todayList?.length > 0) {
    groupList?.push({ list: todayList, display: text, fromDate: today, toDate: today, displayDate: false });
  }

  // For Tomorrow
  text = 'Tomorrow';
  let tomorrow = addDays(today, 1);
  let tomorrowTime = tomorrow.getTime();
  let tomorrowList = itemList_All?.filter((x) => x.date && getUTCDate(x.date).getTime() === tomorrowTime);
  if (tomorrowList?.length > 0) {
    groupList?.push({ list: tomorrowList, display: text, fromDate: tomorrow, toDate: tomorrow, displayDate: true });
  }

  //For Next Days of the Week
  let day = today.getDay();
  let SameDayOfNextWeek = addDays(today, day / day + 6); // +6 bcoz 1 for today
  // let FirstDayOfCurrentWeek = this.addDays(today, -1 * day + 1);
  // let LastDayOfCurrentWeek = this.addDays(FirstDayOfCurrentWeek, 6);
  let tempDate = addDays(tomorrow, 1);
  while (tempDate <= SameDayOfNextWeek) {
    text = days[tempDate.getDay()];

    let temptime = getUTCDate(tempDate).getTime();
    let tempDateList = itemList_All?.filter((x) => x.date && getUTCDate(x.date).getTime() === temptime);
    if (tempDateList?.length > 0) {
      groupList?.push({ list: tempDateList, display: text, fromDate: tempDate, toDate: tempDate, displayDate: true });
    }
    tempDate = addDays(tempDate, 1);
  }

  //For This Month Dates
  let LastDayOfMonth = addDays(getUTCDate(new Date(year, month + 1, 1)), -1);
  text = moment(tempDate).format('MMMM') + '(' + tempDate.getUTCDate() + '-' + LastDayOfMonth.getUTCDate() + ')';
  let thisMonthList = itemList_All?.filter(
    (x) => getUTCDate(x.date) >= getUTCDate(tempDate) && getUTCDate(x.date) <= getUTCDate(LastDayOfMonth)
  );
  if (thisMonthList?.length > 0) {
    groupList?.push({
      list: thisMonthList,
      display: text,
      fromDate: tempDate,
      toDate: LastDayOfMonth,
      displayDate: true
    });
  }

  //For Next 4 Months
  let dayofMonth = today.getUTCDate();
  let lastDateOfFourthMonth = addDays(addMonth(today, 4), -1);
  let tempMonth = addDays(addMonth(today, 1), -1 * dayofMonth + 1);
  while (tempMonth <= lastDateOfFourthMonth) {
    text = moment(tempMonth).format('MMMM');
    let firstDayofCurrentMonth = addDays(tempMonth, 0);
    let lastDayofCurrentMonth = addDays(addMonth(tempMonth, 1), -1);
    let tempMonthList = itemList_All?.filter(
      (x) =>
        getUTCDate(x.date) >= getUTCDate(firstDayofCurrentMonth) &&
        getUTCDate(x.date) <= getUTCDate(lastDayofCurrentMonth)
    );
    if (tempMonthList?.length > 0) {
      groupList?.push({
        list: tempMonthList,
        display: text,
        fromDate: firstDayofCurrentMonth,
        toDate: lastDayofCurrentMonth,
        displayDate: true
      });
    }
    tempMonth = addDays(addMonth(tempMonth, 1), 0);
  }

  // Upcoming;
  text = 'Upcoming';
  let upcomingList = itemList_All?.filter((x) => x.date >= tempMonth);
  if (upcomingList?.length > 0) {
    groupList?.push({
      list: upcomingList,
      display: text,
      fromDate: tempMonth,
      toDate: undefined,
      displayDate: true
    });
  }

  //Others
  text = 'Others';
  let othersList = itemList_All?.filter((x) => x.date === undefined || !x.date);
  if (othersList?.length > 0) {
    groupList?.push({ list: othersList, display: text, fromDate: undefined, toDate: undefined, displayDate: true });
  }
  dispatch(setTaskActivityList(groupList));
};

function dispatchActivityError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
function dispatchActivitySucess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}

export const trackActivityAnalyticActivity = (action) => {
  try {
    trackAnalyticActivity(`activity: ${action}`);
  } catch (e) {
    console.log('track Activity Error', e);
  }
};

export const trackActivityActionAnalyticActivity = (action) => {
  try {
    trackAnalyticActivity(`activity: action`, { action });
  } catch (e) {
    console.log('track Activity Error', e);
  }
};

/**
 * @desc Add Activity Comment
 * @param {*} obj Data Obj
 * @param {*} organization_id, item_id, payload
 */
export const addActivityComment = (organization_id, item_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${item_id}/comments`,
      payload
    );
    const { data } = response;
    if (data) {
      dispatch(updateCommentItem({ id: item_id, item: data?.data, messageId: payload?.emailMessageId }));
      dispatchActivitySucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to add add activity comment please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Delete Activity Comment
 * @param {*} organization_id, item_id, comment_id
 */
export const deleteActivityComment = (organization_id, item_id, comment_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.delete(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${item_id}/comments/${comment_id}`
    );
    const { data } = response;
    if (data) {
      dispatchActivitySucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to add add activity comment please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Resolve Comment
 * @param {*} organization_id, item_id, payload
 */
export const resolveComment = (organization_id, item_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${item_id}/comments/close`,
      payload
    );
    const { data } = response;
    if (data) {
      dispatchActivitySucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to resolve comment please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Subscribe Activity Comments
 * @param {*} organization_id, item_id
 */
export const subscribeActivityComments = (organization_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${item_id}/subscribe`
    );
    const { data } = response;
    if (data) {
      dispatchActivitySucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to subscribe activity comments please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc Unsubscribe Activity Comments
 * @param {*} organization_id, item_id
 */
export const unSubscribeActivityComments = (organization_id, item_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.delete(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/item/${item_id}/subscribe`
    );
    const { data } = response;
    if (data) {
      dispatchActivitySucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to unsubscribe activity comments please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};

/**
 * @desc update Activity Item
 * @param {*} activityId, key
 */
export const updateLocalLookupActivityItem = (activityId) => async (dispatch, getState) => {
  const state = getState();
  const { activity } = state;
  const { activity: lookupActivities } = activity;
  const item = lookupActivities?.find((x) => x?.id === activityId);
  if (item) {
    await ActivityCacheService.getInstance()?.updateActivity(item);
  }
};

/**
 * @desc Trigger call on device
 * @param {*} obj Data Obj
 * @param {*} organization_id Organization Id
 * @param {*} lookup_id Lookup Id
 * @param {*} payload mobile number
 */
export const callForwardToDevice = (organization_id, lookup_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setActivityLoader(true));
    const response = await axios.post(
      `${REACT_APP_APIURL}/lookupActivities/${organization_id}/trigger-call/${lookup_id}`,
      payload
    );
    const { data } = response;
    dispatchActivitySucess(data?.message, dispatch);
    return true;
  } catch (e) {
    dispatchActivityError(getAPIErrorReason(e) || 'Unable to forward call to device. please try again', dispatch);
    return false;
  } finally {
    dispatch(setActivityLoader(false));
  }
};
