import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const callLogLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_CALL_LOGS_LOADER
});

const initialState = [];
const calLogListReducer = createReducerOrig(initialState, (builder) => {
  builder.addCase(Actions.SET_CALL_LOGS, (state = initialState, action) => {
    return action.payload;
  });
});

const callLogReducer = combineReducers({
  loading: callLogLoaderReducer,
  list: calLogListReducer
});

export default callLogReducer;
