import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { useLocation } from 'react-router';
import readXlsxFile from 'read-excel-file';
import { setErrorMessage } from '../../../actions/messageActions';
import Button from '../../../components/atom/Button/Button';
import DropDown from '../../../components/DropDown/DropDown';
import SnackBar from '../../../components/SnackBar/SnackBar';
import {
  DATE_FORMAT,
  LookupFieldTypes,
  LOOKUP_FIELD_CUSTOM_ID,
  LOOKUP_TYPE,
  TIME_FORMAT
} from '../../../constants/constant';
import { getCustomizeFields, getImportData, importTableData } from '../../../services/settingService';
import { ROUTE } from '../../../constants/routeConst';
import { getUrlParam } from '../../../global/Helper';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import Table from '../../../components/Table/Table';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { getLookupFieldValueForDisplay } from '../../../helpers/common';
import { getCurrentCompanyPlanType } from '../../../services/organizationService';
import { setIsAppUpgradeToPro } from '../../../actions/appActions';
import {
  ImportDataWrapper,
  ItemRight,
  Heading,
  TimelineViewWrapper,
  TimelineViewListitemActive,
  TimelineViewListing,
  TimeSpan,
  TimelineViewHeader,
  TimeSpanBlock,
  TimelineViewContent,
  TitleOne,
  TimeSpanDone,
  DropDownWrapper,
  TimelineViewContentBlock,
  ListSelectBlock,
  TimelineViewButton,
  Border,
  LabelTitle,
  LabelWrapper,
  DropDownTitle,
  ListWrappper,
  WrapperInput,
  marginBottom,
  CreatableDropDown,
  FileInput,
  FieldWrapper,
  Title
} from './styles';

const ImportData = (props) => {
  const location = useLocation();
  const mobile = useMobileDevice();
  const toDay = moment().format(`${DATE_FORMAT} ${TIME_FORMAT}`);
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const settingSelector = useSelector((state) => state.setting);
  const { customizeFields, importData, loading } = settingSelector;

  const [stepNo, setStepNo] = useState(1);
  const [tableFields, setTableFields] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState('');
  const [fileData, setFileData] = useState('');
  const [tags, setTags] = useState([{ value: toDay, label: toDay }]);

  const pageSize = 50;
  const dispatch = useDispatch();

  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;

  const tableList = useMemo(() => {
    const list = lookupTableList?.filter((x) => x.type !== LOOKUP_TYPE.leads);
    return list;
  }, [lookupTableList]);

  useEffect(() => {
    const search = location?.search;
    let table = getUrlParam(search, 'tableId');
    if (!table && tableList?.length > 0) {
      table = tableList?.[0]?.id;
    }
    setSelectedTableId(table);
  }, [location?.search, tableList]);

  useEffect(() => {
    if (selectedTableId) {
      dispatch(getCustomizeFields(organization_id, selectedTableId));
    }
  }, [dispatch, organization_id, selectedTableId]);

  const onNext = useCallback(() => {
    const nextStep = stepNo + 1;
    setStepNo(nextStep);
  }, [stepNo]);

  const getSelectedCollection = useCallback(() => {
    const tables = lookupTableList;
    const selectedTable = tables.find((x) => x.id === selectedTableId);
    if (selectedTable) return selectedTable;
  }, [lookupTableList, selectedTableId]);

  const trackImportDataAnalyticActivity = useCallback(
    (activity, count) => {
      try {
        const collection = getSelectedCollection();
        trackAnalyticActivity(activity, {
          'item count': count,
          collection: String(collection?.label).toLowerCase()
        });
      } catch (e) {
        console.log(`track ${activity} Error`, e);
      }
    },
    [getSelectedCollection]
  );
  const setSelectedFileData = useCallback(
    (data) => {
      setFileData(data);
      if (data) {
        const allTextLines = data?.split(/\r\n|\n|\r/);
        const lines = allTextLines.map((data) => data.split(';'));
        if (lines?.length > 0) {
          const activity = 'settings - import: file selected';
          trackImportDataAnalyticActivity(activity, lines?.length);
        }
      }
    },
    [trackImportDataAnalyticActivity]
  );

  const onFileUpload = useCallback(
    (file) => {
      //Check plan, if expired we can show upgrade popup
      const planType = getCurrentCompanyPlanType();
      if (planType === 'expired') {
        dispatch(setIsAppUpgradeToPro(true));
        return;
      }
      const fileType = file?.name?.substring(file?.name?.lastIndexOf('.') + 1);
      if (file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'xlsx') {
        readXlsxFile(file).then((rows) => {
          let newRows = '';
          rows?.forEach((item) => {
            let newItem = '';
            item?.forEach((x) => {
              // eslint-disable-next-line no-useless-escape
              if (x && String(x)?.indexOf(',') !== -1) x = `\"${x}\"`;
              newItem += x ? String(x) + ',' : ',';
            });
            newRows = newRows?.concat(newItem + '\n');
          });
          setSelectedFileData(newRows);
        });
      } else if (file.type === 'application/vnd.ms-excel' || fileType === 'csv') {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          const fileData = reader.result.toString();
          setSelectedFileData(fileData);
        };
      } else {
        setErrorMessage('Upload csv file type only');
      }
      onNext();
    },
    [onNext, setSelectedFileData, dispatch]
  );

  const csvHeader = useMemo(() => {
    if (fileData) {
      const allTextLines = fileData?.split(/\r\n|\n|\r/);
      const lines = allTextLines.map((data) => data.split(';'));
      const header = lines?.[0]?.[0]?.split(/[,;]+/);
      const headerData = header?.map((x, i) => {
        return { index: i, field: x };
      });
      return headerData;
    }
  }, [fileData]);

  useEffect(() => {
    if (customizeFields?.length > 0) {
      let tableFields = customizeFields;
      tableFields?.filter((fieldGroup) => fieldGroup?.fields?.length > 0);
      tableFields = tableFields?.map((fieldGroup) => {
        const fields = fieldGroup?.fields?.filter(
          (field) => field.isNonEditable !== true && field.type !== LookupFieldTypes.Lookup
        );
        const fieldWithDefaultValue = fields.map((item) => {
          return {
            ...item,
            xlColumnIndex: csvHeader?.findIndex(
              (x) =>
                x.field &&
                (item.customId === String(x.field).toLowerCase() ||
                  String(item.name).toLowerCase() === String(x.field).toLowerCase())
            )
          };
        });
        return {
          ...fieldGroup,
          fields: fieldWithDefaultValue
        };
      });
      setTableFields(tableFields);
    }
  }, [customizeFields, csvHeader]);

  const onChangeFieldMapping = (groupId, fieldId, csvIndex) => {
    if (tableFields?.length > 0) {
      let mappingData = [...tableFields];
      const groupIndex = mappingData?.findIndex((fieldGroup) => fieldGroup?.id === groupId);
      if (groupIndex !== -1) {
        const fieldIndex = mappingData[groupIndex]?.fields?.findIndex((field) => field?.id === fieldId);
        if (fieldIndex !== -1) {
          mappingData[groupIndex].fields[fieldIndex] = {
            ...mappingData[groupIndex].fields[fieldIndex],
            xlColumnIndex: csvIndex
          };
        }
        setTableFields(mappingData);
      }
    }
  };

  const getMappedData = useCallback(async () => {
    const tagList = [].concat.apply(
      [],
      tags?.map((x) => x?.value)
    );
    const params = {
      fileData: fileData,
      mapping: tableFields,
      tags: tagList
    };
    const result = await dispatch(getImportData(organization_id, params));
    if (result) {
      onNext();
    }
  }, [tags, fileData, tableFields, dispatch, organization_id, onNext]);

  const tableData = useMemo(() => {
    let columns = [];
    let rowData = [];

    importData?.[0]?.groupFields?.forEach((groupField) => {
      if (groupField?.fields?.length > 0) {
        groupField?.fields?.forEach((field) => {
          columns.push({
            Header: field.label,
            accessor: field.id
          });
        });
      }
    });

    importData?.forEach((item) => {
      let fields = {};
      item?.groupFields?.forEach((groupField) => {
        if (groupField?.fields?.length > 0) {
          groupField?.fields?.forEach((field) => {
            fields[field.id] = getLookupFieldValueForDisplay(field, field);
          });
        }
      });
      rowData.push(fields);
    });

    return {
      columns,
      data: rowData
    };
  }, [importData]);

  const onImportData = useCallback(async () => {
    for (let pageIndex = 0; pageIndex < importData?.length / pageSize; pageIndex++) {
      const offset = pageIndex * pageSize;
      const data = importData?.slice(offset, offset + pageSize);
      const result = await dispatch(importTableData(organization_id, selectedTableId, data));
      if (result?.length > 0) {
        const count = result?.length;
        const activity = 'settings - import: data imported';
        trackImportDataAnalyticActivity(activity, count);
      }
    }
    props.history.push(ROUTE.INBOX);
  }, [dispatch, trackImportDataAnalyticActivity, props, importData, organization_id, selectedTableId, pageSize]);

  const onChangeTags = useCallback((values) => {
    const tags = values?.map((item) => {
      return { ...item, id: item?.value };
    });
    setTags(tags);
  }, []);
  return (
    <ImportDataWrapper step={stepNo}>
      <ItemRight mobile={mobile}>
        {mobile ? null : <Heading mobile={mobile}>Import data</Heading>}
        <TimelineViewWrapper mobile={mobile}>
          <TimelineViewListing>
            <TimelineViewListitemActive
              onClick={() => {
                !loading && setStepNo(1);
              }}>
              <TimelineViewHeader>
                {stepNo > 1 ? <TimeSpanDone></TimeSpanDone> : <TimeSpan>1</TimeSpan>}
                <TitleOne mobile={mobile}>Upload CSV file (Order of columns doesn't matter)</TitleOne>
              </TimelineViewHeader>
              {stepNo === 1 && (
                <TimelineViewContent>
                  <DropDownWrapper>
                    <DropDown
                      options={tableList}
                      labelField={'label'}
                      valueField={'id'}
                      value={selectedTableId}
                      onChange={(value) => {
                        setSelectedTableId(value?.id);
                      }}
                      placeholder={'Select Collection'}
                    />
                  </DropDownWrapper>
                  <WrapperInput>
                    <FileInput
                      type='file'
                      name='myfile'
                      onChange={(e) => {
                        onFileUpload(e.target.files[0]);
                      }}
                      id='upload'
                      isPrimarySmall={true}
                      accept='.csv, .xlsx'
                      marginBottom={marginBottom}
                    />
                    <Button isPrimary={true} title='Select CSV File' />
                  </WrapperInput>
                </TimelineViewContent>
              )}
            </TimelineViewListitemActive>
            <TimelineViewListitemActive
              onClick={() => {
                stepNo > 1 && !loading && setStepNo(2);
              }}>
              <TimelineViewHeader>
                {stepNo < 2 ? (
                  <TimeSpanBlock>2</TimeSpanBlock>
                ) : stepNo === 2 ? (
                  <TimeSpan>2</TimeSpan>
                ) : (
                  <TimeSpanDone></TimeSpanDone>
                )}
                <TitleOne mobile={mobile}>Select Field Mapping</TitleOne>
              </TimelineViewHeader>
              {stepNo === 2 && (
                <TimelineViewContentBlock>
                  {tableFields &&
                    tableFields?.map((fieldGroup) => {
                      return (
                        <FieldWrapper key={fieldGroup?.id}>
                          <LabelWrapper>
                            <LabelTitle mobile={mobile}>{fieldGroup?.name}</LabelTitle>
                            <Border></Border>
                          </LabelWrapper>
                          <ListSelectBlock>
                            {fieldGroup?.fields?.map((field) => {
                              return (
                                <ListWrappper key={field?.id} mobile={mobile}>
                                  <DropDownTitle>{field?.label}</DropDownTitle>
                                  {field?.customId === LOOKUP_FIELD_CUSTOM_ID.TAGS && field?.isSystem ? (
                                    <CreatableSelect
                                      isMulti={true}
                                      isClearable={false}
                                      placeholder={'Add tags'}
                                      options={[]}
                                      styles={CreatableDropDown}
                                      value={tags}
                                      onChange={onChangeTags}
                                    />
                                  ) : (
                                    <DropDown
                                      options={csvHeader}
                                      labelField={'field'}
                                      valueField={'index'}
                                      value={field?.xlColumnIndex}
                                      onChange={(value) => {
                                        onChangeFieldMapping(fieldGroup?.id, field?.id, value?.index);
                                      }}
                                      placeholder={'Select Field'}
                                    />
                                  )}
                                </ListWrappper>
                              );
                            })}
                          </ListSelectBlock>
                        </FieldWrapper>
                      );
                    })}
                  <Button isPrimary={true} title='Next' onClick={getMappedData} loading={loading} />
                </TimelineViewContentBlock>
              )}
            </TimelineViewListitemActive>
            <TimelineViewListitemActive>
              <TimelineViewHeader>
                {stepNo < 3 ? <TimeSpanBlock>3</TimeSpanBlock> : <TimeSpan>3</TimeSpan>}
                <TitleOne mobile={mobile}>Verify Mapping and Import</TitleOne>
              </TimelineViewHeader>
              {stepNo === 3 && (
                <TimelineViewContentBlock>
                  <Table columns={tableData?.columns || []} data={tableData?.data || []} component={'ImportData'} />
                  <TimelineViewButton>
                    <Button
                      secondary
                      title='Rearrange'
                      type='button'
                      onClick={() => {
                        setStepNo(2);
                      }}
                      disabled={loading}
                    />
                    <Button isPrimary={true} title='Import' type='button' onClick={onImportData} loading={loading} />
                  </TimelineViewButton>
                  {loading && <Title>Do not refresh the page or click the back button</Title>}
                </TimelineViewContentBlock>
              )}
            </TimelineViewListitemActive>
          </TimelineViewListing>
        </TimelineViewWrapper>
      </ItemRight>
      <SnackBar />
    </ImportDataWrapper>
  );
};

export default ImportData;
