import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from '@material-ui/core';
import Input from '../Input/Input';
import { getLookupSearchList } from '../../services/lookupService';
import { getTableRoutingPrefix, getGravatarURL } from '../../helpers/common';
import Avtar from '../Avtar/Avtar';
import { LOOKUP_TYPE } from '../../constants/constant';
import useEventListener from '../../hooks/useEventListener';
import SVGIcon from '../../assets/svg/SVGIcon';
import { setIsAppSearchOpen } from '../../actions/appActions';
import {
  SearchContainer,
  SearchRow,
  RightView,
  ListView,
  RowView,
  UserName,
  UserEmail,
  SearchView,
  EscSpan,
  OverLayDiv
} from './styles';

const UP_ARROW = 38;
const DOWN_ARROW = 40;
const ENTER = 13;

let timeout;
let elSelectedRow;
const SearchBar = ({ history }) => {
  const appSelector = useSelector((state) => state.app);
  const authSelector = useSelector((state) => state.auth);
  const tableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = tableSelector;

  const authUser = authSelector?.currentUser;
  const opened = appSelector?.isSearchOpen;

  const [searchText, setSearchText] = useState('');
  const [list, setList] = useState([]);
  const [selectionIndex, setSelectionIndex] = useState();
  // let searchInputRef = useRef();
  const dispatch = useDispatch();

  const maxIndex = useMemo(() => {
    return list?.length || 0;
  }, [list]);

  useEffect(() => {
    setSelectionIndex(0);
  }, [list]);

  const onClose = useCallback(() => {
    dispatch(setIsAppSearchOpen(false));
    setSearchText('');
    setList([]);
  }, [dispatch, setSearchText]);

  const scrollListIfRequired = useCallback(() => {
    const focusedNode = elSelectedRow;
    if (focusedNode) {
      focusedNode.scrollIntoView({ block: 'end' });
    }
  }, []);

  const onNavigateSelection = useCallback(
    (down = true, index) => {
      if (index >= 0 && index < list.length) {
        setSelectionIndex(index);
        scrollListIfRequired();
      }
    },
    [list, scrollListIfRequired]
  );

  const getTabale = useCallback(
    (item) => {
      const table = lookupTableList?.find((x) => x.id === item?.tableId);
      return table;
    },
    [lookupTableList]
  );

  const onLookupSelected = useCallback(
    (item) => {
      const table = getTabale(item);
      if (table) {
        onClose();
        const prefix = getTableRoutingPrefix(table.type);
        const url = `/${prefix}/${table?.name}/view/${item?.id}/overview`;
        history.push(url);
      }
    },
    [getTabale, onClose, history]
  );

  const onSelectItem = useCallback(() => {
    if (list?.length > 0 && selectionIndex <= list?.length) {
      onLookupSelected(list[selectionIndex]);
    }
  }, [onLookupSelected, list, selectionIndex]);

  const onKeyDownListener = useCallback(
    (event) => {
      try {
        if (!opened) return;
        if (timeout) clearTimeout(timeout);
        switch (event.keyCode) {
          case UP_ARROW:
          case DOWN_ARROW: {
            event.preventDefault();
            break;
          }
          default:
            break;
        }

        timeout = setTimeout(() => {
          if (list.length > 0) {
            switch (event.keyCode) {
              case UP_ARROW:
                if (selectionIndex && selectionIndex > 0) {
                  const newIndex = selectionIndex - 1;
                  onNavigateSelection(false, newIndex);
                } else {
                  onNavigateSelection(false, 0);
                }
                break;
              case DOWN_ARROW:
                if (selectionIndex < maxIndex) {
                  const newIndex = selectionIndex + 1;
                  onNavigateSelection(true, newIndex);
                } else if (!selectionIndex || selectionIndex === -1) {
                  onNavigateSelection(true, 0);
                }
                break;
              case ENTER:
                onSelectItem(selectionIndex);
                break;
              default:
                break;
            }
          }
        }, 0);
      } catch (e) {
        console.error('ERROR - onItemSelected', e);
      }
    },
    [list.length, maxIndex, onNavigateSelection, onSelectItem, opened, selectionIndex]
  );

  useEventListener('keydown', onKeyDownListener);
  const onToggleSearch = useCallback(
    (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      if (!authUser || !authUser?.id) return null;
      dispatch(setIsAppSearchOpen(!opened));
      // searchInputRef.current.focus();
    },
    [dispatch, authUser, opened]
  );

  const setSearchResult = async (query) => {
    const result = await getLookupSearchList(query);
    setList(result);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchData = useCallback(debounce(setSearchResult, 300), []);

  const hotKeyOptions = {
    enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']
  };
  useHotkeys('ctrl+k,cmd+k', onToggleSearch, hotKeyOptions);
  useHotkeys(
    'esc',
    () => {
      //if open, clear search text if present else close search modal
      if (opened) {
        if (searchText) {
          setSearchText('');
          setList([]);
        } else {
          dispatch(setIsAppSearchOpen(false));
        }
      }
    },
    hotKeyOptions
  );

  const onSeachQueryChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchText(value);
      debounceSetSearchData(value);
      if (!opened && value) {
        dispatch(setIsAppSearchOpen(true));
      }
    },
    [debounceSetSearchData, opened, dispatch]
  );

  const getGravatar = useCallback(
    (item) => {
      let gravatar;
      const table = getTabale(item);
      if (table) {
        if (table?.type === LOOKUP_TYPE.contacts && item?.email) {
          gravatar = getGravatarURL(item?.email);
        }
      }
      return gravatar;
    },
    [getTabale]
  );

  if (!opened) return null;

  return (
    <>
      <SearchContainer>
        <SearchRow>
          <SearchView>
            <SVGIcon fill='var(--white-color)' size={18} name='modal-search-icon' strokeWidth={2} />
          </SearchView>
          <RightView>
            <Input
              autoFocus
              isPrimarySmall={true}
              placeholder='Search by contact/company name or email or phone'
              value={searchText}
              onChange={onSeachQueryChange}
              // ref={searchInputRef}
            />
          </RightView>
          <EscSpan>⌘ + K</EscSpan>
        </SearchRow>
        <ListView>
          {list?.map((item, index) => {
            const gravatar = getGravatar(item);
            const selected = index === selectionIndex;
            return (
              <RowView
                key={`search_item_${item?.id}_${index}`}
                selected={selected}
                onClick={() => {
                  onLookupSelected(item);
                }}
                ref={(row) => {
                  if (selected) elSelectedRow = row;
                }}>
                {/* <UserIcon /> */}
                <Avtar imgSrc={gravatar} name={item?.name} size={20} />
                <UserName>{item?.name}</UserName>
                {item?.email && <UserEmail>{item?.email}</UserEmail>}
              </RowView>
            );
          })}
        </ListView>
      </SearchContainer>
      <OverLayDiv onClick={onClose}></OverLayDiv>
    </>
  );
};

export default SearchBar;
