import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../assets/svg/SVGIcon';
import Table from '../../../components/Table/Table';
import MobileTitleBar from '../../../components/TitleBar/MobileTitleBar';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { getSmsLogList } from '../../../services/callLogService';
import SmsLogEmptyState from './SmsLogEmptyState';
import { CollLogMain, CollLogHeader, CollLogContainer, LinkBlock, LinkListItem, TableStyle, IconBox } from './styles';

const Message = () => {
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const history = useHistory();
  const organizationSelector = useSelector((state) => state.organization);
  const smsLogSelector = useSelector((state) => state.smsLog);
  const { organization } = organizationSelector;
  const { list, loading } = smsLogSelector;
  const { id: organization_id } = organization;
  const loadData = useCallback(() => {
    dispatch(getSmsLogList(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const tableColumns = useMemo(() => {
    return [
      { Header: 'To', accessor: 'to' },
      { Header: 'From', accessor: 'from' },
      { Header: 'Time', accessor: 'time' },
      { Header: 'Text', accessor: 'body' }
    ];
  }, []);

  const tableData = useMemo(() => {
    const columns = [
      {
        Header: () => <></>,
        accessor: 'call',
        Cell: (props) => {
          return (
            <IconBox title={props?.cell?.row?.original?.status}>
              {props?.cell?.row?.original?.status === 'sent' || props?.cell?.row?.original?.status === 'delivered' ? (
                <SVGIcon name='icon-Msg-Send' fill='var(--callreject-icon)' size={18}></SVGIcon>
              ) : (
                <SVGIcon name='icon-Msg-Receive' fill='var(--mantis-icon)' size={18}></SVGIcon>
              )}
            </IconBox>
          );
        }
      },
      ...tableColumns
    ];

    const data = list?.map((item) => {
      return {
        ...item,
        time: moment(item.date).format('DD MMM YYYY HH:mm:ss')
      };
    });
    return {
      columns,
      data: data
    };
  }, [list, tableColumns]);
  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={true} hasTitle={true}>
          <CollLogHeader mobile={mobile}>
            <LinkBlock>
              <LinkListItem
                onClick={() => {
                  history.push('/settings/calllog/call');
                }}>
                Call
              </LinkListItem>
              <LinkListItem
                className='active'
                onClick={() => {
                  history.push('/settings/calllog/message');
                }}>
                Message
              </LinkListItem>
            </LinkBlock>
          </CollLogHeader>
        </MobileTitleBar>
      )}
      <CollLogMain>
        {mobile ? null : (
          <CollLogHeader>
            <LinkBlock>
              <LinkListItem
                onClick={() => {
                  history.push('/settings/calllog/call');
                }}>
                Call
              </LinkListItem>
              <LinkListItem
                className='active'
                onClick={() => {
                  history.push('/settings/calllog/message');
                }}>
                Message
              </LinkListItem>
            </LinkBlock>
          </CollLogHeader>
        )}
        <CollLogContainer>
          {!loading && (!list || list?.length === 0) && <SmsLogEmptyState />}
          {loading && (!list || list?.length === 0) ? (
            <div>Loading...</div>
          ) : (
            list &&
            list?.length > 0 && (
              <TableStyle mobile={mobile}>
                <Table columns={tableData?.columns || []} data={tableData?.data || []}></Table>
              </TableStyle>
            )
          )}
        </CollLogContainer>
      </CollLogMain>
    </>
  );
};

export default Message;
