import React from 'react';
import PropTypes from 'prop-types';
import { Menu as MaterialMenu } from '@material-ui/core';

const Menu = ({ position, overflow, positionVertical, width, marginTop, boxShadow, border, ...props }) => {
  return (
    <>
      <MaterialMenu
        transitionDuration={0}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: positionVertical,
          horizontal: position
        }}
        transformOrigin={{
          vertical: positionVertical,
          horizontal: position
        }}
        PaperProps={{
          style: {
            width: width,
            marginTop: marginTop,
            overflow: overflow,
            borderRadius: 8,
            boxShadow: 'var(--box-shadow-two)',
            border: border
          }
        }}
        marginThreshold={0}
        {...props}
      />
    </>
  );
};

export default Menu;

Menu.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.oneOf(['right', 'left', 'center']),
  positionVertical: PropTypes.oneOf(['top', 'bottom', 'center']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Menu.defaultProps = {
  open: true,
  position: 'right',
  positionVertical: 'bottom'
};
