import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { PHONE_CALL_TYPE } from '../../constants/constant';
import { getGravatarURL } from '../../helpers/common';
import TwilioService from '../../services/twilioService';
import DropDown from '../DropDown/DropDown';
import SVGIcon from '../../assets/svg/SVGIcon';
import {
  MainContain,
  FirstList,
  CallStatus,
  Secondlist,
  LeftSide,
  ImgView,
  ProfileDetail,
  UserName,
  UserNub,
  RightSide,
  Anscall,
  Decline,
  CallStart,
  CallTime,
  IconView,
  BottonMenu,
  Btmmenu,
  CallingHeaderLeft,
  DragAndDropIcon,
  CallfunctionBlock,
  CallFunctionOptionBlock,
  CallFunctionOption,
  CallSettingBlock,
  MicrophoneSettingDropdown,
  SpeakerSettingDropdown,
  CallDiscoonectBlock,
  customestyle,
  DropDownLeftIcon
} from './styles';

let timer;
function Calling() {
  const authSelector = useSelector((state) => state.auth);
  const phoneCallSelector = useSelector((state) => state.phoneCall);
  const { currentUser } = authSelector;
  const { details, callInputDevices = [], callOutputDevices = [] } = phoneCallSelector || {};
  const [callDuration, setCallDuration] = useState();
  const [isMute, setIsMute] = useState(false);
  const [isMinimize, setMinimize] = useState(false);
  const [isSettings, toggleSettings] = useState(false);
  const dispatch = useDispatch();

  const { name, phoneNumber, type, lookup, connectedAt } = details || {};
  const gravatar = getGravatarURL(lookup?.email);

  const onReceiveCall = useCallback(() => {
    dispatch(TwilioService.getInstance().acceptCall());
  }, [dispatch]);

  const onDisconnectCall = useCallback(() => {
    if (connectedAt) dispatch(TwilioService.getInstance().disconnectCall());
    else dispatch(TwilioService.getInstance().rejectCall());
  }, [dispatch, connectedAt]);

  const onMuteUnmuteCall = useCallback(() => {
    const newValue = !isMute;
    setIsMute(newValue);
    dispatch(TwilioService.getInstance().muteUnmuteCall(newValue));
  }, [dispatch, isMute]);

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (connectedAt) {
      timer = setInterval(() => {
        const startTime = moment(connectedAt);
        const endTime = moment();
        const hrs = moment.utc(endTime.diff(startTime)).format('HH');
        const min = moment.utc(endTime.diff(startTime)).format('mm');
        const sec = moment.utc(endTime.diff(startTime)).format('ss');
        setCallDuration([hrs, min, sec].join(':'));
      }, 1000);
    } else {
      setCallDuration(undefined);
    }
    return () => {
      setCallDuration(undefined);
      if (timer) clearInterval(timer);
    };
  }, [connectedAt]);

  const onChangeInputDevice = useCallback(
    (item) => {
      dispatch(TwilioService.getInstance().onChangeInputDevice(item?.id));
    },
    [dispatch]
  );

  const onChangeOutputDevice = useCallback(
    (item) => {
      dispatch(TwilioService.getInstance().onChangeOutputDevice(item?.id));
    },
    [dispatch]
  );

  const onShowSettings = useCallback(() => {
    toggleSettings(!isSettings);
  }, [isSettings]);

  const selectedInputDevice = useMemo(() => {
    return callInputDevices?.find((x) => x.selected === true);
  }, [callInputDevices]);

  const selectedOutputDevice = useMemo(() => {
    return callOutputDevices?.find((x) => x.selected === true);
  }, [callOutputDevices]);

  if (!currentUser) return null;
  if (!details) return null;

  let callStatusText = type === PHONE_CALL_TYPE.INCOMING ? 'Incoming call...' : 'Calling...';
  // eslint-disable-next-line max-len
  let minCallStatus =
    type === PHONE_CALL_TYPE.INCOMING
      ? `Incoming call... ${name || ''} (${phoneNumber})`
      : `Calling... ${name || ''} (${phoneNumber})`;
  if (connectedAt) {
    callStatusText = `Call running`;
    minCallStatus = `Call running : ${name || ''} ${phoneNumber || ''}`;
  }

  return (
    <>
      {!isMinimize ? (
        <MainContain>
          <FirstList>
            <CallingHeaderLeft>
              <DragAndDropIcon>
                <SVGIcon name='icon-drag-and-drop' size={12} fill='var(--gray-color)' />
              </DragAndDropIcon>
              <CallStatus>{callStatusText}</CallStatus>
            </CallingHeaderLeft>
            <IconView
              onClick={(e) => {
                setMinimize(true);
              }}>
              <SVGIcon name='icon-minus' size={16} fill='var(--white-color)' />
            </IconView>
          </FirstList>
          <Secondlist>
            <LeftSide>
              <ImgView>
                <Avatar className='avatar' src={gravatar}></Avatar>
              </ImgView>
              <ProfileDetail>
                <UserName>{name}</UserName>
                <UserNub>{phoneNumber}</UserNub>
              </ProfileDetail>
            </LeftSide>
            <RightSide>
              {type === PHONE_CALL_TYPE.INCOMING && !connectedAt && (
                <Anscall onClick={onReceiveCall}>
                  <SVGIcon name='icon-phone' size={18} fill='var(--white-color)' />
                </Anscall>
              )}
              {callDuration && (
                <CallStart>
                  <CallTime>{callDuration}</CallTime>
                </CallStart>
              )}
              {!connectedAt && (
                <Decline onClick={onDisconnectCall}>
                  <SVGIcon name='icon-not-answered' size={18} fill='var(--white-color)' />
                </Decline>
              )}
            </RightSide>
          </Secondlist>
          {connectedAt && (
            <CallfunctionBlock>
              <CallFunctionOptionBlock>
                <CallFunctionOption onClick={onShowSettings} selected={isSettings}>
                  <SVGIcon name='icon-tools' size={18} fill='var(--white-color)' />
                </CallFunctionOption>
                <CallFunctionOption title='Coming soon'>
                  <SVGIcon name='icon-keypad' size={18} fill='var(--white-color)' />
                </CallFunctionOption>
                <CallFunctionOption title='Coming soon'>
                  <SVGIcon name='icon-recording' size={18} fill='var(--white-color)' />
                </CallFunctionOption>
                <CallFunctionOption onClick={onMuteUnmuteCall} selected={isMute}>
                  <SVGIcon
                    name={isMute ? 'icon-microphone-off' : 'icon-microphone-on'}
                    size={18}
                    fill='var(--white-color)'
                  />
                </CallFunctionOption>
                <CallFunctionOption title='Coming soon'>
                  <SVGIcon name='icon-audio-pause' size={18} fill='var(--white-color)' />
                </CallFunctionOption>
                <CallDiscoonectBlock onClick={onDisconnectCall}>
                  <SVGIcon name='icon-not-answered' size={18} fill='var(--white-color)' />
                </CallDiscoonectBlock>
              </CallFunctionOptionBlock>
              {isSettings && (
                <CallSettingBlock>
                  <MicrophoneSettingDropdown>
                    <DropDownLeftIcon>
                      <SVGIcon name='icon-microphone-on' fill='var(--disable-color)' size={16} />
                    </DropDownLeftIcon>
                    <DropDown
                      options={callInputDevices || []}
                      styles={customestyle}
                      labelField={'label'}
                      valueField={'id'}
                      value={selectedInputDevice?.value}
                      menuPlacement={'auto'}
                      onChange={onChangeInputDevice}
                      className='basic-multi-select'
                    />
                  </MicrophoneSettingDropdown>
                  <SpeakerSettingDropdown>
                    <DropDownLeftIcon>
                      <SVGIcon name='icon-audio-non-disable' fill='var(--disable-color)' size={16} />
                    </DropDownLeftIcon>
                    <DropDown
                      options={callOutputDevices || []}
                      styles={customestyle}
                      labelField={'label'}
                      valueField={'id'}
                      value={selectedOutputDevice?.value}
                      menuPlacement={'auto'}
                      onChange={onChangeOutputDevice}
                      className='basic-multi-select'
                    />
                  </SpeakerSettingDropdown>
                </CallSettingBlock>
              )}
            </CallfunctionBlock>
          )}
        </MainContain>
      ) : (
        <BottonMenu>
          <Btmmenu>
            <CallStatus>{minCallStatus}</CallStatus>
            <IconView
              onClick={(e) => {
                setMinimize(false);
              }}>
              <SVGIcon name='icon-up-arrow' size={18} fill='var(--white-color)' />
            </IconView>
          </Btmmenu>
        </BottonMenu>
      )}
    </>
  );
}

export default Calling;
