import styled from 'styled-components';
import { Header1, Text1 } from '../../styles';

const AccessContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AccessBlock = styled.div`
  max-width: 374px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AccessHead = styled.h5`
  ${Header1}
  margin: 14px 0px 6px 0px;
  color: var(--dark-color);
  font-weight: 500;
`;

const AcceessText = styled.span`
  ${Text1}
  color: var(--gray-color);
  margin-bottom: 30px;
`;

export { AccessContainer, AccessBlock, AccessHead, AcceessText };
