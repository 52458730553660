import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage, setWarningMessage } from '../../actions/messageActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const SnackBar = () => {
  const message = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const { sucessMessage, errorMessage, warningMessage } = message;
  const messageText = sucessMessage || errorMessage || warningMessage;

  const classes = useStyles();

  const severity = useMemo(() => {
    if (errorMessage) {
      return 'error';
    } else if (sucessMessage) {
      return 'success';
    } else if (warningMessage) {
      return 'warning';
    }
  }, [errorMessage, sucessMessage, warningMessage]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (messageText) {
      messageText && setOpen(true);
    } else {
      setOpen(false);
    }
  }, [dispatch, messageText]);

  const onDismiss = useCallback(() => {
    dispatch(setErrorMessage(null));
    dispatch(setSuccessMessage(null));
    dispatch(setWarningMessage(null));
  }, [dispatch]);

  const onSnakcbarDismiss = useCallback(() => {
    setOpen(false);
    onDismiss();
  }, [setOpen, onDismiss]);

  const renderAlert = (severity) => {
    switch (severity) {
      case 'success':
        return (
          <Alert onClose={onSnakcbarDismiss} severity='success'>
            {sucessMessage}
          </Alert>
        );
      case 'error':
        return (
          <Alert onClose={onSnakcbarDismiss} severity='error'>
            {errorMessage}
          </Alert>
        );
      case 'warning':
        return (
          <Alert onClose={onSnakcbarDismiss} severity='warning'>
            {warningMessage}
          </Alert>
        );
      default:
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={onSnakcbarDismiss}>
        {renderAlert(severity)}
      </Snackbar>
    </div>
  );
};

export default SnackBar;
