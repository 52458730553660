import styled, { css } from 'styled-components';
import { Header2, Header4, Text1, Header3, SubHeader3 } from '../../../../styles';

const PageWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const MainList = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  margin-top: 16px;
`;
const Title = styled.h1`
  margin: 0;
  ${Header2}
  color: var(--dark-color);
`;
const ContainBoxTwo = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  margin-top: 25px;
  ${(props) =>
    props.margin &&
    css`
      margin: 20px 0;
    `}
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const FirstList = styled.div``;
const ContainBox = styled.div`
  svg {
    margin-right: 35px;
  }
  display: flex;
  align-items: center;
`;
const TextWrapper = styled.div``;
const SubText = styled.h4`
  margin: 0 0 0 12px;
  font-style: normal;
  ${Text1}
  text-transform:capitalize;
  color: var(--dark-color);
  display: inline-block;
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1}
  color: var(--gray-color);
  margin: 0;
`;
const IconView = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const LoginDetails = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 10px;
  }
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--Platinum-color);
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const Form = styled.form`
  overflow: auto;
`;
const MainContact = styled.div`
  background: var(--white-color);
  border: 1px solid var(--Platinum-color);
  box-shadow: 0px 6px 25px var(--modal-shadow);
  width: 350px;
  height: 100%;
  margin-left: auto;
  position: relative;
  outline: none;
`;
const TitleText = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px 12px 18px;
  border-bottom: 1px solid var(--Platinum-color);
  svg {
    width: 19px;
    height: 19px;
    cursor: pointer;
  }
`;
const ButtonWrapper = styled.div`
  :nth-child(2) {
    margin-left: 16px;
  }
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 157px;
  justify-content: space-between;
`;
const RightWrapper = styled.div``;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Step1 = styled.div``;
const FormWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const TitleForm = styled.h2`
  ${Header3}
  margin-bottom: 3px;
  color: var(--dark-color);
  margin-top: 16px;
`;
const FormPara = styled.p`
  margin: 0;
  ${Text1}
  color: var(--gray-color);
  margin-bottom: 20px;
`;
const ListWrappper = styled.div`
  width: 100%;
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: block;
`;
const marginBottom = css`
  margin-bottom: 16px;
`;
const Step2 = styled.div`
  h2 {
    margin-bottom: 10px;
  }
`;
const NavigationWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const NavigationTitle = styled.span`
  margin-top: 1px;
`;
const IconLabel = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const LabelTitle = styled.span`
  ${SubHeader3};
  color: var(--dark-color);
  margin-left: 15px;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const CustomCheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
`;
const InputWrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const MailWraper = styled.div``;
const NavigationTitleCord = styled.span`
  ${Header2}
  color:var(--dark-color);
  margin-left: 8px;
`;
const NavigationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    cursor: pointer;
  }
`;
const TitleList = styled.h3`
  ${Header4}
  color: var( --dark-color);
  margin: 0;
`;
const BorderBottom = styled.div`
  padding-bottom: 4px;
  border-bottom: 1px solid var(--gray-border-color);
  width: 100%;
  ${(props) =>
    props.display &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  i {
    cursor: pointer;
  }
`;
const TextCopyWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;
const ListBlock = styled.div`
  width: 100%;
  input {
    border: 0;
    padding: 3px 0;
    color: var(--gray-color);
    ${Text1}
    &:focus {
      border: 0;
    }
  }
  textarea {
    margin-top: 16px;
    font-family: var(--primary-font-stack);
    ${Text1}
    color:var(--gray-color);
  }
`;
const IconWrapper = styled.div`
  border-left: 1px solid var(--gray-border-color);
  padding-left: 12px;
  i {
    cursor: pointer;
  }
`;
const TextArea = styled.textarea`
  border: 0;
  resize: none;
  width: 100%;
  min-height: 108px;
  overflow: auto;
  padding: 0;
  :disabled {
    background-color: var(--white-color);
  }
`;
const ModifyUserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 12px 16px;
  border-left: 1px solid var(--gray-border-color);
  border-right: 1px solid var(--gray-border-color);
  border-bottom: 1px solid var(--gray-border-color);
  margin: 0 -1px;
  border-radius: 6px 6px 0px 0px;
`;
const ModifyUserBlock = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-bottom: 24px;
  input {
    border: 0;
    padding: 0;
    ${Header4}
    :focus {
      box-shadow: none;
    }
  }
`;
const ModifyUserContent = styled.div`
  border-top: 1px solid var(--gray-border-color);
  border-bottom: 1px solid var(--gray-border-color);
  border-left: 1px solid var(--gray-border-color);
  border-right: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  padding: 12px 16px 12px 28px;
  position: relative;
  display: flex;
  align-items: center;
  margin: -1px;
  .leftIcon {
    opacity: 0;
  }
  .icon-more {
    opacity: 0;
    cursor: pointer;
  }
  :hover {
    .leftIcon {
      opacity: 1;
    }
    .icon-more {
      opacity: 1;
    }
  }
`;
const SecondContain = styled.div`
  padding: 12px 0 24px 0;
`;
const DragIcon = styled.div`
  cursor: pointer;
  position: absolute;
  left: 8px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DragWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FieldWrapper = styled.div`
  padding: 16px;
  span {
    cursor: pointer;
    color: var(--primary-color);
    ${Text1}
  }
`;
const Span = styled.span`
  color: var(--primary-color);
  ${Text1}
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  padding: 6px 10px;
  display: inline-block;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const GroupWrapper = styled.div`
  text-align: center;
`;

export {
  PageWrapper,
  MainList,
  Title,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  LoginDetails,
  BottomView,
  Form,
  MainContact,
  TitleText,
  TitleView,
  ButtonWrapper,
  LeftWrapper,
  RightWrapper,
  WrapperTitle,
  FormWrapper,
  TitleForm,
  FormPara,
  ListWrappper,
  DropDownTitle,
  marginBottom,
  Step1,
  Step2,
  NavigationWrapper,
  NavigationTitle,
  IconLabel,
  LabelTitle,
  InputWrapperModal,
  CustomCheckboxWrapper,
  InputWrapperBlock,
  ContainBox,
  TextWrapper,
  MailWraper,
  NavigationTitleCord,
  NavigationBlock,
  TitleList,
  BorderBottom,
  TextCopyWrapper,
  ListBlock,
  IconWrapper,
  TextArea,
  ModifyUserWrapper,
  ModifyUserBlock,
  ModifyUserContent,
  SecondContain,
  DragIcon,
  DragWrapper,
  FieldWrapper,
  Span,
  GroupWrapper
};
