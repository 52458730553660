import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import { Dialog, DialogContentText } from '@material-ui/core';
import Button from '../atom/Button/Button';
import Icon from '../Icon/Icon';
import { HeaderWrapper, HeaderLeft, Title, Backdrop, Paper, DialogContentBox, DialogActionsBox } from './styles';

const ConfirmDialogView = ({
  title = '',
  iconName,
  closeIcon,
  message = '',
  okButtonText = 'Ok',
  cancelButtonText = 'Cancel',
  onOk,
  onCancel,
  okButtonStyle = {},
  cancelButtonStyle = {}
}) => {
  const onCloseDialog = useCallback(() => {
    removeElementReconfirm();
    if (onCancel) onCancel();
  }, [onCancel]);

  const onCancelClicked = useCallback(() => {
    removeElementReconfirm();
    if (onCancel) onCancel();
  }, [onCancel]);

  const onOkClicked = useCallback(() => {
    removeElementReconfirm();
    if (onOk) onOk();
  }, [onOk]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={onCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        BackdropComponent={Backdrop}
        PaperComponent={Paper}>
        <HeaderWrapper>
          <HeaderLeft>
            {iconName && <Icon name={iconName} fontSize='22' color='var(--gray-icon-color)' />}
            {title && <Title id='alert-dialog-title'>{title}</Title>}
          </HeaderLeft>
          {closeIcon && (
            <Icon name={closeIcon} onClick={onCancelClicked} fontSize='20' color='var(--gray-icon-color)' />
          )}
        </HeaderWrapper>
        <DialogContentBox>
          <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
        </DialogContentBox>
        <DialogActionsBox>
          <Button onClick={onCancelClicked} style={cancelButtonStyle} secondary title={cancelButtonText} />
          <Button onClick={onOkClicked} autoFocus style={okButtonStyle} title={okButtonText} />
        </DialogActionsBox>
      </Dialog>
    </div>
  );
};

ConfirmDialogView.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  dialogStyle: PropTypes.object,
  okButtonStyle: PropTypes.object,
  cancelButtonStyle: PropTypes.object
};

ConfirmDialogView.defaultProps = {
  message: '',
  okButtonText: 'Ok',
  cancelButtonText: 'Cancel'
};

export function ConfirmDialog(properties) {
  createElementReconfirm(properties);
}

function removeElementReconfirm() {
  const target = document.getElementById('sc-confirm-dialog');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
}

function createElementReconfirm(properties) {
  let divTarget = document.getElementById('sc-confirm-dialog');
  if (divTarget) {
    render(<ConfirmDialogView {...properties} />, divTarget);
  } else {
    divTarget = document.createElement('div');
    divTarget.id = 'sc-confirm-dialog';
    document.body.appendChild(divTarget);
    render(<ConfirmDialogView {...properties} />, divTarget);
  }
}
