import ContactDetail from '../staticPages/StaticContact/ContactDetail/ContactDetail';
import { ROUTE } from '../constants/routeConst';
import StaticInbox2 from '../staticPages/StaticInbox2/StaticInbox2';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: ROUTE.STATIC_INBOX2,
    exact: true,
    component: StaticInbox2,
    private: true
  },
  {
    path: ROUTE.STATIC_CONTACT_DETAILS,
    exact: true,
    component: ContactDetail,
    private: true
  }
];

export default routes;
