import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Header4, Header6, Header7, Text1, Text2 } from '../../styles';
import SVGIcon from '../../assets/svg/SVGIcon';
import { isElectronApp, isMACApp } from '../../global/Helper';
import { Menu } from '../Menu';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid var(--deal-column-bg)',
    padding: '0px 8px',
    background: 'var(--deal-column-bg)',
    width: '199px',
    marginTop: '20px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));
const StyledMenuTwo = withStyles({
  paper: {
    border: '1px solid var(--nav-dark-color-focus)',
    padding: '0px 6px',
    background: 'var(--nav-dark-color-hover)',
    width: '184px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));

// const NavIcon = styled(Icon).attrs((props) => ({ icon: props.icon, // or whatever you want here }))``;

const NavIcon = styled(SVGIcon).attrs(({ help }) => ({
  size: '18',
  fill: help ? 'var(--gray-color)' : 'var(--white-color)',
  strokeWidth: '2',
  bgfill: 'var(--icon-fill)'
}))``;

const MenuItemLink = styled(NavLink)`
  &.active_menu {
    background: var(--nav-dark-color-focus);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--white-color);
  }
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 1;
  border-radius: 6px;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          padding: 7px 10px;
        `
      : css`
          padding: 7px 10px;
        `}
`;
const MainNav = styled.div`
  height: 100%;
  width: 200px;
`;
const MainNavWrapper = styled.div`
  background: var(--nav-dark-color);
  height: 100%;
  width: 100%;
  ${(props) =>
    props.mobile
      ? css``
      : css`
          box-shadow: 0.5px 0px 0px var(--Platinum-color);
        `}
`;

const NavList = styled.div`
  height: 100%;
  width: 100%;
  ${() =>
    isElectronApp() &&
    css`
      padding-top: 32px;
    `}
  ${() =>
    !isMACApp() &&
    css`
      padding-top: 0px;
    `}
`;
const MoreIcon = styled.div`
  color: var(--gray-color);
  font-size: 18px;
  line-height: 18px;
`;
const ListView = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const NavbarTop = styled.div`
  margin: 8px 8px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`;
const Profile = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
const Li = styled.li`
  list-style: none;
  margin: 0px 8px 8px 8px;
  border-radius: 6px;
  z-index: 9999;
  :hover {
    background: var(--titlebar-bg);
  }
  i {
    -webkit-text-stroke: 0.3px;
    text-shadow: var(--dark-color);
  }
  ${(props) =>
    props.mobile &&
    css`
      :first-child {
        margin: 20px 8px 8px 8px;
      }
    `}
`;
const LiMore = styled.li`
  margin: 0 8px 8px 8px;
  border-radius: 6px;

  i {
    color: var(--gray-more-icon);
  }
  span {
    color: var(--gray-more-icon);
  }
  :hover {
    i {
      color: var(--dark-color);
    }
    span {
      color: var(--white-color);
    }
  }
`;
const Alist = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 7px 10px;
  text-decoration: none;
  border-radius: 6px;
  color: var(--gray-color);
  cursor: pointer;
  &:hover {
    background: var(--nav-dark-color-focus);
  }
  &:active {
    background: var(--nav-dark-color-focus);
  }
`;
const AlistMore = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  text-decoration: none;
  border-radius: 6px;
  color: var(--gray-color);
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          padding: 7px 10px;
        `
      : css`
          padding: 7px 10px;
        `}
`;
const SpanList = styled.span`
  color: var(--white-color);
  margin-left: 14px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${Text1}
  ${(props) =>
    props.help &&
    css`
      color: var(--gray-color);
    `}
`;
const NumbList = styled.span`
  ${Text2};
  color: var(--regent-gray-color);
  margin-left: auto;
`;
const SpanListtwo = styled.span`
  color: var(--gray-color);
  margin-left: 16px;
  ${(props) =>
    props.mobile
      ? css`
          ${Text1};
        `
      : css`
          ${Text1};
        `}
`;
const NavBottom = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0px 8px 0 8px;
  background: var(--cultured-color);
  width: 200px;
`;
const Menulist = styled.span``;
const UlTwo = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const LiTwo = styled.li`
  margin: 0 0 2px 0;
  ${(props) =>
    props.isProfile &&
    css`
      margin-bottom: 0;
    `}
`;
const AlistTwo = styled.a`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--dark-color);
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: var(--nav-dark-color-focus);
  }
  &:active {
    background: var(--nav-dark-color-focus);
  }
`;
const SpanListMenu = styled.span`
  color: var(--white-color);
  ${Text1}
`;
const SpanListMenu2 = styled.span`
  color: var(--white-color);
  margin-right: 4px;
  ${Text1}
`;
const MainContain = styled.div``;
const FirstRow = styled.div`
  padding: 14px 12px 13px 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--Platinum-color);
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const HeadTitle = styled.h3`
  ${Header4};
  color: var(--nav-dark-color);
  margin: 0;
  margin-left: 12px;
`;
const SeconView = styled.div`
  padding: 7px 10px 8px 10px;
`;
const LinkTag = styled.a`
  text-decoration: none;
  display: block;
`;
const SecondRow = styled.div`
  display: flex;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const TextView = styled.div`
  margin-left: 12px;
`;
const HeadCaption = styled.h3`
  ${Text1};
  color: var(--nav-dark-color);
  margin: 0;
  margin-bottom: 2px;
`;
const CaptionText = styled.span`
  ${Text2};
  color: var(--gray-color);
`;
const IconView = styled.div`
  cursor: pointer;
`;

const SpanPlanExpired = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const SpanPlan = styled.span`
  ${Text1};
  color: var(--Red-salsa-color);
`;
const PlanLi = styled.li`
  background-color: var(--nav-dark-color-focus);
  text-align: center;
  margin: 0 -8px;
  padding: 11px;
  list-style: none;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  margin: 0 18px 8px 18px;
  position: relative;
  .search-main {
    margin-bottom: 4px;
  }
`;
const LeftText = styled.span`
  text-transform: uppercase;
  color: var(--Shuttle-Gray);
  padding: 7px 0;
  ${(props) =>
    props.mobile
      ? css`
          ${Text2};
        `
      : css`
          ${Header6};
        `}
`;
const HrLine = styled.div`
  height: 1px;
  background: var(--collection-linear);
  width: 100%;
  margin-left: 8px;
`;
const IconWrapper = styled.div`
  border-radius: 60px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  flex: 0 0 24px;
  cursor: pointer;
  i {
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-text-stroke: 0.3px;
    text-shadow: var(--sonic-silver);
  }
  ${(props) =>
    props.mobile
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;
const CompanyName = styled.span`
  ${Text1}
  color: var(--white-color);
  letter-spacing: 0.4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
const TopLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin-right: 8px;
  border-radius: 6px;
  cursor: pointer;
  :hover {
    background-color: var(--titlebar-bg);
  }
`;
const NotifyIcon = styled.div`
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  border-radius: 6px;
  position: relative;
  ${(props) =>
    props.isOpenNotificationMenu &&
    css`
      background-color: var(--titlebar-bg);
    `}
  :hover {
    background-color: var(--titlebar-bg);
  }
  &.notification {
    :after {
      content: '';
      background-color: var(--danger-Base-bg);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 17px;
    }
    svg {
      path {
        stroke: var(--white-color);
      }
    }
  }
`;
const NavbarTitleBar = styled.div`
  margin: 20px 8px 20px 8px;
`;
const ProfileMenu = withStyles({
  paper: {
    border: '1px solid var(--nav-dark-color-focus)',
    padding: '0px 6px',
    background: 'var(--nav-dark-color-hover)',
    width: '184px',
    marginTop: '12px',
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));
const NotificationMenu = withStyles({
  paper: {
    background: 'var(--white-color)',
    width: ({ mobile }) => (mobile ? '100%' : '460px'),
    marginLeft: ({ mobile }) => (mobile ? '0' : '54px'),
    marginTop: ({ mobile }) => (mobile ? '0' : '10px'),
    maxWidth: ({ mobile }) => mobile && '100%',
    maxHeight: ({ mobile }) => mobile && '100%',
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  }
})((props) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: props.mobile ? 'right' : 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: props.mobile ? 'right' : 'left'
      }}
      {...props}
    />
  );
});

const HelpBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 6px;
  :hover {
    background-color: var(--titlebar-bg);
  }
  &.hideSpan {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  ${(props) =>
    props.isOpenHelpModal &&
    css`
      svg {
        path {
          stroke: var(--disable-color);
        }
      }
      background-color: var(--nav-dark-color-focus);
      span {
        color: var(--disable-color);
      }
    `}
  ${(props) =>
    props.mobile &&
    css`
      background-color: var(--nav-dark-color-focus);
      svg {
        path {
          stroke: var(--disable-color);
        }
      }
      span {
        color: var(--disable-color);
      }
      :hover {
        background-color: var(--nav-dark-color-focus);
        span {
          color: var(--disable-color);
        }
      }
    `}
`;

const HelpMainblock = styled.div`
  padding: 0px 8px 8px 8px;
  position: relative;
  z-index: 1;
`;
const FeedbackModal = styled.div`
  display: flex;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const ModalStyle = {
  width: '360px'
};
const MenuUl = styled.ul`
  list-style: none;
  margin: 6px 0 6px 0;
  padding: 0;
`;
const SlidebarAvtar = styled.div`
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin: 8px auto 20px auto;
  position: relative;
  z-index: 1;
`;
const SlidebarSearch = styled.div`
  width: 32px;
  height: 32px;
  background-color: var(--titlebar-bg);
  border-radius: 6px;
  margin-bottom: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TopLinear = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;
  z-index: 0;
  background: rgb(32, 38, 50);
  background: linear-gradient(0deg, rgba(32, 38, 50, 0) 0%, rgba(32, 38, 50, 1) 30%, rgba(32, 38, 50, 1) 100%);
`;
const BottmLinear = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 0;
  background: rgb(32, 38, 50);
  background: linear-gradient(180deg, rgba(32, 38, 50, 0) 0%, rgba(32, 38, 50, 1) 30%, rgba(32, 38, 50, 1) 100%);
`;
const SwitchList = styled.div`
  padding: 6px 0 8px 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #4a4e59;
  cursor: pointer;
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const WorkSpace = styled.span`
  color: var(--disable-color);
  display: block;
  ${(props) =>
    props.mobile
      ? css`
          ${Text2};
        `
      : css`
          ${Header7};
        `}
`;
const IconViewt = styled.div`
  display: inline-flex;
  min-height: 18px;
  min-width: 18px;
`;

const SearchButton = styled.button`
  cursor: pointer;
  padding: 6px 6px 6px 10px;
  width: 100%;
  background-color: var(--titlebar-bg);
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchLeft = styled.div`
  display: flex;
  align-items: center;
`;
const SearchRight = styled.div``;
const InputBox = styled.div`
  background-color: var(--nav-dark-color);
  border-radius: 4px;
  color: var(--titlebar-tag);
  padding: 3px 4px;
  width: fit-content;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  ${() =>
    isElectronApp() &&
    css`
      background-color: var(--nav-dark-color);
      color: var(--gray-disable-color);
    `}
  ${(props) =>
    props.mobile &&
    css`
      display: none;
    `}
`;

const SearchSpan = styled.span`
  ${Text1}
  color: var(--raven-color);
  display: block;
  margin-left: 14px;
`;

export {
  MainNav,
  MainNavWrapper,
  NavList,
  Ul,
  Li,
  Alist,
  SpanList,
  ListView,
  NumbList,
  SpanListtwo,
  NavBottom,
  StyledMenu,
  StyledMenuTwo,
  MenuItemLink,
  Menulist,
  UlTwo,
  LiTwo,
  AlistTwo,
  SpanListMenu,
  MainContain,
  FirstRow,
  RightView,
  HeadTitle,
  SeconView,
  SecondRow,
  TextView,
  HeadCaption,
  CaptionText,
  IconView,
  NavIcon,
  MoreIcon,
  LinkTag,
  SpanPlanExpired,
  PlanLi,
  LiMore,
  AlistMore,
  SpanPlan,
  TitleView,
  HrLine,
  LeftText,
  IconWrapper,
  NavbarTop,
  Profile,
  CompanyName,
  TopLeft,
  NavbarTitleBar,
  ProfileMenu,
  HelpBlock,
  HelpMainblock,
  FeedbackModal,
  ModalStyle,
  MenuUl,
  SlidebarAvtar,
  SlidebarSearch,
  SwitchList,
  FirstList,
  WorkSpace,
  SpanListMenu2,
  IconViewt,
  NotifyIcon,
  NotificationMenu,
  SearchButton,
  SearchRight,
  SearchLeft,
  InputBox,
  SearchSpan,
  TopLinear,
  BottmLinear
};
