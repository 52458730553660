import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set lookup Tables loader
 */
export const setLookupTableLoader = createAction(Actions.SET_LOOKUP_TABLE_LOADER);

/**
 * @desc Set lookup Tables
 */
export const setLookupTables = createAction(Actions.SET_LOOKUP_TABLES);

/**
 * @desc Set Table Fields
 */
export const setTableFields = createAction(Actions.SET_TABLE_FIELDS);

/**
 * @desc Set Table Item
 */
export const setLookupTableItem = createAction(Actions.SET_LOOKUP_TABLE_ITEM);

/**
 * @desc Update Table Item Field
 */
export const updateLookupTableItemField = createAction(Actions.UPDATE_LOOKUP_TABLE_ITEM);
