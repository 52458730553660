import styled, { css } from 'styled-components';
import { MenuItem, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import LeftArrow from '../../../assets/images/svg/chevron-left.svg';
import RightArrow from '../../../assets/images/svg/chevron-right.svg';
import { Header3, Header6, Header5, Text1, Text2 } from '../../../styles';
import { DETAIL_SCREEN_TYPE } from '../../../constants/constant';
import { Menu } from '../../../components/Menu';

const TwoListDetail = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0;
        `
      : css`
          padding: 0 20px 0 11px;
        `}
`;
const LeftList = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          padding: 0;
        `
      : css`
          max-width: 290px;
          padding: 16px 10px 16px 9px;
        `}
`;
const ProFileContain = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      position: relative;
      flex-direction: column;
    `}
`;
const ProfileLogo = styled.div`
  width: 40px;
  height: 40px;
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
  }
  div {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 0;
    color: var(--gray-color);
    span {
      ${Header3}
    }
  }
`;
const UserView = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin-top: 12px;
        `
      : css`
          margin-left: 14px;
        `}
`;
const UserName = styled.h4`
  margin: 0;
  font-style: normal;
  ${Header3};
  color: var(--dark-color);
`;
const UserLeave = styled.p`
  margin: 0;
  ${Text2};
  color: var(--gray-color);
  ${(props) =>
    props.mobile &&
    css`
      text-align: center;
    `}
`;
const BtnContain = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          margin-top: 16px;
          justify-content: center;
        `
      : css`
          margin-top: 12px;
        `}
`;
const SocialContain = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile
      ? css`
          margin-top: 16px;
          justify-content: center;
        `
      : css`
          margin-top: 24px;
        `}
`;
const SocialBtn = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;
const PhoneIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const IconView = styled.div`
  margin-right: 8px;
  button {
    padding: 0;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    i {
      margin: 0;
    }
  }
  ${(props) =>
    props.width &&
    css`
      width: 25px;
      display: inline-grid;
      button {
        width: 100%;
      }
    `}
`;
const WhatsappView = styled(NavLink)`
  margin-right: 8px;
  text-decoration: none;
  button {
    padding: 0;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    i {
      margin: 0;
    }
  }
  ${(props) =>
    props.width &&
    css`
      width: 25px;
      display: inline-grid;
      button {
        width: 100%;
      }
    `}
`;
const ContactProfileBlock = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      padding: 0px 16px;
      margin-top: -42px;
    `}
  ${(props) =>
    props.hasExternalApp
      ? css`
          display: none;
        `
      : css``}
`;
const RighLinkIcon = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
const ProfileExternalApp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const ExtraOrganize = styled.div`
  padding: 8px;
  border-bottom: 1px solid var(--gray-border-color);
`;
const ExtraOrganizeText = styled.span`
  ${Text1}
  color: var(--dark-color);
  margin-left: 12px;
`;
const ExtraOrganizeInner = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const AddContactBlock = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 3px 6px;
  background-color: var(--popup-color-hover);
  cursor: pointer;
  margin-right: auto;
  border-radius: 4px;
`;
const AddContactSpan = styled.span`
  ${Text2}
  color: var(--gray-icon-color);
  margin-left: 6px;
`;
const ProfileUpperBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ExternalEmpty = styled.div``;
const ExtraEmptyMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const EmptyHeading = styled.h3`
  ${Header5}
  color: var(--dark-color);
  margin: 32px 0 0 0;
`;
const EmptyPara = styled.span`
  ${Text1}
  color: var(--gray-color);
  margin-top: 10px;
  text-align: center;
`;
const FirstConatin = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  width: 100%;
  padding: 0 12px;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          margin-top: 0;
        `
      : css`
          max-width: 262px;
          margin-top: 24px;
        `}
`;
const FirstView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px 0;
        `
      : css`
          padding: 8px 0;
        `}
`;
const FirstList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`;
const BoxText = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin-left: 8px;
  flex-shrink: inherit;
  width: -webkit-fill-available;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const LastViewOne = styled.div`
  flex: 1 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  cursor: pointer;
  span {
    ${Text1}
    color:var(--dark-color);
    display: block;
    margin-left: auto;
  }
`;
const SecondView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  :last-child {
    border-bottom: 0;
  }
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px 0;
        `
      : css`
          padding: 8px 0;
        `}
`;
const LastView = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1 0 auto;
  justify-content: flex-end;
  i {
    margin-left: auto;
  }
`;
const Lasttext = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
  margin-left: auto;
`;
const Lasttext2 = styled.h3`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--darke-color);
  cursor: pointer;
  margin-left: auto;
`;
const SecondContain = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-top: 20px;
  width: 100%;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 262px;
        `}
`;
const TitleView = styled.div`
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
        `
      : css`
          padding: 8px 12px;
        `}
`;
const ALasttext = styled.a`
  font-style: normal;
  ${Text1};
  color: var(--primary-color);
  cursor: pointer;
  margin-left: auto;
  ${(props) =>
    props.margin &&
    css`
      margin-top: 12px;
      display: inline-block;
    `}
`;
const TagView = styled.div``;
const FirstTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
        `
      : css`
          padding: 8px 12px;
        `}
`;
const Tag = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 12px 12px 12px;
`;
const TagBox = styled.div`
  background: var(--popup-color-hover);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  height: 28px;
  padding: 0 8px;
  margin: 3px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin: 2px;
  }
  ${(props) =>
    props.margin &&
    css`
      width: 28px;
      i {
        margin: 2px 0;
      }
    `}
`;
const IconDelete = styled.div`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  :hover {
    background: var(--linear-gradient-hover);
    right: 0;
    top: 1px;
    bottom: 0;
    width: 100%;
    text-align: right;
    color: rgba(46, 62, 70, 0.5);
    border-radius: 4px;
    padding-right: 2px;
    opacity: 1;
    z-index: 1;
  }
`;
const TagText = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  white-space: pre;
`;
const TitleView2 = styled.div`
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding: 12px;
        `
      : css`
          padding: 8px 12px;
        `}
`;
const TextDetail = styled.h3`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin: 0 0 0 8px;
`;
const UlList = styled.div`
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          padding: 8px 12px 12px 12px;
        `
      : css`
          padding: 5px 15px 10px 15px;
        `}
`;
const Ul = styled.ul`
  list-style: none;
  padding: 4px 0 0 0;
  margin: 0;
`;
const IconA = styled.a`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -30px;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
  cursor: pointer;
`;
const Li = styled.li`
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  :first-child form::before {
    content: unset;
  }
  :last-child form::after {
    content: unset;
  }
  :hover {
    span {
      color: var(--dark-color);
    }
    p {
      color: var(--dark-color);
    }
  }
  :hover ${IconA} {
    right: -18px;
    opacity: 1;
    z-index: 1;
  }
`;
const ShowLi = styled(Li)`
  height: unset;
  padding-bottom: 5px;
`;
const LSpan = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  white-space: nowrap;
`;
const RText = styled.p`
  margin: 0 0 0 14px;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  width: 79%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const LSpan2 = styled.span`
  font-style: normal;
  ${Text1};
  text-decoration-line: underline;
  color: var(--gray-color);
  cursor: pointer;
`;
const FormView = styled.form`
  display: flex;
  align-items: center;
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
`;
const InputList = styled.div`
  position: absolute;
  top: 3px;
  right: 0;
  left: 0;
  width: 110%;
  z-index: 9;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      181.23deg,
      rgba(255, 255, 255, 0) 1.04%,
      #ffffff 28.3%,
      #ffffff 75.48%,
      rgba(255, 255, 255, 0) 98.95%
    );
    top: -32px;
    bottom: 0;
    left: 0;
    right: 10%;
    height: 100px;
    z-index: -1;
  }
`;
const SpanTitle = styled.span`
  ${Text2};
  color: var(--dark-color);
  position: absolute;
  padding: 0 5px;
  top: -8px;
  left: 8px;
  font-size: 12px;
  z-index: 2;
  background: var(--white-color);
`;
const RightIconView = styled.button`
  border-left: 1px solid var(--gray-border-color);
  padding: 6.75px;
  cursor: pointer;
`;
const CloseIconView = styled.button`
  border-left: 1px solid var(--gray-border-color);
  padding: 6.75px;
  cursor: pointer;
`;
const PopView = styled.div`
  padding: 2px 0 13px 0;
  .DayPicker {
    display: block;
    width: 100%;

    .DayPicker-wrapper {
      padding-bottom: 0;
    }

    .DayPicker-Caption {
      ${Header6};
      padding: 8px 0;
      margin-bottom: 0;
    }

    .DayPicker-NavButton {
      background-size: 100%;
      border-radius: 2px;
      margin-top: 0;
      top: 8px;
      right: 8px;
      &:hover {
        background-color: var(--popup-color-hover);
      }
    }

    .DayPicker-NavButton--prev {
      margin-right: 20px;
      background-image: url(${LeftArrow});
      width: 20px;
      height: 20px;
      border-radius: 2px;
      background-size: 6px;
      background-position: center;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: var(--popup-color-hover);
      color: var(--dark-color);
    }
    .DayPicker-NavButton--next {
      background-image: url(${RightArrow});
      width: 20px;
      height: 20px;
      border-radius: 2px;
      background-size: 6px;
      background-position: center;
    }

    .DayPicker-Month {
      margin: 0;
    }

    .DayPicker-WeekdaysRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
    }

    .DayPicker-Weekday {
      ${Text2};
      width: 24px;
      height: 24px;
      line-height: 1.7;
      color: var(--gray-color);
      padding: 2px;
      margin-top: 0;
    }

    .DayPicker-Week {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
    }
    .DayPicker-Caption > div {
      ${Text1};
      font-weight: 600;
      color: var(--dark-color);
    }
    .DayPicker-Day {
      border-radius: 5px;
      ${Text2};
      width: 24px;
      height: 24px;
      line-height: 1.7;
      padding: 2px;
      &:hover {
        background-color: var(--popup-color-hover) !important;
      }
    }
    .DayPicker-NavButton:hover {
      opacity: 1;
    }
  }
`;
const ViewList = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 6px 0;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: var(--popup-color-hover);
  }
`;
const DateTextView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 14px;
`;
const ShowText = styled.span`
  ${Text1};
  color: var(--dark-color);
`;
const ShowTime = styled.span`
  ${Text1};
  color: var(--gray-color);
`;
const Border = styled.hr`
  border-bottom: 1px solid var(--Platinum-color);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 6px 0 5px 0;
`;
const useStyles = makeStyles((theme) => ({
  paperMenu: {
    marginLeft: '-17px'
  }
}));
const Item = styled.div`
  display: flex;
  align-items: center;
  :hover ${IconA} {
    right: -18px;
    opacity: 1;
    z-index: 1;
  }
`;
const ProfileName = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 12px;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const InputBlock = styled.div``;
const InputSpan = styled.span``;
const InputEdit = styled.input``;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-left: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    right: 0px;
    margin: 0;
    top: 4px;
  }
  .checkmark {
    position: absolute;
    top: 4px;
    right: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const PopBtn = styled.div`
  margin: 6px;
  text-align: center;
  ${Text1}
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
  :hover {
    background-color: var(--popup-color-hover);
  }
`;
const BtnText = styled.span`
  color: var(--primary-color);
`;
const Selecter = styled.div``;
const BoxFirst = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ValueBox = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 9px 11px;
  margin-top: 12px;
  box-shadow: 0px 1px 3px var(--inbox-hover-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
const NameUserWrapper = styled.div`
  flex: 0 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NameUser = styled.span`
  color: var(--gray-color);
  text-transform: capitalize;
  display: initial;
  ${Text1};
  margin: 0;
  border-radius: 4px;
  padding: 1px 4px;
  width: fit-content;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  width: 100%;
  :hover {
    background: var(--popup-color-hover);
  }
`;
const UserAmount = styled.p`
  ${Text2};
  color: var(--dark-color);
  margin: 2px 4px 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  flex: 1 0 auto;
`;
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      minHeight: 'auto'
    },
    '&:hover': {
      background: 'var(--popup-color-hover)'
    },
    borderRadius: '5px;',
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '17px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '2px 6px',
    padding: '5px 6px',
    cursor: 'pointer'
  }
}))(MenuItem);
const AvtarWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--main-bg);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
`;
const AvtarWrapperBox = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--main-bg);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--primary-color-secondery-active);
  ${Text2}
  white-space: nowrap;
`;
const IconWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--white-color);
`;
const Customize = styled.div`
  padding: 12px 21px 4px 21px;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  ${Text1}
  margin-top: 6px;
  cursor: pointer;
  border-top: 1px solid var(--gray-border-color);
  svg {
    margin-left: 5px;
  }
`;
const Status = styled.span`
  ${Text1};
  color: var(--dark-color);
  max-width: 100%;
  margin: 0 9px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const InputWrapper = styled.div`
  margin: 6px;
  input {
    background-color: var(--popup-color-hover);
  }
`;
const TagItems = styled.div`
  max-height: 350px;
  overflow: auto;
`;
const TagMenu = withStyles({
  paper: {
    marginTop: ({ isBulk }) => (isBulk ? '10px' : '32px'),
    width: '200px',
    marginLeft: ({ isBulk }) => isBulk && '30px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: props.isBulk ? 'bottom' : 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));
const ActionWrapper = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${(props) =>
    props.mobile
      ? css`
          margin: 20px 0 10px 0;
        `
      : css`
          margin: 20px 0 0 0;
        `}
  ${(props) =>
    props.hasExternalApp
      ? css`
          display: none;
        `
      : css``}
`;
const Actions = styled.span`
  ${Text1};
  color: var(--gray-color);
  margin: 0;
  border-radius: 4px;
  padding: 1px 4px;
  width: fit-content;
  cursor: pointer;
  :hover {
    background: var(--popup-color-hover);
    color: var(--primary-color);
    ${(props) =>
      props.delete &&
      css`
        color: var(--danger-Base-bg);
      `}
  }
`;
const ActionLi = styled.li`
  position: relative;
  margin-left: 14px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  :last-child {
    margin-top: 6px;
  }
  :first-child {
    margin-left: 0;
    padding-left: 0;
    ::before {
      content: none;
    }
  }
  ::before {
    content: '';
    background-color: rgba(46, 62, 70, 0.24);
    width: 6px;
    height: 6px;
    border-radius: 24px;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
const ButtonWrapper = styled.div`
  margin-right: 8px;
`;
const ModalFormWrapper = styled.form`
  padding: 16px 20px;
  width: 500px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  max-height: 220px;
  overflow: hidden;
`;
const FirstContainWrap = styled.div`
  ${(props) =>
    props.screen === DETAIL_SCREEN_TYPE.OVERVIEW
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  ${(props) =>
    props.mobile &&
    css`
      padding: 0 16px 16px 16px;
    `}
`;
const MobileTabMain = styled.div`
  border-top: 1px solid var(--gray-border-color);
  margin: 16px 16px 0 16px;
  padding: 24px 0 16px 0;
  ${(props) =>
    props.hasExternalApp
      ? css`
          border-top: none;
          margin: 0px 16px;
          padding: 16px 0;
        `
      : css``}
`;
const MobileActivityTab = styled.div`
  max-width: 219px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 4px;
  border-radius: 8px;
  background-color: var(--white-smoke);
  border: 0.5px solid var(--gray-border-color);
  ${(props) =>
    props.hasExternalApp
      ? css`
          background-color: transparent;
          border: 0.5px solid transparent;
          padding: 0;
          max-width: 240px;
        `
      : css``}
`;
const OverviewButton = styled.div`
  padding: 4px 20px;
  cursor: pointer;
  ${Text1}
  ${(props) =>
    props.hasExternalApp
      ? css`
          padding: 4px 26px;
        `
      : css``}
  ${(props) =>
    props.screen === DETAIL_SCREEN_TYPE.OVERVIEW
      ? css`
          background-color: var(--white-color);
          border-radius: 6px;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
          border: 0.5px solid var(--gray-border-color);
          ${(props) =>
            props.hasExternalApp
              ? css`
                  background-color: var(--popup-color-hover);
                  border: 0.5px solid transparent;
                  border-radius: 20px;
                  box-shadow: none;
                `
              : css``}
        `
      : css`
          background-color: transparent;
          border: 0.5px solid transparent;
        `}
`;
const ActivityButton = styled.div`
  padding: 4px 20px;
  cursor: pointer;
  ${Text1}
  ${(props) =>
    props.screen === DETAIL_SCREEN_TYPE.ACTIVITY
      ? css`
          background-color: var(--white-color);
          border-radius: 6px;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
          border: 0.5px solid var(--gray-border-color);
          ${(props) =>
            props.hasExternalApp
              ? css`
                  background-color: var(--popup-color-hover);
                  border: 0.5px solid transparent;
                  border-radius: 20px;
                  box-shadow: none;
                `
              : css``}
        `
      : css`
          background-color: transparent;
          border: 0.5px solid transparent;
        `}
`;
const IconWrap = styled.div`
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  cursor: pointer;
  ${(props) =>
    props.bothIcon
      ? props.buttonType === 'Call'
        ? css`
            border-right: 0px;
            border-radius: 4px 0px 0px 4px;
          `
        : props.buttonType === 'Email' &&
          css`
            border-radius: 0px 4px 4px 0px;
          `
      : null}
`;
const IconActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;
const BoxContacts = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  display: block;
  width: 100%;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalStyle = {
  width: '640px'
};

export {
  WhatsappView,
  IconActions,
  IconWrap,
  ModalFormWrapper,
  Actions,
  ActionWrapper,
  TwoListDetail,
  LeftList,
  ProFileContain,
  ProfileLogo,
  UserView,
  UserName,
  UserLeave,
  BtnContain,
  PhoneIconWrapper,
  IconView,
  FirstConatin,
  FirstView,
  FirstList,
  BoxText,
  LastViewOne,
  SecondView,
  LastView,
  Lasttext,
  Lasttext2,
  SecondContain,
  TitleView,
  ALasttext,
  TagView,
  FirstTag,
  Tag,
  TagBox,
  TagText,
  TitleView2,
  TextDetail,
  UlList,
  Ul,
  Li,
  ShowLi,
  IconA,
  LSpan,
  RText,
  LSpan2,
  FormView,
  InputList,
  SpanTitle,
  RightIconView,
  CloseIconView,
  PopView,
  ViewList,
  ShowText,
  ShowTime,
  DateTextView,
  Border,
  InputBlock,
  InputSpan,
  InputEdit,
  InputWrapperModal,
  Selecter,
  useStyles,
  Item,
  ProfileName,
  BtnText,
  PopBtn,
  IconDelete,
  BoxFirst,
  ValueBox,
  NameUser,
  UserAmount,
  InputLabel,
  StyledMenuItem,
  AvtarWrapper,
  AvtarWrapperBox,
  IconWrapper,
  Customize,
  Status,
  InputWrapper,
  SocialContain,
  SocialBtn,
  ActionLi,
  ButtonWrapper,
  FirstContainWrap,
  MobileActivityTab,
  ActivityButton,
  OverviewButton,
  MobileTabMain,
  BoxContacts,
  NameUserWrapper,
  SvgWrapper,
  ModalStyle,
  ContactProfileBlock,
  RighLinkIcon,
  ProfileExternalApp,
  ExtraOrganize,
  ExtraOrganizeText,
  ExtraOrganizeInner,
  AddContactBlock,
  AddContactSpan,
  ProfileUpperBlock,
  ExternalEmpty,
  ExtraEmptyMain,
  EmptyHeading,
  EmptyPara,
  TagMenu,
  TagItems
};
