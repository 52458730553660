import React, { useCallback, useMemo, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/Icon/Icon';
import { updateColumnsSetting } from '../../../services/settingService';
import OrganizationPreferencesSingleton from '../../../helpers/OrganizationPreferencesSingleton';
import { setTableFields } from '../../../actions/lookupTableActions';
import { getTableFields } from '../../../services/lookupTableServices';
import {
  MainContact,
  TitleView,
  TitleText,
  IconView,
  Form,
  SecondContain,
  DropDiv,
  DragIcon,
  TextView,
  SpanText,
  DropDownWrapper,
  Switch,
  SaveButon,
  HeaderWrapper,
  SortableItemWrapper
} from './styles';

const ManageColumsModal = ({ props, handleCloseModal }) => {
  const dispatch = useDispatch();

  const fields = OrganizationPreferencesSingleton.getInstance().getFields();

  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const organizationSelector = useSelector((state) => state.organization);

  const lookupName = props?.match.params.name;

  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { list: lookupTableList, tableFields } = lookupTableSelector;

  const [tempLookupTableColumn, setLookupTableColumns] = useState(null);

  const currentTable = useMemo(() => {
    const currentTable = lookupTableList?.find((x) => x?.name === lookupName);
    return currentTable;
  }, [lookupTableList, lookupName]);

  const lookupTableColumns = useMemo(() => {
    const lookupTableFields = tableFields?.find((x) => x?.tableId === currentTable?.id);
    const columns = lookupTableFields?.columns?.filter((x) => x.customId && x.label);
    return columns;
  }, [tableFields, currentTable]);

  const onCloseManageColumns = useCallback(() => {
    if (handleCloseModal) handleCloseModal();
  }, [handleCloseModal]);

  const lookupColumn = useMemo(() => {
    return tempLookupTableColumn || lookupTableColumns;
  }, [tempLookupTableColumn, lookupTableColumns]);

  const handleCheckField = (value) => {
    const newTableColumns = lookupColumn.map((field) =>
      field.id === value?.id ? { ...field, isShow: !field.isShow } : field
    );
    setLookupTableColumns(newTableColumns);
  };

  const SortableItem = SortableElement(({ value }) => (
    <DropDiv>
      <DragIcon title='Drag'>
        <Icon name='icon-drag-and-drop' fontSize='14' color='var(--disable-color)' />
      </DragIcon>
      <DropDownWrapper>
        <TextView>
          <SpanText>{value?.label}</SpanText>
        </TextView>
        <Switch className='switch'>
          <input
            type='checkbox'
            value={value?.isShow}
            checked={value?.isShow}
            onChange={() => {
              handleCheckField(value);
            }}
          />
          <span className='slider round'></span>
        </Switch>
      </DropDownWrapper>
    </DropDiv>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <SortableItemWrapper>
        {items?.map((item, index) => (
          <SortableItem key={`field_${index}`} index={index} value={item} />
        ))}
      </SortableItemWrapper>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newTableColumns = [];
    newTableColumns = arrayMoveImmutable(lookupColumn, oldIndex, newIndex);
    setLookupTableColumns(newTableColumns);
  };

  const updatedFields = useMemo(() => {
    return fields?.map((x) => (x.tableId === currentTable?.id ? { ...x, columns: lookupColumn } : x));
  }, [fields, lookupColumn, currentTable]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (handleCloseModal) handleCloseModal();
      dispatch(setTableFields(updatedFields));
      OrganizationPreferencesSingleton.getInstance().setFields(updatedFields);
      await dispatch(updateColumnsSetting(organization_id, currentTable?.id, lookupColumn));
      dispatch(getTableFields(organization_id, true));
    },
    [handleCloseModal, organization_id, updatedFields, lookupColumn, currentTable, dispatch]
  );

  return (
    <MainContact>
      <TitleView>
        <HeaderWrapper>
          <IconView title='Back'>
            <Icon
              name='icon-big-left-arrow'
              fontSize='12'
              color='var(--gray-icon-color)'
              onClick={onCloseManageColumns}
            />
          </IconView>
          <TitleText>Manage Columns</TitleText>
        </HeaderWrapper>
        <SaveButon onClick={handleSubmit}>Save</SaveButon>
      </TitleView>
      <Form>
        <SecondContain>
          <SortableList items={lookupColumn} onSortEnd={onSortEnd} axis='xy' />
        </SecondContain>
      </Form>
    </MainContact>
  );
};

export default ManageColumsModal;
