import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLookupEnrichmentItem } from '../../../../actions/lookupActions';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import Button from '../../../../components/atom/Button/Button';
import Input from '../../../../components/Input/Input';
import { ENRICH_FEILDS, LookupFieldTypes } from '../../../../constants/constant';
import { getLookupValueFromField } from '../../../../helpers/common';
import OrganizationPreferencesSingleton from '../../../../helpers/OrganizationPreferencesSingleton';
import { getContactEnrichment, updateLookup } from '../../../../services/lookupService';
import {
  EnrichModalMain,
  ModalHead,
  ModalHeadLeft,
  ModalText,
  SvgWrapper,
  Form,
  SecondContain,
  BottomView,
  AllBtnView,
  BtnView,
  ItemRow,
  ItemBlock,
  ItemTextBlock,
  ItemDropdown
} from './styles';

export const EnrichModal = ({ onClose, lookup, groupFields, onSave }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const lookupSelector = useSelector((state) => state.lookup);
  const { enrichmentFields, loading } = lookupSelector;
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    const payload = {
      name: lookup?.name,
      email: lookup?.email,
      phone: lookup?.phone
    };
    await dispatch(getContactEnrichment(organization_id, payload, lookup?.tableId));
  }, [dispatch, lookup, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData, dispatch]);

  const onChangeField = useCallback(
    (field, value, index) => {
      let valueType = getLookupValueFromField(field);
      if (field) {
        field = {
          ...field,
          [valueType]: value
        };
      }
      dispatch(updateLookupEnrichmentItem({ index, propsName: 'field', value: field }));
    },
    [dispatch]
  );

  const fields = useMemo(() => {
    const fieldList = [].concat
      .apply(
        [],
        groupFields?.map((x) => x?.fields)
      )
      .filter((x) => x.type !== LookupFieldTypes.Lookup)
      .map((item) => {
        return { ...item, fieldOption: item?.options, options: undefined };
      });

    return fieldList;
  }, [groupFields]);

  const onSaveFileds = useCallback(() => {
    const newEnrichItems = [];
    enrichmentFields?.forEach((item) => {
      if (item?.field && item?.field?.id) {
        newEnrichItems.push({ enrichId: item?.name, scId: item?.field?.id });
      }
    });

    OrganizationPreferencesSingleton.getInstance().setSettingValue(lookup?.tableId, ENRICH_FEILDS, newEnrichItems);
  }, [enrichmentFields, lookup?.tableId]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let newGroupFields = fields;
      let length = 0;
      enrichmentFields?.forEach((item) => {
        if (item?.field) {
          length = length + 1;
          const index = newGroupFields?.findIndex((x) => x.id === item?.field?.id);
          if (index !== -1) {
            newGroupFields[index] = item?.field;
          }
        }
      });
      if (length > 0) {
        let payload = {
          groupFields: [
            {
              name: 'Lookup',
              fields: newGroupFields
            }
          ],
          companyId: organization_id,
          id: lookup?.id
        };
        let result = await dispatch(updateLookup(organization_id, lookup?.tableId, payload));
        if (result) {
          onSaveFileds();
          if (onClose) onClose();
          if (onSave) onSave();
        }
      }
    },
    [dispatch, enrichmentFields, fields, lookup?.id, lookup?.tableId, onClose, onSave, onSaveFileds, organization_id]
  );

  return (
    <>
      <EnrichModalMain>
        <ModalHead>
          <ModalHeadLeft>
            <SVGIcon name='icon-phone' fill='var(--gray-icon-color)' size={22} />
            <ModalText>Enrich</ModalText>
          </ModalHeadLeft>
          <SvgWrapper onClick={onClose}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
          </SvgWrapper>
        </ModalHead>
      </EnrichModalMain>
      <Form onSubmit={onSubmit}>
        <SecondContain>
          {enrichmentFields &&
            enrichmentFields?.map((item, index) => {
              return (
                <ItemRow key={`enrichmentFields_${item?.id}_${index}`}>
                  <ItemTextBlock>{item?.name}</ItemTextBlock>
                  <ItemBlock>
                    <Input isPrimarySmall={true} autoFocus={true} disabled={true} value={item?.value} />
                  </ItemBlock>
                  <ItemDropdown
                    isClearable={true}
                    options={fields}
                    labelField={'label'}
                    valueField={'id'}
                    value={item?.field?.id}
                    onChange={(field) => {
                      onChangeField(field, item?.value, index);
                    }}
                    placeholder={'Select Field'}></ItemDropdown>
                </ItemRow>
              );
            })}
          {/* <ItemRow>
            <ItemTextBlock>Join Date</ItemTextBlock>
            <ItemDate>
              <DatePicker />
            </ItemDate>
            <ItemDropdown></ItemDropdown>
          </ItemRow>
          <ItemRow>
            <ItemTextBlock>Join Time</ItemTextBlock>
            <ItemDate>
              <TimePicker />
            </ItemDate>
            <ItemDropdown></ItemDropdown>
          </ItemRow>
          <ItemRow>
            <ItemTextBlock>Street Address</ItemTextBlock>
            <ItemBlock>
              <Input isPrimarySmall={true} autoFocus={true} placeholder='Garden Velly' />
            </ItemBlock>
            <ItemDropdown></ItemDropdown>
          </ItemRow>
          <ItemRow>
            <ItemTextBlock>Postal Code</ItemTextBlock>
            <ItemBlock>
              <Input isPrimarySmall={true} autoFocus={true} placeholder='395004' />
            </ItemBlock>
            <ItemDropdown></ItemDropdown>
          </ItemRow>
          <ItemRow>
            <ItemTextBlock>LinkedIn</ItemTextBlock>
            <ItemBlock>
              <Input isPrimarySmall={true} autoFocus={true} placeholder='https://jp.linkedin.co' />
            </ItemBlock>
            <ItemDropdown></ItemDropdown>
          </ItemRow>
          <ItemRow>
            <ItemTextBlock>Twitter</ItemTextBlock>
            <ItemBlock>
              <Input isPrimarySmall={true} autoFocus={true} placeholder='https://twitter.com/s' />
            </ItemBlock>
            <ItemDropdown></ItemDropdown>
          </ItemRow>
          <ItemRow>
            <ItemTextBlock>Facebook</ItemTextBlock>
            <ItemBlock>
              <Input isPrimarySmall={true} autoFocus={true} placeholder='www.facebook.com/s' />
            </ItemBlock>
            <ItemDropdown></ItemDropdown>
          </ItemRow> */}
        </SecondContain>
        <BottomView>
          <AllBtnView>
            <BtnView>
              <Button title='save' type='submit' disabled={loading} loading={loading} />
              <Button onClick={onClose} secondary title='Close' type='button' />
            </BtnView>
          </AllBtnView>
        </BottomView>
      </Form>
    </>
  );
};
