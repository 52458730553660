import { TASK_LIST_TYPE } from '../constants/constant';

export const importDataOption = [
  { label: 'what do you want to import', value: 'what do you want to import' },
  { label: 'what do you want to import1', value: 'what do you want to import1' },
  { label: 'what do you want to import2', value: 'what do you want to import2' },
  { label: 'what do you want to import3', value: 'what do you want to import3' }
];

export const itemPerPage = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
];

export const currencySymbols = [
  { code: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  { code: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  { code: 'ALL', symbol: 'L', name: 'Albanian lek' },
  { code: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  { code: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
  { code: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  { code: 'ARS', symbol: '$', name: 'Argentine peso' },
  { code: 'AUD', symbol: '$', name: 'Australian dollar' },
  { code: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  { code: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  { code: 'BAM', symbol: 'KM', name: 'Bosnia and Herzegovina konvertibilna marka' },
  { code: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  { code: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  { code: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  { code: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  { code: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  { code: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  { code: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  { code: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  { code: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  { code: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  { code: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { code: 'BWP', symbol: 'P', name: 'Botswana pula' },
  { code: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  { code: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  { code: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { code: 'CDF', symbol: 'F', name: 'Congolese franc' },
  { code: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  { code: 'CLP', symbol: '$', name: 'Chilean peso' },
  { code: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  { code: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  { code: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  { code: 'CUC', symbol: '$', name: 'Cuban peso' },
  { code: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  { code: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  { code: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  { code: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  { code: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  { code: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  { code: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  { code: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  { code: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  { code: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  { code: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { code: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  { code: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  { code: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { code: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  { code: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { code: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  { code: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  { code: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  { code: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  { code: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  { code: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  { code: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  { code: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  { code: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  { code: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  { code: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  { code: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  { code: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  { code: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  { code: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { code: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  { code: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { code: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  { code: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  { code: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  { code: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  { code: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
  { code: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  { code: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  { code: 'KPW', symbol: 'W', name: 'North Korean won' },
  { code: 'KRW', symbol: 'W', name: 'South Korean won' },
  { code: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  { code: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  { code: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  { code: 'LAK', symbol: 'KN', name: 'Lao kip' },
  { code: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  { code: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  { code: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  { code: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  { code: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  { code: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  { code: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  { code: 'MAD', symbol: 'MAD', name: 'Morocodean dirham' },
  { code: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  { code: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  { code: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  { code: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  { code: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  { code: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  { code: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  { code: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  { code: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { code: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  { code: 'MXN', symbol: '$', name: 'Mexican peso' },
  { code: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  { code: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  { code: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  { code: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  { code: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { code: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  { code: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  { code: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  { code: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  { code: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  { code: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { code: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  { code: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  { code: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  { code: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  { code: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  { code: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  { code: 'RON', symbol: 'L', name: 'Romanian leu' },
  { code: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  { code: 'RUB', symbol: 'R', name: 'Russian ruble' },
  { code: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  { code: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  { code: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  { code: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  { code: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  { code: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  { code: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  { code: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  { code: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  { code: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  { code: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  { code: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  { code: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  { code: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  { code: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  { code: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  { code: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  { code: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  { code: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  { code: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  { code: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { code: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  { code: 'USD', symbol: 'US$', name: 'United States dollar' },
  { code: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  { code: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  { code: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  { code: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  { code: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  { code: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  { code: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  { code: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  { code: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  { code: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  { code: 'XPF', symbol: 'F', name: 'CFP franc' },
  { code: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  { code: 'ZAR', symbol: 'R', name: 'South African rand' },
  { code: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  { code: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' }
];

export const callLog = [
  { label: 'Incoming', value: 'Incoming Call' },
  { label: 'Outgoing', value: 'Outgoing Call' }
];

export const CountryCodes = [
  { country: 'India', code: '+91', countryCode: 'IN' },
  { country: 'United States', code: '+1', countryCode: 'US' },
  { country: 'Afghanistan', code: '+93', countryCode: 'AF' },
  { country: 'Aland Islands', code: '+358', countryCode: 'AX' },
  { country: 'Albania', code: '+355', countryCode: 'AL' },
  { country: 'Algeria', code: '+213', countryCode: 'DZ' },
  { country: 'American Samoa', code: '+1684', countryCode: 'AS' },
  { country: 'Andorra', code: '+376', countryCode: 'AD' },
  { country: 'Angola', code: '+244', countryCode: 'AO' },
  { country: 'Anguilla', code: '+1264', countryCode: 'AI' },
  { country: 'Antarctica', code: '+672', countryCode: 'AQ' },
  { country: 'Antigua and Barbuda', code: '+1268', countryCode: 'AG' },
  { country: 'Argentina', code: '+54', countryCode: 'AR' },
  { country: 'Armenia', code: '+374', countryCode: 'AM' },
  { country: 'Aruba', code: '+297', countryCode: 'AW' },
  { country: 'Australia', code: '+61', countryCode: 'AU' },
  { country: 'Austria', code: '+43', countryCode: 'AT' },
  { country: 'Azerbaijan', code: '+994', countryCode: 'AZ' },
  { country: 'Bahamas', code: '+1242', countryCode: 'BS' },
  { country: 'Bahrain', code: '+973', countryCode: 'BH' },
  { country: 'Bangladesh', code: '+880', countryCode: 'BD' },
  { country: 'Barbados', code: '+1246', countryCode: 'BB' },
  { country: 'Belarus', code: '+375', countryCode: 'BY' },
  { country: 'Belgium', code: '+32', countryCode: 'BE' },
  { country: 'Belize', code: '+501', countryCode: 'BZ' },
  { country: 'Benin', code: '+229', countryCode: 'BJ' },
  { country: 'Bermuda', code: '+1441', countryCode: 'BM' },
  { country: 'Bhutan', code: '+975', countryCode: 'BT' },
  { country: 'Bolivia, Plurinational State of bolivia', code: '+591', countryCode: 'BO' },
  { country: 'Bosnia and Herzegovina', code: '+387', countryCode: 'BA' },
  { country: 'Botswana', code: '+267', countryCode: 'BW' },
  { country: 'Bouvet Island', code: '+47', countryCode: 'BV' },
  { country: 'Brazil', code: '+55', countryCode: 'BR' },
  { country: 'British Indian Ocean Territory', code: '+246', countryCode: 'IO' },
  { country: 'Brunei Darussalam', code: '+673', countryCode: 'BN' },
  { country: 'Bulgaria', code: '+359', countryCode: 'BG' },
  { country: 'Burkina Faso', code: '+226', countryCode: 'BF' },
  { country: 'Burundi', code: '+257', countryCode: 'BI' },
  { country: 'Cambodia', code: '+855', countryCode: 'KH' },
  { country: 'Cameroon', code: '+237', countryCode: 'CM' },
  { country: 'Canada', code: '+1', countryCode: 'CA' },
  { country: 'Cape Verde', code: '+238', countryCode: 'CV' },
  { country: 'Cayman Islands', code: '+ 345', countryCode: 'KY' },
  { country: 'Central African Republic', code: '+236', countryCode: 'CF' },
  { country: 'Chad', code: '+235', countryCode: 'TD' },
  { country: 'Chile', code: '+56', countryCode: 'CL' },
  { country: 'China', code: '+86', countryCode: 'CN' },
  { country: 'Christmas Island', code: '+61', countryCode: 'CX' },
  { country: 'Cocos (Keeling) Islands', code: '+61', countryCode: 'CC' },
  { country: 'Colombia', code: '+57', countryCode: 'CO' },
  { country: 'Comoros', code: '+269', countryCode: 'KM' },
  { country: 'Congo', code: '+242', countryCode: 'CG' },
  { country: 'Congo, The Democratic Republic of the Congo', code: '+243', countryCode: 'CD' },
  { country: 'Cook Islands', code: '+682', countryCode: 'CK' },
  { country: 'Costa Rica', code: '+506', countryCode: 'CR' },
  { country: "Cote d'Ivoire", code: '+225', countryCode: 'CI' },
  { country: 'Croatia', code: '+385', countryCode: 'HR' },
  { country: 'Cuba', code: '+53', countryCode: 'CU' },
  { country: 'Cyprus', code: '+357', countryCode: 'CY' },
  { country: 'Czech Republic', code: '+420', countryCode: 'CZ' },
  { country: 'Denmark', code: '+45', countryCode: 'DK' },
  { country: 'Djibouti', code: '+253', countryCode: 'DJ' },
  { country: 'Dominica', code: '+1767', countryCode: 'DM' },
  { country: 'Dominican Republic', code: '+1849', countryCode: 'DO' },
  { country: 'Ecuador', code: '+593', countryCode: 'EC' },
  { country: 'Egypt', code: '+20', countryCode: 'EG' },
  { country: 'El Salvador', code: '+503', countryCode: 'SV' },
  { country: 'Equatorial Guinea', code: '+240', countryCode: 'GQ' },
  { country: 'Eritrea', code: '+291', countryCode: 'ER' },
  { country: 'Estonia', code: '+372', countryCode: 'EE' },
  { country: 'Ethiopia', code: '+251', countryCode: 'ET' },
  { country: 'Falkland Islands (Malvinas)', code: '+500', countryCode: 'FK' },
  { country: 'Faroe Islands', code: '+298', countryCode: 'FO' },
  { country: 'Fiji', code: '+679', countryCode: 'FJ' },
  { country: 'Finland', code: '+358', countryCode: 'FI' },
  { country: 'France', code: '+33', countryCode: 'FR' },
  { country: 'French Guiana', code: '+594', countryCode: 'GF' },
  { country: 'French Polynesia', code: '+689', countryCode: 'PF' },
  { country: 'French Southern Territories', code: '+262', countryCode: 'TF' },
  { country: 'Gabon', code: '+241', countryCode: 'GA' },
  { country: 'Gambia', code: '+220', countryCode: 'GM' },
  { country: 'Georgia', code: '+995', countryCode: 'GE' },
  { country: 'Germany', code: '+49', countryCode: 'DE' },
  { country: 'Ghana', code: '+233', countryCode: 'GH' },
  { country: 'Gibraltar', code: '+350', countryCode: 'GI' },
  { country: 'Greece', code: '+30', countryCode: 'GR' },
  { country: 'Greenland', code: '+299', countryCode: 'GL' },
  { country: 'Grenada', code: '+1473', countryCode: 'GD' },
  { country: 'Guadeloupe', code: '+590', countryCode: 'GP' },
  { country: 'Guam', code: '+1671', countryCode: 'GU' },
  { country: 'Guatemala', code: '+502', countryCode: 'GT' },
  { country: 'Guernsey', code: '+44', countryCode: 'GG' },
  { country: 'Guinea', code: '+224', countryCode: 'GN' },
  { country: 'Guinea-Bissau', code: '+245', countryCode: 'GW' },
  { country: 'Guyana', code: '+592', countryCode: 'GY' },
  { country: 'Haiti', code: '+509', countryCode: 'HT' },
  { country: 'Heard Island and Mcdonald Islands', code: '+0', countryCode: 'HM' },
  { country: 'Holy See (Vatican City State)', code: '+379', countryCode: 'VA' },
  { country: 'Honduras', code: '+504', countryCode: 'HN' },
  { country: 'Hong Kong', code: '+852', countryCode: 'HK' },
  { country: 'Hungary', code: '+36', countryCode: 'HU' },
  { country: 'Iceland', code: '+354', countryCode: 'IS' },
  { country: 'Indonesia', code: '+62', countryCode: 'ID' },
  { country: 'Iran, Islamic Republic of Persian Gulf', code: '+98', countryCode: 'IR' },
  { country: 'Iraq', code: '+964', countryCode: 'IQ' },
  { country: 'Ireland', code: '+353', countryCode: 'IE' },
  { country: 'Isle of Man', code: '+44', countryCode: 'IM' },
  { country: 'Israel', code: '+972', countryCode: 'IL' },
  { country: 'Italy', code: '+39', countryCode: 'IT' },
  { country: 'Jamaica', code: '+1876', countryCode: 'JM' },
  { country: 'Japan', code: '+81', countryCode: 'JP' },
  { country: 'Jersey', code: '+44', countryCode: 'JE' },
  { country: 'Jordan', code: '+962', countryCode: 'JO' },
  { country: 'Kazakhstan', code: '+7', countryCode: 'KZ' },
  { country: 'Kenya', code: '+254', countryCode: 'KE' },
  { country: 'Kiribati', code: '+686', countryCode: 'KI' },
  { country: "Korea, Democratic People's Republic of Korea", code: '+850', countryCode: 'KP' },
  { country: 'Korea, Republic of South Korea', code: '+82', countryCode: 'KR' },
  { country: 'Kosovo', code: '+383', countryCode: 'XK' },
  { country: 'Kuwait', code: '+965', countryCode: 'KW' },
  { country: 'Kyrgyzstan', code: '+996', countryCode: 'KG' },
  { country: 'Laos', code: '+856', countryCode: 'LA' },
  { country: 'Latvia', code: '+371', countryCode: 'LV' },
  { country: 'Lebanon', code: '+961', countryCode: 'LB' },
  { country: 'Lesotho', code: '+266', countryCode: 'LS' },
  { country: 'Liberia', code: '+231', countryCode: 'LR' },
  { country: 'Libyan Arab Jamahiriya', code: '+218', countryCode: 'LY' },
  { country: 'Liechtenstein', code: '+423', countryCode: 'LI' },
  { country: 'Lithuania', code: '+370', countryCode: 'LT' },
  { country: 'Luxembourg', code: '+352', countryCode: 'LU' },
  { country: 'Macao', code: '+853', countryCode: 'MO' },
  { country: 'Macedonia', code: '+389', countryCode: 'MK' },
  { country: 'Madagascar', code: '+261', countryCode: 'MG' },
  { country: 'Malawi', code: '+265', countryCode: 'MW' },
  { country: 'Malaysia', code: '+60', countryCode: 'MY' },
  { country: 'Maldives', code: '+960', countryCode: 'MV' },
  { country: 'Mali', code: '+223', countryCode: 'ML' },
  { country: 'Malta', code: '+356', countryCode: 'MT' },
  { country: 'Marshall Islands', code: '+692', countryCode: 'MH' },
  { country: 'Martinique', code: '+596', countryCode: 'MQ' },
  { country: 'Mauritania', code: '+222', countryCode: 'MR' },
  { country: 'Mauritius', code: '+230', countryCode: 'MU' },
  { country: 'Mayotte', code: '+262', countryCode: 'YT' },
  { country: 'Mexico', code: '+52', countryCode: 'MX' },
  { country: 'Micronesia, Federated States of Micronesia', code: '+691', countryCode: 'FM' },
  { country: 'Moldova', code: '+373', countryCode: 'MD' },
  { country: 'Monaco', code: '+377', countryCode: 'MC' },
  { country: 'Mongolia', code: '+976', countryCode: 'MN' },
  { country: 'Montenegro', code: '+382', countryCode: 'ME' },
  { country: 'Montserrat', code: '+1664', countryCode: 'MS' },
  { country: 'Morocco', code: '+212', countryCode: 'MA' },
  { country: 'Mozambique', code: '+258', countryCode: 'MZ' },
  { country: 'Myanmar', code: '+95', countryCode: 'MM' },
  { country: 'Namibia', code: '+264', countryCode: 'NA' },
  { country: 'Nauru', code: '+674', countryCode: 'NR' },
  { country: 'Nepal', code: '+977', countryCode: 'NP' },
  { country: 'Netherlands', code: '+31', countryCode: 'NL' },
  { country: 'Netherlands Antilles', code: '+599', countryCode: 'AN' },
  { country: 'New Caledonia', code: '+687', countryCode: 'NC' },
  { country: 'New Zealand', code: '+64', countryCode: 'NZ' },
  { country: 'Nicaragua', code: '+505', countryCode: 'NI' },
  { country: 'Niger', code: '+227', countryCode: 'NE' },
  { country: 'Nigeria', code: '+234', countryCode: 'NG' },
  { country: 'Niue', code: '+683', countryCode: 'NU' },
  { country: 'Norfolk Island', code: '+672', countryCode: 'NF' },
  { country: 'Northern Mariana Islands', code: '+1670', countryCode: 'MP' },
  { country: 'Norway', code: '+47', countryCode: 'NO' },
  { country: 'Oman', code: '+968', countryCode: 'OM' },
  { country: 'Pakistan', code: '+92', countryCode: 'PK' },
  { country: 'Palau', code: '+680', countryCode: 'PW' },
  { country: 'Palestinian Territory, Occupied', code: '+970', countryCode: 'PS' },
  { country: 'Panama', code: '+507', countryCode: 'PA' },
  { country: 'Papua New Guinea', code: '+675', countryCode: 'PG' },
  { country: 'Paraguay', code: '+595', countryCode: 'PY' },
  { country: 'Peru', code: '+51', countryCode: 'PE' },
  { country: 'Philippines', code: '+63', countryCode: 'PH' },
  { country: 'Pitcairn', code: '+64', countryCode: 'PN' },
  { country: 'Poland', code: '+48', countryCode: 'PL' },
  { country: 'Portugal', code: '+351', countryCode: 'PT' },
  { country: 'Puerto Rico', code: '+1939', countryCode: 'PR' },
  { country: 'Qatar', code: '+974', countryCode: 'QA' },
  { country: 'Romania', code: '+40', countryCode: 'RO' },
  { country: 'Russia', code: '+7', countryCode: 'RU' },
  { country: 'Rwanda', code: '+250', countryCode: 'RW' },
  { country: 'Reunion', code: '+262', countryCode: 'RE' },
  { country: 'Saint Barthelemy', code: '+590', countryCode: 'BL' },
  { country: 'Saint Helena, Ascension and Tristan Da Cunha', code: '+290', countryCode: 'SH' },
  { country: 'Saint Kitts and Nevis', code: '+1869', countryCode: 'KN' },
  { country: 'Saint Lucia', code: '+1758', countryCode: 'LC' },
  { country: 'Saint Martin', code: '+590', countryCode: 'MF' },
  { country: 'Saint Pierre and Miquelon', code: '+508', countryCode: 'PM' },
  { country: 'Saint Vincent and the Grenadines', code: '+1784', countryCode: 'VC' },
  { country: 'Samoa', code: '+685', countryCode: 'WS' },
  { country: 'San Marino', code: '+378', countryCode: 'SM' },
  { country: 'Sao Tome and Principe', code: '+239', countryCode: 'ST' },
  { country: 'Saudi Arabia', code: '+966', countryCode: 'SA' },
  { country: 'Senegal', code: '+221', countryCode: 'SN' },
  { country: 'Serbia', code: '+381', countryCode: 'RS' },
  { country: 'Seychelles', code: '+248', countryCode: 'SC' },
  { country: 'Sierra Leone', code: '+232', countryCode: 'SL' },
  { country: 'Singapore', code: '+65', countryCode: 'SG' },
  { country: 'Slovakia', code: '+421', countryCode: 'SK' },
  { country: 'Slovenia', code: '+386', countryCode: 'SI' },
  { country: 'Solomon Islands', code: '+677', countryCode: 'SB' },
  { country: 'Somalia', code: '+252', countryCode: 'SO' },
  { country: 'South Africa', code: '+27', countryCode: 'ZA' },
  { country: 'South Sudan', code: '+211', countryCode: 'SS' },
  { country: 'South Georgia and the South Sandwich Islands', code: '+500', countryCode: 'GS' },
  { country: 'Spain', code: '+34', countryCode: 'ES' },
  { country: 'Sri Lanka', code: '+94', countryCode: 'LK' },
  { country: 'Sudan', code: '+249', countryCode: 'SD' },
  { country: 'Suriname', code: '+597', countryCode: 'SR' },
  { country: 'Svalbard and Jan Mayen', code: '+47', countryCode: 'SJ' },
  { country: 'Swaziland', code: '+268', countryCode: 'SZ' },
  { country: 'Sweden', code: '+46', countryCode: 'SE' },
  { country: 'Switzerland', code: '+41', countryCode: 'CH' },
  { country: 'Syrian Arab Republic', code: '+963', countryCode: 'SY' },
  { country: 'Taiwan', code: '+886', countryCode: 'TW' },
  { country: 'Tajikistan', code: '+992', countryCode: 'TJ' },
  { country: 'Tanzania, United Republic of Tanzania', code: '+255', countryCode: 'TZ' },
  { country: 'Thailand', code: '+66', countryCode: 'TH' },
  { country: 'Timor-Leste', code: '+670', countryCode: 'TL' },
  { country: 'Togo', code: '+228', countryCode: 'TG' },
  { country: 'Tokelau', code: '+690', countryCode: 'TK' },
  { country: 'Tonga', code: '+676', countryCode: 'TO' },
  { country: 'Trinidad and Tobago', code: '+1868', countryCode: 'TT' },
  { country: 'Tunisia', code: '+216', countryCode: 'TN' },
  { country: 'Turkey', code: '+90', countryCode: 'TR' },
  { country: 'Turkmenistan', code: '+993', countryCode: 'TM' },
  { country: 'Turks and Caicos Islands', code: '+1649', countryCode: 'TC' },
  { country: 'Tuvalu', code: '+688', countryCode: 'TV' },
  { country: 'Uganda', code: '+256', countryCode: 'UG' },
  { country: 'Ukraine', code: '+380', countryCode: 'UA' },
  { country: 'United Arab Emirates', code: '+971', countryCode: 'AE' },
  { country: 'United Kingdom', code: '+44', countryCode: 'GB' },
  { country: 'Uruguay', code: '+598', countryCode: 'UY' },
  { country: 'Uzbekistan', code: '+998', countryCode: 'UZ' },
  { country: 'Vanuatu', code: '+678', countryCode: 'VU' },
  { country: 'Venezuela, Bolivarian Republic of Venezuela', code: '+58', countryCode: 'VE' },
  { country: 'Vietnam', code: '+84', countryCode: 'VN' },
  { country: 'Virgin Islands, British', code: '+1284', countryCode: 'VG' },
  { country: 'Virgin Islands, U.S.', code: '+1340', countryCode: 'VI' },
  { country: 'Wallis and Futuna', code: '+681', countryCode: 'WF' },
  { country: 'Yemen', code: '+967', countryCode: 'YE' },
  { country: 'Zambia', code: '+260', countryCode: 'ZM' },
  { country: 'Zimbabwe', code: '+263', countryCode: 'ZW' }
];

export const currencyNameWithCode = currencySymbols.map((item) => {
  return { ...item, text: item.name + ' - ' + item.code };
});

export const countryWithCodes = CountryCodes.map((item) => {
  return { ...item, text: item.country + ' (' + item.code + ')' };
});

export const TaskFilterOption = [
  { id: TASK_LIST_TYPE.ALL, name: 'All' },
  { id: TASK_LIST_TYPE.COMPLETED, name: 'Completed' },
  { id: TASK_LIST_TYPE.UNCOMPLETED, name: 'Open' }
];

export const teamSize = [
  { name: '1', value: 1 },
  { name: '2-5', value: 2 },
  { name: '6-10', value: 3 },
  { name: '11-50', value: 4 },
  { name: '50+', value: 5 }
];
export const industry = [
  { name: 'Software, App Development', value: 'Software, App Development' },
  { name: 'Health', value: 'Health' },
  { name: 'Tech Startup', value: 'Tech Startup' },
  { name: 'Education and Training', value: 'Education and Training' },
  { name: 'Real Estate', value: 'Real Estate' },
  { name: 'Creative Agency (Web, Advertising, Video)', value: 'Creative Agency (Web, Advertising, Video)' },
  { name: 'Financial or Credit Services', value: 'Financial or Credit Services' },
  { name: 'News, Media and Publications', value: 'News, Media and Publications' },
  { name: 'Manufacturing', value: 'Manufacturing' },
  { name: 'IT Services', value: 'IT Services' },
  { name: 'Consulting', value: 'Consulting' },
  { name: 'Construction', value: 'Construction' },
  { name: 'Trade (Retail, Wholesale)', value: 'Trade (Retail, Wholesale)' },
  { name: 'Ecommerce', value: 'Ecommerce' },
  { name: 'Other', value: 'Other' }
];

export const currencyList = [
  { value: 'INR', name: 'Indian Rupee - INR' },
  { value: 'USD', name: 'United States Dollar - USD' },
  { value: 'EUR', name: 'Euro - EUR' },
  { value: 'GBP', name: 'British Pound - GBP' },
  { value: 'AUD', name: 'Australian Dollar - AUD' },
  { value: 'CAD', name: 'Canadian Dollar - CAD' },
  { value: 'JPY', name: 'Japanese Yen - JPY' },
  { value: 'BYR', name: 'Belarusian Ruble - BYR' },
  { value: 'AED', name: 'United Arab Emirates Dirham - AED' },
  { value: 'AFN', name: 'Afghan Afghani - AFN' },
  { value: 'ALL', name: 'Albanian Lek - ALL' },
  { value: 'AMD', name: 'Armenian Dram - AMD' },
  { value: 'ANG', name: 'Netherlands Antillean Gulden - ANG' },
  { value: 'AOA', name: 'Angolan Kwanza - AOA' },
  { value: 'ARS', name: 'Argentine Peso - ARS' },
  { value: 'AWG', name: 'Aruban Florin - AWG' },
  { value: 'AZN', name: 'Azerbaijani Manat - AZN' },
  { value: 'BAM', name: 'Bosnia and Herzegovina Convertible Mark - BAM' },
  { value: 'BBD', name: 'Barbadian Dollar - BBD' },
  { value: 'BDT', name: 'Bangladeshi Taka - BDT' },
  { value: 'BGN', name: 'Bulgarian Lev - BGN' },
  { value: 'BHD', name: 'Bahraini Dinar - BHD' },
  { value: 'BIF', name: 'Burundian Franc - BIF' },
  { value: 'BMD', name: 'Bermudian Dollar - BMD' },
  { value: 'BND', name: 'Brunei Dollar - BND' },
  { value: 'BOB', name: 'Bolivian Boliviano - BOB' },
  { value: 'BRL', name: 'Brazilian Real - BRL' },
  { value: 'BSD', name: 'Bahamian Dollar - BSD' },
  { value: 'BTC', name: 'Bitcoin - BTC' },
  { value: 'BTN', name: 'Bhutanese Ngultrum - BTN' },
  { value: 'BWP', name: 'Botswana Pula - BWP' },
  { value: 'BYN', name: 'Belarusian Ruble - BYN' },
  { value: 'BZD', name: 'Belize Dollar - BZD' },
  { value: 'CDF', name: 'Congolese Franc - CDF' },
  { value: 'CHF', name: 'Swiss Franc - CHF' },
  { value: 'CLF', name: 'Unidad de Fomento - CLF' },
  { value: 'CLP', name: 'Chilean Peso - CLP' },
  { value: 'CNY', name: 'Chinese Renminbi Yuan - CNY' },
  { value: 'COP', name: 'Colombian Peso - COP' },
  { value: 'CRC', name: 'Costa Rican Colón - CRC' },
  { value: 'CUC', name: 'Cuban Convertible Peso - CUC' },
  { value: 'CUP', name: 'Cuban Peso - CUP' },
  { value: 'CVE', name: 'Cape Verdean Escudo - CVE' },
  { value: 'CZK', name: 'Czech Koruna - CZK' },
  { value: 'DJF', name: 'Djiboutian Franc - DJF' },
  { value: 'DKK', name: 'Danish Krone - DKK' },
  { value: 'DOP', name: 'Dominican Peso - DOP' },
  { value: 'DZD', name: 'Algerian Dinar - DZD' },
  { value: 'EEK', name: 'Estonian Kroon - EEK' },
  { value: 'EGP', name: 'Egyptian Pound - EGP' },
  { value: 'ERN', name: 'Eritrean Nakfa - ERN' },
  { value: 'ETB', name: 'Ethiopian Birr - ETB' },
  { value: 'FJD', name: 'Fijian Dollar - FJD' },
  { value: 'FKP', name: 'Falkland Pound - FKP' },
  { value: 'GBX', name: 'British Penny - GBX' },
  { value: 'GEL', name: 'Georgian Lari - GEL' },
  { value: 'GGP', name: 'Guernsey Pound - GGP' },
  { value: 'GHS', name: 'Ghanaian Cedi - GHS' },
  { value: 'GIP', name: 'Gibraltar Pound - GIP' },
  { value: 'GMD', name: 'Gambian Dalasi - GMD' },
  { value: 'GNF', name: 'Guinean Franc - GNF' },
  { value: 'GTQ', name: 'Guatemalan Quetzal - GTQ' },
  { value: 'GYD', name: 'Guyanese Dollar - GYD' },
  { value: 'HKD', name: 'Hong Kong Dollar - HKD' },
  { value: 'HNL', name: 'Honduran Lempira - HNL' },
  { value: 'HRK', name: 'Croatian Kuna - HRK' },
  { value: 'HTG', name: 'Haitian Gourde - HTG' },
  { value: 'HUF', name: 'Hungarian Forint - HUF' },
  { value: 'IDR', name: 'Indonesian Rupiah - IDR' },
  { value: 'ILS', name: 'Israeli New Sheqel - ILS' },
  { value: 'IMP', name: 'Isle of Man Pound - IMP' },
  { value: 'IQD', name: 'Iraqi Dinar - IQD' },
  { value: 'IRR', name: 'Iranian Rial - IRR' },
  { value: 'ISK', name: 'Icelandic Króna - ISK' },
  { value: 'JEP', name: 'Jersey Pound - JEP' },
  { value: 'JMD', name: 'Jamaican Dollar - JMD' },
  { value: 'JOD', name: 'Jordanian Dinar - JOD' },
  { value: 'KES', name: 'Kenyan Shilling - KES' },
  { value: 'KGS', name: 'Kyrgyzstani Som - KGS' },
  { value: 'KHR', name: 'Cambodian Riel - KHR' },
  { value: 'KMF', name: 'Comorian Franc - KMF' },
  { value: 'KPW', name: 'North Korean Won - KPW' },
  { value: 'KRW', name: 'South Korean Won - KRW' },
  { value: 'KWD', name: 'Kuwaiti Dinar - KWD' },
  { value: 'KYD', name: 'Cayman Islands Dollar - KYD' },
  { value: 'KZT', name: 'Kazakhstani Tenge - KZT' },
  { value: 'LAK', name: 'Lao Kip - LAK' },
  { value: 'LBP', name: 'Lebanese Pound - LBP' },
  { value: 'LKR', name: 'Sri Lankan Rupee - LKR' },
  { value: 'LRD', name: 'Liberian Dollar - LRD' },
  { value: 'LSL', name: 'Lesotho Loti - LSL' },
  { value: 'LTL', name: 'Lithuanian Litas - LTL' },
  { value: 'LVL', name: 'Latvian Lats - LVL' },
  { value: 'LYD', name: 'Libyan Dinar - LYD' },
  { value: 'MAD', name: 'Moroccan Dirham - MAD' },
  { value: 'MDL', name: 'Moldovan Leu - MDL' },
  { value: 'MGA', name: 'Malagasy Ariary - MGA' },
  { value: 'MKD', name: 'Macedonian Denar - MKD' },
  { value: 'MMK', name: 'Myanmar Kyat - MMK' },
  { value: 'MNT', name: 'Mongolian Tögrög - MNT' },
  { value: 'MOP', name: 'Macanese Pataca - MOP' },
  { value: 'MRO', name: 'Mauritanian Ouguiya - MRO' },
  { value: 'MTL', name: 'Maltese Lira - MTL' },
  { value: 'MUR', name: 'Mauritian Rupee - MUR' },
  { value: 'MVR', name: 'Maldivian Rufiyaa - MVR' },
  { value: 'MWK', name: 'Malawian Kwacha - MWK' },
  { value: 'MXN', name: 'Mexican Peso - MXN' },
  { value: 'MYR', name: 'Malaysian Ringgit - MYR' },
  { value: 'MZN', name: 'Mozambican Metical - MZN' },
  { value: 'NAD', name: 'Namibian Dollar - NAD' },
  { value: 'NGN', name: 'Nigerian Naira - NGN' },
  { value: 'NIO', name: 'Nicaraguan Córdoba - NIO' },
  { value: 'NOK', name: 'Norwegian Krone - NOK' },
  { value: 'NPR', name: 'Nepalese Rupee - NPR' },
  { value: 'NZD', name: 'New Zealand Dollar - NZD' },
  { value: 'OMR', name: 'Omani Rial - OMR' },
  { value: 'PAB', name: 'Panamanian Balboa - PAB' },
  { value: 'PEN', name: 'Peruvian Sol - PEN' },
  { value: 'PGK', name: 'Papua New Guinean Kina - PGK' },
  { value: 'PHP', name: 'Philippine Peso - PHP' },
  { value: 'PKR', name: 'Pakistani Rupee - PKR' },
  { value: 'PLN', name: 'Polish Złoty - PLN' },
  { value: 'PYG', name: 'Paraguayan Guaraní - PYG' },
  { value: 'QAR', name: 'Qatari Riyal - QAR' },
  { value: 'RON', name: 'Romanian Leu - RON' },
  { value: 'RSD', name: 'Serbian Dinar - RSD' },
  { value: 'RUB', name: 'Russian Ruble - RUB' },
  { value: 'RWF', name: 'Rwandan Franc - RWF' },
  { value: 'SAR', name: 'Saudi Riyal - SAR' },
  { value: 'SBD', name: 'Solomon Islands Dollar - SBD' },
  { value: 'SCR', name: 'Seychellois Rupee - SCR' },
  { value: 'SDG', name: 'Sudanese Pound - SDG' },
  { value: 'SEK', name: 'Swedish Krona - SEK' },
  { value: 'SGD', name: 'Singapore Dollar - SGD' },
  { value: 'SHP', name: 'Saint Helenian Pound - SHP' },
  { value: 'SKK', name: 'Slovak Koruna - SKK' },
  { value: 'SLL', name: 'Sierra Leonean Leone - SLL' },
  { value: 'SOS', name: 'Somali Shilling - SOS' },
  { value: 'SRD', name: 'Surinamese Dollar - SRD' },
  { value: 'SSP', name: 'South Sudanese Pound - SSP' },
  { value: 'STD', name: 'São Tomé and Príncipe Dobra - STD' },
  { value: 'SVC', name: 'Salvadoran Colón - SVC' },
  { value: 'SYP', name: 'Syrian Pound - SYP' },
  { value: 'SZL', name: 'Swazi Lilangeni - SZL' },
  { value: 'THB', name: 'Thai Baht - THB' },
  { value: 'TJS', name: 'Tajikistani Somoni - TJS' },
  { value: 'TMM', name: 'Turkmenistani Manat - TMM' },
  { value: 'TMT', name: 'Turkmenistani Manat - TMT' },
  { value: 'TND', name: 'Tunisian Dinar - TND' },
  { value: 'TOP', name: 'Tongan Paʻanga - TOP' },
  { value: 'TRY', name: 'Turkish Lira - TRY' },
  { value: 'TTD', name: 'Trinidad and Tobago Dollar - TTD' },
  { value: 'TWD', name: 'New Taiwan Dollar - TWD' },
  { value: 'TZS', name: 'Tanzanian Shilling - TZS' },
  { value: 'UAH', name: 'Ukrainian Hryvnia - UAH' },
  { value: 'UGX', name: 'Ugandan Shilling - UGX' },
  { value: 'UYU', name: 'Uruguayan Peso - UYU' },
  { value: 'UZS', name: 'Uzbekistan Som - UZS' },
  { value: 'VEF', name: 'Venezuelan Bolívar - VEF' },
  { value: 'VND', name: 'Vietnamese Đồng - VND' },
  { value: 'VUV', name: 'Vanuatu Vatu - VUV' },
  { value: 'WST', name: 'Samoan Tala - WST' },
  { value: 'XAF', name: 'Central African Cfa Franc - XAF' },
  { value: 'XAG', name: 'Silver (Troy Ounce) - XAG' },
  { value: 'XAU', name: 'Gold (Troy Ounce) - XAU' },
  { value: 'XBA', name: 'European Composite Unit - XBA' },
  { value: 'XBB', name: 'European Monetary Unit - XBB' },
  { value: 'XBC', name: 'European Unit of Account 9 - XBC' },
  { value: 'XBD', name: 'European Unit of Account 17 - XBD' },
  { value: 'XCD', name: 'East Caribbean Dollar - XCD' },
  { value: 'XDR', name: 'Special Drawing Rights - XDR' },
  { value: 'XFU', name: 'UIC Franc - XFU' },
  { value: 'XOF', name: 'West African Cfa Franc - XOF' },
  { value: 'XPD', name: 'Palladium - XPD' },
  { value: 'XPF', name: 'Cfp Franc - XPF' },
  { value: 'XPT', name: 'Platinum - XPT' },
  { value: 'YER', name: 'Yemeni Rial - YER' },
  { value: 'ZAR', name: 'South African Rand - ZAR' },
  { value: 'ZMK', name: 'Zambian Kwacha - ZMK' },
  { value: 'ZMW', name: 'Zambian Kwacha - ZMW' },
  { value: 'ZWD', name: 'Zimbabwean Dollar - ZWD' },
  { value: 'ZWL', name: 'Zimbabwean Dollar - ZWL' },
  { value: 'ZWN', name: 'Zimbabwean Dollar - ZWN' },
  { value: 'ZWR', name: 'Zimbabwean Dollar - ZWR' }
];

export const dueDateOptions = [
  { id: 0, label: 'Today' },
  { id: 1, label: 'Tomorrow' },
  { id: 2, label: 'Net 2 Days' },
  { id: 3, label: 'Net 3 Days' },
  { id: 4, label: 'Net 4 Days' },
  { id: 5, label: 'Net 5 Days' },
  { id: 6, label: 'Net 6 Days' },
  { id: 7, label: 'Net 7 Days' },
  { id: 10, label: 'Net 10 Days' },
  { id: 15, label: 'Net 15 Days' },
  { id: 30, label: 'Net 30 Days' },
  { id: 35, label: 'Net 35 Days' },
  { id: 45, label: 'Net 45 Days' },
  { id: 60, label: 'Net 60 Days' },
  { id: 70, label: 'Net 70 Days' },
  { id: 80, label: 'Net 80 Days' },
  { id: 90, label: 'Net 90 Days' },
  { id: 100, label: 'Net 100 Days' }
];

export const goalDuration = [
  { label: '7 Days', value: 7 },
  { label: '10 Days', value: 10 },
  { label: '15 Days', value: 15 },
  { label: '30 Days', value: 30 },
  { label: '45 Days', value: 45 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 }
];
