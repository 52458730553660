import React from 'react';
import SVGIcon from '../../assets/svg/SVGIcon';
import { ROUTE } from '../../constants/routeConst';
import useMobileDevice from '../../hooks/useMobileDevice';
import {
  MainNav,
  NavList,
  Ul,
  Li,
  SpanList,
  ListView,
  MenuItemLink,
  MainNavWrapper,
  TopView,
  LeftView,
  TextView,
  SpanText,
  Title,
  Border,
  ReportLi,
  ReportMain,
  HelpBlock,
  NavIcon,
  HelpSpanList,
  TopLinear,
  BottmLinear
} from './styles';

const ReportNavbar = (props) => {
  const mobile = useMobileDevice();
  const { onCloseNavbar, onOpenHelpModal, isOpenHelpModal } = props;
  return (
    <>
      <MainNav>
        <MainNavWrapper>
          <NavList>
            <TopLinear></TopLinear>
            <BottmLinear></BottmLinear>
            <ReportMain>
              <TopView>
                <LeftView onClick={props.onClickBack} mobile={mobile}>
                  <SVGIcon
                    name='icon-big-left-arrow'
                    fill='var(--white-color)'
                    size={mobile ? 14 : 12}
                    strokeWidth={2}
                  />
                  <TextView>
                    <SpanText>Back</SpanText>
                  </TextView>
                </LeftView>
              </TopView>
              <ListView>
                <Ul>
                  <ReportLi>
                    <Title>Reports</Title>
                    <Border></Border>
                  </ReportLi>
                  <Li>
                    <MenuItemLink to={ROUTE.REPORTSOVERVIEW} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Overview</SpanList>
                    </MenuItemLink>
                  </Li>
                  <Li>
                    <MenuItemLink to={ROUTE.ACTIVITY} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Activity</SpanList>
                    </MenuItemLink>
                  </Li>
                  <Li>
                    <MenuItemLink to={ROUTE.CALLSUMMARY} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Call Summary</SpanList>
                    </MenuItemLink>
                  </Li>
                  <Li>
                    <MenuItemLink to={ROUTE.STATUSCHANGE} activeClassName='active_menu' onClick={onCloseNavbar}>
                      <SpanList>Status Change</SpanList>
                    </MenuItemLink>
                  </Li>
                </Ul>
              </ListView>
              <HelpBlock
                activeClassName='active_menu'
                mobile={mobile}
                onClick={onOpenHelpModal}
                isOpenHelpModal={isOpenHelpModal}>
                <NavIcon name='icon-feedback' help />
                <HelpSpanList help>Help & Feedback</HelpSpanList>
              </HelpBlock>
            </ReportMain>
          </NavList>
        </MainNavWrapper>
      </MainNav>
    </>
  );
};

export default ReportNavbar;
