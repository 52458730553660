import styled, { css } from 'styled-components';
import { Header2, Header4, Text1, Text2, Header6 } from '../../../styles';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';

const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
`;
const MainList = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 16px 16px 16px;
        `
      : css`
          margin: 24px auto;
          width: 100%;
          max-width: 680px;
        `}
`;
const Title = styled.h1`
  margin: 0;
  color: var(--dark-color);
  ${Header2}
`;
const InputWrapper = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin: 0 0 12px 0;
        `
      : css`
          margin: 24px 0 16px 0;
        `}
`;
const EmailInput = styled(Input)`
  padding: 7px 12px;
  ${Text1}
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          margin-bottom: 10px;
        `
      : css`
          width: 369px;
        `}
`;
const ContainBoxTwo = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-bottom: 16px;
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  &:last-child {
    border-bottom: 0;
  }
`;
const FirstList = styled.div``;
const SubText = styled.h4`
  margin: 0 0 2px 0;
  font-style: normal;
  ${Header4}
  color: var(--dark-color);
  display: inline-block;
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1}
  color: var(--gray-color);
  margin: 0;
`;
const IconView = styled.div`
  display: block;
  cursor: pointer;
  i {
    display: block;
  }
`;
const LoginDetails = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 10px;
  }
`;
const Label = styled.span`
  ${Text2}
  text-transform: capitalize;
  color: var(--gray-color);
  padding: 2px 12px;
  background-color: var(--popup-color-hover);
  border-radius: 14px;
  margin-left: 6px;
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--Platinum-color);
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const Form = styled.form`
  padding: 16px;
  outline: none;
  overflow: auto;
  height: 100%;
`;
const MainContact = styled.div`
  height: calc(100vh - 260px);
  padding-bottom: 112px;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          height: 100%;
          margin: 0 auto 0 auto;
        `
      : css``}
`;
const TitleText = styled.span`
  ${Header4};
  color: var(--dark-color);
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
  i {
    cursor: pointer;
  }
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 155px;
`;
const ModifyUserBlock = styled.div`
  padding: 12px 0;
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  margin-bottom: 12px;
`;
const ModifyUserHead = styled.div`
  display: flex;
  padding: 0 16px;
`;
const CustomCheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .switch {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 14px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-border-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 0.9px;
    top: 0.9px;
    background-color: var(--white-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-color);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
  }
  .slider.round {
    border-radius: 16px;
  }

  .slider.round:before {
    border-radius: 16px;
  }
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 14px;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: -1px;
    bottom: 2px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid #dcdcdc;
    border-radius: 3px;
  }
  :hover input ~ .checkmark {
    border: 1px solid #999;
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 0px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const CustomCheckbox = styled.div``;
const IconLabel = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const LabelTitle = styled.span`
  ${Text1};
  font-weight: 500;
  letter-spacing: 0;
  color: var(--dark-color);
  margin-left: 8px;
  ${(props) =>
    props.mobile
      ? css`
          ${Text1}
        `
      : css``}
`;
const ModifyUserContent = styled.div`
  border-top: 1px solid var(--gray-border-color);
  margin-top: 12px;
`;
const ModifyUserOption = styled.div`
  padding: 0 16px;
  ${(props) =>
    props.mobile
      ? css`
          display: block;
        `
      : css`
          display: flex;
          align-items: center;
        `}
`;
const ModifyUserOptionItem = styled.div`
  padding-top: 12px;
  ${(props) =>
    props.paddingLeft &&
    css`
      margin-left: 50px;
    `}
  ${(props) =>
    props.mobile &&
    css`
      margin-left: 0;
    `}
`;
const ModifyHeading = styled.h4`
  ${Header6};
  text-transform: uppercase;
  color: var(--gray-color);
  margin: 0;
  margin-bottom: 6px;
`;
const ModifyUserOptionItemList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  li:nth-child(2) {
    margin-left: 18px;
  }
`;
const ModifyUserOptionItemListLi = styled.li`
  list-style: none;
  cursor: pointer;
  position: relative;

  input {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 14px;
    height: 14px;
    z-index: 1;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border-radius: 100%;
    border: 1px solid var(--gray-border-color);
    margin: 0;
  }
  input:checked ~ .checkmark {
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 3px;
    width: 6px;
    height: 6px;
    background-color: var(--primary-color);
    border-radius: 100%;
  }
`;
const LabelInput = styled.label`
  display: inline;
  position: relative;
  cursor: pointer;
  ${Text2}
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const InputLabel = styled.label`
  display: inline;
  position: relative;
  cursor: pointer;
  ${Text2}
  padding-left: 20px;
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const ModifyList = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-bottom: 0;
  }
`;
const ButtonInvite = styled(Button)`
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 0px;
        `
      : css`
          margin-left: 10px;
        `}
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 12px;
  }
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Wrapper,
  MainList,
  Title,
  InputWrapper,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  LoginDetails,
  Label,
  BottomView,
  Form,
  MainContact,
  TitleText,
  TitleView,
  LeftWrapper,
  ModifyUserBlock,
  ModifyUserHead,
  CustomCheckboxWrapper,
  InputWrapperModal,
  CustomCheckbox,
  IconLabel,
  LabelTitle,
  ModifyUserContent,
  ModifyUserOption,
  ModifyUserOptionItem,
  ModifyHeading,
  ModifyUserOptionItemList,
  ModifyUserOptionItemListLi,
  LabelInput,
  ModifyList,
  ButtonInvite,
  EmailInput,
  InputLabel,
  TitleWrapper,
  MenuItemWrapper,
  SvgWrapper
};
