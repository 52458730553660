import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  getAPIErrorReason,
  getLookupValueFromField,
  isEmail,
  isEmpty,
  setFieldConditionOptions
} from '../helpers/common';
import { REACT_APP_APIURL } from '../global/Environment';
import {
  setLookupDetail,
  setLookupGroupFields,
  setLookupList,
  setLookupLoader,
  setLookupValidationError,
  setSegmentList,
  updateLookupItem,
  setManageSegmentList,
  setSegmentFilter,
  setLookupTags,
  setAllLookup,
  setConnectedLookups,
  setLookupFields,
  setLookupEnrichmentFields
} from '../actions/lookupActions';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import {
  ENRICH_FEILDS,
  LookupFieldTypes,
  LOOKUP_FIELD_CUSTOM_ID,
  LOOKUP_SEGMENT_FIELD_TYPE,
  LOOKUP_TYPE,
  SYNC_PAST_EMAIL_TYPE
} from '../constants/constant';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';
import LookupCacheService from './lookupCacheServices';
import { getTableFieldsFromRedux } from './lookupTableServices';
import { getTableFields } from './lookupTableServices';
import { getDashboardDetail } from './dashboardService';
import { trackAnalyticActivity } from './analyticsService';
import EmailCacheService from './emailCacheServices';
import { getActivityByLookup } from './activityService';
import { getEmailsByLookup } from './emailService';
import EmailAccountCacheService from './emailAccountCacheServices';

let ajaxRequest = null;
/**
 * @desc Lookup - Get Lookup List
 * @param {*} obj Data Obj
 */
export const getLookupList = (company_id, table_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setLookupLoader(true));

    //Set list from local cache
    if (table_id) {
      const lookups_local = await LookupCacheService.getInstance()?.getLookupsByTable(table_id);
      if (lookups_local && lookups_local.length > 0) {
        const filterResult = process(lookups_local, {
          sort: [{ field: 'createdOn', dir: 'desc' }]
        });
        const result = filterResult?.data;
        dispatch(setLookupList(result));
        dispatch(getSegmentList(table_id));
      }
    }

    const lastUpdatedTimeFromLocalStorage = await LookupCacheService.getInstance()?.getLastUpdatedTime();
    const lastUpdatedTime = lastUpdatedTimeFromLocalStorage ? new Date(lastUpdatedTimeFromLocalStorage).getTime() : 0;

    if (ajaxRequest) {
      ajaxRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    ajaxRequest = axios.CancelToken.source();
    const response = await axios.get(`${REACT_APP_APIURL}/Lookup/${company_id}/LastUpdatedList/${lastUpdatedTime}`, {
      cancelToken: ajaxRequest.token
    });

    const { data, lastUpdatedOn, archived } = response.data;
    let lookupList = LookupCacheService.getInstance()?.setLookupDisplayValue(data);
    let hasUpdatedData = false;
    if (lastUpdatedTime === 0) {
      await LookupCacheService.getInstance()?.setLookups(lookupList);
      hasUpdatedData = true;
    } else {
      let existingContacts = [];
      if (lookupList?.length > 0) {
        //Get existing contact to ignore to get new emails
        const lookupIds = lookupList?.map((x) => x.id);
        existingContacts = (await LookupCacheService.getInstance()?.getByIds(lookupIds)) || [];
      }
      await LookupCacheService.getInstance()?.updateLookups(lookupList, archived);
      if ((lookupList && lookupList.length > 0) || (archived && archived.length > 0)) {
        hasUpdatedData = true;
        if (lookupList?.length > 0) {
          try {
            const lookupIds = lookupList
              .filter((x) => x.email && existingContacts?.findIndex((y) => y.id === x.id) === -1)
              .map((x) => x.id);
            if (lookupIds?.length > 0) {
              //Get emails only for newly added
              dispatch(getEmailsByLookup(company_id, lookupIds));
            }
          } catch (e) {
            console.log("ERROR Fetch new contact's email", e);
          }
        }
        if (archived?.length > 0) {
          archived.forEach((lookupId) => {
            removeEmailLookupReferences(company_id);
          });
        }
      }
    }
    await LookupCacheService.getInstance()?.setLastUpdatedTime(lastUpdatedOn);

    //Update segment count
    if (hasUpdatedData && table_id) {
      dispatch(getSegmentList(table_id));

      //Set list after update from server
      const lookups = await LookupCacheService.getInstance()?.getLookupsByTable(table_id);
      if (lookups) {
        const filterResult = process(lookups, {
          sort: [{ field: 'createdOn', dir: 'desc' }]
        });
        const result = filterResult?.data;
        dispatch(setLookupList(result));
      }
    }

    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to get lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Get Lookup search List
 * @param {*} obj Data Obj
 */
export const getLookupSearchList = async (query) => {
  try {
    //Set list from local cache
    const MAX_RESULT = 15;
    const tables = OrganizationPreferencesSingleton.getInstance().getTables() || [];
    const tableIds = tables
      .filter((x) => x.type !== LOOKUP_TYPE.others && x.type !== LOOKUP_TYPE.leads)
      .map((x) => x.id);
    const lookups_local = await LookupCacheService.getInstance()?.getLookupsByTableIds(tableIds);
    if (!query || !String(query).trim()) return [];

    if (lookups_local && lookups_local.length > 0) {
      let queryLowerCase = String(query).toLowerCase().trim();
      const result = lookups_local
        ?.filter(
          (x) =>
            (x.name && String(x.name).toLowerCase().includes(queryLowerCase)) ||
            (x.email && String(x.email).toLowerCase().includes(queryLowerCase)) ||
            (x.phone && String(x.phone).toLowerCase().includes(queryLowerCase))
        )
        ?.slice(0, MAX_RESULT);
      return result;
    }
    return lookups_local;
  } catch (e) {
    return undefined;
  }
};

/**
 * @desc Lookup - Get Segment List
 * @param {*} obj Data Obj
 */
export const getSegmentList = (table_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    let segments = OrganizationPreferencesSingleton.getInstance().getSegmentsByTable(table_id);
    const lookups = await LookupCacheService.getInstance()?.getLookupsByTable(table_id);
    segments = segments.map((item) => {
      return { ...item, count: getSegmentFilteredList(lookups, table_id, item?.id).length, tableId: table_id };
    });
    dispatch(setSegmentList(segments));
    return true;
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to get Segments please try again', dispatch);
    return false;
  } finally {
  }
};

/**
 * @desc Lookup - Get Segment List
 * @param {*} obj Data Obj
 */
export const getManageSegmentList = (company_id, table_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Lookup/${company_id}/Segments/${table_id}`);
    const { data } = response;
    dispatch(setManageSegmentList(data));
    return true;
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to get Segments please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Segment - Segment Index Change
 * @param {*} company_id, table_id, segment_id, payload
 */
export const segmentIndexChange = (company_id, table_id, segment_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.put(
      `${REACT_APP_APIURL}/lookup/${company_id}/segments/${table_id}/index/${segment_id}`,
      payload
    );
    const { data } = response;
    if (data) {
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to segment Index Change please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Update Lookup
 * @param {*} obj Data Obj
 */
export const updateLookupField = (organization_id, lookupId, payload) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    if (!organization_id) return false;

    //Update on local
    const updatedLookup = await LookupCacheService.getInstance()?.updateLookupField(
      lookupId,
      payload.field,
      payload.value
    );

    //Update lookup list item content from local to show instant update
    dispatch(updateLookupItem(updatedLookup));
    await dispatch(getDashboardDetail(organization_id));
    //Update lookup detail content from local to show instant update
    const detailLookupId = getState()?.lookup?.lookupDetail?.id;
    if (detailLookupId === lookupId) {
      dispatch(getLookupDetails(organization_id, lookupId));
    }

    //Update on server
    const response = await axios.put(`${REACT_APP_APIURL}/lookup/${organization_id}/fieldValue/${lookupId}`, payload);
    const { data } = response;
    if (data) {
      if (payload?.field?.customId === LOOKUP_FIELD_CUSTOM_ID.STATUS) {
        dispatch(getActivityByLookup(organization_id, lookupId));
      }
      return true;
    }
    return false;
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to update Lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

export const getSegmentFilteredList = (list, tableId, segmentId) => {
  if (!tableId || !segmentId) return [];
  const segmentList = OrganizationPreferencesSingleton.getInstance().getSegmentsByTable(tableId);
  const segment = segmentList.find((x) => x.id === segmentId);
  let filter;
  if (segment) {
    filter = getSegmentFilter(segment);
  }
  const filterResult = process(list, {
    sort: [{ field: 'createdOn', dir: 'desc' }],
    filter: filter
  });
  const result = filterResult.data;
  return result;
};

const getSegmentFilter = (segment) => {
  if (!segment || !segment.filters || segment.filters.length === 0) return undefined;
  let filter = {
    logic: 'and',
    filters: []
  };
  if (segment.condition === 1) filter.logic = 'and';
  else if (segment.condition === 2) filter.logic = 'or';

  segment.filters.forEach((filterItem) => {
    let filterCondition;
    //Check type of segment field, and find filter condition according that
    if (filterItem.type === LOOKUP_SEGMENT_FIELD_TYPE.LookupField)
      filterCondition = getSegmentFilterCondition(filterItem);
    else if (filterItem.type === LOOKUP_SEGMENT_FIELD_TYPE.TagField)
      filterCondition = getSegmentFilterConditionForTag(filterItem);

    if (filterCondition) filter.filters.push(filterCondition);
  });
  return filter;
};

const getUtcDate = (date) => {
  if (!date) date = new Date();
  else date = new Date(date);

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const addDays = (date, number) => {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + number);
  return newDate;
};

const getSegmentFilterCondition = (condition) => {
  if (!condition) return undefined;
  let fieldFilter = { ignoreCase: true, operator: getSegmentFilterOperator(condition.condition.value) };
  let fieldPreFix = 'fields.' + condition.field.id;

  if (condition.condition.value === 'isunknown') {
    fieldFilter.field = fieldPreFix;
  } else if (condition.condition.value === 'any') {
    fieldFilter.field = fieldPreFix;
  }
  if (condition.field) {
    if (
      condition.field.type === LookupFieldTypes.Text ||
      condition.field.type === LookupFieldTypes.MultiLineText ||
      condition.field.type === LookupFieldTypes.Email ||
      condition.field.type === LookupFieldTypes.Url ||
      condition.field.type === LookupFieldTypes.Select ||
      condition.field.type === LookupFieldTypes.Phone
    ) {
      fieldFilter.field = fieldPreFix + '.value';
      fieldFilter.value = condition.value;
    } else if (condition.field.type === LookupFieldTypes.Number || condition.field.type === LookupFieldTypes.Amount) {
      fieldFilter.field = fieldPreFix + '.value_Number';
      fieldFilter.value = condition.value_Number;
    } else if (condition.field.type === LookupFieldTypes.Date || condition.field.type === LookupFieldTypes.Time) {
      if (condition.condition.valueType === 2) {
        let dayMultiplier = 1;
        fieldFilter.operator = 'gt';
        if (condition.condition.value === 'lessthan') dayMultiplier = -1;
        let newDateValue = addDays(getUtcDate(), dayMultiplier * Number(condition.value_Number));
        fieldFilter.field = fieldPreFix + '.value_Date';
        fieldFilter.value = newDateValue;
      } else if (condition.condition.valueType === 3) {
        fieldFilter.field = fieldPreFix + '.value_Date';
        fieldFilter.value = new Date(condition.value_Date);
      }
    } else if (condition.field.type === LookupFieldTypes.Boolean) {
      fieldFilter.field = fieldPreFix + '.value_Bool';
      fieldFilter.value = condition.value_Bool;
    } else if (condition.field.type === LookupFieldTypes.MultiSelect) {
      let filter = {
        logic: 'or',
        filters: []
      };
      if (condition.condition.value === 'isnot') filter.logic = 'and';

      if (condition.field.customId === LOOKUP_FIELD_CUSTOM_ID.TAGS) {
        for (var i = 0; i < 10; i++) {
          let fieldFilterOr = {
            ignoreCase: true,
            operator: getSegmentFilterOperator(condition.condition.value)
          };
          fieldFilterOr.field = fieldPreFix + `.value_Options.[${i}].value`;
          fieldFilterOr.value = condition.value;
          filter.filters.push(fieldFilterOr);
        }
      } else {
        if (condition.field) {
          for (var j = 0; j < condition.field.options.length; j++) {
            let fieldFilterOr = {
              ignoreCase: true,
              operator: getSegmentFilterOperator(condition.condition.value)
            };
            fieldFilterOr.field = fieldPreFix + `.value_Options.['${j}'].id`;
            fieldFilterOr.value = condition.value;
            filter.filters.push(fieldFilterOr);
          }
        }
      }
      return filter;
    } else {
      fieldFilter.field = fieldPreFix + '.value';
      fieldFilter.value = condition.value;
    }
    //if (!fieldFilter.value)
    //	return undefined;
  }
  return fieldFilter;
};

const getSegmentFilterConditionForTag = (condition) => {
  if (!condition) return undefined;

  let fieldPreFix = 'tags';
  let fieldFilter = { ignoreCase: true, operator: getSegmentFilterOperator(condition.condition.value) };
  if (condition.condition.value === 'isunknown') {
    fieldFilter.field = fieldPreFix;
    return fieldFilter;
  } else if (condition.condition.value === 'any') {
    fieldFilter.field = fieldPreFix;
    return fieldFilter;
  } else {
    let filter = {
      logic: 'or',
      filters: []
    };
    if (condition.condition.value === 'isnot' || condition.condition.value === 'notcontains') filter.logic = 'and';
    for (var i = 0; i < 10; i++) {
      let fieldFilterOr = { ignoreCase: true, operator: getSegmentFilterOperator(condition.condition.value) };
      fieldFilterOr.field = `${fieldPreFix}.[${i}].name`;
      fieldFilterOr.value = condition.value;
      filter.filters.push(fieldFilterOr);
    }
    return filter;
  }
};

const getSegmentFilterOperator = (condition) => {
  if (condition === 'is') return 'eq';
  else if (condition === 'isnot') return 'neq';
  else if (condition === 'contains') return 'contains';
  else if (condition === 'notcontains') return 'doesnotcontain';
  else if (condition === 'startwith') return 'startswith';
  else if (condition === 'endwith') return 'endswith';
  else if (condition === 'lessthan') return 'lt';
  else if (condition === 'morethan') return 'gt';
  else if (condition === 'on') return 'eq';
  else if (condition === 'before') return 'lt';
  else if (condition === 'after') return 'gt';
  else if (condition === 'isunknown') return 'isnull';
  else if (condition === 'any') return 'isnotnull';
};

/**
 * @desc Lookup - Get Lookup Detail( contact )
 * @param {*} id Lookup_id
 */
export const getLookupDetails = (organization_id, lookup_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id || !lookup_id) return;
    dispatch(setLookupLoader(true));
    const lookupDetail_Local = await LookupCacheService.getInstance()?.getItem(lookup_id);
    if (lookupDetail_Local) {
      const fields = dispatch(getTableFieldsFromRedux());
      const tableFields = fields?.filter((x) => x.tableId === lookupDetail_Local.tableId);

      const lookupFields = tableFields.map((field) => {
        return {
          ...field,
          ...lookupDetail_Local?.fields[field.id]
        };
      });
      const lookupDetails = {
        id: lookup_id,
        companyId: organization_id,
        groupFields: [
          {
            fields: lookupFields,
            name: 'Lookup'
          }
        ]
      };
      dispatch(setLookupDetail(lookupDetails));
    }
    dispatch(getContactOrCompanyOrDealsByLookup(organization_id, lookup_id));
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to login please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Get Lookup Detail( contact )
 * @param {*} id Lookup_id
 */
export const getLookupDetailsByEmail = (organization_id, email) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id || !email) return;
    dispatch(getLookupList(organization_id));
    dispatch(setLookupLoader(true));
    dispatch(setLookupDetail(undefined));
    const lookups_Local = await LookupCacheService.getInstance()?.getLookupsByEmail(email);
    if (lookups_Local && lookups_Local.length > 0) {
      return lookups_Local[0];
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to get contact, please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Get Lookup Detail( contact )
 * @param {*} id Lookup_id
 */
export const getContactOrCompanyOrDealsByLookup = (organization_id, lookup_id) => async (dispatch) => {
  try {
    dispatch(setConnectedLookups(null));
    if (!organization_id) return false;
    const lookup = await LookupCacheService.getInstance()?.getItem(lookup_id);
    if (lookup) {
      const allTablefields = OrganizationPreferencesSingleton.getInstance()?.getFields();
      const fields = [].concat
        .apply(
          [],
          allTablefields?.map((x) =>
            x.columns?.map((y) => {
              return {
                ...y,
                tableId: x.tableId
              };
            })
          )
        )
        .filter((x) => x.customId === LOOKUP_FIELD_CUSTOM_ID.CONTACT || x.customId === LOOKUP_FIELD_CUSTOM_ID.COMPANY);

      //If not any connected field exist in table then retun
      if (fields?.length === 0) return;

      let filter = {
        logic: 'or',
        filters: []
      };

      fields.forEach((field) => {
        filter.filters.push({ field: `fields.${field.id}.value_Lookup.id`, value: lookup_id, operator: 'eq' });
      });

      const lookups = await LookupCacheService.getInstance()?.getLookups();
      const filterResult = process(lookups, {
        filter: filter
      });
      const filterResultData = filterResult.data;

      const contacts = [];
      const deals = [];
      const companies = [];

      if (lookup.contact) contacts.push(lookup.contact);
      if (lookup.company) companies.push(lookup.company);

      const tables = OrganizationPreferencesSingleton.getInstance()?.getTables();
      filterResultData.forEach((lookup) => {
        const table = tables.find((x) => x.id === lookup.tableId);
        if (table && table.type === LOOKUP_TYPE.contacts) contacts.push(lookup);
        else if (table && table.type === LOOKUP_TYPE.companies) companies.push(lookup);
        else if (table && table.type === LOOKUP_TYPE.deals) deals.push(lookup);
      });
      const result = {
        contacts,
        companies,
        deals
      };
      dispatch(setConnectedLookups(result));
      return result;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to fetch contact/company. please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Get Lookup Detail from server
 * @param {*} id Lookup_id
 */
export const getLookupItemFromServer = (organization_id, lookup_id) => async (dispatch) => {
  try {
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Lookup/${organization_id}/item/${lookup_id}`);
    const { data } = response;
    if (data) {
      const fields = [].concat.apply(
        [],
        data?.groupFields.map((x) => x.fields)
      );
      await dispatch(setLookupGroupFields(fields));
      return fields;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to login please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Create New Lookup
 * @param {*} organization_id, table_id, obj
 */
export const createLookup = (organization_id, table_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatchLookupValidationError({}, dispatch);
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const resError = checkValidation(obj);
    if (!isEmpty(resError)) {
      dispatchLookupValidationError(resError, dispatch);
      return;
    }

    const response = await axios.post(`${REACT_APP_APIURL}/Lookup/${organization_id}/Create/${table_id}`, obj);
    const { data, message } = response?.data;
    dispatchLookupSucess(message, dispatch);
    if (data) return true;
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Create lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Update Lookup
 * @param {*} organization_id, table_id, obj
 */
export const updateLookup = (organization_id, table_id, obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatchLookupValidationError({}, dispatch);
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const resError = checkValidation(obj);
    if (!isEmpty(resError)) {
      dispatchLookupValidationError(resError, dispatch);
      return;
    }
    await LookupCacheService.getInstance()?.updateLookupItem({ ...obj, tableId: table_id });

    const response = await axios.put(`${REACT_APP_APIURL}/Lookup/${organization_id}/Update/${table_id}`, obj);
    const { data, message } = response.data;

    dispatchLookupSucess(message, dispatch);
    if (data) return true;
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Create lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};
const removeLookupFromArray = (data, lookup_id) => {
  const result = data?.map((x) => {
    return { ...x, lookup: x?.lookup?.id === lookup_id ? undefined : x?.lookup };
  });
  return result;
};

const removeEmailLookupReferences = async (lookup_id) => {
  try {
    const emails = await EmailCacheService.getInstance()?.getEmails();
    const lookupEmails = emails?.filter((x) => x?.participants?.findIndex((y) => y?.lookup?.id === lookup_id) !== -1);
    if (!isEmpty(lookupEmails)) {
      const updatedEmails = lookupEmails?.map((item) => {
        if (item.lookup?.id === lookup_id) item.lookup = undefined;
        if (item?.lastMsg?.from?.lookup?.id === lookup_id) item.lastMsg.from.lookup = undefined;
        item.lastMsg.to = removeLookupFromArray(item?.lastMsg?.to, lookup_id);
        item.lastMsg.bcc = removeLookupFromArray(item?.lastMsg?.bcc, lookup_id);
        item.lastMsg.cc = removeLookupFromArray(item?.lastMsg?.cc, lookup_id);
        item.participants = removeLookupFromArray(item?.participants, lookup_id);
        item.messages = item?.messages?.map((message) => {
          message.to = removeLookupFromArray(message?.to, lookup_id);
          message.bcc = removeLookupFromArray(message?.bcc, lookup_id);
          message.cc = removeLookupFromArray(message?.cc, lookup_id);
          if (message?.from?.lookup?.id === lookup_id) {
            message.from = {
              ...message?.from,
              lookup: undefined
            };
          }
          return message;
        });
        return item;
      });

      //Get email account list which have contact only sync
      const emailAccounts = await EmailAccountCacheService.getInstance().getEmailAccounts();
      const emailAccountsHasContactOnlySettings = emailAccounts?.filter(
        (x) => x.isEmailSync && x.syncSettings?.syncPastEmail === SYNC_PAST_EMAIL_TYPE.SALESCAMP_ONLY
      );

      //Remove email threads which do not have any lookup (other than deleted lookup)
      //Remove email thread only if email sync is only for contact
      const emailThreadToBeRemove =
        updatedEmails
          .filter(
            (y) =>
              y.participants?.filter((z) => z.lookup)?.length === 0 &&
              emailAccountsHasContactOnlySettings.findIndex((z) => z.id === y.emailAccountId) !== -1
          )
          ?.map((x) => x.id) || [];

      await EmailCacheService.getInstance()?.updateEmails(updatedEmails, emailThreadToBeRemove);
    }
  } catch (e) {
    console.log('ERROR removeEmailLookupReferences', e);
  }
};

/**
 * @desc Lookup - Update Lookup
 * @param {*} organization_id, lookup_id, obj
 */
export const deleteLookup = (organization_id, lookup_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const emails = await EmailCacheService.getInstance()?.getEmails();
    const lookupEmails = emails?.filter((x) => x?.participants?.findIndex((y) => y?.lookup?.id === lookup_id) !== -1);
    if (!isEmpty(lookupEmails)) {
      removeEmailLookupReferences(lookup_id);
    }
    const response = await axios.delete(`${REACT_APP_APIURL}/Lookup/${organization_id}/item/${lookup_id}`);
    const { message } = response?.data;
    if (response?.data) {
      dispatchLookupSucess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Delete lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookups - Delete lookups
 * @param {*} organization_id,payload
 */
export const deleteLookups = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${organization_id}/delete`, payload);
    const { message } = response?.data;
    if (response?.data) {
      dispatchLookupSucess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to delete lookups please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

export const validateLookupFieldInput = (item) => {
  const result = {
    error: ''
  };
  if (
    item.type === LookupFieldTypes.Text ||
    item.type === LookupFieldTypes.Phone ||
    item.type === LookupFieldTypes.Email ||
    item.type === LookupFieldTypes.Url ||
    item.type === LookupFieldTypes.MultiLineText ||
    item.type === LookupFieldTypes.Select
  ) {
    if (!item.value || item.value.trim() === '') {
      result.error = `${item.label} is Required `;
    } else if (item.type === LookupFieldTypes.Email) {
      if (isEmail(item.value) === false) {
        result.error = 'Please enter a valid email address';
      }
    }
  } else if (item.type === LookupFieldTypes.Number || item.type === LookupFieldTypes.Amount) {
    if (!item.value_Number || String(item.value_Number).trim() === '') {
      result.error = `${item.label} is Required `;
    }
  } else if (item.type === LookupFieldTypes.Date || item.type === LookupFieldTypes.Time) {
    if (!item.value_Date || item.value_Date.trim() === '') {
      result.error = `${item.label} is Required `;
    }
  } else if (item.type === LookupFieldTypes.MultiSelect) {
    if (!item.value_Options || item.value_Options.length === 0) {
      result.error = `${item.label} is Required `;
    }
  } else if (item.type === LookupFieldTypes.Lookup) {
    if (item.isMultiSelection) {
      if (!item.value_MultiLookup || item.value_MultiLookup.length === 0) {
        result.error = `${item.label} is Required `;
      }
    } else {
      if (!item.value_Lookup) {
        result.error = `${item.label} is Required `;
      }
    }
  }
  return result;
};

/**
 * @desc Segment - Create Segment Filter
 * @param {*} organization_id, table_id, payload
 */
export const createSegmentFilter = (organization_id, table_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatchLookupValidationError({}, dispatch);
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const resError = checkSegmentFilterValidation(payload);
    if (!isEmpty(resError)) {
      dispatchLookupValidationError(resError, dispatch);
      return;
    }
    const response = await axios.post(
      `${REACT_APP_APIURL}/Lookup/${organization_id}/Segments/${table_id}/Create`,
      payload
    );
    const { data, message } = response?.data;
    if (data) {
      dispatchLookupSucess(message, dispatch);
      await dispatch(getTableFields(organization_id, true));
      dispatch(getSegmentList(table_id));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Create Segment please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Segment - get Segment Filter Item
 * @param {*} organization_id, table_id, segment_id
 */
export const getSegmentFilterItem = (organization_id, table_id, segment_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatchLookupValidationError({}, dispatch);
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));

    const response = await axios.get(
      `${REACT_APP_APIURL}/Lookup/${organization_id}/Segments/${table_id}/Item/${segment_id}`
    );
    const { data } = response;
    if (data) {
      const filters = data?.filters?.map((item) => {
        setFieldConditionOptions(item, 'field');
        return { ...item, field: { ...item.field, fieldOption: item?.field?.options, options: undefined } };
      });
      const payload = {
        ...data,
        filters
      };
      dispatch(setSegmentFilter(payload));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Get Segment Item please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Segment - Update Segment Filter
 * @param {*} organization_id, table_id,segment_id, payload
 */
export const updateSegmentFilter = (organization_id, table_id, segment_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatchLookupValidationError({}, dispatch);
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const resError = checkSegmentFilterValidation(payload);
    if (!isEmpty(resError)) {
      dispatchLookupValidationError(resError, dispatch);
      return;
    }
    const response = await axios.put(
      `${REACT_APP_APIURL}/Lookup/${organization_id}/Segments/${table_id}/Update/${segment_id}`,
      payload
    );
    const { data } = response?.data;
    if (data) {
      await dispatch(getManageSegmentList(organization_id, table_id, true));
      await dispatch(getTableFields(organization_id, true));
      dispatch(getSegmentList(table_id));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to update Segment please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Segment - Delete Segment Filter
 * @param {*} organization_id, table_id, segment_id
 */
export const deleteSegmentFilter = (organization_id, table_id, segment_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));

    const response = await axios.delete(
      `${REACT_APP_APIURL}/Lookup/${organization_id}/Segments/${table_id}/Item/${segment_id}`
    );
    const { data } = response;
    if (data) {
      await dispatch(getManageSegmentList(organization_id, table_id, true));
      await dispatch(getTableFields(organization_id, true));
      dispatch(getSegmentList(table_id));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Delete segment please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

const checkSegmentFilterValidation = (payload) => {
  let resError = {};
  resError.filters = [];
  if (!payload?.name || payload.name.trim() === '') {
    resError.name = 'Name is Required ';
  }
  payload?.filters?.forEach((item, index) => {
    const error = {};
    if (isEmpty(item?.field)) {
      error.field = 'Is Required';
    }
    if (isEmpty(item?.condition)) {
      error.condition = 'Is Required';
    }
    if (item?.condition?.valueType === 1 || item?.condition?.valueType === 4) {
      if (!item?.value || item.value.trim() === '') {
        error.value = 'Is Required';
      }
    } else if (item?.condition?.valueType === 2) {
      if (!item.value_Number || String(item.value_Number).trim() === '') {
        error.value = 'Is Required';
      }
    } else if (item?.condition?.valueType === 3) {
      if (!item.value_Date || item.value_Date.trim() === '') {
        error.value = 'Is Required';
      }
    } else if (item?.condition?.valueType === 5) {
      if (!item.value_Bool) {
        error.value = 'Is Required';
      }
    }
    if (!isEmpty(error)) resError.filters[index] = error;
  });
  if (!resError.name && isEmpty(resError.filters)) return {};
  else return resError;
};

const checkValidation = (obj) => {
  let resError = {};
  obj?.groupFields[0]?.fields
    ?.filter((item) => item?.isRequired)
    ?.forEach((item) => {
      const valudateResult = validateLookupFieldInput(item);
      if (valudateResult?.error) {
        resError[item.id] = valudateResult?.error;
      }
    });
  return resError;
};

/**
 * @desc Lookup - Get Lookup By Table
 * @param {*} table_id
 */
export const getLookupByTable = async (table_id) => {
  const lookups = await LookupCacheService.getInstance().getLookupsByTable(table_id);
  return lookups;
};

/**
 * @desc Lookup - Get Lookup Tags
 * @param {*} organization_id, table_id
 */
export const getLookupTags = (organization_id, table_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Lookup/${organization_id}/Tags/${table_id}`);
    const { data } = response;
    if (data) {
      dispatch(setLookupTags(data));
      return data;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to get Tags please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookups - Bulk Assign
 * @param {*} organization_id, payload
 */
export const bulkAssign = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${organization_id}/assign/bulk`, payload);
    const { data } = response;
    if (data) {
      dispatchLookupSucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to assign bulk please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookups - Bulk Un-Assign
 * @param {*} organization_id, payload
 */
export const bulkUnAssign = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${organization_id}/unassign/bulk`, payload);
    const { data } = response;
    if (data) {
      dispatchLookupSucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to bulk un-assign please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookups - Bulk Tags
 * @param {*} organization_id, payload
 */
export const bulkTags = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${organization_id}/tags/bulk`, payload);
    const { data } = response;
    if (data) {
      dispatchLookupSucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to tags bulk please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Transfer Lookup
 * @param {*} organization_id, payload
 */
export const transferLookup = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Lookup/${organization_id}/Transfer`, payload);
    const { data } = response;
    if (data) {
      dispatchLookupSucess(data?.message, dispatch);
      dispatch(getLookupList(organization_id));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Transfer Lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc Lookup - Get Lookup Fields
 * @param {*} organization_id, table_id
 */
export const getLookupFields = (organization_id, table_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/Lookup/${organization_id}/Fields/${table_id}`);
    const { data } = response;
    if (data) {
      dispatch(setLookupFields(data));
      return data;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to Lookup Fields please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

export const trackCollectionActionAnalyticActivity = (action, type) => {
  try {
    trackAnalyticActivity('collection: action click', { action: action, type: type });
  } catch (e) {
    console.log('track Inbox Error', e);
  }
};

export const trackQuickUpdateCollectionAnalyticActivity = (action, type) => {
  try {
    trackAnalyticActivity('collection: quick update', { action, type });
  } catch (e) {
    console.log('track Quick Update Collection Error', e);
  }
};

export const trackSortingCollectionAnalyticActivity = (type, columns, direction) => {
  try {
    trackAnalyticActivity('collection: sorting', { type, columns, direction });
  } catch (e) {
    console.log('track sorting Collection Error', e);
  }
};

export const trackRecordPerPageCollectionAnalyticActivity = (type, items) => {
  try {
    trackAnalyticActivity('collection: items per page changed', { type, items });
  } catch (e) {
    console.log('track items per page changed Collection Error', e);
  }
};

export const trackSegmentNaviagateCollectionAnalyticActivity = (type, name, count) => {
  try {
    trackAnalyticActivity('collection: segment navigated', { type, name, count });
  } catch (e) {
    console.log('track segment navigated Collection Error', e);
  }
};

export const trackSegmentAddedCollectionAnalyticActivity = (activityType, type, name) => {
  try {
    trackAnalyticActivity(`collection: segment ${activityType}`, { type, name });
  } catch (e) {
    console.log(`track segment ${activityType} Collection Error`, e);
  }
};

export const trackSegmentReorderedCollectionAnalyticActivity = (type, count) => {
  try {
    trackAnalyticActivity('collection: segment re-ordered', { type, 'segment count': count });
  } catch (e) {
    console.log('track segment re-ordered Collection Error', e);
  }
};

export const bulkActionCollectionAnalyticActivity = (action, type) => {
  try {
    trackAnalyticActivity('collection: bulk action', { action, type });
  } catch (e) {
    console.log('track bulk action Collection Error', e);
  }
};

export const getTableTypeForAnalytics = (table) => {
  if (table) {
    if (table?.type === LOOKUP_TYPE.contacts) return 'contact';
    else if (table?.type === LOOKUP_TYPE.companies) return 'company';
    else if (table?.type === LOOKUP_TYPE.deals) return 'deal';
    else if (table?.type === LOOKUP_TYPE.leads) return 'both';
    else if (table?.type === LOOKUP_TYPE.others) return 'other';
  }
  return undefined;
};

export const trackCustomerActionAnalytics = (type, action, source) => {
  try {
    trackAnalyticActivity(`${type}: ${action}`, { source });
  } catch (e) {
    console.log(`track ${type} ${action} Error`, e);
  }
};

export const trackContactDetailActionAnalytics = (type, action) => {
  try {
    trackAnalyticActivity(`${type} detail: action`, { action });
  } catch (e) {
    console.log(`track ${type} detail: action Error`, e);
  }
};

export const trackContactDetailAnalytics = (type, action, params) => {
  try {
    trackAnalyticActivity(`${type} detail: ${action}`, params ? params : undefined);
  } catch (e) {
    console.log(`track ${type} detail:${action} Error`, e);
  }
};

export const getSelectedLooupFromTableRows = (rows, organization_id) => {
  if (!organization_id) return false;
  const references = rows?.map((item) => {
    return {
      companyId: organization_id,
      email: item?.original?.email,
      id: item?.original?.id,
      name: item?.original?.name
    };
  });
  return references;
};

/**
 * @desc Lookup - Get All Lookup
 * @param {*}
 */
export const getAllLookup = () => async (dispatch) => {
  const lookups = await LookupCacheService.getInstance()?.getLookups();
  dispatch(setAllLookup(lookups));
};
/**

/**
 * @desc Lookup - Merge Lookup
 * @param {*} organization_id, payload
 */
export const mergeLookup = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Lookup/${organization_id}/merge`, payload);
    const { data } = response;
    if (data) {
      dispatchLookupSucess(data?.message, dispatch);
      await dispatch(getLookupList(organization_id));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to merge Lookup please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

/**
 * @desc phone - Get Lookups by phone number
 * @param {*}
 */
export const getLookupsByPhone = async (phone) => {
  const lookups = await LookupCacheService.getInstance()?.getLookups();
  const phoneLookups = lookups.filter(
    (x) => x.phone && (x.phone === phone || x.phone.endsWith(phone.substr(phone.length - 10)))
  );
  return phoneLookups;
};

/**
 * @desc Contact - Get Contact Enrichment
 * @param {*} company_id, payload
 */
export const getContactEnrichment = (company_id, payload, tableId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const lookupDetail = state?.lookup?.lookupDetail;
    const { groupFields } = lookupDetail;
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setLookupLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/lookup/${company_id}/enrichment`, payload);
    const { data } = response;
    if (data) {
      const fields = [].concat
        .apply(
          [],
          groupFields?.map((x) => x?.fields)
        )
        .filter((x) => x.type !== LookupFieldTypes.Lookup)
        .map((item) => {
          return { ...item, fieldOption: item?.options, options: undefined };
        });
      const newData = [];
      const item = {
        ...data?.person,
        ...data?.organization
      };
      //Make array from object
      if (item) {
        Object.keys(item).forEach((key) => {
          const isIdField = key?.endsWith('id');
          if (!isIdField && !isEmpty(item[key])) {
            const isObject = typeof item[key] === 'object';
            if (!isObject) {
              let value = {
                name: key,
                value: item[key]
              };
              newData.push(value);
            }
          }
        });
      }
      const enrichmentItem = await OrganizationPreferencesSingleton.getInstance().getSettingValue(
        tableId,
        ENRICH_FEILDS
      );
      let scFields;
      if (enrichmentItem) {
        scFields = enrichmentItem?.map((item) => fields?.find((x) => item?.scId === x?.id));
      }
      //mapping of match fields
      const enrichmentFields = newData?.map((item) => {
        let newField;
        let field;
        if (enrichmentItem) {
          const enrichItemIndex = enrichmentItem?.findIndex((y) => y?.enrichId === item?.name);
          if (enrichItemIndex !== -1) field = scFields[enrichItemIndex];
        } else {
          field = fields?.find(
            (x) =>
              String(item?.name).toLowerCase() === String(x?.customId).toLowerCase() ||
              String(item?.name).toLowerCase() === String(x?.name).toLowerCase()
          );
        }

        if (field) {
          let valueType = getLookupValueFromField(field);
          newField = {
            ...field,
            [valueType]: item?.value
          };
        }
        return { ...item, field: newField };
      });
      dispatch(setLookupEnrichmentFields(enrichmentFields));
      return true;
    }
  } catch (e) {
    dispatchLookupError(getAPIErrorReason(e) || 'Unable to get Contact Enrichment please try again', dispatch);
    return false;
  } finally {
    dispatch(setLookupLoader(false));
  }
};

function dispatchLookupError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchLookupSucess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}

function dispatchLookupValidationError(msg, dispatch) {
  dispatch(setLookupValidationError(msg));
}
