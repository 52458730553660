import React from 'react';
import FilePreviewer from 'react-file-previewer';
import moment from 'moment';
import Button from '../../../../components/atom/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import FileIcon from '../../../../components/FileIcon/FileIcon';
import {
  FileHeader,
  HeaderLeft,
  HeaderRight,
  DownloadLink,
  FileMain,
  FileText,
  Captiontitle,
  UserWrapper,
  UserName,
  UserUploadedDate,
  FileIconWrapper,
  CloseWrapper,
  FileWrapper,
  FileModal,
  File
} from './Styles';

const FilePreviewModal = (props) => {
  const file = props.location?.state?.file;
  // console.log(props);
  return (
    <FileModal>
      <FileHeader>
        <HeaderLeft>
          <FileMain>
            <FileIconWrapper>
              <FileIcon fileURL={file?.fileURL} />
            </FileIconWrapper>
            <FileText>
              <Captiontitle>{file?.title}</Captiontitle>
              <UserWrapper>
                <UserName>Uploaded by {file?.assignedUser?.name}</UserName>
                <UserUploadedDate>On {moment(file?.createdOn).format('Do MMMM')}.</UserUploadedDate>
              </UserWrapper>
            </FileText>
          </FileMain>
        </HeaderLeft>
        <HeaderRight>
          <DownloadLink href={file?.fileURL} target={'_blank'} rel='noreferrer'>
            <Button title='Download' type='button' />
          </DownloadLink>
          <CloseWrapper onClick={props.history.goBack}>
            <Icon name='icon-close' fontSize='20' color='var(--gray-more-icon)' />
          </CloseWrapper>
        </HeaderRight>
      </FileHeader>
      <File>
        <FileWrapper>
          <FilePreviewer
            hideControls={true}
            file={{
              url: file?.fileURL
            }}
          />
        </FileWrapper>
      </File>
    </FileModal>
  );
};
export default FilePreviewModal;
