import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import history from './history';
import PageNotFound from './Pages/PageNotFound';
import routes from './routes/routes-without-navbar';
import store from './store/store';
import { appInit } from './services/appService';
import SnackBar from './components/SnackBar/SnackBar';
import Calling from './components/Calling/Calling';
import { APP_HOST_TYPE, APP_INIT_RESPONSE_TYPE, PLATFORM_KEY_NAME, USER_PREFERENCES } from './constants/constant';
import PrivateRoutes from './components/PrivateRoutes';
import { fetchIPLocation } from './services/twilioService';
import { getAppHostType } from './helpers/common';
import SearchBar from './components/SearchBar/SearchBar';
import UpgradeToProModal from './Pages/UpgradeSubscription/UpgradeSubscription';

const App = () => {
  const [loading, setLoading] = useState(true);
  const storageEventListener = useCallback((event) => {
    try {
      if (event.storageArea === localStorage) {
        if (event.key === USER_PREFERENCES) {
          if (event.oldValue && event.newValue) {
            let prevOrganization = JSON.parse(event.oldValue);
            let newOrganization = JSON.parse(event.newValue);
            if (
              prevOrganization &&
              newOrganization &&
              prevOrganization?.organization?.id !== newOrganization?.organization?.id
            ) {
              window.location.reload();
            }
          } else if (!event.newValue) {
            window.location.reload();
          }
        }
      }
    } catch (e) {
      console.log('e', e);
    }
  }, []);

  const onAppMessage = useCallback((type, payload) => {
    if (type === 'app-notification-received') {
      //TODO:
    } else if (type === 'app-notification-clicked') {
      if (payload?.data?.url) {
        if (payload?.data?.url?.startsWith('https://dashboard.salescamp')) {
          const url = payload?.data?.url?.replace('https://dashboard.salescamp.app', '');
          history.push(url);
        } else {
          window.location.href = payload?.data?.url;
        }
      }
    }
  }, []);

  const initDesktopAppMessageListners = useCallback(() => {
    try {
      if (window?.salescampApp) {
        window.addEventListener('message', ({ data: inputPayload }) => {
          try {
            if (!inputPayload || typeof inputPayload !== 'string') return;

            const payload = JSON.parse(inputPayload);
            const { type, args } = payload || {};
            if (type === 'sc-desktop-app') {
              const { type: messageType, data: messageData } = args || {};
              onAppMessage(messageType, messageData);
            }
          } catch (e) {
            console.log('MESSAGe ERROR', e, inputPayload);
          }
        });
      }
    } catch (e) {
      console.log('ERROR', e);
    }
  }, [onAppMessage]);

  const initMobileAppMessageListners = useCallback(() => {
    try {
      if (window?.ReactNativeWebView?.postMessage) {
        window.getReactNativeMessage = (type, data) => {
          try {
            const payload = data || {};
            payload.data = payload.additionalData;
            onAppMessage(type, payload);
          } catch (e) {
            console.log(`ERROR: ${e.message}`);
          }
        };
      }
    } catch (e) {
      console.log('ERROR initMobileAppMessageListners', e);
    }
  }, [onAppMessage]);

  useEffect(() => {
    async function init() {
      try {
        fetchIPLocation();
        const result = await store.dispatch(appInit());
        if (result && result?.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
          history.push(result.path);
        }
      } catch (e) {
        console.log('ERROR', e);
      } finally {
        setLoading(false);
        const elLoader = document.getElementById('app_loader');
        if (elLoader) elLoader.style.display = 'none';
      }
    }

    init();
    window.addEventListener('storage', storageEventListener);
    return () => {
      try {
        window.removeEventListener('storage', storageEventListener);
      } catch (e) {}
    };
  }, [storageEventListener]);

  useEffect(() => {
    const search = window.location.search;
    const URLParams = new URLSearchParams(search);
    const platform = URLParams.get(PLATFORM_KEY_NAME);
    if (platform) localStorage.setItem(PLATFORM_KEY_NAME, platform);
  }, []);

  useEffect(() => {
    //Handle dektop and mobile app messages
    initDesktopAppMessageListners();
    initMobileAppMessageListners();
  }, [initDesktopAppMessageListners, initMobileAppMessageListners]);

  const header = useMemo(() => {
    const appHostType = getAppHostType();
    if (appHostType === APP_HOST_TYPE.GET_PEOPLE) {
      return (
        <Helmet>
          <title>Get People</title>
          <link rel='icon' type='image/png' href='/logo192-get-people.png' />
          <link rel='apple-touch-icon' href='/logo192-get-people.png' />
          <link rel='manifest' href='/manifest-get-people.json' />
        </Helmet>
      );
    }
    return (
      <Helmet>
        <title>Salescamp</title>
        <link rel='icon' type='image/png' href='/logo192.png' />
        <link rel='apple-touch-icon' href='/logo192.png' />
        <link rel='manifest' href='/manifest.json' />
      </Helmet>
    );
  }, []);

  if (loading) return null;

  const routeComponents = routes.map((r, i) => {
    if (r.private) {
      return <PrivateRoutes key={i} {...r} />;
    } else {
      return <Route key={i} {...r} />;
    }
  });
  return (
    <>
      {header}
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            {routeComponents}
            <Route component={PageNotFound} />
          </Switch>
        </Router>
        <Calling />
        <SnackBar />
        <SearchBar history={history} />
        <UpgradeToProModal history={history} />
      </Provider>
    </>
  );
};
export default App;
