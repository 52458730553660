import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVITY_ACTION, LOOKUP_TABLE_TYPE, TASK_LIST_TYPE } from '../../constants/constant';
import Button from '../../components/atom/Button/Button';
import Avtar from '../../components/Avtar/Avtar';
import { Menu, MenuItem } from '../../components/Menu';
import {
  deleteActivity,
  getActivityItem,
  getTaskActivityList,
  trackActivityActionAnalyticActivity,
  trackActivityAnalyticActivity,
  updateActivity,
  updateActivityAssignTo
} from '../../services/activityService';
import { clearActivityItem, setActivityValidationError, updateTaskActivityItem } from '../../actions/activityActions';
import DropDown from '../../components/DropDown/DropDown';
import { TaskFilterOption } from '../../data/raw';
import AddActivity from '../Contact/ContactDetail/AddActivity/AddActivity';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { hasUserAccessAndPermission } from '../../services/appService';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import Modal from '../../components/Modal';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import TaskEmptyImg from '../../assets/images/svg/empty_task.svg';
import OrganizationPreferencesSingleton from '../../helpers/OrganizationPreferencesSingleton';
import {
  Task,
  TaskWrapper,
  Header,
  HeaderTitel,
  TaskContainer,
  InputWrapper,
  InputType,
  InputSpan,
  AvtarWrapper,
  ActivityTitel,
  Wrapper,
  TaskchekList,
  Form,
  RightSide,
  Linkdiv,
  Lastdiv,
  NameSpan,
  IconView,
  PopupForm,
  DropdownView,
  DropLabel,
  InputWrapperTwo,
  StyledMenuItem,
  AvtarBox,
  SvgWrapper,
  MobileButton,
  SummarySpan,
  DcIconView,
  SpanWrapper,
  TaskEmptyWrap,
  TaskEmptyBlock,
  TaskEmptyTop,
  TaskEmptyImage,
  TaskEmptyHead,
  TaskSpan,
  LeftWrapper
} from './styles';
const TaskList = (props) => {
  const mobile = useMobileDevice();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isOpenAssignTo, setIsOpenAssignTo] = useState(false);
  const [isTaskFilterMenu, setIsTaskFilterMenu] = useState(false);
  const [taskFilterType, setTaskFilterType] = useState(TASK_LIST_TYPE.UNCOMPLETED);
  const [selectedFilterUser, setSelectedFilterUser] = useState();
  const [isOpenMoreOptionMenu, setMoreOptionMenu] = useState(false);
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [taskId, setTaskId] = useState();
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState();
  const [activityId, setActivityId] = useState();
  const [taskCurrentIndex, setTaskCurrentIndex] = useState();
  const dispatch = useDispatch();
  const activitySelector = useSelector((state) => state.activity);
  const organizationSelector = useSelector((state) => state.organization);
  const authSelector = useSelector((state) => state.auth);
  const { organization } = organizationSelector;
  const { id: organization_id, users } = organization;
  const { currentUser } = authSelector;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;
  const { taskActivityList } = activitySelector;
  const TASK_FILTER_SELECTION = 'taskFilterSelection';
  const companyUsers = useMemo(() => {
    let allUser = [{ id: '', name: 'All Users', email: '' }];
    allUser = allUser.concat(users);
    return allUser;
  }, [users]);

  const loadData = useCallback(
    (taskStatus, selectedUser) => {
      dispatch(getTaskActivityList(organization_id, taskStatus, selectedUser));
      trackActivityAnalyticActivity('loaded');
    },
    [dispatch, organization_id]
  );

  const filterData = useCallback(() => {
    let filterSelection = OrganizationPreferencesSingleton.getInstance().getSettingValue(
      'activity',
      TASK_FILTER_SELECTION
    );
    if (!filterSelection) {
      filterSelection = {
        user: currentUser?.id,
        taskStatus: TASK_LIST_TYPE.UNCOMPLETED
      };
    }
    const user = users?.find((x) => x?.id === filterSelection?.user);
    setSelectedFilterUser(user);
    setTaskFilterType(filterSelection?.taskStatus);
    loadData(filterSelection?.taskStatus, user);
  }, [currentUser, loadData, users]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const userTaskPermission = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('task', 'permissionEdit'));
    const permissionDelete = dispatch(hasUserAccessAndPermission('task', 'permissionDelete'));
    return {
      permissionEdit: permissionEdit,
      permissionDelete: permissionDelete
    };
  }, [dispatch]);

  const onOpenAssignTo = useCallback((e, activity_id, item, groupIndex, taskIndex) => {
    e.stopPropagation();
    setIsOpenAssignTo(true);
    setSelectedUser(item);
    setActivityId(activity_id);
    setTaskCurrentIndex({ groupIndex, taskIndex });
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const handleClickOnMoreOption = useCallback(async (e, task) => {
    setMenuAnchorEl(e.currentTarget);
    const activity = await getActivityItem(task?.id);
    setSelectedActivity(activity);
    setMoreOptionMenu(true);
  }, []);

  const onMoreOptionMenuClose = useCallback(() => {
    setMoreOptionMenu(false);
  }, []);

  const onAddActivityOpen = useCallback(() => {
    setIsAddActivityOpen(true);
  }, []);

  const onEditTask = useCallback(() => {
    if (!selectedActivity) return;
    onMoreOptionMenuClose();
    onAddActivityOpen();
  }, [onAddActivityOpen, onMoreOptionMenuClose, selectedActivity]);

  const onDeleteTask = useCallback(async () => {
    onMoreOptionMenuClose();
    const result = await dispatch(deleteActivity(organization_id, selectedActivity?.id, selectedActivity));
    if (result) {
      trackActivityAnalyticActivity('deleted');
      loadData(taskFilterType, selectedFilterUser);
    }
  }, [
    dispatch,
    loadData,
    onMoreOptionMenuClose,
    organization_id,
    selectedActivity,
    selectedFilterUser,
    taskFilterType
  ]);

  const onAddActivityClose = useCallback(() => {
    setIsAddActivityOpen(false);
    setMenuAnchorEl(null);
    setSelectedActivity(undefined);
    dispatch(clearActivityItem());
    dispatch(setActivityValidationError(null));
  }, [dispatch]);

  const onCloseAssignTo = useCallback(() => {
    setIsOpenAssignTo(false);
    setMenuAnchorEl(null);
    setTaskCurrentIndex();
    setActivityId();
  }, []);

  const onCloseTaskFilterMenu = useCallback(() => {
    setIsTaskFilterMenu(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenTaskFilterMenu = useCallback((e) => {
    setIsTaskFilterMenu(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const handleSummaryText = useCallback(
    (id) => {
      if (taskId && taskId === id) {
        id = undefined;
      }
      setTaskId(id);
    },
    [taskId]
  );

  const onClickUser = useCallback(
    async (item) => {
      setSelectedUser(item);
      dispatch(
        updateTaskActivityItem({
          propsName: 'assignedUser',
          value: item,
          groupIndex: taskCurrentIndex?.groupIndex,
          taskIndex: taskCurrentIndex?.taskIndex
        })
      );
      const reslut = dispatch(updateActivityAssignTo(organization_id, activityId, item));
      if (reslut) trackActivityAnalyticActivity('assigned');
      onCloseAssignTo();
    },
    [taskCurrentIndex, dispatch, organization_id, activityId, onCloseAssignTo]
  );

  const onChangeTaskStatus = useCallback(
    (activityId, value, groupIndex, taskIndex) => {
      dispatch(
        updateTaskActivityItem({
          propsName: 'taskStatus',
          value: !value,
          groupIndex,
          taskIndex
        })
      );
      const result = dispatch(updateActivity(organization_id, activityId, !value));
      if (result) trackActivityActionAnalyticActivity('mark as complete');
    },
    [dispatch, organization_id]
  );

  const getPath = useCallback(
    (lookup) => {
      const table = lookupTableList?.find((x) => x.id === lookup?.tableId);
      if (table) {
        const type = LOOKUP_TABLE_TYPE[table?.type];
        const pathName = `/${type}/${table?.name}/view/${lookup?.id}/overview`;
        return pathName;
      }
    },
    [lookupTableList]
  );

  const onClickName = useCallback(
    (lookup) => {
      const url = getPath(lookup);
      props.history.push(url);
    },
    [getPath, props.history]
  );

  const onSubmitFilter = useCallback(
    (e) => {
      const filterpayload = { user: selectedFilterUser?.id, taskStatus: taskFilterType };
      e.preventDefault();
      onCloseTaskFilterMenu();
      OrganizationPreferencesSingleton.getInstance().setSettingValue('activity', TASK_FILTER_SELECTION, filterpayload);
      loadData(taskFilterType, selectedFilterUser);
    },
    [selectedFilterUser, taskFilterType, onCloseTaskFilterMenu, loadData]
  );

  const onSave = useCallback(() => {
    loadData(taskFilterType, selectedFilterUser);
  }, [loadData, selectedFilterUser, taskFilterType]);

  return (
    <Task>
      <MobileTitleBar hasNavBar={true} hasTitle={true}>
        <IconView mobile={mobile}>
          <SvgWrapper onClick={onOpenTaskFilterMenu} margin>
            <SVGIcon name='icon-filter-sort' fill={mobile ? 'var(--dark-color)' : 'var(--gray-icon-color)'} size={24} />
          </SvgWrapper>
          {userTaskPermission?.permissionEdit && (
            <MobileButton
              iconName='icon-pluse'
              fontSize='14'
              iconColor='var(--white-color)'
              strokeWidth={2}
              size='small'
              onClick={onAddActivityOpen}
            />
          )}
        </IconView>
      </MobileTitleBar>
      {!taskActivityList || taskActivityList.length === 0 ? (
        <TaskEmptyWrap>
          <TaskEmptyBlock>
            <TaskEmptyTop>
              <TaskEmptyImage src={TaskEmptyImg}></TaskEmptyImage>
              <TaskEmptyHead>No task for you!</TaskEmptyHead>
              <TaskSpan>There is no any task for you. Learn more</TaskSpan>
            </TaskEmptyTop>
            {userTaskPermission?.permissionEdit && <Button title='Add New Task' onClick={onAddActivityOpen} />}
          </TaskEmptyBlock>
        </TaskEmptyWrap>
      ) : (
        <TaskWrapper mobile={mobile}>
          {mobile ? null : (
            <Header>
              <HeaderTitel>Task</HeaderTitel>
              <IconView>
                <SvgWrapper onClick={onOpenTaskFilterMenu} margin>
                  <SVGIcon name='icon-filter-sort' fill='var(--gray-icon-color)' size={24} />
                </SvgWrapper>
                {userTaskPermission?.permissionEdit && <Button title='New Task' onClick={onAddActivityOpen} />}
              </IconView>
            </Header>
          )}
          {taskActivityList?.map((item, index) => {
            return (
              <TaskContainer key={`${item?.display}-${index}`}>
                <ActivityTitel>
                  {item?.display} ({item?.list?.length})
                </ActivityTitel>
                {item?.list?.map((task, i) => {
                  return (
                    <Wrapper key={`task-${task?.id}`} mobile={mobile} isSummaryTextShow={taskId === task?.id}>
                      <Form mobile={mobile}>
                        <LeftWrapper>
                          <TaskchekList mobile={mobile}>
                            {userTaskPermission?.permissionEdit && (
                              <>
                                <input
                                  type='checkbox'
                                  checked={task?.taskStatus}
                                  onChange={() => {
                                    onChangeTaskStatus(task?.id, task?.taskStatus, index, i);
                                  }}
                                />
                                <span className='checkmark'></span>
                              </>
                            )}
                            <SpanWrapper
                              onClick={() => {
                                handleSummaryText(task?.id);
                              }}>
                              <span className='checkedmark'>{task?.title}</span>
                              {task?.description && (
                                <DcIconView>
                                  <SVGIcon name='icon-document' fill='var(--gray-more-icon)' size={18} />
                                </DcIconView>
                              )}
                            </SpanWrapper>
                          </TaskchekList>
                          <Linkdiv
                            mobile={mobile}
                            onClick={() => {
                              onClickName(task?.lookup);
                            }}>
                            <SVGIcon name='icon-link' fill='var(--gray-color)' size={16} strokeWidth={2}></SVGIcon>
                            <NameSpan mobile={mobile}> {task?.lookup?.name}</NameSpan>
                          </Linkdiv>
                        </LeftWrapper>
                        <RightSide>
                          <Lastdiv>
                            <AvtarBox
                              mobile={mobile}
                              title='Assign User'
                              onClick={(e) => {
                                trackActivityActionAnalyticActivity('assign');
                                onOpenAssignTo(e, task?.id, task?.assignedUser, index, i);
                              }}>
                              <Avtar
                                imgSrc={task?.assignedUser?.profile_photo}
                                name={task?.assignedUser?.name}
                                size={24}
                              />
                            </AvtarBox>
                            {(userTaskPermission?.permissionEdit || userTaskPermission?.permissionDelete) && (
                              <SvgWrapper
                                onClick={(e) => {
                                  handleClickOnMoreOption(e, task);
                                }}
                                title='More'>
                                <SVGIcon name='icon-more' fill='var(--gray-more-icon)' size={24} />
                              </SvgWrapper>
                            )}
                          </Lastdiv>
                        </RightSide>
                      </Form>
                      {taskId === task?.id && (
                        <SummarySpan
                          mobile={mobile}
                          dangerouslySetInnerHTML={{ __html: task?.description }}></SummarySpan>
                      )}
                    </Wrapper>
                  );
                })}
              </TaskContainer>
            );
          })}
        </TaskWrapper>
      )}
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenAssignTo}
        onClose={onCloseAssignTo}
        getContentAnchorEl={null}
        position='right'
        positionVertical='top'
        marginTop={33}
        width={180}>
        <InputWrapperTwo>
          {users?.map((item) => {
            return (
              <StyledMenuItem
                key={`${item?.id}`}
                onClick={
                  userTaskPermission?.permissionEdit
                    ? () => {
                        onClickUser(item);
                      }
                    : null
                }>
                <AvtarWrapper>
                  <Avtar imgSrc={item?.profile_photo} name={item?.name} size={20} />
                  <InputSpan>{item?.name}</InputSpan>
                </AvtarWrapper>
                <InputType>
                  <input type='checkbox' checked={selectedUser?.id === item?.id} onChange={() => {}} />
                  <span className='checkmark'></span>
                </InputType>
              </StyledMenuItem>
            );
          })}
        </InputWrapperTwo>
      </Menu>
      <Menu
        id='filter-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isTaskFilterMenu}
        onClose={onCloseTaskFilterMenu}
        getContentAnchorEl={null}
        position='right'
        overflow='inherit'
        positionVertical='top'
        marginTop={33}
        width={'260px'}>
        <InputWrapper>
          <PopupForm onSubmit={onSubmitFilter}>
            <DropdownView>
              <DropLabel>Show</DropLabel>
              <DropDown
                options={TaskFilterOption}
                labelField={'name'}
                valueField={'id'}
                placeholder={''}
                value={taskFilterType}
                onChange={(type) => {
                  setTaskFilterType(type?.id);
                }}
              />
            </DropdownView>
            <DropdownView>
              <DropLabel>Task assigned to </DropLabel>
              <DropDown
                options={companyUsers}
                labelField={'name'}
                valueField={'id'}
                placeholder={''}
                hasObject={true}
                value={selectedFilterUser}
                onChange={(item) => {
                  setSelectedFilterUser(item);
                }}
              />
            </DropdownView>
            <div>
              <Button title='submit' type='submit' />
            </div>
          </PopupForm>
        </InputWrapper>
      </Menu>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenMoreOptionMenu}
        onClose={onMoreOptionMenuClose}
        getContentAnchorEl={null}
        position='center'
        positionVertical='top'
        marginTop={24}>
        {userTaskPermission?.permissionEdit && (
          <MenuItem
            onClick={() => onEditTask()}
            text='Edit'
            iconName='icon-edit-pen'
            color='var(--gray-icon-color)'
            fontSize='18'
          />
        )}
        {userTaskPermission?.permissionDelete && (
          <MenuItem
            onClick={() => {
              trackActivityActionAnalyticActivity('delete');
              ConfirmDialog({
                iconName: 'icon-delete',
                closeIcon: 'icon-close',
                title: 'Delete',
                message: 'Are you sure want to delete this activity ?',
                onCancel: () => {
                  onMoreOptionMenuClose();
                },
                onOk: () => {
                  onDeleteTask();
                }
              });
            }}
            iconName='icon-delete'
            color='var(--gray-icon-color)'
            fontSize='18'
            text='Delete'
          />
        )}
      </Menu>
      <Modal open={isAddActivityOpen} onClose={onAddActivityClose}>
        <>
          {isAddActivityOpen && (
            <AddActivity
              type={ACTIVITY_ACTION.Tasks}
              selectedActivity={selectedActivity}
              lookup={props?.lookupDetails}
              onMenuClose={onAddActivityClose}
              onSave={onSave}
            />
          )}
        </>
      </Modal>
    </Task>
  );
};
export default TaskList;
