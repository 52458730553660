import { setActivityList } from '../actions/activityActions';
import { setCurrentUser } from '../actions/authActions';
import { clearEmailData, setEmailAccounts, setEmailList } from '../actions/emailActions';
import { setLookupTables, setTableFields } from '../actions/lookupTableActions';
import { clearOrganizationData, setCurrentOrganization } from '../actions/organizationActions';
import { clearSettingData } from '../actions/settingActions';
import OrganizationPreferencesSingleton from '../helpers/OrganizationPreferencesSingleton';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
import { clearLookupData } from '../actions/lookupActions';
import { clearDashboardData, setDataLoader } from '../actions/dashboardActions';
import { APP_INIT_RESPONSE_TYPE } from '../constants/constant';
import { clearNotificationData } from '../actions/notificationActions';
import { isDev } from '../helpers/common';
import { setupToken } from './../helpers/authTokenHelpers';
import ActivityCacheService from './activityCacheService';
import { getActivityListFromServer } from './activityService';
import EmailCacheService from './emailCacheServices';
import { getEmailAccounts, getEmailList } from './emailService';
import { getLookupTables, getTableFields } from './lookupTableServices';
import { getOrganizationDetails, getOrganizationList } from './organizationService';
import { getLookupList } from './lookupService';
import EmailAccountCacheService from './emailAccountCacheServices';
import { setLogUser } from './logService';
import { initAnalytics, setAnlayticUserAndCompany } from './analyticsService';
import TwilioService, { fetchIPLocation } from './twilioService';
import RealTimeService from './realTimeService';
import { getDashboardDetail } from './dashboardService';
import { getNotificationList } from './notificationServices';

export const appInit = () => async (dispatch) => {
  initAnalytics();
  const token = setupToken();
  if (token) {
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    if (userDetails) {
      setLogUser(userDetails);
    }
    dispatch(initPushNotification());

    let organizationDetails = UserPreferenceSingleton.getInstance().getOrganization();
    if (!organizationDetails) {
      await dispatch(getOrganizationList());
      organizationDetails = UserPreferenceSingleton.getInstance().getOrganization();
    }
    if (organizationDetails) {
      dispatch(setCurrentUser(userDetails));
      dispatch(setCurrentOrganization(organizationDetails));

      setAnlayticUserAndCompany(userDetails, organizationDetails);

      const awaitList = [dispatch(getOrganizationDetails(organizationDetails.id))];
      const noAwaitList = [];

      const tables = OrganizationPreferencesSingleton.getInstance().getTables();
      if (!tables || tables.length === 0) {
        awaitList.push(dispatch(getLookupTables(organizationDetails.id, true)));
      } else {
        noAwaitList.push(dispatch(getLookupTables(organizationDetails.id, true)));
        dispatch(setLookupTables(tables));
      }

      const fields = OrganizationPreferencesSingleton.getInstance().getFields();
      if (!fields || fields.length === 0) {
        awaitList.push(dispatch(getTableFields(organizationDetails.id, true)));
      } else {
        noAwaitList.push(dispatch(getTableFields(organizationDetails.id, true)));
        dispatch(setTableFields(fields));
      }

      const emailAccounts = await EmailAccountCacheService.getInstance()?.getEmailAccounts();
      dispatch(setEmailAccounts(emailAccounts));
      noAwaitList.push(dispatch(getEmailAccounts(organizationDetails.id)));

      const emailList = await EmailCacheService.getInstance()?.getEmails();
      dispatch(setEmailList(emailList));
      noAwaitList.push(dispatch(getEmailList(organizationDetails.id)));

      const activityList = await ActivityCacheService.getInstance()?.getActivities();
      dispatch(setActivityList(activityList));
      noAwaitList.push(dispatch(getActivityListFromServer(organizationDetails.id)));
      noAwaitList.push(dispatch(getLookupList(organizationDetails.id)));
      noAwaitList.push(dispatch(getNotificationList(organizationDetails.id)));
      try {
        try {
          dispatch(setDataLoader(true));
          await Promise.all(noAwaitList).then(() => {
            dispatch(getDashboardDetail(organizationDetails?.id));
            dispatch(setDataLoader(false));
          });
        } catch (e) {
          dispatch(setDataLoader(false));
        }
        await Promise.all(awaitList);
      } catch (e) {
        console.log('AppInit ERROR', e);
      }

      const initPhoneNumbersAndTwilio = async () => {
        const { getPhoneNumbers } = require('./settingService');
        await dispatch(getPhoneNumbers(organizationDetails.id, true));
        await fetchIPLocation();
        await dispatch(TwilioService.getInstance().initCallingService());
        dispatch(RealTimeService.getInstance().init());
      };
      initPhoneNumbersAndTwilio();

      return { type: APP_INIT_RESPONSE_TYPE.SUCCESS };
    } else {
      setAnlayticUserAndCompany(userDetails);
      return { type: APP_INIT_RESPONSE_TYPE.REDIRECT, path: '/onboarding' };
    }
  }
};

export const clearOrgReduxData = () => async (dispatch) => {
  dispatch(clearOrganizationData());
  dispatch(clearDashboardData());
  dispatch(clearLookupData());
  dispatch(clearEmailData());
  dispatch(clearSettingData());
  dispatch(clearNotificationData());
};

/**
 * @desc  Is user has the particular page/module access for navigation
 */
export const hasPageViewAccess = (moduleName, role, tableName) => (dispatch, getState) => {
  const state = getState();

  const userAccess = state?.organization?.organization?.settings?.access || {};
  const roleModuleAccess = userAccess[moduleName];
  if (roleModuleAccess) {
    if (moduleName === 'tables') {
      const table = state?.lookupTables?.list?.find((x) => x.name === tableName);
      if (table) {
        const tableAccess = roleModuleAccess.find((x) => x.tableId === table?.id);
        return tableAccess?.isAccess;
      }
    } else {
      return roleModuleAccess?.isAccess;
    }
  } else if (role === 'admin') {
    const isAdmin = state?.organization?.organization?.isAdmin;
    return isAdmin;
  }
  return true;
};

/**
 * @desc  Is user has the particular page/module access and permission for Edit, Delete and Export
 */
export const hasUserAccessAndPermission = (moduleName, propName, tableName) => (dispatch, getState) => {
  const state = getState();
  const userAccess = state?.organization?.organization?.settings?.access || {};
  const roleModuleAccess = userAccess[moduleName];
  if (roleModuleAccess) {
    if (moduleName === 'tables') {
      const table = state?.lookupTables?.list?.find((x) => x.name === tableName);
      if (table) {
        const tableAccess = roleModuleAccess.find((x) => x.tableId === table?.id);
        return tableAccess?.[propName];
      }
    } else {
      return roleModuleAccess?.[propName];
    }
  }
};

const initPushNotification = () => (dispatch, getState) => {
  try {
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    if (!userDetails) return;

    const userTagPayload = {
      email: userDetails.email,
      userid: userDetails.id
    };

    initDesktopAppMessageListners(userTagPayload);
    initMobileAppMessageListners(userTagPayload);

    const OneSignal = window.OneSignal;
    if (!OneSignal || !OneSignal.sendTags) return;

    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (!org) return;

    try {
      OneSignal.sendTags(userTagPayload, function (tagsSent) {
        // Callback called when tags have finished sending
      });
    } catch (e) {
      console.log('Error in OneSignle-sendTags', e);
    }

    try {
      OneSignal.setDefaultNotificationUrl('https://dashboard.salescamp.app');
    } catch (e) {
      console.log('Error in OneSignle-GetPushNotificationPermission', e);
    }

    OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
      if (isEnabled) console.log('Push notifications are enabled!');
      else console.log('Push notifications are not enabled yet.');
    });

    OneSignal.on('notificationDisplay', function (event) {
      console.log('New notification Received', event);
      dispatch(getNotificationList(org.id));
    });
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const removeFromPushNotification = () => {
  try {
    if (window?.salescampApp?.sendCommand) window?.salescampApp?.sendCommand('unregister-push-notification');
    if (window?.ReactNativeWebView?.postMessage)
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'unregister-push-notification' }));

    const OneSignal = window.OneSignal;
    if (!OneSignal || !OneSignal.sendTag) return;
    OneSignal.sendTag('userid', null);
  } catch (e) {
    console.log('ERROR', e);
  }
};

const initDesktopAppMessageListners = (userTagPayload) => {
  try {
    if (window?.salescampApp) {
      if (window?.salescampApp?.sendCommand) {
        window?.salescampApp?.sendCommand('init-push-notification', userTagPayload);
      }
    }
  } catch (e) {
    console.log('ERROR', e);
  }
};

const initMobileAppMessageListners = (userTagPayload) => {
  try {
    if (window?.ReactNativeWebView?.postMessage) {
      if (isDev()) {
        // eslint-disable-next-line max-len
        const consoleLog = (type, log) =>
          window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'Console', data: { type: type, log: log } }));
        window.console = {
          log: (log) => consoleLog('log', log),
          debug: (log) => consoleLog('debug', log),
          info: (log) => consoleLog('info', log),
          warn: (log) => consoleLog('warn', log),
          error: (log) => consoleLog('error', log)
        };
      }
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'init-push-notification', data: userTagPayload }));
    }
  } catch (e) {
    console.log('ERROR initMobileAppMessageListners', e);
  }
};
