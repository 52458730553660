import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../components/Modal';
import { ACTIVITY_ACTION, ACTIVITY_ACTION_VALUE, SEND_EMAIL_TYPE } from '../../../../constants/constant';
import { Menu, MenuItem } from '../../../../components/Menu';
import AddActivity from '../AddActivity/AddActivity';
import { deleteActivity, getActivityByLookup, getActivityItem } from '../../../../services/activityService';
import {
  clearActivityItem,
  setActivityByLookup,
  setActivityValidationError
} from '../../../../actions/activityActions';
import { ConfirmDialog } from '../../../../components/ConfirmDialog';
import { hasUserAccessAndPermission } from '../../../../services/appService';
import EmailDetailPreview from '../EmailDetailPreview/EmailDetailPreview';
import { setEmailDetail } from '../../../../actions/emailActions';
import SendEmailModal from '../../../Email/SendEmailModal/SendEmailModal';
import { getTableTypeForAnalytics, trackContactDetailActionAnalytics } from '../../../../services/lookupService';
import ContactActivityEmptyState from '../ContactActivityEmptyState/ContactActivityEmptyState';
import { setForwardMailItem, setReplyMailItem } from '../../../../services/emailService';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import ActivityItem from './ActivityItem';
import {
  AddIcon,
  Atag,
  HeaderView,
  Li2,
  MenuList,
  RightContain,
  TitleText,
  Ul2,
  UpdateView,
  RightContainWrapper,
  ActivityWrapper,
  ActivitiesWrapper,
  MenuItemWrapper,
  HeaderLinearBg,
  ActivityMainWrap
  // MoreIconBlock
} from './styles';
const ContactActivity = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const lookup_id = props.match.params.id || props?.lookupDetails?.id;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [isNewMenuOpen, setIsNewMenuOpen] = useState(false);
  const [isOpenMoreOptionMenu, setMoreOptionMenu] = useState(false);
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [isOpenEmailDetailModal, setIsOpenEmailDetailModal] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [hasMoreValue, setHasMoreValue] = useState(true);
  const activityContainerEl = useRef(null);
  const [page, setPage] = useState(1);
  const [upcomingActivities, setUpcomingActivities] = useState([]);
  const [pastActivities, setPastActivities] = useState([]);
  const [isShowCommentInput, setIsShowCommentInput] = useState(false);
  const [sendMailType, setSendMailType] = useState();

  const [addActivity, setAddActivity] = useState();
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [selectedAction, setSelectedAction] = useState(0);
  const dispatch = useDispatch();
  const activitySelector = useSelector((state) => state.activity);
  const { activity: activities } = activitySelector;

  const loadData = useCallback(() => {
    dispatch(getActivityByLookup(organization_id, lookup_id));
  }, [dispatch, lookup_id, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(setActivityByLookup(null));
    };
  }, [dispatch]);

  const tableTypeForAnalytics = useMemo(() => {
    if (props?.table) {
      const type = getTableTypeForAnalytics(props?.table);
      return type;
    }
  }, [props?.table]);

  const userPermission = useMemo(() => {
    const permissionEditTask = dispatch(hasUserAccessAndPermission('task', 'permissionEdit'));
    return {
      permissionEditTask: permissionEditTask
    };
  }, [dispatch]);

  const onNewMenuOpen = useCallback((e) => {
    e.stopPropagation();
    setIsNewMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onNewMenuClose = useCallback(() => {
    setIsNewMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenEmailDetailModal = useCallback((e, item) => {
    e.stopPropagation();
    setIsOpenEmailDetailModal(true);
    setCurrentEmail(item);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseEmailDetailModal = useCallback(() => {
    setIsOpenEmailDetailModal(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenFilePreview = useCallback(
    (e, file) => {
      e.stopPropagation();
      props.history.push({
        pathname: '/file-preview',
        state: { file }
      });
    },
    [props.history]
  );

  const onAddActivityOpen = useCallback(
    (type) => {
      setAddActivity(type);
      setIsAddActivityOpen(true);
      onNewMenuClose();
    },
    [onNewMenuClose]
  );

  const onAddActivityClose = useCallback(() => {
    setIsAddActivityOpen(false);
    setMenuAnchorEl(null);
    setSelectedActivity(undefined);
    dispatch(clearActivityItem());
    dispatch(setActivityValidationError(null));
  }, [dispatch]);

  const uniqeActions = useMemo(() => {
    const uniqeActivities = [].concat
      .apply(
        [],
        activities?.map((x) => {
          return { action: x?.action, name: ACTIVITY_ACTION_VALUE[x?.action] || x?.actionName };
        })
      )
      .filter((value, index, self) => self.findIndex((x) => x?.action === value?.action) === index);
    return uniqeActivities;
  }, [activities]);

  const emails = useMemo(() => {
    const filteredActivityList = activities?.filter((x) => x.action === ACTIVITY_ACTION.Email);
    return filteredActivityList || [];
  }, [activities]);

  const getFilterData = useCallback(() => {
    const filteredItems = activities?.filter((x) => selectedAction === 0 || x.action === selectedAction);
    setPage(1);
    setPastActivities([]);
    setUpcomingActivities([]);
    setHasMoreValue(filteredItems?.length > 0);
    return filteredItems;
  }, [activities, selectedAction]);

  const fetchMoreListItems = useCallback(
    (refresh = false) => {
      if (!hasMoreValue || !refresh) return;
      const items = getFilterData();
      const limit = 25;
      const offset = page * limit;
      const data = items?.slice(0, offset);
      const upcomingActivityList = data?.filter(
        (x) => !x?.taskStatus && (selectedAction === 0 || x.action === selectedAction)
      );
      const pastActivityList = data?.filter(
        (x) => x?.taskStatus && (selectedAction === 0 || x.action === selectedAction)
      );
      setUpcomingActivities(upcomingActivityList);
      setPastActivities(pastActivityList);
      setPage(page + 1);
      setHasMoreValue(items?.length !== data?.length);
    },
    [getFilterData, hasMoreValue, page, selectedAction]
  );

  useEffect(() => {
    if (!activities) return;
    setPage(1);
    setHasMoreValue(true);
    fetchMoreListItems(page === 1 ? true : false);
    const search = window.location.search;
    const URLParams = new URLSearchParams(search);
    const activityId = URLParams.get('activity');
    if (activityId) {
      document.getElementById(`activity_${activityId}`)?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  useEffect(() => {
    fetchMoreListItems(page === 1 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleScroll = () => {
    if (Math.ceil(activityContainerEl?.current?.scrollTop) <= activityContainerEl?.current?.scrollHeight * 0.5) return;
    fetchMoreListItems(true);
  };

  const activityLength = useMemo(() => {
    const upcomingActivity = activities?.filter((x) => !x?.taskStatus);
    const pastActivity = activities?.filter((x) => x?.taskStatus);
    return { upcomingActivity: upcomingActivity?.length, pastActivity: pastActivity?.length };
  }, [activities]);

  const handleClickOnMoreOptionActivity = useCallback(
    async (e, item) => {
      setMenuAnchorEl(e.currentTarget);
      const activity = await getActivityItem(item?.id);
      setSelectedActivity(activity);
      setMoreOptionMenu(true);
      if (isShowCommentInput) {
        setIsShowCommentInput(false);
      }
    },
    [isShowCommentInput]
  );

  const onMoreOptionMenuClose = useCallback(() => {
    setMoreOptionMenu(false);
  }, []);

  const handleClickOnEdit = useCallback(() => {
    if (!selectedActivity) return;
    onMoreOptionMenuClose();
    onAddActivityOpen(selectedActivity?.action);
  }, [onAddActivityOpen, onMoreOptionMenuClose, selectedActivity]);

  const onDeleteActivity = useCallback(async () => {
    onMoreOptionMenuClose();
    setMenuAnchorEl(null);
    setSelectedActivity(undefined);
    const result = await dispatch(deleteActivity(organization_id, selectedActivity?.id, selectedActivity));
    if (result) {
      dispatch(getActivityByLookup(organization_id, lookup_id));
    }
  }, [dispatch, lookup_id, onMoreOptionMenuClose, organization_id, selectedActivity]);

  const openSendEmailModal = useCallback(
    async (item, type) => {
      onCloseEmailDetailModal();
      setIsOpenSendEmailModal(true);
      setSendMailType(type);
      await dispatch(setEmailDetail(item));
      if (type === SEND_EMAIL_TYPE.REPLY) {
        dispatch(setReplyMailItem(item));
      }
      if (type === SEND_EMAIL_TYPE.FORWARD) {
        dispatch(setForwardMailItem(item));
      }
    },
    [dispatch, onCloseEmailDetailModal]
  );

  const closeSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(false);
    setSendMailType();
  }, []);

  const onSend = useCallback(() => {
    loadData();
  }, [loadData]);

  const onClickNewActivity = useCallback(
    (e) => {
      trackContactDetailActionAnalytics(tableTypeForAnalytics, 'new activity');
      setSelectedActivity(undefined);
      onNewMenuOpen(e);
    },
    [onNewMenuOpen, tableTypeForAnalytics]
  );

  const onClickAction = useCallback(
    (action) => {
      setSelectedAction(action);
      const filteredItems = activities?.filter((x) => action === 0 || x.action === action);
      setPage(1);
      setPastActivities([]);
      setUpcomingActivities([]);
      setHasMoreValue(filteredItems?.length > 0);
    },
    [activities]
  );

  const uniqueActionView = useMemo(() => {
    return uniqeActions?.map((item, index) => {
      return (
        <Li2 key={index} mobile={mobile}>
          <Atag
            onClick={() => {
              onClickAction(item?.action);
            }}
            className={selectedAction === item?.action && 'active'}>
            {item?.name}
          </Atag>
        </Li2>
      );
    });
  }, [mobile, onClickAction, selectedAction, uniqeActions]);

  const menuListView = useMemo(() => {
    return (
      <MenuList mobile={mobile}>
        <Ul2 mobile={mobile}>
          {activities && activities?.length !== 0 && (
            <Li2 mobile={mobile}>
              <Atag
                onClick={() => {
                  onClickAction(0);
                }}
                className={selectedAction === 0 && 'active'}>
                {ACTIVITY_ACTION_VALUE[0]}
              </Atag>
            </Li2>
          )}
          {uniqueActionView}
        </Ul2>
      </MenuList>
    );
  }, [activities, mobile, onClickAction, selectedAction, uniqueActionView]);

  const upcomingActivitiesItemsView = useMemo(() => {
    return upcomingActivities?.map((x, index) => {
      return (
        <ActivitiesWrapper key={`upcoming_activity_${x?.id}_${index}`} id={`activity_${x?.id}`}>
          <ActivityItem
            item={x}
            isOpenThread={false}
            isOpenMoreOptionMenu={isOpenMoreOptionMenu}
            onMoreMenuClick={handleClickOnMoreOptionActivity}
            onOpenEmailDetailModal={onOpenEmailDetailModal}
            onOpenFilePreview={onOpenFilePreview}
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
          />
          {/* {renderActivities(x)} */}
        </ActivitiesWrapper>
      );
    });
  }, [
    handleClickOnMoreOptionActivity,
    isOpenMoreOptionMenu,
    onOpenEmailDetailModal,
    onOpenFilePreview,
    selectedActivity,
    upcomingActivities
  ]);

  const upcomingActivitiesView = useMemo(() => {
    if (upcomingActivities?.length > 0)
      return (
        <>
          <UpdateView mobile={mobile}>
            <TitleText>
              Upcoming ({selectedAction === 0 ? activityLength?.upcomingActivity : upcomingActivities?.length})
            </TitleText>
          </UpdateView>
          {upcomingActivitiesItemsView}
        </>
      );
    return null;
  }, [
    activityLength?.upcomingActivity,
    mobile,
    selectedAction,
    upcomingActivities?.length,
    upcomingActivitiesItemsView
  ]);

  const addActivityIconView = useMemo(() => {
    if (userPermission?.permissionEditTask && activities && activities?.length !== 0)
      return (
        <AddIcon
          onClick={(e) => {
            onClickNewActivity(e);
          }}
          title={'add-activity'}>
          <SVGIcon fill='var(--white-color)' size={14} name='icon-pluse' strokeWidth={2} />
        </AddIcon>
      );
    return null;
  }, [activities, onClickNewActivity, userPermission?.permissionEditTask]);

  const pastActivitiesItemsView = useMemo(() => {
    return pastActivities?.map((x, index) => {
      return (
        <ActivitiesWrapper key={`past_activity_${x?.id}_${index}`} id={`activity_${x?.id}`}>
          <ActivityItem
            item={x}
            isOpenMoreOptionMenu={isOpenMoreOptionMenu}
            onMoreMenuClick={handleClickOnMoreOptionActivity}
            onOpenEmailDetailModal={onOpenEmailDetailModal}
            onOpenFilePreview={onOpenFilePreview}
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
          />
          {/* {renderActivities(x)} */}
        </ActivitiesWrapper>
      );
    });
  }, [
    handleClickOnMoreOptionActivity,
    isOpenMoreOptionMenu,
    onOpenEmailDetailModal,
    onOpenFilePreview,
    pastActivities,
    selectedActivity
  ]);

  const pastActivitiesView = useMemo(() => {
    if (pastActivities?.length > 0)
      return (
        <>
          <UpdateView mobile={mobile}>
            <TitleText>Past ({selectedAction === 0 ? activityLength?.pastActivity : pastActivities?.length})</TitleText>
          </UpdateView>

          {pastActivitiesItemsView}
        </>
      );
  }, [activityLength?.pastActivity, mobile, pastActivities?.length, pastActivitiesItemsView, selectedAction]);

  const activitiesView = useMemo(() => {
    if (activities)
      return activities?.length === 0 ? (
        <ContactActivityEmptyState onClickAddActivity={onClickNewActivity} />
      ) : (
        <RightContainWrapper mobile={mobile}>
          <ActivityWrapper>
            <HeaderView mobile={mobile}>
              {mobile && <HeaderLinearBg></HeaderLinearBg>}
              {menuListView}
              {addActivityIconView}
            </HeaderView>
            <ActivityMainWrap mobile={mobile}>
              {upcomingActivitiesView}
              {pastActivitiesView}
            </ActivityMainWrap>
          </ActivityWrapper>
        </RightContainWrapper>
      );
    return null;
  }, [
    activities,
    addActivityIconView,
    menuListView,
    mobile,
    onClickNewActivity,
    pastActivitiesView,
    upcomingActivitiesView
  ]);

  return (
    <>
      <RightContain
        onScroll={handleScroll}
        ref={activityContainerEl}
        mobile={mobile}
        Empty={activities && activities?.length === 0}>
        {activitiesView}
      </RightContain>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isNewMenuOpen}
        onClose={onNewMenuClose}
        getContentAnchorEl={null}
        position='right'
        marginTop={45}
        positionVertical='top'
        width={180}>
        <MenuItem
          onClick={() => onAddActivityOpen(ACTIVITY_ACTION.Notes)}
          iconName='icon-add-notes'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Add Note'
        />
        <MenuItem
          onClick={() => onAddActivityOpen(ACTIVITY_ACTION.Tasks)}
          iconName='icon-task'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Add Task'
        />
        <MenuItem
          onClick={() => onAddActivityOpen(ACTIVITY_ACTION.Meeting)}
          iconName='icon-create-meeting'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Create Meeting'
        />
        <MenuItem
          onClick={() => onAddActivityOpen(ACTIVITY_ACTION.Call)}
          iconName='icon-call'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Log call'
        />
        <MenuItem
          onClick={() => onAddActivityOpen(ACTIVITY_ACTION.File)}
          iconName='icon-attachment'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Add file'
        />
        <MenuItem
          onClick={() => onAddActivityOpen(ACTIVITY_ACTION.Comment)}
          iconName='icon-message'
          fontSize='18'
          color='var(--gray-icon-color)'
          text='Add comment'
        />
      </Menu>
      <Modal
        anchorEl={menuAnchorEl}
        keepMounted
        open={isAddActivityOpen}
        onClose={onAddActivityClose}
        getContentAnchorEl={null}
        position='center'
        positionVertical='center'
        style={{
          overflow: addActivity === ACTIVITY_ACTION.Comment ? 'unset' : 'hidden'
        }}
        MenuListProps={{
          style: {
            padding: 0
          }
        }}>
        <>
          {isAddActivityOpen && (
            <AddActivity
              type={addActivity}
              selectedActivity={selectedActivity}
              lookup={props?.lookupDetails}
              onMenuClose={onAddActivityClose}
            />
          )}
        </>
      </Modal>
      <Modal open={isOpenEmailDetailModal} onClose={onCloseEmailDetailModal}>
        <>
          {isOpenEmailDetailModal && (
            <EmailDetailPreview currentEmail={currentEmail} emails={emails} onOpen={openSendEmailModal} />
          )}
        </>
      </Modal>
      <Modal open={isOpenSendEmailModal} onClose={closeSendEmailModal}>
        <SendEmailModal handleCloseModal={closeSendEmailModal} onSend={onSend} type={sendMailType} />
      </Modal>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isOpenMoreOptionMenu}
        onClose={onMoreOptionMenuClose}
        getContentAnchorEl={null}
        marginTop={20}
        width={112}
        MenuListProps={{ disablePadding: true }}
        positionVertical='top'
        position='Left'>
        <MenuItemWrapper>
          {selectedActivity?.action !== ACTIVITY_ACTION.Sms && (
            <MenuItem
              onClick={() => handleClickOnEdit()}
              text='Edit'
              iconName='icon-edit-pen'
              color='var(--gray-icon-color)'
              fontSize='18'
            />
          )}
          <MenuItem
            onClick={() => {
              ConfirmDialog({
                iconName: 'icon-delete',
                closeIcon: 'icon-close',
                title: 'Delete',
                message: `Are you sure want to delete this activity?`,
                onCancel: () => {
                  onMoreOptionMenuClose();
                },
                onOk: () => {
                  onDeleteActivity();
                }
              });
            }}
            iconName='icon-delete'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Delete'
          />
        </MenuItemWrapper>
      </Menu>
    </>
  );
};

export default ContactActivity;
