import React, { useCallback, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { createSalescampSubscription, createStripeSubscription } from '../../../../services/settingService';
import SnackBar from '../../../../components/SnackBar/SnackBar';
import { setErrorMessage } from '../../../../actions/messageActions';
import Button from '../../../../components/atom/Button/Button';
import UserPreferenceSingleton from './../../../../helpers/UserPreferenceSingleton';
import { ButtonWrapper, Form } from './styles';

const StripeCheckoutForm = ({ plan, userCount }) => {
  const stripe = useStripe();
  const [cardError, setCardError] = useState();
  const [isValidCard, setIsValidCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const elements = useElements();

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    try {
      // Block native form submission.
      event.preventDefault();

      if (cardError) {
        dispatch(setErrorMessage(cardError?.message));
        return;
      }

      if (!stripe || !elements || !isValidCard) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      if (!plan) {
        dispatch(setErrorMessage('Please select plan'));
        return;
      }
      setLoading(true);
      const user = UserPreferenceSingleton.getInstance().getCurrentUser();
      const organization = UserPreferenceSingleton.getInstance().getOrganization();
      if (organization && user && plan && cardElement) {
        const payload = {
          priceId: plan.productId,
          quantity: userCount,
          companyId: organization.id,
          companyName: organization.name,
          planName: plan.planName
        };
        const stripSubscriptionDetails = await dispatch(createStripeSubscription(payload));
        var stripeClientSecret = stripSubscriptionDetails.clientSecret;
        var paymentResult = await stripe.confirmCardPayment(stripeClientSecret, {
          payment_method: {
            card: cardElement
          }
        });

        if (paymentResult && paymentResult.error) {
          dispatch(setErrorMessage(paymentResult?.error?.message));
        } else {
          let model = {
            companyId: organization.id,
            subscriptionType: plan.subscriptionType,
            paymentMode: 'stripe',
            planId: plan.id,
            paymentId: stripSubscriptionDetails.subscriptionId,
            qty: userCount,
            extra: JSON.stringify(paymentResult)
          };

          try {
            const subResult = await dispatch(createSalescampSubscription(model));
            if (subResult) window.location.href = '/payment?success=true';
            else window.location.href = '/payment?success=false';
          } catch (e) {
            dispatch(setErrorMessage(e.message));
          }
        }
      }
    } catch (e) {
      dispatch(setErrorMessage(e.message || 'Unable to load payment gateway.Please try again'));
    } finally {
      setLoading(false);
    }
  };

  const onChange = useCallback((event) => {
    setIsValidCard(event.complete);
    setCardError(event.error);
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <CardElement
        onChange={onChange}
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#9e2146'
            }
          }
        }}
      />
      <ButtonWrapper>
        <Button title='Subscribe' type='submit' disabled={!stripe} loading={loading} />
      </ButtonWrapper>
      <SnackBar />
    </Form>
  );
};
export default StripeCheckoutForm;
