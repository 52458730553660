import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import { hasUserAccessAndPermission } from '../../../services/appService';
import {
  getTableTypeForAnalytics,
  trackCollectionActionAnalyticActivity,
  trackQuickUpdateCollectionAnalyticActivity
} from '../../../services/lookupService';
import {
  DATE_FORMAT,
  LookupFieldTypes,
  LOOKUP_FIELD_EDIT_VIEW_FROM,
  LOOKUP_TYPE,
  TIME_FORMAT
} from '../../../constants/constant';
import { Actions, Item, LinkField } from './Styles';
import LookupFieldInputForm from './LookupFieldInputForm';

const LookupListFieldViewEditForm = (props) => {
  const {
    cell: {
      column: { field },
      row: {
        original: {
          id: lookup_id,
          fields: { [field?.id]: fieldValue },
          tableId
        },
        original: item
      },
      value: value_formatted
    }
  } = props;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;

  const table = useMemo(() => {
    const currentTable = lookupTableList?.find((x) => x?.id === tableId);
    return currentTable;
  }, [lookupTableList, tableId]);

  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams();
  const tableName = params?.name;

  const userAccess = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', tableName));

    return {
      permissionEdit
    };
  }, [dispatch, tableName]);

  const onEdit = () => {
    if (!userAccess?.permissionEdit) return;
    if (!field.isNonEditable) {
      const type = getTableTypeForAnalytics(table);
      trackCollectionActionAnalyticActivity('quick edit', type);
      setIsEdit(true);
    }
  };

  const onCancelEdit = () => {
    setIsEdit(false);
  };

  const onUpdated = (output) => {
    setIsEdit(false);
    const type = getTableTypeForAnalytics(table);
    trackQuickUpdateCollectionAnalyticActivity(output?.value?.customId, type);
    if (props.onUpdated) props.onUpdated(output);
  };
  const getTableType = (item) => {
    switch (item?.type) {
      case LOOKUP_TYPE.contacts:
        return 'cn';
      case LOOKUP_TYPE.companies:
        return 'cm';
      case LOOKUP_TYPE.deals:
        return 'dl';
      case LOOKUP_TYPE.others:
        return 'others';
      default:
        return '';
    }
  };

  const renderDisplayListValue = useMemo(() => {
    const value = value_formatted || '';
    switch (field?.type) {
      case LookupFieldTypes.Date:
        return <span>{value ? moment(value).format(DATE_FORMAT) : null}</span>;
      case LookupFieldTypes.Time:
        return <span>{value ? moment(value).format(TIME_FORMAT) : null}</span>;
      case LookupFieldTypes.Url:
        return (
          <LinkField to={{ pathname: item[field?.id] }} target={'_blank'}>
            {value}
          </LinkField>
        );
      case LookupFieldTypes.Lookup:
        if (field?.lookupTableId !== 'users') {
          if (field?.type === LookupFieldTypes.Lookup && field?.isMultiSelection) {
            const lookupLength = item?.fields[field.id]?.value_MultiLookup?.length;
            return (
              <>
                {item?.fields[field.id]?.value_MultiLookup?.map((multilookup, index) => {
                  let tableType;
                  const tableItem = lookupTableList?.find((y) => y?.id === multilookup?.tableId);
                  if (tableItem) tableType = getTableType(tableItem);
                  return (
                    <Actions
                      key={`multilookup_${multilookup?.id}_${index}`}
                      onClick={() => {
                        history.push(`/${tableType}/${tableItem?.name}/view/${multilookup?.id}/overview`);
                      }}>
                      {multilookup?.name}
                      {index !== lookupLength - 1 ? ',' : null}
                    </Actions>
                  );
                })}
              </>
            );
          } else {
            const lookup = item?.fields[field.id]?.value_Lookup;
            let tableType;
            const tableItem = lookupTableList?.find((y) => y?.id === lookup?.tableId);
            if (tableItem) tableType = getTableType(tableItem);
            return (
              <Actions
                onClick={() => {
                  history.push(`/${tableType}/${tableItem?.name}/view/${lookup?.id}/overview`);
                }}>
                {value}
              </Actions>
            );
          }
        } else {
          return value;
        }

      default:
        return value;
    }
  }, [field, item, value_formatted, lookupTableList, history]);

  return (
    <>
      {!isEdit ? (
        <Item onDoubleClick={onEdit}>{renderDisplayListValue}</Item>
      ) : (
        <LookupFieldInputForm
          tableId={tableId}
          showEditLabel={false}
          viewType={LOOKUP_FIELD_EDIT_VIEW_FROM.LIST}
          lookup_id={lookup_id}
          field={field}
          fieldValue={fieldValue}
          onCancel={onCancelEdit}
          onUpdated={onUpdated}
        />
      )}
    </>
  );
};

export default LookupListFieldViewEditForm;
