import React, { useCallback, useState } from 'react';
import { CALL_NUMBER_VERIFICATION_STEP } from '../../../../constants/constant';
import { trackAnalyticActivity } from '../../../../services/analyticsService';
import VerifyingPhoneNumberModal from './VerifyingPhoneNumberModal';
import VerifyPhoneNumberModal from './VerifyPhoneNumberModal';

const VerifyPhoneNumberContainer = ({ onClose, externalNumber }) => {
  const [activeStep, setActiveStep] = useState(CALL_NUMBER_VERIFICATION_STEP.CALL_ME_STEP);
  const [phoneNumberItem, setPhoneNumberItem] = useState();

  const trackPhoneAddedAnalyticActivity = useCallback(() => {
    try {
      if (phoneNumberItem?.phoneNumber) {
        const phoneNumber = phoneNumberItem?.phoneNumber;
        trackAnalyticActivity('settings - phone: added', { phone: phoneNumber, type: 'existing' });
      }
    } catch (e) {
      console.log('track settings - phone: added Error', e);
    }
  }, [phoneNumberItem]);

  const onVerifyingSuccess = useCallback(() => {
    setActiveStep(CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFIED_STEP);
    trackPhoneAddedAnalyticActivity();
  }, [trackPhoneAddedAnalyticActivity]);

  const onVerifyingFailed = useCallback(() => {
    setActiveStep(CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFICATION_FAILED_STEP);
  }, []);

  const onOpenVerifyingPhoneNubModal = useCallback((phoneNumberItem) => {
    setPhoneNumberItem(phoneNumberItem);
    setActiveStep(CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFYING_STEP);
  }, []);

  return (
    <>
      {activeStep !== CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFYING_STEP && (
        <VerifyPhoneNumberModal
          externalNumber={externalNumber}
          onClose={onClose}
          activeStep={activeStep}
          onOpenVerifyingPhoneNumber={onOpenVerifyingPhoneNubModal}
        />
      )}
      {activeStep === CALL_NUMBER_VERIFICATION_STEP.PHONE_NUMBER_VERIFYING_STEP && (
        <VerifyingPhoneNumberModal
          onClose={onClose}
          phoneNumberItem={phoneNumberItem}
          onVerifyingFailed={onVerifyingFailed}
          onVerifyingSuccess={onVerifyingSuccess}
        />
      )}
    </>
  );
};

export default VerifyPhoneNumberContainer;
