import styled from 'styled-components';
import { Header4, Text1, Text2 } from '../../../../styles';

const CallForwardWrap = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CallForwardTop = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const CallForwardHeading = styled.h4`
  ${Header4}
  margin: 0 0 0 12px;
`;
const CallForwardMiddle = styled.div`
  padding: 16px;
`;
const CallForwardingPara = styled.p`
  margin: 0 0 16px 0;
  ${Text1}
  color:var(--gray-color);
`;
const CallForwardingForm = styled.div`
  ${Text2}
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    top: 1px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const CallForwardMain = styled.div``;
const CallForwardBottom = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray-border-color);
`;
const CallForwardnum = styled.div``;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonPrimary = styled.div`
  margin-right: 12px;
`;

export {
  CallForwardWrap,
  CallForwardTop,
  CallForwardHeading,
  CallForwardMiddle,
  CallForwardingPara,
  CallForwardingForm,
  CallForwardMain,
  CallForwardBottom,
  CallForwardnum,
  HeaderWrapper,
  ButtonPrimary
};
