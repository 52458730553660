import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from './../actions/types';

const setSuccessMessageReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_RES_SUCCESS_MSG
});

const setErrorMessageReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_RES_ERROR_MSG
});

const setWarningMessageReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_RES_WARNING_MSG
});

const setItemSuccessMessageReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_ITEM_SUCESS_MSG
});

const setItemErrorMessageReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_ITEM_ERROR_MSG
});

const clearResponseMessageReducer = createReducer({
  initialState: null,
  actionType: Actions.CLEAR_RES_MSG
});

const messageReducer = combineReducers({
  errorMessage: setErrorMessageReducer,
  sucessMessage: setSuccessMessageReducer,
  warningMessage: setWarningMessageReducer,
  itemErrorMessage: setItemErrorMessageReducer,
  itemSucessMessage: setItemSuccessMessageReducer,
  clearResponseMessage: clearResponseMessageReducer
});

export default messageReducer;
