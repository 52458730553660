import React, { useMemo } from 'react';
import { ReactComponent as Logo } from '../../assets/images/svg/logo.svg';
import { ReactComponent as LogoGetPeople } from '../../assets/images/svg/logo-get-people.svg';
import { getAppHostType } from '../../helpers/common';
import { APP_HOST_TYPE } from '../../constants/constant';

const AppLogo = () => {
  const appHostType = useMemo(() => {
    return getAppHostType();
  }, []);

  if (appHostType === APP_HOST_TYPE.GET_PEOPLE) return <LogoGetPeople />;
  return <Logo />;
};

export default AppLogo;
