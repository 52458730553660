import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { LookupFieldTypes, LOOKUP_FIELD_EDIT_VIEW_FROM } from '../../../constants/constant';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  getTableTypeForAnalytics,
  trackContactDetailAnalytics,
  updateLookupField,
  validateLookupFieldInput
} from '../../../services/lookupService';
import LookupFieldInput from './LookupFieldInput';
import { CloseIconView, FormView, Overlay, RightIconView, SpanTitle, BtnViewadd } from './Styles';

const LookupFieldInputForm = (props) => {
  const mobile = useMobileDevice();
  const { lookup_id, showEditLabel = true, onCancel, onUpdated, tableId, viewType } = props;
  const [field, setField] = useState();
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  useEffect(() => {
    const fieldWithValue = { ...(props.field || {}), ...(props.fieldValue || {}) };
    setField(fieldWithValue);
  }, [props.field, props.fieldValue]);

  const onSubmit = useCallback(
    async (e, inputFieldValue) => {
      if (e) e.preventDefault();
      let fieldWithValue = inputFieldValue || field;
      if (fieldWithValue?.isRequired) {
        const validationError = validateLookupFieldInput(fieldWithValue);
        if (validationError.error) {
          const resError = {};
          resError[fieldWithValue?.id] = validationError.error;
          setError(resError);
          return;
        }
      }
      //TODO: Validate present and new value if any change update on server or bypass update api
      if (
        LookupFieldTypes.Date === fieldWithValue?.value_Date ||
        LookupFieldTypes.Time === fieldWithValue?.value_Date
      ) {
        fieldWithValue.value_Date = new Date(fieldWithValue?.value_Date).toISOString();
      }
      const payload = {
        field: fieldWithValue,
        value: fieldWithValue
      };
      const result = await dispatch(updateLookupField(organization_id, lookup_id, payload));
      if (result) {
        if (props?.table) {
          let action;
          const type = getTableTypeForAnalytics(props.table);
          if (viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD) {
            action = 'information updated';
          } else if (viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD) {
            action = 'information added';
          }
          const params = { field: payload?.field?.customId };
          trackContactDetailAnalytics(type, action, params);
        }
      }
      if (onUpdated) onUpdated(payload);
    },
    [dispatch, field, lookup_id, onUpdated, organization_id, props.table, viewType]
  );

  const onChangeValues = (item) => {
    setField(item);
  };

  return (
    <>
      <>
        {showEditLabel && <SpanTitle>{field?.label}</SpanTitle>}
        <FormView
          onSubmit={onSubmit}
          viewType={viewType}
          border={field?.type === LookupFieldTypes.Boolean}
          mobile={mobile}>
          <LookupFieldInput
            viewType={viewType}
            showLabel={false}
            tableId={tableId}
            item={field}
            onChangeValues={onChangeValues}
            resError={error}
            autoFocus
          />
          <BtnViewadd viewType={viewType} isdescription={field?.type === LookupFieldTypes.MultiLineText}>
            {props?.field && (
              <RightIconView type='submit' viewType={viewType} title={'Save'}>
                <SVGIcon
                  fill='var(--gray-icon-color)'
                  size={viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST ? 14 : 18}
                  name='icon-check'
                  strokeWidth={2}
                />
              </RightIconView>
            )}
            <CloseIconView viewType={viewType} border={props?.field} type='button' onClick={onCancel} title={'Cancel'}>
              <SVGIcon
                fill='var(--gray-icon-color)'
                size={viewType === LOOKUP_FIELD_EDIT_VIEW_FROM.LIST ? 14 : 18}
                name='icon-close'
                strokeWidth={2}
              />
            </CloseIconView>
          </BtnViewadd>
        </FormView>
      </>
      {viewType !== LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_ADD_FIELD && <Overlay onClick={onCancel} />}
    </>
  );
};

export default LookupFieldInputForm;
