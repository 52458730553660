import styled, { css } from 'styled-components';
import { Header4, Text1, Text2 } from '../../styles';

const NotificationMain = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 1px 3px rgba(10, 10, 46, 0.1);
    `}
`;

const EnableBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
`;

const NotificationHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.mobile &&
    css`
      position: sticky;
      z-index: 2;
      top: 0;
      background-color: var(--white-color);
    `}
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const NitifySpan = styled.span`
  display: block;
  ${Header4}
  color: var(--dark-color);
`;

const AllReadSpan = styled.span`
  display: block;
  ${Text1}
  color: var(--primary-color);
  cursor: pointer;
`;

const NotificationContainer = styled.div`
  padding: 12px;
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          max-height: 100%;
        `
      : css`
          max-height: 468px;
        `}
`;

const NotifyMainEvent = styled.div`
  padding: 6px 8px;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  :last-child {
    margin-bottom: 0;
  }
  :hover {
    background-color: var(--popup-color-hover);
  }
  &.unread {
    background-color: var(--unread-color);
  }
`;

const MainAvtarBlock = styled.div`
  display: flex;
  align-items: center;
`;

const LeftAvtar = styled.div`
  min-width: 32px;
  min-height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: auto;
`;

const EventIcon = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--white-color);
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const UserText = styled.span`
  margin: 0 4px 0 0;
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  display: inline;
  &.unread {
    font-weight: 600;
  }
`;

const RightContent = styled.div`
  margin-left: 10px;
  width: 100%;
`;

const UserRecive = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin: 0 4px 0 0;
  display: inline;
  &.unread {
    font-weight: 600;
  }
`;

const TimeText = styled.span`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  display: inline;
  &.unread {
    font-weight: 600;
  }
`;

const ContentDiv = styled.div`
  width: 100%;
`;

const CommentBox = styled.div`
  margin-top: 4px;
  padding: 6px 12px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  width: 100%;
`;

const CommentSpan = styled.span`
  display: block;
  ${Text2}
  color: var(--sonic-silver);
  span {
    color: var(--primary-color);
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    :last-child {
      margin-left: 12px;
    }
  }
`;

const NotifySpan = styled.span`
  ${Text1}
  color: var(--sonic-silver);
  ${(props) =>
    props.mobile &&
    css`
      width: 48%;
    `}
`;

const NotificationEmpty = styled.div`
  height: 432px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotifyEmptyBlock = styled.div`
  max-width: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyGraphic = styled.img``;

const EmptySpan = styled.span`
  display: block;
  ${Text1}
  color: var(--gray-color);
  margin-top: 30px;
`;
const IconClose = styled.div`
  margin-left: 14px;
  svg {
    display: block;
  }
`;

export {
  NotificationMain,
  NotificationHeader,
  Left,
  NitifySpan,
  Right,
  AllReadSpan,
  NotificationContainer,
  NotifyMainEvent,
  MainAvtarBlock,
  EventIcon,
  LeftAvtar,
  UserText,
  RightContent,
  UserRecive,
  TimeText,
  ContentDiv,
  CommentBox,
  CommentSpan,
  EnableBlock,
  ButtonWrap,
  NotifySpan,
  NotificationEmpty,
  NotifyEmptyBlock,
  EmptyGraphic,
  EmptySpan,
  IconClose
};
