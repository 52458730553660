import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from '@material-ui/core';
import { getGravatarURL, getTableRoutingPrefix } from '../../helpers/common';
import { getLookupSearchList } from '../../services/lookupService';
import Avtar from '../Avtar/Avtar';
import { LOOKUP_TYPE } from '../../constants/constant';
import PlatformText from '../PlatformText/PlatformText';
import useMobileDevice from '../../hooks/useMobileDevice';
import useEventListener from '../../hooks/useEventListener';
import SVGIcon from '../../assets/svg/SVGIcon';
import {
  InputWrapper,
  Input,
  InputBox,
  SearchIcon,
  SearchWrapper,
  SearchBox,
  // Span,
  Ul,
  Li,
  UserName,
  UserMail,
  UserWrapper,
  AvtarWrapper,
  UserNameWrapper,
  UserMailWrapper,
  Overlay
} from './styles';

let timeout;
let elSelectedRow;

const UP_ARROW = 38;
const DOWN_ARROW = 40;
const ENTER = 13;

const TitleSearchBar = () => {
  const mobile = useMobileDevice();
  const authSelector = useSelector((state) => state.auth);
  const tableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = tableSelector;

  const authUser = authSelector?.currentUser;

  const [searchText, setSearchText] = useState('');
  const [opened, setOpened] = useState(false);
  const [list, setList] = useState([]);
  const [selectionIndex, setSelectionIndex] = useState();
  const history = useHistory();
  let elSearchInput = useRef();

  const maxIndex = useMemo(() => {
    return list?.length || 0;
  }, [list]);

  useEffect(() => {
    setSelectionIndex(0);
  }, [list]);

  const onClose = useCallback(() => {
    setOpened(false);
    setSearchText('');
    setList([]);
  }, [setSearchText]);

  const scrollListIfRequired = useCallback(() => {
    const focusedNode = elSelectedRow;
    if (focusedNode) {
      focusedNode.scrollIntoView({ block: 'end' });
    }
  }, []);

  const onNavigateSelection = useCallback(
    (down = true, index) => {
      if (index >= 0 && index < list.length) {
        setSelectionIndex(index);
        scrollListIfRequired();
      }
    },
    [list, scrollListIfRequired]
  );

  const getTabale = useCallback(
    (item) => {
      const table = lookupTableList?.find((x) => x.id === item?.tableId);
      return table;
    },
    [lookupTableList]
  );

  const onLookupSelected = useCallback(
    (item) => {
      const table = getTabale(item);
      if (table) {
        onClose();
        const prefix = getTableRoutingPrefix(table.type);
        const url = `/${prefix}/${table?.name}/view/${item?.id}/overview`;
        history.push(url);
      }
    },
    [getTabale, onClose, history]
  );

  const onSelectItem = useCallback(() => {
    if (list?.length > 0 && selectionIndex <= list?.length) {
      onLookupSelected(list[selectionIndex]);
    }
  }, [onLookupSelected, list, selectionIndex]);

  const onKeyDownListener = useCallback(
    (event) => {
      try {
        if (!opened) return;
        if (timeout) clearTimeout(timeout);
        switch (event.keyCode) {
          case UP_ARROW:
          case DOWN_ARROW: {
            event.preventDefault();
            break;
          }
          default:
            break;
        }

        timeout = setTimeout(() => {
          if (list.length > 0) {
            switch (event.keyCode) {
              case UP_ARROW:
                if (selectionIndex && selectionIndex > 0) {
                  const newIndex = selectionIndex - 1;
                  onNavigateSelection(false, newIndex);
                } else {
                  onNavigateSelection(false, 0);
                }
                break;
              case DOWN_ARROW:
                if (selectionIndex < maxIndex) {
                  const newIndex = selectionIndex + 1;
                  onNavigateSelection(true, newIndex);
                } else if (!selectionIndex || selectionIndex === -1) {
                  onNavigateSelection(true, 0);
                }
                break;
              case ENTER:
                onSelectItem(selectionIndex);
                break;
              default:
                break;
            }
          }
        }, 0);
      } catch (e) {
        console.error('ERROR - onItemSelected', e);
      }
    },
    [opened, list?.length, maxIndex, onNavigateSelection, onSelectItem, selectionIndex]
  );

  useEventListener('keydown', onKeyDownListener);

  const onOpenSearch = useCallback(
    (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      if (!authUser || !authUser?.id) return null;
      setOpened(true);
      elSearchInput.current.focus();
    },
    [authUser]
  );

  const setSearchResult = async (query) => {
    const result = await getLookupSearchList(query);
    setList(result);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchData = useCallback(debounce(setSearchResult, 300), []);

  const onCloseSearchMenu = useCallback(() => {
    if (searchText) {
      setSearchText('');
      setList([]);
    } else setOpened(false);
  }, [searchText]);

  const hotKeyOptions = {
    enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']
  };
  useHotkeys('ctrl+k,cmd+k', onOpenSearch, hotKeyOptions);
  useHotkeys(
    'esc',
    () => {
      //if open, clear search text if present else close search modal
      if (opened) {
        onCloseSearchMenu();
      }
    },
    hotKeyOptions
  );

  const onSeachQueryChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchText(value);
      debounceSetSearchData(value);
      if (!opened && value) {
        setOpened(true);
      }
    },
    [debounceSetSearchData, opened]
  );

  const getGravatar = useCallback(
    (item) => {
      let gravatar;
      const table = getTabale(item);
      if (table) {
        if (table?.type === LOOKUP_TYPE.contacts && item?.email) {
          gravatar = getGravatarURL(item?.email);
        }
      }
      return gravatar;
    },
    [getTabale]
  );

  return (
    <SearchWrapper mobile={mobile}>
      <InputWrapper>
        <SearchIcon mobile={mobile}>
          <SVGIcon name='icon-search' size={mobile ? '20' : '18'} fill='var(--gray-color)' strokeWidth={2} />
        </SearchIcon>
        <Input
          mobile={mobile}
          placeholder='Search'
          value={searchText}
          autoFocus
          onChange={onSeachQueryChange}
          ref={elSearchInput}
        />
        <InputBox mobile={mobile}>
          <PlatformText mac={'⌘+K'} win={'⌘+K'} defaultText={'ctrl+K'} />
        </InputBox>
      </InputWrapper>
      {opened && list?.length > 0 && (
        <>
          <SearchBox>
            {/* <Span>Contacts</Span> */}
            <Ul>
              {list?.map((item, index) => {
                const gravatar = getGravatar(item);
                const selected = index === selectionIndex;
                return (
                  <Li
                    key={`search_item_${item?.id}_${index}`}
                    selected={selected}
                    onClick={() => {
                      onLookupSelected(item);
                    }}
                    ref={(row) => {
                      if (selected) elSelectedRow = row;
                    }}>
                    <AvtarWrapper>
                      <Avtar imgSrc={gravatar} name={item?.name} size={20} />
                    </AvtarWrapper>
                    <UserWrapper>
                      <UserNameWrapper>
                        <UserName>{item?.name}</UserName>
                      </UserNameWrapper>
                      <UserMailWrapper>
                        <UserMail>{item?.email}</UserMail>
                      </UserMailWrapper>
                    </UserWrapper>
                  </Li>
                );
              })}
              {/* <Li>
              <Icon fontSize='20' name='icon-user' color='var(--white-color)' />
              <UserName>Create new contact ...</UserName>
            </Li> */}
            </Ul>
          </SearchBox>
          <Overlay onClick={onCloseSearchMenu}></Overlay>
        </>
      )}
    </SearchWrapper>
  );
};

export default TitleSearchBar;
