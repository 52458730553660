import styled, { css } from 'styled-components';
import Button from '../../components/atom/Button/Button';
import { Header1, Header3 } from '../../styles';
import LinearBgMobile from '../../assets/images/mobilelinearbg.png';

const MainWrap = styled.div`
  background: var(--main-bg);
  width: 100%;
  height: inherit;
  overflow-y: hidden;
  ${(props) =>
    props.mobile &&
    css`
      padding-top: 54px;
      background: transparent;
    `}
`;
const MobileHomeLinear = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background-color: #f3f2f0;
      background-image: url(${LinearBgMobile});
      background-size: 100% auto;
      background-position: top;
      background-repeat: no-repeat;
    `}
`;
const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
  ${(props) =>
    props.mobile
      ? css`
          display: block;
          padding-top: 30;
        `
      : css`
          display: flex;
          padding-top: 0;
        `}
`;
const LogoWrapper = styled.div`
  text-align: center;
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 30px;
        `
      : css`
          margin-bottom: 40px;
        `}
`;
const Title = styled.h1`
  text-align: center;
  color: var(--dark-color);
  ${(props) =>
    !props.mobile
      ? css`
          ${Header1}
          margin: 16px 0 0;
        `
      : css`
          ${Header3}
          margin: 0 0 0 20px;
        `}
`;
const AllView = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const ListSpace = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          padding: 8px 16px;
        `
      : css`
          width: 33.33%;
          padding: 10px 20px;
        `}
`;
const ListCompany = styled.div`
  display: flex;
  align-items: center;
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);

  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  ${(props) =>
    props.mobile
      ? css`
          box-shadow: 0px 1px 3px var(--inbox-hover-color);
        `
      : css`
          box-shadow: 0px 1px 3px var(--box-shadow-secondary);
        `}
`;
const ImgView = styled.div`
  display: block;
`;
const ImgProfile = styled.img`
  border-radius: 21px;
  width: 42px;
  height: 42px;
  display: block;
`;
const CompanyName = styled.h4`
  margin: 0 0 0 16px;
  font-style: normal;
  ${Header3}
  color: var(--dark-color);
`;
const BtnView = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
export {
  MainWrap,
  SwitchContainer,
  LogoWrapper,
  Title,
  AllView,
  ListCompany,
  ImgView,
  ImgProfile,
  CompanyName,
  ListSpace,
  BtnView,
  ButtonWrap,
  MobileButton,
  MobileHomeLinear
};
