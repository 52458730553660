import styled from 'styled-components';

const OrgLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
`;
const OrgImgNavbar = styled.div`
  width: 200px;
  height: 100%;
  padding: 8px;
  background-color: var(--nav-dark-color);
  box-shadow: 1px 0px 0px var(--Platinum-color);
`;
const NavbarLoadImg = styled.img``;
const OrgLoadWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
`;
const OrgNavbarImgMain = styled.div`
  max-width: 820px;
  width: 100%;
  margin: auto;
`;

export { OrgLoadingWrapper, OrgImgNavbar, NavbarLoadImg, OrgLoadWrapper, OrgNavbarImgMain };
