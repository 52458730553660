/* eslint-disable max-len */
const previewDefaultStyle = `body {
    line-height:1.4;
    margin:0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    color: #000000;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  pre {
    white-space:pre-wrap;
  }
  
  blockquote{
    margin: 0 0 0 .8ex;
    padding-left:10px;
    border-left:1px solid #CCCCCC;
  }
    
  div[class*='sc_mail'] {
    word-wrap:break-word !important;
  }
  
  .sc_show_quoted_text_tap_icon {
    opacity:0.6;
    padding:15px 25px 15px 0px;
    cursor: pointer;
    width: auto !important;
  }
  
  .sc_show_quoted_text_tap_icon_selected {
    opacity:1.0;
    padding:15px 25px 15px 0px;
  }
  
  .sc_show_quoted_text {
    display:inline-block;
  }
  
  a {
    color:#299CD6;
    white-space:normal;
  }
  
  .spinner {
    width: 70px;
    height: 60px;
    margin:auto;
    text-align: center;
    vertical-align: middle;
  }
  
  .spinner > div {
    width: 20px;
    height: 20px;
    background-color: white;
    margin-top:35%;
    display:inline-block;
    border-radius: 100%;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes bouncedelay {
    0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
    } 40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    }
  }
  
  a.sc_tt_anchor{
    text-decoration: none;
    border-bottom: 1px dashed #999;
    color: #333333;
  }
  .newton_found {
    background-color: #B1D6FF;
  } 
`;

export { previewDefaultStyle };
