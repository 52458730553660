import moment from 'moment';
import { useMemo } from 'react';
import Avtar from '../../../../../components/Avtar/Avtar';
import useMobileDevice from '../../../../../hooks/useMobileDevice';
import { TOOLTIP_DATE_FORMAT } from '../../../../../constants/constant';
import CommentHoverItem from '../Comments/CommentHoverItem';
import RenderComments from '../Comments/RenderComments';
import { getGravatarURL, urlify } from '../../../../../helpers/common';

import {
  MainList,
  ActivityUpperBlock,
  IconList,
  AllList,
  RightList,
  UserText,
  UserRecive,
  ActivityBottomBlock,
  ContainView,
  Captiontitle,
  Captionmess,
  IconWrapper,
  FileMain,
  DateWrapper,
  FileText,
  Monthly,
  Date,
  Time,
  CallSummaryItem,
  AudioText,
  ThraedInner,
  UserWrapperBlock
} from '../styles';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
const MeetingActivityItem = ({
  item,
  svgIconSize,
  itemDate,
  isOpenThread,
  onLookupClick,
  onMoreMenuClick,
  onClickComment,
  selectedActivity,
  isOpenMoreOptionMenu,
  isShowCommentInput
}) => {
  const mobile = useMobileDevice();
  const timeDifference = useMemo(() => {
    const time = moment(item?.time).format('hh:mm');
    const toTime = moment(item?.toTime).format('hh:mm');
    if (time < toTime) {
      var start = moment.duration(time, 'hh:mm');
      var end = moment.duration(toTime, 'hh:mm');
      var diff = end.subtract(start);
      const hour = `${Math.abs(diff.hours())} Hours`;
      const minute = `${Math.abs(diff.minutes())} Min`;
      const totalTime = `${hour !== '0 Hours' ? hour : ''}  ${minute}`;
      return `(${totalTime})`;
    }
  }, [item?.time, item?.toTime]);

  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);

  const htmlContent = useMemo(() => {
    if (item?.description) {
      return urlify(item?.description);
    }
    return item?.description;
  }, [item?.description]);

  return (
    <MainList key={item?.id}>
      <ActivityUpperBlock mobile={mobile} isOpenThread={UserWrapperBlock}>
        {isOpenThread ? (
          item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
        ) : (
          <IconList>
            <IconWrapper bgColor='#7D89F3' mobile={mobile}>
              <SVGIcon fill='var(--white-color)' size={svgIconSize} name='icon-account' strokeWidth={1.8} />
            </IconWrapper>
          </IconList>
        )}

        <AllList>
          <RightList mobile={mobile} isOpenThread={isOpenThread}>
            {isOpenThread && item?.lookup && (
              <ThraedInner
                onClick={() => {
                  onLookupClick(item?.lookup);
                }}>
                <UserText>{item?.lookup?.name}</UserText>
              </ThraedInner>
            )}
            <UserWrapperBlock mobile={mobile}>
              <UserText isOpenThread={isOpenThread} mobile={mobile}>
                {item?.assignedUser?.name}
              </UserText>
              <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                has meeting
              </UserRecive>
              <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                {moment(item?.time).fromNow()}
              </UserRecive>
            </UserWrapperBlock>
          </RightList>
        </AllList>
      </ActivityUpperBlock>
      <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
        <ContainView meeting>
          <FileMain>
            <DateWrapper>
              <Monthly>{itemDate.month}</Monthly>
              <Date>{itemDate.day}</Date>
            </DateWrapper>
            <FileText className='fileBorder'>
              <Captiontitle>{item?.title}</Captiontitle>
              <Captionmess isTitle>
                <Time title={moment(item?.time).format(TOOLTIP_DATE_FORMAT)}>
                  {moment(item?.time).format('h:mm A')}
                </Time>
                {` to `}
                <Time title={moment(item?.toTime).format(TOOLTIP_DATE_FORMAT)}>
                  {moment(item?.toTime).format('h:mm A')} {timeDifference}
                </Time>
              </Captionmess>
            </FileText>
          </FileMain>
          {htmlContent && (
            <CallSummaryItem>
              <FileMain>
                <AudioText>
                  <Captionmess className='quill_format' dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </AudioText>
              </FileMain>
            </CallSummaryItem>
          )}
          {onMoreMenuClick && (
            <CommentHoverItem
              item={item}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
              selectedActivity={selectedActivity}
              onClickMoreMenu={onMoreMenuClick}
              onClickComment={onClickComment}
            />
          )}
        </ContainView>
        <RenderComments item={item} isShowCommentInput={isShowCommentInput} selectedActivity={selectedActivity} />
      </ActivityBottomBlock>
    </MainList>
  );
};

export default MeetingActivityItem;
