import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Empty from '../../assets/images/automation-empty.svg';
import { NewTitle, EmptyImg, EmptyWrapper, DetailsTitle, Details } from './styles';

const AutomationEmptyState = () => {
  const history = useHistory();

  const onClickAutomation = useCallback(() => {
    history.push('/automation/new');
  }, [history]);

  return (
    <EmptyWrapper>
      <EmptyImg src={Empty} />
      <DetailsTitle>No Automation Found</DetailsTitle>
      <Details>There is no contact in your collection, please add contact.</Details>
      <NewTitle onClick={onClickAutomation}>New Automation</NewTitle>
    </EmptyWrapper>
  );
};

export default AutomationEmptyState;
