import styled, { css } from 'styled-components';
import { Header1, Header4, Text1, Text3 } from '../../../styles';
const ReportWrapper = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 0;
        `
      : css`
          padding: 0 24px;
        `}
`;
const ReportBlock = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ReportBox = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex: 0 0 auto;
  width: 100%;
`;
const PanelWrapper = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 4px;
`;
const PanelTop = styled.div`
  border-bottom: 1px solid var(--gray-border-color);
  padding: 10px 15px;
  display: flex;
  align-items: center;
`;
const PanelBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          flex-direction: column;
          padding: 15px 20px 5px 20px;
        `
      : css`
          padding: 15px 20px;
        `}
`;
const OverviewTitle = styled.h3`
  ${Header4}
  color:var(--dark-color);
  margin: 0 0 0 12px;
`;
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex: 0 1 auto;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          flex-direction: column;
        `
      : css``}
`;
const Li = styled.li`
  :first-child {
    border-left: 0 solid transparent;
    padding-left: 0;
    margin-left: 0;
  }
  ${(props) =>
    props.mobile
      ? css`
          border-left: none;
          padding-left: 0;
          margin-left: 0;
          margin-bottom: 10px;
        `
      : css`
          border-left: 1px solid var(--gray-border-color);
          padding-left: 20px;
          margin-left: 30px;
        `}
`;
const LiWrapper = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `
      : css``}
`;
const LiTitle = styled.span`
  ${Text1}
  color:var(--gray-color);
  display: block;
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 0;
        `
      : css`
          margin-bottom: 5px;
        `}
`;
const UlData = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;
const DataLi = styled.li`
  :first-child {
    margin-left: 0;
    ::before {
      display: none;
    }
  }
  margin-left: 30px;
  position: relative;
  color: var(--dark-color);
  ::before {
    content: '';
    background-color: var(--gray-border-color);
    width: 6px;
    height: 6px;
    position: absolute;
    left: -18px;
    top: 50%;
    margin-top: -3px;
    border-radius: 100%;
  }
  ${(props) =>
    props.mobile
      ? css`
          ${Text1}
        `
      : css`
          ${Header1}
        `}
`;
const SummaryContent = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 0;
          margin-top: 16px;
        `
      : css`
          padding: 0 24px;
          margin-top: 40px;
        `}
`;
const SummaryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          flex-direction: column-reverse;
        `
      : css`
          flex-direction: row;
        `}
`;
const SummaryCol = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          padding-right: 0;
        `
      : css`
          max-width: 75%;
          padding-right: 15px;
        `}
`;
const SummaryColTwo = styled.div`
  width: 100%;
  position: relative;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          padding-left: 0;
        `
      : css`
          max-width: 25%;
          padding-left: 15px;
        `}
`;
const ReportActivityWrapper = styled.div``;
const ReportActivityUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  ${(props) =>
    props.mobile
      ? css`
          margin-top: 16px;
        `
      : css``}
`;
const ReportActivityLi = styled.li`
  display: flex;

  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.mobile
      ? css`
          align-items: flex-start;
          background-color: var(--white-color);
          border-radius: 6px;
          margin-bottom: 10px;
          padding: 12px 16px;
          box-shadow: 0px 1px 3px var(--mobile-box-shadow);
        `
      : css`
          align-items: center;
          justify-content: space-between;
          padding: 10px 0px;
        `}
`;
const TimelineView = styled.div`
  background-color: var(--white-color);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border: 1px solid var(--gray-border-color);
  border-radius: 34px;
`;
const ReportActivityHead = styled.div``;
const Calltext = styled.h5`
  margin: 0;
  ${Text1}
  color:var(--dark-color );
  span {
    color: var(--gray-color);
  }
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  flex: 1 1 0;
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          flex-direction: column;
          align-items: flex-start;
        `
      : css`
          align-items: center;
        `}
`;
const ReportActivityTime = styled.div``;
const Timetext = styled.span`
  ${Text3}
  color: var(--gray-color);
`;
const StatusText = styled.span`
  ${Text3}
`;
const StatusSpan = styled.span`
  ${Text3};
  ${(props) =>
    props.successful &&
    css`
      background: var(--white-color);
      color: var(--green-color);
      border-color: var(--gray-border-color);
    `}
  ${(props) =>
    props.Failed &&
    css`
      background: var(--white-color);
      color: var(--danger-Base-bg);
      border-color: var(--gray-border-color);
    `}
    ${(props) =>
    props.NoAnswer &&
    css`
      background: var(--white-color);
      color: var(--dark-color);
      border-color: var(--gray-border-color);
    `}
    ${(props) =>
    props.incoming &&
    css`
      background: var(--white-color);
      color: var(--primary-color);
      border-color: var(--gray-border-color);
    `}
`;
const EventTitle = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    ${Header4}
    margin: 0;
  }
`;
const SummaryColInner = styled.div`
  background: var(--white-color);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
const SummaryColFilter = styled.div`
  padding: 20px 0px 5px 16px;
  display: block;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.isEventMenu && props.mobile
      ? css`
          display: none;
        `
      : css``}
`;
const SummaryUserColFilter = styled.div`
  display: block;
  padding: 20px 0px 5px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.isUserMenu && props.mobile
      ? css`
          display: none;
        `
      : css``}
`;
const SummaryColFilterUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const SummaryColFilterli = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 14px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    top: 3px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const TextPopup = styled.label`
  ${Text1};
  font-style: normal;
  color: var(--dark-color);
  margin-left: 12px;
  cursor: pointer;
`;
const Wrapper = styled.div`
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          height: 100%;
          padding: 0px 16px;
        `
      : css`
          height: calc(100% - 76px);
          padding: 0px 0px 20px 0px;
        `}
`;
const MainMobileButton = styled.div`
  margin-left: auto;
`;

export {
  ReportWrapper,
  ReportBlock,
  ReportBox,
  PanelWrapper,
  PanelTop,
  PanelBottom,
  OverviewTitle,
  Ul,
  Li,
  LiWrapper,
  LiTitle,
  UlData,
  DataLi,
  SummaryContent,
  SummaryRow,
  SummaryCol,
  SummaryColTwo,
  ReportActivityWrapper,
  ReportActivityUl,
  ReportActivityLi,
  TimelineView,
  ReportActivityHead,
  ReportActivityTime,
  Calltext,
  Left,
  Timetext,
  StatusText,
  StatusSpan,
  EventTitle,
  SummaryColInner,
  SummaryColFilter,
  SummaryColFilterUl,
  SummaryColFilterli,
  InputWrapperModal,
  TextPopup,
  Wrapper,
  MainMobileButton,
  SummaryUserColFilter,
  Right
};
