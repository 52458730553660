import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { isEmail, isEmpty } from '../../helpers/common';
import Button from '../../components/atom/Button/Button';
import DropDown from '../../components/DropDown/DropDown';
import { DatePicker } from '../../components/DateTime';
import { currencyNameWithCode, dueDateOptions } from '../../data/raw';
import {
  addEmailInInvoice,
  addInvoiceTax,
  addItemInInvoice,
  deleteEmailFromInvoice,
  deleteInvoiceTax,
  deleteItemFromInvoice,
  setInvoiceItem,
  updateInvoiceItem
} from '../../actions/invoiceActions';
import { getAllLookup } from '../../services/lookupService';
import Message from '../../components/Message/Message';
import { createNewInvoice, getInvoiceItem, getNextInvoiceNumber, updateInvoice } from '../../services/invoiceService';
import { INVOICE_STATUS } from '../../constants/constant';
import useMobileDevice from '../../hooks/useMobileDevice';
import SVGIcon from '../../assets/svg/SVGIcon';
import {
  HeaderWraper,
  HeaderLeft,
  InvoiceTitle,
  Form,
  FormInvoice,
  InvoiceTop,
  Label,
  DropDownWrapper,
  InvoiceInput,
  Percentage,
  DropDownWrapperTex,
  DropDownWrapperBlog,
  Link,
  DropDownWrapperLink,
  LinkSpan,
  InvoiceCenter,
  InvoiceTable,
  Table,
  Thead,
  Tbody,
  InvoiceTr,
  InvoiceTh,
  InvoiceTd,
  InvoiceTdInput,
  TextArea,
  InvoiceWrapper,
  AmountWrapper,
  Ul,
  Li,
  TitleWrapper,
  AmountDueWrapper,
  AmountLabel,
  SubLabel,
  Border,
  InvoiceFooter,
  FooterLeft,
  FooterRight,
  Span,
  LabelInput,
  Labelelse,
  SvgWrapper
} from './styles';

const NewInvoice = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const invoiceSelector = useSelector((state) => state.invoice);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const lookupSelector = useSelector((state) => state.lookup);
  const authselector = useSelector((state) => state.auth);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { tableFields } = lookupTableSelector;
  const { invoiceItem, loading } = invoiceSelector;
  const { lookups } = lookupSelector;
  const { currentUser } = authselector;

  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const { id: invoiceId } = useParams();

  const loadData = useCallback(async () => {
    dispatch(getAllLookup());
    if (!invoiceId) {
      const nextInvoiceNumber = await dispatch(getNextInvoiceNumber(organization_id));
      const invoice = {
        companyId: organization_id,
        number: nextInvoiceNumber,
        currency: organization?.settings?.currency,
        date: new Date(),
        dueDate: new Date(),
        dueDays: 0,
        emails: [],
        discount: 0.0,
        tax: [{ name: 'Tax', value: 0.0 }],
        items: [
          {
            qty: 1.0,
            isTax: true,
            isTax2: true
          }
        ],
        subTotal: 0.0,
        totalDiscount: 0.0,
        totalTax: 0.0,
        totalTax2: 0.0,
        totalAmount: 0.0,
        notes: '',
        isSendMeCopy: false,
        isSendCopyToLookup: false
      };
      dispatch(setInvoiceItem(invoice));
    } else {
      dispatch(getInvoiceItem(organization_id, invoiceId));
    }
  }, [dispatch, invoiceId, organization_id, organization?.settings?.currency]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setInvoiceItem());
    };
  }, [dispatch, loadData]);

  const currencySymbol = useMemo(() => {
    const symbol = currencyNameWithCode?.find((x) =>
      invoiceId ? x.code === invoiceItem?.currency : x.code === organization?.currency
    );
    return symbol;
  }, [invoiceItem?.currency, invoiceId, organization?.currency]);

  const selectedLookup = useMemo(() => {
    const lookup = lookups?.find((item) => item?.id === invoiceItem?.lookupId);
    let lookupItem = {};
    if (lookup) {
      const lookupTableFields = tableFields?.find((x) => x.tableId === lookup?.tableId);
      const columns = lookupTableFields?.columns;
      if (columns) {
        for (let i = 0; i < columns?.length; i++) {
          lookupItem = { ...lookupItem, ...lookup, [columns[i].customId]: lookup?.displayFields[columns[i].id] };
        }
      }
    }
    return lookupItem;
  }, [tableFields, invoiceItem?.lookupId, lookups]);

  const lookupName =
    (selectedLookup?.firstname &&
      selectedLookup?.firstname.concat(` ${selectedLookup?.lastname && selectedLookup?.lastname}`)) ||
    selectedLookup?.name ||
    '';

  const updateValue = useCallback(
    (propName, value, fieldGroup, fieldIndex) => {
      dispatch(updateInvoiceItem({ propName, value, fieldGroup, fieldIndex }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (invoiceItem?.date) {
      const dueDate = moment(new Date(invoiceItem?.date), 'DD-MM-YYYY').add(invoiceItem?.dueDays, 'days').toISOString();
      updateValue('dueDate', dueDate);
    }
  }, [invoiceItem?.date, invoiceItem?.dueDays, updateValue]);

  const totalValues = useMemo(() => {
    let subTotal = 0;
    let totalTax = 0;
    let totalTax2 = 0;
    let totalAmount = 0;

    subTotal = invoiceItem?.items
      ?.filter((item) => !isEmpty(item.desc))
      .reduce((sum, item) => sum + Number(item.amount), 0);

    const totalDiscount = (subTotal * invoiceItem?.discount) / 100 || 0;
    if (invoiceItem?.tax?.length > 0 && subTotal) {
      totalTax = ((subTotal - totalDiscount) * invoiceItem?.tax?.[0]?.value) / 100 || 0;
    }
    if (invoiceItem?.tax?.length === 2) {
      totalTax2 = ((subTotal - totalDiscount) * invoiceItem?.tax?.[1]?.value) / 100 || 0;
    }
    totalAmount = subTotal - totalDiscount + totalTax + totalTax2 || 0;
    return { subTotal, totalDiscount, totalTax, totalTax2, totalAmount };
  }, [invoiceItem?.tax, invoiceItem?.discount, invoiceItem?.items]);

  useEffect(() => {
    updateValue('subTotal', totalValues?.subTotal);
    updateValue('totalDiscount', totalValues?.totalDiscount);
  }, [updateValue, totalValues?.subTotal, totalValues?.totalDiscount]);

  useEffect(() => {
    updateValue('totalTax', Number(totalValues?.totalTax));
    updateValue('totalTax2', totalValues?.totalTax2);
  }, [updateValue, totalValues?.totalTax, totalValues?.totalTax2]);

  useEffect(() => {
    updateValue('totalAmount', totalValues?.totalAmount);
  }, [updateValue, totalValues?.totalAmount]);

  useEffect(() => {
    // previousLookupId is used to compare with lookupId, incase if lookupId changes,
    // then and only customer data should be changed
    if (invoiceItem?.previousLookupId !== invoiceItem?.lookupId) {
      const customer = {
        firstName: selectedLookup?.firstname || selectedLookup?.name,
        lastName: selectedLookup?.lastname || '',
        companyName: selectedLookup?.company?.name || selectedLookup?.name,
        email: selectedLookup?.email,
        phone: selectedLookup?.phone || '',
        addressLine1: selectedLookup?.street || '',
        addressLine2: '',
        city: selectedLookup?.city || '',
        state: selectedLookup?.state || '',
        country: selectedLookup?.country || '',
        pincode: selectedLookup?.postal_code || ''
      };
      updateValue('customer', customer);
      updateValue('previousLookupId', invoiceItem?.lookupId);
    }
  }, [updateValue, selectedLookup, invoiceItem?.lookupId, invoiceId, invoiceItem?.previousLookupId]);

  const updateDueDates = useCallback(
    (dueDays) => {
      const dueDate = moment(new Date(invoiceItem?.date), 'DD-MM-YYYY').add(dueDays, 'days').toISOString();
      updateValue('dueDays', dueDays);
      updateValue('dueDate', dueDate);
    },
    [updateValue, invoiceItem]
  );

  const onAddInvoiceTax = useCallback(() => {
    let taxItem = {
      value: 0
    };
    if (invoiceItem?.tax?.length === 0) {
      taxItem.name = 'Tax';
    } else {
      taxItem.name = 'Tax 2';
    }
    dispatch(addInvoiceTax({ taxItem }));
  }, [dispatch, invoiceItem?.tax]);

  const onDeleteInvoiceTax = useCallback(() => {
    let taxItemIndex;
    if (invoiceItem?.tax?.length === 2) {
      taxItemIndex = 1;
    } else {
      taxItemIndex = 0;
    }
    dispatch(deleteInvoiceTax({ taxItemIndex }));
  }, [dispatch, invoiceItem?.tax]);

  const onAddItemInInvoice = useCallback(() => {
    const productItem = {
      amount: '',
      desc: '',
      qty: 1,
      unitPrice: '',
      isTax: true,
      isTax2: true
    };
    dispatch(addItemInInvoice({ productItem }));
  }, [dispatch]);

  const onDeleteItemFromInvoice = useCallback(
    (productItemIndex) => {
      dispatch(deleteItemFromInvoice({ productItemIndex }));
      if (invoiceItem?.items?.length === 1) {
        onAddItemInInvoice();
      }
    },
    [dispatch, onAddItemInInvoice, invoiceItem?.items?.length]
  );

  const onAddEmailInInvoice = useCallback(() => {
    const email = '';
    dispatch(addEmailInInvoice({ email }));
  }, [dispatch]);

  const onDeleteEmailFromInvoice = useCallback(
    (emailIndex) => {
      dispatch(deleteEmailFromInvoice({ emailIndex }));
    },
    [dispatch]
  );

  const validateInputs = useCallback(() => {
    let valid = true;
    setValidationErrors({});
    let validationErrors = {};
    if (!invoiceItem?.lookupId) {
      validationErrors.lookupId = 'Customer is Required';
      valid = false;
    }
    if (!invoiceItem?.number) {
      validationErrors.number = 'Invoice Id is Required';
      valid = false;
    }
    if (invoiceItem?.discount < 0 || invoiceItem?.discount > 100) {
      validationErrors.percent = 'Discount must be between 0 to 100';
      valid = false;
    }
    invoiceItem?.tax?.map((item) => {
      if (item?.value < 0 || item?.value > 100) {
        validationErrors.percent = 'Tax must be between 0 to 100';
        valid = false;
        return valid;
      }
      return valid;
    });
    invoiceItem?.items?.map((item) => {
      if (!item?.desc) {
        validationErrors.item = 'Item description is Required';
        valid = false;
        return valid;
      } else if (!item?.qty) {
        validationErrors.item = 'Item qty is Required';
        valid = false;
        return valid;
      } else if (!item?.unitPrice) {
        validationErrors.item = 'Item unit price is Required';
        valid = false;
        return valid;
      }
      return valid;
    });
    invoiceItem?.emails?.length > 0 &&
      invoiceItem?.emails?.map((email) => {
        if (email && !isEmail(email)) {
          validationErrors.email = 'Please enter a valid Email address';
          valid = false;
          return valid;
        }
        return valid;
      });
    setValidationErrors(validationErrors);
    return valid;
  }, [invoiceItem]);

  const onSubmit = useCallback(
    async (e, saveType) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;
      setValidationErrors();

      let payload = JSON.parse(JSON.stringify(invoiceItem));

      if (saveType === INVOICE_STATUS.DRAFT) {
        payload = { ...payload, status: INVOICE_STATUS.DRAFT };
      } else if (saveType === INVOICE_STATUS.PENDING) {
        payload = { ...payload, status: INVOICE_STATUS.PENDING };
      }

      if (payload?.isSendMeCopy) {
        currentUser?.email && payload?.emails?.push(currentUser?.email);
      }
      if (payload?.isSendCopyToLookup) {
        selectedLookup?.email && payload?.emails?.push(selectedLookup?.email);
      }
      if (payload?.emails?.length > 0) {
        const filteredEmails = payload?.emails?.filter((email) => email);
        payload = { ...payload, emails: filteredEmails };
      }

      const invoice = invoiceId
        ? await dispatch(updateInvoice(invoiceId, payload))
        : await dispatch(createNewInvoice(payload));

      if (invoice) {
        if (invoice?.status === INVOICE_STATUS.DRAFT) {
          history.push('/invoices');
        } else {
          history.push(`/invoices/view/${invoice?.id}`);
        }
      }
    },
    [validateInputs, dispatch, history, invoiceId, selectedLookup, invoiceItem, currentUser?.email]
  );

  const renderErrors = useCallback(
    (propName) => {
      if (propName) {
        if (validationErrors?.[propName]) {
          return <Message text={validationErrors?.[propName]} isSuccess={false} />;
        }
      }
    },
    [validationErrors]
  );

  return (
    <>
      <Form mobile={mobile}>
        <HeaderWraper mobile={mobile}>
          <HeaderLeft mobile={mobile}>
            {mobile ? (
              <SvgWrapper
                onClick={() => {
                  props.history.goBack();
                }}>
                <SVGIcon name='icon-big-left-arrow' fill='var(--gray-icon-color)' size={20} />
              </SvgWrapper>
            ) : null}
            <SVGIcon name='icon-invoice' fill='var(--dark-color)' size={24} />
            <InvoiceTitle>Invoice for {lookupName}</InvoiceTitle>
          </HeaderLeft>
          {(!invoiceId || invoiceItem?.status === INVOICE_STATUS.DRAFT) && (
            <Button
              title='Save as Draft'
              type='button'
              onClick={(e) => {
                onSubmit(e, INVOICE_STATUS.DRAFT);
              }}
              loading={invoiceId && loading}
            />
          )}
        </HeaderWraper>
        <FormInvoice mobile={mobile}>
          <InvoiceTop>
            <DropDownWrapper mobile={mobile}>
              <Label>invoice for</Label>
              <DropDown
                labelField={'name'}
                valueField={'id'}
                placeholder={'Invoice For'}
                options={lookups}
                value={invoiceItem?.lookupId || ''}
                onChange={(lookup) => {
                  updateValue('lookupId', lookup.id);
                }}
              />
              {renderErrors('lookupId')}
            </DropDownWrapper>
            <DropDownWrapper mobile={mobile}>
              <Label>currency</Label>
              <DropDown
                labelField={'text'}
                valueField={'code'}
                placeholder={'Currency'}
                options={currencyNameWithCode}
                value={invoiceItem?.currency || ''}
                onChange={(e) => {
                  updateValue('currency', e.value);
                }}
              />
            </DropDownWrapper>
            <DropDownWrapper mobile={mobile}>
              <InvoiceInput
                isPrimarySmall={true}
                placeholder='invoice id'
                title='invoice id'
                value={invoiceItem?.number || ''}
                onChange={(e) => {
                  updateValue('number', e.target.value);
                }}
              />
              {renderErrors('number')}
            </DropDownWrapper>
            <DropDownWrapper mobile={mobile}>
              <Label>issue date</Label>
              <DatePicker
                autoOk={true}
                className='date-picker'
                value={(invoiceItem?.date && new Date(invoiceItem?.date)) || new Date()}
                onChange={(date) => {
                  updateValue('date', new Date(date).toISOString());
                }}
              />
            </DropDownWrapper>
            <DropDownWrapper mobile={mobile}>
              <Label>Due Days</Label>
              <DropDown
                labelField={'label'}
                valueField={'id'}
                placeholder={'Due Days'}
                options={dueDateOptions}
                value={invoiceItem?.dueDays || 0}
                onChange={(e) => {
                  updateDueDates(e.value);
                }}
              />
            </DropDownWrapper>
            <DropDownWrapper mobile={mobile}>
              <InvoiceInput
                isPrimarySmall={true}
                placeholder='00.00'
                title='Discount'
                type='number'
                min='0'
                max='100'
                value={invoiceItem?.discount || ''}
                onChange={(e) => {
                  updateValue('discount', e.target.value);
                }}
              />
              <Percentage>%</Percentage>
            </DropDownWrapper>
            <DropDownWrapperTex mobile={mobile}>
              {invoiceItem?.tax?.length > 0 &&
                invoiceItem?.tax?.map((item, index) => {
                  return (
                    <DropDownWrapperBlog key={item?.id || index} mobile={mobile}>
                      <InvoiceInput
                        isPrimarySmall={true}
                        placeholder='00.00'
                        value={item?.value === 0 ? '' : parseFloat(item?.value)}
                        type='number'
                        min='0'
                        max='100'
                        title={item?.name}
                        onChange={(e) => {
                          updateValue('value', e.target.value, 'tax', index);
                        }}
                      />
                      <Percentage>%</Percentage>
                    </DropDownWrapperBlog>
                  );
                })}
              <DropDownWrapperLink mobile={mobile}>
                {invoiceItem?.tax?.length > 0 && (
                  <>
                    <Link
                      onClick={() => {
                        onDeleteInvoiceTax();
                      }}>
                      No tax
                    </Link>
                    <LinkSpan></LinkSpan>
                  </>
                )}
                {invoiceItem?.tax?.length < 2 && (
                  <Link
                    onClick={() => {
                      onAddInvoiceTax();
                    }}>
                    {invoiceItem?.tax?.length === 0 ? 'Apply tax to invoice' : 'Apply another tax'}
                  </Link>
                )}
              </DropDownWrapperLink>
            </DropDownWrapperTex>
          </InvoiceTop>
        </FormInvoice>
        {renderErrors('percent')}
        <InvoiceCenter mobile={mobile}>
          <InvoiceTable>
            <Table>
              <Thead>
                <InvoiceTr>
                  <InvoiceTh>Description</InvoiceTh>
                  <InvoiceTh>Qty</InvoiceTh>
                  <InvoiceTh>Unit Price</InvoiceTh>
                  <InvoiceTh>Amount</InvoiceTh>
                  <InvoiceTh></InvoiceTh>
                </InvoiceTr>
              </Thead>
              <Tbody>
                {invoiceItem?.items?.length > 0 &&
                  invoiceItem?.items?.map((item, index) => {
                    return (
                      <InvoiceTr key={item?.id || index}>
                        <InvoiceTd>
                          <InvoiceTdInput
                            isPrimarySmall={true}
                            placeholder='Description'
                            value={item?.desc}
                            type='text'
                            onChange={(e) => {
                              updateValue('desc', e.target.value, 'items', index);
                            }}
                          />
                        </InvoiceTd>
                        <InvoiceTd>
                          <InvoiceTdInput
                            type='number'
                            min='0'
                            isPrimarySmall={true}
                            placeholder='0'
                            value={item?.qty}
                            onChange={(e) => {
                              updateValue('qty', e.target.value, 'items', index);
                              updateValue('amount', e.target.value * item?.unitPrice, 'items', index);
                            }}
                          />
                        </InvoiceTd>
                        <InvoiceTd>
                          <InvoiceTdInput
                            type='number'
                            min='0'
                            isPrimarySmall={true}
                            placeholder='0'
                            value={item?.unitPrice}
                            onChange={(e) => {
                              updateValue('unitPrice', e.target.value, 'items', index);
                              updateValue('amount', e.target.value * item?.qty, 'items', index);
                            }}
                          />
                        </InvoiceTd>
                        <InvoiceTd>
                          <InvoiceTdInput isPrimarySmall={true} placeholder='0' value={item?.amount} disabled />
                        </InvoiceTd>
                        <InvoiceTd title='Delete'>
                          <SvgWrapper
                            className='svgIcon'
                            onClick={() => {
                              onDeleteItemFromInvoice(index);
                            }}>
                            <SVGIcon name='icon-delete' fill='var(--dark-color)' size={16} />
                          </SvgWrapper>
                        </InvoiceTd>
                      </InvoiceTr>
                    );
                  })}
              </Tbody>
            </Table>
          </InvoiceTable>
          {renderErrors('item')}
          <Button secondary={true} title='Add Item' onClick={onAddItemInInvoice} type='button' />
          <InvoiceWrapper mobile={mobile}>
            <TextArea
              id='business'
              name='business'
              placeholder='Notes'
              value={invoiceItem?.notes || ''}
              onChange={(e) => {
                updateValue('notes', e.target.value);
              }}></TextArea>
            <AmountWrapper mobile={mobile}>
              <Ul>
                <Li>
                  <TitleWrapper>
                    <SubLabel>Sub Total :</SubLabel>
                  </TitleWrapper>
                  <AmountDueWrapper>
                    <AmountLabel>
                      {`${currencySymbol?.symbol}`}
                      {Number(invoiceItem?.subTotal).toFixed(2)}
                    </AmountLabel>
                  </AmountDueWrapper>
                </Li>
                <Li>
                  <TitleWrapper>
                    <SubLabel>Discount ({Number(invoiceItem?.discount)}%) :</SubLabel>
                  </TitleWrapper>
                  <AmountDueWrapper>
                    <AmountLabel>
                      - {`${currencySymbol?.symbol}`}
                      {Number(invoiceItem?.totalDiscount).toFixed(2)}
                    </AmountLabel>
                  </AmountDueWrapper>
                </Li>
                {invoiceItem?.tax?.length > 0 && (
                  <Li>
                    <TitleWrapper>
                      <SubLabel>Tax ({Number(invoiceItem?.tax[0].value)}%) :</SubLabel>
                    </TitleWrapper>
                    <AmountDueWrapper>
                      <AmountLabel>
                        {`${currencySymbol?.symbol}`}
                        {Number(invoiceItem?.totalTax)?.toFixed(2)}
                      </AmountLabel>
                    </AmountDueWrapper>
                  </Li>
                )}
                {invoiceItem?.tax?.length === 2 && (
                  <Li>
                    <TitleWrapper>
                      <SubLabel>Tax 2 ({Number(invoiceItem?.tax[1]?.value)}%) :</SubLabel>
                    </TitleWrapper>
                    <AmountDueWrapper>
                      <AmountLabel>
                        {`${currencySymbol?.symbol}`}
                        {Number(invoiceItem?.totalTax2)?.toFixed(2)}
                      </AmountLabel>
                    </AmountDueWrapper>
                  </Li>
                )}
                <Border></Border>
                <Li>
                  <TitleWrapper>
                    <SubLabel>Amount Due :</SubLabel>
                  </TitleWrapper>
                  <AmountDueWrapper>
                    <AmountLabel>
                      {`${currencySymbol?.symbol}`}
                      {Number(invoiceItem?.totalAmount)?.toFixed(2)}
                    </AmountLabel>
                  </AmountDueWrapper>
                </Li>
              </Ul>
            </AmountWrapper>
          </InvoiceWrapper>
          <InvoiceFooter mobile={mobile}>
            <FooterLeft>
              <Span>Email to</Span>
              {selectedLookup && selectedLookup?.email && lookupName && (
                <LabelInput>
                  <input
                    type='checkbox'
                    checked={invoiceItem?.isSendCopyToLookup}
                    onChange={() => {
                      updateValue('isSendCopyToLookup', !invoiceItem?.isSendCopyToLookup);
                    }}
                  />
                  <span className='checkmark'></span>
                  {lookupName && lookupName}
                  <Link>{selectedLookup?.email ? ` (${selectedLookup?.email})` : ''} </Link>
                </LabelInput>
              )}
              <LabelInput>
                <input
                  type='checkbox'
                  checked={invoiceItem?.isSendMeCopy}
                  onChange={() => {
                    updateValue('isSendMeCopy', !invoiceItem?.isSendMeCopy);
                  }}
                />
                <span className='checkmark'></span>
                Send me a copy
              </LabelInput>
              {invoiceItem?.emails?.map((email, index) => {
                return (
                  <div style={{ display: 'flex', padding: '5px' }} key={index}>
                    <InvoiceInput
                      isPrimarySmall={true}
                      title=''
                      placeholder='email'
                      value={email || ''}
                      onChange={(e) => {
                        updateValue(undefined, e.target.value, 'emails', index);
                      }}
                    />
                    <SvgWrapper
                      className='svgIcon'
                      onClick={() => {
                        onDeleteEmailFromInvoice(index);
                      }}>
                      <SVGIcon name='icon-delete' fill='var(--dark-color)' size={16} />
                    </SvgWrapper>
                  </div>
                );
              })}
              {renderErrors('email')}
              <Labelelse>
                Sending to someone else?{' '}
                <Link
                  onClick={() => {
                    onAddEmailInInvoice();
                  }}>
                  Add Email
                </Link>
              </Labelelse>
            </FooterLeft>
            <FooterRight mobile={mobile}>
              <Button
                title='Save & Send'
                type='button'
                loading={invoiceId && loading}
                onClick={(e) => {
                  onSubmit(e, INVOICE_STATUS.PENDING);
                }}
              />
              <Button
                secondary={true}
                title='Cancel'
                type='button'
                onClick={() => {
                  invoiceId ? history.push(`/invoices/view/${invoiceId}`) : history.push('/invoices');
                }}
              />
            </FooterRight>
          </InvoiceFooter>
        </InvoiceCenter>
      </Form>
    </>
  );
};
export default NewInvoice;
