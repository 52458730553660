import styled from 'styled-components';
import { Text2 } from '../../styles';

const WindowMainNavigation = styled.div`
  height: 30px;
  padding: 0px 7px 0px 17px;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cultured-color);
  box-shadow: 0px 1px 0px var(--gray-border-color);
  position: relative;
  z-index: 3;
`;

const NavigationLeft = styled.div``;
const SalescampSpan = styled.span`
  ${Text2}
  color: var(--gray-icon-color);
`;
const NavigationRight = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-app-region: no-drag;
`;

const Li = styled.li`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  transition: all 300ms;
  :hover {
    background-color: var(--gray-border-color);
  }
`;

export { WindowMainNavigation, NavigationLeft, SalescampSpan, NavigationRight, Li };
