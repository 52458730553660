import { MenuItem, withStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Button from '../../../components/atom/Button/Button';
import { Header2, Header4, Header5, Text1, Text2 } from '../../../styles';

const MainList = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`;
const MainWrap = styled.div`
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
          padding: 0 16px 16px 16px;
        `
      : css`
          max-width: 680px;
          padding: 24px 0px;
        `}
`;
const HeadView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h2`
  margin: 0;
  ${Header2};
  color: var(--dark-color);
`;
const ContainBox = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  &:last-child {
    border-bottom: 0;
  }
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
          padding: 12px;
        `
      : css`
          padding: 12px 16px;
        `}
`;
const FirstList = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          margin-bottom: 14px;
          width: 100%;
        `
      : css`
          margin-bottom: 0px;
        `}
`;
const FirstRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  ${(props) =>
    props.mobile &&
    css`
      flex-wrap: wrap;
    `}
`;
const SubText = styled.h4`
  margin: 0;
  font-style: normal;
  color: var(--dark-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 330px;
  ${(props) =>
    props.mobile
      ? css`
          ${Header5}
        `
      : css`
          ${Header4};
        `}
`;
const PrimaryText = styled.span`
  ${Text2}
  background-color: var(--popup-color-hover);
  padding: 2px 10px;
  border-radius: 14px;
  color: var(--gray-color);
  ${(props) =>
    props.mobile
      ? css`
          white-space: nowrap;
          padding: 2px 8px;
          margin-left: 6px;
        `
      : css`
          padding: 2px 10px;
          margin-left: 10px;
        `}
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 330px;
`;
const SecondList = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      margin-left: auto;
    `}
`;
const LeftIcon = styled.div`
  padding-right: 15px;
  cursor: pointer;
`;
const RightIcon = styled.div`
  padding-left: 15px;
  border-left: 1px solid var(--gray-border-color);
  cursor: pointer;
  height: 20px;
`;
//Popup
const MainPopup = styled.div`
  padding: 12px;
`;
const ContainView = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const TextPopup = styled.span`
  ${Text1};
  font-style: normal;
  color: var(--dark-color);
  margin-left: 12px;
`;
const LastView = styled.div`
  border-top: 1px solid var(--gray-border-color);
  padding-top: 12px;
`;
const SetText = styled.span`
  ${Text1};
  font-style: normal;
  color: var(--dark-color);
  cursor: pointer;
`;
const MainContact = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          position: fixed;
          top: auto;
          bottom: 0;
          height: calc(100% - 54px);
          overflow-y: auto;
          left: 0;
          background-color: var(--white-color);
        `
      : css`
          position: relative;
        `}
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Platinum-color);
`;
const TitleText = styled.span`
  ${Header4};
  display: inline-block;
  margin-left: 12px;
  color: var(--dark-color);
`;
const OptionWrapper = styled.div``;
const OptionBoxUl = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  padding: 16px 8px 16px 8px;
  margin: 0;
  border-bottom: 1px solid var(--gray-border-color);
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
        `
      : css``}
`;
const OptionBoxLi = styled.li`
  width: 100%;
  padding: 0 8px;
  list-style: none;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 100%;
        `
      : css`
          max-width: 33.33%;
        `}
`;
const OptionBox = styled.div`
  height: 100%;
`;
const OptionBoxLabel = styled.div`
  border-radius: 4px;
  display: flex;
  padding: 24px 16px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--gray-border-color);
  position: relative;
  z-index: 1;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid var(--primary-color);
      ::before,
      ::after {
        top: 96%;
        left: 50%;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      ::before {
        z-index: 2;
        border: 12px solid var(--before-after-color);
        border-bottom-color: var(--gray-border-color);
        margin-left: -12px;
      }
      ::after {
        z-index: 2;
        border: 13px solid var(--before-after-color);
        border-bottom-color: var(--white-color);
        margin-left: -13px;
      }
    `}
  :hover {
    border: 1px solid var(--primary-color);
    cursor: pointer;
  }
`;
const OptionBoxSpan = styled.span`
  ${Header4}
  display: inline-block;
  margin-top: 16px;
  color: var(--dark-color);
`;
const OptionBoxSpanPara = styled.p`
  ${Text1}
  color:var(--gray-color);
  margin: 6px 0 0 0;
`;
const StepWrapper = styled.div``;
const Step1 = styled.div``;
const Step2 = styled.div``;
const Step3 = styled.div``;
const StepPara = styled.p`
  margin: 0;
  padding: 16px;
  ${Text1}
  color:var(--gray-color);
  letter-spacing: 0.4px;
  a {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }
`;
const BottomView = styled.div`
  border-top: 1px solid var(--gray-border-color);
  padding: 16px;
  display: flex;
  align-items: center;
  background: var(--white-color);
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
        `
      : css``}
`;
const Form = styled.form``;
const InputSend = styled.div`
  padding: 16px;
`;
const TwoInput = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 0;
  div:last-child {
    ${(props) =>
      props.mobile
        ? css`
            flex-wrap: wrap;
            margin-left: 0px;
          `
        : css`
            margin-left: 8px;
          `}
  }
  div:first-child {
    margin-right: 8px;
    ${(props) =>
      props.mobile
        ? css`
            flex-wrap: wrap;
            margin-right: 0px;
            margin-bottom: 12px;
          `
        : css`
            margin-right: 8px;
            margin-bottom: 0px;
          `}
  }
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
        `
      : css``}
`;
const TwoInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  div:first-child {
    margin-right: 8px;
    width: 76%;
  }
  div:last-child {
    margin-left: 8px;
    width: 24%;
  }
`;
const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const RightPara = styled.p`
  margin: 0 0 0 6px;
  color: var(--gray-color);
  ${Text1}
`;
const ListWrappper = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 0px;
          margin-top: 12px;
        `
      : css`
          margin-left: 16px;
        `}
`;
const DropDownTitle = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  span {
    ${Text1};
    margin-left: 3px;
    color: #f51a1a;
  }
`;
const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SynceWrapper = styled.div`
  padding: 16px 16px 0 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const HeadingTitle = styled.h3`
  margin: 0 0 20px 0;
  ${Text1}
  color:var(--gray-color);
`;
const ListWrapper = styled.div`
  margin-bottom: 16px;
`;
const ListTitel = styled.h4`
  margin: 0 0 12px 0;
  ${Text1}
  color:var(--dark-color);
`;
const ModifyUserOptionItemListLi = styled.li`
  list-style: none;
  cursor: pointer;
  position: relative;
  margin-bottom: 12px;
  input {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 17px;
    height: 17px;
    z-index: 1;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: var(--white-color);
    border-radius: 100%;
    border: 2px solid var(--gray-border-color);
    margin: 0;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 2px;
    top: 2px;
    width: 9px;
    height: 9px;
    background-color: var(--primary-color);
    border-radius: 100%;
  }
`;
const LabelInput = styled.label`
  display: inline;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  ${Text1}
  text-transform: capitalize;
  color: var(--dark-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const KnowTitel = styled.h4`
  ${Text2}
  color:var(--gray-color);
  margin: 0;
  span {
    color: var(--primary-color);
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ModalStyle = {
  width: '640px'
};
const WrapperStep = styled.div``;
const Wrapper = styled.div`
  padding: 16px 16px 4px 16px;
`;
const DropDownWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 0;
  ${(props) =>
    props.mobile
      ? css`
          flex-wrap: wrap;
        `
      : css``}
`;
const Multi = styled.h4`
  margin: 12px 0 12px 0;
  ${Text1}
  color:var(--dark-color);
`;
const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ButtonWrapper = styled.div`
  margin-left: 12px;
`;
const OptionMainWrap = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const UserShareBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: end;
  margin-left: 18px;
`;
const IconWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--white-color);
`;
const AvtarWrapperBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--main-bg);
  border-radius: 100%;
  margin-left: -8px;
  position: relative;
  z-index: 2;
  background-color: var(--primary-color-secondery-active);
  ${Text2}
  white-space: nowrap;
`;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      minHeight: 'auto'
    },
    '&:hover': {
      background: 'var(--popup-color-hover)'
    },
    borderRadius: '5px;',
    fontFamily: 'Inter;',
    fontStyle: 'normal;',
    fontWeight: '500;',
    fontSize: '14px;',
    lineHeight: '17px;',
    letterSpacing: '0.2px;',
    color: 'var(--dark-color);',
    margin: '2px 6px',
    padding: '5px 6px',
    cursor: 'pointer'
  }
}))(MenuItem);

const InputEmailWrapperModal = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-left: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    right: 0px;
    margin: 0;
    top: 2px;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    right: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const InputLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ProfileName = styled.span`
  ${Text1};
  color: var(--dark-color);
  margin-left: 12px;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SyncMainBlock = styled.div`
  display: flex;
  align-items: center;
`;
const SyncTimeBlock = styled.div`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding-left: 8px;
`;
const TimeSapn = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin-left: 3px;
`;
const Circle = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: var(--gray-border-color);
  position: absolute;
  left: 0;
  top: 7px;
`;
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      margin-left: auto;
    `}
`;

const IconButton = styled(Button)`
  margin-right: 16px;
  ${(props) =>
    props.mobile
      ? css`
          padding: 5px;
        `
      : css`
          padding: 8px;
        `}
`;
const InvalidAccountBlock = styled.div`
  font-style: normal;
  ${Text1};
  color: var(--danger-Base-active);
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding-left: 8px;
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;

export {
  MainList,
  Form,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  ListBox,
  FirstList,
  FirstRow,
  SubText,
  CaptionText,
  PrimaryText,
  SecondList,
  LeftIcon,
  RightIcon,
  MainPopup,
  ContainView,
  InputWrapperModal,
  TextPopup,
  LastView,
  SetText,
  MainContact,
  TitleView,
  TitleText,
  OptionBoxUl,
  OptionBoxLi,
  OptionBox,
  OptionBoxLabel,
  OptionBoxSpan,
  OptionBoxSpanPara,
  OptionWrapper,
  StepWrapper,
  Step1,
  Step2,
  Step3,
  StepPara,
  BottomView,
  LeftWrapper,
  TwoInput,
  TwoInputWrapper,
  RightWrapper,
  RightPara,
  ListWrappper,
  DropDownTitle,
  BottomWrapper,
  SynceWrapper,
  HeadingTitle,
  ListWrapper,
  ListTitel,
  ModifyUserOptionItemListLi,
  LabelInput,
  KnowTitel,
  HeaderWrapper,
  ModalStyle,
  InputSend,
  Wrapper,
  WrapperStep,
  DropDownWrapper,
  Multi,
  SvgWrapper,
  ButtonWrapper,
  OptionMainWrap,
  UserShareBlock,
  IconWrapper,
  AvtarWrapperBox,
  StyledMenuItem,
  InputEmailWrapperModal,
  InputLabel,
  ProfileName,
  SyncMainBlock,
  SyncTimeBlock,
  TimeSapn,
  Circle,
  ButtonWrap,
  IconButton,
  InvalidAccountBlock,
  MobileButton
};
