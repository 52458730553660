import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Paragraph = styled.p`
  &.statusText {
    color: #f51a1a;
    font-size: 14px;
    margin: 20px 0;
    font-weight: 500;
    text-align: center;
  }
`;

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: 'Status',
      isSuccess: false
    };
  }
  render() {
    const { text, isSuccess } = this.props;
    return (
      <div className={classNames('statusCover', isSuccess ? 'successBox' : 'errorBox')}>
        <Paragraph className='statusText'>{text === undefined ? (isSuccess ? 'Success' : 'Error') : text}</Paragraph>
      </div>
    );
  }
}

export default Message;
