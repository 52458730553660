import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addActivityCommentItem, updateLookupActivityItem } from '../../../../../actions/activityActions';
import { ACTIVITY_ACTION } from '../../../../../constants/constant';
import { getUniqueId } from '../../../../../helpers/common';
import { addActivityComment, updateLocalLookupActivityItem } from '../../../../../services/activityService';
import AddCommentActivity from '../../AddActivity/AddCommentActivity';
import { CommentBtnWrapper, CommentInputBlock, IconBtn } from './styles';

const RenderCommentInput = ({ item, autoFocus }) => {
  const organizationSelector = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;
  const { organization } = organizationSelector;
  const dispatch = useDispatch();
  const { id: organization_id } = organization;
  const [commentItem, setCommentItem] = useState({});

  const onChangeCommentInput = useCallback((id, value) => {
    const newItem = {
      id,
      comment: value
    };
    setCommentItem(newItem);
  }, []);

  const onClickPostComment = useCallback(
    async (e) => {
      e.preventDefault();
      if (!commentItem?.comment && commentItem?.comment?.trim() === '') return;
      try {
        setLoading(true);
        const newComment = commentItem?.comment?.replaceAll('@', '');
        const payload = {
          localId: getUniqueId(),
          comment: newComment,
          taggedUsers
        };
        // For show message instatnce in comment list
        const commentNewItem = {
          ...payload,
          createdOn: new Date().toISOString(),
          createdBy: currentUser?.id
        };
        dispatch(addActivityCommentItem({ id: item?.id, item: commentNewItem, messageId: item?.messageId }));
        //For show comment open
        if (item?.isCommentsClosed) {
          let commentList = item?.comments || [];
          commentList = commentList?.concat(commentNewItem);
          const newItem = {
            ...item,
            isCommentsClosed: false,
            comments: commentList,
            commentsClosedOn: undefined,
            commentsClosedBy: undefined
          };
          dispatch(updateLookupActivityItem({ id: item?.id, item: newItem }));
        }
        setCommentItem({ ...commentItem, comment: '' });
        setTaggedUsers([]);

        if (item?.action === ACTIVITY_ACTION.Email && item?.threadId && item?.messages?.length > 0) {
          payload.emailThreadId = item?.threadId;
          payload.emailMessageId = item?.messages[0]?.id;
          payload.emailSubject = item?.messages[0]?.subject;
          payload.emailSnippet = item?.messages[0]?.snippet;
        }
        //Comment activity present in sync email for a message
        const activityId = item?.commentActivityId || item?.id;
        const result = await dispatch(addActivityComment(organization_id, activityId, payload));
        if (result) {
          setLoading(false);
          // Update Local activity Item
          await dispatch(updateLocalLookupActivityItem(item?.id));
        }
      } catch (e) {
        console.log('onClickPostComment ERROR', e);
        setLoading(false);
      }
    },
    [commentItem, currentUser?.id, dispatch, item, organization_id, taggedUsers]
  );
  const onAddMentionUser = useCallback(
    (user) => {
      taggedUsers?.push(user);
      setTaggedUsers(taggedUsers);
    },
    [taggedUsers]
  );
  return (
    <>
      <CommentInputBlock onSubmit={onClickPostComment}>
        <AddCommentActivity
          autoFocus={autoFocus}
          onSubmit={onClickPostComment}
          value={commentItem?.comment}
          onAddMentionUser={onAddMentionUser}
          onChange={(value) => {
            onChangeCommentInput(item?.id, value);
          }}
        />
        <CommentBtnWrapper>
          {/* <IconBtn
            className={hasProduction() ? 'inDevelopment' : ''}
            iconName='icon-smiley'
            iconColor='var(--gray-icon-color)'
            type='button'
            fontSize={20}></IconBtn> */}
          <IconBtn
            iconName='icon-send-email'
            iconColor='var(--gray-icon-color)'
            type='submit'
            fontSize={20}
            disabled={loading}></IconBtn>
        </CommentBtnWrapper>
      </CommentInputBlock>
    </>
  );
};

export default RenderCommentInput;
