import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const reportLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_REPORT_LOADER
});

const overviewReportReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_OVERVIEW_REPORT
});

const activityReportReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_ACTIVITY_REPORT
});

const statusChangeReportReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_STATUS_CHANGE_REPORT
});

const callSummaryReportReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CALL_SUMMARY_REPORT
});

const activityEventsReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_ACTIVITY_EVENTS, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_ACTIVITY_EVENTS, (state, action) => {
      const eventIndex = state?.findIndex((event) => event?.id === action?.payload?.eventId);
      state[eventIndex][action.payload.propName] = action.payload.value;
    });
});

const activityUsersReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_ACTIVITY_USERS, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_ACTIVITY_USERS, (state, action) => {
      const userIndex = state?.findIndex((item) => item?.user?.id === action?.payload?.userId);
      state[userIndex][action.payload.propName] = action.payload.value;
    });
});

const callSummaryEventsReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_CALL_SUMMARY_EVENTS, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_CALL_SUMMARY_EVENTS, (state, action) => {
      const eventIndex = state?.findIndex((event) => event?.id === action?.payload?.eventId);
      state[eventIndex][action.payload.propName] = action.payload.value;
    });
});

const callSummaryUsersReducer = createReducerOrig([], (builder) => {
  builder
    .addCase(Actions.SET_CALL_SUMMARY_USERS, (state = [], action) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_CALL_SUMMARY_USERS, (state, action) => {
      const userIndex = state?.findIndex((item) => item?.user?.id === action?.payload?.userId);
      state[userIndex][action.payload.propName] = action.payload.value;
    });
});

const reportReducer = combineReducers({
  loading: reportLoaderReducer,
  overviewReport: overviewReportReducer,
  activityReport: activityReportReducer,
  activityUsers: activityUsersReducer,
  activityEvents: activityEventsReducer,
  statusChangeReport: statusChangeReportReducer,
  callSummaryReport: callSummaryReportReducer,
  callSummaryUsers: callSummaryUsersReducer,
  callSummaryEvents: callSummaryEventsReducer
});

export default reportReducer;
