import moment from 'moment';
import { useMemo } from 'react';

import classNames from 'classnames';
import Avtar from '../../../../../components/Avtar/Avtar';

import useMobileDevice from '../../../../../hooks/useMobileDevice';
import { ACTIVITY_ACTION } from '../../../../../constants/constant';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
import CommentHoverItem from '../Comments/CommentHoverItem';
import RenderComments from '../Comments/RenderComments';
import { getGravatarURL } from '../../../../../helpers/common';

import {
  MainList,
  ActivityUpperBlock,
  IconList,
  IconWrapper,
  AllList,
  RightList,
  UserText,
  UserRecive,
  ActivityBottomBlock,
  ContainView,
  FileMain,
  FileText,
  Captiontitle,
  ThraedInner,
  UserWrapperBlock
} from '../styles';
const CommentActivityItem = ({
  item,
  svgIconSize,
  isOpenThread,
  onLookupClick,
  onMoreMenuClick: handleClickOnMoreOptionActivity,
  isOpenMoreOptionMenu,
  selectedActivity,
  isShowCommentInput,
  onClickComment
}) => {
  const mobile = useMobileDevice();
  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);
  return (
    <>
      <MainList key={item?.id}>
        <ActivityUpperBlock mobile={mobile} isOpenThread={isOpenThread}>
          {isOpenThread ? (
            item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
          ) : (
            <IconList>
              <IconWrapper bgColor='#ED9190' mobile={mobile}>
                <SVGIcon fill='var(--white-color)' size={svgIconSize} name='icon-message' strokeWidth={1.8} />
              </IconWrapper>
            </IconList>
          )}

          <AllList>
            <RightList mobile={mobile} isOpenThread={isOpenThread}>
              {isOpenThread && item?.lookup && (
                <ThraedInner
                  onClick={() => {
                    onLookupClick(item?.lookup);
                  }}>
                  <UserText>{item?.lookup?.name}</UserText>
                </ThraedInner>
              )}
              <UserWrapperBlock mobile={mobile}>
                <UserText isOpenThread={isOpenThread} mobile={mobile}>
                  {item?.assignedUser?.name}
                </UserText>
                <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                  added comments
                </UserRecive>
                <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                  {moment(item?.time).fromNow()}
                </UserRecive>
              </UserWrapperBlock>
            </RightList>
          </AllList>
        </ActivityUpperBlock>
        <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
          <ContainView notes={item?.action === ACTIVITY_ACTION.Notes}>
            <FileMain>
              <FileText className={classNames({ fileBorder: item?.action === ACTIVITY_ACTION.File })}>
                <Captiontitle className='quill_format' dangerouslySetInnerHTML={{ __html: item?.title }}></Captiontitle>
              </FileText>
            </FileMain>
            {handleClickOnMoreOptionActivity && (
              <CommentHoverItem
                item={item}
                isOpenMoreOptionMenu={isOpenMoreOptionMenu}
                selectedActivity={selectedActivity}
                onClickMoreMenu={handleClickOnMoreOptionActivity}
                onClickComment={onClickComment}
              />
            )}
          </ContainView>
          <RenderComments item={item} isShowCommentInput={isShowCommentInput} selectedActivity={selectedActivity} />
        </ActivityBottomBlock>
      </MainList>
    </>
  );
};

export default CommentActivityItem;
