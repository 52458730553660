import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Modal } from '@material-ui/core';
import Modal from '../../components/Modal';
import SnackBar from '../../components/SnackBar/SnackBar';
import Button from '../../components/atom/Button/Button';
import { Menu, MenuItem } from '../../components/Menu';
import {
  getContactSuggestions,
  removeContactSuggestions,
  skipContactSuggestions,
  skipAllContactSuggestions
} from '../../services/dashboardService';
import { LOOKUP_TYPE, LOOKUP_UPDATE_SOURCE } from '../../constants/constant';
import AddNewContact from '../Contact/AddNewContact';
import { deleteContactSuggestionItem } from '../../actions/dashboardActions';
import { isEmpty } from '../../helpers/common';
import SVGIcon from '../../assets/svg/SVGIcon';
import { HeadView } from '../Setting/EmailTemplate/styles';
import MobileTitleBar from '../../components/TitleBar/MobileTitleBar';
import useMobileDevice from '../../hooks/useMobileDevice';
import {
  MainContain,
  Title,
  RowView,
  HeaderWrapper,
  WelView,
  ContainList,
  RowList,
  InboxSuggestion,
  ButtonWrapper,
  Iconbg,
  RowBox,
  UserDetails,
  UserEmail,
  IconWrapper,
  ContactListIcon,
  HeaderTitle,
  MobileRight
} from './styles';

const InboxContactSuggestion = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isOpenSaveContact, setIsOpenSaveContact] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [table, setTable] = useState();
  const dashboard = useSelector((state) => state.dashboard);
  const organizationSelector = useSelector((state) => state.organization);
  const { contactSuggestions } = dashboard;
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList } = lookupTableSelector;
  const loadData = useCallback(() => {
    dispatch(getContactSuggestions(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  const onClickSkipSuggestions = useCallback(
    async (item) => {
      const result = await dispatch(skipContactSuggestions(organization_id, item?.id));
      if (result) {
        dispatch(deleteContactSuggestionItem({ item: selectedItem }));
        loadData();
      }
    },
    [dispatch, loadData, organization_id, selectedItem]
  );

  const onClickSkipAllSuggestions = useCallback(
    async (item) => {
      const payload = contactSuggestions?.map((item) => {
        return item?.id;
      });
      const result = await dispatch(skipAllContactSuggestions(organization_id, payload));
      if (result) {
        loadData();
        props.history.goBack();
      }
    },
    [contactSuggestions, dispatch, loadData, organization_id, props.history]
  );

  const contactTables = useMemo(() => {
    const contactList = lookupTableList?.filter((x) => x?.type === LOOKUP_TYPE.contacts);
    return contactList || [];
  }, [lookupTableList]);

  const isMultipleContactTable = useMemo(() => {
    return contactTables?.length > 1 ? true : false;
  }, [contactTables?.length]);

  const openSaveContact = useCallback(
    (e, item) => {
      e.stopPropagation();
      let newContact = {
        ...item
      };
      if (!isEmpty(item?.name)) {
        let lastname = '';
        let firstname;
        const nameStrings = item?.name?.split(' ');
        if (nameStrings) {
          nameStrings?.forEach((x, i) => {
            if (i === 0) firstname = x;
            else lastname = lastname.concat(x);
            if (i !== 0 && nameStrings.length - 1 !== i) lastname = lastname.concat(' ');
          });
          newContact = {
            ...newContact,
            firstname,
            lastname
          };
        }
      }
      setSelectedItem(newContact);
      if (isMultipleContactTable) {
        setIsOpenSaveContact(true);
        setMenuAnchorEl(e.currentTarget);
      } else {
        setIsOpenAddModal(true);
        setTable(lookupTableList[0]);
      }
    },
    [isMultipleContactTable, lookupTableList]
  );

  const closeSaveContact = () => {
    setIsOpenSaveContact(false);
    setMenuAnchorEl(null);
    setSelectedItem();
  };

  const openAddModal = useCallback((item) => {
    setIsOpenAddModal(true);
    setTable(item);
    setIsOpenSaveContact(false);
    setMenuAnchorEl(null);
  }, []);

  const closeAddModal = useCallback(() => {
    closeSaveContact();
    setIsOpenAddModal(false);
  }, []);

  const onSave = useCallback(() => {
    dispatch(deleteContactSuggestionItem({ item: selectedItem }));
    dispatch(removeContactSuggestions(organization_id, selectedItem?.id));
    closeAddModal();
  }, [closeAddModal, dispatch, organization_id, selectedItem]);

  return (
    <>
      {mobile && (
        <MobileTitleBar hasNavBar={false} hasTitle={false}>
          <MobileRight>
            <HeaderTitle mobile={mobile}>Screening</HeaderTitle>
            <Button
              iconColor='var(--gray-color)'
              fontSize='20px'
              title='skip All Contacts'
              onClick={onClickSkipAllSuggestions}></Button>
          </MobileRight>
        </MobileTitleBar>
      )}
      <MainContain>
        <WelView>
          <ContainList>
            <RowBox mobile={mobile}>
              {mobile ? null : (
                <HeaderWrapper>
                  <HeadView>
                    <RowView>
                      <ContactListIcon onClick={props.history.goBack}>
                        <SVGIcon name='icon-list' fill='var(--primary-color)' size={26} />
                      </ContactListIcon>
                      <Title>Screening</Title>
                    </RowView>
                    <Button
                      iconColor='var(--gray-color)'
                      fontSize='20px'
                      title='skip All Contacts'
                      onClick={onClickSkipAllSuggestions}></Button>
                  </HeadView>
                </HeaderWrapper>
              )}
              {contactSuggestions?.map((item, index) => {
                return (
                  <RowList key={`${item?.id}_${index}`} mobile={mobile}>
                    <InboxSuggestion>
                      <IconWrapper bgColor='var(--icon-suggestion)'>
                        <Iconbg name='icon-idea' fontSize='20' />
                      </IconWrapper>
                      <UserDetails>
                        <UserEmail>{`${item?.name} <${item?.email}>`}</UserEmail>
                        <ButtonWrapper>
                          <Button
                            iconName='icon-save'
                            iconColor='var(--gray-color)'
                            fontSize='20px'
                            secondary
                            title='Save Contact'
                            onClick={(e) => {
                              openSaveContact(e, item);
                            }}></Button>
                          <Button
                            iconName='icon-next'
                            iconColor='var(--gray-color)'
                            fontSize='20px'
                            secondary
                            title='skip'
                            onClick={(e) => {
                              onClickSkipSuggestions(item);
                            }}></Button>
                        </ButtonWrapper>
                      </UserDetails>
                    </InboxSuggestion>
                  </RowList>
                );
              })}
            </RowBox>
          </ContainList>
        </WelView>
      </MainContain>
      <Menu
        id='save-contact-menu'
        anchorEl={menuAnchorEl}
        open={isOpenSaveContact}
        onClose={closeSaveContact}
        getContentAnchorEl={null}
        position='left'
        positionVertical='top'
        marginTop='40px'>
        {contactTables?.map((item) => {
          return (
            <MenuItem
              fontSize='16'
              color='var(--gray-icon-color)'
              text={item?.label}
              onClick={() => {
                openAddModal(item);
              }}
            />
          );
        })}
      </Menu>
      <Modal open={isOpenAddModal} onClose={closeAddModal}>
        <AddNewContact
          handleCloseModal={closeAddModal}
          onSave={onSave}
          item={selectedItem}
          source={LOOKUP_UPDATE_SOURCE.LIST}
          table={table}
        />
      </Modal>
      <SnackBar />
    </>
  );
};

export default InboxContactSuggestion;
