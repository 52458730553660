import styled, { css } from 'styled-components';
import Button from '../../../components/atom/Button/Button';
import { isElectronApp } from '../../../global/Helper';
import { Text1 } from '../../../styles';
const MainContact = styled.div`
  background: var(--white-color);
  box-shadow: 0px 6px 25px var(--modal-box-shadow);
  overflow: hidden;
  outline: none;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
          height: calc(100% - 43px);
          position: fixed;
          top: auto;
          bottom: 0;
          left: 0;
          transform: none;
        `
      : css`
          position: relative;
          height: auto;
          border-radius: 6px;
        `}
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid var(--gray-border-color);
  background: var(--popup-color-hover);
`;
const TitleText = styled.span`
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
`;
const IconView = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SecondView = styled.div`
  padding: 12px 16px 0 16px;
  ${(props) =>
    props.mobile
      ? css`
          flex: 0;
        `
      : css`
          flex: 1 0 auto;
        `}
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--gray-border-color);
  padding: 8px;
  background-color: var(--white-color);
  ${(props) =>
    props.mobile &&
    css`
      margin-top: 0;
    `}
`;
const BtnView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;
const Li = styled.li`
  margin-right: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  :last-child {
    position: relative;
    input {
      position: absolute;
      top: 0;
      width: 30px;
      left: 0;
      opacity: 0;
      height: 30px;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;
      text-indent: -999px;
    }
  }
  :hover {
    background-color: var(--popup-color-hover);
  }
  ${(props) =>
    props.active &&
    css`
      background-color: var(--popup-color-hover);
    `}
  ${() =>
    isElectronApp() &&
    css`
      border-radius: 4px;
      :hover {
        background-color: var(--popup-color-hover);
      }
    `}
`;
const From = styled.form`
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.mobile
      ? css`
          height: calc(100% - 60px);
        `
      : css`
          height: calc(100% - 99px);
        `}
`;
const FromView = styled.div`
  margin-bottom: 8px;
`;
const FromText = styled.span`
  ${Text1};
  color: var(--spanish-gray);
  margin-right: 10px;
`;
const FromDropView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .css-i0syzg-menu {
    z-index: 2;
  }
`;
const FirstView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;
const SelectView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
const ToText = styled.span`
  ${Text1};
  color: var(--spanish-gray);
  margin-right: 2px;
  margin-top: 5px;
`;
const InputEmail = styled.input`
  width: 100%;
  border: none;
  padding: 0;
  height: 22px;
  padding: 0px 10px;
  :focus {
    outline: none;
  }
`;
const InputView = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray-border-color);
  border-bottom: 1px solid var(--gray-border-color);
  input {
    padding: 12px 16px;
    border-radius: 0px;
    border: none;
    :focus {
      border: none;
      box-shadow: none;
    }
  }
`;
const EmailTextArea = styled.div`
  padding: 12px 16px 12px 16px;
  textarea {
    width: 100%;
    border: none;
    resize: none;
    ::placeholder {
      ${Text1}
      color: var(--light-gray);
      font-family: var(--primary-font-stack);
    }
    :focus {
      outline: none;
    }
  }
`;
const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          height: 100%;
          max-height: none;
          min-height: none;
        `
      : css`
          max-height: 360px;
          min-height: 480px;
          @media (max-height: 839px) {
            min-height: 360px;
            max-height: 180px;
          }
        `}
`;
const TextEditer = styled.div`
  margin: 8px;
  border: 1px solid transparent;
  position: relative;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.isShowQuill &&
    css`
      margin: 0px;
    `}
  ${(props) =>
    props.hidequill &&
    css`
      .ql-toolbar.ql-snow {
        display: none;
      }
    `}
  ${(props) =>
    props.mobile &&
    css`
      flex-direction: column-reverse;
      flex: 0;
      .ql-container.ql-snow {
        height: 100%;
      }
      .ql-toolbar.ql-snow {
        padding: 8px 12px 8px 110px;
        display: flex;
        align-items: center;
        overflow: auto;
        .ql-formats {
          :nth-child(1) {
            position: absolute;
            z-index: 10;
            left: 10px;
            background-color: var(--white-color);
          }
          :nth-child(2) {
            display: flex;
            align-items: center;
          }
        }
      }
      .ql-editor {
        font-family: var(--primary-font-stack);
        ${Text1};
        p {
          font-size: 16px;
        }
      }
    `}
  .quill {
    flex: 1 0 120px;
    display: flex;
    flex-direction: column;
  }
  .ql-toolbar.ql-snow {
    margin: -1px;
    border-top: 1px solid var(--gray-border-color);
  }
  .ql-container.ql-snow {
    border: 0;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }
  ::placeholder {
    font-family: var(--primary-font-stack);
  }
  .ql-snow {
    border: none;
    padding: 8px 6px;
  }
  .ql-editor.ql-blank::before {
    color: var(--disable-color);
    font-style: normal;
    ${Text1}
    left: 6px;
  }
  .ql-editor {
    font-family: var(--primary-font-stack);
    ${Text1};
    line-height: 1.5;
    padding: 0;
    flex: 1 0 0;
    overflow: inherit;
    ul {
      padding-left: 0px;
    }
    h1,
    h2,
    h3 {
      margin-bottom: 16px;
    }
  }
  .ql-toolbar {
    .ql-active {
      color: var(--primary-color);
    }
    .ql-formats {
      margin-right: 0;
      :first-child {
        margin-right: 12px;
      }
    }
    button {
      padding: 0;
      height: 20px;
      width: 20px;
      margin-right: 12px;
      svg {
        display: none;
      }
    }
    .ql-expanded .ql-picker-label {
      color: var(--primary-color);
    }
    .ql-picker-label {
      svg {
        display: none;
      }
    }
    .ql-picker-label {
      border-radius: 4px;
    }
    .ql-expanded .ql-picker-options {
      border-radius: 4px;
      border-color: var(--gray-border-color);
      box-shadow: 0 3px 10px var(--menu-modal);
      top: -146px;
      margin-bottom: 6px;
    }
  }
`;
const LastView = styled.div`
  padding: 8px;
  margin: auto 8px 8px 8px;
  border: 1px solid transparent;
  position: relative;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: var(--popup-color-hover);
    border-radius: 6px;
    .actionbutton {
      opacity: 1;
    }
  }
`;
const LastEditView = styled.div`
  margin: 8px;
  border: 1px solid transparent;
  position: relative;
  ${(props) =>
    props.mobile &&
    css`
      .ql-toolbar.ql-snow {
        padding: 8px 12px 8px 110px;
        display: flex;
        align-items: center;
        overflow: auto;
        .ql-formats {
          :nth-child(1) {
            position: absolute;
            z-index: 10;
            left: 10px;
            background-color: var(--white-color);
          }
          :nth-child(2) {
            display: flex;
            align-items: center;
          }
        }
      }
      .ql-editor {
        font-family: var(--primary-font-stack);
        ${Text1};
        p {
          font-size: 16px;
        }
      }
    `}
  .quill {
    background-color: var(--white-color);
  }
  .ql-toolbar.ql-snow {
    margin: -1px;
    border: 1px solid var(--gray-border-color);
    border-radius: 6px 6px 0px 0px;
    padding: 10px 12px;
  }
  .ql-container.ql-snow {
    border: 0;
    padding: 12px;
  }
  ::placeholder {
    font-family: var(--primary-font-stack);
  }
  .ql-snow {
    border: none;
  }
  .ql-editor {
    font-family: var(--primary-font-stack);
    ${Text1};
    padding: 0;
    overflow-x: auto;
  }
  .ql-toolbar {
    .ql-active {
      color: var(--primary-color);
    }
    .ql-formats {
      margin-right: 0;
      :first-child {
        margin-right: 12px;
      }
    }
    button {
      padding: 0;
      height: 20px;
      width: 20px;
      margin-right: 12px;
      svg {
        display: none;
      }
    }
    .ql-expanded .ql-picker-label {
      color: var(--primary-color);
    }
    .ql-picker-label {
      svg {
        display: none;
      }
    }
    .ql-picker-label {
      border-radius: 4px;
    }
    .ql-expanded .ql-picker-options {
      border-radius: 4px;
      margin-top: 6px;
      border-color: var(--gray-border-color);
      box-shadow: 0 3px 10px var(--menu-modal);
      top: -152px;
    }
  }
`;
const TextEnd = styled.div``;
const RegardText = styled.p`
  ${Text1};
  margin: 0;
  color: var(--dark-color);
  p {
    margin: 0 0 4px 0;
    :last-child {
      margin: 0;
    }
  }
`;
const EditeView = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0;
  transition: 0.2s ease-in-out;
`;
const ALIcon = styled.a`
  cursor: pointer;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
`;
const AIcon = styled.a`
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
`;
const Border = styled.hr`
  border-bottom: 1px solid var(--Platinum-color);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 6px 0;
`;
const SignatureText = styled.span`
  display: inline-flex;
  align-items: center;
  color: var(--primary-color);
  ${Text1};
  background: var(--popup-color-hover);
  padding: 6px 10px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  :hover {
    background: var(--gainsBoro-color);
  }
  span {
    margin-left: 10px;
  }
`;
const ButtonCancel = styled.button`
  font-family: var(--primary-font-stack);
  margin: 0;
  text-transform: capitalize;
  ${Text1};
  border-radius: 4px;
  padding: 5px 10px;
  color: var(--gray-color);
  cursor: pointer;
  outline: none;
  background: var(--popup-color-hover);
  border: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background: var(--gainsBoro-color);
  }
`;
const ButtonSave = styled.button`
  font-family: var(--primary-font-stack);
  margin: 0;
  text-transform: capitalize;
  ${Text1};
  border-radius: 4px;
  padding: 5px 10px;
  color: var(--primary-color);
  cursor: pointer;
  outline: none;
  background: var(--popup-color-hover);
  border: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background: var(--gainsBoro-color);
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px 12px;
`;
const colourStyles = {
  control: (base, { isFocused }) => ({
    ...base,
    // This line disable the blue border
    // minHeight: '38px',
    // borderRadius: '4px',
    // borderColor: isFocused ? 'transparent' : 'var(--gray-border-color)',
    // borderWidth: '1px',
    // margin: isFocused ? '0px' : '0px',
    // boxShadow: isFocused ? '0 0 0 2px var(--primary-color)' : 'none',
    // ':hover': {
    //   borderColor: 'var(--gray-border-color)'
    // }
    fontFamily: 'var(--primary-font-stack)',
    fontSize: `${Text1}`,
    color: 'red',
    minHeight: '30px',
    borderRadius: '4px',
    borderColor: 'none',
    borderWidth: '0',
    margin: isFocused ? '0px' : '0px',
    boxShadow: 'none'
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--primary-color)',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    padding: '2px 6px',
    borderRadius: '4px',
    backgroundColor: 'var(--popup-color-hover)'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px',
    backgroundColor: 'var(--white-color)',
    display: 'none'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none '
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '6px',
    right: '20px'
  }),
  option: (styles) => {
    return {
      ...styles,
      // backgroundColor: 'var(--white-color)',
      color: '#000000',
      fontFamily: 'var(--primary-font-stack)',
      fontSize: `${Text1}`
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'var(--popup-color-hover)',
      borderRadius: '4px',
      margin: '2px',
      padding: '2px 6px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '16px'
    };
  },
  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'var(--dark-color)',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    padding: 0,
    paddingLeft: 0
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'var(--gray-icon-color)',
    ':hover': {
      backgroundColor: 'var(--popup-color-hover)',
      cursor: 'pointer'
    }
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '1px 4px 1px 4px'
  })
};
const LastEditBox = styled.div`
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
`;
const BtnBorder = styled.div`
  border-left: 1px solid var(--gray-border-color);
  width: 1px;
  height: 22px;
  margin: 0 5px;
`;
const FileWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  box-shadow: 0px 1px 2px var(--pagination-box-shadow);
  border-radius: 4px;
  padding: 8px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 4px 6px 8px 6px;
  i {
    font-weight: 600;
  }
`;
const FileTitle = styled.span`
  ${Text1}
  padding:0 10px 0 8px;
`;
const FlieBlog = styled.div`
  padding: 0 12px 4px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const BorderFile = styled.div`
  border-right: 1px solid var(--gray-border-color);
  margin-right: 10px;
  height: 21px;
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const AddBcc = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;
const Cc = styled.span`
  ${Text1}
  color: var(--gray-color);
  display: block;
  margin-right: 10px;
  cursor: pointer;
`;
const Bcc = styled.span`
  ${Text1}
  color: var(--gray-color);
  display: block;
  cursor: pointer;
`;
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonSend = styled(Button)`
  border-right: 1px solid var(--primary-color-active);
  border-radius: 4px 0px 0px 4px;
  margin: 0;
  ${(props) =>
    props.hasProduction &&
    css`
      border-radius: 4px 4px 4px 4px;
    `}
`;
const ButtonSchedual = styled(Button)`
  box-shadow: none;
  border-radius: 0px 4px 4px 0px;
  padding: 4px 2px;
  margin: 0;
`;
const IconButtonSchedual = styled.div``;
const SearchBox = styled.div`
  width: 420px;
  background: var(--white-color);
  padding: 0 6px;
  border: 1px solid var(--gray-border-color);
  position: absolute;
  z-index: 99;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
`;
const InputWrapper = styled.div`
  width: 100%;
`;
const DropDownUl = styled.ul`
  padding: 8px 0 6px 0;
  margin: 0;
  list-style: none;
`;
const DropDownLi = styled.li`
  padding: 8px 16px;
  margin-bottom: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: var(--cultured-color);
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: var(--cultured-color);
    `}
  ${() =>
    css`
      :hover {
        background-color: var(--nav-dark-color-focus);
      }
      ${(props) =>
        props.selected &&
        css`
          background-color: var(--nav-dark-color-focus);
        `}
    `}
`;

const FormMainWrap = styled.div`
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
        `
      : css`
          max-height: calc(100vh - 250px);
        `}
`;

export {
  IconButtonSchedual,
  DropDownLi,
  DropDownUl,
  SearchBox,
  MainContact,
  TitleView,
  TitleText,
  IconView,
  SecondView,
  BottomView,
  BtnView,
  Ul,
  Li,
  FirstView,
  ToText,
  From,
  SelectView,
  InputView,
  TextEditer,
  LastView,
  TextEnd,
  RegardText,
  EditeView,
  ALIcon,
  AIcon,
  FromView,
  FromDropView,
  Border,
  colourStyles,
  LastEditView,
  SignatureText,
  ButtonCancel,
  ButtonWrapper,
  ButtonSave,
  LastEditBox,
  BtnBorder,
  FileWrapper,
  FileTitle,
  FlieBlog,
  BorderFile,
  MenuItemWrapper,
  InputEmail,
  EmailTextArea,
  AddBcc,
  Cc,
  Bcc,
  ButtonWrap,
  ButtonSend,
  ButtonSchedual,
  InputWrapper,
  FromText,
  BodyWrapper,
  FormMainWrap
};
