import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/atom/Button/Button';
import { addSmsToItem, clearSmsItem, updateSmsItem } from '../../../../actions/phoneCallActions';
import { sendSms } from '../../../../services/twilioService';
import { getActivityByLookup } from '../../../../services/activityService';
import Validation from '../../../../components/Validation/Validation';
import { isEmpty } from '../../../../helpers/common';
import DropDown from '../../../../components/DropDown/DropDown';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import {
  ModalHeader,
  ModalHeaderTitel,
  LeftWrapper,
  RightWrapper,
  MeetingInputWrapperTwo,
  Footer,
  MainForm,
  BtnWrapper,
  TextArea,
  NumberDropDown,
  MeetingWrap,
  ErrorText
} from './styles';
const SendSmsModal = ({ lookup, onMenuClose }) => {
  const mobile = useMobileDevice();
  const dispatch = useDispatch();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const settingSelector = useSelector((state) => state.setting);
  const { phoneNumberList } = settingSelector;
  const phoneCallSelector = useSelector((state) => state.phoneCall);
  const { sms, loading } = phoneCallSelector;
  const [error, setError] = useState({});

  useEffect(() => {
    return () => {
      dispatch(clearSmsItem());
    };
  }, [dispatch]);

  const onChangeInput = useCallback(
    (propsName, value) => {
      dispatch(updateSmsItem({ propsName, value }));
    },
    [dispatch]
  );

  const twilioNumbers = useMemo(() => {
    const twilioNumber = phoneNumberList?.filter((x) => x.isTwilioNumber);
    return twilioNumber || [];
  }, [phoneNumberList]);

  useEffect(() => {
    const toItem = {
      id: lookup?.id,
      value: lookup?.phone
    };
    dispatch(addSmsToItem({ item: toItem }));
  }, [dispatch, lookup?.id, lookup?.phone]);

  const closeMenu = useCallback(() => {
    onMenuClose();
  }, [onMenuClose]);

  const onClickSend = useCallback(
    async (e) => {
      e.preventDefault();
      let error = {};
      setError(error);
      if (isEmpty(sms?.phone)) {
        error['phone'] = 'Phone is required';
      }
      if (!sms?.message || sms?.message?.trim() === '') {
        error['message'] = 'Message is required';
      }
      if (!isEmpty(error)) {
        setError(error);
        return;
      }
      const result = await dispatch(sendSms(organization_id, sms));
      if (result) {
        dispatch(getActivityByLookup(organization_id, lookup?.id));
        closeMenu();
      }
    },
    [closeMenu, dispatch, lookup?.id, organization_id, sms]
  );
  return (
    <MainForm onSubmit={onClickSend} mobile={mobile}>
      <ModalHeader>
        <LeftWrapper>
          <SVGIcon name='icon-message' fill='var(--gray-icon-color)' size={22} />
          <ModalHeaderTitel>Send Message</ModalHeaderTitel>
        </LeftWrapper>
        <RightWrapper onClick={closeMenu}>
          <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
        </RightWrapper>
      </ModalHeader>
      <NumberDropDown>
        <DropDown
          placeholder={'Select Number'}
          className='dropDown'
          options={twilioNumbers}
          labelField={'phoneNumber'}
          valueField={'phoneNumber'}
          value={sms?.phone}
          onChange={(value) => {
            onChangeInput('phone', value);
          }}
        />
        {error?.phone && (
          <ErrorText>
            <Validation error={error.phone} />
          </ErrorText>
        )}
      </NumberDropDown>
      <MeetingInputWrapperTwo>
        <MeetingWrap>
          <SVGIcon name='icon-rich-text' fill='var(--gray-icon-color)' size={20} />
          <TextArea
            type='text'
            placeholder={'Type your message here....'}
            value={sms?.message}
            onChange={(e) => {
              onChangeInput('message', e.target.value);
            }}></TextArea>
        </MeetingWrap>
        {error?.message && (
          <ErrorText>
            <Validation error={error.message} />
          </ErrorText>
        )}
      </MeetingInputWrapperTwo>

      <Footer>
        <BtnWrapper>
          <Button primary={true} type='submit' title='Send' loading={loading} />
        </BtnWrapper>
        <Button secondary={true} type={'button'} title='Cancel' onClick={onMenuClose} />
      </Footer>
    </MainForm>
  );
};
export default SendSmsModal;
