import styled, { css } from 'styled-components';
import { Header2, Header4, Text1 } from '../../../styles';

const MainList = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;
const MainWrap = styled.div`
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0px 16px 16px 16px;
        `
      : css`
          padding: 24px 0px;
        `}
`;
const HeadView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled.h2`
  margin: 0;
  ${Header2};
  color: var(--dark-color);
`;
const ContainBox = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 12px 16px;
  width: 100%;
  margin-bottom: 12px;
`;
const FirstList = styled.div``;
const SubText = styled.h4`
  margin: 0 0 2px 0;
  font-style: normal;
  ${Header4};
  color: var(--dark-color);
`;
const CaptionText = styled.p`
  font-style: normal;
  ${Text1};
  color: var(--gray-color);
  margin: 0;
`;
const IconView = styled.div`
  display: block;
  cursor: pointer;
`;
const SecondList = styled.div`
  margin-top: 35px;
`;
const SecondTitle = styled.h3`
  font-style: normal;
  ${Header2};
  color: var(--dark-color);
  margin: 0;
`;
const ContainBoxTwo = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  margin-top: 10px;
`;
const ListBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 27px 16px 20px;
  border-bottom: 1px solid var(--gray-border-color);
  &:last-child {
    border-bottom: 0;
  }
`;
export {
  MainList,
  MainWrap,
  HeadView,
  Title,
  ContainBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  SecondList,
  SecondTitle,
  ContainBoxTwo,
  ListBox
};
