import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, TimePicker } from '../../../components/DateTime';
import Validation from '../../../components/Validation/Validation';
import { LookupFieldTypes, LOOKUP_FIELD_CUSTOM_ID } from '../../../constants/constant';
import { isEmpty } from '../../../helpers/common';
import { getLookupByTable, getLookupTags } from '../../../services/lookupService';
import { getOrganizationUsers } from '../../../services/organizationService';
import {
  DateTimeWrapper,
  TitleText,
  InputCheckBoxView,
  InputWrapper,
  LabelTitle,
  Textarea,
  Select,
  customestyle,
  InputBox,
  DropDownDetail,
  mulcustomestyle
} from './Styles';

const LookupFieldInput = (props) => {
  const { item, resError, onChangeValues, showLabel = true, autoFocus = true, tableId, viewType } = props;
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const label = item?.label + (item?.isRequired ? '*' : '');
  const [options, setLookupOption] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getLookupOption() {
      if (item?.type === LookupFieldTypes.Lookup) {
        let option;
        if (item?.lookupTableId === 'users') {
          option = await getOrganizationUsers();
        } else {
          option = await getLookupByTable(item?.lookupTableId);
        }
        setLookupOption(option);
      } else if (
        item?.type === LookupFieldTypes.MultiSelect &&
        item?.customId === LOOKUP_FIELD_CUSTOM_ID.TAGS &&
        item?.isSystem
      ) {
        if (!options) {
          const tags = await dispatch(getLookupTags(organization_id, tableId));
          const tagList = tags?.map((x) => {
            return {
              id: x,
              value: x
            };
          });
          setLookupOption(tagList);
        }
      }
    }
    getLookupOption();
  }, [dispatch, item, item?.lookupTableId, item?.type, organization_id, tableId, options]);

  const onChangeInput = (value) => {
    let fieldItem = {
      ...item,
      value
    };
    onChangeValues(fieldItem);
  };
  const onChangeNumber = (e) => {
    const value = e.target.value;
    let fieldItem = {
      ...item,
      value_Number: value
    };
    onChangeValues(fieldItem);
  };

  const onChangeDate = (value) => {
    let val = value;
    if (item.type === LookupFieldTypes.Time) {
      const time = new Date(`${moment().format('YYYY-MMM-DD')} ${val}`);
      val = time;
    }
    let fieldItem = {
      ...item,
      value_Date: val
    };
    onChangeValues(fieldItem);
  };

  const onChangeItem = (value) => {
    let fieldItem = {
      ...item,
      value_Options: value
    };
    onChangeValues(fieldItem);
  };

  const onChangeCheckBox = () => {
    const value = !item.value_Bool;
    let fieldItem = {
      ...item,
      value_Bool: value
    };
    onChangeValues(fieldItem);
  };

  const onChangeLookup = (value) => {
    if (item.isMultiSelection) {
      const newValue = value?.map((val) => {
        const item = {
          ...val,
          company: undefined,
          contact: undefined
        };
        return item;
      });
      let fieldItem = {
        ...item,
        value_MultiLookup: newValue
      };
      onChangeValues(fieldItem);
    } else {
      value = {
        ...value,
        company: undefined,
        contact: undefined
      };
      let fieldItem = {
        ...item,
        value_Lookup: isEmpty(value) ? null : value
      };
      onChangeValues(fieldItem);
    }
  };

  const inputField = () => {
    switch (item?.type) {
      case LookupFieldTypes.Text:
      case LookupFieldTypes.Email:
      case LookupFieldTypes.Phone:
      case LookupFieldTypes.Url:
        return (
          <>
            <InputBox
              viewType={viewType}
              autoFocus={autoFocus}
              isPrimary={true}
              type={item.type === LookupFieldTypes.Email ? 'email' : 'text'}
              id={item?.id}
              name={item?.label}
              placeholder={item?.label}
              value={item?.value}
              onChange={(e) => onChangeInput(e.target.value)}
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </>
        );
      case LookupFieldTypes.MultiLineText:
        return (
          <>
            <Textarea
              type='text'
              rows='3'
              id={item?.id}
              name={item?.label}
              placeholder={item?.label}
              viewType={viewType}
              title={label}
              value={item?.value}
              onChange={(e) => onChangeInput(e.target.value)}></Textarea>
          </>
        );
      case LookupFieldTypes.Number:
      case LookupFieldTypes.Amount:
        return (
          <>
            <InputBox
              autoFocus={autoFocus}
              isPrimarySmall={true}
              type='number'
              id={item?.id}
              name={item?.label}
              placeholder={item?.label}
              value={item?.value_Number}
              onChange={onChangeNumber}
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </>
        );
      case LookupFieldTypes.Boolean:
        return (
          <>
            <InputWrapper viewType={viewType}>
              <LabelTitle onClick={onChangeCheckBox}>{label}</LabelTitle>
              <InputCheckBoxView>
                <input
                  type='checkbox'
                  checked={item.value_Bool}
                  onChange={onChangeCheckBox}
                  id={item?.id}
                  name={item?.label}
                />
                <span className='checkmark'></span>
              </InputCheckBoxView>
            </InputWrapper>
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </>
        );
      case LookupFieldTypes.Date:
        return (
          <DateTimeWrapper viewType={viewType}>
            <DatePicker
              viewType={viewType}
              autoFocus={autoFocus}
              autoOk={true}
              value={item?.value_Date ? new Date(item?.value_Date) : new Date()}
              className='DatePickerWrapper'
              onChange={onChangeDate}
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </DateTimeWrapper>
        );

      case LookupFieldTypes.Time:
        return (
          <DateTimeWrapper viewType={viewType}>
            <TimePicker
              className='TimePickerWrapper'
              viewType={viewType}
              value={moment(item?.value_Date).format('HH:mm')}
              onChange={(e) => onChangeDate(e.target.value)}
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </DateTimeWrapper>
        );

      case LookupFieldTypes.Select:
        return (
          <Select>
            <DropDownDetail
              options={item?.options || []}
              labelField={'value'}
              valueField={'id'}
              value={item?.value}
              styles={customestyle}
              viewType={viewType}
              menuPlacement={'auto'}
              onChange={(e) => onChangeInput(e.value)}
              placeholder={item?.label}
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </Select>
        );
      case LookupFieldTypes.MultiSelect:
        return (
          <Select>
            <DropDownDetail
              autoFocus={autoFocus}
              options={item?.options && item?.options?.length > 0 ? item?.options : options || []}
              labelField={'value'}
              valueField={'id'}
              isMulti
              styles={mulcustomestyle}
              value={item?.value_Options}
              viewType={viewType}
              menuPlacement={'auto'}
              onChange={(value) => {
                onChangeItem(value);
              }}
              placeholder={item?.label}
              className='basic-multi-select'
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </Select>
        );

      case LookupFieldTypes.Lookup:
        return (
          <Select>
            <DropDownDetail
              hasObject={item?.isMultiSelection ? false : true}
              options={options || []}
              labelField={'name'}
              autoFocus={autoFocus}
              valueField={'id'}
              menuPlacement={'auto'}
              viewType={viewType}
              styles={item?.isMultiSelection ? mulcustomestyle : customestyle}
              isDisabled={item?.isDisabled}
              isMulti={item?.isMultiSelection ? true : false}
              value={
                item?.isMultiSelection ? item?.value_MultiLookup || [] : item?.value_Lookup ? item?.value_Lookup : {}
              }
              onChange={(value) => {
                onChangeLookup(value);
              }}
              placeholder={item?.label}
              className='basic-multi-select'
            />
            {!isEmpty(resError) && <Validation error={resError[item?.id]} />}
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {showLabel && <TitleText Text1>{label}</TitleText>}
      {inputField()}
    </>
  );
};
export default LookupFieldInput;
