import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const lookupTableLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_LOOKUP_TABLE_LOADER
});

const lookupTableListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_LOOKUP_TABLES
});

const tableFieldsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TABLE_FIELDS
});

const tableItemReducer = createReducerOrig({}, (builder) => {
  builder
    .addCase(Actions.SET_LOOKUP_TABLE_ITEM, (state = {}, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_LOOKUP_TABLE_ITEM, (state, action) => {
      const tableItem = { ...state };
      tableItem[action.payload.propName] = action.payload.value;
      return { ...tableItem };
    });
});

const lookupTablesReducer = combineReducers({
  loading: lookupTableLoaderReducer,
  list: lookupTableListReducer,
  tableFields: tableFieldsReducer,
  tableItem: tableItemReducer
});

export default lookupTablesReducer;
