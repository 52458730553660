import React from 'react';
import Button from '../../../components/atom/Button/Button';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { BottomView, Form, LeftWrapper, Step1, StepPara } from './styles';

const TwoWaySyncEmailProvider = (props) => {
  const mobile = useMobileDevice();
  // eslint-disable-next-line max-len
  const actionURL = `https://api.salescamp.app/account/addemailaccount?companyId=${props.orgId}&access_token=${
    props.token
  }&returnUrl=${encodeURIComponent(window.location.href.split('?')[0])}`;

  const office365ActionURL = `https://api.salescamp.app/account/Add365EmailAccount?companyId=${
    props.orgId
  }&access_token=${props.token}&returnUrl=${encodeURIComponent(window.location.href.split('?')[0])}`;
  return (
    <Step1>
      <StepPara>
        To setup another email registered by dashboard.salescamp.app, please reach out to <a>hello@salescamp.app.</a>
        Additional charges may apply.
      </StepPara>
      <BottomView>
        <LeftWrapper mobile={mobile}>
          <Form method='post' action={actionURL}>
            <Button type='submit' title='Add Gmail / G-Suite Account' />
          </Form>
          <Form
            method='post'
            action={office365ActionURL}
            style={{ paddingLeft: mobile ? 0 : 10, marginTop: mobile ? 10 : 0 }}>
            <Button type='submit' title='Add Outlook / Office 365 Account' />
          </Form>
        </LeftWrapper>
      </BottomView>
    </Step1>
  );
};

export default TwoWaySyncEmailProvider;
