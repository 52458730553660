import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE } from '../../../constants/routeConst';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import { forgotPassword } from '../../../services/authServices';
import AppLogo from '../../../components/AppLogo';
import { isMACApp, isWindowsApp } from '../../../global/Helper';
import ElectronTitlebar from '../../../components/ElectronTitlebar/ElectronTitlebar';
import Validation from '../../../components/Validation/Validation';
import { setAuthValidationError } from '../../../actions/authActions';
import {
  ManeWrapper,
  LoginContainer,
  Title,
  LogoWrapper,
  Form,
  AlreadyPara,
  ButtonWrap,
  Link,
  Container,
  PrivacyPolicy,
  MacDragDro
} from './Styles';
import UpdateAppUrl from './UpdateAppUrl';

const ForgotPassword = (props) => {
  const authSelector = useSelector((state) => state.auth);
  const { loading, validationError } = authSelector;
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isShowUpdateAppUrl, setIsShowUpdateAppUrl] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setAuthValidationError(null));
    };
  }, [dispatch]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const payload = {
        email: email
      };
      if (email === 'update_app_url') {
        setIsShowUpdateAppUrl(true);
      } else {
        const result = await dispatch(forgotPassword(payload));
        if (result) {
          setSuccessMessage(result?.message);
        }
      }
    },
    [dispatch, email]
  );

  const renderErrors = useCallback(() => {
    return <Validation error={validationError} />;
  }, [validationError]);

  const onClickLogin = useCallback(() => {
    props.history.push(ROUTE.LOGIN);
  }, [props.history]);

  const onCloseUpdateUrl = useCallback(() => {
    setEmail('');
    dispatch(setAuthValidationError(null));
    setIsShowUpdateAppUrl(false);
  }, [dispatch]);

  return (
    <ManeWrapper>
      {isMACApp() && <MacDragDro />}
      {isWindowsApp() && <ElectronTitlebar />}
      {!isShowUpdateAppUrl ? (
        successMessage ? (
          <>
            <Container>
              <LogoWrapper>
                <AppLogo />
                <Title>Forgot Password Confirmation</Title>
              </LogoWrapper>
              <PrivacyPolicy>{successMessage}</PrivacyPolicy>
              <AlreadyPara>
                Go back to <Link onClick={onClickLogin}>Log in</Link>
              </AlreadyPara>
            </Container>
          </>
        ) : (
          <>
            <LoginContainer>
              <LogoWrapper>
                <AppLogo />
                <Title>Forgot Password</Title>
              </LogoWrapper>
              <Form onSubmit={onSubmit}>
                <Input
                  isPrimary={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id='email'
                  name='email'
                  value={email}
                  placeholder='Email'
                />
                {renderErrors()}
                <ButtonWrap>
                  <Button width='100%' title='Reset Password' primary size='large' type='submit' loading={loading} />
                </ButtonWrap>
                <AlreadyPara>
                  Go back to <Link onClick={loading ? null : onClickLogin}>Log in</Link>
                </AlreadyPara>
              </Form>
            </LoginContainer>
          </>
        )
      ) : (
        <UpdateAppUrl onClose={onCloseUpdateUrl} />
      )}
    </ManeWrapper>
  );
};

export default ForgotPassword;
