import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Menu from '../../../../../components/Menu/Menu';
import { Text1, Text2 } from '../../../../../styles';
import SVGIcon from '../../../../../assets/svg/SVGIcon';
import Button from '../../../../../components/atom/Button/Button';

const UserRecive = styled.span`
  font-style: normal;
  ${Text2};
  color: var(--gray-color);
  margin: 0 4px 0 0;
  display: inline;
  white-space: nowrap;
`;
const TimeText = styled.p`
  margin: 0;
  font-style: normal;
  ${Text2};
  color: var(--gray-color);
  display: inline;
  white-space: nowrap;
  ${(props) =>
    props.Comment &&
    css`
      margin-top: 6px;
    `}
  &.TimeText {
    display: none;
  }
`;

const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;

const SVGIconBg = styled(SVGIcon)`
  width: 20px !important;
  height: 20px !important;
`;

const CommentHoverBox = styled.div`
  &.commentbox {
    padding: 5px 7px;
    background-color: var(--white-color);
    border: 1px solid var(--gray-border-color);
    box-shadow: 0px 1px 3px var(--inbox-hover-color);
    border-radius: 6px;
    position: absolute;
    right: 24px;
    top: -14px;
    display: none;
    ${(props) =>
      props.boxblock &&
      props.current &&
      css`
        display: flex;
        align-items: center;
      `}
  }
`;

const CommentIconMain = styled.div`
  margin-right: 10px;
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
  svg {
    display: block;
  }
`;

const CommentInputBlock = styled.form`
  margin-left: 10px;
  width: 100%;
  position: relative;
`;

const CommentWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
  ${(props) =>
    props.mobile
      ? css`
          padding-left: 30px;
        `
      : css`
          padding-left: 20px;
        `}
`;

const CommentMainBlock = styled.div`
  border-radius: 6px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
`;

const CommentHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 8px 6px;
  position: relative;
  ${(props) =>
    props.isExpand &&
    css`
      border-bottom: none;
    `}
`;

const UseCommentBlock = styled.div`
  display: flex;
  align-items: center;
`;

const AvtarExternal = styled.div`
  display: inherit;
  :first-child {
    margin-left: 0;
  }
  margin-left: -5px;
`;

const UserLeft = styled.div`
  display: flex;
  align-items: center;
`;

const UserCommentTextMain = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const UserRight = styled.div`
  display: flex;
  align-items: center;
`;

const CommnetRightMain = styled.div`
  display: flex;
  transition: all 500ms;
  :hover {
    .TimeText {
      display: inline;
    }
  }
  ${(props) =>
    props.mobile &&
    css`
      flex-direction: column;
      width: 100%;
      .TimeText {
        display: block;
        text-align: end;
        margin-top: 2px;
      }
    `}
`;

const ToggleBlock = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 60px;
`;

const ResolveToggleBlock = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 30px;
`;

const CommentWrapperBlock = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 12px;
`;

const CommentWrapperSpan = styled.div`
  position: relative;
  margin: 0 10px;
  :hover {
    .remove-comment {
      opacity: 1;
    }
  }
`;

const CommentContainer = styled.div`
  display: block;
  ${(props) =>
    props.isExpand &&
    css`
      display: none;
    `}
`;

const CommentMassegeBlock = styled.div`
  margin-bottom: 12px;
  display: flex;
  :last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props.isCommentInput &&
    css`
      margin-bottom: 0px;
      padding: 12px;
    `}
`;

const CommentedUser = styled.div`
  display: inherit;
  margin-bottom: auto;
  margin-top: 5px;
`;

const CommentedMassegeSpan = styled.p`
  max-width: 810px;
  ${Text1}
  display: block;
  color: var(--dark-color);
  padding: 8px 12px;
  border-radius: 16px;
  background-color: var(--popup-color-hover);
  span {
    color: var(--primary-color);
  }
  ${(props) =>
    props.mobile
      ? css`
          margin: 0px 0px 0px 10px;
        `
      : css`
          margin: 0;
        `}
`;

const IconBtn = styled(Button)`
  margin-right: 8px;
  padding: 3px;
  background: none;
  box-shadow: none;
  svg {
    margin-right: 0;
  }
  :hover {
    background: var(--popup-color-hover);
  }
`;

const CommentSvg = styled.div`
  position: absolute;
  top: 0;
  ${(props) =>
    props.mobile
      ? css`
          left: 15px;
        `
      : css`
          left: 0;
        `}
`;

const LSvg = styled.img``;

const CommentResolve = styled.div`
  width: 100%;
  padding: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  cursor: pointer;
  ${(props) =>
    props.isExpand &&
    css`
      border-bottom: none;
    `}
`;

const ResolveImage = styled.img``;
const ResolveSpan = styled.span`
  ${Text2}
  color: var(--gray-color);
  display: block;
`;
const ResolveCommentBlock = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;
const ResolveTime = styled.span`
  ${Text2}
  color: var(--gray-color);
  display: block;
`;
const ResolveLeft = styled.div`
  display: flex;
  align-items: center;
`;
const CommentBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 5px;
`;

const EmojiPickerMenu = withStyles({
  paper: {
    padding: '0',
    background: 'var(--white-color)',
    width: '280px',
    marginTop: '20px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

const IconView = styled.div`
  margin-right: 6px;
  cursor: pointer;
  svg {
    display: block;
  }
`;
const IconWrapper = styled.div`
  transition: all 300ms;
  &.remove-comment {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: var(--linear-gradient-hover);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export {
  CommentHoverBox,
  CommentIconMain,
  SVGIconBg,
  AvtarExternal,
  CommentContainer,
  CommentedMassegeSpan,
  CommentedUser,
  CommentHeader,
  CommentMainBlock,
  CommentMassegeBlock,
  CommentResolve,
  CommentSvg,
  CommentWrapper,
  LSvg,
  MenuItemWrapper,
  ResolveCommentBlock,
  ResolveImage,
  ResolveLeft,
  ResolveSpan,
  ResolveTime,
  TimeText,
  UseCommentBlock,
  UserCommentTextMain,
  UserLeft,
  UserRecive,
  UserRight,
  CommentBtnWrapper,
  CommentInputBlock,
  IconBtn,
  CommnetRightMain,
  EmojiPickerMenu,
  ToggleBlock,
  CommentWrapperBlock,
  ResolveToggleBlock,
  CommentWrapperSpan,
  IconView,
  IconWrapper
};
