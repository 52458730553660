import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../assets/svg/SVGIcon';
import Avtar from '../../components/Avtar/Avtar';
import Button from '../../components/atom/Button/Button';
import { markAsAllReadNotification, markAsReadNotification } from '../../services/notificationServices';
import { LOOKUP_TYPE, NOTIFICATION_TYPE } from '../../constants/constant';
import EmptyGraphicImage from '../../assets/svg/notification_empty_graphics.svg';

import { createCookie, getCookie } from '../../global/Helper';
import useMobileDevice from '../../hooks/useMobileDevice';
import {
  NotificationMain,
  NotificationHeader,
  Left,
  NitifySpan,
  Right,
  AllReadSpan,
  NotificationContainer,
  NotifyMainEvent,
  MainAvtarBlock,
  EventIcon,
  LeftAvtar,
  UserText,
  RightContent,
  UserRecive,
  TimeText,
  ContentDiv,
  CommentBox,
  CommentSpan,
  EnableBlock,
  ButtonWrap,
  NotifySpan,
  NotificationEmpty,
  NotifyEmptyBlock,
  EmptyGraphic,
  EmptySpan,
  IconClose
} from './styles';

const NotificationsComponent = ({ onClose, isUnreadNotification }) => {
  const notificationSelector = useSelector((state) => state.notification);
  const organizationSelector = useSelector((state) => state.organization);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: notifications, loading } = notificationSelector;
  const { organization } = organizationSelector;
  const { users, id: organization_id } = organization;
  const { list: lookupTableList } = lookupTableSelector;

  const history = useHistory();
  const mobile = useMobileDevice();

  const [isNotificationSubscribed, setNotificationSubscribed] = useState(true);
  const [isShowNotNow, setIsShowNotNow] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      const OneSignal = window.OneSignal;
      const isShowNotificationEnableNotNow = getCookie('notification_enable_not_now');
      if (isShowNotificationEnableNotNow) setIsShowNotNow(false);
      if (!OneSignal || !OneSignal.isPushNotificationsEnabled) return;
      OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
        if (isEnabled) setNotificationSubscribed(true);
        else setNotificationSubscribed(false);
      });
    } catch (e) {
      console.log('ERROR', e);
    }
  }, []);

  const onClickMarkAsAllRead = useCallback(async () => {
    await dispatch(markAsAllReadNotification(organization_id));
  }, [dispatch, organization_id]);

  const getTableType = useCallback((item) => {
    switch (item?.type) {
      case LOOKUP_TYPE.contacts:
        return 'cn';
      case LOOKUP_TYPE.companies:
        return 'cm';
      case LOOKUP_TYPE.others:
        return 'others';
      default:
        return '';
    }
  }, []);

  const getTable = useCallback(
    (table_id) => {
      const notificationtable = lookupTableList?.find((x) => x?.id === table_id);
      return notificationtable;
    },
    [lookupTableList]
  );

  const onClickMarkAsRead = useCallback(
    async (item) => {
      let table;
      let navigationPath = '/';
      if (item?.metaData) {
        table = getTable(item?.metaData?.tableId);
        if (table) {
          const tableType = getTableType(table);
          navigationPath = `/${tableType}/${table?.name}/view/${item?.metaData?.lookupId}/overview`;
        }
        if (Number(item?.type) === NOTIFICATION_TYPE.Activity || Number(item?.type) === NOTIFICATION_TYPE.Comments) {
          navigationPath = navigationPath + `?activity=${item?.metaData?.activityId}`;
        } else if (Number(item?.type) === NOTIFICATION_TYPE.IncomingSMS && !item?.metaData?.lookupId) {
          navigationPath = '/settings/calllog/message';
        } else if (Number(item?.type) === NOTIFICATION_TYPE.IncomingCall && !item?.metaData?.lookupId) {
          navigationPath = '/settings/calllog/call';
        }
      }
      if (!item?.isRead) {
        dispatch(markAsReadNotification(organization_id, item?.id));
      }
      if (onClose) onClose();
      history.push(navigationPath);
    },
    [dispatch, getTable, getTableType, history, onClose, organization_id]
  );

  const onClickNotNow = useCallback(() => {
    createCookie('notification_enable_not_now', true);
    setIsShowNotNow(false);
  }, []);

  const onClickAllow = useCallback(() => {
    try {
      const OneSignal = window.OneSignal;
      if (!OneSignal) return;
      OneSignal.setSubscription(true);
      OneSignal.showNativePrompt().then((x) => {
        setNotificationSubscribed(true);
      });
    } catch (e) {
      console.log('ERROR', e);
    }
  }, []);

  const actionIcon = useCallback((type) => {
    let iconName;
    let bgColor;
    switch (type) {
      case NOTIFICATION_TYPE.LookupAssign:
        iconName = 'icon-task';
        bgColor = '#FF4F81';
        break;
      case NOTIFICATION_TYPE.Email:
        iconName = 'icon-email';
        bgColor = '#3EC7CE';
        break;
      case NOTIFICATION_TYPE.IncomingSMS:
        iconName = 'icon-message';
        bgColor = '#3EB458';
        break;
      default:
        iconName = 'icon-deals';
        bgColor = '#00AEFF';
    }
    return (
      <EventIcon bgColor={bgColor}>
        <SVGIcon name={iconName} size={10} fill='var(--white-color)' strokeWidth={2} />
      </EventIcon>
    );
  }, []);

  const renderNotificationItem = useCallback(
    (item) => {
      let user;
      if (item?.userId) {
        user = users?.find((x) => x?.id === item?.userId);
      }
      return (
        <MainAvtarBlock>
          <LeftAvtar>
            <Avtar imgSrc={user?.profile_photo} name={user?.name} size={28} />
            {actionIcon(item?.type)}
          </LeftAvtar>
          <RightContent>
            <ContentDiv>
              <UserText>{item?.username}</UserText>
              <UserRecive>{item?.title}</UserRecive>
              <TimeText>{moment(item?.date).fromNow()}</TimeText>
            </ContentDiv>
            {item?.description ? (
              <CommentBox>
                <CommentSpan dangerouslySetInnerHTML={{ __html: item?.description }}></CommentSpan>
              </CommentBox>
            ) : null}
          </RightContent>
        </MainAvtarBlock>
      );
    },
    [actionIcon, users]
  );

  return (
    <>
      <NotificationMain>
        <NotificationHeader mobile={mobile}>
          <Left>
            <NitifySpan>Notification</NitifySpan>
          </Left>
          <Right>
            {isUnreadNotification && <AllReadSpan onClick={onClickMarkAsAllRead}>Mark all as read</AllReadSpan>}
            {mobile && (
              <IconClose onClick={onClose}>
                <SVGIcon name='icon-close' size={24} fill='var(--gray-icon-color)' />
              </IconClose>
            )}
          </Right>
        </NotificationHeader>
        {!isNotificationSubscribed && isShowNotNow && (
          <EnableBlock>
            <NotifySpan mobile={mobile}>Enable desktop notifications.</NotifySpan>
            <ButtonWrap>
              <Button secondary={true} type={'button'} title='Not Now' onClick={onClickNotNow} />
              <Button primary={true} type={'button'} title='Allow' onClick={onClickAllow} />
            </ButtonWrap>
          </EnableBlock>
        )}
        {!loading && (!notifications || notifications.length === 0) && (
          <NotificationEmpty>
            <NotifyEmptyBlock>
              <EmptyGraphic src={EmptyGraphicImage}></EmptyGraphic>
              <EmptySpan>There is no notifications!!!</EmptySpan>
            </NotifyEmptyBlock>
          </NotificationEmpty>
        )}

        {(notifications || notifications?.length !== 0) && (
          <NotificationContainer mobile={mobile}>
            {notifications?.map((item, index) => {
              return (
                <NotifyMainEvent
                  key={`notifications_${item?.id}_${index}`}
                  className={!item?.isRead ? 'unread' : null}
                  onClick={() => {
                    onClickMarkAsRead(item);
                  }}>
                  {renderNotificationItem(item)}
                </NotifyMainEvent>
              );
            })}
            {/* <NotifyMainEvent className={true ? 'unread' : null}>
            <MainAvtarBlock>
              <LeftAvtar>
                <Avtar name='Pritesh Makasana' size={28} />
                <EventIcon bgColor='#FF4F81'>
                  <SVGIcon name='icon-task' size={10} fill='var(--white-color)' strokeWidth={2} />
                </EventIcon>
              </LeftAvtar>
              <RightContent>
                <ContentDiv>
                  <UserText className={true ? 'unread' : null}>Pritesh Makasana</UserText>
                  <UserRecive className={true ? 'unread' : null}> assigned a task for</UserRecive>
                  <UserText className={true ? 'unread' : null}>Collection Screen</UserText>
                  <TimeText className={true ? 'unread' : null}>3 min ago</TimeText>
                </ContentDiv>
                {false ? (
                  <CommentBox>
                    <CommentSpan>Devanshu we need to review the proposal and provide revised price.</CommentSpan>
                  </CommentBox>
                ) : null}
              </RightContent>
            </MainAvtarBlock>
          </NotifyMainEvent>
          <NotifyMainEvent className={false ? 'unread' : null}>
            <MainAvtarBlock>
              <LeftAvtar>
                <Avtar imgSrc={gravatar} size={28} />
                <EventIcon bgColor='#FF4F81'>
                  <SVGIcon name='icon-task' size={10} fill='var(--white-color)' strokeWidth={2} />
                </EventIcon>
              </LeftAvtar>
              <RightContent>
                <ContentDiv>
                  <UserText>Kanji Patel </UserText>
                  <UserRecive>assigned a task for fix add deal function for deal screen</UserRecive>
                  <TimeText>1 hour ago</TimeText>
                </ContentDiv>
                {false ? (
                  <CommentBox>
                    <CommentSpan>Devanshu we need to review the proposal and provide revised price.</CommentSpan>
                  </CommentBox>
                ) : null}
              </RightContent>
            </MainAvtarBlock>
          </NotifyMainEvent>
          <NotifyMainEvent className={false ? 'unread' : null}>
            <MainAvtarBlock>
              <LeftAvtar>
                <Avtar imgSrc={gravatar} size={28} />
                <EventIcon bgColor='#3EC7CE'>
                  <SVGIcon name='icon-email' size={10} fill='var(--white-color)' strokeWidth={2} />
                </EventIcon>
              </LeftAvtar>
              <RightContent>
                <ContentDiv>
                  <UserText>Devanshu send </UserText>
                  <UserRecive>send a mail </UserRecive>
                  <UserText>Salescamp</UserText>
                  <TimeText>3 hour ago</TimeText>
                </ContentDiv>
                {false ? (
                  <CommentBox>
                    <CommentSpan>Devanshu we need to review the proposal and provide revised price.</CommentSpan>
                  </CommentBox>
                ) : null}
              </RightContent>
            </MainAvtarBlock>
          </NotifyMainEvent>
          <NotifyMainEvent className={false ? 'unread' : null}>
            <MainAvtarBlock>
              <LeftAvtar>
                <Avtar imgSrc={gravatar} size={28} />
                <EventIcon bgColor='#3EB458'>
                  <SVGIcon name='icon-message' size={10} fill='var(--white-color)' strokeWidth={2} />
                </EventIcon>
              </LeftAvtar>
              <RightContent>
                <ContentDiv>
                  <UserText>Sanjay Patel</UserText>
                  <UserRecive>added comment for</UserRecive>
                  <UserText>Salescamp</UserText>
                  <TimeText>1 month ago</TimeText>
                </ContentDiv>
                {true ? (
                  <CommentBox>
                    <CommentSpan>Devanshu we need to review the proposal and provide revised price.</CommentSpan>
                  </CommentBox>
                ) : null}
              </RightContent>
            </MainAvtarBlock>
          </NotifyMainEvent>
          <NotifyMainEvent className={false ? 'unread' : null}>
            <MainAvtarBlock>
              <LeftAvtar>
                <Avtar name='Bhavdip Kasodariya' size={28} />
                <EventIcon bgColor='#3EB458'>
                  <SVGIcon name='icon-massage' size={10} fill='var(--white-color)' strokeWidth={2} />
                </EventIcon>
              </LeftAvtar>
              <RightContent>
                <ContentDiv>
                  <UserText>Bhavdip Kasodariya</UserText>
                  <UserRecive>send a message for</UserRecive>
                  <UserText>Simfrom</UserText>
                  <TimeText>1 month ago</TimeText>
                </ContentDiv>
                {false ? (
                  <CommentBox>
                    <CommentSpan>Devanshu we need to review the proposal and provide revised price.</CommentSpan>
                  </CommentBox>
                ) : null}
              </RightContent>
            </MainAvtarBlock>
          </NotifyMainEvent> */}
          </NotificationContainer>
        )}
      </NotificationMain>
    </>
  );
};

const Notifications = React.forwardRef((props, ref) => <NotificationsComponent innerRef={ref} {...props} />);

export default Notifications;
