import styled, { css } from 'styled-components';
import { Text1, Text2 } from '../../styles';

const MainContain = styled.div`
  width: 300px;
  background: var(--nav-dark-color-hover);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.34);
  border-radius: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  outline: none;
  z-index: 9999;
`;
const DragAndDropIcon = styled.div`
  margin-right: 6px;
  svg {
    display: block;
  }
`;
const CallfunctionBlock = styled.div``;
const CallFunctionOptionBlock = styled.ul`
  list-style: none;
  padding: 0 16px;
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CallFunctionOption = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms;
  svg {
    display: block;
  }
  :hover {
    background-color: var(--nav-dark-color-focus);
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: var(--nav-dark-color-focus);
    `}
`;
const CallSettingBlock = styled.div`
  padding: 12px 16px;
`;

const FirstList = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--charcoal-border); ;
`;
const CallingHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const CallStatus = styled.h6`
  margin: 0;
  ${Text2};
  color: var(--green-lite);
`;
const Secondlist = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;
const ImgView = styled.div`
  margin-right: 16px;
`;
const Profile = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 60px;
`;
const ProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
`;
const UserNub = styled.p`
  margin: 0;
  ${Text2}
  color: var(--gray-color);
`;
const UserName = styled.h6`
  margin: 0 0 2px 0;
  ${Text1}
  color:var(--white-color);
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Anscall = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: var(--green-lite);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;
const Decline = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: var(--red-lite);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const CallStart = styled.div`
  background-color: var(--gray-icon-color);
  border-radius: 25px;
  padding: 3px 10px;
`;
const CallTime = styled.span`
  ${Text2}
  display: block;
  color: var(--white-color);
`;
const BottonMenu = styled.div`
  width: 300px;
  padding: 12px 16px;
  background-color: var(--nav-dark-color-hover);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.34);
  border-radius: 6px 6px 0px 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(0%);
  outline: none;
  z-index: 9999;
`;
const Btmmenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const IconView = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
const MicrophoneSettingDropdown = styled.div`
  margin-bottom: 12px;
  position: relative;
  background-color: var(--nav-dark-color-focus);
  border-radius: 6px;
  z-index: 2;
`;
const SpeakerSettingDropdown = styled.div`
  position: relative;
  background-color: var(--nav-dark-color-focus);
  border-radius: 6px;
  z-index: 1;
`;
const CallDiscoonectBlock = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: var(--red-lite);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    display: block;
  }
`;
const customestyle = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'var(--titlebar-bg)' : 'transparent',
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    color: 'var(--white-color)',
    '&:hover': {
      backgroundColor: ' var(--titlebar-bg)'
    }
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    color: 'var(--white-color)',
    margin: '0px'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px 12px'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    // This line disable the blue border
    boxShadow: 'none',
    minHeight: '34px',
    borderRadius: '6px',
    background: 'var(--nav-dark-color-focus)',
    borderColor: isFocused ? 'var(--dark-blue-border);' : 'transparent',
    borderWidth: isFocused ? '1px' : '1px',
    ':hover': {
      borderColor: isFocused ? 'var(--dark-blue-border);' : 'transparent'
    },
    paddingLeft: '24px'
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    background: 'var(--nav-dark-color-focus)',
    borderRadius: '6px'
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '140px'
  }),

  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px',
    marginLeft: '10px',
    '.iconsvg': {
      display: 'flex',
      svg: {
        path: {
          stroke: 'var(--white-color)'
        }
      }
    }
  }),
  input: (base) => ({
    ...base,
    color: 'var(--white-color)'
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: '14px'
  })
};
const DropDownLeftIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 9px;
  z-index: 2;
`;

export {
  MainContain,
  FirstList,
  CallStatus,
  Secondlist,
  LeftSide,
  ImgView,
  Profile,
  ProfileDetail,
  UserName,
  UserNub,
  RightSide,
  Anscall,
  Decline,
  CallStart,
  CallTime,
  BottonMenu,
  Btmmenu,
  IconView,
  CallingHeaderLeft,
  DragAndDropIcon,
  CallfunctionBlock,
  CallFunctionOptionBlock,
  CallFunctionOption,
  CallSettingBlock,
  MicrophoneSettingDropdown,
  SpeakerSettingDropdown,
  CallDiscoonectBlock,
  customestyle,
  DropDownLeftIcon
};
