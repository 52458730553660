import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGIcon from '../../../assets/svg/SVGIcon';
// import Avtar from '../../../components/Avtar/Avtar';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { ROUTE } from '../../../constants/routeConst';
import { getOpenCommentActivity } from '../../../services/activityService';
import ActivityItem from '../../Contact/ContactDetail/ContactActivity/ActivityItem';
import {
  MainContain,
  WelView,
  ContainList,
  HeaderWrapper,
  HeaderView,
  RowView,
  Title,
  OpenThread,
  ThreadWrapper,
  AvtivityBlock,
  ThreadMainBlock
} from './styles';

const InboxOpenThread = (props) => {
  const mobile = useMobileDevice();

  const organizationSelector = useSelector((state) => state.organization);
  const activitySelector = useSelector((state) => state.activity);
  // const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { organization } = organizationSelector;
  const { openCommentActivityList = [] } = activitySelector;
  const { id: organization_id } = organization;
  // const { list: lookupTableList } = lookupTableSelector;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOpenCommentActivity(organization_id));
  }, [dispatch, organization_id]);

  // const onLookupClick = useCallback(
  //   (lookup) => {
  //     const table = lookupTableList.find((x) => x.id === lookup?.tableId);
  //     if (table) {
  //       const type = LOOKUP_TABLE_TYPE[table?.type];
  //       const pathName = `/${type}/${table?.name}/view/${lookup?.id}/overview`;
  //       props?.history.push(pathName);
  //     }
  //   },
  //   [props?.history, lookupTableList]
  // );

  return (
    <>
      <MainContain mobile={mobile}>
        <WelView>
          <ContainList>
            <HeaderWrapper mobile={mobile}>
              <HeaderView mobile={mobile}>
                <RowView>
                  <SVGIcon name='icon-inbox' size={26} fill='var(--primary-color)' />
                  <Title
                    onClick={() => {
                      props.history.push(ROUTE.INBOX);
                    }}>
                    Inbox
                  </Title>
                  <OpenThread>Open Thread</OpenThread>
                </RowView>
              </HeaderView>
            </HeaderWrapper>
            {openCommentActivityList?.map((item, index) => (
              <ThreadMainBlock key={`activity_item_${item.id}_${index}`}>
                <ThreadWrapper>
                  {/* {item?.lookup && (
                    <ThreadMainRow
                      onClick={() => {
                        onLookupClick(item?.lookup);
                      }}>
                      <Avtar size={30} name={item?.lookup?.name} />
                      <ThraedInner>
                        <UserText>{item?.lookup?.name}</UserText>
                      </ThraedInner>
                    </ThreadMainRow>
                  )} */}
                  <AvtivityBlock>
                    <ActivityItem item={item} isOpenThread={true} />
                  </AvtivityBlock>
                </ThreadWrapper>
              </ThreadMainBlock>
            ))}
          </ContainList>
        </WelView>
      </MainContain>
    </>
  );
};

export default InboxOpenThread;
