import React, { useCallback, useState } from 'react';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import AppLogo from '../../../components/AppLogo';
import { isMACApp, isWindowsApp } from '../../../global/Helper';
import ElectronTitlebar from '../../../components/ElectronTitlebar/ElectronTitlebar';
import Validation from '../../../components/Validation/Validation';
import {
  ManeWrapper,
  LoginContainer,
  Title,
  LogoWrapper,
  Form,
  AlreadyPara,
  ButtonWrap,
  Link,
  MacDragDro
} from './Styles';

const UpdateAppUrl = ({ onClose }) => {
  const [validationError, setValidationError] = useState();
  const [url, setUrl] = useState('');

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!url || url?.trim() === '') {
        setValidationError('url is required');
        return;
      }
      localStorage.setItem('AppUrl', url);
      if (onClose) onClose();
    },
    [onClose, url]
  );

  const renderErrors = useCallback(() => {
    return <Validation error={validationError} />;
  }, [validationError]);

  const onClickForgotPassword = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <ManeWrapper>
      {isMACApp() && <MacDragDro />}
      {isWindowsApp() && <ElectronTitlebar />}
      <LoginContainer>
        <LogoWrapper>
          <AppLogo />
          <Title>Update App Url</Title>
        </LogoWrapper>
        <Form onSubmit={onSubmit}>
          <Input
            isPrimary={true}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            id='url'
            name='url'
            value={url}
            placeholder='Update app url'
          />
          {renderErrors()}
          <ButtonWrap>
            <Button width='100%' title='Save' primary size='large' type='submit' />
          </ButtonWrap>
          <AlreadyPara>
            Go back to <Link onClick={onClickForgotPassword}>Forgot Password</Link>
          </AlreadyPara>
        </Form>
      </LoginContainer>
    </ManeWrapper>
  );
};

export default UpdateAppUrl;
