import styled, { css } from 'styled-components';
import { Header4, Header5, SubHeader3, Text1, Text2 } from '../../../../src/styles';
import SVGIcon from '../../../assets/svg/SVGIcon';
import Button from '../../../components/atom/Button/Button';

const MainContain = styled.div`
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.mobile
      ? css``
      : css`
          height: 100vh;
        `}
`;
const OpenThread = styled.span`
  ${SubHeader3};
  color: var(--dark-color);
  margin: 0 0 0 16px;
  cursor: pointer;
  color: var(--primary-color);
`;
const RowBox = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      padding: 0px 16px 16px 16px;
    `}
`;
const HeaderWrapper = styled.div`
  padding: 24px 0px 24px 0px;
  top: 0;
  right: 17px;
  left: 0px;
  z-index: 1;
  ${(props) =>
    props.mobile
      ? css`
          background: none;
          position: static;
        `
      : css`
          background: var(--linear-gradient-white);
          position: sticky;
        `}
`;
const HeaderView = styled.div`
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  ${(props) =>
    props.mobile
      ? css`
          background-color: none;
        `
      : css`
          background-color: var(--white-color);
        `}
`;
const RowView = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h5`
  ${SubHeader3};
  color: var(--dark-color);
  margin: 0 0 0 12px;
  cursor: pointer;
`;
const WelView = styled.div`
  height: 100%;
  overflow: auto;
`;
const ContainList = styled.div`
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
`;
const ThreadWrapper = styled.div``;
const ThreadMainRow = styled.div`
  display: flex;
  align-items: center;
`;
const ThraedRight = styled.div``;
const ThraedInner = styled.div`
  margin-left: 10px;
`;
const UserReciveTime = styled.span`
  display: inline-block;
  ${Text2}
  color: var(--gray-color);
  margin-left: 4px;
`;
const UserText = styled.span`
  display: block;
  ${Text1}
  color: var(--dark-color);
`;
const UserRecive = styled.span`
  display: inline-block;
  ${Text2}
  color: var(--dark-color);
`;
const AvtivityBlock = styled.div`
  margin-top: 12px;
`;
const ContainView = styled.div`
  background: var(--white-color);
  border: 1px solid var(--gray-border-color);
  border-radius: 6px;
  padding: 11px 15px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  :hover {
    .commentbox {
      display: flex;
    }
  }
  ${(props) =>
    props.notes &&
    css`
      background: var(--yellow-lite-color);
      border: 1px solid var(--yellow-lite-border);
    `}
  ${(props) =>
    props.audio &&
    css`
      display: block;
    `}
`;
const Captiontitle = styled.h5`
  margin: 0;
  font-style: normal;
  ${Text1};
  color: var(--dark-color);
  word-break: break-all;
  span {
    color: var(--primary-color);
  }
`;
const Captionmess = styled.div`
  font-style: normal;
  ${Text2};
  display: block;
  width: 100%;
  color: var(--gray-color);
  p {
    color: var(--charcoal-color);
    margin: 0;
  }
  strong {
    font-weight: 500;
  }
  ul {
    list-style-type: disc;
    padding: 5px 0px 0px 20px;
  }
  ol {
    list-style-type: decimal;
    padding: 5px 0px 0px 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${Text2}
    color: var(--gray-color);
  }
  p {
    em {
      font-style: italic;
    }
    strong {
      font-weight: 500;
    }
  }
  a {
    ${Text2}
    color: var(--primary-color);
  }
  ${(props) =>
    props.isTitle &&
    css`
      margin: 6px 0 0 0;
    `}
`;
const FileMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.audio &&
    css`
      width: auto;
    `}
  ${(props) =>
    props.isPriview &&
    css`
      cursor: pointer;
    `}
`;
const DateWrapper = styled.div`
  border: 1px solid var(--gray-border-color);
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--cultured-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 44px;
`;
const Monthly = styled.span`
  display: inline-block;
  color: var(--callreject-icon);
  ${Header5}
`;
const Date = styled.span`
  display: inline-block;
  color: var(--dark-color);
  ${Header4}
  line-height: 19px;
`;
const FileText = styled.div`
  width: 100%;
  &.fileBorder {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const Time = styled.span``;

/* Commnet Part Here */

const CommentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 20px;
  margin-top: 16px;
`;

const CommentMainBlock = styled.div`
  border-radius: 6px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-border-color);
`;

const CommentHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  padding: 8px 6px;
  position: relative;
  ${(props) =>
    props.isExpand &&
    css`
      border-bottom: none;
    `}
`;

const UseCommentBlock = styled.div`
  display: flex;
  align-items: center;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      margin-left: -6px;
      :first-child {
        margin-left: 0;
      }
    }
  }
`;

const AvtarExternal = styled.div`
  display: inherit;
  :first-child {
    margin-left: 0;
  }
  margin-left: -5px;
`;

const UserLeft = styled.div`
  display: flex;
  align-items: center;
`;

const UserCommentTextMain = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const UserRight = styled.div`
  display: flex;
  align-items: center;
`;

const CommnetRightMain = styled.div`
  display: flex;
  transition: all 500ms;
  :hover {
    .TimeText {
      display: inline;
    }
  }
`;

const ToggleBlock = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 60px;
`;

const ResolveToggleBlock = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 30px;
`;

const CommentWrapperBlock = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 12px;
`;

const CommentContainer = styled.div`
  display: block;
  padding: 12px;
  ${(props) =>
    props.isExpand &&
    css`
      display: none;
    `}
`;

const CommentMassegeBlock = styled.div`
  margin-bottom: 12px;
  display: flex;
  :last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props.isCommentInput &&
    css`
      margin-bottom: 0px;
      padding: 12px;
    `}
`;

const CommentedUser = styled.div`
  display: inherit;
  margin-bottom: auto;
  margin-top: 5px;
`;

const CommentedMassegeSpan = styled.p`
  max-width: 810px;
  ${Text1}
  display: block;
  color: var(--dark-color);
  padding: 8px 12px;
  margin: 0px 10px;
  border-radius: 16px;
  background-color: var(--popup-color-hover);
  span {
    color: var(--primary-color);
  }
`;

const IconBtn = styled(Button)`
  margin-right: 8px;
  padding: 3px;
  background: none;
  box-shadow: none;
  svg {
    margin-right: 0;
  }
  :hover {
    background: var(--popup-color-hover);
  }
`;
const SVGIconBg = styled(SVGIcon)`
  width: 20px !important;
  height: 20px !important;
`;

const CommentSvg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const LSvg = styled.img``;

const CommentResolve = styled.div`
  width: 100%;
  padding: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
  cursor: pointer;
  ${(props) =>
    props.isExpand &&
    css`
      border-bottom: none;
    `}
`;

const ResolveImage = styled.img``;
const ResolveSpan = styled.span`
  ${Text2}
  color: var(--gray-color);
  display: block;
`;
const ResolveCommentBlock = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;
const ResolveTime = styled.span`
  ${Text2}
  color: var(--gray-color);
  display: block;
`;
const ResolveLeft = styled.div`
  display: flex;
  align-items: center;
`;
const CommentBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 5px;
`;
const CommentIconMain = styled.div`
  margin-right: 10px;
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
  svg {
    display: block;
  }
`;
const TimeText = styled.p`
  margin: 0;
  font-style: normal;
  ${Text2};
  color: var(--gray-color);
  display: inline;
  ${(props) =>
    props.Comment &&
    css`
      margin-top: 6px;
    `}
`;
const CommentInputBlock = styled.form`
  margin-left: 10px;
  width: 100%;
  position: relative;
`;
const ValidationWrapper = styled.div`
  width: 100%;
  input {
    height: auto;
    padding: 10px;
    width: 100%;
    border: 1px solid #e4e4e5;
    border-radius: 6px;
    outline: none;
    :focus {
      border-color: var(--primary-color);
      box-shadow: rgb(175 205 255) 0px 0px 0px 3px, rgb(0 0 0 / 5%) 1px 1px 2px 0px;
    }
  }
`;
const MainComment = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
const CommnentText = styled.span`
  ${Text1}
  display: inline-block;
  padding: 5px 10px;
  margin-left: 12px;
  color: var(--dark-color);
  background-color: #f2f2f2;
  border-radius: 10px;
`;
const ThreadMainBlock = styled.div`
  margin-bottom: 30px;
`;

export {
  MainContain,
  WelView,
  ContainList,
  HeaderWrapper,
  Title,
  RowView,
  HeaderView,
  OpenThread,
  RowBox,
  ThreadWrapper,
  ThreadMainRow,
  ThraedRight,
  ThraedInner,
  UserReciveTime,
  UserText,
  UserRecive,
  AvtivityBlock,
  ContainView,
  Captiontitle,
  Captionmess,
  FileMain,
  DateWrapper,
  Monthly,
  Date,
  FileText,
  Time,
  //Here Comment Part
  CommentBtnWrapper,
  ResolveLeft,
  ResolveTime,
  ResolveCommentBlock,
  ResolveSpan,
  ResolveImage,
  CommentResolve,
  LSvg,
  CommentSvg,
  IconBtn,
  CommentedMassegeSpan,
  CommentedUser,
  CommentMassegeBlock,
  CommentContainer,
  CommentWrapperBlock,
  ResolveToggleBlock,
  ToggleBlock,
  CommnetRightMain,
  UserRight,
  UserCommentTextMain,
  UserLeft,
  AvtarExternal,
  UseCommentBlock,
  CommentWrapper,
  CommentMainBlock,
  CommentHeader,
  CommentIconMain,
  SVGIconBg,
  TimeText,
  CommentInputBlock,
  ValidationWrapper,
  MainComment,
  CommnentText,
  ThreadMainBlock
};
