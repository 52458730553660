import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';
import DropDown from '../../../components/DropDown/DropDown';
import {
  createWebForm,
  deleteWebForm,
  getWebFormDetails,
  trackWebFormActionAnalyticActivity,
  trackWebFormAnalyticActivity,
  trackWebFormDeleteAnalyticActivity,
  updateWebForm
} from '../../../services/settingService';
import { LookupFieldTypes, LOOKUP_TYPE } from '../../../constants/constant';
import { updateWebFormDetail } from '../../../actions/settingActions';
import Message from '../../../components/Message/Message';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import Validation from '../../../components/Validation/Validation';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  MainContact,
  TitleView,
  TitleText,
  BottomView,
  Form,
  LeftWrapper,
  RightWrapper,
  FormWrapper,
  TitleForm,
  FormPara,
  ListWrappper,
  DropDownTitle,
  marginBottom,
  Step1,
  Step2,
  NavigationWrapper,
  NavigationTitle,
  IconLabel,
  LabelTitle,
  InputWrapperModal,
  CustomCheckboxWrapper,
  InputWrapperBlock,
  LabelInput,
  HeaderWrapper,
  ButtopnWrapper,
  CollectTitle,
  SvgWrapper
} from './styles';

const ContactFormBuilder = (props) => {
  const { webFormId: id } = props;
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const [step, setStep] = useState(1);
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();
  let history = useHistory();

  const loadData = useCallback(() => {
    if (!id) return;
    dispatch(getWebFormDetails(organization_id, id));
  }, [dispatch, organization_id, id]);

  useEffect(() => {
    loadData();
  }, [loadData, dispatch]);

  const settingSelector = useSelector((state) => state.setting);
  const { webFormDetail, loading } = settingSelector;

  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { list: lookupTableList, tableFields } = lookupTableSelector;

  const collectionList = useMemo(() => {
    const list = lookupTableList?.filter((x) => x.type === LOOKUP_TYPE.contacts);
    return list;
  }, [lookupTableList]);

  useEffect(() => {
    if (!props?.id) {
      dispatch(updateWebFormDetail({ propName: 'tableId', value: collectionList[0].id }));
    }
  }, [dispatch, organization_id, props?.id, collectionList]);

  const tableFieldsToDisplay = useMemo(() => {
    if (tableFields?.length > 0) {
      const allFields = tableFields?.find((x) => x.tableId === webFormDetail?.tableId)?.columns;
      const filteredFields = allFields?.filter((x) => x.type !== LookupFieldTypes.Lookup && x.isNonEditable !== true);

      const fieldsToDisplay = filteredFields?.map((field) => {
        const result = webFormDetail?.fields?.findIndex((x) => x.fieldId === field.id);
        if (result !== -1 && result !== undefined) {
          return {
            ...field,
            isSelected: true
          };
        } else {
          return {
            ...field,
            isSelected: false
          };
        }
      });
      return fieldsToDisplay;
    }
  }, [tableFields, webFormDetail?.fields, webFormDetail?.tableId]);

  const onClose = useCallback(() => {
    if (props.handleCloseModal) props.handleCloseModal();
  }, [props]);

  const onClickNext = useCallback(
    (e) => {
      e.preventDefault();
      let valid = true;
      let validationErrors = {};
      if (!webFormDetail?.name) {
        validationErrors.name = 'Required';
        valid = false;
      }
      if (!webFormDetail?.tableId) {
        validationErrors.tableId = 'Required';
        valid = false;
      }
      setValidationErrors(validationErrors);
      const nextStep = step + 1;
      if (valid) setStep(nextStep);
    },
    [webFormDetail?.name, webFormDetail?.tableId, step]
  );

  const back = useCallback(() => {
    const prevStep = step - 1;
    setStep(prevStep);
  }, [step]);

  const onUpdateWebFormDetail = useCallback(
    (fieldName, FieldVal) => {
      if (fieldName === 'tableId') {
        dispatch(updateWebFormDetail({ propName: 'fields', value: [] }));
      }
      dispatch(updateWebFormDetail({ propName: fieldName, value: FieldVal }));
    },
    [dispatch]
  );

  const handleSelectedFieldChange = useCallback(
    (id) => {
      let fields = webFormDetail?.fields ? [...webFormDetail?.fields] : [];
      const index = fields?.findIndex((x) => x.fieldId === id) || null;
      if (index !== -1) {
        fields?.splice(index, 1);
      } else {
        fields?.push({ fieldId: id });
      }
      dispatch(updateWebFormDetail({ propName: 'fields', value: fields }));
    },
    [webFormDetail?.fields, dispatch]
  );

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!(webFormDetail?.fields?.length > 0)) {
      validationErrors.fields = 'Required';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [webFormDetail?.fields?.length]);

  const selectedFieldsForAnalytics = useMemo(() => {
    let fields = [];
    webFormDetail?.fields?.forEach((field) => {
      const index = tableFieldsToDisplay?.findIndex((item) => item?.id === field?.fieldId);
      if (index !== -1) {
        fields.push(tableFieldsToDisplay[index].customId);
      }
    });
    return fields;
  }, [tableFieldsToDisplay, webFormDetail]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = await validateInputs();
      if (!validateResult) return;
      let params;
      const index = collectionList?.findIndex((x) => x.id === webFormDetail?.tableId);
      if (index === -1) {
        params = { ...webFormDetail };
        params['fields'] = [];
      } else {
        params = webFormDetail;
      }
      const result = params?.id
        ? await dispatch(updateWebForm(organization_id, id, params))
        : await dispatch(createWebForm(organization_id, params));
      if (result) {
        onClose();
        if (id) {
          trackWebFormAnalyticActivity('updated', webFormDetail?.name, selectedFieldsForAnalytics);
        } else {
          trackWebFormAnalyticActivity('added', webFormDetail?.name, selectedFieldsForAnalytics);
        }
      }
    },
    [dispatch, onClose, validateInputs, organization_id, id, webFormDetail, collectionList, selectedFieldsForAnalytics]
  );

  const onDeleteWebForm = useCallback(async () => {
    trackWebFormActionAnalyticActivity('delete', webFormDetail?.name);
    const result = await dispatch(deleteWebForm(organization_id, id));
    if (result) {
      onClose();
      trackWebFormDeleteAnalyticActivity(webFormDetail?.id);
      history?.push('/settings/forms/');
    }
  }, [dispatch, onClose, history, organization_id, id, webFormDetail]);

  const renderPage = () => {
    switch (step) {
      case 1:
        return (
          <Step1>
            <FormWrapper>
              <TitleForm>Form Name</TitleForm>
              <FormPara>Add a descriptive name and set a redirect URL.</FormPara>
              <Input
                marginBottom={marginBottom}
                title='Form Name'
                isPrimarySmall
                type='text'
                autocomplete='off'
                placeholder='Demo'
                value={webFormDetail?.name || ''}
                onChange={(e) => {
                  onUpdateWebFormDetail('name', e.target.value);
                }}
                isRequierd
              />

              <ListWrappper>
                <DropDownTitle>Select Collection</DropDownTitle>
                <DropDown
                  options={collectionList}
                  labelField={'label'}
                  valueField={'id'}
                  value={!id ? webFormDetail?.tableId : webFormDetail?.tableId || ''}
                  onChange={(value) => {
                    onUpdateWebFormDetail('tableId', value?.id);
                  }}
                  placeholder={'Select Collection*'}
                />
                {validationErrors?.tableId && <Message text={validationErrors.tableId} isSuccess={false} />}
              </ListWrappper>
            </FormWrapper>
            <TitleForm>Thank You Page</TitleForm>
            <FormPara margin>
              Users will be sent here after a successful submission. To use our default confirmation page, you can just
              leave this blank.
            </FormPara>
            <Input
              title='Thank You Page'
              isPrimarySmall
              type='text'
              autocomplete='off'
              placeholder='https://www.salescamp.app/thank-you'
              value={webFormDetail?.thankYouUrl || ''}
              onChange={(e) => {
                onUpdateWebFormDetail('thankYouUrl', e.target.value);
              }}
            />
            {!isEmpty(validationErrors) && <Validation error={'* This fields are requierd'}></Validation>}
          </Step1>
        );
      case 2:
        return (
          <Step2>
            <NavigationWrapper onClick={back}>
              <SVGIcon fill='var(--gray-icon-color)' size={12} name='icon-big-left-arrow' />
              <NavigationTitle>Back to go</NavigationTitle>
            </NavigationWrapper>
            <CollectTitle>What information do you want to collect?</CollectTitle>
            {validationErrors?.fields && <Message text={validationErrors.fields} isSuccess={false} />}
            <InputWrapperBlock>
              {tableFieldsToDisplay?.map((field) => {
                return (
                  <CustomCheckboxWrapper key={field?.id}>
                    <LabelInput>
                      <IconLabel>
                        <InputWrapperModal>
                          <input
                            type='checkbox'
                            checked={field.isSelected}
                            onChange={(e) => {
                              handleSelectedFieldChange(field?.id);
                            }}
                          />
                          <span className='checkmark'></span>
                        </InputWrapperModal>
                        <LabelTitle>{field?.label}</LabelTitle>
                      </IconLabel>
                    </LabelInput>
                  </CustomCheckboxWrapper>
                );
              })}
            </InputWrapperBlock>
          </Step2>
        );
      default:
        break;
    }
  };

  return (
    <MainContact mobile={mobile}>
      <TitleView>
        <HeaderWrapper>
          <SVGIcon fill='var(--gray-icon-color)' size={22} name='icon-document' />
          <TitleText>{id ? 'Edit' : 'New'} Webform Builder</TitleText>
        </HeaderWrapper>
        <SvgWrapper onClick={onClose} title='Close'>
          <SVGIcon fill='var(--gray-icon-color)' size={20} name='icon-close' />
        </SvgWrapper>
      </TitleView>
      <Form
        onSubmit={(e) => {
          onSubmit(e);
        }}>
        {loading && isEmpty(webFormDetail) ? <>Loading...</> : <>{renderPage()}</>}
        <BottomView>
          <LeftWrapper>
            {step === 1 ? (
              <ButtopnWrapper>
                <Button
                  onClick={(e) => {
                    onClickNext(e);
                  }}
                  primary={true}
                  type='button'
                  title='Next'
                  loading={loading}
                />
              </ButtopnWrapper>
            ) : (
              <ButtopnWrapper>
                <Button loading={loading} primary={true} type='submit' title='Submit' />
              </ButtopnWrapper>
            )}
            <Button secondary={true} title='Cancel' type='button' onClick={onClose} />
          </LeftWrapper>
          {id && (
            <RightWrapper>
              <Button
                isDelete={true}
                title='Delete'
                type='button'
                onClick={() => {
                  ConfirmDialog({
                    iconName: 'icon-delete',
                    closeIcon: 'icon-close',
                    title: 'Delete',
                    message: 'Press ok to confirm delete this Web Form',
                    onOk: () => {
                      onDeleteWebForm();
                    }
                  });
                }}
                disabled={loading}
              />
            </RightWrapper>
          )}
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default ContactFormBuilder;
