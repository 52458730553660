import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';
/**
 * @desc Set Automation Loader
 */
export const setAutomationLoader = createAction(Actions.SET_AUTOMATION_LOADER);
/**
 * @desc Set Automation List
 */
export const setAutomationList = createAction(Actions.SET_AUTOMATION_LIST);
/**
 * @desc Update Automation List Item
 */
export const updateAutomationListItem = createAction(Actions.UPDATE_AUTOMATION_LIST_ITEM);
/**
 * @desc Set Automation Item
 */
export const setAutomationItem = createAction(Actions.SET_AUTOMATION_ITEM);
/**
 * @desc Update Automation Item
 */
export const updateAutomationItem = createAction(Actions.UPDATE_AUTOMATION_ITEM);
/**
 * @desc Delete Automation Item
 */
export const deleteAutomationItem = createAction(Actions.DELETE_AUTOMATION_ITEM);
/**
 * @desc Add Automation Step Item
 */
export const addAutomationStepItem = createAction(Actions.ADD_AUTOMATION_STEP_ITEM);
/**
 * @desc Delete Automation Step Item
 */
export const deleteAutomationStepItem = createAction(Actions.DELETE_AUTOMATION_STEP_ITEM);
/**
 * @desc Update Automation Step Item
 */
export const updateAutomationStepItem = createAction(Actions.UPDATE_AUTOMATION_STEP_ITEM);
/**
 * @desc Clear Automation Step Item
 */
export const clearAutomationStepItem = createAction(Actions.CLEAR_AUTOMATION_ITEM);
/*
 * @desc Clear Automation Data
 */
export const clearAutomationData = () => (dispatch) => {
  dispatch(setAutomationLoader(false));
  dispatch(setAutomationList(null));
};
