import styled, { css } from 'styled-components';
import { isWindowsApp } from '../../global/Helper';
import LinearBgMobile from './../../assets/images/mobilelinearbg.png';

const ManeWrapper = styled.div`
  display: flex;
  height: 100%;
  ${(props) =>
    props.mobile &&
    css`
      /* background-color: var(--white-color); */
    `}
  ${(props) =>
    props.mobile &&
    props.path &&
    css`
      background-color: transparent;
    `}
`;
const MacDragDro = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 24px;
  width: 100%;
  -webkit-user-select: auto;
  -webkit-app-region: drag;
`;
const RightWrapper = styled.div`
  width: 100%;
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          margin-left: 0px;
        `
      : css`
          margin-left: -15px;
        `}
`;
const ManeBlock = styled.div`
  width: 215px;
  height: 100%;
  position: relative;
  z-index: 1;
  padding-right: 15px;
  ${(props) =>
    props.mobile && props.isShowNavbar
      ? css`
          z-index: 250;
          top: 8px;
          position: fixed;
          bottom: 24px;
          left: 16px;
          transition: all 300ms;
          width: 200px;
          height: calc(100% - 32px);
          border-radius: 14px;
          overflow: hidden;
          box-shadow: var(--box-shadow-two);
        `
      : props.mobile &&
        css`
          left: -100%;
          z-index: 250;
          position: fixed;
          top: 8px;
          bottom: 24px;
          transition: all 300ms;
          width: 200px;
          height: calc(100% - 32px);
          border-radius: 14px;
          overflow: hidden;
          box-shadow: var(--box-shadow-two);
        `}
  ${(props) =>
    props.mobile
      ? css`
          :hover {
            .resizeDriver {
              opacity: 0;
            }
          }
          .icon-add-plus {
            opacity: 1;
            position: unset;
          }
        `
      : css`
          .sidebarNav .collection-blog {
            margin: 0 8px 8px 8px;
            padding: 0;
          }
          .sidebarNav .collection-blog .icon-plus {
            display: flex;
            background-color: var(--titlebar-bg);
            margin-left: 0;
            width: 32px;
            height: 32px;
            flex: 0 0 32px;
            border-radius: 6px;
            box-shadow: var(--box-shadow-two);
          }
          :hover {
            .icon-plus {
              display: flex;
            }
            .resizeDriver {
              opacity: 1;
            }
          }
        `}
  .sidebarWrapper {
    width: 60px;
  }
  &.sidebar {
    width: 60px;
  }
  .sidebarBlock {
    width: 48px;
  }
  .hide {
    display: none;
  }
  .hideSpan {
    margin: 4px 0;
    a {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      i {
        font-size: 20px;
      }
    }
    span {
      display: none;
    }
  }
  .imgWrapper {
    padding: 0 0px 30px 0px;
  }
  .ul {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .navBottom {
    width: 45px;
    padding: 0;
    li {
      width: 34px;
      height: 34px;
      margin: auto;
      div {
        width: 34px;
        height: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .more {
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 20px;
    }
  }
`;
const ScMainWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.mobile &&
    css`
      padding-top: 54px;
    `}
  &.page-container {
    padding-top: 0px;
  }
`;
const MobileHomeLinear = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background-color: #f3f2f0;
      background-image: url(${LinearBgMobile});
      background-size: 100% auto;
      background-position: top;
      background-repeat: no-repeat;
    `}
`;
const MainPageWrapper = styled.div`
  height: 100%;
  ${() =>
    isWindowsApp() &&
    css`
      height: calc(100% - 32px);
    `}
`;
const Link = styled.div`
  background-color: var(--primary-color-secondery-active);
  border-radius: 24px;
  width: 8px;
  height: 40px;
  position: absolute;
  right: -2px;
  margin-left: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
`;
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: transparent;
`;

export {
  ManeWrapper,
  RightWrapper,
  ManeBlock,
  Link,
  Overlay,
  MobileHomeLinear,
  ScMainWrapper,
  MacDragDro,
  MainPageWrapper
};
