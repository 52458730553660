import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input/Input';
import {
  addSegmentFilterItem,
  clearSegmentFilter,
  deleteSegmentFilterItem,
  setLookupValidationError,
  updateSegmentField,
  updateSegmentFilterItem
} from '../../../actions/lookupActions';
import Button from '../../../components/atom/Button/Button';
import DropDown from '../../../components/DropDown/DropDown';
import { LookupFieldTypes, LOOKUP_SEGMENT_FIELD_TYPE, SEGMENT_CONDITION_TYPE } from '../../../constants/constant';
import { isEmpty, setFieldConditionOptions } from '../../../helpers/common';
import Validation from '../../../components/Validation/Validation';
import {
  createSegmentFilter,
  getSegmentFilterItem,
  getTableTypeForAnalytics,
  trackSegmentAddedCollectionAnalyticActivity,
  updateSegmentFilter
} from '../../../services/lookupService';
import SVGIcon from '../../../assets/svg/SVGIcon';
import {
  MainContact,
  TitleView,
  TitleText,
  Form,
  SecondContain,
  InputView,
  BottomView,
  DropView,
  UploadProfilePicture,
  IconView,
  AddView,
  AddText,
  BtnView,
  ModifyUserOptionItemListLi,
  LabelInput,
  AllBtnView,
  RightBtn,
  TitleLeft
} from './styles';
import SegmentInputField from './SegmentInputField';

const AddNewFilter = (props) => {
  const table_id = props.table_id;
  const item_id = props?.item_id;
  const lookupSelector = useSelector((state) => state.lookup);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { tableFields } = lookupTableSelector;
  const dispatch = useDispatch();
  let { segmentFilter, validationError, loading } = lookupSelector;
  let { filters } = segmentFilter;
  const onClose = useCallback(() => {
    if (props.handleCloseModal) props.handleCloseModal();
  }, [props]);

  useEffect(() => {
    if (item_id) dispatch(getSegmentFilterItem(organization_id, table_id, item_id));
    return () => {
      dispatch(clearSegmentFilter());
      dispatch(setLookupValidationError({}));
    };
  }, [dispatch, organization_id, item_id, table_id]);

  const fields = useMemo(() => {
    const allField = tableFields?.find((x) => x.tableId === table_id);
    const fiels = allField?.columns
      .filter((x) => x.type !== LookupFieldTypes.Lookup)
      .map((item) => {
        return { ...item, fieldOption: item?.options, options: undefined };
      });
    return fiels;
  }, [tableFields, table_id]);

  const onChangeValue = useCallback(
    (propsName, value) => {
      dispatch(updateSegmentField({ propsName, value }));
    },
    [dispatch]
  );

  const addNewFilterItem = useCallback(() => {
    const item = {
      condition: undefined,
      field: undefined,
      type: LOOKUP_SEGMENT_FIELD_TYPE.LookupField,
      localConditionOptions: []
    };
    dispatch(addSegmentFilterItem({ item: item }));
  }, [dispatch]);

  const onChangeCondition = useCallback(
    (item, condition, index) => {
      const filterItem = {
        ...item,
        condition: condition
      };
      dispatch(updateSegmentFilterItem({ index: index, item: filterItem }));
    },
    [dispatch]
  );

  const onChangeField = useCallback(
    (item, field, index) => {
      const filterItem = {
        ...item,
        field: field,
        condition: {},
        value: undefined,
        value_Bool: undefined,
        value_Date: undefined,
        value_Lookup: undefined,
        value_Number: undefined
      };
      setFieldConditionOptions(filterItem, 'field');
      dispatch(updateSegmentFilterItem({ index: index, item: filterItem }));
    },
    [dispatch]
  );

  const onRemoveItem = useCallback(
    (index) => {
      dispatch(deleteSegmentFilterItem({ index: index }));
    },
    [dispatch]
  );

  const onChangeValues = useCallback(
    (item, key, value, index) => {
      const filterItem = {
        ...item,
        [key]: value
      };
      dispatch(updateSegmentFilterItem({ index: index, item: filterItem }));
    },
    [dispatch]
  );

  const onSave = useCallback(
    async (e) => {
      e.preventDefault();
      let result;
      if (item_id) {
        result = await dispatch(updateSegmentFilter(organization_id, table_id, item_id, segmentFilter));
      } else {
        result = await dispatch(createSegmentFilter(organization_id, table_id, segmentFilter));
      }
      if (result) {
        const type = getTableTypeForAnalytics(props?.table);
        const activityType = item_id ? 'updated' : 'added';
        trackSegmentAddedCollectionAnalyticActivity(activityType, type, segmentFilter?.name);
        if (props.onUpdateFilter) props.onUpdateFilter(item_id);
        onClose();
      }
    },
    [item_id, dispatch, organization_id, table_id, segmentFilter, props, onClose]
  );

  return (
    <MainContact>
      <TitleView>
        <TitleLeft>
          <IconView title='Close'>
            <SVGIcon name='icon-filter-sort' size={22} fill='var(--gray-icon-color)' />
          </IconView>
          <TitleText>{item_id ? 'Edit' : 'New'} Filter</TitleText>
        </TitleLeft>
        <IconView title='Close' onClick={onClose}>
          <SVGIcon name='icon-close' size={20} fill='var(--gray-icon-color)' />
        </IconView>
      </TitleView>
      <Form onSubmit={onSave}>
        <SecondContain>
          <InputView>
            <Input
              isPrimarySmall={true}
              type='text'
              id='First-Name'
              name='Name'
              placeholder='Filter Name'
              value={segmentFilter?.name}
              onChange={(e) => {
                onChangeValue('name', e.target.value);
              }}
            />
            {!isEmpty(validationError) && <Validation error={validationError.name} />}
          </InputView>

          {filters?.map((item, index) => {
            return (
              <DropView>
                <UploadProfilePicture>
                  <DropDown
                    options={fields}
                    labelField={'label'}
                    valueField={'id'}
                    placeholder={''}
                    value={item?.field}
                    hasObject={true}
                    onChange={(field) => {
                      onChangeField(item, field, index);
                    }}
                  />
                  {!isEmpty(validationError) && validationError?.filters[index] && (
                    <Validation error={validationError?.filters[index].field} />
                  )}
                </UploadProfilePicture>
                <UploadProfilePicture>
                  <DropDown
                    options={item?.localConditionOptions}
                    hasObject={true}
                    labelField={'text'}
                    valueField={'value'}
                    placeholder={''}
                    value={item?.condition}
                    onChange={(condition) => {
                      onChangeCondition(item, condition, index);
                    }}
                  />
                  {!isEmpty(validationError) && validationError?.filters[index] && (
                    <Validation error={validationError?.filters[index].condition} />
                  )}
                </UploadProfilePicture>
                {!isEmpty(item?.condition) && (
                  <UploadProfilePicture>
                    <SegmentInputField
                      type={item?.condition?.valueType}
                      item={item}
                      index={index}
                      fieldName={'field'}
                      resError={validationError}
                      onChangeValues={(key, value) => {
                        onChangeValues(item, key, value, index);
                      }}
                    />
                    {!isEmpty(validationError) && <Validation error={validationError?.filters[index]?.value} />}
                  </UploadProfilePicture>
                )}
                <IconView
                  title='Delete'
                  onClick={() => {
                    onRemoveItem(index);
                  }}>
                  <SVGIcon name='icon-delete' size={22} fill='var(--gray-icon-color)' />
                </IconView>
              </DropView>
            );
          })}

          <AddView>
            <AddText onClick={addNewFilterItem}>+ Add Option</AddText>
          </AddView>
        </SecondContain>
        <BottomView>
          <AllBtnView>
            <BtnView>
              <Button title='save' type='submit' loading={loading} />
              <Button onClick={onClose} secondary title='Close' type='button' />
            </BtnView>
            <RightBtn>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  Match All
                  <input
                    type='radio'
                    name='condition'
                    checked={segmentFilter.condition === SEGMENT_CONDITION_TYPE.MATCH_ALL}
                    onChange={() => {
                      onChangeValue('condition', SEGMENT_CONDITION_TYPE.MATCH_ALL);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
              <ModifyUserOptionItemListLi>
                <LabelInput>
                  Match Any
                  <input
                    type='radio'
                    name='condition'
                    checked={segmentFilter.condition === SEGMENT_CONDITION_TYPE.MATCH_ANY}
                    onChange={() => {
                      onChangeValue('condition', SEGMENT_CONDITION_TYPE.MATCH_ANY);
                    }}
                  />
                  <span className='checkmark'></span>
                </LabelInput>
              </ModifyUserOptionItemListLi>
            </RightBtn>
          </AllBtnView>
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default AddNewFilter;
