import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import Input from '../../../components/Input/Input';
// import Icon from '../../../components/Icon/Icon';
import { addSendOnlyEmailAccount, testSMTPEmail } from '../../../services/emailService';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Validation from '../../../components/Validation/Validation';
import { isEmpty } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { BottomView, Form, LeftWrapper, Step2, TwoInput, TwoInputWrapper, InputSend, ButtonWrapper } from './styles';

const SendOnlyEmailProvider = (props) => {
  const mobile = useMobileDevice();
  const orgId = props.orgId;
  const emailSelector = useSelector((state) => state.email);
  const { loading } = emailSelector;

  const [senderName, setSenderName] = useState('');
  const [senderFullName, setSenderFullName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [smtpLable, setSmtpLable] = useState('');
  const [smtpPassword, setSmtpPassword] = useState('');
  const [smtpHost, setSmtpHost] = useState('');
  const [smtpPort, setSmtpPort] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();

  const validateInputs = useCallback(() => {
    let valid = true;
    let validationErrors = {};
    if (!senderName) {
      validationErrors.senderName = 'Required';
      valid = false;
    }
    if (!senderFullName) {
      validationErrors.senderFullName = 'Required';
      valid = false;
    }
    if (!senderEmail) {
      validationErrors.senderEmail = 'Required';
      valid = false;
    }
    if (!smtpLable) {
      validationErrors.smtpLable = 'Required';
      valid = false;
    }
    if (!smtpPassword) {
      validationErrors.smtpPassword = 'Required';
      valid = false;
    }
    if (!smtpHost) {
      validationErrors.smtpHost = 'Required';
      valid = false;
    }
    if (!smtpPort) {
      validationErrors.smtpPort = 'Required';
      valid = false;
    }
    setValidationErrors(validationErrors);
    return valid;
  }, [senderName, senderFullName, senderEmail, smtpPassword, smtpLable, smtpHost, smtpPort]);

  const getParams = useCallback(() => {
    const paramsObj = {
      accountType: 3,
      isEmailSync: false,
      name: senderName,
      senderName: senderFullName,
      senderEmail: senderEmail,
      userName: smtpLable,
      password: smtpPassword,
      smtp_host: smtpHost,
      smtp_port: smtpPort
    };
    return paramsObj;
  }, [senderName, senderFullName, senderEmail, smtpPassword, smtpLable, smtpHost, smtpPort]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validateResult = validateInputs();
      if (!validateResult) return;

      const params = getParams();
      const result = await dispatch(addSendOnlyEmailAccount(orgId, params));
      if (result) {
        props.onClose();
      }
    },
    [dispatch, validateInputs, orgId, getParams, props]
  );

  const onTestSMTPEmail = useCallback(async () => {
    const validateResult = validateInputs();
    if (!validateResult) return;

    const params = getParams();
    await dispatch(testSMTPEmail(orgId, params));
  }, [dispatch, validateInputs, getParams, orgId]);

  return (
    <Step2>
      <Form onSubmit={(e) => onSubmit(e)}>
        <InputSend>
          <TwoInput mobile={mobile}>
            <Input
              title='Name of Your Sender'
              isPrimarySmall
              onChange={(e) => setSenderName(e.target.value)}
              autocomplete='off'
              placeholder='My Sender'
              isRequierd
            />
            <Input
              title='Sender Email Full Name'
              onChange={(e) => setSenderFullName(e.target.value)}
              isPrimarySmall
              autocomplete='off'
              placeholder='John Doe'
              isRequierd
            />
          </TwoInput>
          <Input
            title='Sender Email'
            isPrimarySmall
            autocomplete='off'
            onChange={(e) => setSenderEmail(e.target.value)}
            placeholder='email@company.com'
            isRequierd
          />
          <TwoInput mobile={mobile}>
            <Input
              title='Lable'
              isPrimarySmall
              autocomplete='off'
              onChange={(e) => setSmtpLable(e.target.value)}
              placeholder='email@yahoo.com'
              isRequierd
            />
            <Input
              title='SMTP Password'
              type='password'
              onChange={(e) => setSmtpPassword(e.target.value)}
              isPrimarySmall
              autocomplete='off'
              placeholder='****'
              isRequierd
            />
          </TwoInput>
          <TwoInputWrapper>
            <Input
              title='SMTP Host'
              isPrimarySmall
              onChange={(e) => setSmtpHost(e.target.value)}
              autocomplete='off'
              placeholder='smtp.domin.com'
              isRequierd
            />
            <Input
              isPrimarySmall
              autocomplete='off'
              onChange={(e) => setSmtpPort(e.target.value)}
              placeholder='Port 587'
            />
          </TwoInputWrapper>
          {!isEmpty(validationErrors) && <Validation error={'* This fields are requierd'}></Validation>}
        </InputSend>
        <BottomView>
          <LeftWrapper>
            <Button loading={loading} type='submit' title='Add Account' />
            <ButtonWrapper>
              <Button secondary loading={loading} type='button' onClick={onTestSMTPEmail} title='Test Email' />
            </ButtonWrapper>
          </LeftWrapper>
          {/* <RightWrapper>
            <Icon name='feather-help-circle' fontSize='18' color='var(--dark-color)' />
            <RightPara>Learn about calling in crm.bearbook.com</RightPara>
          </RightWrapper> */}
        </BottomView>
      </Form>
      <SnackBar />
    </Step2>
  );
};

export default SendOnlyEmailProvider;
