import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { getCurrentCompanyPlanType, getOrganizationList } from '../../../services/organizationService';
import UserPreferenceSingleton from '../../../helpers/UserPreferenceSingleton';
import { ROUTE } from '../../../constants/routeConst';
import AppLoading from '../../AppLoading';
import { appInit, clearOrgReduxData } from '../../../services/appService';
import Avtar from '../../Avtar/Avtar';
import { AvatarBGColors } from '../../../constants/constant';
import { isEmpty } from './../../../helpers/common';
import {
  OrganizationMain,
  OrganizationList,
  OrganizeListItem,
  OrganizeItemBlock,
  OrganizeImg,
  PluseOrganizeIcon,
  ToggleOrgNavbar,
  OranizationWrap
} from './styles';

const OrganizationNavbar = ({ loading, onSwitchOrganizationLoading }) => {
  const [isOrganizationNavbar, setIsOrganizationNavbar] = useState(true);
  const history = useHistory();
  const organizationSelector = useSelector((state) => state.organization);
  const { list, organization } = organizationSelector;
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getOrganizationList());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    var isWorkspaceNavbarOpen = UserPreferenceSingleton.getInstance()?.getSettingValue('is_workspace_navbar_open');
    if (!isEmpty(isWorkspaceNavbarOpen)) {
      setIsOrganizationNavbar(isWorkspaceNavbarOpen);
    }
  }, []);

  const onPressOrganization = useCallback(
    async (item) => {
      try {
        if (onSwitchOrganizationLoading) onSwitchOrganizationLoading(true);
        history?.push(ROUTE.INBOX);
        UserPreferenceSingleton.getInstance().setOrganization(item);
        await dispatch(clearOrgReduxData());
        await dispatch(appInit());
      } catch (e) {
        console.log('ERROR', e);
      } finally {
        if (onSwitchOrganizationLoading) onSwitchOrganizationLoading(false);
      }
    },
    [dispatch, history, onSwitchOrganizationLoading]
  );

  const handleOrganizationNavbar = useCallback(() => {
    const newValue = !isOrganizationNavbar;
    setIsOrganizationNavbar(newValue);
    UserPreferenceSingleton.getInstance()?.setSettingValue('is_workspace_navbar_open', newValue);
  }, [isOrganizationNavbar]);

  const planType = useMemo(() => {
    return getCurrentCompanyPlanType();
  }, []);

  const hasNavBarExpiredText = useMemo(() => {
    if (planType !== 'paid' && (organization?.plan === 'expired' || organization?.planRemainingDays <= 7)) return true;
  }, [organization, planType]);

  if (loading) return <AppLoading />;

  if (list?.length <= 1) return null;

  return (
    <>
      <OranizationWrap>
        {isOrganizationNavbar && (
          <OrganizationMain>
            <OrganizationList>
              {list?.map((item, index) => (
                <OrganizeListItem
                  className={organization?.id === item?.id ? 'active' : ''}
                  key={`org_${item?.id}_index_${index}`}
                  title={item?.name}
                  onClick={() => onPressOrganization(item)}>
                  <OrganizeItemBlock>
                    {item?.logoUrl ? (
                      <OrganizeImg src={item?.logoUrl}></OrganizeImg>
                    ) : (
                      <Avtar
                        name={item?.name}
                        size={30}
                        backgroundColor={AvatarBGColors[index % AvatarBGColors.length]}
                      />
                    )}
                  </OrganizeItemBlock>
                </OrganizeListItem>
              ))}
              <OrganizeListItem PluseIcon className='pluse-icon'>
                <PluseOrganizeIcon
                  onClick={() => {
                    history.push('/onboarding');
                  }}>
                  <SVGIcon name='icon-pluse' size={16} fill='var(--white-color)' strokeWidth={1.5} />
                </PluseOrganizeIcon>
              </OrganizeListItem>
            </OrganizationList>
          </OrganizationMain>
        )}
        <ToggleOrgNavbar onClick={handleOrganizationNavbar} hasNavBarExpiredText={hasNavBarExpiredText}>
          {isOrganizationNavbar ? (
            <SVGIcon name='icon-left-arrow' fill='var(--white-color)' size={22} />
          ) : (
            <SVGIcon name='icon-right-arrow' fill='var(--white-color)' size={22} />
          )}
        </ToggleOrgNavbar>
      </OranizationWrap>
    </>
  );
};

export default OrganizationNavbar;
