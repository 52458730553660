import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atom/Button/Button';
import { getUserAccessData, updateUserAccessPermissionData } from '../../../services/settingService';
import { LOOKUP_TYPE, USER_DATA_ACCESS_TYPE } from '../../../constants/constant';
import { updateUserAccessData, setUserAccessData } from '../../../actions/settingActions';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { isEmpty } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import {
  MainContact,
  TitleView,
  TitleText,
  BottomView,
  Form,
  LeftWrapper,
  ModifyUserBlock,
  ModifyUserHead,
  CustomCheckboxWrapper,
  InputWrapperModal,
  CustomCheckbox,
  IconLabel,
  LabelTitle,
  ModifyUserContent,
  ModifyUserOption,
  ModifyUserOptionItem,
  ModifyHeading,
  ModifyUserOptionItemList,
  ModifyUserOptionItemListLi,
  LabelInput,
  ModifyList,
  InputLabel,
  TitleWrapper,
  SvgWrapper
} from './styles';

const USER_ACCESS_DATA_ITEM_TYPE = {
  EMAIL: 'Email',
  TABLES: 'Tables',
  TASK: 'Task',
  REPORT: 'Report',
  AUTOMATION: 'Automation'
};

const ModifyUserModal = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const onClose = useCallback(() => {
    if (props.handleCloseModal) props.handleCloseModal();
  }, [props]);

  const dispatch = useDispatch();
  const user = props?.user;
  const loadData = useCallback(() => {
    dispatch(getUserAccessData(organization_id, user?.id));
  }, [dispatch, organization_id, user?.id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setUserAccessData());
    };
  }, [dispatch, loadData]);

  const settingSelector = useSelector((state) => state.setting);
  const { userAccessData, loading } = settingSelector;

  const getLookupIcon = useCallback(
    (type, item) => {
      let iconName;
      if (type !== USER_ACCESS_DATA_ITEM_TYPE?.TABLES) {
        switch (type) {
          case USER_ACCESS_DATA_ITEM_TYPE?.EMAIL:
            iconName = 'icon-email';
            break;
          case USER_ACCESS_DATA_ITEM_TYPE?.TASK:
            iconName = 'icon-task';
            break;
          case USER_ACCESS_DATA_ITEM_TYPE?.REPORT:
            iconName = 'icon-infographic';
            break;
          case USER_ACCESS_DATA_ITEM_TYPE?.AUTOMATION:
            iconName = 'icon-webcam';
            break;
          default:
        }
      } else {
        switch (item?.table?.type) {
          case LOOKUP_TYPE?.contacts:
            iconName = 'icon-user';
            break;
          case LOOKUP_TYPE?.companies:
            iconName = 'icon-account';
            break;
          case LOOKUP_TYPE?.deals:
            iconName = 'icon-deals';
            break;
          case LOOKUP_TYPE?.others:
            iconName = 'icon-other';
            break;
          case LOOKUP_TYPE?.leads:
            iconName = 'icon-lead';
            break;
          default:
        }
      }
      return <SVGIcon fill='var(--gray-icon-color)' size={mobile ? '20' : '24'} name={iconName} />;
    },
    [mobile]
  );

  const onUpdateUserAccessData = useCallback(
    (type, prop, val, Id) => {
      dispatch(updateUserAccessData({ type: type.toLowerCase(), propName: prop, value: val, tableId: Id }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const params = userAccessData;
      const result = await dispatch(updateUserAccessPermissionData(organization_id, user?.id, params));
      if (result) {
        onClose();
      }
    },
    [dispatch, onClose, organization_id, user?.id, userAccessData]
  );

  const renderItem = (type, item) => {
    return (
      <ModifyUserBlock>
        <ModifyUserHead>
          <CustomCheckboxWrapper>
            <LabelInput htmlFor={type !== USER_ACCESS_DATA_ITEM_TYPE?.TABLES ? type : item?.table?.label}>
              <CustomCheckbox>
                <IconLabel>
                  {getLookupIcon(type, item)}
                  <LabelTitle mobile={mobile}>
                    {type !== USER_ACCESS_DATA_ITEM_TYPE?.TABLES ? type : item?.table?.label}
                  </LabelTitle>
                </IconLabel>
              </CustomCheckbox>
            </LabelInput>
            <label className='switch'>
              <input
                type='checkbox'
                id={type !== USER_ACCESS_DATA_ITEM_TYPE?.TABLES ? type : item?.table?.label}
                checked={item?.isAccess || false}
                onChange={() => {
                  onUpdateUserAccessData(type, 'isAccess', !item?.isAccess, item?.table?.id);
                }}
              />
              <span className='slider round'></span>
            </label>
          </CustomCheckboxWrapper>
        </ModifyUserHead>
        {type !== USER_ACCESS_DATA_ITEM_TYPE?.REPORT && item?.isAccess && (
          <ModifyUserContent>
            <ModifyUserOption mobile={mobile}>
              <ModifyUserOptionItem>
                <ModifyHeading>Data Access</ModifyHeading>
                <ModifyUserOptionItemList>
                  <ModifyUserOptionItemListLi>
                    <InputLabel>
                      Owner
                      <input
                        type='radio'
                        name={item?.table?.name || type}
                        checked={item?.dataAccess === USER_DATA_ACCESS_TYPE?.OWNER}
                        onChange={() => {
                          onUpdateUserAccessData(type, 'dataAccess', USER_DATA_ACCESS_TYPE?.OWNER, item?.table?.id);
                        }}
                      />
                      <span className='checkmark'></span>
                    </InputLabel>
                  </ModifyUserOptionItemListLi>
                  <ModifyUserOptionItemListLi>
                    <InputLabel>
                      All
                      <input
                        type='radio'
                        name={item?.table?.name || type}
                        checked={item?.dataAccess === USER_DATA_ACCESS_TYPE?.ALL}
                        onChange={() => {
                          onUpdateUserAccessData(type, 'dataAccess', USER_DATA_ACCESS_TYPE?.ALL, item?.table?.id);
                        }}
                      />
                      <span className='checkmark'></span>
                    </InputLabel>
                  </ModifyUserOptionItemListLi>
                </ModifyUserOptionItemList>
              </ModifyUserOptionItem>
              <ModifyUserOptionItem paddingLeft mobile={mobile}>
                <ModifyHeading>Permission</ModifyHeading>
                <ModifyList>
                  <InputWrapperModal paddingLeftRemover>
                    <InputLabel>
                      {type === USER_ACCESS_DATA_ITEM_TYPE?.EMAIL ? 'Send' : 'Edit'}
                      <input
                        type='checkbox'
                        checked={
                          type === USER_ACCESS_DATA_ITEM_TYPE?.EMAIL ? item?.permissionSend : item?.permissionEdit
                        }
                        onChange={() => {
                          onUpdateUserAccessData(
                            type,
                            type === USER_ACCESS_DATA_ITEM_TYPE?.EMAIL ? 'permissionSend' : 'permissionEdit',
                            type === USER_ACCESS_DATA_ITEM_TYPE?.EMAIL ? !item?.permissionSend : !item?.permissionEdit,
                            item?.table?.id
                          );
                        }}
                      />
                      <span className='checkmark'></span>
                    </InputLabel>
                  </InputWrapperModal>
                  <InputWrapperModal>
                    <InputLabel>
                      Delete
                      <input
                        type='checkbox'
                        checked={item?.permissionDelete}
                        onChange={() => {
                          onUpdateUserAccessData(type, 'permissionDelete', !item?.permissionDelete, item?.table?.id);
                        }}
                      />
                      <span className='checkmark'></span>
                    </InputLabel>
                  </InputWrapperModal>
                  {type === USER_ACCESS_DATA_ITEM_TYPE?.TABLES && (
                    <InputWrapperModal>
                      <InputLabel>
                        Export
                        <input
                          type='checkbox'
                          checked={item?.permissionExport}
                          onChange={() => {
                            onUpdateUserAccessData(type, 'permissionExport', !item?.permissionExport, item?.table?.id);
                          }}
                        />
                        <span className='checkmark'></span>
                      </InputLabel>
                    </InputWrapperModal>
                  )}
                </ModifyList>
              </ModifyUserOptionItem>
            </ModifyUserOption>
          </ModifyUserContent>
        )}
      </ModifyUserBlock>
    );
  };

  return (
    <MainContact mobile={mobile}>
      <TitleView>
        <TitleWrapper>
          <SVGIcon fill='var(--gray-icon-color)' size={24} name='icon-user-card' />
          <TitleText>
            Modify User - <span>{user?.name}</span>
          </TitleText>
        </TitleWrapper>
        <SvgWrapper onClick={onClose}>
          <SVGIcon fill='var(--gray-icon-color)' size={20} name='icon-close' title='Close' />
        </SvgWrapper>
      </TitleView>
      <Form onSubmit={onSubmit}>
        {loading && isEmpty(userAccessData) ? (
          <Form>Loading...</Form>
        ) : (
          <>
            {renderItem(USER_ACCESS_DATA_ITEM_TYPE?.EMAIL, userAccessData?.email)}
            {userAccessData?.tables?.map((table, index) => {
              return <div key={index}>{renderItem(USER_ACCESS_DATA_ITEM_TYPE?.TABLES, table)}</div>;
            })}
            {renderItem(USER_ACCESS_DATA_ITEM_TYPE?.TASK, userAccessData?.task)}
            {renderItem(USER_ACCESS_DATA_ITEM_TYPE?.REPORT, userAccessData?.report)}
            {renderItem(USER_ACCESS_DATA_ITEM_TYPE?.AUTOMATION, userAccessData?.automation)}
          </>
        )}
        <BottomView>
          <LeftWrapper>
            <Button primary={true} title='Save' type='submit' loading={loading} />
            <Button secondary={true} title='Cancel' type='button' onClick={onClose} />
          </LeftWrapper>
        </BottomView>
      </Form>
      <SnackBar />
    </MainContact>
  );
};

export default ModifyUserModal;
