import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Avtar from '../../../../../components/Avtar/Avtar';

import useMobileDevice from '../../../../../hooks/useMobileDevice';
import { EMAIL_LABEL_ID, TOOLTIP_DATE_FORMAT } from '../../../../../constants/constant';
import CommentHoverItem from '../Comments/CommentHoverItem';
import RenderComments from '../Comments/RenderComments';
import { getGravatarURL } from '../../../../../helpers/common';

import {
  MainList,
  ActivityUpperBlock,
  IconList,
  AllList,
  RightList,
  UserText,
  UserRecive,
  ActivityBottomBlock,
  ContainView,
  Captiontitle,
  Captionmess,
  TimeText,
  ThraedInner,
  UserWrapperBlock
} from '../styles';
const EmailActivityItem = ({
  item,
  actionIcon,
  isOpenThread,
  onLookupClick,
  onMoreMenuClick,
  onOpenEmailDetailModal,
  isOpenMoreOptionMenu,
  selectedActivity,
  isShowCommentInput,
  onClickComment
}) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;
  const { organization } = organizationSelector;
  const { users } = organization;
  const message = useMemo(() => {
    let sentMessage;
    let senderName;
    sentMessage = item?.lastMsg?.labelIds?.find((x) => x === EMAIL_LABEL_ID.SENT);
    if (sentMessage) {
      if (item?.lastMsg?.sc_username) {
        senderName = item?.lastMsg?.sc_username;
      } else if (item?.lastMsg?.sc_userId) {
        const user = users?.find((x) => x?.id === item?.lastMsg?.sc_userId);
        if (user) {
          senderName = user?.name;
        }
      }
    } else {
      senderName = item?.lookup ? item?.lookup?.name : currentUser?.given_name;
    }
    return { senderName, isSent: sentMessage ? true : false };
  }, [item, currentUser?.given_name, users]);
  const gravatar = useMemo(() => {
    if (isOpenThread && item?.lookup) {
      return getGravatarURL(item?.lookup?.email);
    }
  }, [isOpenThread, item?.lookup]);
  return (
    <MainList key={item?.id}>
      <ActivityUpperBlock mobile={mobile} isOpenThread={isOpenThread}>
        {isOpenThread ? (
          item?.lookup && <Avtar imgSrc={gravatar} name={item?.lookup?.name} size={mobile ? 24 : 26} />
        ) : (
          <IconList>{actionIcon}</IconList>
        )}
        <AllList>
          <RightList isOpenThread={isOpenThread}>
            {isOpenThread && item?.lookup && (
              <ThraedInner
                onClick={() => {
                  onLookupClick(item?.lookup);
                }}>
                <UserText>{item?.lookup?.name}</UserText>
              </ThraedInner>
            )}
            <UserWrapperBlock mobile={mobile}>
              <UserText isOpenThread={isOpenThread} mobile={mobile}>
                {message?.senderName}
              </UserText>
              <UserRecive isOpenThread={isOpenThread} mobile={mobile}>
                {message?.isSent ? 'sent' : 'received'} an Email
                {String(item?.actionName || '').toLowerCase()}
              </UserRecive>
              <TimeText
                isOpenThread={isOpenThread}
                mobile={mobile}
                title={moment(item?.dateTime).format(TOOLTIP_DATE_FORMAT)}>
                {moment(item?.dateTime).fromNow()}
              </TimeText>
            </UserWrapperBlock>
          </RightList>
        </AllList>
      </ActivityUpperBlock>
      <ActivityBottomBlock mobile={mobile} isOpenThread={isOpenThread}>
        <ContainView>
          <div
            onClick={(e) => {
              if (onOpenEmailDetailModal) onOpenEmailDetailModal(e, item);
            }}>
            <Captiontitle>{item?.subject}</Captiontitle>
            <Captionmess
              className='quill_format'
              isTitle={item?.subject ? true : false}
              dangerouslySetInnerHTML={{ __html: item?.snippet }}
            />
          </div>
          {onMoreMenuClick && (
            <CommentHoverItem
              item={item}
              isOpenMoreOptionMenu={isOpenMoreOptionMenu}
              selectedActivity={selectedActivity}
              onClickMoreMenu={onMoreMenuClick}
              onClickComment={onClickComment}
            />
          )}
        </ContainView>
        <RenderComments
          item={item?.commentActivity || item}
          isShowCommentInput={isShowCommentInput}
          selectedActivity={selectedActivity}
        />
      </ActivityBottomBlock>
    </MainList>
  );
};

export default EmailActivityItem;
