import React from 'react';
import DropDown from '../DropDown/DropDown';
import { DropMenu, LabelText, LeftView, SelectedValue } from './styles';

const CountryDropDown = (props) => {
  const { options, value, onChange, labelField, valueField } = props;
  const formatOptionLabel = ({ text, code }, { ...props }) => {
    return props?.context === 'menu' ? (
      <DropMenu>
        <LeftView>
          <LabelText>{text}</LabelText>
        </LeftView>
      </DropMenu>
    ) : (
      <SelectedValue>{code}</SelectedValue>
    );
  };

  return (
    <DropDown
      options={options}
      value={value}
      labelField={labelField}
      valueField={valueField}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      {...props}
    />
  );
};

export default CountryDropDown;
