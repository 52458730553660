import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal/index';
import { Menu, MenuItem } from '../../../components/Menu';
// import { ReactComponent as Dot } from '../../../assets/images/svg/dot.svg';
import { getOrganizationDetails, makeAnAdmin, removeFromAdmin } from '../../../services/organizationService';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { deleteUser, inviteUser } from '../../../services/settingService';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import ModifyUserModal from './ModifyUserModal';
import {
  Wrapper,
  MainList,
  Title,
  InputWrapper,
  ContainBoxTwo,
  ListBox,
  FirstList,
  SubText,
  CaptionText,
  IconView,
  LoginDetails,
  ButtonInvite,
  EmailInput,
  MenuItemWrapper,
  Label,
  SvgWrapper
} from './styles';

const Users = () => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const authSelector = useSelector((state) => state.auth);
  const { currentUser } = authSelector;

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [emailId, setEmailId] = useState('');

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(getOrganizationDetails(organization_id));
  }, [dispatch, organization_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  const { users, invitedUsers, isAdmin } = organization;

  const allUsers = useMemo(() => {
    let allUser = users;
    if (invitedUsers && invitedUsers?.length > 0) {
      allUser = allUser.concat(invitedUsers);
    }
    return allUser;
  }, [users, invitedUsers]);

  const trackRemoveUserAnalyticActivity = useCallback((user) => {
    try {
      trackAnalyticActivity('account removed user', { user_email: user?.email, user_name: user?.name });
    } catch (e) {
      console.log('track account removed user Error', e);
    }
  }, []);

  const trackUserActionEvent = useCallback((action) => {
    try {
      trackAnalyticActivity('settings - user: action', { action: action });
    } catch (e) {
      console.log('track settings - user: action Error', e);
    }
  }, []);

  const onOpenMenu = useCallback((e, user) => {
    e.stopPropagation();
    setSelectedUser(user);
    setIsMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenEditMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setIsMenuOpen(false);
      setIsEditMenuOpen(true);
      setMenuAnchorEl(null);
      trackUserActionEvent('edit');
    },
    [trackUserActionEvent]
  );

  const onCloseEditMenu = useCallback(() => {
    setIsEditMenuOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const onInviteUser = useCallback(() => {
    trackUserActionEvent('add user');
    const params = {
      companyId: organization_id,
      email: emailId,
      name: ''
    };
    const result = dispatch(inviteUser(organization_id, params));
    if (result) {
      setEmailId();
    }
  }, [dispatch, trackUserActionEvent, emailId, organization_id]);

  const onDeleteUser = useCallback(async () => {
    trackUserActionEvent('delete');
    onCloseMenu();
    const result = await dispatch(deleteUser(organization_id, selectedUser?.id));
    if (result) {
      trackRemoveUserAnalyticActivity(selectedUser);
      setEmailId();
    }
  }, [trackUserActionEvent, onCloseMenu, dispatch, organization_id, selectedUser, trackRemoveUserAnalyticActivity]);

  const onClickMakeAdmin = useCallback(async () => {
    let result;
    if (selectedUser?.isAdmin) {
      result = await dispatch(removeFromAdmin(organization_id, selectedUser?.id));
    } else {
      result = await dispatch(makeAnAdmin(organization_id, selectedUser?.id));
    }
    if (result) onCloseMenu();
  }, [dispatch, onCloseMenu, organization_id, selectedUser?.id, selectedUser?.isAdmin]);
  return (
    <Wrapper>
      <MainList mobile={mobile}>
        {mobile ? null : <Title mobile={mobile}>Users</Title>}
        <InputWrapper mobile={mobile}>
          <EmailInput
            mobile={mobile}
            title='Email address'
            isPrimaryLable={true}
            type='email'
            autocomplete='off'
            required=''
            id='email'
            name='email'
            placeholder='enter your email'
            value={emailId || ''}
            onChange={(e) => {
              setEmailId(e.target.value);
            }}
          />
          <ButtonInvite primary title='Invite' type='button' onClick={onInviteUser} mobile={mobile} />
        </InputWrapper>
        <ContainBoxTwo>
          {allUsers?.map((user) => {
            return (
              <ListBox key={user?.id}>
                <FirstList>
                  {user?.name && <SubText>{user?.name}</SubText>}
                  {user?.isOwner ? <Label>Owner</Label> : user?.isAdmin ? <Label>Admin</Label> : null}
                  <LoginDetails>
                    <CaptionText>{user?.email}</CaptionText>
                    {/* <Dot />
                  <CaptionText>
                    <span>Last Login:</span> 12 min ago
                  </CaptionText> */}
                  </LoginDetails>
                </FirstList>
                <IconView>
                  {currentUser?.id !== user?.id && (
                    <SvgWrapper
                      onClick={(e) => {
                        onOpenMenu(e, user);
                      }}>
                      <SVGIcon fill='var(--gray-color)' size={24} name='icon-more' title='More' />
                    </SvgWrapper>
                  )}
                </IconView>
              </ListBox>
            );
          })}
        </ContainBoxTwo>
        <Menu
          id='simple-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={onCloseMenu}
          getContentAnchorEl={null}
          position='left'
          positionVertical='top'
          marginTop={23}
          MenuListProps={{ disablePadding: true }}>
          <MenuItemWrapper>
            {users?.findIndex((x) => x?.id === selectedUser?.id) !== -1 ? (
              <>
                {isAdmin && (
                  <MenuItem
                    iconName='icon-lead'
                    color='var(--gray-icon-color)'
                    fontSize='18px'
                    text={selectedUser?.isAdmin ? 'Remove from admin' : 'Make an admin'}
                    onClick={onClickMakeAdmin}
                  />
                )}
                <MenuItem
                  iconName='icon-edit-pen'
                  color='var(--gray-icon-color)'
                  fontSize='18px'
                  text='Permission'
                  onClick={(e) => {
                    onOpenEditMenu(e);
                  }}
                />
                <MenuItem
                  iconName='icon-delete'
                  color='var(--gray-icon-color)'
                  fontSize='18px'
                  text='Delete'
                  onClick={() => {
                    ConfirmDialog({
                      iconName: 'icon-delete',
                      closeIcon: 'icon-close',
                      title: 'Delete',
                      message: 'Press ok to confirm delete this user',
                      onOk: () => {
                        onDeleteUser();
                      }
                    });
                  }}
                />
              </>
            ) : (
              <MenuItem
                iconName='icon-delete'
                color='var(--gray-icon-color)'
                fontSize='18px'
                text='Delete'
                onClick={() => {
                  ConfirmDialog({
                    iconName: 'icon-delete',
                    closeIcon: 'icon-close',
                    title: 'Delete',
                    message: 'Press ok to confirm delete this user',
                    onOk: () => {
                      onDeleteUser();
                    }
                  });
                }}
              />
            )}
          </MenuItemWrapper>
        </Menu>
        <Modal open={isEditMenuOpen} onClose={onCloseEditMenu}>
          <ModifyUserModal user={selectedUser} handleCloseModal={onCloseEditMenu} />
        </Modal>
        <SnackBar />
      </MainList>
    </Wrapper>
  );
};

export default Users;
