import moment from 'moment';
import { REACT_APP_ENV } from './Environment';

/**
 * @desc Check if given value is string
 * @param {*} value // Accepts string
 */
export function isStirng(value) {
  var myRegEx = /^[a-zA-Z\s]*$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks if given value is Number
 * @param {*} value // Accepts string
 */
export function isNumber(value) {
  var myRegEx = /^(\s*[0-9]+\s*)+$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

export function isDateString(value) {
  var myRegEx = /^\d{2}\/\w{3}\/\d{4}$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for valid email
 * @param {*} value // Accepts string
 */
export function isEmail(value) {
  var myRegEx =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for Empty string
 * @param {*} value // Accepts string, object
 */
export function isEmpty(value) {
  if (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * @desc: Check valid date
 */
export function isValidDate(d) {
  return d instanceof Date;
}

/**
 * @desc check does it dev mode or live mode
 * it return false only if its a production build
 */
export const isDev = () => {
  if (!REACT_APP_ENV || REACT_APP_ENV === 'development') {
    return true;
  }
  return false;
};

/**
 * @desc get query params
 */
export const getUrlParams = (queryParams) => {
  if (!queryParams) return new URLSearchParams();
  return new URLSearchParams(queryParams);
};

/**
 * @desc get query param by name
 */
export const getUrlParam = (query, name) => {
  let queryParams = new URLSearchParams();
  if (query) queryParams = new URLSearchParams(query);
  return queryParams.get(name);
};

export const stringToBoolean = (value) => {
  if (!value) return false;

  switch (value.toString().toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
};

export const getCount = (list) => {
  if (list) {
    let filteredlist = list.filter((x) => x.type === 1 || x.type === 2);
    if (filteredlist) return filteredlist.length;
  }
  return 0;
};

export const getGreetingsText = () => {
  let date = new Date();
  let hours = date.getHours();
  // let minutes = date.getMinutes();
  if (hours < 12) return 'Good Morning,';
  else if (hours === 12) return 'Good Noon,';
  else if (hours > 12 && hours < 18) return 'Good Afternoon,';
  else if (hours >= 18) return 'Good Evening,';
  return 'Hello';
};

export const displayRelativeDate = (date, date_format) => {
  if (!date) {
    return '';
  }
  let diff = moment().diff(date, 'days');
  if (diff === 0) {
    return 'Today';
  } else if (diff === 1) {
    return 'Yesterday';
  } else if (diff === -1) {
    return 'Tomorrow';
  } else {
    return date_format ? moment(date).format(date_format) : moment(date).fromNow();
  }
};

export const getNextDayOfWeek = (date, dayOfWeek) => {
  var resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));
  //if (isSameDate(resultDate, date))
  //	return this.getNextDayOfWeek(addDays(date, 1), 1)
  return resultDate;
};

const calculateDelta = (now, date) => Math.round((now - date) / 1000);

export const transformDate = (dateStamp) => {
  if (dateStamp === undefined || dateStamp == null || dateStamp === '') return '';

  let date = new Date(dateStamp);
  let now = new Date();

  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  now = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0));

  let delta = null;

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  delta = calculateDelta(now, date);

  let postFix = ' ago';
  let preFix = '';
  let isFutureTime = false;
  if (delta < 0) {
    postFix = '';
    preFix = 'in ';
    isFutureTime = true;
  }

  delta = Math.abs(delta);
  switch (true) {
    case Math.floor(delta / day) === 0:
      return 'Today';

    case Math.floor(delta / day) === 1:
      return isFutureTime ? 'Tommorow' : 'Yesterday';

    case delta < week:
      return preFix + Math.floor(delta / day) + ' days ' + postFix;

    case Math.floor(delta / week) === 1:
      return preFix + 'Week ' + postFix;

    case delta < month:
      return preFix + Math.floor(delta / week) + ' weeks ' + postFix;

    case Math.floor(delta / month) === 1:
      return preFix + 'Month ' + postFix;

    case delta < year:
      return preFix + Math.floor(delta / month) + ' months ' + postFix;

    case Math.floor(delta / year) === 1:
      return preFix + 'Year ' + postFix;

    default:
      return preFix + 'More than year ' + postFix;
  }
};

export const getGraphQlErrorMessage = (error) => {
  if (error?.networkError?.result?.errors?.length > 0) {
    return error?.networkError?.result?.errors[0]?.message;
  }
  return;
};

export const getRelativeHHMMSS = (value) => {
  if (value === 0) return '0s';
  if (value) {
    value = parseInt(value);
    var h = Math.floor(value / 3600);
    var m = Math.floor((value % 3600) / 60);
    var s = Math.floor((value % 3600) % 60);
    if (h <= 0 && m <= 0) return `${s.toString().padStart(2, '0')}s`;
    if (h > 0)
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  }
};

export const getRelativeHHMinSS = (value) => {
  if (value === 0) return '0s';
  if (value) {
    value = parseInt(value);
    var h = Math.floor(value / 3600);
    var m = Math.floor((value % 3600) / 60);
    var s = Math.floor((value % 3600) % 60);
    if (h <= 0 && m <= 0) return `${s.toString().padStart(2, '0')}s`;
    if (h > 0) return `${h.toString().padStart(2, '0')} h ${m.toString().padStart(2, '0')} min`;
    return `${m.toString().padStart(2, '0')} min`;
  }
};

export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const createCookie = (name, value, days = 1, path = '/') => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + ';path=' + path;
};

export const isElectronApp = () => {
  if (navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes('electron');
  }
  return false;
};

export const isMAC = () => {
  if (navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes('mac') || navigator.userAgent.toLowerCase().includes('macintosh');
  }
  return false;
};

export const isWindows = () => {
  if (navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes('windows');
  }
  return false;
};

export const isMACApp = () => {
  if (navigator.userAgent) {
    if (navigator.userAgent.toLowerCase().includes('electron')) {
      return (
        navigator.userAgent.toLowerCase().includes('mac') || navigator.userAgent.toLowerCase().includes('macintosh')
      );
    }
  }
  return false;
};

export const isWindowsApp = () => {
  if (navigator.userAgent) {
    if (navigator.userAgent.toLowerCase().includes('electron')) {
      return navigator.userAgent.toLowerCase().includes('windows');
    }
  }
  return false;
};

export const getFormattedPhoneNumber = (input) => {
  if (!input) return;
  if (typeof input !== 'string') input = input.toString();
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  } else if (input.length === 9) {
    return input.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
  } else if (input.length === 8) {
    return input.replace(/(\d{2})(\d{3})(\d{3})/, '$1-$2-$3');
  } else {
    return input;
  }
};
