import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Salescamplogo } from '../../assets/images/svg/salescamp-logo.svg';
import Bodygradient from '../../assets/images/body-gradient-bg.jpg';
import Bodygradientmobile from '../../assets/images/body-gradient-bg-mobile.jpg';

const MainWrapper = styled.div`
  background-image: url(${Bodygradient});
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  z-index: 9999;
  @media screen and (max-width: 767px) {
    background-image: url(${Bodygradientmobile});
  }
`;
const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
`;
const Spinner = styled.div`
  position: relative;
  width: 120px;
  height: 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.38);
  margin: 38px 45px 0px;
`;
const SpinnerProgress = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0.37) 80%, rgba(255, 255, 255, 0) 100%);
  animation-name: animate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &::before,
  &::after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.75;
    filter: blur(3px);
    border-radius: 4px;
    background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0.37) 80%, rgba(255, 255, 255, 0) 100%);
  }
`;

const AppLoading = () => {
  return (
    <MainWrapper>
      <Wrapper>
        <Salescamplogo />
        <Spinner>
          <SpinnerProgress></SpinnerProgress>
        </Spinner>
      </Wrapper>
    </MainWrapper>
  );
};

export default AppLoading;
