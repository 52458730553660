import styled from 'styled-components';
import { Header4 } from '../../../styles';

const MainForm = styled.div``;
const ModalHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ModalHeaderTitel = styled.span`
  color: var(--dark-color);
  ${Header4}
  margin-left: 12px;
`;
const RightWrapper = styled.div`
  svg {
    display: block;
  }
  cursor: pointer;
`;
const FormWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 99px);
`;
const FormMainInner = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding-top: 16px;
`;
const Footer = styled.div`
  margin-top: auto;
  padding: 16px;
  text-align: right;
  border-top: 1px solid var(--gray-border-color);
`;
const BtnWrapper = styled.div`
  display: inline-block;
`;

export {
  MainForm,
  ModalHeader,
  LeftWrapper,
  ModalHeaderTitel,
  RightWrapper,
  FormWrapper,
  FormMainInner,
  Footer,
  BtnWrapper
};
