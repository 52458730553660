import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/Input/Input';
import { Menu, MenuItem } from '../../../components/Menu';
import { addEmailFiles, clearEmailItem, deleteEmailFiles, updateEmailItem } from '../../../actions/emailActions';
import { getAllLookup } from '../../../services/lookupService';
import { createEmail, getEmailAccounts, getEmailTemplates } from '../../../services/emailService';
import { getUniqueId, hasProduction, isEmpty } from '../../../helpers/common';
import { getSettingSignature, updateEmailAccountSignature } from '../../../services/settingService';
import AttachmentList from '../AttachmentList';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import SendSchedualModal from '../SendSchedualModal/SendSchedualModal';
import { LookupFieldTypes, LOOKUP_FIELD_CUSTOM_ID, SEND_EMAIL_TYPE } from '../../../constants/constant';
import DropDown from '../../../components/DropDown/DropDown';
import useMobileDevice from '../../../hooks/useMobileDevice';
import EmailDropDown from '../../../components/EmailDropDown/EmailDropDown';
import SVGIcon from '../../../assets/svg/SVGIcon';
import OrganizationPreferencesSingleton from '../../../helpers/OrganizationPreferencesSingleton';
import {
  MainContact,
  TitleView,
  TitleText,
  IconView,
  From,
  SecondView,
  BottomView,
  BtnView,
  Ul,
  Li,
  FirstView,
  ToText,
  SelectView,
  InputView,
  LastView,
  TextEnd,
  RegardText,
  EditeView,
  ALIcon,
  AIcon,
  FromView,
  FromDropView,
  Border,
  LastEditView,
  SignatureText,
  ButtonCancel,
  ButtonWrapper,
  ButtonSave,
  LastEditBox,
  BtnBorder,
  FlieBlog,
  MenuItemWrapper,
  AddBcc,
  Cc,
  Bcc,
  ButtonSend,
  ButtonWrap,
  ButtonSchedual,
  InputWrapper,
  colourStyles,
  TextEditer,
  FromText,
  IconButtonSchedual,
  BodyWrapper,
  FormMainWrap
} from './styles';
const SendEmailModal = ({ lookupIds, type, handleCloseModal, onSend }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isSendSchedualOpen, setIsSendSchedualOpen] = useState(false);
  const [isSchedualOpen, setIsSchedualOpen] = useState(false);
  const [isTimeListOpen, setIsTimeListOpen] = useState(false);
  const [isEditSignature, setIsEditSignature] = useState(false);
  const [isRemoveSignature, setIsRemoveSignature] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [isShowCc, setIsShowCc] = useState(false);
  const [isShowBcc, setIsShowBcc] = useState(false);
  const [isShowQuill, setIsShowQuill] = useState(false);
  const [emailSignature, setEmailSignature] = useState();
  const emailSelector = useSelector((state) => state.email);
  const lookupSelector = useSelector((state) => state.lookup);
  const authSelector = useSelector((state) => state.auth);
  const organizationSelector = useSelector((state) => state.organization);
  const lookupTableSelector = useSelector((state) => state.lookupTables);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;
  const { tableFields } = lookupTableSelector;
  const { emailAccounts, emailItem, emailTemplates, loading } = emailSelector;
  const { currentUser } = authSelector;
  const { lookups } = lookupSelector;
  const dispatch = useDispatch();
  let history = useHistory();
  const mobile = useMobileDevice();

  const userAccess = useMemo(() => {
    const emailAccessSettings = organization?.settings?.access?.email;
    return {
      permissionSend: emailAccessSettings?.permissionSend
    };
  }, [organization]);

  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
      [{ list: 'ordered' }],
      [{ list: 'bullet' }],
      ['clean']
    ]
  };

  const emailAccountList = useMemo(() => {
    const accounts = emailAccounts?.map((item) => {
      return { ...item, label: `${item?.name} < ${item?.email} >` };
    });
    return accounts;
  }, [emailAccounts]);

  useEffect(() => {
    dispatch(getAllLookup());
    dispatch(getEmailTemplates(organization_id));
    dispatch(getSettingSignature(organization_id));
    return () => {
      dispatch(clearEmailItem());
    };
  }, [dispatch, organization_id]);

  useEffect(() => {
    if (type === SEND_EMAIL_TYPE.COMPOSE) {
      const primaryEmail = emailAccounts?.find((x) => x?.isPrimary);
      let defaultEmailAccountId = OrganizationPreferencesSingleton.getInstance().getDefaultEmailAccountId();
      let lastUsedEmailAccount;
      //Check is account exist in list, if yes then use last used account or use primary account
      if (defaultEmailAccountId) lastUsedEmailAccount = emailAccounts?.find((x) => x?.id === defaultEmailAccountId);

      let defaultEmail = !isEmpty(lastUsedEmailAccount) ? lastUsedEmailAccount : primaryEmail;
      if (defaultEmail) {
        const account = { ...defaultEmail, label: `${defaultEmail?.name} < ${defaultEmail?.email} >` };
        if (defaultEmail?.signature) {
          setEmailSignature(defaultEmail?.signature);
          dispatch(updateEmailItem({ propsName: 'signature', value: defaultEmail?.signature }));
        }
        dispatch(updateEmailItem({ propsName: 'emailAccount', value: account }));
      }
    }
  }, [dispatch, emailAccounts, type]);

  let contactList = useMemo(() => {
    const fields = [].concat.apply(
      [],
      tableFields?.map((x) =>
        x.columns?.map((y) => {
          return {
            ...y,
            tableId: x.tableId
          };
        })
      )
    );

    const contacts = lookups
      ?.map((lookupItem) => {
        const lookup = Object.assign({}, lookupItem);
        const emailFields = fields?.filter(
          (x) =>
            x.tableId === lookup.tableId &&
            x.type === LookupFieldTypes.Email &&
            x.customId !== LOOKUP_FIELD_CUSTOM_ID.EMAIL
        );
        if (emailFields?.length > 0) {
          lookup.emails = [];
          emailFields.forEach((field) => {
            const email = lookup.fields[field?.id]?.value;
            if (email) lookup.emails.push(email);
          });
        }
        return lookup;
      })
      ?.filter((x) => x.email || x.emails?.length > 0);

    const contactsWithMultipleEmails = contacts?.filter((x) => x.emails?.length > 0);
    contactsWithMultipleEmails?.forEach((lookupItem) => {
      lookupItem.emails?.forEach((emailAddress) => {
        const id = getUniqueId();
        const lookup = JSON.parse(JSON.stringify(lookupItem));
        lookup.lookupId = lookup.id;
        lookup.id = id;
        lookup.email = emailAddress;
        lookup.cloneLookup = true;
        contacts.push(lookup);
      });
    });

    let ids = lookupIds || [];
    if (!isEmpty(emailItem?.lookupIds)) ids = ids?.concat(emailItem?.lookupIds);
    if (!isEmpty(ids)) {
      const selectedContact = ids
        ?.map((newItem) => {
          let item;
          let contact = contacts?.find(
            (x) =>
              (x?.id === newItem?.id || x?.lookupId === newItem?.id) && (!newItem?.email || x.email === newItem?.email)
          );
          if (contact) {
            item = contact;
          } else {
            const id = getUniqueId();
            item = {
              email: newItem?.email,
              name: newItem?.email,
              id,
              isCustomEmail: true
            };
            contacts?.unshift(item);
          }
          return item;
        })
        .filter((x) => x && x.email);
      dispatch(updateEmailItem({ propsName: 'to', value: selectedContact }));
    }
    return contacts;
  }, [tableFields, lookups, lookupIds, emailItem?.lookupIds, dispatch]);

  const onClose = useCallback(() => {
    if (handleCloseModal) handleCloseModal();
  }, [handleCloseModal]);

  const openTemplateMenu = useCallback((e) => {
    e.stopPropagation();
    setIsTemplateOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeTemplateMenu = useCallback(() => {
    setIsTemplateOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const openSchedualMenu = useCallback((e) => {
    e.stopPropagation();
    setIsSchedualOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeSchedualMenu = useCallback(() => {
    setIsSchedualOpen(false);
    setMenuAnchorEl(null);
  }, []);
  const openSendSchedualMenu = useCallback((e) => {
    e.stopPropagation();
    setIsSendSchedualOpen(true);
  }, []);

  const closeSendSchedualMenu = useCallback(() => {
    setIsSendSchedualOpen(false);
  }, []);

  const openTimeListMenu = useCallback((e) => {
    e.stopPropagation();
    setIsTimeListOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const closeTimeListMenu = useCallback(() => {
    setIsTimeListOpen(false);
    setMenuAnchorEl(null);
  }, []);

  const updateEmailItemInput = useCallback(
    (propsName, value) => {
      dispatch(updateEmailItem({ propsName, value }));
    },
    [dispatch]
  );

  const onChangeEmailAccount = useCallback(
    (item) => {
      let signature;
      setIsRemoveSignature(false);
      if (item?.signature) {
        signature = item?.signature;
      } else {
        signature = undefined;
        setIsRemoveSignature(true);
      }
      setEmailSignature(signature);
      setIsEditSignature(false);
      dispatch(updateEmailItem({ propsName: 'signature', value: signature }));
      dispatch(updateEmailItem({ propsName: 'emailAccount', value: item }));
    },
    [dispatch]
  );

  const hasEmailSignature = useMemo(() => {
    if (!isEmpty(emailItem?.signature) && !isEditSignature && !isRemoveSignature) return true;
  }, [emailItem?.signature, isEditSignature, isRemoveSignature]);

  const hasEmailAccountOwner = useMemo(() => {
    if (emailItem?.emailAccount?.createdBy === currentUser?.id) return true;
  }, [currentUser?.id, emailItem?.emailAccount?.createdBy]);

  const trackSendMailAnalyticActivity = useCallback(
    (item) => {
      try {
        trackAnalyticActivity('email: email sent', {
          to_count: item?.to?.length,
          subject: item?.subject,
          template: templateName ? String(templateName).toLowerCase() : undefined
        });
      } catch (e) {
        console.log('track Send Mail Error', e);
      }
    },
    [templateName]
  );

  const setCustomEmailArray = useCallback((data) => {
    return (
      data &&
      data?.map((item) => {
        if (item?.isCustomEmail) return { ...item, id: null };
        else if (item?.cloneLookup) return { ...item, id: item?.lookupId };
        return item;
      })
    );
  }, []);

  const onClickSendMail = useCallback(
    async (e, individualEmail = false) => {
      e.preventDefault();
      const to = setCustomEmailArray(emailItem?.to);
      const cc = setCustomEmailArray(emailItem?.cc);
      const bcc = setCustomEmailArray(emailItem?.bcc);
      const payload = {
        ...emailItem,
        signature: !isRemoveSignature ? emailItem?.signature : undefined,
        individualEmail,
        to,
        cc,
        bcc
      };
      const result = await dispatch(createEmail(organization_id, payload));
      if (result) {
        trackSendMailAnalyticActivity(payload);
        onClose();
        if (onSend) onSend();
      }
    },
    [
      setCustomEmailArray,
      emailItem,
      isRemoveSignature,
      dispatch,
      organization_id,
      trackSendMailAnalyticActivity,
      onClose,
      onSend
    ]
  );

  const trackTamplateAnalyticActivity = useCallback(
    (template_title) => {
      try {
        trackAnalyticActivity('new email: template selected', {
          total_template: emailTemplates ? emailTemplates?.length : 0,
          template_title: String(template_title).toLowerCase()
        });
      } catch (e) {
        console.log('track new email: template selected Error', e);
      }
    },
    [emailTemplates]
  );

  const onClickEmailTemplate = useCallback(
    (item) => {
      setTemplateName(item?.name);
      dispatch(updateEmailItem({ propsName: 'subject', value: item?.subject }));
      dispatch(updateEmailItem({ propsName: 'body', value: item?.body }));
      trackTamplateAnalyticActivity(item?.name);
      closeTemplateMenu();
    },
    [dispatch, trackTamplateAnalyticActivity, closeTemplateMenu]
  );

  const onSaveSignature = useCallback(async () => {
    dispatch(updateEmailItem({ propsName: 'signature', value: emailSignature }));
    setIsEditSignature(false);
    const payload = {
      Signature: emailSignature
    };
    const result = await dispatch(updateEmailAccountSignature(organization_id, emailItem?.emailAccount?.id, payload));
    if (result) {
      dispatch(getEmailAccounts(organization_id));
    }
    try {
      trackAnalyticActivity('new email: signature changed', undefined);
    } catch (e) {
      console.log('track signature changed Error', e);
    }
  }, [dispatch, emailItem?.emailAccount?.id, emailSignature, organization_id]);

  const onDeleteSignature = useCallback(() => {
    setIsRemoveSignature(true);
    try {
      trackAnalyticActivity('new email: signature deleted', undefined);
    } catch (e) {
      console.log('track signature deleted Error', e);
    }
  }, []);

  const readFile = useCallback(
    (file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let item = {
          id: getUniqueId(),
          name: file.name,
          contentType: file.type,
          content: reader.result
        };
        dispatch(addEmailFiles({ item }));
      };
    },
    [dispatch]
  );

  const onFileChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files.length > 0) {
        for (var i = 0; i < event.target.files.length; i++) {
          let file = event.target.files[i];
          readFile(file);
        }
      }
    },
    [readFile]
  );

  const onAttachmentRemove = useCallback(
    (index) => {
      dispatch(deleteEmailFiles({ index }));
    },
    [dispatch]
  );

  const showHideQuill = useCallback(() => {
    if (!isShowQuill) {
      var olduill = document.getElementById('mainquill').firstElementChild;
      var newquill = document.getElementById('editquill');
      setIsShowQuill(true);
      newquill.append(olduill);
    } else {
      setIsShowQuill(false);
      var olquill = document.getElementById('mainquill');
      var neweditquill = document.getElementById('editquill').firstElementChild;
      olquill.prepend(neweditquill);
    }
  }, [isShowQuill]);

  const onChangeDropDownInput = useCallback(
    (values) => {
      values?.forEach((item) => {
        const index = contactList?.findIndex((x) => x?.id === item?.id);
        if (index === -1) {
          contactList?.unshift(item);
        }
      });
    },
    [contactList]
  );

  return (
    <>
      <MainContact mobile={mobile}>
        <TitleView>
          <TitleText>New Email</TitleText>
          <IconView title='Close' onClick={onClose}>
            <SVGIcon name='icon-close' size={18} fill='var(--gray-icon-color)' strokeWidth={2} />
          </IconView>
        </TitleView>
        <From mobile={mobile}>
          <FormMainWrap mobile={mobile}>
            <SecondView mobile={mobile}>
              <FromView>
                <FromDropView>
                  <FromText>From:</FromText>
                  <InputWrapper>
                    <DropDown
                      options={emailAccountList}
                      styles={colourStyles}
                      labelField={'label'}
                      valueField={'id'}
                      hasObject={true}
                      value={emailItem?.emailAccount}
                      onChange={(item) => {
                        onChangeEmailAccount(item);
                      }}
                    />
                  </InputWrapper>
                </FromDropView>
              </FromView>
              <FirstView>
                <SelectView>
                  <ToText>To:</ToText>
                  <InputWrapper>
                    <EmailDropDown
                      options={contactList}
                      labelField={'name'}
                      valueField={'id'}
                      isMulti={true}
                      removeIcon={'icon-close'}
                      removeIconSize={14}
                      placeholder=''
                      value={emailItem?.to}
                      onChange={(values) => {
                        onChangeDropDownInput(values);
                        updateEmailItemInput('to', values);
                      }}
                    />
                  </InputWrapper>
                  {!isShowCc && !isShowBcc && (
                    <AddBcc>
                      <Cc
                        onClick={() => {
                          setIsShowCc(true);
                        }}>
                        Cc
                      </Cc>
                      <Bcc
                        onClick={() => {
                          setIsShowBcc(true);
                        }}>
                        Bcc
                      </Bcc>
                    </AddBcc>
                  )}
                </SelectView>
              </FirstView>
              {isShowCc && (
                <FirstView>
                  <SelectView>
                    <ToText>Cc:</ToText>
                    <InputWrapper>
                      <EmailDropDown
                        options={contactList}
                        labelField={'name'}
                        valueField={'id'}
                        removeIcon={'icon-close'}
                        removeIconSize={14}
                        isMulti={true}
                        placeholder=''
                        value={emailItem?.cc}
                        onChange={(values) => {
                          onChangeDropDownInput(values);
                          updateEmailItemInput('cc', values);
                        }}
                      />
                    </InputWrapper>
                    {!isShowBcc && (
                      <AddBcc>
                        <Bcc
                          onClick={() => {
                            setIsShowBcc(true);
                          }}>
                          Bcc
                        </Bcc>
                      </AddBcc>
                    )}
                  </SelectView>
                </FirstView>
              )}
              {isShowBcc && (
                <FirstView>
                  <SelectView>
                    <ToText>Bcc:</ToText>
                    <InputWrapper>
                      <EmailDropDown
                        options={contactList}
                        labelField={'name'}
                        valueField={'id'}
                        isMulti={true}
                        removeIcon={'icon-close'}
                        removeIconSize={14}
                        placeholder=''
                        value={emailItem?.bcc}
                        onChange={(values) => {
                          onChangeDropDownInput(values);
                          updateEmailItemInput('bcc', values);
                        }}
                      />
                    </InputWrapper>
                    {!isShowCc && (
                      <AddBcc>
                        <Cc
                          onClick={() => {
                            setIsShowCc(true);
                          }}>
                          Cc
                        </Cc>
                      </AddBcc>
                    )}
                  </SelectView>
                </FirstView>
              )}
            </SecondView>
            <InputView>
              <Input
                isPrimarySmall
                type='text'
                placeholder='Subject:'
                value={emailItem?.subject}
                onChange={(e) => {
                  updateEmailItemInput('subject', e.target.value);
                }}
              />
            </InputView>
            {/* Body Content */}
            <BodyWrapper mobile={mobile}>
              <TextEditer hidequill>
                <ReactQuill
                  theme='snow'
                  id='mainquill'
                  placeholder={'Type your message here...'}
                  style={{ border: 'none' }}
                  modules={modules}
                  value={emailItem?.body || ''}
                  onChange={(value) => {
                    updateEmailItemInput('body', value);
                  }}
                />
              </TextEditer>
              {hasEmailSignature && (
                <LastView>
                  <TextEnd>
                    <RegardText dangerouslySetInnerHTML={{ __html: emailItem?.signature }}></RegardText>
                  </TextEnd>
                  {hasEmailAccountOwner && (
                    <EditeView className='actionbutton'>
                      <ALIcon
                        title='Edit'
                        onClick={() => {
                          setIsEditSignature(true);
                        }}>
                        <SVGIcon name='icon-edit' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
                      </ALIcon>
                      <AIcon onClick={onDeleteSignature} title='Delete'>
                        <SVGIcon name='icon-delete' size={16} fill='var(--gray-icon-color)' strokeWidth={2} />
                      </AIcon>
                    </EditeView>
                  )}
                </LastView>
              )}
              {isEditSignature ? (
                <LastEditView mobile={mobile}>
                  <LastEditBox>
                    <ReactQuill
                      theme='snow'
                      placeholder={'Insert text here ...'}
                      style={{ border: 'none' }}
                      modules={modules}
                      value={emailSignature || ''}
                      onChange={(value) => {
                        setEmailSignature(value);
                      }}
                    />
                    <ButtonWrapper>
                      <ButtonSave
                        onClick={() => {
                          onSaveSignature();
                        }}>
                        Save
                      </ButtonSave>
                      <BtnBorder></BtnBorder>
                      <ButtonCancel
                        onClick={() => {
                          setEmailSignature(emailItem?.signature);
                          setIsEditSignature(false);
                        }}>
                        cancel
                      </ButtonCancel>
                    </ButtonWrapper>
                  </LastEditBox>
                </LastEditView>
              ) : (
                (isRemoveSignature || isEmpty(emailItem?.signature)) && (
                  <LastEditView>
                    <SignatureText
                      onClick={() => {
                        setIsRemoveSignature(false);
                        setIsEditSignature(true);
                      }}>
                      <SVGIcon name='icon-pluse' size={12} strokeWidth={3} fill='var(--primary-color)' />
                      <span>Add signature</span>
                    </SignatureText>
                  </LastEditView>
                )
              )}
            </BodyWrapper>
            <FlieBlog>
              <AttachmentList attachments={emailItem?.files} onAttachmentRemove={onAttachmentRemove} />
            </FlieBlog>
          </FormMainWrap>
          <TextEditer mobile={mobile} id='editquill' className='quill' isShowQuill></TextEditer>
          <BottomView mobile={mobile}>
            <BtnView>
              <Ul>
                <Li
                  active={isShowQuill}
                  className={hasProduction() ? 'inDevelopment' : ''}
                  title='Format'
                  onClick={() => {
                    showHideQuill();
                  }}>
                  <SVGIcon name='icon-t-fomat' size={22} fill='var(--gray-icon-color)' />
                </Li>
                <Li
                  active={isTemplateOpen}
                  title='Template'
                  onClick={(e) => {
                    openTemplateMenu(e);
                  }}>
                  <SVGIcon name='icon-document' size={22} fill='var(--gray-icon-color)' />
                </Li>
                <Li
                  active={isTimeListOpen}
                  title='Schedual'
                  className={hasProduction() ? 'inDevelopment' : ''}
                  onClick={(e) => {
                    openTimeListMenu(e);
                  }}>
                  <SVGIcon name='icon-timer' size={22} fill='var(--gray-icon-color)' />
                </Li>
                <Li className={hasProduction() ? 'inDevelopment' : ''} title='Show'>
                  <SVGIcon name='icon-show' size={22} fill='var(--gray-icon-color)' />
                </Li>
                <Li title='File'>
                  <Input id='file' type='file' isPrimary={true} onChange={onFileChange} />
                  <SVGIcon name='icon-attachment' size={22} fill='var(--gray-icon-color)' />
                </Li>
              </Ul>
              <ButtonWrap>
                <ButtonSend
                  type='button'
                  onClick={onClickSendMail}
                  title='Send'
                  loading={loading}
                  disabled={!userAccess?.permissionSend}
                  hasProduction={hasProduction()}
                />
                {!hasProduction() && (
                  <IconButtonSchedual title='Schedual'>
                    <ButtonSchedual
                      type='button'
                      iconColor='var(--white-color)'
                      iconName='icon-down-arrow'
                      onClick={(e) => {
                        openSchedualMenu(e);
                      }}
                    />
                  </IconButtonSchedual>
                )}
              </ButtonWrap>
            </BtnView>
          </BottomView>
        </From>
      </MainContact>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isTemplateOpen}
        onClose={closeTemplateMenu}
        getContentAnchorEl={null}
        position='center'
        positionVertical='bottom'
        marginTop={-50}
        style={{ marginLeft: 25 }}
        MenuListProps={{ disablePadding: true }}
        width='154px'>
        <MenuItemWrapper>
          {emailTemplates?.map((item, index) => {
            return (
              <MenuItem
                key={`emailTemplates_${item?.id}_${index}`}
                text={item?.name}
                onClick={() => {
                  onClickEmailTemplate(item);
                }}
              />
            );
          })}
          <Border></Border>
          <MenuItem
            text='Manage Template'
            onClick={() => {
              onClose();
              history.push(`/settings/emailtemplate`);
            }}
          />
        </MenuItemWrapper>
      </Menu>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isTimeListOpen}
        onClose={closeTimeListMenu}
        getContentAnchorEl={null}
        position='center'
        positionVertical='bottom'
        marginTop={-50}
        MenuListProps={{ disablePadding: true }}
        width='187px'>
        <MenuItemWrapper>
          <MenuItem text='In 1 hour' />
          <MenuItem text='In 2 hours' />
          <MenuItem text='Tomorrow Morning' />
          <MenuItem text='Tomorrow Evening' />
          <MenuItem text='This Weekend' />
          <MenuItem text='Next Weekend' />
          <MenuItem text='Write Date/Time' />
        </MenuItemWrapper>
      </Menu>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isSchedualOpen}
        onClose={closeSchedualMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='bottom'
        marginTop={-50}
        MenuListProps={{ disablePadding: true }}
        width='187px'>
        <MenuItemWrapper>
          <MenuItem
            iconName='icon-repeat'
            fontSize='18'
            color='var(--gray-icon-color)'
            text='Send Schedule'
            onClick={(e) => {
              openSendSchedualMenu(e);
            }}
          />
          {type === SEND_EMAIL_TYPE.COMPOSE &&
            emailItem?.to?.length > 0 &&
            emailItem?.bcc?.length === 0 &&
            emailItem?.cc?.length === 0 && (
              <MenuItem
                iconName='icon-share'
                fontSize='18'
                color='var(--gray-icon-color)'
                text='Send Individual'
                onClick={(e) => {
                  closeSchedualMenu();
                  onClickSendMail(e, true);
                }}
              />
            )}
        </MenuItemWrapper>
      </Menu>
      <Menu
        id='simple-menu'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isSendSchedualOpen}
        onClose={closeSendSchedualMenu}
        getContentAnchorEl={null}
        position='right'
        positionVertical='bottom'
        marginTop={-50}
        MenuListProps={{ disablePadding: true }}
        width='214px'>
        <MenuItemWrapper>
          <SendSchedualModal onClose={closeSendSchedualMenu} />
        </MenuItemWrapper>
      </Menu>
    </>
  );
};
export default SendEmailModal;
