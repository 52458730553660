import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTE } from '../../../constants/routeConst';
import SnackBar from '../../../components/SnackBar/SnackBar';
import { login, validateIsLoggedIn } from '../../../services/authServices';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import Message from '../../../components/Message/Message';
import AppLogo from '../../../components/AppLogo';
import UserPreferenceSingleton from '../../../helpers/UserPreferenceSingleton';
import { setAuthValidationError } from '../../../actions/authActions';
import { APP_HOST_TYPE, APP_INIT_RESPONSE_TYPE } from '../../../constants/constant';
import LoginLinearImg from '../../../assets/images/features.png';
import { getAppHostType } from '../../../helpers/common';
import useMobileDevice from '../../../hooks/useMobileDevice';
import { trackPage } from '../../../services/analyticsService';
import { getCookie, isMACApp, isWindowsApp } from '../../../global/Helper';
import ElectronTitlebar from '../../../components/ElectronTitlebar/ElectronTitlebar';
import {
  ManeWrapper,
  LoginContainer,
  Title,
  LogoWrapper,
  Form,
  ButtonWrapper,
  ForgotLink,
  LoginLeft,
  LoginRight,
  LoginWrap,
  LoginImage,
  Lable,
  InputBlock,
  LabelInput,
  InputWrapperModal,
  TextPopup,
  LoginBtnBlock,
  BottomText,
  CreateSpan,
  MacDragDro
} from './Styles';

const Login = ({ ...props }) => {
  const mobile = useMobileDevice();
  const auth = useSelector((state) => state.auth);
  const { loading, validationError } = auth;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRoadMap, setIsRoadMap] = useState(false);

  const dispatch = useDispatch();
  const locationSearch = useLocation().search;
  const isNoltSso = new URLSearchParams(locationSearch).get('nolt-sso');
  const isFromLogoutPage = new URLSearchParams(locationSearch).get('logout');

  useEffect(() => {
    if (!isFromLogoutPage && validateIsLoggedIn()) {
      props.history.push(ROUTE.MAIN);
    }
  }, [props.history, isFromLogoutPage]);

  useEffect(() => {
    if (isNoltSso) {
      setIsRoadMap(true);
    }
  }, [isNoltSso]);

  useEffect(() => {
    trackPage({ visit_url: 'login' });
    // componentDidMount events
    return () => {
      // componentWillUnmount events
      dispatch(setAuthValidationError(''));
    };
  }, [dispatch]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const loginObj = {
        username: email,
        password: password
      };

      const result = await dispatch(login(loginObj));

      //Return to nolt url after login if nolt-sso present
      const isNoltSSO = new URLSearchParams(locationSearch).get('nolt-sso');
      if (isNoltSSO) {
        const noltToken = getCookie('sc-nolt-token');
        if (noltToken) {
          // eslint-disable-next-line max-len
          window.location.href = `https://feedback.salescamp.app/sso/${noltToken}?returnUrl=https%3A%2F%2Ffeedback.salescamp.app%2Froadmap`;
          return;
        }
      }

      if (result && result.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
        props.history.push(result.path);
      } else {
        let organization = UserPreferenceSingleton.getInstance().getOrganization();
        if (organization?.id) {
          const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
          if (userDetails && userDetails.verificationRequired) {
            props.history.push(ROUTE.VERIFICATION_REQUIRED);
            return;
          }

          const returnUrl = new URLSearchParams(locationSearch).get('return-url');
          if (returnUrl) props.history.push(decodeURIComponent(returnUrl));
          else props.history.push(ROUTE.INBOX);
        }
      }
    },
    [dispatch, email, password, props.history, locationSearch]
  );

  const appHostType = useMemo(() => {
    return getAppHostType();
  }, []);

  const onClickForgotPassword = () => {
    props.history.push(ROUTE.FORGOT_PASSWORD);
  };

  const onSignUpClick = () => {
    props.history.push(ROUTE.SIGN_UP);
  };

  const renderErrors = () => {
    return <Message text={validationError} isSuccess={false} />;
  };

  return (
    <ManeWrapper>
      {isMACApp() && <MacDragDro />}
      {isWindowsApp() && <ElectronTitlebar />}
      <LoginWrap>
        <LoginLeft mobile={mobile} isRoadMap={isRoadMap}>
          <LoginContainer>
            <LogoWrapper>
              <AppLogo />
              <Title>Login</Title>
            </LogoWrapper>
            <Form onSubmit={onSubmit}>
              <InputBlock>
                <Lable>Email</Lable>
                <Input
                  isPrimary={true}
                  type='email'
                  maxLength='256'
                  autocomplete='off'
                  required=''
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id='email'
                  name='email'
                  placeholder='Email'
                />
              </InputBlock>
              <InputBlock>
                <Lable>Password</Lable>
                <Input
                  isPrimary={true}
                  type='password'
                  maxLength='256'
                  required=''
                  autocomplete='off'
                  id='password'
                  name='Password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </InputBlock>
              {renderErrors()}
              <ButtonWrapper>
                <LabelInput>
                  <InputWrapperModal>
                    <input type='checkbox' />
                    <span className='checkmark'></span>
                    <TextPopup>Remember me</TextPopup>
                  </InputWrapperModal>
                </LabelInput>
                {appHostType === APP_HOST_TYPE.SALESCAMP && (
                  <ForgotLink disabled={loading} onClick={onClickForgotPassword}>
                    Forgot password?
                  </ForgotLink>
                )}
              </ButtonWrapper>
              <LoginBtnBlock>
                <Button size='large' title='Login' type='submit' loading={loading} width={'100%'} />
              </LoginBtnBlock>
              {appHostType === APP_HOST_TYPE.SALESCAMP && (
                <BottomText>
                  Dont have an account?<CreateSpan onClick={onSignUpClick}> Create your account</CreateSpan>
                </BottomText>
              )}
            </Form>
          </LoginContainer>
        </LoginLeft>
        <LoginRight mobile={mobile} isRoadMap={isRoadMap}>
          <LoginImage src={LoginLinearImg}></LoginImage>
        </LoginRight>
      </LoginWrap>
      <SnackBar />
    </ManeWrapper>
  );
};

export default Login;
