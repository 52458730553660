import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { hasUserAccessAndPermission } from '../../../services/appService';
import { LookupFieldTypes, LOOKUP_FIELD_EDIT_VIEW_FROM } from '../../../constants/constant';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { IconA, Item, LSpan, RText, CustomeEdit, UrlField } from './Styles';
import LookupFieldInputForm from './LookupFieldInputForm';

const LookupFieldViewEditForm = (props) => {
  const params = useParams();
  const tableName = params?.name;
  const { field, lookup_id, viewType, table } = props;
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const userAccess = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', tableName));
    return {
      permissionEdit: permissionEdit
    };
  }, [dispatch, tableName]);

  const onEdit = () => {
    setIsEdit(true);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
  };

  const onUpdated = (output) => {
    setIsEdit(false);
    if (props.onUpdated) props.onUpdated(output);
  };

  const renderDisplayValue = useMemo(() => {
    return field?.type === LookupFieldTypes.Url ? (
      <UrlField to={{ pathname: field?.value }} target={'_blank'} title={field?.value}>
        {field?.value_formatted || ''}
      </UrlField>
    ) : (
      <RText title={field?.value_formatted}>{field?.value_formatted}</RText>
    );
  }, [field?.type, field?.value, field?.value_formatted]);

  return (
    <>
      {!isEdit ? (
        <Item onDoubleClick={userAccess?.permissionEdit ? onEdit : null} viewType={viewType}>
          <LSpan>{field?.label}:</LSpan>
          {renderDisplayValue}
          {userAccess?.permissionEdit && (
            <IconA title={'Edit'} onClick={onEdit}>
              <SVGIcon fill='var(--gray-icon-color)' size={16} name='icon-edit' strokeWidth={2} />
            </IconA>
          )}
        </Item>
      ) : (
        <CustomeEdit>
          <LookupFieldInputForm
            lookup_id={lookup_id}
            field={field}
            fieldValue={field}
            onCancel={onCancelEdit}
            onUpdated={onUpdated}
            viewType={LOOKUP_FIELD_EDIT_VIEW_FROM.DETAILS_UPDATE_FIELD}
            table={table}
          />
        </CustomeEdit>
      )}
    </>
  );
};

export default LookupFieldViewEditForm;
