import { createAction } from '@reduxjs/toolkit';
import * as Actions from './types';

/**
 * @desc Set success message
 */
export const setSuccessMessage = createAction(Actions.SET_RES_SUCCESS_MSG);

/**
 * @desc Set Error message
 */
export const setErrorMessage = createAction(Actions.SET_RES_ERROR_MSG);

/**
 * @desc Set Warning message
 */
export const setWarningMessage = createAction(Actions.SET_RES_WARNING_MSG);

/**
 * @desc Set Error message
 */
export const setItemErrorMessage = createAction(Actions.SET_ITEM_ERROR_MSG);

/**s
 * @desc Set Sucess message
 */
export const setItemSucessMessage = createAction(Actions.SET_ITEM_SUCESS_MSG);

/**
 * @desc Clear Response message
 */
export const clearResponseMessage = createAction(Actions.CLEAR_RES_MSG);
