import styled from 'styled-components';
import DropDown from '../../../../components/DropDown/DropDown';
import { Header4, Text1 } from '../../../../styles';

const EnrichModalMain = styled.div``;

const ModalHead = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const ModalHeadLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ModalText = styled.span`
  ${Header4}
  margin-left: 12px;
  color: var(--dark-color);
`;
const SvgWrapper = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
const Form = styled.form`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
const SecondContain = styled.div`
  padding: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
`;
const BottomView = styled.div`
  border-top: 1px solid var(--Platinum-color);
  padding: 16px;
  margin-top: auto;
`;
const AllBtnView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BtnView = styled.div`
  button {
    margin-right: 15px;
  }
`;
const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0;
  }
`;
const ItemBlock = styled.div`
  max-width: 170px;
  width: 100%;
  input {
    ${Text1}
    color: var(--dark-color);
  }
`;
const ItemTextBlock = styled.div`
  max-width: 170px;
  width: 100%;
  padding: 8px 12px;
  ${Text1}
  color: var(--dark-color);
`;
const ItemDropdown = styled(DropDown)`
  max-width: 170px;
  width: 100%;
`;
const ItemDate = styled.div`
  max-width: 170px;
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
`;

export {
  EnrichModalMain,
  ModalHead,
  ModalHeadLeft,
  ModalText,
  SvgWrapper,
  Form,
  SecondContain,
  BottomView,
  AllBtnView,
  BtnView,
  ItemRow,
  ItemBlock,
  ItemTextBlock,
  ItemDropdown,
  ItemDate
};
