import styled, { css } from 'styled-components';
import { Text1, Text2 } from '../../styles';

const SearchContainer = styled.div`
  width: 640px;
  max-height: 272px;
  background-color: var(--nav-dark-color);
  box-shadow: var(--search-modal-shadow);
  border-radius: 12px;
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translateX(-50%);
  outline: none;
  z-index: 9999;
  overflow: hidden;
`;
const SearchRow = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid var(--titlebar-bg);
`;
const SearchView = styled.div`
  margin-right: 12px;
  svg {
    display: block;
  }
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0;
  label {
    margin: 0;
  }
  input {
    padding: 0 12px 0 0;
    width: 100%;
    border: none;
    background-color: var(--nav-dark-color);
    color: var(--white-color);
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: var(--gray-color);
      ${Text1}
      font-weight: 400;
    }
  }
`;
const ListView = styled.div`
  /* height: calc(100% - 51px); */
  max-height: 221px;
  /* padding: 6px 0; */
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const RowView = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 16px;
  :first-child {
    margin-top: 6px;
  }
  :last-child {
    margin-bottom: 6px;
  }
  &:hover {
    background: var(--nav-dark-color-hover);
  }
  ${(props) =>
    props.selected &&
    css`
      background: var(--nav-dark-color-hover);
    `}
`;
const UserName = styled.span`
  ${Text1}
  color: var(--white-color);
  margin-left: 12px;
`;
const UserEmail = styled.p`
  margin: 0;
  ${Text2}
  color: var(--gray-color);
  padding-left: 24px;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--gray-color);
    border-radius: 50%;
    top: 40%;
    left: 8px;
    right: auto;
  }
`;

const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const EscSpan = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 6px;
  border-radius: 6px;
  color: var(--white-color);
  border: 1px solid var(--nav-dark-color-focus);
  display: block;
`;

export {
  SearchContainer,
  SearchRow,
  RightView,
  ListView,
  RowView,
  UserName,
  UserEmail,
  SearchView,
  OverLayDiv,
  EscSpan
};
