/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import { useDispatch } from 'react-redux';
import DropDown from '../../DropDown/DropDown';
import { itemPerPage } from '../../../data/raw';
import { ReactComponent as MoreIcon } from '../../../assets/images/svg/morelist.svg';
import { BULK_LOOKUP_TYPE_ID, LookupFieldTypes } from '../../../constants/constant';
import OrganizationPreferencesSingleton from '../../../helpers/OrganizationPreferencesSingleton';
import { hasUserAccessAndPermission } from '../../../services/appService';
import { ConfirmDialog } from '../../ConfirmDialog';
import {
  bulkActionCollectionAnalyticActivity,
  getTableTypeForAnalytics,
  trackRecordPerPageCollectionAnalyticActivity,
  trackSortingCollectionAnalyticActivity
} from '../../../services/lookupService';
import useMobileDevice from '../../../hooks/useMobileDevice';
import SVGIcon from '../../../assets/svg/SVGIcon';
import {
  Styles,
  FastCol,
  InputWrapper,
  StylesWrapper,
  PaginationWrapper,
  PaginationLeft,
  Span,
  PaginationRight,
  ArrowButton,
  Tr,
  ViewIcon,
  FlatIcon,
  customestyle,
  SpanPage,
  HeaderTitle,
  SpanArrow,
  IconWrapper,
  SvgWrapper
} from './Styles';
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return (
    <InputWrapper>
      <input type='checkbox' ref={resolvedRef} {...rest} />
      <span className='checkmark'></span>
    </InputWrapper>
  );
});

const PAGE_SIZE = 'pageSize';
const SORTED_FEILDS = 'sortedFeilds';

export default function LookupTable({
  detailUrlPrefix,
  columns,
  data,
  table,
  openMoreMenu,
  defaultColumn,
  onNameClick,
  selectedRows,
  openBulkMoreMenu,
  onClickBulkDelete,
  onClickBulkTags,
  onClickBulkEmail,
  exportData,
  initialState,
  onClickEmail,
  onClickCall,
  hasEmailAccount,
  hasPhoneNumber
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkboxs
          Header: ({ getToggleAllPageRowsSelectedProps }) =>
            !mobile && (
              <FastCol>
                <IndeterminateCheckbox className='input' {...getToggleAllPageRowsSelectedProps()} />
              </FastCol>
            ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              !mobile && (
                <FastCol>
                  <IndeterminateCheckbox className='input' {...row.getToggleRowSelectedProps()} />
                </FastCol>
              )
            );
          }
        },
        ...columns
      ]);
    }
  );

  useEffect(() => {
    const pageSize = OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, PAGE_SIZE) || 100;
    setPerPageItem(pageSize);
    setPageSize(Number(pageSize));
  }, [setPageSize, table?.id]);

  useEffect(() => {
    if (selectedRows) selectedRows(selectedFlatRows);
  }, [selectedFlatRows, selectedRows]);

  const [perPageItem, setPerPageItem] = useState(100);

  const dispatch = useDispatch();

  const userAccess = useMemo(() => {
    const permissionEdit = dispatch(hasUserAccessAndPermission('tables', 'permissionEdit', table?.name));
    const permissionDelete = dispatch(hasUserAccessAndPermission('tables', 'permissionDelete', table?.name));

    return {
      permissionEdit,
      permissionDelete
    };
  }, [dispatch, table?.name]);

  const handleChangePageSize = useCallback(
    (value) => {
      setPageSize(Number(value));
      setPerPageItem(Number(value));
      OrganizationPreferencesSingleton.getInstance().setSettingValue(table?.id, PAGE_SIZE, value);
      const type = getTableTypeForAnalytics(table);
      trackRecordPerPageCollectionAnalyticActivity(type, Number(value));
    },
    [table, setPageSize]
  );

  const onChangeSorting = useCallback(
    (column) => {
      let desc = column?.isSorted ? (column?.isSortedDesc ? true : false) : undefined;
      const value = {
        fieldId: column?.id,
        desc
      };
      OrganizationPreferencesSingleton.getInstance().setSettingValue(table?.id, SORTED_FEILDS, value);
      const type = getTableTypeForAnalytics(table);
      if (desc !== undefined) {
        trackSortingCollectionAnalyticActivity(type, column?.field?.customId, desc ? 'desc' : 'asc');
      }
    },
    [table]
  );

  const tableTypeForAnalytics = useMemo(() => {
    const type = getTableTypeForAnalytics(table);
    return type;
  }, [table]);

  const onClickBulkExport = useCallback(() => {
    bulkActionCollectionAnalyticActivity('export', tableTypeForAnalytics);
    if (exportData) exportData();
  }, [exportData, tableTypeForAnalytics]);

  const mobile = useMobileDevice();

  const isHidePagination = useMemo(() => {
    return data?.length < pageSize;
  }, [data?.length, pageSize]);

  return (
    <>
      <StylesWrapper mobile={mobile}>
        <Styles mobile={mobile} hidePagination={isHidePagination}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      htmlFor={!mobile && index === 1 ? 'name' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <HeaderTitle
                        onClick={() => {
                          setTimeout(() => {
                            onChangeSorting(column);
                          }, 10);
                        }}>
                        {column.render('Header')}
                        <SpanArrow>
                          {column?.isSorted} {column?.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                        </SpanArrow>
                      </HeaderTitle>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return index === 0 ? (
                        <th {...cell.getCellProps()}>{cell.render('Cell')}</th>
                      ) : (
                        <td htmlFor={!mobile && index === 1 ? 'name' : ''} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                          {hasEmailAccount &&
                            cell?.column?.field?.type === LookupFieldTypes.Email &&
                            cell?.row?.original[cell?.column?.field?.id] && (
                              <IconWrapper
                                title='Email'
                                className='icon'
                                onClick={() => {
                                  onClickEmail(cell?.row?.original, cell?.row?.original[cell?.column?.field?.id]);
                                }}>
                                <SVGIcon name='icon-email' fill='var(--gray-icon-color)' size={18} />
                              </IconWrapper>
                            )}
                          {hasPhoneNumber &&
                            cell?.column?.field?.type === LookupFieldTypes.Phone &&
                            cell?.row?.original[cell?.column?.field?.id] && (
                              <IconWrapper
                                title='Call'
                                className='icon'
                                onClick={() => {
                                  onClickCall(cell);
                                }}>
                                <SVGIcon name='icon-phone' fill='var(--gray-icon-color)' size={18} />
                              </IconWrapper>
                            )}
                        </td>
                      );
                    })}
                    <td>
                      {(userAccess?.permissionDelete || userAccess?.permissionEdit) && (
                        <ViewIcon
                          mobile={mobile}
                          onClick={(e) => {
                            openMoreMenu(e, row);
                          }}>
                          <SVGIcon name='icon-more' fill='var(--gray-icon-color)' size={24} />
                        </ViewIcon>
                      )}
                    </td>
                  </Tr>
                );
              })}
            </tbody>
          </table>
        </Styles>
      </StylesWrapper>
      <PaginationWrapper
        className='resize'
        hidePagination={isHidePagination}
        isBulkSelect={selectedFlatRows?.length > 1}
        mobile={mobile}>
        <PaginationLeft>
          <DropDown
            isSearchable={false}
            options={itemPerPage}
            styles={customestyle}
            labelField={'text'}
            valueField={'value'}
            value={perPageItem}
            menuPlacement={'top'}
            onChange={(e) => {
              handleChangePageSize(Number(e?.value));
            }}
          />
          <SpanPage>Items per page</SpanPage>
        </PaginationLeft>
        {selectedFlatRows?.length > 1 && (
          <FlatIcon isBulkSelect={selectedFlatRows?.length > 1} hidePagination={isHidePagination}>
            <SvgWrapper onClick={onClickBulkEmail}>
              <SVGIcon name='icon-email' fill='var(--mantis-icon)' size={24} />
            </SvgWrapper>
            <SvgWrapper onClick={onClickBulkTags}>
              <SVGIcon name='icon-tag' fill='var(--icon-united-nations-blue)' size={24} />
            </SvgWrapper>
            <SvgWrapper onClick={onClickBulkExport}>
              <SVGIcon name='icon-download' fill='var(--icon-mango)' size={24} />
            </SvgWrapper>
            <SvgWrapper
              onClick={() => {
                bulkActionCollectionAnalyticActivity('delete', tableTypeForAnalytics);
                ConfirmDialog({
                  iconName: 'icon-delete',
                  closeIcon: 'icon-close',
                  title: 'Delete',
                  message: `Are you sure want to delete this ${BULK_LOOKUP_TYPE_ID[table?.type]} ?`,
                  onOk: () => {
                    if (onClickBulkDelete) onClickBulkDelete(selectedFlatRows);
                  }
                });
              }}>
              <SVGIcon name='icon-delete' fill='var(--icon-paradise-pink)' size={24} />
            </SvgWrapper>
            <MoreIcon onClick={openBulkMoreMenu} />
          </FlatIcon>
        )}
        <PaginationRight>
          <Span>
            {pageIndex * pageSize + 1} - {pageIndex * pageSize + page.length} of {data?.length}
          </Span>
          <ArrowButton onClick={() => previousPage()} disabled={!canPreviousPage} title='Back'>
            <SVGIcon name='icon-left-arrow' fill='var(--gray-icon-color)' size={24} />
          </ArrowButton>
          <ArrowButton onClick={() => nextPage()} disabled={!canNextPage} title='Forward'>
            <SVGIcon name='icon-right-arrow' fill='var(--gray-icon-color)' size={24} />
          </ArrowButton>
        </PaginationRight>
      </PaginationWrapper>
    </>
  );
}
