import React, { useMemo, useCallback, useState } from 'react';
import Async from 'react-select/async';
import useEventListener from '../../hooks/useEventListener';
import { Text1 } from '../../styles';
import { getUniqueId, isEmail, isEmpty } from '../../helpers/common';
import { DropMenu, EmailText, LabelText, LeftView, Dot, SelectedEmail } from './styles';
let timeout;
const ENTER = 13;
const SEMI_COLON = 186;
const COMMA = 188;

const style = {
  control: (base, { isFocused }) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: `${Text1}`,
    color: 'red',
    minHeight: '30px',
    borderRadius: '4px',
    borderColor: 'none',
    borderWidth: '0',
    margin: isFocused ? '0px' : '0px',
    boxShadow: 'none'
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--primary-color)',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '500',
    padding: '2px 6px',
    borderRadius: '4px',
    backgroundColor: 'var(--popup-color-hover)'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '12px',
    backgroundColor: 'var(--white-color)',
    display: 'none'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none '
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '6px',
    right: '20px'
  }),
  option: (styles) => {
    return {
      ...styles,
      // backgroundColor: 'var(--white-color)',
      color: '#000000',
      fontFamily: 'var(--primary-font-stack)',
      fontSize: `${Text1}`
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'var(--popup-color-hover)',
      borderRadius: '4px',
      margin: '2px',
      padding: '2px 6px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '16px'
    };
  },
  placeholder: (base) => ({
    ...base,
    fontFamily: 'var(--primary-font-stack)',
    fontSize: '14px',
    fontWeight: '500'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'var(--dark-color)',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    padding: 0,
    paddingLeft: 0
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'var(--gray-icon-color)',
    ':hover': {
      backgroundColor: 'var(--popup-color-hover)',
      cursor: 'pointer'
    }
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '1px 4px 1px 4px'
  })
};

const EmailDropDown = ({ options, value, onChange, labelField = 'name', valueField = 'id', rest, placeholder }) => {
  const [inputValue, setInputValue] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const allOptions = useMemo(() => {
    let data = [];
    data =
      options?.map((item) => {
        return {
          ...item,
          label: item[labelField],
          value: item[valueField]
        };
      }) || [];
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, labelField, valueField, options?.length]);

  const defaultOptions = useMemo(() => {
    return allOptions?.slice(0, 50) || [];
  }, [allOptions]);

  const loadOptions = useCallback(
    (inputValue, callback) => {
      const filterItems =
        allOptions
          ?.filter(
            (x) =>
              (x?.email && x?.email?.toLowerCase().includes(inputValue?.toLowerCase())) ||
              (x?.name && x?.name?.toLowerCase().includes(inputValue?.toLowerCase()))
          )
          ?.slice(0, 50) || [];

      const result = filterItems?.map((item) => {
        return {
          ...item,
          label: item[labelField],
          value: item[valueField]
        };
      });
      callback(result);
    },
    [allOptions, labelField, valueField]
  );

  const valueItems = useMemo(() => {
    return value?.map((item) => {
      let optionValue = allOptions?.find((x) => x?.value === item[valueField]);
      return optionValue;
    });
  }, [allOptions, value, valueField]);

  const formatOptionLabel = useCallback(({ label, email }, { ...props }) => {
    return props?.context === 'menu' ? (
      <DropMenu>
        <LeftView>
          <LabelText>{label}</LabelText>
          <Dot></Dot>
          <EmailText>{email}</EmailText>
        </LeftView>
      </DropMenu>
    ) : (
      <SelectedEmail>{email}</SelectedEmail>
    );
  }, []);

  const handleMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setInputValue('');
    setIsMenuOpen(false);
  }, []);

  const onKeyDownListener = useCallback(
    (event) => {
      try {
        if (isEmpty(inputValue) || !isEmail(inputValue)) return;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          switch (event.keyCode) {
            case ENTER:
            case SEMI_COLON:
            case COMMA:
              const id = getUniqueId();
              const item = {
                email: inputValue,
                name: inputValue,
                id,
                isCustomEmail: true
              };
              let values = valueItems;
              values?.push(item);
              onChange(values);
              setInputValue('');
              handleMenuClose();
              break;
            default:
              break;
          }
        }, 0);
      } catch (e) {
        console.error('ERROR - onItemSelected', e);
      }
    },
    [handleMenuClose, inputValue, onChange, valueItems]
  );
  useEventListener('keydown', onKeyDownListener);
  return (
    <Async
      cacheOptions
      defaultOptions={defaultOptions}
      placeholder={placeholder}
      styles={style}
      isMulti={true}
      pageSize={50}
      loadOptions={loadOptions}
      value={valueItems}
      inputValue={inputValue}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      menuIsOpen={isMenuOpen}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onInputChange={(value) => {
        setInputValue(value);
      }}
      {...rest}
    />
  );
};

export default EmailDropDown;
