import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as EmailNotImg } from '../../../assets/images/svg/contact_empty.svg';
import Button from '../../../components/atom/Button/Button';
import {
  EmailNotFoundWrapper,
  EmailNotFoundImg,
  EmailNotFoundHead,
  EmailNotFoundText,
  EmailNotFoundLinkBlock,
  ContactWrapper
} from './styles';

const ContactEmpty = ({ openAddModal, table_id }) => {
  const history = useHistory();

  return (
    <>
      <ContactWrapper>
        <EmailNotFoundWrapper>
          <EmailNotFoundImg>
            <EmailNotImg />
          </EmailNotFoundImg>
          <EmailNotFoundHead>Add contacts to your collection</EmailNotFoundHead>
          <EmailNotFoundText>
            Kick-start your new collection by importing contacts from a *.CSV or xls or add your first record, Learn
            More
          </EmailNotFoundText>
          <EmailNotFoundLinkBlock>
            <Button
              title='Add contact'
              primary
              onClick={() => {
                openAddModal();
              }}
            />
            <Button
              title='Import'
              secondary
              onClick={() => {
                history.push(`/settings/importdata?tableId=${table_id}`);
              }}
            />
          </EmailNotFoundLinkBlock>
        </EmailNotFoundWrapper>
      </ContactWrapper>
    </>
  );
};

export default ContactEmpty;
