import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export function DayPickerCard({ selectedDays, ...props }) {
  const modifiersStyles = {
    today: {
      color: 'var(--primary-color)',
      backgroundColor: 'transparent'
    }
  };

  const onDayClick = (day, { disabled }) => {
    if (disabled) return;
    if (props.onDayClick) props.onDayClick(day);
  };

  return <DayPicker modifiersStyles={modifiersStyles} {...props} selectedDays={selectedDays} onDayClick={onDayClick} />;
}

export default DayPickerCard;
