import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE } from '../../../constants/routeConst';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import { resetPassword } from '../../../services/authServices';
import Message from '../../../components/Message/Message';
import AppLogo from '../../../components/AppLogo';
import {
  ManeWrapper,
  LoginContainer,
  Title,
  LogoWrapper,
  Form,
  AlreadyPara,
  ButtonWrap,
  Link,
  PrivacyPolicy
} from './Styles';

const ResetPassword = () => {
  const location = useLocation();
  const search = location?.search;
  const URLParams = new URLSearchParams(search);
  const code = URLParams.get('code');
  const authSelector = useSelector((state) => state.auth);
  const { loading, validationError } = authSelector;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [suceessMessage, setSuceessMessage] = useState(false);
  const history = useHistory();

  const onClickLogin = useCallback(() => {
    history.push(ROUTE.LOGIN);
  }, [history]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const payload = {
        code,
        email,
        password,
        confirmPassword
      };
      const result = await dispatch(resetPassword(payload));
      if (result) {
        setSuceessMessage(result?.message);
      }
    },
    [code, confirmPassword, dispatch, email, password]
  );
  const renderErrors = () => {
    return <Message text={validationError} isSuccess={false} />;
  };
  return (
    <ManeWrapper>
      <LoginContainer>
        {suceessMessage ? (
          <LogoWrapper>
            <AppLogo />
            <Title>Reset Password Confirmation</Title>
            <PrivacyPolicy>{suceessMessage}</PrivacyPolicy>
            <AlreadyPara>
              Go back to <Link onClick={onClickLogin}>Log in</Link>
            </AlreadyPara>
          </LogoWrapper>
        ) : (
          <>
            <LogoWrapper>
              <AppLogo />
              <Title>Reset Password</Title>
            </LogoWrapper>
            <Form onSubmit={onSubmit}>
              <Input
                isPrimary={true}
                type='email'
                id='email'
                name='Email'
                placeholder='Email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Input
                isPrimary={true}
                type='password'
                id='password'
                name='new-password'
                placeholder='New Password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Input
                isPrimary={true}
                type='password'
                id='confirm'
                name='confirm-password'
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              {renderErrors()}
              <ButtonWrap>
                <Button title='Set Password' primary size='large' type='submit' loading={loading} />
              </ButtonWrap>
              <AlreadyPara>
                Go back to <Link onClick={onClickLogin}>Log in</Link>
              </AlreadyPara>
            </Form>
          </>
        )}
      </LoginContainer>
    </ManeWrapper>
  );
};

export default ResetPassword;
