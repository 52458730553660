import React, { useCallback, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input/Input';
import Button from '../../../components/atom/Button/Button';
import SVGIcon from '../../../assets/svg/SVGIcon';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  createAPIKey,
  getAPIKeyItem,
  trackAPIKeyActionAnalyticActivity,
  updateAPIKey
} from '../../../services/settingService';
import { setAPIKeyItem, updateAPIKeyItem } from '../../../actions/settingActions';
import {
  MainContact,
  TitleView,
  TitleText,
  SecondContain,
  InputView,
  BottomView,
  Form,
  HeaderWrapper,
  ButtonWrapper,
  SvgWrapper
} from './styles';

const NewApiKey = (props) => {
  const mobile = useMobileDevice();
  const organizationSelector = useSelector((state) => state.organization);
  const { organization } = organizationSelector;
  const { id: organization_id } = organization;

  const id = props?.id;
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (!id) return;
    dispatch(getAPIKeyItem(organization_id, id));
  }, [dispatch, organization_id, id]);

  const settingSelector = useSelector((state) => state.setting);
  const { apiKeyItem, loading } = settingSelector;

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setAPIKeyItem(null));
    };
  }, [dispatch, loadData]);

  const onClose = useCallback(() => {
    props.handleCloseModal();
    dispatch(setAPIKeyItem(null));
  }, [props, dispatch]);

  const onUpdateAPIKey = useCallback(
    (fieldName, FieldVal) => {
      dispatch(updateAPIKeyItem({ propName: fieldName, value: FieldVal }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const params = apiKeyItem;
      const result = params?.id
        ? await dispatch(updateAPIKey(organization_id, params))
        : await dispatch(createAPIKey(organization_id, params));

      if (result) {
        onClose();
        if (params?.id) {
          trackAPIKeyActionAnalyticActivity('updated', apiKeyItem?.name, apiKeyItem?.subject);
        } else {
          trackAPIKeyActionAnalyticActivity('added', apiKeyItem?.name, apiKeyItem?.subject);
        }
      }
    },
    [dispatch, onClose, organization_id, apiKeyItem]
  );

  return (
    <MainContact mobile={mobile}>
      <TitleView>
        <HeaderWrapper>
          <SVGIcon fill='var(--gray-icon-color)' size={22} name='icon-document' />
          <TitleText>{id ? 'Edit Key' : 'New Key'}</TitleText>
        </HeaderWrapper>
        <SvgWrapper onClick={onClose} title='Close'>
          <SVGIcon fill='var(--gray-icon-color)' size={20} name='icon-close' />
        </SvgWrapper>
      </TitleView>
      <Form
        onSubmit={(e) => {
          onSubmit(e);
        }}>
        <SecondContain>
          <InputView>
            <Input
              isPrimarySmall={true}
              type='text'
              id='name'
              name='Name'
              placeholder='Name'
              title='Name'
              value={apiKeyItem?.name || ''}
              onChange={(e) => {
                onUpdateAPIKey('name', e.target.value);
              }}
            />
          </InputView>
        </SecondContain>
        <BottomView>
          <ButtonWrapper>
            <Button type='submit' title='save' loading={loading} />
          </ButtonWrapper>
          <Button type='button' title='cancel' secondary onClick={onClose} />
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default NewApiKey;
