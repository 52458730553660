import Empty from '../../../assets/images/automation-empty.svg';
import { EmptyImg, EmptyWrapper, DetailsTitle, Details } from './styles';

const CallLogEmptyState = () => {
  return (
    <EmptyWrapper>
      <EmptyImg src={Empty} />
      <DetailsTitle>No Call Log Found</DetailsTitle>
      <Details>There is no call entry found.</Details>
    </EmptyWrapper>
  );
};

export default CallLogEmptyState;
