import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import useMobileDevice from '../../../../hooks/useMobileDevice';
import SVGIcon from '../../../../assets/svg/SVGIcon';
import OrganizationPreferencesSingleton from '../../../../helpers/OrganizationPreferencesSingleton';
import {
  FirstList,
  BoxText,
  LastView,
  SecondContain,
  TitleView,
  ALasttext,
  TagView,
  FirstTag,
  NameUser,
  ValueBox,
  UserAmount,
  BoxFirst,
  IconActions,
  IconWrap,
  BoxContacts,
  NameUserWrapper,
  ModalStyle
} from '../styles';
import {
  LOOKUP_FIELD_CUSTOM_ID,
  LOOKUP_TYPE,
  SEND_EMAIL_TYPE,
  LOOKUP_UPDATE_SOURCE
} from '../../../../constants/constant';
import { hasUserAccessAndPermission } from '../../../../services/appService';
import { currencySymbols } from '../../../../data/raw';
import { currencyWithDecimal, isEmpty } from '../../../../helpers/common';
import TagList from '../TagList';
import {
  getTableTypeForAnalytics,
  trackContactDetailActionAnalytics,
  trackContactDetailAnalytics,
  updateLookupField
} from '../../../../services/lookupService';
import { getActivityByLookup } from '../../../../services/activityService';
import SendEmailModal from '../../../Email/SendEmailModal/SendEmailModal';
import { removeLookupTags } from '../../../../actions/lookupActions';
import AddNewContact from '../../AddNewContact';
import TwilioService from '../../../../services/twilioService';

const IS_DEAL_EXPANDED_KEY = 'isDealExpanded';
const IS_COMPANY_EXPANDED_KEY = 'isCompanyExpanded';
const IS_CONTACTS_EXPANDED_KEY = 'isContactsExpanded';
const IS_TAG_EXPANDED_KEY = 'isTagExpanded';

const ContactDetailConnectedItems = ({
  lookupDetails,
  lookupTableList,
  connectedLookup,
  table,
  userAccess,
  organization,
  tags,
  lookup_id,
  onSendEmail,
  onSaveContact
}) => {
  const mobile = useMobileDevice();
  const history = useHistory();
  const dispatch = useDispatch();

  const settingsSelector = useSelector((state) => state.setting);
  const emailSelector = useSelector((state) => state.email);

  const { id: organization_id } = organization;
  const { phoneNumberList } = settingsSelector;
  const { emailAccounts } = emailSelector;

  const [isCompanyExpanded, setCompanyExpanded] = useState(true);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [lookupIds, setLookupIds] = useState([]);
  const [isDealExpanded, setDealExpanded] = useState(true);
  const [isOpenAddDealModal, setIsOpenAddDealModal] = useState(false);
  const [newDealContact, setNewDealContact] = useState();
  const [newDealCompany, setNewDealCompany] = useState();
  const [isContactsExpanded, setContactsExpanded] = useState(true);
  const [isTagExpanded, setTagExpanded] = useState(true);
  const [selectedTags, setSelectedTags] = useState();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemCloneId, setSelectedItemCloneId] = useState();

  useEffect(() => {
    const isDealExpanded =
      OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, IS_DEAL_EXPANDED_KEY) || false;
    const isCompanyExpanded =
      OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, IS_COMPANY_EXPANDED_KEY) || false;
    const isContactsExpanded =
      OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, IS_CONTACTS_EXPANDED_KEY) || false;
    const isTagExpanded =
      OrganizationPreferencesSingleton.getInstance().getSettingValue(table?.id, IS_TAG_EXPANDED_KEY) || false;
    setDealExpanded(isDealExpanded);
    setCompanyExpanded(isCompanyExpanded);
    setContactsExpanded(isContactsExpanded);
    setTagExpanded(isTagExpanded);
  }, [table?.id]);

  useEffect(() => {
    setSelectedTags(lookupDetails?.tagsField?.value_Options);
    if (!isEmpty(tags)) {
      lookupDetails?.tagsField?.value_Options?.forEach((element) => {
        const index = tags?.findIndex((x) => x === element?.id);
        dispatch(removeLookupTags({ index: index }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupDetails.tagsField, dispatch]);

  const setIsCompanyExpanded = useCallback(
    (key, value) => {
      setCompanyExpanded(value);
      OrganizationPreferencesSingleton.getInstance().setSettingValue(table?.id, key, value);
    },
    [table?.id]
  );

  const openSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(true);
  }, []);

  const hasPhoneNumber = useMemo(() => {
    return phoneNumberList?.length > 0;
  }, [phoneNumberList?.length]);

  const hasEmailAccount = useMemo(() => {
    return emailAccounts?.filter((x) => x?.isEmailSync)?.length > 0;
  }, [emailAccounts]);

  const getDealTables = useMemo(() => {
    const tables = lookupTableList?.filter((x) => x?.type === LOOKUP_TYPE.deals);
    return tables;
  }, [lookupTableList]);

  const filteredLookupDealsByAccess = useMemo(() => {
    const dealsList = [];
    const dealTablesWithAccess = getDealTables?.map((table) => {
      const isAccess = dispatch(hasUserAccessAndPermission('tables', 'isAccess', table?.name));
      return { ...table, isAccess: isAccess };
    });
    connectedLookup?.deals?.forEach((deal) => {
      const dealWithAccess = dealTablesWithAccess?.find((table) => table?.id === deal?.tableId)?.isAccess;
      if (dealWithAccess) dealsList.push(deal);
    });
    return dealsList;
  }, [dispatch, getDealTables, connectedLookup?.deals]);

  const dealsCount = useMemo(() => {
    return filteredLookupDealsByAccess?.length || 0;
  }, [filteredLookupDealsByAccess]);

  const setIsDealExpanded = useCallback(
    (key, value) => {
      setDealExpanded(value);
      OrganizationPreferencesSingleton.getInstance().setSettingValue(table?.id, key, value);
    },
    [table?.id]
  );

  const openAddModal = useCallback(
    (type) => {
      if (type === 'isAddDeal') setIsOpenAddDealModal(true);

      if (table?.type === LOOKUP_TYPE.contacts) {
        setNewDealContact(lookupDetails);
      }
      if (table?.type === LOOKUP_TYPE.companies) {
        setNewDealCompany(lookupDetails);
      }
    },
    [table, lookupDetails]
  );

  const currencySymbol = useMemo(() => {
    return currencySymbols?.find((x) => x.code === organization?.settings?.currency);
  }, [organization?.settings?.currency]);

  const setIsContactsExpanded = useCallback(
    (key, value) => {
      setContactsExpanded(value);
      OrganizationPreferencesSingleton.getInstance().setSettingValue(table?.id, key, value);
    },
    [table?.id]
  );

  const tagsCount = useMemo(() => {
    return lookupDetails?.tagsField?.value_Options?.length || 0;
  }, [lookupDetails?.tagsField]);

  const setIsTagExpanded = useCallback(
    (key, value) => {
      setTagExpanded(value);
      OrganizationPreferencesSingleton.getInstance().setSettingValue(table?.id, key, value);
    },
    [table?.id]
  );

  const tableTypeForAnalytics = useMemo(() => {
    if (table) {
      const type = getTableTypeForAnalytics(table);
      return type;
    }
    return null;
  }, [table]);

  const onCallClick = useCallback(
    (number, item) => {
      trackContactDetailActionAnalytics(tableTypeForAnalytics, 'call');
      dispatch(TwilioService.getInstance().connectCall(number, lookupDetails?.name, item));
    },
    [dispatch, tableTypeForAnalytics, lookupDetails?.name]
  );

  const trackDetailAnalyticsEvent = useCallback(
    (field, value) => {
      let params, action;
      if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.STATUS || field?.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE) {
        const fromOption = field?.options?.find((x) => x?.id === field?.value);
        const toOption = field?.options?.find((x) => x?.id === value?.value);
        if (fromOption && toOption) {
          params = {
            from: String(fromOption.value).toLowerCase(),
            to: String(toOption.value).toLowerCase()
          };
        }
        action = 'status changed';
        if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.STAGE) action = 'stage changed';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.NEXT_FOLLOW_UP_DATE) {
        action = 'followup changed';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.CLOSE_DATE) {
        action = 'close date changed';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.ASSIGN_USER) {
        action = 'assigned';
      } else if (field?.customId === LOOKUP_FIELD_CUSTOM_ID.TAGS) {
        let result;
        if (
          value?.value_Options?.length > field?.value_Options?.length ||
          (!field?.value_Options && value?.value_Options)
        ) {
          action = 'tag added';
          result = value?.value_Options?.find((x) => !field?.value_Options?.some((y) => x?.id === y?.id));
        } else {
          action = 'tag removed';
          result = field?.value_Options?.find((x) => !value?.value_Options?.some((y) => x?.id === y?.id));
        }
        if (result) {
          params = { 'tag name': result?.id };
        }
      }
      trackContactDetailAnalytics(tableTypeForAnalytics, action, params);
    },
    [tableTypeForAnalytics]
  );

  const updateLookupFields = useCallback(
    async (field, value, isUpdateStatus) => {
      if (!lookup_id) return;
      const obj = {
        field: field,
        value: value
      };
      const result = dispatch(updateLookupField(organization_id, lookup_id, obj));
      if (result) {
        trackDetailAnalyticsEvent(field, value);
        if (isUpdateStatus) {
          dispatch(getActivityByLookup(organization_id, lookup_id));
        }
      }
    },
    [dispatch, organization_id, lookup_id, trackDetailAnalyticsEvent]
  );

  const closeSendEmailModal = useCallback(() => {
    setIsOpenSendEmailModal(false);
    setLookupIds([]);
  }, []);

  const closeAddModal = useCallback(() => {
    setIsOpenAddDealModal(false);
    setSelectedItemId(undefined);
    setSelectedItemCloneId(undefined);
  }, []);

  const onSave = useCallback(() => {
    onSaveContact();
    closeAddModal();
  }, [onSaveContact, closeAddModal]);

  const dealTable = useMemo(() => {
    const table = lookupTableList?.find((x) => x?.type === LOOKUP_TYPE.deals && x?.isSystem);
    return table;
  }, [lookupTableList]);

  const sendEmailModal = useMemo(() => {
    return (
      <Modal
        open={isOpenSendEmailModal}
        onClose={closeSendEmailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        style={ModalStyle}>
        <SendEmailModal
          handleCloseModal={closeSendEmailModal}
          onSend={onSendEmail}
          lookupIds={lookupIds}
          type={SEND_EMAIL_TYPE.COMPOSE}
        />
      </Modal>
    );
  }, [closeSendEmailModal, isOpenSendEmailModal, lookupIds, onSendEmail]);

  const addDealModal = useMemo(() => {
    return (
      <Modal open={isOpenAddDealModal} onClose={closeAddModal} style={ModalStyle}>
        <AddNewContact
          handleCloseModal={closeAddModal}
          onSave={onSave}
          source={LOOKUP_UPDATE_SOURCE.DETAILS}
          currentTableType={tableTypeForAnalytics}
          company={newDealCompany}
          contact={newDealContact}
          itemId={selectedItemId}
          cloneId={selectedItemCloneId}
          table={dealTable}
        />
      </Modal>
    );
  }, [
    closeAddModal,
    dealTable,
    isOpenAddDealModal,
    newDealCompany,
    newDealContact,
    onSave,
    selectedItemCloneId,
    selectedItemId,
    tableTypeForAnalytics
  ]);

  const companyView = useMemo(() => {
    if (connectedLookup?.companies?.length > 0)
      return (
        <TitleView mobile={mobile}>
          <BoxFirst>
            <FirstList>
              <SVGIcon name='icon-account' size={22} fill='var(--gray-icon-color)' />
              <BoxText>Company</BoxText>
            </FirstList>
            <LastView onClick={() => setIsCompanyExpanded(IS_COMPANY_EXPANDED_KEY, !isCompanyExpanded)}>
              <SVGIcon
                title={isCompanyExpanded ? 'Hide Company' : 'Show Company'}
                name='icon-down-arrow'
                size={22}
                fill='var(--gray-icon-color)'
              />
            </LastView>
          </BoxFirst>
          {isCompanyExpanded &&
            connectedLookup?.companies?.map((item, index) => {
              return (
                <ValueBox key={`connectedLookup_${item?.id}_${index}`}>
                  <BoxContacts>
                    <NameUserWrapper>
                      <NameUser
                        onClick={() => {
                          const tableItem = lookupTableList?.find((y) => y.id === item?.tableId);
                          history.push(`/cm/${tableItem?.name}/view/${item?.id}/overview`);
                        }}>
                        {item?.name}
                      </NameUser>
                    </NameUserWrapper>
                    {item?.email && <UserAmount>{item?.email}</UserAmount>}
                  </BoxContacts>

                  <IconActions>
                    {hasPhoneNumber && item?.phone && (
                      <IconWrap
                        bothIcon={hasPhoneNumber && item?.phone && hasEmailAccount && item?.email}
                        buttonType='Call'
                        title='Call'
                        className='icon'
                        onClick={() => {
                          onCallClick(item?.phone, item);
                        }}>
                        <SVGIcon name='icon-phone' size={18} fill='var(--gray-icon-color)' />
                      </IconWrap>
                    )}
                    {hasEmailAccount && item?.email && (
                      <IconWrap
                        bothIcon={hasPhoneNumber && item?.phone && hasEmailAccount && item?.email}
                        title='Email'
                        buttonType='Email'
                        className='icon'
                        onClick={() => {
                          openSendEmailModal();
                          setLookupIds([{ id: item?.id, email: item?.email }]);
                        }}>
                        <SVGIcon name='icon-email' size={18} fill='var(--gray-icon-color)' />
                      </IconWrap>
                    )}
                  </IconActions>
                </ValueBox>
              );
            })}
        </TitleView>
      );
    return null;
  }, [
    connectedLookup?.companies,
    hasEmailAccount,
    hasPhoneNumber,
    history,
    isCompanyExpanded,
    lookupTableList,
    mobile,
    onCallClick,
    openSendEmailModal,
    setIsCompanyExpanded
  ]);

  const dealExpandedView = useMemo(() => {
    if (isDealExpanded)
      return (
        <>
          {filteredLookupDealsByAccess?.map((item, index) => {
            const dealAmount = currencySymbol?.symbol + currencyWithDecimal(item?.dealAmount);
            return (
              <ValueBox key={`filteredLookupDealsByAccess_${item?.id}_${index}`}>
                <NameUserWrapper>
                  <NameUser
                    onClick={() => {
                      const dealItem = getDealTables?.find((y) => y.id === item?.tableId);
                      history.push(`/dl/${dealItem?.name}/view/${item?.id}/overview`);
                    }}>
                    {item?.name}
                  </NameUser>
                </NameUserWrapper>
                {item?.dealAmount && <UserAmount>{dealAmount}</UserAmount>}
              </ValueBox>
            );
          })}

          {filteredLookupDealsByAccess?.length > 0 && userAccess?.permissionEditDeal && (
            <ALasttext
              margin
              onClick={() => {
                openAddModal('isAddDeal');
              }}>
              Add
            </ALasttext>
          )}
        </>
      );
    return null;
  }, [
    currencySymbol?.symbol,
    filteredLookupDealsByAccess,
    getDealTables,
    history,
    isDealExpanded,
    openAddModal,
    userAccess?.permissionEditDeal
  ]);

  const contactsExpandedView = useMemo(() => {
    if (isContactsExpanded)
      return connectedLookup?.contacts?.map((item, index) => {
        return (
          <ValueBox key={`connectedLookup_${item?.id}_${index}`}>
            <BoxContacts>
              <NameUserWrapper>
                <NameUser
                  onClick={() => {
                    const tableItem = lookupTableList?.find((y) => y.id === item?.tableId);
                    history.push(`/cn/${tableItem?.name}/view/${item?.id}/overview`);
                  }}>
                  {item?.name}
                </NameUser>
              </NameUserWrapper>
              {item?.email && <UserAmount>{item?.email}</UserAmount>}
            </BoxContacts>
            <IconActions>
              {hasPhoneNumber && item?.phone && (
                <IconWrap
                  bothIcon={hasPhoneNumber && item?.phone && hasEmailAccount && item?.email}
                  buttonType='Call'
                  title='Call'
                  className='icon'
                  onClick={() => {
                    onCallClick(item?.phone, item);
                  }}>
                  <SVGIcon name='icon-phone' size={18} fill='var(--gray-icon-color)' />
                </IconWrap>
              )}
              {hasEmailAccount && item?.email && (
                <IconWrap
                  bothIcon={hasPhoneNumber && item?.phone && hasEmailAccount && item?.email}
                  title='Email'
                  buttonType='Email'
                  className='icon'
                  onClick={() => {
                    openSendEmailModal();
                    setLookupIds([{ id: item?.id, email: item?.email }]);
                  }}>
                  <SVGIcon name='icon-email' size={18} fill='var(--gray-icon-color)' />
                </IconWrap>
              )}
            </IconActions>
          </ValueBox>
        );
      });
    return null;
  }, [
    connectedLookup?.contacts,
    hasEmailAccount,
    hasPhoneNumber,
    history,
    isContactsExpanded,
    lookupTableList,
    onCallClick,
    openSendEmailModal
  ]);

  const tagsExpandedView = useMemo(() => {
    if (isTagExpanded)
      return (
        <TagList
          tags={tags}
          selectedTags={selectedTags}
          updateLookupFields={updateLookupFields}
          field={lookupDetails?.tagsField}
        />
      );
    return null;
  }, [isTagExpanded, lookupDetails?.tagsField, selectedTags, tags, updateLookupFields]);

  const dealView = useMemo(() => {
    if (table?.type !== LOOKUP_TYPE.deals && filteredLookupDealsByAccess && userAccess?.isDealAccess)
      return (
        <TitleView mobile={mobile}>
          <BoxFirst>
            <FirstList>
              <SVGIcon name='icon-deals' size={22} fill='var(--gray-icon-color)' />
              <BoxText>Deal ({dealsCount})</BoxText>
            </FirstList>
            <LastView>
              {filteredLookupDealsByAccess?.length > 0 ? (
                <LastView onClick={() => setIsDealExpanded(IS_DEAL_EXPANDED_KEY, !isDealExpanded)}>
                  <SVGIcon
                    title={isDealExpanded ? 'Hide Deal' : 'Show Deal'}
                    name='icon-down-arrow'
                    size={22}
                    fill='var(--gray-icon-color)'
                  />
                </LastView>
              ) : (
                userAccess?.permissionEdit && (
                  <ALasttext
                    onClick={() => {
                      openAddModal('isAddDeal');
                    }}>
                    Add
                  </ALasttext>
                )
              )}
            </LastView>
          </BoxFirst>
          {dealExpandedView}
        </TitleView>
      );
    return null;
  }, [
    dealExpandedView,
    dealsCount,
    filteredLookupDealsByAccess,
    isDealExpanded,
    mobile,
    openAddModal,
    setIsDealExpanded,
    table?.type,
    userAccess?.isDealAccess,
    userAccess?.permissionEdit
  ]);

  const contactsView = useMemo(() => {
    if (connectedLookup?.contacts?.length > 0)
      return (
        <TitleView>
          <BoxFirst>
            <FirstList>
              <SVGIcon name='icon-follow-up' size={22} fill='var(--gray-icon-color)' />
              <BoxText>Contacts ({connectedLookup?.contacts?.length})</BoxText>
            </FirstList>
            <LastView onClick={() => setIsContactsExpanded(IS_CONTACTS_EXPANDED_KEY, !isContactsExpanded)}>
              <SVGIcon
                title={isContactsExpanded ? 'Hide Deal' : 'Show Deal'}
                name='icon-down-arrow'
                size={22}
                fill='var(--gray-icon-color)'
              />
            </LastView>
          </BoxFirst>
          {contactsExpandedView}
        </TitleView>
      );
    return null;
  }, [connectedLookup?.contacts?.length, contactsExpandedView, isContactsExpanded, setIsContactsExpanded]);

  const tagsView = useMemo(() => {
    if (lookupDetails?.tagsField)
      return (
        <TagView>
          <FirstTag mobile={mobile}>
            <FirstList>
              <SVGIcon name='icon-tag' size={22} fill='var(--gray-icon-color)' />
              <BoxText>Tag ({tagsCount})</BoxText>
            </FirstList>
            {(tagsCount > 0 || userAccess?.permissionEdit) && (
              <LastView
                onClick={() => {
                  setIsTagExpanded(IS_TAG_EXPANDED_KEY, !isTagExpanded);
                }}>
                <SVGIcon
                  title={isTagExpanded ? 'Hide Tags' : 'Show Tags'}
                  name='icon-down-arrow'
                  size={22}
                  fill='var(--gray-icon-color)'
                />
              </LastView>
            )}
          </FirstTag>
          {tagsExpandedView}
        </TagView>
      );
    return null;
  }, [
    isTagExpanded,
    lookupDetails?.tagsField,
    mobile,
    setIsTagExpanded,
    tagsCount,
    tagsExpandedView,
    userAccess?.permissionEdit
  ]);

  return (
    <>
      <SecondContain mobile={mobile}>
        {companyView}
        {dealView}
        {contactsView}
        {tagsView}
      </SecondContain>
      {sendEmailModal}
      {addDealModal}
    </>
  );
};

export default ContactDetailConnectedItems;
