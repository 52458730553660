import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTE } from '../constants/routeConst';
import { setupToken } from '../helpers/authTokenHelpers';
import { hasPageViewAccess } from '../services/appService';
import { trackPage } from '../services/analyticsService';

const PrivateRoute = ({ component: Component, auth, roleModuleName, role, ...rest }) => {
  trackPage({ visit_url: rest?.location?.pathname });
  const token = setupToken();
  const dispatch = useDispatch();
  let hasAccess = true;
  if (roleModuleName || role) {
    let tableName;
    if (roleModuleName === 'tables') {
      tableName = rest?.computedMatch?.params?.name;
    }
    hasAccess = dispatch(hasPageViewAccess(roleModuleName, role, tableName));
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          if (hasAccess) {
            return <Component {...props} />;
          }
          return <Redirect to='/access-denied' />;
        }
        //set return url if not home page
        const search =
          rest?.location?.pathname !== '/' ? `?return-url=${encodeURIComponent(rest?.location?.pathname)}` : '';
        return (
          <Redirect
            to={{
              pathname: ROUTE.LOGIN,
              search: search
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
