import styled, { css } from 'styled-components';
import Button from '../atom/Button/Button';
import { Header3 } from '../../styles';

const MainHeader = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px 12px 12px 16px;
        `
      : css`
          padding: 24px 24px 12px 24px;
        `}
`;
const LeftView = styled.div`
  display: flex;
  align-items: center;
`;
const SpnaText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--dark-color);
  margin-right: 24px;
  ${(props) =>
    props.select &&
    css`
      color: var(--primary-color);
    `};
`;
const Border = styled.hr`
  border-bottom: 1px solid #e4e6e8;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 8px 0;
`;
const RightView = styled.div`
  display: flex;
  align-items: center;
  .spacing {
    margin-left: 16px;
  }
`;
const MobileButton = styled(Button)`
  padding: 5px;
  border-radius: 50%;
`;
const ContactMobileMenu = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AllMenuSpan = styled.span`
  ${(props) =>
    props.mobile
      ? css`
          ${Header3}
          color: var(--dark-color);
          margin-right: 4px;
          -webkit-line-clamp: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 93px;
        `
      : css`
          ${Header3}
          color: var(--dark-color);
          margin-right: 4px;
        `}
`;
const ArrowUpDownImg = styled.img``;
const AllContactMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  transition: all 300ms;
  :hover {
    background-color: var(--popup-color-hover);
    border-radius: 3px;
  }
  ${(props) =>
    props.isMoreMenuOpen &&
    css`
      background-color: var(--popup-color-hover);
      border-radius: 3px;
    `}
`;
const VerticalIcon = styled.div`
  margin-left: 16px;
  svg {
    display: block;
  }
`;
const ContactMobileRight = styled.div`
  display: flex;
  align-items: center;
`;
const DotDiv = styled.div`
  margin: 0 12px;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: var(--gray-icon-color);
`;
const ContactMobileLeft = styled.div`
  display: flex;
  align-items: center;
`;
const MenuListItem = styled.div`
  .MenuActive {
    font-weight: 700;
  }
`;
const MenuItemWrapper = styled.div`
  padding: 6px 0;
`;
const HeaderMainWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FilterPanelBox = styled.div`
  margin-top: 16px;
`;
const FilterIcon = styled.div`
  margin-right: 16px;
  cursor: pointer;
  svg {
    display: block;
  }
`;
const Customstyle = {
  width: '640px'
};

export {
  MainHeader,
  LeftView,
  SpnaText,
  RightView,
  Border,
  MenuItemWrapper,
  MobileButton,
  ContactMobileMenu,
  AllMenuSpan,
  ArrowUpDownImg,
  AllContactMenu,
  VerticalIcon,
  ContactMobileRight,
  DotDiv,
  ContactMobileLeft,
  MenuListItem,
  HeaderMainWrap,
  FilterPanelBox,
  FilterIcon,
  Customstyle
};
