import styled, { css } from 'styled-components';
import { Text1 } from '../../../styles';

const MainContact = styled.div`
  position: relative;
`;
const TitleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid var(--Platinum-color);
`;
const TitleText = styled.span`
  font-style: normal;
  color: var(--dark-color);
  ${Text1};
`;
const IconView = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 12px;
`;
const Form = styled.form`
  max-height: calc(100vh - 520px);
  overflow: auto;
`;
const SecondContain = styled.div``;
const DropDiv = styled.div`
  background: var(--white-color);
  display: flex;
  align-items: center;
  padding: 0px 12px;
  margin-bottom: 18px;
  z-index: 9999;
  :last-child {
    margin-bottom: 0;
  }
`;
const DragIcon = styled.div`
  margin-right: 8px;
  cursor: grab;
  display: flex;
  align-items: center;
`;
const InputWrapperModal = styled.div`
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) =>
    props.paddingLeftRemover &&
    css`
      padding-left: 0;
    `}
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 14px;
    left: 0px;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--white-color);
    border: 1px solid var(--gainsboro-color);
    border-radius: 4px;
  }
  :hover input ~ .checkmark {
    border: 1px solid var(--spanish-gray);
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const TextView = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
`;
const SpanText = styled.span`
  ${Text1};
  display: inline-block;
  color: var(--nav-dark-color);
`;
const BottomView = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--Platinum-color);
  padding: 10px 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const BtnView = styled.div`
  button {
    margin-right: 15px;
  }
`;
const DropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 23px;
  height: 14px;
  input {
    z-index: 1;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 14px;
    width: 23px;
    left: 0px;
    margin: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-border-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 0.9px;
    top: 0.9px;
    background-color: var(--white-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-color);
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
  }
  .slider.round {
    border-radius: 16px;
  }

  .slider.round:before {
    border-radius: 16px;
  }
`;
const InputOne = styled.input``;
const Span = styled.span`
  &.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-icon-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &.slider:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 3px;
    bottom: 2px;
    background-color: var(--white-color);
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  &.slider&.round {
    border-radius: 34px;
  }

  &.slider&.round:before {
    border-radius: 50%;
  }
`;
const SaveButon = styled.span`
  color: var(--primary-color);
  ${Text1}
  cursor: pointer;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SortableItemWrapper = styled.div`
  margin: 12px 0;
`;

export {
  Span,
  InputOne,
  Switch,
  MainContact,
  TitleView,
  TitleText,
  IconView,
  Form,
  SecondContain,
  DropDiv,
  DragIcon,
  InputWrapperModal,
  TextView,
  SpanText,
  BottomView,
  BtnView,
  DropDownWrapper,
  SaveButon,
  HeaderWrapper,
  SortableItemWrapper
};
