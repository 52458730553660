import styled, { css } from 'styled-components';
import SpanDot from '../../../assets/images/svg/dot.svg';
import { Header4, SubHeader3, Text1, Text2 } from '../../../styles';
import Button from '../../../components/atom/Button/Button';
// import RibbinLeft from '../../../assets/images/ribbinleft.png';
// import RibbinRight from '../../../assets/images/ribbinright.png';
// import RibbinCenter from '../../../assets/images/ribbincenter.png';
import InvoiceLeft from '../../../assets/images/svg/Invoiceleft.svg';
import InvoiceRight from '../../../assets/images/svg/Invoiceright.svg';
import InvoiceCenter from '../../../assets/images/svg/invoicecenter.svg';

const DownloadInvoiceLink = styled.a``;

const InvoiceWrapper = styled.div`
  overflow: auto;
  ${(props) =>
    props.mobile
      ? css`
          height: calc(100vh - 100px);
        `
      : css`
          height: calc(100vh - 46px);
        `}
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 776px;
  margin: auto;
  ${(props) =>
    props.mobile
      ? css`
          padding: 16px;
          flex-wrap: wrap;
        `
      : css`
          padding: 25px 0;
        `}
`;
const InvoiceBoxWrappe = styled.div`
  position: relative;
  max-width: 720px;
  margin: auto;
`;
const SpanInvoiceLeft = styled.span`
  border-top: 1px solid var(--alice-blue-border);
  border-left: 1px solid var(--alice-blue-border);
  border-bottom: 1px solid var(--alice-blue-border);
  width: 27px;
  position: absolute;
  height: 100%;
  background-image: url(${SpanDot});
  background-repeat: repeat-y;
  background-position: center center;
  left: -27px;
  ${(props) =>
    props.mobile
      ? css`
          left: 0;
        `
      : css`
          left: -27px;
        `}
`;
const InvoiceTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const InvoiceStatus = styled.span`
  ${(props) =>
    css`
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      text-transform: uppercase;
      position: relative;
      padding: 7px 30px 6px 25px;
      left: 9px;
      z-index: 9;
      color: var(--white-color);
      background-color: var(${props?.statusColor});
      ::before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        right: 0;
        bottom: -8px;
        border-bottom: 4px solid transparent;
        border-right: 4px solid transparent;
        border-left: 4px solid var(${props?.statusColor});
        border-top: 4px solid var(${props?.statusColor});
      }
      ::after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0;
        left: -15px;
        border-left: 15px solid transparent;
        border-right: 0 solid var(${props?.statusColor});
        border-bottom: 14px solid var(${props?.statusColor});
        border-top: 16px solid var(${props?.statusColor});
      }
    `}
`;
const InvoiceMainStatus = styled.div`
  float: right;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: 13px;
`;
const InvoiceBoxMain = styled.div`
  border: 1px solid var(--gray-border-color);
  background-color: var(--white-color);
  height: 100%;
  ${(props) =>
    props.mobile &&
    css`
      padding: 0 27px;
    `}
`;
const SpanInvoiceRight = styled.span`
  background-image: url(${SpanDot});
  border-top: 1px solid var(--alice-blue-border);
  border-right: 1px solid var(--alice-blue-border);
  border-bottom: 1px solid var(--alice-blue-border);
  width: 27px;
  position: absolute;
  height: 100%;
  background-repeat: repeat-y;
  background-position: center center;
  right: -27px;
  top: 0;
  ${(props) =>
    props.mobile
      ? css`
          right: 0;
        `
      : css`
          right: -27px;
        `}
`;
const InvoiceIcon = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.mobile &&
    css`
      margin-bottom: 16px;
    `}
  i {
    :first-child {
      margin-right: 12px;
    }
  }
`;
const InvoiceNumber = styled.span`
  ${SubHeader3}
  display: block;
  margin-left: 12px;
`;
const InvoiceMainButton = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile &&
    css`
      width: calc(100% - 14px);
      overflow-x: auto;
    `}
`;
const InvoiceButtonList = styled.li`
  margin-right: 10px;
  white-space: nowrap;
  :last-child {
    margin-right: 0px;
  }
`;
const IconButton = styled(Button)`
  padding: 8px;
  i {
    margin-right: 0;
  }
`;
const InvoiceTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 16px;
        `
      : css`
          padding: 0px 25px;
        `}
`;
const InvoiceLogoMain = styled.div`
  width: 100%;
  ${(props) =>
    props.mobile
      ? css`
          max-width: 30%;
        `
      : css`
          max-width: 40%;
        `}
`;
const InvoiceLogoBlock = styled.div`
  height: 70px;
  ${(props) =>
    props.mobile
      ? css`
          width: 100%;
        `
      : css`
          width: 180px;
        `}
`;
const InvoiceLogo = styled.img`
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const InvoiceDetail = styled.div`
  max-width: 60%;
  width: 100%;
`;
const InvoiceListMain = styled.ul`
  max-width: 233px;
  width: 100%;
  margin: 0px 0px 0px auto;
  padding: 0;
`;
const InvoiceInnerList = styled.li`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  flex-wrap: wrap;
  :last-child {
    margin-bottom: 0px;
  }
`;
const InvoiceLable = styled.span`
  display: block;
  max-width: 40%;
  width: 100%;
  text-align: left;
  ${Text2}
  color: var(--gray-color);
`;
const InvoiceDetailText = styled.span`
  display: block;
  max-width: 60%;
  width: 100%;
  text-align: left;
  ${Text2}
  color: var(--dark-color);
`;
const InvoiceRibinMain = styled.div`
  margin: 8px 0 15px 0;
  text-align: center;
  display: block;
  width: 100%;
`;
const InvoiceInnerRibbin = styled.div`
  position: relative;
  ::before {
    content: '';
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 3px 0;
  }
`;
const InvoiceRibbinHead = styled.h4`
  ${Header4}
  line-height: 70px;
  display: inline-block;
  width: auto;
  height: 70px;
  color: var(--dark-color);
  position: relative;
  background-image: url(${InvoiceCenter});
  background-repeat: repeat-x;
  background-size: 20px 70px;
  background-color: var(--white-color);
`;
const InvoiceRibbinRight = styled.span`
  width: 30px;
  height: 70px;
  background-image: url(${InvoiceLeft});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 32px 70px;
  position: absolute;
  left: -30px;
  top: 0;
`;
const InvoiceRibbinLeft = styled.span`
  width: 30px;
  height: 70px;
  background-image: url(${InvoiceRight});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 32px 70px;
  position: absolute;
  top: 0;
  right: -30px;
`;
const BillMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0px 16px;
        `
      : css`
          padding: 0px 24px;
        `}
`;
const BillFrom = styled.div`
  display: flex;
`;
const BillTo = styled.div`
  max-width: 250px;
  width: 100%;
`;
const BillLable = styled.span`
  display: block;
  ${Text2}
  color: var(--gray-color);
  margin-right: 6px;
  white-space: nowrap;
`;
const FromBillMain = styled.div`
  max-width: 215px;
  width: 100%;
`;
const BillHead = styled.h5`
  ${Text1}
  color: var(--dark-color);
  margin: 0px 0px 5px 0px;
`;
const BillPara = styled.p`
  ${Text2}
  margin: 0;
`;
const InvoiceTable = styled.table`
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;
const InvoiceTableHead = styled.thead``;
const InvoiceTableRow = styled.tr`
  width: 100%;
`;
const TableHeadCell = styled.th`
  ${Text1}
  color: var(--gray-color);
  padding: 12px 8px 10px 8px;
  border: 1px solid var(--gray-border-color);
  border-left: 0;
  border-bottom: 2px solid var(--gray-border-color);
  :last-child {
    border-right: 0;
  }
`;
const InvoiceTabaleBody = styled.tbody``;
const InvoicetdCell = styled.td`
  ${Text1}
  color: var(--dark-color);
  padding: 8px 8px 8px 8px;
  border: 1px solid var(--gray-border-color);
  border-left: 0;
  :last-child {
    border-right: 0;
  }
`;
const InvoiceTablewrapper = styled.div`
  padding: 30px 16px;
`;
const InvoiceListWrapper = styled.div`
  flex: 0 0 auto;
  margin: 0px 0px 0px auto;
`;
const InvoiceAmountInner = styled.ul`
  list-style: none;
  padding-left: 26px;
  margin: 0;
  border-bottom: 1px solid var(--gray-border-color);
  :nth-child(2) {
    padding: 12px 0px 12px 26px;
  }
`;
const InvoiceAmountList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 8px;
  }
`;
const AmountLable = styled.span`
  display: block;
  ${Text2}
  color: var(--gray-color);
`;
const AmountDetail = styled.span`
  display: block;
  ${Text2}
  color: var(--dark-color);
  margin-left: 60px;
`;
const TotalAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const InvoiceBottom = styled.div`
  margin-top: 10px;
  padding: 0px 24px 20px 24px;
`;
const InvoiceNote = styled.span`
  display: block;
  margin-bottom: 10px;
  ${Text1}
  color: var(--dark-color);
`;
const InvoiceNotePara = styled.p`
  ${Text2}
  color: var(--gray-color);
  max-width: 624px;
  width: 100%;
  margin: 0;
`;
const InvoiceTotal = styled.div`
  padding: 12px 0px 12px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-border-color);
`;
const InvoiceLine = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 4px;
  background-color: var(--gray-border-color);
`;
const InvoiceLeftBlog = styled.div`
  flex: 1 1 auto;
`;
const InvoiceAmountBlog = styled.div`
  display: flex;
  padding: 0 24px;
`;
const BottomLogoWrapper = styled.div`
  max-width: 150px;
  width: 100%;
  margin: 50px auto;
  text-align: center;
`;
const LogoSpan = styled.span`
  display: block;
  margin-bottom: 10px;
  ${Text2}
  color: var(--gray-color);
`;
const InvoiceBottomLogo = styled.div``;
const SalescampMainLogo = styled.img``;
const SvgWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export {
  InvoiceWrapper,
  Header,
  InvoiceBoxWrappe,
  SpanInvoiceLeft,
  InvoiceStatus,
  InvoiceBoxMain,
  SpanInvoiceRight,
  InvoiceIcon,
  InvoiceNumber,
  InvoiceMainButton,
  IconButton,
  InvoiceTop,
  InvoiceLogoMain,
  InvoiceLogo,
  InvoiceDetail,
  InvoiceListMain,
  InvoiceInnerList,
  InvoiceLable,
  InvoiceDetailText,
  InvoiceMainStatus,
  InvoiceTopWrapper,
  InvoiceRibinMain,
  InvoiceInnerRibbin,
  InvoiceRibbinHead,
  InvoiceRibbinRight,
  InvoiceRibbinLeft,
  BillMain,
  BillFrom,
  BillTo,
  BillLable,
  FromBillMain,
  BillHead,
  BillPara,
  InvoiceTable,
  InvoiceTableHead,
  InvoiceTableRow,
  TableHeadCell,
  InvoiceTabaleBody,
  InvoicetdCell,
  InvoiceTablewrapper,
  InvoiceAmountInner,
  InvoiceAmountList,
  AmountLable,
  AmountDetail,
  TotalAmount,
  InvoiceBottom,
  InvoiceNote,
  InvoiceNotePara,
  InvoiceListWrapper,
  InvoiceTotal,
  InvoiceLine,
  InvoiceLogoBlock,
  InvoiceLeftBlog,
  InvoiceAmountBlog,
  InvoiceButtonList,
  BottomLogoWrapper,
  LogoSpan,
  InvoiceBottomLogo,
  SalescampMainLogo,
  DownloadInvoiceLink,
  SvgWrapper
};
