import styled from 'styled-components';
import { Header1, SubHeader3, Text2 } from '../../../styles';

const ManeWrapper = styled.div`
  background: var(--main-bg);
  width: 100%;
  height: 100%;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  flex-direction: column;
`;
const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  svg {
    display: block;
    margin: auto;
  }
`;
const Title = styled.h1`
  ${Header1}
  text-align: center;
  color: var(--dark-color);
  margin: 25px 0 0;
`;
const Form = styled.form`
  input {
    margin-top: -1px;
    position: relative;
  }
  #confirm {
    border-radius: 0px 0px 4px 4px;
  }
  #password {
    margin-bottom: 1px;
    border-radius: 4px 4px 0px 0px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;
const AlreadyPara = styled.p`
  ${SubHeader3}
  text-align: center;
  color: var(--dark-color);
  margin: 20px 0 0 0;
`;
const ButtonWrap = styled.div`
  margin-top: 30px;
`;
const Link = styled.a`
  text-decoration: none;
  color: var(--primary-color);
  :hover {
    text-decoration: underline;
  }
`;
const PrivacyPolicy = styled.p`
  ${Text2}
  color: var(--dark-color);
  margin: 20px 0 0 0;
  text-align: center;
`;

export { ManeWrapper, LoginContainer, LogoWrapper, Title, Form, AlreadyPara, ButtonWrap, Link, PrivacyPolicy };
