import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import moment from 'moment';
import { Device } from '@twilio/voice-sdk';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  setPhoneCallDetails,
  setPhoneCallLoader,
  setTwilioCharges,
  updatePhoneCallDetails,
  updateInputDevices,
  updateOutputDevices
} from '../actions/phoneCallActions';
import { ACTIVITY_ACTION, LOOKUP_ACTIVITY_TYPE, LOOKUP_TYPE, PHONE_CALL_TYPE } from '../constants/constant';
import { CountryCodes } from '../data/raw';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIErrorReason, getUTCDate } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/UserPreferenceSingleton';
// import { setIsAppUpgradeToPro } from '../actions/appActions';
import { createActivity } from './activityService';
import { trackAnalyticActivity } from './analyticsService';
import { captureException, captureMessage } from './logService';
import { disconnectVoiceCall } from './callLogService';

let singleton;
let organizationId;
let mediaStream;

const INCOMING_CALL_RING_SOUND = 'https://storage.googleapis.com/pixer-resources/salescamp/incoming.wav';

let ipLocation;
/**
 * @desc get clients IP details
 */
export const fetchIPLocation = async () => {
  try {
    if (ipLocation) return ipLocation;
    const response = await axios.get(`https://api.salescamp.app/api/other/location`, {
      headers: {
        accept: 'application/json, text/plain, */*',
        'accept-language': 'en-US,en;q=0.9'
      },
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
        return data;
      }
    });
    const { data } = response;
    ipLocation = data;
    return data;
  } catch (e) {
    return undefined;
  }
};

/**
 * @desc get clients IP details
 */
export const getIPLocation = () => {
  try {
    return ipLocation;
  } catch (e) {
    return undefined;
  }
};

/**
 * @desc Twilio - Get Twilio Charges
 * @param {*} organization_id
 */
export const getTwilioCharges = (organization_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    const response = await axios.get(`${REACT_APP_APIURL}/Twilio/Charges/${organization_id}`);
    const { data } = response;
    if (data) {
      const filterResult = process(data, {
        sort: [{ field: 'dateCreated', dir: 'desc' }]
      });
      dispatch(setTwilioCharges(filterResult?.data));
      return true;
    }
  } catch (e) {
    dispatchTwilioError(getAPIErrorReason(e) || 'Unable to get twilio charges please try again', dispatch);
    return false;
  }
};

/**
 * @desc Twilio - Send Sms
 * @param {*} organization_id, payload
 */
export const sendSms = (organization_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!organization_id) return false;
    dispatch(setPhoneCallLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/Call/${organization_id}/SendSMS`, payload);
    const { data } = response;
    if (data) {
      dispatchTwilioSucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchTwilioError(getAPIErrorReason(e) || 'Unable to send sms please try again', dispatch);
    return false;
  } finally {
    dispatch(setPhoneCallLoader(false));
  }
};

export const getPrimaryPhoneNumber = () => (dispatch, getState) => {
  const state = getState();
  const phoneNumbers = state?.setting?.phoneNumberList;
  const primaryNumberItem = phoneNumbers.find((y) => y.isPrimary === true);
  if (primaryNumberItem) {
    return primaryNumberItem?.phoneNumber;
  }
  return '';
};

export default class TwilioService {
  isConnected = false;
  isDeviceReady = false;
  isNumberReady = false;
  primaryNumber = '';
  isCallRecord = false;
  twilioDevice;
  incomingCall;
  outgoingCall;
  errorCount = 0;

  static getInstance() {
    const org = UserPreferenceSingleton.getInstance().getOrganization();
    if (org) {
      if (!singleton || organizationId !== org.id) {
        organizationId = org.id;
        singleton = new TwilioService();
      }
    }
    return singleton;
  }

  static removeInstance() {
    organizationId = undefined;
    singleton = undefined;
  }

  getToken = async () => {
    const response = await axios.get(`${REACT_APP_APIURL}/call/${organizationId}/capability-token`);
    const { data } = response;
    return data?.token;
  };

  initDeviceToken = async () => {
    const ttl = 600000; // 10 minutes
    const refreshBuffer = 30000; // 30 seconds
    if (this.tokenInterval) clearInterval(this.tokenInterval);

    if (!this.isDeviceReady) return;
    this.tokenInterval = setInterval(async () => {
      const newToken = await this.getToken();
      if (newToken && this.twilioDevice) {
        this.twilioDevice.updateToken(newToken);
      }
    }, ttl - refreshBuffer); // Gives us a generous 30-second buffer
  };

  initCallingService = (isForceInIt) => async (dispatch, getState) => {
    try {
      const state = getState();
      const phoneNumbers = state?.setting?.phoneNumberList;
      const hasPhoneNumber = phoneNumbers?.length > 0;

      if (!hasPhoneNumber) return;
      if (this.isDeviceReady && !isForceInIt) return;

      // TODO check phone numbers, if phone number available we can enable service or we can skip it
      if (!phoneNumbers || phoneNumbers.length === 0) {
        // we need to update once we delete existing number
        this.isNumberReady = false;
        this.primaryNumber = undefined;
        return;
      }

      const primaryNumberItem = phoneNumbers.find((y) => y.isPrimary === true);
      if (primaryNumberItem) {
        this.primaryNumber = primaryNumberItem.phoneNumber;
        this.isNumberReady = true;
      } else {
        this.primaryNumber = dispatch(getPrimaryPhoneNumber());
      }

      if (!this.primaryNumber) return;

      const token = await this.getToken();
      const options = {
        // logLevel: 1,
        sounds: { incoming: INCOMING_CALL_RING_SOUND },
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions.
        codecPreferences: ['opus', 'pcmu']
      };
      this.twilioDevice = new Device(token, options);
      this.twilioDevice.on('registered', (device) => {
        this.isDeviceReady = true;
        this.initDeviceToken();
      });
      this.twilioDevice.on('error', (error) => {
        console.log('Twilio error', error);
        // if (error.message === 'JWT Token Expired') {
        //   console.log('Quill Call Service: JWT Token Expired');
        if (error.message) captureMessage(error.message);
        this.errorCount += 1;
        this.isDeviceReady = false;
        if (this.errorCount <= 15) dispatch(this.initCallingService(true));
        return;
        // }

        // console.log('Twilio.Device Error: ' + error.message);
        // dispatch(setErrorMessage(error.message));

        // //Clear call details to remove UI
        // dispatch(setPhoneCallDetails(undefined));
      });
      this.twilioDevice.on('connect', (conn) => {
        this.isConnected = true;
        if (conn && conn.parameters && conn.parameters.CallSid) {
          dispatch(updatePhoneCallDetails({ propName: 'callSid', value: conn.parameters.CallSid }));
        }
        dispatch(updatePhoneCallDetails({ propName: 'connectedAt', value: new Date() }));
        dispatch(updatePhoneCallDetails({ propName: 'connection', value: conn }));
      });
      this.twilioDevice.audio.on('deviceChange', () => {
        dispatch(this.updateAllAudioDevices());
      });

      this.twilioDevice.on('reject', (conn) => {
        console.log('on reject event');
        dispatch(this.onDisconnectCall(conn));
      });

      this.twilioDevice.on('disconnect', (conn) => {
        //TODO: handle call disconnected
        dispatch(this.onDisconnectCall(conn));
      });

      this.twilioDevice.on('incoming', (conn) => {
        // console.log('Incoming connection from ', conn);
        // add event listener to call object
        conn.on('accept', (call) => {
          this.isConnected = true;
          dispatch(updatePhoneCallDetails({ propName: 'connectedAt', value: new Date() }));
        });
        conn.on('cancel', (call) => {
          dispatch(this.onDisconnectCall(call));
        });
        conn.on('disconnect', (call) => {
          dispatch(this.onDisconnectCall(call));
        });
        conn.on('reject', (call) => {
          dispatch(this.onDisconnectCall(call));
        });

        if (conn && conn.parameters) {
          this.incomingCall = conn;
          const state = getState();
          const phoneCallDetails = JSON.parse(JSON.stringify(state.phoneCall.details));
          if (phoneCallDetails) return;

          const callDetails = {
            phoneNumber: conn.parameters.From,
            startedAt: new Date(),
            type: PHONE_CALL_TYPE.INCOMING,
            accountSid: conn.parameters.AccountSid,
            callSid: conn.parameters.CallSid,
            connection: conn
          };
          dispatch(setPhoneCallDetails(callDetails));

          const { getLookupsByPhone } = require('./lookupService');
          getLookupsByPhone(conn.parameters.From).then((lookups) => {
            if (lookups && lookups.length > 0) {
              const lookup = lookups[0];
              dispatch(updatePhoneCallDetails({ propName: 'lookup', value: lookup }));
              dispatch(updatePhoneCallDetails({ propName: 'name', value: lookup.name }));
            }
          });
        }
      });
      // Device must be registered in order to receive incoming calls
      if (this.twilioDevice.state !== Device.State.Registered) await this.twilioDevice.register();

      //add method to enable twillio log
      window.enableTwilioLog = () => {
        this.twilioDevice.updateOptions({ logLevel: 1 });
      };
    } catch (e) {
      console.log('e.message', e.message);
      captureException(e);
      dispatch(setErrorMessage(e.message));
    }
  };

  onDisconnectCall = (conn) => (dispatch, getState) => {
    this.isConnected = false;
    const state = getState();
    const lookupTableList = state?.lookupTables?.list;
    const phoneCallDetails = JSON.parse(JSON.stringify(state.phoneCall.details));
    dispatch(this.createCallActivity(phoneCallDetails));

    //Track Analytics activity
    const table = lookupTableList?.find((x) => x?.id === phoneCallDetails?.lookup?.tableId);
    if (table) {
      let action;
      const { getTableTypeForAnalytics } = require('./lookupService');
      const type = getTableTypeForAnalytics(table);
      let params = {
        to: conn?.message?.To,
        from: conn?.message?.From
      };

      if (phoneCallDetails?.connectedAt) {
        const connectedAt = phoneCallDetails?.connectedAt;
        var connecteTime = moment(connectedAt);
        var now = moment();
        var duration = moment.duration(now.diff(connecteTime));
      }

      if (duration > 0) {
        action = 'completed';
        params = {
          ...params,
          duration: duration.seconds()
        };
      } else {
        action = 'not connected';
        params = {
          ...params,
          reason: undefined
        };
      }
      this.trackCallActionAnalytics(type, action, params);
    }

    //Clear call details to remove UI
    dispatch(setPhoneCallDetails(undefined));
    this.incomingCall = undefined;
    this.releaseAllDevices();
  };

  trackCallActionAnalytics = (type, action, params) => {
    try {
      trackAnalyticActivity(`${type}: call ${action}`, params);
    } catch (e) {
      console.log(`track ${type}: call ${action} Error`, e);
    }
  };

  connectCall = (phone, name, lookup) => async (dispatch, getState) => {
    const company = UserPreferenceSingleton.getInstance().getOrganization();

    this.primaryNumber = dispatch(getPrimaryPhoneNumber());
    if (!this.primaryNumber) return;

    const state = getState();
    const phoneCallDetails = state?.phoneCall?.details;
    if (phoneCallDetails) return;

    // const { getCurrentCompanyPlanType } = require('./organizationService');
    // let currentPlan = getCurrentCompanyPlanType();
    // if (currentPlan !== 'paid') {
    //   //TODO: Show upgrade plan
    //   dispatch(setIsAppUpgradeToPro(true));
    //   return;
    // }

    const checkMicrophonePermission = async () => {
      try {
        const permissions = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        if (permissions) {
          mediaStream = permissions;
          return true;
        }
      } catch (err) {
        const errorMessage = 'Please enable access to microphone to continue calling';
        dispatch(setErrorMessage(errorMessage));
        return false;
      }
    };

    const result = await checkMicrophonePermission();
    if (!result) return;

    if (!this.primaryNumber) {
      dispatch(setErrorMessage('Phone Number not found, Please add from settings'));
      return;
    }
    if (!phone) {
      dispatch(setErrorMessage('Phone Number not available to call'));
      return;
    }
    if (!this.isDeviceReady) {
      dispatch(setErrorMessage('Device is not ready for call, Try again after few seconds'));
      console.error('Device is not ready for call');
      return;
    }

    if (!this.isNumberReady) {
      dispatch(setErrorMessage('Primary Number not available for call'));
      console.error('Primary Number not available for call');
      return;
    }

    if (!phone.startsWith('+')) {
      //If number not have country code add country dial code(local)
      //Get location and country of user,if found find country code
      let IpLocation = getIPLocation();
      if (IpLocation) {
        let countryCode = IpLocation.country_code;
        if (countryCode) {
          let countryCodeList = CountryCodes;
          let country = countryCodeList.find((x) => (x.countryCode = countryCode));
          if (country) {
            phone = country.code + phone;
          }
        }
      }
    }

    dispatch(this.getAudioDevices());

    if (company && company.settings) this.isCallRecord = company.settings.isCallRecording;

    let user = UserPreferenceSingleton.getInstance().getCurrentUser();

    if (!this.isConnected && company && user) {
      const callDetails = {
        name: name || lookup?.name,
        phoneNumber: phone,
        startedAt: new Date(),
        lookup: lookup,
        type: PHONE_CALL_TYPE.OUTGOING
      };
      dispatch(setPhoneCallDetails(callDetails));

      var params = {
        To: phone,
        From: this.primaryNumber,
        IsRecord: false,
        CompanyId: company.id,
        UserId: user.id,
        LookupId: lookup.id
      };
      if (this.isCallRecord === true) params.IsRecord = true;
      if (this.twilioDevice) {
        const outgoingCall = await this.twilioDevice.connect({ params });
        this.outgoingCall = outgoingCall;
        outgoingCall.on('accept', (call) => {
          this.isConnected = true;
          if (call && call.parameters && call.parameters.CallSid) {
            dispatch(updatePhoneCallDetails({ propName: 'callSid', value: call.parameters.CallSid }));
          }
          dispatch(updatePhoneCallDetails({ propName: 'connectedAt', value: new Date() }));
          dispatch(updatePhoneCallDetails({ propName: 'connection', value: call }));
        });
        outgoingCall.on('ringing', function (hasEarlyMedia) {
          console.log('ringing...............');
        });
        outgoingCall.on('reconnecting', function (hasEarlyMedia) {
          console.log('reconnecting...............');
        });
        outgoingCall.on('reconnected', function (hasEarlyMedia) {
          console.log('reconnected...............');
        });
        outgoingCall.on('cancel', (call) => {
          dispatch(this.onDisconnectCall(call));
        });
        outgoingCall.on('disconnect', (call) => {
          dispatch(this.onDisconnectCall(call));
        });
      }
    }
  };

  muteUnmuteCall = (value) => (dispatch) => {
    if (this.incomingCall) {
      this.incomingCall.mute(value);
    }
    if (this.outgoingCall) {
      this.outgoingCall.mute(value);
    }
  };

  rejectCall = () => (dispatch) => {
    if (this.incomingCall) {
      this.incomingCall.reject();
    }
    if (this.twilioDevice) this.twilioDevice.disconnectAll();
  };

  disconnectCall = () => (dispatch) => {
    let call;
    try {
      call = JSON.parse(JSON.stringify(this.incomingCall?.parameters || this.outgoingCall?.parameters || {}));
      if (this.incomingCall) {
        this.incomingCall.disconnect();
      }
      if (this.outgoingCall) {
        this.outgoingCall.disconnect();
      }
      if (this.twilioDevice) this.twilioDevice.disconnectAll();
    } catch (e) {
      captureException(e);
    } finally {
      const company = UserPreferenceSingleton.getInstance()?.getOrganization();
      dispatch(disconnectVoiceCall(company?.id, call));
    }
  };

  acceptCall = () => (dispatch) => {
    if (this.incomingCall) {
      this.incomingCall.accept();
      dispatch(this.getAudioDevices());
    }
  };

  createCallActivity = (call) => async (dispatch) => {
    if (call && call?.lookup) {
      const user = UserPreferenceSingleton.getInstance().getCurrentUser();
      const activity = {
        companyId: organizationId,
        activityType: LOOKUP_ACTIVITY_TYPE.TASKS,
        taskStatus: true,
        title: call?.type === PHONE_CALL_TYPE.INCOMING ? 'Incoming Call' : 'Outgoing Call',
        action: ACTIVITY_ACTION.Call,
        assignedUser: { id: user?.id, name: user?.given_name, email: user?.email },
        date: getUTCDate(),
        time: call?.connectedAt || call?.startedAt,
        callSid: call?.callSid,
        lookupType: LOOKUP_TYPE.contacts,
        lookup: call?.lookup
      };
      await dispatch(createActivity(organizationId, activity));
    }
  };

  getAudioDevices = () => async (dispatch) => {
    if (mediaStream) return;
    mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    dispatch(this.updateAllAudioDevices());
  };

  updateAllAudioDevices = () => (dispatch) => {
    if (this.twilioDevice) {
      dispatch(this.updateInputDevices());
      dispatch(this.updateOutputDevices());
    }
  };
  updateInputDevices = () => (dispatch) => {
    let devices = [];
    if (!this.twilioDevice) return;
    const selectedInputDeviceId = this.twilioDevice?.audio?.inputDevice?.deviceId || 'default';
    this.twilioDevice.audio.availableInputDevices.forEach(function (device, id) {
      let isActive = false;
      if (selectedInputDeviceId === device.deviceId) isActive = true;
      const deviceItem = { id, value: id, label: device.label, selected: isActive };
      devices.push(deviceItem);
    });
    dispatch(updateInputDevices(devices));
  };

  updateOutputDevices = () => (dispatch) => {
    let devices = [];
    if (!this.twilioDevice) return;
    const selectedDevices = this.twilioDevice.audio.speakerDevices.get();
    this.twilioDevice.audio.availableOutputDevices.forEach(function (device, id) {
      let isActive = selectedDevices.size === 0 && id === 'default';
      selectedDevices.forEach(function (device) {
        if (device.deviceId === id) {
          isActive = true;
        }
      });
      const deviceItem = { id, value: id, label: device.label, selected: isActive };
      devices.push(deviceItem);
    });
    dispatch(updateOutputDevices(devices));
  };

  onChangeInputDevice = (deviceId) => async (dispatch) => {
    if (deviceId && this.twilioDevice) {
      await this.twilioDevice.audio.setInputDevice(deviceId);
      dispatch(this.updateAllAudioDevices());
    }
  };

  onChangeOutputDevice = (deviceId) => async (dispatch) => {
    if (this.twilioDevice) {
      await this.twilioDevice.audio.speakerDevices.set(deviceId);
      dispatch(this.updateAllAudioDevices());
    }
  };

  clearTwilioData = () => {
    try {
      this.isConnected = false;
      this.isDeviceReady = false;
      this.isNumberReady = false;
      this.primaryNumber = undefined;
      this.isCallRecord = false;
      this.twilioDevice = undefined;
      this.errorCount = 0;
    } catch (e) {
      console.log('ERROR clearTwilioData', e);
    }
  };

  releaseAllDevices = () => {
    try {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track && track.stop());
        if (mediaStream.stop) mediaStream.stop();
      }
      if (this.twilioDevice && this.twilioDevice.audio.inputStream && this.twilioDevice.audio.inputStream.getTracks) {
        this.twilioDevice.audio.inputStream.getTracks().forEach((track) => track && track.stop());
      }
    } catch (e) {
      console.log('ERROR releaseAllDevices', e);
    }
  };
}
function dispatchTwilioError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
function dispatchTwilioSucess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
