import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import SVGIcon from '../../../assets/svg/SVGIcon';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../../global/Environment';
import StripeCheckoutForm from '../../Setting/Subscription/Upgrade/StripeCheckoutForm';
import {
  MainForm,
  ModalHeader,
  LeftWrapper,
  ModalHeaderTitel,
  RightWrapper,
  FormWrapper,
  FormMainInner
} from './styles';

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

export const PaymentModal = ({ onClose }) => {
  return (
    <>
      <MainForm>
        <ModalHeader>
          <LeftWrapper>
            <SVGIcon name='icon-dollar' fill='var(--gray-icon-color)' size={22} />
            <ModalHeaderTitel>Make Payment</ModalHeaderTitel>
          </LeftWrapper>
          <RightWrapper onClick={onClose}>
            <SVGIcon name='icon-close' fill='var(--gray-icon-color)' size={20} />
          </RightWrapper>
        </ModalHeader>
        <FormWrapper>
          <FormMainInner>
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm plan={1} userCount={1} />
            </Elements>
          </FormMainInner>
        </FormWrapper>
      </MainForm>
    </>
  );
};
