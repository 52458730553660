import React from 'react';
import Avtar from '../Avtar/Avtar';
import { InputLabel, InputWrapperModal, ProfileName, StyledMenuItem } from './styles';

const UserListPopup = (props) => {
  const { userList, onChange, currentUserId, itemId = '' } = props;

  return (
    <>
      {userList?.map((user, index) => {
        return (
          <StyledMenuItem key={user?.id}>
            <InputWrapperModal>
              <InputLabel htmlFor={`${user?.id}_${index}_${itemId}`}>
                <Avtar key={user?.id} imgSrc={user?.profile_photo} name={user?.name} size={20} />
                <ProfileName>{user?.name}</ProfileName>
              </InputLabel>
              <input
                id={`${user?.id}_${index}_${itemId}`}
                type='checkbox'
                disabled={currentUserId && currentUserId === user?.id}
                checked={user?.isSelected}
                onChange={() => {
                  onChange(user?.isSelected, user?.id, itemId);
                }}
              />
              <span className='checkmark'></span>
            </InputWrapperModal>
          </StyledMenuItem>
        );
      })}
    </>
  );
};

export default UserListPopup;
